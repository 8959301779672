import apiRequest from "shared/network/apiRequest";
import { EventedRoomBookResources } from "./roomBookActions";

export default class RoomBookUpgradeBundlesResource extends EventedRoomBookResources {
  constructor(dispatch, roomBookId) {
    super(
      dispatch,
      "room_book_upgrade_bundle",
      `/api/v1/room_books/${roomBookId}/upgrade_bundles`
    );
    this.refreshPageContentAfterCommit = true;
  }

  updatePrice(upgradeBundleId, priceInCents) {
    const payload = {
      price: {
        price_in_cents: priceInCents
      }
    };
    return apiRequest
      .put(`${this.url}/${upgradeBundleId}/price`, this.dispatch, payload)
      .then(this.propagateRoomBookEvent);
  }
}
