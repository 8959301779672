import { createSelector } from "reselect";

import {
  getProjects,
  getUnits,
  getBuyers,
  getContractors,
  getUsers
} from "./masterdata";

export const getSearchTerms = createSelector(
  [getProjects, getUnits, getBuyers, getContractors, getUsers],
  (projects, units, buyers, contractors, users) => {
    const cfgs = [
      [projects, "checkmark"],
      [buyers, "child"],
      [users, "user"],
      [contractors, "truck"]
    ];
    return cfgs.reduce((accu, cfg) => {
      return accu.concat(
        cfg[0].map(element => {
          return {
            value: `${element.type}:${element.id}`,
            key: `${element.type}:${element.id}`,
            text: element.label || element.name,
            icon: cfg[1]
          };
        })
      );
    }, []);
  }
);
