import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Header, Grid, Button, Icon } from "semantic-ui-react";
import ProjectEmailsResource from "builder_portal/actions/projectEmailsActions";
import { FormattedMessage } from "react-intl";
import { arrayOf, number, func } from "prop-types";
import { If } from "shared/components/elements/Conditions";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import getProjectEmails from "builder_portal/selectors/projectEmails";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { getProjects } from "shared/selectors";
import { ProjectEmailShape } from "shared/shapes/projectEmail.shape";
import EmailDialog from "./EmailDialog";
import EmailIntegrationWizard from "./EmailIntegrationWizard";
import ShareSetupInstructionsDialog from "./ShareSetupInstructionsDialog";
import EmailEditDialog from "./EmailEditDialog";

/* EMAIL list component */
const EmailList = ({ emails, projectId, loadData }) => {
  const emailsPerProject = useMemo(
    () => emails.filter(x => x.project_id === projectId),
    [emails, projectId]
  );

  return (
    <>
      {emailsPerProject.map(item => {
        return (
          <Grid.Row key={item.id}>
            <Grid.Column width={6}>{item.email}</Grid.Column>
            <Grid.Column width={10} textAlign="right">
              <EmailDialog model={item} loadData={loadData} />
              <ShareSetupInstructionsDialog
                model={item}
                trigger={
                  <Button basic>
                    <FormattedMessage id="projectEmail.list.buttons.instructions" />
                  </Button>
                }
              />
              <IsVersionHistoryAccessible>
                <VersionHistoryLink
                  id={item.id}
                  type="ProjectEmail"
                  size="mid"
                  style={{ marginLeft: "10px" }}
                />
              </IsVersionHistoryAccessible>
              <EmailEditDialog
                model={item}
                trigger={
                  <Button basic icon>
                    <Icon name="cog" />
                  </Button>
                }
              />
            </Grid.Column>
          </Grid.Row>
        );
      })}
      <If condition={emailsPerProject.length === 0}>
        <div style={{ margin: "10px" }}>
          <FormattedMessage id="projectEmail.list.noEmails" />
        </div>
      </If>
    </>
  );
};

EmailList.propTypes = {
  projectId: number.isRequired,
  emails: arrayOf(ProjectEmailShape).isRequired,
  loadData: func.isRequired
};

/* Main component - EmailIntegration */
const EmailIntegration = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const emails = useSelector(getProjectEmails);

  useEffect(() => {
    new ProjectsResource(dispatch).fetchAll();
  }, []);

  const loadData = () => {
    new ProjectEmailsResource(dispatch).fetchAll();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Segment attached>
      <Header as="h4">
        <FormattedMessage id="projectEmail.list.title" />
      </Header>
      {projects.map(project => (
        <Segment attached key={project.id}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h4">Projekt {project.name}</Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <EmailIntegrationWizard
                  projectId={project.id}
                  loadData={loadData}
                />
              </Grid.Column>
            </Grid.Row>
            <EmailList
              emails={emails}
              projectId={project.id}
              loadData={loadData}
            />
          </Grid>
        </Segment>
      ))}
    </Segment>
  );
};

export default EmailIntegration;
