/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { I18nShape } from "shared/shapes/i18n.shape";
import { Icon, Modal, Button, Form, TextArea } from "semantic-ui-react";

const INIT_STATE = {
  open: false,
  loading: false,
  currentTrade: "",
  specId: "",
  textValue: "",
  initialTextValue: ""
};

class ProductSpecificationEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = INIT_STATE;
  }

  handleOpen = () => {
    const { initialTextValue, placeholder, trade } = this.props;
    const key = trade?.key || "";
    const id = trade?.spec?.id || "";

    this.setState({
      open: true,
      currentTrade: key,
      specId: id,
      textValue: initialTextValue === "" ? placeholder : initialTextValue
    });
  };

  handleSave = async () => {
    const { initialTextValue, placeholder, handleSaveFn } = this.props;
    const { textValue } = this.state;
    try {
      this.setState({ loading: true });
      await handleSaveFn({
        ...this.state,
        textValue:
          initialTextValue === ""
            ? textValue === placeholder
              ? ""
              : textValue
            : textValue
      });
    } finally {
      this.setState(INIT_STATE);
    }
  };

  renderDeleteButton = () => {
    const { specId, loading } = this.state;
    const { handleSaveFn, i18n } = this.props;
    if (!specId) return null;

    const deleteButton = (
      <Button
        basic
        loading={loading}
        content={i18n["meta.actions.reset"]}
        color="red"
        onClick={this.handleDelete}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.reset",
        color: "red",
        onClick: async () => {
          await handleSaveFn({
            ...this.state,
            textValue: ""
          });
          this.setState({ open: false });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="product.actions.editSpecificationDialog.removeConfirmation.title"
        message="product.actions.editSpecificationDialog.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  render() {
    const { textValue, loading, open, placeholder } = this.state;
    const { i18n } = this.props;

    return (
      <Modal
        open={open}
        size="large"
        trigger={<Icon name="setting" onClick={this.handleOpen} />}
      >
        <Modal.Header>
          <FormattedMessage id="product.actions.editSpecificationDialog.title" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <TextArea
              value={textValue}
              placeholder={placeholder}
              rows={10}
              onChange={(_, data) => this.setState({ textValue: data.value })}
              style={{ width: "100%", maxHeight: "70vh" }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{this.renderDeleteButton()}</div>
            <div>
              <Button
                basic
                content={i18n["meta.actions.cancel"]}
                loading={loading}
                onClick={() => this.setState(INIT_STATE)}
              />
              <Button
                loading={loading}
                content={i18n["meta.actions.save"]}
                color="green"
                onClick={this.handleSave}
              />
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

ProductSpecificationEditDialog.propTypes = {
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  trade: PropTypes.object,
  initialTextValue: PropTypes.string,
  handleSaveFn: PropTypes.func.isRequired,
  i18n: I18nShape.isRequired
};

ProductSpecificationEditDialog.defaultProps = {
  initialTextValue: "",
  placeholder: "",
  trade: null
};

export default ProductSpecificationEditDialog;
