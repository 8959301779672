import React, { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Container, Sticky, Sidebar, Segment } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getAccount, getIsLoading } from "shared/selectors";
import Modals from "builder_portal/components/dialogs/Modals";
import { node, string } from "prop-types";
import GrowlMessage from "shared/components/growlMessage/GrowlMessage";
import PrimaryTopNavigationV2 from "../navigation/PrimaryTopNavigationV2";
import ZendeskHelpWidget from "../zendesk/ZendeskHelpWidget";
import FlashActionMessage from "../flashActions/FlashActionMessage";
import ZendeskResource from "../../actions/zendeskActions";

import "./pageLayoutV2.scss";

const PageLayoutV2 = ({ children, pageName = "projects" }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const isLoading = useSelector(getIsLoading);

  const contextRef = useRef(null);
  const showPaymentReminder = useMemo(
    () => account.isEnabled("payment_reminder"),
    [account]
  );

  const paymentReminderTopMargin = showPaymentReminder
    ? { marginTop: "8.5em", padding: "0 80px" }
    : { padding: "0 80px" };

  const actions = useMemo(
    () => ({
      zendesk: new ZendeskResource(dispatch)
    }),
    []
  );

  return (
    <div data-component="pageLayoutV2">
      <PrimaryTopNavigationV2 profilePolling />

      <div data-route={pageName} style={{ position: "relative" }}>
        <Sticky
          context={contextRef.current}
          offset={70}
          styleElement={{ height: 0 }}
        >
          <GrowlMessage />
        </Sticky>
        <Sidebar.Pushable as={Segment}>
          <div
            className={`loadingIndicator ${isLoading ? "loading" : ""}`}
            data-cucumber="x-cucumber-pending-requests"
          />
          <If condition={showPaymentReminder}>
            <FeatureToggleActive featureToggleName="payment_reminder">
              <div
                className="payment-reminder payment-reminder-top"
                style={{ marginLeft: "55px", padding: "0 80px" }}
              >
                <Container>
                  <FormattedMessage id="payment_reminder.text" />
                </Container>
              </div>
            </FeatureToggleActive>
          </If>
          <Container className="page" style={paymentReminderTopMargin}>
            <div ref={contextRef}>{children}</div>
          </Container>
        </Sidebar.Pushable>

        {account && <ZendeskHelpWidget actions={actions} account={account} />}

        <FlashActionMessage />
        <Modals />
      </div>
    </div>
  );
};

PageLayoutV2.propTypes = {
  children: node,
  pageName: string
};

PageLayoutV2.defaultProps = {
  children: null,
  pageName: ""
};

export default PageLayoutV2;
