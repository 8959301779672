import React from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { find } from "lodash";
import apiRequest from "shared/network/apiRequest";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import ProfileResource from "builder_portal/actions/profileActions";
import PropTypes from "prop-types";

class DeleteProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: null,
      error: null,
      success: null,
      loading: false
    };
  }

  componentDidMount() {
    const { projectsResource } = this.props;
    projectsResource.fetchAll({ shallow: true });
  }

  handleChange = (e, { name, value, checked }) => {
    this.setState({ [name]: value || checked });
  };

  handleSubmit = () => {
    const { projectId } = this.state;
    const { submit } = this.props;
    this.setState({ loading: true });

    submit(projectId)
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  render() {
    const { profile } = this.props;
    const { loading, error, success } = this.state;

    const sourceProjectOptions = find(
      // comes from backend
      // eslint-disable-next-line camelcase
      profile?.account_memberships,
      membership => membership.id === profile?.account.id
    )?.projects?.map(sourceProject => {
      return {
        key: `project-${sourceProject.id}`,
        text: `${sourceProject.name}`,
        value: sourceProject.id
      };
    });

    return (
      <div>
        <h2>Delete a Project</h2>
        <p>This will delete the project and all his dependencies.</p>
        <Form
          loading={loading}
          success={success}
          error={!!error}
          onSubmit={this.handleSubmit}
        >
          <Message
            success
            header="Success!"
            content="Please check the results!"
          />
          <Message error header="Error" content={error} />

          <Form.Select
            name="projectId"
            options={sourceProjectOptions ?? []}
            onChange={this.handleChange}
            label="Project"
            placeholder="Project"
            required
            width="4"
          />

          <Form.Button width="4">Submit</Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  profile: state.account
});

const getSubmit = dispatch => projectId =>
  apiRequest.post("/api/v1/support_utils/delete_project", dispatch, {
    project: {
      project_id: projectId
    }
  });

const mapDispatchToProps = dispatch => ({
  profileResource: new ProfileResource(dispatch),
  projectsResource: new ProjectsResource(dispatch),
  submit: getSubmit(dispatch)
});

DeleteProject.propTypes = {
  projectsResource: PropTypes.instanceOf(ProjectsResource).isRequired,
  submit: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(ProfileResource).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProject);
