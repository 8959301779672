/* eslint-disable import/prefer-default-export */
import { get, orderBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getProjectStoreys = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "project_storeys", []);
  }
);

export const getProjectStoreysOrdered = createSelector(
  [getProjectStoreys],
  projectStoreys => {
    return orderBy(projectStoreys, "position");
  }
);

export const getProjectStoreysOptions = createSelector(
  [getProjectStoreys],
  projectStoreys => {
    const unsortedProjects = orderBy(projectStoreys, "position");

    return unsortedProjects.map(s => ({
      key: s.id,
      value: s.id,
      text: s.name
    }));
  }
);
