import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, List } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { cloneDeep, lowerFirst } from "lodash";

const elementsToAddDefaults = [
  {
    id: "1",
    type: "DashboardBannerV2",
    showLogo: true,
    label: "Adds a banner to the dashboard",
    css: {}
  },
  {
    id: "2",
    type: "MarketingUnitInfo",
    margin: "",
    label: "Adds the standard unit info to the dashboard",
    css: {}
  },
  {
    id: "3",
    type: "MobileMenu",
    label: "Adds a mobile menu to the dashboard",
    margin: "",
    only: {
      screen: [""]
    },
    css: {}
  },
  {
    id: "4",
    type: "HtmlElement",
    label: "Adds a html element to the dashboard",
    margin: "",
    params: {
      html: "<div> </div>"
    },
    css: {}
  },
  {
    id: "5",
    type: "ContentRow",
    label: "Adds a content row to the dashboard",
    margin: "",
    items: [],
    css: {}
  },
  {
    id: "6",
    type: "Feature",
    label: "Adds a Feature Card to the dashboard",
    margin: "2rem",
    params: {
      direction: "default",
      feature: {
        title: {
          text: "",
          variant: ""
        },
        message: {
          text: "",
          variant: ""
        }
        // action: {
        //   action_id: "",
        //   variant: "",
        //   color: ""
        // }
      },
      image: {
        url: ""
      }
    },
    css: {}
  },
  {
    id: "7",
    type: "ApplicationBar",
    label: "Adds an Application Bar to the dashboard",
    css: {}
  },
  {
    id: "8",
    type: "DashboardUnitInfo",
    label: "Adds a Dashboard Unit Info to the dashboard",
    css: {}
  },
  {
    id: "9",
    type: "UnitRecommendations",
    label: "Adds Unit Recommendations to the dashboard",
    css: {}
  }
];

const AddNewElement = ({ trigger, onAddElement }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleSelect = item => {
    const { label, ...itemProps } = item;
    onAddElement(cloneDeep(itemProps));
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="tiny"
      closeIcon
      trigger={trigger}
      onOpen={handleOpen}
    >
      <Modal.Header>
        <FormattedMessage id="configuratorDesign.dashboard.labels.addElement" />
      </Modal.Header>
      <Modal.Content>
        <List selection verticalAlign="middle" divided>
          {elementsToAddDefaults.map(element => {
            return (
              <List.Item key={element.id} onClick={() => handleSelect(element)}>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <List.Content>
                    <List.Header>
                      <FormattedMessage
                        id={`configuratorDesign.dashboard.${lowerFirst(
                          element.type
                        )}.type`}
                        defaultMessage={element.type}
                      />
                    </List.Header>
                    <List.Description>
                      <FormattedMessage
                        id={`configuratorDesign.dashboard.${lowerFirst(
                          element.type
                        )}.label`}
                        defaultMessage={element.type}
                      />
                    </List.Description>
                  </List.Content>
                </div>
              </List.Item>
            );
          })}
        </List>
      </Modal.Content>
    </Modal>
  );
};

AddNewElement.propTypes = {
  trigger: PropTypes.element.isRequired,
  onAddElement: PropTypes.func.isRequired
};

export default AddNewElement;
