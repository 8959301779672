import {
  shape,
  func,
  string,
  number,
  bool,
  array,
  object,
  objectOf,
  oneOfType
} from "prop-types";
import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Header, Loader } from "semantic-ui-react";
import ProjectDeadlinesSegment from "../projectDeadlines/ProjectDeadlinesSegment";

class DeadlinesTab extends React.Component {
  componentDidMount() {
    this.fetchDeadlines();
  }

  getResource = (type, id) => {
    const {
      actions: { projectDeadlines, sectionDeadlinesFn, unitDeadlinesFn }
    } = this.props;

    if (type === "project") {
      return projectDeadlines;
    }
    if (type === "section") {
      return sectionDeadlinesFn(id);
    }
    return unitDeadlinesFn(id);
  };

  handleUpdate = () => {
    const { onUpdate } = this.props;
    if (onUpdate) onUpdate();

    return this.fetchDeadlines();
  };

  fetchDeadlines() {
    const { actions } = this.props;

    return actions.projectDeadlines.fetchAll();
  }

  renderSegments(project) {
    return project.sections
      .sort((a, b) => {
        return (
          a.code.localeCompare(b.code, undefined, { numeric: true }) ||
          a.name.localeCompare(b.name, undefined, { numeric: true })
        );
      })
      .map(section => {
        return this.renderDeadlinesSegment(section, "section");
      });
  }

  renderDeadlinesSegment(item, itemType) {
    const { project, i18n } = this.props;
    return (
      <ProjectDeadlinesSegment
        key={`${itemType}::${item.id}`}
        i18n={i18n}
        project={project}
        item={item}
        itemType={itemType}
        resourceProvider={this.getResource}
        onUpdate={this.handleUpdate}
      />
    );
  }

  render() {
    const { deadlines } = this.props;

    const project = get(deadlines, "project");
    if (project) {
      return (
        <div>
          <div>
            <Header size="large">
              <FormattedMessage
                id="project.tabs.deadlines"
                defaultMessage="project.tabs.deadlines"
              />
            </Header>
          </div>
          {this.renderDeadlinesSegment(project, "project")}
          {this.renderSegments(project)}
        </div>
      );
    }
    return <Loader active />;
  }
}

DeadlinesTab.propTypes = {
  onUpdate: func.isRequired,
  i18n: objectOf(oneOfType([string, number, bool, array])).isRequired,
  actions: shape({
    projectDeadlines: object.isRequired,
    sectionDeadlinesFn: func.isRequired,
    unitDeadlinesFn: func.isRequired
  }).isRequired,
  project: shape({
    deadlines: object,
    project: object
  }),
  deadlines: objectOf(object).isRequired
};

DeadlinesTab.defaultProps = {
  project: {
    deadlines: {},
    project: {}
  }
};

export default DeadlinesTab;
