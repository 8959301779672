import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Header,
  Grid,
  Button,
  Loader,
  Dropdown,
  Icon,
  List,
  Segment
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { getProject } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import {
  UnitFeatureGroupsResource,
  UnitFeatureResource
} from "../../../actions/unitFeatureGroupsActions";
import { getUnitFeatureGroups } from "../../../selectors/unitFeatureGroups";
import UnitFeatureGroupDialog from "./UnitFeatureGroupDialog";
import UnitFeatureDialog from "./UnitFeatureDialog";
import UnitFeaturesList from "./UnitFeaturesList";

const UnitFeatureGroupsList = () => {
  const unitFeatureGroups = useSelector(getUnitFeatureGroups);
  const [isLoading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const project = useSelector(getProject);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openedGroups, setOpenedGroups] = useState([]);

  const options = useMemo(
    () => [
      {
        key: "import",
        icon: "copy",
        text: intl.formatMessage({ id: "meta.actions.import" }),
        value: "import"
      }
    ],
    []
  );

  const deselectAction = () => {
    setAction(null);
  };

  const loadData = useCallback(() => {
    if (project) {
      setLoading(true);
      Promise.all([
        new UnitFeatureGroupsResource(dispatch, project.id).fetchAll(),
        new UnitFeatureResource(dispatch, project.id).fetchAll()
      ])
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          new Growl(dispatch).error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              bodyValues: { translatedBody: err }
            }
          );
        });
    }
  }, [project]);

  useEffect(() => {
    loadData();
  }, [project]);

  const handleTypeDelete = (id, handleClose) => {
    new UnitFeatureGroupsResource(dispatch, project.id)
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.unitFeatureGroups.success.title",
          "roomBook.unitFeatureGroups.success.message"
        );
        loadData();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderDeleteButton = id => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="unit_feature_group_delete"
        disabled={isLoading}
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => handleTypeDelete(id, handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.unitFeatureGroups.types.removeConfirmation.title"
        message="roomBook.unitFeatureGroups.types.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleImportDefault = handleClose => {
    new UnitFeatureGroupsResource(dispatch, project?.id)
      .importDefaults()
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.unitFeatureGroups.types.importDefaults.success.title",
          "roomBook.unitFeatureGroups.types.importDefaults.success.message"
        );
        loadData();
        handleClose();
        deselectAction();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderImportDefaultsButton = () => {
    const buttons = [
      {
        id: "import",
        label: "meta.actions.import",
        color: "green",
        onClick: handleClose => handleImportDefault(handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true,
        onClick: () => deselectAction()
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.unitFeatureGroups.types.importDefaults.confirmationDialog.title"
        message="roomBook.unitFeatureGroups.types.importDefaults.confirmationDialog.message"
        buttons={buttons}
        open
      />
    );
  };

  const toggleGroups = groupId => {
    const index = openedGroups.indexOf(groupId);
    if (index === -1) {
      setOpenedGroups(openedGroups.concat(groupId));
    } else {
      const temp = [...openedGroups];
      temp.splice(index, 1);
      setOpenedGroups(temp);
    }
  };

  const renderUnitFeatureGroup = item => {
    return (
      <Segment key={item.id} id={`unit-feature-group-${item.id}`}>
        <Grid stackable verticalAlign="middle">
          <Grid.Column width={1}>
            <If condition={openedGroups.indexOf(item.id) > -1}>
              <Button
                icon="folder open outline"
                size="tiny"
                data-tooltip="Hide upgrade bundles"
                basic
                onClick={() => toggleGroups(item.id)}
              />
            </If>
            <If condition={openedGroups.indexOf(item.id) === -1}>
              <Button
                icon="folder outline"
                size="tiny"
                basic
                onClick={() => toggleGroups(item.id)}
                data-tooltip="Show upgrade bundles"
              />
            </If>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as="h4">{item.name}</Header>
          </Grid.Column>
          <Grid.Column width={4}>{item.title}</Grid.Column>
          <Grid.Column width={4}>
            <div>{item.description}</div>
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={1}
            className="mobilePaddedColumn"
          >
            <VersionHistoryLink
              id={item.id}
              type="UnitFeatureGroup"
              style={{
                fontSize: "1em"
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={1}
            className="mobilePaddedColumn"
          >
            <UnitFeatureGroupDialog
              model={item}
              button={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  id="unit_feature_group_edit"
                  disabled={isLoading}
                  data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                >
                  <Icon name="setting" color="grey" />
                </a>
              }
            />
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={1}
            className="mobilePaddedColumn"
          >
            {renderDeleteButton(item.id)}
          </Grid.Column>
        </Grid>
        <If
          condition={openedGroups.indexOf(item.id) > -1}
          styles={{ display: "block" }}
        >
          <UnitFeaturesList groupId={item.id} />
          <UnitFeatureDialog
            model={{
              name: "",
              title: "",
              description: "",
              unit_feature_group_id: item.id
            }}
            button={
              <Button
                id="unit_feature_new"
                fluid
                icon="plus"
                content={intl.formatMessage({
                  id: "roomBook.unitFeatures.add"
                })}
                loading={isLoading}
                disabled={isLoading}
                style={{ marginTop: "20px" }}
              />
            }
          />
        </If>
      </Segment>
    );
  };

  const renderUnitFeatureGroups = () => {
    if (isLoading) {
      return <Loader active />;
    }
    if (!unitFeatureGroups.length && !isLoading) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.unitFeatureGroups.notFound"
            defaultMessage="Es liegen keine UnitFeatureGroup zu diesem Projekt vor"
          />
        </Header>
      );
    }

    return unitFeatureGroups.map(renderUnitFeatureGroup);
  };

  const handleChangeAction = (_, { value }) => {
    setAction(value);
  };

  return (
    <>
      <Grid
        stackable
        data-component="upgrade_bundles"
        className="margin bottom medium"
      >
        <Grid.Column width="8">
          <Header size="large">
            <FormattedMessage
              id="roomBook.unitFeatureGroups.types.title"
              defaultMessage="Wohnungsfunktion Gruppe"
            />
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width="8">
          <Button.Group>
            <UnitFeatureGroupDialog
              model={{ name: "", title: "", description: "" }}
              button={
                <Button
                  id="create_unit_feature_group"
                  disabled={isLoading}
                  className="done"
                  color="green"
                >
                  <Icon name="plus" />{" "}
                  {intl.formatMessage({ id: "meta.actions.add" })}
                </Button>
              }
            />
            <Dropdown
              id="unit_feature_group_dropdown"
              className="button icon"
              floating
              selectOnBlur={false}
              options={options}
              trigger={<></>}
              onChange={handleChangeAction}
              value={action}
            />
          </Button.Group>
          <If condition={action === "import"}>
            {renderImportDefaultsButton()}
          </If>
        </Grid.Column>
      </Grid>
      <List divided size="big" data-component="unit_feature_group_items">
        {renderUnitFeatureGroups()}
      </List>
    </>
  );
};

export default UnitFeatureGroupsList;
