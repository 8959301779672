import { get, has, isNumber, merge } from "lodash";
import {
  bool,
  func,
  oneOf,
  oneOfType,
  string,
  number,
  shape,
  node
} from "prop-types";
import React, { Component } from "react";
import { Button, Input } from "semantic-ui-react";
import { formatCurrency, parseNumber } from "../../helpers/formatCurrency";
import "./floatInput.scss";

class FloatField extends Component {
  constructor(props) {
    super(props);
    const initialValue = this.fromNumber(
      get(props, "value", get(props, "defaultValue"))
    );
    this.state = {
      value: initialValue,
      isTouched: false,
      isDirty: false,
      initialValue
    };
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue } = this.props;
    const newValue = has(this.props, "value")
      ? this.fromNumber(value)
      : this.fromNumber(defaultValue);

    if (
      !this.focus &&
      (prevProps.value !== value || prevProps.defaultValue !== defaultValue)
    ) {
      this.setValue(newValue);
    }
  }

  setValue = value => this.setState({ value });

  handleBlur = (event, data) => {
    this.focus = false;
    const { value } = this.state;
    const {
      onChange,
      onBlur,
      onChangeFormik,
      onBlurFormik,
      name,
      customData
    } = this.props;
    const parsedNumber = this.parseNumber(value);
    const parsedCurrency = this.renderValue(parsedNumber);
    this.setState({
      value: parsedCurrency,
      isTouched: true
    });
    if (onChange) {
      onChange(data, { value: parsedNumber, name, customData });
    }
    if (onBlur) {
      onBlur(data, { value: parsedNumber });
    }
    if (onChangeFormik) {
      onChangeFormik(merge(event, { target: { value: parsedNumber } }), {
        ...data,
        value: parsedNumber
      });
    }
    if (onBlurFormik) {
      onBlurFormik(merge(event, { target: { value: parsedNumber } }), {
        ...data,
        value: parsedNumber
      });
    }
  };

  renderValue = value => {
    const { nullable } = this.props;
    if (nullable && !isNumber(value)) {
      return "";
    }
    return formatCurrency(value || 0, null);
  };

  handleChange = (event, data) => {
    const { initialValue } = this.state;
    this.setState({
      value: data.value,
      isDirty: initialValue !== data.value
    });
  };

  handleReset = (event, data) => {
    const { initialValue } = this.state;
    const { onChange, onBlur, onChangeFormik, onBlurFormik } = this.props;
    const parsedNumber = this.parseNumber(initialValue);
    this.setState({
      value: initialValue,
      isDirty: false
    });
    if (onChange) {
      onChange(event, { value: parsedNumber });
    }
    if (onBlur) {
      onBlur(event, { value: parsedNumber });
    }
    if (onChangeFormik) {
      onChangeFormik(event, { ...data, value: parsedNumber });
    }
    if (onBlurFormik) {
      onBlurFormik(event, { ...data, value: parsedNumber });
    }
  };

  handleFocus = () => {
    this.focus = true;
  };

  fromNumber = value => {
    return this.renderValue(value);
  };

  parseNumber = value => {
    const { nullable } = this.props;
    const n = parseNumber(value);
    if (isNaN(n)) {
      return nullable ? "" : 0.0;
    }
    return n;
  };

  render() {
    const {
      label,
      placeholder,
      disabled,
      style,
      fluid,
      id,
      error,
      tabIndex,
      readOnly,
      name,
      labelPosition,
      textAlign,
      size,
      className,
      resettable,
      hideLabel
    } = this.props;
    const { value, isDirty, isTouched } = this.state;

    const classNames = [
      "floatField",
      `align-${textAlign}`,
      isDirty ? "dirty" : "pristine",
      isTouched ? "touched" : "untouched",
      className
    ];
    let resetLabel;
    if (resettable && isDirty) {
      resetLabel = <Button icon="undo" onClick={this.handleReset} />;
    }

    return (
      <Input
        size={size}
        disabled={disabled}
        label={!hideLabel && (resetLabel || label)}
        placeholder={placeholder}
        labelPosition={labelPosition}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        name={name}
        id={id}
        fluid={fluid}
        error={error}
        readOnly={readOnly}
        style={style}
        tabIndex={tabIndex}
        className={classNames.join(" ")}
      />
    );
  }
}

FloatField.propTypes = {
  // Value can't be required, because it might be undefined.
  // eslint-disable-next-line react/require-default-props
  value: oneOfType([number, string]),
  defaultValue: oneOfType([number, string]),
  label: oneOfType([string, shape({ basic: bool, content: node })]),
  hideLabel: bool,
  placeholder: string,
  style: shape({ width: string }),
  error: bool,
  disabled: bool,
  fluid: bool,
  readOnly: bool,
  name: string,
  tabIndex: number,
  id: oneOfType([number, string]),
  labelPosition: string,
  textAlign: string,
  className: string,
  onChange: func,
  onBlur: func,
  resettable: bool,
  nullable: bool,
  size: oneOf(["mini", "small", null, "large", "big", "huge", "massive"]),
  onChangeFormik: func,
  onBlurFormik: func,
  customData: string
};

FloatField.defaultProps = {
  // Setting this default prop breaks functionality in
  // plano-app/app/javascript/builder_portal/components/lineItems/LineItemDialog.jsx
  // value: null,
  defaultValue: null,
  onChange: () => {},
  onBlur: () => {},
  id: null,
  size: null,
  error: null,
  placeholder: null,
  textAlign: "middle",
  className: "",
  resettable: false,
  nullable: false,
  style: {},
  disabled: false,
  fluid: false,
  readOnly: false,
  name: null,
  tabIndex: null,
  labelPosition: null,
  label: null,
  hideLabel: false,
  onChangeFormik: () => {},
  onBlurFormik: () => {},
  customData: ""
};

export default FloatField;
