import PropTypes, { instanceOf } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Header, Loader, Segment, Button } from "semantic-ui-react";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import ProjectEmailsResource from "builder_portal/actions/projectEmailsActions";
import Growl from "builder_portal/actions/growlActions";
import { FormattedMessage } from "react-intl";
import EmailIntegration from "./EmailIntegration";

class DnsItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleMigration = () => {
    const { projectEmails, growl } = this.props;
    this.setState(prev => ({ ...prev, loading: true }));
    projectEmails
      .migration()
      .then(res => {
        if (res.status === 200) {
          growl.success("message.success.title", "message.success.body");
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        growl.error("message.error.title", "message.error.body");
        this.setState(prev => ({ ...prev, loading: false }));
      });
  };

  render() {
    const { account } = this.props;
    const { loading } = this.state;
    return account ? (
      <div data-component="users">
        <Segment attached="top">
          <Header as="h3">
            <FormattedMessage id="email.integration" />
          </Header>
        </Segment>
        <Segment attached>{this.renderPreface()}</Segment>
        <FeatureToggle featureToggleName="email_integration_20" disabled>
          <Segment attached>{this.renderLink(account)}</Segment>
          <IsSystemAdmin>
            <Segment attached>
              <Button
                disabled={loading}
                loading={loading}
                onClick={this.handleMigration}
              >
                <FormattedMessage id="email.attributes.migration.label" />
              </Button>
            </Segment>
          </IsSystemAdmin>
        </FeatureToggle>
        <FeatureToggle featureToggleName="email_integration_20">
          <EmailIntegration />
        </FeatureToggle>
      </div>
    ) : (
      <Loader active />
    );
  }

  renderPreface() {
    return (
      <div>
        <p>
          Der sonderwunsch manager unterstützt den Empfang und Versand von
          E-Mails direkt aus dem System heraus. Grundsätzlich empfiehlt es sich,
          dass E-Mails unter einer projektspezifischen E-Mail Adresse gesendet
          werden. Dies ist allerdings nur möglich, wenn die notwendigen
          technischen Einstellungen durch Ihre IT vorgenommen werden, die dem
          sonderwunsch manager den Versand und Empfang in Ihrem Namen erlauben.
        </p>
      </div>
    );
  }

  renderLink(account) {
    return (
      <div>
        <Header as="h4">Für Ihre IT</Header>
        <p>
          Um Ihre IT um die notwendigen Einstellungen zu bitten, teilen Sie
          bitte den nachfolgenden Link mit Ihrem technischen Ansprechpartner:
        </p>
        <u>
          <a href={account.email_integration_link} target="_config">
            {account.email_integration_link}
          </a>
        </u>
      </div>
    );
  }
}

DnsItem.propTypes = {
  growl: instanceOf(Growl).isRequired,
  projectEmails: instanceOf(ProjectEmailsResource).isRequired,
  actions: PropTypes.object,
  account: PropTypes.object,
  i18n: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    projectEmails: new ProjectEmailsResource(dispatch),
    growl: new Growl(dispatch)
  };
};

export default connect(null, mapDispatchToProps)(DnsItem);
