import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { getAccount } from "shared/selectors";

import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import CancellationWizard from "./CancellationWizard";

class CancellationWizardContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dialogModel, i18n, toggleDialog, ctrl } = this.props;
    if (dialogModel) {
      return (
        <CancellationWizard
          i18n={i18n}
          toggleDialog={toggleDialog}
          lineItem={dialogModel.lineItem}
          ctrl={ctrl}
        />
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, props) => {
  const { module, id } = props;

  return {
    ...props,
    i18n: state.i18n,
    dialogModel: get(state, ["dialog", module, id, "cancellationWizard"]),
    account: getAccount(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { module, id } = props;
  return {
    toggleDialog: toggleDialogCreator(dispatch, id, module)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancellationWizardContainer);
