import { createSelector } from "reselect";
import { get, keyBy, sortBy } from "lodash";

import { getPageContent } from "shared/selectors";

export const getSearchResults = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, ["sub_line_items_search_results", "results"], []);
  }
);

export const getSearchMeta = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["sub_line_items_search_results", "meta"], {});
});

export const getSortedProductGroups = createSelector(
  [getPageContent],
  pageContent => sortBy(get(pageContent, ["product_groups"], []), "text")
);

export const getSortedProducts = createSelector([getPageContent], pageContent =>
  sortBy(get(pageContent, ["products"], []), "text")
);

export const getProductsById = createSelector([getSortedProducts], products => {
  return keyBy(products, "id");
});

export const getProductsByProductGroupId = createSelector(
  [getSortedProductGroups, getSortedProducts],
  (productGroups, products) => {
    return Object.assign(
      ...productGroups.map(group => ({
        [group.id]: group.prices.map(price =>
          products.find(product => product.id === price.product_id)
        )
      }))
    );
  }
);
