import React, { useContext } from "react";
import { If } from "shared/components/elements/Conditions";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment
} from "semantic-ui-react";
import { get } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import UnitVariablesUploadModal from "builder_portal/components/project/unitVariables/UnitVariablesUploadModal";
import UnitVariablesLoader from "builder_portal/components/project/unitVariables/UnitVariablesLoader";
import { ProjectSetupDataContext } from "./ProjectSetupDataContext";

const UnitVariableSegment = ({ disabled }) => {
  const intl = useIntl();
  const {
    projectSetup,
    project,
    account,
    handleReset,
    handleUplaodCompleted,
    renderUploadedMessage,
    prepareActions,
    isLoading
  } = useContext(ProjectSetupDataContext);

  const disabledStyle = disabled
    ? { pointerEvents: "none", opacity: "0.4" }
    : {};

  return (
    <Segment attached style={{ ...disabledStyle }}>
      <Header as="h3">
        <Icon
          name={`${
            projectSetup.step_unit_variables_completed_at
              ? "check circle"
              : "circle outline"
          }`}
          size="huge"
        />
        <FormattedMessage
          id="project.project_setup.items.unit_variables"
          default="Wohnungsmengen"
        />
      </Header>
      <Container textAlign="right">
        <If condition={!projectSetup.step_unit_variables_completed_at} block>
          <Button
            style={{ width: "43%", height: 50, paddingTop: 18 }}
            basic
            href={`${window.location.origin}/api/v1/projects/${
              project?.slug
            }/unit_variables/export.xlsx?_bearer=${get(account, [
              "data",
              "auth_token"
            ])}`}
            target="_blank"
            data-tooltip={intl.formatMessage({
              id: "project.unit_variables.section.upload_tooltip"
            })}
          >
            <FormattedMessage
              id="meta.actions.download"
              default="Herunterladen"
            />
          </Button>
          <UnitVariablesLoader projectId={project.id} isProjectLevel>
            <UnitVariablesUploadModal
              onCompleted={() => handleUplaodCompleted("step_unit_variables_")}
              button={
                <Button
                  basic
                  style={{ width: "43%", height: 50, marginLeft: "10px" }}
                >
                  <FormattedMessage
                    id="meta.actions.upload"
                    default="Hochladen"
                  />
                </Button>
              }
            />
          </UnitVariablesLoader>
        </If>
        <If condition={!!projectSetup.step_unit_variables_completed_at} block>
          <Grid style={{ marginLeft: "35px" }}>
            <Grid.Column width={13} textAlign="left">
              {renderUploadedMessage(
                prepareActions.data(projectSetup, "step_unit_variables_")
              )}
            </Grid.Column>
            <Grid.Column width={3} textAlign="right">
              <Button
                basic
                disabled={isLoading}
                onClick={() => handleReset("step_unit_variables_")}
                className="underlinedButton"
              >
                <FormattedMessage
                  id="project.project_setup.data.reset.label"
                  default="Ändern"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </If>
      </Container>
    </Segment>
  );
};

UnitVariableSegment.propTypes = {
  disabled: PropTypes.bool
};

UnitVariableSegment.defaultProps = {
  disabled: false
};

export default UnitVariableSegment;
