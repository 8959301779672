import { UPDATE_MESSAGE } from "shared/constants/appConstants";

class Growl {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  error(title, body, options) {
    this.trigger(title, body, "exclamation circle", "error", options);
  }

  success(title, body, options) {
    this.trigger(title, body, "check", "success", options);
  }

  warning(title, body, options) {
    this.trigger(title, body, "warning", "warning", options);
  }

  dismiss() {
    this.trigger("", "");
  }

  trigger(
    title,
    body,
    icon = undefined,
    type = undefined,
    options = { timeout: undefined, titleValues: {}, bodyValues: {} }
  ) {
    this.dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        icon,
        type,
        title,
        body,
        ...options
      }
    });
  }
}

export default Growl;
