import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { groupBy, tail, sortBy } from "lodash";
import "./pivotTable.scss";
import { getLineItemLifeCycle } from "shared/selectors";

class PivotTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      expansion: {}
    };
  }

  componentDidMount() {
    this.writeDataToState(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.writeDataToState(this.props);
    }
  }

  writeDataToState = props => {
    this.setState({ data: this.processData(props) });
  };

  renderNodes(accu, nodes, level) {
    const { expansion } = this.state;
    const sortedKeys = sortBy(
      Object.keys(nodes),
      nodeId => nodes[nodeId].sortBy
    );
    return sortedKeys.reduce((accu, nodeId) => {
      const node = nodes[nodeId];
      const hasChildren = node.children;
      const className = `row0${level}`;
      accu.push(
        <tr key={node.id} className={className}>
          <td>
            {hasChildren && (
              <Icon
                name={
                  expansion[node.id]
                    ? "minus square outline"
                    : "plus square outline"
                }
                onClick={() => {
                  this.toggleNode(node.id);
                }}
              />
            )}
          </td>
          <td className="row-header" title={node.hint}>
            {node.label}
          </td>
          {Object.keys(node.aggregate).map(a => (
            <td
              key={a}
              title={node.aggregate[a].hint}
              className={`cell ${node.aggregate[a].cellClass}`}
            >
              {node.aggregate[a].value}
            </td>
          ))}
        </tr>
      );
      if (expansion[node.id] && hasChildren) {
        this.renderNodes(accu, node.children, level + 1);
      }
      return accu;
    }, accu);
  }

  calcAggregates = (data, props) => {
    const { cols } = props;

    return cols.reduce((accu, c) => {
      return c.values.reduce((accu, v) => {
        accu[v.field] = {
          value: v.render(data.reduce(v.func, v.accuFn ? v.accuFn() : 0)),
          hint: v.title,
          cellClass: v.cellClass
        };
        return accu;
      }, accu);
    }, {});
  };

  processData(props) {
    const { data, rows } = props;
    let id = 0;
    const sequenceFn = () => {
      id += 1;
      return id;
    };

    return this.groupData(data, rows, props, sequenceFn);
  }

  groupData(data, keys, props, sequenceFn) {
    if (keys.length > 0) {
      const groupedData = groupBy(data, keys[0].field);
      Object.keys(groupedData).forEach(key => {
        groupedData[key] = {
          id: sequenceFn(),
          label: keys[0].labelFn(groupedData[key][0]),
          sortBy: keys[0].sortBy(groupedData[key][0]).toLowerCase(),
          hint: keys[0].hintFn ? keys[0].hintFn(groupedData[key][0]) : "",
          aggregate: this.calcAggregates(groupedData[key], props),
          children: this.groupData(
            groupedData[key],
            tail(keys),
            props,
            sequenceFn
          )
        };
      });
      return groupedData;
    }
    return null;
  }

  toggleNode(id) {
    const { expansion } = this.state;
    expansion[id] = !expansion[id];
    this.setState(expansion);
  }

  renderColumnHeaders() {
    const { cols } = this.props;
    const valueHeaders = [];
    const groupHeaders = [];

    cols.forEach(c => {
      groupHeaders.push(
        <th
          key={c.id}
          colSpan={c.values.length}
          title={c.hint}
          className={c.className}
        >
          {c.title}
        </th>
      );
      c.values.forEach(v => {
        valueHeaders.push(
          <th key={v.field} title={v.hint} className={v.titleClass}>
            {v.title}
          </th>
        );
      });
    });

    return (
      <thead>
        <tr>
          <th />
          <th />
          {groupHeaders}
        </tr>
        <tr>
          <th />
          <th />
          {valueHeaders}
        </tr>
      </thead>
    );
  }

  renderColumnFooter() {
    const { cols, data } = this.props;
    const valueHeaders = [];

    const aggregates = this.calcAggregates(data, this.props);

    cols.forEach(c => {
      c.values.forEach(v => {
        valueHeaders.push(
          <th
            key={v.field}
            title={v.hint}
            className={v.titleClass}
            style={{ cursor: "pointer" }}
          >
            {aggregates[v.field].value}
          </th>
        );
      });
    });

    return (
      <tfoot>
        <tr>
          <th />
          <th />
          {valueHeaders}
        </tr>
      </tfoot>
    );
  }

  render() {
    const { data } = this.state;

    const rows = this.renderNodes([], data, 1);

    return (
      <table data-component="pivotTable">
        {this.renderColumnHeaders()}
        <tbody>{rows}</tbody>
        {this.renderColumnFooter()}
      </table>
    );
  }
}

PivotTable.propTypes = {
  i18n: PropTypes.object,
  data: PropTypes.array,
  rows: PropTypes.array,
  cols: PropTypes.array,
  processDefinition: PropTypes.object,
  height: PropTypes.number
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  processDefinition: getLineItemLifeCycle(state)
});

export default connect(mapStateToProps)(PivotTable);
