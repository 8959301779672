import { useEffect, useState, useCallback } from "react";
import { useLocalStorage } from "beautiful-react-hooks";

const useTabsWithStorage = (project, tabs, storagKeyName) => {
  const [storedTab, setStoredTab] = useLocalStorage(storagKeyName, null);
  const [currentTab, setCurrentTab] = useState(storedTab);

  const getEnabledInitial = useCallback(() => {
    if (!project) return null;
    let initial = null;
    const { features } = project;
    Object.keys(tabs).forEach(key => {
      if (features[key]) initial = tabs[key];
    });
    return initial;
  }, [project]);

  useEffect(() => {
    // is stored key enabled?
    if (project && currentTab) {
      const feature = Object.keys(tabs).find(key => tabs[key] === currentTab);
      if (!project.features[feature]) setCurrentTab(getEnabledInitial());
    }
    if (project && !currentTab) {
      setCurrentTab(getEnabledInitial());
    }
  }, [project]);

  useEffect(() => {
    if (currentTab && currentTab !== storedTab) setStoredTab(currentTab);
  }, [currentTab]);

  return [currentTab, setCurrentTab];
};

export default useTabsWithStorage;
