import { connect } from "react-redux";
import { get } from "lodash";

import { withRouter } from "../../../shared/helpers/withRouter";

import toggleDialogCreator from "../../helpers/toggleDialogCreator";

import { LineItemResource as RoomBookLineItemResource } from "../../actions/roomBookActions";
import { LineItemResource as ActivityLineItemResource } from "../../actions/activityActions";

import LineItemTimelineDialog from "./LineItemTimelineDialog";

const mapStateToProps = (state, props) => {
  const { module, id, params } = props;
  const { projectId } = params;

  return {
    projectId,
    lineItem: get(state, ["dialog", module, id, "lineItemTimelineDialog"])
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { module, id } = props;
  const resource =
    module === "roomBook"
      ? new RoomBookLineItemResource(dispatch, id)
      : new ActivityLineItemResource(dispatch, id);

  return {
    toggleDialog: toggleDialogCreator(dispatch, id, module),
    resource
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LineItemTimelineDialog)
);
