import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button, Message } from "semantic-ui-react";
import { UPDATE_FLASH_ACTION } from "../../../shared/constants/appConstants";
import "./flashActionMessage.scss";

class FlashActionMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  renderActionButton(a) {
    const { i18n, reset } = this.props;
    const { isLoading } = this.state;

    const dialog = {
      toggleLoading: loading => {
        this.setState({ isLoading: loading });
      },
      close: () => {
        reset();
      }
    };
    return (
      <Button
        color={a.color}
        key={a.id}
        content={i18n[a.label]}
        onClick={() => a.func(dialog)}
        loading={isLoading}
      />
    );
  }

  render() {
    const { action } = this.props;
    if (action) {
      return (
        <Message floating warning className="flashMessageContainer">
          <Message.Header>{action.title}</Message.Header>
          <Message.Content className="flashMessageContent">
            <div>{action.message}</div>
            <div>{action.actions.map(a => this.renderActionButton(a))}</div>
          </Message.Content>
        </Message>
      );
    }
    return null;
  }
}

FlashActionMessage.defaultProps = {
  i18n: {},
  action: null,
  reset: null
};
FlashActionMessage.propTypes = {
  i18n: PropTypes.object,
  action: PropTypes.object,
  reset: PropTypes.func
};

const mapStateToProps = (state, props) => {
  return { ...props, i18n: state.i18n, action: state.flashAction };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...props,
    reset: () => {
      dispatch({
        type: UPDATE_FLASH_ACTION,
        payload: null
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashActionMessage);
