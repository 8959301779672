import moment from "moment";

export const formatDate = function(d, format = "L") {
  if (typeof d === "string") {
    return moment(d).format(format);
  }
  return d?.format(format) ?? "";
};

export const formatDateTime = function(d, format = "L LT") {
  if (typeof d === "string") {
    return moment(d).format(format);
  }
  return d?.format(format) ?? "";
};
