import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { FormattedMessage } from "react-intl";
import Field from "shared/components/forms/FieldComponent";
import { If } from "shared/components/elements/Conditions";
import { I18nShape } from "shared/shapes/i18n.shape";
import ProjectRoomShape from "shared/shapes/projectRoom.shape";
import { flatMap, get } from "lodash";
import { getPageContent, getI18N } from "shared/selectors";
import {
  Header,
  Form,
  Segment,
  Icon,
  Button,
  Modal,
  Grid
} from "semantic-ui-react";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ProjectRoomsResource from "../../../actions/projectRoomsActions";
import Growl from "../../../actions/growlActions";
import { getRoomTypeOptions } from "../../../selectors/masterdata";
import FileUploader from "../../dropzone/FileUploader";
import { getConfiguratorBackends } from "./ConfiguratorBackendsSegment";
import { ConfiguratorBackendShape } from "../../../../shared/shapes/configuratorBackend.shape";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "projectRoom.attributes.name.label",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "title",
      label: "projectRoom.attributes.title.label",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "description",
      label: "projectRoom.attributes.description.label",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "room_type",
      label: "projectRoom.attributes.room_type.label",
      autoComplete: "off"
    },
    {
      id: "mode",
      label: "projectRoom.attributes.mode.label",
      autoComplete: "off"
    },
    {
      id: "configurator_backend_id",
      label: "projectRoom.attributes.configurator_backend_id.label",
      autoComplete: "off"
    },
    {
      id: "render_id",
      label: "projectRoom.attributes.render_id.label",
      autoComplete: "off"
    },
    {
      id: "configurator_alignment",
      label: "projectRoom.attributes.configurator_alignment.label"
    },
    {
      id: "configurator_behaviour",
      label: "projectRoom.attributes.configurator_behaviour.label"
    },
    {
      id: "background_image",
      label: "projectRoom.attributes.background_image.label"
    },
    {
      id: "show_empty",
      label: "projectRoom.attributes.show_empty.label",
      control: "Checkbox"
    },
    {
      id: "hint_empty_state",
      label: "projectRoom.attributes.hint_empty_state.label",
      default: ""
    },
    {
      id: "hint_upgrade_bundles",
      label: "projectRoom.attributes.hint_upgrade_bundles.label",
      default: ""
    },
    {
      id: "label_upgrade_bundles",
      label: "projectRoom.attributes.label_upgrade_bundles.label",
      placeholder: "Upgrades",
      default: ""
    },
    {
      id: "hint_design_lines",
      label: "projectRoom.attributes.hint_design_lines.label",
      default: ""
    },
    {
      id: "label_design_lines",
      label: "projectRoom.attributes.label_design_lines.label",
      placeholder: "Designlinie",
      default: ""
    },
    {
      id: "position_upgrade_bundles",
      label: "projectRoom.attributes.position_upgrade_bundles.label",
      default: "top"
    },
    {
      id: "disclaimer_text",
      label: "projectRoom.attributes.disclaimer_text.label",
      placeholder: "projectRoom.attributes.disclaimer_text.placeholder",
      default: ""
    },
    {
      id: "placeholder_upgrade_bundles",
      label: "projectRoom.attributes.placeholder_upgrade_bundles.label",
      placeholder:
        "projectRoom.attributes.placeholder_upgrade_bundles.placeholder",
      default: ""
    }
  ]
});

const INITIAL_PROJECT_ROOM = {
  name: "",
  title: "",
  section_selectors: "",
  mode: "PRODUCT_SELECTION",
  label_upgrade_bundles: "",
  hint_upgrade_bundles: "",
  label_design_lines: "",
  hint_design_lines: "",
  position_upgrade_bundles: "TOP"
};

const ProjectRoomDialog = ({
  trigger,
  projectRoom,
  roomTypeOptions,
  configuratorBackends,
  i18n,
  onSave,
  onDelete
}) => {
  const [model, setModel] = useState(projectRoom || INITIAL_PROJECT_ROOM);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleSave = useCallback(() => {
    setSubmitting(true);
    return onSave(model)
      .then(({ data: { project_room } }) => {
        setModel(projectRoom ? project_room : INITIAL_PROJECT_ROOM);
        setSubmitting(false);
        setOpen(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }, [model]);
  const handleDelete = useCallback(() => {
    setSubmitting(true);
    return onDelete(model.id)
      .then(() => {
        setOpen(false);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }, [model]);

  const form = useMemo(() => {
    const f = FormFactory.create(model, i18n, {
      onChange: setModel
    });

    f.fields.configurator_backend_id.props.multiple = false;
    f.fields.configurator_backend_id.props.selection = true;
    f.fields.configurator_backend_id.props.clearable = true;
    f.fields.configurator_backend_id.props.closeOnChange = true;
    f.fields.configurator_backend_id.props.options = (
      configuratorBackends || []
    ).map(s => ({
      key: s.id,
      text: s.name,
      value: s.id
    }));

    const selectedBackends = configuratorBackends.filter(
      cb => cb.id === model.configurator_backend_id
    );

    f.fields.render_id.props.multiple = false;
    f.fields.render_id.props.selection = true;
    f.fields.render_id.props.clearable = true;
    f.fields.render_id.props.closeOnChange = true;
    f.fields.render_id.props.options = flatMap(
      selectedBackends.map(s => {
        return Object.keys(s.config).map(key => ({
          key,
          text: s.config[key]?.title,
          value: key
        }));
      })
    );

    f.fields.configurator_alignment.props.multiple = false;
    f.fields.configurator_alignment.props.selection = true;
    f.fields.configurator_alignment.props.clearable = false;
    f.fields.configurator_alignment.props.closeOnChange = true;
    f.fields.configurator_alignment.props.options = [
      {
        key: "top",
        text: "Oben",
        value: "top"
      },
      {
        key: "center",
        text: "Mittig",
        value: "center"
      },
      {
        key: "bottom",
        text: "Unten",
        value: "bottom"
      }
    ];

    f.fields.configurator_behaviour.props.multiple = false;
    f.fields.configurator_behaviour.props.selection = true;
    f.fields.configurator_behaviour.props.clearable = false;
    f.fields.configurator_behaviour.props.closeOnChange = true;
    f.fields.configurator_behaviour.props.options = [
      {
        key: "fixed_width",
        text: "Fixe Breite",
        value: "fixed_width"
      },
      {
        key: "fixed_height",
        text: "Fixe Höhe",
        value: "fixed_height"
      },
      {
        key: "dynamic",
        text: "Dynamisch",
        value: "dynamic"
      }
    ];

    f.fields.room_type.multiple = false;
    f.fields.room_type.props.selection = true;
    f.fields.room_type.props.clearable = true;
    f.fields.room_type.props.closeOnChange = true;
    f.fields.room_type.props.options = roomTypeOptions;

    f.fields.mode.multiple = false;
    f.fields.mode.props.selection = true;
    f.fields.mode.props.clearable = false;
    f.fields.mode.props.closeOnChange = true;
    f.fields.mode.props.options = [
      {
        value: "PRODUCT_SELECTION",
        key: "PRODUCT_SELECTION",
        text: "Produktauswahl"
      },
      {
        value: "FLOORPLAN_SELECTION",
        key: "FLOORPLAN_SELECTION",
        text: "Grundrissauswahl"
      }
    ];

    f.fields.position_upgrade_bundles.multiple = false;
    f.fields.position_upgrade_bundles.props.selection = true;
    f.fields.position_upgrade_bundles.props.clearable = false;
    f.fields.position_upgrade_bundles.props.closeOnChange = true;
    f.fields.position_upgrade_bundles.props.options = [
      {
        value: "TOP",
        key: "TOP",
        text: "Oben"
      },
      {
        value: "BOTTOM",
        key: "BOTTOM",
        text: "Unten"
      }
    ];

    return f;
  }, [model, roomTypeOptions, configuratorBackends]);

  const handleFile = (file, name) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      setModel(currentModel => ({ ...currentModel, [name]: binaryStr }));
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteImage = name => {
    setModel(currentModel => ({
      ...currentModel,
      [name]: null,
      destroy_background_image: true
    }));
  };

  return (
    <Modal
      open={open}
      size="small"
      trigger={trigger}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        {model.id && <FormattedMessage id="projectRoom.actions.edit" />}
        {!model.id && <FormattedMessage id="projectRoom.actions.new" />}
      </Modal.Header>
      <Modal.Content scrolling>
        <Form id="projectRoom" data-component="projectRoomForm">
          <Form.Field>
            <Field component="Input" {...form.fields.name} />
          </Form.Field>
          <Form.Field>
            <Field component="Input" {...form.fields.title} />
          </Form.Field>
          <Form.Field>
            <Field component="Input" {...form.fields.description} />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.room_type} />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.mode} />
          </Form.Field>
          <Form.Field>
            <Field
              component="Select"
              {...form.fields.configurator_backend_id}
            />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.render_id} />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.configurator_alignment} />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.configurator_behaviour} />
          </Form.Field>
          <label htmlFor="background_image" style={{ fontWeight: 700 }}>
            {form.fields.background_image.props.label}
          </label>
          <FileUploader
            id="background_image"
            fileType="image"
            name="background_image"
            previewImageUrl={model.background_image}
            handleFile={handleFile}
            handleDelete={handleDeleteImage}
          />
          <Form.Field>
            <Field component="Checkbox" {...form.fields.show_empty} />
          </Form.Field>
          <Form.Field>
            <Field component="TextArea" {...form.fields.hint_empty_state} />
          </Form.Field>
          <Form.Field>
            <Field component="Input" {...form.fields.label_upgrade_bundles} />
          </Form.Field>
          <Form.Field>
            <Field
              component="Select"
              {...form.fields.position_upgrade_bundles}
            />
          </Form.Field>
          <Form.Field>
            <Field component="TextArea" {...form.fields.hint_upgrade_bundles} />
          </Form.Field>
          <Form.Field>
            <Field
              component="TextArea"
              {...form.fields.placeholder_upgrade_bundles}
            />
          </Form.Field>
          <Form.Field>
            <Field component="Input" {...form.fields.label_design_lines} />
          </Form.Field>
          <Form.Field>
            <Field component="TextArea" {...form.fields.hint_design_lines} />
          </Form.Field>
          <Form.Field>
            <Field component="TextArea" {...form.fields.disclaimer_text} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Column width={8} verticalAlign="middle">
            {model.id && (
              <Button
                id="delete"
                color="red"
                basic
                data-form="product_group"
                content={i18n["meta.actions.remove"]}
                onClick={handleDelete}
                loading={submitting}
                className="left floated element"
              />
            )}
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <Button
              id="submit"
              data-form="product_group"
              positive
              content={i18n["meta.actions.save"]}
              onClick={form.handleSubmit(handleSave)}
              loading={submitting}
            />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

ProjectRoomDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  projectRoom: ProjectRoomShape,
  trigger: PropTypes.node.isRequired,
  configuratorBackends: PropTypes.arrayOf(ConfiguratorBackendShape).isRequired,
  roomTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string
    })
  ),
  i18n: I18nShape.isRequired
};

ProjectRoomDialog.defaultProps = {
  projectRoom: null,
  roomTypeOptions: []
};

const ProjectRoomsSegment = ({
  configuratorBackends,
  projectRooms,
  roomTypeOptions,
  refresh,
  i18n,
  onSave,
  onDelete
}) => {
  useEffect(() => {
    refresh();
  }, []);

  const handleUp = useCallback(
    m => {
      return onSave({ ...m, position: m.position - 1 });
    },
    [onSave]
  );

  const handleDown = useCallback(
    m => {
      return onSave({ ...m, position: m.position + 1 });
    },
    [onSave]
  );

  return (
    <div>
      {projectRooms.map((projectRoom, idx) => (
        <Segment key={projectRoom.id}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h4">{projectRoom.name}</Header>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <IsVersionHistoryAccessible>
                <VersionHistoryLink
                  id={projectRoom.id}
                  type="ProjectRoom"
                  size="mid"
                />
              </IsVersionHistoryAccessible>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <If condition={idx < projectRooms.length - 1}>
                <Icon
                  name="caret down"
                  link
                  onClick={() => handleDown(projectRoom)}
                />
              </If>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <If condition={idx > 0}>
                <Icon
                  name="caret up"
                  link
                  onClick={() => handleUp(projectRoom)}
                />
              </If>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <ProjectRoomDialog
                trigger={<Icon name="edit" link />}
                projectRoom={projectRoom}
                roomTypeOptions={roomTypeOptions}
                configuratorBackends={configuratorBackends}
                i18n={i18n}
                onDelete={onDelete}
                onSave={onSave}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      ))}
      <ProjectRoomDialog
        trigger={
          <Button fluid basic content={i18n["projectRoom.actions.new"]} />
        }
        configuratorBackends={configuratorBackends}
        roomTypeOptions={roomTypeOptions}
        i18n={i18n}
        onDelete={onDelete}
        onSave={onSave}
      />
    </div>
  );
};

ProjectRoomsSegment.propTypes = {
  configuratorBackends: PropTypes.arrayOf(ConfiguratorBackendShape).isRequired,
  projectRooms: PropTypes.arrayOf(ProjectRoomShape).isRequired,
  roomTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  refresh: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  i18n: I18nShape.isRequired
};

export const getProjectRooms = createSelector([getPageContent], pageContent =>
  get(pageContent, "project_rooms", [])
);

const mapStateToProps = state => {
  return {
    i18n: getI18N(state),
    projectRooms: getProjectRooms(state),
    roomTypeOptions: getRoomTypeOptions(state),
    configuratorBackends: getConfiguratorBackends(state)
  };
};

const mapDispatchToProps = (dispatch, { project }) => {
  const resource = new ProjectRoomsResource(dispatch, get(project, "id"));
  return {
    refresh: () => {
      return resource.fetchAll();
    },
    onSave: r => {
      return resource.save(r).then(data => {
        resource.fetchAll();
        return data;
      });
    },
    onDelete: id => {
      return resource.remove(id).then(data => {
        resource.fetchAll();
        return data;
      });
    },
    growl: new Growl(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectRoomsSegment);
