/* eslint-disable react/sort-comp */
import PropTypes from "prop-types";
import React from "react";
import {
  Loader,
  Header,
  Segment,
  Grid,
  Button,
  Checkbox,
  Input,
  Dropdown
} from "semantic-ui-react";
import AccountRolesResource from "builder_portal/actions/accountRolesActions";
import { connect } from "react-redux";
import { IsAccountAdmin } from "shared/components/authorization/IsSystemAdmin";
import { FormattedMessage } from "react-intl";
import { keyBy } from "lodash";
import getAccountRoles, {
  getAccountRolesOptions
} from "shared/selectors/accountRoles";
import { Account } from "shared/models/account";
import { getAccount } from "builder_portal/selectors";
import { getProjectRolesOptions } from "shared/selectors/projectRoles";
import ProjectRolesResource from "builder_portal/actions/projectRolesActions";

import UserWizard from "./UserWizard";
import UserTable from "./UserTable";
import ProjectCards from "./ProjectCards";

class UserList extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    admin: PropTypes.object,
    pageContent: PropTypes.object,
    i18n: PropTypes.object,
    growl: PropTypes.object,
    accountRoles: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    allAccountRolesData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    projectRoles: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showAllUsers: false
    };
  }

  mailTemplates = () => {
    const { pageContent } = this.props;
    return pageContent?.mail_templates;
  };

  renderWizardButton = () => {
    return (
      <Button
        role="button"
        id="user-new"
        compact
        icon="plus"
        content={this.props.i18n["user.actions.add.label"]}
      />
    );
  };

  showAllFilter = item => {
    const { account } = this.props;
    if (account.isSystemAdmin()) return true;
    // no incognito users for account admin
    return !item.incognito;
  };

  filteredMemberships = memberships => {
    if (!memberships) return [];
    const { showAllUsers, roleFilter, textFilter } = this.state;

    let filtered = showAllUsers
      ? memberships.filter(this.showAllFilter)
      : memberships.filter(
          item =>
            item.role !== "contractor" &&
            !item.incognito &&
            item.status !== "inactive"
        );

    if (roleFilter) {
      filtered = filtered.filter(item => item.role === roleFilter);
    }

    if (textFilter) {
      filtered = filtered.filter(
        item =>
          item.user.email.toLowerCase().indexOf(textFilter) > -1 ||
          item.user.first_name.toLowerCase().indexOf(textFilter) > -1 ||
          item.user.last_name.toLowerCase().indexOf(textFilter) > -1
      );
    }

    return filtered;
  };

  componentDidMount() {
    const { accountRolesResource, projectRolesResource } = this.props;
    accountRolesResource.fetchAll();
    projectRolesResource.fetchAll();
  }

  handleFilterChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      admin,
      actions,
      i18n,
      pageContent,
      growl,
      accountRoles,
      allAccountRolesData,
      projectRoles
    } = this.props;
    const { showAllUsers, textFilter, roleFilter } = this.state;
    const { account, account_memberships, project_users } = pageContent;

    const filteredMembership = this.filteredMemberships(account_memberships);

    if (account) {
      return (
        <div>
          <Segment.Group data-component="users">
            <Segment attached="top">
              <Grid stackable verticalAlign="middle">
                <Grid.Column width="10">
                  <Header as="h5">Benutzer: {account.name}</Header>
                </Grid.Column>
                <Grid.Column textAlign="right" width="6">
                  <UserWizard
                    button={this.renderWizardButton()}
                    actions={actions}
                    accountRoles={accountRoles}
                    allAccountRolesData={allAccountRolesData}
                  />
                </Grid.Column>
              </Grid>
            </Segment>

            <Grid style={{ paddingTop: "5px" }}>
              <Grid.Column width={6}>
                <IsAccountAdmin>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      data-id="show-all-users"
                      toggle
                      checked={showAllUsers}
                      onClick={() =>
                        this.setState(prev => ({
                          ...prev,
                          showAllUsers: !prev.showAllUsers
                        }))
                      }
                      style={{ padding: "10px 0 7px 10px" }}
                    />
                    <div style={{ padding: "10px 0 7px 20px" }}>
                      <FormattedMessage id="user.filter.showAll.label" />
                    </div>
                  </div>
                </IsAccountAdmin>
              </Grid.Column>
              <Grid.Column
                width={6}
                textAlign="right"
                style={{ marginBottom: "5px" }}
              >
                <Dropdown
                  name="roleFilter"
                  value={roleFilter}
                  onChange={(_, { name, value }) =>
                    this.handleFilterChange(name, value)
                  }
                  selection
                  search
                  clearable
                  options={allAccountRolesData.map(r => ({
                    key: r.id,
                    value: r.system_reference_role,
                    text: r.name
                  }))}
                  placeholder={i18n["user.filter.role.label"]}
                />
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <Input
                  name="textFilter"
                  onChange={(_, { name, value }) =>
                    this.handleFilterChange(name, value)
                  }
                  value={textFilter}
                  icon="search"
                  fluid
                  style={{ marginRight: "5px" }}
                  placeholder={i18n["user.filter.text.label"]}
                />
              </Grid.Column>
            </Grid>

            <UserTable
              i18n={i18n}
              admin={admin}
              actions={actions}
              accountMemberships={filteredMembership}
              growl={growl}
              accountRoles={accountRoles}
              allAccountRolesData={allAccountRolesData}
            />
          </Segment.Group>
          <ProjectCards
            i18n={i18n}
            actions={actions}
            accountMemberships={filteredMembership}
            accountMembershipsDictionary={keyBy(filteredMembership, "user.id")}
            account={account}
            projects={project_users}
            mailTemplates={this.mailTemplates()}
            showAllUsers={showAllUsers}
            projectRoles={projectRoles}
          />
        </div>
      );
    }
    return <Loader active />;
  }
}

UserList.propTypes = {
  accountRolesResource: PropTypes.instanceOf(AccountRolesResource).isRequired,
  projectRolesResource: PropTypes.instanceOf(ProjectRolesResource).isRequired,
  account: PropTypes.instanceOf(Account).isRequired
};

const mapStateToProps = state => ({
  accountRoles: getAccountRolesOptions(state),
  allAccountRolesData: getAccountRoles(state),
  projectRoles: getProjectRolesOptions(state),
  account: getAccount(state)
});

const mapDispatchToProps = dispatch => {
  return {
    accountRolesResource: new AccountRolesResource(dispatch),
    projectRolesResource: new ProjectRolesResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
