import { number, shape, string } from "prop-types";

export const ContractorAssignmentShape = shape({
  id: number,
  contractor_id: number,
  trade: string,
  parent_id: number,
  parent_type: string
});

export default ContractorAssignmentShape;
