/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import { Container, Dropdown, Icon, Label, Menu } from "semantic-ui-react";
import { IndexLink, Link, locationShape } from "react-router";
import { FormattedMessage } from "react-intl";
import "./primaryNavigation.scss";
import { connect } from "react-redux";
import HasAccessStatisticsCenterRight from "shared/components/authorization/HasAccessStatisticsCenterRight";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import { Account as AccountModel } from "shared/models/account";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import HasEditAccountsSettingsRight from "shared/components/authorization/HasEditAccountsSettingsRight";
import HasAccessAPIDocumentationRight from "shared/components/authorization/HasAccessAPIDocumentationRight";
import logoutAction from "../../actions/logoutAction";
import { withRouter } from "../../../shared/helpers/withRouter";
import AccountSelector from "./AccountSelector";
import {
  IsSystemAdmin,
  IsNormalUser
} from "../../../shared/components/authorization/IsSystemAdmin";
import IsBetaPreview from "../../../shared/components/elements/IsBetaPreview";

class PrimaryNavigation extends React.Component {
  shouldComponentUpdate(newProps) {
    const { loggedIn, alerts, location } = this.props;
    return (
      JSON.stringify(loggedIn) !== JSON.stringify(newProps.loggedIn) ||
      JSON.stringify(alerts) !== JSON.stringify(newProps.alerts) ||
      location.pathname !== newProps.location.pathname
    );
  }

  renderProductsLink = toggleProp => {
    const { account, accountObject, location } = this.props;

    let recentProductCatalogIds = JSON.parse(
      localStorage.getItem("recentProjectCatalogIds")
    );

    if (!recentProductCatalogIds?.[account.id]) {
      recentProductCatalogIds = {
        [account.id]: { last: null }
      };
    }
    const lastProjectId = recentProductCatalogIds[account.id].last;
    const isOnCatalogTab = location.pathname.indexOf("product_groups") > -1;
    const isNotOnProductsPage = location.pathname.indexOf("products") === -1;

    let link = `/products?page=1`;
    if (
      accountObject?.hasAccessProjectCatalogRight() &&
      lastProjectId &&
      (isOnCatalogTab || isNotOnProductsPage)
    )
      link = `/products/${lastProjectId}/product_groups`;
    return (
      <Link
        to={link}
        activeClassName="active item"
        className="item"
        {...toggleProp}
      >
        <FormattedMessage
          id="menu.main.products"
          defaultText="menu.main.products"
        />
      </Link>
    );
  };

  render() {
    const {
      logoPath,
      sidebar,
      user,
      account,
      loggedIn,
      onToggleSidebar,
      alerts,
      profilePolling
    } = this.props;

    let sidebarProps;
    let mainMenuProps;
    let toggleProp;

    if (sidebar) {
      toggleProp = {
        onClick: onToggleSidebar
      };
      sidebarProps = {
        className: "sidebar hidden",
        vertical: true
      };
    } else {
      mainMenuProps = {
        className: "hidden",
        fixed: "top"
      };
    }

    const getShortName = name => {
      const numberOfChars = 32;
      if (name.length > numberOfChars) {
        return `${name.substring(0, numberOfChars)} ...`;
      }
      return name;
    };

    if (loggedIn) {
      return (
        <div data-component="primaryNavigation">
          <Menu size="large" inverted {...sidebarProps} {...mainMenuProps}>
            <Container>
              <IndexLink to="/">
                <div
                  style={{ backgroundImage: `url(${logoPath})` }}
                  className="logo"
                />
              </IndexLink>
              {sidebar && (
                <a className="toc item" onClick={onToggleSidebar}>
                  <Icon name="sidebar" />
                </a>
              )}
              <Link
                to="/projects"
                activeClassName="active item"
                className="item"
                {...toggleProp}
              >
                <FormattedMessage
                  id="menu.main.project"
                  defaultText="menu.main.project"
                />
              </Link>
              {user.masterdata_access && (
                <Link
                  to="/contractors"
                  activeClassName="active item"
                  className="item"
                  {...toggleProp}
                >
                  <FormattedMessage
                    id="menu.main.contractor"
                    defaultText="menu.main.contractor"
                  />
                </Link>
              )}
              {this.renderProductsLink(toggleProp)}
              <Link
                to="/messages"
                activeClassName="active item"
                className="item messages compact"
                {...toggleProp}
              >
                {alerts.messages > 0 && (
                  <Label
                    id="notification-count"
                    size="mini"
                    circular
                    color="red"
                  >
                    {alerts.messages}
                  </Label>
                )}{" "}
                <Icon name="envelope" />
              </Link>
              <FeatureToggleActive featureToggleName="show_statistics_20">
                <HasAccessStatisticsCenterRight>
                  <a
                    href="/analytics_portal"
                    className="item trash compact"
                    {...toggleProp}
                  >
                    <Icon name="pie graph" />
                  </a>
                </HasAccessStatisticsCenterRight>
              </FeatureToggleActive>
              <Link
                to="/notifications"
                activeClassName="active item"
                className="item notifications compact"
                {...toggleProp}
              >
                {alerts.notifications > 0 && (
                  <Label
                    id="notification-count"
                    size="mini"
                    circular
                    color="red"
                  >
                    {alerts.notifications}
                  </Label>
                )}{" "}
                <Icon name="bell" />
              </Link>
              <IsSystemAdmin>
                <Link
                  to="/project_issues"
                  activeClassName="active item"
                  className="item compact"
                  {...toggleProp}
                >
                  <Icon name="warning circle" />
                </Link>
              </IsSystemAdmin>
              <IsSystemAdminOrHasSpecificRight right="ext_allow_edit_configurator_config">
                <Link
                  to="/trashbin"
                  activeClassName="active item"
                  className="item trash compact"
                  {...toggleProp}
                >
                  <Icon name="trash" />
                </Link>
              </IsSystemAdminOrHasSpecificRight>
              <Dropdown icon="help circle" className="item">
                <Dropdown.Menu className="sub-menu">
                  <Dropdown.Item
                    text=<FormattedMessage
                      id="menu.main.help"
                      defaultMessage="Hilfe"
                    />
                    icon="help"
                    onClick={() =>
                      window.open(
                        "https://baudigital.zendesk.com",
                        "_help_center"
                      )
                    }
                  />
                  <Dropdown.Item
                    text=<FormattedMessage
                      id="menu.main.news"
                      defaultMessage="Neuigkeiten"
                    />
                    icon="heart outline"
                    onClick={() =>
                      window.open(
                        "https://baudigital.zendesk.com/hc/de/categories/360000138293-Produktupdates",
                        "_product_news"
                      )
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Menu position="right">
                <AccountSelector profilePolling={profilePolling}>
                  <IsNormalUser>{user.name}</IsNormalUser>
                  <IsSystemAdmin>
                    {user.name}
                    <br />
                    <small>
                      {getShortName(account.name)} ({account.id})
                    </small>
                  </IsSystemAdmin>
                </AccountSelector>

                <Dropdown id="nav-settings" item icon="setting">
                  <Dropdown.Menu>
                    {this.renderSettingsLink(user, toggleProp)}
                    {this.renderAccountAdmin(user, toggleProp)}
                    {this.renderAPIDocs(user, toggleProp)}
                    {this.renderSupportUtils(user, toggleProp)}
                    <IsBetaPreview>
                      <Dropdown.Item>
                        <Link to="/file_imports" {...toggleProp}>
                          <Icon name="file outline" />
                          <FormattedMessage
                            id="menu.main.jobs"
                            defaultText="Jobs"
                          />
                        </Link>
                      </Dropdown.Item>
                    </IsBetaPreview>
                    <Dropdown.Item>
                      <Link
                        id="logout"
                        onClick={() => this.props.logoutAction(true)}
                      >
                        <Icon name="power" />
                        <FormattedMessage
                          id="menu.main.logout"
                          defaultText="menu.main.logout"
                        />
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Container>
          </Menu>
        </div>
        /* eslint-disable react/jsx-props-no-spreading */
      );
    }
    return (
      <div data-component="primaryNavigation">
        <Menu size="large" inverted {...sidebarProps} {...mainMenuProps}>
          <Container>
            <IndexLink to="/">
              <div
                style={{ backgroundImage: `url(${logoPath})` }}
                className="login logo"
                {...toggleProp}
              />
            </IndexLink>
            {sidebar && (
              <a className="toc item" onClick={onToggleSidebar}>
                <Icon name="sidebar" />
              </a>
            )}
          </Container>
        </Menu>
      </div>
    );
  }

  renderSupportUtils = (user, toggleProp) => {
    return (
      <IsSystemAdmin>
        <Dropdown.Item>
          <Link to="/supportUtils" {...toggleProp}>
            <Icon name="exclamation" />
            <FormattedMessage
              id="menu.main.supportUtils"
              defaultText="menu.main.supportUtils"
            />
          </Link>
        </Dropdown.Item>
      </IsSystemAdmin>
    );
  };

  renderAccountAdmin = (user, toggleProp) => {
    // handle role-dependent settings link
    return (
      <HasEditAccountsSettingsRight>
        <Dropdown.Item>
          <Link to="/account" {...toggleProp}>
            <Icon name="setting" />
            <FormattedMessage
              id="menu.main.accountAdmin"
              defaultText="menu.main.accountAdmin"
            />
          </Link>
        </Dropdown.Item>
      </HasEditAccountsSettingsRight>
    );
  };

  renderAPIDocs = (user, toggleProp) => {
    return (
      <FeatureToggleActive featureToggleName="api_documentation">
        <HasAccessAPIDocumentationRight>
          <Dropdown.Item>
            <Link to="/api_documentation" {...toggleProp}>
              <Icon name="retweet" />
              <FormattedMessage id="menu.main.API" defaultText="API" />
            </Link>
          </Dropdown.Item>
        </HasAccessAPIDocumentationRight>
      </FeatureToggleActive>
    );
  };

  renderSettingsLink = (user, toggleProp) => {
    // handle role-dependent settings link
    if (user) {
      return (
        <Dropdown.Item>
          <Link to="/profile" {...toggleProp}>
            <Icon name="user" />
            <FormattedMessage
              id="menu.main.myProfile"
              defaultText="menu.main.myProfile"
            />
          </Link>
        </Dropdown.Item>
      );
    }
    return undefined;
  };
}

PrimaryNavigation.defaultProps = {
  sidebar: null,
  user: null,
  account: null,
  accountObject: null,
  loggedIn: null,
  logoPath: null,
  alerts: null,
  onToggleSidebar: null,
  profilePolling: false
};

PrimaryNavigation.propTypes = {
  sidebar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object,
  accountObject: PropTypes.instanceOf(AccountModel),
  loggedIn: PropTypes.bool,
  logoPath: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  alerts: PropTypes.object,
  onToggleSidebar: PropTypes.func,
  logoutAction: PropTypes.func.isRequired,
  location: locationShape.isRequired,
  profilePolling: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  logoutAction: new logoutAction(dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(PrimaryNavigation));
