import React, { PureComponent } from "react";
import { Grid, Icon, Loader } from "semantic-ui-react";
import { func, object, array } from "prop-types";
import "./productRulesDialog.scss";
import ProductsSelector from "./ProductsSelector";

export default class RuleEditor extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    whenProductGroup: object.isRequired,
    thenProductGroup: object.isRequired,
    rule: object.isRequired,
    dropdownProps: object,
    missingWhenProducts: array
  };

  static defaultProps = {
    dropdownProps: {}
  };

  render() {
    const {
      onChange,
      whenProductGroup,
      thenProductGroup,
      rule,
      dropdownProps,
      missingWhenProducts,
      ...passThroughProps
    } = this.props;

    if (!whenProductGroup || !thenProductGroup) {
      return <Loader active />;
    }

    return (
      <Grid.Row
        verticalAlign="middle"
        data-component="ruleEditor"
        {...passThroughProps}
      >
        <Grid.Column width="7">
          <ProductsSelector
            availableProductIds={whenProductGroup.productIds}
            selectedProductIds={rule.when.product.id}
            missingIds={missingWhenProducts}
            onChange={this.handleProductsChanged}
            data-rulekind="when"
            dropdownProps={{ ...dropdownProps, direction: "right" }}
          />
        </Grid.Column>
        <Grid.Column width="2">
          <Icon name="arrow right" />
        </Grid.Column>
        <Grid.Column width="7">
          <ProductsSelector
            availableProductIds={thenProductGroup.productIds}
            selectedProductIds={rule.then.product.id}
            onChange={this.handleProductsChanged}
            data-rulekind="then"
            dropdownProps={{ ...dropdownProps, direction: "left" }}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }

  handleProductsChanged = (
    event,
    { selectedProductIds, "data-rulekind": kind }
  ) => {
    const { rule, onChange } = this.props;
    const {
      [kind]: { ...restOfKind },
      ...restOfRule
    } = rule;
    onChange(event, {
      ...this.props,
      rule: {
        [kind]: {
          ...restOfKind,
          product: {
            id: selectedProductIds
          }
        },
        ...restOfRule
      }
    });
  };
}
