import React, { createRef } from "react";
import { Helmet } from "react-helmet";
import { IndexLink } from "react-router";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Sticky,
  Image
} from "semantic-ui-react";
import Modals from "builder_portal/components/dialogs/Modals";
import { getAccount } from "shared/selectors";
import { Account as AccountModel } from "shared/models/account";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { connect } from "react-redux";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import PrimaryNavigation from "../navigation/PrimaryNavigation";
import PageFooter from "../navigation/PageFooter";
import ZendeskHelpWidget from "../zendesk/ZendeskHelpWidget";
import FlashActionMessage from "../flashActions/FlashActionMessage";
import "./pageLayout.scss";
import GrowlMessage from "../../../shared/components/growlMessage/GrowlMessage";
import ZendeskResource from "../../actions/zendeskActions";
import top from "../../../../assets/images/top.png";

const SCROLL_BUTTON_BREAKPOINT = 250;

class PageLayout extends React.Component {
  contextRef = createRef();

  static propTypes = {
    account: PropTypes.object,
    children: PropTypes.node,
    logoPath: PropTypes.string,
    pageName: PropTypes.string,
    i18n: PropTypes.object,
    isLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    alerts: PropTypes.object,
    actions: PropTypes.object,
    accountObject: PropTypes.instanceOf(AccountModel).isRequired
  };

  hideShowButton = debounce(() => {
    const y = window.pageYOffset;
    if (y >= SCROLL_BUTTON_BREAKPOINT)
      this.setState({ buttonClassName: "scroll-button button-fadeIn" });
    if (y < SCROLL_BUTTON_BREAKPOINT)
      this.setState({ buttonClassName: "scroll-button button-fadeOut" });
  }, 50);

  constructor(props) {
    super(props);
    this.state = {
      sidebarIsVisible: false,
      buttonClassName: "scroll-button-hidden"
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.hideShowButton();
  };

  handleToggleSidebar = () => {
    this.setState(prevState => ({
      sidebarIsVisible: !prevState.sidebarIsVisible
    }));
  };

  render() {
    const {
      account,
      children,
      logoPath,
      pageName,
      i18n,
      isLoading,
      alerts,
      actions,
      accountObject
    } = this.props;

    const { sidebarIsVisible, buttonClassName } = this.state;
    const pageTitle = [i18n[pageName], i18n["meta.app.name"]].join(" - ");

    const isLoggedIn = account && Boolean(account.auth_token);

    let mainNavigationMenuItems;
    let sidebarNavigationMenuItems;
    if (account && account.user) {
      mainNavigationMenuItems = (
        <PrimaryNavigation
          logoPath={logoPath}
          loggedIn
          user={account.user}
          account={account.account}
          accountObject={accountObject}
          alerts={alerts}
          profilePolling
        />
      );
      sidebarNavigationMenuItems = (
        <PrimaryNavigation
          logoPath={logoPath}
          sidebar
          loggedIn
          user={account.user}
          account={account.account}
          accountObject={accountObject}
          onToggleSidebar={() => this.handleToggleSidebar()}
          alerts={alerts}
        />
      );
    } else {
      mainNavigationMenuItems = <PrimaryNavigation logoPath={logoPath} />;
      sidebarNavigationMenuItems = (
        <PrimaryNavigation
          logoPath={logoPath}
          sidebar
          onToggleSidebar={() => this.handleToggleSidebar()}
        />
      );
    }

    const showPaymentReminder = accountObject.isEnabled("payment_reminder");
    const paymentReminder = (
      <FeatureToggleActive featureToggleName="payment_reminder">
        <div className="payment-reminder payment-reminder-top">
          <Container>
            <FormattedMessage id="payment_reminder.text" />
          </Container>
        </div>
      </FeatureToggleActive>
    );

    return (
      <div data-component="pageLayout" data-route={pageName}>
        <Sticky
          context={this.contextRef}
          offset={70}
          styleElement={{ height: 0 }}
        >
          <GrowlMessage />
        </Sticky>
        <Sidebar.Pushable as={Segment}>
          <Helmet title={pageTitle} />

          {mainNavigationMenuItems}
          {paymentReminder}

          <div
            className={`loadingIndicator ${isLoading ? "loading" : ""}`}
            data-cucumber="x-cucumber-pending-requests"
          />

          <Image
            src={top}
            className={buttonClassName}
            width="25px"
            height="40px"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            }}
          />

          <div className="pusher">
            <Segment inverted vertical className="mobile-navi-toggle">
              <Container>
                <Menu size="large" secondary inverted pointing>
                  {isLoggedIn && (
                    <a
                      className="toc item"
                      onClick={() => this.handleToggleSidebar()}
                    >
                      <Icon name="sidebar" />
                    </a>
                  )}
                  <IndexLink to="/" className="item">
                    <div
                      style={{ backgroundImage: `url(${logoPath})` }}
                      className="logo"
                    />
                  </IndexLink>
                </Menu>
              </Container>
            </Segment>

            <Sidebar
              as={Menu}
              animation="push"
              width="wide"
              visible={sidebarIsVisible}
              vertical
              inverted
            >
              {sidebarNavigationMenuItems}
            </Sidebar>
            <Container
              className="page"
              style={showPaymentReminder ? { marginTop: "6em" } : {}}
            >
              <div ref={this.contextRef}>{children}</div>
            </Container>
            {paymentReminder}
          </div>
        </Sidebar.Pushable>

        {account && <ZendeskHelpWidget actions={actions} account={account} />}

        <PageFooter logoPath={logoPath} />
        <FlashActionMessage />
        <Modals />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { i18n, logoPath, account, notifications, message, isLoading } = state;
  const {
    location: { pathname },
    router: { routes }
  } = props;

  const route = routes.filter(x => x.name && x.path === pathname);

  return {
    i18n,
    logoPath,
    pageName: route[0]?.name,
    account,
    alerts: notifications || { notifications: 0, messages: 0 },
    message,
    isLoading,
    accountObject: getAccount(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      zendesk: new ZendeskResource(dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
