import { createSelector } from "reselect";
import getProductTypes from "./productTypes";

const getProductTypeOptions = createSelector(
  [getProductTypes],
  productOptions => {
    return productOptions.map(o => ({
      key: o.id,
      value: o.id,
      text: o.name
    }));
  }
);

export default getProductTypeOptions;
