import {
  arrayOf,
  bool,
  number,
  objectOf,
  oneOf,
  shape,
  string
} from "prop-types";
import ProductShape from "../../../contractor_portal/product.shape";

export const ViewShape = shape({
  title: string,
  room: string,
  productCategories: arrayOf(string),
  relevantCategories: objectOf(string),
  defaultImages: objectOf(string) // key is string of Categories
});

export const PriceStrategyShape = oneOf([
  "length",
  "pieces",
  "area",
  "flat",
  "hour",
  "individual"
]);
export const SubLineItemShape = shape({
  selection_is_confirmed: bool,
  default_price: number,
  default_product_id: number,
  default_quantity: number,
  description: string,
  display_number: string,
  excess_price: number,
  id: number,
  optional: bool,
  position: number,
  price: number,
  price_strategy: PriceStrategyShape,
  product_group_id: number,
  product_id: number,
  total: number,
  title: string,
  instructions: string,
  quantity: number,
  priceStrategy: PriceStrategyShape,
  displayNumber: string,
  product: ProductShape,
  costTrades: arrayOf(shape({ id: string, label: string }))
});

export const LineItemShape = shape({
  activated: bool,
  cancelled: bool,
  display_mode: oneOf(["cards", "compact_list", "mandatory"]),
  display_number: string,
  external_status: oneOf(["open"]),
  id: number,
  optional: bool,
  position: number,
  replaced: bool,
  replacement: bool,
  replacement_for_id: number,
  status: string,
  sub_line_items: arrayOf(SubLineItemShape),
  title: string
});
const abstractSectionShape = {
  id: number,
  title: string,
  display_number: string,
  position: number
};

export const SubSectionShape = shape({
  ...abstractSectionShape,
  line_items: arrayOf(LineItemShape)
});
export const SectionShape = shape({
  ...abstractSectionShape,
  sections: arrayOf(SubSectionShape)
});

export const RoomBookShape = shape({
  id: number,
  source_id: number,
  sections: arrayOf(SectionShape)
});
