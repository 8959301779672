import PropTypes from "prop-types";
import React from "react";

import { Icon, Popup } from "semantic-ui-react";

class LineItemStatusLabel extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    popover: PropTypes.object,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { model } = this.props;
    const info = model.status_info.status;
    const color = info.phase_color;
    const icon = this.getStatusIcon(info.nature);
    return (
      <div data-component="lineItemStatusIndicator" title={info.hint}>
        <div style={{ color }}>
          {this.renderInner(model, info, color, icon)}
        </div>
      </div>
    );
  }

  renderInner(model, info, color, icon) {
    const { onClick } = this.props;
    return (
      <div
        className="statusLabel pulse"
        style={{ color, borderColor: color }}
        onClick={onClick}
      >
        {info.label} <Icon name={icon} style={{ color }} />
      </div>
    );
  }

  getStatusIcon(nature) {
    switch (nature) {
      case "positive":
        return "checkmark";
      case "negative":
        return "dont";
      default:
        return "circle";
    }
  }

  renderWithPopup(element, message) {
    return <Popup trigger={element} content={message} hoverable />;
  }
}

export default LineItemStatusLabel;
