import React from "react";
import FloatInput from "shared/components/forms/FloatInput";
import { Button } from "semantic-ui-react";
import { bool, func, number } from "prop-types";

function SyncedFloatInput({
  isSynced,
  syncedValue,
  value,
  enableSync,
  disableSync,
  onBlur
}) {
  return (
    <>
      {isSynced ? (
        <FloatInput
          fluid
          readOnly
          value={syncedValue}
          labelPosition="right"
          label={<Button icon="pencil" onClick={disableSync} />}
        />
      ) : (
        <FloatInput
          resettable
          value={value}
          labelPosition="right"
          label={<Button icon="delete" onClick={enableSync} />}
          fluid
          size="small"
          onBlur={onBlur}
        />
      )}
    </>
  );
}

SyncedFloatInput.defaultProps = {
  enableSync: () => {},
  disableSync: () => {},
  onBlur: () => {},
  value: undefined,
  syncedValue: undefined
};

SyncedFloatInput.propTypes = {
  isSynced: bool.isRequired,
  value: number,
  syncedValue: number,
  disableSync: func,
  enableSync: func,
  onBlur: func
};

export default SyncedFloatInput;
