import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { number } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import { getProject } from "shared/selectors";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import UnitFeatureDialog from "./UnitFeatureDialog";
import { getUnitFeatures } from "../../../selectors/unitFeatureGroups";

const UnitFeaturesList = ({ groupId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const unitFeatures = useSelector(getUnitFeatures);

  const unitFeaturesForGroup = useMemo(
    () => unitFeatures.filter(ub => ub.unit_feature_group_id === groupId),
    [unitFeatures]
  );

  const deleteUnitFeature = id => {
    return new UnitFeatureResource(dispatch, project.id)
      .remove(id)
      .then(() => {
        new UnitFeatureResource(dispatch, project.id).fetchAll();
      })
      .catch(() => {
        new Growl(dispatch).error("message.error.title", "message.error.body");
      });
  };

  const renderDeleteButton = model => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="upgrade_bundle-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          deleteUnitFeature(model.id).catch(() => {
            handleClose();
          });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.upgradeBundles.removeConfirmation.title"
        message="roomBook.upgradeBundles.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const renderUnitFeature = item => {
    return (
      <Grid.Row
        key={item.id}
        id={`upgrade_bundles-${item.id}`}
        style={{ borderBottom: "1px solid silver" }}
      >
        <Grid.Column width={4} textAlign="left">
          <Header as="h5">{item.name}</Header>
        </Grid.Column>
        <Grid.Column width={4} textAlign="left">
          <Header as="h5">{item.title}</Header>
        </Grid.Column>
        <Grid.Column width={5} textAlign="left">
          {item.description}
        </Grid.Column>
        <Grid.Column textAlign="right" width={1}>
          <VersionHistoryLink
            id={item.id}
            type="UnitFeature"
            style={{
              fontSize: "1em"
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={1}>
          <UnitFeatureDialog
            model={item}
            button={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="button"
                id="unit_feature-edit"
                data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
              >
                <Icon name="setting" color="grey" />
              </a>
            }
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width={1}>
          {renderDeleteButton(item)}
        </Grid.Column>
      </Grid.Row>
    );
  };

  const renderUnitFeatures = () => {
    if (!unitFeaturesForGroup.length) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.unitFeatures.notFoundInGroup"
            defaultMessage="Es liegen keine Einheitsfunktion zu dieser Gruppe vor"
          />
        </Header>
      );
    }

    return unitFeaturesForGroup.map(renderUnitFeature);
  };

  return (
    <Grid
      data-component="unit_feature_items"
      style={{ marginTop: "5px", paddingLeft: "15px" }}
    >
      {renderUnitFeatures()}
    </Grid>
  );
};

UnitFeaturesList.propTypes = {
  groupId: number.isRequired
};

export default UnitFeaturesList;
