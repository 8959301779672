import apiRequest from "../network/apiRequest";
import { UPDATE_ACCOUNT } from "../constants/appConstants";

export default class TokenResource {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  get() {
    return apiRequest
      .get("/api/v1/tokens", this.dispatch, null, true)
      .then(response => {
        if (response.data && response.data.token.user) {
          // write account to redux store
          this.dispatch({
            type: UPDATE_ACCOUNT,
            payload: response.data.token
          });
          return response.data;
        }
        return null;
      });
  }
}
