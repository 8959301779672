import { createSelector } from "reselect";
import { getAccount } from "../../shared/selectors";

const getProductFilterTagOptions = createSelector([getAccount], account => {
  const taxonomies = account.getTaxonomies();
  const taxonomy = taxonomies.find(
    ({ identifier }) => identifier === "product_filter_tags"
  );
  if (taxonomy) {
    return taxonomy?.tags?.map(({ composite_id, name }) => ({
      key: composite_id,
      value: composite_id,
      text: name
    }));
  }
  return [];
});

export default getProductFilterTagOptions;
