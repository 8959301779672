/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Header, Button, Modal } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Input, Form } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { DocumentationItemsResource } from "builder_portal/actions/documentationItemsActions";
import { browserHistory } from "shared/routes/browserHistory";
import Growl from "../../../actions/growlActions";
import { prefix } from "./routePrefix";

const CreateApiDocumentationDialog = ({ trigger }) => {
  const dispatch = useDispatch();
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = useCallback(values => {
    const resource = new DocumentationItemsResource(dispatch);
    return resource
      .save(values)
      .then(data => {
        const { slug } = data?.data?.documentation_item;
        browserHistory.push(`${prefix}${slug}`);

        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        title: ""
      }}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={trigger}
          >
            <Header as="h2">
              <FormattedMessage id="apiDocumentation.dialog.create" />
            </Header>
            <Modal.Content scrolling>
              <Form id="createApiDocumentation">
                <Form>
                  <Input
                    fluid
                    placeholder={useIntl().formatMessage({
                      id: "apiDocumentation.dialog.title"
                    })}
                    name="title"
                    label={useIntl().formatMessage({
                      id: "apiDocumentation.dialog.title"
                    })}
                  />
                </Form>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

CreateApiDocumentationDialog.propTypes = {
  trigger: PropTypes.node
};

CreateApiDocumentationDialog.defaultProps = {
  trigger: undefined
};

export default CreateApiDocumentationDialog;
