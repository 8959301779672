import PropTypes from "prop-types";

const ProjectQuestionsShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
  position: PropTypes.number,
  published_bp: PropTypes.bool,
  published_mp: PropTypes.bool
});

export default ProjectQuestionsShape;
