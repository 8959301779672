import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { List, Icon } from "semantic-ui-react";
import JsonEditor from "shared/components/forms/JsonEditor";
import { If } from "shared/components/elements/Conditions";
import * as Yup from "yup";
import { ConfiguratorDesignShape } from "shared/shapes/configuratorDesign.shape";
import { string, number, oneOfType, shape, func, object } from "prop-types";
import { lowerFirst } from "lodash";
import VideoCardElement from "./VideoCardElement";
import DocumentCardElement from "./DocumentCardElement";
import HtmlElement from "./HtmlElement";
import MapCardElement from "./MapCardElement";
import InfoItemCardElement from "./InfoItemCardElement";
import FeatureElement from "./FeatureElement";
import ImageGalleryCard from "./ImageGalleryCard";
import GenericElementModalWrapper from "./GenericElementModalWrapper";

const validationSchemas = {
  VideoCard: Yup.object().shape({
    params: Yup.object().shape({
      title: Yup.string()
        .min(3)
        .required(),
      url: Yup.string()
        .min(5)
        .required()
    })
  }),
  InfoItemCard: Yup.object().shape({
    fullWidth: Yup.boolean(),
    params: Yup.object().shape({
      label: Yup.string(),
      title: Yup.string()
        .min(3)
        .required(),
      subtitle: Yup.string(),
      description: Yup.string()
        .required()
        .min(3)
    })
  }),
  DocumentCard: Yup.object().shape({
    params: Yup.object().shape({
      title: Yup.string()
        .min(3)
        .required(),
      role: Yup.string().nullable(),
      url: Yup.string()
        .nullable()
        .when("role", {
          is: role => role === "" || role === undefined || role === null,
          then: Yup.string()
            .required()
            .min(5)
            .nullable()
        })
    })
  }),
  MapCard: Yup.object().shape({
    params: Yup.object().shape(
      {
        title: Yup.string()
          .min(3)
          .required(),
        address: Yup.string().when("coords", {
          is: coords =>
            (!coords.lat || coords.lat === "") &&
            (!coords.lang || coords.lang === ""),
          then: Yup.string().required(),
          otherwise: Yup.string().nullable()
        }),
        coords: Yup.object().shape({
          lat: Yup.number().when("address", {
            is: address => address === "",
            then: Yup.number()
              .required()
              .min(-90, "Latitude must be between -90 and 90")
              .max(90, "Latitude must be between -90 and 90"),
            otherwise: Yup.number()
              .min(-90, "Latitude must be between -90 and 90")
              .max(90, "Latitude must be between -90 and 90")
              .nullable()
          }),
          lang: Yup.number().when("address", {
            is: address => address === "",
            then: Yup.number()
              .required()
              .min(-180, "Longitude must be between -180 and 180")
              .max(180, "Longitude must be between -180 and 180"),
            otherwise: Yup.number()
              .min(-180, "Longitude must be between -180 and 180")
              .max(180, "Longitude must be between -180 and 180")
              .nullable()
          })
        })
      },
      ["address", "coords"]
    ),
    description: Yup.string()
      .nullable()
      .min(5)
  }),
  Feature: Yup.object().shape({
    params: Yup.object().shape({
      feature: Yup.object().shape({
        title: Yup.object().shape({
          text: Yup.string()
            .min(3)
            .required()
        })
      }),
      image: Yup.object().shape({
        url: Yup.string()
          .min(5)
          .required()
      })
    })
  }),
  ImageGallery: Yup.object().shape({
    params: Yup.object().shape({
      title: Yup.string()
        .min(3)
        .required()
    })
  })
};

const RenderListItem = ({
  item,
  moveItem,
  deleteItem,
  index,
  jsonConfigLength,
  handleChange,
  cardId,
  configuratorDesign
}) => {
  const intl = useIntl();
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const yupObject = validationSchemas[item.type];
    if (yupObject) {
      yupObject
        .validate(item)
        .then(() => setValid(true))
        .catch(() => {
          setValid(false);
        });
    } else {
      setValid(true);
    }
  }, [item.params]);

  const renderEdit = (type, button) => {
    const yupObject = validationSchemas[item.type];
    switch (type) {
      case "ImageGallery":
        return (
          <ImageGalleryCard
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
            configuratorDesign={configuratorDesign}
          />
        );
      case "VideoCard":
        return (
          <VideoCardElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
          />
        );
      case "InfoItemCard":
        return (
          <InfoItemCardElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
          />
        );
      case "HtmlElement":
        return (
          <HtmlElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
          />
        );
      case "DocumentCard":
        return (
          <DocumentCardElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
          />
        );
      case "MapCard":
        return (
          <MapCardElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
          />
        );
      case "Feature":
        return (
          <FeatureElement
            initialValue={item}
            button={button}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
            validationSchema={yupObject}
            configuratorDesign={configuratorDesign}
          />
        );
      default:
        return (
          <GenericElementModalWrapper
            button={button}
            type={item.type}
            value={item}
            onChange={value => {
              handleChange(index, value, cardId);
            }}
          >
            <JsonEditor
              value={item}
              name={item.id}
              label={intl.formatMessage({ id: "meta.actions.edit" })}
              onChange={(e, { value }) => {
                handleChange(index, value, cardId);
              }}
            />
          </GenericElementModalWrapper>
        );
    }
  };

  return (
    <List.Item key={item.id}>
      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
        <List.Content floated="right">
          <Icon
            link
            name="chevron up"
            onClick={() => moveItem(index, "up", cardId)}
            disabled={index === 0}
          />
          <Icon
            link
            name="chevron down"
            onClick={() => moveItem(index, "down", cardId)}
            disabled={index === jsonConfigLength - 1}
          />
          {renderEdit(item.type, <Icon link name="edit" />)}
          <Icon
            link
            name="trash alternate"
            onClick={() => deleteItem(index, cardId)}
          />
        </List.Content>
        <List.Content>
          <If condition={!valid}>
            <Icon name="warning" color="yellow" className="circle" />
          </If>
          <FormattedMessage
            id={`configuratorDesign.dashboard.${lowerFirst(item.type)}.type`}
            defaultMessage={item.type}
          />
        </List.Content>
      </div>
    </List.Item>
  );
};

RenderListItem.propTypes = {
  item: shape({
    id: string.isRequired,
    type: string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    params: object
  }).isRequired,
  moveItem: func.isRequired,
  deleteItem: func.isRequired,
  index: oneOfType([string, number]).isRequired,
  jsonConfigLength: oneOfType([number, string]).isRequired,
  handleChange: func.isRequired,
  cardId: string,
  configuratorDesign: ConfiguratorDesignShape.isRequired
};

RenderListItem.defaultProps = {
  cardId: undefined
};

export default RenderListItem;
