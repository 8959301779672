/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Message
} from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Input, Form, Checkbox } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import AccountRolesResource from "builder_portal/actions/accountRolesActions";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Growl from "../../../actions/growlActions";
import { If } from "../../../../shared/components/elements/Conditions";

const DeleteButton = ({ onDelete, loading }) => {
  const button = (
    <Button
      id="remove"
      color="red"
      basic
      content={<FormattedMessage id="meta.actions.remove" />}
      loading={loading}
    />
  );

  const buttons = [
    {
      id: "delete",
      label: "meta.actions.remove",
      color: "red",
      onClick: onDelete,
      loading
    },
    {
      id: "cancel",
      label: "meta.actions.cancel",
      basic: true,
      loading
    }
  ];

  return (
    <ConfirmationDialog
      title="accountRoles.deleteConfirmationDialog.title"
      message="accountRoles.deleteConfirmationDialog.message"
      buttons={buttons}
      button={button}
    />
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const AccountRoleDialog = ({ model, trigger }) => {
  const dispatch = useDispatch();
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleting(false);
    clearForm();
  };

  const onSubmit = useCallback(
    values => {
      const resource = new AccountRolesResource(dispatch);
      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }

      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  const handleDelete = useCallback(() => {
    const resource = new AccountRolesResource(dispatch);

    setDeleting(true);

    return resource
      .remove(model.id)
      .then(() => {
        new Growl(dispatch).success(
          "accountRoles.deleteConfirmationDialog.success.title",
          "accountRoles.deleteConfirmationDialog.success.message"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "accountRoles.deleteConfirmationDialog.error.title",
          "accountRoles.deleteConfirmationDialog.error.message"
        );
        setDeleting(false);
      });
  }, [model]);

  return (
    <Formik
      initialValues={{
        ...model
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={trigger}
          >
            <Header as="h2">
              {model?.id ? (
                <FormattedMessage
                  id="accountRoles.dialog.title.edit"
                  default="accountRoles.dialog.title.edit"
                />
              ) : (
                <FormattedMessage
                  id="accountRoles.dialog.title.add"
                  default="accountRoles.dialog.title.add"
                />
              )}
            </Header>
            <Modal.Content scrolling>
              <Form id="accountRolesBackendForm">
                <Container>
                  <If condition={!!model?.system_reference_role}>
                    <Message info>
                      <FormattedMessage id="accountRoles.dialog.systemRoleHint" />
                    </Message>
                  </If>
                  <Form>
                    <Container>
                      <Grid>
                        <Grid.Column width={16}>
                          <Input
                            fluid
                            placeholder={useIntl().formatMessage({
                              id: "accountRoles.dialog.name"
                            })}
                            name="name"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.name"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Input
                            fluid
                            placeholder={useIntl().formatMessage({
                              id: "accountRoles.dialog.roleDescription"
                            })}
                            name="description"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.description"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ marginBottom: "-10px" }}
                        >
                          <Header as="h4">
                            <FormattedMessage id="accountRoles.dialog.permissions" />
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_edit_account_settings"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.editAccountSettings"
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_edit_contractors"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.editSubcontractors"
                            })}
                          />
                        </Grid.Column>

                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_edit_products"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.editProductsData"
                            })}
                          />
                        </Grid.Column>

                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_project_catalogs"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id: "accountRoles.dialog.editAccessProjectCatalog"
                            })}
                          />
                        </Grid.Column>

                        <FeatureToggle featureToggleName="show_statistics_20">
                          <Grid.Column width={16}>
                            <Checkbox
                              name="allow_access_statistics_center"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id:
                                  "accountRoles.dialog.editAccessStatisticsCenter"
                              })}
                            />
                          </Grid.Column>
                        </FeatureToggle>

                        <Grid.Column width={16}>
                          <Checkbox
                            name="allow_access_unassigned_emails"
                            readOnly={!!model?.system_reference_role}
                            label={useIntl().formatMessage({
                              id:
                                "accountRoles.dialog.editAccessUnassignedEmails"
                            })}
                          />
                        </Grid.Column>

                        <IsSystemAdmin>
                          <Grid.Column
                            width={16}
                            style={{ margin: "15px 0 10px 0" }}
                          >
                            <Header as="h4">
                              <FormattedMessage id="accountRoles.dialog.specialPermissions" />
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_access_trashbin"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.accessTrashbin"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_edit_configurator_config"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.editConfigurator"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_delete_activities"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.deleteActivities"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_delete_room_books"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.deleteRoomBooks"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_access_version_history"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.accessVersionHistory"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <Checkbox
                              name="ext_allow_api_documentation"
                              readOnly={!!model?.system_reference_role}
                              label={useIntl().formatMessage({
                                id: "accountRoles.dialog.accessAPIDocumentation"
                              })}
                              style={{ padding: "1rem 0" }}
                            />
                          </Grid.Column>
                        </IsSystemAdmin>
                      </Grid>
                    </Container>
                  </Form>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div className="flex justify-content-space-between">
                <div>
                  <If condition={model?.id && !model?.system_reference_role}>
                    <DeleteButton
                      model={model}
                      onDelete={handleDelete}
                      loading={isSubmitting || deleting}
                    />
                  </If>
                </div>
                <div>
                  <Button basic disabled={isSubmitting} onClick={handleClose}>
                    <FormattedMessage id="meta.actions.cancel" />
                  </Button>
                  <If condition={!model?.system_reference_role}>
                    <Button
                      primary
                      disabled={isSubmitting || deleting}
                      loading={isSubmitting || deleting}
                      onClick={handleSubmit}
                    >
                      <FormattedMessage id="meta.actions.save" />
                    </Button>
                  </If>
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

AccountRoleDialog.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    system_reference_role: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    allow_edit_account_settings: PropTypes.bool,
    allow_edit_contractors: PropTypes.bool,
    allow_edit_products: PropTypes.bool
  }),
  trigger: PropTypes.node
};

AccountRoleDialog.defaultProps = {
  trigger: undefined,
  model: undefined
};

export default AccountRoleDialog;
