import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import contactsMap from "../../helpers/contactsMap";
import MemoForm from "./MemoForm";

class MemoDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    memo: PropTypes.object,
    button: PropTypes.node,
    handleUpdate: PropTypes.func,
    memosResource: PropTypes.object,
    contacts: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleToggleDialog(open) {
    this.setState({ ...this.state, open });
  }

  render() {
    const { button, handleUpdate, memo, i18n, memosResource } = this.props;

    // make up model
    const model = {
      ...memo,
      contacts: memo.contacts.map(item => `${item.type}-${item.id}`)
    };

    return (
      <Modal
        data-component="memoDialog"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id={memo.id ? "meta.actions.edit" : "meta.actions.new"}
            defaultMessage={memo.id ? "meta.actions.edit" : "meta.actions.new"}
          />
        </Modal.Header>
        <Modal.Content>
          <MemoForm
            i18n={i18n}
            model={model}
            type={memo.role}
            contacts={contactsMap(this.props.contacts)}
            handleUpdate={handleUpdate}
            handleClose={() => this.handleToggleDialog(false)}
            memosResource={memosResource}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default MemoDialog;
