import PropTypes from "prop-types";

const RoomBookUpgradeBundlesShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  base_total: PropTypes.number,
  surcharge: PropTypes.number,
  base_total_without_surcharge: PropTypes.number,
  activated: PropTypes.bool
});

export default RoomBookUpgradeBundlesShape;
