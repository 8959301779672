import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Header, Modal, Grid } from "semantic-ui-react";
import FloatInput from "../../../shared/components/forms/FloatInput";

import "./lineItemCreationWizard.scss";

class QuantitySelector extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      i18n,
      productsRepository,
      dialogModel,
      defaultValue,
      header,
      handleQuantity
    } = this.props;
    const { productGroupId } = dialogModel;
    const productGroup = Object.entries(productsRepository)
      .filter(array => array[0] === "productGroups")[0][1]
      .filter(array => array.id === productGroupId)[0];
    const priceStrategy = productGroup.price_strategy;

    return (
      <Modal.Content className="quantitySelector">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column textAlign="right" verticalAlign="middle">
              <Header>
                <FormattedMessage
                  id={`roomBook.lineItems.wizard.labels.${header}`}
                />
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="left" verticalAlign="middle">
              <FloatInput
                id="quantity"
                name="quantity"
                autoComplete="off"
                label={i18n[`roomBook.priceStrategyLong.${priceStrategy}`]}
                labelPosition="right"
                defaultValue={defaultValue}
                onChange={(event, input) => handleQuantity(input)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    );
  }
}

const mapStateToProps = state => {
  const { i18n } = state;

  return {
    i18n
  };
};

export default connect(mapStateToProps)(QuantitySelector);
