import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export default class PasswordResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "password", "/api/v1/passwords");
  }

  verifyToken(token) {
    const payload = {};
    return apiRequest.get(
      `${this.url}/${token}/token_verification`,
      this.dispatch,
      payload
    );
  }
}
