import React, { useState } from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { instanceOf, string, objectOf } from "prop-types";
import { get } from "lodash";
import Growl from "builder_portal/actions/growlActions";
import { AccountResource } from "../../../actions/accountActions";
import FileUploader from "../../dropzone/FileUploader";
import localStorage from "../../../../shared/helpers/localStorage";
import { UPDATE_PAGE_CONTENT } from "../../../../shared/constants/appConstants";
import { formatMessage } from "../../helpers/i18n";
import { I18nShape } from "../../../../shared/shapes/i18n.shape";
import Resource from "../../../../shared/actions/resourceActions";

const FileUploadSegment = ({
  name,
  fileUrls,
  fileType,
  accountsResource,
  i18n,
  growl
}) => {
  const [loading, setLoading] = useState(false);

  const updateAgreementText = accountResponse => {
    let agreementText = null;

    if (
      accountResponse.data_protection_policy &&
      !accountResponse.terms_conditions
    ) {
      agreementText = formatMessage(
        i18n["buyerPortal.messages.accept_data_policy"],
        {
          policy_url: accountResponse.data_protection_policy.url
        }
      );
    }

    if (
      accountResponse.terms_and_conditions &&
      !accountResponse.data_protection_policy
    ) {
      agreementText = formatMessage(i18n["buyerPortal.messages.accept_terms"], {
        terms_url: accountResponse.terms_and_conditions.url
      });
    }

    if (
      accountResponse.terms_and_conditions &&
      accountResponse.data_protection_policy
    ) {
      agreementText = formatMessage(
        i18n["buyerPortal.messages.accept_terms_and_data_policy"],
        {
          terms_url: get(accountResponse.terms_and_conditions, "url"),
          policy_url: get(accountResponse.data_protection_policy, "url")
        }
      );
    }

    return accountsResource.save({
      config_params: {
        buyer_portal_login_accept_terms_label: agreementText
      }
    });
  };

  const handleDelete = () => {
    const payload = {
      [name]: null
    };
    setLoading(true);
    return accountsResource
      .update(payload)
      .then(async response => {
        await updateAgreementText(response.data.account);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleUpload = formData => {
    setLoading(true);

    fetch("/api/v1/account", {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => {
        if (response.status !== 200) {
          growl.error("message.errorSave.title", "message.errorSave.body");
        }
        return response.json();
      })
      .then(async response => {
        await updateAgreementText(response.account);
        setLoading(false);
      });
  };

  const handleFile = file => {
    const formData = new FormData();
    formData.append(`account[${name}]`, file);
    handleUpload(formData);
  };

  let previewImageUrl;
  let originalFileUrl;

  switch (fileType) {
    case "image":
      previewImageUrl = get(fileUrls, "half_banner");
      originalFileUrl = "original";
      break;
    case "pdf":
      previewImageUrl = get(fileUrls, "thumb");
      originalFileUrl = "url";
      break;
    default:
      previewImageUrl = "";
      originalFileUrl = "";
  }

  return (
    <Segment basic id={name}>
      <label htmlFor={`${name}-upload`}>
        <FormattedMessage
          id={`account.attributes.buyer_portal.uploads.${name}`}
          defaultMessage={name}
        />
      </label>
      <FileUploader
        id={`${name}-upload`}
        fileType={fileType}
        loading={loading}
        name={name}
        previewImageUrl={previewImageUrl}
        originalFileUrl={get(
          fileUrls,
          originalFileUrl,
          "Es konnte keine URL gefunden werden"
        )}
        handleFile={handleFile}
        handleDelete={handleDelete}
      />
    </Segment>
  );
};

FileUploadSegment.propTypes = {
  name: string.isRequired,
  fileUrls: objectOf(string),
  fileType: string.isRequired,
  accountsResource: instanceOf(Resource).isRequired,
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired
};

FileUploadSegment.defaultProps = {
  fileUrls: {}
};

const mapStateToProps = (state, props) => ({
  account: get(state.pageContent, "account"),
  fileUrls: get(state.pageContent.account, props.name),
  i18n: state.i18n
});

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch),
  accountsResource: new AccountResource(dispatch),
  propagateUpdate: data => {
    dispatch({
      type: UPDATE_PAGE_CONTENT,
      payload: data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadSegment);
