import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { get, compact } from "lodash";
import {
  Grid,
  List,
  Header,
  Icon,
  Label,
  Button,
  Segment,
  Responsive
} from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import createCachedSelector from "re-reselect";
import { createStructuredSelector } from "reselect";
import {
  dispatchExpandId,
  dispatchToggleCollapse,
  getCollapsedById
} from "shared/reducers/pageContent/roomBookFilter";
import LineItemModel from "../../models/roomBook/LineItemModel";
import LineItemStatus from "./LineItemStatus";
import SectionOrderControl from "./SectionOrderControl";
import CopyToDerivedDialog from "./CopyToDerivedDialog";
import RoomBookSubLineItem from "./RoomBookSubLineItem";
import RoomBookCancellationItem from "./RoomBookCancellationItem";
import RoomBookBaseTotalItem from "./RoomBookBaseTotalItem";
import ProductGroupSelector from "./ProductGroupSelector";
import LineItemStatusLabel from "./LineItemStatusLabel";
import ConfirmableButton from "../buttons/ConfirmableButton";
import UnitFeatureIndicators from "./UnitFeatureIndicators";
import LineItemActivatorButton from "./LineItemActivatorButton";
import "./roomBookLineItem.scss";
import UpgradeBundleActivationGuard from "./upgradeBundles/UpgradeBundleActivationGuard";

class RoomBookLineItem extends React.PureComponent {
  static propTypes = {
    i18n: PropTypes.object,
    projectId: PropTypes.string,
    featureToggles: PropTypes.object,
    context: PropTypes.object,
    roomBook: PropTypes.object,
    isTemplate: PropTypes.bool,
    products: PropTypes.array,
    trades: PropTypes.array,
    ctrl: PropTypes.object,
    model: PropTypes.object,
    lineItem: PropTypes.object,
    carts: PropTypes.array,
    toggleDialog: PropTypes.func,
    parentDisplayNumber: PropTypes.string,
    toggleCollapse: PropTypes.func.isRequired,
    expandCollapse: PropTypes.func.isRequired,
    collapsed: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showProductGroupSelector: false
    };
  }

  toggleEditorDialog(values) {
    const { model, context, toggleDialog } = this.props;

    toggleDialog("lineItemDialog", {
      context,
      model: values || model
    });
  }

  render() {
    const { showProductGroupSelector } = this.state;
    const {
      i18n,
      model,
      lineItem,
      roomBook,
      ctrl,
      productsRepository,
      toggleDialog,
      trades,
      parentDisplayNumber,
      collapsed
    } = this.props;
    const productGroups = productsRepository.getProductGroups();
    const displayNumber = `${parentDisplayNumber}.${model.position}`;
    const state = lineItem.isActivated() ? "activated" : "deactivated";
    return (
      <List.Item
        data-model="line_item"
        data-component="roomBookLineItem"
        data-state={state}
        data-id={lineItem.getId()}
        id={`item-${lineItem.getId()}`}
      >
        <List.Content>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="10" verticalAlign="middle">
              <div className="titleButton">
                {this.renderLineItemIcon()}
                <Icon
                  color="grey"
                  name="setting"
                  size="large"
                  link
                  onClick={() => {
                    this.toggleEditorDialog();
                  }}
                />
                <Header
                  className="left floated element"
                  onClick={this.handleLineItemCollapse()}
                >
                  <span data-attr="display_number">{displayNumber}</span>
                  <span data-attr="title">{model.title}</span>
                </Header>
                {this.renderOptionalLabel()}
                <UnitFeatureIndicators
                  item={lineItem.currentModel}
                  isTemplate={lineItem.isTemplate}
                />
              </div>
            </Grid.Column>
            <Grid.Column width="6" textAlign="right" verticalAlign="middle">
              {this.renderTopControls()}
            </Grid.Column>
          </Grid>
          {!collapsed && (
            <List divided>
              {this.renderSubLineItems(displayNumber, model)}
              {showProductGroupSelector && (
                <List.Item data-component="roomBookLineItem">
                  <ProductGroupSelector
                    i18n={i18n}
                    toggleDialog={toggleDialog}
                    label={i18n["roomBook.actions.addLineItem"]}
                    productGroups={productGroups}
                    trades={trades}
                    siblings={model.sub_line_items}
                    roomBook={roomBook}
                    parentType="line_item"
                    parentId={model.id}
                    ctrl={ctrl}
                    expanded={showProductGroupSelector}
                    onClose={() => {
                      this.setState({ showProductGroupSelector: false });
                    }}
                  />
                </List.Item>
              )}
              {this.renderBottomControls()}
            </List>
          )}
        </List.Content>
      </List.Item>
    );
  }

  renderLineItemIcon() {
    const { lineItem, collapsed, context } = this.props;
    const stateTitle = lineItem.isProperLineItem() ? "Position" : "Storno";
    const title = context.account.isSystemAdmin()
      ? lineItem.currentModel.id
      : stateTitle;
    const color = lineItem.isProperLineItem() ? "grey" : "purple";
    const open = collapsed ? "" : " open";

    return (
      <Icon
        name={`folder outline${open}`}
        size="large"
        link
        color={color}
        title={title}
        onClick={this.handleLineItemCollapse()}
      />
    );
  }

  handleLineItemCollapse() {
    return () => {
      const { lineItem, toggleCollapse, model, expandCollapse } = this.props;
      toggleCollapse(`line-item-${model.id}`);
      // also expand SubLineItems for quality of Life (issue #1243)
      lineItem
        .getSubLineItemIds()
        .forEach(id => expandCollapse(`sub-line-item-${id}`));
    };
  }

  renderTopControls() {
    const { isTemplate } = this.props;
    const controls = [];

    if (isTemplate) {
      controls.push(this.renderCopyToDerivedDialog());
    } else {
      controls.push(this.renderPriceLabel());
    }

    return compact(controls.concat(this.renderSectionOrderControl()));
  }

  renderSectionOrderControl() {
    const { roomBook, section, model, ctrl, i18n } = this.props;
    return (
      <SectionOrderControl
        key="sectionOrderControl"
        i18n={i18n}
        type="lineItem"
        ctrl={ctrl}
        element={model}
        itemsInGroup={section.line_items.length}
        roomBook={roomBook}
      />
    );
  }

  renderCopyToDerivedDialog() {
    const { roomBook, model } = this.props;
    return (
      <CopyToDerivedDialog
        key="copyToDerivedDialog"
        roomBookId={roomBook.id}
        item={model}
        itemType="line_item"
        trigger={
          <Button size="mini" data-component="copy-to-derived-button">
            Übernehmen
          </Button>
        }
      />
    );
  }

  renderOptionalLabel() {
    const { roomBook, lineItem } = this.props;
    const translationId = lineItem.hasUpgradeBundle()
      ? "roomBook.lineItems.attributes.upgrade_bundle.title"
      : "roomBook.lineItems.attributes.optional.title";
    const name = get(lineItem.getUpgradeBundles(), [0, "name"]);

    if (lineItem.isOptional()) {
      if (lineItem.isTemplate) {
        return (
          <Label color="teal" basic size="small">
            <FormattedMessage id={translationId} values={{ name }} />
          </Label>
        );
      }
      return (
        <LineItemActivatorButton lineItem={lineItem} roomBookId={roomBook.id} />
      );
    }
  }

  renderPriceLabel() {
    const { lineItem, model } = this.props;
    if (lineItem.isCancellation()) {
      return (
        <Label key="price-label" basic style={{ margin: "0px" }} color="purple">
          <FormatCurrency amount={model.total} /> statt{" "}
          <FormatCurrency amount={model.cancellation_total} />
        </Label>
      );
    }
    if (lineItem.isCancelled()) {
      return (
        <Label
          key="price-label"
          basic
          style={{ margin: "0px" }}
          className="strikethrough"
        >
          <FormatCurrency amount={model.total} />
        </Label>
      );
    }

    return (
      <Label
        key="price-label"
        basic
        className={[
          "price-label",
          lineItem.isInactive() ? "optional-inactive" : "optional-active"
        ].join(" ")}
      >
        <FormatCurrency amount={model.total} />
      </Label>
    );
  }

  renderBottomControls() {
    const { showProductGroupSelector } = this.state;

    if (!showProductGroupSelector) {
      return (
        <List.Item>
          <Grid className="bottomControls">
            <Grid.Row>
              <Grid.Column
                tablet={4}
                computer={3}
                mobile={7}
                className="addSubItem"
              >
                {this.renderProductGroupToggle()}
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={8}
                mobile={1}
                className="cartControls"
              >
                {this.renderCartControls(769)}
                &nbsp;
              </Grid.Column>
              <Grid.Column
                tablet={6}
                computer={5}
                mobile={8}
                className="statusLabel"
              >
                {this.renderStatusLabel()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Responsive maxWidth={768}>
            <Grid className="bottomControls">
              <Grid.Row>
                <Grid.Column width={16} className="cartControls">
                  {this.renderCartControls()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Responsive>
        </List.Item>
      );
    }
  }

  renderStatusLabel() {
    const { model, toggleDialog, lineItem } = this.props;

    if (lineItem.requiresStatusLabel()) {
      return (
        <LineItemStatusLabel
          model={model}
          onClick={() => toggleDialog("lineItemTimelineDialog", model)}
        />
      );
    }
  }

  renderInnerCartControls(suggestionMinWidth) {
    const {
      i18n,
      lineItem,
      carts,
      roomBook,
      context,
      projectId,
      toggleDialog
    } = this.props;

    if (lineItem.hasPendingBuyerSelection() && !lineItem.hasCarts()) {
      return (
        <Segment>
          <Grid>
            <Grid.Column width={1}>
              <Icon name="warning circle" color="yellow" size="big" />
            </Grid.Column>
            <Grid.Column width={14} verticalAlign="middle">
              <Header as="h4">
                <FormattedMessage
                  id="roomBook.lineItems.buyerSelection.pending"
                  defaultValue="roomBook.lineItems.buyerSelection.pending"
                />
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
      );
    }
    if (lineItem.requiresCartControls()) {
      return (
        <LineItemStatus
          lineItem={lineItem}
          i18n={i18n}
          account={context.account}
          projectId={projectId}
          carts={carts}
          roomBook={roomBook}
          suggestionMinWidth={suggestionMinWidth}
          toggleDialog={toggleDialog}
        />
      );
    }
    return null;
  }

  renderCartControls(suggestionMinWidth) {
    const { roomBook, lineItem } = this.props;

    return (
      <UpgradeBundleActivationGuard roomBook={roomBook} lineItem={lineItem}>
        {this.renderInnerCartControls(suggestionMinWidth)}
      </UpgradeBundleActivationGuard>
    );
  }

  renderSubLineItems(displayNumber, model) {
    const {
      i18n,
      featureToggles,
      isTemplate,
      productsRepository,
      carts,
      roomBook,
      lineItem,
      ctrl,
      toggleDialog,
      trades,
      context
    } = this.props;

    const cancellationItems = [];

    if (lineItem.isReplacement() || lineItem.isCancellation()) {
      cancellationItems.push(
        <RoomBookBaseTotalItem key="baseTotal" lineItem={lineItem} />
      );
    }

    model.cancellation_items.forEach(item => {
      cancellationItems.push(
        <RoomBookCancellationItem
          parentDisplayNumber={displayNumber}
          context={context}
          parent={model}
          key={`line-item-${item.id}`}
          roomBook={roomBook}
          isTemplate={isTemplate}
          productsRepository={productsRepository}
          model={{ ...item, status_info: model.status_info }}
        />
      );
    });

    const subLineItems = model.sub_line_items.map(item => {
      if (lineItem.isCancelled()) {
        return (
          <RoomBookCancellationItem
            parentDisplayNumber={displayNumber}
            context={context}
            parent={model}
            key={`line-item-${item.id}`}
            roomBook={roomBook}
            isTemplate={isTemplate}
            productsRepository={productsRepository}
            model={{ ...item, status_info: model.status_info }}
          />
        );
      }

      return (
        <RoomBookSubLineItem
          parentDisplayNumber={displayNumber}
          featureToggles={featureToggles}
          context={context}
          parent={model}
          key={`sub-line-item-${item.id}`}
          id={`sub-line-item-${item.id}`}
          i18n={i18n}
          carts={carts}
          trades={trades}
          roomBook={roomBook}
          isTemplate={isTemplate}
          productsRepository={productsRepository}
          model={{ ...item, status_info: model.status_info }}
          ctrl={ctrl}
          toggleDialog={toggleDialog}
        />
      );
    });

    return cancellationItems.concat(subLineItems);
  }

  renderProductGroupToggle() {
    const { i18n, lineItem } = this.props;

    if (lineItem.isCancelled()) {
      return null;
    }

    const buttonProps = {
      content: "Unter\u00ADposition",
      basic: true,
      size: "small",
      icon: "plus",
      "data-action": "add-sub-item"
    };
    if (lineItem.isChangePolicy("confirm")) {
      return (
        <ConfirmableButton
          confirmationTitle="roomBook.lineItems.messages.header"
          confirmationContent={
            <Grid>
              <Grid.Column width={1}>
                <Icon name="attention" color="red" />
              </Grid.Column>
              <Grid.Column width={15}>
                <p>{lineItem.getChangePolicy().confirmation_message}</p>
                <p>{i18n["roomBook.lineItems.messages.confirmChanges"]}</p>
              </Grid.Column>
            </Grid>
          }
          confirmationButtonContent="meta.actions.proceed"
          onConfirm={cb => {
            this.setState({ showProductGroupSelector: true }, cb);
          }}
          {...buttonProps}
        />
      );
    }
    return (
      <Button
        onClick={() => {
          this.setState({ showProductGroupSelector: true });
        }}
        {...buttonProps}
      />
    );
  }
}

const getModel = (_, props) => props.model;
const getIsTemplate = (_, props) => props.isTemplate;
const getCollapsed = (state, props) =>
  getCollapsedById(state, `line-item-${props.model.id}`);
const getLineItem = createCachedSelector(
  [getModel, getIsTemplate],
  (model, isTemplate) => new LineItemModel(model, isTemplate)
)((_, { model }) => model.id);
const mapStateToProps = createStructuredSelector({
  lineItem: getLineItem,
  collapsed: getCollapsed
});

const mapDispatchToProps = dispatch => ({
  toggleCollapse: itemId => {
    dispatchToggleCollapse(dispatch, itemId);
  },
  expandCollapse: itemId => {
    dispatchExpandId(dispatch, itemId);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomBookLineItem);
