import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Header, Message, Modal } from "semantic-ui-react";
import PriceCatalogResource from "builder_portal/actions/priceCatalogActions";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import usePolling from "builder_portal/hooks/usePolling";
import { FormattedMessage, useIntl } from "react-intl";
import { getTradesForProductGroups } from "shared/selectors/unit/productData";
import { getTrades } from "shared/selectors/account";
import FloatInput from "shared/components/forms/FloatInput";
import { keyBy } from "lodash";
import { node, number, instanceOf, string } from "prop-types";
import ProductGroupResource from "../../actions/productGroupActions";

const STATUS_CHECK_WATTIME = 5 * 60 * 1000; // 5 minutes
const STATUS_CHECK_INTERVAL = 2500;
const STATUS_CHECK_COUNT = STATUS_CHECK_WATTIME / STATUS_CHECK_INTERVAL;

const BulkPriceUpdate = ({
  trigger,
  priceCatalogId,
  groupsResource,
  productGroupId
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const usedTrades = useSelector(getTradesForProductGroups);
  const trades = keyBy(useSelector(getTrades), "id");
  const [values, setValues] = useState({});

  useEffect(() => {
    const vals = usedTrades.reduce((acc, trade) => {
      acc[trade] = { price: 0, costs: 0 };
      return acc;
    }, {});
    setValues(vals);
  }, [usedTrades]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleChange = (_, { name, value, customData }) => {
    setValues({
      ...values,
      [name]: { ...values[name], [customData]: value / 100 }
    });
  };

  const afterPolling = () => {
    if (productGroupId) {
      groupsResource.get(productGroupId, true).then(handleClose);
    } else {
      handleClose();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    STATUS_CHECK_COUNT,
    afterPolling
  );

  const onSubmit = () => {
    new PriceCatalogResource(dispatch)
      .bulkPriceUpdate(priceCatalogId, { config: values })
      .then(res => {
        if (res.data?.id) startPolling(res.data.id, STATUS_CHECK_INTERVAL);
      });
  };

  return (
    <Modal
      open={open}
      trigger={trigger}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <FormattedMessage id="product_group.actions.bulks_price_change" />
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6} />
            <Grid.Column width={5}>
              <FormattedMessage
                id="product_group.attributes.costs.label"
                default="Kosten"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <FormattedMessage
                id="product_group.attributes.price.label"
                default="Preise"
              />
            </Grid.Column>
          </Grid.Row>
          {Object.keys(values).map(field => (
            <Grid.Row key={field}>
              <Grid.Column width={6}>
                <Header as="h5">{trades[field]?.label}</Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <FloatInput
                  name={field}
                  customData="costs"
                  onChange={handleChange}
                  label="%"
                  labelPosition="right"
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <FloatInput
                  name={field}
                  customData="price"
                  onChange={handleChange}
                  label="%"
                  labelPosition="right"
                />
              </Grid.Column>
            </Grid.Row>
          ))}
          <Message warning style={{ width: "100%" }}>
            <Header as="h4">
              <FormattedMessage id="product_group.warnings.bulks_price_change.title" />
            </Header>
            <FormattedMessage id="product_group.warnings.bulks_price_change.message" />
          </Message>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Column textAlign="right" width={16}>
            <Button
              id="close"
              data-form="bulk_price_change"
              onClick={handleClose}
              content={intl.formatMessage({
                id: "meta.actions.close"
              })}
            />
            <Button
              id="submit"
              data-form="bulk_price_change"
              positive
              type="submit"
              onClick={onSubmit}
              content={intl.formatMessage({
                id: "meta.actions.start"
              })}
              loading={isPolling}
              disabled={isPolling}
            />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

BulkPriceUpdate.propTypes = {
  trigger: node.isRequired,
  priceCatalogId: number.isRequired,
  groupsResource: instanceOf(ProductGroupResource).isRequired,
  productGroupId: string
};

BulkPriceUpdate.defaultProps = {
  productGroupId: undefined
};

export default BulkPriceUpdate;
