import { UPDATE_NOTIFICATIONS } from "../constants/appConstants";

export const notifications = (state = { messages: 0 }, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      return action.payload;
    default:
      return state;
  }
};
