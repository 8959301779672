import { any, string, number, shape } from "prop-types";

export const ConfiguratorBackendShape = shape({
  id: number,
  name: string,
  url: string,
  // eslint-disable-next-line react/forbid-prop-types
  config: any,
  // eslint-disable-next-line react/forbid-prop-types
  product_mapping: any
});

export default ConfiguratorBackendShape;
