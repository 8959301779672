import PropTypes from "prop-types";
import React from "react";
import { Grid, Header, Icon, List } from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";

import "./roomBookLineItem.scss";

class RoomBookBaseTotalItem extends React.Component {
  static propTypes = {
    lineItem: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = nextProps.lineItem;
    const prevModel = this.props.lineItem;

    return this.state !== nextState || !nextModel.isEqual(prevModel);
  }

  render() {
    const { lineItem } = this.props;

    if (this.shouldRender(lineItem)) {
      return (
        <List.Item
          data-model="sub_line_item"
          data-component="roomBookBaseTotalItem"
        >
          <List.Content>
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="3" />
              <Grid.Column width="13" className="content">
                <Grid>
                  <Grid.Column width={16}>
                    {this.renderTitle(lineItem)}
                  </Grid.Column>
                </Grid>
                <div className="productContainer border">
                  <div className="description">
                    <div className="text">
                      {this.renderDescription(lineItem)}
                    </div>
                  </div>
                  <div className="price">
                    <span data-attr="total">
                      {!lineItem.isTemplate && (
                        <FormatCurrency amount={this.getAmount(lineItem)} />
                      )}
                    </span>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </List.Content>
        </List.Item>
      );
    }
    return null;
  }

  renderTitle = lineItem => {
    const className =
      lineItem.isCancellation() || lineItem.isCancelled()
        ? "strikethrough"
        : "";
    const icon =
      lineItem.isCancellation() || lineItem.isCancelled() ? "remove" : "money";

    return (
      <div className="titleButton">
        <Icon color="grey" name={icon} />
        <Header className={className}>
          <span data-attr="display_number">
            Basispreis {lineItem.getTitle()}
          </span>
        </Header>
      </div>
    );
  };

  shouldRender = lineItem => {
    return (
      !lineItem.isCancellation() || lineItem.getCancellationBaseTotal() != 0
    );
  };

  getAmount = lineItem => {
    if (lineItem.isCancellation()) {
      return lineItem.getCancellationBaseTotal();
    }
    return -lineItem.getReplacementBaseTotal();
  };

  renderDescription = lineItem => {
    if (lineItem.isCancellation()) {
      return <span>Erstattung des Basispreises der stornierten Position.</span>;
    }
    if (lineItem.isReplacement()) {
      return (
        <span>
          Die entfallende Position "{lineItem.getReplacementTitle()}" wird mit
          &nbsp;
          <FormatCurrency amount={lineItem.getReplacementBaseTotal()} />{" "}
          verrechnet.
        </span>
      );
    }
    return (
      <span>
        Basispreis für die Umsetzung der Leistung "{lineItem.getTitle()}".
      </span>
    );
  };
}

export default RoomBookBaseTotalItem;
