import React, { useCallback, useState } from "react";
import { Container, Grid, Header, Image, Modal } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { Input, Form, Select, TextArea } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { ConfiguratorDesignShape } from "shared/shapes/configuratorDesign.shape";
import PropTypes from "prop-types";
import { lowerFirst } from "lodash";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import ImageLibrary from "shared/components/forms/ImageLibrary";
import { useSelector } from "react-redux";
import { getImages } from "shared/selectors/project";
import ActionButtons from "./ActionButtons";

const variantOptions = [
  { key: "h4", value: "h4", text: "h4" },
  { key: "h5", value: "h5", text: "h5" },
  { key: "h6", value: "h6", text: "h6" },
  { key: "subtitle", value: "subtitle", text: "subtitle" },
  { key: "body1", value: "body1", text: "body1" },
  { key: "body2", value: "body2", text: "body2" }
];

const actionVariantOptions = [
  { key: "outlined", value: "outlined", text: "outlined" },
  { key: "contained", value: "contained", text: "contained" }
];

const actionIdOptions = [
  { key: "configurator", value: "configurator", text: "configurator" }
];

const actionColorOptions = [
  { key: "primary", value: "primary", text: "primary" },
  { key: "secondary", value: "secondary", text: "secondary" }
];

const directionOptions = [
  { key: "default", value: "default", text: "default" },
  { key: "reversed", value: "reversed", text: "reversed" }
];

const FeatureElement = ({
  button,
  onChange,
  initialValue,
  validationSchema,
  configuratorDesign
}) => {
  const projectImages = useSelector(getImages);
  const projectId = configuratorDesign.project_id;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = useCallback(values => {
    const actionId = values?.params?.feature?.action?.action_id;
    if (actionId === "" || actionId === null || actionId === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete values.params.feature.action;
    }
    onChange(values);
    handleClose();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitForm, errors }) => {
        const missingImage = !!errors.params?.image?.url;
        return (
          <Modal
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={button}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id={`configuratorDesign.dashboard.${lowerFirst(
                  initialValue.type
                )}.type`}
              />
            </Modal.Header>
            <Modal.Content scrolling>
              <Container style={{ width: "100%" }}>
                <Form id="okDashboardVideoCard">
                  <Grid style={{ width: "100%" }}>
                    <Grid.Column width={16}>
                      <Header as="h4">
                        <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.title" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                      <TextArea
                        fluid
                        name="params.feature.title.text"
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.text"
                        })}
                        errorPrompt={{
                          prompt: false,
                          pointing: false
                        }}
                      />
                      <Select
                        fluid
                        name="params.feature.title.variant"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.variant"
                        })}
                        options={variantOptions}
                        clearable
                      />
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header as="h4">
                        <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.message" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                      <TextArea
                        fluid
                        name="params.feature.message.text"
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.text"
                        })}
                      />
                      <Select
                        fluid
                        name="params.feature.message.variant"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.variant"
                        })}
                        options={variantOptions}
                        clearable
                      />
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header as="h4">
                        <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.action" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                      <Select
                        fluid
                        name="params.feature.action.action_id"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.actionId"
                        })}
                        options={actionIdOptions}
                        clearable
                      />

                      <If
                        condition={!!values.params?.feature?.action?.action_id}
                        block
                      >
                        <Select
                          fluid
                          name="params.feature.action.variant"
                          label={useIntl().formatMessage({
                            id:
                              "configuratorDesign.dialog.contentPageEditor.variant"
                          })}
                          options={actionVariantOptions}
                        />

                        <Select
                          fluid
                          name="params.feature.action.color"
                          label={useIntl().formatMessage({
                            id:
                              "configuratorDesign.dialog.contentPageEditor.color"
                          })}
                          options={actionColorOptions}
                        />
                      </If>
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header
                        as="h4"
                        style={{ color: missingImage ? "#9f3a38" : "" }}
                      >
                        <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.image" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                      <If condition={!!values.params?.image?.url}>
                        <Image
                          src={values.params.image.url}
                          size="medium"
                          style={{ margin: "5px" }}
                        />
                      </If>
                      <ImageLibrary
                        projectId={projectId}
                        multiple={false}
                        getSelectedImage={image => {
                          setFieldValue("params.image.url", image.url);
                        }}
                        images={projectImages}
                      />
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header as="h4">
                        <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.presentation" />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                      <Select
                        fluid
                        name="params.direction"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.direction"
                        })}
                        options={directionOptions}
                      />

                      <Input
                        fluid
                        name="margin"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.margin"
                        })}
                      />
                      <Field
                        id="css"
                        name="css"
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.css"
                        })}
                        component={SemanticFormikJsonEditor}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <ActionButtons onClose={handleClose} onSubmit={submitForm} />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};
FeatureElement.propTypes = {
  button: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  }),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
  configuratorDesign: ConfiguratorDesignShape.isRequired
};

FeatureElement.defaultProps = {
  initialValue: {
    params: {
      direction: "default"
    }
  }
};

export default FeatureElement;
