import { string, number, shape } from "prop-types";

export const HeaderCellShape = shape({
  title: string,
  style: string,
  width: number
});

export const BodyCellShape = shape({
  field: string,
  if: string,
  unless: string,
  style: string,
  width: number
});
