import React, { Component } from "react";
import { instanceOf } from "prop-types";
import ConfirmableButton from "builder_portal/components/buttons/ConfirmableButton";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import * as Sentry from "@sentry/react";
import { withRouter } from "../../../../shared/helpers/withRouter";

class ResetButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null
    };
  }

  handleReset = () => {
    const { ctrl } = this.props;
    this.setState({ loading: true });
    return ctrl
      .reset()
      .then(() => this.setState({ loading: false }))
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        this.setState({ loading: false, error });
        /* Displaying the error that was sent to sentry
         * makes in-place-debugging much easier
         */
        // eslint-disable-next-line no-console
        console.error("api Error occurred", error);
        Sentry.captureException(error);
      });
  };

  render() {
    const { loading, error } = this.state;

    return (
      <IsSystemAdminOrHasSpecificRight right="ext_allow_delete_room_books">
        <ConfirmableButton
          id="reset"
          confirmationTitle="roomBook.actions.resetDialog.title"
          confirmationMessage="roomBook.actions.resetDialog.htmlMessage"
          confirmationButtonId="delete"
          confirmationButtonColor="red"
          errorMessage={error?.message}
          confirmationButtonContent="meta.actions.reset"
          onConfirm={this.handleReset}
          loading={loading}
          color="red"
          basic
          content="meta.actions.reset"
          icon="undo"
          onClose={() => this.setState({ error: null })}
        />
      </IsSystemAdminOrHasSpecificRight>
    );
  }
}

ResetButton.propTypes = {
  ctrl: instanceOf(RoomBookController).isRequired
};

export default withRouter(ResetButton);
