import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { shape, instanceOf, string } from "prop-types";
import {
  Header,
  Grid,
  Button,
  Table,
  Loader,
  Dropdown,
  Icon
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { getProject } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { UnitVariableTypesResource } from "../../../actions/unitVariablesActions";
import { getUnitVariableTypes } from "../../../selectors/unitVariables";
import UnitVariableTypeDialog from "./UnitVariableTypeDialog";

const UnitVariableTypesList = ({ resources, projectId }) => {
  const unitVariableTypes = useSelector(getUnitVariableTypes);
  const [isLoading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const project = useSelector(getProject);
  const intl = useIntl();
  const dispatch = useDispatch();

  const options = useMemo(
    () => [
      {
        key: "import",
        icon: "copy",
        text: intl.formatMessage({ id: "meta.actions.import" }),
        value: "import"
      }
    ],
    []
  );

  const deselectAction = () => {
    setAction(null);
  };

  const loadData = () => {
    setLoading(true);
    resources.unitVariableTypes
      .fetchAll()
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        new Growl(dispatch).error(
          "message.errorBackend.title",
          "message.errorBackend.body",
          {
            bodyValues: { translatedBody: err }
          }
        );
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleTypeDelete = (id, handleClose) => {
    resources.unitVariableTypes
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.unitVariables.success.title",
          "roomBook.unitVariables.success.message"
        );
        loadData();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderDeleteButton = id => {
    const button = (
      <Button
        id="unit_variable_type_delete"
        icon="trash"
        basic
        loading={isLoading}
        disabled={isLoading}
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => handleTypeDelete(id, handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.unitVariables.types.removeConfirmation.title"
        message="roomBook.unitVariables.types.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleImportDefault = handleClose => {
    new UnitVariableTypesResource(dispatch, project?.id)
      .importDefaults()
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.unitVariables.types.importDefaults.success.title",
          "roomBook.unitVariables.types.importDefaults.success.message"
        );
        loadData();
        handleClose();
        deselectAction();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderImportDefaultsButton = () => {
    const buttons = [
      {
        id: "import",
        label: "meta.actions.import",
        color: "green",
        onClick: handleClose => handleImportDefault(handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true,
        onClick: () => deselectAction()
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.unitVariables.types.importDefaults.confirmationDialog.title"
        message="roomBook.unitVariables.types.importDefaults.confirmationDialog.message"
        buttons={buttons}
        open
      />
    );
  };

  const renderUnitVariableType = item => {
    return (
      <Table.Row key={item.id} id={`unit-var-type-${item.id}`}>
        <Table.Cell>{item.identifier}</Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.description}</Table.Cell>
        <Table.Cell textAlign="right">
          <VersionHistoryLink
            id={item.id}
            type="UnitVariableType"
            style={{
              fontSize: "1.2em"
            }}
          />
          <UnitVariableTypeDialog
            model={item}
            projectId={projectId}
            button={
              <Button
                id="unit_variable_type_edit"
                icon="setting"
                basic
                loading={isLoading}
                disabled={isLoading}
                data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                onClick={() => setAction(`edit${item.id}`)}
              />
            }
          />
          {renderDeleteButton(item.id)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderUnitVariableTypes = () => {
    if (isLoading) {
      return <Loader active />;
    }
    if (!unitVariableTypes.length && !isLoading) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div className="notFound">
                <FormattedMessage
                  id="roomBook.unitVariables.notFound"
                  defaultMessage="Es liegen keine Unit variable types zu diesem Projekt vor"
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }

    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.types.dialog.identifier"
                defaultMessage="IDentifier"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.types.dialog.name"
                defaultMessage="Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.types.dialog.description"
                defaultMessage="Description"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="roomBook.unitVariables.types.dialog.action"
                defaultMessage="Action"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{unitVariableTypes.map(renderUnitVariableType)}</Table.Body>
      </>
    );
  };

  const handleChangeAction = (_, { value }) => {
    setAction(value);
  };

  return (
    <>
      <Grid
        stackable
        data-component="upgrade_bundles"
        className="margin bottom medium"
      >
        <Grid.Column width="8">
          <Header size="large">
            <FormattedMessage
              id="roomBook.unitVariables.types.title"
              defaultMessage="Wohnungsvariablen Typen"
            />
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width="8">
          <Button.Group>
            <UnitVariableTypeDialog
              model={{ identifier: "", name: "", description: "" }}
              projectId={projectId}
              button={
                <Button
                  id="create_unit_variable_type"
                  disabled={isLoading}
                  className="done"
                  color="green"
                >
                  <Icon name="plus" />{" "}
                  {intl.formatMessage({ id: "meta.actions.add" })}
                </Button>
              }
            />
            <Dropdown
              id="unit_variable_type_dropdown"
              className="button icon"
              floating
              selectOnBlur={false}
              options={options}
              trigger={<></>}
              onChange={handleChangeAction}
              value={action}
            />
          </Button.Group>
          <If condition={action === "import"}>
            {renderImportDefaultsButton()}
          </If>
        </Grid.Column>
      </Grid>
      <Table data-component="unit_variable_type_items">
        {renderUnitVariableTypes()}
      </Table>
    </>
  );
};

UnitVariableTypesList.propTypes = {
  projectId: string.isRequired,
  resources: shape({
    unitVariableTypes: instanceOf(UnitVariableTypesResource)
  }).isRequired
};

export default UnitVariableTypesList;
