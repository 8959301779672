import React, { useState, useCallback, useRef, useMemo } from "react";
import {
  Container,
  Grid,
  Header,
  Button,
  Modal,
  Form as SemanticForm,
  Message,
  Dropdown,
  Icon,
  Segment
} from "semantic-ui-react";
import { Formik } from "formik";
import { sortBy, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ProjectAnalysisTopicResource from "builder_portal/actions/projectAnalyssisTopicActions";
import { getProjectAnalysisTopics } from "builder_portal/selectors/projectAnalysisTopics";
import { Input, Form, TextArea, Select } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { node, object } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import * as Yup from "yup";
import { TABS } from "./data";

const getEmptyModel = topicType => ({
  name: "",
  description: "",
  summary: "",
  technical_reference: "",
  project_analysis_topic_options: [],
  requirements: {},
  topic_type: topicType
});

const ProjectAnalysisTopicDialog = ({ model, button, tab }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const refResetForm = useRef();
  const topics = useSelector(getProjectAnalysisTopics);
  const topic = useMemo(() => model || getEmptyModel(tab.id), [model, tab]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [options, setOptions] = useState(topic.project_analysis_topic_options);
  const [editingOption, setEditingOption] = useState({});
  const [deletedOptions, setDeletedOptions] = useState([]);
  const [newOption, setNewOption] = useState({
    label: "",
    value: "",
    description: ""
  });
  const [requirements, setRequirements] = useState(topic.requirements || {});
  const [newRequirement, setNewRequirement] = useState({
    technicalReference: "",
    option: ""
  });

  const technicalReferenceOptions = useMemo(() => {
    return uniq(
      topics
        .filter(topicItem => !!topicItem.technical_reference)
        .map(topicItem => ({
          key: topicItem.technical_reference,
          value: topicItem.technical_reference,
          text: topicItem.technical_reference
        }))
    );
  }, [tab]);

  const optionOptions = useMemo(() => {
    return (
      tab.items
        .filter(
          topicItem =>
            topicItem.technical_reference === newRequirement.technicalReference
        )[0]
        ?.project_analysis_topic_options.map(option => ({
          key: option.id,
          value: option.value,
          text: option.label
        })) || []
    );
  }, [newRequirement.technicalReference]);

  const topicOptions = useMemo(() => {
    return TABS.filter(t => t.checkBy === "topics").map(t => ({
      key: t.id,
      value: t.id,
      text: intl.formatMessage({ id: `project.project_setup.tabs.${t.id}` })
    }));
  }, []);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
    setEditingOption({});
  };

  const handleOpen = () => {
    setOptions(topic.project_analysis_topic_options);
    setRequirements(topic.requirements || {});
    setDeletedOptions([]);
    setError("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const handleAddOption = e => {
    e.preventDefault();
    if (
      options.find(
        x => x.value === newOption.value || x.label === newOption.label
      )
    ) {
      setError("optionExists");
      return;
    }
    const maxPosition = Math.max(...options.map(x => x.position));

    setOptions(
      [...options].concat({ ...newOption, position: maxPosition + 1 })
    );
    setError("");
    setNewOption({
      label: "",
      value: "",
      description: ""
    });
  };

  const handleAddRequirement = e => {
    e.preventDefault();
    if (requirements[newRequirement.technicalReference]) {
      setError("requirementExists");
      return;
    }
    setRequirements({
      ...requirements,
      [newRequirement.technicalReference]: newRequirement.option
    });
    setNewRequirement({
      technicalReference: "",
      option: ""
    });
  };

  const handleDeleteOption = option => {
    const opt = options.find(x => x.value === option.value);
    if (opt.id) {
      // option must be deleted form backend (not newly added option)
      setDeletedOptions(
        [...deletedOptions].concat({ id: opt.id, _destroy: true })
      );
    }
    setOptions(options.filter(x => x.value !== option.value));
  };

  const handleDeleteRequirement = key => {
    const temp = { ...requirements };
    delete temp[key];
    setRequirements(temp);
  };

  const onSubmit = useCallback(
    values => {
      // eslint-disable-next-line no-param-reassign
      values.project_analysis_topic_options_attributes = options.concat(
        deletedOptions
      );
      // eslint-disable-next-line no-param-reassign
      values.requirements = requirements;

      const resource = new ProjectAnalysisTopicResource(dispatch);
      if (topic?.id) {
        // eslint-disable-next-line no-param-reassign
        values.id = topic?.id;
      }
      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [topic, options, requirements]
  );

  const renderOptions = () => {
    return (
      <>
        {sortBy(options, "position").map((opt, index) => {
          const readonly = opt.id ? opt.id !== editingOption.id : true;
          const option = readonly ? opt : editingOption;

          return (
            <Segment key={option.id}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FormattedMessage
                      id="project.project_setup.analysisTopics.dialog.option"
                      default="Option"
                    />{" "}
                    {index + 1}
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <If condition={!!opt.id}>
                      <Icon
                        name={readonly ? "edit" : "check"}
                        role="button"
                        color="grey"
                        onClick={() => {
                          if (readonly) setEditingOption({ ...option });
                          if (!readonly) {
                            setOptions(
                              options
                                .filter(x => x.id !== editingOption.id)
                                .concat(editingOption)
                            );
                            setEditingOption({});
                          }
                        }}
                      />
                    </If>
                    <Icon
                      name="trash"
                      role="button"
                      color="grey"
                      onClick={() => handleDeleteOption(option)}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <SemanticForm.Input
                      value={option.label}
                      readOnly={readonly}
                      fluid
                      label={intl.formatMessage({
                        id: "project.project_setup.analysisTopics.dialog.label"
                      })}
                      onChange={(e, { value }) =>
                        setEditingOption({ ...editingOption, label: value })
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SemanticForm.Input
                      value={option.value}
                      readOnly={readonly}
                      fluid
                      label={intl.formatMessage({
                        id: "project.project_setup.analysisTopics.dialog.value"
                      })}
                      onChange={(e, { value }) =>
                        setEditingOption({ ...editingOption, value })
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <SemanticForm.TextArea
                      value={option.description}
                      disabled={readonly}
                      style={{ color: readonly && "gray" }}
                      fluid
                      label={intl.formatMessage({
                        id:
                          "project.project_setup.analysisTopics.dialog.description"
                      })}
                      onChange={(e, { value }) =>
                        setEditingOption({
                          ...editingOption,
                          description: value
                        })
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          );
        })}

        {/* New option */}
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormattedMessage
                  id="project.project_setup.analysisTopics.dialog.newOption"
                  default="Newu Option"
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  basic
                  onClick={handleAddOption}
                  icon="plus"
                  disabled={!newOption.label || !newOption.value}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <SemanticForm.Input
                  id="newOptionName"
                  value={newOption.label}
                  fluid
                  label={intl.formatMessage({
                    id: "project.project_setup.analysisTopics.dialog.label"
                  })}
                  autoComplete="off"
                  onChange={(e, { value }) =>
                    setNewOption({ ...newOption, label: value })
                  }
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <SemanticForm.Input
                  id="newOptionValue"
                  value={newOption.value}
                  fluid
                  label={intl.formatMessage({
                    id: "project.project_setup.analysisTopics.dialog.value"
                  })}
                  autoComplete="off"
                  onChange={(e, { value }) =>
                    setNewOption({ ...newOption, value })
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <SemanticForm.Input
                  id="newOptionDescription"
                  value={newOption.description}
                  fluid
                  label={intl.formatMessage({
                    id:
                      "project.project_setup.analysisTopics.dialog.description"
                  })}
                  autoComplete="off"
                  onChange={(e, { value }) =>
                    setNewOption({ ...newOption, description: value })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    );
  };

  const renderRequirements = () => {
    return (
      <Grid>
        <Grid.Column width={6}>
          <FormattedMessage
            id="project.project_setup.analysisTopics.dialog.technicalReference"
            default="Technical Reference"
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <FormattedMessage
            id="project.project_setup.analysisTopics.dialog.option"
            default="Option"
          />
        </Grid.Column>
        <Grid.Column width={4} />

        {Object.keys(requirements).map(key => (
          <Grid.Row key={key}>
            <Grid.Column width={6}>
              <SemanticForm.Input fluid value={key} readOnly />
            </Grid.Column>
            <Grid.Column width={6}>
              <SemanticForm.Input fluid value={requirements[key]} readOnly />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <Icon
                name="trash"
                size="large"
                role="button"
                color="grey"
                onClick={() => handleDeleteRequirement(key)}
              />
            </Grid.Column>
          </Grid.Row>
        ))}

        {/* New requirement */}
        <Grid.Row>
          <Grid.Column width={6}>
            <Dropdown
              id="newTechnicalReference"
              selection
              fluid
              upward
              value={newRequirement.technicalReference}
              options={technicalReferenceOptions}
              onChange={(e, { value }) =>
                setNewRequirement({
                  ...newRequirement,
                  technicalReference: value
                })
              }
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Dropdown
              id="newOption"
              selection
              fluid
              upward
              value={newRequirement.option}
              options={optionOptions}
              onChange={(e, { value }) =>
                setNewRequirement({ ...newRequirement, option: value })
              }
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <Button
              fluid
              basic
              onClick={handleAddRequirement}
              icon="plus"
              disabled={
                !newRequirement.technicalReference || !newRequirement.option
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    summary: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  return (
    <Formik
      initialValues={{
        ...topic
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={button}
          >
            <Modal.Header>
              <FormattedMessage
                id="project.project_setup.analysisTopics.dialog.title"
                default="Project Analysis Topics Bearbeiten"
              />
            </Modal.Header>
            <Modal.Content scrolling>
              <If condition={!!error}>
                <Message error>
                  <FormattedMessage
                    id={`project.project_setup.analysisTopics.dialog.errors.${error}`}
                  />
                </Message>
              </If>
              <Form>
                <Container>
                  <Grid>
                    <Grid.Column width={16}>
                      <Input
                        fluid
                        placeholder={intl.formatMessage({
                          id: "project.project_setup.analysisTopics.dialog.name"
                        })}
                        name="name"
                        label={intl.formatMessage({
                          id: "project.project_setup.analysisTopics.dialog.name"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Input
                        fluid
                        placeholder={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.summary"
                        })}
                        name="summary"
                        label={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.summary"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <TextArea
                        errorPrompt={{
                          prompt: false,
                          pointing: false
                        }}
                        placeholder={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.description"
                        })}
                        name="description"
                        label={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.description"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        fluid
                        placeholder={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.technicalReference"
                        })}
                        name="technical_reference"
                        label={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.technicalReference"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Select
                        selection
                        name="topic_type"
                        label={intl.formatMessage({
                          id:
                            "project.project_setup.analysisTopics.dialog.topic"
                        })}
                        options={topicOptions}
                      />
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header as="h5">
                        <FormattedMessage
                          id="project.project_setup.analysisTopics.dialog.options"
                          default="Optionen"
                        />
                      </Header>
                      {renderOptions()}
                    </Grid.Column>

                    <Grid.Column width={16}>
                      <Header as="h4">
                        <FormattedMessage
                          id="project.project_setup.analysisTopics.dialog.requirements"
                          default="Optionen"
                        />
                      </Header>
                      {renderRequirements()}
                    </Grid.Column>
                  </Grid>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

ProjectAnalysisTopicDialog.propTypes = {
  button: node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  model: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tab: object.isRequired
};

export default ProjectAnalysisTopicDialog;
