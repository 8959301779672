import { ProjectActivitiesResource } from "builder_portal/actions/projectActions";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { routerShape } from "react-router";
import { Form, Modal } from "semantic-ui-react";
import { withRouter } from "../../../shared/helpers/withRouter";
import "./deleteActivityDialog.scss";

const DEFAULT_STATE = {
  confirmation: null,
  message_confirmation: "0",
  open: false,
  loading: false
};

class DeleteActivityDialog extends React.PureComponent {
  static propTypes = {
    activity: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string
    }).isRequired,
    activitiesResource: PropTypes.instanceOf(ProjectActivitiesResource)
      .isRequired,
    trigger: PropTypes.node.isRequired,
    router: routerShape
  };

  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  handleConfirmationChange = (_event, { value }) =>
    this.setState({ confirmation: value });

  handleMessageConfirmationChange = (_event, { value }) =>
    this.setState({ message_confirmation: value });

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState(DEFAULT_STATE);

  handleRemove = () => {
    const {
      activity: { id },
      activitiesResource
    } = this.props;
    this.setState({ loading: true });
    activitiesResource
      .remove(id)
      .then(this.handleRemoveSuccess, this.handleClose);
  };

  handleRemoveSuccess = () => {
    const { router, activity } = this.props;
    if (activity.units.length === 1)
      router.push(
        `/projects/${activity.project.slug}/units/${activity.units[0].slug}/room-book`
      );
    else router.push(`/projects/${activity.project.slug}/activity`);
  };

  render() {
    const {
      activity: { display_name, messages_count },
      trigger
    } = this.props;
    const { open, loading } = this.state;
    return (
      <Modal
        data-component="delete-activity-confirmation-dialog"
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        trigger={trigger}
      >
        <Modal.Header>
          <FormattedMessage id="activity.actions.delete.header" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <FormattedMessage
                id="activity.actions.delete.confirmationPrompt"
                values={{ display_name }}
              >
                {txt => <label className="confirmation">{txt}</label>}
              </FormattedMessage>
              <Form.Input
                id="confirmation"
                onChange={this.handleConfirmationChange}
              />
            </Form.Field>
            {this.renderMessagesConfirmation()}
          </Form>
        </Modal.Content>
        <Modal.Actions
          actions={[
            {
              id: "delete",
              key: "delete",
              content: <FormattedMessage id="meta.actions.remove" />,
              disabled: loading || !this.isConfirmed(),
              loading,
              negative: true,
              onClick: this.handleRemove
            },
            {
              id: "cancel",
              key: "cancel",
              disabled: loading,
              loading,
              onClick: this.handleClose,
              content: <FormattedMessage id="meta.actions.cancel" />
            }
          ]}
        />
      </Modal>
    );
  }

  isConfirmed = () => {
    const {
      activity: { display_name, messages_count }
    } = this.props;
    const { confirmation, message_confirmation } = this.state;
    return (
      confirmation === display_name &&
      message_confirmation === messages_count.toString()
    );
  };

  renderMessagesConfirmation() {
    const {
      activity: { messages_count }
    } = this.props;
    if (messages_count > 0)
      return (
        <Form.Field>
          <FormattedMessage
            tagName="label"
            id="activity.actions.delete.confirmationMessagesPrompt"
            values={{ messages_count }}
          />
          <Form.Input
            id="message_confirmation"
            onChange={this.handleMessageConfirmationChange}
          />
        </Form.Field>
      );
    return null;
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props.params;
  return {
    activitiesResource: new ProjectActivitiesResource(dispatch, projectId)
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(DeleteActivityDialog)
);
