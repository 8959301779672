import PropTypes from "prop-types";
import React from "react";
import { Grid, Icon, Header, Label } from "semantic-ui-react";
import "./task.scss";

class Task extends React.Component {
  static propTypes = {
    ctxModel: PropTypes.object,
    ctxCtrl: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      taskLoadingState: {}
    };
  }

  completeTask(id) {
    const { ctxCtrl } = this.props;
    const taskLoadingState = { ...this.state.taskLoadingState };
    taskLoadingState[id] = "loading";
    this.setState({ ...this.state, taskLoadingState });

    ctxCtrl.completeTask(id).then(() => {
      taskLoadingState[id] = "completed";
      this.setState({ ...this.state, taskLoadingState });
    });
  }

  reopenTask(id) {
    const { ctxCtrl } = this.props;

    const taskLoadingState = { ...this.state.taskLoadingState };
    taskLoadingState[id] = "loading";
    this.setState({ ...this.state, taskLoadingState });

    ctxCtrl.reopenTask(id).then(() => {
      taskLoadingState[id] = "";
      this.setState({ ...this.state, taskLoadingState });
    });
  }

  render() {
    const { ctxModel } = this.props;
    const tasks = ctxModel.get("tasks", []);
    const activity = ctxModel.getActivity();

    if (!tasks || !tasks.length) {
      return null;
    }

    const children = tasks.map(task => {
      if (task.completed_at && task.filter_tag !== activity.status) {
        return;
      }
      let action;
      if (task.completed_at) {
        action = () => {
          this.reopenTask(task.id);
        };
      } else {
        action = () => {
          this.completeTask(task.id);
        };
      }
      const classNames = [
        this.state.taskLoadingState[task.id] || "",
        task.completed_at ? "completed" : ""
      ];
      return (
        <div
          data-component="taskItem"
          key={task.id}
          className={classNames.join(" ")}
        >
          <div className="right floated element">
            <Label color="blue" size="small">
              <Icon name="shipping" /> {task.delegatee.label.company}
            </Label>
          </div>
          <div className="left floated element checkmark" onClick={action}>
            <Icon
              circular
              loading={this.state.taskLoadingState[task.id] === "loading"}
              name={
                this.state.taskLoadingState[task.id] === "loading"
                  ? "circle notched"
                  : "check"
              }
            />
          </div>
          <div className="left floated element">
            <Header size="small">{task.title}</Header>
          </div>
        </div>
      );
    });

    return (
      <Grid.Column width="16" data-component="taskList">
        {children}
      </Grid.Column>
    );
  }
}

export default Task;
