// Needed for MasterSlaveList
/* eslint-disable react/jsx-props-no-spreading */
import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  objectOf,
  oneOfType,
  shape,
  string
} from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Form,
  Icon,
  Modal,
  Popup,
  Table,
  Dropdown,
  Message
} from "semantic-ui-react";
import { createSelector, createStructuredSelector } from "reselect";
import {
  getActivatedLineItemsCount,
  getProductsById,
  getRoomBook,
  getSubLineItems,
  getUnit,
  getProject,
  getProductRepository
} from "builder_portal/selectors";
import { getI18N } from "shared/selectors";
import MasterSlaveList from "shared/components/elements/MasterSlaveList";
import { groupBy, keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import LineItemStatusLabel from "builder_portal/components/roomBook/LineItemStatusLabel";
import {
  BatchCartAssignmentResource,
  RoomBookCartResource
} from "builder_portal/actions/roomBookActions";
import { routerShape } from "react-router";
import "./cartCreationDialog.scss";
import ProcessDefinition from "shared/models/processDefinition";
import { actionFactory } from "builder_portal/components/activity/actions/BatchAction";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import LineItemModel from "builder_portal/models/roomBook/LineItemModel";
import { SubLineItemShape } from "shared/components/configurator/roomBook.shape";
import { ProductShape } from "shared/shapes/product.shape";
import { UnitShape } from "shared/shapes/unit.shape";
import { ProjectShape } from "shared/shapes/project.shape";
import { I18nShape } from "shared/shapes/i18n.shape";
import { withRouter } from "../../../shared/helpers/withRouter";

const matchesPhase = (lineItemModel, phaseFilter) => {
  if (phaseFilter === "all") return true;

  const fvs =
    phaseFilter === "decided_buyer"
      ? ["decided_buyer", "decided"]
      : ["decided_buyer", "decided", "open"];

  return fvs.includes(lineItemModel?.getPhaseId());
};

const getInitialFilter = lineItem => {
  const phase = lineItem?.getPhaseId();

  return ["decided_buyer", "decided"].includes(phase)
    ? "decided_buyer"
    : "selectable";
};

const getlineItemsCount = (options, phaseFilter) => {
  const activated = options.filter(o => o.lineItem?.currentModel?.activated);
  return activated.filter(o => matchesPhase(o?.lineItem, phaseFilter)).length;
};

class CartCreationDialog extends Component {
  constructor(props) {
    super(props);

    const {
      preSelectedIds: selectedOptions,
      processDefinition,
      unit,
      provokingLineItem
    } = props;

    const parameters = { unitName: unit.display_name };
    const formValues = processDefinition.getDefaultFormValues(parameters);
    const phaseFilter = getInitialFilter(provokingLineItem);
    this.state = {
      selectedOptions,
      formValues,
      loading: false,
      phaseFilter,
      lineItemsCount: getlineItemsCount(props.options, phaseFilter)
    };

    this.refPriceMessage = React.createRef();
  }

  getActions = () => {
    const { processDefinition, i18n, options } = this.props;
    const { loading, phaseFilter } = this.state;
    const startActions = processDefinition.getStartActions();

    const filter = [
      <Dropdown
        id="filter-activity-creation"
        key="filter"
        style={{ float: "left" }}
        selection
        options={[
          {
            key: 1,
            value: "decided_buyer",
            text: i18n["activity.attributes.creation.filter.buyerSelection"]
          },
          {
            key: 2,
            value: "selectable",
            text: i18n["activity.attributes.creation.filter.selectable"]
          },
          {
            key: 3,
            value: "all",
            text: i18n["activity.attributes.creation.filter.all"]
          }
        ]}
        defaultValue={phaseFilter}
        onChange={(_, { value }) => {
          this.setState({
            phaseFilter: value,
            lineItemsCount: getlineItemsCount(options, value)
          });
        }}
      />
    ];

    return filter.concat(
      startActions.map(action => ({
        ...action.button,
        key: action.id,
        content: action.label,
        onClick: this.handleAction,
        disabled: loading,
        "data-action": action,
        loading
      }))
    );
  };

  handleChange = ({ selectedOptions }) => {
    const { options } = this.props;
    const { phaseFilter } = this.state;

    const newValue = options
      .filter(
        o =>
          selectedOptions.includes(o?.lineItem.getId()) &&
          matchesPhase(o?.lineItem, phaseFilter)
      )
      .map(o => o.lineItem.getId());
    this.setState({
      selectedOptions: newValue
    });
  };

  handleInputChange = (_, { name, value }) => {
    return this.setState(({ formValues }) => ({
      formValues: { ...formValues, [name]: value }
    }));
  };

  handleAction = (_event, { "data-action": action }) => {
    const {
      getAction,
      roomBookId,
      processDefinition,
      router,
      onClose,
      project
    } = this.props;
    const {
      selectedOptions,
      formValues: { activityTitle, ...remainingFormValues }
    } = this.state;

    const command = getAction(action.action);

    const { defaults } = action;
    const cart = {
      process_type: processDefinition.id,
      status: action.status,
      name: activityTitle
    };

    this.setState({ loading: true });

    command
      .execute({
        ...defaults,
        lineItemIds: selectedOptions,
        cart,
        roomBookId,
        router,
        projectId: project.id,
        ...remainingFormValues
      })
      .then(onClose, onClose);
  };

  renderSubLineItem = subLineItem => {
    const { productsRepository, i18n } = this.props;

    const productsWithPrices = keyBy(
      productsRepository.getGroupProducts(subLineItem.product_group_id),
      p => p.id
    );

    const product = productsWithPrices[subLineItem.product_id];
    if (
      product?.group?.on_request &&
      !subLineItem.custom_costs_and_prices_confirmed
    ) {
      // during the first render, refPriceMessage.current is still null
      setTimeout(() => {
        const { style } = this.refPriceMessage.current;
        if (style) style.display = "block";
      }, 200);
    }

    const warningIcon =
      product?.group?.on_request &&
      !subLineItem.custom_costs_and_prices_confirmed ? (
        <Popup
          content={i18n["activity.message.priceOnRequest.tooltip"]}
          trigger={<Icon name="warning circle" color="yellow" />}
        />
      ) : null;

    return (
      <Table.Row
        data-model="sub_line_item"
        key={`sub-line-item-${subLineItem.id}`}
      >
        <Table.Cell>{warningIcon}</Table.Cell>
        <Table.Cell>{subLineItem.display_number}</Table.Cell>
        <Table.Cell>{subLineItem.title}</Table.Cell>
        <Table.Cell textAlign="center" singleLine>
          {subLineItem.quantity}{" "}
          <FormattedMessage
            id={`roomBook.priceStrategyShort.${subLineItem.price_strategy}`}
          />
        </Table.Cell>
        <Table.Cell>
          {product ? product.name : subLineItem.description}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <FormatCurrency amount={subLineItem.total} />
        </Table.Cell>
      </Table.Row>
    );
  };

  isItemVisible = ({ sectionId, subSectionId, lineItem }) => {
    const { options } = this.props;
    const { phaseFilter, selectedOptions } = this.state;
    if (phaseFilter === "all") return true;

    const matches = li => {
      return (
        li &&
        li.isActivated() &&
        !li.hasPendingBuyerSelection() &&
        (selectedOptions.includes(li.getId()) || matchesPhase(li, phaseFilter))
      );
    };

    if (lineItem) {
      return matches(lineItem);
    }

    if (subSectionId) {
      return options
        .filter(o => o.subSectionId === subSectionId)
        .map(o => o?.lineItem)
        .some(matches);
    }

    if (sectionId) {
      return options
        .filter(o => o.sectionId === sectionId)
        .map(o => o?.lineItem)
        .some(matches);
    }

    return false;
  };

  renderLineItem = ({
    lineItem,
    disabled,
    sectionId,
    subSectionId,
    preselected,
    ...checkBoxProps
  }) => {
    if (this.isItemVisible({ lineItem })) {
      const { processDefinition } = this.props;
      const { loading } = this.state;
      return (
        <Fragment key={`line-item-${lineItem.getId()}`}>
          <Table.Row data-model="line_item">
            <Table.Cell>
              {disabled ? (
                <Popup
                  content={
                    <FormattedMessage
                      id={lineItem.getUnassignableReason(processDefinition)}
                    />
                  }
                  trigger={<Icon name="close" color="grey" />}
                  position="right center"
                />
              ) : (
                <Checkbox
                  disabled={loading}
                  data-display_number={lineItem.getDisplayNumber()}
                  {...checkBoxProps}
                />
              )}
            </Table.Cell>
            <Table.Cell>{lineItem.getDisplayNumber()}</Table.Cell>
            <Table.Cell>{lineItem.getTitle()}</Table.Cell>
            <Table.Cell />
            <Table.Cell colSpan="2" textAlign="right">
              <LineItemStatusLabel model={lineItem.currentModel} />
            </Table.Cell>
          </Table.Row>
          {lineItem.getSubLineItems().map(this.renderSubLineItem)}
        </Fragment>
      );
    }
    return null;
  };

  renderSubSection = subSection => ({
    masterProps: { disabled, ...masterProps },
    childrenProps
  }) => {
    const { preSelectedSubSectionIds } = this.props;
    if (
      !this.isItemVisible({ subSectionId: subSection.id }) &&
      preSelectedSubSectionIds.indexOf(subSection.id) === -1
    )
      return null;
    const { loading } = this.state;
    return (
      <Fragment key={`sub-section-${subSection.id}`}>
        <Table.Row data-model="sub-section">
          <Table.Cell>
            {disabled ? (
              <Icon name="close" color="grey" />
            ) : (
              <Checkbox disabled={loading} {...masterProps} />
            )}
          </Table.Cell>
          <Table.Cell>{subSection.display_number}</Table.Cell>
          <Table.Cell colSpan="4">{subSection.title}</Table.Cell>
        </Table.Row>
        {childrenProps.map(this.renderLineItem)}
      </Fragment>
    );
  };

  renderSection = section => ({
    masterProps: { disabled, ...masterProps }
  }) => {
    const { preSelectedSectionIds } = this.props;
    if (
      !this.isItemVisible({ sectionId: section.id }) &&
      preSelectedSectionIds.indexOf(section.id) === -1
    )
      return null;
    const { loading } = this.state;
    return (
      <Fragment key={`section-${section.id}`}>
        <Table.Row data-model="section">
          <Table.Cell>
            {disabled ? (
              <Icon name="close" color="grey" />
            ) : (
              <Checkbox disabled={loading} {...masterProps} />
            )}
          </Table.Cell>
          <Table.Cell>{section.display_number}</Table.Cell>
          <Table.Cell colSpan="4">{section.title}</Table.Cell>
        </Table.Row>
        {section.sections.map(this.renderSubSectionList)}
      </Fragment>
    );
  };

  renderSubSectionList = subSection => {
    const { optionsBySubSection } = this.props;
    const { id } = subSection;
    const subSectionOptions = optionsBySubSection[id];
    const { selectedOptions } = this.state;
    return (
      <MasterSlaveList
        key={`sub-section-${subSection.id}`}
        onChange={this.handleChange}
        render={this.renderSubSection(subSection)}
        options={subSectionOptions}
        selectedOptions={selectedOptions}
      />
    );
  };

  renderSectionList = section => {
    const { optionsBySection } = this.props;
    const { id } = section;
    const sectionOptions = optionsBySection[id];
    const { selectedOptions } = this.state;
    return (
      <MasterSlaveList
        key={`section-${id}`}
        onChange={this.handleChange}
        render={this.renderSection(section)}
        options={sectionOptions}
        selectedOptions={selectedOptions}
      />
    );
  };

  getSelectedTotal = () => {
    const { subLineItems } = this.props;
    const { selectedOptions } = this.state;
    return subLineItems.reduce(
      (sum, subLineItem) =>
        selectedOptions.includes(subLineItem.line_item_id)
          ? sum + subLineItem.total
          : sum,
      0
    );
  };

  renderRoomBook = ({ masterProps: { disabled, ...masterProps } }) => {
    const {
      roomBook: { sections }
    } = this.props;
    const { selectedOptions, loading, lineItemsCount } = this.state;
    return (
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                disabled={loading || disabled}
                {...masterProps}
                data-action="select_all"
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan="4">
              <FormattedMessage id="meta.actions.select_all" />
              &nbsp;(
              <FormattedMessage
                id="meta.states.selectedFromTotal"
                values={{
                  selected: selectedOptions.length,
                  total: lineItemsCount
                }}
              />
              )
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormatCurrency amount={this.getSelectedTotal()} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{sections.map(this.renderSectionList)}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="5">
              <FormattedMessageWithFlag
                featureToggleName="show_price_net_label"
                id="roomBook.roomBookTotal.net"
                alternativeId="roomBook.roomBookTotal.gross"
              />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <FormatCurrency amount={this.getSelectedTotal()} />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  };

  renderInputs = ({ label: { i18nId }, name }) => {
    const {
      loading,
      formValues: { [name]: value }
    } = this.state;

    return (
      <Form.Input
        label={<FormattedMessage id={i18nId} />}
        key={name}
        value={value}
        name={name}
        onChange={this.handleInputChange}
        disabled={loading}
      />
    );
  };

  render() {
    const { processDefinition, open, onClose, options } = this.props;
    const { selectedOptions } = this.state;
    return (
      <Modal
        open={open}
        data-component="cartCreationDialog"
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>
          <FormattedMessage
            id="activity.actions.add_with_name"
            values={{ name: processDefinition.getName() }}
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form>
            {processDefinition.getStartInputs().map(this.renderInputs)}
          </Form>

          <div
            ref={this.refPriceMessage}
            style={{ display: "none", marginTop: "1em" }}
          >
            <Message warning>
              <FormattedMessage
                id="activity.message.priceOnRequest.body"
                default="Es gibt Produkte mit Preisen auf Anfrage."
              />
            </Message>
          </div>
          <MasterSlaveList
            onChange={this.handleChange}
            render={this.renderRoomBook}
            options={options}
            selectedOptions={selectedOptions}
          />
        </Modal.Content>
        <Modal.Actions actions={this.getActions()} />
      </Modal>
    );
  }
}

const optionShape = shape({
  sectionId: number,
  subSectionId: number,
  id: number,
  lineItem: instanceOf(LineItemModel),
  disabled: bool,
  preselected: bool
});

CartCreationDialog.propTypes = {
  processDefinition: instanceOf(ProcessDefinition).isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
  getAction: func.isRequired,
  router: routerShape.isRequired,
  roomBookId: oneOfType([number, string]).isRequired,
  roomBook: shape({
    sections: arrayOf(
      shape({
        display_number: string,
        title: string,
        sections: arrayOf(
          shape({
            display_number: string,
            title: string,
            line_items: arrayOf(
              shape({
                id: number,
                display_number: string,
                title: string
              })
            )
          })
        )
      })
    )
  }).isRequired,
  lineItemsCount: number.isRequired,
  roomBookCartResource: instanceOf(RoomBookCartResource).isRequired,
  batchCartAssignmentResource: instanceOf(BatchCartAssignmentResource)
    .isRequired,
  subLineItems: arrayOf(SubLineItemShape).isRequired,
  provokingLineItem: instanceOf(LineItemModel).isRequired,
  products: ProductShape.isRequired,
  options: arrayOf(optionShape).isRequired,
  optionsBySection: objectOf(arrayOf(optionShape)).isRequired,
  optionsBySubSection: objectOf(arrayOf(optionShape)).isRequired,
  preSelectedIds: arrayOf(number).isRequired,
  preSelectedSectionIds: arrayOf(number).isRequired,
  preSelectedSubSectionIds: arrayOf(number).isRequired,
  unit: UnitShape.isRequired,
  project: ProjectShape.isRequired,
  i18n: I18nShape.isRequired,
  productsRepository: shape({
    getGroupProducts: func
  }).isRequired
};

const getProcessDefinition = (_state, props) => props.processDefinition;
const getProvokingLineItem = (state, props) => {
  return props.provokingLineItem;
};
const getOptions = createSelector(
  [getRoomBook, getProcessDefinition, getProvokingLineItem],
  (roomBook, processDefinition, provokingLineItem) =>
    roomBook.sections.reduce(
      (sectionAccu, { id: sectionId, sections: subSections }) =>
        subSections.reduce(
          (subSectionAccu, { id: subSectionId, line_items: lineItems }) =>
            lineItems.reduce((lineItemAccu, lineItemData) => {
              const lineItem = new LineItemModel(lineItemData);
              const id = lineItem.getId();
              const tryToSelectProvokingLineItem =
                provokingLineItem.isAssignableToProcess(processDefinition) &&
                id === provokingLineItem.getId();
              return [
                ...lineItemAccu,
                {
                  sectionId,
                  subSectionId,
                  id,
                  lineItem,
                  disabled: !lineItem.isAssignableToProcess(processDefinition),
                  preselected:
                    processDefinition.shouldPreselect(lineItem) ||
                    tryToSelectProvokingLineItem
                }
              ];
            }, subSectionAccu),
          sectionAccu
        ),
      []
    )
);

const getOptionsBySection = createSelector([getOptions], options =>
  groupBy(options, "sectionId")
);
const getOptionsBySubSection = createSelector([getOptions], options =>
  groupBy(options, "subSectionId")
);

const getPreSelectedIds = createSelector([getOptions], options =>
  options
    .filter(option => option.preselected)
    .map(option => option.lineItem.getId())
);

const getPreSelectedSectionIds = createSelector([getOptions], options => {
  return options
    .filter(option => option.preselected)
    .map(option => option.sectionId);
});

const getPreSelectedSubSectionIds = createSelector([getOptions], options => {
  return options
    .filter(option => option.preselected)
    .map(option => option.subSectionId);
});

const mapStateToProps = createStructuredSelector({
  unit: getUnit,
  roomBook: getRoomBook,
  lineItemsCount: getActivatedLineItemsCount,
  subLineItems: getSubLineItems,
  products: getProductsById,
  options: getOptions,
  optionsBySection: getOptionsBySection,
  optionsBySubSection: getOptionsBySubSection,
  preSelectedIds: getPreSelectedIds,
  preSelectedSectionIds: getPreSelectedSectionIds,
  preSelectedSubSectionIds: getPreSelectedSubSectionIds,
  project: getProject,
  i18n: getI18N,
  productsRepository: getProductRepository
});

const mapDispatchToProps = (dispatch, props) => {
  const { roomBookId } = props;

  return {
    roomBookCartResource: new RoomBookCartResource(dispatch, roomBookId),
    batchCartAssignmentResource: new BatchCartAssignmentResource(
      dispatch,
      roomBookId
    ),
    getAction: actionFactory(dispatch)
  };
};

const CartCreationDialogContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartCreationDialog)
);

CartCreationDialogContainer.propTypes = {
  roomBookId: oneOfType([number, string]).isRequired
};

export default CartCreationDialogContainer;
