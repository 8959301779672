import { string, number, shape, arrayOf } from "prop-types";

export const ConfiguratorDesignShape = shape({
  id: number,
  project_images: arrayOf(
    shape({
      id: number,
      url: string
    })
  )
});

export default ConfiguratorDesignShape;
