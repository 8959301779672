import {
  bool,
  shape,
  number,
  string,
  oneOfType,
  object,
  array,
  objectOf
} from "prop-types";

const ProjectWithContentShape = shape({
  id: oneOfType([string, number]),
  name: string,
  content: shape({
    styles: string,
    additional_html: string,
    configurators: oneOfType([string, object]),
    glencoeURL: string,
    product_mapping: oneOfType([string, object]),
    product_lines: oneOfType([string, array]),
    sections: oneOfType([string, array]),
    logo_medium_url: string,
    logo_large_url: string,
    banner_image_url: string,
    section_combos: oneOfType([string, array])
  }),
  features: objectOf(bool)
});

export default ProjectWithContentShape;
