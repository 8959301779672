import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Icon, List, Popup } from "semantic-ui-react";
import { get, map, omit } from "lodash";

import "./descendantsInfo.scss";

export default class DescendantsSelector extends React.PureComponent {
  static propTypes = {
    roomBookStructure: PropTypes.object.isRequired,
    derivedInfos: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    root: PropTypes.bool
  };

  static defaultProps = {
    root: true
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  getDerivedInfo() {
    const {
      roomBookStructure: { id },
      derivedInfos = {}
    } = this.props;
    return derivedInfos[id] || {};
  }

  render() {
    const {
      roomBookStructure: { template, name, id, derived = [] },
      root
    } = this.props;
    const { open } = this.state;

    const dropdownIcon =
      !root && derived.length > 0 ? (
        <Icon
          name="dropdown"
          rotated={open ? null : "counterclockwise"}
          onClick={event => {
            event.stopPropagation();
            this.setState({ open: !open });
          }}
        />
      ) : null;
    const checkboxId = `roomBook-${id}`;
    const item = root ? (
      <div>
        {getIcon(template)}
        {dropdownIcon}
        {name}
      </div>
    ) : (
      <Checkbox
        id={checkboxId}
        data-model="descendantsInfo"
        label={
          <label htmlFor={checkboxId}>
            {getIcon(template)}
            {dropdownIcon}
            {name}
            {this.renderStatusInfo()}
          </label>
        }
        onChange={this.handleSelect}
        checked={this.isChecked()}
        disabled={this.isDisabled()}
      />
    );

    return (
      <div className="descendantsInfo">
        {item}
        {open && derived.length > 0 ? this.renderChildren() : null}
      </div>
    );
  }

  renderStatusInfo() {
    const { status } = this.getDerivedInfo();
    if (!status || status === "new") return null;

    return (
      <span>
        {" "}
        (<span data-attr="status">{status}</span>)
      </span>
    );
  }

  isItemDisabled = id => {
    return (
      ["new", "deleted"].indexOf(get(this.props.derivedInfos, [id, "status"])) <
      0
    );
  };

  isItemChecked = id => {
    return !!this.getChildSelection()[id];
  };

  isChecked = () => {
    const {
      roomBookStructure: { id },
      selection
    } = this.props;
    return !!selection[id];
  };

  isDisabled = () => {
    return ["new", "deleted"].indexOf(this.getDerivedInfo().status) < 0;
  };

  handleSelect = (event, { checked }) => {
    const {
      roomBookStructure: { id },
      selection,
      onChange
    } = this.props;
    if (checked) {
      onChange({
        selection: {
          ...selection,
          [id]: this.createSelectionForRoomBookId(id)
        }
      });
    } else {
      onChange({ selection: omit(selection, id) });
    }
  };

  handleChildChange = ({ selection: childSelection }) => {
    const {
      roomBookStructure: { id },
      selection,
      onChange
    } = this.props;
    const { status } = this.getDerivedInfo();
    if (status) {
      onChange({
        selection: {
          ...selection,
          [id]: {
            ...this.createSelectionForRoomBookId(id),
            derived: childSelection
          }
        }
      });
    } else {
      onChange({ selection: childSelection });
    }
  };

  getHandleMasterChange = template => (event, { checked }) => {
    const old_child_selection = this.getChildSelection();
    const roomBookIds = map(this.getRoomBooks(template), "id");

    let new_child_selection = null;

    if (checked) {
      new_child_selection = { ...old_child_selection };
      roomBookIds.forEach(id => {
        if (!this.isItemDisabled(id) && !new_child_selection[id]) {
          new_child_selection[id] = this.createSelectionForRoomBookId(id);
        }
      });
    } else {
      new_child_selection = omit(old_child_selection, roomBookIds);
    }
    this.handleChildChange({ selection: new_child_selection });
  };

  createSelectionForRoomBookId(id) {
    const { derivedInfos } = this.props;
    const info = derivedInfos[id];
    const { parentId, sourceId, itemId } = info;

    if (info.status === "new") {
      return {
        parentId,
        sourceId,
        operation: "create"
      };
    }
    if (info.status === "deleted") {
      return {
        parentId,
        sourceId,
        itemId,
        operation: "restore"
      };
    }
    return {
      parentId
    };
  }

  renderChildren() {
    return (
      <div className="masterSelectors">
        {this.renderItems(true)}
        {this.renderItems(false)}
      </div>
    );
  }

  renderItems(template) {
    const {
      roomBookStructure: { id }
    } = this.props;
    const roomBooks = this.getRoomBooks(template);
    if (roomBooks.length <= 0) {
      return null;
    }

    let allChecked = true;
    let allUnChecked = true;
    let allDisabled = true;
    roomBooks.forEach(item => {
      if (!this.isItemDisabled(item.id)) {
        allDisabled = false;
        if (this.isItemChecked(item.id)) {
          allUnChecked = false;
        } else {
          allChecked = false;
        }
      }
    });
    const checkboxId = `master-${id}-${template ? "templates" : "roomBooks"}`;
    return (
      <div>
        <Checkbox
          id={checkboxId}
          label={
            <label htmlFor={checkboxId}>
              <Popup trigger={getIcon(template)} content={getLabel(template)} />
            </label>
          }
          checked={allChecked && !allDisabled}
          disabled={allDisabled}
          indeterminate={!allChecked && !allUnChecked}
          onChange={this.getHandleMasterChange(template)}
        />
        <List items={roomBooks.map(this.infoToItem)} />
      </div>
    );
  }

  infoToItem = info => {
    const { derivedInfos } = this.props;
    return (
      <List.Item key={info.id}>
        <DescendantsSelector
          roomBookStructure={info}
          derivedInfos={derivedInfos}
          selection={this.getChildSelection()}
          onChange={this.handleChildChange}
          root={false}
        />
      </List.Item>
    );
  };

  getChildSelection() {
    const {
      roomBookStructure: { id },
      selection
    } = this.props;
    const { status } = this.getDerivedInfo();
    if (status) {
      return get(selection, [id, "derived"], {});
    }
    return selection;
  }

  getRoomBooks(template) {
    const {
      roomBookStructure: { derived = [] }
    } = this.props;
    return derived
      .filter(item => item.template === template)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}

const getIcon = template => <Icon name={template ? "book" : "cube"} />;

function getLabel(template) {
  return template ? "Vorlagen" : "Raumbücher";
}
