import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { ProjectDataExportsResource } from "builder_portal/actions/projectDataExportsActions";
import { getProjectsSorted, getAccount } from "shared/selectors";
import { Button, Loader, Segment, Table } from "semantic-ui-react";
import { getProjectDataExports } from "shared/selectors/projectDataExports";
import StickyMenu from "shared/components/menus/StickyMenu";
import { If } from "shared/components/elements/Conditions";
import { keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import useInterval from "builder_portal/hooks/useInterval";

const propName = id => `project${id}`;

const ProjectDataExport = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjectsSorted);
  const account = useSelector(getAccount);
  const projectDataExports = useSelector(getProjectDataExports);

  const [project, setProject] = useState(null);
  const [requestSent, setRequestSent] = useState({
    [propName(project?.id)]: false
  });
  const [isLoading, setLoading] = useState(false);
  const [lastId, setLastId] = useState();

  useEffect(() => {
    if (projects?.length && !project) setProject(projects[0]);
  }, [projects]);

  const projectChangeHandler = item => {
    setProject(item);
  };

  useEffect(() => {
    new ProjectsResource(dispatch).fetchAll();
  }, []);

  useEffect(() => {
    if (project?.id && project.id !== projectDataExports[0]?.project_id) {
      setLoading(true);
      new ProjectDataExportsResource(dispatch, project.id)
        .fetchAll()
        .then(() => setLoading(false));
    }
  }, [project]);

  useInterval(() => {
    if (requestSent[propName(project?.id)])
      new ProjectDataExportsResource(dispatch, project.id).fetchAll();
  }, 5000);

  useEffect(() => {
    if (
      projectDataExports[0]?.id !== lastId &&
      (projectDataExports[0]?.status === "completed" ||
        projectDataExports[0]?.status === "failed")
    ) {
      setRequestSent({ ...requestSent, [propName(project?.id)]: false });
    }
  }, [projectDataExports]);

  const handleStartExport = () => {
    new ProjectDataExportsResource(dispatch, project.id).save({});
    setRequestSent({ ...requestSent, [propName(project?.id)]: true });
    setLastId(projectDataExports[0]?.id);
  };

  return (
    <>
      <StickyMenu
        stickyItem={null}
        allItems={keyBy(projects, "id")}
        accountId={account?.data?.account?.id}
        onSelectedItemChange={projectChangeHandler}
        storeKey="projectDataExport"
      />
      <Segment.Group>
        <Segment data-component="project-settings">
          <If condition={isLoading}>
            <Loader active />
          </If>
          <If condition={!isLoading}>
            <If condition={!!project}>
              <If condition={!requestSent[propName(project?.id)]}>
                <Button color="green" onClick={handleStartExport}>
                  <FormattedMessage
                    id="dataExport.button.label"
                    default="Start export"
                  />
                </Button>
              </If>
              <If condition={requestSent[propName(project?.id)]}>
                <FormattedMessage
                  id="dataExport.startMessage"
                  default="Der Export wurde gestartet"
                />
                <If condition={requestSent[propName(project?.id)]}>
                  <Loader active />
                </If>
              </If>
            </If>
          </If>
        </Segment>
        <Table attached striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage
                  id="projectDataExport.id"
                  defaultMessage="ID"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="projectDataExport.createdAt"
                  defaultMessage="Created at"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="projectDataExport.status"
                  defaultMessage="Status"
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projectDataExports.length > 0 ? (
              projectDataExports.map(dataExport => (
                <Table.Row key={dataExport.id}>
                  <Table.Cell>{dataExport.id}</Table.Cell>
                  <Table.Cell>
                    {moment(dataExport.created_at).format("LLL")}
                  </Table.Cell>
                  <Table.Cell>{dataExport.status}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <If condition={dataExport.status === "completed"}>
                      <a
                        href={dataExport.file_url}
                        target="_export"
                        className="link"
                      >
                        Download
                      </a>
                    </If>
                    <If condition={dataExport.status !== "completed"}>-</If>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <FormattedMessage
                    id="projectDataExport.noExports"
                    defaultMessage="No data exports created yet"
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment.Group>
    </>
  );
};

export default ProjectDataExport;
