import React from "react";
import PropTypes from "prop-types";
import { get, keyBy } from "lodash";
import { Grid, Header, Table, Dropdown } from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { FormattedMessage } from "react-intl";
import Empty from "./Empty";

import connectStatistic from "./statisticsContainer";

const CONFIGS = {
  net: {
    defaultMetric: "net_total_profit",
    metrics: {
      net_selected_revenue: "unit.statistics.netSelectedRevenueByTrade.title",
      net_selected_costs: "unit.statistics.netSelectedCostsByTrade.title",
      net_total_revenue: "unit.statistics.netTotalRevenueByTrade.title",
      net_total_costs: "unit.statistics.netTotalCostsByTrade.title",
      net_total_profit: "unit.statistics.netTotalProfitByTrade.title"
    }
  },
  gross: {
    defaultMetric: "gross_total_profit",
    metrics: {
      gross_selected_revenue:
        "unit.statistics.grossSelectedRevenueByTrade.title",
      gross_selected_costs: "unit.statistics.grossSelectedCostsByTrade.title",
      gross_total_revenue: "unit.statistics.grossTotalRevenueByTrade.title",
      gross_total_costs: "unit.statistics.grossTotalCostsByTrade.title",
      gross_total_profit: "unit.statistics.grossTotalProfitByTrade.title"
    }
  }
};

class AmountsByTrade extends React.Component {
  constructor(props) {
    super(props);

    this.handleMetricChange = this.handleMetricChange.bind(this);

    this.state = {};
  }

  handleMetricChange(proxy, data) {
    this.setState({ ...this.state, metric: data.value });
  }

  render() {
    const { statistics } = this.props;
    const byTrade = statistics.by_trade;

    if (byTrade && byTrade.length > 0) {
      return this.renderStatistics();
    }
    return <Empty />;
  }

  renderStatistics() {
    const {
      statistics,
      processDefinition,
      account,
      i18n,
      accountingMode
    } = this.props;
    const config = CONFIGS[accountingMode];

    const labels = config.metrics;

    const metric = get(this.state, "metric", config.defaultMetric);
    const processDictionary = keyBy(processDefinition.getPhases(true), "id");
    const tradesDictionary = keyBy(account.getTrades(true), "id");
    const trades = Object.keys(keyBy(statistics.by_trade, "trade"));

    const tradeStatisticTable = {};

    trades.forEach(trade => {
      const columns = {};
      statistics.by_trade.forEach(item => {
        if (item.trade === trade) {
          columns[item.phase] = item[metric] || 0;
        }
      });
      tradeStatisticTable[trade] = columns;
    });

    const phases = Object.keys(processDictionary);
    phases.shift();

    const totalByPhase = phases.reduce((sums, phase) => {
      sums[phase] = trades.reduce((sum, trade) => {
        return sum + (tradeStatisticTable[trade][phase] || 0);
      }, 0);
      return sums;
    }, {});

    const metrics = labels;
    const metricOptions = Object.keys(metrics).map(m => {
      return {
        key: m,
        value: m,
        text: i18n[metrics[m]]
      };
    });

    return (
      <div>
        <Table definition stackable fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Header>
                  <FormattedMessage
                    id={labels[metric]}
                    default={labels[metric]}
                  />
                </Header>
              </Table.HeaderCell>
              {phases.map(phase => (
                <Table.HeaderCell
                  key={phase}
                  style={{
                    backgroundColor: get(processDictionary[phase], "color")
                  }}
                >
                  {get(processDictionary[phase], "label")}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(tradeStatisticTable).map(trade => {
              const phaseColumns = tradeStatisticTable[trade];
              const labelColumn = trade;
              return (
                <Table.Row key={labelColumn}>
                  <Table.Cell>
                    {get(
                      tradesDictionary[labelColumn.toUpperCase()],
                      "label",
                      labelColumn.toUpperCase()
                    )}
                  </Table.Cell>
                  {phases.map(phase => {
                    return (
                      <Table.Cell textAlign="right" key={phase}>
                        <FormatCurrency amount={phaseColumns[phase] || 0} />
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell
                style={{ borderTop: "1px solid rgba(34, 36, 38, 0.15)" }}
              >
                Gesamt
              </Table.Cell>
              {phases.map(phase => {
                return (
                  <Table.HeaderCell textAlign="right" key={phase}>
                    <FormatCurrency amount={totalByPhase[phase]} />
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Footer>
        </Table>
        <Grid>
          <Grid.Column width={5}>
            <Dropdown
              placeholder="Metrik"
              value={metric}
              fluid
              selection
              options={metricOptions}
              onChange={this.handleMetricChange}
              pointing="bottom"
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

AmountsByTrade.propTypes = {
  i18n: PropTypes.object.isRequired,
  statistics: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  accountingMode: PropTypes.oneOf(["net", "gross"]),
  processDefinition: PropTypes.object.isRequired
};

AmountsByTrade.defaultProps = {
  accountingMode: "net"
};

export default connectStatistic(AmountsByTrade);
