import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Doughnut } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";
import { Statistic, Header, Icon } from "semantic-ui-react";
import "./statistics.scss";
import { get, keyBy } from "lodash";
import { getAccount, getLineItemLifeCycle } from "shared/selectors";
import ProfitByPhase from "../analytics/ProfitByPhase";

class Statistics extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    project: PropTypes.object,
    displayType: PropTypes.string,
    processDefinition: PropTypes.object,
    statistics: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  renderCharts() {
    const {
      projectStatistics,
      activityStatistics,
      processDefinition
    } = this.props;
    if (!projectStatistics || !processDefinition) {
      return null;
    }

    const doughnutOptions = {
      legend: {
        display: false
      },
      animation: {
        duration: 2000
      }
    };

    const phases = [
      {
        id: "overdue",
        label: "Überfällig",
        color: "#bb3131"
      },
      {
        id: "close",
        label: "Bald Fällig",
        color: "#F2711C"
      },
      {
        id: "ontime",
        label: "In Bearbeitung",
        color: "teal"
      },
      {
        id: "completed",
        label: "Erledigt",
        color: "#95c11f"
      }
    ];

    const doughnutChartData = {
      labels: phases.map(phase => phase.label),
      datasets: [
        {
          data: phases.map(phase =>
            get(activityStatistics, `${phase.id}.total`, 0)
          ),
          backgroundColor: phases.map(phase => phase.color)
        }
      ]
    };

    return (
      <div data-component="projectCharts">
        <div className="column">
          <Doughnut data={doughnutChartData} options={doughnutOptions} />
          <Header sub>
            <FormattedMessage
              id="project.statistics.activity"
              defaultMessage="project.statistics.activity"
            />
          </Header>
        </div>
        <div className="column">
          <ProfitByPhase
            statistics={keyBy(projectStatistics.by_phase, "phase")}
          />
          <Header sub>
            <FormattedMessage
              id="project.statistics.profit"
              defaultMessage="project.statistics.profit"
            />
          </Header>
        </div>
      </div>
    );
  }

  renderStatistics() {
    const { project } = this.props;
    const activityStats = get(project, "statistics.activities");
    const activityCount = get(activityStats, "count", 0);
    const unitCount = get(project, "statistics.units.count", 0);

    return (
      <Statistic.Group widths="2" size="mini">
        <Statistic>
          <Statistic.Value>
            <Icon name="wrench" />
            &nbsp;{activityCount}
          </Statistic.Value>
          <Statistic.Label>
            <FormattedMessage
              id="activity.title.other"
              defaultMessage="activity.title.other"
            />
          </Statistic.Label>
        </Statistic>
        <Statistic>
          <Statistic.Value>
            <Icon name="cube" />
            &nbsp;{unitCount}
          </Statistic.Value>
          <Statistic.Label>
            <FormattedMessage
              id="unit.title.other"
              defaultMessage="unit.title.other"
            />
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
    );
  }

  render() {
    const { displayType, project, projectStatistics } = this.props;
    if (project && projectStatistics) {
      return (
        <div data-component="projectStatistics">
          {displayType === "numbers" && this.renderStatistics()}
          {displayType === "charts" && this.renderCharts()}
        </div>
      );
    }
    return <span />;
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  account: getAccount(state),
  processDefinition: getLineItemLifeCycle(state),
  project: get(state, "pageContent.project"),
  activityStatistics: get(state, "pageContent.activityStatistics"),
  projectStatistics: get(state, "pageContent.projectStatistics")
});

export default connect(mapStateToProps)(Statistics);
