import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { string } from "prop-types";
import { Header, Grid, Button, Table, Loader, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { If } from "shared/components/elements/Conditions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ConfigurationGroupDialog from "./ConfigurationGroupDialog";
import ConfigurationGroupsResource from "../../../actions/configurationGroupsActions";
import { getConfigurationGroups } from "../../../selectors/configurationGroups";

const ConfigurationGroupsList = ({ projectId }) => {
  const configurationGroups = useSelector(getConfigurationGroups);
  const intl = useIntl();
  const dispatch = useDispatch();
  const configurationGroupsResource = new ConfigurationGroupsResource(
    dispatch,
    projectId
  );

  const [isLoading, setLoading] = useState(false);

  const loadData = () => {
    setLoading(true);
    configurationGroupsResource
      .fetchAll()
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        new Growl(dispatch).error(
          "message.errorBackend.title",
          "message.errorBackend.body",
          {
            bodyValues: { translatedBody: err }
          }
        );
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleTypeDelete = (id, handleClose) => {
    configurationGroupsResource
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.configurationGroups.success.title",
          "roomBook.configurationGroups.success.message"
        );
        configurationGroupsResource.fetchAll();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderDeleteButton = id => {
    const button = (
      <Button
        id="configuration_group_delete"
        icon="trash"
        basic
        loading={isLoading}
        disabled={isLoading}
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => handleTypeDelete(id, handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.configurationGroups.removeConfirmation.title"
        message="roomBook.configurationGroups.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleMove = (item, direction) => {
    if (isLoading) return;
    const position = direction === "up" ? item.position - 1 : item.position + 1;
    configurationGroupsResource
      .save({
        ...item,
        position
      })
      .then(() => {
        configurationGroupsResource.fetchAll();
      });
  };

  const renderConfigurationGroup = item => {
    return (
      <Table.Row key={item.id} id={`configuration-group-${item.id}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.title}</Table.Cell>
        <Table.Cell>{item.description}</Table.Cell>
        <Table.Cell>{item.summary}</Table.Cell>
        <Table.Cell textAlign="right">
          <VersionHistoryLink
            id={item.id}
            type="ConfigurationGroup"
            style={{
              fontSize: "1em"
            }}
          />
          <If condition={item.position > 1}>
            <Icon
              style={{ cursor: "pointer" }}
              name="arrow up"
              color="grey"
              onClick={() => handleMove(item, "up")}
            />
          </If>
          <If condition={item.position < configurationGroups.length}>
            <Icon
              style={{ cursor: "pointer" }}
              name="arrow down"
              color="grey"
              onClick={() => handleMove(item, "down")}
            />
          </If>
          <ConfigurationGroupDialog
            model={item}
            projectId={projectId}
            button={
              <Button
                id="configuration_group_edit"
                icon="setting"
                basic
                loading={isLoading}
                disabled={isLoading}
                data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
              />
            }
            configurationGroupsResource={configurationGroupsResource}
          />
          {renderDeleteButton(item.id)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderConfigurationGroups = () => {
    if (isLoading) {
      return <Loader active />;
    }
    if (!configurationGroups.length && !isLoading) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div className="notFound">
                <FormattedMessage
                  id="roomBook.configurationGroups.notFound"
                  defaultMessage="Es liegen keine Konfigurationsgruppen zu diesem Projekt vor"
                />
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }

    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.configurationGroups.types.name"
                defaultMessage="Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.configurationGroups.types.title"
                defaultMessage="Title"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.configurationGroups.types.description"
                defaultMessage="Description"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.configurationGroups.types.summary"
                defaultMessage="Summary"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="roomBook.configurationGroups.types.action"
                defaultMessage="Action"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {configurationGroups.map(renderConfigurationGroup)}
        </Table.Body>
      </>
    );
  };

  return (
    <>
      <Grid
        stackable
        data-component="upgrade_bundles"
        className="margin bottom medium"
      >
        <Grid.Column width="8">
          <Header size="large">
            <FormattedMessage id="roomBook.configurationGroups.types.title" />
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width="8">
          <ConfigurationGroupDialog
            projectId={projectId}
            button={
              <Button
                id="create_configuration_group"
                disabled={isLoading}
                className="done"
                color="green"
              >
                <Icon name="plus" />{" "}
                {intl.formatMessage({ id: "meta.actions.add" })}
              </Button>
            }
          />
        </Grid.Column>
      </Grid>
      <Table data-component="configuration_group_items">
        {renderConfigurationGroups()}
      </Table>
    </>
  );
};

ConfigurationGroupsList.propTypes = {
  projectId: string.isRequired
};

export default ConfigurationGroupsList;
