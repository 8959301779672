import React from "react";
import { shape, string, number, func } from "prop-types";
import { Dropdown, Input, Checkbox, Form } from "semantic-ui-react";
import { templateOptions } from "./constants";

const SignatureElementForm = ({ element, onChange }) => {
  const handleChange = event => {
    const {
      target: { name, value }
    } = event;

    onChange({ ...element, [name]: value });
  };

  const handleCheckboxChange = (_, val) => {
    const { name, checked: value } = val;

    onChange({ ...element, [name]: value });
  };

  return (
    <>
      <Form.Field>
        <Dropdown
          button
          style={{ margin: "5px 0" }}
          options={templateOptions}
          value={element?.template}
          onChange={(_, { value }) => onChange({ ...element, template: value })}
        />
      </Form.Field>
      {Object.keys(element)
        .filter(key => key.indexOf("variable_") > -1)
        .map(key => (
          <Form.Field key={key}>
            <label htmlFor={key}>{key}</label>
            <Input
              name={key}
              value={element[key]}
              fluid
              onChange={handleChange}
            />
          </Form.Field>
        ))}
      <Form.Field>
        <label htmlFor="new_line">New line</label>
        <Input
          name="new_line"
          type="number"
          value={element.new_line}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          name="current_date"
          label="Show current date"
          checked={!!element.current_date}
          onChange={handleCheckboxChange}
        />
      </Form.Field>
    </>
  );
};

SignatureElementForm.propTypes = {
  element: shape({
    id: string,
    template: number
  }).isRequired,
  onChange: func.isRequired
};

export default SignatureElementForm;
