import React from "react";
import proptypes from "prop-types";

import { useDispatch } from "react-redux";
import { ProjectImageLibraryResource } from "../../../builder_portal/actions/projectRoleImagesActions";

const ProjectImagesLoader = ({ projectId, children }) => {
  const dispatch = useDispatch();
  const projectsResource = new ProjectImageLibraryResource(dispatch, projectId);

  React.useEffect(() => {
    projectsResource.fetchAll(projectId);
  }, [projectId]);

  return children;
};

ProjectImagesLoader.propTypes = {
  projectId: proptypes.number.isRequired,
  children: proptypes.node.isRequired
};

export default ProjectImagesLoader;
