import { createSelector } from "reselect";
import {
  get,
  sortBy,
  keyBy,
  flatMap,
  uniq,
  intersection,
  max,
  countBy,
  groupBy
} from "lodash";
import { getProjectBranding } from "../project";
import { getBuyerSelections, getFlatRoomBook } from "./base";
import { getProductsById, getProductGroupsById } from "./productData";

const getTaxonomies = state => {
  return get(state, ["account", "account", "taxonomies"], []);
};

const getTags = createSelector([getTaxonomies], taxonomies => {
  return flatMap(taxonomies, taxonomy => taxonomy.tags);
});

const getTagsById = createSelector([getTags], tags => {
  return keyBy(tags, "composite_id");
});

export const getProductLineGroups = createSelector(
  [getProjectBranding],
  branding => {
    return keyBy(get(branding, ["productLines"], []), "id");
  }
);

const getProductLineTags = createSelector(
  [getProductLineGroups],
  productLineGroups => {
    return uniq(
      Object.values(productLineGroups).reduce((accu, group) => {
        return accu.concat(group.tags);
      }, [])
    );
  }
);

export const getHasProductGroupTags = createSelector(
  [getProductsById, getProductGroupsById, getProductLineGroups],
  (productsById, productGroupsById, productLineGroups) => {
    return (productGroupId, productLineGroupId) => {
      const productLineTags = productLineGroups[productLineGroupId].tags;
      const productGroup = productGroupsById[productGroupId];

      return productGroup.prices.some(price => {
        const product = productsById[price.product_id];
        return (
          intersection(
            product.product_tags.concat(price.product_tags),
            productLineTags
          ).length > 0
        );
      });
    };
  }
);

const getSelectedProductTagsWithAssociatedSubLineItem = createSelector(
  [getBuyerSelections, getFlatRoomBook, getProductsById, getProductGroupsById],
  (buyerSelections, flatRoomBook, productsById, productGroupsById) => {
    const subLineItemsById = keyBy(flatRoomBook.subLineItems, "id");
    return Object.keys(buyerSelections)
      .map(sliId => {
        const subLineItem = subLineItemsById[sliId];
        if (subLineItem) {
          const productGroup = productGroupsById[subLineItem.product_group_id];
          const productId = buyerSelections[sliId].product_id;
          const product = productsById[productId];

          if (product && productGroup) {
            const productPrice = productGroup.prices.find(
              price => price.product_id === product.id
            );
            if (productPrice) {
              return {
                subLineItem,
                product_tags: product.product_tags.concat(
                  productPrice.product_tags
                )
              };
            }
            return {
              subLineItem,
              product_tags: product.product_tags
            };
          }
        }
        return {
          subLineItem: undefined,
          product_tags: []
        };
      })
      .filter(group => group.product_tags.length);
  }
);

/*
 * Returns the tags associated with selected products.
 */
const getSelectedProductTags = createSelector(
  [getSelectedProductTagsWithAssociatedSubLineItem],
  selectedProductTagsWithAssociatedSubLineItem => {
    return selectedProductTagsWithAssociatedSubLineItem.map(
      ({ product_tags: productTags }) => productTags
    );
  }
);

const getCountedProductTags = createSelector(
  [getSelectedProductTags],
  selectedProductTags => {
    return countBy(flatMap(selectedProductTags));
  }
);

/* counts usages of the productTags and returns the most used one */
export const getActiveProductTag = createSelector(
  [getCountedProductTags],
  countedProductTags =>
    Object.entries(countedProductTags).reduce(
      (activeProductTag, [compositeId, currentCount]) => {
        return activeProductTag.currentCount >= currentCount
          ? activeProductTag
          : [compositeId, currentCount];
      },
      [null, 0]
    )[0] // only return the composite_id
);

const getSelectedTagGroupsForProductLineGroup = createSelector(
  [getSelectedProductTags, getProductLineGroups],
  (selectedTagGroups, productLineGroups) => {
    return productLineGroupId => {
      const currentRestriction = productLineGroups[productLineGroupId].tags;
      return selectedTagGroups
        .map(group => intersection(currentRestriction, group))
        .filter(group => group.length);
    };
  }
);

const getCountedTagsForProductLineGroup = createSelector(
  [getSelectedTagGroupsForProductLineGroup],
  getApplicableGroups => {
    return productLineGroupId => {
      const applicableGroups = getApplicableGroups(productLineGroupId);
      return countBy(flatMap(applicableGroups));
    };
  }
);

const getActiveProductLines = createSelector(
  [getSelectedTagGroupsForProductLineGroup, getProductLineGroups],
  (getApplicableGroups, productLineGroups) => {
    return Object.keys(productLineGroups).reduce((accu, id) => {
      const group = productLineGroups[id];
      const applicableGroups = getApplicableGroups(id);
      const selectedTags = flatMap(applicableGroups);
      const countedTags = countBy(selectedTags);
      const uniqSelectedTags = uniq(selectedTags);
      const maxTagCount = max(Object.values(countedTags));
      const commonTags = uniqSelectedTags.filter(t => {
        return countedTags[t] === maxTagCount;
      });

      accu[id] = {
        ...group,
        validTags: commonTags.length > 1 ? [] : commonTags,
        selectedTags: uniqSelectedTags,
        selection: uniq(flatMap(applicableGroups)).length > 0
      };
      return accu;
    }, {});
  }
);

export const getActivatedProductLines = createSelector(
  [
    getActiveProductLines,
    getProductLineGroups,
    getCountedTagsForProductLineGroup,
    getTagsById
  ],
  (selections, productLineGroups, countedTags, tagMapping) => {
    const ids = Object.keys(selections);
    return ids.map(id => {
      const countedProductTags = countedTags(id);
      const selectedTagData = selections[id].selectedTags.map(t => {
        return { id: t, count: countedProductTags[t], ...tagMapping[t] };
      });
      return {
        ...productLineGroups[id],
        ...selections[id],
        selectedTagData
      };
    });
  }
);

export const getProductLine = createSelector(
  [getActivatedProductLines],
  productLines => {
    return productLines[0];
  }
);

export const getActivatedProductLine = createSelector(
  [getActivatedProductLines],
  productLines => {
    return tags => {
      return productLines.find(pl => {
        return intersection(pl.selectedTags, tags).length > 0;
      });
    };
  }
);

export const isProductAcceptable = createSelector(
  [getActiveProductLines],
  activeProductLines => {
    return (subLineItem, option) => {
      if (!option.product_tags.length) {
        return true;
      }

      return Object.keys(activeProductLines).every(id => {
        const productLineInfo = activeProductLines[id];
        const isAffectedByProductLine =
          productLineInfo.mode === "warn" &&
          intersection(productLineInfo.tags, option.product_tags).length > 0;
        const matchesCurrentConstraints =
          intersection(productLineInfo.validTags, option.product_tags).length >
          0;

        return (
          !isAffectedByProductLine ||
          !productLineInfo.selection ||
          matchesCurrentConstraints
        );
      });
    };
  }
);

export const isProductRecommendable = createSelector(
  [getActiveProductLines],
  activeProductLines => {
    return (subLineItem, option) => {
      if (!option.product_tags.length) {
        return true;
      }

      return Object.keys(activeProductLines).every(id => {
        const productLineInfo = activeProductLines[id];
        const isAffectedByProductLine =
          productLineInfo.mode === "recommend" &&
          intersection(productLineInfo.tags, option.product_tags).length > 0;
        const matchesCurrentConstraints =
          intersection(productLineInfo.validTags, option.product_tags).length >
          0;
        return (
          !isAffectedByProductLine ||
          !productLineInfo.selection ||
          matchesCurrentConstraints
        );
      });
    };
  }
);

export const getProductLinesForTags = createSelector(
  [
    getProductLineTags,
    getProductLineGroups,
    getTagsById,
    getCountedProductTags,
    getHasProductGroupTags
  ],
  (
    productLineTags,
    productLineGroups,
    tagsById,
    countedTags,
    hasProductGroupTags
  ) => {
    return (productGroupId, tags) => {
      const relevantTags = intersection(tags, productLineTags);
      const relevantTagsByLineGroup = groupBy(relevantTags, tag => {
        return Object.keys(productLineGroups).find(id => {
          return productLineGroups[id].tags.includes(tag);
        });
      });

      return Object.keys(productLineGroups).reduce((accu, groupId) => {
        const applicableTags = relevantTagsByLineGroup[groupId] || [];
        const { exclusive, scope } = productLineGroups[groupId];
        if (applicableTags.length) {
          const sortedGroupTags = sortBy(productLineGroups[groupId].tags, t => {
            return -countedTags[t];
          });
          const totalSelection = productLineGroups[groupId].tags.reduce(
            (s, t) => {
              return s + (countedTags[t] || 0);
            },
            0
          );
          return accu.concat(
            sortBy(
              applicableTags.map(t => {
                return {
                  ...tagsById[t],
                  productLineGroup: groupId,
                  active: totalSelection === 0 || t === sortedGroupTags[0],
                  count: countedTags[t],
                  scope,
                  exclusive
                };
              }),
              t => -t.count
            )
          );
        }
        if (hasProductGroupTags(productGroupId, groupId)) {
          return accu.concat([
            {
              name: `Alle ${productLineGroups[groupId].name_plural}`,
              productLineGroup: groupId,
              active: true,
              count: 0,
              scope,
              exclusive
            }
          ]);
        }
        return accu;
      }, []);
    };
  }
);

export const getProductLineScopeForTags = createSelector(
  [getProductLinesForTags],
  productLinesForTags => {
    return (productGroupId, acceptedProductLineTags) => {
      return productLinesForTags(productGroupId, acceptedProductLineTags)[0]
        ?.scope; // TODO #1473 fix this later for more than one Product_line there is no use for it now... I suggest to fix it on a clean rewrite
    };
  }
);
