/* eslint-disable import/prefer-default-export */

export const UPDATE_LOGO_PATH = "UPDATE_LOGO_PATH";

export const UPDATE_LOCALE = "UPDATE_LOCALE";

export const UPDATE_I18N = "UPDATE_I18N";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_FLASH_ACTION = "UPDATE_FLASH_ACTION";
export const REMOVE_FLASH_ACTION = "REMOVE_FLASH_ACTION";

export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";

export const DELETE_PAGE_CONTENT = "DELETE_PAGE_CONTENT";
export const UPDATE_PAGE_CONTENT = "UPDATE_PAGE_CONTENT";
export const ADD_CONFIGURATOR_SELECTION = "ADD_CONFIGURATOR_SELECTION";
export const REMOVE_CONFIGURATOR_SELECTION = "REMOVE_CONFIGURATOR_SELECTION";
export const SET_CONFIGURATOR_SELECTION = "SET_CONFIGURATOR_SELECTION";
export const TOGGLE_CONFIGURATOR_SELECTION = "TOGGLE_CONFIGURATOR_SELECTION";
export const ADD_PAGE_CONTENT = "ADD_PAGE_CONTENT";
export const UPDATE_PAGE_CONTENT_MESSAGE_FORM =
  "UPDATE_PAGE_CONTENT_MESSAGE_FORM";
export const REMOVE_ID_FROM_PAGE_CONTENT = "REMOVE_ID_FROM_PAGE_CONTENT";
export const UPDATE_ROOM_BOOK = "UPDATE_ROOM_BOOK";
export const UPDATE_SUB_LINE_ITEM_SEARCH_RESULT =
  "UPDATE_SUB_LINE_ITEM_SEARCH_RESULT";

export const UPDATE_FILTER = "UPDATE_FILTER";

export const UPDATE_DIALOG = "UPDATE_DIALOG";

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";

export const UPDATE_CONTRACTOR_SELECTION = "UPDATE_CONTRACTOR_SELECTION";
export const RESET_CONTRACTOR_SELECTION = "RESET_CONTRACTOR_SELECTION";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
