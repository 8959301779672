import React from "react";
import { connect } from "react-redux";
import { If, Unless } from "shared/components/elements/Conditions";
import { getAccount } from "shared/selectors";

const mapStateToProps = state => ({
  account: getAccount(state)
});

export const IsSystemAdmin = connect(mapStateToProps)(props => (
  <If condition={props.account.isSystemAdmin()} styles={props.styles}>
    {props.children}
  </If>
));

export const IsNormalUser = connect(mapStateToProps)(props => (
  <Unless condition={props.account.isSystemAdmin()}>{props.children}</Unless>
));

export const IsAccountAdmin = connect(mapStateToProps)(props => (
  <If condition={props.account.isAdmin() || props.account.isSystemAdmin()}>
    {props.children}
  </If>
));
