import React, { useState, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form, Select } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { arrayOf, node } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { ProjectShape } from "shared/shapes";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";
import { getAccount } from "shared/selectors";
import { DocumentAssignmentsResource } from "../../../actions/documentsActions";

const initialFormValue = {
  source_project_id: null,
  target_project_id: null
};

const DuplicateDialog = ({ button, projects }) => {
  const dispatch = useDispatch();
  const [targetProjectsOptions, setTargetProjectsOption] = useState([]);

  const save = values => {
    return new DocumentAssignmentsResource(dispatch).duplicate(values);
  };

  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);

  const sourceProjectsOptions = useMemo(() => {
    return projects?.map(project => ({
      key: project.id,
      value: project.id,
      text: project.name
    }));
  }, [projects]);

  const validationScheme = Yup.object({
    source_project_id: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    target_project_id: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = values => {
    save(values)
      .then(() => setOpen(false))
      .catch(silentHandleApiRequestErrors);
  };

  const handleSourceProjectChange = (_, { value }) => {
    const otherProjects = projects.filter(project => project.id !== value);
    setTargetProjectsOption(
      otherProjects.map(project => ({
        key: project.id,
        value: project.id,
        text: project.name
      }))
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UpgradeBundleDialog"
    >
      <Modal.Header>
        <FormattedMessage id="documentsExport.dialogs.duplicate.title" />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Select
                  id="sourceProject"
                  errorPrompt
                  name="source_project_id"
                  options={sourceProjectsOptions}
                  label={intl.formatMessage({
                    id: "documentsExport.dialogs.duplicate.sourceProject"
                  })}
                  onChange={handleSourceProjectChange}
                />

                <Select
                  id="targetProject"
                  errorPrompt
                  name="target_project_id"
                  options={targetProjectsOptions}
                  label={intl.formatMessage({
                    id: "documentsExport.dialogs.duplicate.targetProject"
                  })}
                />
                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={intl.formatMessage({ id: "meta.actions.save" })}
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

DuplicateDialog.propTypes = {
  button: node.isRequired,
  projects: arrayOf(ProjectShape).isRequired
};

const mapStateToProps = state => {
  const account = getAccount(state);
  return {
    projects: account.getProjects()
  };
};

export default connect(mapStateToProps)(DuplicateDialog);
