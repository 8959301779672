import PropTypes, { number, string, arrayOf, func, shape } from "prop-types";
import React from "react";
import "./projectTabMenu.scss";
import { Grid } from "semantic-ui-react";
import { useIntl } from "react-intl";
import "./sectionFilter.scss";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { getProjectMembers } from "shared/selectors/project";
import { getI18N } from "shared/selectors";
import { Formik } from "formik";
import { Select } from "formik-semantic-ui-react";

import { useSelector } from "react-redux";

// options for sales_status field
const getSalesStatusOptions = i18n => [
  {
    key: "all",
    value: "all",
    text: i18n["unit.attributes.salesStatus.options.all"]
  },
  {
    key: "VACANT",
    value: "VACANT",
    text: i18n["unit.attributes.salesStatus.options.vacant"]
  },
  {
    key: "RESERVED",
    value: "RESERVED",
    text: i18n["unit.attributes.salesStatus.options.reserved"]
  },
  {
    key: "NOTARY",
    value: "NOTARY",
    text: i18n["unit.attributes.salesStatus.options.notary"]
  },
  {
    key: "SOLD",
    value: "SOLD",
    text: i18n["unit.attributes.salesStatus.options.sold"]
  }
];

// convert memembers to  options for accountManager field
const convertMembersToArray = (members, i18n) => {
  const defaultOptions = [
    {
      key: "all",
      value: "all",
      text: i18n["unit.attributes.accountManager.options.all"]
    },
    {
      key: "none",
      value: "none",
      text: i18n["unit.attributes.accountManager.options.none"]
    }
  ];

  if (members?.length > 0) {
    const membersArray = members.map(({ id, first_name, last_name }) => {
      return {
        key: id,
        value: id,
        text: `${first_name} ${last_name}`
      };
    });
    return [...defaultOptions, ...membersArray];
  }
  return defaultOptions;
};

function SectionFilter({
  sections,
  onSection,
  project,
  getSectionAndUnitValues
}) {
  const { formatMessage } = useIntl();
  const members = useSelector(getProjectMembers);
  const i18n = useSelector(getI18N);
  const unitFeatureOptions = useSelector(getUnitFeatureOptions);

  const unitFeatureOptionsWithAll = React.useMemo(
    () =>
      [
        {
          text: formatMessage({
            id: "section.title.all",
            defaultMessage: i18n["unit.attributes.section.options.all"]
          }),
          value: "all",
          key: "all"
        }
      ].concat(unitFeatureOptions),
    [unitFeatureOptions]
  );

  // options for section field
  const options = [
    {
      name: formatMessage({
        id: "section.title.all",
        defaultMessage: i18n["unit.attributes.section.options.all"]
      }),
      id: "all",
      key: "all"
    },
    ...sections
  ].map(({ name: filterLabel, id: filterValue }) => ({
    key: `${filterLabel}-${filterValue}`,
    text: filterLabel,
    value: filterValue
  }));

  // handle select change
  const handleChange = (e, { value, name }) => {
    const retrievedObject = localStorage.getItem("sectionAndUnitValues");

    const parsedRetrievedObject = JSON.parse(retrievedObject || "{}");
    localStorage.setItem(
      "sectionAndUnitValues",
      JSON.stringify({
        ...parsedRetrievedObject,
        [`${project.slug}-${project.id}`]: {
          ...parsedRetrievedObject[`${project.slug}-${project.id}`],
          [name]: value
        }
      })
    );
    onSection(name, value?.toString());
  };

  return (
    <Formik
      initialValues={getSectionAndUnitValues(project.slug, project.id)}
      enableReinitialize
    >
      {() => {
        return (
          <>
            <Grid columns={1}>
              <Grid.Column width={4}>
                <Select
                  label={formatMessage({
                    id: "unit.attributes.section.label"
                  })}
                  name="sectionFilter"
                  fluid
                  selection
                  options={options}
                  onChange={handleChange}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  label={formatMessage({
                    id: "unit.attributes.accountManager.filter.label"
                  })}
                  name="accountManagerFilter"
                  fluid
                  selection
                  options={convertMembersToArray(members, i18n)}
                  onChange={handleChange}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  label={formatMessage({
                    id: "unit.attributes.salesStatus.label"
                  })}
                  name="salesStatusFilter"
                  fluid
                  selection
                  options={getSalesStatusOptions(i18n)}
                  onChange={handleChange}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Select
                  name="unitFeatureId"
                  fluid
                  selection
                  label={formatMessage({
                    id: "roomBook.sections.attributes.unitFeatures.label"
                  })}
                  options={unitFeatureOptionsWithAll}
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
}

SectionFilter.propTypes = {
  sections: arrayOf(shape({ name: string, id: number })).isRequired,
  onSection: func.isRequired,
  project: shape({
    id: PropTypes.oneOfType([string, number]),
    slug: string
  }).isRequired,
  getSectionAndUnitValues: func.isRequired
};

export default SectionFilter;
