import { createSelector } from "reselect";
import { get } from "lodash";
import { getPageContent } from "./base";

export const getCustomImportConfigs = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "custom_import_configs", undefined) || []
);

export default getCustomImportConfigs;
