import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon, Table, Dropdown, Button, Header } from "semantic-ui-react";
import moment from "moment";
import { sortBy } from "lodash";
import { If } from "shared/components/elements/Conditions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import AccountMembershipDialog from "./AccountMembershipDialog";
import OptionsShape from "../../../../shared/shapes/options.shape";

class UserTable extends React.Component {
  static propTypes = {
    admin: PropTypes.object,
    actions: PropTypes.object,
    accountMemberships: PropTypes.array,
    growl: PropTypes.object,
    accountRoles: PropTypes.arrayOf(OptionsShape).isRequired,
    allAccountRolesData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  };

  constructor(props) {
    super(props);
  }

  handleUpdate() {
    return this.props.actions.account.get(true);
  }

  invertStatus = status => {
    return status === "active" ? "inactive" : "active";
  };

  changeStatus = user => {
    const { accountMemberships, actions, growl } = this.props;
    const user_membership = accountMemberships.find(
      x => x.user?.id === user.id
    );
    const newStatus = this.invertStatus(user_membership.status);
    actions.users
      .update_account_membership(user.id, {
        ...user_membership,
        status: newStatus
      })
      .then(() => {
        growl.success(
          "user.messages.statusChange.title",
          `user.messages.statusChange.message.${
            newStatus === "active" ? "activated" : "deactivated"
          }`
        );
        return this.handleUpdate();
      });
  };

  inviteFn(user) {
    return () => {
      this.props.actions.users.invite(user).then(() => {
        this.props.growl.success(
          "user.messages.resendInvitation.title",
          "user.messages.resendInvitation.message"
        );
      });
    };
  }

  renderLastSignIn = user => {
    const time =
      user.last_sign_in_at || user.current_sign_in_at
        ? user.last_sign_in_at || user.current_sign_in_at
        : null;
    if (!time) return <span>-</span>;

    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {moment(user.current_sign_in_at).format("L")}{" "}
        {moment(user.current_sign_in_at).format("LT")}
      </div>
    );
  };

  renderChildren() {
    const { admin, accountRoles, allAccountRolesData } = this.props;

    const { accountMemberships } = this.props;

    // find in allAccountRolesData the name of the role of the user given the id of the role
    const getRoleName = id => {
      const role = allAccountRolesData.find(
        accountRole => accountRole.id === id
      );
      return role ? role.name : "";
    };

    return sortBy(
      accountMemberships,
      membership => membership.user.first_name
    ).map(membership => {
      const { user } = membership;
      const button = (
        <Button basic data-cmd="edit" className="icon">
          <Icon name="setting" />
        </Button>
      );

      const menu = (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: "0.78em"
          }}
        >
          <VersionHistoryLink
            id={membership.id}
            type="AccountMembership"
            style={{
              fontSize: "1.5em"
            }}
          />
          <Button.Group size="small" basic>
            <AccountMembershipDialog
              user={user}
              admin={admin}
              membership={membership}
              button={button}
              resource={this.props.actions.users}
              handleUpdate={this.handleUpdate.bind(this)}
              accountRoles={accountRoles}
            />
            <Dropdown
              basic
              icon="dropdown"
              pointing="top right"
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.inviteFn(user).bind(this)}>
                  <Icon name="mail" /> Einladung nochmals senden
                </Dropdown.Item>
                <If condition={admin.id !== user.id}>
                  <Dropdown.Item onClick={() => this.changeStatus(user)}>
                    <Icon name="user" />
                    <FormattedMessage
                      id={`user.messages.statusChange.dropdownItem.${
                        membership.status === "active"
                          ? "deactivate"
                          : "activate"
                      }`}
                    />
                  </Dropdown.Item>
                </If>
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>
        </div>
      );

      return (
        <Table.Row key={membership.id} data-id={user.id} data-model="user">
          <Table.Cell data-attr="name">
            <Header as="h4" style={{ marginBottom: "3px" }}>
              {user.first_name} {user.last_name}
              <span data-attr="incognito" data-value={membership.incognito}>
                &nbsp;{membership.incognito && <Icon name="spy" />}
              </span>
            </Header>
            <span data-attr="email">{user.email}</span>
          </Table.Cell>
          <Table.Cell data-attr="role">
            {getRoleName(membership.account_role_id)}
          </Table.Cell>
          <Table.Cell data-attr="last-sign-in" textAlign="right">
            {this.renderLastSignIn(membership.user)}
          </Table.Cell>
          <Table.Cell
            data-attr="status"
            data-value={membership.status}
            textAlign="right"
          >
            <Icon
              name={membership.status === "active" ? "check" : "ban"}
              color={membership.status === "active" ? "green" : "red"}
            />
          </Table.Cell>
          <Table.Cell>{menu}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Table striped attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="user.attributes.name.label"
                defaultMessage="user.attributes.name.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="user.attributes.role.label"
                defaultMessage="user.attributes.role.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="user.attributes.lastSignIn.label"
                defaultMessage="Last sign in"
              />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>{this.renderChildren()}</Table.Body>
      </Table>
    );
  }
}

export default UserTable;
