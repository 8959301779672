import getVideoId from "get-video-id";

const VALIDATE_MP4 = "^(http(s)?://|www.).*(.mp4)$";

export const isMp4Url = url => url.match(VALIDATE_MP4);

export const isVideoUrlValid = url => {
  if (!url) return true;

  const { service } = getVideoId(url);

  if (url && service === "youtube") {
    return true;
  }
  if (isMp4Url(url)) {
    return true;
  }
  if (url) {
    return false;
  }
  return false;
};
