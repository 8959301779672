import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Icon,
  List,
  Loader,
  Menu,
  Dropdown
} from "semantic-ui-react";
import { Link } from "react-router";
import { browserHistory } from "shared/routes/browserHistory";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { loadingProps } from "shared/helpers/propHelper";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { If } from "shared/components/elements/Conditions";
import { I18nShape } from "shared/shapes/i18n.shape";
import { getAccount } from "shared/selectors";
import RoomBookDialog from "./RoomBookDialog";
import { ProjectRoomBooksResource } from "../../actions/roomBookActions";
import UpgradeBundlesLoader from "./upgradeBundles/UpgradeBundlesLoader";
import UpgradeBundles from "./upgradeBundles/UpgradeBundles";
import "./roomBook.scss";
import RoomBookShape from "../../../shared/shapes/roomBook.shape";
import { ProjectCatalogShape } from "../../../shared/shapes/projectCatalog.shape";
import UnitVariableTypesList from "./unitVariables/UnitVariableTypesList";
import { UnitVariableTypesResource } from "../../actions/unitVariablesActions";
import UnitFeatureGroupsList from "./unitFeatureGroups/UnitFeatureGroupsList";
import RoomBookTemplatesImport from "./RoomBookTemplatesImport";
import ConfigurationGroupsList from "./configurationGroups/ConfigurationGroupsList";

const tabs = {
  RoomBookTemplates: "RoomBookTemplates",
  UpgradeBundles: "UpgradeBundles",
  UnitVariables: "UnitVariables",
  UnitFeatureGroups: "UnitFeatureGroups",
  ConfigurationGroups: "ConfigurationGroups"
};

const STORAGE = get(window, "localStorage", {});
class RoomBookTemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: {},
      highlight: null,
      currentTab:
        props.currentTab ||
        STORAGE.getItem("RoombookTemplatesSubTab") ||
        tabs.RoomBookTemplates,
      action: null
    };
  }

  componentDidMount() {
    const { resources } = this.props;
    resources.projectRoomBooks.fetchAll({ template: true });
  }

  componentDidUpdate() {
    const { currentTab: newTab, account } = this.props;
    if (account.isEnabled("show_page_layout_v2")) {
      const { currentTab } = this.state;
      if (currentTab !== newTab) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ currentTab: newTab });
      }
    }
  }

  deselectAction = () => {
    this.setState(prevState => ({ ...prevState, action: null }));
  };

  copyRoomBook(id, name, copyMode) {
    const { resources, projectId, i18n } = this.props;
    this.setState({ loading: { [id]: true } });
    resources.projectRoomBooks
      .save({
        name: `${i18n["roomBook.copyOf"]}${name}`,
        source_id: id,
        copy_mode: copyMode
      })
      .then(data => {
        this.setState({ loading: { [id]: false } });
        const targetId = get(data, "data.room_book.id");
        if (targetId) {
          browserHistory.push(`/projects/${projectId}/room_books/${targetId}`);
        }
      });
  }

  renderRoomBooks() {
    const { roomBooks, catalogs } = this.props;

    if (!roomBooks) {
      return <Loader active />;
    }
    if (!roomBooks.length && catalogs.length) {
      return (
        <div className="notFound">
          <FormattedMessage
            id="project.roomBook.notFound"
            defaultMessage="Es liegen keine Raumbuchvorlagen zu diesem Projekt vor"
          />
        </div>
      );
    }
    if (!roomBooks.length && !catalogs.length) {
      return (
        <div className="notFound">
          <FormattedMessage
            id="project.roomBook.noPriceCatalog"
            defaultMessage="Um eine neue Raumbuchvorlage anzulegen, weisen Sie bitte zunächst dem Projekt eine Preisliste zu."
          />
        </div>
      );
    }
    return roomBooks.map(this.renderRoomBook);
  }

  renderRoomBook = item => {
    const { projectId, i18n, roomBooks } = this.props;
    const { loading, highlight } = this.state;
    const getSourceRoomBookNames = source_id => {
      const sourceRoomBook = roomBooks.find(rb => rb.id === source_id);
      if (sourceRoomBook) {
        return [
          {
            className: highlight === source_id ? "highlight" : null,
            content: sourceRoomBook.name,
            href: `#roomBook-${source_id}`,
            key: `roomBook-${source_id}`,
            onMouseEnter: () => this.setState({ highlight: source_id }),
            onMouseLeave: () => this.setState({ highlight: null })
          },
          ...getSourceRoomBookNames(sourceRoomBook.source_id)
        ];
      }
      return [];
    };

    const sourceRoomBookNames = getSourceRoomBookNames(item.source_id);

    return (
      <List.Item
        data-model="roombook_template"
        data-component="roomBookListItem"
        key={item.id}
        id={`roomBook-${item.id}`}
      >
        <List.Content className={highlight === item.id ? "highlight" : null}>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width={14}>
              <Link
                to={`/projects/${projectId}/room_books/${item.id}`}
                className="roomBookItem"
              >
                <Icon color="grey" name="book" />
                <List.Header>
                  <span data-attr="name">{item.name}</span>
                </List.Header>
              </Link>
              {sourceRoomBookNames.length > 0 && (
                <div className="inheritInfo">
                  <Icon name="fork" />{" "}
                  <Breadcrumb
                    sections={sourceRoomBookNames}
                    icon="arrow left"
                  />
                </div>
              )}
            </Grid.Column>
            <Grid.Column
              textAlign="right"
              width={2}
              className="mobilePaddedColumn"
            >
              <Button
                icon="copy"
                title={i18n["meta.actions.copy"]}
                onClick={() => this.copyRoomBook(item.id, item.name, "copy")}
                {...loadingProps(loading[item.id])}
              />
              <Button
                icon="fork"
                title={i18n["meta.actions.fork"]}
                onClick={() => this.copyRoomBook(item.id, item.name, "fork")}
                {...loadingProps(loading[item.id])}
              />
            </Grid.Column>
          </Grid>
        </List.Content>
      </List.Item>
    );
  };

  renderRoombookTemplates = () => {
    const { i18n, resources, projectId, catalogs } = this.props;
    const { action } = this.state;

    return (
      <Grid stackable className="margin bottom medium">
        <Grid.Column width="10">
          <Header size="large">
            <FormattedMessage
              id="project.tabs.roomBook"
              defaultMessage="project.tabs.roomBook"
            />
          </Header>
        </Grid.Column>
        <Grid.Column textAlign="right" width="6">
          <RoomBookDialog
            i18n={i18n}
            projectId={projectId}
            catalogs={catalogs}
            actions={{ roomBook: resources.projectRoomBooks }}
            button={
              <Button.Group>
                <Button
                  id="roombook_template-new"
                  icon="plus"
                  content={i18n["roomBook.actions.addTemplate"]}
                  disabled={!catalogs.length}
                  color="green"
                />
                <FeatureToggleActive featureToggleName="copy_roombook_templates">
                  <Dropdown
                    id="unit_variable_type_dropdown"
                    className="button icon"
                    floating
                    selectOnBlur={false}
                    options={[
                      {
                        key: "import",
                        icon: "copy",
                        text: i18n["meta.actions.import"],
                        value: "import"
                      }
                    ]}
                    trigger={<></>}
                    onChange={(_, { value }) =>
                      this.setState(prevState => ({
                        ...prevState,
                        action: value
                      }))
                    }
                    value={action}
                  />
                </FeatureToggleActive>
              </Button.Group>
            }
          />
          <If condition={action === "import"}>
            <RoomBookTemplatesImport
              roombookId={1}
              deselectAction={this.deselectAction}
            />
          </If>
        </Grid.Column>
      </Grid>
    );
  };

  renderMenu = () => {
    const { currentTab } = this.state;
    return (
      <Menu>
        <Menu.Item
          name={tabs.RoomBookTemplates}
          onClick={() => {
            STORAGE.setItem("RoombookTemplatesSubTab", tabs.RoomBookTemplates);
            this.setState(prevState => ({
              ...prevState,
              currentTab: tabs.RoomBookTemplates
            }));
          }}
          active={currentTab === tabs.RoomBookTemplates}
        >
          <FormattedMessage
            id="project.tabs.roomBook"
            defaultMessage="Roombook templates"
          />
        </Menu.Item>
        <FeatureToggleActive featureToggleName="show_upgrade_bundles">
          <Menu.Item
            name={tabs.UpgradeBundles}
            onClick={() => {
              STORAGE.setItem("RoombookTemplatesSubTab", tabs.UpgradeBundles);
              this.setState(prevState => ({
                ...prevState,
                currentTab: tabs.UpgradeBundles
              }));
            }}
            active={currentTab === tabs.UpgradeBundles}
          >
            <FormattedMessage
              id="roomBook.upgradeBundles.title"
              defaultMessage="Upgrade bundles"
            />
          </Menu.Item>
        </FeatureToggleActive>
        <FeatureToggleActive featureToggleName="show_unit_variables">
          <Menu.Item
            id="unit_variable_types"
            name={tabs.UnitVariables}
            onClick={() => {
              STORAGE.setItem("RoombookTemplatesSubTab", tabs.UnitVariables);
              this.setState(prevState => ({
                ...prevState,
                currentTab: tabs.UnitVariables
              }));
            }}
            active={currentTab === tabs.UnitVariables}
          >
            <FormattedMessage
              id="roomBook.unitVariables.types.tab"
              defaultMessage="Wohn.variablen Typen"
            />
          </Menu.Item>
        </FeatureToggleActive>
        <FeatureToggleActive featureToggleName="show_unit_feature_groups">
          <Menu.Item
            id="unit_feature_groups"
            name={tabs.UnitFeatureGroups}
            onClick={() => {
              STORAGE.setItem(
                "RoombookTemplatesSubTab",
                tabs.UnitFeatureGroups
              );
              this.setState(prevState => ({
                ...prevState,
                currentTab: tabs.UnitFeatureGroups
              }));
            }}
            active={currentTab === tabs.UnitFeatureGroups}
          >
            <FormattedMessage
              id="roomBook.unitFeatureGroups.types.tab"
              defaultMessage="Einheitsfunktionsgruppen"
            />
          </Menu.Item>
        </FeatureToggleActive>
        <FeatureToggleActive featureToggleName="show_configuration_groups">
          <Menu.Item
            id="configuration_groups"
            name={tabs.ConfigurationGroups}
            onClick={() => {
              STORAGE.setItem(
                "RoombookTemplatesSubTab",
                tabs.ConfigurationGroups
              );
              this.setState(prevState => ({
                ...prevState,
                currentTab: tabs.ConfigurationGroups
              }));
            }}
            active={currentTab === tabs.ConfigurationGroups}
          >
            <FormattedMessage
              id="roomBook.configurationGroups.types.tab"
              defaultMessage="Konfigurationsgruppen"
            />
          </Menu.Item>
        </FeatureToggleActive>
      </Menu>
    );
  };

  render() {
    const { resources, projectId, account } = this.props;
    const { currentTab } = this.state;

    return (
      <div data-component="roomBook">
        <If condition={!account.isEnabled("show_page_layout_v2")}>
          {this.renderMenu()}
        </If>
        <If
          condition={currentTab === tabs.RoomBookTemplates}
          styles={{ display: "block" }}
        >
          {this.renderRoombookTemplates()}
          <List divided size="big" data-component="roombook_templates">
            {this.renderRoomBooks()}
          </List>
        </If>

        <If
          condition={currentTab === tabs.UpgradeBundles}
          styles={{ display: "block" }}
        >
          <FeatureToggleActive featureToggleName="show_upgrade_bundles">
            <UpgradeBundlesLoader projectId={projectId}>
              <UpgradeBundles />
            </UpgradeBundlesLoader>
          </FeatureToggleActive>
        </If>

        <If
          condition={currentTab === tabs.UnitVariables}
          styles={{ display: "block" }}
        >
          <UnitVariableTypesList resources={resources} projectId={projectId} />
        </If>

        <If
          condition={currentTab === tabs.UnitFeatureGroups}
          styles={{ display: "block" }}
        >
          <UnitFeatureGroupsList />
        </If>
        <If
          condition={currentTab === tabs.ConfigurationGroups}
          styles={{ display: "block" }}
        >
          <ConfigurationGroupsList projectId={projectId} />
        </If>
      </div>
    );
  }
}

RoomBookTemplateList.propTypes = {
  i18n: I18nShape.isRequired,
  projectId: PropTypes.string.isRequired,
  resources: PropTypes.shape({
    projectRoomBooks: PropTypes.instanceOf(ProjectRoomBooksResource),
    unitVariableTypes: PropTypes.instanceOf(UnitVariableTypesResource)
  }).isRequired,
  roomBooks: PropTypes.arrayOf(RoomBookShape),
  catalogs: PropTypes.arrayOf(ProjectCatalogShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object.isRequired,
  currentTab: PropTypes.string
};

RoomBookTemplateList.defaultProps = {
  roomBooks: [],
  currentTab: "RoomBookTemplates"
};

const mapStateToProps = state => {
  return {
    account: getAccount(state)
  };
};

export default connect(mapStateToProps)(RoomBookTemplateList);
