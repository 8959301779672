import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";

class ButtonCheckbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
  };

  UNCHECKED_PROPS = {
    icon: "plus"
  };

  CHECKED_PROPS = {
    icon: "check",
    color: "green"
  };

  render() {
    const { checked, onChange, ...passThroughProps } = this.props;
    return (
      <Button
        {...(checked ? this.CHECKED_PROPS : this.UNCHECKED_PROPS)}
        {...passThroughProps}
        onClick={event => {
          onChange(event, { checked: !checked });
        }}
      />
    );
  }
}

export default ButtonCheckbox;
