import { shape, array, string } from "prop-types";

export const HooksConfigShape = shape({
  action: shape({
    id: string,
    label: string,
    class: string
  }),
  // eslint-disable-next-line react/forbid-prop-types
  line_item_status: array
});

export default HooksConfigShape;
