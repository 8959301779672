import React, { useContext } from "react";
import { Loader } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import ProjectQuestionGroups from "./ProjectQuestionGroups";
import { ProjectQuestionsLoaderContext } from "./ProjectQuestionsLoader";

const ProjectQuestions = () => {
  const { isLoading } = useContext(ProjectQuestionsLoaderContext);

  return (
    <>
      <If condition={isLoading}>
        <Loader active />
      </If>
      <ProjectQuestionGroups />
    </>
  );
};

export default ProjectQuestions;
