import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import ProcessDefinition from "../../../../shared/models/processDefinition";

const SINGLE_FILTER_TYPES = Object.freeze([
  "buyer",
  "unit",
  "section",
  "assignee",
  "processType"
]);
const COLLECTION_FILTER_TYPES = Object.freeze(["trades", "contractors"]);
const ALL_FILTER_TYPES = Object.freeze([
  ...SINGLE_FILTER_TYPES,
  ...COLLECTION_FILTER_TYPES
]);

class UniversalFilterBox extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    currentUser: PropTypes.object,
    setFilter: PropTypes.func,
    filter: PropTypes.object,
    assignees: PropTypes.array,
    contractors: PropTypes.array,
    units: PropTypes.array,
    sections: PropTypes.array,
    buyers: PropTypes.array,
    trades: PropTypes.array,
    processDefinitions: PropTypes.arrayOf(
      PropTypes.instanceOf(ProcessDefinition)
    )
  };

  constructor(props) {
    super(props);
  }

  getOptions() {
    const {
      currentUser,
      i18n,
      contractors,
      units,
      buyers,
      trades,
      assignees,
      sections,
      processDefinitions
    } = this.props;

    const options = [];

    // show responsible's own activities
    options.push({
      value: `{"type":"assignee","id":"${currentUser.id}"}`,
      icon: "user",
      text: i18n["project.activity.filter.ownItemsOnly"]
    });

    buyers?.map(item =>
      options.push({
        value: `{"type":"buyer","id":"${item.id}"}`,
        icon: "child",
        text: item.label
      })
    );

    units?.map(item =>
      options.push({
        value: `{"type":"unit","id":"${item.id}"}`,
        icon: "cube",
        text: [item.label, item.buyer].join(" ")
      })
    );

    sections?.map(item =>
      options.push({
        value: `{"type":"section","id":"${item.id}"}`,
        icon: "cubes",
        text: item.label
      })
    );

    contractors?.map(item =>
      options.push({
        value: `{"type":"contractors","id":"${item.id}"}`,
        icon: "shipping",
        text: item.label
      })
    );

    trades?.map(item =>
      options.push({
        value: `{"type":"trades","id":"${item.id}"}`,
        icon: "hand paper",
        text: item.label
      })
    );

    assignees?.map(item => {
      if (item.id !== currentUser.id) {
        options.push({
          value: `{"type":"assignee","id":"${item.id}"}`,
          icon: "user",
          text: item.label
        });
      }
    });

    processDefinitions?.forEach(processDefinition =>
      options.push({
        value: `{"type":"processType","id":"${processDefinition.id}"}`,
        icon: "tasks",
        text: processDefinition.getName()
      })
    );

    return options;
  }

  handleChange(data) {
    const filter = { ...this.props.filter };

    if (typeof data === "string") {
      data = [data];
    }
    // first restore settings from store
    const changedFilter = {};
    data.map(jsonItem => {
      const item = JSON.parse(jsonItem);
      if (SINGLE_FILTER_TYPES.includes(item.type)) {
        changedFilter[item.type] = item.id;
      } else if (COLLECTION_FILTER_TYPES.includes(item.type)) {
        changedFilter[item.type] = changedFilter[item.type] || [];
        changedFilter[item.type].push(item.id);
      }
    });

    ALL_FILTER_TYPES.forEach(type => {
      if (!changedFilter[type]) {
        delete filter[type];
      }
    });
    changedFilter.page = 1;
    this.props.setFilter({ ...filter, ...changedFilter });
  }

  getValues() {
    const value = [];
    const { filter } = this.props;

    SINGLE_FILTER_TYPES.forEach(type => {
      if (filter[type]) {
        value.push(`{"type":"${type}","id":"${filter[type]}"}`);
      }
    });
    COLLECTION_FILTER_TYPES.forEach(type => {
      if (filter[type]) {
        filter[type].map(item =>
          value.push(`{"type":"${type}","id":"${item}"}`)
        );
      }
    });
    return value;
  }

  render() {
    const { i18n } = this.props;

    const renderLabel = label => ({
      color: "blue",
      content: label.text,
      icon: label.icon
    });

    return (
      <Dropdown
        id="universalFilterBox"
        data-component="universalFilterBox"
        icon="search"
        placeholder={i18n["activity.search.universal.placeholder"]}
        fluid
        search
        multiple
        value={this.getValues()}
        selection
        closeOnChange
        options={this.getOptions()}
        noResultsMessage="Keine passenden Vorschläge gefunden"
        renderLabel={renderLabel}
        onChange={(event, data) => this.handleChange(data.value)}
      />
    );
  }
}

export default UniversalFilterBox;
