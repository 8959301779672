import { UPDATE_DIALOG } from "../constants/appConstants";

export default (dispatch, id, module) => {
  return (dialogName, isVisible) => {
    if (typeof dialogName !== "object") {
      if (id) {
        const dialog = {
          [module]: {
            [id]: {
              [dialogName]: isVisible
            }
          }
        };

        dispatch({
          type: UPDATE_DIALOG,
          payload: dialog
        });
      }
    } else {
      // supports manual usage such as toggleDialog({ memo: { [122]: true }})
      if (id) {
        const dialog = {
          [module]: {
            [id]: dialogName
          }
        };

        dispatch({
          type: UPDATE_DIALOG,
          payload: dialog
        });
      }
    }
  };
};
