import PropTypes from "prop-types";
import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import CustomProductForm from "./CustomProductForm";

class AddCustomProduct extends Component {
  static propTypes = {
    resources: PropTypes.object,
    model: PropTypes.object,
    productGroupId: PropTypes.number,
    handleUpdate: PropTypes.func,
    onSave: PropTypes.func,
    suppliers: PropTypes.array
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      model,
      resources,
      onSave,
      handleUpdate,
      suppliers,
      productGroupId
    } = this.props;

    const product =
      model && model.id
        ? model
        : {
            name: "",
            trades: [],
            labels: []
          };

    return (
      <Segment data-component="addCustomProduct">
        <CustomProductForm
          context="AddCustomProduct"
          resources={resources}
          model={product}
          handleUpdate={handleUpdate}
          productGroupId={productGroupId}
          handleClose={onSave}
          suppliers={suppliers}
        />
      </Segment>
    );
  }
}

export default AddCustomProduct;
