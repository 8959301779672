class MasterData {
  constructor(i18n) {
    this.i18n = i18n;
  }

  accountMembershipRoles() {
    return [
      {
        key: "operator",
        value: "operator",
        text: this.i18n["user.roles.operator.label"]
      },
      {
        key: "admin",
        value: "admin",
        text: this.i18n["user.roles.admin.label"]
      },
      {
        key: "api",
        value: "api",
        text: this.i18n["user.roles.api.label"]
      }
    ];
  }

  accountMembershipStatus() {
    return [
      {
        key: "active",
        value: "active",
        text: this.i18n["user.status.active.label"]
      },
      {
        key: "inactive",
        value: "inactive",
        text: this.i18n["user.status.inactive.label"]
      }
    ];
  }

  priceStrategies() {
    return ["flat", "area", "length", "pieces", "hour"].map(k => {
      return {
        key: k,
        value: k,
        text: this.i18n[`product_group.price_strategies.${k}.label`]
      };
    });
  }
}

export default MasterData;
