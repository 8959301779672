import { createSelector } from "reselect";
import { get, keyBy } from "lodash";
import defaultMap from "../../../../../../engines/core/data/processes/general/states_bp.json";
import { getFlatRoomBook } from "./base";

export const getStatusMapping = state => {
  return keyBy(
    get(state, ["account", "account", "config", "buyerapp_status"], defaultMap),
    "id"
  );
};

export const getStatus = createSelector([getStatusMapping], statusMapping => {
  return status => {
    return statusMapping[status];
  };
});

export const getLineItemStatusMap = createSelector(
  [getFlatRoomBook, getStatus],
  (roomBook, getStatus) => {
    return roomBook.lineItems.reduce((accu, lineItem) => {
      accu[lineItem.id] = getStatus(lineItem.external_status);
      return accu;
    }, {});
  }
);

export const getLineItemStatus = createSelector(
  [getLineItemStatusMap],
  lineItemStatus => {
    return lineItem => {
      return lineItemStatus[lineItem.id];
    };
  }
);

export const getSubLineItemStatus = createSelector(
  [getLineItemStatusMap],
  lineItemStatus => {
    return subLineItem => {
      return lineItemStatus[subLineItem.lineItemId];
    };
  }
);
