export function byUniq(prop) {
  const knownProps = [];
  return obj => {
    if (knownProps.includes(obj[prop])) {
      return false;
    }
    knownProps.push(obj[prop]);
    return true;
  };
}

export function byProperty(prop, value) {
  return obj => obj[prop] === value;
}

export function byIncludes(prop, array) {
  return obj => array.includes(obj[prop]);
}
