import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "semantic-ui-react";
import UnitsForm from "./UnitsForm";

class UnitsDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    unit: PropTypes.object,
    sectionId: PropTypes.number,
    sectionOptions: PropTypes.array,
    button: PropTypes.node,
    resource: PropTypes.object,
    handleCreated: PropTypes.func
  };

  constructor(props) {
    super(props);

    // @todo: use redux state not component state
    this.state = { values: null, loading: false, open: false };
  }

  handleSave() {
    const { values } = this.state;
    this.setState({ loading: true });
    const payload = {
      ...values,
      unit_numbers: values.unit_numbers.split("\n")
    };
    this.props.resource.batchCreate(payload).then(result => {
      this.props.resource.fetchAll().then(() => {
        this.setState({ open: false, loading: false });
        if (this.props.handleCreated) {
          this.props.handleCreated(result.data.units);
        }
      });
    });
  }

  handleChange(values) {
    this.setState({ values });
  }

  render() {
    const { unit, sectionOptions, button, i18n } = this.props;
    const { open, loading } = this.state;
    let model = unit;
    if (!unit || !unit.section_id) {
      model = { ...unit, section_id: this.props.sectionId };
    }
    return (
      <Modal
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={button}
        open={open}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
      >
        <Modal.Header>
          <FormattedMessage
            id="unit.actions.batchCreate"
            defaultMessage="unit.actions.batchCreate"
          />
        </Modal.Header>
        <Modal.Content>
          <UnitsForm
            onChange={this.handleChange.bind(this)}
            model={model}
            sectionOptions={sectionOptions}
            i18n={i18n}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content={<FormattedMessage id="meta.actions.cancel" />}
            onClick={() => this.setState({ open: false })}
          />
          <Button
            primary
            type="submit"
            loading={loading}
            onClick={this.handleSave.bind(this)}
          >
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default UnitsDialog;
