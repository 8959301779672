import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors";

export const getDescendantsInfo = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "descendantsInfo", [])
);

export const getRoomBookStructure = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "roomBookStructure", {})
);

export const getDerivedInfos = createSelector([getPageContent], pageContent =>
  get(pageContent, "derived_infos", [])
);

export const getDerivedIds = createSelector([getDerivedInfos], derivedInfos =>
  derivedInfos.map(i => i.id)
);

export const getItemInfo = (_, props) => {
  const { item, itemType } = props;
  const isSection = ["main_section", "sub_section"].includes(itemType);
  return { item, itemType, isSection };
};

export const getDescendantsInfoForItem = createSelector(
  [getDescendantsInfo, getItemInfo, getRoomBookStructure],
  (info, { item, itemType, isSection }, roomBookStructure) => {
    const descendantsInfo =
      info.find(i => i.itemType === itemType && i.itemId === item.id) || {};
    const { cacheKey: key, derivedInfos } = descendantsInfo;

    return { derivedInfos, item, itemType, isSection, key, roomBookStructure };
  }
);
