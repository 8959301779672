import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { get, head, min, sortBy, cloneDeep, set, isNumber } from "lodash";
import {
  Message,
  Modal,
  Form,
  Button,
  Table,
  Checkbox
} from "semantic-ui-react";
import FloatInput from "shared/components/forms/FloatInput";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import { CustomLabelCheckbox } from "shared/components/checkbox/CustomLabelCheckbox";
import { getAccount } from "shared/selectors";
import {
  makeGetDefaultPrice,
  makeGetAlternativePrices
} from "builder_portal/selectors/productGroups";
import { AccountShape, ProductPriceShape } from "shared/shapes";
import { withRouter } from "../../../shared/helpers/withRouter";
import ProductInfoItem from "../product/ProductInfoItem";
import TradeLabel from "../helpers/TradeLabel";
import MultiDialog from "../dialogs/MultiDialog";
import ProductGroupResource from "../../actions/productGroupActions";

import "./productPricesDialog.scss";

class ProductPricesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedPrices: [],
      dialog: null,
      priceChanged: false
    };
  }

  handleClose = () => {
    const { priceChanged } = this.state;
    const { reloadPrices } = this.props;
    if (priceChanged) reloadPrices();
  };

  handleOpen = () => {
    this.setState({ priceChanged: false });
  };

  render() {
    const { account } = this.props;
    if (!account.isEnabled("bulk_price_editing") && !account.isSystemAdmin())
      return null;

    const { selectedPrices } = this.state;
    return (
      <Modal
        closeIcon
        trigger={this.props.trigger}
        size="large"
        data-component="productPricesDialog"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      >
        <Modal.Header>Preisliste</Modal.Header>
        <Modal.Content>
          {this.renderTables()}
          {this.renderBulkPriceEditorDialog()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="left floated element"
            onClick={() => {
              this.toggleBulkEditorDialog(true);
            }}
            disabled={!selectedPrices.length}
          >
            Bearbeiten
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderTables = () => {
    const { defaultPrice, alternativePrices } = this.props;

    if (defaultPrice) {
      return [
        this.renderPriceTable([defaultPrice]),
        this.renderAlternativePricesTable(alternativePrices)
      ];
    }
    return this.renderSimplePricesTable(alternativePrices);
  };

  renderBulkPriceEditorDialog = () => {
    if (this.state.dialog) {
      return (
        <MultiDialog
          title="Bearbeiten"
          pages={this.getBulkEditPages()}
          isVisible
          toggleDialog={this.toggleBulkEditorDialog}
          size="tiny"
        />
      );
    }
  };

  renderPriceForm = (field, label, defaultPrice) => {
    const value = get(this.state, ["dialog", field], defaultPrice || 0.0);
    const id = `default-${field}`;
    return (
      <Form>
        <Form.Field>
          <label htmlFor={id}>{label}</label>
          <FloatInput
            id={id}
            value={value}
            onChange={(_, { value }) => {
              const { dialog } = this.state;
              dialog[field] = value;
              this.setState({ dialog });
            }}
            name="price"
          />
        </Form.Field>
      </Form>
    );
  };

  renderCostForm = (field, label) => {
    const { dialog } = this.state;
    const { trades } = this.props;

    const cost_attributes = get(dialog, [field]);

    const fields = Object.keys(cost_attributes).map(trade => {
      const value = cost_attributes[trade];
      const id = `${trade}-${field}`;
      return (
        <Form.Field key={trade}>
          <label htmlFor={id}>
            <TradeLabel trades={trades} id={trade} />
          </label>
          <FloatInput
            id={id}
            value={value}
            onChange={(_, { value }) => {
              const { dialog } = this.state;
              set(dialog, [field, trade], value);
              this.setState({ dialog });
            }}
          />
        </Form.Field>
      );
    });

    return <Form>{fields}</Form>;
  };

  updatePricesWithReload = values => {
    const { updatePrices } = this.props;
    return updatePrices(values).then(() =>
      this.setState({ priceChanged: true })
    );
  };

  updateExtraPrice = () => {
    const { defaultPrice } = this.props;
    const { dialog } = this.state;
    const { selectedPrices, extraPrice } = dialog;

    const values = selectedPrices.map(selectedPrice => {
      return {
        id: selectedPrice.id,
        price: defaultPrice.price + extraPrice
      };
    });
    return this.updatePricesWithReload(values);
  };

  updatePrice = () => {
    const { dialog } = this.state;
    const { selectedPrices, price } = dialog;
    const values = selectedPrices.map(selectedPrice => {
      return {
        id: selectedPrice.id,
        price
      };
    });
    return this.updatePricesWithReload(values);
  };

  updateExcessPrice = () => {
    const { dialog } = this.state;
    const { selectedPrices, excessPrice, hasExcessPrice } = dialog;
    const values = selectedPrices.map(selectedPrice => {
      return {
        id: selectedPrice.id,
        excess_price: hasExcessPrice ? excessPrice : null
      };
    });
    return this.updatePricesWithReload(values);
  };

  updateCreditMemo = () => {
    const { defaultPrice, alternativePrices } = this.props;
    const { dialog } = this.state;
    const { creditMemo } = dialog;

    const values = [];
    values.push({
      id: defaultPrice.id,
      price: creditMemo
    });
    alternativePrices.forEach(alternativePrice => {
      const extraPrice = alternativePrice.price - defaultPrice.price;
      values.push({
        id: alternativePrice.id,
        price: (creditMemo + extraPrice).toFixed(2)
      });
    });

    return this.updatePricesWithReload(values);
  };

  updateExtraCosts = () => {
    const { defaultPrice } = this.props;
    const { dialog } = this.state;
    const { selectedPrices, extraCosts } = dialog;

    const values = selectedPrices.map(price => {
      return {
        id: price.id,
        costs_attributes: defaultPrice.costs_attributes.map(ca => {
          return {
            trade: ca.trade,
            cost: extraCosts[ca.trade] + ca.cost
          };
        })
      };
    });

    return this.updatePricesWithReload(values);
  };

  updateCosts = () => {
    const { dialog } = this.state;
    const { selectedPrices, costs } = dialog;

    const values = selectedPrices.map(price => {
      return {
        id: price.id,
        costs_attributes: price.costs_attributes.map(ca => ({
          trade: ca.trade,
          cost: costs[ca.trade]
        }))
      };
    });

    return this.updatePricesWithReload(values);
  };

  updateExcessCosts = () => {
    const { defaultPrice } = this.props;
    const { dialog } = this.state;
    const { selectedPrices, excessCosts, hasExcessCosts } = dialog;

    const values = selectedPrices.map(price => {
      return {
        id: price.id,
        costs_attributes: defaultPrice.costs_attributes.map(ca => {
          return {
            trade: ca.trade,
            excess_cost: hasExcessCosts ? excessCosts[ca.trade] : null
          };
        })
      };
    });

    return this.updatePricesWithReload(values);
  };

  updateCreditMemoCosts = () => {
    const { defaultPrice, alternativePrices } = this.props;
    const { dialog } = this.state;
    const { defaultCosts, creditMemoCosts } = dialog;
    const values = [
      {
        id: defaultPrice.id,
        costs_attributes: defaultPrice.costs_attributes.map(ca => {
          return {
            trade: ca.trade,
            cost: creditMemoCosts[ca.trade]
          };
        })
      }
    ];

    alternativePrices.forEach(price => {
      values.push({
        id: price.id,
        costs_attributes: price.costs_attributes.map(ca => {
          const extraCost = ca.cost - defaultCosts[ca.trade];
          return {
            trade: ca.trade,
            cost: creditMemoCosts[ca.trade] + extraCost
          };
        })
      });
    });

    return this.updatePricesWithReload(values);
  };

  getBulkEditPages() {
    const { defaultPrice } = this.props;
    const { dialog } = this.state;
    const selectedPage = get(dialog, "selectedPage");

    return [
      this.extraPriceDialogPage(dialog, defaultPrice),
      this.priceDialogPage(dialog, defaultPrice),
      this.creditMemoDialogPage(dialog, defaultPrice),
      this.excessPriceDialogPage(dialog, defaultPrice),
      this.extraCostDialogPage(dialog, defaultPrice),
      this.costDialogPage(dialog, defaultPrice),
      this.creditMemoCostDialogPage(dialog, defaultPrice),
      this.excessCostDialogPage(dialog, defaultPrice)
    ].filter(page => {
      return !selectedPage || selectedPage === page.id;
    });
  }

  defineSaveAction = fn => {
    return {
      id: "save",
      label: "meta.actions.save",
      color: "green",
      onClick: fn
    };
  };

  extraPriceDialogPage = () => {
    const { defaultPrice } = this.props;
    const { dialog } = this.state;

    return {
      id: "extra_price",
      title: "Mehrpreis (MP) bearbeiten",
      summary: "Bearbeiten Sie den Mehrpreis der ausgewählten Produkte",
      hidden: !defaultPrice,
      renderContent: () => {
        const currentPrice = dialog.extraPrice;
        return (
          <div>
            <div className="margin bottom spacious">
              {this.renderPriceForm("extraPrice", "Mehrpreis")}
            </div>
            <Message>
              <p>Der berechnete Verechnungspreis der Standardmenge beträgt:</p>
              <p>
                <strong>
                  <FormatCurrency amount={defaultPrice.price} /> +{" "}
                  <FormatCurrency amount={currentPrice} /> ={" "}
                  <FormatCurrency amount={defaultPrice.price + currentPrice} />
                </strong>
              </p>
              <p>
                <small>
                  Gutschrift Standardprodukt + Mehrpreis = Verechnungspreis der
                  Standardmenge
                </small>
              </p>
            </Message>
          </div>
        );
      },
      actions: [this.defineSaveAction(this.updateExtraPrice)]
    };
  };

  priceDialogPage = () => {
    const { defaultPrice } = this.props;
    return {
      id: "price",
      title: "Verrechnungspreis (VP) bearbeiten",
      summary: "Bearbeiten Sie den Verrechnungspreis der ausgewählten Produkte",
      hidden: defaultPrice,
      renderContent: () => {
        return this.renderPriceForm("price", "Verrechnungspreis");
      },
      actions: [this.defineSaveAction(this.updatePrice)]
    };
  };

  creditMemoDialogPage = dialog => {
    const { selectedPrices } = dialog;
    return {
      id: "credit_memo",
      title: "Gutschrift des Standardproduktes bearbeiten",
      summary: "Gutschrift des Standardproduktes bearbeiten",
      hidden: selectedPrices.some(price => !price.is_default),
      renderContent: () => {
        return (
          <div>
            <div className="margin bottom spacious">
              {this.renderPriceForm("creditMemo", "Gutschrift")}
            </div>
            <Message>
              <p>
                Durch das Ändern der Gutschift wird der Verrechnungspreis der
                Standardmenge bei allen Produkten so angepasst, das der
                Mehrpreis erhalten wird.
              </p>
            </Message>
          </div>
        );
      },
      actions: [this.defineSaveAction(this.updateCreditMemo)]
    };
  };

  excessPriceDialogPage = () => {
    const { defaultPrice } = this.props;
    return {
      id: "excessPrice",
      title: "Einheitspreis (EP) bearbeiten",
      summary: "Bearbeiten Sie den Einheitspreis der ausgewählten Produkte",
      hidden: !defaultPrice,
      renderContent: () => {
        const { dialog } = this.state;

        return (
          <div>
            <div className="bottom margin spacious">
              <Checkbox
                checked={dialog.hasExcessPrice}
                label="Mehrmengen werden mit abweichendem Einheitspreis berechnet"
                onClick={(_, { checked }) => {
                  this.setState({
                    dialog: { ...dialog, hasExcessPrice: checked }
                  });
                }}
              />
            </div>
            <div>
              {dialog.hasExcessPrice &&
                this.renderPriceForm("excessPrice", "Einheitspreis")}
            </div>
          </div>
        );
      },
      actions: [this.defineSaveAction(this.updateExcessPrice)]
    };
  };

  costDialogPage = () => {
    const { defaultPrice } = this.props;
    return {
      id: "cost",
      title: "Verrechnungskosten (VK) bearbeiten",
      summary:
        "Bearbeiten Sie die Verrechnungskosten der ausgewählten Produkte",
      hidden: defaultPrice,
      renderContent: () => {
        return this.renderCostForm("costs", "Verrechnungskosten");
      },
      actions: [this.defineSaveAction(this.updateCosts)]
    };
  };

  extraCostDialogPage = () => {
    const { defaultPrice } = this.props;
    return {
      id: "extraCost",
      title: "Mehrkosten (MK) bearbeiten",
      summary: "Bearbeiten Sie die Mehrkosten der ausgewählten Produkte",
      hidden: !defaultPrice,
      renderContent: () => {
        return this.renderCostForm("extraCosts", "Mehrkosten");
      },
      actions: [this.defineSaveAction(this.updateExtraCosts)]
    };
  };

  creditMemoCostDialogPage = dialog => {
    const { selectedPrices } = dialog;
    return {
      id: "creditMemoCost",
      title: "Gutschift (GS) bearbeiten",
      summary: "Bearbeiten Sie die Kostengutschrift des Standardproduktes",
      hidden: selectedPrices.some(price => !price.is_default),
      renderContent: () => {
        return this.renderCostForm("creditMemoCosts", "Mehrkosten");
      },
      actions: [this.defineSaveAction(this.updateCreditMemoCosts)]
    };
  };

  excessCostDialogPage = () => {
    const { defaultPrice } = this.props;
    return {
      id: "excessCost",
      title: "Einheitskosten (EK) bearbeiten",
      summary: "Bearbeiten Sie die Einheitskosten der ausgewählten Produkte",
      hidden: !defaultPrice,
      renderContent: () => {
        const { dialog } = this.state;

        return (
          <div>
            <div className="bottom margin spacious">
              <Checkbox
                checked={dialog.hasExcessCosts}
                label="Abweichende Kosten für Mehrmengen?"
                onClick={(_, { checked }) => {
                  this.setState({
                    dialog: { ...dialog, hasExcessCosts: checked }
                  });
                }}
              />
            </div>
            <div>
              {dialog.hasExcessCosts &&
                this.renderCostForm("excessCosts", "Einheitskosten")}
            </div>
          </div>
        );
      },
      actions: [this.defineSaveAction(this.updateExcessCosts)]
    };
  };

  selectAllAlternativePrices = (_, { checked }) => {
    const { alternativePrices } = this.props;
    if (checked) {
      this.setState({
        selectedPrices: [].concat(alternativePrices)
      });
    } else {
      this.setState({
        selectedPrices: []
      });
    }
  };

  selectPrice = price => {
    return (_, { checked }) => {
      const { selectedPrices } = this.state;
      if (checked) {
        selectedPrices.push(price);
        this.setState({ selectedPrices });
      } else {
        this.setState({
          selectedPrices: selectedPrices.filter(
            selectedPrice => selectedPrice !== price
          )
        });
      }
    };
  };

  openEditDialog = (selectedPrices, selectedPage) => {
    return () => {
      const defaultPrice = this.props.defaultPrice || {
        price: 0,
        costs_attributes: []
      };
      /*
       * Costs
       */
      const defaultCosts = defaultPrice.costs_attributes.reduce((accu, c) => {
        accu[c.trade] = c.cost;
        return accu;
      }, {});
      const creditMemoCosts = cloneDeep(defaultCosts);
      const priceWithMinExtraCosts = head(sortBy(selectedPrices, "extra_cost"));
      const extraCosts = priceWithMinExtraCosts.costs_attributes.reduce(
        (accu, c) => {
          accu[c.trade] = c.cost - defaultCosts[c.trade];
          return accu;
        },
        {}
      );
      const priceWithMinExcessCosts = head(
        sortBy(selectedPrices, "excess_cost")
      );
      const excessCosts = priceWithMinExcessCosts.costs_attributes.reduce(
        (accu, c) => {
          accu[c.trade] = c.excess_cost || c.cost;
          return accu;
        },
        {}
      );
      const hasExcessCosts = selectedPrices.some(price => {
        return price.costs_attributes.some(c => {
          return isNumber(c.excess_cost);
        });
      });
      const priceWithMinCosts = head(sortBy(selectedPrices, "cost"));
      const costs = priceWithMinCosts.costs_attributes.reduce((accu, c) => {
        accu[c.trade] = c.cost;
        return accu;
      }, {});
      /*
       * Prices
       */
      const price = min(selectedPrices.map(price => price.price));
      const extraPrice = min(selectedPrices.map(price => price.extra_price));
      const excessPrice = min(selectedPrices.map(price => price.excess_price));
      const creditMemo = min(selectedPrices.map(price => price.price));
      const hasExcessPrice = selectedPrices.some(price => {
        return isNumber(price.excess_price);
      });

      this.setState({
        dialog: {
          selectedPrices,
          selectedPage,
          price,
          creditMemo,
          extraPrice,
          excessPrice,
          hasExcessPrice,
          costs,
          creditMemoCosts,
          defaultCosts,
          extraCosts,
          excessCosts,
          hasExcessCosts
        }
      });
    };
  };

  editSinglePrice = (price, field) => {
    return this.openEditDialog([price], field);
  };

  toggleBulkEditorDialog = isVisible => {
    if (isVisible) {
      this.openEditDialog(this.state.selectedPrices)();
    } else {
      this.setState({ dialog: null, selectedPrices: [] });
    }
  };

  renderPriceTable = prices => {
    const rows = prices.map(price => {
      return (
        <Table.Row
          key={price.id}
          data-model="product_price"
          data-product-name={price.product.name}
        >
          <Table.Cell>
            <ProductInfoItem product={price.product} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "credit_memo")}
            className="editable"
            data-attr="credit_price"
          >
            <FormatCurrency amount={price.price} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "excessPrice")}
            className="editable"
            data-attr="excess_price"
          >
            <FormatCurrency amount={price.excess_price || price.price} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "creditMemoCost")}
            className="editable"
            data-attr="credit_costs"
          >
            <FormatCurrency amount={price.cost} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "excessCost")}
            className="editable"
            data-attr="excess_costs"
          >
            <FormatCurrency amount={price.excess_cost || price.cost} />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table celled structured columns={11} attached="top" key="defaultPrices">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5} />
            <Table.HeaderCell colSpan={2} textAlign="center">
              <FormattedMessageWithFlag
                featureToggleName="show_price_net_label"
                id="product_price.attributes.price.buyer.net"
                alternativeId="product_price.attributes.price.buyer.gross"
              />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={2} textAlign="center">
              <FormattedMessage id="product_price.attributes.cost.label" />
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell width={5}>Standardprodukt</Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Gutschrift (GS) bei Rücktritt vom Standardprodukt"
              className="help"
            >
              Gutschrift
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Einheitspreis (EP) für Mehrmenge"
              className="help"
            >
              Einheitspreis
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Gutschrift (GS) bei Rücktritt vom Standardprodukt"
              className="help"
            >
              Gutschrift
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Einheitskosten (EK) für Mehrmenge"
              className="help"
            >
              Einheitskosten
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  renderAlternativePricesTable = prices => {
    if (!prices.length) return null;
    const { selectedPrices } = this.state;
    const rows = prices.map(price => {
      return (
        <Table.Row
          key={price.id}
          data-model="product_price"
          data-product-name={price.product.name}
        >
          <Table.Cell>
            <CustomLabelCheckbox
              checked={selectedPrices.includes(price)}
              onChange={this.selectPrice(price)}
            >
              <ProductInfoItem product={price.product} />
            </CustomLabelCheckbox>
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "extra_price")}
            className="editable"
            data-attr="extra_price"
          >
            <FormatCurrency amount={price.extra_price} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "excessPrice")}
            className="editable"
            data-attr="excess_price"
          >
            <FormatCurrency amount={price.excess_price || price.price} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "extraCost")}
            className="editable"
            data-attr="extra_costs"
          >
            <FormatCurrency amount={price.extra_cost} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "excessCost")}
            className="editable"
            data-attr="excess_costs"
          >
            <FormatCurrency amount={price.excess_cost || price.cost} />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table celled structured columns={11} attached key="alternativePrices">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5} style={{ maxWidth: "200px" }}>
              <Checkbox
                label="Alternativprodukt"
                checked={selectedPrices.length === prices.length}
                onChange={this.selectAllAlternativePrices}
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Mehrpreis (MP) gegenüber dem Standardprodukt"
              className="help"
            >
              Mehrpreis
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Einheitspreis (EP) für Mehrmenge"
              className="help"
            >
              Einheitspreis
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Mehrkosten (MK) gegenüber dem Standardprodukt"
              className="help"
            >
              Mehrkosten
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Einheitskosten (EK) für Mehrmenge"
              className="help"
            >
              Einheitskosten
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  renderSimplePricesTable = prices => {
    if (!prices.length) return null;
    const { selectedPrices } = this.state;

    const rows = prices.map(price => {
      return (
        <Table.Row
          key={price.id}
          data-model="product_price"
          data-product-name={price.product.name}
        >
          <Table.Cell>
            <CustomLabelCheckbox
              checked={selectedPrices.includes(price)}
              onChange={this.selectPrice(price)}
            >
              <ProductInfoItem product={price.product} />
            </CustomLabelCheckbox>
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "price")}
            className="editable"
            data-attr="excess_price"
          >
            <FormatCurrency amount={price.price} />
          </Table.Cell>
          <Table.Cell
            textAlign="right"
            onClick={this.editSinglePrice(price, "cost")}
            className="editable"
            data-attr="excess_costs"
          >
            <FormatCurrency amount={price.cost} />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table celled structured columns={11} attached="top" key="prices">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              <Checkbox
                label="Produkt"
                checked={selectedPrices.length === prices.length}
                onChange={this.selectAllAlternativePrices}
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Preis"
              className="help"
            >
              <FormattedMessageWithFlag
                featureToggleName="show_price_net_label"
                id="product_price.attributes.price.unit.net"
                alternativeId="product_price.attributes.price.unit.gross"
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              width={1}
              textAlign="right"
              title="Kosten"
              className="help"
            >
              <FormattedMessage id="product_price.attributes.price.unit.cost" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };
}

const sanitizeAmount = amount => {
  if (amount) {
    return Math.round(amount * 100) / 100;
  }
  return amount;
};

const sanitizePrices = prices => {
  prices.forEach(price => {
    price.price = sanitizeAmount(price.price);
    price.excess_price = sanitizeAmount(price.excess_price);
    (price.costs_attributes || []).forEach(ca => {
      ca.cost = sanitizeAmount(ca.cost);
      ca.excess_cost = sanitizeAmount(ca.excess_cost);
    });
  });
  return prices;
};

ProductPricesDialog.propTypes = {
  account: AccountShape.isRequired,
  priceCatalogId: PropTypes.number.isRequired,
  defaultPrice: ProductPriceShape,
  alternativePrices: PropTypes.arrayOf(ProductPriceShape),
  updatePrices: PropTypes.func.isRequired,
  reloadPrices: PropTypes.func.isRequired
};

ProductPricesDialog.defaultProps = {
  defaultPrice: null,
  alternativePrices: []
};

// Which part of the Redux global state does our component want to receive as props?
const makeMapStateToProps = () => {
  const getDefaultPrice = makeGetDefaultPrice();
  const getAlternativePrices = makeGetAlternativePrices();

  return (state, props) => {
    return {
      account: getAccount(state),
      defaultPrice: getDefaultPrice(state, props),
      alternativePrices: getAlternativePrices(state, props)
    };
  };
};

const makeUpdatePrices = createSelector(
  [
    dispatch => dispatch,
    (_, props) => props.params.productGroupId,
    (_, props) => props.params.catalogId
  ],
  (dispatch, productGroupId, catalogId) => {
    return values => {
      return new ProductGroupResource(catalogId, dispatch).updatePrices(
        productGroupId,
        {
          product_group: {
            prices: sanitizePrices(values)
          }
        }
      );
    };
  }
);

const mapDispatchToProps = (dispatch, props) => {
  return {
    updatePrices: makeUpdatePrices(dispatch, props)
  };
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(ProductPricesDialog)
);
