import React, { useEffect, useState, useMemo } from "react";
import {
  arrayOf,
  object,
  number,
  func,
  bool,
  string,
  oneOfType
} from "prop-types";
import { Menu, Dropdown } from "semantic-ui-react";
import { find } from "lodash";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import ProductCatalogDialog from "builder_portal/components/productCatalog/ProductCatalogDialog";
import HasAccessProjectCatalogRight from "shared/components/authorization/HasAccessProjectCatalogRight";
import "./projectCatalogSelectionMenuBar.scss";
import { useHasEditRightsForProjectCatalog } from "shared/components/authorization/HasEditRightsForProjectCatalog";

const CatalogMenuItem = ({ catalog, active, onClick }) => {
  return (
    <Menu.Item
      name={catalog.name}
      active={active}
      onClick={onClick}
      // todo: make this pretty
      className="ellipsis ellipsis-400"
    >
      {catalog.name}
    </Menu.Item>
  );
};

CatalogMenuItem.propTypes = {
  catalog: ProjectCatalogShape.isRequired,
  onClick: func.isRequired,
  active: bool.isRequired
};

const ProjectCatalogSelectionMenuBar = ({
  customItems,
  customOptions,
  customDropdownText,
  catalogs,
  recentProjectIds,
  handleChange,
  activeProjectId
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [catalogDialogOpen, setCatalogDialogOpen] = useState(false);

  const catalogId = useMemo(() => {
    const catalog = find(catalogs, { slug: activeProjectId });
    return catalog && catalog?.id;
  }, [activeProjectId, catalogs]);

  const hideAdddMenuItem = useHasEditRightsForProjectCatalog(catalogId);

  useEffect(() => {
    setDropdownOpen(false);
  }, [activeProjectId]);

  function renderMenuItems() {
    const recentItems = recentProjectIds.map(id => {
      const catalog = find(catalogs, p => p.id === id || p.slug === id);
      if (!catalog) return null;
      return (
        <HasAccessProjectCatalogRight key={catalog.id}>
          <CatalogMenuItem
            catalog={catalog}
            onClick={() => handleChange(catalog.id)}
            active={
              +activeProjectId === +catalog.id ||
              activeProjectId === catalog.slug
            }
          />
        </HasAccessProjectCatalogRight>
      );
    });

    return [...customItems, ...recentItems];
  }

  const defaultOptions = [
    ...customOptions,
    ...catalogs.map(item => {
      return {
        onClick: (e, { value }) => handleChange(value),
        key: item.id,
        value: item.id,
        text: item.name,
        icon: "building"
      };
    })
  ];

  const concatOptions = [
    ...defaultOptions,
    {
      icon: "plus",
      key: "new",
      text: "Neuer Ausstattungskatalog",
      onClick: () => {
        setCatalogDialogOpen(true);
        setDropdownOpen(false);
      }
    }
  ];

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Menu data-component="ProjectCatalogSelectionMenuBar">
        {renderMenuItems()}
        <HasAccessProjectCatalogRight>
          <Menu.Menu position="right">
            <Dropdown
              item
              search
              scrolling
              value={activeProjectId}
              onOpen={() => setDropdownOpen(true)}
              onClose={() => setDropdownOpen(false)}
              text={customDropdownText}
              icon="search"
              open={dropdownOpen}
              options={hideAdddMenuItem ? concatOptions : defaultOptions}
            />
          </Menu.Menu>
        </HasAccessProjectCatalogRight>
      </Menu>
      <ProductCatalogDialog
        open={catalogDialogOpen}
        catalogs={catalogs}
        onClose={() => setCatalogDialogOpen(false)}
      />
    </div>
  );
};

ProjectCatalogSelectionMenuBar.propTypes = {
  catalogs: arrayOf(ProjectCatalogShape).isRequired,
  recentProjectIds: arrayOf(oneOfType([string, number])).isRequired,
  handleChange: func.isRequired,
  customItems: arrayOf(object),
  customOptions: arrayOf(object),
  customDropdownText: string,
  activeProjectId: oneOfType([string, number])
};

ProjectCatalogSelectionMenuBar.defaultProps = {
  activeProjectId: "",
  customItems: [],
  customOptions: [],
  // todo: add internationalization
  customDropdownText: "Projekt wählen"
};

export default ProjectCatalogSelectionMenuBar;
