import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { TextArea, Select } from "formik-semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage, useIntl } from "react-intl";
import { bool, func, number, shape, string } from "prop-types";
import { ContactsResource } from "builder_portal/actions/contactsActions";
import { getUnitOptions } from "shared/selectors/units";
import { cloneDeep } from "lodash";
import ContactForm from "../ContactForm";
import { UnitProspectListLoaderContext } from "../UnitProspectLoader";

export default function ReasonDialog({ isOpen, setOpen, result }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const droppableId = useMemo(() => result?.destination?.droppableId, [
    result?.destination
  ]);
  const unitOptions = useSelector(getUnitOptions);

  const validationScheme = Yup.object({
    reason: Yup.string().nullable()
  });

  const wonValidationScheme = Yup.object({
    unitId: Yup.number()
      .nullable()
      .required(
        intl.formatMessage({
          id: "message.errorForm.required"
        })
      ),
    contact: Yup.object({
      first_name: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      address_number: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      address_postcode: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      address_street: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      address_town: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      company: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      email: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      last_name: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      phone: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "message.errorForm.required"
          })
        ),
      mobile: Yup.string()
        .nullable()
        .matches(
          /\+(?:[0-9]?){6,14}[0-9]$/,
          intl.formatMessage({
            id: "message.errorForm.mobile"
          })
        )
    })
  });

  const { prospectListResource, loadUnitProspectList } = useContext(
    UnitProspectListLoaderContext
  );

  const initialFormValue = useMemo(
    () => ({ contact: cloneDeep(result?.item?.prospect?.contact) }),
    [result?.item]
  );

  const handleClose = resetForm => {
    setOpen(false);
    if (typeof resetForm === "function") resetForm();
  };

  const onSubmit = values => {
    // visually set new position
    result.updateElements();
    result.updateInitialElements();
    new Promise((resolve, reject) => {
      if (droppableId === "won")
        Promise.all([
          new ContactsResource(dispatch).save(values.contact),
          prospectListResource.accept(result.item.id, {
            reason_won: values.reason,
            unit_id: values.unitId
          })
        ])
          .then(res => resolve(res))
          .catch(err => reject(err));
      if (droppableId === "lost")
        prospectListResource
          .reject(result.item.id, { reason_lost: values.reason })
          .then(res => resolve(res))
          .catch(err => reject(err));
    })
      .then(() => {
        // load additionally changes data
        loadUnitProspectList();
      })
      .catch(() => {
        // reset positio
        loadUnitProspectList();
        new Growl(dispatch).error("message.error.title", "message.error.body");
      });

    handleClose();
  };

  return (
    <Formik
      initialValues={{
        ...initialFormValue,
        unitId: result?.item?.unit_id,
        reason: ""
      }}
      validationSchema={
        droppableId === "lost"
          ? validationScheme
          : validationScheme.concat(wonValidationScheme)
      }
      onSubmit={onSubmit}
      data-component="ReasonContactForm"
      enableReinitialize
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <Modal open={isOpen} onClose={() => handleClose(resetForm)} closeIcon>
            <Modal.Header>
              <If condition={droppableId === "won"}>
                <FormattedMessage id="roomBook.unitProspect.moveDialog.won.title" />
              </If>
              <If condition={droppableId === "lost"}>
                <FormattedMessage id="roomBook.unitProspect.moveDialog.lost.title" />
              </If>
            </Modal.Header>
            <Modal.Content scrolling>
              <div className="ui form">
                <If condition={droppableId === "won"}>
                  <div className="field" style={{ marginBottom: "15px" }}>
                    <label htmlFor="unitId">
                      <FormattedMessage id="roomBook.unitProspect.filter.unit" />
                    </label>
                    <Select
                      fluid
                      selection
                      name="unitId"
                      options={unitOptions}
                      style={{ marginBottom: 0 }}
                      errorPrompt
                    />
                  </div>
                </If>
                <div className="field">
                  <label htmlFor="reason">
                    <If condition={droppableId === "won"}>
                      <FormattedMessage id="roomBook.unitProspect.moveDialog.won.label" />
                    </If>
                    <If condition={droppableId === "lost"}>
                      <FormattedMessage id="roomBook.unitProspect.moveDialog.lost.label" />
                    </If>
                  </label>
                  <TextArea
                    rows={3}
                    style={{ width: "100%", marginBottom: 0 }}
                    name="reason"
                    errorPrompt
                  />
                </div>
                <If condition={droppableId === "won"}>
                  <ContactForm scope="contact" />
                </If>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => handleClose(resetForm)} basic>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button primary onClick={handleSubmit}>
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
}

ReasonDialog.propTypes = {
  isOpen: bool.isRequired,
  setOpen: func.isRequired,
  result: shape({
    destination: shape({
      droppableId: string
    }),
    item: shape({
      id: number
    }),
    updateElements: func.isRequired,
    updateInitialElements: func.isRequired
  })
};

ReasonDialog.defaultProps = {
  result: undefined
};
