import React, { Component } from "react";
import {
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Select,
  Popup,
  Image
} from "semantic-ui-react";
import { connect } from "react-redux";
import { array, func, object } from "prop-types";
import { createStructuredSelector } from "reselect";
import {
  getProductGroupsWithProductIds,
  getProductsById
} from "shared/selectors/unit/productData";
import { isEmpty, sortBy, flatMap, uniq } from "lodash";
import "./productRulesDialog.scss";
import ProductInfoItem from "builder_portal/components/product/ProductInfoItem";
import { getScopeOptions } from "builder_portal/selectors/productGroupRuleSets";
import { getI18N } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import RuleEditor from "./RuleEditor";

const EMPTY_RULE = {
  when: {
    product: {
      id: []
    }
  },
  then: {
    product: {
      id: []
    }
  }
};

const isEmptyRule = rule =>
  isEmpty(rule.when.product.id) && isEmpty(rule.then.product.id);

function getDropdownProps(index, array) {
  return array.length < 1 || index === "new" || index > 1
    ? { upward: true }
    : { upward: false };
}

const findRepeats = a =>
  uniq(a.filter((item, index) => a.indexOf(item) !== index));

class RuleSetEditor extends Component {
  static propTypes = {
    onChange: func.isRequired,
    ruleSet: object.isRequired,
    productGroups: object.isRequired,
    scopeOptions: array.isRequired,
    i18n: object.isRequired,
    errors: object,
    products: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { repeats: [] };
  }

  componentDidMount() {
    this.checkRepeats();
  }

  componentDidUpdate() {
    this.checkRepeats();
  }

  renderProduct = id => {
    const { products } = this.props;
    const product = products[id];

    const image = (
      <div
        className="flex align-items-center"
        style={{
          width: "72px",
          padding: "5px",
          height: "100%",
          background: "white",
          border: "1px solid",
          borderRadius: "0.5rem"
        }}
      >
        <Image src={product.thumb_url} />
      </div>
    );

    return (
      <Popup key={product.id} trigger={image}>
        <ProductInfoItem product={product} />
      </Popup>
    );
  };

  renderWarningMessage = () => {
    const { repeats } = this.state;
    return (
      <Message color="yellow">
        <Message.Content>
          <div className="flex align-items-center">
            <Icon name="warning circle" size="huge" />
            <div style={{ marginLeft: "1rem" }}>
              <FormattedMessage id="productRules.warning.productRepeating" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "72px",
                marginLeft: "2rem"
              }}
            >
              {repeats?.map(this.renderProduct)}
            </div>
          </div>
        </Message.Content>
      </Message>
    );
  };

  render() {
    const { ruleSet, scopeOptions, i18n } = this.props;
    const { repeats } = this.state;

    const hasRules = !isEmpty(ruleSet.rules);
    const hasProductGroupsSelected = this.hasValidProductGroupsSelected();
    const notAllProductsAssigned = this.getMissingWhenProducts().length > 0;

    return (
      <Form>
        <Grid divided="vertically">
          {this.renderErrors()}
          <Grid.Row>
            <Grid.Column width={8}>
              <Form.Select
                options={scopeOptions}
                label={i18n["productRules.headers.scope"]}
                value={ruleSet.rule_scope}
                onChange={this.handlePropertyChange}
                property="rule_scope"
              />
            </Grid.Column>
            <Grid.Column width={8} verticalAlign="bottom">
              <div className="margin bottom small">
                <Form.Checkbox
                  checked={!ruleSet.deactivated}
                  toggle
                  property="deactivated"
                  onChange={this.handleActiveToggle}
                  label={
                    !ruleSet.deactivated
                      ? i18n["productRules.status.active"]
                      : i18n["productRules.status.deactivated"]
                  }
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.TextArea
                label={i18n["productRules.headers.description"]}
                value={ruleSet.description}
                property="description"
                onChange={this.handlePropertyChange}
                rows={2}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.TextArea
                label={i18n["productRules.headers.hint"]}
                value={ruleSet.hint}
                property="hint"
                onChange={this.handlePropertyChange}
                rows={2}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <If condition={!!repeats?.length} block>
                {this.renderWarningMessage()}
              </If>
            </Grid.Column>
          </Grid.Row>
          {hasProductGroupsSelected && hasRules
            ? this.renderRulesHeader()
            : this.renderProductGroupsSelectors()}
          {hasProductGroupsSelected && ruleSet.rules.map(this.renderRule)}
          {hasProductGroupsSelected &&
            this.renderRule(EMPTY_RULE, "new", ruleSet.rules)}
          {notAllProductsAssigned && this.renderNotAllAssignedWarning()}
        </Grid>
      </Form>
    );
  }

  renderNotAllAssignedWarning = () => (
    <Grid.Row>
      <Icon name="warning sign" />
      <FormattedMessage id="productRules.warning.notAllAssinged" />
    </Grid.Row>
  );

  hasValidProductGroupsSelected() {
    const thenProductGroup = this.getThenProductGroup();
    const whenProductGroup = this.getWhenProductGroup();
    return (
      thenProductGroup &&
      whenProductGroup &&
      thenProductGroup !== whenProductGroup
    );
  }

  renderErrors() {
    const { i18n, errors } = this.props;
    if (isEmpty(errors)) {
      return null;
    }
    return (
      <Grid.Row>
        <Grid.Column>
          <Message warning>
            <Message.Header
              content={i18n["activerecord.errors.messages.record_invalid"]}
            />
            <Message.List>
              {Object.entries(errors).map(([attribute, messages]) => (
                <Message.Item key={attribute}>
                  {
                    i18n[
                      `activerecord.attributes.product_group_rule_set.${attribute}`
                    ]
                  }
                  <Message.List>
                    {messages.map((message, index) => (
                      <Message.Item key={index} content={message} />
                    ))}
                  </Message.List>
                </Message.Item>
              ))}
            </Message.List>
          </Message>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderProductGroupsSelectors = () => {
    const { productGroups, ruleSet } = this.props;
    const options = Object.values(productGroups).map(productGroup => ({
      text: productGroup.name,
      image: productGroup.thumb_url,
      value: productGroup.id
    }));

    const sortedOptions = sortBy(options, "text");

    return (
      <Grid.Row verticalAlign="middle">
        <Grid.Column width="7">
          <Select
            fluid
            search
            options={sortedOptions}
            value={ruleSet.product_group_when_id}
            onChange={this.handlePropertyChange}
            property="product_group_when_id"
            id="product_group_when_id"
          />
        </Grid.Column>
        <Grid.Column width="2">
          <Icon name="arrow right" />
        </Grid.Column>
        <Grid.Column width="7">
          <Select
            fluid
            search
            options={sortedOptions}
            value={ruleSet.product_group_then_id}
            onChange={this.handlePropertyChange}
            property="product_group_then_id"
            id="product_group_then_id"
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  handlePropertyChange = (event, { value, property }) => {
    const { onChange, ruleSet } = this.props;
    onChange(event, {
      ...this.props,
      ruleSet: { ...ruleSet, [property]: value }
    });
  };

  handleActiveToggle = event => {
    const { onChange, ruleSet } = this.props;
    onChange(event, {
      ...this.props,
      ruleSet: { ...ruleSet, deactivated: !ruleSet.deactivated }
    });
  };

  renderRulesHeader = () => {
    return (
      <Grid.Row verticalAlign="middle">
        <Grid.Column width="7">
          <Header content={this.getWhenProductGroup().name} />
        </Grid.Column>
        <Grid.Column width="2">
          <Icon name="arrow right" />
        </Grid.Column>
        <Grid.Column width="7">
          <Header content={this.getThenProductGroup().name} />
        </Grid.Column>
      </Grid.Row>
    );
  };

  getWhenProductGroup() {
    const { productGroups, ruleSet } = this.props;
    return productGroups[ruleSet.product_group_when_id];
  }

  getThenProductGroup() {
    const { productGroups, ruleSet } = this.props;
    return productGroups[ruleSet.product_group_then_id];
  }

  getMissingWhenProducts = () => {
    const {
      ruleSet: { rules }
    } = this.props;
    const usedProductIds = flatMap(rules, rule => rule.when.product.id);
    const { productIds } = this.getWhenProductGroup();
    return productIds.filter(id => !usedProductIds.includes(id));
  };

  renderRule = (rule, index, array) => (
    <RuleEditor
      whenProductGroup={this.getWhenProductGroup()}
      thenProductGroup={this.getThenProductGroup()}
      missingWhenProducts={this.getMissingWhenProducts()}
      rule={rule}
      key={index}
      onChange={this.handleRuleChange}
      data-index={index}
      dropdownProps={getDropdownProps(index, array)}
    />
  );

  checkRepeats = () => {
    const { ruleSet } = this.props;
    const { rules } = ruleSet;
    const { repeats } = this.state;

    const ids = [];
    rules.forEach(({ when }) => {
      if (when?.product?.id) ids.push(...when?.product?.id);
    });
    const newRepeats = findRepeats(ids);
    if (repeats.length !== newRepeats.length)
      this.setState({ repeats: newRepeats });
  };

  handleRuleChange = (event, { rule: newRule, "data-index": index }) => {
    const { onChange, ruleSet } = this.props;
    const { rules: oldRules, ...rest } = ruleSet;

    let rules;

    if (index === "new") {
      rules = [...oldRules, newRule];
    } else if (isEmptyRule(newRule)) {
      rules = oldRules.filter((_, i) => i !== index);
    } else {
      rules = oldRules.map((rule, i) => (i === index ? newRule : rule));
    }

    onChange(event, { ...this.props, ruleSet: { ...rest, rules } });
  };
}

const mapStateToProps = createStructuredSelector({
  productGroups: getProductGroupsWithProductIds,
  scopeOptions: getScopeOptions,
  i18n: getI18N,
  products: getProductsById
});

export default connect(mapStateToProps)(RuleSetEditor);
