import React from "react";
import { string, oneOfType, number, bool } from "prop-types";
import UnitProspectLoader from "./UnitProspectLoader";
import KanbanBoard from "./kanbanBoard/KanbanBoard";

const UnitProspect = ({ unitId, projectId, isProjectLevel }) => {
  return (
    <UnitProspectLoader
      unitId={unitId}
      projectId={projectId}
      isProjectLevel={isProjectLevel}
    >
      <KanbanBoard />
    </UnitProspectLoader>
  );
};

UnitProspect.propTypes = {
  unitId: string,
  projectId: oneOfType([string, number]),
  isProjectLevel: bool.isRequired
};

UnitProspect.defaultProps = {
  unitId: null,
  projectId: null
};

export default UnitProspect;
