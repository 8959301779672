import React from "react";
import PropTypes from "prop-types";
import FileDropzone from "../dropzone/FileDropzone";

class AttachmentDropzone extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    resourceId: PropTypes.number,
    resourceName: PropTypes.string,
    documentType: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onLoading: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  handleLoading(isLoading) {
    const { onLoading } = this.props;
    if (onLoading) {
      onLoading(isLoading);
    }
  }

  handleSuccess(attachment) {
    const { onSuccess } = this.props;
    if (onSuccess) {
      onSuccess(attachment);
    }
  }

  handleError(message) {
    const { onError } = this.props;
    if (onError) {
      onError(message);
    }
  }

  render() {
    const { resourceId, resourceName, documentType, i18n } = this.props;

    return (
      <FileDropzone
        id="attachment_selector"
        title={i18n["attachment.actions.dragAndDrop"]}
        className="attachmentDropZone margin top medium"
        resourceName={resourceName}
        resourceId={resourceId}
        additionalData={[{ "attachment[role]": documentType }]}
        onSuccess={::this.handleSuccess}
        onError={::this.handleError}
        toggleLoading={::this.handleLoading}
        i18n={i18n}
        disabled={!documentType}
        disabledMessage={i18n["attachment.actions.selectType"]}
      />
    );
  }
}

export default AttachmentDropzone;
