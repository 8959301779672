import { number, oneOf, string, shape } from "prop-types";

const DeadlineShape = shape({
  completed_at: string, // Date
  created_at: string, // Date
  expirable_id: number,
  expirable_type: oneOf(["Activity"]),
  expires_at: string, // Date
  expiry_ref: oneOf(["response", "request"]),
  expiry_type: oneOf(["status"]),
  id: number,
  updated_at: string // Date
});
export default DeadlineShape;
