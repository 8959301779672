import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors";

const getProject = createSelector([getPageContent], pageContent =>
  get(pageContent, "project", {})
);

export const getProjectUsers = createSelector([getPageContent], pageContent =>
  get(pageContent, "project_users", [])
);

export const getSalesUserOptions = createSelector(
  [getProjectUsers, getProject],
  (projectUsers, project) => {
    const currentProjectsUsers =
      projectUsers.find(p => p.id === project?.id)?.project_memberships || [];

    return currentProjectsUsers.reduce(
      (projectMemberships, projectMembership) => {
        const { user } = projectMembership;
        projectMemberships.push({
          key: user?.id,
          value: user?.id,
          text: user?.label
        });

        return projectMemberships;
      },
      []
    );
  }
);
