import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import {
  Table,
  Dropdown,
  Header,
  Segment,
  Pagination,
  Icon
} from "semantic-ui-react";
import {
  getEmailDeliveries,
  getEmailDeliveryStatistics
} from "builder_portal/selectors/emailDeliveries";
import {
  getBuyers,
  getUsers,
  getContractors,
  getContacts
} from "builder_portal/selectors/masterdata";
import { EmailDeliveriesResource } from "builder_portal/actions/emailDeliveries";
import { uniqBy } from "lodash";

const DEFAULT_LIMIT = 50;

const FILTER_OPTIONS = [
  { key: "blocked", value: "blocked", text: "blocked" },
  { key: "bounce", value: "bounce", text: "bounce" },
  { key: "sent", value: "sent", text: "sent" },
  { key: "spam", value: "spam", text: "spam" },
  { key: "queued", value: "queued", text: "queued" }
];

const RecipientsSendStatus = () => {
  const intl = useIntl();
  const [activePage, setActivePage] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const dispatch = useDispatch();
  const emailDeliveries = useSelector(getEmailDeliveries);
  const { total } = useSelector(getEmailDeliveryStatistics);

  const buyers = useSelector(getBuyers);
  const users = useSelector(getUsers);
  const contractors = useSelector(getContractors);
  const contacts = useSelector(getContacts);

  const emailFilterOptions = useMemo(() => {
    const icons = {
      buyer: "child",
      contractor: "truck",
      user: "user",
      contact: "address card outline"
    };

    const allContacts = uniqBy(
      users
        .concat(contractors)
        .concat(contacts)
        .concat(buyers),
      "email"
    );

    return allContacts.map(x => ({
      key: x.email,
      value: x.email,
      text: `${x.label} (${x.email})`,
      content: (
        <Header as="h5">
          <Icon name={icons[x.type]} />
          <Header.Content>
            {x.label}
            <Header.Subheader>{x.email}</Header.Subheader>
          </Header.Content>
        </Header>
      )
    }));
  }, [buyers, users, contractors, contacts, emailDeliveries]);

  const getParams = () => {
    const params = {
      limit: DEFAULT_LIMIT,
      page: activePage
    };
    if (filterStatus) params.status = filterStatus;
    if (filterEmail) params.email = filterEmail;
    return params;
  };

  useEffect(() => {
    const resource = new EmailDeliveriesResource(dispatch);
    resource.fetchAll(getParams());
  }, [activePage, filterStatus, filterEmail]);

  const handlePageChange = (e, { activePage: page }) => {
    setActivePage(page);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil((total || 0) / DEFAULT_LIMIT);

    return (
      <Pagination
        disabled={totalPages < 2}
        onPageChange={handlePageChange}
        activePage={activePage}
        totalPages={totalPages}
      />
    );
  };

  return (
    <>
      <Segment>
        <Table>
          <Table.Header>
            <Table.HeaderCell width={2}>
              <Dropdown
                placeholder={intl.formatMessage({
                  id: "email.attributes.status.label"
                })}
                fluid
                clearable
                selection
                onChange={(e, { value }) => setFilterStatus(value)}
                value={filterStatus}
                options={FILTER_OPTIONS}
                style={{ width: "100px" }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Dropdown
                placeholder={intl.formatMessage({
                  id: "email.attributes.email.label"
                })}
                fluid
                clearable
                selection
                search
                onChange={(e, { value }) => setFilterEmail(value)}
                value={filterEmail}
                options={emailFilterOptions}
                style={{ width: "220px" }}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="email.attributes.subject.label" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="email.attributes.created_at.label" />
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {emailDeliveries.map(email => (
              <Table.Row key={email.id}>
                <Table.Cell>{email.delivery_status}</Table.Cell>
                <Table.Cell>{email.email}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={`/messages/${email.message_thread_id}#message-${email.message_id}`}
                  >
                    {email.subject}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h6">
                    {`am ${moment(email.created_at).format(
                      "DD. MMM YYYY HH:mm"
                    )}`}
                  </Header>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment textAlign="center">{renderPagination()}</Segment>
    </>
  );
};

export default RecipientsSendStatus;
