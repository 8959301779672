import { UPDATE_FILTER } from "../constants/appConstants";

export const setFilter = dispatch => {
  return payload => {
    return dispatch({
      type: UPDATE_FILTER,
      payload
    });
  };
};

export const setOrderStatusFilter = dispatch => {
  return payload => {
    return dispatch({
      type: UPDATE_FILTER,
      payload: {
        bpOrderStatusFilter: payload
      }
    });
  };
};

export const setUnitProjectActivityFilter = (dispatch, projectID, unitID) => {
  return payload => {
    return dispatch({
      type: UPDATE_FILTER,
      payload: {
        projectsUnits: {
          [`${projectID}_${unitID}`]: {
            activities: payload
          }
        }
      }
    });
  };
};
