import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import ProjectForm from "./ProjectForm";
import { browserHistory } from "../../../shared/routes/browserHistory";

class ProjectDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    isVisible: PropTypes.bool,
    project: PropTypes.object,
    button: PropTypes.node,
    resource: PropTypes.object,
    statusOptions: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.state = {
      open: false
    };
  }

  onSave(values) {
    const {
      project: { id, slug },
      resource
    } = this.props;
    return this.props.resource.save({ ...values, id }).then(() => {
      return this.props.resource.get(id).then(() => {
        resource.fetchCustomImportConfigs(id);
        this.handleToggleDialog(false);
        browserHistory.push(`/projects/${slug}`);
      });
    });
  }

  handleToggleDialog(open) {
    this.setState({ open });
  }

  render() {
    const { i18n, button, resource, statusOptions } = this.props;
    const { open } = this.state;
    const project = this.props.project || {};
    return (
      <Modal
        data-component="projectDialog"
        closeIcon
        closeOnEscape
        closeOnDimmerClick={false}
        trigger={button}
        open={open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id={
              project.id
                ? "project.actions.edit.label"
                : "project.actions.new.label"
            }
            defaultMessage={
              project.id
                ? "project.actions.edit.label"
                : "project.actions.new.label"
            }
          />
        </Modal.Header>
        <ProjectForm
          save={this.onSave}
          model={project}
          i18n={i18n}
          resource={resource}
          statusOptions={statusOptions}
          onClose={() => this.handleToggleDialog(false)}
        />
      </Modal>
    );
  }
}

export default ProjectDialog;
