import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Header } from "semantic-ui-react";
import "./confirmationPopover.scss";

class ConfirmationPopover extends Component {
  static propTypes = {
    open: PropTypes.bool,
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    cancelButton: PropTypes.string,
    confirmButton: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      open,
      content,
      header,
      cancelButton,
      confirmButton,
      onCancel,
      onConfirm,
      isLoading
    } = this.props;
    return (
      <div
        data-component="confirmationPopover"
        className={(open && "visible") || null}
      >
        <div className="contentContainer">
          <Header>{header}</Header>
          <div className="content">{content}</div>
        </div>
        <Button.Group widths="2">
          <Button
            id="cancel"
            as="div"
            fluid
            content={cancelButton}
            onClick={onCancel}
            loading={isLoading}
          />
          <Button
            id="confirm"
            as="div"
            fluid
            primary
            content={confirmButton}
            onClick={onConfirm}
            loading={isLoading}
          />
        </Button.Group>
      </div>
    );
  }
}

export default ConfirmationPopover;
