import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { browserHistory } from "shared/routes/browserHistory";

const AuthGuard = ({ children }) => {
  const hasUserAdminRights = useSelector(
    state => state?.account?.account?.account_role?.allow_edit_account_settings
  );

  useEffect(() => {
    if (!hasUserAdminRights) {
      browserHistory.push("/noAccess");
    }
  }, [hasUserAdminRights]);

  return !hasUserAdminRights ? null : children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
