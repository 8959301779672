import scrollToElement from "scroll-to-element";

export const scrollTo = selector => {
  setTimeout(() => {
    const element = window.document.querySelector(selector);
    scrollToElement(element, {
      offset: -10,
      ease: "out-circ",
      duration: 2000
    });
  }, 50);
};

export const handleScrollTop = (offset = -75) => {
  const element = window.document.querySelector('[data-component="pageBody"]');
  window.scrollTo(0, element.offsetTop + offset);
};

export const jumpTo = selector => {
  const element = window.document.querySelector(selector);
  window.requestAnimationFrame(() => {
    return scrollToElement(element, {
      offset: -10,
      ease: "out-circ",
      duration: 50
    });
  });
};

export const jumpToElement = selector => {
  const element = window.document.querySelector(selector);
  element?.scrollIntoView({
    block: "start",
    inline: "nearest",
    behavior: "smooth"
  });
};
