import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FormField } from "semantic-ui-react";

const TimePicker = props => {
  const { onChange, defaultValue, value, label } = props;

  return (
    <FormField
      style={{
        width: "100%"
      }}
    >
      <label htmlFor={label}>{label}</label>
      <DatePicker
        showTimeSelect
        // showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="dd/MM/yyyy h:mm aa"
        onChange={onChange}
        defaultValue={defaultValue}
        selected={value ? moment(value).toDate() : null}
        isClearable
        showMonthDropdown
        showYearDropdown
      />
    </FormField>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string
};

TimePicker.defaultProps = {
  onChange: () => {},
  defaultValue: "",
  value: null,
  label: ""
};

export default TimePicker;
