import React, { useEffect, useState } from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";
import { PropTypes } from "prop-types";

const TemplatesLoader = ({ setTemplate }) => {
  const [templates, setTemplates] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetch(`https://configurator.baudigital.com/api/v1/dashboard_templates`)
      .then(res => res.json())
      .then(data => setTemplates(data.dashboard_templates || []))
      .catch(silentHandleApiRequestErrors);
  }, []);

  return (
    <Modal
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={
        <Button basic fluid onClick={e => e.preventDefault()}>
          <FormattedMessage id="configuratorDesign.dashboard.labels.importTemplates" />
        </Button>
      }
    >
      <Modal.Header>
        <FormattedMessage id="configuratorDesign.dashboard.labels.templates" />
      </Modal.Header>
      <Modal.Content>
        {templates.map(template => (
          <Button
            key={template.id}
            fluid
            basic
            style={{ width: "90%", margin: "10px auto" }}
            onClick={() => setTemplate(template)}
          >
            <Header as="h4">{template.name}</Header>
            <div>{template.name}</div>
          </Button>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button basic id="cancel" onClick={handleClose}>
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

TemplatesLoader.propTypes = {
  setTemplate: PropTypes.func.isRequired
};

export default TemplatesLoader;
