import React, { useState, useRef, useEffect } from "react";
import { Button, Icon, List, Modal, Header, Form } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConfiguratorDesignShape } from "shared/shapes/configuratorDesign.shape";
import PropTypes from "prop-types";
import { cloneDeep, lowerFirst } from "lodash";
import JsonEditor from "shared/components/forms/JsonEditor";
import AddNewCard from "./AddNewCardDialog";
import RenderListItem from "./RenderListItem";

const CardElement = ({
  item: currentItem,
  index,
  addNewCardElement,
  deleteItem,
  deleteCardItem,
  moveItem,
  moveCardItem,
  handleChange,
  handleCartItemChange,
  fieldName,
  configuratorDesign,
  handleCardCssChange
}) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState(currentItem);
  const previousValue = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    if (previousValue.current) setItem(previousValue.current);
  };
  const handleApply = () => {
    setOpen(false);
    previousValue.current = item;
  };
  const handleCancel = () => {
    handleChange(index, cloneDeep(previousValue.current));
    setOpen(false);
  };

  const handleAddNewCard = element => {
    addNewCardElement(item.id, element);
  };

  useEffect(() => setItem(currentItem), [
    currentItem,
    currentItem.items.length
  ]);
  useEffect(() => {
    previousValue.current = cloneDeep(currentItem);
  }, []);

  const renderEdit = editItem => {
    return (
      <Modal
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleCancel}
        trigger={<Icon link name="edit" />}
        closeIcon
      >
        <Modal.Header>{editItem.type}</Modal.Header>
        <Modal.Content>
          <List>
            {editItem.items.map((cardItem, cardItemIndex) => (
              <RenderListItem
                item={cardItem}
                cardId={editItem.id}
                key={cardItem.id}
                deleteItem={deleteCardItem}
                moveItem={moveCardItem}
                index={cardItemIndex}
                jsonConfigLength={editItem.items.length}
                fieldName={fieldName}
                handleChange={handleCartItemChange}
                configuratorDesign={configuratorDesign}
              />
            ))}

            <AddNewCard
              onAddElement={handleAddNewCard}
              trigger={
                <Button style={{ margin: "20px 0" }} fluid type="button">
                  <Icon link name="plus" />{" "}
                  <FormattedMessage id="configuratorDesign.dashboard.labels.addCard" />
                </Button>
              }
            />
            <List.Item>
              <Header as="h4" style={{ marginBottom: 10 }}>
                {useIntl().formatMessage({
                  id: "configuratorDesign.dialog.contentPageEditor.presentation"
                })}
              </Header>
              <Form>
                <JsonEditor
                  name="css"
                  label={
                    <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.css" />
                  }
                  value={editItem.css || {}}
                  onChange={(e, { value }) => {
                    handleCardCssChange(editItem.id, value);
                  }}
                />
              </Form>
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button basic id="cancel" onClick={handleCancel}>
            <FormattedMessage id="meta.actions.cancel" />
          </Button>
          <Button primary id="apply" onClick={handleApply}>
            <FormattedMessage id="meta.actions.apply" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <List.Item key={item.id}>
      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
        <List.Content floated="right">
          <Icon
            link
            name="chevron up"
            onClick={() => moveItem(index, "up")}
            disabled={index === 0}
          />
          <Icon
            link
            name="chevron down"
            onClick={() => moveItem(index, "down")}
            disabled={index === item.items.length - 1}
          />
          {renderEdit(item, index)}
          <Icon link name="trash alternate" onClick={() => deleteItem(index)} />
        </List.Content>
        <List.Content>
          <FormattedMessage
            id={`configuratorDesign.dashboard.${lowerFirst(item.type)}.type`}
          />
        </List.Content>
      </div>
    </List.Item>
  );
};

CardElement.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array
  }).isRequired,
  addNewCardElement: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  moveCardItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  deleteCardItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleCartItemChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  configuratorDesign: ConfiguratorDesignShape.isRequired,
  handleCardCssChange: PropTypes.func.isRequired
};

export default CardElement;
