import React from "react";
import {
  array,
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string
} from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  Button,
  ButtonGroup,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Segment
} from "semantic-ui-react";
import HasProjectAccessRight from "shared/components/authorization/HasProjectAccessRight";
import { get, groupBy } from "lodash";
import { Link } from "react-router";
import { connect } from "react-redux";
import AdvancedRoomBookCreatorDialog from "builder_portal/components/roomBook/AdvancedRoomBookCreatorDialog";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import ConfiguratorDesignsResource from "builder_portal/actions/configuratorDesignActions";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { If } from "shared/components/elements/Conditions";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getCustomImportConfigs } from "shared/selectors/customImportConfigs";
import {
  RoomBookShape,
  UnitShape,
  CustomImportConfigsShape
} from "shared/shapes";
import { getSortedSections } from "../../../../shared/selectors/sections";
import { browserHistory } from "../../../../shared/routes/browserHistory";
import SectionDialog from "../../section/SectionDialog";
import UnitsDialog from "../../unit/UnitsDialog";
import UnitDialog from "../../unit/UnitDialog";
import RoomBookCreator from "../../roomBook/RoomBookCreator";
import RoomBookProgress from "../RoomBookProgress";
import ProjectBuyersResource from "../../../actions/buyerActions";
import { ProjectsResource } from "../../../actions/projectActions";
import SectionsResource from "../../../actions/sectionActions";
import { UnitsResource } from "../../../actions/unitActions";
import silentHandleApiRequestErrors from "../../../../shared/helpers/silentHandleApiRequestErrors";
import EditUnitButton from "./editUnitButton";
import "./sectionAndUnit.scss";
import EditSectionButton from "./editSectionButton";
import MarketingPortalLink from "../../roomBook/marketingPortalLink";
import { getAccount, getI18N } from "../../../../shared/selectors";
import BuyerPortalLink from "../../roomBook/buyerPortalLink";
import DropdownFilters from "../DropdownFilters";
import SectionAndUnitUploadModal from "./SectionAndUnitUploadModal";
import { ProjectStoreysResource } from "../../../actions/projectStoreysActions";
import ProjectStoreysDialog from "../../unit/ProjectStoreysDialog";
import CustomUploadModal from "./CustomUploadModal";

// get values from localstorage
const getSectionAndUnitValues = (name, projectId) => {
  const retrievedObject = localStorage.getItem("sectionAndUnitValues");

  const parsedRetrievedObject = JSON.parse(retrievedObject || "{}");

  const foundObject = parsedRetrievedObject[`${name}-${projectId}`];

  return {
    sectionFilter: foundObject?.sectionFilter || "all",
    accountManagerFilter: foundObject?.accountManagerFilter || "all",
    salesStatusFilter: foundObject?.salesStatusFilter || "all",
    unitFeatureId: foundObject?.unitFeatureId || "all"
  };
};

class SectionAndUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "idle",
      unitIdsToHighlight: [],
      sectionFilter: props.userConfig.getSectionFilter(props.project.slug) || ""
    };
  }

  componentDidMount() {
    const {
      buyersResource,
      projectResource,
      project,
      unitFeatureResource,
      configuratorDesignsResource,
      projectStoreysResource
    } = this.props;

    this.setState({ status: "loading" });
    projectStoreysResource.fetchAll();
    buyersResource.fetchAll();
    projectResource
      .fetchStructure(project.id)
      .catch(silentHandleApiRequestErrors)
      .then(() => this.setState({ status: "idle" }));
    projectResource
      .fetchUnitStatistics(project.id)
      .catch(silentHandleApiRequestErrors);
    projectResource.fetchMembers(project.id);
    unitFeatureResource.fetchAll();
    configuratorDesignsResource.fetchAll();
  }

  static getDerivedStateFromProps(props, state) {
    const { project } = props;

    const initialFiltersValue = getSectionAndUnitValues(
      project.slug,
      project.id
    );

    return {
      ...state,
      ...initialFiltersValue
    };
  }

  setFilter = (filterName, filterId) => {
    this.setState({ [filterName]: filterId });
  };

  onUnitsCreated = units => {
    this.setState({
      unitIdsToHighlight: units.map(unitItem => {
        return unitItem.id;
      })
    });
  };

  onSectionSave = section => {
    const { sectionFilter } = this.state;

    if (sectionFilter && sectionFilter !== section.id.toString()) {
      this.setState({ sectionFilter: section.id });
    }
  };

  onSectionRemove = section => {
    const { userConfig, project } = this.props;
    const { sectionFilter } = this.state;

    if (sectionFilter === section.id.toString()) {
      userConfig.removeSectionFilter(project.slug);
      this.setState({ sectionFilter: "" });
    }
  };

  mapFiltersValue = value => {
    if (!value || value === "none") {
      return undefined;
    }
    return value.toString();
  };

  unitsBySection() {
    const { sections, units } = this.props;
    const {
      sectionFilter,
      accountManagerFilter,
      salesStatusFilter,
      unitFeatureId
    } = this.state;

    if (!units) {
      return null;
    }

    const groupedUnits = groupBy(units, unitItem => {
      return unitItem.section_id;
    });

    const filter = sectionFilter;
    const filteredSection = sections.find(
      section => section.id.toString() === filter.toString()
    );
    const filteredSections = filteredSection ? [filteredSection] : sections;

    const filteredSectionsOnly = filteredSections.map(sectionItem => {
      return {
        section: sectionItem,
        units: (groupedUnits[sectionItem.id] || []).sort((a, b) => {
          return a.unit_number.localeCompare(b.unit_number, undefined, {
            numeric: true
          });
        })
      };
    });

    const filteredSectionsAndUnits = filteredSectionsOnly.map(sectionItem => {
      const { section, units: allUnits } = sectionItem;
      const filteredUnits = allUnits
        .filter(unitItem => {
          return (
            this.mapFiltersValue(accountManagerFilter) === "all" ||
            [
              unitItem.account_manager_id?.toString(),
              unitItem.sales_manager_id?.toString()
            ].includes(this.mapFiltersValue(accountManagerFilter))
          );
        })
        .filter(unitItem => {
          return (
            this.mapFiltersValue(salesStatusFilter) === "all" ||
            unitItem.sales_status?.toString() ===
              this.mapFiltersValue(salesStatusFilter)
          );
        })
        .filter(unitItem => {
          return (
            this.mapFiltersValue(unitFeatureId) === "all" ||
            unitItem.unit_feature_ids.includes(unitFeatureId)
          );
        });
      return { section, units: filteredUnits };
    });
    return filteredSectionsAndUnits;
  }

  sectionOptions() {
    const { sections } = this.props;
    return sections.map(sectionItem => {
      return {
        key: sectionItem.id,
        value: sectionItem.id,
        text: `${sectionItem.code}: ${sectionItem.name}`
      };
    });
  }

  renderMarketingLabel(unitItem) {
    const { account } = this.props;

    const hasRoomBook = !!unitItem.room_book_id;

    return (
      <MarketingPortalLink
        label={unitItem.description || undefined}
        linkProps={{
          disabled: !hasRoomBook || !account.isEnabled("buyer_portal"),
          onlyActiveOnIndex: true,
          to: unitItem.marketing_portal_url
        }}
        buttonProps={{
          compact: true,
          fluid: true
        }}
      />
    );
  }

  renderBuyerLabel(unitItem) {
    const { account } = this.props;

    const hasRoomBook = !!unitItem.room_book_id;
    return (
      <BuyerPortalLink
        buyers={unitItem.buyers}
        linkProps={{
          disabled: !hasRoomBook || !account.isEnabled("buyer_portal"),
          onlyActiveOnIndex: true,
          to: unitItem.buyer_portal_url
        }}
        buttonProps={{
          compact: true
        }}
      />
    );
  }

  renderSection(sectionItem, sectionIndex) {
    const { sections, i18n, sectionsResource, project } = this.props;
    const isLastSection = sectionIndex === sections.length - 1;

    return (
      <Segment
        data-model="section"
        data-id={sectionItem.section.id}
        data-component="sectionListItem"
        key={sectionItem.section.id}
      >
        <div className="sectionHeader">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="6">
              <Icon color="grey" name="cubes" />
              <Header className="left floated element">
                <span data-attr="code">{sectionItem.section.code}</span>:{" "}
                <span data-attr="name">{sectionItem.section.name}</span>
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="right" width="10">
              <HasProjectAccessRight
                right="allow_access_edit_units"
                projectId={project.id}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                    justifyContent: "flex-end"
                  }}
                >
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink
                      id={sectionItem.section.id}
                      type="Section"
                    />
                  </IsVersionHistoryAccessible>
                  <ButtonGroup>
                    <SectionDialog
                      button={
                        <div>
                          <EditSectionButton />
                        </div>
                      }
                      i18n={i18n}
                      resource={sectionsResource}
                      section={sectionItem.section}
                      onSave={this.onSectionSave}
                      onRemove={this.onSectionRemove}
                    />
                  </ButtonGroup>
                </div>
              </HasProjectAccessRight>
            </Grid.Column>
          </Grid>
        </div>
        <List divided verticalAlign="middle" data-component="unitList">
          {this.renderUnits(sectionItem, isLastSection)}
        </List>
      </Segment>
    );
  }

  renderUnits(section, isLastSection) {
    const { unitStatistics } = this.props;
    const progressStatistics = groupBy(unitStatistics.by_unit, "unit");
    if (section.units.length > 0) {
      return section.units.map((unit, idx) => {
        return this.renderUnit(
          unit,
          section.units.length - idx,
          section.units.length,
          isLastSection,
          progressStatistics
        );
      });
    }
    return (
      <FormattedMessage
        id="unit.message.noRecords"
        defaultMessage="unit.message.noRecords"
        key="noRecords"
      />
    );
  }

  renderUnit = (
    unitItem,
    position,
    length,
    isLastSection,
    progressStatistics
  ) => {
    const { unitsResource, i18n, project, roomBooks, account } = this.props;
    const projectId = project.slug;
    const { unitIdsToHighlight } = this.state;

    const opensUpwards = isLastSection && position < 5;
    const advancedRoomBookCreationFeatureActive = account.isEnabled(
      "advanced_room_book_creation"
    );

    const showVersionLinks =
      account.isSystemAdmin() ||
      account.hasAccountRoleRight("ext_allow_access_version_history");

    return (
      <List.Item
        data-model="unit"
        data-component="unitListItem"
        className={unitIdsToHighlight.indexOf(unitItem.id) >= 0 ? "flash" : ""}
        key={unitItem.id}
      >
        <List.Content>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width={3}>
              <Link
                onlyActiveOnIndex
                to={`/projects/${project.slug}/units/${unitItem.id}/room-book`}
                title={account.isSystemAdmin() ? `ID: ${unitItem.id}` : ""}
              >
                <Icon color="grey" name="cube" />
                <Header size="tiny" className="left floated element">
                  <p data-attr="display_name">{unitItem.display_name}</p>
                </Header>
              </Link>
            </Grid.Column>
            <Grid.Column width={1}>
              {this.renderDeadlines(unitItem)}
            </Grid.Column>
            <Grid.Column width={3}>
              {this.renderProgress(unitItem, progressStatistics)}
            </Grid.Column>
            <Grid.Column width={3}>
              {unitItem.room_book_id && (
                <Link
                  onlyActiveOnIndex
                  to={`/projects/${project.slug}/units/${unitItem.slug}/room-book`}
                  title={
                    account.isSystemAdmin()
                      ? `ID: ${unitItem.room_book_id}`
                      : ""
                  }
                >
                  <Button
                    basic
                    compact
                    fluid
                    icon="book"
                    className="roomBookLink"
                    content={i18n["roomBook.actions.goto"]}
                  />
                </Link>
              )}
              <HasProjectAccessRight
                right="allow_access_edit_units"
                projectId={project.id}
              >
                {!unitItem.room_book_id &&
                  (advancedRoomBookCreationFeatureActive ? (
                    <AdvancedRoomBookCreatorDialog
                      unitId={unitItem.id}
                      projectId={unitItem.project_id}
                      onCreate={() => {
                        browserHistory.push(
                          `/projects/${project.slug}/units/${unitItem.slug}/room-book`
                        );
                      }}
                    />
                  ) : (
                    <RoomBookCreator
                      unit={unitItem}
                      templates={roomBooks}
                      pointing={opensUpwards ? "bottom" : "top"}
                      onCreate={() => {
                        browserHistory.push(
                          `/projects/${project.slug}/units/${unitItem.slug}/room-book`
                        );
                      }}
                    />
                  ))}
              </HasProjectAccessRight>
            </Grid.Column>
            <Grid.Column width={4}>
              <If condition={unitItem.sample}>
                {this.renderMarketingLabel(unitItem)}
              </If>
              <If condition={!unitItem.sample}>
                <div
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center"
                  }}
                >
                  {this.renderBuyerLabel(unitItem)}
                  {!unitItem.sample &&
                    !!unitItem.room_book_id &&
                    !unitItem.buyers.length && (
                      <Button
                        compact
                        basic
                        icon
                        color="purple"
                        as={Link}
                        href={unitItem.marketing_portal_url}
                        target="_mp"
                      >
                        <Icon name="building" />
                      </Button>
                    )}
                </div>
              </If>
            </Grid.Column>
            <Grid.Column textAlign="right" width={2}>
              <div
                style={{
                  display: "flex",
                  gap: "0.25rem",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <If condition={showVersionLinks}>
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink id={unitItem.id} type="Unit" />
                  </IsVersionHistoryAccessible>
                </If>
                <UnitDialog
                  button={
                    <div>
                      <EditUnitButton />
                    </div>
                  }
                  i18n={i18n}
                  handleUpdated={this.onUnitsCreated}
                  resource={unitsResource}
                  unit={unitItem}
                  sectionOptions={this.sectionOptions()}
                  withBuyerPortal={account.isEnabled("buyer_portal")}
                  projectId={projectId}
                />
              </div>
            </Grid.Column>
          </Grid>
        </List.Content>
      </List.Item>
    );
  };

  renderSections() {
    const { status } = this.state;

    if (status === "loading") {
      return (
        <div className="margin top spacious">
          <Loader active />
        </div>
      );
    }
    const unitsBySection = this.unitsBySection();

    if (!unitsBySection?.length) {
      return (
        <FormattedMessage
          id="section.message.noRecords"
          defaultMessage="section.message.noRecords"
          key="noRecords"
        />
      );
    }

    return (
      <div>
        {unitsBySection.map((section, index) =>
          this.renderSection(section, index)
        )}
      </div>
    );
  }

  renderDeadlines(unitItem) {
    const { i18n } = this.props;
    const stats = get(unitItem, "statistics.deadlines");

    if (stats.overdue > 0) {
      return (
        <Label
          color="red"
          size="mini"
          circular
          data-tooltip={i18n["projectDeadline.messages.overdue"]}
          key="overdue"
        >
          {stats.overdue}
        </Label>
      );
    }
    if (stats.close > 0) {
      return (
        <Label
          color="orange"
          size="mini"
          circular
          data-tooltip={i18n["projectDeadline.messages.close"]}
          key="close"
        >
          {stats.close}
        </Label>
      );
    }
    return null;
  }

  renderProgress(unitItem, unitStatistics) {
    const { account, i18n } = this.props;
    if (!unitItem.sample) {
      return (
        <RoomBookProgress
          unitItem={unitItem}
          i18n={i18n}
          unitStatistics={unitStatistics}
          processDefinition={account.getLineItemLifeCycle()}
          withBuyerPortal={account.isEnabled("buyer_portal")}
        />
      );
    }
    return null;
  }

  render() {
    const {
      i18n,
      sectionsResource,
      sections,
      units,
      unitsResource,
      project,
      account,
      customImportConfigs
    } = this.props;

    const { sectionFilter } = this.state;
    const unitsBySection = this.unitsBySection();
    const firstSectionId =
      unitsBySection.length && unitsBySection[0].section.id;
    return (
      <div data-component="sectionAndUnit">
        <div basic data-component="header">
          <Grid stackable>
            <Grid.Column width="7">
              <Header size="large">
                <FormattedMessage
                  id="project.tabs.sectionAndUnit"
                  defaultMessage="project.tabs.sectionAndUnit"
                />
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="right" width="9">
              <Grid>
                <Grid.Column width="14" floated="right">
                  <HasProjectAccessRight
                    right="allow_access_edit_units"
                    projectId={project.id}
                  >
                    <UnitsDialog
                      button={
                        <Button
                          id="units-new"
                          basic
                          icon="plus"
                          content={
                            <FormattedMessage
                              id="project.actions.addUnit.label"
                              defaultMessage="project.actions.addUnit.label"
                            />
                          }
                        />
                      }
                      i18n={i18n}
                      unit={(units && units.new) || {}}
                      handleCreated={this.onUnitsCreated}
                      resource={unitsResource}
                      sectionId={firstSectionId}
                      sectionOptions={this.sectionOptions()}
                    />
                    <SectionDialog
                      button={
                        <Button
                          id="section-new"
                          basic
                          icon="plus"
                          content={
                            <FormattedMessage
                              id="project.actions.addSection.label"
                              defaultMessage="Neuer Bauabschnitt"
                            />
                          }
                        />
                      }
                      contractorTabHidden
                      i18n={i18n}
                      onSave={this.onSectionSave}
                      onRemove={this.onSectionRemove}
                      resource={sectionsResource}
                    />
                  </HasProjectAccessRight>
                  <IsSystemAdmin>
                    <Button
                      icon
                      basic
                      href={`${window.location.origin}/api/v1/projects/${
                        project?.slug
                      }/units/export.xlsx?_bearer=${get(account, [
                        "data",
                        "auth_token"
                      ])}`}
                      target="_blank"
                      data-tooltip={i18n["unit.actions.download.tooltip"]}
                    >
                      <Icon name="cloud download" />
                    </Button>
                    <HasProjectAccessRight
                      right="allow_access_edit_units"
                      projectId={project.id}
                    >
                      <SectionAndUnitUploadModal
                        button={
                          <Button
                            basic
                            icon="cloud upload"
                            data-tooltip={i18n["unit.actions.upload.tooltip"]}
                          />
                        }
                      />
                    </HasProjectAccessRight>
                  </IsSystemAdmin>
                  <FeatureToggleActive featureToggleName="project_storeys">
                    <ProjectStoreysDialog
                      trigger={
                        <Button icon basic>
                          <Icon name="building" />
                        </Button>
                      }
                    />
                  </FeatureToggleActive>
                  {customImportConfigs?.map(importer => {
                    return (
                      <CustomUploadModal
                        key={importer.importer}
                        button={
                          <Button
                            basic
                            icon="upload"
                            data-tooltip={importer.dialog.title}
                          />
                        }
                        config={importer}
                      />
                    );
                  })}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </div>
        <div basic data-component="filters" style={{ margin: "1.5em 0" }}>
          <DropdownFilters
            sections={sections}
            value={sectionFilter}
            onSection={this.setFilter}
            // eslint-disable-next-line react/destructuring-assignment
            project={project}
            getSectionAndUnitValues={getSectionAndUnitValues}
          />
        </div>
        <div basic data-component="sections">
          {this.renderSections()}
        </div>
      </div>
    );
  }
}

SectionAndUnit.propTypes = {
  i18n: objectOf(oneOfType([string, number, bool, array])).isRequired,
  project: shape({
    id: oneOfType([string, number]),
    slug: string
  }).isRequired,
  account: shape({
    isEnabled: func,
    getLineItemLifeCycle: func
  }).isRequired,
  sections: arrayOf(shape({ id: number, code: string, name: string })),
  units: arrayOf(UnitShape),
  unitStatistics: shape({
    by_unit: arrayOf(
      shape({
        count: number,
        phase: oneOf([
          "open",
          "decided_buyer",
          "decided",
          "tendering",
          "ordering",
          "contracting",
          "completed",
          "cancelled"
        ]),
        selected_costs: number,
        total: number,
        total_costs: number,
        unit: number
      })
    )
  }),
  projectResource: instanceOf(ProjectsResource).isRequired,
  buyersResource: instanceOf(ProjectBuyersResource).isRequired,
  projectStoreysResource: instanceOf(ProjectStoreysResource).isRequired,
  sectionsResource: instanceOf(SectionsResource).isRequired,
  unitsResource: instanceOf(UnitsResource).isRequired,
  userConfig: shape({
    string
  }),
  roomBooks: arrayOf(RoomBookShape),
  unitFeatureResource: instanceOf(UnitFeatureResource).isRequired,
  configuratorDesignsResource: instanceOf(ConfiguratorDesignsResource)
    .isRequired,
  customImportConfigs: CustomImportConfigsShape
};

SectionAndUnit.defaultProps = {
  sections: [],
  units: [{}],
  userConfig: {},
  roomBooks: [],
  unitStatistics: {},
  customImportConfigs: {}
};

const mapStateToProps = (state, props) => ({
  i18n: getI18N(state),
  account: getAccount(state, props),
  sections: getSortedSections(state),
  units: state.pageContent.units,
  roomBooks: state.pageContent.room_books,
  unitStatistics: state.pageContent.unit_statistics,
  customImportConfigs: getCustomImportConfigs(state)
});

const mapDispatchToProps = (dispatch, props) => {
  const sectionsResource = new SectionsResource(dispatch, props.project.id);
  const unitFeatureResource = new UnitFeatureResource(
    dispatch,
    props.project.id
  );
  const configuratorDesignsResource = new ConfiguratorDesignsResource(
    dispatch,
    props.project.id
  );

  const projectStoreysResource = new ProjectStoreysResource(
    dispatch,
    props.project.id
  );

  return {
    sectionsResource,
    unitFeatureResource,
    configuratorDesignsResource,
    projectStoreysResource
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionAndUnit);
