/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { compact, intersection, keyBy, toString, values, uniqBy } from "lodash";
import {
  object,
  number,
  arrayOf,
  shape,
  string,
  func,
  instanceOf
} from "prop-types";
import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "shared/helpers/withRouter";
import { Button, Form, Grid, Header, Icon } from "semantic-ui-react";
import WysiwygEditor from "shared/components/elements/wysiwyg/WysiwygEditor";
import htmlToText from "html-to-text";
import { UnitShape } from "shared/shapes/unit.shape";
import { If } from "shared/components/elements/Conditions";
import InputWithActions from "shared/components/forms/InputWithActions";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import {
  MessageDraftsResource,
  MessageDraftsForMessagesResource
} from "builder_portal/actions/messageDraftsActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Field from "../../../shared/components/forms/FieldComponent";
import { FormDefinition } from "../../../shared/components/forms/FormDefinition";
import MessagesResource from "../../actions/messagesActions";
import {
  getContactsById,
  getProjectOptionsForNewMessage
} from "../../selectors";
import { getBuyers, getUnits } from "../../selectors/masterdata";
import AttachmentPicker from "./AttachmentPicker";
import { MessageReplyShape } from "../../../shared/shapes/messageReply.shape";
import Growl from "../../actions/growlActions";
import CreateContactDialog from "./contacts/CreateContactDialog";

const INIT_MESSAGE = {
  parent_id: null,
  subject: "",
  body_html: "",
  recipients: [],
  cc: [],
  bcc: [],
  attachments: [],
  project_id: null,
  unit_id: null
};

class MessageDialog extends PureComponent {
  constructor(props) {
    super(props);

    const draft = props.messageDraft || {};

    const projectId = (draft.project_id || props.reply?.project_id)?.toString();

    const message =
      props.reply || draft?.id
        ? {
            ...props.reply,
            ...draft,
            project_id: projectId,
            attachments: draft?.attachments || [],
            id: draft.id
          }
        : INIT_MESSAGE;

    this.state = {
      loading: false,
      message,
      unitOptions: this.getUnitOptions(message, props.units || []),
      ccOptions: this.getAvailableContactOptions(message.project_id),
      bccOptions: this.getAvailableContactOptions(message.project_id),
      cc: !!message.cc?.length,
      bcc: !!message.bcc?.length,
      touched: false,
      showContactModal: false,
      contactModalType: null
    };
  }

  getAvailableContactsById(projectId) {
    const { contactsById, buyers, participants } = this.props;

    let buyersById = {};
    if (buyers) {
      const availableBuyers = buyers.filter(
        buyer => toString(buyer.project_id) === toString(projectId)
      );
      if (availableBuyers.length > 0) {
        buyersById = keyBy(availableBuyers, buyer => `buyer:${buyer.id}`);
      }
    }

    let externalParticipantsById = {};
    if (participants.length) {
      const externalParticipants = participants.filter(
        p => p.type === "contact"
      );
      if (externalParticipants.length > 0) {
        externalParticipantsById = keyBy(
          externalParticipants,
          externalParticipant => `contact:${externalParticipant.id}`
        );
      }
    }

    return {
      ...externalParticipantsById,
      ...contactsById,
      ...buyersById
    };
  }

  getOptionContent = (icon, label, email, address_town) => {
    return (
      <Header as="h5">
        <Icon name={icon} />
        <Header.Content>
          {label}
          <Header.Subheader>
            {email && compact([address_town, email]).join(", ")}
            {!email && (
              <FormattedMessage id="messaging.recipient_options.no_email" />
            )}
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  };

  getAvailableContactOptions(projectId) {
    const contacts = this.getAvailableContactsById(projectId);

    const list = values(contacts).map(contact => {
      const icons = {
        buyer: "child",
        contractor: "truck",
        user: "user",
        contact: "address card outline"
      };
      const { type, id, label, email, address_town } = contact;
      // "[Achtung: Keine E-Mail hinterlegt]"
      return {
        key: `${type}:${id}`,
        value: `${type}:${id}`,
        text: `${label} (${email})`,
        disabled: !email,
        content: this.getOptionContent(icons[type], label, email, address_town)
      };
    });
    return uniqBy(list, "key");
  }

  getRecipients = formData => {
    const { message } = this.state;
    const availableContactsById = this.getAvailableContactsById(
      message.project_id
    );

    const recipients = [];
    if (formData.recipients) {
      formData.recipients.forEach(id => {
        if (availableContactsById[id]) {
          recipients.push({
            ...availableContactsById[id],
            mode: "to"
          });
        }
      });
    }

    if (formData.cc) {
      formData.cc.forEach(c => {
        if (availableContactsById[c]) {
          recipients.push({
            ...availableContactsById[c],
            mode: "cc"
          });
        } else {
          recipients.push({
            label: c,
            email: c,
            mode: "cc"
          });
        }
      });
    }

    if (formData.bcc) {
      formData.bcc.forEach(bc => {
        if (availableContactsById[bc]) {
          recipients.push({
            ...availableContactsById[bc],
            mode: "bcc"
          });
        } else {
          recipients.push({
            label: bc,
            email: bc,
            mode: "bcc"
          });
        }
      });
    }

    return recipients;
  };

  tryDeleteDraft = () => {
    const {
      messageDraft,
      deleteDraft,
      loadDrafts,
      loadThreadDrafts,
      messageThreadId
    } = this.props;

    const { savedMessageDraft } = this.state;
    const messageDraftId = messageDraft?.id || savedMessageDraft;

    if (messageDraftId) {
      this.setState(prev => ({ ...prev, loading: true }));
      return deleteDraft(messageDraftId)
        .then(() => {
          this.setState(prev => ({
            ...prev,
            message: INIT_MESSAGE,
            savedMessageDraft: undefined,
            loading: false
          }));
          if (messageThreadId) {
            return loadThreadDrafts();
          }
          return loadDrafts();
        })
        .catch(() => this.setState(prev => ({ ...prev, loading: false })));
    }
    return Promise.resolve();
  };

  sendMessage = formData => {
    const { send, growl, closeMessageForm } = this.props;
    const { message } = this.state;

    const recipients = this.getRecipients(formData);

    this.setState({ loading: true });
    send({
      ...formData,
      body_html: message.body_html,
      body_text: htmlToText.fromString(message.body_html, {
        singleNewLineParagraphs: true
      }),
      render_mode: formData.render_message_thread ? "thread" : "message",
      recipients,
      attachments: formData.attachments.map(attachment => attachment.id),
      id: undefined
    }).then(() => {
      growl.success(
        "buyer.attributes.email.label",
        "messaging.messages_container.messages.message_sent",
        { timeout: 5000 }
      );
      this.setState({ loading: false, message: INIT_MESSAGE });
      this.tryDeleteDraft()
        .then(closeMessageForm)
        .catch(() => this.setState({ loading: true }));
    });
  };

  handleAttachmentChanged = attachments => {
    this.setState(nextState => ({
      touched: true,
      message: {
        ...nextState.message,
        attachments
      }
    }));
  };

  handleCancellation = closeConfirmation => {
    const { reply } = this.props;

    this.setState({
      message: reply || INIT_MESSAGE
    });

    this.tryDeleteDraft();
    closeConfirmation();
    this.handleCloseDialog();
  };

  onSaveDraft = formData => {
    const { saveDraft, messageThreadId, growl } = this.props;
    const { message } = this.state;

    const recipients = this.getRecipients(formData);

    const draft = {
      ...formData,
      message_thread_id: messageThreadId,
      body_html: message.body_html,
      body_text: htmlToText.fromString(message.body_html, {
        singleNewLineParagraphs: true
      }),
      render_mode: formData.render_message_thread ? "thread" : "message",
      recipients,
      attachments: formData.attachments.map(attachment => attachment.id),
      id: message.id
    };
    this.setState(prev => ({ ...prev, loading: true }));
    saveDraft(draft)
      .then(res => {
        this.setState({
          touched: false,
          message: { ...message, id: res.data?.message_draft?.id },
          savedMessageDraft: res.data?.message_draft?.id,
          loading: false
        });
        growl.success(
          "messaging.drafts.messages.saved.title",
          "messaging.drafts.messages.saved.body"
        );
      })
      .catch(() => {
        this.setState(prev => ({ ...prev, loading: false }));
      });
  };

  handleCloseDialog = () => {
    const { closeMessageForm } = this.props;
    this.setState({ loading: false });

    closeMessageForm();
  };

  handleRemoveAttachment = attachmentId => {
    const { message } = this.state;
    const attachments = message.attachments.filter(a => a.id !== attachmentId);
    this.setState(nextState => ({
      message: {
        ...nextState.message,
        attachments
      }
    }));
  };

  openContactDialogButton = type => (
    <div
      onClick={e => {
        e.preventDefault();
        this.setState(prevState => ({
          ...prevState,
          showContactModal: true,
          contactModalType: type
        }));
      }}
      style={{
        width: "100%",
        cursor: "pointer"
      }}
    >
      <Icon
        name="add"
        style={{
          marginTop: "2px"
        }}
      />
      <a href="#">
        <FormattedMessage id="messaging.message_container.empty_references.add_new_contact" />
      </a>
    </div>
  );

  renderForm = form => {
    const { reply } = this.props;
    const { loading, message, cc, bcc } = this.state;

    return (
      <Form loading={loading}>
        <If condition={!reply}>
          <Form.Field>
            <Field
              component="Select"
              {...form.fields.project_id}
              data-test="field-select-project"
            />
          </Form.Field>
        </If>
        <Form.Field>
          <FeatureToggle featureToggleName="show_cc_bcc" disabled>
            <Field component="Select" {...form.fields.recipients} />
          </FeatureToggle>

          <FeatureToggle featureToggleName="show_cc_bcc">
            <InputWithActions
              component="Select"
              field={form.fields.recipients}
              actions={[
                {
                  label: "cc",
                  name: "cc",
                  color: cc ? "grey" : undefined,
                  handler: name =>
                    this.setState(prev => ({ ...prev, [name]: !prev[name] }))
                },
                {
                  label: "bcc",
                  name: "bcc",
                  color: bcc ? "grey" : undefined,
                  handler: name =>
                    this.setState(prev => ({ ...prev, [name]: !prev[name] }))
                }
              ]}
            />
          </FeatureToggle>
        </Form.Field>

        <If condition={cc}>
          <div
            style={{
              width: "100%",
              margin: "0 0 1rem 0"
            }}
          >
            <FeatureToggle featureToggleName="email_contacts" disabled>
              <Form.Field>
                <Field component="Select" {...form.fields.cc} />
              </Form.Field>
            </FeatureToggle>
            <FeatureToggle featureToggleName="email_contacts">
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.cc}
                  props={{
                    ...form.fields.cc.props,
                    allowAdditions: false,
                    noResultsMessage: this.openContactDialogButton("cc")
                  }}
                />
              </Form.Field>
            </FeatureToggle>
          </div>
        </If>

        <If condition={bcc}>
          <div
            style={{
              width: "100%",
              margin: "0 0 1rem 0"
            }}
          >
            <FeatureToggle featureToggleName="email_contacts" disabled>
              <Form.Field>
                <Field component="Select" {...form.fields.bcc} />
              </Form.Field>
            </FeatureToggle>
            <FeatureToggle featureToggleName="email_contacts">
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.bcc}
                  props={{
                    ...form.fields.bcc.props,
                    allowAdditions: false,
                    noResultsMessage: this.openContactDialogButton("bcc")
                  }}
                />
              </Form.Field>
            </FeatureToggle>
          </div>
        </If>

        <If condition={!reply}>
          <Form.Field>
            <Field component="Select" {...form.fields.unit_id} />
          </Form.Field>
        </If>

        <Form.Field>
          <Field component="Input" {...form.fields.subject} />
        </Form.Field>
        <WysiwygEditor
          name="bodyHtml"
          value={message.body_html}
          onChange={({ value: newBody }) => {
            this.setState(prevState => ({
              touched: true,
              message: {
                ...prevState.message,
                body_html: newBody
              }
            }));
          }}
        />

        {message.parent_id && (
          <Form.Field>
            <Field
              component="Checkbox"
              {...form.fields.render_message_thread}
            />
          </Form.Field>
        )}
        <Form.Field>
          {message.attachments.map(attachment => {
            return (
              <Button.Group compact basic size="small" key={attachment.id}>
                <Button
                  icon="file outline"
                  content={attachment.display_name}
                  as="a"
                  href={attachment.attachment_url}
                  target="_document"
                />
                <Button
                  type="button"
                  icon="delete"
                  onClick={() => this.handleRemoveAttachment(attachment.id)}
                />
              </Button.Group>
            );
          })}
        </Form.Field>
      </Form>
    );
  };

  getUnitOptions = (message, units) => {
    const buyers = message.recipients
      .filter(rec => rec.indexOf("buyer:") === 0)
      .map(x => parseInt(x.split(":")[1], 10));

    const list = [];
    if (buyers.length > 0) {
      list.push(
        ...units.filter(
          unit => intersection(buyers, unit.buyer_ids).length === buyers.length
        )
      );
    } else {
      list.push(...units);
    }
    return list
      .filter(unit => unit.project_id === parseInt(message.project_id, 10))
      .map(unit => ({
        key: unit.id,
        value: unit.id,
        text: unit.name
      }));
  };

  setUnitOptions = () => {
    const { units } = this.props;
    const { message } = this.state;

    const unitOptions = this.getUnitOptions(message, units);
    this.setState(
      prevState => ({ ...prevState, unitOptions }),
      () => {
        if (unitOptions.length === 1)
          this.setState(prevState => ({
            ...prevState,
            message: {
              ...prevState.message,
              unit_id: unitOptions[0].value
            }
          }));
      }
    );
  };

  onDialogClose = () => {
    this.setState({
      showContactModal: false
    });
  };

  buildForm() {
    const { i18n, projects } = this.props;
    const { message, unitOptions, ccOptions, bccOptions } = this.state;

    const fields = [
      {
        id: "project_id",
        label: "email.attributes.project.label",
        rule: "isRequired",
        placeholder: "email.attributes.project.placeholder",
        message: "email.attributes.project.error"
      },
      {
        id: "parent_id"
      },
      {
        id: "subject",
        label: "email.attributes.subject.label",
        rule: "isRequired",
        placeholder: "email.attributes.subject.placeholder",
        message: "email.attributes.subject.error"
      },
      {
        id: "recipients",
        label: "email.attributes.recipients.label",
        rule: "isRequired",
        placeholder: "email.attributes.recipients.placeholder",
        message: "email.attributes.recipients.error"
      },
      {
        id: "cc",
        label: "email.attributes.cc.label",
        placeholder: "email.attributes.recipients.placeholder"
      },
      {
        id: "bcc",
        label: "email.attributes.bcc.label",
        placeholder: "email.attributes.recipients.placeholder"
      },
      {
        id: "unit_id",
        label: "email.attributes.unit.label",
        placeholder: "email.attributes.unit.placeholder"
      },
      {
        id: "attachments",
        label: "email.attributes.attachments.label",
        placeholder: "email.attributes.attachments.placeholder"
      },
      {
        id: "render_message_thread",
        label: "email.attributes.render_message_thread.label",
        control: "Checkbox"
      }
    ];
    const FormFactory = new FormDefinition({ fields });
    const form = FormFactory.create(message, i18n, {
      onChange: msg => {
        this.setState({ message: msg, touched: true });
      }
    });

    form.fields.project_id.props = {
      ...form.fields.project_id.props,
      closeOnChange: true,
      selection: true,
      options: projects,
      onChange: (_, { value: nextProjectId }) => {
        this.setState(
          prevState => ({
            touched: true,
            message: {
              ...prevState.message,
              project_id: nextProjectId,
              unit_id: "",
              // update recipients on project change, check if the recipient is contained in project
              recipients: intersection(
                message.recipients,
                this.getAvailableContactOptions(nextProjectId).map(c => c.value)
              )
            },
            ccOptions: this.getAvailableContactOptions(nextProjectId),
            contactId: this.getAvailableContactOptions(nextProjectId)
          }),
          () => this.setUnitOptions()
        );
      }
    };

    form.fields.recipients.props = {
      ...form.fields.recipients.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      options: this.getAvailableContactOptions(message.project_id),
      clearable: true,
      noResultsMessage: (
        <div>
          <FeatureToggle featureToggleName="email_contacts">
            {this.openContactDialogButton("recipients")}
          </FeatureToggle>
          <FeatureToggle featureToggleName="email_contacts" disabled>
            <FormattedMessage id="messaging.message_container.empty_references.no_results_found" />
          </FeatureToggle>
        </div>
      ),
      onChange: (_, { value: recipients }) => {
        this.setState(
          prevState => ({
            touched: true,
            message: {
              ...prevState.message,
              recipients,
              unit_id: ""
            }
          }),
          () => this.setUnitOptions()
        );
      }
    };

    form.fields.cc.props = {
      ...form.fields.cc.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      options: ccOptions,
      allowAdditions: true,
      onAddItem: (_, { options, value }) => {
        options.push({
          key: value,
          value,
          text: value,
          disabled: false,
          content: this.getOptionContent("pencil alternate", value, value, "")
        });
        this.setState(prev => ({ ...prev, ccOptions: options }));
      }
    };

    form.fields.bcc.props = {
      ...form.fields.bcc.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      selection: true,
      options: bccOptions,
      clearable: true,
      allowAdditions: true,
      onAddItem: (_, { options, value }) => {
        options.push({
          key: value,
          value,
          text: value,
          disabled: false,
          content: this.getOptionContent("pencil alternate", value, value, "")
        });
        this.setState(prev => ({ ...prev, bccOptions: options }));
      }
    };

    form.fields.unit_id.props = {
      ...form.fields.unit_id.props,
      selection: true,
      closeOnChange: true,
      search: true,
      options: unitOptions,
      clearable: true
    };

    if (unitOptions.length === 1) {
      form.fields.unit_id.props = {
        ...form.fields.unit_id.props,
        value: message.unit_id
      };
    }

    return form;
  }

  renderDeleteButton = () => {
    const { loading } = this.state;
    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          this.handleCancellation(closeConfirmation);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="messaging.drafts.delete.title"
        message="messaging.drafts.delete.message"
        button={
          <Button as="a" icon="trash" disabled={loading} color="red" basic />
        }
        buttons={buttons}
      />
    );
  };

  render() {
    const {
      message,
      loading,
      touched,
      unitOptions,
      showContactModal,
      contactModalType
    } = this.state;

    const form = this.buildForm();

    return (
      <>
        <Header as="h4">
          <FormattedMessage
            id="messaging.messages_container.header.new_message"
            defaultMessage="Neue Nachricht"
          />
        </Header>
        <CreateContactDialog
          contactModalType={contactModalType}
          isOpened={showContactModal}
          onDialogClose={this.onDialogClose}
          onContactCreated={(contact, type) => {
            const contactToPush = {
              key: `${"contact"}:${contact.id}`,
              value: `${"contact"}:${contact.id}`,
              text: `${contact.first_name} ${contact.last_name} (${contact.email})`,
              disabled: !contact.email,
              content: this.getOptionContent(
                "address card outline",
                `${contact.first_name} ${contact.last_name}`,
                contact.email,
                contact.address_town
              )
            };

            this.setState(
              prevState => ({
                bccOptions: [...prevState.bccOptions, contactToPush],
                ccOptions: [...prevState.ccOptions, contactToPush],
                touched: true,
                message: {
                  ...prevState.message,
                  [type]: [...prevState.message[type], `contact:${contact.id}`],
                  unit_id:
                    contactModalType === "recipients"
                      ? ""
                      : prevState.message.unit_id
                }
              }),
              () => this.setUnitOptions()
            );
          }}
        />

        {this.renderForm(form)}
        <Grid style={{ marginTop: "5px" }}>
          <Grid.Column width={5}>
            {this.renderDeleteButton()}
            <If condition={touched}>
              <FeatureToggle featureToggleName="show_drafts">
                <Button
                  onClick={() => this.onSaveDraft(form.trimmedValues)}
                  disabled={loading}
                  basic
                  icon="save"
                  color="grey"
                  style={{ marginLeft: "5px" }}
                />
              </FeatureToggle>
            </If>
          </Grid.Column>
          <Grid.Column width={11} textAlign="right">
            <AttachmentPicker
              trigger={
                <Button
                  as="a"
                  icon
                  labelPosition="left"
                  basic
                  color="grey"
                  disabled={loading}
                  style={{ marginLeft: "5px" }}
                >
                  <Icon name="file" />
                  <FormattedMessage
                    id="attachment.attachDialog.header"
                    defaultMessage="Dokumente Anhängen"
                  />
                </Button>
              }
              selection={message.attachments}
              onChange={this.handleAttachmentChanged}
              message={message}
              parentUnitOptions={message.project_id ? unitOptions : null}
            />
            <Button
              className="send-button"
              disabled={loading}
              color="blue"
              icon
              labelPosition="left"
              onClick={form.handleSubmit(this.sendMessage)}
              style={{ marginLeft: "5px" }}
            >
              <Icon name="paper plane" />
              <FormattedMessage
                id="meta.actions.send"
                defaultMessage="Absenden"
              />
            </Button>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

MessageDialog.defaultProps = {
  reply: undefined,
  messageDraft: undefined,
  messageThreadId: undefined,
  participants: []
};

MessageDialog.propTypes = {
  i18n: object.isRequired,
  messageThreadId: string,
  projects: arrayOf(
    shape({
      key: string,
      text: string,
      value: string
    })
  ).isRequired,
  closeMessageForm: func.isRequired,
  reply: MessageReplyShape,
  buyers: arrayOf(
    shape({
      id: number,
      type: string,
      label: string,
      email: string,
      phone: string,
      address_town: string,
      first_name: string,
      last_name: string,
      project_id: number
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  contactsById: object.isRequired,
  send: func.isRequired,
  saveDraft: func.isRequired,
  deleteDraft: func.isRequired,
  loadDrafts: func.isRequired,
  loadThreadDrafts: func.isRequired,
  growl: instanceOf(Growl).isRequired,
  units: arrayOf(UnitShape).isRequired,
  messageDraft: shape({
    id: number
  }),
  participants: arrayOf(
    shape({
      id: number
    })
  )
};

const mapStateToProps = (state, props) => ({
  i18n: state.i18n,
  messageThreadId: props.params?.id,
  projects: getProjectOptionsForNewMessage(state),
  contactsById: getContactsById(state),
  buyers: getBuyers(state),
  units: getUnits(state)
});

const mapDispatchToProps = (dispatch, props) => {
  const messageResource = new MessagesResource(dispatch);
  const messageDraftResource = new MessageDraftsResource(dispatch);
  const messageThreadId = props.params?.id;
  const messageDraftThreadResource = new MessageDraftsForMessagesResource(
    dispatch,
    messageThreadId
  );

  return {
    send: params => {
      return messageResource.save(params);
    },
    loadDrafts: () => {
      return messageDraftResource.fetchAll();
    },
    loadThreadDrafts: () => {
      return messageDraftThreadResource.fetchAll();
    },
    saveDraft: params => {
      return messageDraftResource.save(params);
    },
    deleteDraft: id => {
      return messageDraftResource.remove(id);
    },
    growl: new Growl(dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessageDialog)
);
