import { createSelector } from "reselect";
import { getPageContent } from "./base";

export const getFeatureGroups = createSelector(
  [getPageContent],
  pageContent => {
    return pageContent?.feature_groups || [];
  }
);

export const getFeaturesAccount = createSelector(
  [getPageContent],
  pageContent => {
    return pageContent?.features_account || {};
  }
);
