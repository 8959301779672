import React, { useEffect, useState } from "react";
import { func, number, shape } from "prop-types";
import { Progress } from "semantic-ui-react";
import "./timeoutProgressBar.scss";

const useAnimationFrame = callback => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animationStep = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animationStep);
  };

  React.useEffect(() => {
    // componentDidMount
    requestRef.current = requestAnimationFrame(animationStep);

    // componentWillUnmount
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return () => {
    cancelAnimationFrame(requestRef.current);
  };
};

function TimeoutProgressBar({ progressBarProps, timeout, onTimeoutComplete }) {
  const [progress, setProgress] = useState(timeout);
  const cancelAnimation = useAnimationFrame(deltaTime => {
    // Pass on a function to the setter of the state
    // to make sure we always have the latest state
    setProgress(prevProgress => prevProgress - deltaTime);
  });

  useEffect(() => {
    if (progress <= 0) {
      cancelAnimation();
      onTimeoutComplete();
    }
  }, [progress]);

  // spreading is totally valid for a HOC
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Progress {...progressBarProps} value={progress} total={timeout} />;
}

TimeoutProgressBar.defaultProps = {
  timeout: 5000, // keep between 4 and 8 seconds
  progressBarProps: {}
};

TimeoutProgressBar.propTypes = {
  timeout: number,
  onTimeoutComplete: func.isRequired,
  progressBarProps: shape(Progress.propTypes)
};

export default TimeoutProgressBar;
