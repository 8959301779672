import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ProjectActivitiesResource } from "builder_portal/actions/projectActions";
import { FormattedMessage } from "react-intl";
import { Button, Icon } from "semantic-ui-react";
import { arrayOf, number } from "prop-types";

const GeneratePreviewButton = ({ contractorIds, projectId, activityId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const openPreview = contractorId => {
    setLoading(true);
    new ProjectActivitiesResource(dispatch, projectId)
      .getPriceInquiryPreviewUrl(activityId, contractorId)
      .then(res => {
        if (res.status === 200) {
          const { data } = res;
          window.open(data.preview_url, "sw_preview");
        }
        setLoading(false);
      });
  };

  return contractorIds.map(contractorId => (
    <Button
      key={contractorId}
      data-id="open-preview"
      compact
      icon
      labelPosition="left"
      loading={loading}
      type="button"
      onClick={() => openPreview(contractorId)}
    >
      <Icon name="eye" />
      <FormattedMessage
        id="activity.preview.open"
        defaultMessage="Vorschau öffnen"
      />
    </Button>
  ));
};

GeneratePreviewButton.propTypes = {
  contractorIds: arrayOf(number).isRequired,
  projectId: number.isRequired,
  activityId: number.isRequired
};

export default GeneratePreviewButton;
