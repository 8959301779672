import {
  getHandleDeleteFailed,
  getHandleUpdateFailed
} from "shared/actions/resourcesErrorHandling";
import apiRequest from "../network/apiRequest";
import serialize from "../helpers/serialize";
import {
  ADD_PAGE_CONTENT,
  REMOVE_ID_FROM_PAGE_CONTENT,
  UPDATE_PAGE_CONTENT
} from "../constants/appConstants";

class Resources {
  refreshPageContentAfterCommit; // usually being overloaded

  constructor(dispatch, namespace, url, reduxProperty) {
    this.dispatch = dispatch;
    this.namespace = namespace;
    this.reduxProperty = reduxProperty;
    this.url = url;
    this.handleUpdateFailed = getHandleUpdateFailed(dispatch);
    this.handleDeleteFailed = getHandleDeleteFailed(dispatch);
  }

  propagateUpdate = response => {
    switch (response.status) {
      case 401: // unauthorized fall-through
      case 404: // not found fall-through
      case 200:
        // write data to store
        this.dispatch({
          type: UPDATE_PAGE_CONTENT,
          payload: this.toPayload(response.data)
        });
        break;
      case 201: // created
        this.dispatch({
          type: ADD_PAGE_CONTENT,
          payload: this.toPayload([response.data])
        });
        break;
    }
    return response;
  };

  propagateDeletedUpdate = id => response => {
    if (this.reduxProperty && response.status === 204) {
      this.dispatch({
        type: REMOVE_ID_FROM_PAGE_CONTENT,
        payload: { property: this.reduxProperty, id }
      });
      return response;
    }
    return this.propagateUpdate(response);
  };

  toPayload(data) {
    if (this.reduxProperty) {
      return {
        [this.reduxProperty]: data || undefined
      };
    }
    return data || undefined;
  }

  save(values, parentUrlString = "") {
    const payload = {};
    payload[this.namespace] = values;
    let request;

    if (!values.id) {
      request = apiRequest.post(
        `${this.url}${parentUrlString}`,
        this.dispatch,
        payload
      );
    } else {
      request = apiRequest.patch(
        `${this.url}${parentUrlString}/${values.id}`,
        this.dispatch,
        payload
      );
    }

    if (this.refreshPageContentAfterCommit) {
      request = request.then(this.propagateUpdate);
    }
    return request.catch(this.handleUpdateFailed);
  }

  get(id, deep) {
    const deepParameter = (deep && "?deep=true") || "";
    const url = `${this.url}/${id}${deepParameter}`;
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }

  remove(id) {
    const url = `${this.url}/${id}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateDeletedUpdate(id))
      .catch(this.handleDeleteFailed);
  }

  fetchAll(params) {
    let { url } = this;
    if (params && Object.keys(params).length > 0) {
      url = `${url}?${serialize(params)}`;
    }
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }
}

export default Resources;
