import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReferencesResource } from "builder_portal/actions/references";
import { getReferences } from "builder_portal/selectors/references";
import { If } from "shared/components/elements/Conditions";
import { Button, Header, Loader, Message, Table } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { number, shape, string } from "prop-types";

const References = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const references = useSelector(getReferences);

  const subject_type = new URLSearchParams(window.location.search).get(
    "subject_type"
  );
  const subject_id = new URLSearchParams(window.location.search).get(
    "subject_id"
  );

  useEffect(() => {
    setLoading(true);
    if (subject_type && subject_id) {
      new ReferencesResource(dispatch)
        .fetchAll({ subject_type, subject_id })
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setError("noParams");
    }
  }, []);

  return (
    <>
      <Header as="h3">
        <FormattedMessage
          id="references.title"
          values={{ subject_type, subject_id }}
        />
      </Header>
      <Loader active={isLoading} />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="references.headers.project_name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="references.headers.display_name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="references.headers.status" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="references.headers.title" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage id="references.headers.link" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <If condition={!isLoading && !references.activities?.length}>
            <Table.Row>
              <Table.Cell width={16}>
                <Header as="h4">
                  <FormattedMessage
                    id="references.empty"
                    default="No references for this subject."
                  />
                </Header>
              </Table.Cell>
            </Table.Row>
          </If>
          {references.activities?.map(reference => (
            <RenderRow key={reference.id} reference={reference} />
          ))}
        </Table.Body>
      </Table>
      <If condition={!!error}>
        <Message color="red">
          <p>
            <FormattedMessage id={`versions.errors.${error}`} default="Error" />
          </p>
        </Message>
      </If>
    </>
  );
};

const RenderRow = ({ reference }) => {
  const handleClick = () => {
    window.open(
      `projects/${reference.project?.slug}/activities/${reference.id}`,
      "_blank"
    );
  };

  return (
    <Fragment key={reference.id}>
      <Table.Row>
        <Table.Cell width={3}>{reference.project?.name}</Table.Cell>
        <Table.Cell width={4}>{reference.display_name}</Table.Cell>
        <Table.Cell width={3}>{reference.status}</Table.Cell>
        <Table.Cell width={4}>{reference.title}</Table.Cell>
        <Table.Cell width={2}>
          <Button onClick={handleClick} fluid>
            <FormattedMessage
              id="references.link.label"
              default="Open activity"
            />
          </Button>
        </Table.Cell>
      </Table.Row>
    </Fragment>
  );
};

RenderRow.propTypes = {
  reference: shape({
    id: number,
    display_name: string,
    status: string,
    title: string,
    project: shape({
      name: string
    })
  }).isRequired
};

export default References;
