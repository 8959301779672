import React, { PureComponent } from "react";
import PropTypes, { shape, bool } from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { get, sortBy } from "lodash";
import { getAccount } from "shared/selectors";
import {
  Button,
  Grid,
  Header,
  Icon,
  Label,
  Segment,
  Input,
  Dropdown
} from "semantic-ui-react";
import { connect } from "react-redux";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ReferencesLink from "shared/components/elements/ReferencesLink";
import HasEditContractorRoles from "shared/components/authorization/HasEditContractorRoles";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import ContractorCreationDialog from "./ContractorCreationDialog";
import ContractorsResource from "../../actions/contractorActions";

class Contractors extends PureComponent {
  static propTypes = {
    featureToggles: shape({ contractor_accounting_mode: bool }).isRequired,
    i18n: PropTypes.object,
    pageContent: PropTypes.object,
    trades: PropTypes.object,
    tradeOptions: PropTypes.array,
    resource: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      initialLoadingIndicator: false,
      open: false,
      filterContractor: "",
      filterTrade: undefined
    };
  }

  componentDidMount() {
    this.setState({ initialLoadingIndicator: true });
    this.props.resource
      .fetchAll()
      .then(() => {
        this.setState({ initialLoadingIndicator: false });
      })
      .catch(() => {
        this.setState({ initialLoadingIndicator: false });
      });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  getTradeOptions = () => {
    const { i18n, tradeOptions } = this.props;
    const temp = [
      {
        key: "no_trade",
        value: "no_trade",
        text: i18n["account.selector.trade.no_trades"]
      }
    ];
    return temp.concat(tradeOptions);
  };

  renderChildren() {
    const { i18n } = this.props;
    const { filterContractor, filterTrade } = this.state;
    const contractors = get(this.props, "pageContent.contractors", []);

    const fieldsToFilter = [
      "name",
      "contact_person",
      "phone",
      "fax",
      "email",
      "address_number",
      "address_street",
      "address_town",
      "address_postcode",
      "trades"
    ];

    let filteredContractors =
      filterContractor.trim().length > 0
        ? contractors.filter(x =>
            fieldsToFilter.some(
              field =>
                x[field] &&
                (Array.isArray(x[field])
                  ? x[field].some(
                      trade =>
                        trade
                          .toLowerCase()
                          .indexOf(filterContractor.trim().toLowerCase()) > -1
                    )
                  : x[field]
                      .toString()
                      .toLowerCase()
                      .indexOf(filterContractor.trim().toLowerCase()) > -1)
            )
          )
        : contractors;

    if (filterTrade) {
      if (filterTrade !== "no_trade") {
        filteredContractors = filteredContractors.filter(
          x => x.trades.indexOf(filterTrade) > -1
        );
      } else {
        filteredContractors = filteredContractors.filter(
          x => x.trades.length === 0
        );
      }
    }

    if (filteredContractors.length) {
      return sortBy(filteredContractors, "name").map(contractor => {
        const buttonIcon = (
          <a role="button" data-tooltip={i18n["meta.actions.edit"]}>
            <Icon name="setting" size="large" color="grey" />
          </a>
        );
        const buttonText = (
          <a role="button">{`${contractor.name} (${contractor.contact_person})`}</a>
        );

        return (
          <Segment key={contractor.id} clearing data-model="contractor">
            <Grid>
              <Grid.Column width={12}>
                <Header
                  as="h5"
                  size="medium"
                  data-attr="name"
                  className="margin top none"
                >
                  {this.renderModal(contractor, buttonText)}
                </Header>
                <div data-attr="trades" className="margin top medium">
                  {contractor.trades.map(trade => {
                    return (
                      <Label
                        key={trade}
                        content={this.props.trades[trade] || trade}
                        icon="paper hand"
                      />
                    );
                  })}
                </div>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                {this.renderModal(contractor, buttonIcon)}
                <IsVersionHistoryAccessible>
                  <VersionHistoryLink id={contractor.id} type="Contractor" />
                </IsVersionHistoryAccessible>
                <IsSystemAdmin>
                  <ReferencesLink id={contractor.id} type="Contractor" />
                </IsSystemAdmin>
              </Grid.Column>
            </Grid>
          </Segment>
        );
      });
    }
    return (
      <Segment loading={this.state.initialLoadingIndicator}>
        <FormattedMessage
          id="meta.message.noResults"
          defaultMessage="meta.message.noResults"
        />
      </Segment>
    );
  }

  renderModal(model, button) {
    const { featureToggles, i18n, resource, tradeOptions } = this.props;
    return (
      <ContractorCreationDialog
        contractor={model}
        i18n={i18n}
        tradeOptions={tradeOptions}
        resource={resource}
        featureToggles={featureToggles}
        button={button}
      />
    );
  }

  renderCreateButton() {
    const button = (
      <Button
        id="contractor-new"
        icon="plus"
        fluid
        basic
        primary
        content={this.props.i18n["meta.actions.add"]}
      />
    );
    return this.renderModal(
      {
        trades: [],
        cost_accounting_mode: "GROSS_ACCOUNTING"
      },
      button
    );
  }

  render() {
    const { i18n } = this.props;
    const { filterContractor, filterTrade } = this.state;
    return (
      <div data-component="contractors">
        <Helmet title={i18n["contractor.title.other"]} />
        <Grid
          stackable
          verticalAlign="middle"
          className="margin bottom spacious"
        >
          <Grid.Column width="12">
            <Header size="large">
              <FormattedMessage
                id="contractor.title.other"
                defaultMessage="contractor.title.other"
              />
            </Header>
          </Grid.Column>
          <Grid.Column width="4" textAlign="right">
            <HasEditContractorRoles>
              {this.renderCreateButton()}
            </HasEditContractorRoles>
          </Grid.Column>
        </Grid>
        <Segment data-component="contractorsList">
          <Grid stackable>
            <Grid.Column width={12}>
              <Input
                name="filterContractor"
                fluid
                value={filterContractor}
                onChange={e => this.handleChange(e.target.name, e.target.value)}
                icon="search"
                autoFocus
                autoComplete="off"
                placeholder={i18n["account.selector.search.placeholder"]}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                name="filterTrade"
                value={filterTrade}
                fluid
                clearable
                selection
                options={this.getTradeOptions()}
                onChange={(_, { name, value }) =>
                  this.handleChange(name, value)
                }
                placeholder={i18n["account.selector.trade.placeholder"]}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment.Group>{this.renderChildren()}</Segment.Group>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const account = getAccount(state);
  const trades = get(
    state,
    "account.account.config.contractors.trades",
    []
  ).reduce((accu, t) => {
    accu[t.id] = t.label;
    return accu;
  }, {});

  const tradeOptions = get(
    state,
    "account.account.config.contractors.trades",
    []
  ).map(t => {
    return {
      key: t.id,
      value: t.id,
      text: t.label
    };
  });

  return {
    ...props,
    i18n: state.i18n,
    featureToggles: account.getFeatureToggles(),
    trades,
    tradeOptions,
    pageContent: state.pageContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resource: new ContractorsResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
