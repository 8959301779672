import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import getRoomBookUpgradeBundles from "../../../selectors/roomBookUpgradeBundles";
import ConfirmationDialog from "../../../../shared/components/dialogs/ConfirmationDialog";
import RoomBookUpgradeBundlesResource from "../../../actions/roomBookUpgradeBundles";

const useUpgradeBundleConflicts = lineItem => {
  const upgradeBundles = useSelector(getRoomBookUpgradeBundles);

  return useMemo(() => {
    const bundlesForLineItem = upgradeBundles.filter(b =>
      lineItem.getUpgradeBundleIds().includes(b.id)
    );
    const groupIds = bundlesForLineItem.map(b => b.upgrade_bundle_group_id);
    const siblingsForBundle = upgradeBundles.filter(
      b =>
        !lineItem.getUpgradeBundleIds().includes(b.id) &&
        groupIds.includes(b.upgrade_bundle_group_id)
    );
    return siblingsForBundle.filter(
      b =>
        lineItem.isActivated() &&
        !lineItem.hasCarts() &&
        !b.activated &&
        b.buyer_activated
    );
  }, [upgradeBundles, lineItem]);
};

const useUpgradeBundlePreferences = lineItem => {
  const upgradeBundles = useSelector(getRoomBookUpgradeBundles);

  return useMemo(() => {
    return upgradeBundles
      .filter(b => lineItem.getUpgradeBundleIds().includes(b.id))
      .filter(b => !lineItem.hasCarts() && !b.activated && b.buyer_activated);
  }, [upgradeBundles, lineItem]);
};

const DiscardActivationButton = ({ onClick }) => {
  const button = (
    <Button
      fluid
      compact
      basic
      color="red"
      className="discard"
      content={<FormattedMessage id="meta.actions.discard" />}
    />
  );
  const buttons = [
    {
      id: "confirm",
      label: "meta.actions.discard",
      color: "red",
      onClick
    },
    {
      id: "cancel",
      label: "meta.actions.cancel",
      basic: true
    }
  ];

  return (
    <ConfirmationDialog
      title="roomBook.actions.discardBuyerSelectionDialog.title"
      message="roomBook.actions.discardBuyerSelectionDialog.message"
      buttons={buttons}
      button={button}
    />
  );
};

DiscardActivationButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

const useActivateUpgradeBundle = roomBookId => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const activateUpgradeBundle = useCallback(
    upgradeBundle => {
      setLoading(true);
      const roomBookUpgradeBundlesResource = new RoomBookUpgradeBundlesResource(
        dispatch,
        roomBookId
      );

      return roomBookUpgradeBundlesResource
        .save({
          id: upgradeBundle.id
        })
        .then(() => setLoading(false));
    },
    [dispatch, roomBookId]
  );

  return { activateUpgradeBundle, loading };
};

const useDiscardUpgradeBundle = roomBookId => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const discardUpgradeBundle = useCallback(
    upgradeBundle => {
      setLoading(true);
      const roomBookUpgradeBundlesResource = new RoomBookUpgradeBundlesResource(
        dispatch,
        roomBookId
      );

      return roomBookUpgradeBundlesResource
        .save({
          id: upgradeBundle.id,
          discard: true
        })
        .then(() => setLoading(false));
    },
    [dispatch, roomBookId]
  );

  return { discardUpgradeBundle, discarding: loading };
};

const UpgradeBundleActivationGuard = ({ roomBook, lineItem, children }) => {
  const conflicts = useUpgradeBundleConflicts(lineItem);
  const preferences = useUpgradeBundlePreferences(lineItem);
  const { activateUpgradeBundle, loading } = useActivateUpgradeBundle(
    roomBook.id
  );
  const { discardUpgradeBundle, discarding } = useDiscardUpgradeBundle(
    roomBook.id
  );

  if (conflicts.length) {
    return (
      <Segment>
        <Grid>
          <Grid.Column width={1}>
            <Icon name="warning circle" color="yellow" size="big" />
          </Grid.Column>
          <Grid.Column width={14} verticalAlign="middle">
            <Header as="h4">
              <FormattedMessage id="roomBook.upgradeBundles.guard.conflict" />
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
  if (preferences.length) {
    return preferences.map(preference => (
      <Segment
        id={`buyerSelectionControl-${preference.id}`}
        key={preference.id}
        data-component="lineItemBuyerSelectionControl"
        loading={loading || discarding}
      >
        <Grid>
          <Grid.Column width={1}>
            <Icon name="warning circle" color="yellow" size="big" />
          </Grid.Column>
          <Grid.Column width={15} verticalAlign="middle">
            <Header as="h4">
              <FormattedMessage id="roomBook.upgradeBundles.guard.preference" />
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Button
              id={`accept-upgrade-bundle-${preference.id}`}
              fluid
              compact
              onClick={() => activateUpgradeBundle(preference)}
              basic
              color="green"
              className="apply"
              content={<FormattedMessage id="meta.actions.activate" />}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <DiscardActivationButton
              onClick={() => discardUpgradeBundle(preference)}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    ));
  }
  return children;
};

export default UpgradeBundleActivationGuard;
