import React, { useState, useMemo, useContext } from "react";
import { Formik, Field } from "formik";
import { Form, Input, TextArea } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { number, node } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import SemanticFormikFileUpload from "shared/components/forms/SemanticFormikFileUpload";
import { DesignLineShape } from "shared/shapes";
import { DesignLinesLoaderContext } from "./DesignLinesLoader";

const DesignLineDialog = ({ button, model, groupId }) => {
  const { saveDesignLine } = useContext(DesignLinesLoaderContext);

  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    summary: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
  });

  const onSubmit = (values, formik) => {
    // only send image to backend when changed.
    const image = values.image !== model.image ? values.image : null;

    saveDesignLine({ ...values, image }, groupId).then(() => {
      const isCreateDialog = !!values.id;
      if (!isCreateDialog) formik.resetForm();
      setOpen(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UpgradeBundleDialog"
    >
      <Modal.Header>
        <FormattedMessage id="design_lines.groups.dialog.title" />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.name.label"
                  })}
                />
                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.title_field.label"
                  })}
                />
                <TextArea
                  id="description"
                  name="description"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.description.label"
                  })}
                />
                <Input
                  id="summary"
                  errorPrompt
                  name="summary"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.summary.label"
                  })}
                />
                <Field
                  name="image"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.image.label"
                  })}
                  component={SemanticFormikFileUpload}
                />

                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

DesignLineDialog.propTypes = {
  button: node.isRequired,
  model: DesignLineShape.isRequired,
  groupId: number.isRequired
};

export default DesignLineDialog;
