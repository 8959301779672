import * as Sentry from "@sentry/react";
import "core-js/stable";
import { extend } from "lodash";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import persistState from "redux-localstorage";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { applyMiddleware, compose, createStore } from "redux";
import "regenerator-runtime/runtime";
import "semantic-ui-css/semantic.min.css";
import { AlertsResource } from "../builder_portal/actions/notificationActions";
import "shared/styles/reset.scss";
import { combinedReducers } from "../builder_portal/reducers/setup";
import routes from "../builder_portal/routes/routes";
import { translations } from "../i18n/translations";
import { UPDATE_ACCOUNT } from "../shared/constants/appConstants";
import localStorage from "../shared/helpers/localStorage";
import apiRequest from "../shared/network/apiRequest";
import { browserHistory } from "../shared/routes/browserHistory";

if (process.env.RAVEN_DSN && process.env.RAVEN_DSN !== "") {
  Sentry.init({ dsn: process.env.RAVEN_DSN });
}

const locale = "de";
const localizedI18nMessages = translations[locale];
moment.locale(locale);
let filter = {};
try {
  filter = JSON.parse(localStorage.get("filter"));
} catch (e) {
  // continue regardless of error
}

const UNPROTECTED_ROUTES = ["/users/password", "/auth_redirect"];

/* ATTENTION
  setting this mock will break scrolling in the app.
  if you use any function from `features/support/temporal_cucumber_helpers.rb` the `TimeCopMockTime` variable will be set
  until now we could work around the issue by carefully using mocking time
  TODO: figure out how to use MockDate.set() with Scrolling
 */
if (process.env.RAILS_ENV !== "production" && window.TimeCopMockTime) {
  import("mockdate").then(MockDate => {
    MockDate.set(window.TimeCopMockTime);
    if (!window.TimeCopMockTimeFreeze) {
      setInterval(() => {
        MockDate.set(Date.now() + 100);
      }, 100);
    }
  });
}

class App extends React.Component {
  constructor(props) {
    super(props);

    // APM.setInitialPageLoadName(`BuilderApp - ${window.location.pathname}`);

    this.initialState = {
      ...this.props,
      ...window.initialProps,
      filter,
      locale,
      i18n: localizedI18nMessages,
      pageContent: null,
      account: null,
      message: null,
      flashAction: null,
      isLoading: false,
      dialog: null
    };
    this.state = { initialized: false };

    const middlewares = [];
    let composeEnhancers = compose;
    if (
      process.env.DISABLE_REDUX_STATE_LOGGING !== "true" &&
      process.env.RAILS_ENV === "development"
    ) {
      // logger may not even initialize to prevent logging
      // eslint-disable-next-line global-require
      const logger = require("../shared/middlewares/logger").default;
      middlewares.push(logger);
    }

    if (process.env.RAILS_ENV === "development") {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    }

    this.store = createStore(
      combinedReducers,
      this.initialState,
      composeEnhancers(
        applyMiddleware(...middlewares),
        persistState("roomBookFilter")
      )
    );
  }

  componentDidMount() {
    this.validateSessionTokenAndRedirect();
  }

  pollForNotifications() {
    new AlertsResource(this.store.dispatch).refresh();
  }

  validateSessionTokenAndRedirect() {
    const that = this;
    const { pathname, search, hash } = window.location;
    let desiredLocation = `${pathname}${search}${hash}`; // @todo handle parameters
    if (UNPROTECTED_ROUTES.indexOf(pathname) < 0) {
      // validate user's sessionToken and load account configuration
      apiRequest.get("/api/v1/tokens", this.store.dispatch, null, true).then(
        response => {
          that.setState({ initialized: true });
          if (response.data && response.data.token.user) {
            // write translation for account to state
            if (translations[response.data.token.account.slug]) {
              that.setState({
                i18n: extend(
                  localizedI18nMessages,
                  translations[response.data.token.account.slug]
                )
              });
            }

            // write account to redux store
            that.store.dispatch({
              type: UPDATE_ACCOUNT,
              payload: response.data.token
            });

            if (!desiredLocation || desiredLocation.match("/users/")) {
              // do not redirect to login, logout, password reset
              desiredLocation = "/";
            }

            // redirect user to desired location
            browserHistory.push(desiredLocation);

            // get notifications
            that.pollForNotifications(); // initial poll
          } else if (
            browserHistory.getCurrentLocation().pathname !== "/users/login"
          ) {
            browserHistory.push("/users/login");
          }
        },
        ({ data: { redirect } = {}, status }) => {
          if (status === 403 && redirect) {
            window.location.replace(redirect);
          } else if (
            browserHistory.getCurrentLocation().pathname !== "/users/login"
          ) {
            browserHistory.push("/users/login");
          }
          that.setState({ initialized: true });
        }
      );
    } else {
      that.setState({ initialized: true });
    }

    /*
     * Even if the user is not authenticated, continue polling.
     * As soon as the user logs in, the polling will be successful.
     */
    if (process.env.DISABLE_NOTIFICATION_POLLING !== "true") {
      setInterval(() => {
        that.pollForNotifications();
      }, 120000); // poll for notifications every 2 Minutes
    }
  }

  render() {
    const { initialized } = this.state;
    return initialized ? (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={localizedI18nMessages}
      >
        <Provider store={this.store}>
          <Router history={browserHistory}>
            {routes(this.store.dispatch)}
          </Router>
        </Provider>
      </IntlProvider>
    ) : null;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement("div"))
  );
  const child = document.getElementById("global-loading-wrapper");
  child.parentNode.removeChild(child);
});
