import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

const getUpgradeBundles = createSelector([getPageContent], pageContent => {
  const upgradeBundles = sortBy(get(pageContent, "upgrade_bundles", []), [
    "name"
  ]);
  return upgradeBundles.map(ub => ({ ...ub, summary: ub.summary || "" }));
});

export const getUpgradeBundleOptions = createSelector(
  [getUpgradeBundles],
  upgradeBundles => {
    return upgradeBundles.map(bundle => ({
      key: bundle.id,
      value: bundle.id,
      text: bundle.name,
      content: bundle.name
    }));
  }
);

export const getDefaultUpgradeBundleOptions = createSelector(
  [getUpgradeBundles],
  upgradeBundles => {
    return upgradeBundles
      .filter(bundle => {
        return bundle.default_dummy;
      })
      .map(bundle => ({
        key: bundle.id,
        value: bundle.id,
        text: bundle.name,
        content: bundle.name
      }));
  }
);

export default getUpgradeBundles;
