import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Button, Icon } from "semantic-ui-react";

const VersionHistoryButton = ({
  id,
  size,
  tooltipPosition,
  floated,
  onClick,
  mode,
  intl,
  color = "grey"
}) => {
  const tooltip = intl.formatMessage({
    id: "attachment.hints.version_history"
  });

  switch (mode) {
    case "icon":
      return (
        <a
          id={id}
          role="button"
          data-tooltip={tooltip}
          data-position={tooltipPosition}
        >
          <Icon name="history" size={size} color={color} onClick={onClick} />
        </a>
      );
    default:
      return (
        <Button
          data-tooltip={tooltip}
          data-position={tooltipPosition}
          icon="history"
          size={size}
          floated={floated}
          onClick={onClick}
        />
      );
  }
};

VersionHistoryButton.propTypes = {
  intl: PropTypes.object.isRequired,
  id: PropTypes.string,
  size: PropTypes.string,
  tooltipPosition: PropTypes.string,
  floated: PropTypes.string,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf(["icon"]),
  color: PropTypes.string
};

VersionHistoryButton.defaultProps = {
  color: "grey"
};

export default injectIntl(VersionHistoryButton);
