import { createSelector } from "reselect";
import { getAccount } from "./account";

const getProductFilterTags = createSelector([getAccount], account => {
  const { taxonomies } = account?.data?.account?.config;
  const taxonomy = taxonomies.find(
    t => t.identifier === "product_filter_tags"
  ) || { tags: [] };

  return taxonomy.tags;
});

export default getProductFilterTags;
