import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { get, uniq, forEach } from "lodash";
import moment from "moment";
import { FormattedMessage, FormattedPlural } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Growl from "builder_portal/actions/growlActions";
import {
  Segment,
  List,
  Label,
  Header,
  Button,
  Grid,
  Icon
} from "semantic-ui-react";
import "./notificationCollection.scss";
import { connect } from "react-redux";
import NotificationsResource, {
  AlertsResource
} from "../../actions/notificationActions";

const NotificationLabel = ({ count, message }) => {
  return (
    <>
      {count > 0 ? (
        <FormattedMessage id={message} defaultMessage={message} />
      ) : (
        ""
      )}
    </>
  );
};

NotificationLabel.propTypes = {
  count: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

class NotificationCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoadingIndicator: false
    };
  }

  componentDidMount() {
    this.setState({ initialLoadingIndicator: true });
    this.props.notificationsResource
      .fetchAll()
      .then(() => {
        this.setState({ initialLoadingIndicator: false });
      })
      .catch(() => {
        this.setState({ initialLoadingIndicator: false });
      });
  }

  handleDismiss = notification => {
    this.setState({ initialLoadingIndicator: true });

    const { alertsResource, notificationsResource } = this.props;
    const payload = [{ id: notification.id }];

    notificationsResource
      .batchUpdate(payload)
      .then(() => {
        notificationsResource.fetchAll();
      })
      .then(() => {
        alertsResource.refresh();
      })
      .catch(() => {
        this.setState({ initialLoadingIndicator: false });
      });
    this.setState({ initialLoadingIndicator: false });
  };

  generateMessage(item) {
    const { i18n } = this.props;

    if (item.messages > 0) {
      return (
        <>
          {item.messages > 1 ? item.messages : ""}{" "}
          <FormattedPlural
            value={item.notificationCount}
            zero={i18n["notification.amountMessages.zero"]}
            one={i18n["notification.amountMessages.one"]}
            other={i18n["notification.amountMessages.other"]}
            defaultMessage={i18n["notification.amountMessages.other"]}
          />{" "}
          {item.contacts.join(", ")}
        </>
      );
    }

    if (item.notification_type === "prospect_configuration") {
      return (
        <FormattedMessage
          id="notification.prospects.new"
          defaultMessage="notification.prospects.new"
        />
      );
    }

    return "";
  }

  renderChildren(notifications) {
    const { i18n } = this.props;
    const children = [];
    const groupedNotifications = {};

    // group notifications by activity
    notifications.map(notification => {
      if (!groupedNotifications[notification.notification_type]) {
        groupedNotifications[notification.notification_type] = {};
      }

      if (
        !groupedNotifications[notification.notification_type][
          notification.source.id
        ]
      ) {
        groupedNotifications[notification.notification_type][
          notification.source.id
        ] = {
          id: notification.id,
          source_id: notification.source.id,
          project_id: notification.source.project_id,
          display_name: notification.source.display_name,
          notificationCount: 0,
          notificationDate: moment.utc(notification.created_at),
          contacts: [],
          messages: 0,
          assignments: 0,
          memoCreated: 0,
          statusChanged: 0,
          messageOutboundCreated: 0,
          type: notification.source.type,
          payload: notification.payload,
          notification_type: notification.notification_type
        };
      }

      const group =
        groupedNotifications[notification.notification_type][
          notification.source.id
        ];
      group.notificationCount += 1;

      switch (notification.notification_type) {
        case "inbound_message":
        case "spoofed_media_type_message":
        case "bounced_message": {
          const contact = get(notification, "payload.sender.label.contact");
          group.contacts.push(contact);
          group.contacts = uniq(group.contacts, "id");
          group.messages += 1;
          break;
        }
        case "assignment":
          group.assignments += 1;
          break;
        case "memo_created":
          group.memoCreated += 1;
          break;
        case "status_changed":
          group.statusChanged += 1;
          break;
        case "message_outbound_created":
          group.messageOutboundCreated += 1;
          break;
        case "prospect_configration_created":
          group.prospectConfigrationCreated += 1;
          break;
      }
    });

    forEach(groupedNotifications, notification => {
      const sortedItems = Object.keys(notification)
        .map(key => {
          return notification[key];
        })
        .sort((a, b) => {
          return b.notificationDate.toDate() - a.notificationDate.toDate();
        });

      sortedItems.map(item => {
        const messagesElement = this.generateMessage(item);

        let header = "";
        let link = "";
        switch (item.type) {
          case "Message":
            header = "messaging.title";
            link = `/messages/${item.payload.thread.id}`;
            break;
          case "Unit":
            if (item.notification_type === "prospect_configuration") {
              header = "notification.prospects.one";
              link = `projects/${item.project_id}/units/${item.source_id}/prospect_list`;
            } else {
              header = "unit.one";
              link = `projects/${item.project_id}/units/${item.source_id}/room-book`;
            }
            break;
          default:
            header = "activity.title.one";
            link = `/projects/${item.project_id}/activities/${item.source_id}`;
        }

        children.push(
          <Grid key={`grid-notification-${item.id}`}>
            <Grid.Column floated="left" width={14}>
              <List.Item key={`notification-${item.source_id}`}>
                <Label color="red" circular className="notificationCount">
                  {item.notificationCount}
                </Label>
                <List.Content>
                  <List.Header>
                    <Link to={link}>
                      <FormattedMessage id={header} defaultMessage={header} />{" "}
                      {item.display_name}
                    </Link>
                    <div className="right floated element">
                      <small>{item.notificationDate.fromNow()}</small>
                    </div>
                  </List.Header>
                  <List.Description>
                    {messagesElement}
                    <NotificationLabel
                      count={item.assignments}
                      message="notification.assignment.label"
                    />
                    <NotificationLabel
                      count={item.memoCreated}
                      message="notification.memoCreated.label"
                    />
                    <NotificationLabel
                      count={item.statusChanged}
                      message="notification.statusChanged.label"
                    />
                    <NotificationLabel
                      count={item.messageOutboundCreated}
                      message="notification.messageOutboundCreated.label"
                    />
                  </List.Description>
                </List.Content>
              </List.Item>
            </Grid.Column>
            <Grid.Column floated="right" width={2}>
              <>
                <Button
                  animated="fade"
                  color="blue"
                  onClick={() => this.handleDismiss(item)}
                  basic
                  size="mini"
                  fluid
                >
                  <Button.Content visible>
                    {i18n["meta.actions.remove"]}
                  </Button.Content>
                  <Button.Content hidden>
                    <Icon name="check" />
                  </Button.Content>
                </Button>
              </>
            </Grid.Column>
          </Grid>
        );
      });
    });
    return children;
  }

  renderDeleteButton = () => {
    const { i18n, notificationsResource, growl, pageContent } = this.props;
    const { isDeletingAll } = this.state;

    const deleteButton = (
      <Button
        id="delete"
        animated="fade"
        color="red"
        basic
        size="mini"
        fluid
        loading={isDeletingAll}
      >
        <Button.Content visible>
          {i18n["meta.actions.remove_all"]}
        </Button.Content>
        <Button.Content hidden>
          <Icon name="check" />
        </Button.Content>
      </Button>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          const notifications =
            (pageContent && pageContent.notifications) || [];
          const ids = notifications.map(notification => ({
            id: notification.id
          }));

          this.setState({ isDeletingAll: true });
          notificationsResource
            .batchUpdate(ids)
            .then(() => {
              closeConfirmation();
              this.setState({ isDeletingAll: false });
              notificationsResource.fetchAll();
            })
            .catch(() => {
              this.setState({ isDeletingAll: false });
              growl.error(
                "message.error.title",
                "meta.confirmations.changesNotSaved"
              );
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="notification.confirmation.title"
        message="notification.confirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  render() {
    const { pageContent } = this.props;
    const notifications = (pageContent && pageContent.notifications) || [];

    if (notifications.length === 0) {
      return (
        <>
          <Header size="large">
            <FormattedMessage
              id="notification.title.other"
              defaultMessage="notification.title.other"
            />
          </Header>

          <Segment
            loading={this.state.initialLoadingIndicator}
            data-component="notificationCollection"
          >
            <FormattedMessage
              id="notification.noResults"
              defaultMessage="notification.noResults"
            />
          </Segment>
        </>
      );
    }

    return (
      <Segment
        loading={this.state.initialLoadingIndicator}
        data-component="notificationCollection"
      >
        <Grid>
          <Grid.Column width={14}>
            <Header>
              <FormattedMessage
                id="notification.title.other"
                defaultMessage="notification.title.other"
              />
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            {this.renderDeleteButton()}
          </Grid.Column>
        </Grid>
        <List celled data-component="notificationList">
          {this.renderChildren(notifications)}
        </List>
      </Segment>
    );
  }
}

NotificationCollection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContent: PropTypes.object.isRequired,
  alertsResource: PropTypes.instanceOf(AlertsResource).isRequired,
  notificationsResource: PropTypes.instanceOf(NotificationsResource).isRequired,
  growl: PropTypes.instanceOf(Growl).isRequired
};

const mapStateToProps = state => {
  const pageContent = { ...state.pageContent };
  return {
    i18n: state.i18n,
    pageContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    alertsResource: new AlertsResource(dispatch),
    notificationsResource: new NotificationsResource(dispatch),
    growl: new Growl(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCollection);
