import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";
import { oneOfType, string, node, shape } from "prop-types";
import "./marketingPortalLink.scss";

function MarketingPortalLink({ label, buttonProps, linkProps }) {
  const mergedProps = {
    target: "_mp",
    ...linkProps,
    as: Link,
    color: "purple",
    basic: true,
    ...buttonProps
  };

  return (
    <Button
      className="marketingPortalLink"
      data-attr="description"
      // PropSpreading is totally fine for HOCs with inheritance
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...mergedProps}
    >
      <Icon name="building" />
      {label}
    </Button>
  );
}

MarketingPortalLink.defaultProps = {
  label: (
    <FormattedMessage
      id="unit.preview.marketing"
      defaultMessage="Musterwohnungsansicht"
    />
  ),
  buttonProps: undefined
};

MarketingPortalLink.propTypes = {
  label: oneOfType([string, node]),
  buttonProps: shape(Button.propTypes),
  linkProps: shape(Link.propTypes).isRequired
};

export default MarketingPortalLink;
