import { string, func, oneOfType, instanceOf } from "prop-types";
import React from "react";
import { Link } from "react-router";
import { LineItemShape } from "shared/components/configurator/roomBook.shape";
import { If } from "shared/components/elements/Conditions";
import { AccountShape } from "shared/shapes";

import {
  Modal,
  Button,
  Icon,
  Loader,
  Grid,
  Dimmer,
  Header,
  Popup
} from "semantic-ui-react";
import { formatDateTime } from "../../../shared/helpers/formatDate";
import { LineItemResource as RoomBookLineItemResource } from "../../actions/roomBookActions";
import { LineItemResource as ActivityLineItemResource } from "../../actions/activityActions";

class LineItemTimelineDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeline: null
    };
  }

  // todo: remove this deprecated method without breaking the component
  // https://gitlab.com/baudigital/plano/-/issues/1334
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { lineItem } = this.props;
    if (nextProps.lineItem && nextProps.lineItem !== lineItem) {
      const { resource, lineItem: nextLineItem } = nextProps;
      resource
        .timeline(nextLineItem.id)
        .then(
          response =>
            this.setState({ timeline: response.data.line_item_timeline }),
          this.handleClose
        );
    }
  }

  handleClose = () => {
    const { toggleDialog } = this.props;
    this.setState({ timeline: null }, () => {
      toggleDialog("lineItemTimelineDialog", null);
    });
  };

  renderTimeline() {
    const { timeline } = this.state;
    if (timeline) {
      if (timeline.events.length) {
        return timeline.events.map(entry => {
          if (entry.type === "transition") {
            return this.renderTransition(entry);
          }
          if (entry.type === "assignment") {
            return this.renderAssignment(entry);
          }
          if (entry.type === "unassignment") {
            return this.renderUnassignment(entry);
          }
          return null;
        });
      }
      return (
        <Grid.Column
          width={16}
          style={{ height: "150px" }}
          textAlign="center"
          verticalAlign="middle"
        >
          <h4>Es liegen noch keine Ereignisse für diese Position vor.</h4>
        </Grid.Column>
      );
    }
    return (
      <Grid.Column width={16} style={{ height: "150px" }}>
        <Dimmer active inverted>
          <Loader>Die Timeline wird geladen...</Loader>
        </Dimmer>
      </Grid.Column>
    );
  }

  renderAssignment(entry) {
    return (
      <Grid.Row key={entry.type + entry.id}>
        <Grid.Column width={10}>
          <h4 style={{ color: "black" }}>
            <Icon name="plus" /> Vorgangszuordnung
          </h4>
          <p style={{ paddingLeft: "10px" }}>
            <small>
              <Icon name="user" />
              &nbsp;{entry.user.name}&nbsp;&nbsp;&nbsp;
              <Icon name="time" />
              &nbsp;{formatDateTime(entry.timestamp)}&nbsp;
            </small>
          </p>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          {this.renderActivityButton(entry.activity)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderUnassignment(entry) {
    return (
      <Grid.Row key={entry.type + entry.id}>
        <Grid.Column width={10}>
          <h4 style={{ color: "black" }}>
            <Icon name="remove" /> Vorgang entfernt
          </h4>
          <p style={{ paddingLeft: "10px" }}>
            <small>
              <Icon name="user" />
              &nbsp;{entry.user.name}&nbsp;&nbsp;&nbsp;
              <Icon name="time" />
              &nbsp;{formatDateTime(entry.timestamp)}&nbsp;
            </small>
          </p>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          {this.renderActivityButton(entry.activity)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderActivityButton(activity, props = {}) {
    const { projectId, account } = this.props;
    const hasActivitiesRight = account.hasProjectRoleRight(
      projectId,
      "allow_access_activities"
    );
    const buttonProps = {
      ...{
        "data-component": "activity-assignment",
        icon: "circle",
        color: "black",
        basic: true,
        size: "tiny",
        content: activity.title,
        disabled: !hasActivitiesRight
      },
      ...props
    };

    const button = <Button {...buttonProps} />;
    return activity.deleted ? (
      <Popup trigger={button}>Vorgang wurde gelöscht.</Popup>
    ) : (
      <>
        <If condition={hasActivitiesRight}>
          <Link
            to={`/projects/${projectId}/activities/${activity.id}/timeline`}
          >
            {button}
          </Link>
        </If>
        <If condition={!hasActivitiesRight}>{button}</If>
      </>
    );
  }

  renderTransition(entry) {
    return (
      <Grid.Row key={entry.type + entry.id}>
        <Grid.Column width={10}>
          <h4 style={{ color: entry.new_state.phase_color }}>
            <Icon name="circle" /> {entry.new_state.label}
          </h4>
          <p style={{ paddingLeft: "10px" }}>
            <small>
              <Icon name="user" />
              &nbsp;{entry.user.name}&nbsp;&nbsp;&nbsp;
              <Icon name="time" />
              &nbsp;{formatDateTime(entry.timestamp)}&nbsp;
            </small>
          </p>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          {this.renderActivityButton(entry.activity, {
            basic: false,
            color: "grey",
            style: { background: entry.new_state.phase_color }
          })}
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { lineItem } = this.props;

    if (!lineItem) return null;

    const { status } = lineItem.status_info;
    return (
      <Modal
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        open={!!lineItem}
        onClose={this.handleClose}
      >
        <Modal.Header>
          {lineItem.display_number} {lineItem.title}
        </Modal.Header>
        <Modal.Content
          style={{ borderBottom: "1px solid rgba(34, 36, 38, 0.15)" }}
        >
          <Header as="h4">
            <Icon
              name="circle"
              size="huge"
              style={{ color: status.phase_color }}
            />{" "}
            {status.label}
          </Header>
          <p style={{ paddingLeft: "20px" }}>{status.hint}</p>
        </Modal.Content>
        <Modal.Content scrolling>
          <Grid>{this.renderTimeline()}</Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

LineItemTimelineDialog.propTypes = {
  projectId: string.isRequired,
  lineItem: LineItemShape,
  resource: oneOfType([
    instanceOf(RoomBookLineItemResource),
    instanceOf(ActivityLineItemResource)
  ]).isRequired,
  toggleDialog: func.isRequired,
  account: AccountShape.isRequired
};

LineItemTimelineDialog.defaultProps = {
  lineItem: null
};

export default LineItemTimelineDialog;
