import React from "react";
import { FormattedMessage } from "react-intl";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { Icon, Message } from "semantic-ui-react";

export const SubLineItemWarnings = props => {
  const { lineItem } = props;

  const warnings = [
    {
      fn: "hasQuantityOnRequest",
      title: "roomBook.lineItems.messages.quantityOnRequest.title",
      body: "roomBook.lineItems.messages.quantityOnRequest.body",
      skipCheckCarts: false
    },
    {
      fn: "hasPriceOnRequest",
      title: "roomBook.lineItems.messages.priceOnRequest.title",
      body: "roomBook.lineItems.messages.priceOnRequest.body",
      skipCheckCarts: false,
      dependencyField: "custom_costs_and_prices_confirmed"
    },
    {
      fn: "isPriseCostDiverged",
      title: "message.warning.title",
      body: "roomBook.lineItems.messages.priseCostDiverged",
      skipCheckCarts: true,
      featureFlagged: true,
      dependencyField: "custom_costs_and_prices_confirmed"
    }
  ];

  const items = warnings
    .filter(w => {
      return (!lineItem.hasCarts() || w.skipCheckCarts) && lineItem[w.fn]();
    })
    .map((w, idx) => {
      if (w.dependencyField && lineItem.currentModel[w.dependencyField])
        return null;
      return (
        <FeatureToggle
          featureToggleName="show_additional_price_cost_diverge"
          ignore={!w.featureFlagged}
          key={idx}
        >
          <Message color="yellow" icon>
            <Icon name="warning circle" size="small" />
            <Message.Content>
              <Message.Header>
                <FormattedMessage
                  id={`${w.title}`}
                  defaultMessage={`${w.title}`}
                />
              </Message.Header>
              <FormattedMessage id={`${w.body}`} defaultMessage={`${w.body}`} />
            </Message.Content>
          </Message>
        </FeatureToggle>
      );
    });

  return <div style={{ marginTop: "1em" }}>{items}</div>;
};
