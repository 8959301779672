import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { get, orderBy } from "lodash";
import { Header, Container, Icon, Segment } from "semantic-ui-react";
import ActivityModel from "builder_portal/containers/activity/activityModel";
import DraftMessages from "builder_portal/components/messages/DraftMessages";
import { If } from "shared/components/elements/Conditions";
import contactsMap from "builder_portal/helpers/contactsMap";
import { BuyerShape } from "shared/shapes/buyer.shape";
import { ContractorShape } from "shared/shapes/contractor.shape";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import ChangeStatusEvent from "./ChangeStatusEvent";
import ChangeDeadlineEvent from "./ChangeDeadlineEvent";
import TaskCompletedEvent from "./TaskCompletedEvent";
import PriceInquirySubmittedEvent from "./PriceInquirySubmittedEvent";
import ChangeTitleEvent from "./ChangeTitleEvent";
import ChangeAssigneeEvent from "./ChangeAssigneeEvent";
import ChangeSummaryEvent from "./ChangeSummaryEvent";
import UploadAttachmentEvent from "./UploadAttachmentEvent";
import RemoveAttachmentEvent from "./RemoveAttachmentEvent";
import AssignContractorEvent from "./AssignContractorEvent";
import UnassignContractorEvent from "./UnassignContractorEvent";
import CreateEvent from "./CreateEvent";
import DeadlineEvent from "./DeadlineEvent";
import MessageEvent from "./MessageEvent";
import MemoEvent from "./MemoEvent";
import Today from "./Today";
import MessageForm from "./MessageForm";

import "./timeline.scss";

class Timeline extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messageDraft: null
    };
  }

  setMessageDraft = messageDraft => {
    this.setState(prev => ({ ...prev, messageDraft }));
  };

  render() {
    const {
      project,
      account,
      i18n,
      buyer,
      buyers,
      contractors,
      activity,
      filter,
      actions,
      timeline,
      states,
      deadlines,
      handleUpdate,
      memosResource,
      notifications,
      messageForm,
      updateMessageForm,
      attachments,
      macros,
      contacts,
      ctxModel,
      ctxCtrl,
      draftsCount
    } = this.props;

    const { messageDraft } = this.state;

    const tomorrow = moment(new Date()).add(1, "days");

    // flatten event date
    let timelineEvents = (timeline && timeline.slice(0)) || []; // clones timeline prop (to not modify it)

    // add deadline elements to timelineEvents (just added will be sorted below)
    if (states && timelineEvents) {
      deadlines?.forEach((deadlineItem, index) => {
        timelineEvents.push({
          event: {
            event: "deadline_expiry_date",
            key: `deadline_expiry_date-${moment(
              deadlineItem.expires_at
            ).unix()}-${index}`,
            created_at: deadlineItem.expires_at,
            complete: Boolean(deadlineItem.completed_at),
            label:
              states[deadlineItem.expiry_ref] &&
              states[deadlineItem.expiry_ref].label
          }
        });
      });
    }
    // sort timeline events by date
    timelineEvents = orderBy(
      timelineEvents,
      element => element.event.created_at,
      ["desc"]
    );

    let draftListPosition = 1;

    if (!filter || !filter.showFutureEvents) {
      draftListPosition = 0;
      // erase upcoming events
      timelineEvents =
        timelineEvents.length &&
        timelineEvents.filter(item => {
          if (item && item.event && item.event.created_at) {
            if (
              moment(item.event && item.event.created_at).isBefore(tomorrow)
            ) {
              return true;
            }
          } else {
            return true;
          }
        });
    }

    const timelineSegments = [];

    let currentMonth = "";
    let lastMonth = "";
    let todaySegmentParsedAlready = false;

    // there will be always at least one item to display: the activity created item
    if (states) {
      timelineEvents?.map((item, index) => {
        // render month dividers
        const timestamp = moment(item.event.sent_at || item.event.created_at);
        currentMonth = `${timestamp.format("MMMM")} ${moment(
          item.event.created_at
        ).format("YYYY")}`;
        if (currentMonth !== lastMonth) {
          timelineSegments.push(
            <Header size="large" className="monthDivider" key={currentMonth}>
              {currentMonth}
            </Header>
          );
        }
        lastMonth = currentMonth;

        const date = timestamp.format("DD.MM.YYYY");
        const time = timestamp.format("HH:mm");
        const user = item.event && item.event.editor;
        const userName =
          item.event && item.event.editor && item.event.editor.label;
        // decide if today-segment should be placed here (future events will be shown above it, past events below it)
        if (timestamp.isBefore(tomorrow) && !todaySegmentParsedAlready) {
          timelineSegments.push(
            <Today
              key={`item-today-${ctxModel.id}`}
              i18n={i18n}
              ctxModel={ctxModel}
              ctxCtrl={ctxCtrl}
            />
          );
          todaySegmentParsedAlready = true;
        }

        if (draftsCount && index === draftListPosition && filter.showMessages) {
          const showDraftList = !messageDraft || messageDraft?.parent_id;
          timelineSegments.push(
            <FeatureToggle
              featureToggleName="show_drafts"
              key={`drafts-${activity.id}`}
            >
              <div data-component="timelineItem">
                <div className="bullet-pointer">
                  <Icon
                    color="grey"
                    size="large"
                    circular
                    name="edit outline"
                  />
                </div>
                <If condition={!!showDraftList}>
                  <Container>
                    <Segment>
                      <DraftMessages
                        handleOpenDraft={this.setMessageDraft}
                        activityId={activity.id}
                        view="timeline"
                      />
                    </Segment>
                  </Container>
                </If>
                <If condition={!showDraftList}>
                  <Container>
                    <Segment data-component="messageForm">
                      <MessageForm
                        account={account}
                        i18n={i18n}
                        activity={activity}
                        buyer={buyer}
                        buyers={buyers}
                        contractors={contractors}
                        project={project}
                        handleClose={() => this.setMessageDraft(null)}
                        handleUpdate={handleUpdate}
                        contacts={contactsMap(contacts)}
                        attachments={attachments}
                        defaultAttachments={messageDraft?.attachments}
                        macro={messageDraft || {}}
                        ctxCtrl={ctxCtrl}
                        messageDraft={messageDraft}
                      />
                    </Segment>
                  </Container>
                </If>
              </div>
            </FeatureToggle>
          );
        }

        // apply filter settings on which elements to show

        const eventsToShow = [];
        // if (filter.showAssigneeChanged)
        eventsToShow.push("activity_created"); // always show
        eventsToShow.push("price_inquiry_submitted"); // always show price inquiry submissions

        if (filter && filter.showDeadlines) {
          eventsToShow.push("deadline_expiry_date");
        }
        if (filter && filter.showStatusChanges) {
          eventsToShow.push("status_changed");
        }
        if (filter && filter.showAuditEvents) {
          eventsToShow.push("assignee_changed");
          eventsToShow.push("title_changed");
          eventsToShow.push("summary_changed");
          eventsToShow.push("deadline_changed");
          eventsToShow.push("contractor_assigned");
          eventsToShow.push("contractor_unassigned");
          eventsToShow.push("attachment_uploaded");
          eventsToShow.push("task_completed");
          eventsToShow.push("price_inquiry_submitted");
          eventsToShow.push("attachment_removed");
        }
        if (filter && filter.showMessages) {
          eventsToShow.push("message");
        }
        if (filter && filter.showMemos) {
          eventsToShow.push("memo");
        }

        // event segments
        if (
          item.event &&
          item.event.event &&
          eventsToShow.find(allowedItem => allowedItem == item.event.event)
        ) {
          switch (item.event.event) {
            case "activity_created":
              timelineSegments.push(
                <CreateEvent key={item.event.key} date={date} user={userName} />
              );
              break;
            case "assignee_changed":
              timelineSegments.push(
                <ChangeAssigneeEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  oldValue={item.event.old_value.name}
                  newValue={item.event.new_value.name}
                />
              );
              break;
            case "deadline_expiry_date":
              timelineSegments.push(
                <DeadlineEvent
                  key={item.event.key}
                  date={date}
                  complete={item.event.complete}
                  label={item.event.label}
                />
              );
              break;
            case "status_changed":
              timelineSegments.push(
                <ChangeStatusEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  color={get(states[item.event.old_value], "phase.color")}
                  oldValue={get(states[item.event.old_value], "label")}
                  newValue={get(states[item.event.new_value], "label")}
                />
              );
              break;
            case "task_completed":
              timelineSegments.push(
                <TaskCompletedEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  title={item.event.title}
                  delegatee={get(item, "event.delegatee.label.company")}
                />
              );
              break;
            case "price_inquiry_submitted":
              timelineSegments.push(
                <PriceInquirySubmittedEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  title={activity.title}
                  contractor={get(item, "event.contractor.name")}
                />
              );
              break;
            case "deadline_changed":
              if (item.event.expiry_type === "status") {
                timelineSegments.push(
                  <ChangeDeadlineEvent
                    key={item.event.key}
                    date={date}
                    user={userName}
                    status={states[item.event.expiry_ref].label}
                    oldValue={item.event.expires_at.old_value}
                    newValue={item.event.expires_at.new_value}
                  />
                );
              }
              break;
            case "title_changed":
              timelineSegments.push(
                <ChangeTitleEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  oldValue={item.event.old_value}
                  newValue={item.event.new_value}
                />
              );
              break;
            case "summary_changed":
              timelineSegments.push(
                <ChangeSummaryEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  oldValue={item.event.old_value}
                  newValue={item.event.new_value}
                />
              );
              break;
            case "contractor_assigned":
              timelineSegments.push(
                <AssignContractorEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  name={get(item, "event.name")}
                />
              );
              break;
            case "contractor_unassigned":
              timelineSegments.push(
                <UnassignContractorEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  name={get(item, "event.name")}
                />
              );
              break;
            case "attachment_uploaded":
              timelineSegments.push(
                <UploadAttachmentEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  type={
                    item.event.attachment &&
                    i18n[
                      `attachment.type.${item.event.attachment.role}.singluar`
                    ]
                  }
                  name={
                    item.event.attachment && item.event.attachment.display_name
                  }
                />
              );
              break;
            case "attachment_removed":
              timelineSegments.push(
                <RemoveAttachmentEvent
                  key={item.event.key}
                  date={date}
                  user={userName}
                  type={
                    item.event.attachment &&
                    i18n[
                      `attachment.type.${item.event.attachment.role}.singluar`
                    ]
                  }
                  name={
                    item.event.attachment && item.event.attachment.display_name
                  }
                />
              );
              break;
            case "message": {
              let showThisMessage = true;
              if (
                filter.stakeholder &&
                JSON.stringify(filter.stakeholder) !== "{}"
              ) {
                showThisMessage = false;
                Object.keys(filter.stakeholder).map(type => {
                  filter.stakeholder[type].map(id => {
                    if (
                      id === item.event.message.recipient.id ||
                      id === item.event.message.sender.id
                    ) {
                      showThisMessage = true;
                    }
                  });
                });
              }

              if (showThisMessage) {
                let isRead = true;
                notifications?.map(notificationItem => {
                  if (
                    notificationItem.payload &&
                    notificationItem.payload.message_id == item.event.message.id
                  ) {
                    isRead = false;
                  }
                });
                timelineSegments.push(
                  <MessageEvent
                    account={account}
                    i18n={i18n}
                    key={`assign-trade-event-${item.event.created_at}`}
                    isRead={isRead}
                    date={date}
                    time={time}
                    actions={actions}
                    activity={activity}
                    attachments={attachments}
                    macros={macros}
                    contacts={contacts}
                    messageForm={messageForm}
                    updateMessageForm={updateMessageForm}
                    message={item.event.message}
                    ctxCtrl={ctxCtrl}
                    messageDraft={
                      messageDraft?.parent_id === item.event.message.id
                        ? messageDraft
                        : null
                    }
                    handleClose={() => this.setMessageDraft(null)}
                  />
                );
              }
              break;
            }
            case "memo":
              timelineSegments.push(
                <MemoEvent
                  i18n={i18n}
                  currentUser={account.getCurrentUser()}
                  contacts={contacts}
                  memosResource={memosResource}
                  handleUpdate={handleUpdate}
                  key={item.event.key}
                  date={date}
                  time={time}
                  user={user}
                  memo={item.event && item.event.memo}
                />
              );
              break;
          }
        }
      });
    }

    return (
      <div data-component="timeline">
        <div className="items">{timelineSegments || []}</div>
      </div>
    );
  }
}

Timeline.propTypes = {
  account: PropTypes.object,
  actions: PropTypes.object,
  activity: PropTypes.object,
  attachments: PropTypes.object,
  buyer: PropTypes.object,
  buyers: PropTypes.arrayOf(BuyerShape),
  contractors: PropTypes.arrayOf(ContractorShape),
  contacts: PropTypes.array,
  contractorAssignmentResource: PropTypes.object,
  contractorOptions: PropTypes.object,
  contractorsAssigned: PropTypes.array,
  ctxModel: PropTypes.instanceOf(ActivityModel).isRequired,
  deadlines: PropTypes.array,
  handleUpdate: PropTypes.func,
  i18n: PropTypes.object,
  macros: PropTypes.array,
  memosResource: PropTypes.object,
  messageForm: PropTypes.object,
  notifications: PropTypes.array,
  project: PropTypes.object,
  states: PropTypes.object,
  tasks: PropTypes.array,
  tasksResource: PropTypes.object,
  updateMessageForm: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object.isRequired,
  draftsCount: PropTypes.number.isRequired
};

Timeline.defaultProps = {
  buyers: [],
  contractors: []
};

export default Timeline;
