import React from "react";
import { connect } from "react-redux";
import { getI18N } from "shared/selectors";
import { createStructuredSelector } from "reselect";

import AddAccount from "./AddAccount";
import AddProject from "./AddProject";
import AddSalesAccount from "./AddSalesAccount";
import ChangePricesAndCosts from "./ChangePricesAndCosts";
import ChangePriceStrategy from "./ChangePriceStrategy";
import CopyProjectCatalog from "./CopyProjectCatalog";
import MigrateUser from "./MigrateUser";
import DeleteProductFromProductGroup from "./DeleteProductFromProductGroup";
import DeleteProject from "./DeleteProject";
import MigrateDocumentExports from "./MigrateDocumentExports";

import "./supportUtils.scss";

class SupportUtils extends React.Component {
  render() {
    const { i18n } = this.props;

    return (
      <div data-component="support_utils">
        <h1>Danger Zone</h1>
        <p>
          <b>
            Bitte nur nutzen, wenn du weisst, was du tust! Sonst bitte einen
            Entwickler, dir zu helfen.
          </b>
        </p>
        <ChangePriceStrategy i18n={i18n} />
        <hr />
        <AddAccount i18n={i18n} />
        <hr />
        <AddSalesAccount i18n={i18n} />
        <hr />
        <AddProject i18n={i18n} />
        <hr />
        <CopyProjectCatalog />
        <hr />
        <MigrateUser />
        <hr />
        <DeleteProductFromProductGroup />
        <hr />
        <DeleteProject />
        <hr />
        <ChangePricesAndCosts />
        <hr />
        <MigrateDocumentExports />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  i18n: getI18N
});

export default connect(mapStateToProps)(SupportUtils);
