import moment from "moment";

const buyerInvitationStatus = {
  status: buyers => {
    if (buyers.some(buyer => buyer.portal_active)) {
      if (buyers.some(buyer => buyer.login_count > 0)) {
        return "all";
      }
      if (buyers.some(buyer => buyer.portal_registered)) {
        return "registered";
      }
      if (buyers.some(buyer => buyer.invited_at)) {
        return "sent";
      }
    } else if (buyers.some(buyer => buyer.portal_active)) {
      return "some";
    }

    return "none";
  },

  color: buyers => {
    if (buyers.some(buyer => buyer.portal_active)) {
      if (buyers.some(buyer => buyer.login_count > 0)) {
        return "green";
      }

      if (buyers.some(buyer => buyer.portal_active)) {
        const buyer = buyers.find(function(buyer) {
          return moment(buyer.invited_at);
        });
        const invitedAt = moment(buyer.invited_at);
        const today = moment();
        const difference = today.diff(invitedAt, "days");

        if (difference < 3) {
          return "teal";
        }
        if (difference < 7) {
          return "orange";
        }
        return "red";
      }
    } else if (buyers.some(buyer => buyer.portal_active)) {
      return "orange";
    } else {
      return "grey";
    }
  },

  icon: buyers => {
    if (buyers.some(buyer => buyer.portal_active)) {
      if (buyers.some(buyer => buyer.login_count > 0)) {
        return "check circle";
      }
      if (buyers.some(buyer => buyer.portal_registered)) {
        return "envelope open";
      }

      if (buyers.some(buyer => buyer.invited_at)) {
        return "envelope";
      }
    } else if (buyers.some(buyer => buyer.portal_active)) {
      return "check circle";
    } else {
      return "minus circle";
    }
  }
};

export { buyerInvitationStatus };
