/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Container, Header, Button, Modal } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Form,
  Select,
  Checkbox,
  TextArea
} from "formik-semantic-ui-react";
import { useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import SemanticFormikColorInputWithHexField from "shared/components/forms/SemanticFormikColorInputWithHexField";
import SemanticFormikImageSelector from "shared/components/forms/SemanticFormikImageSelector";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import * as Yup from "yup";
import { getConfiguratorDesigns } from "builder_portal/selectors/configuratorDesigns";
import { sortBy } from "lodash";
import copyToClipboard from "copy-to-clipboard";
import Growl from "../../../../actions/growlActions";
import { FlatFinderResource } from "../../../../actions/flatFinderActions";

const FlatFinderDialog = ({ model, trigger, resource, projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const configDesigns = sortBy(useSelector(getConfiguratorDesigns), "id");

  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    data_url: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    configurator_design_id: Yup.string().nullable(),
    slug: Yup.string()
  });

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = useCallback(
    values => {
      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }

      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "flatFinder.states.saving"
          );
          handleClose();
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "flatFinder.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  const onDelete = () => {
    resource
      .remove(model?.id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "flatFinder.dialog.savedSuccessfully"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "flatFinder.dialog.changesNotSaved"
        );
      });
  };

  const configDesignsOptions = React.useMemo(() => {
    const options = configDesigns?.map(customDataType => ({
      key: customDataType.id,
      text: `${customDataType.name}`,
      value: customDataType.id
    }));
    return options;
  }, [configDesigns]);

  const renderDeleteButton = isSubmitting => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="flatFinder.dialog.deleteTitle"
        message="flatFinder.dialog.deleteMessage"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Formik
      initialValues={
        model ?? {
          name: "",
          data_url: "",
          configurator_design: ""
        }
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            trigger={trigger}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            size="small"
          >
            <Header
              content={intl.formatMessage({
                id: model?.id
                  ? "flatFinder.dialog.editTitle"
                  : "flatFinder.dialog.newTitle"
              })}
            />
            <Modal.Content scrolling>
              <Container>
                <Form>
                  <Input
                    fluid
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.name"
                    })}
                    name="name"
                    errorPrompt
                  />
                  <If condition={!!model}>
                    <Input
                      label={intl.formatMessage({
                        id: "flatFinder.dialog.url"
                      })}
                      action={{
                        icon: "copy",
                        "data-component": "copy-to-clipboard",
                        onClick: event => {
                          event.preventDefault();
                          copyToClipboard(model?.url);
                        }
                      }}
                      name="url"
                      readOnly
                    />
                  </If>
                  <Input
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.slug"
                    })}
                    name="slug"
                    errorPrompt
                  />
                  <Input
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.dataUrl"
                    })}
                    name="data_url"
                    errorPrompt
                  />
                  <Select
                    fluid
                    clearable
                    search
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.configuratorDesign"
                    })}
                    name="configurator_design_id"
                    options={configDesignsOptions}
                    errorPrompt
                  />

                  <Checkbox
                    name="default"
                    label={useIntl().formatMessage({
                      id: "flatFinder.dialog.default"
                    })}
                  />

                  <Field
                    name="color_hover"
                    type="color"
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.colorHover"
                    })}
                    component={SemanticFormikColorInputWithHexField}
                  />
                  <Field
                    name="color_active"
                    type="color"
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.colorActive"
                    })}
                    component={SemanticFormikColorInputWithHexField}
                  />
                  <Input
                    fluid
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.welcomeTitle"
                    })}
                    name="welcome_title"
                  />

                  <TextArea
                    name="welcome_message"
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.welcomeMessage"
                    })}
                  />

                  <Field
                    name="placeholder_image_id"
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.placeholderImage"
                    })}
                    component={SemanticFormikImageSelector}
                    title={intl.formatMessage({
                      id: "flatFinder.dialog.placeholderImage"
                    })}
                    projectId={projectId}
                    multiple={false}
                  />

                  <Field
                    name="config"
                    label={intl.formatMessage({
                      id: "flatFinder.dialog.config"
                    })}
                    component={SemanticFormikJsonEditor}
                  />
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    content={intl.formatMessage({ id: "meta.actions.save" })}
                    loading={isSubmitting}
                    positive
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

FlatFinderDialog.propTypes = {
  trigger: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object,
  resource: PropTypes.instanceOf(FlatFinderResource).isRequired,
  projectId: PropTypes.number.isRequired
};

FlatFinderDialog.defaultProps = {
  model: null
};

export default FlatFinderDialog;
