import PropTypes from "prop-types";

export const UnitVariableTypeShape = PropTypes.shape({
  identifier: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
});

export const UnitVariableShape = PropTypes.shape({
  unitId: PropTypes.number,
  unitVariableTypeId: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
});
