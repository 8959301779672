import { func, string, object, arrayOf } from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Button,
  Modal,
  Icon,
  Form,
  Grid,
  Popup
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getDocumentExportTextKeys } from "builder_portal/selectors/documentExports";

function ExportTexts({ roleData: exportType, value, onChange }) {
  const exportTextKeys = useSelector(getDocumentExportTextKeys);

  const handleChange = (e, field_key) => {
    const newTextsData = { ...value, [field_key]: e.target.value };
    onChange(newTextsData);
  };

  const handleAddCustomKey = ({ key, val }) => {
    const newTextsData = { ...value };
    newTextsData[key] = val;
    onChange(newTextsData);
  };

  const handleDeleteCustomKey = key => {
    const newTextsData = { ...value };
    delete newTextsData[key];

    onChange(newTextsData);
  };

  const renderDeleteIcon = key => {
    return (
      <Icon
        link
        name="trash"
        className="delete-field"
        style={{ marginBottom: "1.3rem", marginLeft: "0.5rem" }}
        onClick={() => handleDeleteCustomKey(key)}
      />
    );
  };

  const getCustomKeys = () => {
    const default_keys = exportTextKeys[exportType]?.map(field => field.key);
    const config_keys = Object.keys(value);

    const custom_keys = config_keys.filter(
      config_key => !default_keys.includes(config_key)
    );
    return custom_keys;
  };

  return (
    <Grid columns={2} style={{ marginTop: "1rem" }}>
      {exportTextKeys[exportType]?.map(field => {
        return (
          <Grid.Column key={field.key} width={8}>
            <Form.Field>
              <label htmlFor={field.key}>
                {field.label || field.key}
                <Popup
                  content={field.description || "No Info"}
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ paddingLeft: "0.2rem" }}
                    />
                  }
                />
              </label>
              <Input
                id={field.key}
                fluid
                placeholder={field.default_value}
                style={{ maxWidth: "80%" }}
                value={value[field.key]}
                onChange={e => handleChange(e, field.key)}
              />
            </Form.Field>
          </Grid.Column>
        );
      })}
      <Grid.Row>
        {getCustomKeys().map(key => {
          return (
            <Grid.Column key={key}>
              <label style={{ display: "block", margin: "0 0 .28571429rem 0" }}>
                {key}
              </label>
              <Input
                style={{ marginBottom: "1rem", maxWidth: "80%" }}
                value={value[key]}
                onChange={e => handleChange(e, key)}
              />
              {renderDeleteIcon(key)}
            </Grid.Column>
          );
        })}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddFieldModal
            defaultKeys={exportTextKeys[exportType]?.map(field => field.key)}
            handleAddCustomKey={handleAddCustomKey}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function AddFieldModal({ handleAddCustomKey, defaultKeys }) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({});
  const [formKeyError, setFormKeyError] = useState(false);
  const [formMissingKeyError, setFormMissingKeyError] = useState(false);
  const [formValueError, setFormValueError] = useState(false);

  const handleOpen = e => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = e => {
    e.preventDefault();
    setFormData({});
    setFormKeyError(false);
    setFormMissingKeyError(false);
    setFormValueError(false);
    setOpen(false);
  };

  const handleChange = (e, { name, value }) => {
    if (formMissingKeyError && name === "key") setFormMissingKeyError(false);
    if (formValueError && name === "value") setFormValueError(false);
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = e => {
    if (defaultKeys.includes(formData.key)) {
      setFormKeyError({
        content: (
          <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.error" />
        ),
        pointing: "below"
      });
    } else if (!formData.key) {
      setFormMissingKeyError({
        content: (
          <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.noKey" />
        ),
        pointing: "below"
      });
    } else if (!formData.value) {
      setFormValueError({
        content: (
          <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.errorValue" />
        ),
        pointing: "below"
      });
    } else if (formData.key && formData.value) {
      handleAddCustomKey({ key: formData.key, val: formData.value });
      handleClose(e);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Form>
          <Form.Input
            autoFocus
            label={
              <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.key" />
            }
            error={formKeyError || formMissingKeyError}
            name="key"
            value={formData.key}
            onChange={handleChange}
          />

          <Form.Input
            label={
              <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.value" />
            }
            name="value"
            error={formValueError}
            value={formData.value}
            onChange={handleChange}
          />
        </Form>
      </>
    );
  };

  const renderModalActions = () => {
    return (
      <>
        <Button
          basic
          id="cancel"
          content={<FormattedMessage id="meta.actions.cancel" />}
          onClick={handleClose}
        />
        <Button
          primary
          id="submit"
          content={<FormattedMessage id="meta.actions.add" />}
          onClick={handleSubmit}
        />
      </>
    );
  };

  return (
    <Modal
      closeOnEscape
      closeOnDimmerClick
      closeIcon
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      size="large"
      trigger={
        <Button
          icon="plus"
          content={
            <FormattedMessage id="documentsExport.dialogs.exports.content.addCustomKey.add" />
          }
          style={{ marginTop: "1rem" }}
        />
      }
    >
      <Modal.Header>
        <FormattedMessage
          id="documentsExport.dialogs.exports.content.addCustomKey.title"
          default="documentsExport.dialogs.exports.content.addCustomKey.title"
        />
      </Modal.Header>
      <Modal.Content>{renderModalContent()}</Modal.Content>
      <Modal.Actions>{renderModalActions()}</Modal.Actions>
    </Modal>
  );
}

ExportTexts.propTypes = {
  roleData: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: object.isRequired,
  onChange: func.isRequired
};

AddFieldModal.propTypes = {
  handleAddCustomKey: func.isRequired,
  defaultKeys: arrayOf(string).isRequired
};

export default ExportTexts;
