import localStorage from "../helpers/localStorage";

export default class UserConfig {
  set(key, value) {
    localStorage.set(key, value);
  }

  get(key) {
    return localStorage.get(key);
  }

  remove(key) {
    localStorage.remove(key);
  }

  setSectionFilter(projectSlug, sectionId) {
    return this.set(`project.${projectSlug}.sectionFilter`, sectionId);
  }

  getSectionFilter(projectSlug) {
    return this.get(`project.${projectSlug}.sectionFilter`);
  }

  removeSectionFilter(projectSlug) {
    return this.remove(`project.${projectSlug}.sectionFilter`);
  }
}
