import React from "react";
import { object, string } from "prop-types";
import { Form } from "semantic-ui-react";
import FileUploader from "../../../builder_portal/components/dropzone/FileUploader";

const SemanticFormikFileUpload = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label
  } = props;

  const handleFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      setFieldValue(name, binaryStr);
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = () => {
    setFieldValue(name, null);
    setFieldValue(`destroy_${name}`, true);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <FileUploader
        id={name}
        fileType="image"
        name={name}
        previewImageUrl={value}
        handleFile={handleFile}
        handleDelete={handleRemove}
      />
    </Form.Field>
  );
};

SemanticFormikFileUpload.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  field: object.isRequired,
  label: string.isRequired
};

export default SemanticFormikFileUpload;
