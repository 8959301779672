import React from "react";
import { string, oneOfType, number } from "prop-types";
import UnitVariablesLoader from "./UnitVariablesLoader";
import UnitVariablesList from "./UnitVariablesList";

const UnitVariables = ({ unitId, projectId }) => {
  return (
    <UnitVariablesLoader
      unitId={unitId}
      projectId={projectId}
      isProjectLevel={false}
    >
      <UnitVariablesList />
    </UnitVariablesLoader>
  );
};

UnitVariables.propTypes = {
  unitId: oneOfType([string, number]),
  projectId: oneOfType([string, number])
};

UnitVariables.defaultProps = {
  unitId: null,
  projectId: null
};

export default UnitVariables;
