import React from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import apiRequest from "shared/network/apiRequest";
import ProfileResource from "builder_portal/actions/profileActions";
import { func, instanceOf } from "prop-types";

class MigrateDocumentExports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceId: null,
      migrationType: null,
      error: null,
      success: null,
      loading: false
    };
  }

  componentDidMount() {
    const { profileResource } = this.props;
    profileResource.get(true);
  }

  handleSubmit = () => {
    const { sourceId } = this.state;
    const { migrationType } = this.state;
    const { submit } = this.props;
    this.setState({ loading: true });

    submit(sourceId, migrationType)
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      })
      .catch(error => {
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        });
        // prevent silent catch
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  render() {
    const { profile } = this.props;
    const { loading, error, success } = this.state;

    const accountOptions = profile?.account_memberships.map(account => {
      return {
        key: `project-${account.id}`,
        text: `${account.name}`,
        value: `${account.id}`
      };
    });

    const migrationTypes = ["old", "plano", "main_table"].map(key => {
      return {
        key,
        text: key,
        value: key
      };
    });

    return (
      <div>
        <h2>Migrate document exports to DB</h2>
        <p>
          This action migrates all document exports belonging to the source
          account from .json files to the database.
        </p>
        <Form
          loading={loading}
          success={success}
          error={!!error}
          onSubmit={this.handleSubmit}
        >
          <Message
            success
            header="Success!"
            content="Please check the results!"
          />
          <Message error header="Error" content={error} />
          <Form.Select
            name="sourceId"
            options={accountOptions}
            onChange={(i, { value }) => this.setState({ sourceId: value })}
            label="Source Account"
            placeholder="Source Account"
            required
            width="4"
          />
          <Form.Select
            name="migrationType"
            options={migrationTypes}
            onChange={(i, { value }) => this.setState({ migrationType: value })}
            label="Migration Type"
            placeholder="Migration Type"
            required
            width="4"
          />
          <Form.Button width="4">Submit</Form.Button>
        </Form>
      </div>
    );
  }
}

const getSubmit = dispatch => (sourceId, migrationType) =>
  apiRequest.post("/api/v1/support_utils/migrate_document_exports", dispatch, {
    migrate_document_export: {
      source_id: sourceId,
      migration_type: migrationType
    }
  });

MigrateDocumentExports.propTypes = {
  profileResource: instanceOf(ProfileResource).isRequired,
  profile: instanceOf(ProfileResource).isRequired,
  submit: func.isRequired
};

const mapStateToProps = state => ({ profile: state.account });

const mapDispatchToProps = dispatch => ({
  profileResource: new ProfileResource(dispatch),
  submit: getSubmit(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrateDocumentExports);
