import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link, routerShape } from "react-router";
import { Helmet } from "react-helmet";
import { get, find, take, uniq } from "lodash";
import { getAccountData } from "shared/selectors";
import {
  shape,
  string,
  instanceOf,
  arrayOf,
  oneOfType,
  node,
  func,
  number
} from "prop-types";
import { Container, Loader, Menu } from "semantic-ui-react";
import { I18nShape } from "shared/shapes/i18n.shape";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import { FormattedMessage, useIntl } from "react-intl";
import { ProductCatalogsResource } from "builder_portal/actions/productCatalogActions";
import { withRouter } from "../../../shared/helpers/withRouter";
import CatalogResource from "../../actions/catalogActions";
import ProductGroupResource from "../../actions/productGroupActions";
import ProjectCatalogSelectionMenuBar from "../navigation/ProjectCatalogSelectionMenuBar";
import removeDeprecatedLocalStorageItems from "../../helpers/removeDeprecatedLocalStorageItems";

import "./productLayout.scss";
import HasAccessProjectCatalogRight from "../../../shared/components/authorization/HasAccessProjectCatalogRight";

const LOCALSTORAGE_KEY = "recentProjectCatalogIds";

removeDeprecatedLocalStorageItems(["recentProductCatalogIds"]);

const ProductLayout = ({
  accountId,
  params,
  i18n,
  resources,
  catalogs,
  children,
  router,
  location
}) => {
  const [loading, setLoading] = useState(true);
  const { formatMessage } = useIntl();

  const [storedProjectIds, setStoredProjectIds] = useState(
    JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || {}
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      resources.catalogs.fetchAll({ shallow: true }),
      resources.productCatalogs.fetchAll()
    ]).then(() => setLoading(false));
  }, []);

  const activeProjectId = params.catalogId;
  const recentProjectIds = useMemo(() => {
    const defaultIds = catalogs.map(c => c.slug);
    return take(
      uniq(get(storedProjectIds, [accountId, "recent"], []).concat(defaultIds)),
      2
    );
  }, [catalogs, storedProjectIds]);

  function handleChange(id) {
    const catalog = find(catalogs, c => c.id === id);

    const update = {
      ...storedProjectIds,
      [accountId]: {
        recent: take(uniq([catalog.slug].concat(recentProjectIds)), 2),
        last: catalog.slug
      }
    };

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(update));

    setStoredProjectIds(update);

    router.push(`/products/${catalog.slug}/product_groups`);
  }

  const options = [
    {
      as: Link,
      to: "/products",
      key: "all-products",
      value: "all-products",
      text: formatMessage({ id: "product_group.menu.overview" }),
      icon: "warehouse"
    }
  ];

  const productCatalogMenuItem = (
    <Menu.Item
      as={Link}
      to="/products"
      key="all-products"
      name={formatMessage({ id: "product_group.menu.overview" })}
      active={location.pathname === "/products" || !!params.supplier_id}
      // todo: make this pretty
      className="ellipsis ellipsis-400"
    >
      <FormattedMessage id="product_group.menu.overview" />
    </Menu.Item>
  );

  if (loading) return <Loader active />;

  return (
    <div>
      <Helmet
        title={`${i18n["product.title.catalog"]} - ${i18n["meta.app.name"]}`}
      />
      <Container>
        <HasAccessProjectCatalogRight>
          <ProjectCatalogSelectionMenuBar
            customOptions={options}
            customDropdownText="Katalog wählen"
            customItems={[productCatalogMenuItem]}
            catalogs={catalogs}
            recentProjectIds={recentProjectIds}
            handleChange={handleChange}
            activeProjectId={activeProjectId}
          />
        </HasAccessProjectCatalogRight>
        <div>{children}</div>
      </Container>
    </div>
  );
};

ProductLayout.propTypes = {
  params: shape({
    projectId: string
  }).isRequired,
  i18n: I18nShape.isRequired,
  resources: shape({
    catalogs: instanceOf(CatalogResource),
    productGroups: func,
    productCatalogs: instanceOf(ProductCatalogsResource)
  }).isRequired,
  accountId: oneOfType([string, number]),
  catalogs: arrayOf(ProjectCatalogShape).isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
  router: routerShape.isRequired,
  location: shape({
    pathname: string
  }).isRequired
};

ProductLayout.defaultProps = {
  accountId: undefined
};

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = state => {
  const pageContent = get(state, "pageContent", {});
  const accountData = getAccountData(state);
  const catalogs = get(pageContent, "project_catalogs", []);

  return {
    i18n: state.i18n,
    accountId: accountData?.account.id,
    catalogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resources: {
      catalogs: new CatalogResource(dispatch),
      productGroups: catalogId => new ProductGroupResource(catalogId, dispatch),
      productCatalogs: new ProductCatalogsResource(dispatch)
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductLayout)
);
