import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { getProjectsSorted, getAccount } from "shared/selectors";
import { getProjectFiles } from "shared/selectors/projectFiles";
import { ProjectFilesResource } from "builder_portal/actions/projectFilesActions";
import moment from "moment";
import copyToClipboard from "copy-to-clipboard";
import StickyMenu from "shared/components/menus/StickyMenu";
import { keyBy } from "lodash";
import {
  Form,
  Modal,
  Button,
  Header,
  Message,
  Table,
  Grid,
  Segment
} from "semantic-ui-react";
import localStorage from "shared/helpers/localStorage";
import { node, func, number } from "prop-types";
import FileUploader from "builder_portal/components/dropzone/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { ProjectShape } from "shared/shapes";

const EMPTY_MODEL = { file: null };

const ProjectFiles = () => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjectsSorted);
  const [project, setProject] = useState(null);
  const account = useSelector(getAccount);
  const projectFiles = useSelector(getProjectFiles);

  useEffect(() => {
    new ProjectsResource(dispatch).fetchAll();
  }, []);

  useEffect(() => {
    if (project) new ProjectFilesResource(dispatch, project.id).fetchAll();
  }, [project]);

  useEffect(() => {
    if (projects?.length && !project) setProject(projects[0]);
  }, [projects]);

  const projectChangeHandler = item => {
    setProject(item);
  };

  const onCompleted = () => {
    if (project) new ProjectFilesResource(dispatch, project.id).fetchAll();
  };

  const handleClipboardCopy = (e, url) => {
    e.preventDefault();
    copyToClipboard(url);
  };

  return (
    <>
      <StickyMenu
        stickyItem={null}
        allItems={keyBy(projects, "id")}
        accountId={account?.data?.account?.id}
        onSelectedItemChange={projectChangeHandler}
        storeKey="projectFiles"
      />
      <Segment.Group data-component="project-files">
        <Segment attached="top">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width={8}>
              <Header as="h4">
                <FormattedMessage
                  id="menu.main.projectFiles"
                  default="Project Files"
                />
              </Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <UploadModal
                button={
                  <Button size="small">
                    <FormattedMessage
                      id="projectFiles.uploadButton.label"
                      default="Upload file"
                    />
                  </Button>
                }
                onCompleted={onCompleted}
                project={project}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <If condition={projectFiles.length === 0}>
          <Segment attached>
            <Header as="h4">
              <FormattedMessage id="projectFiles.empty" deafult="Empty list" />
            </Header>
          </Segment>
        </If>
        <Table striped attached>
          <Table.Body>
            {projectFiles.map(file => (
              <Table.Row key={file.id}>
                <Table.Cell>
                  <a
                    href={file.file_url}
                    target="_blank"
                    style={{ fontWeight: 700 }}
                    rel="noreferrer"
                  >
                    {file.file_file_name}
                  </a>
                </Table.Cell>
                <Table.Cell>{file.file_content_type}</Table.Cell>
                <Table.Cell>{moment(file.updated_at).format("LLL")}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    basic
                    icon="clipboard outline"
                    onClick={e => handleClipboardCopy(e, file.file_url)}
                  />
                  <UploadModal
                    button={<Button basic icon="cog" />}
                    onCompleted={onCompleted}
                    project={project}
                    id={file.id}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment.Group>
    </>
  );
};

export default ProjectFiles;

const UploadModal = ({ button, onCompleted, project, id }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [model, setModel] = useState(EMPTY_MODEL);

  const handleOpen = () => {
    setError("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModel(EMPTY_MODEL);
  };

  const handleFile = file => {
    setModel(currentModel => ({
      ...currentModel,
      file
    }));
    setError("");
  };

  const handleUpload = () => {
    const formData = new FormData();
    const { file } = model;

    if (!file) {
      setError(
        intl.formatMessage({
          id: "buyers.upload.select_file"
        })
      );
      return;
    }
    setError("");

    formData.append(`project_file[file]`, file);
    const method = !id ? "POST" : "PATCH";
    const url = !id
      ? `/api/v1/projects/${project.slug}/project_files`
      : `/api/v1/projects/${project.slug}/project_files/${id}`;
    fetch(url, {
      method,
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(() => {
        handleClose();
        onCompleted();
      })
      .catch(() => {
        setError(
          intl.formatMessage({
            id: "buyers.upload.upload_error"
          })
        );
      });
  };

  return (
    <>
      <Modal
        closeIcon
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        trigger={button}
      >
        <Modal.Header>
          <Header as="h3">
            <FormattedMessage id="projectFiles.dialog.title" />
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Message error content={error} hidden={!error} />
          <Form>
            <If condition={!model.file}>
              <FileUploader handleFile={handleFile} />
            </If>
            <If condition={!!model.file}>
              <Header as="h5">{model.file?.path}</Header>
            </If>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            id="close"
            content={intl.formatMessage({ id: "meta.actions.close" })}
            onClick={handleClose}
          />
          <Button
            primary
            id="submit"
            content={intl.formatMessage({
              id: "projectFiles.dialog.submitButton"
            })}
            onClick={handleUpload}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

UploadModal.propTypes = {
  button: node.isRequired,
  onCompleted: func,
  project: ProjectShape.isRequired,
  id: number
};

UploadModal.defaultProps = {
  onCompleted: () => {},
  id: undefined
};
