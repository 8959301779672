import React from "react";
import Field from "shared/components/forms/FieldComponent";
import { Button } from "semantic-ui-react";
import { arrayOf, string, shape, func, object } from "prop-types";

import "./inputWithActions.scss";

const InputWithActions = ({ component, field, actions }) => {
  return (
    <div data-component="inputWithActionsComponent">
      <div className="ia-container">
        <Field component={component} {...field} className="flex1" />
        {actions.map(action => (
          <Button
            key={action.name}
            basic
            color={action.color}
            className="button"
            onClick={e => {
              e.preventDefault();
              action.handler(action.name);
            }}
            tabIndex={0}
          >
            {action.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

InputWithActions.propTypes = {
  component: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  field: object.isRequired,
  actions: arrayOf(
    shape({
      label: string,
      name: string,
      handler: func
    })
  ).isRequired
};

export default InputWithActions;
