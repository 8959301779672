import PropTypes from "prop-types";
import React from "react";
import { Icon, Label } from "semantic-ui-react";
import "./contactLabel.scss";

class ContactLabel extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    contact: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  getContactIcon(contact) {
    if (!contact.type) {
      return "user secret";
    }
    if (contact.type === "Contractor") {
      return "shipping";
    }
    if (contact.type === "Buyer") {
      return "child";
    }
    if (contact.type === "Project") {
      return "building";
    }
    return "user";
  }

  getContactColor(contact) {
    if (contact.type === "Contractor") {
      return "blue";
    }
    if (contact.type === "Buyer") {
      return "teal";
    }
    if (contact.type === "Project") {
      return "pink";
    }
    return "grey";
  }

  render() {
    const { contact } = this.props;
    return (
      <Label
        className="ui outline"
        data-component="contactLabel"
        color={this.getContactColor(contact)}
        size="small"
      >
        <Icon name={this.getContactIcon(contact)} /> {contact.label.contact}{" "}
        {contact.label.company ? `(${contact.label.company})` : ""}
      </Label>
    );
  }
}

export default ContactLabel;
