import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Icon } from "semantic-ui-react";
import { getAccount, getProject } from "shared/selectors";

const EditUnitButton = () => {
  const { formatMessage } = useIntl();
  const account = useSelector(getAccount);
  const project = useSelector(getProject);

  const isDisabled = !account.hasProjectRoleRight(
    project?.id,
    "allow_access_edit_units"
  );

  const rest = {};
  if (isDisabled) {
    // Modals trigger button click opens dialog even when the button element is disabled!
    rest.style = { opacity: 0.7 };
    rest.onClick = e => e.stopPropagation();
  } else {
    rest["data-tooltip"] = formatMessage({ id: "unit.actions.edit" });
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button basic icon className="mobileBehaviour editUnit" {...rest}>
      <Icon color="grey" name="setting" />
      <span>
        <FormattedMessage
          id="unit.actions.edit"
          defaultMessage="unit.actions.edit"
        />
      </span>
    </Button>
  );
};

export default EditUnitButton;
