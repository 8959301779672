import { Dropdown } from "semantic-ui-react";
import React from "react";
import "./productDropdown.scss";

export function ProductDropdown(props) {
  const { options } = props;
  return (
    // Higher Order Components depend on prop spreading
    /* eslint-disable react/jsx-props-no-spreading */
    <Dropdown
      {...props}
      data-component="productDropdown"
      scrolling={options.length > 6}
      closeOnChange={false}
      options={options.map(o => {
        // very special hack if an Item was re-clicked, it should toggle confirmation (handled in onChange handler)
        const onClick = (event, data) => {
          try {
            props.onChange(event, data);
          } catch (e) {
            // this is mostly not a critical error, due unexpected event. Don't crash the whole app
            // eslint-disable-next-line no-console
            console.error(e);
          }
        };
        // only overwrite undefined onClick handlers
        return { ...o, onClick: o.onClick ?? onClick };
      })}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

ProductDropdown.propTypes = {
  ...Dropdown.propTypes
};

ProductDropdown.defaultProps = {
  ...Dropdown.defaultProps,
  icon: "dropdown",
  name: "product_id",
  className: "icon",
  labeled: true,
  basic: true,
  button: true,
  compact: true,
  fluid: true,
  selectOnBlur: false,
  options: []
};

export default ProductDropdown;
