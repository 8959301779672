import React, { useMemo, useCallback } from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import { func, array } from "prop-types";

const INDENTATION = 20;

const SelectableTree = ({ data, onChange }) => {
  const icon = useMemo(() => <Icon name="book" />, []);
  const checkbox = useCallback(
    (id, value) => (
      <Checkbox
        checked={value}
        onClick={(_, { checked }) => onChange(id, checked)}
      />
    ),
    [onChange]
  );

  const renderChildren = (item, i, drawLines) => {
    if (!item.derived.length) return null;
    return (
      <div
        style={{
          borderLeft: `${drawLines ? "black solid 1px" : ""}`,
          marginLeft: "8px"
        }}
      >
        {item.derived.map(d => (
          <div key={d.id} style={{ marginLeft: `${i * INDENTATION}px` }}>
            {checkbox(d.id, d.checked)}
            {icon}
            {d.name}
            {renderChildren(d, i + 1, drawLines)}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div>
      {data.map((d, ii) => {
        return (
          <div key={d.id}>
            {checkbox(d.id, d.checked)}
            {icon}
            {d.name}
            {renderChildren(d, 1, ii < data.length - 1)}
          </div>
        );
      })}
    </div>
  );
};

SelectableTree.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: array.isRequired,
  onChange: func.isRequired
};

export default SelectableTree;
