import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  Icon,
  Grid,
  Button,
  Divider,
  Message
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { cloneDeep, keyBy } from "lodash";
import { string, func, object, number } from "prop-types";
import { useLocalStorage } from "beautiful-react-hooks";
import { FormattedMessage, useIntl } from "react-intl";
import TableCellDialog from "./tableContentPartials/TableCellDialog";
import { getDocumentExportsMetadata } from "../../../selectors/documentExports";

import "./documentExport.scss";

const byKey = metadata => {
  const temp = { validations: {}, conditions: {}, styles: {} };
  temp.validations.line_item = keyBy(metadata.validations.line_item, "name");
  temp.validations.sub_line_item = keyBy(
    metadata.validations.sub_line_item,
    "name"
  );
  temp.conditions.line_item = keyBy(metadata.conditions.line_item, "name");
  temp.conditions.sub_line_item = keyBy(
    metadata.conditions.sub_line_item,
    "name"
  );
  temp.styles.line_item = keyBy(metadata.styles.line_item, "name");
  temp.styles.sub_line_item = keyBy(metadata.styles.sub_line_item, "name");
  return temp;
};

const TableEditor = ({ value, onChange }) => {
  const intl = useIntl();
  const metadata = useSelector(getDocumentExportsMetadata);
  const metadataByKey = useMemo(() => byKey(metadata), [metadata]);
  const [tableConfig, setTableConfig] = useState(value);
  const [activeIndex, setActiveIndex] = useLocalStorage(
    "DocumentExportTableContentIndex",
    0
  );

  const TABLE_PARTS = useMemo(
    () => ({
      line_item_header: {
        title: intl.formatMessage({
          id: "documentsExport.dialogs.exports.content.lineItemHeader"
        })
      },
      line_item_body: {
        title: intl.formatMessage({
          id: "documentsExport.dialogs.exports.content.lineItemBody"
        })
      },
      // sub_line_item_header: {
      //   title: intl.formatMessage({
      //     id: "documentsExport.dialogs.exports.content.subLineItemHeader"
      //   })
      // },
      sub_line_item_body: {
        title: intl.formatMessage({
          id: "documentsExport.dialogs.exports.content.subLineItemBody"
        })
      }
    }),
    []
  );

  useEffect(() => {
    setTableConfig(value);
  }, [value]);

  const updateRow = ({ rowType, data, elIndex, tableType, rowIndex }) => {
    const temp = tableConfig ? cloneDeep(tableConfig) : {};
    if (!temp) {
      Object.keys(TABLE_PARTS).forEach(key => {
        temp[key] = [];
      });
    }
    if (!temp[rowType]) temp[rowType] = [];

    if (tableType === "header") {
      temp[rowType][elIndex] = data;
    } else {
      if (!temp[rowType][rowIndex]) temp[rowType][rowIndex] = [];
      temp[rowType][rowIndex][elIndex] = data;
    }

    setTableConfig(temp);
    onChange(temp);
  };

  const moveCell = (rowType, rowIndex, elIndex, direction) => {
    if (elIndex === 0 && direction === -1) return;

    const temp = tableConfig ? cloneDeep(tableConfig) : {};
    let tempLevel = temp[rowType];
    // header level is array, not array in array as for body
    const notHeaderLevel = Array.isArray(tempLevel[rowIndex]);
    if (notHeaderLevel) tempLevel = tempLevel[rowIndex];

    if (elIndex === tempLevel.length - 1 && direction === 1) return;
    if (!Array.isArray(tempLevel)) return;

    const element1 = tempLevel[elIndex];
    const element2 = tempLevel[elIndex + direction];
    const newLevel = tempLevel.map((element, index) => {
      if (elIndex === index) return element2;
      if (elIndex + direction === index) return element1;
      return element;
    });
    if (notHeaderLevel) temp[rowType][rowIndex] = newLevel;
    if (!notHeaderLevel) temp[rowType] = newLevel;

    setTableConfig(temp);
    onChange(temp);
  };

  const deleteCell = ({ tableType, rowType, elIndex, rowIndex }) => {
    const temp = cloneDeep(tableConfig);

    if (tableType === "header") {
      temp[rowType].splice(elIndex, 1);
    } else {
      temp[rowType][rowIndex].splice(elIndex, 1);
    }

    setTableConfig(temp);
    onChange(temp);
  };

  const deleteRow = ({ rowType, rowIndex }) => {
    const temp = cloneDeep(tableConfig);
    temp[rowType].splice(rowIndex, 1);
    setTableConfig(temp);
    onChange(temp);
  };

  const addRow = ({ rowType, rowIndex }) => {
    const temp = cloneDeep(tableConfig);
    if (!temp[rowType]) temp[rowType] = [];
    temp[rowType][rowIndex] = [];
    setTableConfig(temp);
    onChange(temp);
  };

  const renderAddButtons = (tableType, key, rows) => {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign="right">
            <If condition={tableType === "header"}>
              <TableCellDialog
                rowType={key}
                elIndex={rows[0]?.length}
                tableType={tableType}
                rowIndex={0}
                updateRow={updateRow}
                metadata={metadata}
                model={{ title: "", width: 1 }}
                button={
                  <Button basic onClick={e => e.preventDefault()}>
                    <Icon name="plus" />
                    <FormattedMessage id="documentsExport.dialogs.exports.content.addCell" />
                  </Button>
                }
              />
            </If>
            <If condition={tableType === "body"}>
              <Button
                basic
                onClick={e => {
                  e.preventDefault();
                  addRow({ rowType: key, rowIndex: rows.length });
                }}
              >
                <Icon name="plus" />
                <FormattedMessage id="documentsExport.dialogs.exports.content.addRow" />
              </Button>
            </If>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <>
      {Object.keys(TABLE_PARTS).map((key, index) => {
        const tableType = key.indexOf("body") > -1 ? "body" : "header";
        const items = tableConfig[key] || []; // items can be cells for headers and row for bodies
        const rows = tableType === "body" ? items : [items];

        return (
          <Accordion key={key} data-component="TableEditor">
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}
            >
              <Icon name="dropdown" />
              {TABLE_PARTS[key]?.title}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              {rows.map((row, rowIndex) => {
                const level =
                  key.indexOf("sub_line_item") === 0
                    ? "sub_line_item"
                    : "line_item";
                const deprecated = row.filter(
                  cell =>
                    metadataByKey.validations[level][cell.field]?.deprecated
                ).length;

                const list = row.map((element, elIndex) => {
                  const unique = `${element.title || element.field}${elIndex}`;
                  return (
                    <Row
                      rowType={key}
                      tableType={tableType}
                      element={element}
                      key={unique}
                      updateRow={updateRow}
                      deleteCell={deleteCell}
                      elIndex={elIndex}
                      rowIndex={rowIndex}
                      metadataByKey={metadataByKey}
                      moveCell={moveCell}
                    />
                  );
                });
                const unique = `${key}${index}${rowIndex}`;

                return (
                  <Grid style={{ padding: "0 21px" }} key={unique}>
                    {list}
                    <If condition={list.length === 0}>
                      <div style={{ paddingLeft: "30px" }}>
                        <FormattedMessage id="documentsExport.dialogs.exports.content.noCells" />
                      </div>
                    </If>
                    <If condition={tableType === "body"}>
                      <Grid.Row>
                        <Grid.Column width={16} textAlign="right">
                          <TableCellDialog
                            rowType={key}
                            elIndex={row.length}
                            tableType={tableType}
                            rowIndex={rowIndex}
                            updateRow={updateRow}
                            metadata={metadata}
                            model={{ field: "", width: 1 }}
                            button={
                              <Button basic onClick={e => e.preventDefault()}>
                                <Icon name="plus" />
                                <FormattedMessage id="documentsExport.dialogs.exports.content.addCell" />
                              </Button>
                            }
                          />

                          <Button
                            basic
                            onClick={e => {
                              e.preventDefault();
                              deleteRow({
                                rowType: key,
                                rowIndex
                              });
                            }}
                          >
                            <Icon name="trash" />
                            <FormattedMessage id="documentsExport.dialogs.exports.content.deleteRow" />
                          </Button>
                          <Divider style={{ padding: "0" }} />
                        </Grid.Column>
                      </Grid.Row>
                    </If>

                    <Message
                      negative
                      hidden={!deprecated}
                      style={{ width: "100%", marginTop: 0 }}
                    >
                      <Message.Header>
                        <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.deprecated.title" />
                      </Message.Header>
                      <p>
                        <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.deprecated.message" />
                      </p>
                    </Message>
                  </Grid>
                );
              })}
              {renderAddButtons(tableType, key, rows)}
            </Accordion.Content>
          </Accordion>
        );
      })}
    </>
  );
};

TableEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: object.isRequired,
  onChange: func.isRequired
};

export default TableEditor;

const Row = ({
  element,
  updateRow,
  rowType,
  tableType,
  deleteCell,
  elIndex,
  rowIndex,
  metadataByKey,
  moveCell
}) => {
  const metadata = useSelector(getDocumentExportsMetadata);
  const { title, field, width, style } = element;
  const level =
    rowType.indexOf("sub_line_item") === 0 ? "sub_line_item" : "line_item";

  const checkArray = val => {
    if (typeof val === "string") {
      return [val];
    }
    return val;
  };

  return (
    <Grid.Row className="element-border" verticalAlign="middle">
      <Grid.Column width={1}>
        <Icon name="th large" color="grey" />
      </Grid.Column>
      <Grid.Column width={5}>
        <div>
          {title || metadataByKey.validations[level][field]?.title || field}
        </div>
      </Grid.Column>
      <Grid.Column width={1}>
        <div>{width}</div>
      </Grid.Column>
      <Grid.Column width={4}>
        <If condition={!!element.if}>
          <strong>
            <FormattedMessage id="documentsExport.dialogs.exports.content.if" />
          </strong>
          {checkArray(element.if)?.map(el => (
            <div key={`${el}-${elIndex}`}>{`${metadataByKey.conditions[level][
              el
            ]?.title || el}`}</div>
          ))}
        </If>
        <If condition={!!element.unless}>
          <strong>
            <FormattedMessage id="documentsExport.dialogs.exports.content.unless" />
          </strong>
          {checkArray(element.unless)?.map(el => (
            <div key={`${el}-${elIndex}`}>{`${metadataByKey.conditions[level][
              el
            ]?.title || el}`}</div>
          ))}
        </If>
      </Grid.Column>
      <Grid.Column width={3}>
        <div>{metadataByKey.styles[level][style]?.title || style}</div>
      </Grid.Column>
      <Grid.Column width={2} textAlign="right">
        <TableCellDialog
          rowType={rowType}
          elIndex={elIndex}
          tableType={tableType}
          rowIndex={rowIndex}
          updateRow={updateRow}
          metadata={metadata}
          model={element}
          button={
            <Icon
              color="grey"
              name="edit"
              onClick={e => {
                e.preventDefault();
              }}
            />
          }
        />

        <Icon
          color="grey"
          name="arrow up"
          onClick={e => {
            e.preventDefault();
            moveCell(rowType, rowIndex, elIndex, -1);
          }}
        />

        <Icon
          color="grey"
          name="arrow down"
          onClick={e => {
            e.preventDefault();
            moveCell(rowType, rowIndex, elIndex, 1);
          }}
        />

        <Icon
          color="grey"
          name="trash"
          onClick={e => {
            e.preventDefault();
            deleteCell({ tableType, rowType, elIndex, rowIndex });
          }}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

Row.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  element: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadataByKey: object.isRequired,
  rowType: string.isRequired,
  updateRow: func.isRequired,
  deleteCell: func.isRequired,
  elIndex: number,
  rowIndex: number.isRequired,
  tableType: string.isRequired,
  moveCell: func.isRequired
};

Row.defaultProps = {
  elIndex: 0
};
