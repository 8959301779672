import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getProjectSetupParameters = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "project_setup_parameters", []);
  }
);

export default getProjectSetupParameters;
