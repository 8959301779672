import localStorage from "shared/helpers/localStorage";
import Resources from "../../shared/actions/resourcesActions";

export class ProjectSetupParametersResource extends Resources {
  constructor(dispatch, projectSetupId) {
    super(
      dispatch,
      "project_setup_parameter",
      `/api/v1/project_setups/${projectSetupId}/project_setup_parameters`
    );
  }

  saveParameter = param => {
    const paramId = param.new ? undefined : param.id;
    const paramUrl = paramId ? `/${paramId}` : "";

    const formData = new FormData();

    formData.append(`project_setup_parameter[note]`, param.note);
    if (param.value)
      formData.append(`project_setup_parameter[value]`, param.value);
    formData.append(
      `project_setup_parameter[completes_topic]`,
      !!param.completes_topic
    );
    formData.append(
      `project_setup_parameter[project_analysis_topic_id]`,
      param.project_analysis_topic_id
    );
    param.project_setup_parameter_attachments_attributes?.forEach(
      (x, index) => {
        if (x.file) {
          formData.append(
            `project_setup_parameter[project_setup_parameter_attachments_attributes][${index}]file`,
            x.file
          );
        }

        if (x.destroy && x.id) {
          formData.append(
            `project_setup_parameter[project_setup_parameter_attachments_attributes][${index}]id`,
            x.id
          );
          formData.append(
            `project_setup_parameter[project_setup_parameter_attachments_attributes][${index}]_destroy`,
            true
          );
        }
      }
    );

    return fetch(`${this.url}${paramUrl}`, {
      method: paramId ? "PUT" : "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    });
  };
}

export default ProjectSetupParametersResource;
