import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import { Input, Form } from "formik-semantic-ui-react";
import { MessageLabelsResource } from "builder_portal/actions/messageLabelsActions";
import MessagesResource from "builder_portal/actions/messagesActions";
import { getSearchParams } from "builder_portal/selectors";
import SemanticFormikMessageLabelsDropdown from "shared/components/forms/SemanticFormikMessageLabelsDropdown";
import * as Yup from "yup";
import { Modal, Button, Container } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { node, string, shape } from "prop-types";

const MAX_THREADS = 20;

const MessageLabelDialog = ({ model, button }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const searchParams = useSelector(getSearchParams);
  const refResetForm = useRef();

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const validationScheme = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    color: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = useCallback((values, formik) => {
    new MessageLabelsResource(dispatch)
      .save(values)
      .then(() => {
        setOpen(false);
        new Growl(dispatch).success(
          "message.success.title",
          "email.message_labels.add.success"
        );
        new MessageLabelsResource(dispatch).fetchAll();
        formik.resetForm();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
        formik.setSubmitting(false);
      });
  }, []);

  const onDelete = useCallback(() => {
    new MessageLabelsResource(dispatch)
      .remove(model.id)
      .then(() => {
        setOpen(false);
        new Growl(dispatch).success(
          "message.success.title",
          "email.message_labels.delete.success"
        );
        new MessageLabelsResource(dispatch).fetchAll();
        new MessagesResource(dispatch).fetchAll({
          ...searchParams,
          limit: MAX_THREADS,
          t: new Date().getTime()
        });
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  }, []);

  const renderDeleteButton = isSubmitting => {
    const deleteButton = (
      <Button
        id="delete"
        color="red"
        basic
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="email.message_labels.remove_confirmation.title"
        message="email.message_labels.remove_confirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  return (
    <Formik
      initialValues={model || { name: "", color: "" }}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, values, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Modal.Header>
              <FormattedMessage
                id={`email.message_labels.${model ? "edit" : "add"}.title`}
              />
            </Modal.Header>
            <Modal.Content>
              <Form id="messageLabelsForm">
                <Container>
                  <Input
                    name="name"
                    label={intl.formatMessage({
                      id: "email.message_labels.name"
                    })}
                    errorPrompt
                  />
                  <Field
                    name="color"
                    label={intl.formatMessage({
                      id: "email.message_labels.color"
                    })}
                    value={values.color}
                    component={SemanticFormikMessageLabelsDropdown}
                  />
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button
                    color="grey"
                    loading={isSubmitting}
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                  />
                  <Button
                    id="submit"
                    positive
                    loading={isSubmitting}
                    content={
                      model
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    onClick={submitForm}
                  />
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

MessageLabelDialog.propTypes = {
  button: node.isRequired,
  model: shape({ name: string, color: string })
};

MessageLabelDialog.defaultProps = {
  model: undefined
};

export default MessageLabelDialog;
