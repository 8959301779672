import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Header, Segment, Button, Loader, Icon, Grid } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import MembershipReplacementResource from "builder_portal/actions/membershipReplacementActions";
import { getMembershipReplacements } from "shared/selectors/membershipReplacements";
import ProfileResource from "builder_portal/actions/profileActions";
import Growl from "builder_portal/actions/growlActions";
import moment from "moment";
import MembershipReplacementDialog from "./MembershipReplacementDialog";

const MembershipReplacement = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const membershipReplacements = useSelector(getMembershipReplacements);

  useEffect(() => {
    setLoading(true);
    new MembershipReplacementResource(dispatch)
      .fetchAll()
      .then(() => setLoading(false))
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
        setLoading(false);
      });
    new ProfileResource(dispatch).projectsMembership();
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      <Segment attached="top">
        <Header as="h4">
          <FormattedMessage id="project.attributes.membership.title" />
        </Header>
      </Segment>
      <Segment attached>
        <If condition={isLoading}>
          <Segment>
            <Loader active inline="centered" />
          </Segment>
        </If>
        <If condition={membershipReplacements.length === 0}>
          <Segment>
            <FormattedMessage
              id="project.attributes.membership.empty.label"
              defaultMessage="Keine Project membership replacement gefunden"
            />
          </Segment>
        </If>
        {membershipReplacements.map(mr => (
          <Segment key={mr.id} clearing data-model="contact">
            <Grid>
              <Grid.Column width={9}>
                <div>
                  <span style={{ fontWeight: 700, paddingRight: "10px" }}>
                    {mr.replacement_user?.first_name || "k.a"}{" "}
                    {mr.replacement_user?.last_name || "k.a"}
                  </span>
                  <span>{mr.project_name}</span>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                {moment(mr.active_from).format("DD.MM.YYYY")} -{" "}
                {moment(mr.active_to).format("DD.MM.YYYY")}
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                <MembershipReplacementDialog
                  originalModel={mr}
                  trigger={
                    <Icon color="grey" name="setting" className="pointer" />
                  }
                />
              </Grid.Column>
            </Grid>
          </Segment>
        ))}
        <MembershipReplacementDialog
          trigger={
            <Button
              icon="plus"
              basic
              content={intl.formatMessage({
                id: "project.attributes.membership.add.label"
              })}
              fluid
            />
          }
        />
      </Segment>
    </div>
  );
};

export default MembershipReplacement;
