/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React, { useState, useCallback } from "react";
import { Container, List, Button, Icon } from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import { ConfiguratorDesignShape } from "shared/shapes/configuratorDesign.shape";
import { If } from "shared/components/elements/Conditions";
import AddNewElement from "./AddNewElementDialog";
import CardElement from "./CardElement";
import RenderListItem from "./RenderListItem";
import TemplatesLoader from "./TemplatesLoader";

const RenderList = ({
  value,
  setFieldValue,
  fieldName,
  configuratorDesign,
  pageEditor,
  handlePageItemsUpdate
}) => {
  const [jsonConfig, setJsonConfig] = useState(value);

  React.useEffect(() => {
    setJsonConfig(value);
  }, [value]);

  // based on pageEditor call handlePageItemsUpdate or setFieldValue
  const handleUpdate = useCallback(
    valueOfField => {
      if (pageEditor) {
        handlePageItemsUpdate(valueOfField);
      } else {
        setFieldValue(fieldName, valueOfField);
      }
    },
    [pageEditor, handlePageItemsUpdate, setFieldValue]
  );

  const setTemplate = val => {
    if (val.template) setFieldValue(fieldName, val.template);
  };

  const deleteItem = index => {
    const newJsonConfig = [...jsonConfig];
    newJsonConfig.splice(index, 1);
    handleUpdate(newJsonConfig);
  };

  const deleteCardItem = (index, cardId) => {
    const newJsonConfig = [...jsonConfig];
    const cardElement = newJsonConfig.find(item => item.id === cardId);
    cardElement.items.splice(index, 1);
    handleUpdate(newJsonConfig);
  };

  const moveItem = (index, direction) => {
    const newJsonConfig = [...jsonConfig];
    if (direction === "up" && index > 0) {
      const item = newJsonConfig[index];
      newJsonConfig[index] = newJsonConfig[index - 1];
      newJsonConfig[index - 1] = item;
      handleUpdate(newJsonConfig);
    } else if (direction === "down" && index < newJsonConfig.length - 1) {
      const item = newJsonConfig[index];
      newJsonConfig[index] = newJsonConfig[index + 1];
      newJsonConfig[index + 1] = item;
      handleUpdate(newJsonConfig);
    }
  };

  const moveCardItem = (index, direction, cardId) => {
    const newJsonConfig = [...jsonConfig];
    const cardElement = newJsonConfig.find(item => item.id === cardId);
    if (direction === "up" && index > 0) {
      const item = cardElement.items[index];
      cardElement.items[index] = cardElement.items[index - 1];
      cardElement.items[index - 1] = item;
      handleUpdate(newJsonConfig);
    } else if (direction === "down" && index < cardElement.items.length - 1) {
      const item = cardElement.items[index];
      cardElement.items[index] = cardElement.items[index + 1];
      cardElement.items[index + 1] = item;
      handleUpdate(newJsonConfig);
    }
  };

  const handleChange = (index, valueOfField) => {
    const newJsonConfig = [...jsonConfig];
    newJsonConfig[index] = valueOfField;
    handleUpdate(newJsonConfig);
  };

  const handleCardCssChange = (id, valueOfField) => {
    const updatedJsonConfig = jsonConfig.map(item => {
      if (item.id === id) {
        return { ...item, css: valueOfField };
      }
      return item;
    });
    handleUpdate(updatedJsonConfig);
  };

  const handleCartItemChange = (index, valueOfField, cardId) => {
    const newJsonConfig = [...jsonConfig];
    const cardElement = newJsonConfig.find(item => item.id === cardId);
    cardElement.items[index] = valueOfField;
    handleUpdate(newJsonConfig);
  };

  const addNewElement = element => {
    const newJsonConfig = jsonConfig ? [...jsonConfig] : [];
    newJsonConfig.push({ ...element, id: uuid() });
    handleUpdate(newJsonConfig);
  };

  const addNewCardElement = (cardId, element) => {
    const newJsonConfig = [...jsonConfig];
    const cardElement = newJsonConfig.find(item => item.id === cardId);
    cardElement?.items.push({ ...element, id: uuid() });
    handleUpdate(newJsonConfig);
  };

  const renderItem = useCallback(
    (item, index) => {
      switch (item.type) {
        case "ContentRow":
          return (
            <CardElement
              item={item}
              key={item.id}
              deleteItem={deleteItem}
              deleteCardItem={deleteCardItem}
              moveItem={moveItem}
              moveCardItem={moveCardItem}
              index={index}
              jsonConfigLength={jsonConfig.length}
              fieldName={fieldName}
              handleChange={handleChange}
              handleCartItemChange={handleCartItemChange}
              addNewCardElement={addNewCardElement}
              configuratorDesign={configuratorDesign}
              handleCardCssChange={handleCardCssChange}
            />
          );
        default:
          return (
            <RenderListItem
              item={item}
              key={item.id}
              deleteItem={deleteItem}
              moveItem={moveItem}
              index={index}
              jsonConfigLength={jsonConfig.length}
              fieldName={fieldName}
              handleChange={handleChange}
              configuratorDesign={configuratorDesign}
            />
          );
      }
    },
    [jsonConfig]
  );

  return (
    <Container>
      <List divided verticalAlign="middle" size="large">
        {jsonConfig &&
          jsonConfig?.map((item, index) => renderItem(item, index))}
      </List>
      <AddNewElement
        onAddElement={addNewElement}
        trigger={
          <Button style={{ margin: "20px 0" }} fluid type="button">
            <Icon link name="plus" /> Add Element
          </Button>
        }
      />
      <If condition={!jsonConfig?.length}>
        <TemplatesLoader setTemplate={setTemplate} />
      </If>
    </Container>
  );
};

RenderList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  setFieldValue: PropTypes.func,
  fieldName: PropTypes.string,
  configuratorDesign: ConfiguratorDesignShape.isRequired,
  pageEditor: PropTypes.bool,
  handlePageItemsUpdate: PropTypes.func
};

RenderList.defaultProps = {
  value: null,
  pageEditor: false,
  handlePageItemsUpdate: () => {},
  setFieldValue: () => {},
  fieldName: ""
};

export default RenderList;
