import Resources from "shared/actions/resourcesActions";

export default class ConfiguratorBackendsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "configurator_backend",
      `/api/v1/projects/${projectId}/configurator_backends`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
