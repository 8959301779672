import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

export default class AttachmentFeatureToggle extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    attachment: PropTypes.object,
    onUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    this.toggleFeatureFlag = this.toggleFeatureFlag.bind(this);
  }

  render() {
    const { i18n, attachment } = this.props;

    if (this.state.loading) {
      return <Icon name="circle notched" loading size="large" />;
    }
    const icon = attachment.featured ? "star" : "outline star";
    const color = attachment.featured ? "olive" : "black";
    const title =
      i18n[`attachment.hints.${attachment.featured ? "unfeature" : "feature"}`];

    return (
      <Icon
        link
        name={icon}
        color={color}
        onClick={this.toggleFeatureFlag}
        size="large"
        title={title}
      />
    );
  }

  toggleFeatureFlag() {
    const { attachment, onUpdate } = this.props;
    this.setState({ loading: true });
    onUpdate({ id: attachment.id, featured: !attachment.featured }).then(() => {
      this.setState({ loading: false });
    });
  }
}
