import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Header, Label, Menu } from "semantic-ui-react";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { If } from "shared/components/elements/Conditions";
import "./projectTabMenu.scss";
import { bool, string, arrayOf, object } from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  getAccount,
  getProjectStatistics,
  getFeatureToggles
} from "shared/selectors";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { AccountShape, I18nShape } from "../../../shared/shapes";

class TabMenu extends React.Component {
  subTabMatch = val => {
    const { account, subTab } = this.props;
    if (!account.isEnabled("show_page_layout_v2")) return false;
    if (subTab === val) return true;
    return false;
  };

  render() {
    const {
      account,
      activeTab,
      i18n,
      rootUrl,
      searchEnabled,
      statistics,
      visibleTabs,
      showProjectSetup,
      projectName,
      subTab
    } = this.props;

    const directionProps = account.isEnabled("show_page_layout_v2")
      ? { vertical: true, secondary: true }
      : { stackable: true, tabular: true, attached: "top" };

    const itemClass = account.isEnabled("show_page_layout_v2")
      ? "projectTabMenuItem"
      : "";

    const labelStyle = account.isEnabled("show_page_layout_v2")
      ? {
          position: "absolute",
          right: 0
        }
      : {};

    const menuStyle = account.isEnabled("show_page_layout_v2")
      ? {
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "1rem",
          border: "1px solid #d4d4d5"
        }
      : {};

    return (
      <div style={menuStyle}>
        <If condition={account.isEnabled("show_page_layout_v2")}>
          <Header size="large">{projectName}</Header>
        </If>
        <Menu
          {...directionProps}
          data-component="projectTabMenu"
          className="menuWidth"
        >
          <If condition={visibleTabs.indexOf("overview") > -1}>
            <Menu.Item
              id="project-overview-tab"
              name="overview"
              className={itemClass}
              content={
                <Link to={`${rootUrl}/overview`}>
                  <FormattedMessage
                    id="project.tabs.overview"
                    defaultMessage="Übersicht"
                  />
                </Link>
              }
              active={activeTab === "overview"}
            />
          </If>

          <If condition={visibleTabs.indexOf("project_setup") > -1}>
            <If condition={showProjectSetup}>
              <Menu.Item
                id="project-setup-tab"
                name="project_setup"
                className={itemClass}
                content={
                  <Link to={`${rootUrl}/project_setup`}>
                    <FormattedMessage
                      id="project.tabs.projectSetup"
                      defaultMessage="Projekt Wizard"
                    />
                  </Link>
                }
                active={activeTab === "project_setup"}
              />
            </If>
          </If>

          <FeatureToggleActive featureToggleName="mp_prospect_generation">
            <If condition={visibleTabs.indexOf("prospects") > -1}>
              <Menu.Item
                id="project-prospects-tab"
                name="prospects"
                className={itemClass}
                content={
                  <Link to={`${rootUrl}/prospects`}>
                    <FormattedMessage
                      id="roomBook.unitProspect.prospect"
                      defaultMessage="Interessenten"
                    />
                  </Link>
                }
                active={activeTab === "prospects"}
              />
            </If>
          </FeatureToggleActive>

          <If condition={visibleTabs.indexOf("sectionAndUnit") > -1} block>
            <Menu.Item
              id="project-units-tab"
              name="sectionAndUnit"
              className={itemClass}
              content={this.renderUnitsTabLabel(labelStyle)}
              active={activeTab === "sectionAndUnit"}
            />

            <If condition={account.isEnabled("show_page_layout_v2")} block>
              <div style={{ marginLeft: "10px", opacity: 0.8 }}>
                <Menu.Item
                  id="unit-setup-tab-1"
                  name="UnitVariables"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/unit_setup?p=UnitVariables`}>
                      <FormattedMessage
                        id="roomBook.unitVariables.tab"
                        defaultMessage="Mengen"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "unit_setup" &&
                    this.subTabMatch("UnitVariables")
                  }
                />

                <Menu.Item
                  id="unit-setup-tab-1"
                  name="UnitFeatures"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/unit_setup?p=UnitFeatures`}>
                      <FormattedMessage
                        id="unitSetup.tabs.unitFeatures"
                        defaultMessage="Merkmale"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "unit_setup" &&
                    this.subTabMatch("UnitFeatures")
                  }
                />
              </div>
            </If>
          </If>

          <If condition={visibleTabs.indexOf("activity") > -1}>
            <Menu.Item
              id="project-activities-tab"
              name="activity"
              className={itemClass}
              style={{ position: "relative" }}
              content={
                <Link to={`${rootUrl}/activity`}>
                  {statistics?.activities?.overdue > 0 && (
                    <Label
                      color="red"
                      size="mini"
                      circular
                      data-tooltip={i18n["activity.overdue"]}
                      key="overdue"
                      style={labelStyle}
                    >
                      {statistics.activities.overdue}
                    </Label>
                  )}
                  {statistics?.activities?.close > 0 && (
                    <Label
                      color="orange"
                      size="mini"
                      circular
                      data-tooltip={i18n["activity.close"]}
                      key="due"
                      style={labelStyle}
                    >
                      {statistics.activities.close}
                    </Label>
                  )}
                  <FormattedMessage
                    id="project.tabs.activity"
                    defaultMessage="Vorgänge"
                  />
                </Link>
              }
              active={activeTab === "activity"}
            />
          </If>

          <If condition={visibleTabs.indexOf("deadlines") > -1}>
            <Menu.Item
              id="project-deadlines-tab"
              name="deadlines"
              className={itemClass}
              content={this.renderDeadlineTabLabel(labelStyle)}
              active={activeTab === "deadlines"}
            />
          </If>

          <If condition={visibleTabs.indexOf("documents") > -1}>
            <FeatureToggle featureToggleName="project_documents">
              <Menu.Item
                id="project-documents"
                name="documents"
                className={itemClass}
                content={
                  <Link to={`${rootUrl}/documents`}>
                    {i18n["unit.tabs.attachments"]}
                  </Link>
                }
                active={activeTab === "documents"}
              />
            </FeatureToggle>
          </If>

          <If condition={visibleTabs.indexOf("buyers") > -1}>
            <Menu.Item
              id="project-buyers-tab"
              name="buyers"
              className={itemClass}
              content={
                <Link to={`${rootUrl}/buyers`}>
                  <FormattedMessage
                    id="menu.main.buyer"
                    defaultMessage="Erwerber"
                  />
                </Link>
              }
              active={activeTab === "buyers"}
            />
          </If>

          <If condition={visibleTabs.indexOf("contractors") > -1}>
            <Menu.Item
              id="project-contractors-tab"
              name="contractors"
              className={itemClass}
              content={
                <Link to={`${rootUrl}/contractors`}>
                  <FormattedMessage
                    id="project.tabs.contractors"
                    defaultMessage="Nachunternehmen"
                  />
                </Link>
              }
              active={activeTab === "contractors"}
            />
          </If>

          {this.renderStatisticsTab(itemClass)}

          <If condition={visibleTabs.indexOf("room_books") > -1} block>
            <Menu.Item
              id="project-roomBook-tab"
              name="roomBook"
              className={itemClass}
              content={
                <Link to={`${rootUrl}/room_books`}>
                  <FormattedMessage
                    id="project.tabs.roomBookTemplates"
                    defaultMessage="Raumbuchvorlagen"
                  />
                </Link>
              }
              active={activeTab === "room_books" && !subTab}
            />
            <If condition={account.isEnabled("show_page_layout_v2")} block>
              <div style={{ marginLeft: "10px", opacity: 0.8 }}>
                <Menu.Item
                  id="project-roomBook-tab-2"
                  name="UpgradeBundles"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/room_books?p=UpgradeBundles`}>
                      <FormattedMessage
                        id="roomBook.upgradeBundles.title"
                        defaultMessage="Upgrade bundles"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "room_books" &&
                    this.subTabMatch("UpgradeBundles")
                  }
                />

                <Menu.Item
                  id="project-roomBook-tab-3"
                  name="UnitVariables"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/room_books?p=UnitVariables`}>
                      <FormattedMessage
                        id="roomBook.unitVariables.types.tab"
                        defaultMessage="Wohn.variablen Typen"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "room_books" &&
                    this.subTabMatch("UnitVariables")
                  }
                />

                <Menu.Item
                  id="project-roomBook-tab-4"
                  name="UnitFeatureGroups"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/room_books?p=UnitFeatureGroups`}>
                      <FormattedMessage
                        id="roomBook.unitFeatureGroups.types.tab"
                        defaultMessage="Einheitsfunktionsgruppen"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "room_books" &&
                    this.subTabMatch("UnitFeatureGroups")
                  }
                />

                <Menu.Item
                  id="project-roomBook-tab-5"
                  name="ConfigurationGroups"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/room_books?p=ConfigurationGroups`}>
                      <FormattedMessage
                        id="roomBook.configurationGroups.types.tab"
                        defaultMessage="Konfigurationsgruppen"
                      />
                    </Link>
                  }
                  active={
                    activeTab === "room_books" &&
                    this.subTabMatch("ConfigurationGroups")
                  }
                />
              </div>
            </If>
          </If>

          <If condition={visibleTabs.indexOf("search") > -1}>
            {searchEnabled || account.isSystemAdmin()
              ? this.renderSearchTab(itemClass)
              : null}
          </If>

          <If condition={!account.isEnabled("show_page_layout_v2")} block>
            <If condition={visibleTabs.indexOf("unit_setup") > -1}>
              <FeatureToggle
                featureTogglesArray={[
                  "show_unit_variables",
                  "show_unit_feature_groups"
                ]}
                any
              >
                <Menu.Item
                  id="unit-setup-tab"
                  name="unitSetup"
                  className={itemClass}
                  content={
                    <Link to={`${rootUrl}/unit_setup`}>
                      <FormattedMessage
                        id="roomBook.unitVariables.tab"
                        defaultMessage="Unit vars"
                      />
                    </Link>
                  }
                  active={activeTab === "unit_setup"}
                />
              </FeatureToggle>
            </If>
          </If>
          <FeatureToggleActive featureToggleName="project_quantities">
            <Menu.Item
              id="project-quantities-tab"
              name="projectQuantities"
              className={itemClass}
              content={
                <Link to={`${rootUrl}/project_quantities`}>
                  <FormattedMessage
                    id="project.tabs.projectQuantities"
                    defaultMessage="Mengenauswertung"
                  />
                </Link>
              }
              active={activeTab === "project_quantities"}
            />
          </FeatureToggleActive>
        </Menu>
      </div>
    );
  }

  renderDeadlineTabLabel(labelStyle) {
    const { i18n, statistics, rootUrl } = this.props;

    if (statistics && statistics.deadlines) {
      const base = statistics.deadlines;
      const stats = {
        overdue: base.project.overdue + base.sections.overdue,
        close: base.project.close + base.sections.close
      };

      const children = [];
      if (stats.overdue > 0) {
        children.push(
          <Label
            color="red"
            data-attr="overdue"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.overdue"]}
            key="overdue"
            style={labelStyle}
          >
            {stats.overdue}
          </Label>
        );
      }
      if (stats.close > 0) {
        children.push(
          <Label
            color="orange"
            data-attr="close"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.close"]}
            key="due"
            style={labelStyle}
          >
            {stats.close}
          </Label>
        );
      }
      return (
        <Link to={`${rootUrl}/deadlines`}>
          {children}{" "}
          <FormattedMessage
            id="project.tabs.deadlines"
            defaultMessage="Projektfristen"
          />
        </Link>
      );
    }
    return (
      <Link to={`${rootUrl}/deadlines`}>
        <FormattedMessage
          id="project.tabs.deadlines"
          defaultMessage="Projektfristen"
        />
      </Link>
    );
  }

  renderUnitsTabLabel(labelStyle) {
    const { i18n, statistics, rootUrl } = this.props;

    if (statistics && statistics.deadlines) {
      const stats = statistics.deadlines.units;

      const children = [];
      if (stats.overdue > 0) {
        children.push(
          <Label
            color="red"
            data-attr="overdue"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.overdue"]}
            key="overdue"
            style={labelStyle}
          >
            {stats.overdue}
          </Label>
        );
      }
      if (stats.close > 0) {
        children.push(
          <Label
            color="orange"
            data-attr="close"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.close"]}
            key="due"
          >
            {stats.close}
          </Label>
        );
      }
      return (
        <Link to={`${rootUrl}/sectionAndUnit`}>
          {children}{" "}
          <FormattedMessage
            id="project.tabs.roomBooks"
            defaultMessage="Raumbücher"
          />
        </Link>
      );
    }
    return (
      <Link to={`${rootUrl}/sectionAndUnit`}>
        <FormattedMessage
          id="project.tabs.roomBooks"
          defaultMessage="Raumbücher"
        />
      </Link>
    );
  }

  renderSearchTab(itemClass) {
    const { activeTab, rootUrl } = this.props;

    return (
      <Menu.Item
        id="project-search"
        name="search"
        className={itemClass}
        content={
          <Link to={`${rootUrl}/search`}>
            <FormattedMessage id="project.tabs.search" defaultMessage="Suche" />
          </Link>
        }
        active={activeTab === "search"}
      />
    );
  }

  renderStatisticsTab(itemClass) {
    const { activeTab, rootUrl } = this.props;
    return (
      <Menu.Item
        id="project-statistics"
        name="statistics"
        className={itemClass}
        content={
          <Link to={`${rootUrl}/statistics`}>
            <FormattedMessage
              id="project.tabs.statistics"
              defaultMessage="Auswertung"
            />
          </Link>
        }
        active={activeTab === "statistics"}
      />
    );
  }
}

TabMenu.propTypes = {
  account: AccountShape.isRequired,
  i18n: I18nShape.isRequired,
  statistics: object,
  activeTab: string,
  subTab: string,
  rootUrl: string.isRequired,
  searchEnabled: bool,
  visibleTabs: arrayOf(string).isRequired,
  showProjectSetup: bool,
  projectName: string.isRequired
};

TabMenu.defaultProps = {
  searchEnabled: false,
  showProjectSetup: false,
  subTab: undefined
};

const mapStateToProps = state => {
  const featureToggles = getFeatureToggles(state);
  const searchEnabled = featureToggles.search_enabled;
  return {
    searchEnabled,
    account: getAccount(state),
    statistics: getProjectStatistics(state)
  };
};

export default connect(mapStateToProps)(TabMenu);
