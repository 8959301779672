import PropTypes from "prop-types";
import React from "react";
import { Statistic } from "semantic-ui-react";
import { get } from "lodash";

class PhaseStatistics extends React.Component {
  static propTypes = {
    statistics: PropTypes.object,
    filter: PropTypes.string,
    onSetFilter: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  handleClick(phase) {
    const { filter } = this.props;
    if (filter && filter === phase) {
      this.props.onSetFilter(); // reset phase filter
    } else {
      this.props.onSetFilter(phase);
    }
  }

  render() {
    const { statistics, filter } = this.props;

    if (!statistics || !Object.keys(statistics).length) {
      return null;
    }

    const phases = [
      {
        id: "overdue",
        label: "Überfällig",
        color: "#bb3131"
      },
      {
        id: "close",
        label: "Bald Fällig",
        color: "#F2711C"
      },
      {
        id: "ontime",
        label: "In Bearbeitung",
        color: "teal"
      },
      {
        id: "completed",
        label: "Erledigt",
        color: "#95c11f"
      }
    ];

    const { length } = phases;

    const children = phases.map(item => {
      const phase = item.id;
      const { color } = item;
      const { label } = item;
      const count = get(statistics, [item.id, "total"], 0);

      let dimmed = "";
      if (filter) {
        dimmed = filter !== phase ? "dimmed" : "undimmed";
      }

      return (
        <Statistic
          key={`statistic-${phase}`}
          onClick={() => this.handleClick(phase)}
          className={`${dimmed} columns-${length}`}
        >
          <Statistic.Value data-phase={phase}>
            <div className="phaseBubble" style={{ backgroundColor: color }} />{" "}
            {count}
          </Statistic.Value>
          <Statistic.Label>{label}</Statistic.Label>
        </Statistic>
      );
    });

    return (
      <Statistic.Group
        data-component="phaseStatistics"
        className="margin bottom medium"
        widths={length || 1}
      >
        {children}
      </Statistic.Group>
    );
  }
}

export default PhaseStatistics;
