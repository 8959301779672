/**
 * Find the nearest char in given string.
 *
 * @param {string}  source    String to search in.
 * @param {array}   lookFor   array includes the matching characters.
 * @param {number}  startPos  An integer representing the index at which to start the search.
 *                            Integers that are negative or out of range of the `source`.length String will not work.
 * @param {number}  direction 1 for lookAhead, -1 for lookBehind defaults 1.
 *
 * @return {Object} Object including the found Character and its Position in the `source` String.
 *                  If there was no match `undefined` and `-1` will be returned
 */
export function closestChar(source, lookFor, startPos = 0, direction = 1) {
  let foundChar;
  let i = startPos;
  while (foundChar === undefined && i >= 0 && i < source?.length) {
    const suspiciousChar = source.charAt(i);
    const foundCharIndex = lookFor.indexOf(suspiciousChar);
    foundChar = lookFor[foundCharIndex];
    i += direction;
  }

  // revert the increment to keep original position of last iteration
  i += -direction;

  // we are used to see -1 for "not found" keep this behavior
  if (foundChar === undefined) {
    i = -1;
  }
  return { foundChar, position: i };
}

export default closestChar;
