import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getProjectFeatures = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "project_features", []);
  }
);

export const getProjectFeatureOptions = createSelector(
  [getProjectFeatures],
  projectFeatures => {
    return projectFeatures.map(f => ({
      key: f.id,
      value: f.id,
      text: f.label
    }));
  }
);
