import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { includes, get, orderBy } from "lodash";
import { Card, Image, Popup } from "semantic-ui-react";
import { ProjectCardImagesResource } from "../../../actions/projectImagesActions";

class ProjectImagesGallery extends React.PureComponent {
  componentDidMount() {
    const { projectCardImagesResource } = this.props;
    projectCardImagesResource.fetchAll();
  }

  componentDidUpdate(prevProps) {
    const { projectId, projectCardImagesResource } = this.props;
    if (projectId !== prevProps.projectId) {
      projectCardImagesResource.fetchAll();
    }
  }

  renderImageCard = (className, image) => {
    const { editing, handleAssignImage, handleSelectImage } = this.props;
    return (
      <Card
        key={image.id || image.large_url}
        className={className}
        data-filename={image.image_file_name || "none"}
        onClick={() => {
          if (editing && className !== "assigned") {
            handleSelectImage(image.id);
          } else if (!editing) {
            handleAssignImage(image.url);
          }
        }}
      >
        <Card.Content>
          <Image
            data-name={image.image_file_name}
            src={image.large_url}
            height="180px"
            width="100%"
          />
        </Card.Content>
      </Card>
    );
  };

  renderImageCards = () => {
    const { idsToDelete, projectImages, currentlyAssignedImage } = this.props;

    return orderBy(projectImages, ["id"], "desc").map(image => {
      let className = includes(idsToDelete, image.id)
        ? "selected-deletion"
        : "";

      if (image.url === currentlyAssignedImage) className = "assigned";

      if (className === "assigned")
        return (
          <Popup
            key={image.id || image.large_url}
            data-attr="no-delete-warning"
            pinned
            on="click"
            trigger={this.renderImageCard(className, image)}
          >
            <Popup.Content>
              <FormattedMessage id="account.settings.image_selector.unable_to_delete_selected_image" />
            </Popup.Content>
          </Popup>
        );

      return this.renderImageCard(className, image);
    });
  };

  render() {
    const { fileUploader, projectImages, editing } = this.props;
    return (
      <>
        {(editing || !projectImages.length) && fileUploader()}
        <Card.Group itemsPerRow={4} data-component="ProjectImagesGallery">
          {this.renderImageCards()}
        </Card.Group>
      </>
    );
  }
}

ProjectImagesGallery.propTypes = {
  // todo: re-enable once we decided how to handle forbidden prop types
  /* eslint-disable react/forbid-prop-types */
  projectCardImagesResource: PropTypes.object.isRequired,
  idsToDelete: PropTypes.array.isRequired,
  projectImages: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  editing: PropTypes.bool.isRequired,
  handleAssignImage: PropTypes.func.isRequired,
  handleSelectImage: PropTypes.func.isRequired,
  currentlyAssignedImage: PropTypes.string.isRequired,
  fileUploader: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const { projectId } = props;
  return {
    projectImages: get(
      state.pageContent,
      `project_${projectId}_card_images`,
      []
    )
  };
};

const mapDispatchToProps = (dispatch, { projectId }) => ({
  projectCardImagesResource: new ProjectCardImagesResource(dispatch, projectId)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectImagesGallery);
