import { UPDATE_FILTER } from "shared/constants/appConstants";
import { FILTER_MESSAGES } from "../../constants/filters";

export const setMessagesFilter = dispatch => {
  return payload => {
    return dispatch({
      type: UPDATE_FILTER,
      payload: {
        [FILTER_MESSAGES]: payload
      }
    });
  };
};

export const resetMessagesFilter = dispatch => {
  return dispatch({
    type: UPDATE_FILTER,
    payload: {
      [FILTER_MESSAGES]: {}
    }
  });
};
