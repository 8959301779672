import React from "react";
import JsonEditor from "shared/components/forms/JsonEditor";
import { Form } from "semantic-ui-react";
import { func, object, string, number } from "prop-types";

const SemanticFormikJsonEditor = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label,
    changeCallback,
    rows
  } = props;

  const handleChange = (_, o) => {
    setFieldValue(name, o.value);
    changeCallback(o.value);
  };

  React.useEffect(() => {
    if (!value) handleChange(null, { value: {} });
  }, []);

  return (
    <Form.Field>
      <JsonEditor
        component={JsonEditor}
        onChange={handleChange}
        name={name}
        label={label}
        value={value}
        rows={rows}
      />
    </Form.Field>
  );
};

SemanticFormikJsonEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  field: object.isRequired,
  label: string.isRequired,
  changeCallback: func,
  rows: number
};

SemanticFormikJsonEditor.defaultProps = {
  changeCallback: () => {},
  rows: undefined
};

export default SemanticFormikJsonEditor;
