import { cloneDeep, compact, tail, values } from "lodash";

export default class RoomBookSelectionModel {
  constructor(roomBook, state) {
    this.roomBook = roomBook;
    if (state) {
      this.state = state;
    } else {
      this.state = roomBook.getSections().reduce((accu, section) => {
        accu[section.id] = {
          id: section.id,
          type: "section",
          selected: false,
          selectable: section.getSections().every(section => {
            return section.getLineItems().every(lineItem => {
              return lineItem.getSubLineItems().every(subLineItem => {
                return subLineItem.isChangePolicy("allowed");
              });
            });
          }),
          children: section.getSections().reduce((accu, section) => {
            accu[section.id] = {
              id: section.id,
              type: "section",
              selected: false,
              selectable: section.getLineItems().every(lineItem => {
                return lineItem.getSubLineItems().every(subLineItem => {
                  return subLineItem.isChangePolicy("allowed");
                });
              }),
              children: section.getLineItems().reduce((accu, lineItem) => {
                accu[lineItem.id] = {
                  id: lineItem.id,
                  type: "line_item",
                  selected: false,
                  selectable: lineItem.getSubLineItems().every(subLineItem => {
                    return subLineItem.isChangePolicy("allowed");
                  }),
                  children: lineItem
                    .getSubLineItems()
                    .reduce((accu, subLineItem) => {
                      accu[subLineItem.getId()] = {
                        id: subLineItem.getId(),
                        type: "sub_line_item",
                        selected: false,
                        selectable: subLineItem.isChangePolicy("allowed")
                      };
                      return accu;
                    }, {})
                };
                return accu;
              }, {})
            };
            return accu;
          }, {})
        };
        return accu;
      }, {});
    }
  }

  toCommand() {
    const result = {
      sections: [],
      line_items: [],
      sub_line_items: []
    };

    this.traverseAll({ children: this.state }, node => {
      if (node.selected) {
        result[`${node.type}s`].push(node.id);
        return false;
      }
    });

    return result;
  }

  hasSelection() {
    const command = this.toCommand();
    return !!(
      command.sections.length ||
      command.line_items.length ||
      command.sub_line_items.length
    );
  }

  toggle(s1, s2, li, sli) {
    const newState = cloneDeep(this.state);
    const ele = this.ele([s1, s2, li, sli], newState);
    const value = this.isSelected(s1, s2, li, sli);
    this.traverseAll(ele, e => {
      e.selected = !value;
    });

    return new RoomBookSelectionModel(this.roomBook, newState);
  }

  isSelected(s1, s2, li, sli) {
    return this.map([s1, s2, li, sli], n => {
      return n;
    }).some(n => {
      return n.selected;
    });
  }

  isSelectable(s1, s2, li, sli) {
    const e = this.ele([s1, s2, li, sli]);
    return e.selectable;
  }

  map(path, fn, state) {
    const accu = [];
    const root = { children: state || this.state };
    this.traverse(compact(path), root, e => {
      accu.push(fn(e));
    });
    return accu;
  }

  traverse(path, node, fn) {
    if (path.length) {
      const c = node.children[path[0]];
      fn(c);
      this.traverse(tail(path), c, fn);
    }
  }

  traverseAll(node, fn) {
    const retVal = fn(node);
    if (retVal !== false && node.children) {
      values(node.children).forEach(n => {
        this.traverseAll(n, fn);
      });
    }
  }

  ele(path, state) {
    return compact(path).reduce(
      (a, id) => {
        return a.children[id];
      },
      { children: state || this.state }
    );
  }
}
