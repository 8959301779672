import { createSelector } from "reselect";
import { get } from "lodash";

import { getI18N, getPageName } from "shared/selectors/base";
import { getRootSections } from "shared/selectors/unit/sections";
import { getProjectSlug } from "shared/selectors/project";
import { getSubLineItems } from "./subLineItems";

export const getOnly3D = props => {
  return get(props, "router.location.query.only3D", "");
};

export const getBaseUnit = state => {
  return get(state, ["pageContent", "unit"], null);
};

export const getUnitCount = state => {
  return get(state, ["pageContent", "unit_count"], 0);
};

export const getUnit = createSelector(
  [getBaseUnit, getRootSections],
  (unit, sections) => {
    return {
      ...unit,
      total: sections.reduce((sum, s) => sum + s.total, 0),
      isPriceOnRequest: sections.some(s => s.isPriceOnRequest),
      isDecided: sections.every(s => s.isDecided)
    };
  }
);

export const getUnitTotal = createSelector([getUnit], unit => unit.total);

/*
 Journey and Unit Progress may differ.
 see: https://gitlab.com/baudigital/plano/-/merge_requests/1124#note_556077382
 */
export const getUnitSamplingProgress = createSelector(
  [getSubLineItems],
  subLineItems => {
    return (
      subLineItems.filter(sli => sli.hasBuyerSelection).length /
      subLineItems.length
    );
  }
);

export const getPageTitle = createSelector(
  [getUnit, getI18N, getPageName],
  (unit, i18n, pageName) => {
    let pageTitle = i18n[pageName];

    if (unit && unit.display_name) {
      pageTitle = [pageTitle, unit.display_name].join(" - ");
    }

    return [pageTitle, i18n["meta.app.name"]].join(" - ");
  }
);

export const isSampleUnit = createSelector([getUnit], unit => {
  return get(unit, "sample", false);
});

export const getUnitPath = createSelector(
  [getUnit, getProjectSlug],
  (unit, projectSlug) => {
    if (unit && projectSlug) {
      return `/${projectSlug}/unit/${unit.slug}`;
    }
    return "/";
  }
);
