import React from "react";
import { func, object } from "prop-types";
import { Checkbox, Dropdown, Header, Input, Segment } from "semantic-ui-react";
import { cloneDeep, get, set } from "lodash";
import { If } from "shared/components/elements/Conditions";

const defaultRoles = ["offer", "order", "enquiry", "roombook", "catalog"];
function ExportSettings({ exportRole, value, onChange, metadata }) {
  const configData = cloneDeep(value);

  const isCustomRole = !defaultRoles.includes(exportRole);

  // update formik config_content when configData state is  changed
  const setJson = data => {
    onChange(undefined, { value: data });
  };

  const handleChange = (e, field, optionValue = null) => {
    const { type, path, key, configDefault, allowEmptyInput } = field;
    const actualPath = path ? `${path}.${key}` : key;

    if (type === "bool") {
      const newConfigData = { ...configData };
      const currentValue = get(configData, actualPath);

      if (currentValue !== undefined) {
        set(newConfigData, actualPath, !currentValue);
      } else {
        set(newConfigData, actualPath, !configDefault);
      }

      setJson(newConfigData);
      return;
    }

    if (type === "input") {
      const newConfigData = { ...configData };

      set(newConfigData, actualPath, e.target.value);
      if (allowEmptyInput === false && e.target.value === "") {
        // delete property if empty string
        if (!path) {
          delete newConfigData[key];
        } else {
          const properties = get(newConfigData, path, {});
          delete properties[key];
          set(newConfigData, path, properties);
        }
      }

      setJson(newConfigData);
      return;
    }

    if (type === "show/hide") {
      const newConfigData = { ...configData };
      const currentValue = get(configData, actualPath);

      if (currentValue !== undefined) {
        set(
          newConfigData,
          actualPath,
          currentValue === "show" ? "hide" : "show"
        );
      } else {
        set(
          newConfigData,
          actualPath,
          configDefault === "show" ? "hide" : "show"
        );
      }

      setJson(newConfigData);
      return;
    }

    if (type === "option") {
      const newConfigData = { ...configData };
      set(newConfigData, actualPath, optionValue);

      setJson(newConfigData);
    }
  };

  const getFieldState = field => {
    const { type, path, key, configDefault, defaultBehaviour } = field;
    const actualPath = path ? `${path}.${key}` : key;

    if (type === "bool") {
      const currentValue = get(configData, actualPath);
      if (currentValue !== undefined) {
        if (currentValue === configDefault) return defaultBehaviour === "show";
        return !(defaultBehaviour === "show");
      }
      return defaultBehaviour === "show";
    }

    if (type === "input") {
      return get(configData, actualPath) || null;
    }

    if (type === "show/hide") {
      const currentValue = get(configData, actualPath);
      if (currentValue) return currentValue === "show";
      return configDefault === "show";
    }

    if (type === "option") {
      const currentValue = get(configData, actualPath);
      if (currentValue) return currentValue;
      return configDefault;
    }

    return undefined;
  };

  const renderBoolField = field => {
    return (
      <div name="field" style={{ marginBottom: "1em" }} key={field.key}>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <span style={{ fontSize: "1em", fontWeight: 700, maxWidth: "20em" }}>
            {field.label}
          </span>
          <Checkbox
            width="1em"
            toggle
            checked={getFieldState(field)}
            style={{ position: "absolute", left: "25em", paddingTop: "1em" }}
            onChange={e => handleChange(e, field)}
          />
        </div>
        <div
          style={{
            paddingLeft: "0.1em",
            color: "grey",
            maxWidth: "20em",
            wordWrap: "break-word",
            minHeight: "1em"
          }}
        >
          {field.description}
        </div>
      </div>
    );
  };

  const renderInputField = field => {
    return (
      <div name="field" style={{ marginBottom: "1em" }} key={field.key}>
        <div style={{ fontWeight: 700 }}>{field.label}</div>
        <Input
          type="text"
          defaultValue={getFieldState(field)}
          onChange={e => handleChange(e, field)}
          style={{ width: "25em" }}
        />
        <If condition={field.description}>
          <div
            style={{
              paddingLeft: "0.5em",
              marginTop: "0.3em",
              color: "grey",
              maxWidth: "20em",
              wordWrap: "break-word",
              minHeight: "1em"
            }}
          >
            {field.description}
          </div>
        </If>
      </div>
    );
  };

  const renderOptionField = field => {
    let actualOptions = field.options;
    if (field.multiSelect) {
      const currentSelected = getFieldState(field) || [];
      const addedOptions = currentSelected.map(el => {
        return { text: el, value: el };
      });
      actualOptions = [...field.options, ...addedOptions];
    }

    return (
      <div name="field" style={{ marginBottom: "1em" }} key={field.key}>
        <div style={{ fontWeight: 700 }}>{field.label}</div>
        <Dropdown
          options={actualOptions}
          value={getFieldState(field)}
          onChange={(e, { value: val }) => handleChange(e, field, val)}
          style={{ width: "25em", marginTop: "0.2rem" }}
          selection
          search={!!field.multiSelect}
          allowAdditions={!!field.multiSelect}
          multiple={!!field.multiSelect}
          clearable={!!field.multiSelect}
        />
        <If condition={!!field.description}>
          <div
            style={{
              paddingLeft: "0.1em",
              marginTop: "0.3em",
              color: "grey",
              maxWidth: "20em",
              wordWrap: "break-word",
              minHeight: "1em"
            }}
          >
            {field.description}
          </div>
        </If>
      </div>
    );
  };

  const renderField = field => {
    switch (field.type) {
      case "bool":
        return renderBoolField(field);
      case "input":
        return renderInputField(field);
      case "option":
        return renderOptionField(field);
      case "show/hide":
        return renderBoolField(field);
      default:
        return null;
    }
  };

  const renderCategory = category => {
    const { fields, label } = metadata[category];

    return (
      <div key={category}>
        <Header as="h3" attached="top">
          {label}
        </Header>
        <Segment name="category" attached style={{ paddingBottom: "0" }}>
          {fields.map(field => {
            if (
              field.onlyForRoles === undefined ||
              field.onlyForRoles.length === 0
            )
              return renderField(field, category);
            if (!Array.isArray(field.onlyForRoles)) return null;
            if (isCustomRole) return renderField(field, category);
            if (!field.onlyForRoles.includes(exportRole)) return null;

            return renderField(field, category);
          })}
        </Segment>
      </div>
    );
  };

  return Object.keys(metadata).map(category => renderCategory(category));
}

ExportSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: object.isRequired,
  onChange: func.isRequired
};

export default ExportSettings;
