import { UPDATE_LOGO_PATH } from "../constants/appConstants";

export const logoPath = (state = "", action) => {
  switch (action.type) {
    case UPDATE_LOGO_PATH:
      return action.payload;
    default:
      return state;
  }
};
