import { array, arrayOf, number, shape, string } from "prop-types";

export const MessageReplyShape = shape({
  parent_id: number,
  project_id: number,
  subject: string,
  recipients: arrayOf(string),
  body: string,
  attachments: array
});

export default MessageReplyShape;
