import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Divider,
  Header,
  Message,
  Modal
} from "semantic-ui-react";
import { connect } from "react-redux";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import { getDescendantsInfoForItem } from "builder_portal/selectors/roomBooks/descendantsInfo";

import "./copyToDerivedDialog.scss";
import { isEmpty } from "lodash";
import DescendantsSelector from "builder_portal/components/roomBook/DescendantsSelector";
import { withRouter } from "../../../shared/helpers/withRouter";

const mapDerivedParams = value => {
  const { parentId, derived, itemId, operation } = value;
  return derived
    ? {
        parentId,
        itemId,
        operation,
        derived: Object.values(derived).map(mapDerivedParams)
      }
    : { parentId, itemId, operation };
};

class CopyToDerivedDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      withChildren: true,
      selection: {}
    };
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  };

  getCopyToDerivedParams() {
    const { itemType } = this.props;
    const { selection, withChildren } = this.state;

    const grouped = Object.values(selection).reduce((akku, value) => {
      const { sourceId, parentId, derived, operation, itemId } = value;

      const entry = derived
        ? {
            parentId,
            itemId,
            operation,
            derived: Object.values(derived).map(mapDerivedParams)
          }
        : { parentId, itemId, operation };

      if (akku[sourceId]) {
        akku[sourceId].targets.push(entry);
      } else {
        akku[sourceId] = {
          sourceId,
          targets: [entry]
        };
      }
      return akku;
    }, {});

    return {
      sourceType: itemType,
      operations: Object.values(grouped),
      options: { withChildren }
    };
  }

  handleChange = ({ selection }) =>
    this.setState({
      selection
    });

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    // This component might disappear when there is no deviation in the
    // descendants left... so we have to deal with that appropriately.
    if (!this.unmounted) {
      this.setState({ open: false, isLoading: false, error: false });
    }
  };

  handleSubmit = () => {
    const { roomBookResource, roomBookId } = this.props;
    this.setState({ isLoading: true });
    roomBookResource
      .copy_to_derived_room_books(roomBookId, this.getCopyToDerivedParams())
      .then(this.handleClose)
      .catch((response = {}) => {
        const { data: error, status } = response;
        this.setState({
          error: error || `Status: ${status}`,
          isLoading: false
        });
      });
  };

  render() {
    const {
      trigger,
      item,
      isSection,
      derivedInfos,
      roomBookStructure
    } = this.props;
    const { withChildren, isLoading, error, selection, open } = this.state;

    if (!derivedInfos || !roomBookStructure) return null;

    const validSelection = !isEmpty(selection);

    return (
      <Modal
        trigger={trigger}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        size="small"
        closeIcon
        data-component="copyToDerivedDialog"
      >
        <Modal.Header>
          <Header>
            {isSection ? "Abschnitt" : "Position"} &quot;{item.title}&quot;
            übernehmen
            <Header.Subheader>
              Wählen Sie die Raumbuchvorlagen / Raumbücher aus, in die{" "}
              {isSection ? "dieser Abschnitt" : "diese Position"} übernommen
              werden soll.
            </Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div className="options">
            <Checkbox
              checked={withChildren}
              label="Unterabschnitte und Positionen mit übernehmen"
              onChange={(_event, { checked }) =>
                this.setState({ withChildren: checked })
              }
            />
          </div>
          <Divider />
          <DescendantsSelector
            roomBookStructure={roomBookStructure}
            derivedInfos={derivedInfos}
            selection={selection}
            onChange={this.handleChange}
          />
          <Message
            error
            header="Ein Fehler ist aufgetreten!"
            content={error}
            hidden={!error}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            disabled={isLoading}
            loading={isLoading}
            onClick={this.handleClose}
          >
            Abbrechen
          </Button>
          <Button
            positive
            disabled={isLoading || !validSelection}
            loading={isLoading}
            onClick={this.handleSubmit}
          >
            Kopieren
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

CopyToDerivedDialog.propTypes = {
  roomBookId: PropTypes.number.isRequired,
  derivedInfos: PropTypes.object,
  roomBookStructure: PropTypes.object,
  trigger: PropTypes.node.isRequired,
  item: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  isSection: PropTypes.bool.isRequired,
  roomBookResource: PropTypes.instanceOf(ProjectRoomBooksResource).isRequired
};

CopyToDerivedDialog.defaultProps = {
  derivedInfos: null,
  roomBookStructure: null
};

const mapDispatchToProps = (dispatch, props) => ({
  roomBookResource: new ProjectRoomBooksResource(
    dispatch,
    props.params.projectId
  )
});

const Component = withRouter(
  connect(getDescendantsInfoForItem, mapDispatchToProps)(CopyToDerivedDialog)
);

Component.propTypes = {
  item: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
  roomBookId: PropTypes.number.isRequired,
  trigger: PropTypes.node.isRequired
};

export default Component;
