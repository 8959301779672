import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import getUnitProspectList from "builder_portal/selectors/unitProspectList";
import { string, oneOfType, func, node, bool } from "prop-types";
import NotificationsResource, {
  AlertsResource
} from "builder_portal/actions/notificationActions";
import { get } from "lodash";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { AccountResource } from "builder_portal/actions/accountActions";
import UnitProspectListResources from "builder_portal/actions/projectUnitProspectsActions";
import UnitProspectListResource from "builder_portal/actions/unitProspectListActions";
import { SilentApiRequestError } from "shared/network/apiRequest";

export const UnitProspectListLoaderContext = createContext({});

const UnitVariablesLoader = ({
  unitId,
  projectId,
  children,
  isProjectLevel
}) => {
  const dispatch = useDispatch();
  const unitProspectList = useSelector(getUnitProspectList);
  const [isLoading, setLoading] = useState(false);

  const prospectListResource = isProjectLevel
    ? new UnitProspectListResources(dispatch, projectId)
    : new UnitProspectListResource(dispatch, unitId);

  const notificationsResource = new NotificationsResource(dispatch);
  const alertsResource = new AlertsResource(dispatch);

  const loadUnitProspectList = () => {
    setLoading(true);
    prospectListResource.fetchAll().then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadUnitProspectList();
    if (projectId) new UnitsResource(dispatch, projectId).fetchAll();
    new AccountResource(dispatch)
      .get()
      .catch(() => new SilentApiRequestError());
    notificationsResource.fetchAll().then(result => {
      const notifications = get(result, "data.notifications", []);
      const filteredNotifications = notifications.filter(
        notification =>
          notification.source.id?.toString() === unitId?.toString() &&
          notification.notification_type === "prospect_configuration"
      );

      if (filteredNotifications.length > 0) {
        const payload = filteredNotifications.map(item => {
          return { id: item.id };
        });
        notificationsResource.batchUpdate(payload).then(() => {
          alertsResource.refresh();
        });
      }
    });
  }, [unitId]);

  return (
    <UnitProspectListLoaderContext.Provider
      value={{
        unitId,
        projectId,
        unitProspectList,
        isLoading,
        prospectListResource,
        isProjectLevel,
        loadUnitProspectList
      }}
    >
      {children}
    </UnitProspectListLoaderContext.Provider>
  );
};

UnitVariablesLoader.propTypes = {
  unitId: string,
  projectId: string,
  children: oneOfType([func, node]),
  isProjectLevel: bool.isRequired
};

UnitVariablesLoader.defaultProps = {
  unitId: null,
  projectId: null,
  children: null
};

export default UnitVariablesLoader;
