import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  Button,
  Segment,
  Grid,
  Icon,
  Header,
  Label,
  Card
} from "semantic-ui-react";
import { get } from "lodash";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import "./productLightbox.scss";

class ProductLightbox extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    model: PropTypes.object,
    toggleDialog: PropTypes.func,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialProductProperties(props);

    this.handleChange = this.handleChange.bind(this);
    this.applyChange = this.applyChange.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  getInitialProductProperties(props) {
    const { model } = props;
    const product = model.getSelectedProductWithTotals();
    const initialProductImage = get(product, "images.0.url");
    const displayedImage = initialProductImage || "";

    return {
      displayedImage,
      product
    };
  }

  handleChange(product) {
    const initialProductImage = get(product, "images.0.url");
    const displayedImage = initialProductImage || "";
    this.setState({
      displayedImage,
      product,
      showInfo: false,
      loading: false
    });
  }

  applyChange(productId) {
    const { onChange, toggleDialog } = this.props;
    this.setState({ loading: true }, () => {
      toggleDialog(false);
      onChange({}, { value: productId });
    });
  }

  toggleDialog(open) {
    if (!open) {
      const { toggleDialog } = this.props;
      toggleDialog(false);
    } else {
      this.setState(this.getInitialProductProperties(this.props));
    }
  }

  render() {
    const { toggleDialog, i18n } = this.props;
    return (
      <Modal
        closeOnEscape
        closeOnDimmerClick={false}
        closeIcon
        open
        onOpen={() => toggleDialog(true)}
        onClose={() => toggleDialog(false)}
        data-component="productLightbox"
      >
        <Modal.Header>{this.renderModalHeader()}</Modal.Header>
        <Modal.Content>{this.renderBody()}</Modal.Content>
        {this.state.product && (
          <Modal.Actions>
            <Grid>
              <Grid.Column width={8} textAlign="left">
                <Button
                  color="black"
                  icon="grid layout"
                  basic
                  content={i18n["roomBook.lineItems.lightBox.title"]}
                  onClick={() => this.handleChange(null)}
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  color="green"
                  content={i18n["meta.actions.apply"]}
                  onClick={() => this.applyChange(this.state.product.id)}
                  loading={this.state.loading}
                />
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        )}
      </Modal>
    );
  }

  renderBody() {
    if (this.state.product) {
      return (
        <Grid stackable>
          <Grid.Column width="14">{this.renderProductContent()}</Grid.Column>
          <Grid.Column width="2">{this.renderGalleryMenu()}</Grid.Column>
        </Grid>
      );
    }
    return this.renderProductSelector();
  }

  renderProductContent() {
    const showImage = this.state.displayedImage && !this.state.showInfo;

    return (
      <Segment style={{ minHeight: "400px" }}>
        <Label as="a" color="red" ribbon>
          {this.renderTotal(
            get(this.state, "product", {
              total: 0,
              group: { on_request: false }
            })
          )}
        </Label>
        <div className="product-content">
          {showImage && this.renderProductImage()}
          {!showImage && this.renderProductDescription()}
        </div>
      </Segment>
    );
  }

  renderProductImage() {
    return (
      <div
        style={{ backgroundImage: `url(${this.state.displayedImage})` }}
        className="image"
      />
    );
  }

  renderProductDescription() {
    const { product } = this.state;
    return (
      <div className="info">
        <Header as="h5">
          <FormattedMessage
            id="product.attributes.description.label"
            defaultMessage="product.attributes.description.label"
          />
        </Header>
        <p>{product.description}</p>
      </div>
    );
  }

  renderGalleryMenu() {
    const images = get(this.state.product, "images", []);

    return (
      <div className="gallery">
        <div className="info" onClick={() => this.setState({ showInfo: true })}>
          <Icon name="info" />
        </div>

        {images.map(image => {
          if (!image || !image.url) {
            return null;
          }

          return (
            <div
              key={image.url}
              className="thumb"
              onClick={() =>
                this.setState({ displayedImage: image.url, showInfo: false })
              }
              style={{ backgroundImage: `url(${image.url})` }}
            />
          );
        })}
      </div>
    );
  }

  renderProductSelector() {
    const { model } = this.props;

    return (
      <div data-component="productSelector">
        <Card.Group itemsPerRow={3}>
          {model.getProductsWithTotals().map(item => {
            return (
              <Card
                key={`product-${item.id}`}
                onClick={() => this.handleChange(item)}
              >
                <Card.Content
                  className="image"
                  style={{
                    backgroundImage: `url(${get(item, "images.0.url", "")})`
                  }}
                >
                  <Label color="red" ribbon>
                    {this.renderTotal(item)}
                  </Label>
                </Card.Content>
                <Card.Content>
                  <Card.Header>{get(item, "name")}</Card.Header>
                  <Card.Meta>{this.renderProductCardMeta(item)}</Card.Meta>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      </div>
    );
  }

  renderTotal = item => {
    if (get(item, ["group", "on_request"], false)) {
      return (
        <FormattedMessage
          id="product_price.attributes.on_request.label"
          defaultMessage="product_price.attributes.on_request.label"
        />
      );
    }
    return <FormatCurrency amount={get(item, "total", 0)} />;
  };

  renderModalHeader() {
    const { product } = this.state;

    if (product && product.id !== "__custom__") {
      return (
        <Header as="h4">
          {get(product, "name")}
          <Header.Subheader>
            {get(product, "supplier")}, {get(product, "series")}
          </Header.Subheader>
        </Header>
      );
    }
    if (product && product.id === "__custom__") {
      return (
        <Header as="h4">
          {get(product, "name")}
          <Header.Subheader>{get(product, "description")}</Header.Subheader>
        </Header>
      );
    }
    return (
      <Header as="h4">
        <FormattedMessage
          id="roomBook.lineItems.lightBox.title"
          defaultMessage="roomBook.lineItems.lightBox.title"
        />
      </Header>
    );
  }

  renderProductCardMeta(item) {
    if (item.id === "__custom__") {
      return <span>{get(item, "description")}</span>;
    }
    return (
      <span>
        {get(item, "supplier")}, {get(item, "series")}
      </span>
    );
  }
}

export default ProductLightbox;
