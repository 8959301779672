import React from "react";
import PropTypes, { string, bool } from "prop-types";
import { Button, Image, Header, Grid, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getImages } from "shared/selectors/project";
import { ReactSortable } from "react-sortablejs";
import ImagesLibrary from "./ImageLibrary";
import "./imageSelector.scss";

const ImageSelector = ({ value, onChange, projectId, multiple, title }) => {
  const projectImages = useSelector(getImages);
  const [allDataImages, setAllDataImages] = React.useState([]);

  React.useEffect(() => {
    setAllDataImages(projectImages);
  }, [projectImages]);

  const setValue = newImage => {
    if (multiple) {
      const newImageId = newImage.id;
      if (value && value.length >= 0) {
        const newValues = [...value, newImageId];
        const uniqueValues = [...new Set(newValues)];
        onChange(uniqueValues);
        setAllDataImages([...allDataImages, newImage]);
      } else {
        onChange([newImage.id]);
        setAllDataImages([...allDataImages, newImage]);
      }
    } else {
      setAllDataImages([...allDataImages, newImage]);
      onChange(newImage?.id);
    }
  };

  const getProjectImageById = id => {
    return allDataImages.find(image => {
      return image.id === id;
    });
  };

  const getSelectedImages = () => {
    if (value && value.length > 0) {
      return value.map(id => getProjectImageById(id));
    }
    return [];
  };

  const fullDataImages = React.useMemo(() => {
    if (multiple) return getSelectedImages();
    return [];
  }, [value, allDataImages]);

  const onDragDropEnds = (oldIndex, newIndex) => {
    const newImage = [...fullDataImages];
    const movedImage = newImage.splice(oldIndex, 1)[0];
    newImage.splice(newIndex, 0, movedImage);
    const newImageId = newImage.map(image => image.id);
    onChange(newImageId);
  };

  // check if all items of array are undefined
  const isAllItemsUndefined = arr => {
    if (arr && arr.length > 0) {
      return arr.every(item => item !== undefined);
    }
    return false;
  };

  const isAllItemsUndefinedMemo = React.useMemo(() => {
    return isAllItemsUndefined(fullDataImages);
  }, [fullDataImages]);

  return (
    <Grid celled>
      <Grid.Column width={16}>
        <div className="image-selector-header ">
          <div>
            <Header as="h3">
              {title ||
                (multiple ? (
                  <FormattedMessage id="imageSelector.imageGallery" />
                ) : (
                  <FormattedMessage id="imageSelector.singleImage" />
                ))}
            </Header>
          </div>
          <div>
            {!multiple && value && (
              <Button
                primary
                onClick={() => {
                  onChange(null);
                }}
              >
                <FormattedMessage id="imageSelector.removeImage" />
              </Button>
            )}
            <ImagesLibrary
              projectId={projectId}
              multiple={multiple}
              getSelectedImage={image => {
                setValue(image);
              }}
              images={projectImages}
            />
          </div>
        </div>
      </Grid.Column>
      <Grid.Column width={16}>
        {!multiple && value && (
          <div className="image-selector-container-single_image">
            <Image
              src={getProjectImageById(value)?.large_url}
              style={{
                width: "80%",
                minHeight: "10rem",
                maxHeight: "25rem",
                objectFit: "contain"
              }}
            />
          </div>
        )}

        {multiple && value && value.length > 0 && isAllItemsUndefinedMemo && (
          <ReactSortable
            list={fullDataImages}
            setList={() => {}}
            ghostClass="dropArea"
            handle=".dragHandle"
            filter=".ignoreDrag"
            className="grid-container"
            preventOnFilter
            onEnd={({ oldIndex, newIndex }) =>
              onDragDropEnds(oldIndex, newIndex)
            }
          >
            {fullDataImages.map(image => (
              <div
                key={image?.id}
                style={{ height: "240px", cursor: "pointer" }}
                className="dragHandle"
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    marginBottom: 36,
                    height: "220px"
                  }}
                >
                  <Button
                    size="mini"
                    icon="delete"
                    onClick={() => {
                      onChange(value?.filter(img => img !== image.id));
                    }}
                    className="image-selector-delete-button"
                  />

                  <Image
                    src={image?.large_url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                  />
                </div>
              </div>
            ))}
          </ReactSortable>
        )}
        {(!value || (Array.isArray(value) && value.length === 0)) && (
          <Grid centered columns={2}>
            <Grid.Column textAlign="center" width={16}>
              <Icon name="images outline" size="big" />
            </Grid.Column>
            <Grid.Column textAlign="center" width={16}>
              <Header as="h3">
                <FormattedMessage id="imageSelector.noImageUploaded" />
              </Header>
            </Grid.Column>
          </Grid>
        )}
      </Grid.Column>
    </Grid>
  );
};

ImageSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  projectId: string.isRequired,
  multiple: bool,
  title: string
};

ImageSelector.defaultProps = {
  multiple: true,
  title: ""
};

export default ImageSelector;
