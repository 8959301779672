import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { useLocalStorage } from "beautiful-react-hooks";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import { getUnit } from "builder_portal/selectors/roomBooks/roomBook";
import UnitVariables from "builder_portal/components/project/unitVariables/UnitVariables";
import UnitFeaturesList from "./UnitFeaturesList";

const tabs = {
  show_unit_variables: "UnitVariables",
  show_unit_feature_groups: "UnitFeatures"
};

const UnitSetup = () => {
  const dispatch = useDispatch();
  const unit = useSelector(getUnit);

  const [currentTab, setCurrentTab] = useLocalStorage(
    "unitLevelUnitSetup",
    tabs.show_unit_variables
  );

  useEffect(() => {
    if (unit)
      Promise.all([
        new UnitsResource(dispatch, unit.project_id).fetchAll(),
        new UnitFeatureResource(dispatch, unit?.project_id).fetchAll()
      ]);
  }, [unit]);

  return (
    <>
      <Menu>
        <FeatureToggleActive featureToggleName="show_unit_variables">
          <Menu.Item
            name={tabs.UnitVariables}
            onClick={() => {
              setCurrentTab(tabs.show_unit_variables);
            }}
            active={currentTab === tabs.show_unit_variables}
          >
            <FormattedMessage id="unitSetup.tabs.unitVariables" />
          </Menu.Item>
        </FeatureToggleActive>

        <FeatureToggleActive featureToggleName="show_unit_feature_groups">
          <Menu.Item
            name={tabs.UnitFeatures}
            onClick={() => {
              setCurrentTab(tabs.show_unit_feature_groups);
            }}
            active={currentTab === tabs.show_unit_feature_groups}
          >
            <FormattedMessage id="unitSetup.tabs.unitFeatures" />
          </Menu.Item>
        </FeatureToggleActive>
      </Menu>

      <FeatureToggleActive featureToggleName="show_unit_variables">
        <If condition={currentTab === tabs.show_unit_variables}>
          <UnitVariables unitId={unit?.id} />
        </If>
      </FeatureToggleActive>

      <FeatureToggleActive featureToggleName="show_unit_feature_groups">
        <If condition={currentTab === tabs.show_unit_feature_groups}>
          <UnitFeaturesList />
        </If>
      </FeatureToggleActive>
    </>
  );
};

export default UnitSetup;
