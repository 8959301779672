import PropTypes from "prop-types";
import React, { Component } from "react";
import ProductSearchTabMenu from "./ProductSearchTabMenu";
import LocalProductSearch from "./LocalProductSearch";
import GlobalProductSearch from "./GlobalProductSearch";
import AddCustomProduct from "./AddCustomProduct";
import "./productSearchContainer.scss";
import HasEditProductsRight from "../../../shared/components/authorization/HasEditProductsRight";

class ProductSearchContainer extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    resources: PropTypes.object,
    account: PropTypes.object,
    handleUpdate: PropTypes.func,
    productGroupId: PropTypes.number,
    usedProductIds: PropTypes.array,
    suppliers: PropTypes.array,
    products: PropTypes.array,
    onSave: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTab: "localProductSearch",
      searchTerm: ""
    };
  }

  handleChangeSearchTerm = value => {
    this.setState({ searchTerm: value });
  };

  render() {
    const {
      i18n,
      resources,
      account,
      productGroupId,
      usedProductIds,
      onSave,
      handleUpdate,
      products,
      suppliers
    } = this.props;
    const { currentTab, searchTerm } = this.state;

    return (
      <div data-component="productSearchContainer">
        <ProductSearchTabMenu
          i18n={i18n}
          currentTab={currentTab}
          onChange={this.handleChangeTab}
          account={account}
        />

        {currentTab === "localProductSearch" && (
          <LocalProductSearch
            i18n={i18n}
            resources={resources}
            handleUpdate={handleUpdate}
            onChangeTab={this.handleChangeTab}
            searchTerm={searchTerm}
            onChangeSearchTerm={this.handleChangeSearchTerm}
            productGroupId={productGroupId}
            usedProductIds={usedProductIds}
            products={products}
          />
        )}

        {(account.isSystemAdmin() ||
          account.isEnabled("product_manager_enabled")) &&
          currentTab === "globalProductSearch" && (
            <HasEditProductsRight>
              <GlobalProductSearch
                i18n={i18n}
                resources={resources}
                handleUpdate={handleUpdate}
                onChangeSearchTerm={this.handleChangeSearchTerm}
                searchTerm={searchTerm}
                suppliers={suppliers}
                productGroupId={productGroupId}
              />
            </HasEditProductsRight>
          )}

        {currentTab === "addCustomProduct" && (
          <HasEditProductsRight>
            <AddCustomProduct
              resources={resources}
              handleUpdate={handleUpdate}
              suppliers={suppliers}
              productGroupId={productGroupId}
              onSave={onSave}
            />
          </HasEditProductsRight>
        )}
      </div>
    );
  }

  handleChangeTab = value => {
    this.setState({ currentTab: value });
  };
}

export default ProductSearchContainer;
