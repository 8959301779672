import React from "react";
import { FormattedMessage } from "react-intl";
import { Loader, Header, Form, Icon, Segment } from "semantic-ui-react";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import JsonEditor from "shared/components/forms/JsonEditor";
import Field from "shared/components/forms/FieldComponent";
import { get } from "lodash";
import PropTypes from "prop-types";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "account.attributes.name.label",
      rule: "isRequired"
    },
    {
      id: "slug",
      label: "account.attributes.slug.label",
      rule: "isRequired"
    },
    {
      id: "logo_url",
      label: "account.attributes.logo_url.label"
    },
    {
      id: "domain",
      label: "account.attributes.domain.label",
      rule: "isRequired"
    },
    {
      id: "unknown_message_mode",
      label: "account.attributes.unknown_message_mode.label",
      rule: "isRequired"
    },
    {
      id: "fallback_email",
      label: "account.attributes.fallback_email.label"
    },
    {
      id: "mailjet_variation",
      label: "account.attributes.mailjet_variation.label"
    },
    {
      id: "features",
      label: "account.attributes.features.label",
      rule: "isRequired"
    },
    {
      id: "project_roles",
      label: "account.attributes.project_roles.label",
      accessor: "config_params.project_roles"
    }
  ]
});

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      account: {}
    };
  }

  componentDidMount() {
    const initAccount = get(this.props, "pageContent.account", {});
    this.writeAccountToState(initAccount);
  }

  componentDidUpdate(prevProps) {
    const prevAccount = get(prevProps, "pageContent.account");
    const nextAccount = get(this.props, "pageContent.account");
    if (nextAccount !== prevAccount) {
      this.writeAccountToState(nextAccount);
    }
  }

  onSubmit(data) {
    const { resource } = this.props;
    const { i18n, growl } = this.props;
    this.setState({ isLoading: true });
    resource
      .save(data)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(error => {
        // We decided to log errors every now and then
        // eslint-disable-next-line no-console
        console.error(error);
        growl.error(
          i18n["message.errorLoad.title"],
          i18n["message.errorLoad.body"]
        );
      });
  }

  writeAccountToState = account => {
    this.setState({ account });
  };

  renderAccount(account) {
    const { i18n } = this.props;
    const { isLoading } = this.state;

    const form = FormFactory.create(account, i18n, {
      onChange: data => {
        this.setState({ account: data });
      }
    });

    form.fields.mailjet_variation.props = {
      ...form.fields.mailjet_variation.props,
      options: [
        { key: "ACCOUNT_1", value: "ACCOUNT_1", text: "ACCOUNT_1" },
        { key: "ACCOUNT_2", value: "ACCOUNT_2", text: "ACCOUNT_2" }
      ]
    };

    return (
      <div>
        <Segment attached="top">
          <Header as="h4">Account Config: {account.name}</Header>
        </Segment>
        <Segment attached>
          <Form
            id="account"
            onSubmit={form.handleSubmit(this.onSubmit.bind(this))}
            data-component="accountForm"
          >
            <Form.Field>
              <Field component="Input" {...form.fields.name} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.slug} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.domain} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.unknown_message_mode} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.fallback_email} />
            </Form.Field>

            <Form.Field>
              <Field component="Select" {...form.fields.mailjet_variation} />
            </Form.Field>

            <Form.Field>
              <Field component="TextArea" {...form.fields.features} />
            </Form.Field>

            <Form.Field>
              <Field component={JsonEditor} {...form.fields.project_roles} />
            </Form.Field>

            <Form.Field>
              <Form.Button
                type="submit"
                color="green"
                loading={isLoading}
                id="save"
              >
                <Icon name="save" />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Form.Button>
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  }

  render() {
    const { account } = this.state;
    if (account) {
      return this.renderAccount(account);
    }
    return <Loader active />;
  }
}

Account.propTypes = {
  resource: PropTypes.object,
  pageContent: PropTypes.object,
  i18n: PropTypes.object,
  growl: PropTypes.object
};

export default Account;
