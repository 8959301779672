const { flatMap } = require("lodash");

const loadProductGroupsForMapping = (resource, catalogs) => {
  return Promise.all(catalogs.map(({ id }) => resource.get(id))).then(
    results => {
      return flatMap(
        results.map(({ data: { product_groups, products } }, idx) => {
          return product_groups.map(product_group => ({
            ...product_group,
            project_catalog_name: catalogs[idx].name,
            project_catalog_slug: catalogs[idx].slug,
            products: product_group.product_options.map(
              ({ product_id, glencoe_product_id }) => ({
                ...products.find(
                  ({ id: productId }) => productId === product_id
                ),
                ...{ glencoe_product_id }
              })
            )
          }));
        })
      );
    }
  );
};

export default loadProductGroupsForMapping;
