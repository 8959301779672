import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getDesignLineGroups } from "shared/selectors";
import { Segment, Header, Grid, Button } from "semantic-ui-react";
import DesignLineGroupsShape from "shared/shapes/designLineGroups.shape";
import { FormattedMessage, useIntl } from "react-intl";
import DesignLinesLoader from "./DesignLinesLoader";
import DesignLineGroupDialog from "./DesignLineGroupDialog";
import DesignLineGroups from "./DesignLineGroups";

const DesignLines = ({ catalogId, designLineGroups }) => {
  const intl = useIntl();

  return (
    <DesignLinesLoader catalogId={catalogId}>
      <Segment.Group>
        <Header as="h5" attached="top">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="12">
              <FormattedMessage id="design_lines.title" />
            </Grid.Column>
            <Grid.Column width="4" textAlign="right">
              <DesignLineGroupDialog
                model={{ name: "", title: "", title_action: "", severity: "recommendation", scope: "unit", confirmation_message: "" }}
                catalogId={catalogId}
                button={
                  <Button
                    id="design_line_group-new"
                    fluid
                    icon="plus"
                    content={intl.formatMessage({
                      id: "design_lines.groups.add"
                    })}
                    loading={!designLineGroups}
                    disabled={!designLineGroups}
                  />
                }
              />
            </Grid.Column>
          </Grid>
        </Header>
        <Segment>
          <DesignLineGroups designLineGroups={designLineGroups} />
        </Segment>
      </Segment.Group>
    </DesignLinesLoader>
  );
};

DesignLines.propTypes = {
  catalogId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  designLineGroups: DesignLineGroupsShape
};

DesignLines.defaultProps = {
  designLineGroups: null
};

const mapStateToProps = state => {
  const designLineGroups = getDesignLineGroups(state);
  return {
    designLineGroups
  };
};

export default connect(mapStateToProps)(DesignLines);
