/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Container, Grid, Header, Button, Modal } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Input, Form, Select } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { WebhooksResource } from "builder_portal/actions/webhooksActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import * as Yup from "yup";
import Growl from "../../../actions/growlActions";

const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|localhost|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

const eventOpt = [
  { key: "*", text: "*", value: null },
  { key: "create", text: "Create", value: "create" },
  { key: "update", text: "Update", value: "update" },
  { key: "delete", text: "Delete", value: "delete" }
];

const methodOpt = [
  { key: "post", text: "POST", value: "post" },
  { key: "put", text: "PUT", value: "put" },
  { key: "get", text: "GET", value: "get" }
];

const itemTypes = [
  { key: "*", text: "*", value: null },
  { key: "roombook", text: "RoomBook", value: "RoomBook" },
  { key: "activity", text: "Activity", value: "Activity" },
  { key: "productgroup", text: "ProductGroup", value: "ProductGroup" },
  { key: "product", text: "Product", value: "Product" },
  { key: "buyer", text: "Buyer", value: "Buyer" },
  { key: "contractor", text: "Contractor", value: "Contractor" }
];

const CreateWebhookDialog = ({ model, trigger, projects }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const resource = new WebhooksResource(dispatch);
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    url: Yup.string()
      .matches(
        urlRegex,
        intl.formatMessage({ id: "message.errorForm.url_format" })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    request_method: Yup.string()
      .transform(v => (v === null ? "" : v))
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = useCallback(
    values => {
      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }
      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  const projectOptions = React.useMemo(() => {
    const options = projects?.map(project => ({
      key: project.id,
      text: project.name,
      value: project.id
    }));
    return [{ key: "*", text: "*", value: null }, ...options];
  }, [projects]);

  const onDelete = () => {
    resource
      .remove(model.id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderDeleteButton = isSubmitting => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="webhooks.dialog.deleteTitle"
        message="webhooks.dialog.deleteWebhook"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Formik
      initialValues={
        model || {
          url: "",
          request_method: null
        }
      }
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={trigger}
          >
            <Header as="h2">
              {model?.id ? (
                <FormattedMessage id="meta.actions.edit" />
              ) : (
                <FormattedMessage id="meta.actions.add" />
              )}
            </Header>
            <Modal.Content>
              <Form id="projectRolesBackendForm">
                <Container>
                  <Grid>
                    <Grid.Column width={16}>
                      <Select
                        clearable
                        name="project_id"
                        fluid
                        placeholder="*"
                        options={projectOptions}
                        label={intl.formatMessage({
                          id: "webhooks.dialog.project"
                        })}
                      />
                      <Select
                        clearable
                        placeholder="*"
                        name="item_type"
                        fluid
                        options={itemTypes}
                        label={intl.formatMessage({
                          id: "webhooks.dialog.entity"
                        })}
                      />
                      <Select
                        name="event"
                        placeholder="*"
                        fluid
                        options={eventOpt}
                        label={intl.formatMessage({
                          id: "webhooks.dialog.event"
                        })}
                      />
                      <Input
                        name="url"
                        fluid
                        placeholder={intl.formatMessage({
                          id: "webhooks.dialog.url"
                        })}
                        label={intl.formatMessage({
                          id: "webhooks.dialog.url"
                        })}
                        errorPrompt
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Select
                        name="request_method"
                        fluid
                        placeholder={intl.formatMessage({
                          id: "webhooks.dialog.method"
                        })}
                        options={methodOpt}
                        label={intl.formatMessage({
                          id: "webhooks.dialog.method"
                        })}
                        errorPrompt
                      />
                    </Grid.Column>
                  </Grid>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button basic disabled={isSubmitting} onClick={handleClose}>
                    <FormattedMessage id="meta.actions.cancel" />
                  </Button>
                  <Button
                    primary
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    <FormattedMessage id="meta.actions.save" />
                  </Button>
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

CreateWebhookDialog.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  trigger: PropTypes.node,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    })
  ).isRequired
};

CreateWebhookDialog.defaultProps = {
  trigger: undefined,
  model: undefined
};

export default CreateWebhookDialog;
