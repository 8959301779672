import serialize from "shared/helpers/serialize";
import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export default class ProjectIssuesResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "project_issue", "/api/v1/project_issues");
  }

  fetchFiltered = filter => {
    let { url } = this;

    const existingFilter = {};
    Object.keys(filter)
      .filter(f => filter[f])
      .forEach(f => {
        existingFilter[f] = filter[f];
      });
    if (Object.keys(existingFilter).length > 0) {
      url = `${url}?${serialize(existingFilter)}`;
    }
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  };
}
