import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import labelByDueDays from "../../helpers/labelByDueDays";

export default class ProjectDeadlineLabel extends Component {
  static propTypes = {
    deadline: PropTypes.object,
    loading: PropTypes.bool,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { loading, deadline, onClick } = this.props;

    const icon = deadline.completed ? "checkmark" : "circle outline";
    const color = deadline.completed
      ? "green"
      : this.getDueLabelColor(deadline.due_in);
    const labelKey = deadline.completed
      ? "activity.done"
      : labelByDueDays(deadline.due_in);
    const label = (
      <FormattedMessage
        id={labelKey}
        defaultMessage={labelKey}
        values={{ amount: Math.abs(deadline.due_in) }}
      />
    );
    const status = this.getStatus(deadline);

    return (
      <Button
        id="project_deadline-label"
        icon={icon}
        color={color}
        label={{ as: "a", content: label }}
        labelPosition="left"
        loading={loading}
        onClick={onClick}
        data-attr="status"
        data-value={status}
      />
    );
  }

  getDueLabelColor(due_in) {
    if (due_in > 3) {
      return "yellow";
    }
    if (due_in > 0) {
      return "orange";
    }
    return "red";
  }

  getStatus(deadline) {
    if (deadline.completed) {
      return "completed";
    }
    if (deadline.due_in <= 0) {
      return "overdue";
    }
    if (deadline.due_in <= 3) {
      return "close";
    }
    return "pending";
  }
}
