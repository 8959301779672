/* eslint-disable react/jsx-props-no-spreading */
import { Draggable } from "react-beautiful-dnd";
import React, { useContext, useMemo } from "react";
import { Button, Card, Grid, Icon, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { browserHistory } from "shared/routes/browserHistory";
import { useSelector } from "react-redux";
import { Link } from "react-router";
import moment from "moment";
import { UnitProspectListLoaderContext } from "../UnitProspectLoader";

import UnitProspectDialog from "../UnitProspectDialog";
import {
  If,
  Unless
} from "../../../../../shared/components/elements/Conditions";
import { getUnits } from "../../../../../shared/selectors/units";

const ListItem = ({ item, index }) => {
  const {
    prospect: { contact }
  } = item;
  const { unitId, projectId } = useContext(UnitProspectListLoaderContext);
  const units = useSelector(getUnits);
  const unit = useMemo(() => units.find(u => u.id === item.unit_id), [
    units,
    item
  ]);

  const renderRedirectToMessagesButton = messageThreadId => {
    return (
      <Button
        id="unit_variable_delete"
        basic
        disabled={!messageThreadId}
        onClick={() => browserHistory.push(`/messages/${messageThreadId}`)}
      >
        <FormattedMessage id="roomBook.unitProspect.attributes.redirect_to_messages" />
      </Button>
    );
  };

  const renderRedirectToConfigurationUrl = prospectConfigurations => {
    const lastConfiguration =
      prospectConfigurations[prospectConfigurations?.length - 1] || {};

    return (
      <Button
        basic
        disabled={!lastConfiguration?.prospect_configuration_url}
        as="a"
        href={lastConfiguration?.prospect_configuration_url}
        target="_configurator"
      >
        <FormattedMessage id="roomBook.unitProspect.attributes.redirect_to_configuration_url" />
      </Button>
    );
  };

  return (
    <Draggable
      draggableId={`${item.id}`}
      index={index}
      key={item.id}
      type="item"
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              marginTop: 12,
              ...provided.draggableProps.style,
              top: "auto !important",
              left: "auto !important"
              // position: "fixed"
            }}
          >
            <div>
              <Card
                style={{
                  border: "1px solid #ccc",
                  boxShadow: "none",
                  borderRadius: 0
                }}
              >
                <Card.Content>
                  <Grid>
                    <Grid.Row style={{ paddingTop: "0.5rem" }}>
                      <Grid.Column width={13}>
                        <div style={{ wordBreak: "break-word" }}>
                          <div className="muted">
                            {moment(item.updated_at).format("LLL")}
                          </div>
                          <Header as="h4" style={{ marginTop: 0 }}>
                            <If
                              condition={
                                contact?.first_name && contact?.last_name
                              }
                            >
                              {contact.first_name} {contact.last_name}
                            </If>
                            <Unless
                              condition={
                                contact?.first_name && contact?.last_name
                              }
                            >
                              {contact.email}
                            </Unless>
                          </Header>
                          <If condition={unit}>
                            <p>
                              <Link
                                to={`/projects/${projectId}/units/${unit?.slug}/room-book`}
                                className="external"
                              >
                                {unit?.display_name}
                              </Link>
                            </p>
                          </If>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <UnitProspectDialog
                          unitId={unitId}
                          prospectId={item.id}
                          model={{
                            unit_prospect: {
                              status: item?.status,
                              account_manager_id: item?.account_manager_id,
                              unit_id: item?.unit_id,
                              prospect_attributes: {
                                id: item?.prospect?.id,
                                contact_attributes: item?.prospect?.contact,
                                comment: item?.prospect?.comment
                              },
                              prospect_configurations:
                                item?.prospect_configurations
                            }
                          }}
                          button={
                            <Icon
                              link
                              name="setting"
                              size="large"
                              style={{ paddingRight: 8, paddingTop: 8 }}
                            />
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
                <Card.Content extra>
                  <div className="ui two buttons">
                    {renderRedirectToConfigurationUrl(
                      item.prospect_configurations
                    )}
                    {renderRedirectToMessagesButton(item.message_thread_id)}
                  </div>
                </Card.Content>
              </Card>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    updated_at: PropTypes.string,
    account_manager_id: PropTypes.number,
    message_thread_id: PropTypes.number,
    unit_id: PropTypes.number,
    status: PropTypes.string,
    prospect_configurations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        prospect_configuration_url: PropTypes.string
      })
    ),
    prospect: PropTypes.shape({
      id: PropTypes.number,
      comment: PropTypes.string,
      contact: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string
      })
    })
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default ListItem;
