import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Modal,
  Table,
  Button,
  Icon,
  Dropdown,
  Grid,
  Segment,
  Header
} from "semantic-ui-react";

class CartTransitionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      loading: false,
      model: this.generateModel(props)
    };
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    if (!onSubmit) return null;

    const { transition } = this.props;
    const { model } = this.state;
    const lineItemTransitions = Object.keys(model).map(id => {
      return { ...model[id], id };
    });
    this.setState({ loading: true });
    return onSubmit(transition.targetState, lineItemTransitions);
  };

  handleToggleDialog(open) {
    const { onClose } = this.props;
    this.setState({ open, loading: false }, () => {
      if (!open && onClose) onClose();
    });
  }

  handleStateSelectionFn = lineItemId => {
    return (event, data) => {
      const { model } = this.state;
      model[lineItemId].new_state = data.value;

      this.setState({ model });
    };
  };

  generateModel(props) {
    const { ctxModel, transition } = props;
    const carts = ctxModel.getCarts();
    const stateCandidates = ctxModel
      .getStateById(transition.targetState)
      .getLineItemStatus();

    return carts.reduce((accu, cart) => {
      return cart.sections.reduce((accu, section) => {
        return section.sections.reduce((accu, section) => {
          return section.line_items.reduce((accu, li) => {
            accu[li.id] = {
              old_state: li.status,
              new_state: stateCandidates[0]
            };
            return accu;
          }, accu);
        }, accu);
      }, accu);
    }, {});
  }

  renderLegends() {
    const { ctxModel, transition } = this.props;
    const { showHelp } = this.state;

    if (showHelp) {
      const stateCandidates = ctxModel
        .getStateById(transition.targetState)
        .getLineItemStatus();
      const lineItemLifeCycle = ctxModel.account.getLineItemLifeCycle();

      const content = stateCandidates.map(stateId => {
        const state = lineItemLifeCycle.getStateById(stateId);
        const icon = !state.terminal
          ? "circle"
          : state.nature === "positive"
          ? "checkmark"
          : "dont";

        return (
          <Grid.Row key={state.id}>
            <Grid.Column width={4} style={{ color: state.phase_color }}>
              <Icon name={icon} style={{ color: state.phase_color }} />{" "}
              {state.label}
            </Grid.Column>
            <Grid.Column width={12}>
              {state.transitionHint || state.hint}
            </Grid.Column>
          </Grid.Row>
        );
      });

      return (
        <Segment>
          <Header as="h4">Optionen für den Statuswechsel</Header>
          <Grid>{content}</Grid>
        </Segment>
      );
    }
    return (
      <Grid>
        <Grid.Column width={16}>
          <Icon name="info circle" />
          <a
            role="link"
            onClick={() => {
              this.setState({ showHelp: true });
            }}
          >
            Weitere Informationen zu den möglichen Optionen anzeigen.
          </a>
        </Grid.Column>
      </Grid>
    );
  }

  renderLineItem(lineItem) {
    const { ctxModel, transition } = this.props;
    const { model } = this.state;
    const stateCandidates = ctxModel
      .getStateById(transition.targetState)
      .getLineItemStatus();
    const lineItemLifeCycle = ctxModel.account.getLineItemLifeCycle();

    const itemTransition = model[lineItem.id];
    const targetStatus = lineItemLifeCycle.getStateById(
      itemTransition.new_state
    );

    const statusOptions = stateCandidates.map(o => {
      const state = lineItemLifeCycle.getStateById(o);
      const icon = !state.terminal
        ? "circle"
        : state.nature === "positive"
        ? "checkmark"
        : "dont";
      return {
        key: state.id,
        value: state.id,
        text: (
          <div>
            <span style={{ color: state.phase_color, fontWeight: "bold" }}>
              <Icon name={icon} style={{ color: state.phase_color }} />{" "}
              {state.label}
            </span>
          </div>
        ),
        content: (
          <div>
            <span style={{ color: state.phase_color, fontWeight: "bold" }}>
              <Icon name={icon} style={{ color: state.phase_color }} />{" "}
              {state.label}
            </span>
          </div>
        )
      };
    });

    return (
      <Table.Row key={lineItem.id} data-model="lineItem" data-id={lineItem}>
        <Table.Cell colSpan={4}>
          <span data-attr="display-number">{lineItem.display_number}</span>{" "}
          {lineItem.title}
        </Table.Cell>
        <Table.Cell colSpan={1} textAlign="left">
          <Dropdown
            id={`status-${lineItem.id}`}
            data-attr="status"
            fluid
            value={targetStatus.id}
            options={statusOptions}
            style={{
              border: "1px solid #eee",
              padding: "8px 6px",
              borderRadius: "4px"
            }}
            onChange={this.handleStateSelectionFn(lineItem.id)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  renderSection(parent) {
    const tableParts = [];
    parent.sections.forEach(section => {
      tableParts.push(
        <Table.Header key={`secondary-section-${section.id}`}>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              {section.display_number} {parent.title} / {section.title}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Neuer Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      );

      tableParts.push(
        <Table.Body key={`secondary-section-${section.id}-line-items`}>
          {section.line_items.reduce((accu, item) => {
            return accu.concat(this.renderLineItem(item));
          }, [])}
        </Table.Body>
      );
    });
    return tableParts;
  }

  renderCarts(carts) {
    return carts.map(cart => {
      return (
        <Table key={cart.id} stackable compact>
          {cart.sections.map(section => {
            return this.renderSection(section);
          })}
        </Table>
      );
    });
  }

  render() {
    const { i18n, ctxModel } = this.props;
    const { open, loading } = this.state;

    const carts = ctxModel.getCarts();
    return (
      <Modal
        data-component="cartTransitionDialog"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        open={open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>Statuswechsel</Modal.Header>
        <Modal.Content>
          {this.renderLegends()}
          {this.renderCarts(carts)}
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            color="green"
            id="save"
            onClick={this.handleSubmit}
            content={i18n["meta.actions.save"]}
            loading={loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

CartTransitionDialog.propTypes = {
  i18n: PropTypes.object,
  ctxModel: PropTypes.object,
  transition: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default CartTransitionDialog;
