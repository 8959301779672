import { arrayOf, bool, shape, string } from "prop-types";

export const CustomImportArgumentShape = shape({
  id: string,
  label: string,
  data_type: string,
  provided: bool
});

export const CustomImportConfigsShape = shape({
  dialog: shape({
    title: string,
    description: string
  }),
  importer: string,
  scope: string,
  arguments: arrayOf(CustomImportArgumentShape)
});

export default CustomImportConfigsShape;
