import { ProjectShape } from "shared/shapes/project.shape";
import { SectionShape } from "shared/shapes/section.shape";
import { bool, number, oneOfType, shape, string } from "prop-types";

export const UnitShape = shape({
  id: number,
  slug: string,
  display_name: string,
  unit_number: string,
  sample: bool,
  sample_unit_token: string,
  description: string,
  cellar_number: string,
  parking_space_number: string,
  marketing_portal_url: string,
  buyer_portal_url: string,
  project_id: oneOfType([number, string]),
  project: ProjectShape,
  section_id: number,
  section: SectionShape,
  buyer_id: number,
  buyer: shape({ label: string }),
  room_book_id: number,
  social_media_title_override: string,
  social_media_description_override: string,
  statistics: shape({
    deadlines: shape({
      overdue: number,
      close: number
    })
  })
});

export default UnitShape;
