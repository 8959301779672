import React, { useState, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Checkbox, Form, Input, Select } from "formik-semantic-ui-react";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import UpgradeBundleGroupsResource from "builder_portal/actions/upgradeBundleGroupsActions";
import UpgradeBundleGroupShape from "shared/shapes/upgradeBundleGroup.shape";
import Growl from "builder_portal/actions/growlActions";
import { node } from "prop-types";

import { UpgradeBundlesLoaderContext } from "./UpgradeBundlesLoader";
import { If } from "../../../../shared/components/elements/Conditions";
import IsBetaPreview from "../../../../shared/components/elements/IsBetaPreview";
import FeatureToggleActive from "../../../../shared/components/elements/FeatureToggleActive";

const UpgradeBundleGroupDialog = ({ button, model }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { projectId, loadData } = useContext(UpgradeBundlesLoaderContext);
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const modeOptions = useMemo(
    () => [
      {
        key: "SINGLE_SELECT",
        value: "SINGLE_SELECT",
        text: intl.formatMessage({
          id: "roomBook.upgreadeBundleGroups.dialog.singleSelect"
        })
      },
      {
        key: "MULTI_SELECT",
        value: "MULTI_SELECT",
        text: intl.formatMessage({
          id: "roomBook.upgreadeBundleGroups.dialog.multiSelect"
        })
      }
    ],
    []
  );

  const onSubmit = (values, formik) => {
    new UpgradeBundleGroupsResource(dispatch, projectId)
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        loadData();
        setOpen(false);
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
      })
      .catch(() => {
        formik.setSubmitting(false);
        new Growl(dispatch).error(
          "roomBook.upgreadeBundleGroups.error.title",
          "roomBook.upgreadeBundleGroups.error.body"
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UpgradeBundleDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`roomBook.upgreadeBundleGroups.dialog.${
            !model.id ? "createTitle" : "editTitle"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.upgreadeBundleGroups.dialog.name"
                  })}
                />

                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "roomBook.upgreadeBundleGroups.dialog.titleField"
                  })}
                />

                <If condition={!values.acts_as_product_group}>
                  <Select
                    id="mode"
                    errorPrompt
                    clearable
                    name="mode"
                    options={modeOptions}
                    label={intl.formatMessage({
                      id: "roomBook.upgreadeBundleGroups.dialog.mode"
                    })}
                  />
                </If>

                <Checkbox
                  id="acts_as_product_group"
                  name="acts_as_product_group"
                  label={intl.formatMessage({
                    id:
                      "roomBook.upgreadeBundleGroups.dialog.actsAsProductGroup"
                  })}
                />

                <If condition={values.acts_as_product_group}>
                  <Checkbox
                    id="acts_as_preselection"
                    name="acts_as_preselection"
                    label={intl.formatMessage({
                      id:
                        "roomBook.upgreadeBundleGroups.dialog.actsAsPreselection"
                    })}
                  />
                </If>

                <Checkbox
                  id="dynamic_calculation"
                  name="dynamic_calculation"
                  label={intl.formatMessage({
                    id:
                      "roomBook.upgreadeBundleGroups.dialog.dynamicCalculation"
                  })}
                />

                <Input
                  id="upgrade_code"
                  errorPrompt
                  name="upgrade_code"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.upgradeCode"
                  })}
                />

                <FeatureToggleActive featureToggleName="show_external_id">
                  <Input
                    id="external_id"
                    errorPrompt
                    name="external_id"
                    label={intl.formatMessage({
                      id: "roomBook.upgradeBundles.dialog.externalId"
                    })}
                  />
                </FeatureToggleActive>

                <IsBetaPreview>
                  <Input
                    id="configuration_link"
                    errorPrompt
                    name="configuration_link"
                    label={intl.formatMessage({
                      id:
                        "roomBook.upgreadeBundleGroups.dialog.configurationLink"
                    })}
                  />
                </IsBetaPreview>

                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UpgradeBundleGroupDialog.propTypes = {
  button: node.isRequired,
  model: UpgradeBundleGroupShape.isRequired
};

export default UpgradeBundleGroupDialog;
