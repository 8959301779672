import { once } from "lodash";

import {
  RoomBookCartResource,
  LineItemResource,
  CartAssignmentResource,
  BatchCartAssignmentResource
} from "../../actions/roomBookActions";

import {
  UPDATE_FLASH_ACTION,
  REMOVE_FLASH_ACTION
} from "../../../shared/constants/appConstants";

class FlashActionDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  dispatchAction(payload) {
    payload.action_id = new Date().getTime();
    this.dispatch({
      type: UPDATE_FLASH_ACTION,
      payload
    });
    setTimeout(() => {
      this.dispatch({
        type: REMOVE_FLASH_ACTION,
        payload
      });
    }, 10000);
  }
}

export default class CartSelectorController {
  constructor(roomBookId, dispatch, i18n) {
    this.roomBookId = roomBookId;
    this.dispatch = dispatch;
    this.i18n = i18n;
  }

  createActivity(values) {
    const resource = new RoomBookCartResource(this.dispatch, this.roomBookId);
    return resource.save(values);
  }

  changeActivity(roomBookId, lineItem, activity_id) {
    const resource = new CartAssignmentResource(
      this.dispatch,
      roomBookId,
      lineItem.getId()
    );
    if (activity_id) {
      return resource.save({ activity_id });
    }
    return resource.remove(lineItem.getActiveCartId());
  }

  suggestBulkChange(suggestion) {
    if (suggestion) {
      const { section, activity, roomBook, lineItems } = suggestion;
      const payload = {
        title: "Weitere Positionen zuordnen?",
        message: `Es können ${lineItems.length} weitere Positionen des Teilabschnitts '${section.title}' dem Vorgang '${activity.title}' zugeordnet werden.`,
        actions: [
          {
            id: "yes",
            label: "meta.actions.assign",
            color: "blue",
            func: once(dialog => {
              const resource = new BatchCartAssignmentResource(
                this.dispatch,
                roomBook.id
              );

              dialog.toggleLoading(true);
              return resource
                .save({
                  activity_id: activity.id,
                  line_item_ids: lineItems.map(li => li.id)
                })
                .then(() => {
                  dialog.toggleLoading(false);
                  dialog.close();
                });
            })
          }
        ]
      };
      new FlashActionDispatcher(this.dispatch).dispatchAction(payload);
    }
  }

  cancelLineItem(element) {
    return this.lineItemResource().cancel(element.id);
  }

  lineItemResource() {
    return new LineItemResource(this.dispatch, this.roomBookId);
  }
}
