import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { formatCurrency } from "shared/helpers/formatCurrency";

import { getProjectCurrency } from "shared/selectors/currency";
import { PriceStrategyShape } from "shared/components/configurator/roomBook.shape";
import { withRouter } from "../../helpers/withRouter";

class FormatCurrency extends Component {
  renderPerUnit() {
    const { priceStrategy } = this.props;
    if (priceStrategy && priceStrategy !== "flat") {
      return (
        <span>
          &nbsp;/&nbsp;
          <FormattedMessage
            id={`roomBook.priceStrategy.${priceStrategy}`}
            defaultText={`roomBook.priceStrategy.${priceStrategy}`}
          />
        </span>
      );
    }
    return null;
  }

  render() {
    const { currency, amount, isOnRequest, className } = this.props;
    if (isOnRequest) {
      return (
        <FormattedMessage
          id="product_group.price_strategies.on_request"
          defaultMessage="Preis auf Anfrage"
        />
      );
    }

    return (
      <span className={className}>
        {formatCurrency(amount, currency.code)}
        {this.renderPerUnit()}
      </span>
    );
  }
}

FormatCurrency.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.shape({
    code: PropTypes.string.isRequired
  }).isRequired,
  priceStrategy: PriceStrategyShape,
  isOnRequest: PropTypes.bool,
  className: PropTypes.string
};

FormatCurrency.defaultProps = {
  amount: 0,
  priceStrategy: null,
  isOnRequest: false,
  className: ""
};

const mapStateToProps = (state, props) => {
  const { projectId } = props.params; // passed by `withRouter()` method
  return {
    currency: getProjectCurrency(state, projectId)
  };
};

export default withRouter(connect(mapStateToProps)(FormatCurrency));
