import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Button, Icon } from "semantic-ui-react";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { formatMessage } from "../helpers/i18n";

const syncFileNameToDisplayName = (oldModel, newModel) => {
  const [fileName, extension] = (newModel.file_name || "").split(".");

  if (fileName === oldModel.display_name) {
    return [newModel.display_name, extension].join(".");
  }
  return newModel.file_name;
};

class AttachmentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
      model: props.attachment
    };
  }

  componentDidUpdate(prevProps) {
    const { attachment } = this.props;
    if (attachment !== prevProps.attachment) {
      this.writeModelToState(attachment);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onSave = values => {
    const { onUpdate } = this.props;
    this.closeOnDone(() => {
      return onUpdate(values);
    });
  };

  onRemove = () => {
    const { onRemove, attachment } = this.props;
    this.closeOnDone(() => {
      return onRemove(attachment.id);
    });
  };

  getFormFactory = () => {
    return new FormDefinition({
      fields: [
        {
          id: "display_name",
          label: "attachment.attributes.display_name.label",
          placeholder: "attachment.attributes.display_name.placeholder",
          message: "attachment.attributes.display_name.error",
          rule: "isRequired"
        },
        {
          id: "role",
          label: "attachment.attributes.role.label",
          rule: "isRequired"
        },
        {
          id: "file_name",
          label: "attachment.attributes.file_name.label"
        }
      ]
    });
  };

  handleToggleDialog = isVisible => {
    this.setState({ open: isVisible });
  };

  writeModelToState = attachment => {
    this.setState({ model: attachment });
  };

  closeOnDone(func) {
    this.setState({ isLoading: true }, () => {
      return func().then(() => {
        if (!this.unmounted) {
          this.setState({ isLoading: false, open: false });
        }
      });
    });
  }

  destructionStrategy() {
    const { i18n, attachment, destructionMode } = this.props;

    if (destructionMode === "unit" || destructionMode === "project") {
      const disclaimer = formatMessage(
        i18n["attachment.actions.removeDialog.messageWithReferences"],
        { count: attachment.references_count - 1 }
      );
      return {
        button: {
          basic: false,
          icon: "trash",
          content: "meta.actions.remove"
        },
        dialog: {
          title: "attachment.actions.removeDialog.title",
          content: (
            <div>
              {attachment.references_count > 1 && (
                <p>
                  <strong>{disclaimer}</strong>
                </p>
              )}
              <p>
                <FormattedMessage id="attachment.actions.removeDialog.message" />
              </p>
            </div>
          )
        }
      };
    }
    if (destructionMode === "lineItem" || destructionMode === "activity") {
      return {
        button: {
          basic: true,
          icon: "remove",
          content: "meta.actions.unlink"
        },
        dialog: {
          title: "attachment.actions.unlinkDialog.title",
          content: (
            <div>
              <p>
                <FormattedMessage
                  id={`attachment.actions.unlinkDialog.message.${destructionMode}`}
                />
              </p>
              <p>
                <small>
                  <FormattedMessage id="attachment.actions.unlinkDialog.hint" />
                </small>
              </p>
            </div>
          )
        }
      };
    }
    return null;
  }

  renderDeleteButton() {
    const { isLoading } = this.state;
    const strategy = this.destructionStrategy();

    if (strategy) {
      const button = (
        <Button
          id="attachment-delete"
          color="red"
          basic={strategy.button.basic}
          icon={strategy.button.icon}
          content={<FormattedMessage id={strategy.button.content} />}
          loading={isLoading}
          className="left floated element"
        />
      );

      const buttons = [
        {
          id: "delete",
          label: strategy.button.content,
          basic: strategy.button.basic,
          color: "red",
          onClick: cb => {
            cb();
            this.onRemove();
          }
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title={strategy.dialog.title}
          message={strategy.dialog.message}
          content={strategy.dialog.content}
          buttons={buttons}
          button={button}
        />
      );
    }
    return null;
  }

  render() {
    const { account, i18n, button } = this.props;

    const { model, open, isLoading } = this.state;
    const roleOptions = account.getDocumentTypes();
    const form = this.getFormFactory().create(model, i18n, {
      onChange: data => {
        this.setState({
          model: { ...data, file_name: syncFileNameToDisplayName(model, data) }
        });
      }
    });

    form.fields.role.props.options = roleOptions.map(roleOption => {
      return {
        key: roleOption.id,
        text: roleOption.label,
        content: (
          <span>
            <Icon name="file outline" /> {roleOption.label}
          </span>
        ),
        value: roleOption.id
      };
    });

    return (
      <Modal
        data-component="attachmentDialog"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={open}
        size="small"
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id="attachment.actions.edit"
            defaultMessage="attachment.actions.edit"
          />
        </Modal.Header>
        <Modal.Content>
          <Form
            id="attachment"
            onSubmit={form.handleSubmit(this.onSave)}
            data-component="attachmentForm"
          >
            <Form.Field width="16">
              <Field component="Input" {...form.fields.display_name} />
            </Form.Field>

            <Form.Field width="16">
              <Field component="Input" {...form.fields.file_name} />
            </Form.Field>

            <Form.Field width="16">
              <Field component="Select" {...form.fields.role} />
            </Form.Field>

            <Form.Field width="16">
              <label htmlFor={`download-attachment-${model.id}`}>
                Download
              </label>
              <Button
                id={`download-attachment-${model.id}`}
                href={model && model.attachment_url}
                target="_blank"
                fluid
                basic
                icon="download"
                content={model && `"${model.file_name}" herunterladen`}
                style={{ textAlign: "left" }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {model.deletable && this.renderDeleteButton()}
          <Button
            data-form="attachment"
            type="submit"
            color="green"
            loading={isLoading}
            className="editAttachment"
            onClick={form.handleSubmit(this.onSave)}
          >
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AttachmentDialog.propTypes = {
  attachment: PropTypes.object,
  button: PropTypes.node,
  activityId: PropTypes.string,
  account: PropTypes.object,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func
};

export default AttachmentDialog;
