import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import { Button } from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";

import { CartAssignmentResource } from "../../actions/activityActions";

import "./cart.scss";

class CartRemover extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    lineItem: PropTypes.object,
    handleUpdate: PropTypes.func,
    cartAssignmentResource: PropTypes.instanceOf(CartAssignmentResource),
    isLoading: PropTypes.bool,
    cart: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  unassignLineItem() {
    const { cartAssignmentResource, handleUpdate, lineItem, cart } = this.props;
    return cartAssignmentResource.remove(cart.id).then(() => {
      return handleUpdate(lineItem.id);
    });
  }

  handleCartUnassignmentFn() {
    const { cartAssignmentResource, cart } = this.props;
    return () => {
      cartAssignmentResource.get(cart.id).then(response => {
        this.setState({ assignment: get(response, "data.cart_assignment") });
      });
    };
  }

  render() {
    const { lineItem, i18n, isLoading } = this.props;
    const { assignment } = this.state;

    const button = (
      <Button
        id="remove-cart"
        loading={isLoading}
        className="transparent"
        basic
        icon="remove"
        onClick={this.handleCartUnassignmentFn()}
      />
    );
    if (!assignment) {
      return button;
    }
    if (assignment.removal_policy.policy === "allowed") {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.unlink",
          color: "red",
          onClick: callback => {
            this.unassignLineItem().then(callback);
          }
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          key={lineItem.id}
          title="roomBook.lineItems.actions.unlinkDialog.title"
          message="roomBook.lineItems.actions.unlinkDialog.message"
          buttons={buttons}
          button={button}
          open
        />
      );
    }
    const buttons = [
      {
        id: "cancel",
        label: i18n["meta.actions.accept"],
        basic: true
      }
    ];

    const message = assignment.removal_policy.hint;
    const messageParams = assignment.removal_policy.hint_params || {};

    return (
      <ConfirmationDialog
        key={lineItem.id}
        title={i18n["roomBook.lineItems.actions.unlinkDialog.title"]}
        content={
          <FormattedMessage
            id={message}
            defaultValue={message}
            values={messageParams}
          />
        }
        buttons={buttons}
        button={button}
        open
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  i18n: state.i18n,
  isLoading: CartAssignmentResource.isLoadingSelector(state, props)
});

const mapDispatchToProps = (dispatch, props) => {
  const activityId = get(props, "cart.activity_id");
  const lineItemId = get(props, "lineItem.id");
  return {
    cartAssignmentResource: new CartAssignmentResource(
      dispatch,
      activityId,
      lineItemId
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartRemover);
