import React, { useState, useMemo, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Checkbox, Form, Select, TextArea } from "formik-semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { node, number } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import ProjectQuestionsResource from "builder_portal/actions/projectQuestionsActions";
import ProjectQuestionShape from "shared/shapes/projectQuestions.shape";

import { ProjectQuestionsLoaderContext } from "./ProjectQuestionsLoader";

const ProjectQuestionDialog = ({ button, total, model }) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { projectQuestionGroups, projectId, loadData } = useContext(
    ProjectQuestionsLoaderContext
  );

  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const projectQuestionGroupOptions = useMemo(() => {
    return projectQuestionGroups.map(group => ({
      key: group.id,
      value: group.id,
      text: group.name
    }));
  }, [projectQuestionGroups]);

  const positionOptions = useMemo(() => {
    return Array.from({ length: total }, (_, i) => ({
      key: i + 1,
      value: i + 1,
      text: i + 1
    }));
  });

  const validationScheme = Yup.object({
    question: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    answer: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = useCallback(
    (values, formik) => {
      new ProjectQuestionsResource(dispatch, projectId)
        .save(values)
        .then(() => {
          const isCreateDialog = !!values.id;
          if (!isCreateDialog) formik.resetForm();
          loadData();
          setOpen(false);
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
        });
    },
    [model]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="ProjectQuestionDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`roomBook.projectQuestions.dialog.${
            !model.id ? "createTitle" : "editTitle"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Select
                  id="project_question_group_id"
                  errorPrompt
                  clearable
                  name="project_question_group_id"
                  options={projectQuestionGroupOptions}
                  label={intl.formatMessage({
                    id:
                      "roomBook.projectQuestions.dialog.projectQuestionGroupId"
                  })}
                />

                <TextArea
                  id="question"
                  name="question"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestions.dialog.question"
                  })}
                />

                <TextArea
                  id="answer"
                  name="answer"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestions.dialog.answer"
                  })}
                />

                <Checkbox
                  id="published_bp"
                  name="published_bp"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestions.dialog.publishedBp"
                  })}
                />

                <Checkbox
                  id="published_mp"
                  name="published_mp"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestions.dialog.publishedMp"
                  })}
                />

                <Select
                  id="position"
                  name="position"
                  options={positionOptions}
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestions.dialog.position"
                  })}
                />

                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({
                      id: "meta.actions.cancel"
                    })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

ProjectQuestionDialog.propTypes = {
  button: node.isRequired,
  model: ProjectQuestionShape.isRequired,
  total: number.isRequired
};

export default ProjectQuestionDialog;
