import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import {
  DesignLineGroupsResource,
  DesignLinesResource
} from "../../actions/designLinesActions";

export const DesignLinesLoaderContext = createContext({});

const DesignLinesLoader = ({ catalogId, children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const loadData = () => {
    setLoading(true);
    new DesignLineGroupsResource(dispatch, catalogId)
      .fetchAll()
      .then(() => setLoading(false));
  };

  const saveDesignLineGroup = values => {
    setLoading(true);
    return new DesignLineGroupsResource(dispatch, catalogId)
      .save(values)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const destroyDesignLineGroup = id => {
    setLoading(true);
    return new DesignLineGroupsResource(dispatch, catalogId)
      .remove(id)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const saveDesignLine = (values, groupId) => {
    setLoading(true);
    return new DesignLinesResource(dispatch, catalogId, groupId)
      .save(values)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const destroyDesignLine = (id, groupId) => {
    setLoading(true);
    return new DesignLinesResource(dispatch, catalogId, groupId)
      .remove(id)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  useEffect(() => {
    if (catalogId) {
      loadData();
    }
  }, [catalogId]);

  return (
    <DesignLinesLoaderContext.Provider
      value={{
        loadData,
        saveDesignLineGroup,
        destroyDesignLineGroup,
        saveDesignLine,
        destroyDesignLine,
        catalogId,
        loading
      }}
    >
      {children}
    </DesignLinesLoaderContext.Provider>
  );
};

DesignLinesLoader.propTypes = {
  catalogId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

DesignLinesLoader.defaultProps = {
  children: null
};

export default DesignLinesLoader;
