import Resources from "../../shared/actions/resourcesActions";

export class ProjectDeadlinesResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "deadline", `/api/v1/projects/${projectId}/deadlines`);
  }
}

export class SectionDeadlinesResource extends Resources {
  constructor(dispatch, sectionId) {
    super(dispatch, "deadline", `/api/v1/sections/${sectionId}/deadlines`);
  }
}

export class UnitDeadlinesResource extends Resources {
  constructor(dispatch, unitId) {
    super(dispatch, "deadline", `/api/v1/units/${unitId}/deadlines`);
  }
}
