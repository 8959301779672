export default class SubLineItemSearchUpdate {
  constructor(state, events) {
    this.state = state;
    this.events = events;
  }

  apply() {
    return this.events.reduce((state, event) => {
      switch (event.type) {
        case "sub_line_item_updated":
          return this.applyUpdate(state, event.payload);
        case "sub_line_item_deleted":
          return this.applyDelete(state, event.payload);
        default:
          return state;
      }
    }, this.state);
  }

  applyUpdate(state, new_item) {
    const {
      sub_line_items_search_results: oldSubLineItemsSearchResults = {}
    } = state;
    const { results: oldResults = [] } = oldSubLineItemsSearchResults;
    const results = oldResults.map(item =>
      item.id !== new_item.id ? item : new_item
    );
    return {
      ...state,
      sub_line_items_search_results: {
        ...oldSubLineItemsSearchResults,
        results
      }
    };
  }

  applyDelete(state, deleted_item) {
    const {
      sub_line_items_search_results: oldSubLineItemsSearchResults = {}
    } = state;
    const {
      meta: oldMeta = {},
      results: oldResults = []
    } = oldSubLineItemsSearchResults;
    let limit_value = oldMeta.limit_value || 0;
    const results = oldResults.filter(item => {
      const isDeleted = item.id === deleted_item.id;
      if (isDeleted) {
        limit_value--;
      }
      return !isDeleted;
    });
    const total_count = (oldMeta.total_count || 0) - 1;
    return {
      ...state,
      sub_line_items_search_results: {
        ...oldSubLineItemsSearchResults,
        meta: {
          ...oldMeta,
          limit_value,
          total_count
        },
        results
      }
    };
  }
}
