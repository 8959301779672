import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Icon } from "semantic-ui-react";

class TaskCompletedEvent extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    user: PropTypes.string,
    title: PropTypes.string,
    delegatee: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { date, user, title, delegatee } = this.props;

    return (
      <div
        data-component="timelineItem"
        className="taskCompletedEvent"
        data-event="task_completed"
      >
        <div className="bullet-pointer">
          <Icon color="grey" size="large" circular name="check" />
        </div>
        <Container data-component="logDetails">
          <time>{date}</time>{" "}
          <FormattedMessage
            id="activity.timeline.event.taskCompleted"
            defaultMessage="activity.timeline.event.taskCompleted"
            values={{ title, delegatee }}
          />
          <div className="user">{user}</div>
        </Container>
      </div>
    );
  }
}

export default TaskCompletedEvent;
