import { UPDATE_IS_LOADING } from "../constants/appConstants";

export const isLoading = (state = "", action) => {
  switch (action.type) {
    case UPDATE_IS_LOADING: {
      let loaders = { ...state, ...action.payload };
      Object.keys(loaders).map(key => {
        if (!loaders[key]) {
          delete loaders[key];
        }
      });
      if (JSON.stringify(loaders) === "{}") {
        loaders = false;
      }
      return loaders;
    }
    default:
      return state;
  }
};
