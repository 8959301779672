import React, { useState, useMemo, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Modal,
  Button,
  Input,
  Header,
  Dropdown,
  Grid
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { node, number, string, func, object, oneOfType } from "prop-types";
import {
  HeaderCellShape,
  BodyCellShape
} from "shared/shapes/tableContent.shape";
import DropdownItem from "./DropdownItem";

const getExpression = data => {
  if (data.if) return "if";
  if (data.unless) return "unless";
  return "";
};

const TableCellDialog = ({
  button,
  rowType,
  elIndex,
  tableType,
  rowIndex,
  updateRow,
  metadata,
  model
}) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState(model);
  const [expression, setExpression] = useState(getExpression(data));

  const editMode = useMemo(() => {
    return !!(data?.field || data?.title);
  }, [data]);

  const handleOpen = () => {
    setData({ ...model });
    setOpen(true);
  };

  const handleClose = () => {
    setData({ width: 1 });
    setOpen(false);
  };

  const handleUpdateRow = e => {
    e.preventDefault();
    updateRow({
      rowType,
      data,
      elIndex,
      tableType,
      rowIndex
    });
    handleClose();
  };

  const handleChange = (_, { name, value, type }) => {
    const val = type === "number" ? parseInt(value, 10) : value;
    if (name === "condition" && !expression) return;
    const field_name = name === "condition" ? expression : name;
    const temp = { ...data };
    if (name === "condition" && value.length === 0) {
      delete temp.if;
      delete temp.unless;
      setData(temp);
      return;
    }
    setData({ ...temp, [field_name]: val });
  };

  const levelFilter = useCallback(
    list => {
      const level =
        rowType.indexOf("sub_line_item") === 0 ? "sub_line_item" : "line_item";
      return list[level];
    },
    [rowType]
  );

  const fieldOptions = useMemo(() => {
    return levelFilter(metadata.validations).map(x => ({
      key: x.name,
      value: x.name,
      text: x.title || x.name,
      content: <DropdownItem item={x} />
    }));
  }, [metadata]);

  const conditionOptions = useMemo(() => {
    return levelFilter(metadata.conditions).map(x => ({
      key: x.name,
      value: x.name,
      text: x.title || x.name,
      content: <DropdownItem item={x} />
    }));
  }, [metadata]);

  const styleOptions = useMemo(() => {
    return levelFilter(metadata.styles).map(x => ({
      key: x.name,
      value: x.name,
      text: x.title || x.name,
      content: <DropdownItem item={x} />
    }));
  }, [metadata]);

  const handleExpressionChange = (_, { value }) => {
    const temp = { ...data };
    if (!value) {
      delete temp.if;
      delete temp.unless;
    }
    setData(temp);
    setExpression(value);
  };

  return (
    <Modal
      data-component="TableCellDialog"
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={button}
      size="large"
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header>
        <FormattedMessage
          id="documentsExport.dialogs.exports.content.dialog.title"
          values={{ editMode }}
        />
      </Modal.Header>
      <Modal.Content>
        <If
          condition={tableType === "body"}
          styles={{ display: "block", marginBottom: "10px" }}
        >
          <Header as="h5">
            <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.fieldName" />
          </Header>
          <Dropdown
            name="field"
            selection
            search
            fluid
            defaultValue={data.field}
            options={fieldOptions}
            onChange={handleChange}
          />
        </If>
        <If condition={tableType === "header"} styles={{ display: "block" }}>
          <Header as="h5">
            <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.titleName" />
          </Header>
          <Input
            name="title"
            fluid
            value={data.title}
            onChange={handleChange}
            autoComplete="off"
          />
        </If>
        <If condition={tableType === "body"} styles={{ display: "block" }}>
          <Grid>
            <Grid.Column width={5}>
              <Header as="h5">
                <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.expression" />
              </Header>
              <Dropdown
                fluid
                selection
                clearable
                options={[
                  { key: "if", value: "if", text: "if" },
                  { key: "unless", value: "unless", text: "unless" }
                ]}
                value={expression}
                onChange={handleExpressionChange}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <Header as="h5">
                <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.condition" />
              </Header>
              <Dropdown
                name="condition"
                multiple
                selection
                search
                fluid
                value={data[expression] || []}
                options={conditionOptions}
                onChange={handleChange}
              />
            </Grid.Column>
          </Grid>
        </If>
        <Header as="h5">
          <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.style" />
        </Header>

        <Dropdown
          name="style"
          selection
          search
          fluid
          defaultValue={data.style}
          options={styleOptions}
          onChange={handleChange}
        />
        <Header as="h5">
          <FormattedMessage id="documentsExport.dialogs.exports.content.dialog.width" />
        </Header>
        <Input
          name="width"
          type="number"
          fluid
          value={data.width}
          onChange={handleChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="grey"
          content={intl.formatMessage({ id: "meta.actions.cancel" })}
          onClick={handleClose}
        />
        <Button
          positive
          content={intl.formatMessage({ id: "meta.actions.save" })}
          onClick={handleUpdateRow}
          disabled={!data.field && !data.title}
        />
      </Modal.Actions>
    </Modal>
  );
};

TableCellDialog.propTypes = {
  button: node.isRequired,
  rowType: string.isRequired,
  elIndex: number,
  tableType: string.isRequired,
  rowIndex: number.isRequired,
  updateRow: func.isRequired,
  model: oneOfType([HeaderCellShape, BodyCellShape]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: object.isRequired
};

TableCellDialog.defaultProps = {
  elIndex: 0
};

export default TableCellDialog;
