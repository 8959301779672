import React from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import DropzoneComponent from "react-dropzone-component";
import localStorage from "../../../shared/helpers/localStorage";
import "./dropzone.scss";

const DEFAULT_MAX_FILE_SIZE = 5;
const MEGA = 1024 * 1024;

class ImageDropzone extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    // @todo: destroy dropzone
  }

  render() {
    const {
      i18n,
      title,
      resourceName,
      resourceId,
      className,
      handleSuccess,
      handleComplete,
      paramName = "image[image]",
      maxFilesize = DEFAULT_MAX_FILE_SIZE
    } = this.props;
    const options = this.props.options || { path: "thumb" };

    const componentConfig = {
      iconFiletypes: [".jpg", ".png", ".gif"],
      showFiletypeIcon: true,
      postUrl: `/api/v1/${resourceName}/${resourceId}/${options.path}`
    };

    const djsConfig = {
      dictDefaultMessage: title || i18n["dropzone.defaultMessage"],
      dictFallbackMessage: i18n["dropzone.fallbackMessage"],
      dictInvalidFileType: i18n["dropzone.invalidFileType"],
      dictFileTooBig: i18n["dropzone.fileTooBig"],
      dictResponseError: i18n["dropzone.responseError"],
      dictCancelUpload: i18n["dropzone.cancelUpload"],
      dictCancelUploadConfirmation: i18n["dropzone.cancelUploadConfirmation"],
      dictRemoveFile: i18n["dropzone.removeFile"],
      dictMaxFilesExceeded: i18n["dropzone.maxFilesExceeded"],
      autoProcessQueue: true,
      uploadMultiple: false,
      paramName,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      },
      acceptedFiles: "image/jpeg,image/png,image/gif",
      maxFilesize,
      previewTemplate: ReactDOMServer.renderToStaticMarkup(<div />)
    };

    const eventHandlers = {
      sending: (file, xhrObject, formData) => {
        const element = document.querySelectorAll(".dz-error")?.[0];
        if (element) element.innerHTML = "";

        formData.append("image[role]", "thumb");
        return FormData;
      },
      complete: handleComplete,
      success: handleSuccess,
      addedfile: file => {
        setTimeout(() => {
          if (file.size > maxFilesize * MEGA) {
            const element = document.querySelectorAll(".dz-error")?.[0];
            if (element) {
              const size = `${Math.round(file.size / MEGA, 1)}MB`;
              element.style.color = "#bb3131";
              element.style.cursor = "pointer";
              element.style.fontSize = "0.85rem";
              element.style.lineHeight = "0.9rem";
              element.style.marginTop = "10px";
              element.innerHTML = i18n["dropzone.fileTooBig"]
                .replace("{{filesize}}", size)
                .replace("{{maxFilesize}}", `${maxFilesize}MB`);

              // move up in DOM to have messages  horizontally
              const shadow = document.querySelectorAll(`.${className}`)?.[0];
              if (shadow) {
                shadow.classList.remove("dz-started");
                const parent = shadow.parentElement.querySelectorAll(
                  ".dz-message"
                )[0];
                if (parent) parent.appendChild(element);
              }
            }
          }
        }, 500);
      }
    };

    return (
      <div data-component="imageDropzone">
        <DropzoneComponent
          className={["dropzone", className].join(" ")}
          config={componentConfig}
          djsConfig={djsConfig}
          eventHandlers={eventHandlers}
        />
      </div>
    );
  }
}

ImageDropzone.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  resourceName: PropTypes.string,
  resourceId: PropTypes.number,
  handleSuccess: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  options: PropTypes.object,
  paramName: PropTypes.string,
  maxFilesize: PropTypes.number
};

ImageDropzone.defaultProps = {
  paramName: undefined,
  maxFilesize: DEFAULT_MAX_FILE_SIZE
};

export default ImageDropzone;
