import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { arrayOf, func, number, object, shape, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import { ProductShape } from "shared/shapes";
import { stopPropagation } from "shared/helpers/event";
import { Button, Grid, Image, Modal, Select } from "semantic-ui-react";
import { cloneDeep, sortBy } from "lodash";
import Growl from "builder_portal/actions/growlActions";

const ParamMapperModal = ({
  mapperDialogOpen,
  activeProductGroup,
  setProductDetail,
  paramMapping,
  setMapperDialogOpen,
  handleMapperApply
}) => {
  const [formValues, setFormValues] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (activeProductGroup) setFormValues(cloneDeep(activeProductGroup));
  }, [activeProductGroup]);

  const handleChange = (productId, selectedParamName, selectedParamValue) => {
    const changedProducts = formValues.products.map(product => {
      if (product.id === productId) {
        const temp = { ...product };
        if (selectedParamName) {
          temp.selected = [selectedParamName, selectedParamValue];
        } else if (!selectedParamName && !selectedParamValue) {
          temp.selected = undefined;
        }
        return temp;
      }
      if (!product.selected || !product.selected[1]) {
        return { ...product, selected: [selectedParamName, null] };
      }
      return product;
    });
    setFormValues({ ...formValues, products: changedProducts });
  };

  const renderSelectParamName = (selectedMapping, productGroup, productId) => {
    const selectedParamName = selectedMapping && selectedMapping[0];
    const options = sortBy(
      Object.keys(paramMapping).map(x => ({
        key: x,
        value: x,
        text: x
      })),
      option => {
        return option.text;
      }
    );
    return (
      <Select
        options={options}
        search
        data-component="viewSelectorParam"
        value={selectedParamName || ""}
        onTouchStart={stopPropagation}
        onTouchEnd={stopPropagation}
        onChange={(event, selectObject) =>
          handleChange(productId, selectObject.value)
        }
        style={{ minWidth: "7em" }}
      />
    );
  };

  const renderSelectParamValue = (selectedMapping, productGroup, productId) => {
    const selectedParamName = selectedMapping?.[0] || null;
    const selectedParamValue = selectedMapping?.[1] || null;
    if (!selectedParamName)
      return <Select options={[]} style={{ width: "17em" }} />;

    const paramValues = paramMapping[selectedParamName];
    if (!paramValues) {
      new Growl(dispatch).error(
        "message.invalid_mapping.title",
        "message.invalid_mapping.body",
        {
          bodyValues: {
            paramName: selectedParamName
          }
        }
      );
      return <Select options={[]} style={{ width: "17em" }} />;
    }

    const options = sortBy(
      Object.keys(paramValues).map(x => {
        let name = paramValues[x];
        name = !name ? x : `${name} (${x})`;
        return { key: x, value: x, text: name };
      }),
      option => {
        return option.text;
      }
    );
    return (
      <Select
        options={options}
        data-component="viewSelectorName"
        value={selectedParamValue}
        onTouchStart={stopPropagation}
        onTouchEnd={stopPropagation}
        onChange={(event, selectObject) =>
          handleChange(productId, selectedParamName, selectObject.value)
        }
        style={{ width: "17em" }}
      />
    );
  };

  const handleMapperClose = () => {
    setMapperDialogOpen(0);
  };

  return (
    <Modal
      data-component="buyerPortalMapperModal"
      size="large"
      open={mapperDialogOpen > 0}
      closeOnEscape
      closeOnDimmerClick
      onClose={handleMapperClose}
    >
      <Modal.Header>
        <FormattedMessage id="product_group.title.one" />: {formValues?.name} -{" "}
        {formValues?.project_catalog_name}
      </Modal.Header>
      <Modal.Content>
        <Grid columns={14}>
          {formValues?.products?.map(product => (
            <Grid.Row key={product.id}>
              <Grid.Column width={2}>
                <Image src={product.thumb_url} alt={product.name} />
              </Grid.Column>
              <Grid.Column width={4}>
                {product.name}
                <div className="supplier">
                  {product.supplier} - {product.series}
                </div>
              </Grid.Column>
              <Grid.Column width={1}>
                <Button onClick={() => setProductDetail(product)}>i</Button>
              </Grid.Column>
              <Grid.Column width={2}>
                {renderSelectParamName(
                  product.selected,
                  mapperDialogOpen,
                  product.id
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                {renderSelectParamValue(
                  product.selected,
                  mapperDialogOpen,
                  product.id
                )}
              </Grid.Column>
              <Grid.Column width={1}>
                {product.selected?.[1] && (
                  <Button
                    onClick={() =>
                      handleChange(product.id, product.selected?.[0], null)
                    }
                  >
                    <FormattedMessage id="meta.actions.unlink" />
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleMapperClose}>
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
        <Button onClick={e => handleMapperApply(e, formValues)} positive>
          <FormattedMessage id="meta.actions.ok" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ParamMapperModal.propTypes = {
  mapperDialogOpen: number.isRequired,
  activeProductGroup: shape({
    id: number,
    name: string.isRequired,
    products: arrayOf(ProductShape),
    selected: arrayOf(string),
    sku: string,
    supplier: string,
    thumb_url: string
  }),
  setProductDetail: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paramMapping: object.isRequired,
  handleMapperApply: func.isRequired,
  setMapperDialogOpen: func.isRequired
};

ParamMapperModal.defaultProps = {
  activeProductGroup: undefined
};

export default ParamMapperModal;
