import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { I18nShape } from "shared/shapes/i18n.shape";
import { arrayOf, func, instanceOf, shape, string } from "prop-types";
import { BuyerShape } from "shared/shapes/buyer.shape";
import { SectionShape } from "shared/shapes/section.shape";
import { getFeatureToggles, getI18N } from "shared/selectors";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { getSortedSections } from "shared/selectors/sections";
import { getUnits } from "shared/selectors/units";
import { UnitShape } from "shared/shapes/unit.shape";
import ProjectBuyersResource, {
  ProjectBuyerInvitationResource
} from "../../actions/buyerActions";
import { getBuyersWithStatus } from "../../selectors/buyers";
import BuyerCollection from "./BuyerCollection";

class ProjectBuyerContainer extends PureComponent {
  componentDidMount() {
    const { actions } = this.props;
    actions.buyersResource.fetchAll();
  }

  render() {
    const {
      i18n,
      buyers,
      featureToggles,
      actions,
      projectId,
      sections,
      units
    } = this.props;
    return (
      <BuyerCollection
        projectId={projectId}
        buyers={buyers || []}
        featureToggles={featureToggles}
        actions={actions}
        i18n={i18n}
        sections={sections}
        units={units}
      />
    );
  }
}

ProjectBuyerContainer.propTypes = {
  projectId: string.isRequired,
  i18n: I18nShape.isRequired,
  buyers: arrayOf(BuyerShape).isRequired,
  sections: arrayOf(SectionShape).isRequired,
  actions: shape({
    buyersResource: instanceOf(ProjectBuyersResource),
    projectResource: instanceOf(ProjectsResource),
    buyerInvitationResourceCreator: func
  }).isRequired,
  units: arrayOf(UnitShape).isRequired
};

const mapStateToProps = state => {
  return {
    i18n: getI18N(state),
    featureToggles: getFeatureToggles(state),
    buyers: getBuyersWithStatus(state),
    sections: getSortedSections(state),
    units: getUnits(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props;
  return {
    actions: {
      buyersResource: new ProjectBuyersResource(dispatch, projectId),
      projectResource: new ProjectsResource(dispatch),
      buyerInvitationResourceCreator: buyerId => {
        return new ProjectBuyerInvitationResource(dispatch, projectId, buyerId);
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBuyerContainer);
