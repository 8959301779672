import { number, shape, string, object } from "prop-types";

const OnlineConfigurationShape = shape({
  id: number,
  logo: string,
  banner: string,
  backdrop: string,
  // eslint-disable-next-line react/forbid-prop-types
  translations: object,
  styling_mode: string,
  // eslint-disable-next-line react/forbid-prop-types
  styling_advanced: object
});
export default OnlineConfigurationShape;
