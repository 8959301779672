import { find } from "lodash";
import {
  UPDATE_CONTRACTOR_SELECTION,
  RESET_CONTRACTOR_SELECTION
} from "../constants/appConstants";

const INIT_STATE = {
  selectedContractorIds: []
};

export const contractors = (state = INIT_STATE, action) => {
  let nextSelection;
  switch (action.type) {
    case UPDATE_CONTRACTOR_SELECTION:
      if (find(state.selectedContractorIds, action.payload)) {
        nextSelection = state.selectedContractorIds.filter(
          contractor => contractor.id !== action.payload.id
        );
      } else {
        nextSelection = [...state.selectedContractorIds, action.payload];
      }

      return { ...state, selectedContractorIds: nextSelection };
    case RESET_CONTRACTOR_SELECTION:
      return { ...state, selectedContractorIds: [] };
    default:
      return state;
  }
};
