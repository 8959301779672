import { get, sortBy, keyBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getUnitFeatureGroups = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "unit_feature_groups", []), ["name"]);
  }
);

export const getUnitFeatureGroupsOptions = createSelector(
  [getPageContent],
  pageContent => {
    const unitFeatureGroups = sortBy(
      get(pageContent, "unit_feature_groups", []),
      ["name"]
    );
    return unitFeatureGroups.map((t, i) => ({
      key: `${t.id}${i}`,
      value: t.id,
      text: t.name
    }));
  }
);

export const getUnitFeatures = createSelector([getPageContent], pageContent => {
  return sortBy(get(pageContent, "unit_features", []), "id");
});

export const getUnitFeaturesById = createSelector(
  [getPageContent],
  pageContent => {
    return keyBy(get(pageContent, "unit_features", []), "id");
  }
);

export const getUnitFeatureOptions = createSelector(
  [getUnitFeatures],
  unitFeatures => {
    return unitFeatures.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name
    }));
  }
);
