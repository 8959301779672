import Resource from "../../shared/actions/resourceActions";
import apiRequest from "../../shared/network/apiRequest";

export default class ProfileResource extends Resource {
  constructor(dispatch) {
    super(dispatch, "profile", "/api/v1/profile");
  }

  update(values) {
    const payload = {};
    payload[this.namespace] = values;

    return apiRequest.patch(`${this.url}`, this.dispatch, payload);
  }

  projectsMembership() {
    return apiRequest
      .get(`${this.url}/project_memberships`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}
