import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal, Table } from "semantic-ui-react";
import { get } from "lodash";

const BatchDeleteDialog = props => {
  const { selectedItems } = props;

  const tableRows = selectedItems.map(item => {
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{get(item, "product.name")}</Table.Cell>
        <Table.Cell>{get(item, "product.sku")}</Table.Cell>
        <Table.Cell>{get(item, "title")}</Table.Cell>
        <Table.Cell>{get(item, "section.title")}</Table.Cell>
        <Table.Cell>{get(item, "unit.label")}</Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Modal.Content>
      <Modal.Description>
        <FormattedMessage id="batchEdit.messages.itemsSelected" />
        <b>{selectedItems.length}</b>
      </Modal.Description>
      <Modal.Description className="margin top medium">
        <FormattedMessage id="batchEdit.messages.delete" />
      </Modal.Description>
      <Modal.Description>
        <FormattedMessage id="batchEdit.messages.noUndo" />
      </Modal.Description>
      <Modal.Description>
        <Table className="margin top spacious">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Produkt</Table.HeaderCell>
              <Table.HeaderCell>SKU</Table.HeaderCell>
              <Table.HeaderCell>Produktgruppe</Table.HeaderCell>
              <Table.HeaderCell>Sektion</Table.HeaderCell>
              <Table.HeaderCell>Wohneinheit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tableRows}</Table.Body>
        </Table>
      </Modal.Description>
    </Modal.Content>
  );
};

BatchDeleteDialog.propTypes = {
  selectedItems: PropTypes.array.isRequired
};

export default BatchDeleteDialog;
