/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
// eslint-disable react/require-default-props
import PropTypes from "prop-types";
import React from "react";
import { sortBy, intersection } from "lodash";
import { Link } from "react-router";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Container,
  Segment,
  Header,
  Icon,
  Message,
  Table,
  Button,
  Dropdown
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import SubLineItemModel from "builder_portal/models/roomBook/SubLineItemModel";
import CartContent from "../../cart/CartContent";
import LineItemActions from "../lineItems/LineItemActions";
import LineItemDialogContainer from "../../lineItems/LineItemDialogContainer";
import SubLineItemDialogContainer from "../../lineItems/SubLineItemDialogContainer";
import LineItemTimelineDialogContainer from "../../lineItems/LineItemTimelineDialogContainer";

class ActivityCarts extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    actions: PropTypes.object,
    ctxModel: PropTypes.object,
    ctxCtrl: PropTypes.object,
    context: PropTypes.object,
    dialog: PropTypes.object,
    handleUpdate: PropTypes.func,
    toggleDialog: PropTypes.func
  };

  static defaultProps = {
    i18n: {},
    actions: {},
    ctxModel: {},
    ctxCtrl: {},
    context: {},
    dialog: {},
    handleUpdate: () => {},
    toggleDialog: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedCartId: null,
      selectedTradeId: null
    };
  }

  render() {
    const { ctxCtrl, context } = this.props;
    return (
      <div data-component="carts">
        {this.renderActions()}
        {this.renderCarts()}
        <LineItemDialogContainer
          module="activity"
          id={this.props.ctxModel.id}
          ctrl={ctxCtrl}
        />
        <SubLineItemDialogContainer
          module="activity"
          id={this.props.ctxModel.id}
          ctrl={ctxCtrl}
        />
        <LineItemTimelineDialogContainer
          module="activity"
          id={this.props.ctxModel.id}
          account={context.account}
        />
      </div>
    );
  }

  handleTradeChange = (e, { value }) => {
    this.setState({ selectedTradeId: value });
  };

  renderCarts() {
    const { selectedCartId } = this.state;
    const carts = this.getSortedCarts();

    if (selectedCartId) {
      const cart = carts.filter(c => {
        return c.id === selectedCartId;
      })[0];
      return this.renderCart(cart);
    }
    if (carts.length < 1) {
      return this.renderEmptyMessage();
    }
    if (carts.length > 1) {
      return this.renderCartsOverview(carts);
    }
    return this.renderCart(carts[0]);
  }

  renderEmptyMessage() {
    return (
      <Message warning>
        <Message.Header>
          <FormattedMessage id="activity.message.noLineItems.title" />
        </Message.Header>
        <p>
          <FormattedMessage id="activity.message.noLineItems.body" />
        </p>
      </Message>
    );
  }

  renderCartsOverview(carts) {
    const { ctxModel } = this.props;
    const accountingMode = ctxModel.getAccountingMode();

    const showWarningsIds = [];
    carts.forEach(cart => {
      const productsRepository = ctxModel.getProductRepositoryForPriceCatalog(
        cart.price_catalog_id
      );
      cart.sections.forEach(section => {
        section.sections.forEach(s => {
          s.line_items.forEach(lineItem => {
            lineItem.sub_line_items.forEach(subLineItem => {
              const productCollection = subLineItem.product_group_id
                ? productsRepository.getGroupProducts(
                    subLineItem.product_group_id
                  )
                : [];
              const subLineItemModel = new SubLineItemModel(
                subLineItem,
                productCollection,
                false
              );
              if (subLineItemModel.isPriseCostDiverged())
                showWarningsIds.push(subLineItem.id);
            });
          });
        });
      });
    });

    let totalLineItemCount = 0;
    const items = carts.map(cart => {
      let subLineItems = [];
      const lineItemCount = cart.sections.reduce((c, s) => {
        return s.sections.reduce((c, s) => {
          subLineItems = s.line_items
            .map(lineItem =>
              lineItem.sub_line_items.map(subLineItem => subLineItem.id)
            )
            .flat();
          return c + s.line_items.length;
        }, c);
      }, 0);
      totalLineItemCount += lineItemCount;
      return (
        <Table.Row key={cart.id} data-model="cart">
          <Table.Cell width={5}>
            <Icon name="cube" /> {cart.unit.label} {cart.unit.buyer}
            <If
              condition={intersection(subLineItems, showWarningsIds).length > 0}
            >
              <Icon name="warning" color="yellow" className="circle" />
            </If>
          </Table.Cell>
          <Table.Cell width={2} textAlign="right">
            {lineItemCount}
          </Table.Cell>
          <Table.Cell width={4} textAlign="right">
            <FormatCurrency amount={cart.total_costs} />
          </Table.Cell>
          <Table.Cell width={4} textAlign="right">
            <FormatCurrency amount={cart.total} />
          </Table.Cell>
          <Table.Cell width={1} textAlign="center">
            <a
              role="button"
              onClick={() => {
                this.setState({ selectedCartId: cart.id });
              }}
            >
              <Icon name="arrow right" />
            </a>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table stackable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Wohneinheit</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Positionen</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id={`roomBook.carts.tableHeaders.costs.${accountingMode}`}
                defaultMessage={`roomBook.carts.tableHeaders.costs.${accountingMode}`}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="roomBook.carts.tableHeaders.price.net"
                defaultMessage="roomBook.carts.tableHeaders.price.net"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="meta.actions.details"
                defaultMessage="details"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{items}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.genericSubtotal"
                defaultMessage="roomBook.genericSubtotal"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" data-attr="lineItemCount">
              {totalLineItemCount}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormatCurrency
                amount={ctxModel.getActivity().applicable_costs}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormatCurrency
                amount={ctxModel.getActivity().applicable_price}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">&#8203;</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  renderCart(cart) {
    const {
      i18n,
      ctxModel,
      ctxCtrl,
      dialog,
      toggleDialog,
      actions,
      context,
      handleUpdate
    } = this.props;
    const { selectedCartId, selectedTradeId } = this.state;

    const trades = ctxModel.getTrades();
    const { sections, trades: currentAcitivityTrades } = cart;

    const filteredSections = sections.reduce((acc, section) => {
      const filteredSubSections = section.sections.reduce(
        (subAcc, subSection) => {
          const filteredLineItems = subSection.line_items.filter(lineItem => {
            if (!selectedTradeId || selectedTradeId === "all") {
              return true;
            }
            if (selectedTradeId === "none") {
              return !lineItem.trades.length;
            }
            return lineItem.trades.includes(selectedTradeId);
          });
          return [...subAcc, { ...subSection, line_items: filteredLineItems }];
        },
        []
      );
      return [...acc, { ...section, sections: filteredSubSections }];
    }, []);

    const filteredCart = {
      ...cart,
      sections: filteredSections
    };

    return (
      <div data-component="line_items">
        <div data-component="carts">
          <Container key={cart.id} data-component="cartTabContainer">
            <Segment.Group
              data-component="cart"
              data-model="cart"
              data-name={filteredCart.name}
            >
              <Segment>
                <Grid>
                  <Grid.Column width={6} verticalAlign="middle">
                    <Header as="h4">
                      <Link
                        to={`/projects/${ctxModel.getProject().slug}/units/${
                          filteredCart.unit.slug
                        }/room-book`}
                      >
                        <Icon name="cube" /> Wohneinheit:{" "}
                        {filteredCart.unit.display_name}
                      </Link>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={7} floated="right">
                    <Dropdown
                      search
                      clearable
                      placeholder={i18n["roomBook.lineItems.filterByTrade"]}
                      fluid
                      selection
                      options={[
                        { key: "all", value: "all", text: "Alle" },
                        { key: "none", value: "none", text: "Keine" },
                        ...trades
                          .map(trade => {
                            return {
                              key: trade.id,
                              value: trade.id,
                              text: trade.label
                            };
                          })
                          .filter(trade =>
                            currentAcitivityTrades.includes(trade.key)
                          )
                      ]}
                      onChange={this.handleTradeChange}
                    />
                  </Grid.Column>
                  {selectedCartId && (
                    <Grid.Column width={4} verticalAlign="middle">
                      <Button
                        onClick={() => {
                          this.setState({ selectedCartId: null });
                        }}
                        content={i18n["meta.actions.close"]}
                        size="tiny"
                        floated="right"
                        basic
                      />
                    </Grid.Column>
                  )}
                </Grid>
              </Segment>
              <Segment>
                <CartContent
                  ctxModel={ctxModel}
                  ctxCtrl={ctxCtrl}
                  model={filteredCart}
                  context={context}
                  i18n={i18n}
                  dialog={dialog}
                  toggleDialog={toggleDialog}
                  actions={actions}
                  handleUpdate={handleUpdate}
                />
              </Segment>
            </Segment.Group>
          </Container>
        </div>
      </div>
    );
  }

  renderActions() {
    const { i18n, ctxCtrl, ctxModel } = this.props;
    return (
      <LineItemActions ctxModel={ctxModel} ctxCtrl={ctxCtrl} i18n={i18n} />
    );
  }

  getSortedCarts() {
    const { ctxModel } = this.props;
    const carts = ctxModel.getCarts();
    const filteredCarts = carts.filter(cart => {
      return cart.sections.length;
    });
    return sortBy(filteredCarts, cart => {
      return cart.unit.label;
    });
  }
}

export default ActivityCarts;
