import Resources from "../../shared/actions/resourcesActions";

export class UserMigrationResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "migration",
      `/api/v1/projects/${projectId}/user_migration`
    );
  }
}

export default UserMigrationResource;
