import {
  func,
  instanceOf,
  string,
  oneOf,
  arrayOf,
  shape,
  number
} from "prop-types";
import React, { Component } from "react";
import { cloneDeep, keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { Button, Dropdown, Label, Table } from "semantic-ui-react";
import { formatNumber } from "shared/helpers/formatNumber";
import CurrencySign from "shared/components/currency/CurrencySign";
import FloatInput from "shared/components/forms/FloatInput";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import { I18nShape } from "shared/shapes/i18n.shape";
import SubLineItemFormModel from "builder_portal/models/roomBook/SubLineItemFormModel";

class LineItemCosts extends Component {
  addCostItem(item) {
    const { costs, onChange } = this.props;
    const { trade: newTrade } = item;
    if (!costs.some(({ trade }) => trade === newTrade))
      onChange([...costs, item]);
  }

  removeCostItem(index) {
    const { costs, onChange } = this.props;
    const nextCosts = cloneDeep(costs).filter((el, i) => i !== index);
    onChange(nextCosts);
  }

  updateCostItem(value, index) {
    const { costs, onChange } = this.props;
    const nextCosts = cloneDeep(costs);
    nextCosts[index].cost = value;
    onChange(nextCosts);
  }

  updateExcessCostItem(value, index) {
    const { costs, onChange } = this.props;
    const nextCosts = cloneDeep(costs);
    if (nextCosts[index].cost === value) {
      nextCosts[index].excess_cost = null;
    } else {
      nextCosts[index].excess_cost = value;
    }
    onChange(nextCosts);
  }

  renderTable(costs, tradesDictionary) {
    const { formModel, mode } = this.props;

    if (mode === "selectedCosts") {
      if (formModel.isFlat()) {
        return this.renderSelectionCostsFlat(
          formModel,
          costs,
          tradesDictionary
        );
      }
      return this.renderSelectionCosts(formModel, costs, tradesDictionary);
    }
    if (formModel.isFlat()) {
      return this.renderDefaultCostsFlat(formModel, costs, tradesDictionary);
    }
    return this.renderDefaultCosts(formModel, costs, tradesDictionary);
  }

  renderSelectionCosts(formModel, costs, tradesDictionary) {
    const { i18n } = this.props;
    let totalCostNet = 0;
    return (
      <div>
        {costs.map((item, index) => {
          const excessCost =
            item.excess_cost || item.excess_cost === 0.0
              ? item.excess_cost
              : item.cost;
          const itemCost =
            formModel.getDefaultQuantity() * item.cost +
            formModel.getExcessQuantity() * excessCost;
          totalCostNet += itemCost;

          return (
            <Table stackable key={`${item.id}-${item.trade}`}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={3}>
                    {
                      (tradesDictionary[item.trade] || { label: item.trade })
                        .label
                    }
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    <Button
                      as="div"
                      data-component="deleteCostItem"
                      onClick={event => {
                        event.preventDefault();
                        this.removeCostItem(index);
                      }}
                      compact
                      size="tiny"
                      basic
                      circular
                      color="red"
                      icon="remove"
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body
                data-component="costItem"
                key={`${item.id}-${item.trade}`}
              >
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="roomBook.lineItems.attributes.costs.default_quantity.label"
                      defaultMessage="roomBook.lineItems.attributes.costs.default_quantity.label"
                    />
                  </Table.Cell>
                  <Table.Cell className="field">
                    <FloatInput
                      fluid
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      defaultValue={formModel.getDefaultQuantity()}
                      readOnly
                    />
                  </Table.Cell>
                  <Table.Cell collapsing className="operator">
                    x
                  </Table.Cell>
                  <Table.Cell className="field">
                    <CurrencyInput
                      id={`${item.trade}-cost`}
                      name={`costs_attributes.[${index}].cost`}
                      fluid
                      defaultValue={item.cost}
                      onBlur={(event, data) =>
                        this.updateCostItem(data.value, index)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="roomBook.lineItems.attributes.costs.excess_quantity.label"
                      defaultMessage="roomBook.lineItems.attributes.costs.excess_quantity.label"
                    />
                  </Table.Cell>
                  <Table.Cell className="field">
                    <FloatInput
                      fluid
                      defaultValue={formModel.getExcessQuantity()}
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      readOnly
                    />
                  </Table.Cell>
                  <Table.Cell collapsing className="operator">
                    x
                  </Table.Cell>
                  <Table.Cell className="field">
                    <CurrencyInput
                      id={`${item.trade}-excess-cost`}
                      name={`costs_attributes.[${item.id}].excess_cost`}
                      fluid
                      defaultValue={excessCost}
                      onBlur={(event, data) =>
                        this.updateExcessCostItem(data.value, index)
                      }
                    />
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell colSpan={2} />
                  <Table.Cell>
                    <FormattedMessage
                      id="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                      defaultMessage="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                    />
                  </Table.Cell>
                  <Table.Cell className="field">
                    <CurrencyInput
                      id={`${item.trade}-total`}
                      fluid
                      defaultValue={itemCost}
                      readOnly
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          );
        })}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={4}>
                <FormattedMessage
                  id="roomBook.lineItems.attributes.costs.total_costs.label"
                  defaultMessage="roomBook.lineItems.attributes.costs.total_costs.label"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Footer>
            <Table.Row data-component="costItem">
              <Table.HeaderCell colSpan={3} textAlign="right">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.costs.selection_costs.label"
                  defaultMessage="roomBook.lineItems.attributes.costs.selection_costs.label"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="field">
                <div
                  id="subtotal-cost"
                  className="ui right labeled fluid input"
                >
                  <input
                    name="subtotal-cost"
                    readOnly
                    value={formatNumber(totalCostNet)}
                  />
                  <Label>
                    <CurrencySign />
                  </Label>
                </div>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row data-component="costItem">
              <Table.HeaderCell colSpan={3} textAlign="right">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.costs.default_costs.label"
                  defaultMessage="roomBook.lineItems.attributes.costs.default_costs.label"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="field">
                <CurrencyInput
                  id="budget"
                  fluid
                  defaultValue={-formModel.getCostBudget()}
                  readOnly
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row data-component="costItem">
              <Table.HeaderCell colSpan={3} textAlign="right">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                  defaultMessage="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="field">
                <div id="total-cost" className="ui right labeled fluid input">
                  <input
                    name="total-cost"
                    readOnly
                    value={formatNumber(
                      totalCostNet - formModel.getCostBudget()
                    )}
                  />
                  <Label>
                    <CurrencySign />
                  </Label>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }

  renderDefaultCosts(formModel, costs, tradesDictionary) {
    const { i18n } = this.props;
    let totalCostNet = 0;
    return (
      <Table
        stackable
        size="small"
        className="margin top medium"
        data-component="costItemTable"
      >
        <Table.Body>
          {costs.map((item, index) => {
            const itemCost = formModel.getDefaultQuantity() * item.cost;
            totalCostNet += itemCost;

            return (
              <Table.Row
                data-component="costItem"
                key={`${item.id}-${item.trade}`}
              >
                <Table.Cell>
                  {
                    (tradesDictionary[item.trade] || { label: item.trade })
                      .label
                  }
                </Table.Cell>
                <Table.Cell className="field">
                  <FloatInput
                    fluid
                    label={
                      i18n[
                        `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                      ]
                    }
                    labelPosition="right"
                    defaultValue={formModel.getDefaultQuantity()}
                    readOnly
                  />
                </Table.Cell>
                <Table.Cell collapsing className="operator">
                  x
                </Table.Cell>
                <Table.Cell className="field">
                  <CurrencyInput
                    id={`${item.trade}-cost`}
                    name={`costs_attributes.[${item.id}].cost`}
                    fluid
                    defaultValue={item.cost}
                    onBlur={(event, data) =>
                      this.updateCostItem(data.value, index)
                    }
                  />
                </Table.Cell>
                <Table.Cell collapsing className="operator">
                  =
                </Table.Cell>
                <Table.Cell className="field">
                  <CurrencyInput
                    id={`${item.trade}-total`}
                    fluid
                    defaultValue={itemCost}
                    readOnly
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    as="div"
                    data-component="deleteCostItem"
                    onClick={event => {
                      event.preventDefault();
                      this.removeCostItem(index);
                    }}
                    compact
                    size="tiny"
                    basic
                    circular
                    color="red"
                    icon="remove"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row data-component="costItem">
            <Table.HeaderCell colSpan={5}>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="field" textAlign="right">
              <div id="total-cost" className="ui right labeled fluid input">
                <input
                  name="total-cost"
                  readOnly
                  value={formatNumber(totalCostNet)}
                />
                <Label>
                  <CurrencySign />
                </Label>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  renderSelectionCostsFlat(formModel, costs, tradesDictionary) {
    let totalCostNet = 0;
    return (
      <Table
        stackable
        size="small"
        className="margin top medium"
        data-component="costItemTable"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={10}>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.trade.label"
                defaultMessage="roomBook.lineItems.attributes.costs.trade.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={6} colSpan={2}>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {costs.map((item, index) => {
            const itemCost = item.cost;
            totalCostNet += itemCost;

            return (
              <Table.Row
                data-component="costItem"
                key={`${item.id}-${item.trade}`}
              >
                <Table.Cell>
                  {
                    (tradesDictionary[item.trade] || { label: item.trade })
                      .label
                  }
                </Table.Cell>
                <Table.Cell className="field">
                  <CurrencyInput
                    id={`${item.trade}-cost`}
                    name={`costs_attributes.[${item.id}].cost`}
                    fluid
                    defaultValue={item.cost}
                    onBlur={(event, data) =>
                      this.updateCostItem(data.value, index)
                    }
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    as="div"
                    data-component="deleteCostItem"
                    onClick={event => {
                      event.preventDefault();
                      this.removeCostItem(index);
                    }}
                    compact
                    size="tiny"
                    basic
                    circular
                    color="red"
                    icon="remove"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Body>
          <Table.Row data-component="costItem">
            <Table.HeaderCell
              style={{
                backgroundColor: "#F9FAFB",
                borderTop: "1px solid rgba(34, 36, 38, 0.1)",
                borderBottom: "1px solid rgba(34, 36, 38, 0.1)"
              }}
            >
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.selection_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.selection_costs.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell
              className="field"
              textAlign="right"
              style={{
                backgroundColor: "#F9FAFB",
                borderTop: "1px solid rgba(34, 36, 38, 0.1)",
                borderBottom: "1px solid rgba(34, 36, 38, 0.1)"
              }}
            >
              <div id="subtotal-cost" className="ui right labeled fluid input">
                <input
                  name="subtotal-cost"
                  readOnly
                  value={formatNumber(totalCostNet)}
                />
                <Label>
                  <CurrencySign />
                </Label>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                backgroundColor: "#F9FAFB",
                borderTop: "1px solid rgba(34, 36, 38, 0.1)",
                borderBottom: "1px solid rgba(34, 36, 38, 0.1)"
              }}
            />
          </Table.Row>
        </Table.Body>
        <Table.Body>
          <Table.Row data-component="costItem">
            <Table.Cell>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.default_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.default_costs.label"
              />
            </Table.Cell>
            <Table.Cell className="field">
              <CurrencyInput
                id="budget"
                fluid
                defaultValue={-formModel.getCostBudget()}
                readOnly
              />
            </Table.Cell>
            <Table.Cell textAlign="right" />
          </Table.Row>
        </Table.Body>
        <Table.Footer>
          <Table.Row data-component="costItem">
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.total_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.total_costs.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="field" textAlign="right">
              <div id="total-cost" className="ui right labeled fluid input">
                <input
                  name="total-cost"
                  readOnly
                  value={formatNumber(totalCostNet - formModel.getCostBudget())}
                />
                <Label>
                  <CurrencySign />
                </Label>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  renderDefaultCostsFlat(formModel, costs, tradesDictionary) {
    let totalCostNet = 0;
    return (
      <Table
        stackable
        size="small"
        className="margin top medium"
        data-component="costItemTable"
      >
        <Table.Body>
          {costs.map((item, index) => {
            const itemCost = item.cost;
            totalCostNet += itemCost;

            return (
              <Table.Row
                data-component="costItem"
                key={`${item.id}-${item.trade}`}
              >
                <Table.Cell>
                  {
                    (tradesDictionary[item.trade] || { label: item.trade })
                      .label
                  }
                </Table.Cell>
                <Table.Cell className="field">
                  <CurrencyInput
                    id={`${item.trade}-cost`}
                    name={`costs_attributes.[${item.id}].cost`}
                    fluid
                    defaultValue={item.cost}
                    onBlur={(event, data) =>
                      this.updateCostItem(data.value, index)
                    }
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    as="div"
                    data-component="deleteCostItem"
                    onClick={event => {
                      event.preventDefault();
                      this.removeCostItem(index);
                    }}
                    compact
                    size="tiny"
                    basic
                    circular
                    color="red"
                    icon="remove"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row data-component="costItem">
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
                defaultMessage="roomBook.lineItems.attributes.costs.net_sum_of_costs.label"
              />
            </Table.HeaderCell>
            <Table.HeaderCell className="field" textAlign="right">
              <div id="total-cost" className="ui right labeled fluid input">
                <input
                  name="total-cost"
                  readOnly
                  value={formatNumber(totalCostNet)}
                />
                <Label>
                  <CurrencySign />
                </Label>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  render() {
    const { i18n, trades, costs } = this.props;
    const tradesDictionary = keyBy(trades, "id");
    const usedTrades = costs.map(cost => cost.trade);
    const tradeOptions = trades.map(tradeOption => {
      const disabled =
        usedTrades.filter(usedTrade => usedTrade === tradeOption.id).length > 0;

      return {
        key: tradeOption.id,
        disabled,
        value: tradeOption.id,
        text: tradeOption.label
      };
    });

    return (
      <>
        <div className="margin bottom medium">
          <Dropdown
            id="tradesSelector"
            text={i18n["roomBook.actions.addCostItem"]}
            data-component="tradesSelector"
            compact
            labeled
            button
            search
            selection
            icon="plus"
            className="icon"
            noResultsMessage={i18n["meta.message.noResults"]}
            options={tradeOptions}
            value={-1} // allows re-selection of the same Item we do not need to store any info here
            onChange={(e, d) => {
              this.addCostItem({
                id: null,
                cost: 0,
                excess_cost: null,
                trade: d.value
              });
            }}
          />
        </div>

        <div className="tableFieldset">
          {costs && this.renderTable(costs, tradesDictionary)}
        </div>
      </>
    );
  }
}

LineItemCosts.propTypes = {
  i18n: I18nShape.isRequired,
  trades: arrayOf(shape({ id: string, label: string })).isRequired,
  costs: arrayOf(
    shape({ id: number, trade: string, cost: number, excess_cost: number })
  ).isRequired,
  mode: oneOf(["defaultCosts", "selectedCosts"]).isRequired,
  formModel: instanceOf(SubLineItemFormModel).isRequired,
  onChange: func.isRequired
};

export default LineItemCosts;
