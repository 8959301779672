/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React, { useState, useCallback } from "react";
import { Container, Button, Icon, Table, Tab } from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import { Field } from "formik";
import EditPageDialog from "./content-page-elements/EditPageDialog";
import AddNewPageDialog from "./content-page-elements/AddNewPageDialog";
import TemplatesLoader from "./content-page-elements/TemplatesLoader";

const Tabs = props => {
  const { fieldName } = props;
  const panes = [
    {
      menuItem: "Visuell",
      render: () => (
        <Tab.Pane>
          <WebsiteBuilder {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Json",
      render: () => (
        <Tab.Pane>
          <Field
            id={fieldName}
            name={fieldName}
            component={SemanticFormikJsonEditor}
            label="JSON content"
          />
        </Tab.Pane>
      )
    }
  ];
  return <Tab panes={panes} />;
};

Tabs.propTypes = {
  fieldName: PropTypes.string.isRequired
};

const WebsiteBuilder = ({ fieldName, value, setFieldValue }) => {
  const [jsonConfig, setJsonConfig] = useState(value);

  React.useEffect(() => {
    setJsonConfig(value);
  }, [value]);

  const setTemplate = val => {
    if (val.template) setFieldValue(fieldName, val.template);
  };

  const addNewPage = element => {
    const newJsonConfig = jsonConfig ? [...jsonConfig] : [];
    newJsonConfig.push({ ...element, id: uuid(), type: element.type });
    setFieldValue(fieldName, newJsonConfig);
  };

  const handlePageUpdate = values => {
    const newJsonConfig = [...jsonConfig];
    const index = newJsonConfig.findIndex(item => item.id === values.id);
    newJsonConfig[index] = values;
    setFieldValue(fieldName, newJsonConfig);
  };

  const renderItem = useCallback(
    item => {
      return (
        <Table.Row>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>{item.type}</Table.Cell>
          <Table.Cell>
            <EditPageDialog
              model={item}
              trigger={<Button icon="edit" type="button" />}
              value={item?.items || []}
              setFieldValue={setFieldValue}
              handlePageUpdate={handlePageUpdate}
              fieldName={fieldName}
              modelId={item.id}
              jsonConfig={jsonConfig}
            />
          </Table.Cell>
        </Table.Row>
      );
    },
    [jsonConfig]
  );

  return (
    <Container>
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.title" />
            </Table.HeaderCell>
            <Table.HeaderCell width={9}>
              <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.type" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {jsonConfig && jsonConfig?.map(item => renderItem(item))}
        </Table.Body>
      </Table>
      <AddNewPageDialog
        onAddPage={addNewPage}
        trigger={
          <Button style={{ margin: "20px 0" }} fluid type="button">
            <Icon link name="plus" />{" "}
            <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.addPage" />
          </Button>
        }
      />
      <If condition={!jsonConfig?.length}>
        <TemplatesLoader setTemplate={setTemplate} />
      </If>
    </Container>
  );
};

WebsiteBuilder.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  setFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired
};

WebsiteBuilder.defaultProps = {
  value: null
};

export default Tabs;
