import { createSelector } from "reselect";
import { get } from "lodash";

export const getI18N = state => state.i18n;
export const getAccountData = state => state.account;
export const getFilter = state => state.filter;
export const getPageContent = state => get(state, "pageContent", {});
export const getNotifications = state =>
  get(state, "notifications", { notifications: 0, messages: 0 });
export const getState = state => state;
export const getProps = (_, props) => props;
export const getRouterParams = createSelector(
  [getProps],
  props => props.params
);
export const getPageName = (_, props) => get(props, "router.routes[1].name");

export const getUser = createSelector([getAccountData], accountData =>
  get(accountData, "user")
);

export const getUserRole = createSelector([getUser], user => {
  return get(user, "group", "buyer");
});
