import React, { useState, useCallback, useMemo, useRef } from "react";
import PropTypes, { node } from "prop-types";
import { Grid, Button, Modal, Header, Label } from "semantic-ui-react";
import { Formik } from "formik";
import { Form, Select, Input } from "formik-semantic-ui-react";
import { cloneDeep } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import SemanticFormikFloatInput from "shared/components/forms/SemanticFormikFloatInput";
import { getTrades, getContractors } from "shared/selectors";
import SemanticFormikDateInput from "shared/components/forms/SemanticFormikDateInput";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import { ContractorAssignmentRulesResource } from "../../actions/contractorActions";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

const staticOptions = [{ key: "all", value: "all", text: "Alle" }];

const emptyModel = {
  cash_discount_in_percent: 0,
  contract_date: null,
  contract_negotiation_date: null,
  contract_number: "",
  contractor_id: "",
  external_id: "",
  profit_margin: 0,
  section_id: "",
  trade: "",
  unit_id: ""
};

const App = ({ button, model, projectId }) => {
  const intl = useIntl();
  const refResetForm = useRef();
  const dispatch = useDispatch();
  const contractorAssignmentRulesResource = new ContractorAssignmentRulesResource(
    dispatch,
    projectId
  );

  const [isOpen, setOpen] = useState(false);
  const [tradeId, setTradeId] = useState(model?.trade || null);
  const [hasSubmitCompleted, setHasSubmitCompleted] = useState(false);
  const { sections, units } = useSelector(state => state.pageContent);
  const contractors = useSelector(getContractors);
  const tradesLabel = useSelector(getTrades);
  const initialFormValue = useMemo(
    () => cloneDeep({ ...emptyModel, ...model }),
    [model]
  );

  const tradesLabelOptions = useMemo(() => {
    const options =
      tradesLabel?.map(tradeLabel => {
        return {
          key: tradeLabel.id,
          value: tradeLabel.id,
          text: tradeLabel.label
        };
      }) || [];

    // sort options by text ascending
    options.sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
    return options;
  }, [tradesLabel]);

  // group units by section_id
  const unitsBySectionOptions = useMemo(() => {
    const unitOptions =
      units?.reduce((acc, unit) => {
        const { section_id } = unit;
        if (!acc[section_id]) {
          acc[section_id] = [];
        }

        acc[section_id].push({
          key: unit.id,
          value: unit.id,
          text: unit.display_name
        });

        return acc;
      }, {}) || {};

    const newUnitOptions = Object.keys(unitOptions).reduce((acc, key) => {
      acc[key] = staticOptions.concat(unitOptions[key]);
      return acc;
    }, {});
    return newUnitOptions;
  }, [units]);

  // get contractor and convert to options
  const contractorOptions = useMemo(() => {
    const filteredContractors = tradeId
      ? contractors.filter(contractor => {
          return contractor.trades.includes(tradeId);
        })
      : contractors;

    return filteredContractors.map(contractor => {
      return {
        key: contractor.id,
        value: contractor.id,
        text: contractor.name
      };
    });
  }, [contractors, tradeId]);

  // get sections from pageContent and convert them to dropdown values
  const sectionsOptions = useMemo(() => {
    const allSections =
      sections?.map(({ id, name }) => {
        return {
          key: id,
          value: id,
          text: name
        };
      }) || [];

    return [...staticOptions, ...allSections];
  }, [sections]);

  const clearForm = () => {
    const closingCreateDialog = !model.id;
    if (closingCreateDialog && typeof refResetForm.current === "function")
      refResetForm.current();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onSubmit = useCallback(
    values => {
      const { section_id, unit_id, ...rest } = values;
      const payload = {
        ...rest,
        section_id: section_id === "all" ? null : section_id,
        unit_id: unit_id === "all" ? null : unit_id
      };

      return contractorAssignmentRulesResource
        .save({ ...payload })
        .then(() => contractorAssignmentRulesResource.fetchAll())
        .then(handleClose);
    },
    [model, handleClose]
  );

  const renderDeleteButton = (id, isSubmitting) => {
    const deleteButton = (
      <Button
        floated="left"
        id="delete"
        color="red"
        basic
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
        content={intl.formatMessage({
          id: "meta.actions.remove"
        })}
        loading={isSubmitting}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          return contractorAssignmentRulesResource
            .remove(model.id)
            .then(() => contractorAssignmentRulesResource.fetchAll())
            .then(handleClose)
            .then(closeConfirmation)
            .catch(() => {
              new Growl(dispatch).error(
                "message.error.title",
                "meta.confirmations.changesNotSaved"
              );
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="configuratorDesign.removeConfirmation.title"
        message="configuratorDesign.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      data-component="UnitProspectLisStatustDialog"
      enableReinitialize
      validate={values => {
        const errors = {};
        if (!values.contractor_id) {
          errors.contractor_id = intl.formatMessage({
            id: "contractorAssignmentRule.dialog.attributes.field_required"
          });
        }
        if (!values.trade) {
          errors.trade = intl.formatMessage({
            id: "contractorAssignmentRule.dialog.attributes.field_required"
          });
        }

        return errors;
      }}
      initialValues={{
        ...initialFormValue,
        section_id: initialFormValue.section_id || "all",
        unit_id: initialFormValue.unit_id || "all"
      }}
    >
      {({
        isSubmitting,
        resetForm,
        handleSubmit,
        values,
        setFieldValue,
        errors
      }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            data-component="UnitVariableTypeDialog"
            size="small"
          >
            <Modal.Header>
              <FormattedMessage
                id="contractor.actions.assign"
                defaultMessage="contractor.actions.assign"
              />
            </Modal.Header>
            <Modal.Content scrolling>
              <Form id="unitProspectStatusForm">
                <Grid>
                  <Grid.Column width={16}>
                    <Select
                      id="trade"
                      search
                      fluid
                      name="trade"
                      options={tradesLabelOptions}
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.tradeId.label"
                      })}
                      onChange={(e, { value }) => {
                        setTradeId(value);
                        setFieldValue("contractor_id", null);
                        setHasSubmitCompleted(false);
                      }}
                    />
                    {hasSubmitCompleted && errors?.trade && (
                      <Label
                        basic
                        color="red"
                        pointing
                        style={{ marginTop: "-10px" }}
                      >
                        {errors.trade}
                      </Label>
                    )}
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Select
                      id="contractor"
                      search
                      fluid
                      name="contractor_id"
                      options={contractorOptions}
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractorId.label"
                      })}
                      onChange={() => {
                        setHasSubmitCompleted(false);
                      }}
                    />
                    {hasSubmitCompleted && errors?.contractor_id && (
                      <Label
                        basic
                        color="red"
                        pointing
                        style={{ marginTop: "-10px" }}
                      >
                        {errors.contractor_id}
                      </Label>
                    )}
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Select
                      fluid
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.sectionId.label"
                      })}
                      name="section_id"
                      id="sectionId"
                      options={sectionsOptions}
                      value={values.section_id}
                      onChange={(e, { value }) => {
                        if (value === "all") {
                          setFieldValue("unit_id", null);
                          return;
                        }
                        setFieldValue("unit_id", "all");
                      }}
                    />
                  </Grid.Column>
                  {values.section_id &&
                    values.section_id !== "all" &&
                    values.section_id !== null && (
                      <Grid.Column width={8}>
                        <Select
                          fluid
                          name="unit_id"
                          id="unitId"
                          options={unitsBySectionOptions[values.section_id]}
                          label={intl.formatMessage({
                            id:
                              "contractorAssignmentRule.dialog.attributes.unitId.label"
                          })}
                        />
                      </Grid.Column>
                    )}
                </Grid>
                <Grid>
                  <Grid.Column width={16}>
                    <Header as="h4">
                      <FormattedMessage
                        id="contractorAssignmentRule.dialog.attributes.details"
                        defaultMessage="Vertragsnummer"
                      />
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      id="contract_number"
                      errorPrompt
                      name="contract_number"
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractNumber.label"
                      })}
                      placeholder={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractNumber.placeholder"
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SemanticFormikDateInput
                      id="contract_date"
                      name="contract_date"
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractDate.label"
                      })}
                      placeholder={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractDate.placeholder"
                      })}
                      nullable
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <SemanticFormikDateInput
                      id="contract_negotiation_date"
                      errorPrompt
                      name="contract_negotiation_date"
                      label={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractNegotiationDate.label"
                      })}
                      placeholder={intl.formatMessage({
                        id:
                          "contractorAssignmentRule.dialog.attributes.contractNegotiationDate.placeholder"
                      })}
                      nullable
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <FeatureToggleActive featureToggleName="show_external_id">
                      <Input
                        id="external_id"
                        errorPrompt
                        name="external_id"
                        label={intl.formatMessage({
                          id:
                            "contractorAssignmentRule.dialog.attributes.externalId.label"
                        })}
                        placeholder={intl.formatMessage({
                          id:
                            "contractorAssignmentRule.dialog.attributes.externalId.placeholder"
                        })}
                      />
                    </FeatureToggleActive>
                  </Grid.Column>
                  <FeatureToggleActive featureToggleName="contractor_profit_margin">
                    <Grid.Column width={8}>
                      <SemanticFormikFloatInput
                        id="cash_discount_in_percent"
                        errorPrompt
                        name="cash_discount_in_percent"
                        label={intl.formatMessage({
                          id:
                            "contractorAssignmentRule.dialog.attributes.cashDiscountInPercent.label"
                        })}
                        placeholder="0"
                        nullable
                        inputLabel="%"
                        inputLabelPosition="right"
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <SemanticFormikFloatInput
                        id="profit_margin"
                        errorPrompt
                        name="profit_margin"
                        label={intl.formatMessage({
                          id:
                            "contractorAssignmentRule.dialog.attributes.profitMargin.label"
                        })}
                        nullable
                        inputLabel="%"
                        inputLabelPosition="right"
                      />
                    </Grid.Column>
                  </FeatureToggleActive>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <If condition={!!model.id}>
                {renderDeleteButton(model.id, isSubmitting)}
              </If>
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({
                  id: "meta.actions.cancel"
                })}
                onClick={handleClose}
              />
              <Button
                primary
                id="submit"
                type="submit"
                content={intl.formatMessage({
                  id: "meta.actions.save"
                })}
                loading={isSubmitting}
                onClick={() => {
                  setHasSubmitCompleted(true);
                  handleSubmit();
                }}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

App.propTypes = {
  button: node.isRequired,
  model: PropTypes.shape({
    id: PropTypes.number,
    trade: PropTypes.string
  }).isRequired,
  projectId: PropTypes.string.isRequired
};

export default App;
