import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Modal, Form, Button, Icon } from "semantic-ui-react";

import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";

/**
 * Dialog used to create a new activity
 */
class CartDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    cart: PropTypes.object,
    button: PropTypes.object,
    open: PropTypes.bool,
    processDefinitions: PropTypes.array,
    handleSave: PropTypes.func,
    handleClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      isLoading: false,
      cart: props.cart
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleOpen = () => {
    this.setState({
      open: true,
      cart: this.props.cart
    });
  };

  handleClose = () => {
    if (!this.unmounted) {
      this.setState({
        open: false,
        isLoading: false
      });
    }
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  onSaveFn(status) {
    return values => {
      const { handleSave } = this.props;
      values.status = status;
      this.setState({ isLoading: true }, () => {
        handleSave(values).then(this.handleClose, this.handleClose);
      });
    };
  }

  getFormFactory(cart) {
    return new FormDefinition({
      fields: [
        {
          id: "name",
          label: "roomBook.cart.attributes.title.label",
          placeholder: "roomBook.cart.attributes.title.placeholder",
          message: "roomBook.cart.attributes.title.error",
          rule: "isRequired",
          autoFocus: true
        },
        {
          id: "process_type",
          label: "roomBook.cart.attributes.process_type.label",
          rule: "isRequired"
        },
        {
          id: "unit_id",
          label: "roomBook.cart.attributes.unit.label",
          rule: cart.unit_id ? "isRequired" : null
        },
        {
          id: "section_id",
          label: "roomBook.cart.attributes.section.label",
          rule: cart.section_id ? "isRequired" : null
        }
      ]
    });
  }

  render() {
    const { cart } = this.state;
    const { i18n, processDefinitions, button } = this.props;
    const form = this.getFormFactory(cart).create(cart, i18n, {
      onChange: cart => {
        this.setState({ cart });
      }
    });

    const units = get(this.props, "units", []);
    const sections = get(this.props, "sections", []);

    const currentProcess = processDefinitions.filter(proc => {
      return proc.id === cart.process_type;
    })[0];

    form.fields.process_type.props.options = processDefinitions.map(pd => {
      return {
        key: pd.id,
        value: pd.id,
        text: pd.getName()
      };
    });

    form.fields.unit_id.props.search = true;
    form.fields.unit_id.props.options = units.map(unit => {
      return {
        key: unit.id,
        value: unit.id,
        text: unit.label
      };
    });

    form.fields.section_id.props.search = true;
    form.fields.section_id.props.options = sections.map(section => {
      return {
        key: section.id,
        value: section.id,
        text: section.label
      };
    });

    return (
      <Modal
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          {processDefinitions.length ? (
            <FormattedMessage
              id="activity.actions.add_with_name"
              values={{ name: processDefinitions[0].getName() }}
            />
          ) : (
            <FormattedMessage id="activity.actions.add" />
          )}
        </Modal.Header>
        <Modal.Content>
          <Form
            id="cart"
            onSubmit={form.handleSubmit(this.onSaveFn(cart.status).bind(this))}
            data-component="cartForm"
          >
            {sections.length > 1 && (
              <Form.Field width="16">
                <Field component="Select" {...form.fields.section_id} />
              </Form.Field>
            )}
            {units.length > 1 && (
              <Form.Field width="16">
                <Field component="Select" {...form.fields.unit_id} />
              </Form.Field>
            )}
            {processDefinitions.length > 1 && (
              <Form.Field width="16">
                <Field component="Select" {...form.fields.process_type} />
              </Form.Field>
            )}
            <Form.Field width="16">
              <Field component="Input" {...form.fields.name} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {this.renderButtons(form, currentProcess)}
        </Modal.Actions>
      </Modal>
    );
  }

  renderButtons(form, processDefinition) {
    const { i18n } = this.props;
    const states = processDefinition.getProcessStartStates();

    if (states.length > 1) {
      const buttons = states.map(state => {
        return (
          <Button
            basic
            key={`submit-${state.id}`}
            id={`submit-${state.id}`}
            data-form="activity"
            positive
            content={`${state.label}`}
            onClick={form.handleSubmit(this.onSaveFn(state.id).bind(this))}
            loading={this.state.isLoading}
            labelPosition="left"
            label={{
              basic: false,
              color: "green",
              content: <Icon name="play" style={{ marginRight: "0px" }} />
            }}
          />
        );
      });

      return (
        <div>
          <span style={{ marginRight: "10px" }}>
            <b>Beginnen mit:</b>
          </span>
          {buttons}
        </div>
      );
    }
    if (states.length == 1) {
      return (
        <Button
          basic
          id="submit"
          data-form="activity"
          positive
          content={i18n["meta.actions.start"]}
          onClick={form.handleSubmit(this.onSaveFn(states[0].id).bind(this))}
          loading={this.state.isLoading}
          labelPosition="left"
          label={{
            basic: false,
            color: "green",
            content: <Icon name="play" style={{ marginRight: "0px" }} />
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return { ...props, i18n: state.i18n };
};

const mapDispatchToProps = (dispatch, props) => {
  return { ...props };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDialog);
