import PropTypes from "prop-types";
import React from "react";
import { groupBy, keyBy, sortBy, uniqBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { Header, Icon, Accordion, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { getTrades } from "shared/selectors";

class TradesAndContractors extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    trades: PropTypes.array,
    contractors: PropTypes.array,
    contractorAssignments: PropTypes.array
  };

  render() {
    const { contractors, contractorAssignments, trades } = this.props;
    const tradesDictionary = keyBy(trades, "id");
    const contractorDictionary = keyBy(contractors, "id");
    const contractorGroups = Object.entries(
      groupBy(contractorAssignments, "contractor_id")
    );
    const sortedContractorGroups = sortBy(
      contractorGroups,
      ([contractor_id]) => contractorDictionary[contractor_id].name
    );

    const contractorAccordion = [];

    for (const [
      contractor_id,
      contractorAssignments
    ] of sortedContractorGroups) {
      const contractor = contractorDictionary[contractor_id];
      const trades = uniqBy(
        contractorAssignments,
        contractorAssignment => contractorAssignment.trade
      ).map(
        contractorAssignment =>
          tradesDictionary[contractorAssignment.trade] || {
            id: contractorAssignment.trade,
            label: contractorAssignment.trade
          }
      );

      const details = [];
      if (contractor.contact_person)
        details.push(
          <p key="user" className="user">
            <Icon name="user" /> {contractor.contact_person}
          </p>
        );
      if (contractor.email)
        details.push(
          <p key="email" className="email">
            <Icon name="mail" /> {contractor.email}
          </p>
        );
      if (contractor.phone)
        details.push(
          <p key="phone" className="phone">
            <Icon name="phone" /> {contractor.phone}
          </p>
        );
      if (contractor.fax)
        details.push(
          <p key="fax" className="fax">
            <Icon name="fax" /> {contractor.fax}
          </p>
        );

      contractorAccordion.push({
        key: `accordion-panel-${contractor.id}`,
        title: {
          key: `title-${contractor.id}`,
          "data-model": "contractor",
          content: [
            <Header size="tiny" key="trade-header">
              <Icon name="shipping" size="small" />
              <Header.Content>
                {contractor.name}
                <Header.Subheader>
                  {TradesAndContractors.renderTrades(contractor, trades)}
                </Header.Subheader>
              </Header.Content>
            </Header>
          ]
        },
        content: {
          className: "contact",
          key: `content-${contractor.id}`,
          content: details
        }
      });
    }

    if (contractorAccordion.length === 0) {
      contractorAccordion.push({
        key: "accordion-panel-missing",
        title: {
          key: "title-missing",
          content: (
            <FormattedMessage
              id="contractor.message.noneAssigned"
              defaultText="contractor.message.noneAssigned"
              key="noneAssigned"
            />
          )
        }
      });
    }

    return <Accordion panels={contractorAccordion} />;
  }

  static renderTrades(contractor, trades) {
    return trades.map((trade, i) => (
      <Label
        key={`${trade.id}-${i}`}
        content={trade.label}
        data-trade={trade.id}
        size="mini"
      />
    ));
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  trades: getTrades(state),
  contractorAssignments: state.pageContent.contractor_assignments,
  contractors: state.pageContent.contractors
});

export default connect(mapStateToProps)(TradesAndContractors);
