import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import * as Yup from "yup";
import { Button, Header, Input, Message, Modal } from "semantic-ui-react";
import { func, number } from "prop-types";
import { If } from "../../../../shared/components/elements/Conditions";
import Growl from "../../../actions/growlActions";
import ProjectEmailsResource from "../../../actions/projectEmailsActions";
import ShareSetupInstructionsDialog from "./ShareSetupInstructionsDialog";

const EmailIntegrationWizard = ({ projectId, loadData }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({});
  const [error, setError] = useState("");

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "projectEmail.wizard.validation.invalidEmail"
        })
      )
      .required(
        intl.formatMessage({
          id: "projectEmail.wizard.validation.emailRequired"
        })
      )
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError("");
    setOpen(false);
    setInstructionsOpen(false);
    setModel({});
  };

  const handleChange = (_, { name, value }) => {
    setModel(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const saveModel = {
      ...model,
      project_id: projectId
    };

    const isValid = await schema
      .validate({ ...saveModel })
      .catch(err => setError(err.message));
    if (isValid) {
      setError("");
      setLoading(true);
      new ProjectEmailsResource(dispatch)
        .save(saveModel)
        .then(({ data: { project_email } }) => {
          setLoading(false);
          setModel(project_email);
          loadData();
          setInstructionsOpen(true);
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saved"
          );
        })
        .catch(() => {
          setLoading(false);
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    }
  };

  return (
    <Modal
      closeIcon
      trigger={
        <Button basic>
          <FormattedMessage id="projectEmail.list.buttons.add" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>
        <FormattedMessage id="projectEmail.wizard.title" />
      </Modal.Header>
      <Modal.Content>
        <div>
          <Message>
            <Message.Header>
              <FormattedMessage id="projectEmail.wizard.message.title" />
            </Message.Header>
            <p>
              <FormattedMessage id="projectEmail.wizard.message.body" />
            </p>
          </Message>
          <If condition={!!error}>
            <Message negative>
              <Message.Content>{error}</Message.Content>
            </Message>
          </If>
          <div key="email" style={{ margin: "15px 0" }}>
            <Header as="h5">
              <FormattedMessage id="projectEmail.wizard.labels.email" />
            </Header>
            <Input
              fluid
              name="email"
              onChange={handleChange}
              value={model.email}
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <ShareSetupInstructionsDialog
          model={model}
          open={instructionsOpen}
          onSend={handleClose}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={loading}
          loading={loading}
          onClick={handleSave}
        >
          <FormattedMessage id="meta.actions.add" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

EmailIntegrationWizard.propTypes = {
  projectId: number.isRequired,
  loadData: func.isRequired
};

export default EmailIntegrationWizard;
