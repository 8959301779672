import Resources from "shared/actions/resourcesActions";

// eslint-disable-next-line import/prefer-default-export
export class ProjectFilesResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_files",
      `/api/v1/projects/${projectId}/project_files`
    );
  }
}
