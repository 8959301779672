import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import ProductInfoItem from "../../components/product/ProductInfoItem";

const _getPrice = (model, productGroup) => {
  // calculate price by quantity
  let { price } = productGroup;

  if (model.price_strategy !== "flat") {
    price = model.quantity * price;
  }

  // subtract discount
  price -= model.discount;
  price += model.surcharge;

  // calculate default price by default quantity
  let defaultPrice = model.default_price;
  if (model.price_strategy !== "flat") {
    defaultPrice = model.default_quantity * defaultPrice;
  }
  if (model.optional) {
    defaultPrice = 0;
  }
  // subtract default price
  return price - defaultPrice;
};

const renderPrice = (model, productGroup, hidePrice) => {
  if (hidePrice) {
    return null;
  }
  if (productGroup.on_request) {
    return (
      <div className="right floated element priceTag">
        <FormattedMessage
          id="product_price.attributes.on_request.label"
          defaultMessage="product_price.attributes.on_request.label"
        />
      </div>
    );
  }
  const price = _getPrice(model, productGroup);
  return (
    <div
      data-attr="total"
      id="product-price"
      className="right floated element priceTag"
    >
      <FormatCurrency amount={price} />
    </div>
  );
};

const renderProductOptions = (
  model,
  products,
  customProduct = false,
  hidePrice = false,
  showDeprecated = false,
  deprecatedsIsSelected = false
) => {
  if (!model || !products) {
    return [];
  }

  const hasDeprecated =
    !deprecatedsIsSelected && products.some(p => p.deprecated);
  const productsList =
    !hasDeprecated || showDeprecated
      ? products
      : products.filter(p => !p.deprecated);

  const options = [];
  productsList.map(product => {
    const standardSelection =
      parseInt(model.default_product_id, 10) === product.id;
    // eslint-disable-next-line react/no-multi-comp
    const infoItem = renderPriceFn => {
      return (
        <ProductInfoItem
          product={product}
          model={model}
          hidePrice={hidePrice}
          renderPrice={renderPriceFn}
          mode="detailed-reverse"
          standardSelection={standardSelection}
          className="productOption"
        />
      );
    };
    options.push({
      key: `product-option-${product.id}`,
      value: product.id,
      text: infoItem(
        () =>
          !hidePrice && (
            <div className="price">
              <span data-attr="total">
                <FormatCurrency amount={model.total} />
              </span>
            </div>
          )
      ),
      content: infoItem(() => renderPrice(model, product.group, hidePrice))
    });
  });

  if (hasDeprecated) {
    const message = () => {
      return (
        <div>
          <strong>
            <Icon name="idea" />
            <FormattedMessage
              id={`roomBook.actions.${
                showDeprecated ? "hideDeprecated" : "showDeprecated"
              }`}
              defaultMessage={
                showDeprecated ? "Hide deprecated" : "Show deprecated"
              }
            />
          </strong>
        </div>
      );
    };

    options.push({
      key: "product-option-showDeprecated",
      value: -1,
      className: "dividing sticky bottom",
      text: message(),
      content: message()
    });
  }

  if (customProduct) {
    // eslint-disable-next-line react/no-multi-comp
    const message = () => {
      return (
        <div>
          <strong>
            <Icon name="idea" />
            <FormattedMessage
              id="roomBook.actions.addCustomProduct"
              defaultMessage="roomBook.actions.addCustomProduct"
            />
          </strong>
        </div>
      );
    };

    options.push({
      key: "product-option-customProduct",
      value: null,
      className: "dividing sticky bottom",
      text: message(),
      content: message()
    });
  }

  return options;
};

export default renderProductOptions;
