import { cloneDeep } from "lodash";

const ROOM_BOOK_META = {
  line_item: {
    parent: "section"
  },
  sub_line_item: {
    parent: "line_item"
  },
  section: {
    parent: "parent"
  }
};

export default class RoomBookUpdate {
  constructor(state, events) {
    this.state = state;
    this.events = events;
  }

  apply() {
    return this.events.reduce((state, event) => {
      switch (event.type) {
        case "room_book_updated":
          return this.applyRoomBookUpdate(state, event);
        case "section_deleted":
          return this.applySectionDelete(state, event);
        case "line_item_created":
          return this.applyLineItemCreate(state, event);
        case "line_item_updated":
          return this.applyUpdate(state, "line_item", event);
        case "line_item_deleted":
          return this.applyDelete(state, "line_item", event);
        case "line_item_moved":
          return this.applyMove(state, "line_item", event);
        case "line_item_parent_changed":
          return this.applyLineItemParentChanged(state, event);
        case "sub_line_item_created":
          return this.applySubLineItemCreate(state, event);
        case "sub_line_item_updated":
          return this.applyUpdate(state, "sub_line_item", event);
        case "sub_line_item_deleted":
          return this.applyDelete(state, "sub_line_item", event);
        case "sub_line_item_moved":
          return this.applyMove(state, "sub_line_item", event);
        default:
          return state;
      }
    }, this.state);
  }

  applyLineItemCreate(state, event) {
    return this.updateRoomBook(state, "section", (section, cb) => {
      if (section.id === event.payload.section_id) {
        section.line_items.push(event.payload);
        cb(section);
      }
    });
  }

  applySubLineItemCreate(state, event) {
    return this.updateRoomBook(state, "line_item", (lineItem, cb) => {
      if (lineItem.id === event.payload.line_item_id) {
        lineItem.sub_line_items.push(event.payload);
        cb(lineItem);
      }
    });
  }

  applyRoomBookUpdate(state, event) {
    return this.updateRoomBook(state, "room_book", (roomBook, cb) => {
      cb(event.payload);
    });
  }

  applyUpdate(state, entity_type, event) {
    return this.updateRoomBook(state, entity_type, (item, cb) => {
      if (item.id === event.payload.id) {
        cb(event.payload);
      }
    });
  }

  applySectionDelete(state, event) {
    return ["room_book", "section"].reduce((s, parentType) => {
      return this.updateRoomBook(s, parentType, (parent, cb) => {
        parent.sections = parent.sections.filter(item => {
          return item.id !== event.payload.id;
        });
        cb(parent);
      });
    }, state);
  }

  applyDelete(state, entity_type, event) {
    const meta = ROOM_BOOK_META[entity_type];
    return this.updateRoomBook(state, meta.parent, (parent, cb) => {
      parent[`${entity_type}s`] = parent[`${entity_type}s`].filter(item => {
        return item.id !== event.payload.id;
      });
      // Must be better!
      cb(parent);
    });
  }

  move(array, elementId, newPosition) {
    const item = array.filter(e => e.id === elementId)[0];
    const oldPosition = array.indexOf(item);
    array.splice(oldPosition, 1);
    array.splice(newPosition - 1, 0, item);
  }

  applyMove(state, entity_type, event) {
    const meta = ROOM_BOOK_META[entity_type];
    return this.updateRoomBook(state, meta.parent, (parent, cb) => {
      const element = event.payload;
      if (parent.id === element[`${meta.parent}_id`]) {
        this.move(parent[`${entity_type}s`], element.id, element.position);
        cb(parent);
      }
    });
  }

  applyLineItemParentChanged(state, event) {
    const {
      payload: { element, newSectionId, oldSectionId }
    } = event;
    return this.updateRoomBook(state, "section", (section, cb) => {
      if (section.id === newSectionId) {
        element.section_id = newSectionId;
        section.line_items.push(element);

        cb(section);
      }
      if (section.id === oldSectionId) {
        const item = section.line_items.filter(e => e.id === element.id)[0];
        const oldPosition = section.line_items.indexOf(item);
        section.line_items.splice(oldPosition, 1);

        cb(section);
      }
    });
  }

  touch(path) {
    path.forEach(element => {
      element.updated_at = new Date().toISOString();
    });
  }

  updateRoomBook(state, elementType, func) {
    if (elementType == "room_book") {
      func(state.room_book, updated => {
        state.room_book = updated;
      });
      return state;
    }
    if (!state.room_book) return state;
    const room_book = cloneDeep(state.room_book);

    room_book.sections.forEach((primarySection, idx) => {
      if (elementType === "section") {
        func(primarySection, updated => {
          room_book.sections[idx] = updated;
          this.touch([room_book, primarySection]);
        });
      }
      primarySection.sections.forEach((secondarySection, idx) => {
        if (elementType === "section") {
          func(secondarySection, updated => {
            primarySection.sections[idx] = updated;
            this.touch([room_book, primarySection, secondarySection]);
          });
        }
        secondarySection.line_items.forEach((lineItem, idx) => {
          if (elementType === "line_item") {
            func(lineItem, updated => {
              secondarySection.line_items[idx] = updated;
              this.touch([
                room_book,
                primarySection,
                secondarySection,
                lineItem
              ]);
            });
          }
          lineItem.sub_line_items.forEach((subLineItem, idx) => {
            if (elementType === "sub_line_item") {
              func(subLineItem, updated => {
                lineItem.sub_line_items[idx] = updated;
                this.touch([
                  room_book,
                  primarySection,
                  secondarySection,
                  lineItem,
                  subLineItem
                ]);
              });
            }
          });
        });
      });
    });

    return { ...state, room_book: this.recalculate(room_book) };
  }

  recalculate(roomBook) {
    let roomBookTotal = 0.0;
    roomBook.sections.forEach((primarySection, idx) => {
      let primarySectionTotal = 0.0;
      primarySection.sections.forEach((secondarySection, idx) => {
        let secondarySectionTotal = 0.0;
        secondarySection.line_items.forEach((lineItem, idx) => {
          let lineItemTotal = 0.0;
          let lineItemTotalBase = -lineItem.replacement_total;

          lineItem.sub_line_items.forEach((subLineItem, idx) => {
            lineItem.sub_line_items[idx].position = idx + 1;

            if (!lineItem.cancelled) {
              lineItemTotal += lineItem.sub_line_items[idx].total;
            }

            if (lineItem.optional) {
              lineItemTotalBase += lineItem.sub_line_items[idx].default_total;
            }
          });

          lineItem.buyer_selection_pending = lineItem.sub_line_items.some(
            sli => {
              return sli.buyer_selection_pending;
            }
          );

          secondarySection.line_items[idx].position = idx + 1;
          secondarySection.line_items[idx].total =
            lineItemTotal + lineItemTotalBase;

          if (
            !lineItem.replaced &&
            (!lineItem.optional || lineItem.activated)
          ) {
            secondarySectionTotal += secondarySection.line_items[idx].total;
          }
        });

        secondarySection.position = idx + 1;
        secondarySection.total = secondarySectionTotal;
        primarySectionTotal += secondarySectionTotal;
      });

      primarySection.position = idx + 1;
      primarySection.total = primarySectionTotal;
      roomBookTotal += primarySectionTotal;
    });
    roomBook.total = roomBookTotal;

    return roomBook;
  }
}
