import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Menu, Icon, Segment, Checkbox } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getProject } from "shared/selectors";
import { getProjectAnalysisTopics } from "builder_portal/selectors/projectAnalysisTopics";
import { AccountResource } from "builder_portal/actions/accountActions";
import { ProjectAnalysisTopicsResource } from "builder_portal/actions/projectAnalysisTopicsActions";
import { ProjectSetupParametersResource } from "builder_portal/actions/projectSetupParametersActions";
import Growl from "builder_portal/actions/growlActions";
import { cloneDeep } from "lodash";
import { getProjectSetupParameters } from "builder_portal/selectors/projectSetupParameters";
import Content from "./Content";
import BasicProjectTab from "./BasicProjectTab";
import DataTab from "./DataTab";
import { TABS } from "./data";

const ICONS = {
  done: { name: "check circle", color: "green" },
  notStarted: { name: "dot circle", color: "red" },
  inProgress: { name: "warning sign", color: "yellow" }
};

/* Component WizardContainer */

const WizardContainer = () => {
  const dispatch = useDispatch();
  const [currentTabId, setCurrentTab] = useState(TABS[0].id);
  const [isInEditMode, setEditMode] = useState(false);
  const [menuTabs, setMenuTabs] = useState(TABS.filter(tab => tab.mandatory));
  const project = useSelector(getProject);
  const topics = useSelector(getProjectAnalysisTopics);
  const params = useSelector(getProjectSetupParameters);

  useEffect(() => {
    new AccountResource(dispatch).get();
    new ProjectAnalysisTopicsResource(dispatch).fetchAll();
  }, []);

  useEffect(() => {
    if (project.project_setup?.id)
      new ProjectSetupParametersResource(
        dispatch,
        project.project_setup?.id
      ).fetchAll();
  }, [project]);

  const saveParameter = values => {
    const resource = new ProjectSetupParametersResource(
      dispatch,
      project.project_setup?.id
    );
    return resource
      .saveParameter(values)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        resource.fetchAll();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const deleteParameter = id => {
    const resource = new ProjectSetupParametersResource(
      dispatch,
      project.project_setup?.id
    );
    return resource
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
        resource.fetchAll();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  useEffect(() => {
    const temp = cloneDeep(TABS);
    topics.forEach(topic => {
      temp.find(tab => tab.id === topic.topic_type).items.push({ ...topic });
    });
    setMenuTabs(temp);
  }, [topics]);

  const checkTopics = tabId => {
    let count = 0;
    const tabTopics = topics.filter(t => t.topic_type === tabId);
    let topicParamsExist = false;
    tabTopics.forEach(t => {
      const topicId = t.id;
      const topicParams = params.filter(
        p => p.project_analysis_topic_id === topicId
      );
      if (topicParams.length) topicParamsExist = true;
      if (
        topicParams.length &&
        topicParams[topicParams.length - 1].completes_topic
      )
        count += 1;
    });
    if (tabTopics.length > 0 && count === tabTopics.length) return "done";
    if (topicParamsExist) return "inProgress";
    return "notStarted";
  };

  const checkData = () => {
    const { project_setup } = project;
    const {
      step_project_catalog_completed_at,
      step_units_completed_at,
      step_room_books_completed_at,
      step_unit_features_completed_at,
      step_unit_variables_completed_at
    } = project_setup;

    const isCompleted =
      step_project_catalog_completed_at &&
      step_units_completed_at &&
      step_room_books_completed_at &&
      step_unit_features_completed_at &&
      step_unit_variables_completed_at;
    if (isCompleted) return "done";

    const inProgress =
      step_project_catalog_completed_at ||
      step_units_completed_at ||
      step_room_books_completed_at ||
      step_unit_features_completed_at ||
      step_unit_variables_completed_at;
    if (inProgress) return "inProgress";

    return "notStarted";
  };

  const checkStatus = tabId => {
    let count = 0;
    const currentTab = TABS.find(tab => tab.id === tabId);
    const { statusCheck, checkBy } = currentTab;

    switch (checkBy) {
      case "project":
        statusCheck.forEach(status => {
          if (project[status]) count += 1;
        });
        if (count === 0 || statusCheck.length === 0) return "notStarted";
        if (count === statusCheck.length) return "done";
        return "inProgress";
      case "topics":
        return checkTopics(currentTab.id);
      case "data":
        return checkData();
      default:
        return "notStarted";
    }
  };

  const renderIcon = tabId => {
    const status = checkStatus(tabId);

    return (
      <Icon
        name={ICONS[status].name}
        color={ICONS[status].color}
        size="large"
      />
    );
  };

  const renderMenu = () => {
    return (
      <>
        <Menu pointing vertical fluid>
          {menuTabs.map(tab => (
            <Menu.Item
              name={tab.id}
              key={tab.id}
              active={tab.id === currentTabId}
              onClick={() => setCurrentTab(tab.id)}
            >
              <span>
                {renderIcon(tab.id)}
                <FormattedMessage id={`project.project_setup.tabs.${tab.id}`} />
              </span>
            </Menu.Item>
          ))}
        </Menu>
        <Segment>
          <Checkbox
            toggle
            label="Struktur bearbeiten"
            onChange={() => setEditMode(!isInEditMode)}
          />
        </Segment>
      </>
    );
  };

  const renderContent = () => {
    switch (currentTabId) {
      case "basic_data":
        return <BasicProjectTab />;
      case "data_providing":
        return <DataTab />;
      case "project_setup":
        return (
          <div>
            Placeholder{" "}
            <FormattedMessage
              id={`project.project_setup.tabs.${currentTabId}`}
            />
          </div>
        );
      default:
        return (
          <Content
            tab={menuTabs.find(mt => mt.id === currentTabId)}
            saveParameter={saveParameter}
            deleteParameter={deleteParameter}
            isInEditMode={isInEditMode}
          />
        );
    }
  };

  return (
    <Grid stackable>
      <Grid.Column width={4}>{renderMenu()}</Grid.Column>

      <Grid.Column width={12}>
        <div
          style={{ overflowY: "auto", overflowX: "hidden", paddingTop: "1px" }}
          data-component="projectAnalysisTopics"
        >
          {renderContent()}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default WizardContainer;
