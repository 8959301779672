import React from "react";
import PropTypes from "prop-types";
import { Icon, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

import "./empty.scss";

class Empty extends React.Component {
  static propTypes = {
    text: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    let { text } = this.props;
    text = text || "statistics.messages.empty.title";
    return (
      <div data-component="emptyStatistics">
        <Header as="h2" icon textAlign="center">
          <Icon name="bar chart" />
          <Header.Content>
            <FormattedMessage id={text} default={text} />
          </Header.Content>
        </Header>
      </div>
    );
  }
}

export default Empty;
