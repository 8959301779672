import React, { PureComponent } from "react";
import { array, func, object } from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Button,
  Dropdown,
  Icon,
  Image,
  Loader,
  Popup
} from "semantic-ui-react";

import { getProductsById } from "shared/selectors/unit/productData";

import ProductInfoItem from "../product/ProductInfoItem";

import "./productRulesDialog.scss";

class ProductsSelector extends PureComponent {
  static propTypes = {
    availableProductIds: array.isRequired,
    selectedProductIds: array.isRequired,
    products: object,
    onChange: func.isRequired,
    dropdownProps: object,
    missingIds: array
  };

  static defaultProps = {
    dropdownProps: {},
    missingIds: []
  };

  render() {
    const {
      availableProductIds,
      selectedProductIds,
      products,
      onChange,
      dropdownProps,
      missingIds,
      ...passThroughProps
    } = this.props;
    return (
      <div className="products" {...passThroughProps}>
        {selectedProductIds.map(this.renderProduct)}
        {this.renderAddProductButton()}
      </div>
    );
  }

  renderProduct = id => {
    const { products, availableProductIds } = this.props;
    const product = products[id];
    const content = product ? (
      <Image src={product.thumb_url} />
    ) : (
      <Loader active />
    );
    const unavailable = !availableProductIds.includes(id);
    const button = (
      <Button
        animated="fade"
        className="product"
        data-id={id}
        onClick={this.handleRemoveProduct}
        key={id}
      >
        {unavailable && <Icon name="warning sign" color="orange" corner />}
        <Button.Content visible>{content}</Button.Content>
        <Button.Content hidden>
          <Icon name="minus" />
        </Button.Content>
      </Button>
    );

    return (
      <Popup key={id} trigger={button}>
        <ProductInfoItem product={product} />
        {unavailable && (
          <div>
            <Icon name="warning sign" color="orange" />
            <FormattedMessage id="productRules.error.notInProductGroup" />
          </div>
        )}
      </Popup>
    );
  };

  renderAddProductButton = () => {
    const { dropdownProps } = this.props;
    const options = this.getProductOptions();

    if (options.length === 0) return null;

    return (
      <Dropdown
        {...dropdownProps}
        data-component="ruleOptions"
        fluid
        floating
        scrolling
        button
        search
        text=" "
        icon={{
          name: "plus"
        }}
        options={options}
        onChange={this.handleAddProduct}
        value=""
        selectOnNavigation={false}
        selectOnBlur={false}
      />
    );
  };

  handleAddProduct = (event, { value }) => {
    const {
      selectedProductIds,
      onChange,
      availableProductIds,
      missingIds
    } = this.props;
    if (value === "all") {
      onChange(event, {
        ...this.props,
        selectedProductIds: availableProductIds
      });
    } else if (value === "without_rule") {
      onChange(event, {
        ...this.props,
        selectedProductIds: [...selectedProductIds, ...missingIds]
      });
    } else {
      onChange(event, {
        ...this.props,
        selectedProductIds: [...selectedProductIds, value]
      });
    }
  };

  handleRemoveProduct = (event, { "data-id": value }) => {
    const { selectedProductIds, onChange } = this.props;
    onChange(event, {
      ...this.props,
      selectedProductIds: selectedProductIds.filter(i => i !== value)
    });
  };

  getProductOptions = () => {
    const {
      availableProductIds,
      selectedProductIds,
      products,
      missingIds
    } = this.props;
    const missingProductIds = availableProductIds.filter(
      id => !selectedProductIds.includes(id)
    );

    const options = missingProductIds.map(productId => {
      const product = products[productId];
      return product
        ? {
            text: `${product.name} - ${product.supplier} - ${product.series} - ${product.sku}`,
            content: (
              <ProductInfoItem product={product} style={{ marginTop: "0" }} />
            ),
            image: product.thumb_url,
            value: product.id,
            key: product.id
          }
        : {
            value: productId,
            key: productId
          };
    });

    const optionCount = options.length;

    if (missingIds.length > 0) {
      options.unshift({
        text: "Alle ohne Regel auswählen",
        value: "without_rule"
      });
    }

    if (optionCount > 1) {
      options.unshift({
        text: "Alle auswählen",
        value: "all"
      });
    }

    return options;
  };
}

const mapStateToProps = createStructuredSelector({
  products: getProductsById
});

export default connect(mapStateToProps, () => ({}))(ProductsSelector);
