import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { Form, Button, Icon, Input, Header, Label } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { SingleDatePicker } from "react-dates";
import "./deadlinesForm.scss";
import "react-dates/lib/css/_datepicker.css";

class DeadlinesForm extends React.Component {
  static propTypes = {
    onHandleToggleDialog: PropTypes.func,
    deadlines: PropTypes.array,
    activity: PropTypes.object,
    actions: PropTypes.object,
    deadlinesResource: PropTypes.object,
    states: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      date: {},
      deadlines: props.deadlines || []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateDay(id, date) {
    if (typeof date === "string") {
      date = moment.utc(date);
    }
    const updatedDeadlines = this.state.deadlines.map(item => {
      const updatedItem = { ...item }; // clone object
      if (item.id === id) {
        updatedItem.expires_at = date.format("YYYY-MM-DD");
      }
      return updatedItem;
    });

    this.setState({ ...this.state, deadlines: updatedDeadlines });
  }

  handleSubmit(proxy) {
    proxy.preventDefault();
    this.props.deadlinesResource.batchUpdate(this.state.deadlines).then(() => {
      this.props.deadlinesResource.fetchAll();
      this.props.actions.activity.get(this.props.activity.id, true);
      this.props.onHandleToggleDialog(false);
    });
  }

  render() {
    const { states } = this.props;
    const { deadlines } = this.state;
    const children = [];
    deadlines.map((item, index) => {
      if (item && item.expires_at && item.expiry_type) {
        switch (item.expiry_type) {
          case "status": {
            const completed = Boolean(item.completed_at);
            const expiresAt = moment.utc(item.expires_at);
            const prevDay = moment
              .utc(item.expires_at)
              .subtract(1, "days")
              .format();
            const nextDay = moment
              .utc(item.expires_at)
              .add(1, "days")
              .format();
            const direction = deadlines.length / 2 > index ? "down" : "up";
            children.push(
              <Form.Group widths="equal" key={item.id} data-model="deadline">
                <Form.Field>
                  <Header size="small">
                    <Icon size="large" name="flag checkered" />
                    {states &&
                      states[item.expiry_ref] &&
                      states[item.expiry_ref].label}
                  </Header>
                  <Input type="text" action>
                    {prevDay && (
                      <Button
                        basic
                        icon="minus"
                        className="buttonMinus"
                        onClick={() => this.updateDay(item.id, prevDay)}
                        type="button"
                      />
                    )}
                    <div className={direction}>
                      <SingleDatePicker
                        date={expiresAt}
                        isOutsideRange={() => false}
                        onDateChange={date => this.updateDay(item.id, date)}
                        numberOfMonths={1}
                        placeholder="Datum des Fristendes"
                        displayFormat="DD.MM.YYYY"
                        focused={
                          (this.state.date[item.id] &&
                            this.state.date[item.id].focused) ||
                          false
                        }
                        onFocusChange={focused =>
                          this.setState({ date: { [item.id]: focused } })
                        }
                      />
                    </div>
                    {nextDay && (
                      <Button
                        basic
                        icon="plus"
                        className="buttonPlus"
                        onClick={() => this.updateDay(item.id, nextDay)}
                        type="button"
                      />
                    )}
                  </Input>
                </Form.Field>
                {completed && (
                  <Label
                    className="completed"
                    icon="check"
                    color="green"
                    content="abgeschlossen"
                  />
                )}
              </Form.Group>
            );
            break;
          }
        }
      }
    });

    return (
      <Form
        id="deadlines"
        onSubmit={this.handleSubmit}
        data-component="deadlines"
      >
        {children}
        <Form.Field>
          <Button type="submit" color="green" className="editDeadlines">
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

export default DeadlinesForm;
