const getContactOptions = (contacts, requireEmail, getOptionContent) => {
  if (!contacts) return [];
  const icons = {
    Buyer: "child",
    Contractor: "truck",
    User: "user",
    Contact: "address card outline",
    Project: "building"
  };

  return Object.keys(contacts).map(contactId => {
    const contact = contacts[contactId];
    return {
      key: contactId,
      value: contactId,
      text: `${contact.text}${
        requireEmail && !contact.email
          ? "[Achtung: Keine E-Mail hinterlegt]"
          : ""
      }`,
      disabled: requireEmail ? !contact.email : false,
      content:
        typeof getOptionContent === "function"
          ? getOptionContent(icons[contact.type], contact.text, contact.email)
          : null
    };
  });
};

export default getContactOptions;
