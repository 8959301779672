import React from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { getAccount } from "../../selectors";

const IsBetaPreview = ({ children, ignore }) => {
  const account = useSelector(getAccount);

  if (ignore) return <>{children}</>;

  return account.hasBetaPreviewRight() ? <>{children}</> : null;
};

IsBetaPreview.propTypes = {
  children: PropTypes.node,
  ignore: PropTypes.bool
};

IsBetaPreview.defaultProps = {
  children: null,
  ignore: false
};

export default IsBetaPreview;
