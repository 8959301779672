import React from "react";
import PropTypes from "prop-types";

import { get } from "lodash";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { browserHistory } from "../../../../shared/routes/browserHistory";

import CartDialog from "../../cart/CartDialog";

class ActionBar extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    projectId: PropTypes.string,
    reportUrls: PropTypes.object,
    units: PropTypes.array,
    sections: PropTypes.array,
    processDefinitions: PropTypes.array,
    activitiesResource: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      cartModel: null
    };
  }

  createActivity(values) {
    const { projectId } = this.props;
    return this.props.activitiesResource
      .save({ ...values, title: values.name })
      .then(response => {
        browserHistory.push(
          `/projects/${projectId}/activities/${response.data.activity.id}`
        );
        return false;
      });
  }

  render() {
    return (
      <div>
        <Menu attached="top">
          {this.renderActivityCreationDropdown()}
          <Menu.Menu position="right">{this.renderExportDropdown()}</Menu.Menu>
        </Menu>
        {this.renderCartDialog()}
      </div>
    );
  }

  renderActivityCreationDropdown() {
    const { processDefinitions, i18n } = this.props;

    const activityItems = processDefinitions
      .filter(pd => {
        return !pd.isDeprecated();
      })
      .map(pd => {
        const scope = pd.getActivityScope();
        const scopeElements = get(this.props, `${scope}s`, []);
        const scopeProps = { [`${scope}s`]: scopeElements };
        const cartModel = {
          cart: {
            process_type: pd.id,
            status: pd.getProcessStartStates()[0].id,
            name: "",
            [`${scope}_id`]: get(scopeElements, [0, "id"])
          },
          processDefinition: pd,
          scopeProps
        };
        return (
          <Dropdown.Item
            key={pd.id}
            onClick={() => {
              this.setState({ cartModel });
            }}
          >
            {pd.getName()}
          </Dropdown.Item>
        );
      });

    return (
      <Dropdown
        item
        trigger={
          <span>
            <Icon name="plus" /> {i18n["activity.actions.add"]}
          </span>
        }
      >
        <Dropdown.Menu>{activityItems}</Dropdown.Menu>
      </Dropdown>
    );
  }

  renderCartDialog() {
    const { i18n } = this.props;
    const { cartModel } = this.state;

    if (cartModel) {
      return (
        <CartDialog
          i18n={i18n}
          cart={cartModel.cart}
          processDefinitions={[cartModel.processDefinition]}
          open
          handleSave={this.createActivity.bind(this)}
          handleClose={() => {
            this.setState({ cartModel: null });
          }}
          {...cartModel.scopeProps}
        />
      );
    }
    return null;
  }

  renderExportDropdown() {
    const { i18n, reportUrls } = this.props;
    if (reportUrls) {
      return (
        <Dropdown
          item
          trigger={
            <span>
              <Icon name="file excel outline" /> {i18n["activity.export.title"]}
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <a href={reportUrls.xlsx_by_units}>
                <Icon name="cube" /> {i18n["activity.export.byUnit"]}
              </a>
            </Dropdown.Item>
            <Dropdown.Item>
              <a href={reportUrls.xlsx_by_contractors}>
                <Icon name="shipping" /> {i18n["activity.export.byContractor"]}
              </a>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return null;
  }
}

export default ActionBar;
