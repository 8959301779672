import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button, Segment } from "semantic-ui-react";
import DeleteActivityDialog from "builder_portal/components/activity/DeleteActivityDialog";

export default class UtilitySegment extends React.Component {
  static propTypes = {
    activity: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string
    }).isRequired
  };

  render = () => {
    const { activity } = this.props;
    return this.renderSegment(activity);
  };

  renderSegment = activity => {
    const button = (
      <Button
        fluid
        color="red"
        id="delete-activity"
        content={<FormattedMessage id="meta.actions.remove" />}
      />
    );
    return (
      <Segment>
        <DeleteActivityDialog activity={activity} trigger={button} />
      </Segment>
    );
  };
}
