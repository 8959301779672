import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import WebsiteTabsEditor from "./WebsiteTabsEditor";

const AddNewPage = ({
  trigger,
  model,
  handlePageUpdate,
  fieldName,
  setFieldValue: onSetFieldValue,
  modelId,
  jsonConfig
}) => {
  const refResetForm = useRef();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => setOpen(true);

  const onSubmit = values => {
    handlePageUpdate(values);
    handleClose();
  };

  return (
    <Formik
      initialValues={{
        ...model
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, resetForm, values, setFieldValue }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            open={open}
            onClose={handleClose}
            size="tiny"
            closeIcon
            trigger={trigger}
            onOpen={handleOpen}
          >
            <Modal.Header>
              <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.editPage" />
            </Modal.Header>
            <Modal.Content>
              <Form id="configuratorBackendForm">
                <Input
                  id="title"
                  name="title"
                  label={intl.formatMessage({
                    id: "configuratorDesign.dialog.contentPageEditor.title"
                  })}
                />
                <WebsiteTabsEditor
                  configuratorDesign={model}
                  setFieldValue={setFieldValue}
                  pageEditor
                  handlePageItemsUpdate={pageItem => {
                    setFieldValue("items", pageItem);
                  }}
                  onJsonConfigChange={data => {
                    const newJsonConfig = jsonConfig.map(item => {
                      if (item.id === modelId) {
                        return {
                          ...item,
                          items: data
                        };
                      }
                      return item;
                    });
                    onSetFieldValue(fieldName, newJsonConfig);
                  }}
                  value={values.items || []}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions className="right">
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({ id: "meta.actions.cancel" })}
                onClick={handleClose}
                loading={isSubmitting}
              />
              <Button
                primary
                type="submit"
                content={intl.formatMessage({ id: "meta.actions.edit" })}
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

AddNewPage.propTypes = {
  trigger: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object.isRequired,
  handlePageUpdate: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  modelId: PropTypes.number.isRequired,
  jsonConfig: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AddNewPage;
