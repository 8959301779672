import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Input, Segment, Button, Form, Icon, Modal } from "semantic-ui-react";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import DateInput from "shared/components/forms/DateInput";
import { connect } from "react-redux";
import { getAccount } from "shared/selectors";
import { flatMap, get, sortBy } from "lodash";
import { ContractorShape, ContractorAssignmentShape } from "shared/shapes";
import moment from "moment";
import { withLoadingIndicator } from "../helpers/withLoadingIndicator";

import "./cartExportDialog.scss";

/**
 * Dialog to create exports
 *
 * Can be used in two modes:
 *  ATTACHMENT: Save document as attachment
 *  PREVIEW: Just show the document as preview
 *
 * When mode is ATTACHMENT the dialog first shows a form where you can set name and any additional
 * parameters for the export. Submitting the form is delegated to the onSave property and after
 * successful submission the form switches to a Button where you can download the document.
 *
 * When in PREVIEW mode the form is not submitted with the onSave property, but a get-URL is build
 * with all the parameters and this is then opened in a new tab. The extra download button is not
 * shown.
 */
class CartExportDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
      model: props.attachment,
      activeIndex: 0
    };
    this.onSave = withLoadingIndicator.bind(this)(this.onSave);
    this.customFieldsArg = undefined;
  }

  setDefaultCustomInfoValue = values => {
    const { activeIndex } = this.state;
    if (activeIndex > 0) return values;
    const customFields = this.getCustomFields(this.customFieldsArg);
    const firstGroupFields = customFields.filter(field => field.group === 0);
    if (firstGroupFields.length === 1) {
      const customInfoId = firstGroupFields[0]?.id;
      // eslint-disable-next-line no-param-reassign
      values[customInfoId] = true;
    }
    return values;
  };

  onDownload = values => {
    const exp = this.getExport();
    const option = exp.options.find(option => {
      return option.format === values.format;
    });
    const params = exp.arguments
      .reduce((accu, f) => {
        if (values[f.id]) {
          accu.push(`${f.id}=${encodeURIComponent(values[f.id])}`);
        }
        return accu;
      }, [])
      .join("&");

    const customFields = this.getCustomFields(this.customFieldsArg);
    const valuesWithDefaultCustomInfo = this.setDefaultCustomInfoValue(values);
    const customFieldParams = customFields
      .reduce((accu, f) => {
        if (valuesWithDefaultCustomInfo[f.id]) {
          accu.push(
            `${f.id}=${encodeURIComponent(valuesWithDefaultCustomInfo[f.id])}`
          );
        }
        return accu;
      }, [])
      .join("&");
    const additionalParams = customFieldParams ? `&${customFieldParams}` : "";

    this.setState({ open: false }, () => {
      window.open(`${option.url}${additionalParams}&${params}`, "_blank");
    });
  };

  onSave = values => {
    const { onSave } = this.props;
    const valuesWithDefaultCustomInfo = this.setDefaultCustomInfoValue(values);
    return onSave(valuesWithDefaultCustomInfo).then(
      this.closeDialog,
      this.closeDialog
    );
  };

  getExport() {
    const { exports } = this.props;
    const { model } = this.state;
    return exports.filter(exp => {
      return exp.id === model.export_id;
    })[0];
  }

  getCustomFields = arg => {
    if (!arg?.custom_fields) return [];
    return arg.custom_fields.reduce((accu, customGroup, index) => {
      return accu.concat(
        customGroup.map(customField => ({
          id: customField.id,
          label: customField.label,
          group: index
        }))
      );
    }, []);
  };

  getFormFactory(exp) {
    const argumentFields = exp.arguments.reduce((accu, arg) => {
      if (arg.type === "boolean") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          control: "Checkbox"
        });
      }
      if (arg.type === "string") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          rule: arg.isRequired ? "isRequired" : null,
          default: arg.defaultValue ? arg.defaultValue : null
        });
      }
      if (arg.type === "text") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          rule: arg.isRequired ? "isRequired" : null,
          default: arg.defaultValue ? arg.defaultValue : null
        });
      }
      if (arg.type === "date") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          rule: arg.isRequired ? "isRequired" : null,
          default: arg.defaultValue
            ? moment(arg.defaultValue, "DD.MM.YYYY")
            : null,
          nullable: !arg.isRequired
        });
      }
      if (arg.type === "custom_fields") {
        this.customFieldsArg = { ...arg };
        return accu;
      }
      return accu.concat({
        id: arg.id,
        label: arg.label,
        rule: "isRequired"
      });
    }, []);

    if (this.customFieldsArg)
      argumentFields.push(...this.getCustomFields(this.customFieldsArg));

    return new FormDefinition({
      fields: [
        {
          id: "export_id",
          label: "attachment.attributes.export_id.label",
          rule: "isRequired"
        },
        {
          id: "display_name",
          label: "attachment.attributes.display_name.label",
          placeholder: "attachment.attributes.display_name.placeholder",
          message: "attachment.attributes.display_name.error",
          rule: "isExportDisplayName"
        },
        {
          id: "role",
          label: "attachment.attributes.role.label",
          rule: "isRequired"
        }
      ].concat(argumentFields)
    });
  }

  getTradeOptions() {
    const { trades, contractors, contractorAssignments } = this.props;
    const orderedTrades = sortBy(trades, "label");

    return flatMap(orderedTrades, tradeToOptions);

    function tradeToOptions(trade) {
      const assignedContractors = contractorAssignments
        .filter(contractor_assignment => {
          return contractor_assignment.trade === trade.id;
        })
        .map(contractor_assignment => {
          return contractors.find(contractor => {
            return contractor.id === contractor_assignment.contractor_id;
          });
        });

      const disabled = assignedContractors.length > 0;
      const tradeOption = {
        key: trade.id,
        value: trade.id,
        text: trade.label,
        "data-model": "trade",
        "data-id": trade.id,
        icon: "paper hand",
        size: "small",
        disabled
      };
      const contractorOptions = assignedContractors.map(contractor => ({
        key: `${trade.id}-${contractor.id}`,
        value: `${trade.id}-${contractor.id}`,
        text: contractor.name,
        "data-model": "contractor",
        "data-id": contractor.id,
        icon: "shipping",
        size: "small",
        className: "indented"
      }));

      return [tradeOption, ...contractorOptions];
    }
  }

  handleOpen = () => {
    const { attachment } = this.props;

    const exp = this.getExport();
    const model = this.withDefaults(attachment, exp);

    this.setState({ model, open: true });
  };

  withDefaults(attachment, exp) {
    (exp.arguments || []).forEach(f => {
      if (f.type === "trades" && !attachment[f.id]) {
        const defaultOption = this.getTradeOptions().find(
          option => !option.disabled
        );
        attachment[f.id] = get(defaultOption, "value");
      }
      if (f.type === "date" && f.isRequired && !attachment[f.id]) {
        attachment[f.id] = new Date();
      }
    });

    return attachment;
  }

  closeDialog = () => {
    this.setState({ open: false });
  };

  renderArgumentByType(form, argument, disabled) {
    // eslint-disable-next-line no-param-reassign
    if (disabled) form.fields[argument.id].props.disabled = disabled;
    if (argument.type === "trades") {
      const tradeOptions = this.getTradeOptions();
      form.fields[argument.id].props.options = tradeOptions;
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Select" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "boolean") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Checkbox" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "date") {
      return (
        <Form.Field key={argument.id}>
          <Field component={DateInput} {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "string") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Input" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "text") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="TextArea" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    return <span key={argument.id} />;
  }

  resetOtherCustomFields = index => {
    const customFields = this.getCustomFields(this.customFieldsArg);

    const closedFields = customFields.filter(field => field.group !== index);
    closedFields.forEach(field => {
      this.setState(prevState => ({
        model: { ...prevState.model, [field.id]: "" }
      }));
    });

    const openedFields = customFields.filter(field => field.group === index);
    if (openedFields.length === 1) {
      this.setState(prevState => ({
        model: { ...prevState.model, [openedFields[0]?.id]: true }
      }));
    }
  };

  handleClick = index => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
    this.resetOtherCustomFields(newIndex);
  };

  renderArgument(form, argument) {
    const { activeIndex } = this.state;
    if (argument.type === "custom_fields") {
      return (
        <div key={argument.id}>
          {argument.custom_fields?.reduce((accu, customGroup, index) => {
            const active = activeIndex === index;
            const hasContent = customGroup[0]?.type !== "info";
            const title = hasContent ? "" : customGroup[0].label;
            const checkboxMarginTop = hasContent
              ? { marginTop: "0.2rem" }
              : { marginTop: "0.6rem" };

            const content = customGroup.reduce((accuContent, customField) => {
              if (!customField) accuContent.push(<span />);
              accuContent.push(
                this.renderArgumentByType(form, customField, !active)
              );
              return accuContent;
            }, []);

            accu.push(
              <Segment
                style={{ display: "flex", marginBottom: "15px" }}
                key={`content-${customGroup[0].id}`}
              >
                <div style={{ display: "flex", alignItems: "top" }}>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleClick(index)}
                    checked={active}
                    style={checkboxMarginTop}
                  />
                  <div as="h4" style={{ margin: "5px" }}>
                    {title}
                  </div>
                </div>
                <div>{content}</div>
              </Segment>
            );
            return accu;
          }, [])}
        </div>
      );
    }
    return this.renderArgumentByType(form, argument);
  }

  renderCloseButton() {
    return (
      <Button
        type="button"
        color="green"
        id="close"
        onClick={() => {
          this.closeDialog(false);
        }}
      >
        <FormattedMessage
          id="meta.actions.accept"
          defaultMessage="meta.actions.accept"
        />
      </Button>
    );
  }

  renderPreviewButton(form) {
    return (
      <Button
        data-form="export"
        type="submit"
        color="green"
        className="editExport"
        onClick={form.handleSubmit(this.onDownload)}
      >
        <Icon name="download" />
        <FormattedMessage
          id="meta.actions.preview"
          defaultMessage="meta.actions.preview"
        />
      </Button>
    );
  }

  renderSaveButton(form) {
    const { isLoading } = this.state;
    return (
      <Button
        data-form="export"
        type="submit"
        color="green"
        loading={isLoading}
        className="editExport"
        onClick={form.handleSubmit(this.onSave)}
      >
        <Icon name="save" />
        <FormattedMessage
          id="meta.actions.save"
          defaultMessage="meta.actions.save"
        />
      </Button>
    );
  }

  renderForm(form, exp) {
    const { exports, mode } = this.props;
    return (
      <Modal.Content>
        <Form
          id="export"
          onSubmit={form.handleSubmit(this.onSave)}
          data-component="exportForm"
        >
          {mode === "ATTACHMENT" && exports.length > 1 && (
            <Form.Field width="16">
              <Field component="Select" {...form.fields.export_id} />
            </Form.Field>
          )}

          {mode === "ATTACHMENT" && (
            <Form.Field width="16">
              <Field component="Input" {...form.fields.display_name} />
            </Form.Field>
          )}

          {mode === "ATTACHMENT" && (
            <Form.Field width="16">
              <Field component="Select" {...form.fields.role} />
            </Form.Field>
          )}

          {exp.arguments.map(f => {
            return this.renderArgument(form, f);
          })}
        </Form>
      </Modal.Content>
    );
  }

  render() {
    const { account, i18n, button, exports, mode, title } = this.props;

    const { model, open } = this.state;

    const roleOptions = account.getDocumentTypes();

    const exp = this.getExport();

    const form = this.getFormFactory(exp).create(model, i18n, {
      onChange: data => this.setState({ model: data })
    });

    form.fields.export_id.props.options = exports.map(expOption => {
      return {
        key: expOption.id,
        text: expOption.label,
        value: expOption.id
      };
    });

    form.fields.role.props.options = roleOptions.map(roleOption => {
      return {
        key: roleOption.id,
        text: roleOption.label,
        content: (
          <span>
            <Icon name="file outline" /> {roleOption.label}
          </span>
        ),
        value: roleOption.id
      };
    });

    let actionButton;
    if (mode === "ATTACHMENT") {
      actionButton = this.renderSaveButton(form);
    } else {
      actionButton = this.renderPreviewButton(form);
    }
    return (
      <Modal
        data-component="CartExportDialog"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={button}
        open={open}
        size="small"
        onOpen={this.handleOpen}
        onClose={() => this.closeDialog()}
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.cart.actions.export"
            defaultMessage="roomBook.cart.actions.export"
          />
          : {title}
        </Modal.Header>
        {this.renderForm(form, exp)}
        <Modal.Actions>{actionButton}</Modal.Actions>
      </Modal>
    );
  }
}

CartExportDialog.propTypes = {
  i18n: PropTypes.object,
  title: PropTypes.string,
  exports: PropTypes.array,
  button: PropTypes.node,
  account: PropTypes.object,
  trades: PropTypes.array,
  mode: PropTypes.string,
  attachment: PropTypes.object,
  onSave: PropTypes.func,
  contractors: PropTypes.arrayOf(ContractorShape),
  contractorAssignments: PropTypes.arrayOf(ContractorAssignmentShape)
};

CartExportDialog.defaultProps = {
  contractors: [],
  contractorAssignments: []
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    account: getAccount(state)
  };
};

export default connect(mapStateToProps)(CartExportDialog);
