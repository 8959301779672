import React from "react";
import {
  func,
  shape,
  string,
  number,
  instanceOf,
  oneOfType,
  objectOf
} from "prop-types";
import { FormattedMessage } from "react-intl";
import { Form, Segment, Button, Icon, Header } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";

import { FormDefinition } from "shared/components/forms/FormDefinition";
import { I18nShape } from "shared/shapes/i18n.shape";
import getContactOptions from "../../helpers/getContactOptions";
import WysiwygEditor from "../../../shared/components/elements/wysiwyg/WysiwygEditor";
import MemosResource from "../../actions/memosActions";
import { ContactsShape } from "../../../shared/shapes/contacts.shape";

class MemoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.model,
      isLoading: false
    };
  }

  cancel = event => {
    const { handleClose } = this.props;
    event.preventDefault();
    handleClose();
  };

  delete = event => {
    event.preventDefault();

    const { model, memosResource, handleUpdate, handleClose } = this.props;

    memosResource.delete(model.id).then(() => {
      handleUpdate();
      handleClose();
    });
  };

  handleChange(data) {
    this.setState({ message: data });
  }

  handleUpdate(data) {
    const {
      contacts,
      type,
      memosResource,
      handleUpdate,
      handleClose
    } = this.props;

    const recipients = data.contacts.map(recipientId => {
      return contacts[recipientId];
    });

    const values = {
      id: data.id,
      body: data.body,
      body_html: data.body_html,
      role: type,
      contacts: recipients
    };

    this.setState({ isLoading: true });

    memosResource.save(values).then(() => {
      handleUpdate();
      this.setState({ isLoading: false });
      handleClose();
    });
  }

  buildForm() {
    const { i18n, contacts } = this.props;
    const { message } = this.state;
    const fields = [
      {
        id: "contacts",
        label: "memo.attributes.recipients.label",
        placeholder: "memo.attributes.recipients.placeholder"
      }
    ];

    const FormFactory = new FormDefinition({ fields });

    const form = FormFactory.create(message, i18n, {
      onChange: data => this.handleChange({ ...message, ...data })
    });

    form.fields.contacts.props = {
      ...form.fields.contacts.props,
      multiple: true,
      search: true,
      selection: true,
      closeOnChange: true,
      options: getContactOptions(contacts)
    };

    return form;
  }

  render() {
    const { type, model } = this.props;
    const { isLoading, message } = this.state;
    const form = this.buildForm();
    return (
      <Segment data-component="memoForm">
        <Form
          id="memo"
          onSubmit={form.handleSubmit(this.handleUpdate.bind(this))}
        >
          {(!model || !model.id) && (
            <Header>
              {type === "note" && (
                <FormattedMessage
                  id="meta.actions.message.newNote"
                  defaultMessage="meta.actions.message.newNote"
                />
              )}
              {type === "call" && (
                <FormattedMessage
                  id="meta.actions.message.newCall"
                  defaultMessage="meta.actions.message.newCall"
                />
              )}
            </Header>
          )}
          <WysiwygEditor
            value={message.body_html}
            name="bodyHtml"
            buttonsHide={["html"]}
            onChange={({ value: newBody }) => {
              this.setState(prevState => ({
                message: {
                  ...prevState.message,
                  body_html: newBody
                }
              }));
            }}
          />
          <Form.Field>
            <Field component="Dropdown" {...form.fields.contacts} />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <Button fluid color="green" loading={isLoading} type="submit">
                <span>
                  <Icon name="save" />
                  <FormattedMessage
                    id="meta.actions.save"
                    defaultMessage="meta.actions.save"
                  />
                </span>
              </Button>
            </Form.Field>
            {model && model.id && (
              <Form.Field>
                <Button
                  fluid
                  color="red"
                  className="right floated"
                  onClick={this.delete}
                >
                  <Icon name="remove" />
                  <FormattedMessage
                    id="meta.actions.remove"
                    defaultMessage="meta.actions.remove"
                  />
                </Button>
              </Form.Field>
            )}
            {(!model || !model.id) && (
              <Button
                fluid
                basic
                className="right floated"
                onClick={this.cancel}
              >
                <Icon name="remove" />
                <FormattedMessage
                  id="meta.actions.cancel"
                  defaultMessage="meta.actions.cancel"
                />
              </Button>
            )}
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

MemoForm.propTypes = {
  i18n: I18nShape.isRequired,
  model: shape({
    id: oneOfType([number, string])
  }).isRequired,
  contacts: objectOf(ContactsShape),
  onSave: func,
  onDelete: func,
  handleUpdate: func,
  handleClose: func,
  memosResource: instanceOf(MemosResource).isRequired,
  type: string
};

MemoForm.defaultProps = {
  type: "",
  contacts: {},
  onSave: () => {},
  onDelete: () => {},
  handleUpdate: () => {},
  handleClose: () => {}
};

export default MemoForm;
