import React from "react";
import { Grid, Checkbox } from "semantic-ui-react";

import "./customLabelCheckbox.scss";

export const CustomLabelCheckbox = props => {
  return (
    <Grid
      onClick={e => {
        props.onChange(e, { checked: !props.checked });
      }}
      data-component="customLabelCheckbox"
    >
      <Grid.Column verticalAlign="middle">
        <Checkbox checked={props.checked} onChange={props.onChange} />
      </Grid.Column>
      <Grid.Column width={14}>{props.children}</Grid.Column>
    </Grid>
  );
};
