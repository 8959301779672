import { number, oneOf, shape, string } from "prop-types";

const ImageShape = shape({
  id: number,
  role: oneOf(["product_image", "primary", "thumb"]),
  position: number,
  created_at: string,
  updated_at: string,
  url: string
});
export default ImageShape;
