import { createSelector } from "reselect";
import { get, orderBy } from "lodash";

import { getPageContent } from "shared/selectors/base";

export const getVersions = createSelector([getPageContent], pageContent =>
  orderBy(get(pageContent, "versions", []), "created_at", "desc")
);

export const getEntityReference = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "entity_reference", [])
);

export const getParentReference = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "parent_reference", [])
);
