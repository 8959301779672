import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import StickyMenu from "shared/components/menus/StickyMenu";
import { groupBy, pickBy, sortBy } from "lodash";
import { getAccount } from "shared/selectors";
import { useRoomBookUpgradeBundlesContext } from "../../../resources/roomBooks/RoomBookUpgradeBundlesContext";
import { If } from "../../../../shared/components/elements/Conditions";
import FormatCurrency from "../../../../shared/components/currency/FormatCurrency";
import RoomBookUpgradeBundlesShape from "../../../../shared/shapes/roomBookUpgradeBundles.shape";

const UpgradeBundleBasePrice = ({ upgradeBundle }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(0);
  const { updatePrice } = useRoomBookUpgradeBundlesContext();

  useEffect(() => {
    if (upgradeBundle) {
      setPrice(upgradeBundle.total_base);
    }
  }, [upgradeBundle]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback((_, { value }) => {
    setPrice(value);
  }, []);

  const handleUpdate = useCallback(() => {
    setLoading(true);
    return updatePrice(upgradeBundle, price).then(() => {
      setLoading(false);
      handleClose();
    });
  }, [upgradeBundle, updatePrice, price, handleClose]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
      }}
    >
      <span className="margin right medium">
        <FormatCurrency amount={upgradeBundle.total_base} />
      </span>
      <Modal
        trigger={<Icon name="edit" link />}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        size="tiny"
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.roomBookUpgradeBundles.dialog.adjustPriceTitle"
            defaultMessage="roomBook.roomBookUpgradeBundles.adjustPriceTitle"
          />
        </Modal.Header>
        <Modal.Content>
          <CurrencyInput fluid value={price} onBlur={handleChange} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            type="button"
            onClick={handleUpdate}
            loading={loading}
          >
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

UpgradeBundleBasePrice.propTypes = {
  upgradeBundle: RoomBookUpgradeBundlesShape.isRequired
};

const UpgradeBundleButton = ({ upgradeBundle }) => {
  const [loading, setLoading] = useState(false);
  const { activate, deactivate } = useRoomBookUpgradeBundlesContext();
  const handleClick = useCallback(() => {
    setLoading(true);
    const method = upgradeBundle.activated ? deactivate : activate;
    return method(upgradeBundle).then(() => setLoading(false));
  }, [upgradeBundle, activate, deactivate]);

  return (
    <>
      <If condition={!upgradeBundle.activated}>
        <Button fluid primary loading={loading} onClick={handleClick}>
          <FormattedMessage
            id="meta.actions.activate"
            defaultMessage="meta.actions.activate"
          />
        </Button>
      </If>
      <If condition={upgradeBundle.activated}>
        <Button
          fluid
          color="primary"
          loading={loading}
          onClick={handleClick}
          basic
        >
          <FormattedMessage
            id="meta.actions.deactivate"
            defaultMessage="meta.actions.deactivate"
          />
        </Button>
      </If>
    </>
  );
};

UpgradeBundleButton.propTypes = {
  upgradeBundle: RoomBookUpgradeBundlesShape.isRequired
};

const UpgradeBundlesDialog = ({ trigger }) => {
  const [open, setOpen] = useState(false);
  const account = useSelector(getAccount);
  const { upgradeBundles } = useRoomBookUpgradeBundlesContext();
  const { upgradeBundleGroups } = useRoomBookUpgradeBundlesContext();

  const bundlesByGroup = useMemo(
    () => groupBy(upgradeBundles, "upgrade_bundle_group_id"),
    [upgradeBundles]
  );

  const filteredGroups = useMemo(
    () => pickBy(upgradeBundleGroups, g => bundlesByGroup[g.id]),
    [upgradeBundleGroups, bundlesByGroup]
  );

  const [currentTab, setCurrentTab] = useState(Object.keys(bundlesByGroup)[0]);

  useEffect(() => {
    if (!currentTab || !bundlesByGroup[currentTab]) {
      setCurrentTab(Object.keys(bundlesByGroup)[0]);
    }
  }, [bundlesByGroup, currentTab]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleGroupChange = group => {
    setCurrentTab(group.id);
  };

  if (upgradeBundles.length) {
    return (
      <Modal
        trigger={trigger}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.roomBookUpgradeBundles.dialog.title"
            defaultMessage="roomBook.roomBookUpgradeBundles.dialog.title"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: "70vh" }}>
          <p>
            <FormattedMessage
              id="roomBook.roomBookUpgradeBundles.dialog.summary"
              defaultMessage="roomBook.roomBookUpgradeBundles.summary"
            />
          </p>
          <div style={{ minHeight: "240px" }}>
            <StickyMenu
              stickyItem={null}
              allItems={filteredGroups}
              accountId={account?.data?.account?.id}
              onSelectedItemChange={handleGroupChange}
              storeKey="upgradeBundleGroup"
              showPopup
            />
            <Table stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.name"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.name"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="right">
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.basePriceCalculated"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.basePriceCalculated"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="right">
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.basePriceSurcharge"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.basePriceSurcharge"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="right">
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.basePriceFinal"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.basePriceFinal"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="right">
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.total"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.total"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    <FormattedMessage
                      id="roomBook.roomBookUpgradeBundles.dialog.table.status"
                      defaultMessage="roomBook.roomBookUpgradeBundles.dialog.table.status"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortBy(bundlesByGroup[currentTab], "name").map(ub => (
                  <Table.Row key={ub.id}>
                    <Table.Cell>{ub.name}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <FormatCurrency
                        amount={ub.total_base_without_surcharge}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <FormatCurrency amount={ub.surcharge} />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <UpgradeBundleBasePrice upgradeBundle={ub} />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <If condition={ub.activated}>
                        <FormatCurrency amount={ub.total} />
                      </If>
                      <If condition={!ub.activated}>---</If>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <If condition={ub.activated}>
                        <FormattedMessage
                          id="roomBook.roomBookUpgradeBundles.dialog.status.activated"
                          defaultMessage="roomBook.roomBookUpgradeBundles.table.status.activated"
                        />
                      </If>
                      <If condition={!ub.activated}>
                        <FormattedMessage
                          id="roomBook.roomBookUpgradeBundles.dialog.status.deactivated"
                          defaultMessage="roomBook.roomBookUpgradeBundles.table.status.deactivated"
                        />
                      </If>
                    </Table.Cell>
                    <Table.Cell>
                      <UpgradeBundleButton upgradeBundle={ub} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic type="button" onClick={handleClose}>
            <FormattedMessage
              id="meta.actions.close"
              defaultMessage="meta.actions.close"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

UpgradeBundlesDialog.propTypes = {
  trigger: PropTypes.node.isRequired
};

export default UpgradeBundlesDialog;
