import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

class ActivityAssignmentDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    ctxModel: PropTypes.object,
    ctxCtrl: PropTypes.object,
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.ctxModel.needsReassignment(),
      info: props.ctxModel.getAssignmentInfo()
    };
  }

  handleToggleDialog(open) {
    this.setState({ ...this.state, open, loading: false }, () => {
      if (!open && this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  handleAssignmentFn(userId) {
    return () => {
      const { ctxCtrl } = this.props;
      this.setState({ loading: true });
      return ctxCtrl.reassignActivity(userId).then(() => {
        this.setState({ loading: false, open: false });
      });
    };
  }

  render() {
    const { i18n } = this.props;
    const { info } = this.state;

    return (
      <Modal
        data-component="cartTransitionDialog"
        size="small"
        closeIcon={false}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id="activity.assignments.suggestion.title"
            defaultMessage="activity.assignments.suggestion.title"
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="activity.assignments.suggestion.message"
              defaultMessage="activity.assignments.suggestion.message"
              values={{ state: info.state.label, role: info.role.label }}
            />
          </p>
          {this.renderAssigness()}
          {this.renderFilterToggle()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            basic
            id="cancel"
            onClick={() => this.handleToggleDialog(false)}
            content={i18n["meta.actions.cancel"]}
            loading={this.state.loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  renderFilterToggle() {
    const { showAll } = this.state;

    if (showAll) {
      return null;
    }
    return (
      <a
        onClick={() => {
          this.setState({ showAll: true });
        }}
        role="link"
      >
        <FormattedMessage
          id="meta.actions.showAll"
          defaultMessage="meta.actions.showAll"
        />
      </a>
    );
  }

  renderAssigness() {
    return this.getAssigneesByRole().map(item => {
      return (
        <div key={item.id}>
          <Header as="h4">{item.label}</Header>
          <div style={{ marginBottom: "15px" }}>
            {item.assignees.map(assignee => {
              return (
                <Button
                  key={assignee.id}
                  content={assignee.label}
                  icon="user"
                  labelPosition="left"
                  loading={this.state.loading}
                  onClick={this.handleAssignmentFn(assignee.id)}
                />
              );
            })}
          </div>
        </div>
      );
    });
  }

  getAssigneesByRole() {
    const { showAll } = this.state;
    return this.state.info.assignees.filter(role => {
      return showAll || role.id === this.state.info.role.id;
    });
  }
}

export default ActivityAssignmentDialog;
