import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getProjectAnalysisTopics = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "project_analysis_topics", []), "position");
  }
);

export default getProjectAnalysisTopics;
