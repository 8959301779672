import { createSelector } from "reselect";
import { get, sortBy } from "lodash";

import { getPageContent } from "shared/selectors/base";

const getRecipients = (recipients, mode) =>
  recipients
    .filter(r => {
      return r.mode === mode;
    })
    .map(r => {
      return `${r.type}:${r.id}`;
    });

export const getMessageDrafts = createSelector([getPageContent], pageContent =>
  sortBy(
    get(pageContent, "message_drafts", []).map(draft => ({
      ...draft,
      originalRecipients: draft.recipients,
      recipients: getRecipients(draft.recipients, "to"),
      cc: getRecipients(draft.recipients, "cc"),
      bcc: getRecipients(draft.recipients, "bcc")
    })),
    obj => -new Date(obj.updated_at).getTime()
  )
);

export const getMessageDraftsNoActivities = createSelector(
  [getMessageDrafts],
  messageDrafts => messageDrafts.filter(md => !md.activity_id)
);

export const getMessageDraftsForThreads = createSelector(
  [getMessageDrafts],
  messageDrafts => messageDrafts.filter(md => !!md.message_thread_id)
);

export const getMessageDraftForCurrentThread = createSelector(
  [getMessageDrafts],
  messageDrafts => {
    const lastIndex = messageDrafts.length ? messageDrafts.length - 1 : 0;
    return messageDrafts[lastIndex];
  }
);
