import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { If } from "../../../shared/components/elements/Conditions";
import "./messageBodyV2.scss";
import getSeparatedMessage from "./getSeparatedMessage";

const useSeparateMessage = text => {
  return useMemo(() => getSeparatedMessage(text), [text]);
};

const MessageBodyV2 = ({ message: email }) => {
  const [conversationExpanded, setConversationExpanded] = useState(false);
  const text = useMemo(() => {
    if (email.body_text && email.body_html === email.body_text) {
      return email.body_text.replaceAll(/\r\n/g, "<br/>");
    }
    return (
      email.body_html || (email.body_text || "").replaceAll(/\n/g, "<br/>")
    );
  }, [email]);
  const { message, quote, separated } = useSeparateMessage(text);

  return (
    <div
      className="htmlMessageContainer messageBody"
      style={{ whiteSpace: "unset !important" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <If condition={separated}>
        <div className="margin top bottom medium">
          <Button
            size="mini"
            basic
            color="grey"
            onClick={() => setConversationExpanded(!conversationExpanded)}
          >
            ...
          </Button>
        </div>
      </If>
      <If condition={conversationExpanded}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: quote }} />
      </If>
    </div>
  );
};

MessageBodyV2.propTypes = {
  message: PropTypes.shape({
    body_html: PropTypes.string,
    body_text: PropTypes.string
  }).isRequired
};

export default MessageBodyV2;
