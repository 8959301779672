/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { last } from "lodash";
import { IndexRoute, Route } from "react-router";
import { browserHistory } from "../../shared/routes/browserHistory";

import {
  DELETE_PAGE_CONTENT,
  UPDATE_FLASH_ACTION
} from "../../shared/constants/appConstants";

import { resetMessagesFilter } from "../actions/filters/messages";

import PageLayoutWrapper from "../components/pageLayout/PageLayoutWrapper";
import LoginLayout from "../components/pageLayout/LoginLayout";
import ProjectItem from "../components/project/ProjectItem";
import Login from "../components/login/Login2";
import AuthRedirect from "../components/login/AuthRedirect";
import Projects from "../components/project/ProjectCollection";
import Password from "../components/login/Password";
import ActivityItem from "../components/activity/ActivityItem";
import Contractors from "../components/contractor/ContractorCollection";
import NotificationCollection from "../components/notification/NotificationCollection";
import TrashBin from "../components/trashbin/TrashBin";
import ProjectIssues from "../components/project_issues/ProjectIssues";
import Versions from "../components/versions/Versions";
import References from "../components/references/References";
import AccountAdmin from "../components/account/AccountAdmin";
import ZendeskLogin from "../components/zendesk/ZendeskLogin";
import ZendeskLogout from "../components/zendesk/ZendeskLogout";
import ErrorPage from "../components/errorPage/ErrorPage";

import UnitContainer from "../components/unit/UnitContainer";
import RoomBookTemplateContainer from "../components/roomBook/RoomBookTemplateContainer";

import MessagesContainer from "../components/messages/MessagesContainer";
import MessageContainer from "../components/messages/MessageContainer";
import Profile from "../components/profiles/Profile";

import ProductGroup from "../components/productGroup/ProductGroup";
import ProductLayout from "../components/productGroup/ProductLayout";
import ProductList from "../components/productGroup/ProductList";
import SupportUtils from "../components/supportUtils/SupportUtils";
import NoAccessPage from "../components/errorPage/NoAccessPage";
import AccountAdminGuard from "../components/account/AccountAdminGuard";
import ApiDocumentation from "../components/account/apiDocumentation/ApiDocumentation";
import Jobs from "../components/account/Jobs";

let lastState = {
  routes: {},
  params: {},
  location: {}
};

const Routes = dispatch => {
  const _handleRedirects = () => {
    if (window.location.pathname === "/") {
      browserHistory.push("/projects"); // redirect / to /projects
      return true;
    }
    return false;
  };

  const trimTabSegment = (location, tab) => {
    return String(location)
      .replace(`/${String(tab)}`, "")
      .replace(/\/\//, "/")
      .replace(/\/$/, "");
  };

  const onlyTabChanged = (nextState, lastState) => {
    // TODO: remove this workaroud after activities have moved to the unit tab
    if (
      last(lastState.routes)?.path === ":projectId/units/:id(/:tab)" &&
      last(nextState.routes)?.path ===
        ":projectId/activities/:activityId(/:tab)"
    ) {
      return true;
    }

    if (
      lastState.params.tab &&
      nextState.params.tab &&
      lastState.params.tab === nextState.params.tab
    ) {
      return false;
    }
    const s1 = trimTabSegment(
      lastState.location.pathname,
      lastState.params.tab
    );
    const s2 = trimTabSegment(
      nextState.location.pathname,
      nextState.params.tab
    );
    return s1 === s2;
  };

  const resetPageContentState = nextState => {
    if (onlyTabChanged(nextState, lastState)) {
      lastState = nextState;
      return;
    }
    lastState = nextState;
    if (!_handleRedirects()) {
      dispatch({
        type: DELETE_PAGE_CONTENT
      });
      dispatch({
        type: UPDATE_FLASH_ACTION,
        payload: null
      });
    }
  };

  const resetMessageFilter = () => {
    resetMessagesFilter(dispatch);
  };

  return (
    <>
      <Route path="react_router" component={LoginLayout}>
        <div style={{ height: "100vh", overflow: "hidden" }}>
          <Route
            path="/users/login"
            name="menu.main.login"
            component={Login}
            onEnter={resetPageContentState}
          />
        </div>
        <Route
          path="/auth_redirect"
          name="menu.main.auth_redirect"
          component={AuthRedirect}
          onEnter={resetPageContentState}
        />
      </Route>

      <Route path="react_router" component={PageLayoutWrapper}>
        <Route
          path="/"
          name="project.title.other"
          component={Projects}
          onEnter={resetPageContentState}
        />
        {/* route for noAccess */}
        <Route
          path="/noAccess"
          name="project.title.other"
          component={NoAccessPage}
          onEnter={resetPageContentState}
        />

        <Route
          path="/users/password"
          name="menu.main.password"
          component={Password}
          onEnter={resetPageContentState}
        />

        <Route path="/projects">
          <IndexRoute
            name="project.title.other"
            component={Projects}
            onEnter={resetPageContentState}
          />

          <Route
            path=":projectId/product_groups(/:productGroupId)"
            name="product_group.title.other"
            component={ProductGroup}
          />

          <Route
            path=":projectId(/:tab)"
            name="project.title.one"
            component={ProjectItem}
            onEnter={resetPageContentState}
          />

          <Route
            path=":projectId/units/:id(/:tab)"
            name="unit"
            component={UnitContainer}
            onEnter={resetPageContentState}
          />
          <Route
            path=":projectId/room_books/:id"
            name="roomBook"
            component={RoomBookTemplateContainer}
            onEnter={resetPageContentState}
          />
          <Route
            path=":projectId/activities/:activityId(/:tab)"
            name="activity.title.one"
            component={ActivityItem}
            onEnter={resetPageContentState}
          />
        </Route>

        <Route path="/products" component={ProductLayout}>
          <IndexRoute name="products.title.other" component={ProductList} />
          <Route
            path="all"
            name="products.title.other"
            component={ProductList}
          />
          <Route
            path=":catalogId/design_lines(/:designLineList)"
            name="product_group.title.other"
            component={ProductGroup}
          />
          <Route
            path=":catalogId/product_groups(/:trade)"
            name="product_group.title.other"
            component={ProductGroup}
          />
          <Route
            path=":catalogId/product_groups(/:trade/:productGroupId)"
            name="product_group.title.other"
            component={ProductGroup}
          />
          <Route
            path=":supplier_id"
            name="products.title.other"
            component={ProductList}
          />
          <Route
            path=":supplier_id/:product_id"
            name="products.title.one"
            component={ProductList}
          />
        </Route>

        <Route
          path="/contractors"
          name="contractor"
          component={Contractors}
          onEnter={resetPageContentState}
        />

        <Route path="/messages" name="messages" onEnter={resetMessageFilter}>
          <IndexRoute
            name="message.title.other"
            component={MessagesContainer}
            onEnter={resetPageContentState}
          />
          <Route
            path=":id"
            name="project.title.one"
            component={MessageContainer}
            onEnter={resetPageContentState}
          />
        </Route>

        <Route
          path="/notifications"
          name="notifications.title.other"
          component={NotificationCollection}
          onEnter={resetPageContentState}
        />

        <Route
          path="/project_issues"
          name="projectIssues.title"
          component={ProjectIssues}
          onEnter={resetPageContentState}
        />

        <Route
          path="/trashbin"
          name="trashBin.title"
          component={TrashBin}
          onEnter={resetPageContentState}
        />

        <Route
          path="/versions"
          name="versions.title"
          component={Versions}
          onEnter={resetPageContentState}
        />

        <Route
          path="/references"
          name="references.title"
          component={References}
          onEnter={resetPageContentState}
        />

        <Route
          path="/api_documentation(/:id)"
          name="menu.main.API"
          component={ApiDocumentation}
          onEnter={resetPageContentState}
        />

        <Route
          path="/file_imports"
          name="menu.main.jobs"
          component={Jobs}
          onEnter={resetPageContentState}
        />

        <Route
          path="/account(/:tab)"
          name="account.title.one"
          component={() => (
            <AccountAdminGuard>
              <AccountAdmin />
            </AccountAdminGuard>
          )}
          onEnter={resetPageContentState}
        />
        <Route
          path="/account(/:tab)/:id"
          name="account.title.one"
          component={() => (
            <AccountAdminGuard>
              <AccountAdmin />
            </AccountAdminGuard>
          )}
          onEnter={resetPageContentState}
        />

        <Route
          path="/accounts/:id"
          name="account.title.one"
          component={() => (
            <AccountAdminGuard>
              <AccountAdmin />
            </AccountAdminGuard>
          )}
          onEnter={resetPageContentState}
        />
        <Route
          path="/profile"
          name="profile.title.one"
          component={Profile}
          onEnter={resetPageContentState}
        />

        <Route
          path="/zendesk/login"
          name="zendesk.title.one"
          component={ZendeskLogin}
          onEnter={resetPageContentState}
        />
        <Route
          path="/zendesk/logout"
          name="zendesk.title.one"
          component={ZendeskLogout}
          onEnter={resetPageContentState}
        />

        <Route
          path="/supportUtils"
          name="menu.main.supportUtils"
          component={SupportUtils}
        />

        <Route path="/*" name="error" component={ErrorPage} />
      </Route>

      <Route path="/products" component={ProductLayout}>
        <IndexRoute name="products.title.other" component={ProductList} />
        <Route path="all" name="products.title.other" component={ProductList} />
        <Route
          path=":catalogId/design_lines(/:designLineList)"
          name="product_group.title.other"
          component={ProductGroup}
        />
        <Route
          path=":catalogId/product_groups(/:trade)"
          name="product_group.title.other"
          component={ProductGroup}
        />
        <Route
          path=":catalogId/product_groups(/:trade/:productGroupId)"
          name="product_group.title.other"
          component={ProductGroup}
        />
        <Route
          path=":supplier_id"
          name="products.title.other"
          component={ProductList}
        />
        <Route
          path=":supplier_id/:product_id"
          name="products.title.one"
          component={ProductList}
        />
      </Route>
      <Route
        path="/contractors"
        name="contractor"
        component={Contractors}
        onEnter={resetPageContentState}
      />

      <Route path="/messages" name="messages" onEnter={resetMessageFilter}>
        <IndexRoute
          name="message.title.other"
          component={MessagesContainer}
          onEnter={resetPageContentState}
        />
        <Route
          path=":id"
          name="project.title.one"
          component={MessageContainer}
          onEnter={resetPageContentState}
        />
      </Route>

      <Route
        path="/notifications"
        name="notifications.title.other"
        component={NotificationCollection}
        onEnter={resetPageContentState}
      />

      <Route
        path="/trashbin"
        name="trashBin.title"
        component={TrashBin}
        onEnter={resetPageContentState}
      />

      <Route
        path="/versions"
        name="versions.title"
        component={Versions}
        onEnter={resetPageContentState}
      />

      <Route
        path="/account(/:tab)"
        name="account.title.one"
        component={() => (
          <AccountAdminGuard>
            <AccountAdmin />
          </AccountAdminGuard>
        )}
        onEnter={resetPageContentState}
      />

      <Route
        path="/accounts/:id"
        name="account.title.one"
        component={() => (
          <AccountAdminGuard>
            <AccountAdmin />
          </AccountAdminGuard>
        )}
        onEnter={resetPageContentState}
      />
      <Route
        path="/profile"
        name="profile.title.one"
        component={Profile}
        onEnter={resetPageContentState}
      />

      <Route
        path="/zendesk/login"
        name="zendesk.title.one"
        component={ZendeskLogin}
        onEnter={resetPageContentState}
      />
      <Route
        path="/zendesk/logout"
        name="zendesk.title.one"
        component={ZendeskLogout}
        onEnter={resetPageContentState}
      />

      <Route
        path="/supportUtils"
        name="menu.main.supportUtils"
        component={SupportUtils}
      />

      <Route path="/*" name="error" component={ErrorPage} />
    </>
  );
};

export default Routes;
