import Resources from "shared/actions/resourcesActions";

class ConfiguratorDesignsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "configurator_design",
      `/api/v1/projects/${projectId}/configurator_designs`
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export default ConfiguratorDesignsResource;
