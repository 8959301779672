import "react-dates/initialize";
import { string, func, bool } from "prop-types";
import React, { Component } from "react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

import "react-dates/lib/css/_datepicker.css";

class DateInput extends Component {
  constructor(props) {
    super(props);
    const { value, defaultValue, nullable } = props;
    const initialValue = moment.utc(value || defaultValue);
    this.state = {
      value: nullable && !props.value ? null : initialValue,
      focused: false
    };
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue } = this.props;
    const nextValue = value || defaultValue;
    const prevValue = prevProps.value || prevProps.defaultValue;
    if (nextValue !== prevValue) {
      this.writeValueToState(moment.utc(nextValue));
    }
  }

  writeValueToState = value => {
    this.setState({ value });
  };

  handleChange = value => {
    if (this.props.onChange) {
      this.props.onChange(this.makeEvent(), {
        value: value && value.format("YYYY-MM-DD")
      });
    }
    this.setState({ value });
  };

  handleFocusChange = ({ focused }) => {
    if (!focused) {
      if (this.props.onBlur) {
        const { value } = this.state;
        this.props.onBlur(this.makeEvent(), {
          value: value && value.format("YYYY-MM-DD")
        });
      }
    }
    this.setState({ focused });
  };

  makeEvent = () => {
    const { id, name } = this.props;
    return {
      target: {
        type: "input",
        id,
        name
      }
    };
  };

  render() {
    const {
      id,
      name,
      nullable,
      placeholder,
      displayFormat,
      noBorder,
      small
    } = this.props;
    const { value, focused } = this.state;
    return (
      <div
        // className="ui input"
        style={{
          width: "100%"
        }}
      >
        <SingleDatePicker
          id={id}
          name={name}
          date={value}
          isOutsideRange={() => false}
          onDateChange={this.handleChange}
          numberOfMonths={1}
          placeholder={placeholder}
          displayFormat={displayFormat}
          focused={focused}
          onFocusChange={this.handleFocusChange}
          noBorder={noBorder}
          required={!nullable}
          showClearDate={nullable}
          small={small}
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  value: string,
  defaultValue: string,
  onChange: func,
  onBlur: func,
  id: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  displayFormat: string,
  nullable: bool,
  noBorder: bool,
  small: bool
};

DateInput.defaultProps = {
  nullable: false,
  placeholder: "Datum des Fristendes",
  displayFormat: "DD.MM.YYYY",
  onChange: () => {},
  onBlur: () => {},
  value: null,
  defaultValue: null,
  noBorder: true,
  small: false
};

export default DateInput;
