import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Label,
  Message,
  Modal,
  Placeholder,
  Table
} from "semantic-ui-react";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { getAccount, getSortedProducts } from "builder_portal/selectors";
import ProductInfoItem from "builder_portal/components/product/ProductInfoItem";
import { getProductGroups } from "shared/selectors/unit/productData";
import { arrayOf, instanceOf, node, string } from "prop-types";
import {
  ProductGroupShape,
  PriceCatalogShape,
  ProductShape,
  TradeLabelShape
} from "shared/shapes";
import TradeLabel from "builder_portal/components/helpers/TradeLabel";
import ProductGroupResource from "builder_portal/actions/productGroupActions";
import Growl from "builder_portal/actions/growlActions";
import { isFinite, compact } from "lodash";
import XLSX from "xlsx";
import {
  makeGetCatalogNameById,
  getPriceCatalogsById
} from "shared/selectors/catalog";
import { defaultRichTextTags } from "builder_portal/helpers/defaultRichTextTags";
import { Account } from "shared/models/account";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import { withRouter } from "../../../../shared/helpers/withRouter";
import SyncedFloatInput from "./SyncedFloatInput";

import "./editAllPrices.scss";

const renderFilterLabel = label => ({
  color: label.color,
  content: label.text,
  icon: label.icon
});

const renderLoadingTableBodyRows = () => {
  return [1, 2, 3, 4].map(v => (
    <Table.Row className="isFirstElement" key={v}>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Line length="very short" />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Line length="very short" />
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line length="very short" />
          </Placeholder.Header>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  ));
};

const renderNoResults = () => {
  return (
    <Message icon info size="huge">
      <Icon disabled name="search" />
      <FormattedMessage
        id="edit_product_prices.filter.no_result_found"
        defaultMessage="Keine Produkte gefunden."
      />
    </Message>
  );
};

const renderOnboarding = () => {
  return (
    <Message icon info size="huge">
      <Icon disabled name="lightbulb" />
      <FormattedMessage
        id="edit_product_prices.filter.start_search"
        defaultMessage="Um die Suche zu starten, fügen Sie Kriterien zum Filter hinzu."
      />
    </Message>
  );
};

function EditAllPrices({
  productGroups,
  trades,
  productGroupResource,
  products,
  growl,
  children,
  catalogName,
  priceCatalogsById,
  account
}) {
  const [productGroupOptions, setProductGroupOptions] = useState([]);
  const [fullTextOption, setFullTextOptions] = useState([]);
  const [tradeOptions, setTradeOptions] = useState([]);
  const [productRows, setProductRows] = useState([]);
  const [filteredProductRows, setFilteredProductRows] = useState(null);
  const [loadingRows, setLoadingRows] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredTrade, setFilteredTrade] = useState(false); // TODO : handle multiple trades
  const { formatMessage } = useIntl();

  const handleBlur = ({
    productPriceId,
    productGroupId,
    costsAttributeTrade,
    cost,
    excessCost,
    price,
    excessPrice,
    originalCostAttributes
  }) => {
    const prevProductGroups = changedValues;
    const prevPrices = prevProductGroups[productGroupId]?.prices;
    const prevPrice = prevProductGroups[productGroupId]?.prices[productPriceId];
    const prevCostsAttributes = prevPrice?.costsAttributes;
    const prevCostsAttribute = prevPrice?.costsAttributes[costsAttributeTrade];

    const nextCostsAttributes = {
      ...originalCostAttributes,
      ...prevCostsAttributes,
      [costsAttributeTrade]: {
        cost: isFinite(cost) ? cost : prevCostsAttribute?.cost,
        excessCost:
          excessCost === null || isFinite(excessCost)
            ? excessCost
            : prevCostsAttribute?.excessCost
      }
    };
    const nextPrices = {
      ...prevPrices,
      [productPriceId]: {
        price: isFinite(price) ? price : prevPrice?.price,
        excessPrice:
          excessPrice === null || isFinite(excessPrice)
            ? excessPrice
            : prevPrice?.excessPrice,
        costsAttributes: nextCostsAttributes
      }
    };
    const nextProductGroups = {
      ...prevProductGroups,
      [productGroupId]: {
        prices: nextPrices
      }
    };

    setChangedValues(nextProductGroups);
  };

  const csvDataFor = rows => {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR"
    });

    return (
      rows
        .map(item => {
          return item.costsAttributes
            .filter(row => {
              if (!filteredTrade) return true;
              return row.costsAttributeTrade === filteredTrade;
            })
            .map(cost => {
              const specs =
                item.product.specifications.find(p => p.trade === filteredTrade)
                  ?.specification || "";

              return {
                [formatMessage({
                  id: "product_group.title.one"
                })]: item.productGroup.name,
                [formatMessage({
                  id: "product.title.one"
                })]: item.product.name,
                [formatMessage({
                  id: "product.attributes.description.label"
                })]: item.product.description,
                [formatMessage({
                  id: "product.attributes.description.specifications"
                })]: specs,
                [formatMessage({
                  id: "product_group.attributes.price_strategy.alternate_label"
                })]: formatMessage({
                  id: `product_group.price_strategies.${item.productGroup.price_strategy}.label`
                }),
                [formatMessage({
                  id: "product_price.attributes.cost.label"
                })]: formatter.format(cost.cost) || 0,
                [formatMessage({
                  id: "product_price.attributes.gross_cost.label"
                })]: formatter.format(cost.cost * 1.19) || 0
              };
            });
        })
        .flat() || []
    );
  };

  const handleDownload = data => {
    const workSheetName = catalogName
      .replace(/[:\\/?*[\]]/g, " ") // remove not allowed chars
      .replace(/ +(?= )/g, "") // remove double whitespaces
      .trim() // remove leading and trailing whitespaces
      .substring(0, 31); // only 31 chars allowed
    const tradeLabel =
      trades.filter(t => {
        return t.value === filteredTrade;
      })[0]?.text || "";

    const worksheet = XLSX.utils.aoa_to_sheet([[workSheetName], [tradeLabel]]);
    XLSX.utils.sheet_add_json(worksheet, csvDataFor(data), { origin: "A4" });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, workSheetName);
    XLSX.writeFile(
      workbook,
      `${formatMessage({
        id: "product.title.one"
      })}.xlsx`,
      {}
    );
  };

  const handleSave = () => {
    setLoadingSubmit(true);
    Promise.all(
      Object.keys(changedValues).map(productGroupId => {
        const nextPrices = Object.keys(changedValues[productGroupId].prices)
          .map(productPriceId => {
            const nextPrice = {};

            const { excessPrice, price } = changedValues[productGroupId].prices[
              productPriceId
            ];
            const costsAttributes = Object.keys(
              changedValues[productGroupId].prices[productPriceId]
                .costsAttributes
            )
              .map(costsAttributeTrade => {
                const { cost, excessCost } = changedValues[
                  productGroupId
                ].prices[productPriceId].costsAttributes[costsAttributeTrade];
                const nextCostsAttribute = {};
                if (isFinite(cost)) {
                  nextCostsAttribute.trade = costsAttributeTrade;
                  nextCostsAttribute.cost = cost;
                }
                if (excessCost === null || isFinite(excessCost)) {
                  nextCostsAttribute.trade = costsAttributeTrade;
                  nextCostsAttribute.excess_cost = excessCost;
                }

                return nextCostsAttribute.trade
                  ? nextCostsAttribute
                  : undefined;
              })
              .filter(nullcheck => !!nullcheck);

            if (isFinite(price)) {
              nextPrice.id = productPriceId;
              nextPrice.price = price;
            }

            if (excessPrice === null || isFinite(excessPrice)) {
              nextPrice.id = productPriceId;
              nextPrice.excess_price = excessPrice;
            }

            if (costsAttributes.length > 0) {
              nextPrice.id = productPriceId;
              nextPrice.costs_attributes = costsAttributes;
            }
            return nextPrice.id ? nextPrice : undefined;
          })
          .filter(nullcheck => !!nullcheck);

        return productGroupResource.updatePrices(productGroupId, {
          product_group: {
            prices: nextPrices
          }
        });
      })
    )
      .then(() => productGroupResource.fetchAll())
      .then(() => {
        growl.success(
          "meta.states.saved",
          "edit_product_prices.action.success"
        );
        setLoadingSubmit(false);
        setIsModalOpen(false);
      })
      .catch(() => {
        growl.error("login.error.title", "login.error.unknown");
        setLoadingSubmit(false);
      });
  };

  useEffect(() => {
    if (trades.length > 0) {
      setTradeOptions(
        trades.map(trade => ({
          key: trade.key,
          value: `trade:${trade.value}`,
          text: formatMessage(
            {
              id: "edit_product_prices.filter.options.trade",
              defaultMessage: "Gewerk | {tradeName}"
            },
            { tradeName: trade.text }
          ),
          icon: "hand paper",
          color: "brown"
        }))
      );
    }
  }, [trades]);

  useLayoutEffect(() => {
    if (products.length > 0) {
      const flattenPrices = productGroups.flatMap(productGroup => {
        return productGroup.product_options.flatMap(option => {
          return option.product_prices.map(price => ({ ...option, ...price }));
        });
      });

      const nextProductRows = flattenPrices.map(
        ({
          id: productPriceId,
          product_id: productId,
          product_group_id: productGroupId,
          price_catalog_id: priceCatalogId,
          is_default: isDefault,
          price,
          excess_price: excessPrice,
          costs_attributes: originCostsAttributes,
          updated_at: productPriceUpdatedAt
        }) => {
          const product = products.find(p => p.id === productId);
          const productGroup = productGroups.find(
            pg => pg.id === productGroupId
          );
          const priceCatalogName = priceCatalogsById[priceCatalogId]?.name;
          const costsAttributes = originCostsAttributes.map(
            (
              { cost, excess_cost: excessCost, trade: costsAttributeTrade },
              index
            ) => {
              const isFirstElement = index === 0;

              return {
                cost,
                excessCost,
                costsAttributeTrade,
                isFirstElement
              };
            }
          );

          return {
            productPriceId,
            productGroupId,
            priceCatalogId,
            priceCatalogName,
            product,
            productGroup,
            isDefault,
            price,
            excessPrice,
            productPriceUpdatedAt,
            costsAttributes
          };
        }
      );
      setProductRows(nextProductRows);
      setLoadingRows(false);
    }
  }, [products]);

  useEffect(() => {
    if (productGroups.length > 0) {
      setProductGroupOptions(
        productGroups.map(pg => ({
          key: pg.id,
          value: `productGroup:${pg.id}`,
          text: formatMessage(
            {
              id: "edit_product_prices.filter.options.product_group",
              defaultMessage: "Produktgruppe | {pgName}"
            },
            { pgName: pg.name }
          ),
          content: formatMessage(
            {
              id: "edit_product_prices.filter.options.product_group",
              defaultMessage: "Produktgruppe | {pgName}"
            },
            { pgName: compact([pg.name, pg.desciption]).join(" | ") }
          ),
          icon: "images outline",
          color: "blue"
        }))
      );
    }
  }, [productGroups]);

  const renderProductRowFilter = () => {
    return (
      <Dropdown
        closeOnChange
        placeholder={formatMessage({
          id: "edit_product_prices.filter.placeholder",
          defaultMessage:
            "Geben Sie Kriterien ein, um die Produkte zu Filtern: Gewerk, Produktgruppe, Textsuche..."
        })}
        icon="search"
        fluid
        multiple
        search
        selection
        renderLabel={renderFilterLabel}
        onChange={(_, { value }) => {
          const nextFilteredProductRows =
            value.length > 0
              ? productRows.filter(row => {
                  const fullTextSources = [
                    // productGroupName and Trade are already searched by exclusive filter options
                    row.product.name,
                    row.product.sku,
                    row.product.series,
                    row.product.supplier
                  ]
                    .map(sourceString => sourceString?.toLowerCase())
                    .filter(nullfilter => !!nullfilter);
                  return value.reduce((filterConclusion, abstractFilter) => {
                    const [filterKey, filterValue] = abstractFilter.split(":");
                    let filterDecision = true;

                    // heads up: filterValue is always a String
                    switch (filterKey) {
                      case "isDefault":
                        filterDecision = filterValue === `${row.isDefault}`;
                        break;
                      case "productGroup":
                        filterDecision =
                          filterValue === `${row.productGroup.id}`;
                        break;
                      case "trade":
                        filterDecision = row.costsAttributes
                          .map(cA => cA.costsAttributeTrade)
                          .includes(filterValue);
                        setFilteredTrade(filterValue);
                        break;
                      case "fullText":
                        filterDecision = fullTextSources.some(sourceString =>
                          sourceString.includes(filterValue.toLowerCase())
                        );
                        break;
                      default:
                        throw new Error(
                          `filter Key "${filterKey}" not implemented`
                        );
                    }
                    return filterConclusion && filterDecision;
                  }, true);
                })
              : null;
          setFilteredProductRows(nextFilteredProductRows);
        }}
        onSearchChange={(_, { searchQuery }) => {
          setFullTextOptions([
            {
              key: "fullText",
              value: `fullText:${searchQuery}`,
              text: formatMessage(
                {
                  id: "edit_product_prices.filter.options.fullText",
                  defaultMessage: "Text | {searchQuery}"
                },
                { searchQuery }
              ),
              icon: "text cursor",
              color: "grey"
            }
          ]);
        }}
        options={[
          {
            key: "isDefault",
            value: "isDefault:true",
            text: formatMessage({
              id: "edit_product_prices.filter.options.onlyDefault",
              defaultMessage: "nur Standardprodukte"
            }),
            icon: "check circle outline",
            color: "green"
          },
          ...productGroupOptions,
          ...tradeOptions,
          ...fullTextOption
        ]}
      />
    );
  };

  return (
    <Modal
      data-component="editAllPrices"
      open={isModalOpen}
      onOpen={() => {
        setIsModalOpen(true);
        setFilteredProductRows(null);
        setChangedValues({});
      }}
      onClose={() => setIsModalOpen(false)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      centered={false}
      trigger={children}
      closeIcon
      size="fullscreen"
    >
      <Modal.Header>
        <Label color="purple" ribbon>
          Beta
        </Label>
        <FormattedMessage
          id="edit_product_prices.header.title"
          defaultMessage="Produktpreise für <i>{catalogName}</i> bearbeiten"
          values={{ ...defaultRichTextTags, catalogName }}
        />
      </Modal.Header>
      <Modal.Content>
        <Header size="medium">{renderProductRowFilter()}</Header>
      </Modal.Content>
      <Modal.Content scrolling>
        {filteredProductRows === null ? renderOnboarding() : null}
        {filteredProductRows?.length === 0 ? renderNoResults() : null}
        {filteredProductRows?.length > 0 ? (
          <Table attached>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={8} colSpan={4} textAlign="center">
                  <FormattedMessage
                    id="edit_product_prices.table.header.product_information"
                    defaultMessage="Produktinformation"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell width={3} colSpan={2} textAlign="center">
                  <FormattedMessageWithFlag
                    featureToggleName="show_price_net_label"
                    id="edit_product_prices.table.header.price.net"
                    alternativeId="edit_product_prices.table.header.price.gross"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell width={5} colSpan={3} textAlign="center">
                  <FormattedMessage
                    id="edit_product_prices.table.header.cost_net"
                    defaultMessage="Nachunternehmen Kosten Netto"
                  />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="center">
                  <FormattedMessage
                    id="product.attributes.description.label"
                    defaultMessage="Produktbeschreibung"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="product_group.title.one"
                    defaultMessage="Produktgruppe"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={2} collapsing>
                  <FormattedMessage
                    id="batchEdit.labels.default_product"
                    defaultMessage="Standardprodukt"
                  />
                  ,&nbsp;
                  <br />
                  <FormattedMessage
                    id="product_group.attributes.price_strategy.label"
                    defaultMessage="Preisberechnung"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="roomBook.lineItems.attributes.default_quantity.label"
                    defaultMessage="Standardmenge"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="roomBook.lineItems.attributes.costs.excess_quantity.label"
                    defaultMessage="Mehrmenge"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <FormattedMessage
                    id="trades.title.one"
                    defaultMessage="Gewerk"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="roomBook.lineItems.attributes.default_quantity.label"
                    defaultMessage="Standardmenge"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="roomBook.lineItems.attributes.costs.excess_quantity.label"
                    defaultMessage="Mehrmenge"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loadingRows
                ? renderLoadingTableBodyRows()
                : filteredProductRows.map(
                    ({
                      productPriceId,
                      product,
                      productGroupId,
                      priceCatalogName,
                      productGroup,
                      isDefault,
                      price,
                      excessPrice,
                      costsAttributes
                    }) => {
                      const changedProductPrice =
                        changedValues[productGroupId]?.prices[productPriceId];
                      const changedPrice = changedProductPrice?.price;
                      const changedExcessPrice =
                        changedProductPrice?.excessPrice;
                      const hasExcessPrice =
                        (changedExcessPrice !== null &&
                          isFinite(changedExcessPrice)) ||
                        (changedExcessPrice === undefined &&
                          isFinite(excessPrice));

                      const originalCostAttributes = costsAttributes.reduce(
                        (accu, { costsAttributeTrade, cost, excessCost }) => ({
                          ...accu,
                          [costsAttributeTrade]: {
                            cost,
                            excessCost
                          }
                        }),
                        {}
                      );

                      return costsAttributes.map(
                        ({
                          costsAttributeTrade,
                          cost,
                          excessCost,
                          isFirstElement
                        }) => {
                          const changedCostsAttributes =
                            changedProductPrice?.costsAttributes[
                              costsAttributeTrade
                            ];
                          const changedCost = changedCostsAttributes?.cost;
                          const changedExcessCost =
                            changedCostsAttributes?.excessCost;
                          const hasExcessCost =
                            (changedExcessCost !== null &&
                              isFinite(changedExcessCost)) ||
                            (changedExcessCost === undefined &&
                              isFinite(excessCost));

                          return (
                            <Fragment
                              key={`${productPriceId}-${costsAttributeTrade}`}
                            >
                              <Table.Row
                                className={
                                  isFirstElement ? "isFirstElement" : ""
                                }
                              >
                                {isFirstElement && (
                                  <>
                                    <Table.Cell
                                      rowSpan={costsAttributes.length}
                                    >
                                      <div className="productDetails">
                                        <ProductInfoItem
                                          product={product}
                                          renderSku
                                          mode="default"
                                        />
                                      </div>
                                      <div />
                                    </Table.Cell>
                                    <Table.Cell
                                      rowSpan={costsAttributes.length}
                                    >
                                      <div>{productGroup.name}</div>
                                      <Label
                                        basic
                                        size="tiny"
                                        title="Preisliste"
                                      >
                                        {priceCatalogName}
                                      </Label>
                                    </Table.Cell>
                                    <Table.Cell
                                      collapsing
                                      rowSpan={costsAttributes.length}
                                    >
                                      <Icon
                                        name={`${
                                          isDefault ? "check " : ""
                                        }circle outline`}
                                      />
                                    </Table.Cell>
                                    <Table.Cell
                                      collapsing
                                      rowSpan={costsAttributes.length}
                                    >
                                      <FormattedMessage
                                        id={`roomBook.priceStrategyLong.${productGroup.price_strategy}`}
                                        defaultMessage="Preisstrategie"
                                      />
                                    </Table.Cell>
                                    <Table.Cell
                                      rowSpan={costsAttributes.length}
                                      verticalAlign={
                                        costsAttributes.length > 1
                                          ? "top"
                                          : "middle"
                                      }
                                    >
                                      <CurrencyInput
                                        resettable
                                        value={changedPrice ?? price}
                                        fluid
                                        size="small"
                                        onBlur={(_, { value }) =>
                                          handleBlur({
                                            productPriceId,
                                            productGroupId,
                                            price: value
                                          })
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell
                                      rowSpan={costsAttributes.length}
                                      verticalAlign={
                                        costsAttributes.length > 1
                                          ? "top"
                                          : "middle"
                                      }
                                    >
                                      <SyncedFloatInput
                                        isSynced={!hasExcessPrice}
                                        syncedValue={changedPrice ?? price}
                                        value={
                                          changedExcessPrice ?? excessPrice
                                        }
                                        enableSync={() => {
                                          // null excessPrice will sync the Input
                                          handleBlur({
                                            productPriceId,
                                            productGroupId,
                                            excessPrice: null
                                          });
                                        }}
                                        disableSync={() => {
                                          // non-null excessPrice will unsync Input
                                          handleBlur({
                                            productPriceId,
                                            productGroupId,
                                            // set to price, to keep reset-logic intuitive for humans :)
                                            excessPrice: changedPrice ?? price
                                          });
                                        }}
                                        onBlur={(_, { value }) =>
                                          handleBlur({
                                            productPriceId,
                                            productGroupId,
                                            excessPrice: value
                                          })
                                        }
                                      />
                                    </Table.Cell>
                                  </>
                                )}
                                <Table.Cell
                                  verticalAlign="middle"
                                  textAlign="right"
                                >
                                  <strong>
                                    <TradeLabel
                                      id={costsAttributeTrade}
                                      trades={trades}
                                    />
                                  </strong>
                                </Table.Cell>
                                <Table.Cell>
                                  <CurrencyInput
                                    resettable
                                    value={changedCost ?? cost}
                                    fluid
                                    size="small"
                                    onBlur={(_, { value }) =>
                                      handleBlur({
                                        productPriceId,
                                        productGroupId,
                                        costsAttributeTrade,
                                        cost: value,
                                        originalCostAttributes
                                      })
                                    }
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <SyncedFloatInput
                                    isSynced={!hasExcessCost}
                                    syncedValue={changedCost ?? cost}
                                    value={changedExcessCost ?? excessCost}
                                    enableSync={() => {
                                      // null excessCost will sync the Input
                                      handleBlur({
                                        productPriceId,
                                        productGroupId,
                                        costsAttributeTrade,
                                        excessCost: null,
                                        originalCostAttributes
                                      });
                                    }}
                                    disableSync={() => {
                                      // non-null excessCost will unsync Input
                                      handleBlur({
                                        productPriceId,
                                        productGroupId,
                                        costsAttributeTrade,
                                        // set to cost, to keep reset-logic intuitive for humans :)
                                        excessCost: changedCost ?? cost,
                                        originalCostAttributes
                                      });
                                    }}
                                    onBlur={(_, { value }) =>
                                      handleBlur({
                                        productPriceId,
                                        productGroupId,
                                        costsAttributeTrade,
                                        excessCost: value,
                                        originalCostAttributes
                                      })
                                    }
                                  />
                                </Table.Cell>
                              </Table.Row>
                            </Fragment>
                          );
                        }
                      );
                    }
                  )}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan={9}>
                  <FormattedMessage
                    id="edit_product_prices.filter.result_count"
                    defaultMessage="gefundene Ergebnisse: {count} {count, plural, one {Produkt} other {Produkte}}"
                    values={{
                      count: filteredProductRows?.length ?? 0
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          icon
          disabled={loadingRows || loadingSubmit}
          onClick={() => setIsModalOpen(false)}
        >
          <Icon name="cancel" />
          <FormattedMessage
            id="meta.actions.cancel"
            defaultMessage="Abbrechen"
          />
        </Button>
        {account.isEnabled("download_filtered_prices") && (
          <Button
            type="button"
            icon
            disabled={!filteredProductRows}
            loading={loadingSubmit}
            onClick={() => handleDownload(filteredProductRows)}
          >
            <Icon name="download" />
            <FormattedMessage
              id="meta.actions.download"
              defaultMessage="Download"
            />
          </Button>
        )}
        <Button
          type="submit"
          positive
          icon
          disabled={
            loadingRows ||
            loadingSubmit ||
            Object.keys(changedValues).length === 0
          }
          loading={loadingSubmit}
          onClick={() => handleSave()}
        >
          <Icon name="save" />
          <FormattedMessage id="meta.actions.save" defaultMessage="Speichern" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

EditAllPrices.defaultProps = {
  children: undefined,
  catalogName: "",
  priceCatalogsById: {}
};

EditAllPrices.propTypes = {
  productGroupResource: instanceOf(ProductGroupResource).isRequired,
  productGroups: arrayOf(ProductGroupShape).isRequired,
  products: arrayOf(ProductShape).isRequired,
  trades: arrayOf(TradeLabelShape).isRequired,
  children: node,
  growl: instanceOf(Growl).isRequired,
  catalogName: string,
  priceCatalogsById: PriceCatalogShape,
  account: instanceOf(Account).isRequired
};

const mapStateToProps = () => {
  const getCatalogNameById = makeGetCatalogNameById();
  return (state, props) => {
    return {
      products: getSortedProducts(state),
      productGroups: getProductGroups(state),
      catalogName: getCatalogNameById(state, {
        catalogId: props.params.catalogId
      }),
      priceCatalogsById: getPriceCatalogsById(state),
      account: getAccount(state)
    };
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    productGroupResource: new ProductGroupResource(
      props.params.catalogId,
      dispatch
    ),
    growl: new Growl(dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAllPrices)
);
