import { UPDATE_MESSAGE } from "shared/constants/appConstants";

export const message = (state = "", action) => {
  switch (action.type) {
    case UPDATE_MESSAGE:
      return action.payload;
    default:
      return state;
  }
};
