import { arrayOf, bool, number, shape, string } from "prop-types";
import { PriceStrategyShape } from "shared/components/configurator/roomBook.shape";
import ProductPriceShape from "./productPrice.shape";
import ProductOptionShape from "./productOption.shape";

const ProductGroupShape = shape({
  id: number,
  name: string,
  desciption: string,
  price_strategy: PriceStrategyShape,
  trades: arrayOf(string),
  deletable: bool,
  portal_offer: bool,
  quantity_on_request: bool,
  created_at: string,
  updated_at: string,
  prices: arrayOf(ProductPriceShape),
  product_options: arrayOf(ProductOptionShape),
  thumb_url: string,
  external_id: string
});
export default ProductGroupShape;
