/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { withRouter } from "shared/helpers/withRouter";
import { node } from "prop-types";
import PageLayout from "./PageLayout";
import PageLayoutV2 from "./PageLayoutV2";

const PageLayoutWrapper = props => {
  return (
    <>
      <FeatureToggleActive featureToggleName="show_page_layout_v2">
        <PageLayoutV2>{props.children}</PageLayoutV2>
      </FeatureToggleActive>
      <FeatureToggle featureToggleName="show_page_layout_v2" disabled>
        <PageLayout {...props} />
      </FeatureToggle>
    </>
  );
};

PageLayoutWrapper.propTypes = {
  children: node.isRequired
};

export default withRouter(PageLayoutWrapper);
