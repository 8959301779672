import Resources from "shared/actions/resourcesActions";
import apiRequest from "shared/network/apiRequest";

export default class ProductGroupWarningsResource extends Resources {
  constructor(catalogId, productGroupId, dispatch) {
    super(
      dispatch,
      "product_group_warning",
      `/api/v1/project_catalogs/${catalogId}/product_groups/${productGroupId}/warnings`
    );
  }

  updateWarnings(values) {
    return apiRequest
      .patch(`${this.url}/update`, this.dispatch, values)
      .then(this.propagateUpdate.bind(this));
  }
}
