import { useDispatch } from "react-redux";
import { useEffect } from "react";
import MasterDataResource from "../../actions/masterDataActions";

const MasterDataLoader = () => {
  const dispatch = useDispatch();
  const resource = new MasterDataResource(dispatch);

  useEffect(() => {
    resource.get();
  }, []);

  return null;
};

export default MasterDataLoader;
