import PropTypes, { func, node } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Icon, Modal, Segment } from "semantic-ui-react";
import ActivityTransitionControl from "../../../builder_portal/components/activity/ActivityTransitionControl";
import ActivityController from "../../../builder_portal/containers/activity/activityController";
import ActivityModel from "../../../builder_portal/containers/activity/activityModel";

function ImportInquiryModal({
  children,
  trigger,
  onSubmit,
  ctxCtrl,
  ctxModel
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const unmounted = useRef(false);
  useEffect(() => () => {
    unmounted.current = true;
  });
  return (
    <Modal
      size="large"
      trigger={trigger}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      data-component="importInquiryModal"
    >
      <Modal.Header>
        <FormattedMessage
          id="contractor.inquiry.import"
          defaultMessage="Nachunternehmer Angebot Importieren"
        />
        &nbsp;&nbsp;
      </Modal.Header>
      <Modal.Content>
        <Segment basic loading={loading}>
          {children}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} negative onClick={() => setOpen(false)}>
          <Icon name="cancel" />
          <FormattedMessage
            id="meta.actions.cancel"
            defaultMessage="meta.actions.cancel"
          />
        </Button>
        <ActivityTransitionControl
          disabled={loading}
          ctxCtrl={ctxCtrl}
          ctxModel={ctxModel}
          onTransition={state => {
            if (state.started) {
              // be aware of race conditions due to ActivityTransitionControl's requests
              if (!unmounted.current) setLoading(true);
            } else if (state.completed) {
              if (!unmounted.current) {
                setLoading(false);
                setOpen(false);
              }
              onSubmit();
            } else if (!unmounted.current) setLoading(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}

ImportInquiryModal.defaultProps = {
  children: ""
};

ImportInquiryModal.propTypes = {
  trigger: node.isRequired,
  onSubmit: func.isRequired,
  children: node,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  ctxModel: PropTypes.instanceOf(ActivityModel).isRequired
};

export default ImportInquiryModal;
