import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { indexOf, groupBy, keyBy } from "lodash";
import { Dropdown, Header } from "semantic-ui-react";
import "./attachmentAssigner.scss";

class AttachmentAssigner extends React.Component {
  static propTypes = {
    attachments: PropTypes.array,
    label: PropTypes.string,
    compact: PropTypes.bool,
    handleChange: PropTypes.func
  };

  onChange = (_, { value: id }) => {
    const { handleChange } = this.props;
    if (id) handleChange(id);
  };

  renderOptions() {
    const { account, attachments } = this.props;

    const options = [];

    const attachmentTypesById = keyBy(account.getDocumentTypes(), "id");

    // group by roleOptions and drop duplicates (due to multiple attachment references)
    const groupedAttachments = groupBy(attachments, "role");
    const usedAttachmentIds = [];
    Object.keys(groupedAttachments).map(role => {
      const { label } = attachmentTypesById[role] || { label: role };
      const group = groupedAttachments[role];
      options.push({
        key: role,
        text: role,
        content: <Header sub>{label}</Header>
      });
      group.map(item => {
        if (indexOf(usedAttachmentIds, item.id) === -1)
          options.push({
            key: item.id,
            value: item.id,
            icon: "file outline",
            text: `${label} ${item.display_name}`, // label is added to support type specific typeahead results such as "Plan" or "Rechnung"
            content: (
              <span>
                {item.display_name}
                <time dateTime={item.created_at}>
                  {moment(item.created_at).format("LLL")}
                </time>
              </span>
            )
          });
        usedAttachmentIds.push(item.id);
      });
    });

    return options;
  }

  render() {
    const { attachments, i18n } = this.props;

    if (!attachments || attachments.length === 0) {
      return null;
    }

    return (
      <div data-component="attachmentAssigner">
        <Dropdown
          id="attachmentAssigner"
          labeled
          text={this.props.label || this.props.i18n["attachment.actions.add"]}
          icon="linkify"
          compact={this.props.compact || true}
          fluid
          search
          selection
          button
          selectOnNavigation={false}
          onChange={this.onChange}
          className="icon"
          noResultsMessage={i18n["meta.message.noResults"]}
          options={this.renderOptions()}
        />
      </div>
    );
  }
}

export default AttachmentAssigner;
