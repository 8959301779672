import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Table, Icon, Label } from "semantic-ui-react";
import { keyBy, intersection, difference } from "lodash";
import { UnitShape } from "shared/shapes/unit.shape";
import { arrayOf, shape, number } from "prop-types";
import UnitFeatureAssignmentDialog from "./UnitFeatureAssignmentDialog";
import { getUnitFeatures } from "../../../selectors/unitFeatureGroups";

const UnitFeatureAssignementsList = ({
  units,
  filters: { unitId, unitFeatureIds, unitNoFeatureIds }
}) => {
  const intl = useIntl();
  const unitFeatures = keyBy(useSelector(getUnitFeatures), "id");

  const unitList = useMemo(() => {
    if (unitId) return units.filter(unit => unit.id === unitId);
    let temp = units;
    if (unitFeatureIds.length) {
      temp = temp.filter(
        unit => !difference(unitFeatureIds, unit.unit_feature_ids).length
      );
    }
    if (unitNoFeatureIds.length) {
      temp = temp.filter(
        unit => !intersection(unit.unit_feature_ids, unitNoFeatureIds).length
      );
    }

    return temp;
  }, [units, unitId, unitFeatureIds, unitNoFeatureIds]);

  const renderUnits = () => {
    return unitList.map(unit => {
      return (
        <Table.Row key={unit.slug}>
          <Table.Cell textAlign="left">{unit.display_name}</Table.Cell>
          <Table.Cell textAlign="left">
            {unit.unit_feature_ids.map(uf_id => (
              <Label key={uf_id}>{unitFeatures[uf_id]?.name}</Label>
            ))}
          </Table.Cell>
          <Table.Cell textAlign="right">
            <UnitFeatureAssignmentDialog
              model={unit}
              button={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  id="unit-feature-assignments-edit"
                  data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                >
                  <Icon name="setting" color="grey" />
                </a>
              }
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="unitFeatureAssignments.table.unit" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="unitFeatureAssignments.table.unitFeatures" />
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            <FormattedMessage id="unitFeatureAssignments.table.action" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderUnits()}</Table.Body>
    </Table>
  );
};

UnitFeatureAssignementsList.propTypes = {
  units: arrayOf(UnitShape).isRequired,
  filters: shape({
    unitId: number,
    unitFeatureIds: arrayOf(number)
  }).isRequired
};

export default UnitFeatureAssignementsList;
