import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import CustomProductForm from "./CustomProductForm";

class ProductDialog extends Component {
  static propTypes = {
    model: PropTypes.object,
    resources: PropTypes.object,
    button: PropTypes.object,
    handleUpdate: PropTypes.func,
    suppliers: PropTypes.array,
    mode: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { open: false };
    this.handleClose = this.handleClose.bind(this);
  }

  getTitle = () => {
    const { mode, model } = this.props;
    if (mode === "clone") return "clone";
    if (model.id) return "edit";
    return "add";
  };

  render() {
    const {
      model,
      resources,
      button,
      handleUpdate,
      suppliers,
      mode
    } = this.props;
    const title = `product.actions.${this.getTitle()}.label`;

    return (
      <Modal
        size="small"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
        trigger={button}
        open={this.state.open}
        onOpen={() => this.setState({ ...this.state, open: true })}
        onClose={() => this.setState({ ...this.state, open: false })}
      >
        <Modal.Header>
          <FormattedMessage id={title} defaultMessage={title} />
        </Modal.Header>
        <CustomProductForm
          model={model}
          resources={resources}
          handleUpdate={handleUpdate}
          suppliers={suppliers}
          handleClose={this.handleClose}
          mode={mode}
        />
      </Modal>
    );
  }

  handleClose() {
    this.setState({ open: false });
  }
}

export default ProductDialog;
