import { arrayOf, number, shape, string } from "prop-types";

export const MessageThreadShape = shape({
  id: number,
  account_id: number,
  project_id: number,
  unit_id: number,
  created_at: string,
  updated_at: string,
  message_count: number,
  message_types: arrayOf(string),
  attachment_count: number,
  subject: string,
  body: string,
  tags: arrayOf(shape({ id: number, type: string }))
});

export default MessageThreadShape;
