import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, Form, Button, Menu, Select, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { cloneDeep, get, set } from "lodash";
import { string, node, shape, func, object } from "prop-types";
import { I18nShape } from "shared/shapes/i18n.shape";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import JsonEditor from "shared/components/forms/JsonEditor";
import Field from "shared/components/forms/FieldComponent";
import TradeHintsSelector from "shared/components/forms/TradeHintsSelector";
import ExportTexts from "shared/components/forms/ExportTexts";
import { getDocumentExportSettingFields } from "builder_portal/selectors/documentExports";
import TableEditor from "./TableEditor";
import ExportSettings from "./ExportSettings";
import DialogueFieldEditor from "./DialogueFieldEditor";
import { getGroups, getRoles } from "../../../selectors/documentExports";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "documentsExport.dialogs.exports.placeholders.name",
      autoComplete: "off",
      autoFocus: true,
      rule: "isRequired"
    },
    {
      id: "group",
      label: "documentsExport.dialogs.exports.placeholders.group",
      rule: "isRequired"
    },
    {
      id: "role",
      label: "documentsExport.dialogs.exports.placeholders.role"
    },
    {
      id: "config_content",
      label: "JSON",
      rule: "isRequired"
    },
    {
      id: "trade_hints",
      label: "Trade Hints"
    },
    {
      id: "main_table_configs",
      label: "Table config"
    },
    {
      id: "export_texts",
      label: "Export Texts"
    },
    {
      id: "dialogue_fields",
      label: "Dialogue Fields"
    }
  ]
});

const groupOptions = getGroups.map(key => {
  const intlId =
    key === "room_book_carts" ? "activity.title.one" : "roomBook.title.one";
  const label = <FormattedMessage id={intlId} defaultMessage={key} />;

  return {
    key,
    value: key,
    text: label
  };
});

const roleOptions = getRoles.map(key => ({
  key,
  value: key,
  text: key
}));

const emptyModel = {
  name: "",
  group: "",
  role: "",
  config_content: {}
};

const actualRoleOptions = currentRole => {
  if (!currentRole || roleOptions.some(e => e.value === currentRole))
    return roleOptions;
  return [
    ...roleOptions,
    { key: currentRole, value: currentRole, text: currentRole }
  ];
};

const checkTradeHintsStructure = tempModel => {
  const config_content = tempModel.config_content || {};
  if (!config_content.arguments) config_content.arguments = {};
  if (!config_content.arguments.template)
    config_content.arguments.template = {};
  return config_content;
};

const updateTradeHints = (model, tradeHints) => {
  const tempModel = cloneDeep(model);
  const config_content = checkTradeHintsStructure(tempModel);

  config_content.arguments.template.trade_hints = tradeHints;
  return config_content;
};

const updateDialogueFields = (model, dialogueFields) => {
  const tempModel = cloneDeep(model);
  const { config_content } = tempModel;

  set(config_content, "params.arguments", dialogueFields);
  return config_content;
};

const checkMainTableStructure = tempModel => {
  const config_content = tempModel.config_content || {};
  if (!config_content.main_table_configs)
    config_content.main_table_configs = {};
  return config_content;
};

const updateTableConfig = (model, tableConfig) => {
  const tempModel = cloneDeep(model);
  const config_content = checkMainTableStructure(tempModel);

  config_content.main_table_configs = tableConfig;
  return config_content;
};

const checkExportTextsStructure = tempModel => {
  const config_content = tempModel.config_content || {};
  if (!config_content.texts) config_content.texts = {};
  return config_content;
};

const updateExportTexts = (model, textsData) => {
  const tempModel = cloneDeep(model);
  const config_content = checkExportTextsStructure(tempModel);

  config_content.texts = textsData;
  return config_content;
};

const tabs = {
  Base: "base",
  TradeHints: "tradeHints",
  Content: "content",
  Texts: "export_texts",
  Settings: "settings",
  DialogueFields: "dialogue_fields",
  JSON: "JSON"
};

const ExportDialog = ({ button, i18n, originalExport, onSubmit }) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const [form, setForm] = useState();
  const [currentTab, setCurrentTab] = useState(tabs.Base);

  const exportSettingsMetadata = useSelector(getDocumentExportSettingFields);

  const prepareModel = () => {
    if (originalExport) {
      const exportObject = originalExport
        ? cloneDeep(originalExport)
        : { config_content: {} };

      const trade_hints =
        exportObject.config_content?.arguments?.template?.trade_hints || {};
      const main_table_configs =
        exportObject.config_content?.main_table_configs || {};
      const export_texts = exportObject.config_content?.texts || {};
      const dialogue_fields = get(
        exportObject,
        "config_content.params.arguments",
        []
      );

      setModel({
        ...exportObject,
        trade_hints,
        main_table_configs,
        export_texts,
        dialogue_fields
      });
    }
  };

  useEffect(() => {
    prepareModel();
  }, [originalExport]);

  const handleClose = () => {
    setOpen(false);
  };

  const removeEmptyTextFields = config_content => {
    const newConfigContent = { ...config_content };
    if (!newConfigContent.texts) return newConfigContent;

    Object.keys(newConfigContent.texts).forEach(key => {
      if (newConfigContent.texts[key] === "")
        delete newConfigContent.texts[key];
    });
    return newConfigContent;
  };

  const handleSubmit = () => {
    setLoading(true);
    const strModel = {
      ...model,
      config_content: JSON.stringify(
        removeEmptyTextFields(model.config_content)
      )
    };
    const { trade_hints, ...permittedParams } = strModel;
    onSubmit(permittedParams)
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
    prepareModel();
  };

  const setTradeHints = tradeHints => {
    setModel(prev => ({
      ...prev,
      trade_hints: tradeHints,
      config_content: updateTradeHints(prev, tradeHints)
    }));
  };

  const handleDialogueFieldsChanged = dialogueFields => {
    setModel(prev => ({
      ...prev,
      dialogue_fields: dialogueFields,
      config_content: updateDialogueFields(prev, dialogueFields)
    }));
  };

  const handleMainTableChanged = tableConfig => {
    setModel(prev => ({
      ...prev,
      main_table_configs: tableConfig,
      config_content: updateTableConfig(prev, tableConfig)
    }));
  };

  const handleExportTextsChanged = textsData => {
    setModel(prev => ({
      ...prev,
      export_texts: textsData,
      config_content: updateExportTexts(prev, textsData)
    }));
  };

  const handleRoleChanged = ({ value: val }) => {
    setModel(prev => ({
      ...prev,
      role: val
    }));
  };

  const valid = model.name && model.group && model.role;

  useEffect(() => {
    const f = FormFactory.create(model, i18n, {
      onChange: data => {
        const {
          id,
          name,
          group,
          role,
          config_content,
          trade_hints,
          main_table_configs,
          export_texts,
          dialogue_fields
        } = data;
        setModel({
          id,
          name,
          group,
          role,
          config_content,
          trade_hints,
          main_table_configs,
          export_texts,
          dialogue_fields
        });
        f.fields.name.props.value = name;
      }
    });

    f.fields.group.props.options = groupOptions;
    f.fields.role.props.options = roleOptions;
    f.fields.trade_hints.props.setTradeHints = setTradeHints;
    f.fields.dialogue_fields.props.onChange = handleDialogueFieldsChanged;
    f.fields.main_table_configs.props.onChange = handleMainTableChanged;
    f.fields.export_texts.props.onChange = handleExportTextsChanged;
    f.fields.config_content.props.rows = 19;
    f.fields.export_texts.props = {
      ...f.fields.export_texts.props,
      roleData: model.role
    };

    setForm(f);
  }, [model]);

  return (
    <Modal
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={button}
      size="large"
    >
      <Modal.Header>
        <FormattedMessage
          id={`documentsExport.dialogs.exports.title.${
            originalExport ? "edit" : "create"
          }`}
        />
        {`: ${model.name || ""}`}
      </Modal.Header>
      <Modal.Content style={{ minHeight: "500px" }}>
        <Menu>
          <Menu.Item
            name={i18n["documentsExport.dialogs.exports.tabs.base"]}
            onClick={() => setCurrentTab(tabs.Base)}
            active={currentTab === tabs.Base}
          >
            <FormattedMessage
              id="documentsExport.dialogs.exports.tabs.base"
              defaultMessage="Base"
            />
          </Menu.Item>
          <Menu.Item
            name={i18n["documentsExport.dialogs.exports.tabs.texts"]}
            onClick={() => setCurrentTab(tabs.Texts)}
            active={currentTab === tabs.Texts}
          >
            <FormattedMessage
              id="documentsExport.dialogs.exports.tabs.texts"
              defaultMessage="Export Texts"
            />
          </Menu.Item>
          <If condition={model.role === "offer"}>
            <Menu.Item
              name={i18n["documentsExport.dialogs.exports.tabs.tradeHints"]}
              onClick={() => setCurrentTab(tabs.TradeHints)}
              active={currentTab === tabs.TradeHints}
            >
              <FormattedMessage
                id="documentsExport.dialogs.exports.tabs.tradeHints"
                defaultMessage="Trade Hints"
              />
            </Menu.Item>
          </If>
          <Menu.Item
            name={i18n["documentsExport.dialogs.exports.tabs.dialogueFields"]}
            onClick={() => setCurrentTab(tabs.DialogueFields)}
            active={currentTab === tabs.DialogueFields}
          >
            <FormattedMessage
              id="documentsExport.dialogs.exports.tabs.dialogueFields"
              defaultMessage="Dialogue Fields"
            />
          </Menu.Item>
          <FeatureToggleActive featureToggleName="document_exports_12">
            <If condition={model.role !== "catalog"}>
              <Menu.Item
                name={i18n["documentsExport.dialogs.exports.tabs.content"]}
                onClick={() => setCurrentTab(tabs.Content)}
                active={currentTab === tabs.Content}
              >
                <FormattedMessage
                  id="documentsExport.dialogs.exports.tabs.content"
                  defaultMessage="Content"
                />
              </Menu.Item>
            </If>
          </FeatureToggleActive>
          <Menu.Item
            name={i18n["documentsExport.dialogs.exports.tabs.settings"]}
            onClick={() => setCurrentTab(tabs.Settings)}
            active={currentTab === tabs.Settings}
          >
            <FormattedMessage
              id="documentsExport.dialogs.exports.tabs.settings"
              defaultMessage="Settings"
            />
          </Menu.Item>
          <Menu.Item
            name={i18n["documentsExport.dialogs.exports.tabs.json"]}
            onClick={() => setCurrentTab(tabs.JSON)}
            active={currentTab === tabs.JSON}
          >
            <FormattedMessage
              id="documentsExport.dialogs.exports.tabs.json"
              defaultMessage="JSON"
            />
          </Menu.Item>
        </Menu>
        {form && (
          <Form
            id="export"
            onSubmit={form.handleSubmit(handleSubmit)}
            data-component="exportForm"
          >
            <If
              condition={currentTab === tabs.Base}
              styles={{ display: "block" }}
            >
              <Form.Field>
                <Field component="Input" {...form.fields.name} />
              </Form.Field>

              <Form.Field>
                <Field component="Select" {...form.fields.group} />
              </Form.Field>

              <Header
                as="h5"
                style={{ marginBottom: "0.5em", marginTop: "0em" }}
              >
                <FormattedMessage
                  id="documentsExport.dialogs.exports.placeholders.role"
                  defaultMessage="Role"
                />
              </Header>
              <Select
                options={actualRoleOptions(model.role)}
                defaultValue={model.role}
                onChange={(_, data) => handleRoleChanged(data)}
                allowAdditions
                search
                fluid
              />
            </If>

            <If condition={currentTab === tabs.Texts}>
              <Form.Field>
                <Field component={ExportTexts} {...form.fields.export_texts} />
              </Form.Field>
            </If>

            <If
              condition={currentTab === tabs.TradeHints}
              styles={{ display: "block" }}
            >
              <Form.Field>
                <Field
                  component={TradeHintsSelector}
                  {...form.fields.trade_hints}
                />
              </Form.Field>
            </If>

            <If
              condition={currentTab === tabs.DialogueFields}
              styles={{ display: "block" }}
            >
              <DialogueFieldEditor
                value={form.fields.dialogue_fields.props.value}
                onChange={form.fields.dialogue_fields.props.onChange}
                exportRole={form.fields.role.props.value}
              />
            </If>

            <If condition={currentTab === tabs.Content}>
              <Form.Field>
                <Field
                  component={TableEditor}
                  {...form.fields.main_table_configs}
                />
              </Form.Field>
            </If>

            <If condition={currentTab === tabs.Settings}>
              <ExportSettings
                exportRole={form.fields.role.props.value}
                value={form.fields.config_content.props.value}
                onChange={form.fields.config_content.props.onChange}
                metadata={exportSettingsMetadata}
              />
            </If>

            <If
              condition={currentTab === tabs.JSON}
              styles={{ display: "block" }}
            >
              <Form.Field>
                <Field component={JsonEditor} {...form.fields.config_content} />
              </Form.Field>
            </If>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          id="cancel"
          content={i18n["meta.actions.cancel"]}
          onClick={handleClose}
          loading={isLoading}
        />
        <Button
          primary
          id="submit"
          content={
            originalExport
              ? i18n["meta.actions.save"]
              : i18n["meta.actions.add"]
          }
          onClick={handleSubmit}
          disabled={!valid}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

ExportDialog.propTypes = {
  i18n: I18nShape.isRequired,
  button: node.isRequired,
  originalExport: shape({
    name: string,
    group: string,
    role: string,
    // eslint-disable-next-line react/forbid-prop-types
    config_content: object
  }),
  onSubmit: func.isRequired
};

ExportDialog.defaultProps = {
  originalExport: null
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n
  };
};
export default connect(mapStateToProps)(ExportDialog);
