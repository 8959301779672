import PropTypes from "prop-types";
import React from "react";
import localStorage from "shared/helpers/localStorage";
import DropzoneComponent from "react-dropzone-component";
import { uniqueId } from "lodash";
import "./dropzone.scss";

class FileDropzone extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    resourceName: PropTypes.string,
    resourceId: PropTypes.number,
    additionalData: PropTypes.array,
    toggleLoading: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    i18n: PropTypes.object,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string
  };

  handleSend(file, xhrObject, formData) {
    const { additionalData, toggleLoading } = this.props;
    additionalData?.map(item =>
      formData.append(Object.keys(item)[0], item[Object.keys(item)[0]])
    );
    toggleLoading(true);
  }

  handleSuccess(responseData) {
    const { onSuccess } = this.props;
    if (responseData) {
      try {
        const { attachment } = JSON.parse(responseData.xhr.responseText);
        onSuccess(attachment);
      } catch (e) {
        this.handleError(e, e.message);
      }
    }
  }

  handleError(data, message) {
    const { onError } = this.props;
    console.error(message);
    onError?.(message);
  }

  render() {
    const {
      i18n,
      title,
      resourceName,
      resourceId,
      className,
      toggleLoading,
      disabled,
      disabledMessage
    } = this.props;

    if (disabled) {
      return (
        <div data-component="fileDropzone">
          <div className={["dropzone", "disabled", className].join(" ")}>
            <span className="dz-default">{disabledMessage}</span>
          </div>
        </div>
      );
    }

    const componentConfig = {
      postUrl: `/api/v1${resourceName}/${String(resourceId)}/attachments`
    };

    const uid = uniqueId("hiddenContainer");
    const hiddenContainer = <div id={uid} />;

    const djsConfig = {
      dictDefaultMessage: title || i18n["dropzone.defaultMessage"],
      dictFallbackMessage: i18n["dropzone.fallbackMessage"],
      dictInvalidFileType: i18n["dropzone.invalidFileType"],
      dictFileTooBig: i18n["dropzone.fileTooBig"],
      dictResponseError: i18n["dropzone.responseError"],
      dictCancelUpload: i18n["dropzone.cancelUpload"],
      dictCancelUploadConfirmation: i18n["dropzone.cancelUploadConfirmation"],
      dictRemoveFile: i18n["dropzone.removeFile"],
      dictMaxFilesExceeded: i18n["dropzone.maxFilesExceeded"],
      autoProcessQueue: true,
      uploadMultiple: false,
      paramName: "attachment[file]",
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      },
      hiddenInputContainer: `#${uid}`
    };

    const eventHandlers = {
      sending: ::this.handleSend,
      complete: () => {
        toggleLoading(false);
      },
      success: ::this.handleSuccess,
      error: ::this.handleError
    };

    return (
      <div data-component="fileDropzone">
        <DropzoneComponent
          className={["dropzone", className].join(" ")}
          config={componentConfig}
          djsConfig={djsConfig}
          eventHandlers={eventHandlers}
        />
        {hiddenContainer}
      </div>
    );
  }
}

export default FileDropzone;
