import { get } from "lodash";
import { getUserRole } from "shared/selectors/base";
import {
  findSection,
  findSubSections,
  findLineItem,
  findLineItems,
  findSubLineItem,
  findSubLineItems,
  getUnitPath,
  getRootSections,
  getSectionPath,
  getLineItemPath,
  makeSectionPath,
  makeLineItemPath,
  makeSubLineItemPath
} from ".";

import { getAccount } from "../account";
import { getNextSibling } from "./subLineItems";

export const getSubLineItemModel = (state, props) => {
  const { lineItemId, subLineItemId } = props.params;

  const subLineItem = findSubLineItem(state)(subLineItemId);
  const lineItem = findLineItem(state)(lineItemId);

  const color = subLineItem.isDecided ? "primary" : "secondary";
  const basic = !subLineItem.isDecided;

  if (lineItem.isCompactDisplay) {
    return {
      next: {
        label: "Fertig",
        path: getLineItemPath(props),
        color,
        basic
      }
    };
  }
  const nextSibling = getNextSibling(state, subLineItem, sibling => {
    return sibling.isConfigurable;
  });

  const nextSiblingPath = nextSibling
    ? makeSubLineItemPath(props, nextSibling.id)
    : getLineItemPath(props);

  return {
    next: {
      label: nextSibling ? "Weiter" : "Fertig",
      path: nextSiblingPath,
      color,
      basic
    }
  };
};

export const getLineItemModel = (state, props) => {
  const { id, lineItemId } = props.params;
  const { i18n } = state;
  const userRole = getUserRole(state);

  const lineItem = findLineItem(state)(lineItemId);

  if (lineItem.isDecided) {
    return {
      next: {
        label: "Fertig",
        path: getSectionPath(props),
        color: "primary",
        basic: false
      }
    };
  }
  if (lineItem.isCompactDisplay) {
    if (!["admin", "buyer"].includes(userRole)) {
      return {
        next: {
          label: i18n["meta.actions.next"],
          path: getSectionPath(props),
          color: "secondary",
          basic: true
        }
      };
    }

    const subLineItems = findSubLineItems(state)(
      lineItemId,
      sli => sli.isVisible
    );

    const selection = subLineItems.reduce((accu, sli) => {
      if (sli.options.length && sli.isConfigurable && !sli.hasBuyerSelection) {
        // const validOptions = sli.options.filter((o) => o.isValidOption);
        // const selectedOption = validOptions.find((o) => o.isActualSelection);
        // const buyerOption = validOptions.find((o) => o.isBuyerSelection);
        // const defaultOption = validOptions.find((o) => o.isDefault);

        accu[sli.id] = get(sli.defactoSelectedOption, "id");
      }
      return accu;
    }, {});

    return {
      next: {
        label: "Auswahl übernehmen",
        path: getSectionPath(props),
        color: "primary",
        basic: false,
        eventAction: {
          type: "autoSelectOptions",
          payload: { unitId: id, selection }
        }
      }
    };
  }
  const subLineItems = findSubLineItems(state)(
    lineItemId,
    sli => sli.isVisible
  );
  if (subLineItems.length == 1) {
    return {
      next: {
        label: "Fertig",
        path: getSectionPath(props),
        color: "secondary",
        basic: true
      }
    };
  }
  const undecided = subLineItems.find(
    sli => !sli.isDecided && !sli.isMandatoryDisplay
  );
  const path = makeSubLineItemPath(props, undecided.id);

  return renderNextButton(undecided, path);
};

export const getSectionModel = (state, props) => {
  const { sectionId } = props.params;

  const section = findSection(state)(sectionId);

  const parentPath = section.isRoot
    ? getUnitPath(props)
    : makeSectionPath(props, section.parentId);

  const completedButton = {
    next: {
      label: "Fertig",
      path: parentPath,
      color: "primary",
      basic: false
    }
  };

  if (section.isDecided) {
    return completedButton;
  }
  if (section.isRoot) {
    const sections = findSubSections(state, sectionId);
    const undecided = sections.find(
      s => s.isVisible && !s.isDecided && !s.isMandatoryDisplay
    );
    if (!undecided) {
      return completedButton;
    }

    const path = makeSectionPath(props, undecided.id);

    return renderNextButton(undecided, path);
  }
  const lineItems = findLineItems(state)(sectionId);
  const undecided = lineItems.find(
    li => li.isActivated && !li.isDecided && !li.isMandatoryDisplay
  );

  if (undecided) {
    const path = makeLineItemPath(props, undecided.id);

    return renderNextButton(undecided, path);
  }

  return {
    next: {
      label: "Fertig",
      path: parentPath,
      color: "secondary",
      basic: true,
      eventAction: {
        type: "sectionCompleted",
        payload: { value: section.id }
      }
    }
  };
};

export const getRoomBookModel = (state, props) => {
  const account = getAccount(state);
  const sections = getRootSections(state);
  const undecided = sections.find(s => !s.isDecided && !s.isMandatoryDisplay);
  const showCompletionButton = account.isEnabled("bp_completion_button");

  const allSelectedEnforcement = account.isEnabled(
    "bp_completion_button_all_selected"
  );
  const unitDecided = allSelectedEnforcement ? undecided === undefined : true;

  if (undecided) {
    const path = makeSectionPath(props, undecided.id);
    return renderNextButton(
      undecided,
      path,
      true,
      showCompletionButton && unitDecided
    );
  }
  return {
    showCompletionButton: showCompletionButton && unitDecided
  };
};

export const renderNextButton = (
  undecided,
  path,
  hideBackButton,
  showCompletionButton = false
) => {
  return {
    next: {
      label: `${undecided.title} konfigurieren`,
      path,
      color: "primary",
      basic: false,
      labelMobile: undecided.title,
      iconMobile: "cogs"
    },
    hideBackButton,
    showCompletionButton: !!showCompletionButton
  };
};

export const getBottomNavigationModel = (state, props) => {
  const { id, sectionId, lineItemId, subLineItemId } = props.params;

  if (subLineItemId) {
    return getSubLineItemModel(state, props);
  }
  if (lineItemId) {
    return getLineItemModel(state, props);
  }
  if (sectionId) {
    return getSectionModel(state, props);
  }
  if (id) {
    return getRoomBookModel(state, props);
  }
};
