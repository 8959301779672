import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { values, groupBy, uniq, take, isArray } from "lodash";
import {
  Modal,
  Dropdown,
  Grid,
  Table,
  Button,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";
import ButtonCheckbox from "shared/components/elements/ButtonCheckbox";
import VersionHistoryButton from "builder_portal/components/buttons/VersionHistoryButton";
import moment from "moment";
import {
  getAttachmentsFilter,
  getUnitsForProject,
  getAttachments
} from "builder_portal/selectors";
import { I18nShape } from "shared/shapes/i18n.shape";
import { MessageShape } from "shared/shapes/message.shape";
import OptionsShape from "shared/shapes/options.shape";
import AttachmentsResource from "../../actions/messaging/attachmentsActions";
import AttachmentDropzone from "../attachment/AttachmentDropzone";
import { getProjects } from "../../selectors/masterdata";
import { setAttachmentsFilter } from "../../actions/filters/attachments";

class AttachmentPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      view: "list"
    };
  }

  componentDidUpdate(prevProps) {
    const { filter, message, setFilter } = this.props;

    if (message && message !== prevProps.message) {
      const newFilter = {
        document_type: filter.document_type,
        project_id: parseInt(message.project_id, 10),
        unit_id: parseInt(message.unit_id, 10)
      };
      setFilter(newFilter);
      return;
    }

    if (filter !== prevProps.filter) {
      this.loadAttachmentsForFilter(filter);
    }
  }

  toggleVersions = attachment => {
    const name = attachment.display_name;
    this.setState({ [name]: !this.state[name] });
  };

  handleUploadSuccess = attachment => {
    return this.loadAttachments().then(() => {
      this.handleSelectionChange(attachment)(null, { checked: true });
      this.setState({ view: "list", loading: false });
    });
  };

  handleUploadError = () => {};

  toggleUploadLoading = loading => {
    this.setState({ loading });
  };

  handleProjectChange = (event, { value }) => {
    const { filter } = this.props;

    this.updateFilter({ ...filter, project_id: value, unit_id: null });
  };

  handleOpen = () => {
    this.setState({ open: true }, () => {
      this.loadAttachments();
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSelectionChange(attachment) {
    const { onChange, selection } = this.props;
    return (event, { checked }) => {
      if (checked) {
        onChange(selection.concat([attachment]));
      } else {
        onChange(selection.filter(item => item.id !== attachment.id));
      }
    };
  }

  updateFilter(filter) {
    const { setFilter } = this.props;
    setFilter(filter);
  }

  handleFilterChange(field) {
    const { filter } = this.props;
    return (event, { value }) => {
      this.updateFilter({ ...filter, [field]: value });
    };
  }

  loadAttachmentsForFilter(filter) {
    const { loadAttachments } = this.props;
    const { view } = this.state;
    this.setState({ loading: view === "list" });
    return loadAttachments(filter).then(() => {
      this.setState({ loading: false });
    });
  }

  loadAttachments() {
    const { filter } = this.props;
    return this.loadAttachmentsForFilter(filter);
  }

  switchViewFn(view) {
    return () => {
      this.setState({ view });
    };
  }

  renderList() {
    const { i18n, attachments } = this.props;
    const groupedAttachments = values(groupBy(attachments, "display_name"));
    if (groupedAttachments.length > 0) {
      return (
        <Table basic="very">
          <Table.Body>
            {groupedAttachments.map(group => this.renderItems(group))}
          </Table.Body>
        </Table>
      );
    }
    return (
      <Message
        content={i18n["messaging.attachment_picker.messages.documentsMissing"]}
      />
    );
  }

  renderUpload() {
    const { filter, i18n } = this.props;

    if (filter.document_type !== "all") {
      return (
        <AttachmentDropzone
          i18n={i18n}
          resourceName="/units"
          resourceId={filter.unit_id}
          documentType={filter.document_type}
          onSuccess={this.handleUploadSuccess}
          onError={this.handleUploadError}
          onLoading={this.toggleUploadLoading}
        />
      );
    }
    return (
      <Message
        content={
          i18n["messaging.attachment_picker.messages.documentTypeMissing"]
        }
      />
    );
  }

  renderContent() {
    const { view } = this.state;

    if (view === "list") {
      return this.renderList();
    }
    return this.renderUpload();
  }

  renderViewToggleButton() {
    const { view } = this.state;

    if (view === "list") {
      return (
        <Button
          icon="cloud upload"
          basic
          onClick={this.switchViewFn("upload")}
        />
      );
    }
    return <Button icon="list" basic onClick={this.switchViewFn("list")} />;
  }

  renderItems(attachments) {
    const { selection } = this.props;
    const name = attachments[0].display_name;
    // dynamic state access is not destructible in a readable way
    // eslint-disable-next-line react/destructuring-assignment
    const items = !this.state[name] ? take(attachments) : attachments;

    return items.map(attachment => {
      return (
        <Table.Row key={attachment.id}>
          <Table.Cell>
            <a
              href={attachment.attachment_url}
              target="_document"
              className="ellipsis ellipsis-300"
            >
              {attachment.display_name}
            </a>
          </Table.Cell>
          <Table.Cell textAlign="left">
            {moment(attachment.created_at).format("LLL")}
          </Table.Cell>
          <Table.Cell>
            <ButtonCheckbox
              data-action="add-attachment"
              compact
              floated="right"
              checked={selection.filter(s => s.id === attachment.id).length > 0}
              onChange={this.handleSelectionChange(attachment)}
            />
            {attachments.length > 1 &&
              attachments.indexOf(attachment) === 0 && (
                <VersionHistoryButton
                  size="tiny"
                  floated="right"
                  tooltipPosition="left center"
                  onClick={() => this.toggleVersions(attachment)}
                />
              )}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const {
      i18n,
      trigger,
      filter,
      projects,
      units,
      parentUnitOptions,
      message
    } = this.props;
    const { open, loading } = this.state;

    const projectOptions = projects
      .map(project => {
        return {
          value: project.id,
          text: project.name
        };
      })
      .filter(project =>
        message.project_id
          ? project.value === parseInt(message.project_id, 10)
          : true
      );

    const unitOptions =
      isArray(parentUnitOptions) && !message.unit_id
        ? parentUnitOptions
        : units
            .map(unit => {
              const buyers = uniq(
                unit.buyers.map(buyer => buyer.last_name)
              ).join(", ");
              return {
                value: unit.id,
                text: [unit.name, buyers].join(" - ")
              };
            })
            .filter(unit =>
              message.unit_id ? unit.value === message.unit_id : true
            );

    const project = projects.find(p => p.id === filter.project_id);

    const documentOptions = [{ id: "all", label: "Alle" }]
      .concat(project?.attachment_types || [])
      .map(documentType => {
        return {
          key: documentType.id || "all",
          value: documentType.id,
          text: documentType.label
        };
      });

    return (
      <Modal
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick
        trigger={trigger}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        centered
      >
        <Modal.Header>
          <FormattedMessage
            id="messaging.attachment_picker.title"
            defaultValue="messaging.attachment_picker.title"
          />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={5}>
              <Dropdown
                placeholder="Projekt"
                fluid
                search
                selection
                options={projectOptions}
                value={filter.project_id}
                onChange={this.handleProjectChange}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Dropdown
                placeholder="Wohneinheit"
                fluid
                search
                selection
                options={unitOptions}
                value={filter.unit_id}
                onChange={this.handleFilterChange("unit_id")}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                placeholder="Dokument"
                fluid
                search
                selection
                options={documentOptions}
                value={filter.document_type}
                onChange={this.handleFilterChange("document_type")}
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              {this.renderViewToggleButton()}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Content scrolling style={{ height: "40vh", paddingTop: "0px" }}>
          <Dimmer active={loading} inverted>
            <Loader inverted />
          </Dimmer>
          {this.renderContent()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="grey"
            content={i18n["meta.actions.close"]}
            onClick={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

AttachmentPicker.propTypes = {
  i18n: I18nShape.isRequired,
  filter: PropTypes.object,
  projects: PropTypes.array,
  units: PropTypes.array,
  attachments: PropTypes.array,
  selection: PropTypes.array,
  setFilter: PropTypes.func.isRequired,
  loadAttachments: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  message: MessageShape.isRequired,
  parentUnitOptions: PropTypes.arrayOf(OptionsShape)
};

AttachmentPicker.defaultProps = {
  parentUnitOptions: []
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    projects: getProjects(state),
    units: getUnitsForProject(state),
    filter: getAttachmentsFilter(state),
    attachments: getAttachments(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: setAttachmentsFilter(dispatch),
    loadAttachments: filter => {
      const { unit_id, document_type } = filter;
      const params = {};
      if (document_type !== "all") {
        params.document_type = document_type;
      }
      if (unit_id) {
        params.unit_id = unit_id;
      }

      const resource = new AttachmentsResource(dispatch);
      return resource.fetchAll(params);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentPicker);
