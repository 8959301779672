import { shape, string, arrayOf, func, objectOf } from "prop-types";

const AccountShape = shape({
  data: shape({
    account: shape({
      config: shape({
        taxonomies: arrayOf(
          shape({
            tags: arrayOf(objectOf(string))
          })
        )
      })
    })
  }),
  isEnabled: func
});

export default AccountShape;
