import React, { Component } from "react";
import { Button, Grid, Icon, Message, Modal } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import {
  arrayOf,
  shape,
  array,
  number,
  string,
  objectOf,
  oneOfType,
  bool,
  node,
  func
} from "prop-types";
import moment from "moment";
import { BuyerShape } from "shared/shapes/buyer.shape";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { buyerInvitationStatus } from "../../helpers/buyerInvitationStatus.js";
import { formatMessage } from "../helpers/i18n";
import { If } from "../../../shared/components/elements/Conditions";

class BuyerInvitationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false, loading: false };
  }

  onInvite(buyer) {
    const { loading } = this.state;
    if (loading) return null;

    const { actions, onUpdate } = this.props;
    const resource = actions.buyerInvitationResourceCreator(buyer.id);

    this.setState({ loading: true });
    return resource
      .create({})
      .then(() => {
        if (onUpdate) {
          onUpdate().then(() => {
            this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false, error: true });
      });
  }

  handleClose = () => this.setState({ open: false });

  handleOpen = () => this.setState({ open: true });

  renderContent() {
    const { buyers } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Message negative>
          <Message.Header>
            <b>Achtung:&nbsp;</b>
            <FormattedMessage
              id="buyer.actions.invitationDialog.error.title"
              defaultMessage="buyer.actions.invitationDialog.error.title"
            />
          </Message.Header>
          <p>
            <FormattedMessage
              id="buyer.actions.invitationDialog.error.message"
              defaultMessage="buyer.actions.invitationDialog.error.message"
            />
          </p>
        </Message>
      );
    }
    return <Grid>{this.renderBuyers(buyers)}</Grid>;
  }

  renderBuyers(buyers) {
    const { i18n } = this.props;
    const { loading } = this.state;

    return buyers.map(buyer => {
      const color = buyerInvitationStatus.color([buyer]);
      const icon = buyerInvitationStatus.icon([buyer]);
      return (
        <Grid.Row key={buyer.id}>
          <Grid.Column width={6} verticalAlign="middle">
            {buyer.first_name} {buyer.last_name}
            <br />
            <small>{buyer.email || "- Keine E-Mail hinterlegt -"}</small>
          </Grid.Column>
          <Grid.Column width={1} verticalAlign="middle">
            <Icon name={icon} color={color} size="large" />
          </Grid.Column>
          <Grid.Column width={4} verticalAlign="middle">
            {this.renderInvitationDateInfo(buyer)}
          </Grid.Column>
          <Grid.Column width={5} textAlign="right" verticalAlign="middle">
            <Button
              id="send-invitation"
              data-loading={loading}
              loading={loading}
              basic={buyer.portal_active}
              color={color}
              onClick={() => {
                this.onInvite(buyer);
              }}
            >
              {buyer.portal_active
                ? i18n["buyer.actions.invitationDialog.resend"]
                : i18n["buyer.actions.invitationDialog.send"]}
            </Button>
          </Grid.Column>
        </Grid.Row>
      );
    });
  }

  renderInvitationDateInfo = buyer => {
    const { i18n } = this.props;

    if (buyer.invited_at) {
      const invitedDaysAgo = moment(buyer.invited_at).format("Do MMMM YYYY");
      const createdAt = moment(buyer.invited_at).format("Do MMMM YYYY");
      const remindedAt = moment(buyer.reminded_at).format("Do MMMM YYYY");
      const reminded =
        buyer.reminded_at &&
        moment(buyer.invited_at).diff(moment(buyer.reminded_at), "days") !== 0;

      return (
        <p>
          <FeatureToggle featureToggleName="bp_invitation_reminder" disabled>
            <span>
              {formatMessage(
                i18n[
                  "buyer.actions.invitationDialog.invitation_date_info.invited_days_ago"
                ],
                { invited_days_ago: invitedDaysAgo }
              )}
            </span>
          </FeatureToggle>
          <FeatureToggle featureToggleName="bp_invitation_reminder">
            <If condition={reminded}>
              <span>
                {formatMessage(
                  i18n[
                    "buyer.actions.invitationDialog.invitation_date_info.created_at"
                  ],
                  { created_at: createdAt, reminded_at: remindedAt }
                )}
              </span>
            </If>
            <If condition={!reminded}>
              <span>
                {formatMessage(
                  i18n[
                    "buyer.actions.invitationDialog.invitation_date_info.invited_days_ago"
                  ],
                  { invited_days_ago: invitedDaysAgo }
                )}
              </span>
            </If>
          </FeatureToggle>
        </p>
      );
    }
    return <span>{i18n["buyer.actions.invitationDialog.not_found"]}</span>;
  };

  render() {
    const { i18n, button } = this.props;
    const { open } = this.state;

    return (
      <Modal
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="buyer.actions.invitationDialog.title"
            defaultMessage="buyer.actions.invitationDialog.title"
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="buyer.actions.invitationDialog.message"
              defaultMessage="buyer.actions.invitationDialog.message"
            />
          </p>
          <Message warning>
            <Message.Header>
              <FormattedMessage id="buyer.actions.invitationDialog.reminder.title" />
            </Message.Header>
            <Message.Content>
              <FormattedMessage id="buyer.actions.invitationDialog.reminder.body" />
              <ul>
                <li>
                  <FormattedMessage id="buyer.actions.invitationDialog.reminder.list.quantities" />
                </li>
                <li>
                  <FormattedMessage id="buyer.actions.invitationDialog.reminder.list.sorting" />
                </li>
                <li>
                  <FormattedMessage id="buyer.actions.invitationDialog.reminder.list.default" />
                </li>
              </ul>
            </Message.Content>
          </Message>
          {this.renderContent()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="close"
            content={i18n["meta.actions.close"]}
            basic
            color="grey"
            onClick={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

BuyerInvitationDialog.propTypes = {
  buyers: arrayOf(BuyerShape).isRequired,
  i18n: objectOf(oneOfType([string, number, array, bool])).isRequired,
  button: node.isRequired,
  onUpdate: func.isRequired,
  actions: shape({
    buyerInvitationResourceCreator: func.isRequired
  }).isRequired
};

export default BuyerInvitationDialog;
