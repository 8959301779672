import { instanceOf, string } from "prop-types";
import React from "react";
import { Link } from "react-router";
import { Card, List } from "semantic-ui-react";
import { Account } from "shared/models/account";
import ProductGroupShape from "shared/shapes/productGroup.shape";
import "./productGroupCard.scss";

const ProductGroupCard = ({ group, catalogId, trade, account }) => {
  const url = `/products/${catalogId}/product_groups/${trade ||
    group.trades[0]}/${group.id}`;
  return (
    <Card
      key={group.id}
      data-component="productGroupCard"
      data-model="product_group"
    >
      <Card.Content>
        <List.Header>
          <Link to={url}>{group.name}</Link>
        </List.Header>
        {account.isEnabled("show_product_group_description_in_cards") && (
          <List.Description className="product">
            {group.desciption}
          </List.Description>
        )}
      </Card.Content>
      <Card.Content>
        <Link to={url}>
          <div
            style={{ backgroundImage: `url(${group.thumb_url})` }}
            className="image"
          />
        </Link>
      </Card.Content>
    </Card>
  );
};

ProductGroupCard.defaultProps = {
  trade: ""
};

ProductGroupCard.propTypes = {
  account: instanceOf(Account).isRequired,
  group: ProductGroupShape.isRequired,
  trade: string,
  catalogId: string.isRequired
};

export default ProductGroupCard;
