import React from "react";
import { connect } from "react-redux";
import { Button, Icon, Message, Modal, Progress } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import { countBy, find, groupBy, tail, uniq } from "lodash";
import { SimpleSubLineItemResource } from "builder_portal/actions/roomBookActions";
import Growl from "builder_portal/actions/growlActions";
import { func, instanceOf, node } from "prop-types";
import { FormattedMessage } from "react-intl";
import { withRouter } from "../../../../shared/helpers/withRouter";

const COLUMN_MAP = {
  ID: "ID",
  QUANTITY: "Menge KA",
  DEFAULT_QUANTITY: "Menge SA"
};

function forEachPromise(functions) {
  if (functions.length) {
    return functions[0]().then(() => {
      return forEachPromise(tail(functions));
    });
  }
  return Promise.resolve(true);
}

class UploadQuantitiesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updates: null,
      errors: [],
      uploading: false,
      progress: 0,
      total: 0,
      open: false,
      done: false
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      updates: null,
      total: 0,
      progress: 0,
      uploading: false,
      done: false
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleUpdates = () => {
    const { updates } = this.state;
    const { update } = this.props;

    const rids = Object.keys(updates);

    const inc = () => {
      this.setState(prevState => ({
        progress: prevState.progress + 1
      }));
    };

    this.setState({ uploading: true }, () => {
      forEachPromise(
        rids.map(rid => {
          return () => {
            return update(rid, updates[rid]).then(inc);
          };
        })
      ).then(() => {
        this.setState({ uploading: false, done: true });
      });
    });
  };

  handleFileDrop = acceptedFiles => {
    const file = find(acceptedFiles, "name");
    const reader = new FileReader();

    reader.onload = ({ target: { result } }) => {
      try {
        const array = new Uint8Array(result);
        const workbook = XLSX.read(array, { type: "array" });

        const updates = Object.values(workbook.Sheets).reduce(
          (workSheetAccu, worksheet) => {
            const jsonWorksheet = XLSX.utils.sheet_to_json(worksheet, {
              raw: false
            });

            const rowsByRoomBook = groupBy(jsonWorksheet, row => {
              return row[COLUMN_MAP.ID]?.split("/")[0] ?? "UNKNOWN_SECTION";
            });

            return Object.keys(rowsByRoomBook).reduce(
              (roomBookRowsAccu, rid) => {
                if (rid !== "UNKNOWN_SECTION") {
                  const rows = rowsByRoomBook[rid];
                  roomBookRowsAccu[rid] = rows.map(row => {
                    const id = row[COLUMN_MAP.ID]?.split("/")[1];
                    if (id === undefined) {
                      this.setState(prevState => ({
                        errors: prevState.errors.concat("UNKNOWN_UNIT")
                      }));
                    }
                    const quantity = row[COLUMN_MAP.QUANTITY];
                    if (quantity === undefined) {
                      this.setState(prevState => ({
                        errors: prevState.errors.concat("UNKNOWN_QUANTITY")
                      }));
                    }
                    const defaultQuantity = row[COLUMN_MAP.DEFAULT_QUANTITY];
                    if (defaultQuantity === undefined) {
                      this.setState(prevState => ({
                        errors: prevState.errors.concat(
                          "UNKNOWN_DEFAULT_QUANTITY"
                        )
                      }));
                    }
                    return {
                      id,
                      quantity,
                      default_quantity: defaultQuantity
                    };
                  });
                } else {
                  this.setState(prevState => ({
                    errors: prevState.errors.concat("UNKNOWN_SECTION")
                  }));
                }
                return roomBookRowsAccu;
              },
              workSheetAccu
            );
          },
          {}
        );

        this.setState({
          updates,
          progress: 0,
          total: Object.keys(updates).length
        });
      } catch (e) {
        const { growl } = this.props;
        if (e.message === "Could not find workbook") {
          growl.error("message.errorLoad.title", "message.errorLoad.body");
        } else if (e.message.match(/Unsupported (\w)+ file/)) {
          growl.error(
            "message.errorLoad.title",
            "attachment.message.unsupportedFile"
          );
        } else {
          growl.error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              bodyValues: { translatedBody: e.message }
            }
          );
          throw e;
        }
      }
    };

    reader.readAsArrayBuffer(file);
  };

  render() {
    const {
      errors,
      updates,
      uploading,
      progress,
      total,
      open,
      done
    } = this.state;
    const { trigger } = this.props;

    return (
      <Modal
        open={open}
        size="small"
        trigger={trigger}
        closeIcon
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage id="upload_quantities_dialog.title" />
        </Modal.Header>
        <Modal.Content>
          {!updates && (
            <Dropzone multiple={false} onDrop={this.handleFileDrop}>
              {({ getRootProps, getInputProps }) => (
                <Button {...getRootProps()}>
                  <Icon name="plus" />
                  <FormattedMessage id="meta.actions.dragAndDropFile" />
                  <input {...getInputProps()} />
                </Button>
              )}
            </Dropzone>
          )}
          {!!updates && !!total && (
            <Progress color="olive" value={progress} total={total} />
          )}
        </Modal.Content>
        {errors.length > 0 && (
          <Modal.Content>
            <Message negative>
              <Message.Header>
                <FormattedMessage id="upload_quantities_dialog.error.title" />
              </Message.Header>
              <Message.Content>
                <ul>
                  {uniq(errors).map(intlKey => {
                    const foo = countBy(errors, v => v);
                    return (
                      <li key={intlKey}>
                        <FormattedMessage
                          id={`upload_quantities_dialog.error.${intlKey.toLowerCase()}`}
                          values={{ count: foo[intlKey] }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Message.Content>
            </Message>
          </Modal.Content>
        )}
        <Modal.Actions>
          {!done && (
            <Button
              basic
              content={<FormattedMessage id="meta.actions.execute" />}
              onClick={this.handleUpdates}
              disabled={!updates}
              loading={uploading}
            />
          )}
          {done && (
            <Button
              basic
              content={<FormattedMessage id="meta.actions.close" />}
              onClick={this.handleClose}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

UploadQuantitiesDialog.propTypes = {
  update: func.isRequired,
  growl: instanceOf(Growl).isRequired,
  trigger: node.isRequired
};

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props.params;
  return {
    growl: new Growl(dispatch),
    update: (roomBookId, values) => {
      const resource = new SimpleSubLineItemResource(
        dispatch,
        projectId,
        roomBookId
      );
      return resource.saveAll(values);
    }
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(UploadQuantitiesDialog)
);
