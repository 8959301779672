import es6Promise from "es6-promise";
import fetch from "isomorphic-fetch";
import { UPDATE_IS_LOADING, UPDATE_MESSAGE } from "../constants/appConstants";
import localStorage from "../helpers/localStorage";
// import APM from "../middlewares/apm";
import { browserHistory } from "../routes/browserHistory";
// es lint:
/* global Promise */

// isomorphic fetch
es6Promise.polyfill();

const versionString = "";

const handleUnauthorizedRequest = (dispatch, silent) => {
  const { pathname } = browserHistory.getCurrentLocation();
  const { search } = browserHistory.getCurrentLocation();

  if (!pathname.match("users/")) {
    localStorage.set("landingPage", `/${pathname}`.replace("//", "/") + search);
    browserHistory.push("/users/login");
  }

  if (!silent) {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        title: "message.errorUnauthorized.title",
        body: "message.errorUnauthorized.body"
      }
    });
  }

  console.info("Unauthorized Request - Redirecting to Login");
};

function failureMessage(method) {
  switch (method) {
    case "GET":
      return {
        type: "error",
        title: "message.errorLoad.title",
        body: "message.errorLoad.body"
      };
    case "POST":
    case "PATCH":
    case "PUT":
      return {
        type: "error",
        title: "message.errorSave.title",
        body: "message.errorSave.body"
      };
    case "DELETE":
      return {
        type: "error",
        title: "message.errorDelete.title",
        body: "message.errorDelete.body"
      };
  }
}

function requestObject(method, payload) {
  const hasBody = method === "POST" || method === "PUT" || method === "PATCH";
  const authorization = `Bearer ${localStorage.get("token")}`;

  if (hasBody) {
    if (payload instanceof FormData) {
      return {
        method,
        headers: {
          Authorization: authorization
        },
        timeout: 5000,
        body: payload
      };
    }
    return {
      method,
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      timeout: 5000,
      body: JSON.stringify(payload)
    };
  }
  return {
    method,
    headers: {
      Authorization: authorization,
      Accept: "application/json"
    },
    timeout: 5000
  };
}

export class SilentApiRequestError extends Error {
  constructor() {
    super();
    this.name = "SilentApiRequestError";
  }
}

const apiRequest = {
  fetch: (method, url, dispatch, payload, silent) => {
    return new Promise((resolve, reject) => {
      // const apmTransaction = APM.startTransaction("apiRequest", "fetch");
      // const apmSpan = apmTransaction?.startSpan(`${url}`, method);

      dispatch({
        type: UPDATE_IS_LOADING,
        payload: { [url]: true }
      });

      return fetch(versionString + url, requestObject(method, payload))
        .catch(error => {
          console.error(`API ${method} ${url} request failed: ${error}`);
          reject(new SilentApiRequestError());
          return Promise.reject(error);
        })
        .then(response => {
          dispatch({
            type: UPDATE_IS_LOADING,
            payload: { [url]: null }
          });

          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            return response
              .json()
              .then(data => ({ response, data }))
              .catch(error => {
                // If json parsing fails, we log the error, but continue
                console.error(
                  `Failed parsing response of ${method} ${url}: ${error}`
                );
                return { response };
              });
          }
          return { response };
        })
        .then(({ response, data }) => {
          const { status } = response;
          if (response.ok) {
            // apmSpan?.end();
            // apmTransaction?.end();
            resolve({ status, data });
          } else {
            dispatch({
              type: UPDATE_IS_LOADING,
              payload: { [url]: null }
            });

            if (status === 401) {
              handleUnauthorizedRequest(dispatch, silent);
            } else {
              console.error(
                `API ${method} request failed`,
                { status, data },
                url,
                payload
              );
              if (!silent) {
                const messagePayload = failureMessage(method);
                dispatch({ type: UPDATE_MESSAGE, payload: messagePayload });
              }
            }
            // apmSpan?.end();
            // apmTransaction?.end();
            reject({ status, data });
          }
        });
    });
  },
  get: (url, dispatch, payload, silent) =>
    apiRequest.fetch("GET", url, dispatch, payload, silent),
  post: (url, dispatch, payload, silent) =>
    apiRequest.fetch("POST", url, dispatch, payload, silent),
  put: (url, dispatch, payload, silent) =>
    apiRequest.fetch("PUT", url, dispatch, payload, silent),
  patch: (url, dispatch, payload, silent) =>
    apiRequest.fetch("PUT", url, dispatch, payload, silent), // TODO: WTF!! Why do we use put for patch?!?
  delete: (url, dispatch, silent) =>
    apiRequest.fetch("DELETE", url, dispatch, null, silent)
};

export default apiRequest;
