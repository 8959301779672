import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

const getProjectQuestions = createSelector([getPageContent], pageContent => {
  const projectQuestions = sortBy(get(pageContent, "project_questions", []), [
    "name"
  ]);
  return projectQuestions.map(ub => ({ ...ub, summary: ub.summary || "" }));
});

export const getProjectQuestionOptions = createSelector(
  [getProjectQuestions],
  projectQuestions => {
    return projectQuestions.map(bundle => ({
      key: bundle.id,
      value: bundle.id,
      text: bundle.name,
      content: bundle.name
    }));
  }
);

export const getDefaultProjectQuestionOptions = createSelector(
  [getProjectQuestions],
  projectQuestions => {
    return projectQuestions
      .filter(bundle => {
        return bundle.default_dummy;
      })
      .map(bundle => ({
        key: bundle.id,
        value: bundle.id,
        text: bundle.name,
        content: bundle.name
      }));
  }
);

export default getProjectQuestions;
