import { CLOSE_MODAL, OPEN_MODAL } from "shared/constants/appConstants";

export default function reducer(state = [], action) {
  switch (action.type) {
    case OPEN_MODAL:
      return state.concat(action.modal);
    case CLOSE_MODAL:
      return state.filter(modal => modal.id !== action.modal.id);
    default:
      return state;
  }
}
