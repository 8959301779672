// eslint-disable-next-line import/prefer-default-export
export const TABS = [
  {
    id: "basic_data",
    checkBy: "project",
    statusCheck: [
      "name",
      "customer_success_manager_id",
      "default_unit_type_id",
      "unit_quota",
      "address"
    ],
    mandatory: true
  },
  {
    id: "project",
    checkBy: "topics",
    items: []
  },
  {
    id: "project_structure",
    checkBy: "topics",
    items: []
  },
  {
    id: "project_catalog",
    checkBy: "topics",
    items: []
  },
  {
    id: "room_books",
    checkBy: "topics",
    items: []
  },
  {
    id: "process",
    checkBy: "topics",
    items: []
  },
  {
    id: "email_integration",
    checkBy: "topics",
    items: []
  }
  // currently hidden
  // {
  //   id: "data_providing",
  //   checkBy: "data",
  //   mandatory: true
  // }
];
