import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { instanceOf, func, number, string, shape } from "prop-types";
import { Button, Header, Modal, Message, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { get } from "lodash";
import { getProductRepository } from "builder_portal/selectors/roomBooks/roomBook";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import ProductsRepository from "builder_portal/models/roomBook/ProductsRepository";
import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import ProductSelector from "./ProductSelector";
import QuantitySelector from "./QuantitySelector";
import "./lineItemCreationWizard.scss";

const INIT_STATE = {
  isLoading: false,
  messageHidden: true,
  page: "productSelection",
  message: "productSelection",
  mode: null,
  productId: null,
  quantity: 1,
  defaultQuantity: 0,
  defaultProductId: null,
  desc: undefined
};

class LineItemCreationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = INIT_STATE;
  }

  componentDidMount() {
    this.setState(this.initState);
  }

  componentDidUpdate(prevProps) {
    const { dialogModel } = this.props;
    if (dialogModel !== prevProps.dialogModel) {
      this.initializeState();
    }
  }

  initializeState = () => {
    this.setState(INIT_STATE);
  };

  toggleSelection = product => {
    const { page, productId, defaultProductId } = this.state;
    if (page === "productSelection") {
      const selectedProduct = productId;

      if (product === "no_product") {
        this.setState({
          productId: null,
          desc: "no_product",
          productGroupId: null,
          messageHidden: true,
          buttonBasic: true,
          message: "productSelection"
        });
      } else if (selectedProduct?.toString() !== product.id.toString()) {
        this.setState({
          productId: product.id,
          productGroupId: product.group.product_group_id,
          messageHidden: true,
          buttonBasic: false,
          message: "modeSelection",
          desc: undefined
        });
      } else if (selectedProduct.toString() === product.id.toString()) {
        this.setState({
          productId: null,
          productGroupId: null,
          messageHidden: true,
          buttonBasic: true,
          message: "productSelection",
          desc: undefined
        });
      }
    } else if (page === "defaultProductSelection") {
      if (defaultProductId?.toString() !== product.id.toString()) {
        this.setState({ defaultProductId: product.id, messageHidden: true });
      } else if (defaultProductId?.toString() === product.id.toString()) {
        this.setState({
          defaultProductId: null,
          messageHidden: true,
          message: "defaultProductSelection"
        });
      }
    }
  };

  handleQuantity = input => {
    const { value } = input;
    const { page } = this.state;

    if (page === "productSelection") {
      if (value < 0) {
        this.setState({
          quantity: value,
          messageHidden: true,
          message: "quantity"
        });
      } else if (value >= 0) {
        this.setState({ quantity: value, messageHidden: true });
      }
    } else if (page === "defaultProductSelection") {
      if (value < 0) {
        this.setState({ defaultQuantity: 0, messageHidden: false });
      } else if (value >= 0) {
        this.setState({
          defaultQuantity: value,
          messageHidden: true,
          message: "quantity"
        });
      }
    }
  };

  handleCancelation = () => {
    const { toggleDialog } = this.props;
    this.setState({
      page: "productSelection",
      mode: null,
      quantity: 0,
      defaultQuantity: 0
    });
    toggleDialog("lineItemCreationWizard", null);
  };

  getFirstProductOption = () => {
    const {
      dialogModel: { productGroupId },
      productsRepository: { productGroupsById }
    } = this.props;

    return productGroupsById[productGroupId].product_options[0]?.product_id;
  };

  getCostsAttributes = () => {
    const { productId } = this.state;
    if (productId) return {};

    const {
      dialogModel: { productGroupId },
      productsRepository: { productGroupsById }
    } = this.props;
    const { trades } = productGroupsById[productGroupId];
    const costsAttributes = trades.map(trade => ({
      id: null,
      cost: 0,
      excess_cost: null,
      trade
    }));
    return { costs_attributes: costsAttributes };
  };

  handleAddStandard() {
    const {
      ctrl,
      dialogModel: { parentType, parentId, productGroupId }
    } = this.props;

    const {
      quantity,
      defaultQuantity,
      productId,
      defaultProductId
    } = this.state;

    const values = {
      product_id: productId,
      product_group_id: productGroupId,
      quantity,
      default_quantity: defaultQuantity,
      default_product_id:
        defaultProductId || productId || this.getFirstProductOption(),
      ...this.getCostsAttributes()
    };

    if (parentType === "line_item") {
      return ctrl.createSubLineItem(parentId, values);
    }
    return ctrl.createLineItem(parentId, values);
  }

  saveSelection() {
    const { toggleDialog } = this.props;
    this.setState({ isLoading: true });
    this.handleAddStandard().then(() => {
      toggleDialog("lineItemCreationWizard", null);
    });
  }

  handleModeSelection() {
    const { mode } = this.state;

    if (mode === "withStandard") {
      this.setState({ page: "defaultProductSelection" });
    } else if (mode === "withoutStandard") {
      this.saveSelection();
    }
  }

  toggleMode(button) {
    const { mode } = this.state;

    if (mode === button) {
      this.setState({ mode: null });
    } else if (button === "withStandard") {
      this.setState({
        mode: "withStandard",
        messageHidden: true,
        defaultQuantity: 1
      });
    } else if (button === "withoutStandard") {
      this.setState({ mode: "withoutStandard", messageHidden: true });
    }
  }

  displayMessage() {
    this.setState({ messageHidden: false });
  }

  renderMessage() {
    const { message, messageHidden } = this.state;

    return (
      <Message hidden={messageHidden}>
        <Message.Header>
          <FormattedMessage
            id={`roomBook.lineItems.wizard.messages.${message}.header`}
          />
        </Message.Header>
        <FormattedMessage
          id={`roomBook.lineItems.wizard.messages.${message}.body`}
        />
      </Message>
    );
  }

  renderCancelationButton() {
    return (
      <Button onClick={() => this.handleCancelation()}>
        <FormattedMessage id="roomBook.lineItems.wizard.buttons.cancelation" />
      </Button>
    );
  }

  renderMessageButton() {
    return (
      <Button onClick={() => this.displayMessage()}>
        <FormattedMessage id="roomBook.lineItems.wizard.buttons.next" />
      </Button>
    );
  }

  renderNextButton() {
    const {
      page,
      mode,
      productId,
      defaultProductId,
      isLoading,
      defaultQuantity,
      desc
    } = this.state;

    if (page === "productSelection") {
      if (productId || desc === "no_product") {
        return (
          <Button
            id="next"
            color={productId || desc === "no_product" ? "green" : null}
            loading={isLoading}
            onClick={() => this.setState({ page: "modeSelection" })}
          >
            <FormattedMessage id="roomBook.lineItems.wizard.buttons.next" />
          </Button>
        );
      }
      return this.renderMessageButton();
    }
    if (page === "modeSelection") {
      if (mode) {
        return (
          <Button
            id="next"
            color={mode ? "green" : null}
            loading={isLoading}
            onClick={() => this.handleModeSelection()}
          >
            <FormattedMessage
              id={
                mode === "withoutStandard"
                  ? "roomBook.lineItems.wizard.buttons.done"
                  : "roomBook.lineItems.wizard.buttons.next"
              }
            />
          </Button>
        );
      }
      return this.renderMessageButton();
    }
    if (page === "defaultProductSelection") {
      if (!defaultProductId || defaultQuantity < 0) {
        return this.renderMessageButton();
      }
      return (
        <Button
          id="next"
          color={defaultProductId ? "green" : null}
          loading={isLoading}
          onClick={() => this.saveSelection()}
        >
          <FormattedMessage id="roomBook.lineItems.wizard.buttons.done" />
        </Button>
      );
    }

    return null;
  }

  renderPageContent() {
    const { page, productId, defaultProductId } = this.state;

    switch (page) {
      case "productSelection":
        return this.renderProductSelector(productId);
      case "modeSelection":
        return this.renderModeSelector();
      case "defaultProductSelection":
        return this.renderProductSelector(defaultProductId);
      default:
        return null;
    }
  }

  renderProductSelector(id) {
    const { productsRepository, dialogModel } = this.props;
    const { page, desc } = this.state;

    return (
      <ProductSelector
        productsRepository={productsRepository}
        dialogModel={dialogModel}
        selection={id}
        page={page}
        toggleSelection={this.toggleSelection}
        desc={desc}
      />
    );
  }

  renderQuantitySelector() {
    const { productsRepository, dialogModel } = this.props;
    const { page, quantity, defaultQuantity } = this.state;

    switch (page) {
      case "productSelection":
        return (
          <QuantitySelector
            page={page}
            productsRepository={productsRepository}
            dialogModel={dialogModel}
            defaultValue={quantity}
            header="quantity"
            handleQuantity={this.handleQuantity}
          />
        );
      case "defaultProductSelection":
        return (
          <QuantitySelector
            page={page}
            productsRepository={productsRepository}
            dialogModel={dialogModel}
            defaultValue={defaultQuantity}
            header="defaultQuantity"
            handleQuantity={this.handleQuantity}
          />
        );
      default:
        return null;
    }
  }

  renderModeSelector() {
    const { mode } = this.state;
    return (
      <Modal.Content>
        <Grid columns={1} className="margin top spacious">
          <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Header>
                <FormattedMessage id="roomBook.lineItems.wizard.labels.modeSelection" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Button
                id="withStandard"
                size="huge"
                positive={mode === "withStandard"}
                onClick={() => this.toggleMode("withStandard")}
              >
                <FormattedMessage id="roomBook.lineItems.wizard.buttons.positive" />
              </Button>
              <Button
                id="withoutStandard"
                size="huge"
                positive={mode === "withoutStandard"}
                onClick={() => this.toggleMode("withoutStandard")}
              >
                <FormattedMessage id="roomBook.lineItems.wizard.buttons.negative" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    );
  }

  render() {
    const { page } = this.state;
    const { dialogModel } = this.props;

    if (!dialogModel) return null;

    return (
      <Modal
        size="large"
        data-component="lineItemCreationWizard"
        open={!!dialogModel}
      >
        <Modal.Header>
          <FormattedMessage id="roomBook.lineItems.wizard.header" />
          <Header.Subheader>
            <FormattedMessage
              id={`roomBook.lineItems.wizard.subheaders.${page}`}
            />
          </Header.Subheader>
        </Modal.Header>
        <Modal.Content scrolling>
          {this.renderMessage()}
          {this.renderPageContent()}
        </Modal.Content>
        {this.renderQuantitySelector()}
        <Modal.Actions>
          {this.renderCancelationButton()}
          {this.renderNextButton()}
        </Modal.Actions>
      </Modal>
    );
  }
}

LineItemCreationWizard.propTypes = {
  // is used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  id: number.isRequired,
  // is used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  module: string.isRequired,
  dialogModel: shape({
    productGroupId: number,
    parentType: string,
    parentId: number
  }),
  productsRepository: instanceOf(ProductsRepository).isRequired,
  toggleDialog: func.isRequired,
  ctrl: instanceOf(RoomBookController).isRequired
};

LineItemCreationWizard.defaultProps = {
  dialogModel: undefined
};

const mapStateToProps = (state, props) => {
  const { i18n } = state;
  const { module, id } = props;
  return {
    i18n,
    productsRepository: getProductRepository(state),
    dialogModel: get(
      state,
      ["dialog", module, id, "lineItemCreationWizard"],
      undefined
    )
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { module, id } = props;
  return {
    toggleDialog: toggleDialogCreator(dispatch, id, module)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemCreationWizard);
