import React from "react";
import { bool, func, node, string } from "prop-types";
import ConfirmableButton from "./ConfirmableButton";

const DeleteButton = ({
  basic,
  className,
  color,
  confirmationContent,
  confirmationMessage,
  confirmationTitle,
  content,
  icon,
  loading,
  onDelete
}) => (
  <ConfirmableButton
    id="remove"
    confirmationTitle={confirmationTitle}
    confirmationMessage={confirmationMessage}
    confirmationContent={confirmationContent}
    confirmationButtonId="delete"
    confirmationButtonColor="red"
    confirmationButtonContent="meta.actions.remove"
    onConfirm={onDelete}
    className={className}
    loading={loading}
    color={color}
    basic={basic}
    content={content}
    icon={icon}
  />
);

DeleteButton.defaultProps = {
  basic: true,
  className: null,
  color: "red",
  confirmationContent: null,
  confirmationMessage: null,
  content: "meta.actions.remove",
  icon: null,
  loading: false
};

DeleteButton.propTypes = {
  basic: bool,
  className: string,
  color: string,
  confirmationContent: node,
  confirmationMessage: string,
  confirmationTitle: string.isRequired,
  content: node,
  icon: string,
  loading: bool,
  onDelete: func.isRequired
};

export default DeleteButton;
