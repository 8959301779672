import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes, { bool, oneOf, object, shape, func } from "prop-types";
import { FormattedList, FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { get, uniq, keyBy } from "lodash";
import { Header, Label, Grid, Loader } from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { SubLineItemShape } from "shared/components/configurator/roomBook.shape";
import { ProductShape } from "shared/shapes/product.shape";
import { getAccount, getAllDesignLines } from "../../../shared/selectors";
import { getTagNames } from "../../helpers/getTagNames";

import "./productInfoItem.scss";

const ProductInfoItem = ({
  product,
  renderSku,
  mode,
  style,
  standardSelection,
  account,
  hidePrice,
  model,
  renderPrice,
  buyerPortalRenderSku
}) => {
  const approvedSelection =
    model.selection_is_confirmed && model.product_id === product.id;
  const buyerSelection = model.buyer_selection?.product_id === product.id;
  const productStatus = [];
  const selectionStatusText = [];
  const designLines = keyBy(useSelector(getAllDesignLines), "id");

  if (standardSelection) {
    productStatus.push("product-status-default");
    selectionStatusText.push(
      <FormattedMessage
        key="roomBook.standardSelection"
        id="roomBook.standardSelection"
        defaultMessage="Standardauswahl"
      />
    );
  }
  if (buyerSelection) {
    productStatus.push("product-status-buyer");
    selectionStatusText.push(
      <FormattedMessage
        key="roomBook.buyerSelection"
        id="roomBook.buyerSelection"
        defaultMessage="Erwerberwunsch"
      />
    );
  }
  if (approvedSelection) {
    productStatus.push("product-status-approved");
    selectionStatusText.push(
      <FormattedMessage
        key="roomBook.approvedSelection"
        id="roomBook.approvedSelection"
        defaultMessage="Bestätigt"
      />
    );
  }
  let taxonomies;
  let renderTagNames;
  let renderDesignLineNames;
  let productTagNames;
  let productGroupTags;
  let combinedTags;

  const renderSelectionStatusText = () => (
    <p className="selectionStatus">
      (<FormattedList value={selectionStatusText} type="conjunction" />)
    </p>
  );

  switch (mode) {
    case "compact":
      return (
        <span
          data-test="component-product-info-item-compact"
          data-component="product-info-item-compact"
          data-attr="product-name"
          id="product-name"
        >
          {product.name}
          {product.supplier && `, ${product.supplier}, ${product.series}`}
        </span>
      );
    case "reverse":
      return (
        <Header
          as="h4"
          style={style}
          data-test="component-product-info-item-reverse"
          data-component="product-info-item-reverse"
        >
          {product.supplier && (
            <span className="descriptionHeader" data-attr="product-supplier">
              {product.supplier}, {product.series}
            </span>
          )}
          {!product.supplier && (
            <span
              className="productName descriptionHeader"
              data-test="product-name"
              id="name"
              data-attr="product-name"
            >
              {product.name}
            </span>
          )}
          {product.supplier && (
            <Header.Subheader>
              <span
                className="productName"
                data-test="product-name"
                id="name"
                data-attr="product-name"
              >
                {product.name}
                {renderSku && product.supplier && `, ${product.sku}`}
              </span>
            </Header.Subheader>
          )}
          {selectionStatusText.length > 0 && renderSelectionStatusText()}
        </Header>
      );
    case "detailed-reverse": {
      taxonomies = get(account, "data.account.config.taxonomies");
      productTagNames = getTagNames(product.product_tags, taxonomies);
      productGroupTags = getTagNames(product.group.product_tags, taxonomies);
      combinedTags = uniq([...productTagNames, ...productGroupTags]);
      renderTagNames = () =>
        combinedTags.map(tag => (
          <Label size="tiny" key={tag} data-attr="product-tag">
            {tag}
          </Label>
        ));

      renderDesignLineNames = () => {
        if (
          !Object.keys(designLines).length &&
          product.group.design_line_ids.length > 0
        )
          return <Loader active inline size="tiny" />;

        return product.group.design_line_ids.map(dline => (
          <Label size="tiny" key={dline} data-attr="design-line">
            {designLines[dline]?.name}
          </Label>
        ));
      };

      return (
        <Grid
          data-test="component-product-info-item-detailed-reverse"
          data-component="product-info-item-detailed-reverse"
          className={productStatus.join(" ")}
        >
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column width={11}>
              <div style={{ display: "flex" }}>
                <Header as="h4" style={style}>
                  {product.supplier && (
                    <span
                      className="descriptionHeader"
                      data-attr="product-supplier"
                    >
                      {product.supplier}, {product.series}
                    </span>
                  )}
                  {!product.supplier && (
                    <span
                      className="productName descriptionHeader"
                      data-test="product-name"
                      id="name"
                      data-attr="product-name"
                    >
                      {product.name}
                    </span>
                  )}
                </Header>
                <If condition={!!product.deprecated}>
                  <div>
                    <Label style={{ margin: "-5px 0px 0px 10px" }}>
                      Ausgemustert
                    </Label>
                  </div>
                </If>
              </div>
            </Grid.Column>
            <Grid.Column width={5} textAlign="right" verticalAlign="top">
              {!hidePrice && model && renderPrice(model, product, hidePrice)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: ".3em" }}>
            <Grid.Column width={10}>
              <Header as="h4">
                {product.supplier && (
                  <Header.Subheader>
                    <span
                      className="productName"
                      data-test="product-name"
                      id="name"
                      data-attr="product-name"
                    >
                      {product.name}
                      {renderSku && product.supplier && `, ${product.sku}`}
                    </span>
                  </Header.Subheader>
                )}
                {selectionStatusText.length > 0 && renderSelectionStatusText()}
              </Header>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right" verticalAlign="top">
              <If condition={!!combinedTags.length}>
                <div className="product-tags" data-attr="product-tags">
                  {renderTagNames()}
                </div>
              </If>
              <FeatureToggleActive featureToggleName="design_lines_admin">
                <div className="design-lines" data-attr="design-lines">
                  {renderDesignLineNames()}
                </div>
              </FeatureToggleActive>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    case "bp_card":
      return (
        <Header as="h4" data-component="BPCardProductInfoItem" style={style}>
          <Header.Subheader>
            {product.supplier && (
              <span className="product-supplier">{product.supplier}</span>
            )}
            {product.supplier && <span className="subheader-separator">-</span>}
            {product.series && (
              <span className="product-series">{product.series}</span>
            )}
            {buyerPortalRenderSku && (
              <div className="product-sku">{product.sku}</div>
            )}
          </Header.Subheader>
          <Header.Content data-attr="product-name">
            {product.name}
          </Header.Content>
        </Header>
      );
    default:
      return (
        <Header
          as="h4"
          data-test="component-product-info-item"
          data-component="product-info-item"
          style={style}
        >
          {product.supplier && (
            <Header.Subheader>
              <div className="productSupplier" data-test="product-supplier">
                {product.supplier}, {product.series}
              </div>
            </Header.Subheader>
          )}
          <div
            className="product-name"
            data-test="product-name"
            id="product-name"
            data-attr="product-name"
          >
            {product.name}
            {renderSku && product.supplier && `, ${product.sku}`}
          </div>
        </Header>
      );
  }
};

ProductInfoItem.propTypes = {
  product: ProductShape.isRequired,
  renderSku: PropTypes.bool,
  renderPrice: func,
  buyerPortalRenderSku: bool,
  mode: oneOf(["compact", "reverse", "detailed-reverse", "bp_card", "default"]),
  style: shape({
    object
  }),
  standardSelection: bool,
  account: shape({
    object
  }).isRequired,
  hidePrice: bool,
  model: SubLineItemShape
};

ProductInfoItem.defaultProps = {
  mode: "default",
  renderPrice: () => {},
  hidePrice: false,
  standardSelection: false,
  style: {},
  model: {},
  renderSku: false,
  buyerPortalRenderSku: false
};

const mapStateToProps = (state, ownProps) => {
  return {
    renderSku:
      ownProps.renderSku === undefined
        ? getAccount(state).isEnabled("product_option_render_sku")
        : ownProps.renderSku,
    buyerPortalRenderSku:
      ownProps.buyerPortalRenderSku === undefined
        ? getAccount(state).isEnabled("bp_render_sku")
        : ownProps.buyerPortalRenderSku,
    account: getAccount(state)
  };
};

export default connect(mapStateToProps)(ProductInfoItem);
