import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Header, Button } from "semantic-ui-react";
import { ProductManagerResource } from "../../actions/productManagerActions";

class ProductSynchronisation extends Component {
  static propTypes = {
    product: PropTypes.object,
    trigger: PropTypes.element
  };

  // state = {
  //   loading: false
  // };

  render() {
    const { product: { product_manager_url } = {} } = this.props;

    // render only if product_manager_url is present
    if (!product_manager_url) {
      return null;
    }

    return (
      <Segment attached>
        <Header as="h4">Produkt Manager</Header>
        {this.renderContent()}
      </Segment>
    );
  }

  renderContent = () => {
    const { product: { product_manager_url } = {} } = this.props;
    return (
      <div>
        <p>Das Produkt wurde erfolgreich synchronisiert</p>
        <Button
          icon="checkmark"
          basic
          as="a"
          href={product_manager_url}
          target="product_manager"
          content="Link zum Produkt"
        />
      </div>
    );

    // return (
    //   <div>
    //     <p>Synchronisiere dieses Produkt in den globalen Produkt Manager.</p>
    //     <Button
    //       icon="retweet"
    //       size="large"
    //       onClick={this.handleOpen}
    //       loading={this.state.loading}
    //       content="Synchronisieren"
    //     />
    //   </div>
    // );
  };

  // handleOpen = () => {
  //   this.setState({ loading: true }, () => {
  //     this.props
  //       .sync()
  //       .then(() => {
  //         this.setState({ loading: false });
  //       })
  //       .catch(() => {
  //         this.setState({ loading: false });
  //       });
  //   });
  // };
}

const mapDispatchToProps = (dispatch, props) => {
  const { product } = props;
  return {
    sync: () => {
      return new ProductManagerResource(dispatch).save({ id: product.id });
    }
  };
};

export default connect(null, mapDispatchToProps)(ProductSynchronisation);
