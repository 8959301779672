import { UPDATE_FILTER } from "shared/constants/appConstants";
import { FILTER_ATTACHMENTS } from "../../constants/filters";

export const setAttachmentsFilter = dispatch => {
  return payload => {
    return dispatch({
      type: UPDATE_FILTER,
      payload: {
        [FILTER_ATTACHMENTS]: payload
      },
      transient: true
    });
  };
};
