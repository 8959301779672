import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid, Header, Button, Icon, Table, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import { getAccount, getProject } from "shared/selectors";
import { getUnit } from "builder_portal/selectors/roomBooks/roomBook";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import UnitFeaturesUploadModal from "builder_portal/components/project/unitFeatureGroups/UnitFeaturesUploadModal";
import { getUnitFeatures } from "builder_portal/selectors/unitFeatureGroups";
import { get } from "lodash";

const UnitFeaturesList = () => {
  const intl = useIntl();
  const allUnitFeatures = useSelector(getUnitFeatures);
  const unit = useSelector(getUnit);
  const project = useSelector(getProject);
  const account = useSelector(getAccount);

  const unitFeatures = useMemo(() => {
    return allUnitFeatures.map(feature => ({
      ...feature,
      active: feature.units.indexOf(unit?.id) > -1
    }));
  }, [allUnitFeatures, unit]);

  const renderList = () => {
    return (
      <Table style={{ padding: 0, marginBottom: "1rem" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.name"
                defaultValue="Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.active"
                defaultValue="Aktiv"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {unitFeatures.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell width={15}>{item.name}</Table.Cell>
              <Table.Cell width={1} textAlign="center">
                {item.active ? "X" : ""}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Segment>
      <Grid>
        <Grid.Column width={13}>
          <Header as="h3">
            <FormattedMessage id="unitFeatureAssignments.title" />
          </Header>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <IsSystemAdmin>
            <FeatureToggleActive featureToggleName="show_unit_feature_groups">
              <Header as="h5">
                <FormattedMessage id="project.unit_variables.section.label" />
              </Header>
              <Button
                basic
                icon
                href={`${window.location.origin}/api/v1/projects/${
                  project?.slug
                }/unit_features/export.xlsx?_bearer=${get(account, [
                  "data",
                  "auth_token"
                ])}
                ${unit?.id ? `&unit_id=${unit.id}` : ""}
                `}
                target="_blank"
                data-tooltip={intl.formatMessage({
                  id: "unitFeatureAssignments.actions.download.tooltip"
                })}
              >
                <Icon name="cloud download" />
              </Button>
              <UnitFeaturesUploadModal
                unitId={unit?.id}
                button={
                  <Button
                    basic
                    icon="cloud upload"
                    data-tooltip={intl.formatMessage({
                      id: "unitFeatureAssignments.actions.upload.tooltip"
                    })}
                  />
                }
              />
            </FeatureToggleActive>
          </IsSystemAdmin>
        </Grid.Column>
      </Grid>

      <Grid
        data-component="unit_feature_items"
        style={{ marginTop: "5px", paddingLeft: "15px" }}
      >
        <If condition={!unitFeatures.length}>
          <Header as="h5">
            <FormattedMessage
              id="roomBook.upgradeBundles.notFoundInUnit"
              defaultMessage="Es liegen keine Upgrade Pakete zu dieser Wohneinheit vor"
            />
          </Header>
        </If>
        <If condition={!!unitFeatures.length}>{renderList()}</If>
      </Grid>
    </Segment>
  );
};

export default UnitFeaturesList;
