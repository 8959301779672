import { get } from "lodash";
import createCachedSelector from "re-reselect";
import { getIsLoading } from "shared/selectors";
import Resource from "../../shared/actions/resourceActions";
import Resources from "../../shared/actions/resourcesActions";
import { UPDATE_MESSAGE } from "../../shared/constants/appConstants";
import apiRequest from "../../shared/network/apiRequest";
import BaseCartAssignmentResource from "./cartAssignmentActions";
import BaseLineItemResource, {
  BaseSubLineItemResource
} from "./lineItemActions";

export class ActivitiesResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "activity", "/api/v1/activities");
  }

  successor(id, values) {
    return apiRequest.post(`${this.url}/${id}/successors`, this.dispatch, {
      successor: values
    });
  }
}

export class SuccessorsResource extends Resources {
  constructor(dispatch, activityId) {
    super(dispatch, "successor", `/api/v1/activities/${activityId}/successors`);
  }
}

export class DeadlinesResource extends Resources {
  constructor(dispatch, id) {
    super(dispatch, "deadlines", `/api/v1/activities/${id}/deadlines/`);
  }

  batchUpdate(values) {
    const payload = {};
    payload[this.namespace] = values;
    return apiRequest.patch(`${this.url}/batch`, this.dispatch, payload);
  }
}

export class TransitionsResource extends Resources {
  constructor(dispatch, id) {
    super(dispatch, "transition", `/api/v1/activities/${id}/transitions/`);
  }
}

export class ContractorAssignmentResource extends Resources {
  constructor(dispatch, activityId) {
    super(
      dispatch,
      "contractor_assignment",
      `/api/v1/activities/${activityId}/contractor_assignments`
    );
  }

  update_all(params) {
    return apiRequest.patch(this.url, this.dispatch, params);
  }

  getSuggestion() {
    return apiRequest.get(`${this.url}/suggestion`, this.dispatch);
  }
}

export class AttachmentReferencesResource extends Resources {
  constructor(dispatch, id) {
    super(dispatch, "attachment", `/api/v1/activities/${id}/attachments`);
  }

  remove(id) {
    const url = `${this.url}/${id}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  updateAttachment(values) {
    if (!values || !values.attachment || !values.attachment.id) {
      console.error("updateAttachment failed payload not given");
      return false;
    }
    const url = `${this.url}/${values.attachment.id}`;
    return apiRequest
      .patch(url, this.dispatch, values)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  assignAttachment(id) {
    if (!id) {
      console.error("assignAttachment failed payload not given");
      return false;
    }
    const url = `${this.url}`;
    return apiRequest
      .post(url, this.dispatch, { attachment: { id } })
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }
}

export class TasksResource extends Resources {
  constructor(dispatch) {
    super(dispatch);
  }

  complete(id) {
    const payload = {
      task: {
        status: "completed"
      }
    };
    return apiRequest.patch(
      `/api/v1/tasks/${id}/status`,
      this.dispatch,
      payload
    );
  }

  reopen(id) {
    const payload = {
      task: {
        status: "pending"
      }
    };
    return apiRequest.patch(
      `/api/v1/tasks/${id}/status`,
      this.dispatch,
      payload
    );
  }
}

export class ExportResource extends Resources {
  constructor(dispatch, activityId) {
    super(dispatch, "export", `/api/v1/activities/${activityId}/export`);
  }
}

export class LineItemResource extends BaseLineItemResource {
  constructor(dispatch, activityId) {
    super(dispatch, `/api/v1/activities/${activityId}/line_items`);
  }
}

export class SubLineItemResource extends BaseSubLineItemResource {
  constructor(dispatch, activityId) {
    super(dispatch, `/api/v1/activities/${activityId}/sub_line_items`);
  }

  update(subLineItems) {
    const url = `${this.url}/update_multiple`;
    const payload = { sub_line_items: subLineItems };
    return apiRequest
      .put(url, this.dispatch, payload)
      .then(response => {
        this.propagateUpdate(response);
      })
      .catch(this.handleUpdateFailed);
  }
}

const getActivityId = (_, props) => get(props, "cart.activity_id");
const getLineItemId = (_, props) => get(props, "lineItem.id");

export class CartAssignmentResource extends BaseCartAssignmentResource {
  static isLoadingSelector = createCachedSelector(
    [getIsLoading, getLineItemId, getActivityId],
    (isLoading, lineItemId, activityId) => {
      const regExp = new RegExp(
        `/api/v1/activities/${activityId}/line_items/${lineItemId}/cart_assignments`
      );
      return Object.keys(isLoading)
        .filter(key => regExp.test(key))
        .some(key => isLoading[key]);
    }
  )(getLineItemId);

  constructor(dispatch, activityId, line_item_id) {
    super(
      dispatch,
      `/api/v1/activities/${activityId}/line_items/${line_item_id}/cart_assignments`
    );
  }
}

export class AssignmentSuggestionsResource extends Resource {
  constructor(dispatch, activityId) {
    super(
      dispatch,
      "line_item_batch_assignment",
      `/api/v1/activities/${activityId}/assignment`
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export class ActivityContactsResource extends Resources {
  constructor(dispatch, projectId, activityId) {
    super(
      dispatch,
      "activities",
      `/api/v1/projects/${projectId}/activities/${activityId}/contacts`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
