import React from "react";
import PropTypes from "prop-types";
import { Label, Popup } from "semantic-ui-react";
import { keyBy } from "lodash";
import connectStatistic from "./statisticsContainer";

class ProgressBar extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    statistics: PropTypes.array,
    processDefinition: PropTypes.object,
    height: PropTypes.number
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, statistics, processDefinition } = this.props;

    const stats = statistics;
    const phases = processDefinition.getPhases(true);
    const statsByPhase = keyBy(stats, "phase");

    const total = stats.reduce((sum, stat) => {
      return sum + stat.count;
    }, 0);

    const progressSegments = phases.map(phase => {
      const s = statsByPhase[phase.id] || {
        phase: phase.id,
        count: 0
      };
      return {
        id: phase.id,
        count: s.count,
        label: phase.label,
        color: phase.color,
        progress: (s.count * 100) / total
      };
    });

    const progressBar = this.renderSegments(progressSegments);
    const legend = (
      <table className="legend">
        <tbody>
          {progressSegments.map(segment => {
            return (
              <tr key={segment.id}>
                <td width="10%">
                  <Label
                    circular
                    empty
                    style={{ backgroundColor: segment.color }}
                  />
                </td>
                <td width="70%">{segment.label}</td>
                <td width="20%" style={{ textAlign: "right" }}>
                  {Math.round(segment.progress)}%
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
    return (
      <div data-component="room-book-progress">
        <Popup
          trigger={progressBar}
          header={i18n["roomBook.progress.title.one"]}
          content={legend}
        />
      </div>
    );
  }

  renderSegments(segments) {
    const items = segments.map(segment => {
      return (
        <div
          key={segment.label}
          style={{
            backgroundColor: segment.color,
            height: this.props.height || 25,
            width: `${segment.progress}%`,
            display: "inline-block"
          }}
        >
          &nbsp;
        </div>
      );
    });

    return (
      <a>
        <div>{items}</div>
      </a>
    );
  }
}

export default connectStatistic(ProgressBar);
