import { get, some, find, startsWith } from "lodash";
import { ExportResource } from "builder_portal/actions/activityActions";
import ActivityController from "../../../containers/activity/activityController";
import { openModal } from "../../../actions/modals";

export default class CreateExportAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.activityExport = null;
    this.exportConfiguration = null;
  }

  setExport(activityExport) {
    this.activityExport = activityExport;
  }

  setConfig(configData) {
    this.exportConfiguration = configData;
  }

  configureExport() {
    return new Promise(resolve => {
      this.dispatch(
        openModal("exportConfigDialog", {
          configArguments: this.activityExport.arguments,
          onSubmit: async configData => {
            await this.setConfig(configData);
            resolve();
          }
        })
      );
    });
  }

  async execute(params) {
    const {
      activityId,
      cartExportRole,
      cartExportFormat,
      cartExportDisplayName,
      cart,
      projectId
    } = params;
    const exportResource = new ExportResource(this.dispatch, activityId);
    const activityCtrl = new ActivityController(
      projectId,
      activityId,
      this.dispatch
    );

    let configRequired = false;

    await activityCtrl.load().then(response => {
      const { exports } = response.data.activity;
      const activityExport = find(
        exports,
        item => item.role === cartExportRole
      );
      this.setExport(activityExport);
    });

    if (this.activityExport) {
      configRequired = some(this.activityExport.arguments, argument =>
        startsWith(argument.id, "ctx")
      );
    }
    if (configRequired) {
      await this.configureExport();
    }

    const displayName = cartExportDisplayName || get(cart, "name");

    const data = {
      ...this.exportConfiguration,
      export_id: this.activityExport.id,
      format: cartExportFormat,
      role: cartExportRole,
      display_name: displayName
    };

    return exportResource.save(data).then(response => {
      const attachmentId = get(response, "data.attachment.id");
      return { ...params, attachmentId };
    });
  }
}
