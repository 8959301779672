import { useState } from "react";
import { useDispatch } from "react-redux";
import Growl from "builder_portal/actions/growlActions";
import { get } from "lodash";
import useInterval from "./useInterval";

const usePolling = (
  resource,
  cycles,
  afterPolling,
  responseProps = "import_status",
  errorMessageProps = "error_message",
  endCondition = "completed",
  failCondition = "failed",
  options = {
    errorTimeTitle: "message.file_upload.error.time.title",
    errorTimeBody: "message.file_upload.error.time.body",
    errorTitle: "message.file_upload.error.title",
    errorBody: "message.file_upload.error.body",
    successTitle: "message.file_upload.success.title",
    successBody: "message.file_upload.success.body"
  }
) => {
  const dispatch = useDispatch();
  const [resourceId, setResourceId] = useState(null);
  const [delay, setDelay] = useState(null);
  const [counter, setCounter] = useState(0);
  const [isPolling, setPolling] = useState(false);

  const pollingFinished = () => {
    setPolling(false);
    afterPolling();
    setDelay(null);
    setResourceId(null);
    setCounter(0);
  };

  const startPolling = (id, interval) => {
    setPolling(true);
    setResourceId(id);
    setDelay(interval);
  };

  const stopPolling = () => {
    setPolling(false);
    setResourceId(null);
    setDelay(null);
    setCounter(0);
  };

  useInterval(() => {
    if (resourceId) {
      setCounter(counter + 1);
      if (counter > cycles) {
        pollingFinished();
        new Growl(dispatch).error(
          options.errorTimeTitle,
          options.errorTimeBody
        );
      } else {
        resource.get(resourceId).then(res => {
          if (get(res.data, responseProps, undefined) === endCondition) {
            pollingFinished();
            new Growl(dispatch).success(
              options.successTitle,
              options.successBody
            );
          }
          if (get(res.data, responseProps, undefined) === failCondition) {
            pollingFinished();
            const errorMessage = get(res.data, errorMessageProps, "");
            new Growl(dispatch).error(options.errorTitle, options.errorBody, {
              bodyValues: { msg: errorMessage }
            });
          }
        });
      }
    }
  }, delay);

  return [startPolling, stopPolling, isPolling];
};

export default usePolling;
