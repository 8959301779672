import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Icon, Modal, Form, Button } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { range } from "lodash";
import PropTypes from "prop-types";

class RoomBookPositionDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    position: PropTypes.number,
    itemsInGroup: PropTypes.number,
    onSubmit: PropTypes.func,
    button: PropTypes.node
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isLoading: false,
      model: {
        position: props.position
      }
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getFormFactory() {
    return new FormDefinition({
      fields: [
        {
          id: "position",
          label: `roomBook.lineItems.attributes.newPosition.label`,
          rule: "isRequired"
        }
      ]
    });
  }

  onSubmit(model) {
    this.setState({ isLoading: true });
    return this.props.onSubmit(model.position).then(this.handleClose);
  }

  render() {
    const { i18n, button, itemsInGroup } = this.props;

    const form = this.getFormFactory().create(this.state.model, i18n, {
      onChange: model => this.setState({ model })
    });

    form.fields.position.props.options = range(1, itemsInGroup + 1).map(idx => {
      return {
        text: idx,
        value: idx,
        key: idx
      };
    });

    return (
      <Modal
        data-component="roomBookPositionDialog"
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.actions.sortOrder"
            defaultMessage="roomBook.actions.sortOrder"
          />
        </Modal.Header>
        <Modal.Content>
          <Form
            id="positionForm"
            data-component="positionForm"
            onSubmit={form.handleSubmit(this.onSubmit)}
          >
            <Form.Field width="16">
              <Field component="Select" {...form.fields.position} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="submit"
            color="green"
            data-form="position"
            onClick={form.handleSubmit(this.onSubmit)}
            loading={this.state.isLoading}
            id="submit"
          >
            <Icon name="sort" />
            <FormattedMessage
              id="meta.actions.move"
              defaultMessage="meta.actions.move"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleOpen() {
    this.setState({
      open: true,
      isLoading: false
    });
  }

  handleClose() {
    this.setState({
      open: false,
      isLoading: false
    });
  }
}

export default RoomBookPositionDialog;
