import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    i18n: state.i18n
  };
};

export const withI18n = Component => {
  return connect(mapStateToProps)(Component);
};
