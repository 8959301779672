import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import { browserHistory } from "shared/routes/browserHistory";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import RoomBookForm from "./RoomBookForm";

class RoomBookDialog extends Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);

    this.state = {
      open: false
    };
  }

  onSave(values) {
    const { actions, projectId } = this.props;
    return actions.roomBook.save(values).then(data => {
      const id = get(data, "data.room_book.id");
      this.setState({ open: false });
      if (id) {
        browserHistory.push(`/projects/${projectId}/room_books/${id}`);
      }
    });
  }

  render() {
    const { i18n, button, model, catalogs } = this.props;

    return (
      <Modal
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
        size="small"
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.actions.addTemplate"
            defaultMessage="roomBook.actions.addTemplate"
          />
        </Modal.Header>
        <Modal.Content>
          <RoomBookForm
            i18n={i18n}
            model={model}
            save={this.onSave}
            catalogs={catalogs}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

RoomBookDialog.propTypes = {
  i18n: PropTypes.object,
  model: PropTypes.object,
  projectId: PropTypes.string,
  actions: PropTypes.object,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape)
};

RoomBookDialog.defaultProps = {
  catalogs: []
};

export default RoomBookDialog;
