import es6Promise from "es6-promise";
// es lint:
/* global Promise */
import apiRequest from "shared/network/apiRequest";
import Resources from "shared/actions/resourcesActions";
import Resource from "shared/actions/resourceActions";
import {
  UPDATE_MESSAGE,
  UPDATE_ROOM_BOOK
} from "shared/constants/appConstants";
import BaseCartAssignmentResource from "./cartAssignmentActions";

es6Promise.polyfill();

export class ProjectRoomBooksResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "room_book", `/api/v1/projects/${projectId}/room_books`);
    this.refreshPageContentAfterCommit = true;
  }

  createFromMultiple(unitId, selectedRoomBooks) {
    const url = `${this.url}/create_from_multiple`;
    const payload = {
      unit_id: unitId,
      source_room_books: selectedRoomBooks
    };
    return apiRequest
      .post(url, this.dispatch, payload)
      .then(this.propagateUpdate);
  }

  copyRoomBookToUnit(options) {
    const payload = {
      unit_id: options.unitId,
      room_book: {
        name: options.roomBookName,
        source_id: options.roomBookId,
        copy_mode: "fork"
      }
    };
    return apiRequest.post(this.url, this.dispatch, payload);
  }

  generate(roomBookId, payload) {
    const url = `${this.url}/${roomBookId}/generator`;
    return apiRequest
      .post(url, this.dispatch, payload)
      .then(this.propagateUpdate);
  }

  copy_to_derived_room_books(roomBookId, payload) {
    const url = `${this.url}/${roomBookId}/copy_to_derived_room_books`;
    return apiRequest
      .post(url, this.dispatch, payload)
      .then(this.propagateUpdate);
  }

  deleteContent(roomBookId, contentToDelete) {
    const url = `${this.url}/${roomBookId}/content`;
    return apiRequest
      .patch(url, this.dispatch, { delete: contentToDelete })
      .then(this.propagateUpdate);
  }

  descendantsStatus(id) {
    return apiRequest
      .get(`${this.url}/${id}/descendants_status`, this.dispatch)
      .then(this.propagateUpdate);
  }

  reset(roomBookId) {
    const url = `${this.url}/${roomBookId}/reset`;
    return apiRequest.post(url, this.dispatch);
  }

  purge(roomBookId) {
    const url = `${this.url}/${roomBookId}/purge`;
    return apiRequest.delete(url, this.dispatch);
  }

  derivedRoomBooks(roomBookId) {
    return apiRequest
      .get(`${this.url}/${roomBookId}/derived_room_books`, this.dispatch)
      .then(this.propagateUpdate);
  }

  fetchTemplatesWithoutPropagatedUpdate(targetProject) {
    return apiRequest.get(
      `/api/v1/projects/${targetProject}/room_books?template=true`,
      this.dispatch
    );
  }

  copyRoomBookTemplates(payload) {
    return apiRequest
      .post(`${this.url}/copy_room_book_templates`, this.dispatch, payload)
      .then(this.propagateUpdate);
  }
}

export class RoomBookCartResource extends Resources {
  constructor(dispatch, roomBookId) {
    super(dispatch, "cart", `/api/v1/room_books/${roomBookId}/carts`);
  }
}

export class CartAssignmentResource extends BaseCartAssignmentResource {
  constructor(dispatch, roomBookId, lineItemId) {
    super(
      dispatch,
      `/api/v1/room_books/${roomBookId}/line_items/${lineItemId}/cart_assignments`
    );
  }
}

export class BatchCartAssignmentResource extends Resources {
  constructor(dispatch, roomBookId) {
    super(
      dispatch,
      "cart_assignment",
      `/api/v1/room_books/${roomBookId}/cart_assignments`
    );

    this.refreshPageContentAfterCommit = true;
  }
}

export class EventedRoomBookResources extends Resources {
  propagateRoomBookEvent = response => {
    if (response.data.events) {
      this.dispatch({
        type: UPDATE_ROOM_BOOK,
        payload: response.data.events
      });
    } else {
      this.propagateUpdate(response);
    }
    return response;
  };

  save(values, parentUrlString = "") {
    return super
      .save(values, parentUrlString)
      .then(this.propagateRoomBookEvent);
  }

  remove(id) {
    return super.remove(id).then(this.propagateRoomBookEvent);
  }
}

class EventedRoomBookResource extends Resource {
  propagateRoomBookEvent = response => {
    if (response.data.events) {
      this.dispatch({
        type: UPDATE_ROOM_BOOK,
        payload: response.data.events
      });
    } else {
      this.propagateUpdate(response);
    }
    return response;
  };

  save(values, parentUrlString = "") {
    return super
      .save(values, parentUrlString)
      .then(this.propagateRoomBookEvent);
  }

  remove() {
    return super.remove().then(this.propagateRoomBookEvent);
  }
}

export class LineItemResource extends EventedRoomBookResources {
  constructor(dispatch, roomBookId) {
    super(dispatch, "line_item", `/api/v1/room_books/${roomBookId}/line_items`);
    this.roomBookId = roomBookId;
  }

  save(values, parentUrlString = "") {
    return new Promise((resolve, reject) => {
      super
        .save(values, parentUrlString)
        .then(this.propagateRoomBookEvent)
        .then(response => {
          resolve(response);
          return response;
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  move(element, position) {
    const payload = {
      id: element.id,
      position
    };
    return super.save(payload).then(() => {
      this.dispatch({
        type: UPDATE_ROOM_BOOK,
        payload: [
          {
            type: "line_item_moved",
            payload: { ...payload, section_id: element.section_id }
          }
        ]
      });
    });
  }

  updateParent(element, parent) {
    const payload = {
      id: element.id,
      position: parent.line_items.length + 1,
      room_book_section_id: parent.id
    };

    return super.save(payload).then(response => {
      this.dispatch({
        type: UPDATE_ROOM_BOOK,
        payload: [
          {
            type: "line_item_parent_changed",
            payload: {
              element,
              oldSectionId: element.section_id,
              newSectionId: parent.id
            }
          }
        ]
      });
      return response;
    });
  }

  add(payload) {
    const url = `/api/v1/room_books/${this.roomBookId}/sections/${payload.section_id}/line_items`;
    return apiRequest
      .post(url, this.dispatch, payload)
      .then(this.propagateRoomBookEvent);
  }

  cancel(id, options) {
    const url = `/api/v1/room_books/${this.roomBookId}/line_items/${id}/cancellation`;
    return apiRequest
      .post(url, this.dispatch, { cancellation: { options } })
      .then(this.propagateRoomBookEvent);
  }

  timeline(id) {
    const url = `${this.url}/${id}/timeline`;
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }

  activate(id) {
    const url = `/api/v1/room_books/${this.roomBookId}/line_items/${id}/activation`;
    return apiRequest
      .post(url, this.dispatch, {})
      .then(this.propagateRoomBookEvent);
  }

  deactivate(id) {
    const url = `/api/v1/room_books/${this.roomBookId}/line_items/${id}/activation`;
    return apiRequest
      .delete(url, this.dispatch, {})
      .then(this.propagateRoomBookEvent);
  }

  getDerivedInfo(id) {
    const url = `/api/v1/room_books/${this.roomBookId}/line_items/${id}/derived_infos`;
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }
}

export class ProjectLineItemsResource extends EventedRoomBookResources {
  constructor(dispatch, projectId) {
    super(dispatch, "line_item", `/api/v1/projects/${projectId}/line_items`);
  }

  delete(ids) {
    const url = `${this.url}/delete_multiple`;
    return apiRequest
      .post(url, this.dispatch, { ids })
      .then(this.propagateRoomBookEvent)
      .catch(this.handleDeleteFailed);
  }
}

export class ProjectSectionsResource extends EventedRoomBookResources {
  constructor(dispatch, projectId) {
    super(dispatch, "section", `/api/v1/projects/${projectId}/sections`);
  }

  delete(ids) {
    const url = `${this.url}/delete_multiple`;
    return apiRequest
      .post(url, this.dispatch, { ids })
      .then(this.propagateRoomBookEvent)
      .catch(this.handleDeleteFailed);
  }
}

export class LineItemOptionalityResource extends EventedRoomBookResource {
  constructor(dispatch, roomBookId, lineItemId) {
    super(
      dispatch,
      "optionality",
      `/api/v1/room_books/${roomBookId}/line_items/${lineItemId}/optionality`
    );
  }
}

export class SubLineItemResource extends EventedRoomBookResources {
  constructor(dispatch, projectId, roomBookId) {
    super(
      dispatch,
      "sub_line_item",
      `/api/v1/room_books/${roomBookId}/sub_line_items`
    );
    this.roomBookId = roomBookId;
    this.projectId = projectId;
  }

  move(element, position) {
    const payload = {
      id: element.id,
      position
    };
    return super.save(payload).then(() => {
      this.dispatch({
        type: UPDATE_ROOM_BOOK,
        payload: [
          {
            type: "sub_line_item_moved",
            payload: { ...payload, line_item_id: element.line_item_id }
          }
        ]
      });
    });
  }

  save(values, parentUrlString = "") {
    return new Promise((resolve, reject) => {
      this.propagateRoomBookEvent({
        data: {
          events: [
            {
              type: "sub_line_item_updated",
              payload: values
            }
          ]
        }
      });
      super
        .save(values, parentUrlString)
        .then(response => {
          resolve(response);
          return response;
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  add(payload) {
    if (payload.line_item_id) {
      const url = `/api/v1/room_books/${this.roomBookId}/line_items/${payload.line_item_id}/sub_line_items`;
      return apiRequest
        .post(url, this.dispatch, payload)
        .then(this.propagateRoomBookEvent.bind(this));
    }
    const url = `/api/v1/room_books/${this.roomBookId}/sections/${payload.section_id}/line_items`;
    return apiRequest
      .post(url, this.dispatch, payload)
      .then(this.propagateRoomBookEvent.bind(this));
  }

  discardBuyerSelection(id) {
    const url = `${this.url}/${id}/buyer_selection`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateRoomBookEvent.bind(this));
  }

  saveAll(subLineItems) {
    return apiRequest
      .patch(
        `/api/v1/projects/${this.projectId}/room_books/${this.roomBookId}/batch_update`,
        this.dispatch,
        {
          sub_line_items: subLineItems
        }
      )
      .then(this.propagateRoomBookEvent.bind(this));
  }

  getDerivedInfo(id) {
    const url = `${this.url}/${id}/derived_infos`;
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }
}

export class SimpleSubLineItemResource extends Resources {
  constructor(dispatch, projectId, roomBookId) {
    super(
      dispatch,
      "sub_line_item",
      `/api/v1/room_books/${roomBookId}/sub_line_items`
    );
    this.roomBookId = roomBookId;
    this.projectId = projectId;
  }

  saveAll(subLineItems) {
    return apiRequest.patch(
      `/api/v1/projects/${this.projectId}/room_books/${this.roomBookId}/batch_update`,
      this.dispatch,
      {
        sub_line_items: subLineItems
      }
    );
  }
}

export class RoomBookSectionResource extends Resources {
  constructor(dispatch, roomBookId) {
    super(dispatch, "section", `/api/v1/room_books/${roomBookId}/sections`);
    this.refreshPageContentAfterCommit = true;
  }

  duplicate(sectionId) {
    const url = `${this.url}/${sectionId}/duplicate`;
    return apiRequest.post(url, this.dispatch, {}).then(this.propagateUpdate);
  }

  getDerivedInfo(id) {
    const url = `${this.url}/${id}/derived_infos`;
    return apiRequest.get(url, this.dispatch).then(this.propagateUpdate);
  }
}
