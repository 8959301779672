// The HTML is being sanitized
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  arrayOf,
  oneOfType,
  shape,
  number,
  string,
  instanceOf
} from "prop-types";
import DOMPurify from "dompurify";
import { Header, Icon, Segment, Grid, Loader, Label } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { getMacros } from "shared/selectors";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import MacroResource from "../../../actions/macroActions";
import MacroDialog from "./MacroDialog";
import MacroCopyDialog from "./MacroCopyDialog";
import Growl from "../../../actions/growlActions";

import "./macroItem.scss";

const MacroItems = ({ admin, actions }) => {
  const macros = useSelector(getMacros);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const loadData = () => {
    setLoading(true);
    actions.macros.fetchAll().then(() => setLoading(false));
    new ProjectsResource(dispatch).fetchAll();
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!macros || macros.length <= 0) return <Loader active />;

  const onRemove = (id, handleClose) => {
    setLoading(true);
    handleClose();
    actions.macros
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "macro.message.success.title",
          "macro.message.success.body_remove"
        );
        setLoading(false);
        loadData();
      })
      .catch(() => {
        setLoading(false);
        new Growl(dispatch).error(
          "macro.message.error.title",
          "macro.message.error.body_remove"
        );
      });
  };

  const renderDeleteButton = item => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a role="button" disabled={isLoading}>
        <span>
          {process.name} <Icon name="trash" color="grey" />
        </span>
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => onRemove(item.id, handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="process.remove_dialog.title"
        message="process.remove_dialog.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <div>
      <div data-component="macroItem">
        <Segment attached="top">
          <Grid>
            <Grid.Column width={12}>
              <Header as="h3">Standardemails (Makros)</Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <If condition={admin.group === "admin"}>
                <MacroDialog
                  model={{}}
                  macros={macros}
                  resource={actions.macros}
                  button={
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a role="button">
                      <span>
                        <Icon name="plus" color="grey" />
                      </span>
                    </a>
                  }
                />
              </If>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached>
          <p>
            Unten finden Sie eine Vorschau aller im System hinterlegter Makros.
            Bei Änderungswünschen wenden Sie sich bitte an{" "}
            <a
              target="_support"
              href="mailto:support@baudigital.com"
              className="external"
            >
              support@baudigital.com
            </a>
            .
          </p>
          <p>
            Die blau hinterlegten Wörter sind beispielhafte Werte für die
            Platzhalter in den Vorlagen, und werden beim Benutzen der Makros mit
            den korrekten Werten ersetzt.
          </p>
        </Segment>
      </div>
      {macros.map(macro => {
        return (
          <div key={macro.id} data-component="macroItem">
            <Segment attached="top">
              <Grid>
                <Grid.Column width={8} verticalAlign="middle">
                  <div className="flex align-items-center">
                    <Header as="h4" className="header">
                      {macro.name}
                    </Header>
                    {macro.projects?.map(p => (
                      <Label key={p.id}>{p.name}</Label>
                    ))}
                  </div>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right" verticalAlign="middle">
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink
                      id={macro.id}
                      type="Macro"
                      size="small"
                    />
                  </IsVersionHistoryAccessible>
                  <If condition={admin.group === "admin"}>
                    <MacroCopyDialog
                      model={macro}
                      trigger={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a role="button" id={`macro-copy-${macro.id}-edit`}>
                          <span>
                            <Icon name="copy" color="grey" />
                          </span>
                        </a>
                      }
                    />
                    <MacroDialog
                      model={macro}
                      resource={actions.macros}
                      button={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a role="button" id={`macro-${macro.id}-edit`}>
                          <span>
                            <Icon name="setting" color="grey" />
                          </span>
                        </a>
                      }
                    />
                    {renderDeleteButton(macro)}
                  </If>
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment attached>
              <Header as="h4">
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(macro.preview.subject)
                  }}
                />
              </Header>
              <div
                className="email"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(macro.preview.body)
                }}
              />
            </Segment>
          </div>
        );
      })}
    </div>
  );
};

MacroItems.propTypes = {
  actions: shape({
    macros: instanceOf(MacroResource)
  }).isRequired,
  admin: shape({ group: string }).isRequired,
  macros: arrayOf(
    shape({
      id: oneOfType([number, string]),
      name: string,
      preview: shape({
        subject: string,
        body: string
      })
    })
  )
};

MacroItems.defaultProps = {
  macros: []
};

export default MacroItems;
