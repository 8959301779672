import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dropdown, Grid, Header, Button, Icon } from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { FormattedMessage, useIntl } from "react-intl";
import { getUnits } from "shared/selectors/units";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { getAccount, getProject } from "shared/selectors";
import { get } from "lodash";
import UnitFeatureAssignementsList from "./UnitFeatureAssignementsList";
import UnitFeaturesUploadModal from "./UnitFeaturesUploadModal";
import HasProjectAccessRight from "../../../../shared/components/authorization/HasProjectAccessRight";

const UnitFeaturesProjectTab = () => {
  const intl = useIntl();
  const units = useSelector(getUnits);
  const unitFeatureOptions = useSelector(getUnitFeatureOptions);
  const [filters, setFilters] = useState({
    unitFeatureIds: [],
    unitNoFeatureIds: []
  });
  const account = useSelector(getAccount);
  const project = useSelector(getProject);

  const unitFilterOptions = useMemo(() => {
    return units.map(({ id, display_name }) => ({
      key: id,
      value: id,
      text: display_name
    }));
  }, [units]);

  return (
    <>
      <Grid>
        <Grid.Column width={13}>
          <Header size="large">
            <FormattedMessage id="unitFeatureAssignments.title" />
          </Header>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <HasProjectAccessRight
            right="allow_access_edit_units"
            projectId={project.id}
          >
            <FeatureToggleActive featureToggleName="show_unit_feature_groups">
              <Button
                basic
                icon
                href={`${window.location.origin}/api/v1/projects/${
                  project?.slug
                }/unit_features/export.xlsx?_bearer=${get(account, [
                  "data",
                  "auth_token"
                ])}`}
                target="_blank"
                data-tooltip={intl.formatMessage({
                  id: "unitFeatureAssignments.actions.download.tooltip"
                })}
              >
                <Icon name="cloud download" />
              </Button>
              <UnitFeaturesUploadModal
                button={
                  <Button
                    basic
                    icon="cloud upload"
                    data-tooltip={intl.formatMessage({
                      id: "unitFeatureAssignments.actions.upload.tooltip"
                    })}
                  />
                }
              />
            </FeatureToggleActive>
          </HasProjectAccessRight>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={4}>
          <Header as="h5">
            <FormattedMessage id="unitFeatureAssignments.filters.unit" />
          </Header>
          <Dropdown
            name="unitFilter"
            fluid
            selection
            search
            clearable
            value={filters.unitId}
            options={unitFilterOptions}
            onChange={(_, { value }) =>
              setFilters({
                unitId: value,
                unitFeatureIds: [],
                unitNoFeatureIds: []
              })
            }
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <Header as="h5">
            <FormattedMessage id="unitFeatureAssignments.filters.unitFeature" />
          </Header>
          <Dropdown
            name="featureFilter"
            fluid
            selection
            search
            clearable
            multiple
            value={filters.unitFeatureIds}
            options={unitFeatureOptions}
            onChange={(_, { value }) =>
              setFilters(prev => ({
                unitFeatureIds: value,
                unitNoFeatureIds: prev.unitNoFeatureIds,
                unitId: null
              }))
            }
          />
        </Grid.Column>

        <Grid.Column width={6}>
          <Header as="h5">
            <FormattedMessage id="unitFeatureAssignments.filters.unitNoFeature" />
          </Header>
          <Dropdown
            name="noFeatureFilter"
            fluid
            selection
            search
            clearable
            multiple
            value={filters.unitNoFeatureIds}
            options={unitFeatureOptions}
            onChange={(_, { value }) =>
              setFilters(prev => ({
                unitNoFeatureIds: value,
                unitFeatureIds: prev.unitFeatureIds,
                unitId: null
              }))
            }
          />
        </Grid.Column>
      </Grid>
      <UnitFeatureAssignementsList filters={filters} units={units} />
    </>
  );
};

export default UnitFeaturesProjectTab;
