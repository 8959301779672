/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useMemo } from "react";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Loader, Dropdown, Form, Icon, Header } from "semantic-ui-react";
import { ProjectsCatalogResource } from "builder_portal/actions/projectCatalogActions";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import { getTrades, getAccount } from "shared/selectors";
import { getSectionOptions } from "shared/selectors/units";
import { If } from "shared/components/elements/Conditions";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import "./styles.scss";
import { FormattedMessage, useIntl } from "react-intl";

import ProductItem from "./ProductItem";

const ProjectQuantities = ({ projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [
    loadingCatalogsAndStructure,
    setLoadingCatalogsAndStructure
  ] = useState(false);

  const [productQuantities, setProducQuantities] = useState([]);
  const [productsData, setProducts] = useState([]);
  const [initialProductsData, setInitialProducts] = useState([]);
  const [productsGroup, setProductGroups] = useState([]);
  const [sections, setSections] = useState([]);
  const [units, setUnits] = useState([]);
  const trades = useSelector(getTrades);
  const [tradeId, setTradeId] = useState(null);
  const [unitFeatureId, setUnitFeatureId] = useState(null);
  const [params, setParams] = useState({ contracted: true });
  const sectionOptions = useSelector(getSectionOptions);
  const unitFeatureOptions = useSelector(getUnitFeatureOptions);
  const account = useSelector(getAccount);

  const tradesOptions = useMemo(() => {
    return trades.map(trade => ({
      key: trade.id,
      text: trade.label,
      value: trade.id
    }));
  }, [trades]);

  useEffect(() => {
    if (!projectId) return;
    setLoadingCatalogsAndStructure(true);
    const projectCalatogResource = new ProjectsCatalogResource(
      dispatch,
      projectId
    );
    const projectsResource = new ProjectsResource(dispatch);
    Promise.all([
      projectCalatogResource.get(),
      projectsResource.fetchStructure(projectId),
      new UnitFeatureResource(dispatch, projectId).fetchAll()
    ])
      .then(([productsResponse, structureResponse]) => {
        setProductGroups(productsResponse.data.product_groups);
        setProducts(productsResponse.data.products);
        setInitialProducts(productsResponse.data.products);
        setSections(structureResponse.data.sections);
        setUnits(structureResponse.data.units);
        setLoadingCatalogsAndStructure(false);
      })
      .catch(() => setLoadingCatalogsAndStructure(false));
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;
    setLoading(true);
    // remove all keys that have null or undefined values or empty string
    const newParams = { ...params };
    Object.keys(newParams).forEach(key => {
      if (
        newParams[key] === null ||
        newParams[key] === undefined ||
        newParams[key] === ""
      ) {
        delete newParams[key];
      }
    });

    const projectsResource = new ProjectsResource(dispatch);
    projectsResource
      .fetchProductQuantities(projectId, newParams)
      .then(response => {
        setProducQuantities(response.data.product_quantities);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [projectId, params]);

  const productGroupsMapped = useMemo(() => {
    const groups = {};
    productQuantities.forEach(quantity => {
      const groupId = quantity.product_group_id;
      if (!groups[groupId]) {
        groups[groupId] = {
          id: groupId,
          name: productsGroup.find(group => group.id === groupId)?.name,
          trades: productsGroup.find(group => group.id === groupId)?.trades,
          priceStrategy: productsGroup.find(group => group.id === groupId)
            ?.price_strategy,
          products: []
        };
      }
      const product = productsData.find(
        productItem => productItem.id === quantity.product_id
      );

      if (product) {
        groups[groupId].products.push({
          ...product,
          quantity: quantity.quantity
        });
      }
    });

    return Object.values(groups)
      .filter(group => group.products.length > 0)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [productQuantities, productsData, productsGroup]);

  const filterProductsByTrade = seletectedTradeId => {
    // filter productsData where productData.trades includes tradeId
    if (!seletectedTradeId) return setProducts(initialProductsData);
    const filteredProducts = initialProductsData.filter(product =>
      product.trades.includes(seletectedTradeId)
    );
    setProducts(filteredProducts);
  };

  const handleStatusChange = (field, value) => {
    if (field === "unit_feature_id") {
      setUnitFeatureId(value);
      setParams({ unit_feature_id: value });
    }
    if (field === "section_id") {
      setParams({ section_id: value });
    }
    if (field === "status") {
      setParams({
        [value]: true
      });
    }

    if (value === null) {
      setParams({});
    }
  };

  const statusOptions = [
    {
      key: "all",
      text: intl.formatMessage({
        id: "projectQuantities.filter.status.all"
      }),
      value: null
    },
    {
      key: "offered",
      value: "offered",
      datalabel: intl.formatMessage({
        id: "projectQuantities.filter.status.offered"
      }),
      text: intl.formatMessage({
        id: "projectQuantities.filter.status.offered"
      })
    },
    {
      key: "ordered",
      text: intl.formatMessage({
        id: "projectQuantities.filter.status.ordered"
      }),
      value: "ordered",
      datalabel: intl.formatMessage({
        id: "projectQuantities.filter.status.ordered"
      })
    },
    {
      key: "contracted",
      text: intl.formatMessage({
        id: "projectQuantities.filter.status.contracted"
      }),
      value: "contracted",
      datalabel: intl.formatMessage({
        id: "projectQuantities.filter.status.contracted"
      })
    }
  ];

  const paramProps = React.useMemo(() => {
    const newParams = { ...params, trade_id: tradeId };
    Object.keys(newParams).forEach(key => {
      if (
        newParams[key] === null ||
        newParams[key] === undefined ||
        newParams[key] === "" ||
        key === undefined
      ) {
        delete newParams[key];
      }
    });
    return newParams;
  }, [params, tradeId]);

  return (
    <>
      <If condition={account.isEnabled("show_page_layout_v2")}>
        <Header size="large">
          <FormattedMessage
            id="project.tabs.projectQuantities"
            defaultMessage="Mengenauswertung"
          />
        </Header>
      </If>
      <Form>
        <Grid padded stackable>
          <Grid.Row>
            <Grid style={{ width: "100%" }}>
              <Grid.Column width={4}>
                <Dropdown
                  name="status"
                  fluid
                  selection
                  placeholder={intl.formatMessage({
                    id: "projectQuantities.filter.status.all"
                  })}
                  options={statusOptions}
                  defaultValue="contracted"
                  onChange={(_, { value, name }) =>
                    handleStatusChange(name, value)
                  }
                  search
                  clearable
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdown
                  name="trade_id"
                  fluid
                  selection
                  placeholder={intl.formatMessage({
                    id: "projectQuantities.filter.trade.all"
                  })}
                  options={[
                    { key: "all", text: "All", value: null },
                    ...tradesOptions
                  ]}
                  onChange={(_, { value }) => {
                    setTradeId(value);
                    filterProductsByTrade(value);
                  }}
                  value={tradeId}
                  search
                  clearable
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdown
                  name="section_id"
                  fluid
                  selection
                  placeholder={intl.formatMessage({
                    id: "projectQuantities.filter.section.all"
                  })}
                  options={sectionOptions}
                  onChange={(_, { value, name }) =>
                    handleStatusChange(name, value)
                  }
                  search
                  clearable
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdown
                  name="unit_feature_id"
                  fluid
                  selection
                  placeholder={intl.formatMessage({
                    id: "projectQuantities.filter.unitFeature.all"
                  })}
                  options={unitFeatureOptions}
                  onChange={(_, { value }) =>
                    handleStatusChange("unit_feature_id", value)
                  }
                  value={unitFeatureId}
                  search
                  clearable
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <table
              data-component="quantitiesTable"
              style={{
                width: "100%"
              }}
            >
              <thead>
                <tr>
                  <th colSpan="8">
                    <FormattedMessage id="projectQuantities.productGroups" />
                  </th>
                  <th className="width-100">
                    <FormattedMessage id="projectQuantities.quantity" />
                  </th>
                  <th className="width-70" />
                </tr>
              </thead>
              <tbody>
                {isLoading || loadingCatalogsAndStructure ? (
                  <tr>
                    <td>
                      <Loader
                        active
                        inline="centered"
                        style={{
                          marginTop: 20
                        }}
                      />
                    </td>
                  </tr>
                ) : productGroupsMapped.length === 0 ? (
                  <tr>
                    <td colSpan="10">
                      <FormattedMessage id="projectQuantities.noProductsFound" />
                    </td>
                  </tr>
                ) : (
                  productGroupsMapped.map(group => (
                    <React.Fragment key={group.id}>
                      <tr className="row01">
                        <td className="row-header" colSpan="8">
                          <h4>{group.name}</h4>
                        </td>
                        <td
                          className="width-100"
                          colSpan="1"
                          style={{
                            textAlign: "right"
                          }}
                        >
                          <FormattedMessage
                            id={`product_group.price_strategies.${group.priceStrategy}.label`}
                          />
                        </td>
                        <td
                          className="width-70"
                          style={{
                            textAlign: "center"
                          }}
                        >
                          <a
                            href={`/products/${projectId}/product_groups/${group.trades[0]}/${group.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon name="external" />
                          </a>
                        </td>
                      </tr>
                      {group.products.map(product => (
                        <ProductItem
                          key={product.id}
                          product={product}
                          sections={sections}
                          units={units}
                          projectId={projectId}
                          params={paramProps}
                          unitFeatureId={unitFeatureId}
                          sectionId={params.section_id}
                        />
                      ))}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

ProjectQuantities.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectQuantities;
