import PropTypes from "prop-types";
import React from "react";
import { Form, Icon } from "semantic-ui-react";
import "./inlineInput.scss";

class InlineInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      focus: false,
      value: ""
    };

    this.inlineInput = null;
  }

  componentDidMount() {
    const { model, fieldName } = this.props;
    this.setState({ value: model[fieldName] });
  }

  componentDidUpdate(prevProps) {
    const { model, fieldName } = this.props;
    if (model[fieldName] !== prevProps.model[fieldName]) {
      this.writeModelToState(model[fieldName]);
    }
  }

  writeModelToState = modelField => {
    this.setState({ value: modelField });
  };

  handleFocus = () => {
    this.setState({ focus: true });
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleBlur = event => {
    const { value } = this.state;
    this.setState({ value: event.target.value }, () => {
      this.save(value);
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.inlineInput.blur();
    }
  };

  save = value => {
    const { onChange, model, fieldName } = this.props;
    const values = { ...model, [fieldName]: value };
    this.setState({ loading: true, focus: false });
    onChange(values).then(() => {
      this.setState({ loading: false, focus: false });
    });
  };

  render() {
    const { labelPosition, fluid, transparent, className, icon } = this.props;
    const { focus, loading, value } = this.state;

    const classNames = [className];
    if (focus) {
      classNames.push("focus");
    }
    if (labelPosition && labelPosition === "left") {
      classNames.push("leftIcon");
    }

    const inputClassNames = ["ui", "icon", "input"];
    if (fluid) inputClassNames.push("fluid");
    if (transparent) inputClassNames.push("transparent");
    if (loading) inputClassNames.push("loading");

    return (
      <Form.Field className={classNames.join(" ")} data-component="inlineInput">
        <div className={inputClassNames.join(" ")}>
          {(icon && <Icon name={icon} />) || <Icon name="pencil" />}
          <input
            ref={input => {
              this.inlineInput = input;
            }}
            value={value}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </div>
      </Form.Field>
    );
  }
}

InlineInput.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  labelPosition: PropTypes.string,
  transparent: PropTypes.bool,
  fluid: PropTypes.bool,
  icon: PropTypes.string,
  model: PropTypes.object
};

InlineInput.defaultProps = {
  className: ""
};

export default InlineInput;
