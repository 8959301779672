import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getPriceStrategyOptions } from "builder_portal/selectors/masterdata";
import { func, string } from "prop-types";

function PriceStrategyDropdown({ value, onChange }) {
  const priceStrategyOptions = useSelector(getPriceStrategyOptions);

  return (
    <Dropdown
      fluid
      selection
      value={value}
      onChange={onChange}
      options={priceStrategyOptions}
    />
  );
}

PriceStrategyDropdown.defaultProps = {
  value: "flat"
};
PriceStrategyDropdown.propTypes = {
  value: string,
  onChange: func.isRequired
};

export default PriceStrategyDropdown;
