import { arrayOf, func, shape, string, bool, array } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getImages } from "shared/selectors/project";
import "./redactor/redactor"; /* global $R */
import "./redactor/de";
import "./redactor/redactor.css";
import "./redactor/table";
import "./redactor/variable";
import "./redactor/variable.css";
import { closestChar } from "shared/helpers/closestChar";
import { replaceAt } from "shared/helpers/replaceAt";
import { isEqual } from "lodash";

import "./wysiwygEditor.scss";

export function toRedactorVariable(liquidPreview) {
  return `<span data-redactor-type="variable">${liquidPreview}</span>`;
}

class WysiwygEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { images: props.images };
    this.redactor = React.createRef();
    this.current = null;
  }

  componentDidMount() {
    const { imageButton } = this.props;

    if (imageButton) {
      const plugins = ["imageButton"];
      this.addImageButton($R);
      this.setUpRedactor(plugins);
      // in the process of adding button, we cannot reference it, so the only option is DOM manipulation and inserting the trigger button of the modal instead of the created one
      const addedButton = document.querySelector(
        '[data-re-name="imageButton"]'
      );
      const mediathekButton = document.querySelector("#image-selector-button");
      addedButton.parentNode.replaceChild(mediathekButton, addedButton);
    } else {
      this.setUpRedactor();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newImageList = props.images;
    const currentImageList = state.images;
    if (!isEqual(newImageList, currentImageList))
      return { images: newImageList };

    return state;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { state } = this;
    // if list of gallery images is changes, update...
    if (!isEqual(nextState.images, state.images)) return true;
    // otherwise don't update, redactor's vanilla javascript will mutate DOM.
    return false;
  }

  componentWillUnmount() {
    $R(this.redactor.current, "destroy");
  }

  setUpRedactor = (plugins = []) => {
    const {
      flatLiquidContext,
      onChange,
      buttonsHide,
      isReply,
      emailReplyIncludeThread
    } = this.props;

    this.app = $R(this.redactor.current, {
      lang: "de",
      notranslate: true,
      buttonsHide,
      plugins: ["table"]
        .concat(flatLiquidContext ? "variable" : [])
        .concat(plugins),
      /*
        Variables should be shown instead of values. But there are two exceptions. Show values if:
        1. variables are too long - they contain some logic (conditions, etc.)
        2. varibles contain links
      */
      variables: flatLiquidContext?.map(({ value, variable }) => {
        return variable.indexOf("{%") === -1 && variable.indexOf("href") === -1
          ? variable
          : value;
      }),
      callbacks: {
        changed: html => {
          const value = this.redactorToLiquid(html);
          onChange({ value });
          return value;
        },
        started: () => {
          const { value } = this.props;
          if (emailReplyIncludeThread && isReply && value) {
            setTimeout(() => {
              this.app?.selection.caret.offset.set({
                start: 3,
                end: value?.length
              });
              this.app?.module.inline.format({ tag: "blockquote" });
              this.app?.selection.caret.offset.set({ start: -1, end: -1 });
            }, 0);
          }
        },
        click: () => {
          this.carret = this.app.selection.getCurrent();
        },
        keyup: () => {
          this.carret = this.app.selection.getCurrent();
        },
        enter: () => {
          this.carret = this.app.selection.getCurrent();
        },
        tab: () => {
          this.carret = this.app.selection.getCurrent();
        }
      },
      minHeight: "22vh",
      maxHeight: "50vh"
    });
  };

  addImageButton = R => {
    R.add("plugin", "imageButton", {
      translations: {
        de: {
          imageButton: "Bild"
        }
      },
      init(app) {
        this.app = app;
        this.lang = app.lang;
        this.toolbar = app.toolbar;
        // this.selection = app.selection;
        // this.insertion = app.insertion;
      },
      start() {
        const buttonData = {
          title: this.lang.get("imageButton"),
          api: "plugin.imageButton.action"
        };

        this.toolbar.addButton("imageButton", buttonData);
      },
      action: () => {}
    });
  };

  liquidToRedactor = liquid => {
    const { flatLiquidContext } = this.props;
    let html = liquid;

    function htmlAwareReplace({ value: liquidPreview, variable }) {
      let foundPos = html.indexOf(variable);
      while (foundPos !== -1) {
        const HTMLTags = ["<", ">"];
        const lookAheadChar = closestChar(
          html,
          HTMLTags,
          foundPos + variable.length,
          1
        ).foundChar;
        const lookBehindChar = closestChar(html, HTMLTags, foundPos - 1, -1)
          .foundChar;

        const isBetweenHtmlTags =
          lookAheadChar === "<" && lookBehindChar === ">";
        const replaceVariable = isBetweenHtmlTags
          ? toRedactorVariable(liquidPreview)
          : variable;

        html = replaceAt(html, variable, replaceVariable, foundPos);

        // next start is at the end of your match
        foundPos = html.indexOf(variable, foundPos + replaceVariable.length);
      }
    }

    flatLiquidContext?.forEach(htmlAwareReplace);
    return html;
  };

  redactorToLiquid = html => {
    const { flatLiquidContext } = this.props;

    let value = html;
    flatLiquidContext?.forEach(({ value: liquidPreview, variable }) => {
      value = value?.replaceAll(
        `<span data-redactor-type="variable">${liquidPreview}</span>`,
        `${variable}`
      );
    });
    return value;
  };

  handleSelectImage = imageUrl => {
    this.app.caret.setAfter(this.carret);
    this.app.editor.insertion.insertHtml(`<img src="${imageUrl}" />`);
  };

  render() {
    const { value, name, imageButton, images } = this.props;
    return (
      <>
        <textarea
          ref={this.redactor}
          defaultValue={this.liquidToRedactor(value)}
          name={name}
        />
        <div>
          {typeof imageButton === "function" &&
            imageButton(this.handleSelectImage, images)}
        </div>
      </>
    );
  }
}

WysiwygEditor.defaultProps = {
  flatLiquidContext: undefined,
  value: "",
  buttonsHide: [],
  isReply: false,
  emailReplyIncludeThread: false,
  imageButton: null,
  images: []
};

WysiwygEditor.propTypes = {
  flatLiquidContext: arrayOf(shape({ variable: string, value: string })),
  onChange: func.isRequired,
  value: string,
  name: string.isRequired,
  buttonsHide: arrayOf(string),
  isReply: bool,
  emailReplyIncludeThread: bool,
  imageButton: func,
  // eslint-disable-next-line react/forbid-prop-types
  images: array
};

const mapStateToProps = state => ({
  images: getImages(state)
});

export default connect(mapStateToProps)(WysiwygEditor);
