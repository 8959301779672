/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Segment } from "semantic-ui-react";
import { DocumentationItemsResource } from "builder_portal/actions/documentationItemsActions";
import { useSelector, useDispatch } from "react-redux";
import { getDocumentationItems } from "shared/selectors";
import DocumentationItem from "./DocumentationItem";
import DocumentationItemsList from "./DocumentationItemsList";
import { prefix } from "./routePrefix";

const ApiDocumentation = () => {
  const { pathname } = window.location;
  const dispatch = useDispatch();
  const resource = new DocumentationItemsResource(dispatch);
  const documentationItems = useSelector(getDocumentationItems);
  const [loading, setLoading] = useState(false);

  const [
    seletectedDocumentationItem,
    setSeletectedDocumentationItem
  ] = useState(null);

  const documentationItemId = pathname.slice(prefix.length);

  const isDocumentationItem = React.useMemo(() => {
    if (pathname.startsWith(prefix)) {
      if (documentationItemId) {
        return true;
      }
    }
    return false;
  }, [pathname]);

  useEffect(() => {
    setLoading(true);

    resource.fetchAll().then(() => {
      setLoading(false);
    });
  }, [pathname]);

  useEffect(() => {
    if (isDocumentationItem) {
      const documentationItem = documentationItems.find(
        item => item.slug === documentationItemId
      );
      if (documentationItem) {
        setSeletectedDocumentationItem(documentationItem);
      }
    }
  }, [pathname, documentationItems, isDocumentationItem]);

  return (
    <Segment style={{ marginTop: 0, paddingBottom: "50px" }}>
      {loading && (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
      )}
      {isDocumentationItem ? (
        <DocumentationItem
          documentationItem={seletectedDocumentationItem}
          loading={loading}
          resource={resource}
        />
      ) : (
        <DocumentationItemsList
          loading={loading}
          documentationItems={documentationItems}
          setSeletectedDocumentationItem={setSeletectedDocumentationItem}
        />
      )}
    </Segment>
  );
};

export default ApiDocumentation;
