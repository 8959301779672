import {
  UPDATE_CONTRACTOR_SELECTION,
  RESET_CONTRACTOR_SELECTION
} from "shared/constants/appConstants";
import Resources from "../../shared/actions/resourcesActions";

export default class ContractorsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "contractor", "/api/v1/contractors");
    this.refreshPageContentAfterCommit = true;
  }
}

export class ContractorAssignmentRulesResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "contractor_assignment_rule",
      `/api/v1/projects/${projectId}/contractor_assignment_rules`
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export const selectContractor = payload => {
  return { type: UPDATE_CONTRACTOR_SELECTION, payload };
};

export const resetContractorSelection = () => {
  return { type: RESET_CONTRACTOR_SELECTION };
};
