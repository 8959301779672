import React, { useCallback, useState } from "react";
import {
  Container,
  Grid,
  Image,
  Header,
  Input as InputField,
  Form as ImageDetailsForm,
  Modal
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import { Input, Form } from "formik-semantic-ui-react";

import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { ConfiguratorDesignShape } from "shared/shapes/configuratorDesign.shape";
import { lowerFirst } from "lodash";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import ImageLibrary from "shared/components/forms/ImageLibrary";
import { useSelector } from "react-redux";
import { getImages } from "shared/selectors/project";
import ActionButtons from "./ActionButtons";

const ImageRow = ({ image, handleImageChange, handleImageDelete }) => {
  const [values, setValues] = useState({ ...image });

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = { ...values, [name]: value };
    setValues(newValue);
    handleImageChange(newValue);
  };

  return (
    <Grid style={{ marginBottom: "5px" }} key={image.url}>
      <Grid.Column width={5} style={{ height: "150px", overflow: "hidden" }}>
        <span style={{ verticalAlign: "middle", display: "inline-block" }} />
        <Image key={image.url} src={image.url} size="large" />
      </Grid.Column>
      <Grid.Column width={11}>
        <ImageDetailsForm>
          <Header as="h5">
            <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.title" />
          </Header>
          <InputField
            name="title"
            value={values.title}
            onChange={handleChange}
          />

          <Header as="h5">
            <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.summary" />
          </Header>
          <InputField
            name="summary"
            value={values.summary}
            onChange={handleChange}
          />
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="button"
              onClick={() => handleImageDelete(image)}
              style={{ color: "red", textDecoration: "underline" }}
              tabIndex={0}
              onKeyDown={() => handleImageDelete(image)}
            >
              <FormattedMessage id="meta.actions.remove" />
            </a>
          </div>
        </ImageDetailsForm>
      </Grid.Column>
    </Grid>
  );
};

ImageRow.propTypes = {
  handleImageChange: PropTypes.func.isRequired,
  handleImageDelete: PropTypes.func.isRequired,
  image: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    url: PropTypes.string.isRequired
  }).isRequired
};

const ImageGalleryCard = ({
  button,
  onChange,
  initialValue,
  validationSchema,
  configuratorDesign
}) => {
  const projectImages = useSelector(getImages);
  const projectId = configuratorDesign.project_id;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = resetForm => {
    resetForm();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onSubmit = useCallback(values => {
    onChange(values);
    handleClose();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitForm, resetForm }) => {
        return (
          <Modal
            open={isOpen}
            onOpen={() => handleOpen(resetForm)}
            onClose={handleClose}
            trigger={button}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id={`configuratorDesign.dashboard.${lowerFirst(
                  initialValue.type
                )}.type`}
              />
            </Modal.Header>
            <Modal.Content scrolling>
              <Container style={{ width: "100%" }}>
                <Form id="okDashboardImageGalleryCard">
                  <Grid style={{ width: "100%" }}>
                    <Grid.Column width={16}>
                      <Input
                        fluid
                        name="params.title"
                        label={useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.title"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      {values.params.images.map(image => (
                        <ImageRow
                          key={image.url}
                          image={image}
                          handleImageDelete={img => {
                            const images = values.params?.images?.filter(
                              x => x.url !== img.url
                            );
                            setFieldValue("params.images", images);
                          }}
                          handleImageChange={img => {
                            const images = values.params?.images?.map(x =>
                              x.url === img.url ? img : x
                            );
                            setFieldValue("params.images", images);
                          }}
                        />
                      ))}
                      <ImageLibrary
                        projectId={projectId}
                        multiple
                        getSelectedImage={image => {
                          setFieldValue(
                            "params.images",
                            values.params.images.concat({
                              title: "",
                              summary: "",
                              url: image.large_url
                            })
                          );
                        }}
                        images={projectImages}
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header as="h4" style={{ marginBottom: 10 }}>
                        {useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.presentation"
                        })}
                      </Header>
                      <Field
                        id="css"
                        name="css"
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.css"
                        })}
                        component={SemanticFormikJsonEditor}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <ActionButtons onClose={handleClose} onSubmit={submitForm} />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};
ImageGalleryCard.propTypes = {
  button: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  }),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
  configuratorDesign: ConfiguratorDesignShape.isRequired
};

ImageGalleryCard.defaultProps = {
  initialValue: {
    params: {
      title: "",
      images: []
    }
  }
};

export default ImageGalleryCard;
