export const formatCurrency2 = (
  amount,
  minimumFractionDigits = 2,
  currency = "EUR"
) => {
  let formatter;
  if (!currency) {
    formatter = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits
    });
  } else {
    formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency,
      minimumFractionDigits
    });
  }
  return formatter.format(Number(amount));
};

export const formatCurrency = (amount, currency = "EUR") => {
  return formatCurrency2(amount, 2, currency);
};

export const parseNumber = string => {
  if (!string) return;
  string = string.toString();
  string = string.replace(" ", "");
  string = string.replace("€", "");
  string = string.replace("EUR", "");
  string = string.replace(/euro/i, "");
  let decimalSign = "none";
  if (string.substr(-3, 1) === "," || string.substr(-2, 1) === ",") {
    decimalSign = "comma";
  }
  if (string.substr(-3, 1) === "." || string.substr(-2, 1) === ".") {
    decimalSign = "dot";
  }
  if (string.match(/,/g)?.length > 1) {
    decimalSign = "dot";
  }
  if (string.match(/\./g)?.length > 1) {
    decimalSign = "comma";
  }
  // invalid number of commas and dots
  if (string.match(/,/g)?.length > 1 && string.match(/\./g)?.length > 1) {
    string = "0";
    decimalSign = "none";
  }

  switch (decimalSign) {
    case "comma":
      string = `${string.split(",")[0].replace(/\./g, "")}.${string.split(
        ","
      )[1] || 0}`;
      break;
    case "dot":
      string = `${string.split(".")[0].replace(/,/g, "")}.${string.split(
        "."
      )[1] || 0}`;
      break;
    case "none":
      string = string.replace(/[,.]/g, "");
      break;
  }
  string = Math.round(Number(string) * 100) / 100;
  return string;
};
