import { createSelector } from "reselect";

export const getIsLoading = state => state.isLoading;

export const createIsLoadingSelector = regExp =>
  createSelector(getIsLoading, isLoading =>
    Object.keys(isLoading)
      .filter(key => regExp.test(key))
      .some(key => isLoading[key])
  );
