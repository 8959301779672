import { bool, number, shape, string } from "prop-types";

export const SectionShape = shape({
  id: number,
  parentId: number,
  title: string,
  image: string,
  imageClass: string,
  total: number,
  isVisible: bool,
  hasChildren: bool,
  isRoot: bool,
  isDecided: bool,
  isValid: bool,
  isConfigurable: bool,
  isPriceOnRequest: bool,
  configuratorId: string,
  selector: string,
  position: number
});

export default SectionShape;
