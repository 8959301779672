import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Header, Icon, Image, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { number } from "prop-types";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import UpgradeBundlesResource from "builder_portal/actions/upgradeBundlesActions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import UpgradeBundleDialog from "./UpgradeBundleDialog";

import { UpgradeBundlesLoaderContext } from "./UpgradeBundlesLoader";

const UpgradeBundlesList = ({ groupId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { upgradeBundles, projectId, isLoading, loadData } = useContext(
    UpgradeBundlesLoaderContext
  );

  const upgradeBundlesForGroup = useMemo(
    () => upgradeBundles.filter(ub => ub.upgrade_bundle_group_id === groupId),
    [upgradeBundles]
  );

  const deleteUpgradeBundle = id => {
    return new UpgradeBundlesResource(dispatch, projectId)
      .remove(id)
      .then(() => {
        loadData();
      });
  };

  const renderDeleteButton = model => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="upgrade_bundle-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          deleteUpgradeBundle(model.id).catch(() => {
            handleClose();
          });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.upgradeBundles.removeConfirmation.title"
        message="roomBook.upgradeBundles.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const renderUpgradeBundle = item => {
    return (
      <Table.Row key={item.id} id={`upgrade_bundles-${item.id}`}>
        <Table.Cell width={3}>
          {item.image ? (
            <Image src={item.image} alt={item.name} size="small" />
          ) : (
            <FormattedMessage
              id="roomBook.upgradeBundles.noImage"
              defaultMessage="Kein Bild"
            />
          )}
        </Table.Cell>
        <Table.Cell width={5} textAlign="left">
          <Header>{item.name}</Header>
        </Table.Cell>
        <Table.Cell width={5} textAlign="left">
          {item.description}
        </Table.Cell>
        <Table.Cell width={1} textAlign="right">
          <VersionHistoryLink
            id={item.id}
            type="UpgradeBundle"
            style={{
              fontSize: "1em"
            }}
          />
        </Table.Cell>
        <Table.Cell textAlign="right" width={1}>
          <UpgradeBundleDialog
            model={item}
            button={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="button"
                id="upgrade_bundle-edit"
                loading={isLoading}
                disabled={isLoading}
                data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
              >
                <Icon name="setting" color="grey" />
              </a>
            }
          />
        </Table.Cell>
        <Table.Cell textAlign="right" width={1}>
          {renderDeleteButton(item)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderUpgradeBundles = () => {
    if (!upgradeBundlesForGroup.length && !isLoading) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.upgradeBundles.notFoundInGroup"
            defaultMessage="Es liegen keine Upgrade Pakete zu dieser Gruppe vor"
          />
        </Header>
      );
    }

    return upgradeBundlesForGroup.map(renderUpgradeBundle);
  };

  return (
    <Table data-component="upgrade_bundle_items" style={{ marginTop: ".5em" }}>
      <Table.Body>{renderUpgradeBundles()}</Table.Body>
    </Table>
  );
};

UpgradeBundlesList.propTypes = {
  groupId: number.isRequired
};

export default UpgradeBundlesList;
