import React from "react";
import { Button, Icon, List, Popup } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";
import { string, shape, arrayOf, number } from "prop-types";
import "./marketingPortalLink.scss";

function BuyerPortalLink({ buyers, buttonProps, linkProps }) {
  const hasBuyers = buyers.length > 0;
  const buyerLabels = hasBuyers ? (
    buyers.map(buyer => buyer.label).join(", ")
  ) : (
    <FormattedMessage id="buyer.title.zero" defaultMessage="buyer.title.zero" />
  );
  const popupContent = hasBuyers ? (
    <List
      items={buyers.map(buyer => (
        <List.Item key={buyer.id}>
          <List.Icon name="child" />
          <List.Content>{buyer.label}</List.Content>
        </List.Item>
      ))}
    />
  ) : (
    <FormattedMessage id="buyer.title.zero" defaultMessage="buyer.title.zero" />
  );

  const mergedProps = {
    target: "_bp",
    ...linkProps,
    as: Link,
    primary: hasBuyers,
    basic: true,
    fluid: true,
    ...buttonProps
  };

  return (
    <Popup
      trigger={
        // PropSpreading is totally fine for HOCs with inheritance
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button className="buyerPortalLink truncate" {...mergedProps}>
          {hasBuyers && <Icon name="child" />}
          <span data-attr="buyer">{buyerLabels}</span>
        </Button>
      }
      content={popupContent}
    />
  );
}

BuyerPortalLink.defaultProps = {
  buttonProps: undefined,
  buyers: []
};

BuyerPortalLink.propTypes = {
  buyers: arrayOf(shape({ id: number, label: string, slug: string })),
  buttonProps: shape(Button.propTypes),
  linkProps: shape(Link.propTypes).isRequired
};

export default BuyerPortalLink;
