import React, { useMemo, useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router";
import {
  Table,
  Header,
  Message,
  Loader,
  Icon,
  Modal,
  Button
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { isNumber, isObject } from "lodash";
import { If } from "shared/components/elements/Conditions";
import copyToClipboard from "copy-to-clipboard";
import {
  getVersions,
  getEntityReference,
  getParentReference
} from "builder_portal/selectors/versions";
import ReactDiffViewer from "react-diff-viewer";
import { VersionsResource } from "builder_portal/actions/versions";
import PropTypes, { object, string } from "prop-types";
import { formatDateTime } from "../../../shared/helpers/formatDate";
import FormatCurrency from "../../../shared/components/currency/FormatCurrency";

const LONG_VALUE_LENGTH = 50;

const Versions = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const versions = useSelector(getVersions);
  const entity = useSelector(getEntityReference);
  const parent = useSelector(getParentReference);

  const [error, setError] = useState("");
  const [defaultCollapsed, setDefaultCollapsed] = useState(false);
  const container_type = new URLSearchParams(window.location.search).get(
    "type"
  );

  const container_id = new URLSearchParams(window.location.search).get("id");

  useEffect(() => {
    setLoading(true);
    if (container_type && container_id) {
      const resource = new VersionsResource(dispatch);
      Promise.all([resource.fetchAll({ container_type, container_id })])
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      setError("no_params");
    }
  }, [container_type, container_id]);

  const renderBreadcrumbs = () => {
    const elements = [];
    if (parent)
      elements.push(
        <Link key="parent_link" to={parent.url}>
          {parent.label}
        </Link>
      );
    if (parent)
      elements.push(
        <span key="slash" style={{ padding: "0 3px" }}>
          /
        </span>
      );
    elements.push(
      <Link key="entity_link" to={entity.url}>
        {entity.label}
      </Link>
    );
    return <div>{elements}</div>;
  };

  return (
    <>
      {renderBreadcrumbs()}
      <Header as="h3" style={{ marginTop: "5px" }}>
        <FormattedMessage id="versions.title" />{" "}
        <Link to={entity.url}>{entity.label}</Link>
        <div style={{ float: "right" }}>
          <Icon
            name={defaultCollapsed ? "angle double up" : "angle double right"}
            onClick={() => setDefaultCollapsed(prevState => !prevState)}
          />
        </div>
      </Header>
      <Loader active={isLoading} />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="versions.headers.datum" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="versions.headers.user" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="versions.headers.child" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="versions.headers.action" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="versions.headers.details" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {versions.map(version => (
            <RenderRow
              key={version.id}
              version={version}
              defaultCollapsed={defaultCollapsed}
            />
          ))}
        </Table.Body>
      </Table>
      <If condition={!!error}>
        <Message color="red">
          <p>
            <FormattedMessage id="versions.paramError" />
          </p>
        </Message>
      </If>
    </>
  );
};

const VersionValue = ({ field, value }) => {
  if (isObject(value) && value.ref) {
    return (
      <Link to={value.url} role="link">
        {value.label}
      </Link>
    );
  }
  if (isNumber(value) && field.endsWith("_in_cents")) {
    return <FormatCurrency amount={value / 100} />;
  }
  return <span>{value?.toString()}</span>;
};

VersionValue.propTypes = {
  field: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired
};

const VersionField = ({ field }) => {
  const label = useMemo(() => {
    return ["_id", "_in_cents"].reduce((s, postfix) => {
      return s.replace(postfix, "");
    }, field);
  }, [field]);
  return <span>{label}</span>;
};

VersionField.propTypes = {
  field: PropTypes.string.isRequired
};

const RenderRow = ({ version, defaultCollapsed }) => {
  const [collapsed, setcollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    setcollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  return (
    <Fragment key={version.id}>
      <Table.Row>
        <Table.Cell width={3}>{formatDateTime(version.created_at)}</Table.Cell>
        <Table.Cell width={2}>{version.user.name}</Table.Cell>
        <Table.Cell width={3}>
          <If condition={version.relation !== "self"}>
            <If condition={!!version.item.url}>
              <Link to={version.item.url}>{version.item.label}</Link>
            </If>
            <If condition={!version.item.url}>{version.item.label}</If>
            <Link
              to={`/versions?type=${version.item.type}&id=${version.item.id}`}
            >
              <Icon name="history" style={{ marginLeft: "7px" }} />
            </Link>
          </If>
        </Table.Cell>
        <Table.Cell width={2}>{version.event}</Table.Cell>
        <Table.Cell width={2}>
          <div
            role="button"
            tabIndex={0}
            style={{ cursor: "pointer" }}
            onClick={() => setcollapsed(prevState => !prevState)}
            onKeyPress={() => setcollapsed(prevState => !prevState)}
          >
            <If condition={collapsed === true}>
              <Icon name="angle double up" />
            </If>
            <If condition={collapsed === false}>
              <Icon name="angle double right" />
            </If>
          </div>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <If condition={collapsed}>
          <Table.Cell
            width={12}
            colSpan={12}
            style={{ backgroundColor: "#EEE" }}
          >
            <ChangesTable data={version.object_changes} />
          </Table.Cell>
        </If>
      </Table.Row>
    </Fragment>
  );
};

RenderRow.propTypes = {
  version: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    relation: PropTypes.string.isRequired,
    item: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
      type: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    }).isRequired,
    event: PropTypes.string.isRequired,
    object_changes: PropTypes.shape({}).isRequired
  }).isRequired,
  defaultCollapsed: PropTypes.bool.isRequired
};

const isJSON = val => {
  if (typeof val !== "string") return false;
  // keep the view simple if json is empty object
  if (val === "{}") return false;
  if (val === "[]") return false;

  if (val[0] !== "{" && val[0] !== "[") return false;
  if (val[val.length - 1] !== "}" && val[val.length - 1] !== "]") return false;
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

const checkIsJson = (data, field) => {
  return isJSON(data[field][0]) || isJSON(data[field][1]);
};

const isLargerTextOrHtml = (data, field) => {
  return (
    data[field][0]?.length > LONG_VALUE_LENGTH ||
    data[field][1]?.length > LONG_VALUE_LENGTH
  );
};

const renderValueCells = (data, field) => {
  const isJson = checkIsJson(data, field);

  if (isLargerTextOrHtml(data, field))
    return (
      <Table.Cell colSpan={2} textAlign="center">
        <DiffModal data={data} field={field} type="text" />
      </Table.Cell>
    );

  if (isJson)
    return (
      <Table.Cell colSpan={2} textAlign="center">
        <DiffModal data={data} field={field} type="json" />
      </Table.Cell>
    );

  return (
    <>
      <Table.Cell>
        <VersionValue field={field} value={data[field][0]} />
      </Table.Cell>
      <Table.Cell>
        <VersionValue field={field} value={data[field][1]} />
      </Table.Cell>
    </>
  );
};

const ChangesTable = ({ data }) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}>
            <FormattedMessage id="versions.changes.headers.field" />
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>
            <FormattedMessage id="versions.changes.headers.oldValue" />
          </Table.HeaderCell>
          <Table.HeaderCell width={4}>
            <FormattedMessage id="versions.changes.headers.newValue" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(data).map(field => (
          <Table.Row key={field}>
            <Table.Cell>
              <VersionField field={field} />
            </Table.Cell>
            {renderValueCells(data, field)}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

ChangesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired
};

const DiffModal = ({ data, field, type }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const oldValue = useMemo(() => {
    if (type === "json") {
      return JSON.stringify(JSON.parse(data[field][0]), null, 2);
    }
    return data[field][0] || "";
  }, [data, field]);

  const newValue = useMemo(() => {
    if (type === "json") {
      return JSON.stringify(JSON.parse(data[field][1]), null, 2);
    }
    return data[field][1] || "";
  }, [data, field]);

  return (
    <Modal
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={
        <Button fluid size="small">
          <FormattedMessage
            id="versions.compare.title"
            default="JSON compare"
          />
        </Button>
      }
      closeIcon
      size="fullscreen"
    >
      <Modal.Header>
        <FormattedMessage id="versions.compare.title" default="JSON compare" />
      </Modal.Header>
      <Modal.Content scrolling>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px"
          }}
        >
          <Button
            basic
            icon="clipboard outline"
            onClick={() => {
              copyToClipboard(oldValue);
            }}
          />
          <Button
            basic
            icon="clipboard outline"
            onClick={() => {
              copyToClipboard(newValue);
            }}
          />
        </div>
        <ReactDiffViewer oldValue={oldValue} newValue={newValue} splitView />
      </Modal.Content>
      <Modal.Actions className="right">
        <Button basic id="cancel" onClick={handleClose}>
          <FormattedMessage id="meta.actions.cancel" default="Cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DiffModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object.isRequired,
  field: string.isRequired,
  type: string
};

DiffModal.defaultProps = {
  type: ""
};

export default Versions;
