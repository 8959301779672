import { arrayOf, number, shape, string } from "prop-types";
import CostAttributeShape from "shared/shapes/costAttribute.shape";

const ProductPriceShape = shape({
  id: number.isRequired,
  product_option_id: number.isRequired,
  price_catalog_id: number.isRequired,
  price: number.isRequired,
  excess_price: number,
  costs_attributes: arrayOf(CostAttributeShape).isRequired,
  deprecated_at: string,
  deprecated_by_id: number,
  created_at: string.isRequired,
  updated_at: string
});
export default ProductPriceShape;
