/* eslint-disable max-classes-per-file */
import apiRequest from "shared/network/apiRequest";
import Resources from "../../shared/actions/resourcesActions";

export class DocumentAssignmentsResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "document_export_assignment",
      "/api/v1/document_export_assignments"
    );
    this.refreshPageContentAfterCommit = false;
  }

  duplicate(values) {
    const payload = { ...values };
    return apiRequest
      .post(`${this.url}/duplicate`, this.dispatch, payload)
      .then(this.propagateUpdate.bind(this));
  }

  documentExportPreview(group, modelId, id) {
    const searchTerm =
      group === "room_book_carts" ? "activity_id" : "room_book_id";
    return apiRequest
      .get(
        `${this.url}/${modelId}/document_export_preview${
          id ? `?${searchTerm}=${id}` : ""
        }`,
        this.dispatch
      )
      .then(this.propagateUpdate.bind(this));
  }
}

export class DocumentExportsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "document_export", "/api/v1/document_exports");
    this.refreshPageContentAfterCommit = false;
  }

  metadata() {
    return apiRequest
      .get(`/api/v1/document_export_metadata`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class DocumentStylesResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "document_export_style",
      "/api/v1/document_export_styles",
      "document_export_styles"
    );
    this.refreshPageContentAfterCommit = false;
  }
}

export class DocumentSignaturesResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "document_export_signature",
      "/api/v1/document_export_signatures"
    );
    this.refreshPageContentAfterCommit = false;
  }
}
