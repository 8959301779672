import { createSelector } from "reselect";
import { get, keyBy, sortBy, uniq, min } from "lodash";
import { getI18N } from "../base";
import { getProjectBranding } from "../project";
import { getBuyerSelections } from "./base";
import { isProductAcceptable as productRuleMatcher } from "./productRules";
import {
  isProductAcceptable as productLineMatcher,
  isProductRecommendable,
  getProductLinesForTags,
  getActiveProductTag,
  getProductLine
} from "./productLines";
import { getProductsById, getProductGroupsById } from "./productData";
import { PriceCalculator } from "./priceCalculator";

export const getProductOptions = createSelector(
  [
    getProductsById,
    getProductGroupsById,
    getProjectBranding,
    getBuyerSelections,
    productRuleMatcher,
    productLineMatcher,
    isProductRecommendable,
    getProductLinesForTags,
    getI18N,
    getActiveProductTag,
    getProductLine
  ],
  (
    products,
    productGroups,
    branding,
    buyerSelections,
    productRuleMatcher,
    productLineMatcher,
    isProductRecommendable,
    getProductLinesForTags,
    i18n,
    activeProductTag,
    productLine
  ) => {
    return subLineItem => {
      const buyerSelection = buyerSelections[subLineItem.id];
      const buyerSelectedProductId = get(buyerSelection, "product_id", null);
      const productGroup = productGroups[subLineItem.product_group_id];
      const prices = get(productGroup, "prices", []);
      const pricesByProductId = keyBy(prices, "product_id");
      const defaultProductPrice =
        pricesByProductId[subLineItem.default_product_id];
      const isQuantityOnRequest = get(
        productGroup,
        "quantity_on_request",
        false
      );

      const options = prices.map(price => {
        const product = products[price.product_id];
        const isBuyerSelection = product.id === buyerSelectedProductId;

        const isSelected = product.id === subLineItem.product_id;
        const isSelectable = subLineItem.status.selectable;
        const isActualSelection =
          isSelectable && buyerSelection ? isBuyerSelection : isSelected;
        const isPortalOffer = get(price, "portal_offer", false);

        const total = PriceCalculator.calculatePrice(
          subLineItem,
          price,
          defaultProductPrice
        );

        const hasCustomPrice =
          isSelected && PriceCalculator.hasCustomPrice(subLineItem, price);
        const isOnRequest = !hasCustomPrice && get(price, "on_request", false);
        const productTags = uniq(
          price.product_tags.concat(product.product_tags)
        );
        const productWithTags = { ...product, product_tags: productTags };
        const isAcceptableProduct =
          !subLineItem.status.selectable ||
          productRuleMatcher(subLineItem, productWithTags);
        const isValidOption =
          isAcceptableProduct &&
          productLineMatcher(subLineItem, productWithTags);
        const isValidSelection = !isBuyerSelection || isValidOption;
        const isRecommendable =
          isAcceptableProduct &&
          isProductRecommendable(subLineItem, productWithTags);
        const productLines = getProductLinesForTags(
          subLineItem.product_group_id,
          productTags
        );
        // this variable hides the option, when there is an selected ProductTag that's 'exclusive'
        // keep in mind, the builderPortals choice should always dominate.
        const filteredByExclusiveRule =
          !productLine?.exclusive || isValidOption;

        const isVisible =
          filteredByExclusiveRule &&
          (isBuyerSelection || (!isSelectable && isSelected) || isPortalOffer);

        return {
          id: product.id,
          subLineItemId: subLineItem.id,
          name: product.name,
          display_name: product.display_name,
          supplier: product.supplier,
          series: product.series,
          description: product.description,
          technical_description: product.technical_description,
          sku: product.sku,
          priceStrategy: productGroup.price_strategy,
          productTags,
          productLines,
          total,
          defaultTotal: PriceCalculator.calculateDefaultPrice(
            subLineItem,
            defaultProductPrice
          ),
          price: get(price, "price") || subLineItem.price,
          position: get(price, "position", 0),
          excessPrice:
            get(price, "excess_price") ||
            get(price, "price") ||
            subLineItem.excess_price ||
            subLineItem.price,
          relativePrice: PriceCalculator.calculateRelativePrice(
            price,
            defaultProductPrice
          ),
          isDefault:
            product.id === subLineItem.default_product_id &&
            subLineItem.default_quantity !== 0,
          isSelected,
          isBuyerSelection,
          isActualSelection,
          isVisible,
          isValidOption,
          isValidSelection,
          isAcceptableProduct,
          isRecommendable,
          isOnRequest,
          isQuantityOnRequest,
          images: product.images,
          thumbUrl: product.thumb_url
        };
      });

      if (!subLineItem.product_id) {
        const thumbUrl = get(branding, ["data", "default_thumb_url"]);
        const price = {
          price: subLineItem.price,
          excess_price: subLineItem.excess_price
        };
        const isDefault = !subLineItem.default_product_id;
        const isSelected = !subLineItem.product_id;
        const isBuyerSelection = buyerSelection && !buyerSelection.product_id;
        const isActualSelection =
          status.selectable && buyerSelection ? isBuyerSelection : isSelected;
        const isVisible = true;

        options.push({
          id: null,
          description: subLineItem.description,
          supplier: i18n["roomBook.lineItems.individualProduct"],
          series: null,
          name: subLineItem.description,
          total: PriceCalculator.calculatePrice(
            subLineItem,
            price,
            defaultProductPrice
          ),
          defaultTotal: PriceCalculator.calculateDefaultPrice(
            subLineItem,
            defaultProductPrice
          ),
          relativePrice: PriceCalculator.calculateRelativePrice(
            subLineItem,
            defaultProductPrice
          ),
          priceStrategy: subLineItem.price_strategy,
          thumbUrl,
          individual: true,
          images: [
            {
              url: thumbUrl
            }
          ],
          price: price.price,
          excessPrice: price.excess_price,
          isDefault,
          isSelected,
          isVisible,
          isValidSelection: true,
          isValidOption: true,
          isRecommendable: true,
          isAcceptableProduct: true,
          isOnRequest: false,
          isQuantityOnRequest,
          isBuyerSelection,
          isActualSelection,
          productTags: [],
          productLines: []
        });
      }

      const defaultMinPrice = min(options.map(o => o.total)) || 0;

      options.forEach(o => {
        if (subLineItem.optional) {
          o.relativeTotal = o.total - defaultMinPrice;
        } else {
          o.relativeTotal = o.total;
        }
      });

      return sortBy(options, (option, idx) => {
        if (option.isActualSelection) {
          return -4000;
        }
        if (option.isBuyerSelection) {
          return -3000;
        }
        if (option.isSelected) {
          return -2000;
        }
        if (option.isDefault) {
          return -1000;
        }
        return idx;
      });
    };
  }
);

export const getProductGroup = (state, subLineItem) => {
  return getProductGroupsById(state)[subLineItem.product_group_id];
};
