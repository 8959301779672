import {
  LineItemOptionalityResource,
  LineItemResource,
  ProjectLineItemsResource,
  ProjectRoomBooksResource,
  ProjectSectionsResource,
  RoomBookSectionResource,
  SubLineItemResource
} from "builder_portal/actions/roomBookActions";
import { ProjectsCatalogResource } from "builder_portal/actions/projectCatalogActions";
import { AttachmentResource } from "builder_portal/actions/attachmentsActions";
import toggleDialogCreator from "builder_portal/helpers/toggleDialogCreator";
import { ProjectSubLineItemResource } from "builder_portal/actions/subLineItemActions";

export default class RoomBookController {
  constructor(projectId, id, mode, dispatch) {
    this.projectId = projectId;
    this.id = id;
    this.mode = mode;
    this.dispatch = dispatch;
  }

  toggleDialog(id, payload) {
    toggleDialogCreator(this.dispatch, this.id, "roomBook")(id, payload);
  }

  /*
   * SECTION ACTIONS
   */

  createSection(values, parentId) {
    const parentUrl = parentId ? `/${parentId}/sections` : "";
    return this.sectionResource()
      .save(values, parentUrl)
      .then(this.onSuccess);
  }

  updateSection(values) {
    return this.sectionResource()
      .save(values)
      .then(this.onSuccess);
  }

  deleteSection(id) {
    return this.sectionResource()
      .remove(id)
      .then(this.onSuccess);
  }

  deleteSections(ids) {
    return this.projectSectionsResource()
      .delete(ids)
      .then(this.onSuccess);
  }

  moveSection = (element, position) => {
    const payload = {
      id: element.id,
      position
    };
    return this.sectionResource()
      .save(payload)
      .then(this.onSuccess);
  };

  updateSectionParent(element, parent) {
    const payload = {
      id: element.id,
      position: parent.sections.length + 1,
      parent_id: parent.id
    };
    return this.sectionResource()
      .save(payload)
      .then(this.onSuccess);
  }

  duplicateSection = element => {
    return this.sectionResource()
      .duplicate(element.id)
      .then(this.onSuccess);
  };

  /*
   * LINE ITEM ACTIONS
   */

  createLineItem(section_id, values) {
    return this.lineItemResource()
      .add({ ...values, section_id })
      .then(this.onSuccess);
  }

  updateLineItem(element) {
    return this.lineItemResource()
      .save(element)
      .then(this.onSuccess);
  }

  cancelLineItem(element, options) {
    return this.lineItemResource()
      .cancel(element.id, options)
      .then(this.onSuccess);
  }

  updateLineItemParent(element, parent) {
    return this.lineItemResource()
      .updateParent(element, parent)
      .then(this.onSuccess);
  }

  deleteLineItem(id) {
    return this.lineItemResource()
      .remove(id)
      .then(this.onSuccess);
  }

  deleteLineItems(ids) {
    return this.projectLineItemResource()
      .delete(ids)
      .then(this.onSuccess);
  }

  moveLineItem = (element, position) => {
    return this.lineItemResource()
      .move(element, position)
      .then(this.onSuccess);
  };

  createLineItemOptionality(id, options) {
    return this.lineItemOptionalityResource(id)
      .save(options)
      .then(this.onSuccess);
  }

  deleteLineItemOptionality(id) {
    return this.lineItemOptionalityResource(id)
      .remove()
      .then(this.onSuccess);
  }

  /*
   * SUB LINE ITEM ACTIONS
   */

  createSubLineItem(line_item_id, values) {
    return this.subLineItemResource()
      .add({ ...values, line_item_id })
      .then(this.onSuccess);
  }

  updateSubLineItem(element) {
    return this.subLineItemResource()
      .save(element)
      .then(this.onSuccess);
  }

  updateSubLineItems(elements) {
    return this.subLineItemResource()
      .saveAll(elements)
      .then(this.onSuccess);
  }

  deleteSubLineItem(id) {
    return this.subLineItemResource()
      .remove(id)
      .then(this.onSuccess);
  }

  moveSubLineItem = (element, position) => {
    return this.subLineItemResource()
      .move(element, position)
      .then(this.onSuccess);
  };

  discardBuyerSelection(element) {
    return this.subLineItemResource()
      .discardBuyerSelection(element.id)
      .then(this.onSuccess);
  }

  deleteSubLineItems(ids) {
    return this.projectSubLineItemResource()
      .delete(ids)
      .then(this.onSuccess);
  }

  /*
   * RoomBook
   */

  load() {
    return this.roomBookResource().get(this.id, true);
  }

  generate(payload) {
    return this.roomBookResource()
      .generate(this.id, payload)
      .then(() => this.refresh())
      .then(this.onSuccess);
  }

  loadProjectCatalog() {
    return new ProjectsCatalogResource(this.dispatch, this.projectId).get();
  }

  refresh() {
    return this.roomBookResource().get(this.id);
  }

  updateRoomBook(values) {
    return this.roomBookResource().save(values);
  }

  reloadDescendants() {
    return this.roomBookResource().descendantsStatus(this.id);
  }

  deleteRoomBook() {
    return this.roomBookResource().remove(this.id);
  }

  purgeRoomBook() {
    return this.roomBookResource().purge(this.id);
  }

  reset() {
    return this.roomBookResource().reset(this.id);
  }

  deleteContent(items) {
    return this.roomBookResource()
      .deleteContent(this.id, items)
      .then(this.onSuccess);
  }

  onSuccess = response => {
    if (this.mode === "template") {
      this.reloadDescendants();
    }
    return response;
  };

  /*
   * Resources
   */

  sectionResource() {
    return new RoomBookSectionResource(this.dispatch, this.id);
  }

  lineItemResource() {
    return new LineItemResource(this.dispatch, this.id);
  }

  lineItemOptionalityResource(lineItemId) {
    return new LineItemOptionalityResource(this.dispatch, this.id, lineItemId);
  }

  subLineItemResource() {
    return new SubLineItemResource(this.dispatch, this.projectId, this.id);
  }

  attachmentsResource() {
    return new AttachmentResource(this.dispatch, this.id);
  }

  roomBookResource() {
    return new ProjectRoomBooksResource(this.dispatch, this.projectId);
  }

  projectLineItemResource() {
    return new ProjectLineItemsResource(this.dispatch, this.projectId);
  }

  projectSubLineItemResource() {
    return new ProjectSubLineItemResource(this.dispatch, this.projectId);
  }

  projectSectionsResource() {
    return new ProjectSectionsResource(this.dispatch, this.projectId);
  }
}
