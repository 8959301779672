import Resources from "shared/actions/resourcesActions";

export default class UpgradeBundleGroupsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "upgrade_bundle_group",
      `/api/v1/projects/${projectId}/upgrade_bundle_groups`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
