import React, { PureComponent } from "react";
import { Confirm, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { ProductGroupRuleSetResource } from "builder_portal/actions/productGroupActions";
import { instanceOf, node, object } from "prop-types";
import { createStructuredSelector } from "reselect";

import "./productRulesDialog.scss";
import { getRelevantProductGroupRuleSetsById } from "builder_portal/selectors/productGroupRuleSets";
import RuleSetEditor from "builder_portal/components/productRules/RuleSetEditor";
import { loadingProps } from "shared/helpers/propHelper";
import { getI18N } from "shared/selectors";
import { withRouter } from "../../../shared/helpers/withRouter";

class RuleSetDialog extends PureComponent {
  static propTypes = {
    productGroupRuleSetResource: instanceOf(ProductGroupRuleSetResource)
      .isRequired,
    ruleSet: object,
    i18n: object.isRequired,
    trigger: node.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmOpen: false,
      loading: false,
      ruleSet: props.ruleSet,
      errors: {}
    };
  }

  render() {
    const { i18n, trigger } = this.props;
    const { open, loading } = this.state;
    return (
      <Modal
        data-component="ruleSetDialog"
        trigger={trigger}
        open={open}
        closeOnDimmerClick={false}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Header content="Regel" />
        <Modal.Content scrolling>
          {this.renderContent()}
          {this.renderConfirmDialog()}
        </Modal.Content>
        <Modal.Actions
          actions={[
            {
              content: i18n["meta.actions.cancel"],
              onClick: this.handleClose,
              key: "cancel",
              "data-action": "cancel",
              ...loadingProps(loading)
            },
            {
              content: i18n["meta.actions.save"],
              onClick: this.handleSave,
              key: "save",
              positive: true,
              "data-action": "save",
              ...loadingProps(loading)
            }
          ]}
        />
      </Modal>
    );
  }

  renderConfirmDialog() {
    const { i18n } = this.props;
    const { confirmOpen } = this.state;
    return (
      <Confirm
        header={i18n["meta.title.attention"]}
        content={i18n["meta.confirmations.changesNotSaved"]}
        confirmButton={i18n["meta.actions.discard"]}
        cancelButton={i18n["meta.actions.back"]}
        open={confirmOpen}
        onCancel={this.handleConfirmCancel}
        onConfirm={this.handleConfirmClose}
      />
    );
  }

  handleConfirmCancel = () => this.setState({ confirmOpen: false });

  handleConfirmClose = () => this.setState({ confirmOpen: false, open: false });

  renderContent() {
    const { ruleSet, errors } = this.state;
    return (
      <RuleSetEditor
        ruleSet={ruleSet}
        onChange={this.handleRuleSetChange}
        errors={errors}
      />
    );
  }

  handleOpen = () => {
    const { ruleSet } = this.props;
    this.setState({ ruleSet, open: true, errors: {} });
  };

  handleClose = () => {
    if (this.state.ruleSet === this.props.ruleSet) {
      this.setState({ open: false });
    } else {
      this.setState({ confirmOpen: true });
    }
  };

  handleSave = () => {
    const { productGroupRuleSetResource, i18n } = this.props;
    const { ruleSet } = this.state;
    this.setState({ loading: true, errors: {} });
    productGroupRuleSetResource
      .save(ruleSet)
      .then(() =>
        productGroupRuleSetResource
          .fetchAll()
          .then(() => this.setState({ loading: false, open: false }))
          .catch(() => this.setState({ loading: false }))
      )
      .catch(response =>
        this.setState({
          errors: response.data || { base: [i18n["message.errorSave.body"]] },
          loading: false
        })
      );
  };

  handleRuleSetChange = (_, { ruleSet }) => this.setState({ ruleSet });
}

const mapStateToProps = createStructuredSelector({
  ruleSets: getRelevantProductGroupRuleSetsById,
  i18n: getI18N
});

const mapDispatchToProps = (dispatch, props) => ({
  productGroupRuleSetResource: new ProductGroupRuleSetResource(
    props.params.catalogId,
    dispatch
  )
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RuleSetDialog)
);
