import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Header, Select } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { MessageThreadShape } from "shared/shapes/messageThread.shape";
import { getUnitsForProject } from "builder_portal/selectors/masterdata";
import MessagesResource from "../../actions/messagesActions";

function MessageUnitPicker(props) {
  const { projectUnits, messagesResource } = props;
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Form>
      <Form.Field
        loading={isLoading}
        control={Select}
        fluid
        search
        selectOnBlur={false}
        selectOnNavigation={false}
        options={projectUnits.map(unit => {
          const buyerLabel = props.getBuyersLabel(unit.id);
          return {
            key: unit.id,
            value: unit.id,
            text: `${unit.name},${buyerLabel}`,
            content: (
              <Header as="h5" content={unit.name} subheader={buyerLabel} />
            )
          };
        })}
        onChange={(e, { value }) => {
          const { messageThread } = props;
          setIsLoading(true);
          messagesResource
            .save({ ...messageThread, unit_id: value })
            .then(() => setIsLoading(false));
        }}
        placeholder={formatMessage({
          id: "messaging.message_container.empty_references.no_unit",
          defaultMessage: "!"
        })}
      />
    </Form>
  );
}

MessageUnitPicker.propTypes = {
  // is used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  projectId: PropTypes.number.isRequired,
  messageThread: MessageThreadShape.isRequired,
  messagesResource: PropTypes.instanceOf(MessagesResource).isRequired,
  // is used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  getBuyersLabel: PropTypes.func.isRequired,
  projectUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

const mapStateToProps = (state, props) => {
  return {
    projectUnits: getUnitsForProject(state)(props.projectId)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    messagesResource: new MessagesResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageUnitPicker);
