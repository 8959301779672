const get3dOptions = json => {
  if (!json) return [];
  return Object.keys(json).map(room => {
    return {
      key: room,
      value: room,
      text: room
    };
  });
};

export default get3dOptions;
