import Resource from "../../shared/actions/resourceActions";
import apiRequest from "../../shared/network/apiRequest";

export default class UnitRoomBookResource extends Resource {
  constructor(dispatch, unitId) {
    super(dispatch, "unit_room_book", `/api/v1/units/${unitId}/room_book`);
  }

  getExports() {
    return apiRequest
      .get(`${this.url}/exports`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}
