import { get } from "lodash";
import { createSelector } from "reselect";
import { getState } from "../../shared/selectors";

const getTradeOptions = createSelector([getState], state => {
  return get(state, "account.account.config.contractors.trades", []).map(
    ({ id, label }) => {
      return {
        key: id,
        value: id,
        text: label
      };
    }
  );
});

export default getTradeOptions;
