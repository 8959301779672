import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form, Input, TextArea, Checkbox } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { node, func, oneOfType, number, string } from "prop-types";
import { cloneDeep } from "lodash";
import { Modal, Button, Grid } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { UnitVariableTypeShape } from "shared/shapes/unitVariables.shape";
import Growl from "builder_portal/actions/growlActions";
import { UnitVariableTypesResource } from "../../../actions/unitVariablesActions";

const UnitVariableTypeDialog = ({ button, model, onCreate, projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const validationScheme = Yup.object({
    identifier: Yup.string()
      .matches(
        /^[a-z0-9.]+$/gm,
        intl.formatMessage({ id: "message.errorForm.identifierFormat" })
      )
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const onSubmit = useCallback((values, formik) => {
    new UnitVariableTypesResource(dispatch, projectId)
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        new Growl(dispatch).success(
          "macro.message.success.title",
          "macro.message.success.body"
        );

        new UnitVariableTypesResource(dispatch, projectId)
          .fetchAll()
          .then(res => {
            if (onCreate) {
              const id = res.data?.unit_variable_types?.find(
                x => x.identifier === values.identifier
              )?.id;
              if (id) onCreate(id);
            }
          });

        setOpen(false);
      })
      .catch(() => {
        formik.setSubmitting(false);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      open={isOpen}
      trigger={button}
      onOpen={handleOpen}
      onClose={handleClose}
      data-component="UnitVariableTypeDialog"
    >
      <Modal.Header>
        <FormattedMessage id="roomBook.unitVariables.types.dialog.title" />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form id="unitVariablTypeForm">
                <Input
                  id="identifier"
                  errorPrompt
                  name="identifier"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.types.dialog.identifier"
                  })}
                />

                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.types.dialog.name"
                  })}
                />

                <TextArea
                  id="description"
                  errorPrompt
                  name="description"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.types.dialog.description"
                  })}
                />
                <Input
                  id="default_value"
                  errorPrompt
                  name="default_value"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.dialog.default"
                  })}
                  type="number"
                  nullable
                />
                <Checkbox
                  id="global"
                  errorPrompt
                  name="global"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.dialog.global"
                  })}
                />

                <Modal.Actions>
                  <Grid>
                    <Grid.Column width={16} textAlign="right">
                      <Button
                        basic
                        id="cancel"
                        content={intl.formatMessage({
                          id: "meta.actions.cancel"
                        })}
                        onClick={handleClose}
                        loading={isSubmitting}
                      />
                      <Button
                        primary
                        id="submit"
                        type="submit"
                        content={
                          model.id
                            ? intl.formatMessage({ id: "meta.actions.save" })
                            : intl.formatMessage({ id: "meta.actions.add" })
                        }
                        loading={isSubmitting}
                      />
                    </Grid.Column>
                  </Grid>
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UnitVariableTypeDialog.propTypes = {
  button: node.isRequired,
  model: UnitVariableTypeShape.isRequired,
  projectId: oneOfType([number, string]).isRequired,
  onCreate: func
};

UnitVariableTypeDialog.defaultProps = {
  onCreate: null
};

export default UnitVariableTypeDialog;
