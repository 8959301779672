import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Modal,
  Button,
  Icon,
  Header,
  Segment,
  Menu,
  Label
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useIntl, FormattedMessage } from "react-intl";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import SemanticFormikColorInputWithHexField from "shared/components/forms/SemanticFormikColorInputWithHexField";
import ConfiguratorDesignsResource from "builder_portal/actions/configuratorDesignActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import { Form, Input, Select, TextArea } from "formik-semantic-ui-react";
import { getConfiguratorDesigns } from "builder_portal/selectors/configuratorDesigns";
import OnlineConfigurationShape from "shared/shapes/onlineKonfiguration.shape";
import { number, node } from "prop-types";
import { get, sortBy, isEmpty } from "lodash";
import { isVideoUrlValid } from "shared/helpers/matchers";
import SemanticFormikImageSelector from "shared/components/forms/SemanticFormikImageSelector";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import Faqs from "./Faqs";
import colorList from "./ConfiguratorDesignColorList";
import ContentPageEditor from "./ContentPageEditor";
import Translations from "./Translations";
import WebsiteBuilder from "./WebsiteBuilder";
import ProjectImagesLoader from "../../../../shared/components/forms/ProjectImagesLoader";

const tabs = {
  Settings: "settings",
  Images: "images",
  Logos: "logos",
  Style: "style",
  Dashboards: "dashboards",
  Translations: "translations",
  Faqs: "faqs",
  Pages: "pages"
};

const LANGUAGES = [
  {
    key: "de",
    value: "de",
    text: "Deutsch"
  },
  {
    key: "en",
    value: "en",
    text: "Englisch"
  },
  {
    key: "zh",
    value: "zh",
    text: "Chinesisch"
  },
  {
    key: "fr",
    value: "fr",
    text: "Französisch"
  },
  {
    key: "es",
    value: "es",
    text: "Spanisch"
  },
  {
    key: "nl",
    value: "nl",
    text: "Holländisch"
  },
  {
    key: "uk",
    value: "uk",
    text: "Ukrainisch"
  },
  {
    key: "ru",
    value: "ru",
    text: "Russisch"
  }
];

const useDashboardMenuOptions = () => {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        id: "left",
        value: "left",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardMenu.values.left"
        })
      },
      {
        id: "none",
        value: "none",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardMenu.values.none"
        })
      }
    ],
    []
  );
};

const useDashboardVersions = () => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        key: 1,
        value: "v1",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardVersion.values.v1"
        })
      },
      {
        key: 2,
        value: "v2",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardVersion.values.v2"
        })
      },
      {
        key: 3,
        value: "v3",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardVersion.values.v3"
        })
      },
      {
        key: 4,
        value: "custom",
        text: intl.formatMessage({
          id: "configuratorDesign.dialog.dashboardVersion.values.custom"
        })
      }
    ],
    []
  );
};

/* OK Configuration Dialog Component */

const ConfiguratorDesignDialog = ({ model, projectId, button }) => {
  const logos = [
    "project_logo_id",
    "project_logo_secondary_id",
    "project_logo_wide_id",
    "company_logo_id",
    "favicon_id",
    "project_logo_menu_id",
    "project_logo_loader_id",
    "flat_finder_logo_id",
    "flat_finder_logo_drawer_id"
  ];
  const desktopImages = [
    "banner_id",
    "backdrop_id",
    "social_media_image_id",
    "contact_image_id",
    "marketing_banner_id",
    "marketing_hero_id"
  ];
  const mobileImages = ["mobile_banner_id", "mobile_banner_public_id"];
  const imageLists = ["project_image_ids", "marketing_project_image_ids"];
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(tabs.Settings);
  const [isOpen, setOpen] = useState(false);
  const [applyMode, setApplyMode] = useState(false);
  const [showJsonTranslations, setShowJsonTranslations] = useState(false);
  const dashboardVersionOptions = useDashboardVersions();
  const dashboardMenuOptions = useDashboardMenuOptions();

  const validationScheme = Yup.object({
    name: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
      .min(
        5,
        intl.formatMessage(
          {
            id: "meta.validation.minCharacters"
          },
          { min: 5 }
        )
      ),
    video_url: Yup.string()
      .nullable()
      .notRequired()
      .test(
        "is-video-url-valid",
        intl.formatMessage({ id: "product.attachment.invalid" }),
        value => isVideoUrlValid(value)
      )
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values, { resetForm, setFieldValue }) => {
    return new Promise((_, reject) => {
      const resource = new ConfiguratorDesignsResource(dispatch, projectId);
      resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
          resource.fetchAll().then(res => {
            if (res.status === 200) {
              imageLists.forEach(l => {
                const images = get(
                  res.data,
                  ["configurator_designs", 0, l],
                  []
                );
                if (images) {
                  setFieldValue(l, images);
                }
              });
            }
            reject();
            if (!model.id) resetForm();
            if (!applyMode) handleClose();
          });
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
          reject();
        });
    });
  };

  const renderDeleteButton = (id, isSubmitting) => {
    const deleteButton = (
      <Button
        id="delete"
        color="red"
        basic
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
        content={intl.formatMessage({
          id: "meta.actions.remove"
        })}
        loading={isSubmitting}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          const resource = new ConfiguratorDesignsResource(dispatch, projectId);
          resource
            .remove(id)
            .then(() => resource.fetchAll())
            .then(closeConfirmation)
            .catch(() => {
              new Growl(dispatch).error(
                "message.error.title",
                "meta.confirmations.changesNotSaved"
              );
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="configuratorDesign.removeConfirmation.title"
        message="configuratorDesign.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  return (
    <Formik
      initialValues={model}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
        return (
          <Modal
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={button}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id={`configuratorDesign.dialog.${
                  model.id ? "editTitle" : "newTitle"
                }`}
              />
            </Modal.Header>
            <Modal.Content scrolling style={{ height: "70vh" }}>
              <ProjectImagesLoader projectId={projectId}>
                <span />
              </ProjectImagesLoader>
              <Form style={{ marginBottom: "50px" }}>
                <Grid style={{ marginBottom: "10px" }}>
                  <Grid.Column width={11}>
                    <Header asw="h5">
                      <FormattedMessage id="configuratorDesign.dialog.name" />
                    </Header>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Design name"
                      errorPrompt
                    />
                  </Grid.Column>
                  <Grid.Column width={5} textAlign="right" verticalAlign="top">
                    <If condition={!values.default}>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          setFieldValue("default", true);
                        }}
                        style={{ marginTop: "2.5em" }}
                      >
                        <FormattedMessage id="configuratorDesign.dialog.defaultButton" />
                      </Button>
                    </If>
                    <If condition={values.default}>
                      <Label
                        style={{ marginTop: "3.5em" }}
                        content={intl.formatMessage({
                          id: "configuratorDesign.dialog.defaultLabel"
                        })}
                      />
                    </If>
                  </Grid.Column>
                </Grid>

                <Menu
                  data-component="configuratorDesignMenu"
                  style={{ margin: "0 0 10px 0" }}
                >
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.settings"
                    })}
                    onClick={() => setCurrentTab(tabs.Settings)}
                    active={currentTab === tabs.Settings}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.settings" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.logos"
                    })}
                    onClick={() => setCurrentTab(tabs.Logos)}
                    active={currentTab === tabs.Logos}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.logos" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.images"
                    })}
                    onClick={() => setCurrentTab(tabs.Images)}
                    active={currentTab === tabs.Images}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.images" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.style"
                    })}
                    onClick={() => setCurrentTab(tabs.Style)}
                    active={currentTab === tabs.Style}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.style" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.style"
                    })}
                    onClick={() => setCurrentTab(tabs.Dashboards)}
                    active={currentTab === tabs.Dashboards}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.dashboards" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.translations"
                    })}
                    onClick={() => setCurrentTab(tabs.Translations)}
                    active={currentTab === tabs.Translations}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.translations" />
                  </Menu.Item>
                  <Menu.Item
                    name={intl.formatMessage({
                      id: "configuratorDesign.dialog.tabs.faqs"
                    })}
                    onClick={() => setCurrentTab(tabs.Faqs)}
                    active={currentTab === tabs.Faqs}
                  >
                    <FormattedMessage id="configuratorDesign.dialog.tabs.faqs" />
                  </Menu.Item>
                  <FeatureToggle featureToggleName="ok20_pages">
                    <Menu.Item
                      name={intl.formatMessage({
                        id: "configuratorDesign.dialog.tabs.pages"
                      })}
                      onClick={() => setCurrentTab(tabs.Pages)}
                      active={currentTab === tabs.Pages}
                    >
                      <FormattedMessage id="configuratorDesign.dialog.tabs.pages" />
                    </Menu.Item>
                  </FeatureToggle>
                </Menu>

                <If condition={currentTab === tabs.Dashboards} block>
                  <Select
                    id="dashboard_version"
                    name="dashboard_version"
                    errorPrompt
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.dashboardVersion.title"
                    })}
                    options={dashboardVersionOptions}
                  />
                  <If condition={values.dashboard_version !== "v1"} block>
                    <Input
                      id="video_url"
                      errorPrompt
                      className="fluid"
                      name="video_url"
                      autoComplete="off"
                      label={intl.formatMessage({
                        id: "configuratorDesign.dialog.projectVideo.title"
                      })}
                    />
                  </If>
                  <Select
                    id="buyer_portal_dashboard_menu"
                    name="buyer_portal_dashboard_menu"
                    errorPrompt
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.buyerPortalDashboardMenu.title"
                    })}
                    options={dashboardMenuOptions}
                  />
                  <If condition={values.dashboard_version === "custom"} block>
                    <ContentPageEditor
                      configuratorDesign={model}
                      setFieldValue={setFieldValue}
                      fieldName="buyer_portal_dashboard"
                      value={values.buyer_portal_dashboard}
                    />
                  </If>
                  <Select
                    id="marketing_portal_dashboard_version"
                    name="marketing_portal_dashboard_version"
                    errorPrompt
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.marketingPortalDashboardVersion.title"
                    })}
                    placeholder={intl.formatMessage({
                      id: `configuratorDesign.dialog.dashboardVersion.values.${values.dashboard_version}`
                    })}
                    clearable
                    options={dashboardVersionOptions}
                  />
                  <Select
                    id="marketing_portal_dashboard_menu"
                    name="marketing_portal_dashboard_menu"
                    errorPrompt
                    placeholder={intl.formatMessage({
                      id: `configuratorDesign.dialog.dashboardMenu.values.${values.buyer_portal_dashboard_menu}`
                    })}
                    clearable
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.marketingPortalDashboardMenu.title"
                    })}
                    options={dashboardMenuOptions}
                  />
                  <If
                    condition={
                      values.marketing_portal_dashboard_version === "custom"
                    }
                    block
                  >
                    <ContentPageEditor
                      configuratorDesign={model}
                      setFieldValue={setFieldValue}
                      fieldName="marketing_portal_dashboard"
                      value={values.marketing_portal_dashboard}
                    />
                  </If>
                </If>

                <If condition={currentTab === tabs.Settings} block>
                  <Input
                    id="buyer_portal_app_id"
                    errorPrompt
                    className="fluid"
                    name="buyer_portal_app_id"
                    autoComplete="off"
                    placeholder="default"
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.appId.title"
                    })}
                  />
                  <Input
                    id="marketing_portal_app_id"
                    errorPrompt
                    className="fluid"
                    name="marketing_portal_app_id"
                    autoComplete="off"
                    placeholder={values.buyer_portal_app_id || "default"}
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.marketingPortalAppId.title"
                    })}
                  />
                  <Select
                    id="source_language"
                    name="source_language"
                    errorPrompt
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.sourceLanguage.title"
                    })}
                    options={LANGUAGES}
                  />
                  <Select
                    id="supported_languages"
                    name="supported_languages"
                    errorPrompt
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.supportedLanguages.title"
                    })}
                    multiple
                    clearable
                    options={LANGUAGES}
                  />

                  <Input
                    id="buyer_portal_social_media_project_title"
                    errorPrompt
                    className="fluid"
                    name="buyer_portal_social_media_project_title"
                    autoComplete="off"
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.buyerPortalSocialMediaProjectTitle.title"
                    })}
                  />
                  <Input
                    id="buyer_portal_social_media_project_description"
                    errorPrompt
                    className="fluid"
                    name="buyer_portal_social_media_project_description"
                    autoComplete="off"
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.buyerPortalSocialMediaProjectDescription.title"
                    })}
                  />
                  <Input
                    id="marketing_portal_social_media_project_title"
                    errorPrompt
                    className="fluid"
                    name="marketing_portal_social_media_project_title"
                    autoComplete="off"
                    placeholder={
                      values.buyer_portal_social_media_project_title ||
                      "default"
                    }
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.marketingPortalSocialMediaProjectTitle.title"
                    })}
                  />
                  <Input
                    id="marketing_portal_social_media_project_description"
                    errorPrompt
                    className="fluid"
                    name="marketing_portal_social_media_project_description"
                    autoComplete="off"
                    placeholder={
                      values.buyer_portal_social_media_project_description ||
                      "default"
                    }
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.marketingPortalSocialMediaProjectDescription.title"
                    })}
                  />
                  <Field
                    id="buyer_portal_config"
                    name="buyer_portal_config"
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.buyerPortalConfig.title"
                    })}
                    component={SemanticFormikJsonEditor}
                  />
                  <Field
                    id="marketing_portal_config"
                    name="marketing_portal_config"
                    label={intl.formatMessage({
                      id:
                        "configuratorDesign.dialog.marketingPortalConfig.title"
                    })}
                    component={SemanticFormikJsonEditor}
                  />
                </If>
                <If condition={currentTab === tabs.Logos} block>
                  {logos.map(img => (
                    <Field
                      key={img}
                      id={img}
                      name={img}
                      label={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                      multiple={false}
                      title={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                      projectId={projectId}
                      component={SemanticFormikImageSelector}
                    />
                    // <Field
                    //   key={img}
                    //   id={img}
                    //   name={img}
                    //   label={intl.formatMessage({
                    //     id: `configuratorDesign.dialog.${img}`
                    //   })}
                    //   component={SemanticFormikFileUpload}
                    // />
                  ))}
                </If>
                <If condition={currentTab === tabs.Images} block>
                  {desktopImages.map(img => (
                    <Field
                      key={img}
                      id={img}
                      name={img}
                      label={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                      component={SemanticFormikImageSelector}
                      title={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                      projectId={projectId}
                      multiple={false}
                    />
                  ))}

                  {mobileImages.map(img => (
                    <Field
                      key={img}
                      id={img}
                      name={img}
                      label={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                      component={SemanticFormikImageSelector}
                      projectId={projectId}
                      multiple={false}
                      title={intl.formatMessage({
                        id: `configuratorDesign.dialog.${img}`
                      })}
                    />
                  ))}
                  {imageLists.map(l => (
                    <Field
                      key={l}
                      id={l}
                      name={l}
                      label={intl.formatMessage({
                        id: `configuratorDesign.dialog.${l}`
                      })}
                      component={SemanticFormikImageSelector}
                      projectId={projectId}
                      title={intl.formatMessage({
                        id: `configuratorDesign.dialog.${l}`
                      })}
                      multiple
                    />
                  ))}
                </If>

                <If condition={currentTab === tabs.Style} block>
                  <Select
                    id="theme"
                    name="theme"
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.theme.title"
                    })}
                    options={[
                      {
                        key: 1,
                        value: "light",
                        text: intl.formatMessage({
                          id: "configuratorDesign.dialog.theme.light"
                        })
                      },
                      {
                        key: 2,
                        value: "dark",
                        text: intl.formatMessage({
                          id: "configuratorDesign.dialog.theme.dark"
                        })
                      }
                    ]}
                  />
                  {colorList.map(group => {
                    return (
                      <div
                        key={group.id}
                        className="margin bottom top spacious"
                      >
                        <Header as="h5">{group.label}</Header>
                        {group.items.map(item => (
                          <div key={`${values.theme}-${item.id}`}>
                            <If condition={item.type === "color"}>
                              <Field
                                key={`${values.theme}-${item.id}-color`}
                                id={item.id}
                                name={`styling_simple.${item.id}`}
                                type="color"
                                label={item.label}
                                defaultValue={item.defaultValue[values.theme]}
                                component={SemanticFormikColorInputWithHexField}
                              />
                            </If>
                            <If condition={item.type === "select"}>
                              <Select
                                id={item.id}
                                key={`${values.theme}-${item.id}-select`}
                                name={`styling_simple.${item.id}`}
                                label={item.label}
                                defaultValue={item.defaultValue[values.theme]}
                                options={item.options}
                              />
                            </If>
                          </div>
                        ))}
                      </div>
                    );
                  })}

                  <Field
                    id="styling_advanced"
                    name="styling_advanced"
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.stylingMode.advanced"
                    })}
                    component={SemanticFormikJsonEditor}
                  />

                  <TextArea
                    id="html_head"
                    name="html_head"
                    label={intl.formatMessage({
                      id: "configuratorDesign.dialog.htmlHead"
                    })}
                    rows={5}
                  />
                </If>

                <If condition={currentTab === tabs.Translations} block>
                  <Translations
                    handleApply={setFieldValue}
                    fieldName="translations"
                    json={values.translations}
                  />
                  <div style={{ marginTop: "3rem" }}>
                    <If condition={showJsonTranslations}>
                      <Field
                        id="translations"
                        name="translations"
                        label={intl.formatMessage({
                          id: "configuratorDesign.dialog.translations"
                        })}
                        component={SemanticFormikJsonEditor}
                      />
                    </If>
                    <If condition={!showJsonTranslations}>
                      <Button
                        basic
                        onClick={() => setShowJsonTranslations(true)}
                      >
                        JSON anzeigen
                      </Button>
                    </If>
                  </div>
                </If>

                <If condition={currentTab === tabs.Faqs} block>
                  <Faqs
                    data={isEmpty(values.faqs) ? [] : values.faqs}
                    onChange={setFieldValue}
                  />
                </If>

                <FeatureToggle featureToggleName="ok20_pages">
                  <If condition={currentTab === tabs.Pages} block>
                    <WebsiteBuilder
                      setFieldValue={setFieldValue}
                      fieldName="pages"
                      value={values.pages}
                    />
                    {/* <Field
                      id="pages"
                      name="pages"
                      label={intl.formatMessage({
                        id: "configuratorDesign.dialog.pages.label"
                      })}
                      component={SemanticFormikJsonEditor}
                    /> */}
                  </If>
                </FeatureToggle>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Grid>
                <Grid.Column width={4} textAlign="left">
                  <If condition={!model.default}>
                    {renderDeleteButton(model.id, isSubmitting)}
                  </If>
                </Grid.Column>
                <Grid.Column width={12} textAlign="right">
                  <Button
                    basic
                    color="grey"
                    disabled={isSubmitting}
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    primary
                    basic
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    content={intl.formatMessage({ id: "meta.actions.apply" })}
                    onClick={() => {
                      setApplyMode(true);
                      handleSubmit();
                    }}
                  />
                  <Button
                    primary
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      setApplyMode(false);
                      handleSubmit();
                    }}
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                  />
                </Grid.Column>
              </Grid>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

ConfiguratorDesignDialog.propTypes = {
  model: OnlineConfigurationShape.isRequired,
  projectId: number.isRequired,
  button: node.isRequired
};

/* OK COnfiguration Component */

const ConfiguratorDesign = ({ projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const configDesigns = sortBy(useSelector(getConfiguratorDesigns), "id");

  useEffect(() => {
    setLoading(true);
    new ConfiguratorDesignsResource(dispatch, projectId)
      .fetchAll()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const handleCreate = useCallback(() => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      const resource = new ConfiguratorDesignsResource(dispatch, projectId);
      resource
        .save({})
        .then(() => {
          resource.fetchAll().then(() => {
            new Growl(dispatch).success(
              "message.success.title",
              "message.success.body"
            );
            resolve();
          });
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
          reject();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [projectId, dispatch]);

  const handleDuplicate = useCallback(
    item => {
      return new Promise((resolve, reject) => {
        setLoading(true);
        const resource = new ConfiguratorDesignsResource(dispatch, projectId);

        resource
          .save({})
          .then(res => {
            if (res.data?.configurator_design?.id) {
              const values = {
                ...item,
                id: res.data.configurator_design.id,
                name: intl.formatMessage(
                  { id: "configuratorDesign.dialog.duplicateName" },
                  { originalName: item.name }
                ),
                default: false
              };
              resource.save(values).then(() => {
                resource.fetchAll().then(() => {
                  new Growl(dispatch).success(
                    "message.success.title",
                    "message.success.body"
                  );
                  resolve();
                });
              });
            }
          })
          .catch(() => {
            new Growl(dispatch).error(
              "message.error.title",
              "meta.confirmations.changesNotSaved"
            );
            stopLoading("project");
            reject();
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [projectId, dispatch]
  );

  const renderConfigDesign = useCallback(item => {
    return (
      <Segment key={item.id}>
        <Grid>
          <Grid.Column width={11}>
            <Header as="h5">{item.name}</Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign="center">
            <If condition={item.default}>
              <Label
                content={intl.formatMessage({
                  id: "configuratorDesign.dialog.defaultLabel"
                })}
              />
            </If>
          </Grid.Column>
          <Grid.Column width={1} textAlign="center">
            <IsVersionHistoryAccessible>
              <VersionHistoryLink
                id={item.id}
                type="ConfiguratorDesign"
                size="mid"
              />
            </IsVersionHistoryAccessible>
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            <ConfiguratorDesignDialog
              model={item}
              projectId={projectId}
              button={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                >
                  <Icon name="edit" />
                </a>
              }
            />
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="button"
              data-tooltip={intl.formatMessage({ id: "meta.actions.clone" })}
              onClick={() => handleDuplicate(item)}
              onKeyDown={() => handleDuplicate(item)}
              tabIndex={0}
            >
              <Icon name="copy outline" />
            </a>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }, []);

  return (
    <>
      {configDesigns.map(item => renderConfigDesign(item))}
      <Button
        icon="plus"
        color="green"
        content={intl.formatMessage({
          id: "configuratorDesign.migration.label"
        })}
        onClick={handleCreate}
        loading={isLoading}
        fluid
      />
    </>
  );
};

ConfiguratorDesign.propTypes = {
  projectId: number.isRequired
};

export default ConfiguratorDesign;
