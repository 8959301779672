import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Form } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Modal, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import ProjectEmailsResource from "../../../actions/projectEmailsActions";
import Growl from "../../../actions/growlActions";
import { ProjectEmailShape } from "../../../../shared/shapes/projectEmail.shape";

const ShareSetupInstructionsDialog = ({
  model,
  open: initialOpen,
  trigger,
  onSend
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setOpen(initialOpen);
  }, [model, initialOpen]);

  const schema = Yup.object().shape({
    technical_contact_name: Yup.string()
      .required(
        intl.formatMessage({
          id: "meta.validation.required"
        })
      )
      .min(
        5,
        intl.formatMessage(
          {
            id: "meta.validation.minCharacters"
          },
          { min: 5 }
        )
      ),
    technical_contact_email: Yup.string()
      .required(
        intl.formatMessage({
          id: "meta.validation.required"
        })
      )
      .email(
        intl.formatMessage({
          id: "meta.validation.email"
        })
      )
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    values => {
      const resource = new ProjectEmailsResource(dispatch);
      return resource
        .instructions(model.id, values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.sent"
          );
          if (onSend) {
            onSend();
          }
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model, onSend]
  );

  return (
    <Formik initialValues={model} validationSchema={schema} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, handleSubmit }) => (
        <Modal
          closeIcon
          trigger={trigger}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          size="small"
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Modal.Header>
            <FormattedMessage id="projectEmail.instructionDialog.title" />
          </Modal.Header>
          <Modal.Content>
            <div className="margin bottom spacious">
              <p>
                <FormattedMessage
                  id="projectEmail.instructionDialog.summary"
                  values={model}
                />
              </p>
              <Segment inverted>
                <a
                  href={model.status_page_url}
                  target="_instructions"
                  style={{
                    color: "white !important",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  {model.status_page_url}
                </a>
              </Segment>
            </div>
            <div className="margin bottom spacious">
              <p>
                <FormattedMessage id="projectEmail.instructionDialog.alternative" />
              </p>
            </div>
            <Form id="configuratorBackendForm">
              <Input
                id="technical_contact_name"
                errorPrompt
                name="technical_contact_name"
                label={intl.formatMessage({
                  id: "projectEmail.wizard.labels.name"
                })}
              />
              <Input
                id="technical_contact_email"
                errorPrompt
                name="technical_contact_email"
                label={intl.formatMessage({
                  id: "projectEmail.wizard.labels.email"
                })}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button basic disabled={isSubmitting} onClick={handleClose}>
              <FormattedMessage id="meta.actions.close" />
            </Button>
            <Button
              primary
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              <FormattedMessage id="meta.actions.send" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

ShareSetupInstructionsDialog.propTypes = {
  trigger: PropTypes.node,
  open: PropTypes.bool,
  model: ProjectEmailShape,
  onSend: PropTypes.func
};

ShareSetupInstructionsDialog.defaultProps = {
  trigger: undefined,
  open: false,
  model: undefined,
  onSend: () => {}
};

export default ShareSetupInstructionsDialog;
