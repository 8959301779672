/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { sortBy } from "lodash";
import {
  Dropdown,
  Icon,
  Header,
  Popup,
  Table,
  Button,
  Segment,
  Grid
} from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import Growl from "builder_portal/actions/growlActions";
import { connect } from "react-redux";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import RemoveDialog from "./RemoveDialog";
import EditProjectMembershipDialog from "./EditProjectMembershipDialog";
import ProjectEmailsResource from "../../../actions/projectEmailsActions";
import getProjectEmails from "../../../selectors/projectEmails";

const DEFAULT_PROJECT_ROLES = [];
const DEFAULT_MAIL_TEMPLATES = [];

class ProjectCards extends React.Component {
  getAvailableUsers(project) {
    const { accountMemberships } = this.props;
    return (accountMemberships || []).filter(accountMembership => {
      return project.project_memberships.every(projectMembership => {
        return (
          projectMembership.user.id !== accountMembership.user.id &&
          accountMembership.status === "active"
        );
      });
    });
  }

  componentDidMount() {
    const { projectEmailsResource } = this.props;
    projectEmailsResource.fetchAll();
  }

  getWorkflowRoles() {
    const { account } = this.props;
    return account.config_params?.project_roles || DEFAULT_PROJECT_ROLES;
  }

  getAccountMailTemplates() {
    const { mailTemplates } = this.props;
    return mailTemplates || DEFAULT_MAIL_TEMPLATES;
  }

  hasAccountMailTemplates() {
    return !!this.getAccountMailTemplates().length;
  }

  handleAssign(options) {
    const { actions, growl } = this.props;
    actions.users
      .addUserToProject({
        projectId: options.projectId,
        userId: options.userId,
        role: "operator"
      })
      .then(() => {
        actions.account.get(true); // refresh view
      })
      .then(() => {
        growl.success(
          "macro.message.success.title",
          "account.project_memberships.user_added_to_project",
          {
            timeout: 2500
          }
        );
      })
      .catch(() => {
        growl.error(
          "macro.message.error.title",
          "account.project_memberships.user_added_to_project_error",
          {
            timeout: 2500
          }
        );
      });
  }

  handleAddUserRole(options) {
    const { actions, growl } = this.props;
    return actions.users
      .updateProjectMembership(options)
      .then(() => {
        return actions.account.get(true); // refresh view
      })
      .then(() => {
        growl.success(
          "macro.message.success.title",
          "account.project_memberships.user_changed",
          {
            timeout: 2500
          }
        );
      })
      .catch(() => {
        growl.error(
          "macro.message.error.title",
          "account.project_memberships.user_changed_error",
          {
            timeout: 2500
          }
        );
      });
  }

  handleUnassign(options) {
    const { actions, growl } = this.props;
    actions.users
      .removeProjectMembership({
        membershipId: options.membershipId,
        projectId: options.projectId
      })
      .then(() => {
        return actions.account.get(true); // refresh view
      })
      .then(() => {
        growl.success(
          "macro.message.success.title",
          "account.project_memberships.user_removed_from_project",
          {
            timeout: 2500
          }
        );
      })
      .catch(() => {
        growl.error(
          "macro.message.error.title",
          "account.project_memberships.user_removed_from_project_error",
          {
            timeout: 2500
          }
        );
      });
  }

  renderProjects() {
    const { projects } = this.props;
    return projects.map(item => {
      return (
        <Segment.Group
          data-id={item.id}
          data-component="projectUsersCardItem"
          key={item.id}
          style={{
            width: "100%"
          }}
        >
          <Segment attached="top">
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="14">
                <Header as="h5"> {item.name}</Header>
              </Grid.Column>
              <Grid.Column textAlign="right" width="2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  <Dropdown
                    id="select-user"
                    scrolling
                    compact
                    floating
                    labeled
                    pointing="right"
                    button
                    icon="plus"
                  >
                    <Dropdown.Menu>
                      {this.renderUserOptions(item)}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Grid.Column>
            </Grid>

            <Header size="small" className="left floated element" />
          </Segment>
          <Table striped attached>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>
                  <FormattedMessage
                    id="account.project_memberships.attributes.name.label"
                    defaultMessage="account.project_memberships.attributes.name.label"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <FormattedMessage id="account.project_memberships.attributes.project_role.label" />
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <FormattedMessage id="account.project_memberships.attributes.email_signature.label" />
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  <FormattedMessage id="account.project_memberships.attributes.project_email.label" />
                </Table.HeaderCell>
                <Table.HeaderCell width={3} />
              </Table.Row>
            </Table.Header>

            <Table.Body>{this.renderProjectMemberships(item)}</Table.Body>
          </Table>
        </Segment.Group>
      );
    });
  }

  renderProjectMemberships(item) {
    const {
      showAllUsers,
      accountMembershipsDictionary,
      projectRoles,
      projectEmails
    } = this.props;
    if (!item.project_memberships.length) {
      return (
        <Table.Row>
          <Table.Cell colSpan={16}>
            <FormattedMessage
              id="account.project_memberships.missing"
              defaultMessage="account.project_memberships.missing"
            />
          </Table.Cell>
        </Table.Row>
      );
    }

    const filteredMemberships = showAllUsers
      ? item.project_memberships
      : item.project_memberships.filter(
          x => !!accountMembershipsDictionary[x.user.id]
        );

    const accountMailTemplates = this.getAccountMailTemplates();
    const workflowRoles = this.getWorkflowRoles();

    return sortBy(filteredMemberships, membership => membership.user.label).map(
      membership => {
        return (
          <Table.Row
            data-id={membership.id}
            data-model="project_membership"
            key={membership.id}
          >
            <Table.Cell>
              <Header as="h5">{membership.user.label}</Header>
              {membership.user.email}
            </Table.Cell>
            <Table.Cell>{this.renderRoleLabel(membership)}</Table.Cell>
            <Table.Cell>{this.getMailTemplateLabel(membership)}</Table.Cell>
            <Table.Cell>
              {this.getProjectEmailLabel(membership?.project_email_id)}
            </Table.Cell>

            <Table.Cell textAlign="center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around"
                }}
              >
                <VersionHistoryLink id={membership.id} type="User" />
                <EditProjectMembershipDialog
                  model={membership}
                  trigger={
                    <Button basic data-cmd="edit" className="icon">
                      <Icon name="setting" />
                    </Button>
                  }
                  projectRoles={projectRoles}
                  projectId={item?.id}
                  membership={membership}
                  onHandleAddUserRole={options =>
                    this.handleAddUserRole(options)
                  }
                  userLabel={membership.user.label}
                  accountMailTemplates={accountMailTemplates}
                  projectEmails={projectEmails}
                  workflowRoles={workflowRoles}
                />
                <FeatureToggleActive featureToggleName="show_remove_user_dialog">
                  <RemoveDialog
                    membership={membership}
                    project={item}
                    button={
                      <Button
                        basic
                        data-cmd="delete"
                        className="icon"
                        onClick={e => e.preventDefault()}
                        id="delete-membership-dialog"
                      >
                        <Icon name="remove" color="red" />
                      </Button>
                    }
                  />
                </FeatureToggleActive>
                <FeatureToggle
                  featureToggleName="show_remove_user_dialog"
                  disabled
                >
                  <Button
                    data-cmd="delete"
                    className="icon"
                    onClick={() =>
                      this.handleUnassign({
                        projectId: item.id,
                        membershipId: membership.id
                      })
                    }
                    id="delete-membership-no-dialog"
                  >
                    <Icon name="remove" color="red" circular />
                  </Button>
                </FeatureToggle>
              </div>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  }

  renderRoleLabel(membership) {
    const { projectRoles } = this.props;
    // find label in the project roles
    const role = projectRoles.find(
      roleItem => roleItem.value === membership.project_role_id
    );

    return role?.text || "";
  }

  getProjectEmailLabel(emailId) {
    const { projectEmails } = this.props;
    const email = projectEmails.find(emailItem => emailItem.id === emailId);

    return email?.email || "Standard";
  }

  getMailTemplateLabel(membership) {
    if (!this.hasAccountMailTemplates()) {
      return null;
    }

    const mailTemplates = this.getAccountMailTemplates();
    const defaultMailTemplates = mailTemplates.filter(
      mailTemplate => mailTemplate.role === "default_mail"
    );
    const currentDefaultMailTemplate = defaultMailTemplates.find(
      mailTemplate => mailTemplate.id === membership.mail_template_id
    );

    return currentDefaultMailTemplate ? currentDefaultMailTemplate.name : "NoN";
  }

  renderUserOptions(project) {
    const users = this.getAvailableUsers(project);

    return sortBy(users, membership => membership.user.first_name).map(
      accountMembership => {
        const { user } = accountMembership;
        return (
          <Dropdown.Item
            key={`user-item-${user.id}`}
            value={user.id}
            icon={
              <Popup content={user.email} trigger={<Icon name="user" link />} />
            }
            text={`${[user.first_name, user.last_name].join(" ")}`}
            onClick={() =>
              this.handleAssign({ userId: user.id, projectId: project.id })
            }
          />
        );
      }
    );
  }

  render() {
    const { accountMemberships, projects } = this.props;

    if (!accountMemberships || !projects || !projects.length) {
      return null;
    }

    return (
      <div className="margin top spacious">
        <Header>
          <FormattedMessage
            id="account.project_memberships.title"
            defaultMessage="account.project_memberships.title"
          />
          <Header.Subheader>
            <FormattedMessage
              id="account.project_memberships.description"
              defaultMessage="account.project_memberships.description"
            />
          </Header.Subheader>
        </Header>
        <div
          itemsPerRow={1}
          stackable
          data-component="projectUsersCardCollection"
          style={{
            marginTop: "1em"
          }}
        >
          {this.renderProjects()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    projectEmails: getProjectEmails(state)
  };
};

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch),
  projectEmailsResource: new ProjectEmailsResource(dispatch)
});

ProjectCards.propTypes = {
  actions: PropTypes.object,
  account: PropTypes.object,
  accountMemberships: PropTypes.array,
  mailTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  projects: PropTypes.array,
  showAllUsers: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accountMembershipsDictionary: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectRoles: PropTypes.array.isRequired,
  growl: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  projectEmailsResource: PropTypes.object.isRequired,
  projectEmails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  )
};

ProjectCards.defaultProps = {
  actions: {},
  account: {},
  accountMemberships: [],
  projects: [],
  projectEmails: []
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCards);
