import Resources from "shared/actions/resourcesActions";

export default class UpgradeBundlesResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "upgrade_bundle",
      `/api/v1/projects/${projectId}/upgrade_bundles`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
