import { arrayOf, string } from "prop-types";
import React from "react";
import { TradeLabelShape } from "shared/shapes/tradeLabel.shape";

function TradeLabel({ trades, id }) {
  const ele = trades.filter(t => {
    return t.value === id;
  })[0];
  return <span>{ele ? ele.text : id}</span>;
}

TradeLabel.propTypes = {
  trades: arrayOf(TradeLabelShape).isRequired,
  id: string.isRequired
};

export default TradeLabel;
