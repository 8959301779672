import React from "react";
import PropTypes from "prop-types";
import { get, keyBy } from "lodash";
import { Doughnut } from "react-chartjs-2";

import connectStatistic from "./statisticsContainer";

class ProgressByPhase extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    statistics: PropTypes.array,
    processDefinition: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { statistics, processDefinition } = this.props;
    const { i18n } = this.props;

    const doughnutOptions = {
      legend: {
        display: false,
        labels: {
          defaultFontFamily: "Lato"
        }
      },
      tooltips: {
        backgroundColor: "#333",
        titleFontFamily: "Lato",
        bodyFontFamily: "Lato",
        bodyFontSize: 17,
        mode: "nearest",
        callbacks: {
          title(tooltipItem, data) {
            const label = data.labels[tooltipItem[0].index];
            return `${label}`;
          },
          label(tooltipItem, data) {
            const value = data.datasets[0].data[tooltipItem.index];
            return `${value} ${i18n["roomBook.lineItems.title.other"]}`;
          }
        }
      },
      animation: {
        duration: 2000
      }
    };

    const phaseStatistic = keyBy(statistics, "phase");
    const phases = processDefinition.getPhases();

    const doughnutChartData = {
      labels: phases.map(phase => {
        return phase.label;
      }),
      datasets: [
        {
          data: phases.map(phase => {
            return get(phaseStatistic[phase.id], "count", 0);
          }),
          backgroundColor: phases.map(phase => get(phase, "color", "#ccc"))
        }
      ]
    };

    return (
      <div data-component="itemsPerPhaseStatistic">
        <Doughnut data={doughnutChartData} options={doughnutOptions} />
      </div>
    );
  }
}

export default connectStatistic(ProgressByPhase);
