import { Liquid } from "liquidjs";
import PropTypes from "prop-types";
import React from "react";
import "./liquidPreview.scss";
import * as Sentry from "@sentry/react";

const liquidEngine = new Liquid({ strictVariables: true });

function LiquidPreview({ html, liquidContext, colorize }) {
  let parsedHtml = "";
  try {
    parsedHtml = liquidEngine.parseAndRenderSync(
      colorize ? LiquidPreview.wrapTags(html) : html,
      liquidContext
    );
  } catch (e) {
    /* Displaying the error that was sent to sentry
     * makes in-place-debugging much easier
     */
    // eslint-disable-next-line no-console
    console.error("Liquid Error occurred", e);
    Sentry.captureException(e);

    parsedHtml = html; // fallback
  }

  return (
    <div
      data-component="liquidPreview"
      /*
       * for very specific customer-demands we need to allow any HTML Code, not only WYSIWYG elements
       * still: a potential XSS is possible from the company-employees
       */
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: parsedHtml }}
    />
  );
}

LiquidPreview.wrapTags = s =>
  s.replace(/{{([^}]+)}}/g, '<span class="liquidTemplate">{{$1}}</span>');

LiquidPreview.defaultProps = {
  colorize: false
};
LiquidPreview.propTypes = {
  html: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  liquidContext: PropTypes.object.isRequired,
  colorize: PropTypes.bool
};

export default LiquidPreview;
