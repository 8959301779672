import { get, sortBy, groupBy, string } from "lodash";
import moment from "moment";
import { func, instanceOf, number, object, shape, arrayOf } from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
  Button,
  Feed,
  Grid,
  Header,
  Icon,
  Loader,
  Popup,
  Segment
} from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { If } from "shared/components/elements/Conditions";
import Observers from "shared/components/observers/Observers";
import { getMessageDraftForCurrentThread } from "builder_portal/selectors/index";
import { MessageDraftsForMessagesResource } from "builder_portal/actions/messageDraftsActions";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { MessageLabelsResource } from "builder_portal/actions/messageLabelsActions";
import { getMessageLabels } from "builder_portal/selectors/messages";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import MessagesResource from "../../actions/messagesActions";
import MasterDataResource from "../../actions/messaging/masterDataActions";
import { getUnitBuyerNames } from "../../selectors/masterdata";
import { getMessageThread, getReplyTemplate } from "../../selectors/messages";
import HtmlMessageDialog from "./HtmlMessageDialog";
import MessageActivityPicker from "./MessageActivityPicker";
import MessageDialog from "./MessageDialog";
import MessageProjectPicker from "./MessageProjectPicker";
import "./messages.scss";
import MessageUnitPicker from "./MessageUnitPicker";
import { AlertsResource } from "../../actions/notificationActions";
import { UPDATE_NOTIFICATIONS } from "../../../shared/constants/appConstants";
import { MessageReplyShape } from "../../../shared/shapes/messageReply.shape";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import MessageBody from "./MessageBody";
import MessageBodyV1 from "./MessageBodyV1";
import ActivityCreator from "./ActivityCreator";
import MessageLabelsSelect from "./MessageLabelsSelect";

const PARTICIPANT_ICON = {
  buyer: "child",
  contractor: "truck",
  user: "user",
  contact: "address book outline",
  unknown: "question"
};
class MessageContainer extends React.Component {
  static checkForEmptyReferences(arr, msg) {
    if (!arr || (arr && arr.length === 0)) {
      return (
        <p className="muted">
          <i>{msg}</i>
        </p>
      );
    }
    return arr;
  }

  constructor(props) {
    super(props);

    this.state = {
      isMessageFormOpen: false,
      draftLoaded: false,
      readLoading: false
    };
    props.loadMessage();
    props.loadMasterdata();
    props.loadDraft();
    props.loadMessageLabels();

    this.scrolled = false;
  }

  componentDidMount() {
    const { pollNotifications } = this.props;
    pollNotifications();
    setTimeout(() => this.scrollToHash(), 800);
  }

  componentDidUpdate() {
    setTimeout(() => this.scrollToHash(), 800);
  }

  static getDerivedStateFromProps(props, state) {
    const { messageDraft } = props;
    const { isMessageFormOpen, draftLoaded } = state;

    if (!draftLoaded && messageDraft && !isMessageFormOpen) {
      return {
        ...state,
        isMessageFormOpen: true,
        draftLoaded: true
      };
    }

    return state;
  }

  scrollToHash = () => {
    if (!this.scrolled) {
      const element = document.getElementById(
        window.location.hash?.replace("#", "")
      );
      if (!element) return;

      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth"
      });
      this.scrolled = true;
    }
  };

  getBuyersLabel = id => {
    const { unitBuyers } = this.props;
    return unitBuyers[id];
  };

  handleRead = () => {
    const {
      messageThread,
      unreadMessage,
      readMessage,
      notifications,
      updateNotifications
    } = this.props;
    let markMessageFn;
    let messageCount = get(notifications, "messages", 0);

    if (messageThread.read) {
      markMessageFn = unreadMessage;
      messageCount += 1;
    } else {
      markMessageFn = readMessage;
      messageCount -= 1;
      if (messageCount < 0) messageCount = 0;
    }

    this.setState({ readLoading: true }, () => {
      markMessageFn()
        .then(() => {
          this.setState({ readLoading: false });
        })
        .then(() => {
          updateNotifications({
            ...notifications,
            messages: messageCount
          });
        })
        .catch(e => {
          this.setState({ readLoading: false });
          throw e;
        });
    });
  };

  displayUsers = () => {
    const { i18n, messageThread } = this.props;
    const users = get(messageThread, "participants", [])
      .filter(message => {
        return message.type === "user";
      })
      .map(user => {
        return (
          <div key={user.id}>
            <Popup
              content={user?.email}
              trigger={
                <div>
                  <Icon name="user" />
                  {user.label}
                </div>
              }
            />
          </div>
        );
      });

    return MessageContainer.checkForEmptyReferences(
      users,
      i18n["messaging.message_container.empty_references.no_internal"]
    );
  };

  unlinkActivity = () => {
    const { messagesResource, messageThread } = this.props;
    messagesResource.save({ ...messageThread, activity_id: null });
  };

  unlinkProject = () => {
    const { messagesResource, messageThread } = this.props;
    messagesResource.save({
      ...messageThread,
      project_id: null,
      unit_id: null,
      activity_id: null
    });
  };

  unlinkUnit = () => {
    const { messageThread, messagesResource } = this.props;
    messagesResource.save({
      ...messageThread,
      unit_id: null,
      activity_id: null
    });
  };

  addEmails = (mode, recipients) => {
    const content = [];
    content.push(
      <Grid.Column
        key={`${mode}-col1`}
        width={2}
        textAlign="right"
        style={{ opacity: 0.6 }}
      >
        <FormattedMessage id={`email.attributes.${mode}.label`} />:{" "}
      </Grid.Column>
    );

    const emailList = recipients.reduce((accu, recipient) => {
      accu.push(
        <div key={recipient.email}>
          <span>{recipient.label}</span>
          <span style={{ opacity: 0.7 }}>{` <${recipient.email}> `}</span>
        </div>
      );
      return accu;
    }, []);

    content.push(
      <Grid.Column key={`${mode}-col2`} width={14}>
        {[...emailList]}
      </Grid.Column>
    );

    return (
      <Grid.Row key={mode} style={{ padding: "2px 0" }}>
        {[...content]}
      </Grid.Row>
    );
  };

  renderRecipientsPopup = message => {
    const groupedParticipants = groupBy(message.recipients, "mode");
    const { sender } = message;

    const content = [];
    content.push(
      this.addEmails("from", [{ label: sender.label, email: sender.email }])
    );

    const modes = ["to", "cc", "bcc"];
    modes.forEach(mode => {
      const recipients = groupedParticipants[mode]?.map(({ label, email }) => ({
        label,
        email
      }));
      if (recipients) content.push(this.addEmails(mode, recipients));
    });

    return <Grid style={{ margin: "0.125rem 0" }}>{[...content]}</Grid>;
  };

  displayUnit() {
    const { i18n, messageThread } = this.props;
    const references = get(messageThread, "references", []);

    const project = references.find(message => {
      return message.type === "project";
    });
    const unit = references
      .filter(message => {
        return message.type === "unit";
      })
      .map(({ id, url, label }) => {
        return (
          <div key={id} className="link-wrapper">
            <Link
              id="unit"
              name="unit"
              role="link"
              className="item"
              activeClassName="item"
              to={url}
            >
              <Header
                as="span"
                content={label}
                subheader={this.getBuyersLabel(id)}
              />
            </Link>
            <Button
              basic
              icon
              circular
              className="unlink"
              onClick={this.unlinkUnit}
            >
              <Icon name="delete" />
            </Button>
          </div>
        );
      });

    if (unit.length === 0 && project) {
      return (
        <MessageUnitPicker
          projectId={project.id}
          messageThread={messageThread}
          getBuyersLabel={this.getBuyersLabel}
        />
      );
    }
    return MessageContainer.checkForEmptyReferences(
      unit,
      i18n["messaging.message_container.empty_references.no_unit"]
    );
  }

  displayProject() {
    const { i18n, messageThread } = this.props;
    const references = get(messageThread, "references", []);

    const project = references
      .filter(message => {
        return message.type === "project";
      })
      .map(({ id, url, label }) => {
        return (
          <div key={id} className="link-wrapper">
            <Link
              id="project"
              name="project"
              role="link"
              className="item"
              activeClassName="item"
              to={url}
            >
              {label}
            </Link>
            <Button
              basic
              icon
              circular
              className="unlink"
              onClick={this.unlinkProject}
            >
              <Icon name="delete" />
            </Button>
          </div>
        );
      });

    if (project.length === 0) {
      return (
        <MessageProjectPicker
          placeholder={
            i18n["messaging.message_container.empty_references.no_project"]
          }
          messageThread={messageThread}
        />
      );
    }
    return MessageContainer.checkForEmptyReferences(
      project,
      i18n["messaging.message_container.empty_references.no_project"]
    );
  }

  displayExternalParticipants() {
    const { i18n, messageThread } = this.props;
    const participants = get(messageThread, "participants", []);

    const externalParticipants = participants
      .filter(message => {
        return message.type !== "user";
      })
      .map(user => {
        return (
          <p key={user.id}>
            <Popup
              content={user?.email}
              trigger={
                <div>
                  <Icon name={PARTICIPANT_ICON[user.type]} />
                  {user.label}
                </div>
              }
            />
          </p>
        );
      });

    return MessageContainer.checkForEmptyReferences(
      externalParticipants,
      i18n["messaging.message_container.empty_references.no_external"]
    );
  }

  displayActivity() {
    const { i18n, messageThread } = this.props;
    const references = get(messageThread, "references", []);
    const project = references.find(message => {
      return message.type === "project";
    });
    const unit = references.find(message => {
      return message.type === "unit";
    });
    const activity = references
      .filter(message => {
        return message.type === "activity";
      })
      .map(({ id, url, label, title }) => {
        return (
          <div key={id} className="link-wrapper">
            <Link
              id="activity"
              name="activity"
              role="link"
              className="item"
              activeClassName="item"
              to={url}
              title={`${label} - ${title}`}
            >
              <Header as="span" content={label} subheader={title} />
            </Link>
            <Button
              basic
              icon
              circular
              className="unlink"
              onClick={this.unlinkActivity}
            >
              <Icon name="delete" />
            </Button>
          </div>
        );
      });

    if (activity.length === 0 && unit && project) {
      return (
        <MessageActivityPicker
          placeholder={
            i18n["messaging.message_container.empty_references.no_activity"]
          }
          messageThread={messageThread}
          unitId={unit.id}
          projectId={project.id}
        />
      );
    }
    return MessageContainer.checkForEmptyReferences(
      activity,
      i18n["messaging.message_container.empty_references.no_activity"]
    );
  }

  renderDeleteThreadButton = () => {
    const { destroyThread, router, i18n } = this.props;
    const button = (
      <Button
        icon="trash"
        className="red basic small"
        data-tooltip={
          i18n["messaging.messages_container.messageThread.confirmation.title"]
        }
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          destroyThread()
            .then(() => {
              setTimeout(() => {
                router.push("/messages");
              }, 500);
            })
            .catch(() => {
              handleClose();
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="messaging.messages_container.messageThread.confirmation.title"
        message="messaging.messages_container.messageThread.confirmation.body"
        buttons={buttons}
        button={button}
      />
    );
  };

  renderReadButton() {
    const { readLoading } = this.state;
    const {
      messageThread: { read }
    } = this.props;

    const readButton = (
      <Button
        basic={!read}
        size="small"
        icon={read ? "envelope open outline" : "envelope outline"}
        color={read ? "green" : "grey"}
        loading={readLoading}
        onClick={this.handleRead}
      />
    );
    return (
      <Popup trigger={readButton}>
        <FormattedMessage
          id={`messaging.message_container.buttons.tooltips.${
            read ? "mark_unread" : "mark_read"
          }`}
          defaultMessage="Als Ungelesen / Gelesen markieren"
        />
      </Popup>
    );
  }

  renderReplyButton() {
    const { replyTemplate } = this.props;
    const validProjectId = replyTemplate && replyTemplate.project_id > 0;
    const replyButton = (
      <Button
        basic
        size="small"
        icon="reply"
        color="grey"
        onClick={() => {
          if (validProjectId) {
            this.setState({
              isMessageFormOpen: true
            });
          }
        }}
      />
    );

    return (
      <Popup trigger={replyButton}>
        <FormattedMessage
          id={
            validProjectId
              ? "messaging.message_container.buttons.reply"
              : "messaging.message_container.buttons.tooltips.select_project"
          }
          defaultMessage="Antworten"
        />
      </Popup>
    );
  }

  render() {
    const {
      messageThread,
      i18n,
      replyTemplate,
      loadMessage,
      messageDraft,
      messageLabels
    } = this.props;
    const { isMessageFormOpen } = this.state;
    const participants = get(messageThread, "participants", []);

    if (messageThread) {
      const sortedMessages = sortBy(messageThread.messages, [
        message => message.sent_at || message.created_at
      ]);
      const messageItems = sortedMessages
        .map(message => {
          const animate = `#message-${message.id}` === window.location.hash;
          return (
            <Feed.Event
              key={message.id}
              id={`message-${message.id}`}
              className={animate ? "flash" : ""}
            >
              <Feed.Label
                icon={{
                  name: PARTICIPANT_ICON[message.sender.type],
                  circular: true
                }}
                size="large"
              />
              <Feed.Content>
                <Feed.Date>
                  {moment(message.sent_at || message.created_at).format(
                    "Do MMMM YYYY, H:mm:ss"
                  )}
                </Feed.Date>
                <Feed.Summary>
                  <Grid>
                    <Grid.Column width={9}>
                      <Feed.User>{message.sender.label}</Feed.User>
                      <Popup
                        wide="very"
                        trigger={
                          <Icon
                            name="caret down"
                            style={{ cursor: "pointer" }}
                          />
                        }
                      >
                        <Popup.Content>
                          {this.renderRecipientsPopup(message)}
                        </Popup.Content>
                      </Popup>
                    </Grid.Column>
                    <Grid.Column width={7} textAlign="right">
                      <HtmlMessageDialog message={message} />
                    </Grid.Column>
                  </Grid>
                </Feed.Summary>
                <Feed.Extra text>
                  <MessageBody message={message} />
                </Feed.Extra>
                <Feed.Extra>
                  {message.attachments.map(attachment => {
                    return (
                      <Button
                        key={attachment.id}
                        icon="file outline"
                        content={attachment.label}
                        as="a"
                        href={attachment.url}
                        target="_document"
                      />
                    );
                  })}
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          );
        })
        .reverse();

      return (
        <Grid data-component="MessageContainer">
          <Helmet title={i18n["messaging.message_container.title"]} />
          <Grid.Row>
            <Grid.Column width={4}>
              <Segment>
                <Link
                  id="messages"
                  name="messages"
                  className="item"
                  to="/messages"
                  title={i18n["messaging.message_container.menu.back"]}
                >
                  <Icon name="arrow left" style={{ marginRight: 0 }} />
                  {i18n["messaging.message_container.menu.back"]}
                </Link>
              </Segment>
              <Segment>
                <Header
                  dividing
                  sub
                  textAlign="left"
                  icon="address book outline"
                  content={i18n["messaging.message_container.menu.internal"]}
                  style={{ marginBottom: "7px" }}
                />
                <div className="margin bottom medium">
                  {this.displayUsers()}
                </div>
                <Observers
                  modelIdName="message_thread_id"
                  model={messageThread}
                />
              </Segment>

              <Segment>
                <Header
                  dividing
                  sub
                  textAlign="left"
                  icon="address book outline"
                  content={i18n["messaging.message_container.menu.external"]}
                  style={{ marginBottom: "7px" }}
                />
                {this.displayExternalParticipants()}
              </Segment>

              <Segment>
                <Header
                  color={replyTemplate.project_id > 0 ? "black" : "red"}
                  sub
                  textAlign="left"
                  icon="cubes"
                  content={i18n["messaging.message_container.menu.project"]}
                  style={{ marginBottom: "7px" }}
                />
                {this.displayProject()}
                <Header
                  sub
                  textAlign="left"
                  icon="cube"
                  content={i18n["messaging.message_container.menu.unit"]}
                  style={{ marginBottom: "7px" }}
                />
                {this.displayUnit()}
                <Header
                  sub
                  textAlign="left"
                  icon="play"
                  content={i18n["messaging.message_container.menu.activity"]}
                  style={{ marginBottom: "7px" }}
                />
                {this.displayActivity()}
              </Segment>
              <FeatureToggleActive featureToggleName="message_labels">
                <If condition={messageLabels.length > 0}>
                  <MessageLabelsSelect messageThread={messageThread} />
                </If>
              </FeatureToggleActive>
            </Grid.Column>

            <Grid.Column width={12}>
              <If condition={isMessageFormOpen}>
                <Segment>
                  <MessageDialog
                    key={replyTemplate.project_id}
                    closeMessageForm={() => {
                      this.setState({
                        isMessageFormOpen: false
                      });
                      loadMessage();
                    }}
                    reply={replyTemplate}
                    messageDraft={messageDraft}
                    participants={participants}
                  />
                </Segment>
              </If>
              <Segment>
                <Grid>
                  <Grid.Column width={12} verticalAlign="middle">
                    <Header
                      id="display_subject"
                      name="display_subject"
                      size="medium"
                      className="truncate"
                      content={
                        <MessageBodyV1
                          text={get(messageThread, "messages[0].subject", "")}
                          whiteSpace="unset"
                          messageDraft={messageDraft}
                        />
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right">
                    <IsVersionHistoryAccessible>
                      <VersionHistoryLink
                        id={messageThread.id}
                        type="MessageThread"
                      />
                    </IsVersionHistoryAccessible>
                    {this.renderReplyButton()}
                    {this.renderReadButton()}
                    <IsSystemAdmin>
                      {this.renderDeleteThreadButton()}
                    </IsSystemAdmin>
                  </Grid.Column>
                </Grid>
                <Feed>{messageItems}</Feed>
              </Segment>
              <ActivityCreator />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return <Loader active />;
  }
}

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    pageContent: state.pageContent,
    messageThread: getMessageThread(state),
    notifications: state.notifications,
    replyTemplate: getReplyTemplate(state),
    unitBuyers: getUnitBuyerNames(state),
    messageDraft: getMessageDraftForCurrentThread(state),
    messageLabels: getMessageLabels(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { id } = props.params;
  return {
    loadMessage: () => {
      const resource = new MessagesResource(dispatch);
      return resource.get(id);
    },
    readMessage: () => {
      const resource = new MessagesResource(dispatch);
      return resource.read(id);
    },
    unreadMessage: () => {
      const resource = new MessagesResource(dispatch);
      return resource.unread(id);
    },
    destroyThread: () => {
      const resource = new MessagesResource(dispatch);
      return resource.remove(id);
    },
    pollNotifications: () => {
      const resource = new AlertsResource(dispatch);
      resource.refresh();
    },
    updateNotifications: payload => {
      dispatch({
        type: UPDATE_NOTIFICATIONS,
        payload
      });
    },
    loadMasterdata: () => {
      const resource = new MasterDataResource(dispatch);
      return resource.fetchAll();
    },
    messagesResource: new MessagesResource(dispatch),
    loadDraft: () => {
      const resource = new MessageDraftsForMessagesResource(dispatch, id);
      return resource.fetchAll();
    },
    loadMessageLabels: () => {
      const resource = new MessageLabelsResource(dispatch);
      return resource.fetchAll();
    }
  };
};

MessageContainer.defaultProps = {
  messageThread: null,
  replyTemplate: {}
};

MessageContainer.propTypes = {
  // TODO create PropTypes for disabled lines
  // eslint-disable-next-line react/forbid-prop-types
  i18n: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  messageThread: object,
  readMessage: func.isRequired,
  loadMessage: func.isRequired,
  loadMasterdata: func.isRequired,
  replyTemplate: MessageReplyShape,
  // eslint-disable-next-line react/forbid-prop-types
  unitBuyers: object.isRequired,
  unreadMessage: func.isRequired,
  messagesResource: instanceOf(MessagesResource).isRequired,
  pollNotifications: func.isRequired,
  updateNotifications: func.isRequired,
  notifications: shape({
    messages: number.isRequired
  }).isRequired,
  destroyThread: func.isRequired,
  router: shape({
    push: func
  }).isRequired,
  loadDraft: func.isRequired,
  loadMessageLabels: func.isRequired,
  messageDraft: shape({
    id: number
  }),
  messageLabels: arrayOf(
    shape({
      name: string,
      color: string
    })
  )
};

MessageContainer.defaultProps = {
  messageDraft: null,
  messageLabels: []
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
