import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Label, Segment, Button, Form } from "semantic-ui-react";
import DeadlinesDialog from "./DeadlinesDialog";

class DeadlinesSegment extends React.Component {
  static propTypes = {
    activity: PropTypes.object,
    deadlines: PropTypes.array,
    states: PropTypes.object,
    actions: PropTypes.object,
    deadlinesResource: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { showMore: false };
  }

  render() {
    const {
      activity,
      deadlines,
      states,
      actions,
      deadlinesResource
    } = this.props;

    return (
      <Segment data-component="deadlinesSegment">
        <Label attached="top" basic>
          <DeadlinesDialog
            states={states}
            deadlines={deadlines}
            button={
              <Button
                className="right floated editDeadlines"
                size="tiny"
                basic
                icon="setting"
                type="button"
              />
            }
            activity={activity}
            actions={actions}
            deadlinesResource={deadlinesResource}
          />
          <FormattedMessage
            id="deadline.title.other"
            defaultText="deadline.title.other"
          />
        </Label>
        <Form className="margin bottom spacious" onSubmit={() => false}>
          {this.renderDeadlines()}
        </Form>
      </Segment>
    );
  }

  renderDeadlines() {
    const { deadlines } = this.props;
    const { showMore } = this.state;

    const uncompletedDeadlines = deadlines.filter(
      deadline => !deadline.completed_at
    );

    if (uncompletedDeadlines.length < 1) {
      return (
        <div className="margin top spacious">
          <FormattedMessage
            id="activity.deadline.noDeadlines"
            defaultText="activity.deadline.noDeadlines"
          />
        </div>
      );
    }
    if (uncompletedDeadlines.length === 1 || showMore) {
      return uncompletedDeadlines.map(::this.renderDeadline);
    }
    return [
      this.renderDeadline(uncompletedDeadlines[0]),
      <span
        key="show-more"
        className="show-more"
        onClick={() => {
          this.setState({ showMore: true });
        }}
      >
        <u>
          <FormattedMessage
            id="activity.deadline.showMore"
            defaultText="activity.deadline.showMore"
            values={{ amount: uncompletedDeadlines.length - 1 }}
          />
        </u>
      </span>
    ];
  }

  renderDeadline(item) {
    const { states } = this.props;
    switch (item.expiry_type) {
      // @todo: add other deadline types to switch-case
      case "status":
        return (
          <Form.Field key={`deadlineItem-${item.id}`}>
            <label>
              {item.expiry_ref &&
                states &&
                states[item.expiry_ref] &&
                states[item.expiry_ref].label}
            </label>
            <Form.Input
              transparent
              readOnly
              fluid
              icon="flag checkered"
              name="deadline"
              value={moment(item.expires_at).format("DD.MM.YYYY")}
              onChange={() => {}}
            />
          </Form.Field>
        );
    }
  }
}

export default DeadlinesSegment;
