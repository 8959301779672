const logger = store => next => action => {
  console.group(action.type);
  console.debug("dispatching", action);
  // console.debug(`dispatching ${JSON.stringify(action, null, 2)}`);
  const result = next(action);
  console.debug("next state", store.getState());
  // console.debug(`next pageContent ${JSON.stringify(store.getState().pageContent, null, 2)}`);
  console.groupEnd(action.type);
  return result;
};

export default logger;
