/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Container, Header, Button, Modal } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Input, Form, Select, Checkbox } from "formik-semantic-ui-react";
import { useIntl } from "react-intl";
import { CustomFieldsResource } from "builder_portal/actions/customFieldsActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import * as Yup from "yup";
import { CustomDataTypesResource } from "../../../actions/customDataTypesActions";
import Growl from "../../../actions/growlActions";

const CustomFieldsDialog = ({ model, trigger, customDataTypes }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const resource = new CustomFieldsResource(dispatch);
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    custom_data_type_id: Yup.string()
      .transform(v => (v === null ? "" : v))
      .required("customFields.validations.customTypeRequired")
  });

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = useCallback(
    values => {
      if (values.custom_data_type_id !== 2) {
        values.multiple = false;
      }

      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }
      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "customFields.states.saving"
          );
          handleClose();
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "customFields.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  const onDelete = () => {
    resource
      .remove(model.id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "customFields.states.saving"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "customFields.confirmations.changesNotSaved"
        );
      });
  };

  React.useEffect(() => {
    const customDataTypesResource = new CustomDataTypesResource(dispatch);
    customDataTypesResource.fetchAll();
  }, []);

  const customDataTypesOptions = React.useMemo(() => {
    const options = customDataTypes?.map(customDataType => ({
      key: customDataType.id,
      // text: `${customDataType.label} - ${customDataType.base_type}`,
      text: `${customDataType.label}`,
      value: customDataType.id
    }));
    return options;
  }, [customDataTypes]);

  const renderDeleteButton = isSubmitting => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="customFields.dialog.deleteTitle"
        message="customFields.dialog.deleteMessage"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Formik
      initialValues={
        model ?? {
          name: "",
          label: "",
          custom_data_type_id: null
        }
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, values, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            trigger={trigger}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            size="small"
            scrollable
          >
            <Header
              content={intl.formatMessage({ id: "customFields.title" })}
            />
            <Modal.Content>
              <Container>
                <Form>
                  <Input
                    fluid
                    label={intl.formatMessage({
                      id: "customFields.name"
                    })}
                    name="name"
                    errorPrompt
                  />
                  <Input
                    fluid
                    label={intl.formatMessage({
                      id: "customFields.label"
                    })}
                    name="label"
                  />
                  <Select
                    fluid
                    clearable
                    search
                    label={intl.formatMessage({
                      id: "customFields.subject"
                    })}
                    name="subject"
                    options={[
                      { key: "Product", text: "Produkt", value: "Product" }
                    ]}
                    errorPrompt
                  />
                  <Select
                    fluid
                    clearable
                    search
                    label={intl.formatMessage({
                      id: "customFields.customDataType"
                    })}
                    name="custom_data_type_id"
                    options={customDataTypesOptions}
                    errorPrompt
                  />

                  {values.custom_data_type_id === 2 && (
                    <Checkbox
                      label={intl.formatMessage({
                        id: "customFields.multiple"
                      })}
                      name="multiple"
                    />
                  )}
                  <Checkbox
                    label={intl.formatMessage({
                      id: "customFields.required"
                    })}
                    name="required"
                  />
                  <Input
                    label={intl.formatMessage({
                      id: "customFields.errorMessage"
                    })}
                    name="error_message"
                  />
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    content={intl.formatMessage({ id: "meta.actions.save" })}
                    loading={isSubmitting}
                    positive
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

CustomFieldsDialog.propTypes = {
  trigger: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object,
  customDataTypes: PropTypes.arrayOf(PropTypes.object).isRequired
};

CustomFieldsDialog.defaultProps = {
  model: null
};

export default CustomFieldsDialog;
