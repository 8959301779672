import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { FormattedMessage } from "react-intl";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import { get, last } from "lodash";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "roomBook.attributes.name.label",
      placeholder: "roomBook.attributes.name.placeholder",
      rule: "isRequired"
    },
    {
      id: "project_catalog_id",
      label: "roomBook.attributes.project_catalog_id.label",
      placeholder: "roomBook.attributes.project_catalog_id.placeholder",
      rule: "isRequired"
    },
    {
      id: "price_catalog_id",
      label: "roomBook.attributes.price_catalog_id.label",
      placeholder: "roomBook.attributes.price_catalog_id.placeholder",
      rule: "isRequired"
    }
  ]
});

class RoomBookForm extends Component {
  constructor(props) {
    super(props);

    const { catalogs } = props;

    this.state = {
      isLoading: false,
      model: {
        name: "",
        project_catalog_id: get(catalogs, [0, "id"]),
        price_catalog_id: last(get(catalogs, [0, "price_catalogs"]))?.id
      }
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    if (this.state.isLoading) {
      return false;
    }
    this.setState({ ...this.state, isLoading: true });
    this.props.save({ ...this.state, ...values });
  }

  getProjectCatalogById(catalogId) {
    const { catalogs } = this.props;
    return catalogs.find(c => {
      return c.id === catalogId;
    });
  }

  getLastPriceCatalog(catalogId) {
    return last(this.getProjectCatalogById(catalogId).price_catalogs);
  }

  renderCatalogSelect = field => {
    const { props, error, message, dirty } = field;

    return (
      <Field
        component="Select"
        props={props}
        error={error}
        message={message}
        dirty={dirty}
      />
    );
  };

  render() {
    const { isLoading, model } = this.state;
    const { i18n, catalogs } = this.props;

    const selectedCatalog = this.getProjectCatalogById(
      model.project_catalog_id
    );
    const priceCatalogs = get(selectedCatalog, "price_catalogs", []);

    const form = FormFactory.create(model, i18n, {
      onChange: data => {
        const { model: oldModel } = this.state;
        if (oldModel.project_catalog_id !== data.project_catalog_id) {
          const priceCatalogId = this.getLastPriceCatalog(
            data.project_catalog_id
          ).id;
          this.setState({
            model: {
              ...data,
              price_catalog_id: priceCatalogId
            }
          });
        } else {
          this.setState({ model: data });
        }
      }
    });

    form.fields.project_catalog_id.props.options = (catalogs || []).map(c => ({
      key: c.id,
      value: c.id,
      text: c.name
    }));

    form.fields.price_catalog_id.props.options = priceCatalogs.map(c => ({
      key: c.id,
      value: c.id,
      text: c.name
    }));

    return (
      <Form
        id="roombook_template"
        onSubmit={form.handleSubmit(this.onSubmit)}
        data-component="roomBookForm"
      >
        <Form.Field>
          <Field component="Input" {...form.fields.name} />
        </Form.Field>
        <Form.Field>
          {this.renderCatalogSelect(form.fields.project_catalog_id)}
        </Form.Field>
        {priceCatalogs.length > 1 && (
          <Form.Field>
            {this.renderCatalogSelect(form.fields.price_catalog_id)}
          </Form.Field>
        )}

        <Form.Field>
          <Button
            type="submit"
            color="green"
            loading={isLoading}
            id={(model.id && "roomBook-edit") || "roomBook-new"}
          >
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

RoomBookForm.propTypes = {
  save: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape)
};

RoomBookForm.defaultProps = {
  catalogs: []
};

export default RoomBookForm;
