import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

class ProjectUnitProspectsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "unit_prospect",
      `/api/v1/projects/${projectId}/unit_prospects`
    );
  }

  accept(id, data) {
    return apiRequest
      .patch(`${this.url}/${id}/accept`, this.dispatch, data)
      .then(this.propagateUpdate.bind(this));
  }

  reject(id, data) {
    return apiRequest
      .patch(`${this.url}/${id}/reject`, this.dispatch, data)
      .then(this.propagateUpdate.bind(this));
  }
}

export default ProjectUnitProspectsResource;
