import {
  number,
  arrayOf,
  bool,
  instanceOf,
  oneOfType,
  shape,
  string
} from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { sortBy } from "lodash";
import {
  Button,
  Grid,
  Header,
  Icon,
  List,
  Segment,
  Input,
  Dropdown
} from "semantic-ui-react";
import { I18nShape } from "shared/shapes/i18n.shape";
import { SectionShape } from "shared/shapes/section.shape";
import ProjectBuyersResource from "builder_portal/actions/buyerActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import XLSX from "xlsx";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import { UnitShape } from "shared/shapes/unit.shape";
import BuyerDialog from "./BuyerDialog";
import BuyerInvitationStatus from "./BuyerInvitationStatus";
import VersionHistoryLink from "../../../shared/components/elements/VersionHistoryLink";
import BuyerUploadModal from "./BuyerUploadModal";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

class Buyers extends Component {
  static addBuyerButton = (
    <Button
      id="buyer-new"
      icon="plus"
      basic
      content={<FormattedMessage id="meta.actions.add" />}
    />
  );

  constructor(props) {
    super(props);

    this.state = {
      initialLoadingIndicator: false,
      filterBuyer: "",
      statusFilter: "all",
      sectionFilter: "all"
    };
  }

  componentDidMount() {
    const { actions, projectId } = this.props;
    this.loadData();
    if (actions.projectResource)
      actions.projectResource.fetchStructure(projectId);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  statusOptions = () => {
    const { i18n } = this.props;
    return [
      { key: "all", value: "all", text: i18n["buyer.options.all"] },
      {
        key: "not_invited",
        value: "not_invited",
        text: i18n["buyer.options.not_invited"]
      },
      {
        key: "pending_invitation",
        value: "pending_invitation",
        text: i18n["buyer.options.pending_invitation"]
      },
      {
        key: "accepted_invitation",
        value: "accepted_invitation",
        text: i18n["buyer.options.accepted_invitation"]
      }
    ];
  };

  sectionOptions = () => {
    const { i18n, sections } = this.props;
    return [
      { key: "all", value: "all", text: i18n["buyer.section_options.all"] }
    ].concat(
      sections.map(section => ({
        key: section.id,
        value: section.id,
        text: section.name
      }))
    );
  };

  loadData() {
    const { buyersResource } = this.props.actions;
    this.setState({ initialLoadingIndicator: true });
    buyersResource.getBuyerappStatus();
    return buyersResource
      .fetchAll()
      .then(() => {
        return this.setState({ initialLoadingIndicator: false });
      })
      .catch(() => {
        return this.setState({ initialLoadingIndicator: false });
      });
  }

  renderUnitsButton = buyer => {
    const { units, projectId } = this.props;
    const bUnits = units.filter(unit => buyer.unit_ids.indexOf(unit.id) > -1);
    if (bUnits.length === 0) {
      return null;
    }
    if (bUnits.length === 1) {
      return (
        <Button
          basic
          icon
          color="grey"
          href={`/projects/${projectId}/units/${bUnits[0].slug}/room-book`}
          target="_blank"
          size="small"
        >
          <div
            className="flex justify-content-space-between align-items-center"
            style={{ gap: "0.5rem" }}
          >
            <FormattedMessage id="unit.title.singular" />
            <Icon name="external alternate" />
          </div>
        </Button>
      );
    }
    return (
      <Dropdown
        options={bUnits.map(unit => ({
          key: unit.id,
          value: unit.id,
          icon: "external alternate",
          content: (
            <a
              href={`/projects/${projectId}/units/${unit.slug}/room-book`}
              target="_blank"
              rel="noreferrer"
            >
              {unit.display_name}
            </a>
          )
        }))}
        icon={null}
        trigger={
          <Button basic icon color="grey" size="small">
            <div
              className="flex justify-content-space-between align-items-center"
              style={{ gap: "0.25rem" }}
            >
              <FormattedMessage id="unit.title.other" />
              <Icon name="dropdown" />
            </div>
          </Button>
        }
      />
    );
  };

  renderChildren() {
    const { i18n, featureToggles, actions, buyers } = this.props;
    const {
      initialLoadingIndicator,
      filterBuyer,
      statusFilter,
      sectionFilter
    } = this.state;
    const fieldsToFilter = [
      "first_name",
      "last_name",
      "display_name",
      "email",
      "phone",
      "address_street",
      "address_town"
    ];

    let filteredBuyers =
      filterBuyer.trim().length > 0
        ? buyers.filter(x =>
            fieldsToFilter.some(
              field =>
                x[field]
                  ?.toLowerCase()
                  ?.indexOf(filterBuyer.trim().toLowerCase()) > -1
            )
          )
        : buyers;

    switch (statusFilter) {
      case "not_invited":
        filteredBuyers = filteredBuyers.filter(buyer => !buyer.invited_at);
        break;
      case "pending_invitation":
        filteredBuyers = filteredBuyers.filter(
          buyer => !!buyer.invited_at && !buyer.portal_registered
        );
        break;
      case "accepted_invitation":
        filteredBuyers = filteredBuyers.filter(
          buyer => !!buyer.portal_registered
        );
        break;
      default:
    }

    if (sectionFilter !== "all") {
      filteredBuyers = filteredBuyers.filter(
        buyer => buyer.section_ids.indexOf(sectionFilter) > -1
      );
    }

    if (filteredBuyers.length) {
      const sortedBuyers = sortBy(filteredBuyers, "last_name");

      return sortedBuyers.map(buyer => {
        const inviteButton = (
          <BuyerInvitationStatus
            enabled={featureToggles.buyer_portal}
            buyers={[buyer]}
            i18n={i18n}
            actions={actions}
            onUpdate={this.loadData.bind(this)}
          />
        );
        const buttonText = (
          <a role="button">
            <span data-attr="label">{buyer.label}</span>
            <Icon color="grey" name="setting" />
          </a>
        );
        const userReady = buyer.email && buyer.unit_ids.length > 0;
        // @todo: use Grid instead of floating divs
        return (
          <Segment key={buyer.id} clearing data-model="buyer">
            <Grid>
              <Grid.Column width="7">
                <Header
                  as="h5"
                  size="medium"
                  data-attr="name"
                  className="margin top none"
                >
                  {this.renderBuyerDialogModal(buyer, buttonText)}
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink
                      id={buyer.id}
                      type="Buyer"
                      size="normal"
                    />
                  </IsVersionHistoryAccessible>
                </Header>
                <List horizontal>
                  <List.Item>
                    <Icon name="mail outline" />
                    <span data-attr="email">{buyer.email}</span>
                  </List.Item>
                  {buyer.phone && (
                    <List.Item>
                      <Icon name="call" />
                      <span data-attr="phone">{buyer.phone}</span>
                    </List.Item>
                  )}
                </List>
              </Grid.Column>
              <Grid.Column width="1" textAlign="right" />
              <Grid.Column width="4" textAlign="right" verticalAlign="middle">
                {this.renderUnitsButton(buyer)}
              </Grid.Column>
              <Grid.Column width="4" textAlign="right" verticalAlign="middle">
                {userReady && inviteButton}
              </Grid.Column>
            </Grid>
          </Segment>
        );
      });
    }
    return (
      <Segment loading={initialLoadingIndicator}>
        <FormattedMessage
          id="meta.message.noResults"
          defaultMessage="meta.message.noResults"
        />
      </Segment>
    );
  }

  renderBuyerDialogModal(model, button) {
    const { i18n, actions, projectId } = this.props;
    return (
      <BuyerDialog
        buyer={model}
        i18n={i18n}
        actions={actions}
        projectId={projectId}
        button={button}
      />
    );
  }

  renderCreateButton() {
    return this.renderBuyerDialogModal(undefined, Buyers.addBuyerButton);
  }

  render() {
    const { i18n, buyers } = this.props;
    const {
      initialLoadingIndicator,
      filterBuyer,
      statusFilter,
      sectionFilter
    } = this.state;

    const csvDataFor = rows => {
      return (
        rows
          .map(item => {
            return {
              [i18n["buyer.attributes.salutation.label"]]:
                i18n[`meta.form_of_address.salutation.${item.salutation}`],
              [i18n["buyer.attributes.title.label"]]:
                i18n[`meta.form_of_address.title.${item.title}`],
              [i18n["buyer.attributes.firstName.label"]]: item.first_name,
              [i18n["buyer.attributes.lastName.label"]]: item.last_name,
              [i18n["buyer.attributes.company.label"]]: item.company,
              [i18n["buyer.attributes.address_street.label"]]:
                item.address_street,
              [i18n["buyer.attributes.address_number.label"]]:
                item.address_number,
              [i18n["buyer.attributes.address_postcode.label"]]:
                item.address_postcode,
              [i18n["buyer.attributes.address_town.label"]]: item.address_town,
              [i18n["buyer.attributes.email.label"]]: item.email,
              [i18n["buyer.attributes.phone.label"]]: item.phone,
              [i18n["unit.singular"]]: item.unit_label
            };
          })
          .flat() || []
      );
    };

    const handleDownload = data => {
      const workSheetName = i18n["buyer.title.one"];
      const worksheet = XLSX.utils.json_to_sheet(csvDataFor(data));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, workSheetName);
      XLSX.writeFile(workbook, `${i18n["buyer.title.one"]}.xlsx`, {});
    };

    return (
      <div>
        <div data-component="buyers">
          <Helmet title={i18n["buyer.title.other"]} />
          <Grid stackable className="margin bottom spacious">
            <Grid.Column width="7">
              <Header size="large">
                <FormattedMessage
                  id="buyer.title.other"
                  defaultMessage="buyer.title.other"
                />
              </Header>
            </Grid.Column>
            <Grid.Column width="9" textAlign="right" floated="right">
              <Button
                id="download-buyers"
                icon
                basic
                data-tooltip={i18n["buyer.actions.download"]}
                onClick={() => handleDownload(buyers)}
                disabled={initialLoadingIndicator}
              >
                <Icon name="cloud download" />
              </Button>

              <BuyerUploadModal
                button={
                  <Button
                    id="upload-buyers"
                    icon
                    basic
                    data-tooltip={i18n["buyer.actions.upload"]}
                    disabled={initialLoadingIndicator}
                  >
                    <Icon name="cloud upload" />
                  </Button>
                }
              />

              {this.renderCreateButton()}
            </Grid.Column>
          </Grid>
          <Segment data-component="buyersList">
            <Grid>
              <Grid.Column width={16}>
                <div className="flex flex-gap-2">
                  <Input
                    name="filterBuyer"
                    value={filterBuyer}
                    onChange={this.handleChange}
                    icon="search"
                    autoFocus
                    autoComplete="off"
                    placeholder={i18n["account.selector.search.placeholder"]}
                    style={{ minWidth: "55%", width: "100%" }}
                  />
                  <FeatureToggleActive featureToggleName="buyer_portal">
                    <Dropdown
                      selection
                      fluid
                      defaultValue={statusFilter}
                      options={this.statusOptions()}
                      onChange={(_, { value }) =>
                        this.setState({ statusFilter: value })
                      }
                      style={{ minWidth: "20%", maxWidth: "25%" }}
                    />
                  </FeatureToggleActive>
                  <Dropdown
                    selection
                    fluid
                    defaultValue={sectionFilter}
                    options={this.sectionOptions()}
                    onChange={(_, { value }) =>
                      this.setState({ sectionFilter: value })
                    }
                    style={{ minWidth: "20%", maxWidth: "25%" }}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment.Group>{this.renderChildren()}</Segment.Group>
        </div>
      </div>
    );
  }
}
Buyers.propTypes = {
  i18n: I18nShape.isRequired,
  buyers: arrayOf(shape({ last_name: string })).isRequired,
  sections: arrayOf(SectionShape),
  actions: shape({
    buyersResource: instanceOf(ProjectBuyersResource),
    projectResource: instanceOf(ProjectsResource)
  }).isRequired,
  featureToggles: shape({ buyer_portal: bool }).isRequired,
  projectId: oneOfType([string, number]).isRequired,
  units: arrayOf(UnitShape).isRequired
};

Buyers.defaultProps = {
  sections: []
};

export default Buyers;
