/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Header, List, Segment, Grid, Button, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import UpgradeBundleGroupsResource from "builder_portal/actions/upgradeBundleGroupsActions";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import UpgradeBundleGroupDialog from "./UpgradeBundleGroupDialog";
import UpgradeBundlesList from "./UpgradeBundlesList";
import UpgradeBundleDialog from "./UpgradeBundleDialog";
import { UpgradeBundlesLoaderContext } from "./UpgradeBundlesLoader";

const UpgradeBundleGroups = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    upgradeBundleGroups,
    projectId,
    isLoading,
    openedBundles,
    setOpenedBundles,
    loadData
  } = useContext(UpgradeBundlesLoaderContext);

  const toggleBundles = groupId => {
    const index = openedBundles.indexOf(groupId);
    if (index === -1) {
      setOpenedBundles(openedBundles.concat(groupId));
    } else {
      const temp = [...openedBundles];
      temp.splice(index, 1);
      setOpenedBundles(temp);
    }
  };

  const deleteUpgradeBundle = id => {
    return new UpgradeBundleGroupsResource(dispatch, projectId)
      .remove(id)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderDeleteButton = model => {
    const button = (
      <a
        role="button"
        id="upgrade_bundle-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          deleteUpgradeBundle(model.id).catch(() => {
            handleClose();
          });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.upgradeBundles.removeConfirmation.title"
        message="roomBook.upgradeBundles.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const renderMode = mode => {
    if (mode === "SINGLE_SELECT")
      return (
        <FormattedMessage id="roomBook.upgreadeBundleGroups.dialog.singleSelect" />
      );
    return (
      <FormattedMessage id="roomBook.upgreadeBundleGroups.dialog.multiSelect" />
    );
  };

  const handleDuplicate = id => {
    return new UpgradeBundleGroupsResource(dispatch, projectId)
      .save({ prototype_group_id: id, name: undefined })
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderUpgradeBundleGroup = item => {
    return (
      <Segment key={item.id} id={`upgrade_bundles-${item.id}`}>
        <Grid stackable verticalAlign="middle">
          <Grid.Column width={1}>
            <If condition={openedBundles.indexOf(item.id) > -1}>
              <Button
                icon="folder open outline"
                size="tiny"
                data-tooltip="Hide upgrade bundles"
                basic
                onClick={() => toggleBundles(item.id)}
              />
            </If>
            <If condition={openedBundles.indexOf(item.id) === -1}>
              <Button
                icon="folder outline"
                size="tiny"
                basic
                onClick={() => toggleBundles(item.id)}
                data-tooltip="Show upgrade bundles"
              />
            </If>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as="h5">{item.name}</Header>
          </Grid.Column>
          <Grid.Column width={4}>{item.title}</Grid.Column>
          <Grid.Column width={3}>
            <div>{renderMode(item.mode)}</div>
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            <VersionHistoryLink
              id={item.id}
              type="UpgradeBundleGroup"
              style={{
                fontSize: "1em"
              }}
            />
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={1}
            className="mobilePaddedColumn"
          >
            <UpgradeBundleGroupDialog
              model={item}
              button={
                <a
                  role="button"
                  id="upgrade_bundle-edit"
                  loading={isLoading}
                  disabled={isLoading}
                  data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                >
                  <Icon name="setting" color="grey" />
                </a>
              }
            />
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            <a
              role="button"
              id="upgrade_bundle-duplicate"
              loading={isLoading}
              disabled={isLoading}
              data-tooltip={intl.formatMessage({
                id: "meta.actions.duplicate"
              })}
              onClick={() => handleDuplicate(item.id)}
            >
              <Icon name="copy" color="grey" />
            </a>
          </Grid.Column>
          <Grid.Column
            textAlign="right"
            width={1}
            className="mobilePaddedColumn"
          >
            {renderDeleteButton(item)}
          </Grid.Column>
        </Grid>
        <If
          condition={openedBundles.indexOf(item.id) > -1}
          styles={{ display: "block" }}
        >
          <UpgradeBundlesList groupId={item.id} />
          <UpgradeBundleDialog
            model={{
              name: "",
              title: "",
              description: "",
              image: null,
              upgrade_bundle_group_id: item.id
            }}
            button={
              <Button
                id="upgrade_bundle-new"
                fluid
                icon="plus"
                content={intl.formatMessage({
                  id: "roomBook.upgradeBundles.add"
                })}
                loading={isLoading}
                disabled={isLoading}
                style={{ marginTop: "10px" }}
              />
            }
          />
        </If>
      </Segment>
    );
  };

  const renderUpgradeBundleGroups = () => {
    if (!upgradeBundleGroups.length && !isLoading) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.upgreadeBundleGroups.notFound"
            defaultMessage="Es liegen keine Upgrade Pakete zu diesem Projekt vor"
          />
        </Header>
      );
    }

    return upgradeBundleGroups.map(renderUpgradeBundleGroup);
  };

  return (
    <>
      <Grid
        stackable
        data-component="upgrade_bundle_groups"
        className="margin bottom medium"
      >
        <Grid.Column width="12">
          <Header size="large" style={{ marginBottom: "1em" }}>
            <FormattedMessage
              id="roomBook.upgradeBundles.title"
              defaultMessage="roomBook.upgradeBundles.title"
            />
          </Header>
          <p className="description">
            <FormattedMessage
              id="roomBook.upgradeBundles.description"
              defaultMessage="roomBook.upgradeBundles.description"
            />
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right" width="4" verticalAlign="top">
          <UpgradeBundleGroupDialog
            model={{ name: "", title: "", mode: "" }}
            button={
              <Button
                id="upgrade-bundle-group-new"
                icon="plus"
                fluid
                content={intl.formatMessage({
                  id: "roomBook.upgreadeBundleGroups.add"
                })}
                loading={isLoading}
                disabled={isLoading}
              />
            }
          />
        </Grid.Column>
      </Grid>

      <List divided size="big" data-component="upgrade_bundle_items">
        {renderUpgradeBundleGroups()}
      </List>
    </>
  );
};
export default UpgradeBundleGroups;
