import React from "react";
import { FormattedMessage } from "react-intl";
import { Header } from "semantic-ui-react";

class App extends React.Component {
  render() {
    return (
      <div data-component="dashboard">
        <Header size="large">404</Header>
        <Header size="large">
          <FormattedMessage
            id="error.pageNotFound.title"
            defaultText="error.pageNotFound.title"
          />
        </Header>
      </div>
    );
  }
}

export default App;
