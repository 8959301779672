import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { Grid, Loader } from "semantic-ui-react";
import RoomBook from "builder_portal/components/roomBook/RoomBook";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import RoomBookCatalogResource from "builder_portal/actions/roomBookCatalogActions";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";
import { getAccount } from "shared/selectors";
import { getRoomBook } from "builder_portal/selectors";
import { AccountShape, I18nShape, RoomBookShape } from "shared/shapes";
import {
  UnitFeatureGroupsResource,
  UnitFeatureResource
} from "builder_portal/actions/unitFeatureGroupsActions";
import { ProjectsResource } from "../../actions/projectActions";
import UpgradeBundlesResource from "../../actions/upgradeBundlesActions";
import ProjectRoomsLoader from "../projectRooms/ProjectRoomsLoader";
import { If } from "../../../shared/components/elements/Conditions";

class RoomBookTemplateContainer extends React.Component {
  componentDidMount() {
    const { loadData } = this.props;
    loadData();
  }

  componentDidUpdate(prevProps) {
    const { id, loadData } = this.props;
    if (prevProps.id !== id) {
      loadData();
    }
  }

  render() {
    const { i18n, id, projectId, context, roomBook } = this.props;
    return (
      <>
        <ProjectRoomsLoader projectId={projectId} />
        <If condition={!roomBook}>
          <Grid stackable data-component="roomBookItem">
            <Grid.Column width="16">
              <Loader active />
            </Grid.Column>
          </Grid>
        </If>
        <If condition={!!roomBook}>
          <RoomBook
            id={id}
            roomBook={roomBook}
            projectId={projectId}
            i18n={i18n}
            context={context}
            mode="template"
          />
        </If>
      </>
    );
  }
}

RoomBookTemplateContainer.propTypes = {
  id: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  i18n: I18nShape.isRequired,
  roomBook: RoomBookShape,
  loadData: PropTypes.func.isRequired,
  context: PropTypes.shape({
    account: AccountShape.isRequired
  }).isRequired
};

RoomBookTemplateContainer.defaultProps = {
  roomBook: null
};

const getRoomBookContext = createSelector([getAccount], account => {
  return {
    account
  };
});

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state, props) => {
  const id = parseInt(props.params.id, 10) || undefined;
  const projectId = props.params.projectId || undefined;

  const roomBook = getRoomBook(state);
  const context = getRoomBookContext(state);

  return {
    i18n: state.i18n,
    id,
    projectId,
    roomBook,
    context,
    mode: "template"
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const id = parseInt(props.params.id, 10) || undefined;
  const projectId = props.params.projectId || undefined;

  return {
    loadData: () => {
      new RoomBookCatalogResource(dispatch, id).get();
      const roomBookResource = new ProjectRoomBooksResource(
        dispatch,
        projectId
      );
      roomBookResource.get(id, true).catch(silentHandleApiRequestErrors);
      roomBookResource
        .descendantsStatus(id)
        .catch(silentHandleApiRequestErrors);
      new UpgradeBundlesResource(dispatch, projectId)
        .fetchAll()
        .catch(silentHandleApiRequestErrors);
      new ProjectsResource(dispatch).get(projectId);
      new UnitFeatureGroupsResource(dispatch, projectId).fetchAll();
      new UnitFeatureResource(dispatch, projectId).fetchAll();
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomBookTemplateContainer);
