import React, { useState, useRef } from "react";
import { Modal, Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { node, string, func, object } from "prop-types";
import { lowerFirst } from "lodash";

import "./styles.scss";

const GenericElementModalWrapper = ({
  children,
  button,
  type,
  value,
  onChange
}) => {
  const [isOpen, setOpen] = useState(false);
  const previousValue = useRef(value);
  const handleOpen = () => setOpen(true);
  const handleApply = () => {
    setOpen(false);
    previousValue.current = value;
  };
  const handleCancel = () => {
    onChange(previousValue.current);
    setOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleCancel}
      trigger={button}
      closeIcon
    >
      <Modal.Header>
        <FormattedMessage
          id={`configuratorDesign.dashboard.${lowerFirst(type)}.type`}
        />
      </Modal.Header>
      <Modal.Content className="full-width">{children}</Modal.Content>
      <Modal.Actions>
        <Button basic id="cancel" onClick={handleCancel}>
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
        <Button primary id="apply" onClick={handleApply}>
          <FormattedMessage id="meta.actions.apply" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

GenericElementModalWrapper.propTypes = {
  children: node.isRequired,
  button: node.isRequired,
  type: string.isRequired,
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: object
};

GenericElementModalWrapper.defaultProps = {
  value: {}
};

export default GenericElementModalWrapper;
