import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Form, Input, Select } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import Growl from "builder_portal/actions/growlActions";
import { ProjectSetupsResource } from "builder_portal/actions/projectSetupActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { AccountUsersResource } from "builder_portal/actions/accountActions";
import { getUsers } from "shared/selectors/account";
import { node } from "prop-types";

const ProjectSetupDialog = ({ button }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const users = useSelector(getUsers);

  useEffect(() => {
    new AccountUsersResource(dispatch).fetchAll();
  }, []);

  const userOptions = useMemo(() => {
    return users
      .filter(user => user.group === "admin")
      .map(user => {
        return {
          key: user.id,
          value: user.id,
          text: `${user.first_name} ${user.last_name}`
        };
      });
  }, [users]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = values => {
    new ProjectSetupsResource(dispatch)
      .save(values)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        new ProjectsResource(dispatch).fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const validationScheme = Yup.object({
    project_name: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
      .min(
        5,
        intl.formatMessage(
          {
            id: "meta.validation.minCharacters"
          },
          { min: 5 }
        )
      ),
    customer_success_manager: Yup.number().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  return (
    <Formik
      initialValues={{ project_name: "", customer_success_manager: "" }}
      onSubmit={onSubmit}
      validationSchema={validationScheme}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Modal
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={button}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id="project.project_setup.title"
                default="Projekt anlegen"
              />
            </Modal.Header>

            <Modal.Content>
              <Form>
                <Input
                  errorPrompt
                  label={intl.formatMessage({
                    id: "project.project_setup.dialog.project_name.label"
                  })}
                  name="project_name"
                />

                <Select
                  fluid
                  selection
                  clearable
                  errorPrompt
                  name="customer_success_manager"
                  options={userOptions}
                  label={intl.formatMessage({
                    id:
                      "project.project_setup.dialog.customer_success_manager.label"
                  })}
                />
              </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button basic onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                type="submit"
                primary
                data-form="profit-margin"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

ProjectSetupDialog.propTypes = {
  button: node.isRequired
};

export default ProjectSetupDialog;
