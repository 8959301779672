import React from "react";
import { If } from "shared/components/elements/Conditions";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const MessageBodyV1 = ({ text, whiteSpace, messageDraft }) => {
  return (
    <div className="flex">
      <If condition={!!messageDraft}>
        <Icon name="edit outline" />
      </If>
      {/* eslint-disable-next-line react/no-danger */}
      <div style={{ whiteSpace }} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

MessageBodyV1.propTypes = {
  text: PropTypes.string.isRequired,
  whiteSpace: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  messageDraft: PropTypes.object
};

MessageBodyV1.defaultProps = {
  whiteSpace: "pre-line",
  messageDraft: undefined
};

export default MessageBodyV1;
