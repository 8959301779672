import React, { useState, useEffect } from "react";
import {
  Table,
  Icon,
  Header,
  Button,
  Segment,
  Loader
} from "semantic-ui-react";
import { CustomFieldsResource } from "builder_portal/actions/customFieldsActions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getCustomFields } from "shared/selectors/customFields";
import { getCustomDataTypes } from "shared/selectors/customDataTypes";
import CustomFieldsDialog from "./CreateCustomFieldDialog";
import "./customFields.scss";

const CustomFields = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const customFieldsResource = new CustomFieldsResource(dispatch);

  const customFields =
    useSelector(getCustomFields)?.sort((a, b) => a.position - b.position) || [];
  const customDataTypes = useSelector(getCustomDataTypes) || [];

  useEffect(() => {
    setLoading(true);
    customFieldsResource.fetchAll().then(() => {
      setLoading(false);
    });
  }, []);

  const getDataType = type => {
    // find name of custom data type by id
    const customDataType = customDataTypes.find(item => item.id === type);
    return customDataType?.name;
  };

  const moveRow = (index, direction) => {
    // update the position of the row in the customFields array
    const newCustomFields = [...customFields];
    const temp = newCustomFields[index];
    newCustomFields[index] = newCustomFields[index + direction];
    newCustomFields[index + direction] = temp;

    customFieldsResource
      .save({
        id: newCustomFields[index + direction].id,
        position: newCustomFields[index].position
      })
      .then(() => {
        customFieldsResource.fetchAll();
      });
  };

  return (
    <Segment.Group data-component="customFields">
      <Segment.Group horizontal>
        <Segment>
          <Header>
            <FormattedMessage
              id="customFields.title"
              defaultMessage="Custom Fields"
            />
          </Header>
        </Segment>
        <CustomFieldsDialog
          trigger={<Button icon="plus" floated="right" id="new-webhook" />}
          customDataTypes={customDataTypes}
        />
      </Segment.Group>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <Table attached striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                <FormattedMessage
                  id="customFields.position"
                  defaultMessage="Position"
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>
                <FormattedMessage
                  id="customFields.name"
                  defaultMessage="Name"
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>
                <FormattedMessage
                  id="customFields.type"
                  defaultMessage="Type"
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                <FormattedMessage
                  id="customFields.action"
                  defaultMessage="Action"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {customFields.length > 0 ? (
              customFields.map((customField, index) => (
                <Table.Row key={customField.id}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{customField.name}</Table.Cell>
                  <Table.Cell>
                    {getDataType(customField.custom_data_type_id)}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center"
                      }}
                    >
                      <Icon
                        style={{
                          cursor: "pointer"
                        }}
                        name="arrow up"
                        disabled={index === 0}
                        onClick={() => moveRow(index, -1)}
                      />

                      <Icon
                        style={{
                          cursor: "pointer"
                        }}
                        name="arrow down"
                        disabled={index === customFields.length - 1}
                        onClick={() => moveRow(index, 1)}
                      />
                      {/* add edit icon to open dialog */}
                      <CustomFieldsDialog
                        trigger={
                          <Icon
                            name="setting"
                            style={{
                              cursor: "pointer"
                            }}
                          />
                        }
                        model={customField}
                        customDataTypes={customDataTypes}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <div style={{ textAlign: "center" }}>
                    <Icon name="folder open" size="massive" />
                    <Header as="h4">
                      <FormattedMessage
                        id="customFields.noCustomFieldsFound"
                        defaultMessage="No custom fields found"
                      />
                    </Header>
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
    </Segment.Group>
  );
};

export default CustomFields;
