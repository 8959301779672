import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "semantic-ui-react";
import { loadingProps } from "shared/helpers/propHelper";

const BatchEditNextButton = props => {
  const {
    page,
    operation,
    newDefaultQuantity,
    newQuantity,
    newProductId,
    newDefaultProductId,
    nextButtonBehaviorFn,
    loading,
    disabled,
    newDefaultQuantityEnabled,
    newQuantityEnabled
  } = props;
  const edit =
    (newDefaultQuantity >= 0 && newDefaultQuantityEnabled) ||
    (newQuantity >= 0 && newQuantityEnabled) ||
    newDefaultProductId ||
    newProductId;
  const id =
    page === "delete" || page === "confirm_edit" ? "confirm" : "continue";

  return (
    <Button
      color={
        page === "select_operation" && operation
          ? "green"
          : page === "delete"
          ? "red"
          : page.includes("edit") && edit
          ? "green"
          : null
      }
      onClick={() => nextButtonBehaviorFn()}
      id={id}
      {...loadingProps(loading)}
      disabled={loading || disabled}
    >
      <FormattedMessage id={`batchEdit.buttons.${page}`} />
    </Button>
  );
};

BatchEditNextButton.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.string,
  operation: PropTypes.string,
  newDefaultQuantity: PropTypes.number,
  newQuantity: PropTypes.number,
  newProductId: PropTypes.number,
  nextButtonBehaviorFn: PropTypes.func,
  newDefaultProductId: PropTypes.number,
  newDefaultQuantityEnabled: PropTypes.bool.isRequired,
  newQuantityEnabled: PropTypes.bool.isRequired
};

export default BatchEditNextButton;
