import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Icon, Header, Modal } from "semantic-ui-react";
import "./roomBookSortOrderDialog.scss";

class RoomBookSortOrderDialog extends Component {
  static propTypes = {
    ctrl: PropTypes.object,
    type: PropTypes.string,
    element: PropTypes.object,
    roomBook: PropTypes.object,
    button: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { button } = this.props;

    return (
      <Modal
        data-component="roomBookSortOrderDialog"
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="roomBook.actions.sortOrder"
            defaultMessage="roomBook.actions.sortOrder"
          />
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width="16">{this.renderControl()}</Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }

  renderControl() {
    const { roomBook, type, element } = this.props;
    const isPrimarySection = type === "primarySection";
    const isSecondarySection = type === "secondarySection";
    const isLineItem = type === "lineItem";
    return (
      <div className="control">
        {!isPrimarySection && (
          <Header size="small" className="sectionControlsHeader">
            <FormattedMessage
              id="roomBook.actions.moveToOtherSection"
              defaultMessage="roomBook.actions.moveToOtherSection"
              values={{ title: element.title }}
            />
          </Header>
        )}
        {!isPrimarySection &&
          roomBook.sections.map(primarySection => {
            return (
              <div
                className={`${!isLineItem ? "section selectable" : "section"}`}
                key={primarySection.id}
              >
                {this.renderPrimarySectionSelector(primarySection, isLineItem)}

                {!isSecondarySection && (
                  <div>
                    {primarySection.sections.map(secondarySection => {
                      return (
                        <div
                          className={`${
                            isLineItem
                              ? "section indent selectable"
                              : "section indent"
                          }`}
                          key={secondarySection.id}
                          onClick={() => this.handleClick(secondarySection)}
                        >
                          <Icon name="folder outline" />
                          {secondarySection.display_number}{" "}
                          {secondarySection.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }

  renderPrimarySectionSelector(section, isLineItem) {
    const func = () => {
      if (!isLineItem) {
        this.handleClick(section);
      }
    };
    return (
      <div onClick={func}>
        <Icon name="folder outline" />
        {section.display_number} {section.title}
      </div>
    );
  }

  handleClick(targetSection) {
    const { ctrl, element, type } = this.props;

    this.setState({ open: false });

    if (type === "lineItem") {
      return ctrl.updateLineItemParent(element, targetSection);
    }
    return ctrl.updateSectionParent(element, targetSection);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
}

export default RoomBookSortOrderDialog;
