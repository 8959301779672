import PropTypes from "prop-types";
import React from "react";
import { Button, Form } from "semantic-ui-react";
import { filter, get } from "lodash";

import "./projectSettings.scss";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import {
  AccountProjectConfigsResource,
  ProjectConfigSelectionsResource
} from "../../../actions/accountActions";
import ProjectConfigDialog from "./ProjectConfigDialog";
import FeatureToggle from "../../../../shared/components/elements/FeatureToggle";

const roleToProperty = role => `${role}_config_id`;

class ProjectConfigSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openDialog: null
    };
  }

  getValueFor(role) {
    const { project } = this.props;
    return get(project, `project_config_selection.${roleToProperty(role)}`);
  }

  getConfigOptions(role) {
    const { project_configs } = this.props;
    const role_configs = filter(project_configs, ["role", role]);
    return role_configs
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .map(config => ({
        text: (
          <div>
            {config.name}
            {this.renderDeleteButton(role, config)}
          </div>
        ),
        value: config.id,
        key: config.id
      }));
  }

  getHandleAddItemFor(role) {
    const { accountProjectConfigsResource } = this.props;
    return (event, { value }) => {
      this.setState({ isLoading: true });
      accountProjectConfigsResource
        .save({ name: value, role })
        .then(({ data: { id } }) => this.setConfigSelection(role, id))
        .then(() => this.setState({ isLoading: false, openDialog: role }))
        .catch(() => this.setState({ isLoading: false }));
    };
  }

  getHandleChangeFor(role) {
    return (event, { value }) => this.setConfigSelection(role, value);
  }

  setConfigSelection(role, value) {
    const { projectConfigSelectionsResource } = this.props;
    if (this.isValidConfig(role, value)) {
      this.setState({ isLoading: true, openDialog: null });
      return projectConfigSelectionsResource
        .save({ [roleToProperty(role)]: value })
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  handleDelete(config) {
    const { accountProjectConfigsResource } = this.props;
    this.setState({ isLoading: true });
    return accountProjectConfigsResource
      .remove(config.id)
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  isValidConfig(role, value) {
    const { project_configs } = this.props;
    return project_configs.some(
      project_config =>
        project_config.id === value && project_config.role === role
    );
  }

  renderDeleteButton(role, config) {
    if (this.getValueFor(role) === config.id) return null;

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: cb => {
          this.handleDelete(config)
            .then(cb)
            .catch(cb);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title={`Konfiguration ${config.name} löschen`}
        message="Wollen Sie die Konfiguration löschen? Diese Aktion kann nicht rückgängig gemacht werden!"
        button={
          <Button
            icon="trash"
            size="mini"
            data-cmd="delete"
            onClick={event => event.stopPropagation()}
          />
        }
        buttons={buttons}
      />
    );
  }

  renderFormFor(role) {
    const { isLoading, openDialog } = this.state;
    return (
      <Form id={role} key={role}>
        <Form.Group>
          <Form.Select
            id={`select-${role}`}
            allowAdditions
            search
            loading={isLoading}
            disabled={isLoading}
            label={
              <label htmlFor={`select-${role}`}>
                <FormattedMessage
                  id={`activerecord.attributes.project_config_selection.${role}_config`}
                />
              </label>
            }
            options={this.getConfigOptions(role)}
            value={this.getValueFor(role)}
            onChange={this.getHandleChangeFor(role)}
            onAddItem={this.getHandleAddItemFor(role)}
          />
          <ProjectConfigDialog
            disabled={isLoading}
            projectConfigId={this.getValueFor(role)}
            defaultOpen={openDialog === role}
          />
        </Form.Group>
      </Form>
    );
  }

  render() {
    return (
      <div>
        <FeatureToggle featureToggleName="document_exports_12" disabled>
          {this.renderFormFor("exports")}
        </FeatureToggle>
        <FeatureToggle featureToggleName="email_integration_20" disabled>
          {this.renderFormFor("emails")}
        </FeatureToggle>
        {this.renderFormFor("hooks")}
      </div>
    );
  }
}

ProjectConfigSelection.propTypes = {
  project: PropTypes.object,
  project_configs: PropTypes.arrayOf(PropTypes.object),
  accountProjectConfigsResource: PropTypes.instanceOf(
    AccountProjectConfigsResource
  ),
  projectConfigSelectionsResource: PropTypes.instanceOf(
    ProjectConfigSelectionsResource
  )
};

const mapStateToProps = state => ({
  projects: state.pageContent.projects,
  project_configs: state.pageContent.project_configs
});

const mapDispatchToProps = (dispatch, props) => {
  const project_id = props.project?.id;
  return {
    accountProjectConfigsResource: new AccountProjectConfigsResource(dispatch),
    projectConfigSelectionsResource: new ProjectConfigSelectionsResource(
      dispatch,
      project_id
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectConfigSelection);
