import { createSelector } from "reselect";
import { get, keyBy } from "lodash";
import { getPageContent } from "./base";

export const getCityOptions = createSelector([getPageContent], pageContent => {
  return (
    get(pageContent, "cities", []).map(c => ({
      key: c.id,
      value: c.id,
      text: c.name
    })) || []
  );
});

export const getCities = createSelector([getPageContent], pageContent => {
  return keyBy(get(pageContent, "cities", []), "id");
});
