import apiRequest from "../../shared/network/apiRequest";
import Resources from "../../shared/actions/resourcesActions";
import { UPDATE_MESSAGE } from "../../shared/constants/appConstants";

export class ProductResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "product", "/api/v1/products");
  }

  createMultiple(products) {
    return apiRequest
      .put(`${this.url}/create_multiple`, this.dispatch, { products })
      .then(({ data: { count } }) => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            type: "success",
            title: "supplierIntegration.transfer.title",
            body: "supplierIntegration.transfer.body",
            bodyValues: { count }
          }
        });
      })
      .catch(this.handleUpdateFailed);
  }

  references(id) {
    return apiRequest
      .get(`${this.url}/${id}/references`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  productsByManufacturerSeries(id, supplierId, series) {
    return apiRequest
      .get(
        `${this.url}/products_by_manufacturer_series?supplier_id=${supplierId}&series=${series}&id=${id}`,
        this.dispatch
      )
      .then(this.propagateUpdate.bind(this));
  }
}

export class ProductSpecificationResource extends Resources {
  constructor(dispatch, productId) {
    super(
      dispatch,
      "specification",
      `/api/v1/products/${productId}/specifications`
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export class ProductImageResource extends Resources {
  constructor(dispatch, productId) {
    super(dispatch, "image", `/api/v1/products/${productId}/images`);
  }
}

export class ProductVideosResource extends Resources {
  constructor(dispatch, productId) {
    super(dispatch, "video", `/api/v1/products/${productId}/videos`);
  }
}

export class ProductFilesResource extends Resources {
  constructor(dispatch, productId) {
    super(
      dispatch,
      "product_file",
      `/api/v1/products/${productId}/product_files`
    );
  }
}

export class ProductMetaDataResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "products_meta_data",
      `/api/v1/products/products_meta_data`
    );
  }
}

export class SupplierResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "supplier", "/api/v1/suppliers");
  }
}
