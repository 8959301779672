/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Select } from "formik-semantic-ui-react";

const RenderRoles = ({ workflowRoles, label }) => {
  if (!workflowRoles || workflowRoles.length === 0) {
    return null;
  }

  const roleOptionsForDropdown = workflowRoles.map(role => {
    return {
      key: `role-${role.id}`,
      value: role.id,
      text: role.label
    };
  });

  return (
    <div>
      <Select
        name="roles"
        fluid
        options={roleOptionsForDropdown}
        label={label}
        multiple
        clearable
        search
      />
    </div>
  );
};

RenderRoles.propTypes = {
  workflowRoles: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
};

export default RenderRoles;
