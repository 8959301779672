import { createSelector } from "reselect";
import { get, orderBy } from "lodash";
import Branding from "./branding";

const getBranding = state => state.branding;

export const getProject = state => get(state, ["pageContent", "project"], null);
export const getProjectStatistics = state =>
  get(state, ["pageContent", "project", "statistics"], null);
export const getProjectSlug = (state, props) =>
  get(props, "projectId") ||
  get(props, ["params", "projectId"]) ||
  get(props, ["router", "params", "projectId"]) ||
  get(state, ["pageContent", "project", "slug"], null);
export const getProjects = state => get(state, ["pageContent", "projects"], []);
export const getProjectsSorted = state =>
  orderBy(get(state, ["pageContent", "projects"], []), "id");
export const getProjectNameById = (state, projectId) =>
  state.pageContent?.projects?.find(project => {
    return project.slug === projectId;
  })?.name;

const DEFAULT_PROJECT_BRANDING = {};
export const getProjectBranding = createSelector(
  [getBranding, getProjectSlug],
  (branding, projectSlug) => {
    return new Branding(get(branding, projectSlug, DEFAULT_PROJECT_BRANDING));
  }
);

export const getProjectId = (state, props) => {
  return props.routeParams?.projectId ?? props.params?.projectId;
};

export const getProjectMembers = state =>
  get(state, ["pageContent", "members"], []);

export const getAllProjectOptions = createSelector([getProjects], projects =>
  projects.map(p => ({ id: p.id, value: p.id, text: p.name }))
);

export const getImages = state => get(state, ["pageContent", "images"], []);

export const getProjectAcitvityContacts = state =>
  get(state, ["pageContent", "activityContacts", "contacts"], []);
