import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import DeadlinesForm from "./DeadlinesForm";
import "./deadlinesDialog.scss";

class DeadlinesDialog extends Component {
  static propTypes = {
    deadlines: PropTypes.array,
    button: PropTypes.node,
    activity: PropTypes.object,
    actions: PropTypes.object,
    deadlinesResource: PropTypes.object,
    states: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleToggleDialog = this.handleToggleDialog.bind(this);
  }

  handleToggleDialog(open) {
    this.setState({ ...this.state, open });
  }

  render() {
    const {
      button,
      activity,
      actions,
      deadlinesResource,
      deadlines,
      states
    } = this.props;

    return (
      <Modal
        data-component="deadlinesDialog"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={button}
        open={this.state.open}
        size="small"
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id="deadline.actions.edit"
            defaultMessage="deadline.actions.edit"
          />
        </Modal.Header>
        <Modal.Content>
          <DeadlinesForm
            onHandleToggleDialog={this.handleToggleDialog}
            deadlinesResource={deadlinesResource}
            activity={activity}
            actions={actions}
            deadlines={deadlines}
            states={states}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default DeadlinesDialog;
