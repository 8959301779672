import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "semantic-ui-react";
import colorByDueDays from "../../helpers/colorByDueDays";

function DueLabel({ date, ...labelProps }) {
  if (!date) return null;

  const days = moment(date).diff(moment().startOf("day"), "days");
  const color = colorByDueDays(date);

  return (
    // Spreading in a HOC is totally valid
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Label basic className="dueLabel" style={{ color }} {...labelProps}>
      {days < -1 && (
        <FormattedMessage
          id="activity.overdueDays"
          defaultMessage="activity.overdueDays"
          values={{ amount: days * -1 }}
        />
      )}
      {days === -1 && (
        <FormattedMessage
          id="activity.dueYesterday"
          defaultMessage="activity.dueYesterday"
        />
      )}
      {days === 0 && (
        <FormattedMessage
          id="activity.dueToday"
          defaultMessage="activity.dueToday"
        />
      )}
      {days === 1 && (
        <FormattedMessage
          id="activity.dueTomorrow"
          defaultMessage="activity.dueTomorrow"
        />
      )}
      {days > 1 && (
        <FormattedMessage
          id="activity.dueDays"
          defaultMessage="activity.dueDays"
          values={{ amount: days }}
        />
      )}
    </Label>
  );
}

DueLabel.defaultProps = {
  date: ""
};

DueLabel.propTypes = {
  date: PropTypes.string
};
export default DueLabel;
