import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "shared/helpers/withRouter";
import { Header, Loader } from "semantic-ui-react";
import AttachmentsList from "builder_portal/components/attachment/AttachmentsList";
import { If } from "shared/components/elements/Conditions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { getAccount, getI18N } from "shared/selectors";
import { getAttachments } from "builder_portal/selectors";
import { string } from "prop-types";
import { FormattedMessage } from "react-intl";
import { getProject } from "../../../shared/selectors";

const ProjectAttachmentsList = ({ projectId }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const account = useSelector(getAccount);
  const project = useSelector(getProject);
  const i18n = useSelector(getI18N);
  const attachments = useSelector(getAttachments);

  const actions = useMemo(() => {
    const resource = new ProjectsResource(dispatch);
    return {
      updateAttachment: attachment => {
        const values = { attachment };
        return resource
          .updateAttachment(values, projectId)
          .then(() => resource.fetchAttachments(projectId));
      },
      refresh: () => {
        return resource.fetchAttachments(projectId);
      },
      removeAttachment: id => {
        return resource
          .removeAttachment(id, projectId)
          .then(() => resource.fetchAttachments(projectId));
      }
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    new ProjectsResource(dispatch)
      .fetchAttachments(projectId)
      .then(() => setLoading(false));
  }, []);

  return (
    <>
      <If condition={account.isEnabled("show_page_layout_v2")}>
        <Header size="large">
          <FormattedMessage
            id="unit.tabs.attachments"
            defaultMessage="Dokumente"
          />
        </Header>
      </If>
      <If condition={isLoading}>
        <Loader active />
      </If>
      <If condition={!isLoading}>
        <AttachmentsList
          actionLayout="stacked"
          context={{ account, project }}
          resourceId={parseInt(projectId, 10)}
          resourceName="/projects"
          i18n={i18n}
          handleUpdate={actions.updateAttachment}
          handleAdd={actions.refresh}
          handleRemove={actions.removeAttachment}
          attachments={attachments}
          parentAttachments={null}
          destructionMode="project"
        />
      </If>
    </>
  );
};

ProjectAttachmentsList.propTypes = {
  projectId: string.isRequired
};

export default withRouter(ProjectAttachmentsList);
