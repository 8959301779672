import React from "react";
import { Grid, Form, Message } from "semantic-ui-react";
import { object } from "prop-types";

class ChangePriceStrategy extends React.Component {
  state = {
    price_strategy: "",
    product_group_id: "",
    error: null,
    success: null,
    loading: false
  };

  handleSubmit = () => {
    const { price_strategy, product_group_id } = this.state;
    this.setState({
      price_strategy,
      product_group_id,
      loading: true
    });

    fetch("/api/v1/support_utils/change_price_strategy", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        product_group: {
          id: product_group_id,
          price_strategy
        }
      })
    })
      .then(response => response.json())
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { i18n } = this.props;
    const {
      price_strategy,
      product_group_id,
      error,
      success,
      loading
    } = this.state;

    const options = [
      {
        key: "flat",
        text: i18n["product_group.price_strategies.flat.label"],
        value: "flat"
      },
      {
        key: "area",
        text: i18n["product_group.price_strategies.area.label"],
        value: "area"
      },
      {
        key: "length",
        text: i18n["product_group.price_strategies.length.label"],
        value: "length"
      },
      {
        key: "pieces",
        text: i18n["product_group.price_strategies.pieces.label"],
        value: "pieces"
      },
      {
        key: "hour",
        text: i18n["product_group.price_strategies.hour.label"],
        value: "hour"
      }
    ];

    return (
      <div>
        <h2>Change Price Strategy</h2>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form
              loading={loading}
              success={success}
              error={!!error}
              onSubmit={this.handleSubmit}
            >
              <Message
                success
                header="Price Strategy Change Completed"
                content="Please check the results!"
              />
              <Message
                error
                header="Price Strategy Change Failed"
                content={error}
              />
              <Form.Input
                name="product_group_id"
                value={product_group_id}
                onChange={this.handleChange}
                label="Product Group Id"
                placeholder="Product Group Id"
                required
                width="4"
              />
              <Form.Select
                name="price_strategy"
                value={price_strategy}
                options={options}
                onChange={this.handleChange}
                label="New Price Strategy"
                placeholder="Price Strategy"
                required
                width="4"
              />
              <Form.Button width="4">Submit</Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}

ChangePriceStrategy.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  i18n: object.isRequired
};

export default ChangePriceStrategy;
