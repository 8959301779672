import {
  getHandleDeleteFailed,
  getHandleUpdateFailed
} from "shared/actions/resourcesErrorHandling";
import apiRequest from "../network/apiRequest";
import { UPDATE_PAGE_CONTENT } from "../constants/appConstants";
import serialize from "../helpers/serialize";

class Resource {
  constructor(dispatch, namespace, url, reduxProperty) {
    this.dispatch = dispatch;
    this.namespace = namespace;
    this.reduxProperty = reduxProperty;
    this.url = url;
    this.handleUpdateFailed = getHandleUpdateFailed(dispatch);
    this.handleDeleteFailed = getHandleDeleteFailed(dispatch);
  }

  propagateUpdate(response) {
    switch (response.status) {
      case 401: // unauthorized fall-through
      case 404: // not found fall-through
      case 200: {
        // write data to store
        let payload;
        if (this.reduxProperty) {
          payload = {
            [this.reduxProperty]: response.data || undefined
          };
        } else {
          payload = response.data || undefined;
        }
        this.dispatch({
          type: UPDATE_PAGE_CONTENT,
          payload
        });
        break;
      }
    }
    return response;
  }

  save(values) {
    const payload = {};
    payload[this.namespace] = values;
    const request = apiRequest
      .patch(`${this.url}`, this.dispatch, payload)
      .catch(this.handleUpdateFailed);
    if (this.refreshPageContentAfterCommit) {
      return request.then(this.propagateUpdate.bind(this));
    }
    return request;
  }

  create(values) {
    const payload = {};
    payload[this.namespace] = values;
    const request = apiRequest.post(`${this.url}`, this.dispatch, payload);
    if (this.refreshPageContentAfterCommit) {
      return request.then(this.propagateUpdate.bind(this));
    }
    return request;
  }

  get(deep, params) {
    let { url } = this;
    params = params || {};
    if (deep) {
      params.deep = deep;
    }
    if (params && Object.keys(params).length > 0) {
      url = `${url}?${serialize(params)}`;
    }
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  remove() {
    return apiRequest
      .delete(this.url, this.dispatch)
      .then(this.propagateUpdate.bind(this))
      .catch(this.handleDeleteFailed);
  }

  update(values) {
    const payload = {};
    payload[this.namespace] = values;
    const request = apiRequest.patch(`${this.url}`, this.dispatch, payload);

    if (this.refreshPageContentAfterCommit) {
      return request.then(this.propagateUpdate.bind(this));
    }
    return request;
  }
}

export default Resource;
