import { createSelector } from "reselect";
import { get } from "lodash";

import { getPageContent } from "./base";

export const getProjectFiles = createSelector([getPageContent], pageContent =>
  get(pageContent, "project_files", [])
);

export default getProjectFiles;
