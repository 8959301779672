import React, { useCallback, useMemo } from "react";
import { func, number } from "prop-types";

import { get } from "lodash";
import { Dropdown } from "semantic-ui-react";
import { ProjectCatalogShape } from "shared/shapes";

const PriceCatalogDropdown = ({ catalog, value, onChange }) => {
  const options = useMemo(() => {
    return get(catalog, "price_catalogs", []).map(c => ({
      key: c.id,
      value: c.id,
      text: c.name
    }));
  }, [catalog]);

  const handleChange = useCallback(
    (_, d) => {
      onChange(d.value);
    },
    [onChange]
  );

  return (
    <Dropdown
      id="price-catalog-dropdown"
      fluid
      selection
      value={value}
      options={options}
      onChange={handleChange}
    />
  );
};

PriceCatalogDropdown.propTypes = {
  catalog: ProjectCatalogShape.isRequired,
  value: number.isRequired,
  onChange: func.isRequired
};

export default PriceCatalogDropdown;
