import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Modal,
  Button,
  Header,
  Message,
  Checkbox
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage, useIntl } from "react-intl";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import FileUploader from "builder_portal/components/dropzone/FileUploader";
import localStorage from "shared/helpers/localStorage";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import PropTypes, { func } from "prop-types";

const STATUS_CHECK_INTERVAL = 5000;
const NUMBER_OF_TRIES = 20;
const EMPTY_MODEL = { json_content: null };

const ProductCatalogUploadModal = ({
  trigger,
  project_catalog_id,
  onCompleted
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [asDesignLine, setAsDesignLine] = useState(true);
  const [error, setError] = useState("");
  const [model, setModel] = useState(EMPTY_MODEL);

  const handleOpen = () => {
    setError("");
    setModel(EMPTY_MODEL);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterPolling = () => {
    if (typeof onCompleted === "function") {
      onCompleted();
    }
    handleClose();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    NUMBER_OF_TRIES,
    afterPolling
  );

  const handleUpload = () => {
    const formData = new FormData();
    const { json_content } = model;
    if (!json_content) {
      setError(
        intl.formatMessage({
          id: "project.unit_variables.dialog.messages.selectFile"
        })
      );
      return;
    }
    setError("");

    formData.append(`json_content`, json_content);
    formData.append(`project_catalog_id`, project_catalog_id);
    formData.append(`project_catalog_as_design_line`, asDesignLine);

    fetch(`/api/v1/import_marketplace_project_catalogs/import`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.id) {
          startPolling(res.id, STATUS_CHECK_INTERVAL);
        }
      })
      .catch(err => {
        stopPolling();
        setError(err.message);
      });
  };

  const handleFile = file => {
    if (file) {
      const readFile = new FileReader();
      readFile.onload = e => {
        const contents = e.target.result;

        setModel(currentModel => ({
          ...currentModel,
          json_content: contents
        }));
        setError("");
      };
      readFile.readAsText(file);
    }
  };

  return (
    <Modal
      closeIcon
      onOpen={handleOpen}
      onClose={handleClose}
      open={open}
      trigger={trigger}
    >
      <Modal.Header>
        <Header as="h3">
          <FormattedMessage id="project_catalog.title.upload" />
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Message error content={error} hidden={!error} />
        <Form>
          <If condition={!model.json_content}>
            <FileUploader handleFile={handleFile} fileType="json" />
          </If>
          <If condition={!!model.json_content}>
            <Header as="h5" style={{ maxHeight: "100px", overflowY: "scroll" }}>
              {model.json_content}
            </Header>
          </If>
          <Checkbox
            name="project_catalog_as_design_line"
            label={intl.formatMessage({
              id: "project_catalog.title.as_design_line"
            })}
            checked={asDesignLine}
            onChange={(e, { checked }) => {
              setAsDesignLine(checked);
            }}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button
          basic
          id="close"
          content={intl.formatMessage({ id: "meta.actions.close" })}
          onClick={handleClose}
        />
        <Button
          primary
          id="submit"
          content={intl.formatMessage({ id: "attachment.actions.upload" })}
          onClick={handleUpload}
          disabled={isPolling}
        />
      </Modal.Actions>
      <TinyLoader opened={isPolling} />
    </Modal>
  );
};

ProductCatalogUploadModal.propTypes = {
  trigger: PropTypes.node.isRequired,
  project_catalog_id: PropTypes.number.isRequired,
  onCompleted: func
};

ProductCatalogUploadModal.defaultProps = {
  onCompleted: () => {}
};

export default ProductCatalogUploadModal;
