import React from "react";
import PropTypes, { bool } from "prop-types";

export const ShowIf = ({ condition, children }) => {
  return (
    <div style={{ display: condition ? "block" : "none" }}>{children}</div>
  );
};
ShowIf.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const If = props => {
  const { condition, children, styles, block } = props;
  if (condition) {
    if (children?.length > 1) {
      return (
        <div style={{ display: block ? "block" : "inline-block", ...styles }}>
          {children}
        </div>
      );
    }
    if (children) return children;
  }
  return null;
};
If.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  condition: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object,
  block: bool
};
If.defaultProps = {
  condition: false,
  styles: {},
  block: false,
  children: null
};
export const Unless = props => {
  const { condition, children } = props;
  return <If condition={!condition}>{children}</If>;
};
Unless.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
