/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Select } from "formik-semantic-ui-react";

const DEFAULT_PROJECT_ROLES = [];

const RenderProjectRoles = ({ projectRoles, membership, label }) => {
  const [projectRoleValue, setProjectRoleValue] = React.useState(
    membership?.project_role_id
  );

  React.useEffect(() => {
    setProjectRoleValue(membership?.project_role_id);
  }, [membership?.project_role_id]);

  return (
    <Select
      name="projectRoleId"
      label={label}
      fluid
      selection
      options={projectRoles || DEFAULT_PROJECT_ROLES}
      value={projectRoleValue}
    />
  );
};

RenderProjectRoles.propTypes = {
  projectRoles: PropTypes.array.isRequired,
  membership: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default RenderProjectRoles;
