/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Button, Icon, Grid } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import DateInput from "shared/components/forms/DateInput";
import PropTypes from "prop-types";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { connect } from "react-redux";
import { If } from "../../../shared/components/elements/Conditions";
import getTradeOptions from "../../selectors/trades";
import OptionsShape from "../../../shared/shapes/options.shape";

const reminderFieldsRequired = [
  {
    id: "reminder_subject",
    label: "projectDeadline.attributes.reminder_subject.label",
    autoComplete: "off",
    rule: "isRequired"
  },
  {
    id: "reminder_text",
    label: "projectDeadline.attributes.reminder_text.label",
    autoComplete: "off",
    rule: "isRequired"
  }
];
const defaultFields = [
  {
    id: "title",
    label: "projectDeadline.attributes.title.label",
    rule: "isRequired",
    autoComplete: "off",
    autoFocus: true
  },
  {
    id: "expires_at",
    label: "projectDeadline.attributes.expires_at.label",
    rule: "isRequired",
    autoComplete: "off"
  },
  {
    id: "expiration_scope",
    label: "projectDeadline.attributes.expiration_scope.label",
    rule: "isRequired",
    autoComplete: "off"
  },
  {
    id: "description",
    label: "projectDeadline.attributes.description.label"
  },
  {
    id: "scheduled_for",
    label: "projectDeadline.attributes.scheduled_for.label",
    rule: "isRequired"
  },
  {
    id: "reminder_active",
    label: "projectDeadline.attributes.reminder_active.label",
    control: "Checkbox"
  },
  {
    id: "reminder_schedule",
    label: "projectDeadline.attributes.reminder_schedule.label",
    autoComplete: "off",
    rule: "isNumber",
    default: 1
  },

  {
    id: "trades",
    label: "projectDeadline.attributes.trades.label"
  },
  {
    id: "lock_trades",
    label: "projectDeadline.attributes.lock_trades.label",
    control: "Checkbox"
  },
  {
    id: "reminder_subject",
    label: "projectDeadline.attributes.reminder_subject.label",
    autoComplete: "off"
  },
  {
    id: "reminder_text",
    label: "projectDeadline.attributes.reminder_text.label",
    autoComplete: "off"
  }
];

const FormFactory = new FormDefinition({
  fields: defaultFields
});

const FormFactoryWithRequiredReminders = new FormDefinition({
  fields: defaultFields.concat(reminderFieldsRequired)
});

class ProjectDeadlineDialog extends Component {
  static propTypes = {
    deadline: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    button: PropTypes.node.isRequired,
    actions: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    trades: PropTypes.arrayOf(OptionsShape).isRequired
  };

  constructor(props) {
    super(props);

    // @todo: use redux state not component state
    this.state = { open: false, deadline: cloneDeep(this.props.deadline) };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleOverride = this.handleOverride.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleUpdate() {
    if (this.props.onUpdate) {
      return this.props.onUpdate();
    }
    const { deadlines } = this.props.actions;
    return deadlines.fetchAll();
  }

  onSave(values) {
    const { deadlines } = this.props.actions;

    this.setState({ isLoading: true });
    return deadlines.save(values).then(() => {
      return this.handleUpdate().then(() => {
        if (!this.unmounted) {
          this.setState({ open: false, isLoading: false });
        }
      });
    });
  }

  onRemove() {
    const { deadlines } = this.props.actions;

    this.setState({ isLoading: true });
    return deadlines.remove(this.state.deadline.id).then(() => {
      return this.handleUpdate();
    });
  }

  handleOverride() {
    this.setState({ isOverriding: true });
  }

  handleOpen() {
    this.setState({ open: true, deadline: cloneDeep(this.props.deadline) });
  }

  handleClose() {
    this.setState({ open: false, deadline: cloneDeep(this.props.deadline) });
  }

  render() {
    const { i18n } = this.props;
    const { deadline } = this.state;
    const message = this.state.deadline.id
      ? "projectDeadline.actions.edit"
      : "projectDeadline.actions.new";

    const form = deadline.reminder_active
      ? FormFactoryWithRequiredReminders.create(deadline, this.props.i18n, {
          onChange: deadline => {
            this.setState({ deadline });
          }
        })
      : FormFactory.create(deadline, this.props.i18n, {
          onChange: deadline => {
            this.setState({ deadline });
          }
        });

    form.fields.expiration_scope.props.options = this.getScopes().map(scope => {
      return {
        key: scope,
        value: scope,
        text: i18n[`projectDeadline.expiration_scopes.${scope}.label`]
      };
    });

    form.fields.scheduled_for.props.options = [
      {
        value: "operator",
        text: "Kundenbetreuer"
      },
      {
        value: "buyer",
        text: "Erwerber"
      }
    ];

    return (
      <Modal
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Modal.Header>
          <FormattedMessage id={message} defaultMessage={message} />
        </Modal.Header>
        <Modal.Content scrolling>
          {this.renderDialogContent(deadline, form)}
        </Modal.Content>
        <Modal.Actions>
          {this.renderDialogActions(deadline, form)}
        </Modal.Actions>
      </Modal>
    );
  }

  renderDialogContent(deadline, form) {
    const { type, trades } = this.props;
    const { isOverriding } = this.state;

    const readonly = !!(deadline.parent_id || isOverriding);

    ["title"].forEach(field => {
      form.fields[field].props.readOnly = readonly;
    });
    ["description", "scheduled_for", "trades"].forEach(field => {
      form.fields[field].props.disabled = readonly;
    });
    ["expiration_scope"].forEach(field => {
      form.fields[field].props.disabled = !!deadline.id || readonly;
    });

    // makeup trades dropdown
    // eslint-disable-next-line no-param-reassign
    form.fields.trades.props.multiple = true;
    // eslint-disable-next-line no-param-reassign
    form.fields.trades.props.selection = true;
    // eslint-disable-next-line no-param-reassign
    form.fields.trades.props.search = true;
    // eslint-disable-next-line no-param-reassign
    form.fields.trades.props.closeOnChange = true;
    // eslint-disable-next-line no-param-reassign
    form.fields.trades.props.options = trades || [];

    if (deadline.id || !deadline.parent_id || isOverriding) {
      return (
        <Form id="project_deadline" data-component="projectDeadlineForm">
          <Form.Field>
            <Field component="Input" {...form.fields.title} />
          </Form.Field>
          <Form.Field>
            <Field component={DateInput} {...form.fields.expires_at} />
          </Form.Field>
          <Form.Field width="16">
            <Field component="Select" {...form.fields.expiration_scope} />
          </Form.Field>
          <Form.Field width="16">
            <Field component="Select" {...form.fields.scheduled_for} />
          </Form.Field>
          <Form.Field>
            <Field component="Select" {...form.fields.trades} />
          </Form.Field>
          <Form.Field>
            <Field component="Checkbox" {...form.fields.lock_trades} />
          </Form.Field>
          <If condition={deadline.scheduled_for === "buyer"} block>
            <Form.Field width="16">
              <Field component="TextArea" {...form.fields.description} />
            </Form.Field>
            <If condition={deadline.expiration_scope === "unit"} block>
              <FeatureToggle featureToggleName="project_deadline_reminder">
                <Form.Field width="16">
                  <Field
                    component="Checkbox"
                    {...form.fields.reminder_active}
                  />
                </Form.Field>
                <If condition={deadline.reminder_active} block>
                  <Form.Field width="16">
                    <Field
                      component="Input"
                      {...form.fields.reminder_schedule}
                    />
                  </Form.Field>
                  <Form.Field width="16">
                    <Field
                      component="Input"
                      {...form.fields.reminder_subject}
                    />
                  </Form.Field>
                  <Form.Field width="16">
                    <Field
                      component="TextArea"
                      {...form.fields.reminder_text}
                    />
                  </Form.Field>
                </If>
              </FeatureToggle>
            </If>
          </If>
        </Form>
      );
    }
    const parent = type === "section" ? "Projekt" : "Bauabschnitt";
    return (
      <div style={{ textAlign: "center", margin: "0 40px" }}>
        <p>
          Diese Frist wird vom übergeordneten {parent} definiert. Sie können
          diese Frist überschreiben, um ein abweichendes Datum zu hinterlegen.
        </p>
        <div>
          <Button
            id="project_deadline-override"
            onClick={this.handleOverride}
            name="override"
            color="grey"
            loading={this.state.isLoading}
          >
            <FormattedMessage
              id="projectDeadline.actions.override"
              defaultMessage="projectDeadline.actions.override"
            />
          </Button>
        </div>
      </div>
    );
  }

  renderDialogActions(deadline, form) {
    const { isOverriding } = this.state;
    if (deadline.id || !deadline.parent_id || isOverriding) {
      return (
        <Grid>
          <Grid.Column width={8} verticalAlign="middle">
            {deadline.id && this.renderDeleteButton(deadline)}
          </Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <Button
              type="submit"
              color="green"
              data-form="project_deadline"
              onClick={form.handleSubmit(this.onSave)}
              loading={this.state.isLoading}
              id={
                (deadline.id && "projectDeadline-edit") || "projectDeadline-new"
              }
            >
              <Icon name="save" />
              <FormattedMessage
                id="meta.actions.save"
                defaultMessage="meta.actions.save"
              />
            </Button>
          </Grid.Column>
        </Grid>
      );
    }
    return (
      <Grid>
        <Grid.Column width={16} verticalAlign="middle" textAlign="right">
          <Button
            onClick={this.handleClose}
            name="cancel"
            as="div"
            color="grey"
            basic
            loading={this.state.isLoading}
            className="right floated element"
          >
            <FormattedMessage
              id="meta.actions.cancel"
              defaultMessage="meta.actions.cancel"
            />
          </Button>
        </Grid.Column>
      </Grid>
    );
  }

  renderDeleteButton() {
    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isLoading}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: this.onRemove
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="projectDeadline.actions.removeDialog.title"
        message="projectDeadline.actions.removeDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  getScopes() {
    const { type } = this.props;

    if (type === "project") {
      return ["project", "section", "unit"];
    }
    if (type === "section") {
      return ["section", "unit"];
    }
    return ["unit"];
  }
}

const mapStateToProps = state => ({
  trades: getTradeOptions(state)
});

export default connect(mapStateToProps)(ProjectDeadlineDialog);
