import { object, number, instanceOf, oneOf, string } from "prop-types";
import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { scrollToTop } from "../../helpers/scrollToTop";
import TimeoutProgressBar from "../elements/timeoutProgressBar";
import Growl from "../../../builder_portal/actions/growlActions";
import "./growlMessage.scss";

class GrowlMessage extends PureComponent {
  componentDidMount() {
    scrollToTop();
  }

  dismiss = () => {
    const { growl } = this.props;
    growl.dismiss();
  };

  render() {
    const {
      type,
      title,
      body,
      icon,
      timeout,
      titleValues,
      bodyValues
    } = this.props;

    const progressBarColorMap = {
      success: "green",
      warning: "yellow",
      error: "red"
    };

    if (!title && !body) return null;
    return (
      <>
        <Message
          onDismiss={this.dismiss}
          error={type === "error"}
          warning={type === "warning"}
          success={type === "success"}
          data-component="growlMessage"
          icon
          floating
        >
          <Icon name={icon} />
          <Message.Content>
            <Message.Header>
              <FormattedMessage
                id={title}
                defaultMessage="Generic Growl Header"
                values={titleValues}
              />
            </Message.Header>
            <FormattedMessage
              id={body}
              values={bodyValues}
              defaultMessage="Generic Growl Message"
            />
            {timeout && (
              <TimeoutProgressBar
                timeout={timeout}
                onTimeoutComplete={this.dismiss}
                progressBarProps={{
                  attached: "bottom",
                  color: progressBarColorMap[type]
                }}
              />
            )}
          </Message.Content>
        </Message>
      </>
    );
  }
}

GrowlMessage.propTypes = {
  type: oneOf(["error", "success", "warning"]),
  title: string,
  // will be passed to FormattedMessage for dynamic translation values
  // eslint-disable-next-line react/forbid-prop-types
  titleValues: object,
  body: string,
  // will be passed to FormattedMessage for dynamic translation values
  // eslint-disable-next-line react/forbid-prop-types
  bodyValues: object,
  icon: oneOf(["exclamation circle", "check", "warning"]),
  timeout: number,
  growl: instanceOf(Growl).isRequired
};

GrowlMessage.defaultProps = {
  type: "error",
  title: "",
  titleValues: {},
  body: "",
  bodyValues: {},
  icon: "exclamation circle",
  timeout: 6000
};

const mapStateToProps = state => ({
  ...state.message
});

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GrowlMessage);
