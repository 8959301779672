import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { Button, Header, Modal } from "semantic-ui-react";
import { Form, Input } from "formik-semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import getProductFilterTags from "../../../shared/selectors/productFilterTags";
import { ProductFilterTagsResource } from "../../actions/productFilterTagsActions";
import TokenResource from "../../../shared/actions/tokenActions";

const EMPTY_MODEL = {
  name: "",
  identifier: ""
};

const ProductFilterTagsDialog = ({ trigger }) => {
  const [open, setOpen] = useState(false);
  const refResetForm = useRef();
  const tags = useSelector(getProductFilterTags);

  const dispatch = useDispatch();
  const resource = new ProductFilterTagsResource(dispatch);

  const handleOpen = () => setOpen(true);
  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };
  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onDelete = tag => {
    resource
      .remove(tag.composite_id)
      .then(() => {
        return new TokenResource(dispatch).get();
      })
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
        clearForm();
      })
      .catch(() => {
        new Growl(dispatch).error("message.error.title", "meta.states.error");
      });
  };

  const onSubmit = (values, { resetForm }) => {
    resource
      .save({ ...values, taxonomy_identifier: "product_filter_tags" })
      .then(() => {
        return new TokenResource(dispatch).get();
      })
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saved"
        );
        resetForm();
      })
      .catch(() => {
        new Growl(dispatch).error("message.error.title", "meta.states.error");
      });
  };

  return (
    <Modal
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={trigger}
    >
      <Modal.Header>
        <Header as="h3">
          <FormattedMessage
            id="product_filter_tags.title"
            default="Produktfilter Tags verwalten"
          />
        </Header>
      </Modal.Header>
      <Modal.Content>
        <div>
          {tags.map(tag => (
            <Formik
              key={tag.identifier}
              initialValues={tag}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ isSubmitting, dirty, handleSubmit }) => {
                return (
                  <Form>
                    <div
                      className="block margin bottom small"
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center"
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Input
                          fluid
                          errorPrompt
                          name="name"
                          placeholder="Name"
                        />
                      </div>
                      <Button
                        disabled={!dirty || isSubmitting}
                        onClick={handleSubmit}
                      >
                        <FormattedMessage id="meta.actions.save" />
                      </Button>
                      <Button
                        onClick={event => {
                          event.preventDefault();
                          onDelete(tag);
                        }}
                      >
                        <FormattedMessage id="meta.actions.remove" />
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ))}
        </div>
        <Formik
          initialValues={EMPTY_MODEL}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting, dirty, handleSubmit }) => {
            return (
              <Form>
                <div
                  className="block margin top spacious"
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <Input
                      fluid
                      errorPrompt
                      name="name"
                      placeholder="Neuer Tag"
                    />
                  </div>
                  <Button
                    disabled={!dirty || isSubmitting}
                    onClick={handleSubmit}
                  >
                    <FormattedMessage id="meta.actions.add" />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          <FormattedMessage id="meta.actions.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ProductFilterTagsDialog.propTypes = {
  trigger: PropTypes.node.isRequired
};

export default ProductFilterTagsDialog;
