import { UPDATE_FILTER } from "../constants/appConstants";
import localStorage from "../helpers/localStorage";

export const filter = (state = "", action) => {
  switch (action.type) {
    case UPDATE_FILTER: {
      const newFilter = { ...state, ...action.payload };
      if (!action.transient) {
        localStorage.set("filter", JSON.stringify(newFilter));
      }
      return newFilter;
    }
    default:
      return state;
  }
};
