import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUnitFeaturesById } from "builder_portal/selectors/unitFeatureGroups";
import { Label, Popup, Icon } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { bool, shape, arrayOf, number } from "prop-types";

const UnitFeatureIndicators = ({
  item: { unit_feature_ids },
  isTemplate,
  basic
}) => {
  const unitFeatures = useSelector(getUnitFeaturesById);

  const plus = useMemo(() => unit_feature_ids.length - 2, [unit_feature_ids]);

  if (
    !isTemplate ||
    !Array.isArray(unit_feature_ids) ||
    !unit_feature_ids.length
  )
    return null;

  return (
    <Popup
      trigger={
        <Label basic={basic}>
          <Icon name="filter" />
          {unit_feature_ids.map((id, index) => {
            if (index < 2)
              return (
                <span key={id} style={{ paddingRight: "8px" }}>
                  {unitFeatures[id]?.name}
                </span>
              );
            return null;
          })}
          <If condition={plus > 0}>
            <span style={{ fontWeight: 700 }}>+{plus}</span>
          </If>
        </Label>
      }
    >
      {unit_feature_ids.map(id => (
        <div key={id}>{unitFeatures[id]?.name}</div>
      ))}
    </Popup>
  );
};

UnitFeatureIndicators.propTypes = {
  item: shape({
    unit_feature_ids: arrayOf(number)
  }).isRequired,
  isTemplate: bool.isRequired,
  basic: bool
};

UnitFeatureIndicators.defaultProps = {
  basic: false
};

export default UnitFeatureIndicators;
