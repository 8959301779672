/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { Header, Button, Modal } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { Formik } from "formik";
import { Input, Form, Select } from "formik-semantic-ui-react";
import RenderProjectRoles from "./RenderProjectRoles";
import RenderMailTemplates from "./RenderMailTemplates";
import RenderRoles from "./RenderRoles";

const EditProjectMembershipDialog = ({
  trigger,
  projectRoles,
  projectId,
  membership,
  onHandleAddUserRole,
  userLabel,
  model,
  accountMailTemplates,
  projectEmails,
  workflowRoles
}) => {
  const refResetForm = useRef();
  const [open, setOpen] = useState(false);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const projectEmailsForDropdown = projectEmails
    .filter(projectEmail => projectEmail.project_id === projectId)
    .map(projectEmail => ({
      key: projectEmail.id,
      text: projectEmail.email,
      value: projectEmail.id
    }));

  return (
    <Formik
      initialValues={{
        name: userLabel,
        projectRoleId: membership?.project_role_id,
        mailTemplateId: membership?.mail_template_id,
        roles: model?.roles,
        projectEmailId: membership?.project_email_id
      }}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        onHandleAddUserRole({
          ...values,
          projectId,
          id: membership.id
        })
          .then(() => {
            setSubmitting(false);
            handleClose();
          })
          .catch(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            size="small"
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            trigger={trigger}
          >
            <Header as="h2">
              <FormattedMessage id="account.project_memberships.dialog.edit" />
            </Header>
            <Modal.Content>
              <Form id="editProjectMembershipForm">
                <Input
                  readOnly
                  fluid
                  placeholder={useIntl().formatMessage({
                    id: "accountRoles.dialog.name"
                  })}
                  name="name"
                  label={useIntl().formatMessage({
                    id: "account.project_memberships.attributes.name.label"
                  })}
                />
                <FeatureToggleActive featureToggleName="custom_roles">
                  <RenderProjectRoles
                    projectRoles={projectRoles}
                    membership={membership}
                    label={useIntl().formatMessage({
                      id:
                        "account.project_memberships.attributes.project_role.label"
                    })}
                  />
                </FeatureToggleActive>
                <RenderMailTemplates
                  label={useIntl().formatMessage({
                    id:
                      "account.project_memberships.attributes.email_signature.label"
                  })}
                  accountMailTemplates={accountMailTemplates}
                />
                {projectEmailsForDropdown.length > 1 && (
                  <Select
                    fluid
                    placeholder={useIntl().formatMessage({
                      id:
                        "account.project_memberships.attributes.project_email.label"
                    })}
                    name="projectEmailId"
                    label={useIntl().formatMessage({
                      id:
                        "account.project_memberships.attributes.project_email.label"
                    })}
                    options={projectEmailsForDropdown}
                    clearable
                  />
                )}
                <RenderRoles
                  label={useIntl().formatMessage({
                    id: "account.project_memberships.attributes.roles.label"
                  })}
                  workflowRoles={workflowRoles}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

EditProjectMembershipDialog.propTypes = {
  model: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.object),
    project_role_id: PropTypes.number,
    mailtemplateid: PropTypes.number
  }),
  trigger: PropTypes.node,
  projectRoles: PropTypes.arrayOf(PropTypes.object),
  projectId: PropTypes.number,
  membership: PropTypes.object,
  onHandleAddUserRole: PropTypes.func,
  userLabel: PropTypes.string,
  workflowRoles: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/require-default-props
  accountMailTemplates: PropTypes.arrayOf(PropTypes.object),
  projectEmails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  )
};

EditProjectMembershipDialog.defaultProps = {
  trigger: undefined,
  model: {},
  projectRoles: undefined,
  projectId: undefined,
  membership: undefined,
  onHandleAddUserRole: undefined,
  userLabel: undefined,
  projectEmails: [],
  workflowRoles: []
};

export default EditProjectMembershipDialog;
