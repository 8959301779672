// 'service', 'offset'
// function: get
// WHY? After opening variable modal, caret is lost very often
// WHAT? caret should be preserved
// HOW? if  this.selection.getBlock() does not exist, store offset info on global level

export const storeCaretGlobal = (selection, offset, node, el) => {
  // if block exists, store it to restore it after variable modal removes it
  // node is editor, el is <p> - sometimes offset refers to node, sometimes to el
  if (selection?.getBlock()) {
    Window.redactor = {};
    Window.redactor.offset = offset;
    Window.redactor.node = node;
    Window.redactor.el = el;
  }
};

// api: function(name)
// condition: if isPlugin
// WHY? At this moment, after opening variable modal, selection block is very often lost
// WHAT? Caret should be set again
// HOW? If caret is stored to Window.redactor by storeCaretGlobal function, it should be reset

export const setCaret = selection => {
  if (!selection?.getBlock() && Window.redactor?.offset) {
    const { node, el, offset } = Window.redactor;

    const chars = node?.textContent?.length;
    if (offset.start === offset.end && Math.abs(offset.start - chars) === 1) {
      offset.start = chars;
      offset.end = chars;
    }

    selection.caret.offset.set(offset, el || node);
  }
};

// redactor update
// those two function must be imported into redactor.js
// import {storeCaretGlobal, setCaret} from '../caretHelper';
// and called:
// HOW TO CALL storeCaretGlobal:
/*  
    (before returning offset from service.offset.get function)
    storeCaretGlobal(this.selection, offset, node, el);  
*/
// HOW TO CALL setCaret:
/*
        // plugin
        else if (isPlugin)
        {
            if (this.isInstanceExists(this.plugin, arr[1]))
            {
                setCaret(this.selection);
                return this.callInstanceMethod(this.plugin[arr[1]], arr[2], args);
            }

AND for the isModule, too:
                setCaret(this.selection);
                return this.callInstanceMethod(this.module[arr[1]], arr[2], args);
*/
