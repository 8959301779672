/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export class UnitVariableTypesResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "unit_variable_type",
      `/api/v1/projects/${projectId}/unit_variable_types`
    );
    this.refreshPageContentAfterCommit = true;
  }

  importDefaults() {
    return apiRequest
      .post(`${this.url}/import_defaults`, this.dispatch, {})
      .then(this.propagateUpdate.bind(this));
  }
}

export class UnitVariablesResource extends Resources {
  constructor(dispatch, unitId) {
    super(dispatch, "unit_variable", `/api/v1/units/${unitId}/unit_variables`);
    this.refreshPageContentAfterCommit = true;
  }

  projectUnitVariables(projectId) {
    return apiRequest
      .get(`/api/v1/projects/${projectId}/unit_variables`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchWithPaginationUnitLevel(unitId, params) {
    const queryString = params
      ? `?${Object.keys(params)
          .map(k => `${k}=${params[k]}&`)
          .join("")}`
      : "";
    return apiRequest
      .get(
        `/api/v1/units/${unitId}/unit_variables${queryString}`,
        this.dispatch
      )
      .then(this.propagateUpdate.bind(this));
  }

  fetchWithPaginationProjectLevel(projectId, params) {
    const queryString = params
      ? `?${Object.keys(params)
          .map(k => `${k}=${params[k]}&`)
          .join("")}`
      : "";
    return apiRequest
      .get(
        `/api/v1/projects/${projectId}/unit_variables${queryString}`,
        this.dispatch
      )
      .then(this.propagateUpdate.bind(this));
  }
}
