import { RoomBookCartResource } from "builder_portal/actions/roomBookActions";
import { get } from "lodash";

export default class CreateActivityAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  execute(params) {
    const { roomBookId, cart } = params;
    const roomBookCartResource = new RoomBookCartResource(
      this.dispatch,
      roomBookId
    );
    return roomBookCartResource.save(cart).then(response => {
      const activityId = get(response, "data.cart.activity.id");
      return { ...params, activityId };
    });
  }
}
