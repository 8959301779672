import React from "react";
import PropTypes from "prop-types";
import { get, keyBy } from "lodash";
import { Bar } from "react-chartjs-2";
import { formatNumber } from "../../../shared/helpers/formatNumber";

import connectStatistic from "./statisticsContainer";

class ProfitByPhase extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { processDefinition, statistics, accountingMode } = this.props;

    const processDictionary = keyBy(processDefinition.getPhases(true), "id");

    const barChartOptions = {
      legend: {
        display: false,
        labels: {
          defaultFontFamily: "Lato"
        }
      },
      tooltips: {
        backgroundColor: "#333",
        titleFontFamily: "Lato",
        bodyFontFamily: "Lato",
        bodyFontSize: 17,
        callbacks: {
          title(tooltipItem, data) {
            const label = data.labels[tooltipItem[0].index];
            return `${label}`;
          },
          label(tooltipItem, data) {
            const value = data.datasets[0].data[tooltipItem.index];
            return formatNumber(value);
          }
        }
      },
      scales: {
        xAxes: [
          {
            display: false
          }
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              callback(value) {
                return value;
              }
            }
          }
        ]
      },
      animation: {
        duration: 2000
      }
    };

    const phases = Object.keys(processDictionary);
    phases.shift(); // Remove first phase

    const amountField =
      accountingMode === "net" ? "net_total_profit" : "gross_total_profit";

    const barChartData = {
      labels: phases.map(phase => get(processDictionary[phase], "label")),
      datasets: [
        {
          data: phases.map(phase =>
            Number(get(statistics, [phase, amountField], 0))
          ),
          backgroundColor: phases.map(phase =>
            get(processDictionary[phase], "color")
          )
        }
      ]
    };

    return (
      <div data-component="resultPerPhaseStatistic">
        <Bar data={barChartData} options={barChartOptions} />
      </div>
    );
  }
}

ProfitByPhase.propTypes = {
  statistics: PropTypes.object.isRequired,
  accountingMode: PropTypes.oneOf(["net", "gross"]),
  processDefinition: PropTypes.object.isRequired
};

ProfitByPhase.defaultProps = {
  accountingMode: "net"
};

export default connectStatistic(ProfitByPhase);
