import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Menu,
  Modal,
  Segment
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Input } from "formik-semantic-ui-react";
import React, { useCallback, useMemo, useState } from "react";
import { cloneDeep, keyBy, values } from "lodash";
import PropTypes from "prop-types";
import { signatureElementTypes } from "./constants";
import TextElementForm from "./TextElementForm";
import SignatureElementForm from "./SignatureElementForm";
import AddElements from "./AddElements";
import { If } from "../../../../../shared/components/elements/Conditions";
import ConfirmationDialog from "../../../../../shared/components/dialogs/ConfirmationDialog";

const BlocksEditor = ({ blocks, onChange }) => {
  const intl = useIntl();
  const [editingId, setEditingId] = useState();
  const [currentBlockIdx, setCurrentBlockIdx] = useState(0);
  const [deleteBlockModalIsOpen, setDeleteBlockModalIsOpen] = useState(false);

  const currentBlock = useMemo(() => {
    return blocks[currentBlockIdx];
  }, [blocks, currentBlockIdx]);

  const getBlockLabel = block => {
    return block?.label || block?.id || "Unbenannt";
  };

  const blockLabelValue = useMemo(() => {
    if (currentBlock) {
      return getBlockLabel(currentBlock);
    }
    return undefined;
  }, [currentBlock]);

  const jsonConfig = useMemo(() => {
    return (currentBlock?.elements || []).map((e, id) => ({
      ...e,
      id: id + 1
    }));
  }, [currentBlock]);

  const setBlocks = useCallback(
    newBlocks => {
      onChange(newBlocks);
    },
    [onChange]
  );

  const updateJson = useCallback(
    elements => {
      const newBlocks = blocks.map(b => {
        if (b === currentBlock) {
          return { ...b, elements };
        }
        return b;
      });

      setBlocks(newBlocks);
    },
    [blocks, currentBlock]
  );

  const handleElementChange = useCallback(
    element => {
      updateJson(
        jsonConfig.map(e => {
          if (e.id === element.id) {
            return element;
          }
          return e;
        })
      );
    },
    [jsonConfig, updateJson]
  );

  const handleAddElement = useCallback(
    element => {
      updateJson(jsonConfig.concat({ ...element, id: jsonConfig.length + 1 }));
    },
    [jsonConfig, updateJson]
  );

  const handleElementRemove = element => {
    const { id } = element;
    const temp = keyBy(jsonConfig, "id");
    delete temp[id];
    const json = values(temp);
    updateJson(json);
  };

  const moveElementUp = element => {
    const index = jsonConfig.findIndex(el => el.id === element.id);
    const json = [...jsonConfig];
    if (index < json.length && index > 0) {
      const temp = json[index];
      json[index] = json[index - 1];
      json[index - 1] = temp;
    }
    updateJson(json);
  };

  const moveElementDown = element => {
    const index = jsonConfig.findIndex(el => el.id === element.id);
    const json = [...jsonConfig];
    if (index < json.length - 1) {
      const temp = json[index];
      json[index] = json[index + 1];
      json[index + 1] = temp;
    }
    updateJson(json);
  };

  const handleCurrentBlockChange = useCallback(
    (field, value) => {
      let newBlocks = cloneDeep(blocks);
      newBlocks = newBlocks.map((block, idx) => {
        if (idx === currentBlockIdx) {
          return { ...currentBlock, [field]: value };
        }
        return block;
      });

      setBlocks(newBlocks);
    },
    [currentBlock]
  );

  const handleCheckboxChange = (_, val) => {
    const { name, checked: value } = val;

    handleCurrentBlockChange(name, value);
  };

  const handleBlockLabelChange = (_, { value }) => {
    handleCurrentBlockChange("label", value);
  };

  const handleDeleteBlock = () => {
    setBlocks(blocks.filter((block, idx) => idx !== currentBlockIdx));
    setDeleteBlockModalIsOpen(false);
    setCurrentBlockIdx(0);
  };

  const handleAddBlock = () => {
    const temp = cloneDeep(blocks);
    temp.push({
      elements: [],
      id: `block${temp.length + 1}`,
      new_page: false,
      label: `block${temp.length + 1}`
    });

    setBlocks(temp);
  };

  const handleMoveBlock = (e, direction) => {
    e.stopPropagation();

    const temp = cloneDeep(blocks);
    if (direction === "left") {
      if (currentBlockIdx === 0) return;
      [temp[currentBlockIdx - 1], temp[currentBlockIdx]] = [
        temp[currentBlockIdx],
        temp[currentBlockIdx - 1]
      ];

      setCurrentBlockIdx(c => c - 1);
    }
    if (direction === "right") {
      if (currentBlockIdx === blocks.length - 1) return;
      [temp[currentBlockIdx + 1], temp[currentBlockIdx]] = [
        temp[currentBlockIdx],
        temp[currentBlockIdx + 1]
      ];

      setCurrentBlockIdx(c => c + 1);
    }

    setBlocks(temp);
  };

  const renderRemoveButton = (onRemove, element) => {
    const deleteButton = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="product_group-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          onRemove(element);
          closeConfirmation();
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="documentsExport.dialogs.editSignature.removeConfirmation.title"
        message="documentsExport.dialogs.editSignature.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  const renderActions = (element, unknownType) => {
    return (
      <>
        <Grid.Column width={1}>
          <If condition={!unknownType}>
            <Icon
              style={{
                display: editingId !== element.id ? "block" : "none"
              }}
              name="edit"
              color="grey"
              onClick={() => {
                setEditingId(element.id);
              }}
            />
            <Icon
              style={{
                display: editingId === element.id ? "block" : "none"
              }}
              name="check"
              color="grey"
              onClick={() => setEditingId(null)}
            />
          </If>
        </Grid.Column>
        <Grid.Column width={1}>
          <Icon
            name="arrow up"
            color="grey"
            onClick={() => moveElementUp(element)}
          />
        </Grid.Column>
        <Grid.Column width={1}>
          <Icon
            name="arrow down"
            color="grey"
            onClick={() => moveElementDown(element)}
          />
        </Grid.Column>
        <Grid.Column width={1}>
          {renderRemoveButton(handleElementRemove, element)}
        </Grid.Column>
      </>
    );
  };

  return (
    <>
      {blocks.length > 0 && (
        <>
          <Header
            as="h3"
            style={{
              marginBottom: "-0.5em",
              marginLeft: "0.1em"
            }}
          >
            Blocks
          </Header>
          <Menu attached="top" tabular>
            {blocks.map((block, idx) => {
              const k = `block-${idx}`;
              return (
                <Menu.Item
                  key={k}
                  onClick={() => setCurrentBlockIdx(idx)}
                  active={currentBlockIdx === idx}
                >
                  <div className="flex align-items-center">
                    <span>{getBlockLabel(block)}</span>

                    <If condition={currentBlockIdx === idx}>
                      <span
                        style={{
                          marginLeft: "1em"
                        }}
                      >
                        <Icon
                          name="arrow left"
                          onClick={e => handleMoveBlock(e, "left")}
                        />
                        <Icon
                          name="arrow right"
                          onClick={e => handleMoveBlock(e, "right")}
                        />
                      </span>
                    </If>
                  </div>
                </Menu.Item>
              );
            })}
            <Menu.Item onClick={() => handleAddBlock()}>
              <Icon circular name="add" />
            </Menu.Item>
          </Menu>
          <Segment attached="middle">
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Checkbox
                name="new_page"
                label={{
                  children: (
                    <FormattedMessage
                      id="documentsExport.dialogs.editSignature.blocks.newPage"
                      defaultMessage="New Page"
                    />
                  )
                }}
                checked={!!currentBlock.new_page}
                onChange={handleCheckboxChange}
              />
              <If condition={blocks.length > 1}>
                <Modal
                  open={deleteBlockModalIsOpen}
                  onOpen={() => setDeleteBlockModalIsOpen(true)}
                  onClose={() => setDeleteBlockModalIsOpen(false)}
                  trigger={
                    <Button basic color="red" onClick={e => e.preventDefault()}>
                      <Icon name="trash" />
                      <FormattedMessage
                        id="documentsExport.dialogs.editSignature.blocks.deleteButton"
                        defaultMessage="Delete Block"
                      />
                    </Button>
                  }
                >
                  <Modal.Header>
                    <FormattedMessage
                      id="documentsExport.dialogs.editSignature.blocks.deleteButton"
                      defaultMessage="Delete Block"
                    />
                  </Modal.Header>
                  <Modal.Content>
                    <FormattedMessage
                      id="documentsExport.dialogs.editSignature.blocks.deleteConfirmationMessage"
                      defaultMessage="Delete Block"
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => handleDeleteBlock()} color="red">
                      <FormattedMessage
                        id="meta.actions.remove"
                        defaultMessage="Delete"
                      />
                    </Button>
                    <Button
                      onClick={() => setDeleteBlockModalIsOpen(false)}
                      basic
                    >
                      <FormattedMessage
                        id="meta.actions.cancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </Modal.Actions>
                </Modal>
              </If>
            </span>
            <Input
              id="block_label"
              name="label"
              value={blockLabelValue}
              onChange={handleBlockLabelChange}
              style={{
                width: "15em",
                marginTop: "0.4em",
                marginBottom: "1.8em"
              }}
            />
          </Segment>
        </>
      )}
      <Segment attached="bottom">
        <Grid className="visual-list">
          {jsonConfig.map(element => {
            const unknownType =
              signatureElementTypes.indexOf(element.type) === -1;
            return (
              <Grid.Row key={element.id} className="element-border">
                <If condition={element.type === "text"}>
                  <Grid.Column width={12}>
                    <If condition={editingId !== element.id}>
                      <div className={`${element.style}`}>{element.value}</div>
                    </If>

                    <If
                      condition={editingId === element.id}
                      styles={{ width: "100%" }}
                    >
                      <TextElementForm
                        element={element}
                        onChange={handleElementChange}
                      />
                    </If>
                  </Grid.Column>
                </If>

                <If condition={element.type === "signature"}>
                  <Grid.Column width={12}>
                    <div
                      className="show-signature"
                      style={{
                        display: editingId !== element.id ? "block" : "none"
                      }}
                    >
                      <Header as="h5">
                        Signature: template-
                        {element.template}
                      </Header>
                      <div>
                        {element.variable_1} {element.variable_2}{" "}
                        {element.variable_3} {element.variable_4}
                      </div>
                      <div>
                        {element.current_date &&
                          new Date().toLocaleDateString("de-DE")}
                      </div>
                    </div>

                    <Grid.Row
                      className="edit-signature"
                      verticalAlign="middle"
                      style={{
                        display: editingId === element.id ? "block" : "none"
                      }}
                    >
                      <If condition={editingId === element.id}>
                        <SignatureElementForm
                          element={element}
                          onChange={handleElementChange}
                        />
                      </If>
                    </Grid.Row>
                  </Grid.Column>
                </If>

                <If condition={unknownType}>
                  <Grid.Column width={12}>
                    <Header as="h4">Type: {element.type}</Header>
                  </Grid.Column>
                </If>

                {renderActions(element, unknownType)}
              </Grid.Row>
            );
          })}
          <AddElements onAddElement={handleAddElement} />
        </Grid>
      </Segment>
    </>
  );
};

BlocksEditor.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      elements: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          value: PropTypes.string,
          new_line: PropTypes.string
        })
      ).isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default BlocksEditor;
