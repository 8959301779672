import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Form, Header, Button, Loader, Modal, Icon } from "semantic-ui-react";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import JsonEditor from "shared/components/forms/JsonEditor";
import { If } from "shared/components/elements/Conditions";
import Field from "shared/components/forms/FieldComponent";
import { cloneDeep } from "lodash";
import PropTypes, { node, arrayOf } from "prop-types";
import WysiwygEditor from "shared/components/elements/wysiwyg/WysiwygEditor";
import { MacroShape } from "shared/shapes/macro.shape";
import { getI18N, getAccount } from "shared/selectors";
import Growl from "../../../actions/growlActions";
import MacroResource from "../../../actions/macroActions";
import OptionsShape from "../../../../shared/shapes/options.shape";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "macro_id",
      label: "macro.attributes.macro_id.label",
      rule: "isRequired"
    },
    {
      id: "name",
      label: "macro.attributes.name.label",
      rule: "isRequired"
    },
    {
      id: "call_to_action",
      label: "macro.attributes.call_to_action.label",
      rule: "isRequired"
    },
    {
      id: "icon",
      label: "macro.attributes.icon.label",
      rule: "isRequired"
    },
    {
      id: "subject_template",
      label: "macro.attributes.subject_template.label",
      rule: "isRequired"
    },
    {
      id: "body_template",
      label: "macro.attributes.body_template.label",
      rule: "isRequired"
    },
    {
      id: "deadline",
      label: "macro.attributes.deadline.label",
      rule: "isRequired"
    },
    {
      id: "config",
      label: "macro.attributes.config.label",
      rule: "isRequired"
    },
    {
      id: "original_macro",
      label: "macro.attributes.original_macro.label"
    },
    {
      id: "project_ids",
      label: "macro.attributes.projects.label",
      placeholder: "macro.attributes.projects.label",
      default: []
    }
  ]
});

export const MacroDialogComponent = ({
  model,
  button,
  macros,
  projectOptions,
  resource
}) => {
  const dispatch = useDispatch();
  const i18n = useSelector(getI18N);
  const [macro, setMacro] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  if (!model) return <Loader active data-component="loader" />;

  const onOpen = () => {
    setMacro(cloneDeep(model));
    setOpen(true);
  };

  const onClose = saved => {
    setMacro(cloneDeep(saved ? macro : model));
    setLoading(false);
    setOpen(false);
  };

  const getMacroOptions = () => {
    return macros.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    }));
  };

  const handleMacroSelect = (_, { value }) => {
    const selectedMacro = macros.filter(m => m.id === value);

    if (selectedMacro[0]) {
      const { id, macro_id, name, ...newMacro } = selectedMacro[0];
      setMacro({ ...newMacro, original_macro: id, project_ids: [] });
    }
  };

  const onSubmit = data => {
    setLoading(true);
    return resource
      .save({ ...data, config: JSON.stringify(data.config) })
      .catch(err => {
        setLoading(false);
        new Growl(dispatch).error(
          "message.errorBackend.title",
          "message.errorBackend.body",
          {
            timeout: 8000,
            bodyValues: { translatedBody: err }
          }
        );
      })
      .then(response => {
        if (response.status === 200) {
          new Growl(dispatch).success(
            "macro.message.success.title",
            "macro.message.success.body",
            { timeout: 1500 }
          );
        }
        return response;
      })
      .then(response => {
        if (!response.errors) return null;
        return response.errors.forEach(error => {
          new Growl(dispatch).error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              timeout: 8000,
              bodyValues: { translatedBody: error }
            }
          );
        });
      })
      .then(() => {
        resource.fetchAll();
        onClose(true);
      });
  };

  const form = FormFactory.create(macro, i18n, {
    onChange: values => setMacro(values)
  });

  form.fields.config.props = {
    ...form.fields.config.props,
    rows: 10
  };

  form.fields.body_template.props = {
    ...form.fields.body_template.props,
    rows: 10
  };

  form.fields.macro_id.props.readOnly = !!macro.id;

  if (!macro.id) {
    form.fields.original_macro.props = {
      ...form.fields.original_macro.props,
      multiple: false,
      closeOnChange: true,
      search: true,
      selection: true,
      options: getMacroOptions(),
      onChange: handleMacroSelect
    };
  }

  form.fields.project_ids.props = {
    ...form.fields.project_ids.props,
    multiple: true,
    closeOnChange: true,
    search: true,
    selection: true,
    clearable: true,
    options: projectOptions
  };

  return (
    <Modal
      closeOnEscape
      closeOnDimmerClick
      closeIcon
      trigger={button}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <Header size="large">
          <Header.Content>
            <Icon name={`${macro.id ? "edit outline" : "copy outline"}`} />
            <FormattedMessage id="macro.title" values={{ name: macro?.name }} />
          </Header.Content>
        </Header>
      </Modal.Header>

      <Modal.Content>
        <Form id="macro" data-component="macroForm">
          <If condition={macro.id === undefined} styles={{ width: "100%" }}>
            <Form.Field>
              <Field component="Select" {...form.fields.original_macro} />
            </Form.Field>
          </If>
          <Form.Field>
            <Field component="Input" {...form.fields.macro_id} />
          </Form.Field>

          <Form.Field>
            <Field component="Input" {...form.fields.name} />
          </Form.Field>

          <If condition={macro.id !== undefined} styles={{ width: "100%" }}>
            <Form.Field>
              <Field component="Input" {...form.fields.call_to_action} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.icon} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.deadline} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.subject_template} />
            </Form.Field>

            <Form.Field>
              <label htmlFor="bodyHtml">
                <FormattedMessage
                  id="macro.attributes.body_template.label"
                  defaultMessage="Vorlage Nachricht"
                />
              </label>
              <WysiwygEditor
                value={macro.body_template}
                id="bodyHtml"
                name="bodyHtml"
                onChange={({ value: newBody }) => {
                  setMacro(prevState => ({
                    ...prevState,
                    body_template: newBody
                  }));
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field component={JsonEditor} {...form.fields.config} />
            </Form.Field>

            <Form.Field>
              <Field component="Dropdown" {...form.fields.project_ids} />
            </Form.Field>
          </If>
        </Form>
      </Modal.Content>
      <Modal.Actions style={{ marginBottom: macro.id ? "20px" : "0" }}>
        <Button
          basic
          id="cancel"
          content={i18n["meta.actions.cancel"]}
          onClick={onClose}
          loading={isLoading}
        />
        <Button
          primary
          positive
          id="submit"
          content={
            macro.id ? i18n["meta.actions.save"] : i18n["meta.actions.add"]
          }
          loading={isLoading}
          onClick={form.handleSubmit(onSubmit)}
        />
      </Modal.Actions>
    </Modal>
  );
};

MacroDialogComponent.propTypes = {
  button: node.isRequired,
  model: MacroShape.isRequired,
  macros: arrayOf(MacroShape),
  projectOptions: PropTypes.arrayOf(OptionsShape),
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired
};

MacroDialogComponent.defaultProps = {
  macros: [],
  projectOptions: []
};

const mapStateToProps = state => {
  const account = getAccount(state);
  const projectOptions = account.getProjects().map(project => ({
    key: project.id,
    value: project.id,
    text: project.name
  }));
  return { projectOptions };
};

const mapDispatchToProps = dispatch => ({
  resource: new MacroResource(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MacroDialogComponent);
