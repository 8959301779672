// thanks to https://gist.github.com/penguinboy/762197#gistcomment-2380871
export default function flattenObject(object, prefix = "") {
  return Object.keys(object).reduce((prev, element) => {
    return object[element] &&
      typeof object[element] === "object" &&
      !Array.isArray(element)
      ? { ...prev, ...flattenObject(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
  }, {});
}
