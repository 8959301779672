import React, { Component } from "react";
import { Button, Icon, Image, Modal, Table, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { ProductGroupRuleSetResource } from "builder_portal/actions/productGroupActions";
import { instanceOf, number, object, string } from "prop-types";
import { get, size } from "lodash";
import { createStructuredSelector } from "reselect";
import "./productRulesDialog.scss";
import {
  getRelevantProductGroupRuleSetsById,
  getScopes
} from "builder_portal/selectors/productGroupRuleSets";
import { loadingProps } from "shared/helpers/propHelper";
import { getProductGroupsById } from "shared/selectors/unit/productData";
import { getAccount, getI18N } from "shared/selectors";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { FormattedMessage } from "react-intl";
import { withRouter } from "../../../shared/helpers/withRouter";
import RuleSetDialog from "./RuleSetDialog";

class ProductRulesDialog extends Component {
  static propTypes = {
    productGroupId: number.isRequired,
    productGroupRuleSetResource: instanceOf(ProductGroupRuleSetResource)
      .isRequired,
    ruleSets: object,
    productGroups: object,
    scopes: object,
    i18n: object,
    account: object,
    catalogId: string
  };

  constructor(props) {
    super(props);
    this.state = {
      ruleSetsLoading: !props.ruleSets
    };
  }

  updateRuleSets() {
    const { productGroupRuleSetResource } = this.props;
    this.setState({ ruleSetsLoading: true });
    return productGroupRuleSetResource.fetchAll().then(
      () => this.setState({ ruleSetsLoading: false }),
      err => {
        this.setState({ ruleSetsLoading: false });
        throw err;
      }
    );
  }

  componentDidMount() {
    if (this.props.catalogId) {
      this.updateRuleSets();
    }
  }

  render() {
    const { account } = this.props;
    if (!account.isEnabled("product_rules_admin") && !account.isSystemAdmin())
      return null;

    return (
      <Modal
        data-component="productRulesDialog"
        trigger={this.renderTrigger()}
        content={{
          content: this.renderContent()
        }}
        header="Regeln"
        closeOnDimmerClick={false}
        closeIcon
        actions={["Schließen"]}
      />
    );
  }

  renderTrigger() {
    const { ruleSets } = this.props;
    const { ruleSetsLoading } = this.state;
    return (
      <Button
        id="rule-sets"
        content={`Regeln (${size(ruleSets)})`}
        {...loadingProps(ruleSetsLoading)}
      />
    );
  }

  renderContent() {
    const { i18n, ruleSets } = this.props;

    return (
      ruleSets && (
        <Table celled textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="productRules.headers.when" />
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <FormattedMessage id="productRules.headers.then" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="productRules.headers.status" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="productRules.headers.scope" />
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(ruleSets).map(this.renderRuleSet)}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>
                <RuleSetDialog
                  ruleSet={this.getNewRuleSet()}
                  trigger={
                    <Button
                      content={i18n["meta.actions.add"]}
                      icon="add"
                      id="add-rule-set"
                    />
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )
    );
  }

  getNewRuleSet = () => ({
    product_group_when_id: this.props.productGroupId,
    product_group_then_id: this.props.productGroupId,
    rule_scope: "line_item",
    rules: []
  });

  handleActiveToggle = ruleSet => {
    const { productGroupRuleSetResource } = this.props;
    this.setState({ ruleSetsLoading: true });
    productGroupRuleSetResource
      .save({
        id: ruleSet.id,
        deactivated: !ruleSet.deactivated
      })
      .then(() => this.updateRuleSets());
  };

  renderRuleSet = ruleSet => {
    const { productGroups, scopes, i18n } = this.props;
    const whenProductGroup = productGroups[ruleSet.product_group_when_id];
    const thenProductGroup = productGroups[ruleSet.product_group_then_id];

    return (
      <Table.Row key={ruleSet.id}>
        <Table.Cell>
          {whenProductGroup && (
            <Image avatar src={whenProductGroup.thumb_url} />
          )}
          {whenProductGroup && whenProductGroup.name}
        </Table.Cell>
        <Table.Cell>
          <Icon name="arrow right" />
        </Table.Cell>
        <Table.Cell>
          {thenProductGroup && (
            <Image avatar src={thenProductGroup.thumb_url} />
          )}
          {thenProductGroup && thenProductGroup.name}
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            checked={!ruleSet.deactivated}
            toggle
            onClick={() => this.handleActiveToggle(ruleSet)}
          />
        </Table.Cell>
        <Table.Cell>{scopes[ruleSet.rule_scope]}</Table.Cell>
        <Table.Cell>
          <RuleSetDialog
            ruleSet={ruleSet}
            trigger={<Button content={i18n["meta.actions.edit"]} />}
          />
          {this.renderDeleteButton(ruleSet)}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderDeleteButton = ruleSet => {
    const { i18n } = this.props;
    const buttons = [
      {
        id: "delete",
        label: i18n["meta.actions.remove"],
        color: "red",
        onClick: this.getHandleDeleteConfirm(ruleSet.id)
      },
      {
        id: "cancel",
        label: i18n["meta.actions.cancel"],
        basic: true
      }
    ];

    const button = <Button icon="trash" />;

    return (
      <ConfirmationDialog
        title={i18n["productRules.actions.removeDialog.title"]}
        message={i18n["productRules.actions.removeDialog.message"]}
        buttons={buttons}
        button={button}
      />
    );
  };

  getHandleDeleteConfirm = ruleSetId => callback => {
    const { productGroupRuleSetResource } = this.props;
    productGroupRuleSetResource
      .remove(ruleSetId)
      .then(() => productGroupRuleSetResource.fetchAll())
      .then(callback, callback);
  };
}

const mapStateToProps = createStructuredSelector({
  ruleSets: getRelevantProductGroupRuleSetsById,
  productGroups: getProductGroupsById,
  scopes: getScopes,
  i18n: getI18N,
  account: getAccount,
  catalogId: (state, props) => get(props, "params.catalogId", 0)
});

const mapDispatchToProps = (dispatch, props) => ({
  productGroupRuleSetResource: new ProductGroupRuleSetResource(
    props.params.catalogId,
    dispatch
  )
});

const ProductRulesDialogContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductRulesDialog)
);
ProductRulesDialogContainer.propTypes = {
  productGroupId: number.isRequired
};
export default ProductRulesDialogContainer;
