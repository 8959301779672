import PropTypes from "prop-types";
import React from "react";
import { Label, Popup } from "semantic-ui-react";
import { groupBy } from "lodash";

export default class RoomBookProgress extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    unitItem: PropTypes.object,
    unitStatistics: PropTypes.object,
    processDefinition: PropTypes.object
  };

  /*
   * segments: [{
   *   progress: 10,
   *   color: 'red',
   *   label: 'some label'
   * }]
   *
   */
  static propTypes = {
    segments: PropTypes.array
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { unitItem, i18n, unitStatistics, processDefinition } = this.props;

    let stats = unitStatistics[unitItem.id] || [];
    const phases = processDefinition.getPhases(true);

    if (unitItem.room_book_id) {
      let total = stats.reduce((sum, stat) => {
        return sum + stat.count;
      }, 0);

      if (total === 0) {
        stats = [
          {
            phase: "open",
            count: 1
          }
        ];
        total = 1;
      }

      const statsByPhase = groupBy(stats, "phase");

      const progressSegments = phases.map(phase => {
        const base = {
          phase: phase.id,
          count: 0
        };
        const s = (statsByPhase[phase.id] || []).reduce((accu, item) => {
          accu.count += item.count;
          return accu;
        }, base);
        return {
          id: phase.id,
          count: s.count,
          label: phase.label,
          color: phase.color,
          progress: (s.count * 100) / total
        };
      });

      const progressBar = this.renderSegments(progressSegments);
      const legend = (
        <table className="legend">
          <tbody>
            {progressSegments.map(segment => {
              return (
                <tr key={segment.id}>
                  <td width="10%">
                    <Label
                      circular
                      empty
                      style={{ backgroundColor: segment.color }}
                    />
                  </td>
                  <td width="70%">{segment.label}</td>
                  <td width="20%" style={{ textAlign: "right" }}>
                    {Math.round(segment.progress)}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
      return (
        <div data-component="room-book-progress">
          <Popup
            trigger={progressBar}
            header={i18n["roomBook.progress.title.one"]}
            content={legend}
          />
        </div>
      );
    }
    return <span />;
  }

  renderSegments(segments) {
    const items = segments.map(segment => {
      return (
        <div
          key={segment.label}
          style={{
            backgroundColor: segment.color,
            width: `${segment.progress}%`,
            display: "inline-block"
          }}
        >
          &nbsp;
        </div>
      );
    });

    return (
      <a>
        <div>{items}</div>
      </a>
    );
  }
}
