import PropTypes from "prop-types";
import React from "react";
import { Menu } from "semantic-ui-react";

class Pager extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number,
    totalItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(proxy, data) {
    this.props.onClick(data.name);
  }

  render() {
    const { currentPage, totalItems, itemsPerPage } = this.props;
    const firstPage = 1;
    const lastPage = Math.ceil(totalItems / itemsPerPage);
    const nextPage = currentPage + 1 < lastPage ? currentPage + 1 : undefined;
    const prevPage = currentPage - 1 > firstPage ? currentPage - 1 : undefined;

    if (totalItems === 0 || lastPage === 1) {
      return null;
    }

    return (
      <Menu pagination className="margin top medium" data-component="pager">
        {currentPage !== 1 && (
          <Menu.Item name="1" onClick={this.handleItemClick} />
        )}
        {prevPage && currentPage !== 2 && <Menu.Item disabled>...</Menu.Item>}
        {prevPage && (
          <Menu.Item
            name={prevPage.toString()}
            onClick={this.handleItemClick}
          />
        )}
        <Menu.Item
          name={currentPage.toString()}
          active
          onClick={this.handleItemClick}
        />
        {nextPage && (
          <Menu.Item
            name={nextPage.toString()}
            onClick={this.handleItemClick}
          />
        )}
        {nextPage && <Menu.Item disabled>...</Menu.Item>}
        {currentPage !== lastPage && (
          <Menu.Item
            name={lastPage.toString()}
            onClick={this.handleItemClick}
          />
        )}
      </Menu>
    );
  }
}

export default Pager;
