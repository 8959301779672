import PropTypes, {
  arrayOf,
  number,
  shape,
  string,
  array,
  oneOfType
} from "prop-types";

export const MessageShape = shape({
  parent_id: number,
  project_id: oneOfType([number, string]),
  subject: string,
  // eslint-disable-next-line react/forbid-prop-types
  recipients: array,
  body: string,
  attachments: arrayOf(number),
  body_html: PropTypes.string,
  body_text: PropTypes.string
});

export default MessageShape;
