import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  Button,
  Header,
  Input,
  Checkbox,
  Icon,
  Grid
} from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { cloneDeep, pick } from "lodash";
import FloatInput from "shared/components/forms/FloatInput";
import RoomBookFormModel from "../../models/roomBook/RoomBookFormModel";
import RoomBookSelectionModel from "../../models/roomBook/RoomBookSelectionModel";
import "./roomBookEditorDialog.scss";

class RoomBookEditorDialog extends Component {
  static propTypes = {
    roomBook: PropTypes.object,
    ctrl: PropTypes.object,
    productsRepository: PropTypes.object,
    button: PropTypes.node,
    i18n: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
      roomBook: null
    };
  }

  handleOpen = () => {
    const roomBook = this.makeSectioModel(this.props);
    this.setState({
      open: true,
      isLoading: false,
      roomBook,
      selection: new RoomBookSelectionModel(roomBook)
    });
  };

  handleClose = () => {
    this.setState({ open: false, isLoading: false });
  };

  handleSave = () => {
    if (!this.state.isLoading) {
      const { roomBook } = this.state;
      const { ctrl } = this.props;

      this.setState({ isLoading: true }, () => {
        ctrl.updateSubLineItems(roomBook.getDirtyLineItemData()).then(() => {
          this.handleClose();
        });
      });
    }
  };

  makeSectioModel(props) {
    const data = cloneDeep(props.roomBook);
    return new RoomBookFormModel(data, data, props.productsRepository);
  }

  handleUpdateLineItem(lineItemId, properties) {
    const { roomBook } = this.state;
    const whitelistedProps = pick(properties, [
      "title",
      "quantity",
      "default_quantity"
    ]);
    if (roomBook.isTemplate()) {
      whitelistedProps.quantity = whitelistedProps.default_quantity;
    }
    const newRoomBook = this.state.roomBook.updateLineItem(
      lineItemId,
      whitelistedProps
    );
    this.setState({ roomBook: newRoomBook });
  }

  handleUndo(lineItem, field) {
    const originalValue = lineItem.getInitialValue(field);
    const roomBook = this.state.roomBook.updateLineItem(lineItem.getId(), {
      [field]: originalValue
    });
    this.setState({ roomBook });
  }

  handleSelectionToggle(s1, s2, li, sli) {
    const { selection } = this.state;
    this.setState({
      selection: selection.toggle(s1, s2, li, sli)
    });
  }

  handleDelete = () => {
    if (!this.state.isLoading) {
      const { ctrl } = this.props;
      const { selection } = this.state;

      this.setState({ isLoading: true }, () => {
        ctrl.deleteContent(selection.toCommand()).then(() => {
          this.handleClose();
        });
      });
    }
  };

  render() {
    const { button } = this.props;
    const { roomBook } = this.state;

    return (
      <Modal
        size="large"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <Header as="h3">
            <FormattedMessage
              id="meta.actions.edit"
              defaultValue="meta.actions.edit"
            />
          </Header>
        </Modal.Header>
        <Modal.Content>
          {roomBook && this.renderLineItems(roomBook)}
        </Modal.Content>
        <Modal.Actions>
          {roomBook && this.renderDeleteButton(roomBook)}
          {roomBook && this.renderSaveButton(roomBook)}
        </Modal.Actions>
      </Modal>
    );
  }

  renderLineItems(roomBook) {
    if (roomBook.isTemplate()) {
      return this.renderTemplateLineItems();
    }
    return this.renderRoomBookLineItems();
  }

  renderRoomBookLineItems() {
    const { roomBook, selection } = this.state;

    const content = roomBook.sections.reduce((accu, section) => {
      const sectionDisplayNumber = section.position;
      accu.push(
        <thead key={`header-${section.id}`}>
          <tr>
            <th className="th1">
              <Checkbox
                onChange={() => {
                  this.handleSelectionToggle(section.id);
                }}
                checked={selection.isSelected(section.id)}
                disabled={!selection.isSelectable(section.id)}
              />
            </th>
            <th className="th1">{sectionDisplayNumber}</th>
            <th className="th1" colSpan="7">
              {section.title}
            </th>
          </tr>
        </thead>
      );

      return section.getSections().reduce((accu, subsection) => {
        const subsectionDisplayNumber = `${sectionDisplayNumber}.${subsection.position}`;
        accu.push(
          <thead key={`header-${section.id}-${subsection.id}`}>
            <tr>
              <th>
                <Checkbox
                  onChange={() => {
                    this.handleSelectionToggle(section.id, subsection.id);
                  }}
                  checked={selection.isSelected(section.id, subsection.id)}
                  disabled={
                    selection.isSelected(section.id) ||
                    !selection.isSelectable(section.id, subsection.id)
                  }
                />
              </th>
              <th width="5%">{subsectionDisplayNumber}</th>
              <th width="18%">{subsection.title}</th>
              <th width="18%">
                <FormattedMessage
                  id="roomBook.lineItems.tabs.selection"
                  defaultValue="roomBook.lineItems.tabs.selection"
                />
              </th>
              <th width="15%">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.quantity.label"
                  defaultValue="roomBook.lineItems.attributes.quantity.label"
                />
              </th>
              <th width="18%">
                <FormattedMessage
                  id="roomBook.lineItems.tabs.standards"
                  defaultValue="roomBook.lineItems.tabs.standards"
                />
              </th>
              <th width="15%">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.quantity.label"
                  defaultValue="roomBook.lineItems.attributes.quantity.label"
                />
              </th>
              <th width="18%" className="right">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.total.label"
                  defaultValue="roomBook.lineItems.attributes.total.label"
                />
              </th>
              <th />
            </tr>
          </thead>
        );

        const items = subsection.getLineItems().reduce((accu, line_item) => {
          const lineItemDisplayNumber = `${subsectionDisplayNumber}.${line_item.position}`;
          const selected = selection.isSelected(
            section.id,
            subsection.id,
            line_item.id
          );
          const parentSelected = selection.isSelected(
            section.id,
            subsection.id
          );
          const selectable = selection.isSelectable(
            section.id,
            subsection.id,
            line_item.id
          );
          accu.push(
            <tr key={`lineItem-${line_item.id}`}>
              <th className="th3">
                <Checkbox
                  onChange={() => {
                    this.handleSelectionToggle(
                      section.id,
                      subsection.id,
                      line_item.id
                    );
                  }}
                  checked={selected}
                  disabled={parentSelected || !selectable}
                />
              </th>
              <th className="th3">{lineItemDisplayNumber}</th>
              <th className="th3" colSpan="7">
                {line_item.title}
              </th>
            </tr>
          );
          return line_item.getSubLineItems().reduce((accu, sub_line_item) => {
            const subLineItemDisplayNumber = `${lineItemDisplayNumber}.${sub_line_item.currentModel.position}`;
            const selected = selection.isSelected(
              section.id,
              subsection.id,
              line_item.id,
              sub_line_item.getId()
            );
            const parentSelected = selection.isSelected(
              section.id,
              subsection.id,
              line_item.id
            );
            const selectable = selection.isSelectable(
              section.id,
              subsection.id,
              line_item.id,
              sub_line_item.getId()
            );
            accu.push(
              <tr key={`subLineItem-${sub_line_item.getId()}`}>
                <td>
                  <Checkbox
                    onChange={() => {
                      this.handleSelectionToggle(
                        section.id,
                        subsection.id,
                        line_item.id,
                        sub_line_item.getId()
                      );
                    }}
                    checked={selected}
                    disabled={parentSelected || !selectable}
                  />
                </td>
                <td className="muted">{subLineItemDisplayNumber}</td>
                {this.renderTitleCell(sub_line_item)}
                {this.renderProductCell(sub_line_item)}
                {this.renderQuantityCell(sub_line_item)}
                {this.renderDefaultProductCell(sub_line_item)}
                {this.renderDefaultQuantityCell(sub_line_item)}
                <td className="muted right">
                  <FormatCurrency amount={sub_line_item.getTotal()} />
                </td>
                <td className="muted" style={{ textAlign: "center" }}>
                  <Icon
                    name="circle"
                    style={{ color: sub_line_item.getStatusColor() }}
                    title={sub_line_item.getStatusLabel()}
                  />
                </td>
              </tr>
            );

            return accu;
          }, accu);
        }, []);

        accu.push(
          <tbody key={`body-${section.id}-${subsection.id}`}>{items}</tbody>
        );

        return accu;
      }, accu);
    }, []);

    return <table data-component="sectionExportDialog">{content}</table>;
  }

  renderProductCell(line_item) {
    const product = line_item.getProduct();

    if (product) {
      return (
        <td className="muted" title={product.name}>
          <div className="ellipsis ellipsis-200">
            {product.supplier}, {product.name}
          </div>
        </td>
      );
    }
    return (
      <td className="muted" title={line_item.getSummary()}>
        <div className="ellipsis ellipsis-200">
          Wunschprodukt, {line_item.getSummary() || "k.a."}
        </div>
      </td>
    );
  }

  renderDefaultProductCell(line_item) {
    const product = line_item.getDefaultProduct();

    if (product) {
      return (
        <td className="muted" title={product.name}>
          <div className="ellipsis ellipsis-200">
            {product.supplier}, {product.name}
          </div>
        </td>
      );
    }
    return (
      <td className="muted" title={line_item.getDefaultSummary()}>
        <div className="ellipsis ellipsis-200">
          {line_item.getDefaultSummary() || "k.a."}
        </div>
      </td>
    );
  }

  renderTemplateLineItems() {
    const { roomBook, selection } = this.state;

    const content = roomBook.sections.reduce((accu, section) => {
      const sectionDisplayNumber = section.position;
      accu.push(
        <thead key={`header-${section.id}`}>
          <tr>
            <th className="th1">
              <Checkbox
                onChange={() => {
                  this.handleSelectionToggle(section.id);
                }}
                checked={selection.isSelected(section.id)}
                disabled={!selection.isSelectable(section.id)}
              />
            </th>
            <th className="th1">{sectionDisplayNumber}</th>
            <th className="th1" colSpan="4">
              {section.title}
            </th>
          </tr>
        </thead>
      );

      return section.getSections().reduce((accu, subsection) => {
        const subsectionDisplayNumber = `${sectionDisplayNumber}.${subsection.position}`;
        accu.push(
          <thead key={`header-${section.id}-${subsection.id}`}>
            <tr>
              <th>
                <Checkbox
                  onChange={() => {
                    this.handleSelectionToggle(section.id, subsection.id);
                  }}
                  checked={selection.isSelected(section.id, subsection.id)}
                  disabled={
                    selection.isSelected(section.id) ||
                    !selection.isSelectable(section.id, subsection.id)
                  }
                />
              </th>
              <th width="5%">{subsectionDisplayNumber}</th>
              <th width="18%">{subsection.title}</th>
              <th width="42%">
                <FormattedMessage
                  id="roomBook.lineItems.tabs.standards"
                  defaultValue="roomBook.lineItems.tabs.standards"
                />
              </th>
              <th width="15%">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.quantity.label"
                  defaultValue="roomBook.lineItems.attributes.quantity.label"
                />
              </th>
              <th width="18%" className="right">
                <FormattedMessage
                  id="roomBook.lineItems.attributes.budget.label"
                  defaultValue="roomBook.lineItems.attributes.budget.label"
                />
              </th>
            </tr>
          </thead>
        );

        const items = subsection.getLineItems().reduce((accu, line_item) => {
          const lineItemDisplayNumber = `${subsectionDisplayNumber}.${line_item.position}`;
          const selected = selection.isSelected(
            section.id,
            subsection.id,
            line_item.id
          );
          const parentSelected = selection.isSelected(
            section.id,
            subsection.id
          );
          const selectable = selection.isSelectable(
            section.id,
            subsection.id,
            line_item.id
          );
          accu.push(
            <tr key={`lineItem-${line_item.id}`}>
              <th className="th3">
                <Checkbox
                  onChange={() => {
                    this.handleSelectionToggle(
                      section.id,
                      subsection.id,
                      line_item.id
                    );
                  }}
                  checked={selected}
                  disabled={parentSelected || !selectable}
                />
              </th>
              <th className="th3">{lineItemDisplayNumber}</th>
              <th className="th3" colSpan="7">
                {line_item.title}
              </th>
            </tr>
          );
          return line_item.getSubLineItems().reduce((accu, sub_line_item) => {
            const subLineItemDisplayNumber = `${lineItemDisplayNumber}.${sub_line_item.currentModel.position}`;
            const selected = selection.isSelected(
              section.id,
              subsection.id,
              line_item.id,
              sub_line_item.getId()
            );
            const parentSelected = selection.isSelected(
              section.id,
              subsection.id,
              line_item.id
            );
            const selectable = selection.isSelectable(
              section.id,
              subsection.id,
              line_item.id,
              sub_line_item.getId()
            );
            const defaultProduct = sub_line_item.getDefaultProduct();
            accu.push(
              <tr key={sub_line_item.currentModel.id}>
                <td>
                  <Checkbox
                    onChange={() => {
                      this.handleSelectionToggle(
                        section.id,
                        subsection.id,
                        line_item.id,
                        sub_line_item.getId()
                      );
                    }}
                    checked={selected}
                    disabled={parentSelected || !selectable}
                  />
                </td>
                <td className="muted">{subLineItemDisplayNumber}</td>
                {this.renderTitleCell(sub_line_item)}
                <td className="muted" title={defaultProduct.name}>
                  <div className="ellipsis ellipsis-400">
                    {defaultProduct.supplier}, {defaultProduct.name}
                  </div>
                </td>
                {this.renderDefaultQuantityCell(sub_line_item)}
                <td className="muted right">
                  <FormatCurrency amount={sub_line_item.getBudget()} />
                </td>
              </tr>
            );

            return accu;
          }, accu);
        }, []);

        accu.push(
          <tbody key={`body-${section.id}-${subsection.id}`}>{items}</tbody>
        );

        return accu;
      }, accu);
    }, []);

    return <table data-component="sectionExportDialog">{content}</table>;
  }

  renderTitleCell(line_item) {
    return this.renderStringCell(line_item, "title", "getTitle");
  }

  renderQuantityCell(line_item) {
    return this.renderNumericCell(line_item, "quantity", "getQuantity");
  }

  renderDefaultQuantityCell(line_item) {
    return this.renderNumericCell(
      line_item,
      "default_quantity",
      "getDefaultQuantity"
    );
  }

  renderStringCell(line_item, field, accessorFunc) {
    const classNames = [];
    const dirty = line_item.isFieldDirty(field);
    const confirmable = line_item.isChangePolicy("confirm");
    if (dirty) {
      classNames.push("dirty");
    }
    if (confirmable) {
      classNames.push("confirmable");
    }
    return (
      <td
        className={classNames.join(" ")}
        title={line_item.getChangePolicy().confirmation_message}
      >
        <Input
          id={field}
          name={field}
          fluid
          onChange={(event, data) => {
            this.handleUpdateLineItem(line_item.getId(), {
              [field]: data.value
            });
          }}
          value={line_item[accessorFunc]()}
          readOnly={this.state.isLoading}
        />
        {dirty ? this.renderUndoAction(line_item, field) : null}
      </td>
    );
  }

  renderNumericCell(lineItem, field, accessorFunc) {
    if (lineItem.isFlat()) {
      return (
        <td className="muted">
          <span>
            <FormattedMessage
              id="roomBook.priceStrategy.flat"
              defaultValue="roomBook.priceStrategy.flat"
            />
          </span>
        </td>
      );
    }
    const classNames = [];
    const dirty = lineItem.isFieldDirty(field);
    const confirmable = lineItem.isChangePolicy("confirm");
    if (dirty) {
      classNames.push("dirty");
    }
    if (confirmable) {
      classNames.push("confirmable");
    }
    return (
      <td
        className={classNames.join(" ")}
        title={lineItem.getChangePolicy().confirmation_message}
      >
        <FloatInput
          id={field}
          name={field}
          autoComplete="off"
          fluid
          onChange={(event, data) => {
            this.handleUpdateLineItem(lineItem.getId(), {
              [field]: data.value
            });
          }}
          defaultValue={lineItem[accessorFunc]()}
          readOnly={this.state.isLoading}
        />
        {dirty ? this.renderUndoAction(lineItem, field) : null}
      </td>
    );
  }

  renderUndoAction(lineItem, field) {
    return (
      <Icon
        name="undo"
        className="inputAction"
        onClick={() => {
          this.handleUndo(lineItem, field);
        }}
      />
    );
  }

  renderDeleteButton() {
    const { isLoading, selection } = this.state;

    const button = (
      <Button
        color="red"
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={isLoading}
        disabled={!selection.hasSelection()}
        floated="left"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: this.handleDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.actions.removeContent.title"
        message="roomBook.actions.removeContent.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  renderSaveButton(roomBook) {
    const messages = roomBook.getConfirmationMessages();

    if (messages.length) {
      return this.renderSaveButtonWithConfirmation(messages);
    }
    return this.renderSimpleSaveButton();
  }

  renderSimpleSaveButton() {
    return (
      <Button
        color="green"
        content={<FormattedMessage id="meta.actions.save" />}
        onClick={this.handleSave}
        loading={this.state.isLoading}
      />
    );
  }

  renderSaveButtonWithConfirmation(messages) {
    const button = (
      <Button
        color="green"
        content={<FormattedMessage id="meta.actions.save" />}
        loading={this.state.isLoading}
      />
    );
    const buttons = [
      {
        id: "save",
        label: "meta.actions.save",
        color: "green",
        onClick: this.handleSave
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    const content = (
      <Grid>
        <Grid.Row>
          <Grid.Column width={1}>
            <Icon name="warning sign" color="red" size="large" />
          </Grid.Column>
          <Grid.Column width={15}>
            <p>
              <strong>
                <FormattedMessage
                  id="roomBook.lineItems.messages.warningsPresent"
                  defaultValue="roomBook.lineItems.messages.warningsPresent"
                />
              </strong>
            </p>
            <ul>
              {messages.map((m, idx) => (
                <li key={idx}>{m}</li>
              ))}
            </ul>
            <p>
              <strong>
                <FormattedMessage
                  id="roomBook.lineItems.messages.confirmChanges"
                  defaultValue="roomBook.lineItems.messages.confirmChanges"
                />
              </strong>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <ConfirmationDialog
        title="roomBook.lineItems.messages.header"
        content={content}
        buttons={buttons}
        button={button}
      />
    );
  }
}

export default RoomBookEditorDialog;
