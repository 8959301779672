/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Icon } from "semantic-ui-react";
// import { browserHistory } from "shared/routes/browserHistory";
import PropTypes from "prop-types";

const VersionHistoryLink = ({
  id,
  type,
  size,
  className,
  style,
  tooltip = "Versionsverlauf"
}) => {
  const handleClick = () => {
    // browserHistory.push(`/versions?type=${type}&id=${id}`);
    window.open(`/versions?type=${type}&id=${id}`, "_blank");
  };

  return (
    <a role="button" className={className} data-tooltip={tooltip}>
      <Icon
        name="history"
        size={size}
        style={style}
        color="grey"
        onClick={handleClick}
      />
    </a>
  );
};

VersionHistoryLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

VersionHistoryLink.defaultProps = {
  size: "large",
  className: "",
  tooltip: "Versionsverlauf",
  style: {}
};

export default VersionHistoryLink;
