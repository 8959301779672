import React from "react";
import { connect } from "react-redux";
import { arrayOf, func, number, shape, string } from "prop-types";
import {
  Button,
  Dropdown,
  Grid,
  Label,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { I18nShape } from "shared/shapes/i18n.shape";
import { If } from "shared/components/elements/Conditions";
import { MessageDraftsResource } from "builder_portal/actions/messageDraftsActions";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { getMessageDraftsNoActivities } from "builder_portal/selectors/messageDrafts";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import { MessageLabelsResource } from "builder_portal/actions/messageLabelsActions";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getAccount } from "shared/selectors";
import AccountShape from "shared/shapes/account.shape";
import { setMessagesFilter } from "../../actions/filters/messages";
import MessagesResource from "../../actions/messagesActions";
import MasterDataResource from "../../actions/messaging/masterDataActions";
import RecipientsSendStatus from "./RecipientsSendStatus";
import MessageLabelsList from "./MessageLabelsList";

import {
  getInboxMenu,
  getMessages,
  getMessagesFilter,
  getMessagesStatistics,
  getOutboxMenu,
  getProjectOptions,
  getUnitOptions,
  getSearchParams
} from "../../selectors/messages";
import MessageDialog from "./MessageDialog";
import DraftMessages from "./DraftMessages";
import MessageThreadsContainer from "./MessageThreadsContainer";

import "./messages.scss";
import Contacts from "./contacts/ContactsTable";
import Filters from "./filters/Filters";

class MessagesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMessageFormOpen: false,
      disableUnitDropdown: true,
      showDrafts: props.filter?.item === "drafts",
      currentProjectId: "all",
      currentUnitValue: "all",
      showDeliveryStatus: props.filter?.item === "status",
      isContactsOpen: false,
      isFiltersOpen: false
    };

    props.loadMasterdata();
    props.loadDrafts();
    props.loadMessageLabels();
  }

  componentDidMount() {
    const { filter } = this.props;
    if (filter.project) {
      this.handleProjectSelection(filter.project);
    }

    if (filter.project && filter.unit) {
      this.handleUnitSelection(filter.unit);
    }
  }

  closeMessageForm = () => {
    this.setState({
      isMessageFormOpen: false,
      isContactsOpen: false
    });
  };

  handleOpenMessage = () => {
    this.setState({
      isMessageFormOpen: true,
      showDrafts: false,
      messageDraft: undefined,
      showDeliveryStatus: false,
      isContactsOpen: false,
      isFiltersOpen: false
    });
  };

  handleProjectSelection = value => {
    const { filter, setFilter } = this.props;
    if (value === "all" || value === "none") {
      this.setState({ disableUnitDropdown: true });
    } else {
      this.setState({ disableUnitDropdown: false });
    }
    this.setState({ currentProjectId: value, currentUnitValue: "all" });
    setFilter({ ...filter, project: value, unit: "all", page: 1 });
  };

  handleUnitSelection = value => {
    const { filter, setFilter } = this.props;
    this.setState({ currentUnitValue: value });
    setFilter({ ...filter, unit: value, page: 1 });
  };

  handleOpenDraft = draft => {
    this.setState(prev => ({
      ...prev,
      isMessageFormOpen: true,
      isContactsOpen: false,
      isFiltersOpen: false,
      showDrafts: false,
      messageDraft: { ...draft }
    }));
  };

  renderMenu(menu, header, draftsMenu) {
    const { filter, setFilter, messageDrafts } = this.props;
    const { showDrafts, showDeliveryStatus } = this.state;
    const activeItem = filter.item || "inbound";
    const outboundMenu = menu[0]?.id?.indexOf("outbound") > -1;

    return (
      <Menu vertical fluid>
        <Menu.Item>
          <Menu.Header>{header}</Menu.Header>
          <Menu.Menu>
            {menu.map(({ id, title, className, color, label, labelCount }) => (
              <Menu.Item
                key={id}
                name={id}
                title={title}
                className={className}
                active={activeItem === id}
                onClick={() => {
                  setFilter({
                    ...filter,
                    item: id,
                    page: 1,
                    message_label: undefined
                  });
                  this.setState({
                    isMessageFormOpen: false,
                    showDrafts: false,
                    messageDraft: undefined,
                    showDeliveryStatus: false,
                    isContactsOpen: false,
                    isFiltersOpen: false
                  });
                }}
              >
                {labelCount > 0 && <Label color={color}>{labelCount}</Label>}
                {label}
              </Menu.Item>
            ))}
            <FeatureToggle featureToggleName="show_drafts">
              <If condition={!!draftsMenu}>
                <Menu.Item
                  active={showDrafts}
                  onClick={() => {
                    setFilter({ ...filter, item: "drafts" });
                    this.setState(prev => ({
                      ...prev,
                      showDrafts: true,
                      isMessageFormOpen: false,
                      messageDraft: undefined,
                      showDeliveryStatus: false
                    }));
                  }}
                >
                  <FormattedMessage id="messaging.drafts.menu.title" />
                  {messageDrafts.length > 0 && (
                    <Label color="grey">{messageDrafts.length}</Label>
                  )}
                </Menu.Item>
              </If>
            </FeatureToggle>
            <If condition={!!outboundMenu}>
              <IsSystemAdmin>
                <Menu.Item
                  active={showDeliveryStatus}
                  onClick={() => {
                    setFilter({ ...filter, item: "status" });
                    this.setState({
                      showDeliveryStatus: true,
                      showDrafts: false,
                      isMessageFormOpen: false,
                      isContactsOpen: false,
                      isFiltersOpen: false
                    });
                  }}
                >
                  <FormattedMessage id="messaging.send_status.title" />
                </Menu.Item>
              </IsSystemAdmin>
            </If>
          </Menu.Menu>
        </Menu.Item>
      </Menu>
    );
  }

  renderProjectsDropdown() {
    const { projects } = this.props;
    const { currentProjectId } = this.state;
    return (
      projects.length > 0 && (
        <Dropdown
          placeholder="Projekt"
          fluid
          selection
          value={currentProjectId}
          options={projects}
          onChange={(_, { value }) => this.handleProjectSelection(value)}
        />
      )
    );
  }

  renderUnitsDropdown() {
    const {
      currentProjectId,
      currentUnitValue,
      disableUnitDropdown
    } = this.state;
    const { units } = this.props;
    const unitOptions = units.filter(
      unit =>
        unit.project === currentProjectId ||
        unit.key === "unit-all" ||
        unit.key === "unit-unassigned"
    );
    return (
      <Dropdown
        placeholder="Wohneinheit"
        disabled={disableUnitDropdown}
        options={unitOptions}
        search
        fluid
        selection
        className="margin top medium"
        value={currentUnitValue}
        onChange={(_, { value }) => this.handleUnitSelection(value)}
      />
    );
  }

  render() {
    const {
      i18n,
      outboxMenu,
      inboxMenu,
      filter,
      setFilter,
      account
    } = this.props;
    const {
      isMessageFormOpen,
      disableUnitDropdown,
      showDrafts,
      messageDraft,
      showDeliveryStatus,
      isContactsOpen,
      isFiltersOpen
    } = this.state;

    return (
      <Grid data-component="MessagesContainer">
        <Grid.Row>
          <Grid.Column width={4}>
            <Segment>
              <Button
                content={i18n["messaging.messages_container.buttons.write"]}
                color="red"
                fluid
                onClick={this.handleOpenMessage}
              />
            </Segment>
            {this.renderProjectsDropdown()}
            {disableUnitDropdown && (
              <Popup
                trigger={<div>{this.renderUnitsDropdown()}</div>}
                mouseEnterDelay={500}
                mouseLeaveDelay={200}
              >
                <Popup.Content>
                  <FormattedMessage
                    id="messaging.messages_container.popup.unit_selection_disabled"
                    defaultMessage="Bitte wählen Sie zunächst ein Projekt."
                  />
                </Popup.Content>
              </Popup>
            )}
            {!disableUnitDropdown && this.renderUnitsDropdown()}

            {this.renderMenu(
              inboxMenu,
              i18n["messaging.messages_container.menu.inbox"]
            )}
            {this.renderMenu(
              outboxMenu,
              i18n["messaging.messages_container.menu.outbox"],
              true
            )}

            <If
              condition={
                account.isEnabled("email_contacts") ||
                account.isEnabled("email_filters")
              }
              block
            >
              <Menu vertical fluid>
                <Menu.Item style={{ paddingTop: "4px" }}>
                  <Menu.Menu>
                    <FeatureToggle featureToggleName="email_contacts">
                      <Menu.Item
                        active={isContactsOpen}
                        onClick={() =>
                          this.setState({
                            isContactsOpen: true,
                            isFiltersOpen: false,
                            isMessageFormOpen: false,
                            showDrafts: false,
                            messageDraft: undefined,
                            showDeliveryStatus: false
                          })
                        }
                      >
                        <FormattedMessage id="messaging.messages_container.menu.contacts" />
                      </Menu.Item>
                    </FeatureToggle>
                    <FeatureToggle featureToggleName="email_filters">
                      <Menu.Item
                        active={isFiltersOpen}
                        onClick={() =>
                          this.setState({
                            isFiltersOpen: true,
                            isContactsOpen: false,
                            isMessageFormOpen: false,
                            showDrafts: false,
                            messageDraft: undefined,
                            showDeliveryStatus: false
                          })
                        }
                      >
                        <FormattedMessage id="messaging.messages_container.menu.filters" />
                      </Menu.Item>
                    </FeatureToggle>
                  </Menu.Menu>
                </Menu.Item>
              </Menu>
            </If>

            <FeatureToggleActive featureToggleName="message_labels">
              <MessageLabelsList setFilter={setFilter} filter={filter} />
            </FeatureToggleActive>
          </Grid.Column>
          <Grid.Column width={12}>
            <If condition={isContactsOpen}>
              <Segment>
                <Contacts />
              </Segment>
            </If>
            <If condition={isFiltersOpen}>
              <Segment>
                <Filters />
              </Segment>
            </If>
            <If condition={isMessageFormOpen}>
              <Segment>
                <MessageDialog
                  closeMessageForm={this.closeMessageForm}
                  messageDraft={messageDraft}
                />
              </Segment>
            </If>
            <If
              condition={
                !isMessageFormOpen &&
                !showDrafts &&
                !showDeliveryStatus &&
                !isContactsOpen &&
                !isFiltersOpen
              }
            >
              <MessageThreadsContainer />
            </If>
            <If condition={showDrafts}>
              <DraftMessages handleOpenDraft={this.handleOpenDraft} />
            </If>
            <If condition={!!showDeliveryStatus}>
              <RecipientsSendStatus />
            </If>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

MessagesContainer.propTypes = {
  i18n: I18nShape.isRequired,
  inboxMenu: arrayOf(
    shape({
      id: string,
      label: string,
      title: string,
      labelCount: number,
      color: string
    })
  ).isRequired,
  outboxMenu: arrayOf(shape({ id: string, label: string, title: string }))
    .isRequired,
  projects: arrayOf(shape({ key: string, text: string, value: string }))
    .isRequired,
  units: arrayOf(shape({ key: string, text: string, value: string }))
    .isRequired,
  filter: shape({ id: string, page: number, project: string }).isRequired,
  setFilter: func.isRequired,
  loadMasterdata: func.isRequired,
  loadDrafts: func.isRequired,
  loadMessageLabels: func.isRequired,
  messageDrafts: arrayOf(shape({ id: number })).isRequired,
  account: AccountShape.isRequired
};

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = state => {
  const projectOptions = getProjectOptions(state);
  const projectOptionAll = {
    key: "project-all",
    text: "Alle Projekte",
    value: "all"
  };
  const projects = [projectOptionAll, ...projectOptions];

  const unitOptions = getUnitOptions(state);
  const unitOptionAll = {
    key: "unit-all",
    text: "Alle Wohneinheiten",
    value: "all"
  };
  const units = [unitOptionAll, ...unitOptions];

  return {
    i18n: state.i18n,
    filter: getMessagesFilter(state),
    messageThreads: getMessages(state),
    messageDrafts: getMessageDraftsNoActivities(state),
    inboxMenu: getInboxMenu(state),
    outboxMenu: getOutboxMenu(state),
    projects,
    units,
    searchParams: getSearchParams(state),
    messagesStatistics: getMessagesStatistics(state),
    account: getAccount(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: setMessagesFilter(dispatch),
    loadMessages: params => {
      const resource = new MessagesResource(dispatch);
      return resource.fetchAll({ ...params, limit: 20 });
    },
    loadMasterdata: () => {
      const resource = new MasterDataResource(dispatch);
      return resource.fetchAll();
    },
    readMessage: id => {
      const resource = new MessagesResource(dispatch);
      return resource.read(id);
    },
    unreadMessage: id => {
      const resource = new MessagesResource(dispatch);
      return resource.unread(id);
    },
    loadDrafts: () => {
      const resource = new MessageDraftsResource(dispatch);
      return resource.fetchAll();
    },
    loadMessageLabels: () => {
      const resource = new MessageLabelsResource(dispatch);
      return resource.fetchAll();
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
