import React from "react";
import { useSelector } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { getAccount } from "shared/selectors";
import PropTypes from "prop-types";

const HasAccessStatisticsCenterRight = ({ children }) => {
  const account = useSelector(getAccount);

  return (
    <If condition={account.hasAccessStatisticsCenterRight()}>{children}</If>
  );
};

HasAccessStatisticsCenterRight.propTypes = {
  children: PropTypes.node
};

HasAccessStatisticsCenterRight.defaultProps = {
  children: null
};

export default HasAccessStatisticsCenterRight;
