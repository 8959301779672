import Resource from "../../shared/actions/resourceActions";
import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";
import { UPDATE_PAGE_CONTENT } from "../../shared/constants/appConstants";

export class AccountResource extends Resource {
  constructor(dispatch) {
    super(dispatch, "account", "/api/v1/account");
    this.refreshPageContentAfterCommit = true;
  }

  buyerPortal() {
    const url = `${this.url}/buyer_portal`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  getProjectSettings() {
    const url = `${this.url}/projectSettings`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  accountMembershipUsers() {
    const url = `${this.url}/account_membership_users`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class AccountProjectsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "project", "/api/v1/account/projects", "projects");
  }
}

export class AccountProjectConfigsResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "project_config",
      "/api/v1/account/project_configs",
      "project_configs"
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export class ProjectConfigSelectionsResource extends Resource {
  constructor(dispatch, project_id) {
    super(
      dispatch,
      "project_config_selection",
      `/api/v1/account/projects/${project_id}/config_selection`,
      "projects"
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export class ProcessDefinitionsResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "process_definition",
      "/api/v1/account/process_definitions"
    );
  }
}

export class MailTemplatesResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "mail_template",
      "/api/v1/account/mail_templates",
      "mail_templates"
    );
    this.refreshPageContentAfterCommit = true;
  }

  loadExampleParams() {
    const url = `${this.url}/example_params`;
    return apiRequest.get(url, this.dispatch).then(response => {
      this.dispatch({
        type: UPDATE_PAGE_CONTENT,
        payload: { mailTemplateExampleParams: response.data }
      });
    });
  }
}

export class AccountUsersResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "user", "/api/v1/account/users");
  }

  emailCheck(email) {
    const url = `${this.url}/email_check`;
    return apiRequest
      .post(url, this.dispatch, { user: { email } })
      .then(response => {
        return response.data;
      });
  }

  invite(values) {
    const url = `${this.url}/invitation`;
    return apiRequest
      .post(url, this.dispatch, { user: values })
      .then(response => {
        return response.data;
      });
  }

  update_account_membership(id, values) {
    const url = `${this.url}/${id}/account_membership`;
    return apiRequest
      .patch(url, this.dispatch, { account_membership: values })
      .then(this.propagateUpdate.bind(this))
      .then(response => {
        return response.data;
      });
  }

  addUserToProject(options) {
    const url = `/api/v1/projects/${options.projectId}/memberships`;
    return apiRequest.post(url, this.dispatch, {
      project_membership: {
        user_id: options.userId,
        role: options.role
      }
    });
  }

  removeProjectMembership(options) {
    const url = `/api/v1/projects/${options.projectId}/memberships/${options.membershipId}`;
    return apiRequest.delete(url, this.dispatch);
  }

  updateProjectMembership(options) {
    const url = `/api/v1/projects/${options.projectId}/memberships/${options.id}`;
    return apiRequest.patch(url, this.dispatch, {
      project_membership: {
        roles: options.roles,
        mail_template_id: options.mailTemplateId,
        project_role_id: options.projectRoleId,
        project_email_id: options.projectEmailId
      }
    });
  }
}

export class RestorationResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "deletable_types", "/api/v1/account/deletable_types");
  }

  getDeletedItems(type, params) {
    const queryString = params
      ? `?${Object.keys(params)
          .map(k => `${k}=${params[k]}&`)
          .join("")}`
      : "";
    const url = `/api/v1/account/deleted/${type}${queryString}`;
    return apiRequest.get(url, this.dispatch);
  }

  restoreItem(type, id) {
    const url = `/api/v1/account/deleted/${type}/${id}/restoration`;
    return apiRequest.get(url, this.dispatch);
  }
}
