import { number, string, shape } from "prop-types";

export const DesignLineGroupShape = shape({
  id: number,
  name: string,
  title: string,
  description: string,
  severity: string,
  scope: string
});

export const DesignLineShape = shape({
  id: number,
  name: string,
  title: string,
  description: string,
  image: string
});
