import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Popup } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { I18nShape } from "shared/shapes/i18n.shape";
import { formatMessage } from "../helpers/i18n";
import ConfirmableButton from "../buttons/ConfirmableButton";
import { LineItemResource } from "../../actions/roomBookActions";
import RoomBookUpgradeBundlesResource from "../../actions/roomBookUpgradeBundles";

const MAX_LABEL_PACKETS = 1;

class LineItemActivatorButton extends Component {
  static propTypes = {
    lineItem: PropTypes.object,
    i18n: I18nShape.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleUpgradeBundle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const { lineItem } = props;
    this.state = {
      loading: false,
      isUpgradeBundle: lineItem.hasUpgradeBundle(),
      upgradeBundles: lineItem.getUpgradeBundles()
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { lineItem } = props;
    const { isUpgradeBundle } = state;
    if (isUpgradeBundle !== lineItem.hasUpgradeBundle()) {
      return {
        ...state,
        isUpgradeBundle: lineItem.hasUpgradeBundle(),
        upgradeBundles: lineItem.getUpgradeBundles()
      };
    }
    return state;
  }

  render() {
    const { lineItem, i18n } = this.props;
    const { isUpgradeBundle, upgradeBundles } = this.state;

    const disabled = !this.shouldRender();
    const activated = lineItem.isActivated();
    const { loading } = this.state;
    const messageRoot = isUpgradeBundle
      ? "cartAssignment.actions.upgradeBundle"
      : "cartAssignment.actions.optionality";
    const activateIcon = isUpgradeBundle ? "box" : "check circle outline";
    const upgradeBundleNameShort =
      upgradeBundles.length <= MAX_LABEL_PACKETS
        ? upgradeBundles.map(x => x.name).join(", ")
        : `${upgradeBundles
            .filter((_, i) => i <= MAX_LABEL_PACKETS - 1)
            .map(x => x.name)
            .join(" ")} (+${upgradeBundles.length - MAX_LABEL_PACKETS})`;
    const upgradeBundleNameAll = upgradeBundles.map(x => x.name).join(", ");
    const tooltipMessageId = activated
      ? `${messageRoot}.deactivateTitle`
      : `${messageRoot}.activateTitle`;

    const props = {
      content: activated ? (
        <FormattedMessage
          id={`${messageRoot}.deactivate`}
          values={{ upgradeBundleName: upgradeBundleNameShort }}
        />
      ) : (
        <FormattedMessage
          id={`${messageRoot}.activate`}
          values={{ upgradeBundleName: upgradeBundleNameShort }}
        />
      ),
      color: activated ? "grey" : "teal",
      icon: activated ? "ban" : activateIcon,
      loading,
      "data-state": activated ? "activated" : "deactivated",
      tooltip: formatMessage(i18n[tooltipMessageId], {
        upgradeBundleName: upgradeBundleNameAll
      }),
      disabled
    };

    if (lineItem.isActivated() && lineItem.hasBuyerSelection()) {
      return this.renderWithConfirmation(props);
    }
    return this.renderWithoutConfirmation(props);
  }

  renderWithConfirmation = props => {
    return (
      <ConfirmableButton
        data-component="lineItemActivatorButton"
        compact
        basic
        confirmationTitle="cartAssignment.actions.optionality.deactivate"
        confirmationMessage="cartAssignment.message.confirmationAlert"
        confirmationButtonId={props.state}
        confirmationButtonColor={props.color}
        confirmationButtonContent={props.content}
        onConfirm={this.handleActivation}
        size="tiny"
        {...props}
      />
    );
  };

  renderWithoutConfirmation = props => {
    return (
      <Popup
        content={props.tooltip}
        trigger={
          <Button
            data-component="lineItemActivatorButton"
            compact
            basic
            onClick={this.handleActivation}
            style={{ marginBottom: "4px" }}
            {...props}
          />
        }
      />
    );
  };

  shouldRender() {
    const { lineItem } = this.props;

    if (lineItem.isInProcess()) {
      return false;
    }
    if (lineItem.hasActiveCart()) {
      return false;
    }
    return lineItem.isActivated() || lineItem.isActivatable();
  }

  handleActivation = () => {
    const { isUpgradeBundle, loading } = this.state;
    if (!loading) {
      const { toggle, toggleUpgradeBundle } = this.props;
      const resolvedToggle = isUpgradeBundle ? toggleUpgradeBundle : toggle;

      this.setState({ loading: true });
      resolvedToggle()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };
}

const mapStateToProps = (state, props) => {
  return { ...props, i18n: state.i18n };
};

const mapDispatchToProps = (dispatch, props) => {
  const { lineItem, roomBookId } = props;
  const lineItemResource = new LineItemResource(dispatch, roomBookId);
  const roomBookUpgradeBundlesResource = new RoomBookUpgradeBundlesResource(
    dispatch,
    roomBookId
  );
  return {
    toggle: () => {
      if (lineItem.isActivated()) {
        return lineItemResource.deactivate(props.lineItem.getId());
      }
      return lineItemResource.activate(props.lineItem.getId());
    },
    toggleUpgradeBundle: () => {
      if (lineItem.isActivated()) {
        return roomBookUpgradeBundlesResource.remove(
          props.lineItem.getUpgradeBundleIds()[0]
        );
      }
      return roomBookUpgradeBundlesResource.save({
        id: props.lineItem.getUpgradeBundleIds()[0]
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemActivatorButton);
