/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router";
import {
  Grid,
  Button,
  Tab,
  TextArea,
  Breadcrumb,
  Header
} from "semantic-ui-react";
import { prefix } from "./routePrefix";

const DocumentationItem = ({ documentationItem, resource }) => {
  const [editMode, setEditMode] = useState(false);
  const [editContent, setEditContent] = useState(documentationItem?.content);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditContent(documentationItem?.content || "");
  }, [documentationItem]);

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleSaveButtonClick = () => {
    setLoading(true);
    resource
      .save({
        id: documentationItem.id,
        content: editContent
      })
      .then(() => {
        resource.fetchAll().then(() => {
          setLoading(false);
          setEditMode(false);
        });
      });
  };

  const handleCancelEdit = () => {
    // Reset the edit content and disable edit mode
    setEditContent(documentationItem?.content);
    setEditMode(false);
  };

  const handleEditContentChange = event => {
    setEditContent(event.target.value);
  };

  const previewPane = (
    <Tab.Pane>
      <ReactMarkdown
        children={editContent}
        components={{
          pre: ({ node, children, ...props }) => (
            <pre
              {...props}
              style={{
                backgroundColor: "rgba(135, 131, 120, 0.15)",
                padding: "10px"
              }}
            >
              {children}
            </pre>
          )
        }}
      />
    </Tab.Pane>
  );

  const editPane = (
    <Tab.Pane>
      <TextArea
        rows={25}
        value={editContent}
        onChange={handleEditContentChange}
        style={{ width: "100%" }}
      />
    </Tab.Pane>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Breadcrumb size="small">
            <Breadcrumb.Section
              link
              as={Link}
              to={prefix}
              style={{
                color: "#2185d0",
                cursor: "pointer",
                fontSize: "14px"
              }}
            >
              API Documentation
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              active
              style={{
                fontSize: "14px"
              }}
            >
              {documentationItem?.title}
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
        <Grid.Column width={16}>
          <Grid.Row
            style={{
              width: "100%",
              marginTop: "8px"
            }}
          >
            <Grid.Column textAlign="right">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "1rem 0"
                }}
              >
                <Header as="h1">{documentationItem?.title}</Header>
                {editMode ? (
                  <Button.Group>
                    <Button
                      onClick={handleSaveButtonClick}
                      style={{ marginRight: 10 }}
                      loading={loading}
                    >
                      Save
                    </Button>
                    <Button onClick={handleCancelEdit}>Cancel</Button>
                  </Button.Group>
                ) : (
                  <Button onClick={handleEditButtonClick}>Edit</Button>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={16}>
          {editMode ? (
            <Tab
              panes={[
                { menuItem: "Edit", render: () => editPane, active: true },
                { menuItem: "Preview", render: () => previewPane }
              ]}
            />
          ) : (
            <ReactMarkdown
              children={documentationItem?.content}
              components={{
                pre: ({ node, children, ...props }) => (
                  <pre
                    {...props}
                    style={{
                      backgroundColor: "rgba(135, 131, 120, 0.15)",
                      padding: "10px"
                    }}
                  >
                    {children}
                  </pre>
                )
              }}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DocumentationItem.propTypes = {
  documentationItem: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired
};

export default DocumentationItem;
