import React from "react";
import PropTypes from "prop-types";
import { Icon, List } from "semantic-ui-react";

import "./copyToDerivedDialog.scss";

export default class DescendantsVisualizer extends React.PureComponent {
  static propTypes = {
    roomBookStructure: PropTypes.object.isRequired,
    derivedInfos: PropTypes.array.isRequired,
    root: PropTypes.bool
  };

  static defaultProps = {
    root: true
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    const {
      roomBookStructure: { template, name, derived = [] },
      root
    } = this.props;
    const { open } = this.state;

    const dropdownIcon =
      !root && derived.length > 0 ? (
        <Icon
          name="dropdown"
          rotated={open ? null : "counterclockwise"}
          onClick={event => {
            event.stopPropagation();
            this.setState({ open: !open });
          }}
        />
      ) : null;
    return (
      <div className="descendantsInfo">
        <div>
          {getIcon(template)}
          {dropdownIcon}
          {name}
        </div>
        {open && derived.length > 0 ? this.renderChildren() : null}
      </div>
    );
  }

  renderChildren() {
    return (
      <div>
        {this.renderItems(true)}
        {this.renderItems(false)}
      </div>
    );
  }

  renderItems(template) {
    const roomBooks = this.getRoomBooks(template);
    if (roomBooks.length <= 0) {
      return null;
    }
    return <List items={roomBooks.map(this.infoToItem)} />;
  }

  infoToItem = info => {
    const { derivedInfos } = this.props;
    return (
      <List.Item key={info.id}>
        <DescendantsVisualizer
          roomBookStructure={info}
          derivedInfos={derivedInfos}
          root={false}
        />
      </List.Item>
    );
  };

  getRoomBooks(template) {
    const {
      roomBookStructure: { derived = [] },
      derivedInfos
    } = this.props;
    return derived
      .filter(
        item =>
          item.template === template &&
          derivedInfos.some(i => i.room_book_id === item.id)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}

const getIcon = template => <Icon name={template ? "book" : "cube"} />;
