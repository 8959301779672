import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid, Icon } from "semantic-ui-react";

export default class ConfirmationPromptContent extends Component {
  static propTypes = {
    message: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { message } = this.props;
    return (
      <Grid>
        <Grid.Column width={1}>
          <Icon name="attention" color="red" size="large" />
        </Grid.Column>
        <Grid.Column width={15}>
          <p>
            <strong>{message}</strong>
          </p>
          <p>
            <FormattedMessage
              id="roomBook.lineItems.messages.confirmChanges"
              defaultValue="roomBook.lineItems.messages.confirmChanges"
            />
          </p>
        </Grid.Column>
      </Grid>
    );
  }
}
