import { get } from "lodash";
import { getProjectsConfig } from "./account";

const CURRENCY_MAPPING = {
  USD: "$",
  EUR: "€",
  CHF: "CHF"
};

export const getProjectCurrency = (state, projectId) => {
  const code = get(
    getProjectsConfig(state),
    [projectId, "currency_code"],
    "EUR"
  );
  const sign = CURRENCY_MAPPING[code];
  return { code, sign };
};
