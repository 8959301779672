import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  shape,
  string
} from "prop-types";
import React, { Component } from "react";
import { withI18n } from "shared/containers/i18n/i18n";
import { Button, Message, Modal } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { I18nShape } from "shared/shapes/i18n.shape";

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { open: props.open };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onSubmit(button, event) {
    this.setState({ loading: true });
    if (button.onClick) {
      button.onClick(this.handleClose, event);
    } else {
      this.handleClose();
    }
  }

  handleClick = event => event.stopPropagation();

  handleClose = () => {
    if (this.unmounted) {
      this.triggerOnCloseCallback();
    } else {
      this.setState({ loading: false, open: false }, () => {
        this.triggerOnCloseCallback();
      });
    }
  };

  triggerOnCloseCallback() {
    const { onAsyncClose } = this.props;
    if (onAsyncClose) {
      onAsyncClose();
    }
  }

  renderButtons() {
    const { i18n, buttons } = this.props;
    const { loading } = this.state;

    return buttons.map(button => {
      return (
        <Button
          key={button.id}
          id={button.id}
          className={button.color || "grey"}
          icon={button.icon}
          basic={button.basic}
          onClick={event => this.onSubmit(button, event)}
          loading={button.loading ?? loading}
          disabled={button.loading ?? loading}
          content={i18n[button.label] || button.label}
        />
      );
    });
  }

  render() {
    const {
      i18n,
      message,
      content,
      title,
      errorMessage,
      button,
      scrollContent,
      extra
    } = this.props;
    const { open } = this.state;

    return (
      <Modal
        onClick={this.handleClick}
        id="confirmation"
        size="small"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon={false}
        trigger={button}
        open={open}
        onOpen={() => this.setState({ open: true })}
        onClose={this.handleClose}
      >
        <Modal.Header>{i18n[title] || title}</Modal.Header>
        <Modal.Content scrolling={scrollContent}>
          {message ? (
            <p dangerouslySetInnerHTML={{ __html: i18n[message] || message }} />
          ) : (
            <div>{content}</div>
          )}
        </Modal.Content>
        {errorMessage && (
          <Modal.Content>
            <Message negative>
              <Message.Header>
                <FormattedMessage
                  id="message.errorSave.title"
                  defaultMessage="Hoppla"
                />
              </Message.Header>
              <p>
                <FormattedMessage
                  id="message.errorSave.body"
                  defaultMessage="Hier ist etwas schiefgelaufen. Die Daten konnten nicht gespeichert werden."
                />
              </p>
            </Message>
          </Modal.Content>
        )}
        {extra && <Modal.Content>{extra}</Modal.Content>}
        <Modal.Actions>{this.renderButtons()}</Modal.Actions>
      </Modal>
    );
  }
}
ConfirmationDialog.defaultProps = {
  onAsyncClose: undefined,
  errorMessage: undefined,
  message: undefined,
  content: undefined,
  button: undefined,
  buttons: undefined,
  open: undefined,
  scrollContent: false,
  extra: undefined
};

ConfirmationDialog.propTypes = {
  i18n: I18nShape.isRequired,
  title: oneOfType([object, string]).isRequired,
  message: string,
  content: node,
  button: node,
  buttons: arrayOf(
    shape({
      loading: bool,
      id: string,
      color: string,
      basic: bool,
      label: string,
      onClick: func
    })
  ),
  onAsyncClose: func, // be aware: This onClose is Async. No guarantee any component still exists when method is called
  errorMessage: string,
  open: bool,
  scrollContent: bool,
  extra: node
};
export default withI18n(ConfirmationDialog);
