import React, { PureComponent } from "react";
import CartCreationDialog from "builder_portal/components/roomBook/CartCreationDialog";
import ExportConfigDialog from "builder_portal/components/roomBook/ExportConfigDialog";
import { connect } from "react-redux";
import { closeModal } from "builder_portal/actions/modals";
import { arrayOf, func, shape, string, object } from "prop-types";

// You can use any register any component as a dialog here.
// It will get passed an open-prop and an onClose-prop that is a function you can call from inside
// the component to close itself.
// All other props are passed though with the dispatched action.
// see app/javascript/builder_portal/actions/modals.js
const MODALS = {
  cartCreationDialog: CartCreationDialog,
  exportConfigDialog: ExportConfigDialog
};

class Modals extends PureComponent {
  getOnClose = id => () => {
    const { onCloseModal } = this.props;
    return onCloseModal(id);
  };

  createModal = ({ id, props }) => {
    const Modal = MODALS[id];
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Modal open key={id} onClose={this.getOnClose(id)} {...props} />;
  };

  render() {
    const { modals } = this.props;

    return modals.map(this.createModal);
  }
}

Modals.propTypes = {
  onCloseModal: func.isRequired,
  modals: arrayOf(shape({ id: string, props: object })).isRequired
};

const actionCreators = { onCloseModal: closeModal };
const mapStateToProps = state => ({ modals: state.modals });

export default connect(mapStateToProps, actionCreators)(Modals);
