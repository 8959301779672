import { cloneDeep, pick, uniq } from "lodash";
import SubLineItemFormModel from "./SubLineItemFormModel";

export default class RoomBookFormModel {
  constructor(initialModel, currentModel, productsRepository) {
    this.currentModel = currentModel;
    this.productsRepository = productsRepository;
    this.initialModel = initialModel;

    const keyedInitialLineItems = initialModel.sections.reduce(
      (accu, section) => {
        return section.sections.reduce((accu, section) => {
          return section.line_items.reduce((accu, lineItem) => {
            return lineItem.sub_line_items.reduce((accu, sli) => {
              accu[sli.id] = sli;
              return accu;
            }, accu);
          }, accu);
        }, accu);
      },
      {}
    );

    this.sections = this.currentModel.sections.map(section => {
      return {
        ...section,
        getSections: () => {
          return section.sections.map(section => {
            return {
              ...section,
              getLineItems: () => {
                return section.line_items.map(lineItem => {
                  return {
                    ...lineItem,
                    getSubLineItems: () => {
                      return lineItem.sub_line_items.map(sli => {
                        const model = {
                          ...sli,
                          status_info: lineItem.status_info
                        };
                        const products = productsRepository.getGroupProducts(
                          model.product_group_id
                        );
                        return new SubLineItemFormModel(
                          keyedInitialLineItems[sli.id],
                          model,
                          products,
                          currentModel.template
                        );
                      });
                    }
                  };
                });
              }
            };
          });
        }
      };
    });
  }

  getId() {
    return this.currentModel.id;
  }

  isTemplate() {
    return this.currentModel.template;
  }

  getSections() {
    return this.sections;
  }

  getDirtyLineItemData() {
    return this.sections.reduce((accu, section) => {
      return section.getSections().reduce((accu, section) => {
        return section.getLineItems().reduce((accu, lineItem) => {
          return lineItem.getSubLineItems().reduce((accu, subLineItem) => {
            if (subLineItem.isDirty()) {
              return accu.concat([
                pick(subLineItem.currentModel, [
                  "id",
                  "title",
                  "quantity",
                  "default_quantity"
                ])
              ]);
            }
            return accu;
          }, accu);
        }, accu);
      }, accu);
    }, []);
  }

  updateLineItem(subLineItemId, properties) {
    return this.processLineItemUpdate(li => {
      if (li.id === subLineItemId) {
        return { ...li, ...properties };
      }
      return li;
    });
  }

  processLineItemUpdate(fn) {
    const model = cloneDeep(this.currentModel);
    model.sections.forEach(section => {
      section.sections.forEach(section => {
        section.line_items.forEach(li => {
          li.sub_line_items.forEach((sli, idx) => {
            li.sub_line_items[idx] = fn(sli);
          });
        });
      });
    });
    return new RoomBookFormModel(
      this.initialModel,
      model,
      this.productsRepository
    );
  }

  /*
   * Returns warning messages for all dirty line items that require
   * confirmation before saving.
   */
  getConfirmationMessages() {
    return this.sections.reduce((accu, section) => {
      return section.getSections().reduce((accu, section) => {
        return section.getLineItems().reduce((accu, lineItem) => {
          return lineItem.getSubLineItems().reduce((accu, subLineItem) => {
            if (
              subLineItem.isDirty() &&
              subLineItem.isChangePolicy("confirm")
            ) {
              accu.push(subLineItem.getChangePolicy().confirmation_message);
              return uniq(accu);
            }
            return accu;
          }, accu);
        }, accu);
      }, accu);
    }, []);
  }
}
