import { get, groupBy } from "lodash";
import { createSelector } from "reselect";

import { getPageContent } from "shared/selectors/base";
import { getSubLineItemsById, getSubLineItemsByScope } from "./roomBook";

export const getProductRules = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["product_rules"]);
});

export const getProductRulesByProductGroupWhen = createSelector(
  [getProductRules],
  productRules => {
    return groupBy(productRules, "product_group_when");
  }
);

export const getProductRulesByProductGroupThen = createSelector(
  [getProductRules],
  productRules => {
    return groupBy(productRules, "product_group_then");
  }
);

export const getActiveRules = createSelector(
  [getSubLineItemsByScope, getProductRulesByProductGroupWhen],
  (subLineItemsByScope, productRulesByProductGroupWhen) => {
    return Object.keys(productRulesByProductGroupWhen).reduce(
      (accu, productGroupId) => {
        const productRules =
          productRulesByProductGroupWhen[productGroupId] || [];
        const subLineItems =
          subLineItemsByScope.product_group[productGroupId] || [];

        return subLineItems.reduce((accu, subLineItem) => {
          productRules.forEach(ruleSet => {
            ruleSet.rules.forEach(rule => {
              if (rule.when.product.id.includes(subLineItem.product_id)) {
                accu.push({
                  scope: ruleSet.scope,
                  sub_line_item_when: subLineItem,
                  product_group_when: ruleSet.product_group_when,
                  product_group_then: ruleSet.product_group_then,
                  when: rule.when,
                  then: rule.then
                });
              }
            });
          });
          return accu;
        }, accu);
      },
      []
    );
  }
);

export const getInvalidSubLineItemsById = createSelector(
  [getSubLineItemsById, getSubLineItemsByScope, getActiveRules],
  (subLineItemsById, subLineItemsByScope, activeRules) => {
    return activeRules.reduce((accu, rule) => {
      const subLineItemWhen = rule.sub_line_item_when;
      const scopeMap = subLineItemsByScope.scope_map[subLineItemWhen.id] || {};
      const scopeId = scopeMap[rule.scope];

      (subLineItemsByScope[rule.scope][scopeId] || [])
        .filter(subLineItem => {
          return (
            subLineItem.product_group_id === rule.product_group_then &&
            !rule.then.product.id.includes(subLineItem.product_id) &&
            (!subLineItem.optional || subLineItem.activated) &&
            (!subLineItemWhen.optional || subLineItemWhen.activated) &&
            subLineItem.quantity
          );
        })
        .forEach(subLineItem => {
          accu[subLineItem.id] = {
            ...rule,
            sub_line_item_then: subLineItem
          };
        });

      return accu;
    }, {});
  }
);
