import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { countBy, get, keyBy, sortBy, toPairs } from "lodash";
import {
  Button,
  Header,
  Icon,
  Segment,
  Dropdown,
  Modal,
  TextArea
} from "semantic-ui-react";
import PriceStrategyDropdown from "builder_portal/components/productGroup/priceStrategyDropdown";
import PropTypes, { arrayOf, bool, func, shape, string } from "prop-types";

import "./productGroupSelector.scss";

class ProductGroupSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      expanded: props.expanded || false,
      selectedTrade: null,
      tradeWasGuessed: false
    };
  }

  componentDidMount() {
    this.setState({
      tradeWasGuessed: true,
      selectedTrade: this.guessTradeBySection()
    });
  }

  guessTradeBySection() {
    const { siblings } = this.props;
    const countedTrades = countBy(
      (siblings || []).reduce((accu, s) => {
        return accu.concat(s.trades);
      }, [])
    );
    return sortBy(toPairs(countedTrades), t => -t[1]).map(t => t[0])[0];
  }

  handleAddAdHoc(productGroupId) {
    this.handleAddStandard(productGroupId, true);
  }

  handleAddStandard(id, addHoc = false) {
    const { ctrl, roomBook, parentType, parentId, productGroups } = this.props;

    this.setState({ isLoading: true });
    const productGroupDictionary = keyBy(productGroups, "id");
    const prices = get(productGroupDictionary, [id, "prices"], []);
    const price = prices.find(p => p.is_default) || prices[0];

    const values = {
      product_id: !addHoc ? get(price, "product_id") : null,
      default_product_id: !addHoc ? null : get(price, "product_id"),
      product_group_id: id,
      quantity: 1,
      default_quantity: roomBook.template ? 1 : 0
    };

    if (parentType === "line_item") {
      ctrl.createSubLineItem(parentId, values).then(() => {
        this.setState({ isLoading: false });
      });
    } else {
      ctrl.createLineItem(parentId, values).then(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  mapProductsToTrades() {
    const { productGroups } = this.props;

    const sortedProductGroups = sortBy(
      productGroups.map(item => {
        return { ...item, caseInsensitiveName: item.name.toLowerCase() };
      }),
      "caseInsensitiveName"
    );

    return sortedProductGroups.reduce((trades, productGroup) => {
      return productGroup.trades.reduce((trades, trade) => {
        if (!trades[trade]) {
          trades[trade] = [];
        }
        trades[trade].push(productGroup);
        return trades;
      }, trades);
    }, {});
  }

  getSortedTrades() {
    const { productGroups, trades } = this.props;
    const tradesDictionary = keyBy(trades, "id");
    const availableTrades = {};
    productGroups.map(productGroup => {
      productGroup.trades?.map(trade => {
        if (!availableTrades[trade]) {
          availableTrades[trade] = {};
        }
        availableTrades[trade] = {
          id: trade,
          label: get(tradesDictionary, [trade, "label"], trade)
        };
      });
    });
    return sortBy(Object.values(availableTrades), "label");
  }

  handleClose() {
    this.setState({ expanded: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { i18n, trades, parentType, parentId, label } = this.props;
    const { isLoading, selectedTrade, tradeWasGuessed, expanded } = this.state;

    const tradesDictionary = keyBy(trades, "id");

    return (
      <Segment
        loading={isLoading}
        id={`position-new-${parentType}-${parentId}`}
        data-component="productGroupSelector"
        onClick={() => !expanded && this.setState({ expanded: true })}
      >
        {!expanded && (
          <div>
            <Icon name="plus" /> {label}
          </div>
        )}
        {expanded && (
          <Button
            className="closer"
            data-tooltip={i18n["meta.actions.close"]}
            icon="remove"
            onClick={this.handleClose.bind(this)}
          />
        )}
        {expanded && (
          <div
            className={
              selectedTrade ? "control productGroups" : "control trades"
            }
          >
            {selectedTrade && (
              <Header sub>
                <Button
                  content={
                    tradeWasGuessed
                      ? i18n["roomBook.otherTrades"]
                      : i18n["meta.actions.back"]
                  }
                  basic
                  icon="chevron left"
                  className="back"
                  onClick={() => {
                    this.setState({ selectedTrade: null });
                  }}
                />
                {`${label} ${get(
                  tradesDictionary,
                  [selectedTrade, "label"],
                  selectedTrade
                )}`}
              </Header>
            )}

            <div className="buttonMenu">
              {this.renderProductGroupsMenu()}

              {!selectedTrade && (
                <Header
                  sub
                >{`${label}: ${i18n["roomBook.chooseTrade"]}`}</Header>
              )}

              {!selectedTrade &&
                this.tradeSelectorList(trade => {
                  this.setState({
                    selectedTrade: trade.id
                  });
                }, selectedTrade)}

              <ProductAdHocDialog
                trades={trades}
                tradesWithProductGroups={this.getSortedTrades()}
                productGroupSelector={this}
                renderProductGroupsMenu={this.renderProductGroupsMenu}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </Segment>
    );
  }

  renderProductGroupsMenu = (
    selectedTrade,
    handleClose,
    addAsAdHoc = false
  ) => {
    const { roomBook } = this.props;
    const productGroupsByTrades = this.mapProductsToTrades();
    const groups = get(
      productGroupsByTrades,
      selectedTrade || this.state.selectedTrade,
      []
    );

    return groups
      .filter(productGroup => productGroup.prices.length > 0)
      .map(productGroup => {
        const productGroupDescription = productGroup.desciption ? (
          <span>({productGroup.desciption})</span>
        ) : (
          ""
        );
        return (
          <Button
            className="productGroup"
            basic
            onClick={() => {
              if (addAsAdHoc) {
                this.handleAddAdHoc(productGroup.id);
              } else if (roomBook.template || selectedTrade) {
                this.handleAddStandard(productGroup.id);
              } else {
                this.toggleLineItemCreationWizard(productGroup);
              }
              if (typeof handleClose === "function") handleClose();
            }}
            key={productGroup.id}
          >
            <Icon name="plus" />
            {productGroup.name}
            {productGroupDescription}
          </Button>
        );
      });
  };

  toggleLineItemCreationWizard(productGroup) {
    const { toggleDialog, parentType, parentId } = this.props;

    toggleDialog("lineItemCreationWizard", {
      productGroupId: productGroup.id,
      parentType,
      parentId
    });
  }

  tradeSelectorList = (handler, tradeId) => {
    const trades = this.getSortedTrades();
    return trades.map(trade => {
      return (
        <Button
          key={trade.id}
          content={trade.label}
          basic={trade.id !== tradeId}
          positive={trade.id === tradeId}
          className="trade"
          icon="folder"
          onClick={() => handler(trade)}
        />
      );
    });
  };
}

ProductGroupSelector.propTypes = {
  i18n: PropTypes.object,
  label: PropTypes.string,
  productGroups: PropTypes.array,
  roomBook: PropTypes.object,
  parentType: PropTypes.string,
  parentId: PropTypes.number,
  trades: PropTypes.array,
  siblings: PropTypes.array,
  ctrl: PropTypes.object,
  expanded: PropTypes.bool,
  onClose: PropTypes.func
};

export default ProductGroupSelector;

/*
  ProductAdHocDialog COmponent
*/

const EMPTY_MODEL = {
  description: "",
  individualPriceStrategy: "flat",
  selectedTrades: []
};

const modelShape = Yup.object().shape({
  description: Yup.string().required(),
  individualPriceStrategy: Yup.string().required(),
  selectedTrades: Yup.array()
    .of(Yup.string())
    .required()
});

const handleAddCustomProduct = (productGroupSelector, model, handleClose) => {
  const {
    ctrl,
    roomBook,
    parentType,
    parentId,
    i18n,
    toggleDialog,
    trades
  } = productGroupSelector.props;

  const initial_costs = model.selectedTrades.map(trade => ({
    id: null,
    cost: 0,
    excess_cost: null,
    trade
  }));
  const storeModel = {
    [`${parentType}_id`]: parentId,
    description: model.description,
    product_id: null,
    product_group_id: null,
    role: "adhoc",
    price: 0,
    default_price: 0,
    quantity: 1,
    default_quantity: 1,
    price_strategy: model.individualPriceStrategy,
    discount: 0,
    title: i18n["roomBook.customProduct"],
    costs_attributes: initial_costs,
    default_costs_attributes: []
  };

  const handleCreate = response => {
    productGroupSelector.setState({ isLoading: false }, () => {
      const event = get(response, ["data", "events", 0, "type"]);
      const payload = get(response, ["data", "events", 0, "payload"]);
      const model =
        event === "line_item_created" ? payload.sub_line_items[0] : payload;

      toggleDialog("subLineItemDialog", {
        roomBookId: roomBook.id,
        model,
        ctrl,
        trades,
        autoFocus: true,
        products: []
      });
    });
  };

  productGroupSelector.setState({ isLoading: true });
  if (parentType === "line_item") {
    return ctrl
      .createSubLineItem(parentId, storeModel)
      .then(handleCreate)
      .then(handleClose);
  }
  return ctrl
    .createLineItem(parentId, storeModel)
    .then(handleCreate)
    .then(handleClose);
};

const ProductAdHocDialog = ({
  trades,
  tradesWithProductGroups,
  productGroupSelector,
  renderProductGroupsMenu,
  isLoading
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [trade, setTrade] = useState();
  const [step, setStep] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(true);

  const [model, setModel] = useState(EMPTY_MODEL);

  useEffect(() => {
    if (trade) setModel({ ...model, selectedTrades: [trade.id] });
  }, [trade]);

  const resetDialog = () => {
    setTrade();
    setStep(1);
    setModel(EMPTY_MODEL);
  };

  const handleOpen = () => {
    resetDialog();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSelectTrade = val => setTrade(val);

  const tradeSelectorDropdown = () => {
    const tradeOptions = trades.map(tradeOption => {
      return {
        key: tradeOption.id,
        value: tradeOption.id,
        text: tradeOption.label
      };
    });

    return (
      <Dropdown
        id="tradesSelector"
        fluid
        selection
        multiple
        search
        options={tradeOptions}
        value={model.selectedTrades}
        onChange={(_, { value }) => {
          setModel({ ...model, selectedTrades: value });
        }}
      />
    );
  };

  const renderSelectTrade = () => {
    const tradeOptions = trades.map(tradeOption => {
      return {
        key: tradeOption.id,
        value: tradeOption.id,
        text: tradeOption.label
      };
    });

    return (
      <>
        <Header as="h4">
          <FormattedMessage id="roomBook.lineItems.messages.selectInitialTrades.label" />
        </Header>
        <div data-component="productGroupSelector">
          <Dropdown
            id="tradesSelector"
            fluid
            selection
            search
            options={tradeOptions}
            value={trade?.id}
            onChange={(_, { value }) => {
              handleSelectTrade({ id: value });
            }}
          />
        </div>
      </>
    );
  };

  const renderProductGroupStep = () => {
    return (
      <>
        <Header as="h4">
          <FormattedMessage id="roomBook.lineItems.wizard.subheaders.adhoc.stepProductGroupHint" />
        </Header>
        <div className="flex" style={{ flexWrap: "wrap" }}>
          {renderProductGroupsMenu(trade.id, handleClose, true).map(
            (b, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={idx} className="margin top tiny">
                {b}
              </div>
            )
          )}
        </div>
      </>
    );
  };

  const renderAdHocProduct = () => {
    return (
      <>
        <Header as="h4">
          <FormattedMessage id="roomBook.lineItems.wizard.subheaders.adhoc.stepAdhoc" />
        </Header>

        <p className="marginTop">
          <strong>
            <FormattedMessage id="roomBook.lineItems.individualProductDescription" />
          </strong>
        </p>
        <TextArea
          style={{ width: "100%" }}
          onChange={(_, { value }) =>
            setModel({ ...model, description: value })
          }
          value={model.description}
        />

        <p className="marginTop">
          <strong>
            <FormattedMessage id="product_group.attributes.price_strategy.label" />
          </strong>
        </p>
        <PriceStrategyDropdown
          onChange={(_, { value }) =>
            setModel({ ...model, individualPriceStrategy: value })
          }
          value={model.individualPriceStrategy}
        />

        <p className="marginTop">
          <strong>
            <FormattedMessage id="roomBook.lineItems.messages.trades.label" />
          </strong>
        </p>
        {tradeSelectorDropdown()}
      </>
    );
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return renderSelectTrade();
      case 2:
        return renderProductGroupStep();
      case 3:
        return renderAdHocProduct();
      default:
        return null;
    }
  };

  const renderHeader = () => {
    switch (step) {
      case 1:
        return (
          <FormattedMessage id="roomBook.lineItems.wizard.subheaders.adhoc.stepTrade" />
        );
      case 2:
        return (
          <FormattedMessage id="roomBook.lineItems.wizard.subheaders.adhoc.stepProductGroup" />
        );
      case 3:
        return (
          <FormattedMessage id="roomBook.lineItems.wizard.subheaders.adhoc.stepAdhoc" />
        );
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    handleAddCustomProduct(productGroupSelector, model, handleClose);
  };

  const moveNext = () => {
    if (
      step === 1 &&
      trade &&
      !tradesWithProductGroups.map(e => e.id).includes(trade.id)
    ) {
      setStep(3);
    } else if (step < 3) {
      setStep(step + 1);
    } else {
      handleSubmit();
    }
  };

  const movePrev = () => {
    if (step === 3) {
      setStep(1);
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const prevDisabled = () => step === 1;

  useEffect(() => {
    switch (step) {
      case 1:
        setNextDisabled(!trade);
        break;
      case 2:
        setNextDisabled(false);
        break;
      case 3:
        modelShape
          .validate(model)
          .then(() => setNextDisabled(false))
          .catch(() => setNextDisabled(true));
        break;
      default:
        setNextDisabled(true);
    }
  }, [model, trade, step]);

  const renderButtonLabel = () => {
    switch (step) {
      case 2:
        return "roomBook.lineItems.wizard.buttons.skipProductGroup";
      case 3:
        return "meta.actions.save";
      default:
        return "roomBook.lineItems.wizard.buttons.next";
    }
  };

  return (
    <Modal
      open={open}
      data-component="confirmDialog"
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
      trigger={
        <Button
          className="customProduct"
          content={intl.formatMessage({
            id: "roomBook.actions.addCustomLineItem"
          })}
          basic
          icon="idea"
        />
      }
    >
      <Modal.Header>{renderHeader()}</Modal.Header>
      <Modal.Content>{renderContent()}</Modal.Content>
      <Modal.Actions>
        <Button
          data-form="product_group"
          basic
          onClick={movePrev}
          content={intl.formatMessage({ id: "meta.actions.back" })}
          disabled={prevDisabled()}
          loading={isLoading}
        />
        <Button
          data-form="product_group"
          positive
          onClick={moveNext}
          content={intl.formatMessage({
            id: renderButtonLabel()
          })}
          disabled={nextDisabled}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

ProductAdHocDialog.propTypes = {
  trades: arrayOf(
    shape({
      id: string,
      label: string
    })
  ),
  tradesWithProductGroups: arrayOf(
    shape({
      id: string,
      label: string
    })
  ),
  productGroupSelector: func.isRequired,
  renderProductGroupsMenu: func.isRequired,
  isLoading: bool
};

ProductAdHocDialog.defaultProps = {
  isLoading: false,
  trades: [],
  tradesWithProductGroups: []
};
