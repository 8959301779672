import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Button, Message } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { uniq, difference } from "lodash";
import { If } from "shared/components/elements/Conditions";
import RoomTypesResource from "builder_portal/actions/roomTypesActions";
import { connect } from "react-redux";
import MasterData from "../../helpers/masterData";
import TradeLabel from "../helpers/TradeLabel";
import { getTagOptions } from "../../helpers/getTagOptions";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import { getRoomTypes } from "../../../shared/selectors/roomTypes";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";

class ProductGroupDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    resources: PropTypes.object,
    button: PropTypes.object,
    model: PropTypes.object,
    account: PropTypes.object,
    handleUpdate: PropTypes.func,
    trades: PropTypes.array,
    productCategories: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
    ).isRequired,
    roomTypes: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    ).isRequired,
    actions: PropTypes.shape({
      roomTypesResource: PropTypes.instanceOf(RoomTypesResource).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      group: props.model,
      categoryOptions: [],
      currentValue: props.model.glencoe_product_category
    };
  }

  componentDidMount() {
    const {
      actions: { roomTypesResource }
    } = this.props;
    roomTypesResource.fetchAll();
  }

  onOpen = () => {
    const { model, productCategories } = this.props;
    const value = model.glencoe_product_category;
    const additionalCategory =
      value && !productCategories.find(x => x.id === value)
        ? { key: value, id: value, name: value }
        : [];

    this.setState({
      open: true,
      group: model,
      currentValue: value,
      categoryOptions: this.getProductCategorieOptions(
        productCategories.concat(additionalCategory)
      )
    });
  };

  onClose = () => {
    this.setState({
      open: false
    });
  };

  onSave(values) {
    const { resources, handleUpdate } = this.props;
    const { currentValue } = this.state;
    this.setState({ isLoading: true });
    // eslint-disable-next-line no-param-reassign
    values.glencoe_product_category = currentValue;

    resources.groups.save(values).then(response => {
      this.setState({ open: false, isLoading: false });
      handleUpdate(response.data.product_group);
    });
  }

  handleAddition = (_, { value }) => {
    this.setState(prevState => ({
      categoryOptions: [
        { key: value, value, text: value },
        ...prevState.categoryOptions
      ]
    }));
  };

  handleChange = (e, { value }) => this.setState({ currentValue: value });

  getRoomTypes() {
    const { roomTypes } = this.props;
    return (
      roomTypes.map(roomType => ({
        key: roomType.id,
        value: roomType.id,
        text: roomType.name
      })) || []
    );
  }

  render() {
    const { i18n, account } = this.props;
    const { group, categoryOptions, currentValue } = this.state;
    const masterData = new MasterData(i18n);
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "name",
          label: "product_group.attributes.name.label",
          rule: "isRequired",
          autoComplete: "off",
          autoFocus: true
        },
        {
          id: "desciption",
          label: "product_group.attributes.desciption.label",
          autoComplete: "off"
        },
        {
          id: "price_strategy",
          label: "product_group.attributes.price_strategy.label",
          rule: "isRequired"
        },
        {
          id: "trades",
          label: "product_group.attributes.trades.label",
          default: [],
          rule: "isRequired"
        },
        {
          id: "portal_offer",
          label: "product_group.attributes.portal_offer.label",
          control: "Checkbox"
        },
        {
          id: "glencoe_product_category",
          label: "product_group.attributes.glencoe_product_category.label",
          placeholder:
            "product_group.attributes.glencoe_product_category.placeholder",
          autoComplete: "off",
          autoFocus: true
        },
        {
          id: "quantity_on_request",
          label: "product_group.attributes.quantity_on_request.label",
          control: "Checkbox"
        },
        {
          id: "quantity_hidden",
          label: "product_group.attributes.quantity_hidden.label",
          control: "Checkbox"
        },
        {
          id: "product_group_tags",
          label: "product_group.attributes.product_group_tags.label",
          placeholder:
            "product_group.attributes.product_group_tags.placeholder",
          default: []
        },
        {
          id: "room_type_ids",
          label: "product_group.attributes.room_types.label",
          placeholder: "product_group.attributes.room_types.placeholder",
          default: []
        },
        {
          id: "marketing_portal_offer",
          label: "product_group.attributes.marketing_portal_offer.label",
          control: "Checkbox"
        },
        {
          id: "external_id",
          label: "product_group.attributes.external_id.label",
          placeholder: "product_group.attributes.external_id.placeholder",
          autoComplete: "off"
        }
      ]
    });

    const form = FormFactory.create(group, i18n, {
      onChange: group => {
        this.setState({ group });
      }
    });

    form.fields.price_strategy.props.options = masterData.priceStrategies();
    form.fields.price_strategy.props.disabled = group.id && !group.deletable;

    // makeup trades dropdown
    form.fields.trades.props.multiple = true;
    form.fields.trades.props.selection = true;
    form.fields.trades.props.search = true;
    form.fields.trades.props.closeOnChange = true;
    form.fields.trades.props.options = this.props.trades || [];

    form.fields.product_group_tags.props = {
      ...form.fields.product_group_tags.props,
      multiple: true,
      closeOnChange: true,
      search: true,
      options: this.getProductTags(),
      upward: true
    };

    form.fields.glencoe_product_category.props = {
      ...form.fields.glencoe_product_category.props,
      closeOnChange: true,
      clearable: true,
      search: true,
      options: categoryOptions,
      upward: true,
      allowAdditions: true,
      value: currentValue,
      onAddItem: this.handleAddition,
      onChange: this.handleChange
    };

    form.fields.room_type_ids.props = {
      ...form.fields.room_type_ids.props,
      multiple: true,
      search: true,
      clearable: true,
      options: this.getRoomTypes()
    };

    const title = `product_group.actions.${group.id ? "edit" : "add"}.label`;

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick={false}
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.onOpen}
        onClose={this.onClose}
      >
        <Modal.Header>
          <FormattedMessage id={title} defaultMessage={title} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form id="product_group" data-component="productGroup">
            <Form.Field>
              <Field component="Input" {...form.fields.name} />
            </Form.Field>
            <Form.Field>
              <Field component="TextArea" {...form.fields.desciption} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.price_strategy} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.trades} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.room_type_ids} />
            </Form.Field>
            {account.isEnabled("buyer_portal") && (
              <Form.Field>
                <Field component="Checkbox" {...form.fields.portal_offer} />
              </Form.Field>
            )}
            <If condition={group.portal_offer}>
              <Form.Field>
                <Field
                  component="Checkbox"
                  {...form.fields.marketing_portal_offer}
                />
              </Form.Field>
            </If>
            <Form.Field>
              <Field
                component="Checkbox"
                {...form.fields.quantity_on_request}
              />
            </Form.Field>
            <Form.Field>
              <Field component="Checkbox" {...form.fields.quantity_hidden} />
            </Form.Field>
            {!!form.fields.product_group_tags.props.options.length && (
              <Form.Field>
                <Field component="Select" {...form.fields.product_group_tags} />
              </Form.Field>
            )}
            {this.renderTradesWarning(group)}
            <IsSystemAdmin>
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.glencoe_product_category}
                />
              </Form.Field>
            </IsSystemAdmin>
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field>
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="submit"
            data-form="product_group"
            positive
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.onSave.bind(this))}
            loading={this.state.isLoading}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  getProductTags() {
    const { account } = this.props;
    const { taxonomies } = account.data.account.config;

    return getTagOptions(taxonomies);
  }

  getProductCategorieOptions = productCategories => {
    return productCategories.map(category => {
      const { id, name } = category;
      return { key: id, value: id, text: `${name} (${id})` };
    });
  };

  renderTradesWarning(group) {
    const usedTrades = uniq(
      (group.prices || []).reduce((accu, price) => {
        return accu.concat(
          price.costs_attributes
            .filter(cost => {
              return cost.id;
            })
            .map(cost => {
              return cost.trade;
            })
        );
      }, [])
    );
    const trades = difference(usedTrades, group.trades);
    if (trades.length) {
      return (
        <Message warning>
          <div>
            <FormattedMessage
              id="product_group.warnings.trades.title"
              defaultValue="product_group.warnings.trades.title"
            />
          </div>
          <ul>
            {trades.map(t => {
              return (
                <li key={t}>
                  <TradeLabel id={t} trades={this.props.trades} />
                </li>
              );
            })}
          </ul>
          <div>
            <FormattedMessage
              id="product_group.warnings.trades.suggestion"
              defaultValue="product_group.warnings.trades.suggestion"
            />
          </div>
        </Message>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    roomTypes: getRoomTypes(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      roomTypesResource: new RoomTypesResource(dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupDialog);
