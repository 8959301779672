import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, List } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { cloneDeep, lowerFirst } from "lodash";

const cardsToAddDefaults = [
  {
    id: "0",
    type: "ImageGallery",
    label: "Adds a Image Gallery to the dashboard",
    params: {
      title: "",
      images: []
    },
    css: {}
  },
  {
    id: "1",
    type: "ProjectMap",
    label: "Adds a Project Map to the dashboard",
    css: {}
  },
  {
    id: "2",
    type: "ProjectVideo",
    label: "Adds a Project Video to the dashboard",
    css: {}
  },
  {
    id: "3",
    type: "FloorPlan",
    label: "Adds a Floor Plan to the dashboard",
    css: {}
  },
  {
    id: "4",
    type: "VideoCard",
    label: "Adds a Video Card to the dashboard",
    params: {
      title: "",
      url: ""
    },
    css: {}
  },
  {
    id: "5",
    type: "InfoItemCard",
    label: "Adds a Info Item Card to the dashboard",
    fullWidth: false,
    params: {
      label: "",
      title: "",
      subtitle: "",
      description: ""
    },
    css: {}
  },
  {
    id: "6",
    type: "DocumentCard",
    label: "Adds a Document Card to the dashboard",
    params: {
      title: "",
      role: "",
      url: ""
    },
    css: {}
  },
  {
    id: "mapCard",
    type: "MapCard",
    label: "Adds a Map Card to the dashboard",
    params: {
      title: "",
      description: "",
      address: "",
      coords: {
        lat: null,
        lang: null
      }
    },
    css: {}
  }
];

const AddNewCardDialog = ({ trigger, onAddElement }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleSelect = item => {
    const { label, ...itemProps } = item;
    onAddElement(cloneDeep(itemProps));
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="tiny"
      closeIcon
      trigger={trigger}
      onOpen={handleOpen}
    >
      <Modal.Header>
        <FormattedMessage id="configuratorDesign.dashboard.labels.addCard" />
      </Modal.Header>
      <Modal.Content>
        <List selection verticalAlign="middle" divided>
          {cardsToAddDefaults.map(element => {
            return (
              <List.Item key={element.id} onClick={() => handleSelect(element)}>
                <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <List.Content>
                    <List.Header>
                      <FormattedMessage
                        id={`configuratorDesign.dashboard.${lowerFirst(
                          element.type
                        )}.type`}
                        defaultMessage={element.type}
                      />
                    </List.Header>
                    <List.Description>
                      <FormattedMessage
                        id={`configuratorDesign.dashboard.${lowerFirst(
                          element.type
                        )}.label`}
                        defaultMessage={element.type}
                      />
                    </List.Description>
                  </List.Content>
                </div>
              </List.Item>
            );
          })}
        </List>
      </Modal.Content>
    </Modal>
  );
};

AddNewCardDialog.propTypes = {
  trigger: PropTypes.element.isRequired,
  onAddElement: PropTypes.func.isRequired
};

export default AddNewCardDialog;
