import {
  ADD_PAGE_CONTENT,
  DELETE_PAGE_CONTENT,
  UPDATE_PAGE_CONTENT,
  UPDATE_PAGE_CONTENT_MESSAGE_FORM,
  UPDATE_ROOM_BOOK,
  UPDATE_SUB_LINE_ITEM_SEARCH_RESULT,
  REMOVE_ID_FROM_PAGE_CONTENT
} from "shared/constants/appConstants";
import { isObject, mergeWith, cloneDeep } from "lodash";
import SubLineItemSearchUpdate from "shared/reducers/pageContent/subLineItemSearchUpdate";
import RoomBookUpdate from "./pageContent/roomBookUpdate";

function deepConcat(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return [...srcValue, ...objValue];
  }
  if (isObject(objValue)) {
    return mergeWith(objValue, srcValue, deepConcat);
  }
}

function removeId(state, { property, id }) {
  const oldData = state[property] || [];
  if (!oldData.filter) return state; // if state[property] is object, not an array
  const newData = oldData.filter(object => object.id !== id);

  return { ...state, [property]: newData };
}

export const pageContent = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PAGE_CONTENT:
      return {};

    case UPDATE_PAGE_CONTENT:
      return { ...state, ...action.payload };

    case ADD_PAGE_CONTENT:
      return mergeWith(cloneDeep(state), action.payload, deepConcat);

    case REMOVE_ID_FROM_PAGE_CONTENT:
      return removeId(state, action.payload);

    case UPDATE_ROOM_BOOK:
      return new RoomBookUpdate(state, action.payload).apply();

    case UPDATE_SUB_LINE_ITEM_SEARCH_RESULT:
      return new SubLineItemSearchUpdate(state, action.payload).apply();

    case UPDATE_PAGE_CONTENT_MESSAGE_FORM:
      return { ...state, messageForm: action.payload || {} };

    default:
      return state;
  }
};
