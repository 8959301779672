/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes, { shape, bool } from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import Field from "shared/components/forms/FieldComponent";
import FloatInput from "shared/components/forms/FloatInput";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import {
  getPageContent,
  getSalutations,
  getTitles,
  getAccount
} from "shared/selectors";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import HasEditContractorRoles from "shared/components/authorization/HasEditContractorRoles";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "salutation",
      label: "meta.form_of_address.salutation.label",
      autoComplete: "off"
    },
    {
      id: "title",
      label: "meta.form_of_address.title.label",
      autoComplete: "off"
    },
    {
      id: "name",
      label: "contractor.attributes.name.label",
      rule: "isRequired",
      autoComplete: "off",
      autoFocus: true
    },
    {
      id: "trades",
      label: "contractor.attributes.trades.label",
      default: [],
      rule: "isRequired"
    },
    {
      id: "contact_person",
      label: "contractor.attributes.contactPerson.label",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "email",
      label: "contractor.attributes.email.label",
      message: "contractor.attributes.email.error",
      rule: "isEmailOrEmpty",
      autoComplete: "off"
    },
    {
      id: "phone",
      label: "contractor.attributes.phone.label",
      autoComplete: "off"
    },
    {
      id: "fax",
      label: "contractor.attributes.fax.label",
      autoComplete: "off"
    },
    {
      id: "address_street",
      label: "contractor.attributes.address_street.label",
      autoComplete: "off"
    },
    {
      id: "address_number",
      label: "contractor.attributes.address_number.label",
      autoComplete: "off"
    },
    {
      id: "address_postcode",
      label: "contractor.attributes.address_postcode.label",
      autoComplete: "off"
    },
    {
      id: "address_town",
      label: "contractor.attributes.address_town.label",
      autoComplete: "off"
    },
    {
      id: "cost_accounting_mode",
      label: "contractor.attributes.cost_accounting_mode.label",
      autoComplete: "off"
    },
    {
      id: "profit_margin",
      label: "%",
      placeholder: "contractor.attributes.profit_margin.placeholder",
      autoComplete: "off"
    },
    {
      id: "external_id",
      label: "contractor.attributes.external_id.label",
      placeholder: `contractor.attributes.external_id.placeholder`,
      autoComplete: "off"
    },
    {
      id: "notes",
      label: "contractor.attributes.notes.label"
    }
  ]
});

class ContractorCreationDialog extends Component {
  static propTypes = {
    contractor: PropTypes.object,
    featureToggles: shape({ contractor_accounting_mode: bool }).isRequired,
    trades: PropTypes.array,
    button: PropTypes.node,
    resource: PropTypes.object,
    i18n: PropTypes.object
  };

  defa;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isLoading: false,
      model: cloneDeep(props.contractor)
    };
  }

  onSave = values => {
    const { isLoading } = this.state;
    if (!isLoading) {
      const { resource } = this.props;

      this.setState({ isLoading: true });
      return resource
        .save(values)
        .then(() => resource.fetchAll())
        .then(() => this.setState({ isLoading: false, open: false }));
    }
  };

  onRemove = () => {
    const { isLoading } = this.state;
    if (!isLoading) {
      const { resource } = this.props;

      this.setState({ isLoading: true });
      return resource.remove(this.state.model.id).then(() => {
        this.setState({ open: false, isLoading: false });
        return resource.fetchAll();
      });
    }
  };

  handleOpen = () => {
    this.setState({ open: true, model: cloneDeep(this.props.contractor) });
  };

  handleClose = () => {
    this.setState({ open: false, model: cloneDeep(this.props.contractor) });
  };

  render() {
    const { featureToggles, account } = this.props;
    const { model } = this.state;

    const hasEditContractorRoles = account.hasEditContractorRoles();

    const message = model.id
      ? "contractor.actions.edit"
      : "contractor.actions.new";
    const form = FormFactory.create(model, this.props.i18n, {
      onChange: model => {
        this.setState({ model });
      }
    });

    // makeup trades dropdown
    form.fields.trades.props.multiple = true;
    form.fields.trades.props.selection = true;
    form.fields.trades.props.search = true;
    form.fields.trades.props.closeOnChange = true;
    form.fields.trades.props.options = this.props.tradeOptions || [];
    form.fields.email.props.disabled = !!model.user_id;

    // makeup cost_accounting_mode dropdown
    form.fields.cost_accounting_mode.props.options = [
      {
        key: "GROSS_ACCOUNTING",
        text: this.props.i18n[
          "contractor.attributes.cost_accounting_mode.values.GROSS_ACCOUNTING.label"
        ],
        value: "GROSS_ACCOUNTING"
      },
      {
        key: "NET_ACCOUNTING",
        text: this.props.i18n[
          "contractor.attributes.cost_accounting_mode.values.NET_ACCOUNTING.label"
        ],
        value: "NET_ACCOUNTING"
      }
    ];

    form.fields.profit_margin.props.labelPosition = "right";
    form.fields.profit_margin.props.nullable = true;

    return (
      <Modal
        closeIcon
        closeOnEscape
        closeOnDimmerClick={false}
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        size="large"
      >
        <Modal.Header>
          <FormattedMessage id={message} defaultMessage={message} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form id="contractor" data-component="contractorForm">
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.name}
                props={{
                  ...form.fields.name.props,
                  readOnly: !hasEditContractorRoles
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field
                component="Select"
                {...form.fields.trades}
                props={{
                  ...form.fields.trades.props,
                  disabled: !hasEditContractorRoles
                }}
              />
            </Form.Field>

            <Form.Group>
              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.salutation}
                  props={{
                    ...form.fields.salutation.props,
                    disabled: !hasEditContractorRoles,
                    clearable: true,
                    placeholder: this.props.i18n[
                      "meta.form_of_address.salutation.nil"
                    ],
                    options: getSalutations.map(key => {
                      return {
                        key,
                        value: key,
                        text: this.props.i18n[
                          `meta.form_of_address.salutation.${key || "nil"}`
                        ]
                      };
                    })
                  }}
                />
              </Form.Field>

              <Form.Field>
                <Field
                  component="Select"
                  {...form.fields.title}
                  props={{
                    ...form.fields.title.props,
                    disabled: !hasEditContractorRoles,
                    clearable: true,
                    placeholder: this.props.i18n[
                      "meta.form_of_address.title.nil"
                    ],
                    options: getTitles.map(key => {
                      return {
                        key,
                        value: key,
                        text: this.props.i18n[
                          `meta.form_of_address.title.${key || "nil"}`
                        ]
                      };
                    })
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.contact_person}
                props={{
                  ...form.fields.contact_person.props,
                  readOnly: !hasEditContractorRoles
                }}
              />
            </Form.Field>
            <Form.Field>
              <Field
                component="Input"
                {...form.fields.email}
                props={{
                  ...form.fields.email.props,
                  readOnly: !hasEditContractorRoles
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field
                component="Input"
                {...form.fields.phone}
                props={{
                  ...form.fields.phone.props,
                  readOnly: !hasEditContractorRoles
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field
                component="Input"
                {...form.fields.fax}
                props={{
                  ...form.fields.fax.props,
                  readOnly: !hasEditContractorRoles
                }}
              />
            </Form.Field>

            <Form.Group widths="2">
              <Form.Field width="14">
                <Field
                  component="Input"
                  {...form.fields.address_street}
                  props={{
                    ...form.fields.address_street.props,
                    readOnly: !hasEditContractorRoles
                  }}
                />
              </Form.Field>

              <Form.Field width="4">
                <Field
                  component="Input"
                  {...form.fields.address_number}
                  props={{
                    ...form.fields.address_number.props,
                    readOnly: !hasEditContractorRoles
                  }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="2">
              <Form.Field width="4">
                <Field
                  component="Input"
                  {...form.fields.address_postcode}
                  props={{
                    ...form.fields.address_postcode.props,
                    readOnly: !hasEditContractorRoles
                  }}
                />
              </Form.Field>

              <Form.Field width="12">
                <Field
                  component="Input"
                  {...form.fields.address_town}
                  props={{
                    ...form.fields.address_town.props,
                    readOnly: !hasEditContractorRoles
                  }}
                />
              </Form.Field>
            </Form.Group>

            {featureToggles.contractor_accounting_mode && (
              <Form.Field>
                {this.renderSelect(
                  form.fields.cost_accounting_mode,
                  hasEditContractorRoles
                )}
              </Form.Field>
            )}

            {featureToggles.contractor_profit_margin && (
              <Form.Field>
                <label htmlFor="profit_margin">
                  <FormattedMessage
                    id="contractor.attributes.profit_margin.label"
                    defaultMessage="Aufschlag"
                  />
                </label>
                <Field
                  component={FloatInput}
                  {...form.fields.profit_margin}
                  props={{
                    ...form.fields.profit_margin.props,
                    readOnly: !hasEditContractorRoles
                  }}
                />
              </Form.Field>
            )}
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field width="16">
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
            <Form.Field width="16">
              <Field component="TextArea" {...form.fields.notes} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8} verticalAlign="middle" textAlign="left">
              <HasEditContractorRoles>
                {(model.id && this.renderDeleteButton(model)) || <div />}
              </HasEditContractorRoles>
              <IsSystemAdmin>
                <Button
                  basic
                  onClick={() =>
                    window.open(
                      `/references?subject_type=Contractor&subject_id=${model.id}`,
                      "_blank"
                    )
                  }
                >
                  <FormattedMessage
                    id="references.tooltip"
                    default="References"
                  />
                </Button>
              </IsSystemAdmin>
            </Grid.Column>
            <Grid.Column textAlign="right" width={8}>
              <Button
                type="submit"
                color="green"
                data-form="contractor"
                onClick={form.handleSubmit(this.onSave)}
                loading={this.state.isLoading}
                id={(model.id && "contractor-edit") || "contractor-new"}
                disabled={!hasEditContractorRoles}
              >
                <Icon name={hasEditContractorRoles ? "save" : "lock"} />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDeleteButton(contractor) {
    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isLoading}
        className="left floated element"
      />
    );

    if (contractor.deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemove
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="contractor.actions.removeDialog.title"
          message="contractor.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: "meta.actions.accept",
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title="contractor.actions.undeletableDialog.title"
        message="contractor.actions.undeletableDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  renderSelect = ({ props, error, message, dirty }, hasEditContractorRoles) => {
    return (
      <Field
        component="Select"
        props={{
          ...props,
          disabled: !hasEditContractorRoles
        }}
        error={error}
        message={message}
        dirty={dirty}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    pageContent: getPageContent(state),
    account: getAccount(state)
  };
};

export default connect(mapStateToProps)(ContractorCreationDialog);
