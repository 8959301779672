import PropTypes from "prop-types";
import React from "react";
import { Form, Modal, Input } from "semantic-ui-react";

import { find } from "lodash";

import { connect } from "react-redux";
import {
  AccountProjectsResource,
  AccountProjectConfigsResource
} from "builder_portal/actions/accountActions";
import JsonEditor from "shared/components/forms/JsonEditor";
import * as Sentry from "@sentry/react";
import UsageHint from "./UsageHint";

const FIELDS_EMAIL = [
  "sender_mode",
  "sender_email",
  "reply_to_email",
  "project_bcc"
];

class ProjectConfigDialog extends React.Component {
  constructor(props) {
    super(props);

    const { projectConfig, defaultOpen } = this.props;
    this.state = {
      open: defaultOpen,
      isLoading: false,
      config: projectConfig.config,
      name: projectConfig.name
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultOpen, projectConfig } = this.props;
    if (defaultOpen && !prevProps.defaultOpen) {
      this.handleOpen();
    }

    if (projectConfig && projectConfig !== prevProps.projectConfig) {
      this.writeConfigToState(projectConfig);
    }
  }

  writeConfigToState(projectConfig) {
    this.setState({
      config: projectConfig.config,
      name: projectConfig.name
    });
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = event => {
    if (!event.isDefaultPrevented || !event.isDefaultPrevented())
      this.setState({
        open: false,
        isLoading: false
      });
  };

  handleConfigChange = (event, { name, value }) => {
    const { config } = this.state;
    this.setState({ config: { ...config, [name]: value } });
  };

  handleNameChange = (event, { value }) => {
    this.setState({ name: value });
  };

  handleSave = event => {
    const { projectConfigId, projectConfigsResource } = this.props;
    const { config, name } = this.state;
    event.preventDefault();

    this.setState({ isLoading: true });

    projectConfigsResource
      .save({ id: projectConfigId, config, name })
      .then(this.handleClose)
      .catch(() => this.setState({ isLoading: false }));
  };

  renderContent() {
    const { name } = this.state;
    const { projectConfig } = this.props;

    return (
      <Modal.Content>
        <UsageHint projectConfig={projectConfig} />
        <Form>
          <Form.Input
            inline
            key="name"
            label="Name:"
            value={name}
            onChange={this.handleNameChange}
          />
          {this.renderFields()}
        </Form>
      </Modal.Content>
    );
  }

  renderFields() {
    const { projectConfig } = this.props;

    switch (projectConfig.role) {
      case "emails":
        return this.renderEmailsConfigFields();
      case "exports":
        return this.renderExportsConfigFields();
      case "hooks":
        return this.renderHooksConfigFields();
      default:
        Sentry.captureMessage(`unknown config role: ${projectConfig.role}`);
        return null;
    }
  }

  renderEmailsConfigFields() {
    const { config } = this.state;

    return FIELDS_EMAIL.map(key => (
      <Form.Field key={key}>
        <label>{key}</label>
        <Input
          name={key}
          value={config[key]}
          onChange={this.handleConfigChange}
        />
      </Form.Field>
    ));
  }

  renderHooksConfigFields() {
    const { config } = this.state;

    return Object.entries(config).map(([key, value]) => (
      <JsonEditor
        key={key}
        label={key}
        name={key}
        value={value}
        onChange={this.handleConfigChange}
      />
    ));
  }

  renderExportsConfigFields() {
    const { config } = this.state;

    return Object.entries(config).map(([key, value]) => (
      <JsonEditor
        key={key}
        label={key}
        name={key}
        value={value}
        onChange={this.handleConfigChange}
      />
    ));
  }

  render() {
    const { isLoading, open } = this.state;
    const {
      projectConfigId,
      projectConfig,
      projectsResource,
      projectConfigsResource,
      defaultOpen,
      ...passThroughProps
    } = this.props;
    const buttonStatus = {
      loading: isLoading,
      disabled: isLoading
    };

    if (!projectConfig) return null;

    return (
      <Modal
        closeIcon
        open={open}
        data-component="project-config-dialog"
        data-role={projectConfig.role}
        trigger={
          <Form.Button
            {...passThroughProps}
            icon="setting"
            content="Edit"
            data-action="edit"
          />
        }
        actions={[
          {
            content: "Abbrechen",
            negative: true,
            key: "abort",
            onClick: this.handleClose,
            ...buttonStatus
          },
          {
            content: "Speichern",
            positive: true,
            key: "save",
            onClick: this.handleSave,
            ...buttonStatus
          }
        ]}
        header="Konfiguration bearbeiten"
        content={this.renderContent()}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      />
    );
  }
}

ProjectConfigDialog.propTypes = {
  projectConfigId: PropTypes.number.isRequired,
  projectConfig: PropTypes.object.isRequired,
  projectsResource: PropTypes.instanceOf(AccountProjectsResource),
  projectConfigsResource: PropTypes.instanceOf(AccountProjectConfigsResource),
  defaultOpen: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const projectConfig = find(state.pageContent.project_configs, {
    id: props.projectConfigId
  });
  return { projectConfig };
};

const mapDispatchToProps = dispatch => ({
  projectConfigsResource: new AccountProjectConfigsResource(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectConfigDialog);
