import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import { cloneDeep, keyBy } from "lodash";
import { getAccount } from "shared/selectors";
import { Account } from "shared/models/account";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  List,
  Menu,
  Message,
  Modal,
  Segment,
  Dropdown
} from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { UnitFeatureShape } from "shared/shapes/unitFeatureGroup.shape";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import OptionsShape from "shared/shapes/options.shape";
import Field from "shared/components/forms/FieldComponent";
import FloatInput from "shared/components/forms/FloatInput";
import { formatNumber } from "shared/helpers/formatNumber";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import ConfirmationPromptContent from "builder_portal/components/buttons/ConfirmationPromptContent";
import DeleteButton from "builder_portal/components/buttons/DeleteButton";
import { ProductDropdown } from "builder_portal/components/product/ProductDropdown";
import UnitVariableTypeDialog from "../project/unitVariables/UnitVariableTypeDialog";
import {
  areProductCostsDirty,
  isProductPriceDirty
} from "../../models/roomBook/lineItemHelpers";
import ConfirmationPopover from "./ConfirmationPopover";
import LineItemFormAttachmentWrapper from "./LineItemFormAttachmentWrapper";
import LineItemCosts from "./LineItemCosts";
import SubLineItemFormModel from "../../models/roomBook/SubLineItemFormModel";
import "./subLineItemForm.scss";
import { If } from "../../../shared/components/elements/Conditions";
import FeatureToggle from "../../../shared/components/elements/FeatureToggle";
import { getConfigurationGroupOptions } from "../../selectors/configurationGroups";
import getProductFilterTagOptions from "../../selectors/getProductFilterTagOptions";

const WARNINGS_FOR_ACTION_LINK = [
  "WID_STALE_PRICE",
  "WID_STALE_EXCESS_PRICE",
  "WID_STALE_DEFAULT_PRICE",
  "WID_STALE_COSTS",
  "WID_STALE_DEFAULT_COSTS"
];

const ftDiscountEnabled = account =>
  account.isEnabled("show_line_item_discount_fields");

const showDiscount = (account, showFromModel) => {
  if (ftDiscountEnabled(account)) return true;
  return !!showFromModel;
};

class SubLineItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      model: cloneDeep(props.model),
      formModel: new SubLineItemFormModel(
        cloneDeep(props.model),
        cloneDeep(props.model),
        props.products,
        props.isTemplate
      ),
      menu: "selection",
      confirm: {
        productId: null,
        content: null,
        open: false
      },
      missingCostsError: false,
      showDeprecated: false
    };
  }

  componentDidMount() {
    this.handleUpdateModel({});
  }

  getFormFactory() {
    const { isProductLineItem, autoFocus, isTemplate } = this.props;

    let fields = [
      {
        id: "title",
        label: "roomBook.lineItems.attributes.title.label",
        placeholder: "roomBook.lineItems.attributes.title.placeholder",
        message: "roomBook.lineItems.attributes.title.error",
        rule: "isRequired",
        autoFocus
      },
      {
        id: "description",
        label: "roomBook.lineItems.attributes.description.label",
        placeholder: "roomBook.lineItems.attributes.description.placeholder",
        message: "roomBook.lineItems.attributes.description.error"
      },
      {
        id: "instructions",
        label: "roomBook.lineItems.attributes.instructions.label",
        placeholder: "roomBook.lineItems.attributes.instructions.placeholder"
      }
    ];
    if (!isProductLineItem) {
      fields = fields.concat([
        {
          id: "default_description",
          label: "roomBook.lineItems.attributes.default_description.label",
          placeholder:
            "roomBook.lineItems.attributes.default_description.placeholder",
          message: "roomBook.lineItems.attributes.default_description.error"
        }
      ]);
    }
    if (isTemplate) {
      fields = fields.concat([
        {
          id: "unit_feature_ids"
        }
      ]);
    }
    return new FormDefinition({ fields });
  }

  onSubmit = values => {
    const { isLoading } = this.state;

    if (values.costs_attributes?.length === 0) {
      this.setState({ missingCostsError: true });
      return;
    }

    if (isLoading) {
      return false;
    }
    this.setState({ isLoading: true, data: values }, this.saveModel);
  };

  saveModel = () => {
    const { i18n } = this.props;

    if (this.isFormDirty() && this.requiresConfirmation()) {
      const messageComponent = (
        <List size="big" data-component="confirm-messages-list">
          <List.Item>
            <div className="column">
              <Icon name="attention" color="red" />
            </div>
            <div className="column">
              <p>{this.getChangePolicy().confirmation_message}</p>
              <p>{i18n["roomBook.lineItems.messages.confirmChanges"]}</p>
            </div>
          </List.Item>
        </List>
      );
      this.setState({
        confirm: { open: true, productId: null, content: messageComponent },
        isLoading: false
      });
    } else {
      this.save();
    }
  };

  requiresConfirmation() {
    return this.getChangePolicy().policy === "confirm";
  }

  getChangePolicy() {
    const { formModel } = this.state;
    return formModel.getChangePolicy();
  }

  save() {
    const { isTemplate, isProductLineItem } = this.props;
    const model = cloneDeep(this.state.model);
    if (isTemplate && isProductLineItem) {
      model.product_id = model.default_product_id;
      model.quantity = model.default_quantity;
    }
    this.props.save(model);
  }

  handleConfirm = () => {
    const { products } = this.props;
    const productDictionary = keyBy({ ...products }, "id");

    if (this.state.confirm.productId) {
      const productId =
        (this.state.confirm.productId !== "custom" &&
          this.state.confirm.productId) ||
        null;
      this.changeProduct(productId, productDictionary);
    } else if (this.state.confirm.defaultProductId) {
      const productId = this.state.confirm.defaultProductId;
      this.changeDefaultProduct(productId, productDictionary);
    } else {
      this.setState({ isLoading: true }, () => {
        this.save();
      });
    }
  };

  handleCancel = () => {
    return this.setState({
      isLoading: false,
      confirm: {
        productId: null,
        content: null,
        open: false
      }
    });
  };

  handleChangeProduct = (e, data) => {
    const { i18n, products } = this.props;
    const productDictionary = keyBy({ ...products }, "id");
    const productId = data.value;

    if (productId === -1) {
      e.stopPropagation();
      this.setState(prevState => ({
        ...prevState,
        showDeprecated: !prevState.showDeprecated
      }));
      return;
    }

    this.setState({
      ...this.state,
      confirm: { ...this.state.confirm, productId, content: null }
    });

    const warnings = [];

    if (areProductCostsDirty(this.state.model, productDictionary)) {
      warnings.push(i18n["roomBook.lineItems.messages.changeOfCosts"]);
    }
    if (isProductPriceDirty(this.state.model, productDictionary)) {
      warnings.push(i18n["roomBook.lineItems.messages.changeOfPrice"]);
    }

    if (warnings.length > 0) {
      // aborted change of cost: rerender but do not change
      const messageComponent = (
        <List size="big" data-component="confirm-messages-list">
          <List.Item>
            <div className="column">
              <Icon name="attention" color="red" />
            </div>
            <div className="column">
              {warnings.map((w, idx) => {
                return <p key={idx}>{w}</p>;
              })}
            </div>
          </List.Item>
        </List>
      );
      const pseudoProductId = productId || "custom";
      this.setState({
        ...this.state,
        confirm: {
          open: true,
          productId: pseudoProductId,
          content: messageComponent
        }
      });
    } else {
      this.changeProduct(productId);
    }
  };

  changeProduct(product_id) {
    const { formModel } = this.state;
    const newFormModel = formModel.changeProduct(product_id);
    this.setState({
      model: newFormModel.currentModel,
      formModel: newFormModel,
      confirm: {
        productId: null,
        content: null,
        open: false
      }
    });
  }

  changeDefaultProduct(default_product_id) {
    const { formModel } = this.state;
    const newFormModel = formModel.changeDefaultProduct(default_product_id);
    this.setState({
      model: newFormModel.currentModel,
      formModel: newFormModel,
      confirm: {
        defaultProductId: null,
        content: null,
        open: false
      }
    });
  }

  handleChangeDefaultProduct = (proxy, data) => {
    const { i18n, products } = this.props;
    // update product options (to show new standard product)
    const productDictionary = keyBy({ ...products }, "id");
    const productId = data.value;
    const warnings = [];

    if (areProductCostsDirty(this.state.model, productDictionary, true)) {
      warnings.push(i18n["roomBook.lineItems.messages.changeOfDefaultCosts"]);
    }
    if (isProductPriceDirty(this.state.model, productDictionary, true)) {
      warnings.push(i18n["roomBook.lineItems.messages.changeOfDefaultPrice"]);
    }

    if (warnings.length > 0) {
      // aborted change of cost: rerender but do not change
      const messageComponent = (
        <List size="big" data-component="confirm-messages-list">
          <List.Item>
            <div className="column">
              <Icon name="attention" color="red" />
            </div>
            <div className="column">
              {warnings.map((w, idx) => {
                return <p key={idx}>{w}</p>;
              })}
            </div>
          </List.Item>
        </List>
      );
      this.setState({
        ...this.state,
        confirm: {
          open: true,
          defaultProductId: productId,
          content: messageComponent
        }
      });
    } else {
      this.changeDefaultProduct(productId);
    }
  };

  handleUpdateModel(values, otherState) {
    const { formModel } = this.state;

    const customCostsAndPricesConfirmed = this.checkPricesAndWarning(values);
    const newFormModel = formModel.update({
      ...values,
      custom_costs_and_prices_confirmed: customCostsAndPricesConfirmed
    });

    const update = {
      model: newFormModel.currentModel,
      formModel: newFormModel
    };

    this.setState(Object.assign(this.state, update, otherState || {}));
  }

  onCreate = id => {
    const { formModel } = this.state;

    const newFormModel = formModel.applyUnitVariableType(id);
    this.setState(prev => ({
      ...prev,
      model: newFormModel.currentModel,
      formModel: newFormModel
    }));
  };

  renderDeleteButton() {
    if (this.hasDeleteButton() && this.props.module === "roomBook") {
      const { formModel } = this.state;
      const props = {};
      if (formModel.isChangePolicy("allowed")) {
        props.confirmationMessage =
          "roomBook.lineItems.actions.removeDialog.message";
      } else {
        props.confirmationContent = (
          <ConfirmationPromptContent
            message={formModel.getChangePolicy().confirmation_message}
          />
        );
      }
      return (
        <DeleteButton
          confirmationTitle="roomBook.lineItems.actions.removeDialog.title"
          onDelete={this.props.onDelete}
          loading={this.state.isLoading}
          icon="trash"
          {...props}
        />
      );
    }
  }

  hasDeleteButton() {
    const { formModel } = this.state;
    return !!formModel.getId();
  }

  getMenuTabItems = () => {
    const { i18n } = this.props;

    const extra = [];

    extra.push({
      key: "standards",
      id: "standards",
      active: this.state.menu === "standards",
      className: "right",
      content: i18n["roomBook.lineItems.tabs.standards"],
      icon: "setting",
      onClick: () => this.setState({ menu: "standards" })
    });

    return [
      {
        key: "selection",
        id: "selection",
        active: this.state.menu === "selection",
        content: i18n["roomBook.lineItems.tabs.selection"],
        onClick: () => this.setState({ menu: "selection" })
      },
      {
        key: "costs",
        id: "costs",
        active: this.state.menu === "costs",
        content: i18n["roomBook.lineItems.tabs.costs"],
        onClick: () => this.setState({ menu: "costs" })
      },
      {
        key: "documents",
        id: "documents",
        active: this.state.menu === "documents",
        content: i18n["roomBook.lineItems.tabs.documents"],
        onClick: () => this.setState({ menu: "documents" })
      }
    ].concat(extra);
  };

  isFormDirty() {
    const { isTemplate } = this.props;
    const { formModel } = this.state;

    if (isTemplate) {
      return false;
    }

    return formModel.isDirty();
  }

  renderUnitVariableType = () => {
    const { unitVariableTypesOptions, i18n, projectId } = this.props;
    const { formModel } = this.state;

    return (
      <tr>
        <td>
          <label htmlFor="default_quantity">
            <FormattedMessage
              id="roomBook.unitVariableType"
              defaultMessage="Unit variable type"
            />
          </label>
        </td>
        <td colSpan={3}>
          <div style={{ display: "flex" }}>
            <Dropdown
              selection
              search
              clearable
              style={{ width: "100%" }}
              value={formModel.getUnitVariableType()}
              options={unitVariableTypesOptions}
              onChange={(_, { value }) => {
                this.handleUpdateModel({ unit_variable_type_id: value });
              }}
            />
            <UnitVariableTypeDialog
              model={{ identifier: "", name: "", description: "" }}
              onCreate={this.onCreate}
              projectId={projectId}
              button={
                <Button
                  id="unit_variable_type_add"
                  icon="plus"
                  basic
                  style={{ margin: "0 0 0 10px" }}
                  data-tooltip={i18n["meta.actions.add"]}
                  onClick={e => e.preventDefault()}
                />
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  renderBudget() {
    const { isTemplate, i18n } = this.props;
    const { formModel } = this.state;

    return (
      <tr>
        <td>
          <label htmlFor="default_quantity">
            <FormattedMessage
              id="roomBook.budget"
              defaultMessage="roomBook.budget"
            />
          </label>
        </td>
        <td>
          <FloatInput
            id="default_quantity"
            name="default_quantity"
            autoComplete="off"
            fluid
            label={
              i18n[`roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`]
            }
            labelPosition="right"
            onChange={(event, data) => {
              this.handleUpdateModel({ default_quantity: data.value });
            }}
            defaultValue={formModel.getDefaultQuantity()}
          />
        </td>
        <td style={{ textAlign: "center" }}>x</td>
        <td>
          <CurrencyInput
            id="default_price"
            name="default_price"
            autoComplete="off"
            fluid
            onChange={(event, data) => {
              this.handleUpdateModel({ default_price: data.value });
            }}
            defaultValue={formModel.getDefaultPrice()}
            readOnly={isTemplate}
          />
        </td>
        <td style={{ textAlign: "center" }}>=</td>
        <td>
          <CurrencyInput
            id="budget"
            name="budget"
            autoComplete="off"
            fluid
            defaultValue={formModel.getBudget()}
            readOnly
          />
        </td>
      </tr>
    );
  }

  renderProductDropdown() {
    const { i18n } = this.props;
    const { formModel, showDeprecated } = this.state;

    const productOptions = formModel.getProductOptions(showDeprecated);

    return (
      <ProductDropdown
        id="product"
        selectedId={formModel.product_selected?.id}
        value={parseInt(formModel.getProductId(), 10)}
        trigger={
          !formModel.hasProduct() && (
            <strong>{i18n["roomBook.lineItems.individualProduct"]}</strong>
          )
        }
        onChange={this.handleChangeProduct}
        options={productOptions}
      />
    );
  }

  renderDefaultProductDropdown() {
    const { i18n } = this.props;
    const { formModel } = this.state;

    if (formModel.isProductLineItem()) {
      const productOptions = formModel.getDefaultProductOptions();
      return (
        <tr>
          <td style={{ lineHeight: "3.5em" }}>
            <label htmlFor="default_product">
              <FormattedMessage
                id="roomBook.lineItems.attributes.default_product_id.label"
                defaultMessage="roomBook.lineItems.attributes.default_product_id.label"
              />
            </label>
          </td>
          <td colSpan="5">
            <ProductDropdown
              id="default_product"
              selectedId={formModel.product_default?.id}
              name="default_product_id"
              value={formModel.getDefaultProductId()}
              onChange={this.handleChangeDefaultProduct}
              noResultsMessage={i18n["meta.message.noResults"]}
              options={productOptions}
            />
          </td>
        </tr>
      );
    }
    return null;
  }

  handleWarningFn(warningId) {
    return () => {
      const { formModel } = this.state;
      const newFormModel = formModel.fixWarning(warningId);

      this.setState({
        model: newFormModel.currentModel,
        formModel: newFormModel
      });
    };
  }

  getWarnings() {
    const { formModel } = this.state;
    const { i18n } = this.props;

    return [
      [
        "isProductPriceStale",
        "productPriceDiversion",
        "WID_STALE_PRICE",
        "custom_costs_and_prices_confirmed"
      ],
      [
        "isDefaultProductPriceStale",
        "defaultProductPriceDiversion",
        "WID_STALE_DEFAULT_PRICE",
        "custom_costs_and_prices_confirmed"
      ],
      [
        "isExcessPriceStale",
        "defaultExcessPriceDiversion",
        "WID_STALE_EXCESS_PRICE",
        "custom_costs_and_prices_confirmed"
      ],
      [
        "areCostsStale",
        "costsDiversion",
        "WID_STALE_COSTS",
        "custom_costs_and_prices_confirmed"
      ],
      [
        "areDefaultCostsStale",
        "defaultCostsDiversion",
        "WID_STALE_DEFAULT_COSTS",
        "custom_costs_and_prices_confirmed"
      ],
      [
        "isQuantityStale",
        "quantityUnitVariableDiversion",
        "WID_FORCE_UNIT_VARIABLE_QUANTITY_SYNC",
        formatNumber(formModel.currentModel?.unit_variable_value)
      ],
      [
        "isDefaultQuantityStale",
        "defaultQuantityUnitVariableDiversion",
        "WID_FORCE_UNIT_VARIABLE_DEFAULT_QUANTITY_SYNC",
        formatNumber(formModel.currentModel?.unit_variable_value)
      ]
    ].reduce((warnings, warning) => {
      // hiding warning based on the value of some prop
      if (warning[3] && formModel.currentModel[warning[3]]) return warnings;

      if (formModel[warning[0]]()) {
        warnings.push({
          text: i18n[`roomBook.lineItems.messages.${warning[1]}`],
          warningId: warning[2]
        });
      }
      return warnings;
    }, []);
  }

  renderErrorModal = () => {
    const { missingCostsError } = this.state;
    return (
      <Modal
        open={missingCostsError}
        onClose={() => this.setState({ missingCostsError: false })}
        closeOnEscape
        closeOnDimmerClick
      >
        <Modal.Header>
          <FormattedMessage id="roomBook.actions.addCostItemWarning.title" />
        </Modal.Header>
        <Modal.Content>
          <Icon name="warning circle" />
          <FormattedMessage id="roomBook.actions.addCostItemWarning.body" />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ missingCostsError: false })}>
            <FormattedMessage id="meta.actions.close" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  handleCorrectPrices = event => {
    event.preventDefault();
    const { formModel } = this.state;

    const newFormModel = formModel.setCustomCostsAndPricesConfirmed(true);
    this.setState({
      model: newFormModel.currentModel,
      formModel: newFormModel
    });
  };

  checkPricesAndWarning = values => {
    const { formModel } = this.state;
    if (!formModel.currentModel.custom_costs_and_prices_confirmed) return false;

    const key = Object.keys(values)[0];
    if (!key) return formModel.currentModel.custom_costs_and_prices_confirmed;

    let priceChanged = false;
    switch (key) {
      case "costs_attributes":
        priceChanged = formModel.compareCosts(
          values[key],
          formModel.currentModel.costs_attributes
        );
        break;
      default:
        priceChanged = formModel.currentModel[key] !== values[key];
    }
    return !priceChanged;
  };

  render() {
    const {
      i18n,
      isTemplate,
      isProductLineItem,
      ctrl,
      context,
      attachments,
      trades,
      unitFeatures,
      productFilterTags,
      configurationGroups,
      account
    } = this.props;
    const { formModel, model } = this.state;

    const form = this.getFormFactory().create(model, i18n, {
      onChange: data => {
        this.handleUpdateModel(data);
      }
    });

    if (model && model.product_id) {
      form.fields.description.props.label =
        i18n["roomBook.lineItems.attributes.description.label"];
    } else {
      form.fields.description.props.label =
        i18n["roomBook.lineItems.individualProductDescription"];
    }
    form.fields.instructions.props.rows = 1;
    form.fields.description.props.rows = 3;
    if (!isProductLineItem) {
      form.fields.default_description.props.rows = 3;
    }

    if (isTemplate) {
      form.fields.unit_feature_ids.props = {
        ...form.fields.unit_feature_ids.props,
        multiple: true,
        search: true,
        selection: true,
        options: unitFeatures,
        clearable: true
      };
    }

    const MenuExampleProps = () => (
      <Menu items={this.getMenuTabItems()} stackable attached="top" />
    );

    const warnings = this.getWarnings();
    const showActionLink = warnings.some(
      w => WARNINGS_FOR_ACTION_LINK.indexOf(w.warningId) > -1
    );

    return (
      <Form
        id="line_item"
        onSubmit={form.handleSubmit(this.onSubmit)}
        data-component="lineItemForm"
        className={(isTemplate && "isTemplate") || null}
      >
        <ConfirmationPopover
          header={i18n["roomBook.lineItems.messages.header"]}
          content={this.state.confirm.content}
          open={this.state.confirm.open}
          isLoading={this.state.isLoading}
          cancelButton={i18n["roomBook.lineItems.actions.cancel"]}
          confirmButton={i18n["roomBook.lineItems.actions.confirm"]}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />

        {this.renderErrorModal()}

        <FeatureToggle featureToggleName="show_configuration_groups" disabled>
          <Form.Field>
            <Field component="Input" className="title" {...form.fields.title} />
          </Form.Field>
        </FeatureToggle>
        <FeatureToggle featureToggleName="show_configuration_groups">
          <Form.Group>
            <Form.Field width={12}>
              <Field
                component="Input"
                className="title"
                {...form.fields.title}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Form.Dropdown
                selection
                search
                clearable
                label="Positionsgruppe"
                value={formModel.getConfigurationGroup()}
                options={configurationGroups}
                onChange={(_, { value }) => {
                  this.handleUpdateModel({ configuration_group_id: value });
                }}
              />
            </Form.Field>
          </Form.Group>
        </FeatureToggle>

        {!isTemplate && <MenuExampleProps />}

        <Segment attached={isTemplate || !warnings.length ? "bottom" : "top"}>
          <div
            className={
              ((isTemplate || this.state.menu === "standards") &&
                "standards") ||
              "standards hidden"
            }
          >
            {!isProductLineItem && (
              <Field
                component="TextArea"
                {...form.fields.default_description}
              />
            )}

            <table className="calculation" width="100%">
              <thead>
                <tr>
                  <th width="30%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                  <th width="5%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                  <th width="5%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {this.renderDefaultProductDropdown()}
                {this.renderBudget()}
                <FeatureToggleActive featureToggleName="show_unit_variables">
                  {this.renderUnitVariableType()}
                </FeatureToggleActive>
                <FeatureToggleActive featureToggleName="show_unit_feature_groups">
                  <If condition={isTemplate}>
                    <tr>
                      <td>
                        <label htmlFor="unit_feature_ids">
                          <FormattedMessage id="roomBook.sections.attributes.unitFeatures.label" />
                        </label>
                      </td>
                      <td colSpan={5}>
                        <Dropdown
                          selection
                          search
                          clearable
                          multiple
                          fluid
                          style={{ width: "100%" }}
                          value={formModel.getUnitFeatureIds()}
                          options={unitFeatures}
                          onChange={(_, { value }) => {
                            this.handleUpdateModel({ unit_feature_ids: value });
                          }}
                        />
                      </td>
                    </tr>
                  </If>
                </FeatureToggleActive>
                <If condition={!!productFilterTags.length}>
                  <tr>
                    <td>
                      <label htmlFor="unit_feature_ids">Filter Tags</label>
                    </td>
                    <td colSpan={5}>
                      <Dropdown
                        selection
                        search
                        clearable
                        fluid
                        multiple
                        style={{ width: "100%" }}
                        value={formModel.getFilterTags()}
                        options={productFilterTags}
                        onChange={(_, { value }) => {
                          this.handleUpdateModel({ filter_tags: value });
                        }}
                      />
                    </td>
                  </tr>
                </If>
              </tbody>
            </table>
            {!isTemplate && this.state.menu === "standards" && (
              <Container data-component="defaultCostAttributes">
                <Header>
                  <FormattedMessage
                    id="roomBook.standardCostsAttributes"
                    defaultMessage="roomBook.standardCostsAttributes"
                  />
                </Header>
                <LineItemCosts
                  i18n={i18n}
                  trades={trades}
                  formModel={formModel}
                  costs={formModel.getDefaultCosts()}
                  price_strategy={formModel.getPriceStrategy()}
                  quantity={formModel.getDefaultQuantity()}
                  mode="defaultCosts"
                  onChange={default_costs_attributes => {
                    this.handleUpdateModel({ default_costs_attributes });
                  }}
                />
              </Container>
            )}
          </div>

          {!isTemplate && this.state.menu === "costs" && (
            <div
              className={
                (!isTemplate && this.state.menu === "costs" && "costs") ||
                "costs hidden"
              }
            >
              <LineItemCosts
                i18n={i18n}
                trades={trades}
                formModel={formModel}
                costs={formModel.getCosts()}
                price_strategy={formModel.getPriceStrategy()}
                quantity={formModel.getQuantity()}
                mode="selectedCosts"
                onChange={costs_attributes => {
                  this.handleUpdateModel({ costs_attributes });
                }}
              />
            </div>
          )}

          <div
            className={
              ((isTemplate || this.state.menu === "documents") &&
                "documents") ||
              "documents hidden"
            }
          >
            {this.state.menu === "documents" && this.state.model.id && (
              <LineItemFormAttachmentWrapper
                i18n={i18n}
                attachments={this.state.model.attachments}
                parentAttachments={
                  (attachments && attachments.map && attachments) || [
                    ...attachments.unit,
                    ...attachments.activity
                  ]
                }
                isTemplate={isTemplate}
                context={context}
                ctrl={ctrl}
                lineItemId={this.state.model.id}
                onChange={attachments => {
                  this.handleUpdateModel({ attachments });
                  return ctrl.refresh();
                }}
              />
            )}
          </div>

          <div
            className={
              (!isTemplate && this.state.menu === "selection" && "selection") ||
              "selection hidden"
            }
          >
            {isProductLineItem && (
              <Form.Field>
                <label>
                  {model?.product_id && (
                    <div className="productDropdownLabel">
                      <FormattedMessage
                        id="roomBook.lineItems.attributes.product_id.label"
                        defaultMessage="roomBook.lineItems.attributes.product_id.label"
                      />
                      <Button
                        disabled={!model.selection_is_confirmed}
                        icon
                        basic
                        type="button"
                        onClick={() => {
                          this.handleUpdateModel({
                            selection_is_confirmed: false
                          });
                        }}
                      >
                        <Icon name="undo" />
                        <FormattedMessage
                          id="roomBook.actions.clearProductStatus"
                          defaultMessage="Entscheidung offen"
                        />
                      </Button>
                    </div>
                  )}
                </label>
                {this.renderProductDropdown(model, formModel)}
              </Form.Field>
            )}

            {(!isProductLineItem || !model.product_id) && (
              <Field component="TextArea" {...form.fields.description} />
            )}

            <Grid>
              <Grid.Column width={4}>
                <div data-component="fieldComponent">
                  <div className="field">
                    <label htmlFor="quantity">Menge</label>
                    <FloatInput
                      id="quantity"
                      name="quantity"
                      autoComplete="off"
                      fluid
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      onChange={(event, data) => {
                        this.handleUpdateModel({ quantity: data.value });
                      }}
                      defaultValue={formModel.getQuantity()}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <Field component="TextArea" {...form.fields.instructions} />
              </Grid.Column>
            </Grid>

            <table className="calculation" width="100%">
              <thead>
                <tr>
                  <th width="30%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                  <th width="5%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                  <th width="5%">&nbsp;</th>
                  <th width="20%">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="transfer_quantity">
                      <FormattedMessage
                        id="roomBook.selection"
                        defaultMessage="roomBook.selection"
                      />
                    </label>
                  </td>
                  <td>
                    <FloatInput
                      id="transfer_quantity"
                      name="transfer_quantity"
                      autoComplete="off"
                      fluid
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      defaultValue={formModel.getTransferQuantity()}
                      readOnly
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>x</td>
                  <td>
                    <CurrencyInput
                      id="price"
                      name="price"
                      autoComplete="off"
                      fluid
                      onChange={(event, data) => {
                        this.handleUpdateModel({ price: data.value });
                      }}
                      defaultValue={formModel.getPrice()}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>=</td>
                  <td>
                    <CurrencyInput
                      id="subtotal"
                      name="subtotal"
                      autoComplete="off"
                      fluid
                      defaultValue={formModel.getTransferTotal()}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="excess_quantity">
                      <FormattedMessage
                        id="roomBook.selectionExcess"
                        defaultMessage="roomBook.selectionExcess"
                      />
                    </label>
                  </td>
                  <td>
                    <FloatInput
                      id="excess_quantity"
                      name="excess_quantity"
                      autoComplete="off"
                      fluid
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      defaultValue={formModel.getExcessQuantity()}
                      readOnly
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>x</td>
                  <td>
                    <CurrencyInput
                      id="excess_price"
                      name="excess_price"
                      autoComplete="off"
                      fluid
                      onChange={(event, data) => {
                        this.handleUpdateModel({
                          excess_price: !data.value ? null : data.value
                        });
                      }}
                      defaultValue={formModel.getExcessPrice()}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>=</td>
                  <td>
                    <CurrencyInput
                      id="excess_total"
                      name="excess_total"
                      autoComplete="off"
                      fluid
                      defaultValue={formModel.getExcessTotal()}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="default_quantity">
                      <FormattedMessage
                        id="roomBook.budget"
                        defaultMessage="roomBook.budget"
                      />
                    </label>
                  </td>
                  <td>
                    <FloatInput
                      id="default_quantity"
                      name="default_quantity"
                      autoComplete="off"
                      fluid
                      label={
                        i18n[
                          `roomBook.priceStrategyLong.${formModel.getPriceStrategy()}`
                        ]
                      }
                      labelPosition="right"
                      defaultValue={formModel.getDefaultQuantity() * -1}
                      readOnly
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>x</td>
                  <td>
                    <CurrencyInput
                      id="default_price"
                      name="default_price"
                      autoComplete="off"
                      fluid
                      defaultValue={formModel.getDefaultPrice()}
                      readOnly
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>=</td>
                  <td>
                    <CurrencyInput
                      id="budget"
                      name="budget"
                      autoComplete="off"
                      fluid
                      defaultValue={formModel.getBudget() * -1}
                      readOnly
                    />
                  </td>
                </tr>
                <If condition={showDiscount(account, formModel.getDiscount())}>
                  <tr>
                    <td colSpan="5">
                      <label htmlFor="discount">
                        <FormattedMessage
                          id="roomBook.lineItems.discount"
                          defaultMessage="roomBook.lineItems.discount"
                        />
                      </label>
                    </td>
                    <td>
                      <CurrencyInput
                        id="discount"
                        name="discount"
                        autoComplete="off"
                        fluid
                        defaultValue={-formModel.getDiscount()}
                        readOnly={!ftDiscountEnabled(account)}
                        onChange={(event, data) => {
                          this.handleUpdateModel({ discount: -data.value });
                        }}
                      />
                    </td>
                  </tr>
                </If>
                <If condition={!!formModel.getSurcharge()}>
                  <tr>
                    <td colSpan="5">
                      <label htmlFor="surcharge">
                        <FormattedMessage
                          id="roomBook.lineItems.surcharge"
                          defaultMessage="roomBook.lineItems.surcharge"
                        />
                      </label>
                    </td>
                    <td>
                      <CurrencyInput
                        id="surcharge"
                        name="surcharge"
                        autoComplete="off"
                        fluid
                        defaultValue={formModel.getSurcharge()}
                        readOnly
                      />
                    </td>
                  </tr>
                </If>
                <tr>
                  <td colSpan="5">
                    <label htmlFor="total">
                      <FormattedMessageWithFlag
                        featureToggleName="show_price_net_label"
                        id="roomBook.lineItems.total.net"
                        alternativeId="roomBook.lineItems.total.gross"
                      />
                    </label>
                  </td>
                  <td>
                    <CurrencyInput
                      id="total"
                      name="total"
                      autoComplete="off"
                      fluid
                      defaultValue={formModel.getTotal()}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Segment>

        <Message attached="bottom" warning visible={warnings.length > 0}>
          {warnings.map((w, idx) => {
            return (
              <p key={idx}>
                <Icon name="warning" />
                {w.text}&nbsp;
                <a
                  className="right floated element"
                  onClick={this.handleWarningFn(w.warningId)}
                  role="link"
                >
                  <FormattedMessage
                    id="meta.actions.correct"
                    defaultMessage="meta.actions.correct"
                  />
                </a>
              </p>
            );
          })}
        </Message>

        <If condition={showActionLink}>
          <Button
            basic
            onClick={this.handleCorrectPrices}
            style={{ marginBottom: "15px" }}
          >
            <Icon name="magic" />
            <FormattedMessage
              id="meta.actions.confirmeCustomCostsPrices"
              default="The prices are correct"
            />
          </Button>
        </If>

        <Form.Group>
          <Form.Field width="6">{this.renderDeleteButton()}</Form.Field>
          <Form.Field width="10">
            <Button
              onClick={form.handleSubmit(this.onSubmit)}
              name="commit"
              as="div"
              color="green"
              loading={this.state.isLoading}
              id={(model.id && "line-item-edit") || "line-item-new"}
              className="right floated element"
            >
              <FormattedMessage
                id="meta.actions.save"
                defaultMessage="meta.actions.save"
              />
            </Button>
            <Button
              onClick={() => this.props.cancel()}
              name="cancel"
              as="div"
              color="grey"
              basic
              loading={this.state.isLoading}
              className="right floated element"
            >
              <FormattedMessage
                id="meta.actions.cancel"
                defaultMessage="meta.actions.cancel"
              />
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}

SubLineItemForm.propTypes = {
  context: PropTypes.object,
  model: PropTypes.object,
  ctrl: PropTypes.object,
  products: PropTypes.array,
  trades: PropTypes.array,
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  save: PropTypes.func,
  cancel: PropTypes.func,
  isTemplate: PropTypes.bool,
  isProductLineItem: PropTypes.bool,
  autoFocus: PropTypes.bool,
  i18n: PropTypes.object,
  module: PropTypes.string,
  unitVariableTypesOptions: PropTypes.arrayOf(OptionsShape),
  projectId: PropTypes.string.isRequired,
  unitFeatures: PropTypes.arrayOf(UnitFeatureShape).isRequired,
  productFilterTags: PropTypes.arrayOf(OptionsShape).isRequired,
  configurationGroups: PropTypes.arrayOf(OptionsShape).isRequired,
  account: PropTypes.instanceOf(Account).isRequired
};

SubLineItemForm.defaultProps = {
  module: null,
  unitVariableTypesOptions: []
};

const mapStateToProps = createStructuredSelector({
  unitFeatures: getUnitFeatureOptions,
  productFilterTags: getProductFilterTagOptions,
  configurationGroups: getConfigurationGroupOptions,
  account: getAccount
});

export default connect(mapStateToProps)(SubLineItemForm);
