import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, Header, Loader } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import UpgradeBundleDialog from "./UpgradeBundleDialog";
import UpgradeBundleGroups from "./UpgradeBundleGroups";
import { UpgradeBundlesLoaderContext } from "./UpgradeBundlesLoader";

const UpgradeBundles = () => {
  const intl = useIntl();
  const { isLoading } = useContext(UpgradeBundlesLoaderContext);

  const renderHeader = () => {
    <Grid
      stackable
      verticalAlign="middle"
      data-component="upgrade_bundles"
      className="margin bottom medium"
    >
      <Grid.Column width="12" className="marginTop">
        <Header size="large">
          <FormattedMessage
            id="roomBook.upgradeBundles.title"
            defaultMessage="roomBook.upgradeBundles.title"
          />
        </Header>
        <p className="description">
          <FormattedMessage
            id="roomBook.upgradeBundles.description"
            defaultMessage="roomBook.upgradeBundles.description"
          />
        </p>
      </Grid.Column>
      <Grid.Column textAlign="right" width="4">
        <UpgradeBundleDialog
          model={{ name: "", title: "", description: "", image: null }}
          button={
            <Button
              id="upgrade_bundle-new"
              fluid
              icon="plus"
              content={intl.formatMessage({ id: "meta.actions.add" })}
              loading={isLoading}
              disabled={isLoading}
            />
          }
        />
      </Grid.Column>
    </Grid>;
  };

  return (
    <>
      {renderHeader()}
      <If condition={isLoading}>
        <Loader active />
      </If>
      <UpgradeBundleGroups />
    </>
  );
};

export default UpgradeBundles;
