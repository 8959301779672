import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce, get, sortBy, trim } from "lodash";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import {
  Button,
  Card,
  Grid,
  Header,
  Input,
  Label,
  Loader,
  Segment,
  Form
} from "semantic-ui-react";
import {
  getSuppliers,
  getProductTypeOptions
} from "shared/selectors/productManager";
import OptionsShape from "shared/shapes/options.shape";
import CustomProductForm from "./CustomProductForm";
import { ProductManagerResource } from "../../actions/productManagerActions";
import "./productImporter.scss";
import { I18nShape } from "../../../shared/shapes/i18n.shape";
import { ProductGroupResourcesShape } from "../../../shared/shapes/productGroupResources.shape";

class ProductImporter extends Component {
  searchProducts = debounce(filter => {
    this.fetchProducts(filter);
  }, 1500);

  fetchProducts = filter => {
    const { resource } = this.props;
    this.setState({ isLoadingProducts: true });
    resource
      .search(filter)
      .then(products => {
        this.setState({ isLoadingProducts: false, products });
      })
      .catch(() => {
        this.setState({ isLoadingProducts: false });
      });
  };

  constructor(props) {
    super(props);
    this.tagIds = React.createRef();
    this.tagIds.current = {};
    this.state = {
      isLoadingProducts: false,
      isLoadingTaxonomies: false,
      selectedProduct: null,
      products: [],
      filter: {},
      productSerieOptions: [],
      taxonomies: []
    };

    this.renderProduct = this.renderProduct.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { searchTerm, resource } = this.props;

    Promise.all([resource.fetchSuppliers(), resource.fetchProductTypes()]);

    if (searchTerm) {
      this.throttledSearchProducts({ term: searchTerm });
    } else {
      this.fetchProducts({});
    }
  }

  prepareOptions = options => {
    if (Array.isArray(options)) {
      return [
        {
          key: -1,
          value: -1,
          text: "Alle"
        }
      ].concat(options);
    }
    return [];
  };

  supplierOptions = () => {
    const { suppliers } = this.props;
    return suppliers.map(s => ({
      key: s.id,
      value: s.id,
      text: s.name
    }));
  };

  productSerieOptions = supplierId => {
    const { suppliers } = this.props;
    const series = suppliers.find(s => s.id === supplierId)?.series || [];
    return sortBy(series, "name").map(s => ({
      key: s.id,
      value: s.id,
      text: s.name
    }));
  };

  handleFocus = e => {
    const { value } = e.target;
    e.target.value = "";
    e.target.value = value;
  };

  fetchTaxonomies = productTypeId => {
    if (!productTypeId) {
      this.setState({ taxonomies: [] });
      return;
    }
    const { resource } = this.props;
    this.setState({ isLoadingTaxonomies: true });
    resource.fetctProductTypeMetadata(productTypeId).then(res => {
      this.setState({
        taxonomies: res.data?.product_type?.taxonomies || [],
        isLoadingTaxonomies: false
      });
    });
  };

  tagsOptions = tags => {
    return sortBy(tags, "name").map(tag => ({
      key: tag.id,
      value: tag.id,
      text: tag.name
    }));
  };

  updateSearchTags = (e, { name, value }) => {
    const { filter } = this.state;
    if (value > -1) {
      this.tagIds.current[name] = value;
    } else {
      delete this.tagIds.current[name];
    }

    const tagIds = Object.keys(this.tagIds.current).map(
      key => this.tagIds.current[key]
    );
    const newFilter = { ...filter, tag_ids: tagIds };

    this.setState({ filter: newFilter });
    this.fetchProducts(newFilter);
  };

  updateSearchResults = (e, { name, value }) => {
    const { onChangeSearchTerm } = this.props;
    const { filter } = this.state;

    if (onChangeSearchTerm && name === "term") {
      onChangeSearchTerm(name, value);
    }
    const newFilter = { ...filter, [name]: value };
    Object.keys(newFilter).forEach(key => {
      if (!newFilter[key]) delete newFilter[key];
    });

    if (name === "product_type_ids") {
      this.setState({ taxonomies: [] });
      delete newFilter.tag_ids;
      this.tagIds.current = {};
      if (value === -1) {
        delete newFilter.product_type_ids;
      } else {
        this.fetchTaxonomies(value);
      }
    }

    if (name === "supplier_id") {
      delete newFilter.serie_id;
      if (value === -1) delete newFilter.supplier_id;
      const productSerieOptions = value ? this.productSerieOptions(value) : [];
      this.setState({ filter: newFilter, productSerieOptions });
    } else {
      this.setState({ filter: newFilter });
    }

    if (name === "term") {
      this.throttledSearchProducts(newFilter);
    } else {
      this.fetchProducts(newFilter);
    }
  };

  selectProduct(product) {
    const { setShowCloseIcon } = this.props;
    this.setState({ selectedProduct: product });
    setShowCloseIcon(false);
  }

  throttledSearchProducts(filter) {
    return this.searchProducts.call(this, filter);
  }

  handleSave(product) {
    const { selectedProduct } = this.state;
    const { resources, handleUpdate, productGroupId } = this.props;

    const nextProduct = { ...selectedProduct, ...product };

    const values = {
      name: nextProduct.name,
      supplier: nextProduct.supplier_name,
      description: nextProduct.description,
      technical_description: nextProduct.technical_description,
      sku: nextProduct.sku,
      series: nextProduct.series,
      product_tags: nextProduct.product_tags,
      trades: nextProduct.trades,
      product_manager_id: nextProduct.product_manager_id,
      product_type_ids: nextProduct.product_type_ids,
      product_catalog_id: nextProduct.product_catalog_id
    };

    // handle assets
    values.images_attributes = nextProduct.assets
      .filter(asset => asset.type === "image")
      .map(img => ({ image: img.url }));

    return resources.products.save(values).then(response => {
      if (productGroupId) {
        // we're in a project's product group context ...
        const productId = get(response, "data.product.id");
        const payload = { product_id: productId, price: 0.0 };
        const resource = resources.pricesFactory(productGroupId);
        // ... so we add the newly created product to the given product group
        resource.save(payload).then(() => {
          this.setState({ selectedProduct: null });
          return handleUpdate(response.data.product);
        });
      } else {
        this.setState({ selectedProduct: null });
        return handleUpdate(response.data.product);
      }
    });
  }

  renderProducts() {
    const { products } = this.state;
    if (products.length) {
      return (
        <div className="scrollableContainer">
          <Card.Group itemsPerRow="4" stackable style={{ margin: 0 }}>
            {products.map(this.renderProduct)}
          </Card.Group>
        </div>
      );
    }
    return (
      <div>
        <Header as="h3">
          <FormattedMessage
            id="product_price.search.results.none.title"
            defaultMessage="Es wurde kein Produkt zu diesem Suchbegriff gefunden."
          />
        </Header>
      </div>
    );
  }

  renderProduct(product, index) {
    const { imagePlaceholder } = this.props;
    const image = product.assets.filter(asset => {
      return asset.type === "image";
    })[0] || { url: imagePlaceholder };

    return (
      <Card
        className="productCard"
        key={`${product.id}-${index}`}
        onClick={() => this.selectProduct(product)}
      >
        <Card.Content className="cardContent">
          <div className="imageContainer">
            <img src={image.url} className="imageContent" alt="Produktbild" />
            <If
              condition={product.price_in_cents && product.price_in_cents > 0}
            >
              <div className="price">
                <Label
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#4E4E4E",
                    color: "white"
                  }}
                >
                  <FormatCurrency amount={product.price_in_cents / 100} /> UVP
                </Label>
              </div>
            </If>
          </div>
          <Grid>
            <Grid.Column width={16} className="textContent">
              <div
                className="flex flex-col justify-content-space-between h-full"
                style={{ overflowY: "hidden" }}
              >
                <div className="flex-grow">
                  <div style={{ fontSize: "0.8rem", color: "gray" }}>
                    {`${product.supplier_name}, ${product.series[0]}`}
                  </div>
                  <div>{product.name}</div>
                </div>
                <div>
                  {product.tags?.map(tag => (
                    <Label key={tag.id} style={{ margin: "1px" }}>
                      {tag.name}
                    </Label>
                  ))}
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    );
  }

  render() {
    const {
      i18n,
      searchTerm,
      resources,
      productGroupId,
      handleUpdate,
      productTypeOptions,
      setShowCloseIcon
    } = this.props;
    const {
      isLoadingProducts,
      selectedProduct,
      productSerieOptions,
      filter,
      taxonomies,
      isLoadingTaxonomies
    } = this.state;

    let model;
    if (selectedProduct) {
      model = {
        ...selectedProduct,
        id: null,
        supplier: selectedProduct.supplier_name,
        series: (selectedProduct.series || [])[0] || "",
        description: trim(selectedProduct.summary)
      };
    }

    const image = selectedProduct && selectedProduct.assets[0];

    return (
      <div data-component="productImporter">
        {!selectedProduct && (
          <Grid>
            <Grid.Column width={3}>
              <Form>
                <div className="list">
                  <div
                    className="scrollableContainer"
                    style={{ paddingRight: "1rem" }}
                  >
                    <div className="termSearch">
                      <Input
                        id="product_search"
                        fluid
                        defaultValue={searchTerm}
                        onChange={this.updateSearchResults}
                        icon="search"
                        name="term"
                        placeholder={i18n["product_price.search.placeholder"]}
                        onFocus={this.handleFocus}
                        autoFocus
                        autoComplete="off"
                      />
                    </div>

                    <Form.Dropdown
                      selection
                      search
                      selectOnBlur={false}
                      label={i18n["product_price.search.supplier"]}
                      name="supplier_id"
                      value={filter.supplier_id || -1}
                      options={this.prepareOptions(this.supplierOptions())}
                      onChange={this.updateSearchResults}
                      fluid
                    />

                    <If condition={!!filter.supplier_id}>
                      <Form.Dropdown
                        selection
                        search
                        selectOnBlur={false}
                        label={i18n["product_price.search.productSeries"]}
                        name="serie_id"
                        value={filter.serie_id || -1}
                        options={this.prepareOptions(productSerieOptions)}
                        onChange={this.updateSearchResults}
                        fluid
                      />
                    </If>

                    <Form.Dropdown
                      selection
                      search
                      closeOnChange
                      selectOnBlur={false}
                      label={i18n["product_price.search.productType"]}
                      name="product_type_ids"
                      value={filter.product_type_ids || -1}
                      options={this.prepareOptions(productTypeOptions)}
                      onChange={this.updateSearchResults}
                      fluid
                    />

                    <If condition={isLoadingTaxonomies}>
                      <Loader active style={{ marginTop: "50px" }} />
                    </If>

                    {taxonomies.map(t => (
                      <Form.Dropdown
                        key={t.id}
                        selection
                        search
                        defaultValue={-1}
                        selectOnBlur={false}
                        label={t.label}
                        name={t.slug}
                        options={this.prepareOptions(this.tagsOptions(t.tags))}
                        onChange={this.updateSearchTags}
                        fluid
                      />
                    ))}
                  </div>
                </div>
              </Form>
            </Grid.Column>
            <Grid.Column width={13}>
              <If condition={isLoadingProducts}>
                <Loader active size="large" />
              </If>
              <If condition={!isLoadingProducts}>
                <div className="list">
                  <Segment style={{ padding: 0 }}>
                    {this.renderProducts()}
                  </Segment>
                </div>
              </If>
            </Grid.Column>
          </Grid>
        )}
        {selectedProduct && (
          <div className="selectedProduct">
            <Button
              icon="caret left"
              basic
              className="backButton"
              color="red"
              content={i18n["meta.actions.back"]}
              onClick={() => {
                this.setState({ selectedProduct: null });
                setShowCloseIcon(true);
              }}
            />
            <Grid>
              <Grid.Column width="11">
                <CustomProductForm
                  context="ProductImporter"
                  resources={resources}
                  model={model}
                  handleUpdate={handleUpdate}
                  productGroupId={productGroupId}
                  handleSave={this.handleSave}
                  suppliers={[]}
                  setShowCloseIcon={setShowCloseIcon}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <div
                  style={image ? { backgroundImage: `url(${image.url})` } : {}}
                  className="productImage"
                />
              </Grid.Column>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    suppliers: getSuppliers(state),
    productTypeOptions: getProductTypeOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resource: new ProductManagerResource(dispatch)
  };
};

ProductImporter.defaultProps = {
  onChangeSearchTerm: null,
  searchTerm: "",
  productGroupId: null,
  suppliers: []
};

ProductImporter.propTypes = {
  i18n: I18nShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  productGroupId: PropTypes.number,
  searchTerm: PropTypes.string,
  onChangeSearchTerm: PropTypes.func,
  handleUpdate: PropTypes.func.isRequired,
  resource: PropTypes.instanceOf(ProductManagerResource).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  suppliers: PropTypes.array,
  productTypeOptions: PropTypes.arrayOf(OptionsShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  imagePlaceholder: PropTypes.any.isRequired,
  setShowCloseIcon: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImporter);
