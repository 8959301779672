import React from "react";
import { Grid } from "semantic-ui-react";
import AccountRoles from "./AccountRoles";
import ProjectRoles from "./ProjectRoles";

const Roles = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <AccountRoles />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <ProjectRoles />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Roles;
