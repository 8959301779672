import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import { Modal, Form, Button, Header } from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { cloneDeep } from "lodash";

class ProcessStateDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    model: PropTypes.object,
    button: PropTypes.object,
    onSave: PropTypes.func,
    onRemove: PropTypes.func.isRequired,
    growl: PropTypes.instanceOf(Growl).isRequired
  };

  constructor(props) {
    super(props);

    this.state = { open: false, model: cloneDeep(props.model) };
  }

  onClose() {
    this.setState({
      ...this.state,
      open: false,
      model: cloneDeep(this.props.model)
    });
  }

  onSave(activityItem) {
    const { growl } = this.props;
    this.setState({ ...this.state, loading: true });
    if (activityItem.terminal) {
      delete activityItem.deadline;
      delete activityItem.deadline_on;
    }
    this.props
      .onSave(activityItem)
      .then(() => {
        this.setState(prevState => ({
          ...prevState,
          open: false,
          loading: false
        }));
        growl.success(
          `process.messages.edit.success.title`,
          `process.messages.edit.success.body`
        );
      })
      .catch(() => {
        this.setState(prevState => ({
          ...prevState,
          open: false,
          loading: false
        }));
        growl.error(
          `process.messages.edit.error.title`,
          `process.messages.edit.error.body`
        );
      });
  }

  colseDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      open: false,
      loading: false
    }));
  };

  onRemove = () => {
    const { growl, onRemove } = this.props;
    const { model } = this.state;

    this.setState(prevState => ({ ...prevState, loading: true }));

    onRemove(model)
      .then(() => {
        growl.success(
          `process.messages.remove.success.title`,
          `process.messages.remove.success.body`
        );
        this.colseDialog();
      })
      .catch(() => {
        growl.error(
          `process.messages.remove.error.title`,
          `process.messages.remove.error.body`
        );
        this.colseDialog();
      });
  };

  renderDeleteButton() {
    const { i18n } = this.props;
    const { loading } = this.state;
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        loading={loading}
        content={i18n["meta.actions.remove"]}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: this.onRemove
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="process.remove_dialog.title"
        message="process.remove_dialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  render() {
    const { i18n } = this.props;
    const { model } = this.state;

    if (!model.terminal && !model.deadline_on) {
      model.deadline_on = "create";
    }

    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "id",
          label: "process.attributes.id.label",
          rule: "isRequired"
        },
        {
          id: "label",
          label: "process.attributes.label.label",
          message: "process.attributes.label.error",
          rule: "isRequired"
        },
        {
          id: "summary",
          label: "process.attributes.summary.label"
        },
        {
          id: "startable",
          label: "process.attributes.startable.label",
          control: "Checkbox"
        },
        {
          id: "terminal",
          label: "process.attributes.terminal.label",
          control: "Checkbox"
        },
        {
          id: "deadline",
          label: "process.attributes.deadline.label",
          placeholder: "process.attributes.deadline.placeholder",
          message: "process.attributes.deadline.error",
          rule: model.terminal ? null : "isNumberOrEmpty"
        },
        {
          id: "deadline_on",
          label: "process.attributes.deadline_on.label",
          message: "process.attributes.deadline_on.error",
          rule: model.terminal ? null : "isRequired"
        }
      ]
    });

    const form = FormFactory.create(model, i18n, {
      onChange: model => {
        this.setState({ ...this.state, model });
      }
    });

    form.fields.id.props.readOnly = true;
    form.fields.summary.props.rows = 2;
    form.fields.deadline_on.props.options = [
      {
        key: "entry",
        value: "entry",
        text: "Beginn: Arbeitsschritt"
      },
      {
        key: "create",
        value: "create",
        text: "Beginn: Vorgang"
      }
    ];
    form.fields.deadline_on.props.disabled = !model.deadline;

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={() => this.setState({ ...this.state, open: true })}
        onClose={() => this.onClose()}
      >
        <Modal.Header>
          <FormattedMessage
            id="process.title.state.one"
            default="process.title.state.one"
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="attachment_type" data-component="attachment_type">
            <Form.Field>
              <Field component="Input" {...form.fields.id} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.label} />
            </Form.Field>
            <Form.Field>
              <Field component="TextArea" {...form.fields.summary} />
            </Form.Field>
            <Form.Field>
              <Field component="Checkbox" {...form.fields.startable} />
            </Form.Field>
            <Header as="h4">Fristen</Header>
            {!model.terminal && (
              <Form.Field>
                <Field component="Input" {...form.fields.deadline} />
              </Form.Field>
            )}
            {!model.terminal && (
              <Form.Field>
                <Field component="Select" {...form.fields.deadline_on} />
              </Form.Field>
            )}
            <Form.Field>
              <Field component="Checkbox" {...form.fields.terminal} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {this.renderDeleteButton()}
          <Button
            id="submit"
            positive
            loading={this.state.loading}
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.onSave.bind(this))}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ProcessStateDialog;
