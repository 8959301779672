import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getConfiguratorDesigns = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "configurator_designs", []);
  }
);

export const getConfiguratorDesignFeatures = createSelector(
  [getConfiguratorDesigns],
  configDesigns => {
    return configDesigns.map(design => ({
      key: design.id,
      value: design.id,
      text: design.name
    }));
  }
);
