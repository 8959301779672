import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export default class BaseLineItemResource extends Resources {
  constructor(dispatch, url) {
    super(dispatch, "line_item", url);
    this.refreshPageContentAfterCommit = true;
  }

  timeline(id) {
    const url = `${this.url}/${id}/timeline`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class BaseSubLineItemResource extends Resources {
  constructor(dispatch, url) {
    super(dispatch, "sub_line_item", url);
    this.refreshPageContentAfterCommit = true;
  }
}
