/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Select } from "formik-semantic-ui-react";

const RenderMailTemplates = ({ accountMailTemplates, label }) => {
  if (!accountMailTemplates.length) return null;

  const defaultMailTemplates = accountMailTemplates.filter(
    mailTemplate => mailTemplate.role === "default_mail"
  );

  const mailTemplateOptions = defaultMailTemplates.map(mailTemplate => {
    return {
      key: `mail-template-${mailTemplate.id}`,
      value: mailTemplate.id,
      text: mailTemplate.name
    };
  });

  return (
    <Select
      name="mailTemplateId"
      fluid
      selection
      options={mailTemplateOptions}
      label={label}
    />
  );
};

RenderMailTemplates.propTypes = {
  accountMailTemplates: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
};

export default RenderMailTemplates;
