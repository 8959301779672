import { number, oneOfType, shape, string } from "prop-types";

export const ContactsShape = shape({
  id: oneOfType([string, number]).isRequired,
  email: string.isRequired,
  text: string,
  type: string
});

export default ContactsShape;
