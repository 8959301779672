import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Header, Form, Segment, Button, Icon } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { I18nShape } from "shared/shapes/i18n.shape";
import { instanceOf } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import PasswordResource from "builder_portal/actions/passwordActions";
import { withRouter } from "../../../shared/helpers/withRouter";

class PasswordRequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user: {
        email: ""
      }
    };
  }

  onSubmit(values) {
    const { resource, growl, router } = this.props;
    this.setState({ isLoading: true });

    resource
      .save(values)
      .then(() => {
        router.push("/users/login");
        growl.success(
          "devise.passwords.send_instructions.title",
          "devise.passwords.send_instructions.message"
        );
      })
      .catch(() => {
        growl.error(
          "message.passwordRequestFailed.title",
          "message.passwordRequestFailed.body"
        );
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { i18n } = this.props;
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "email",
          label: "user.attributes.email.label",
          message: "user.attributes.email.error",
          rule: "isEmail"
        }
      ]
    });

    const form = FormFactory.create(this.state.user, i18n, {
      onChange: user => {
        this.setState({ user });
      }
    });

    form.fields.email.props.type = "email";

    return (
      <Grid centered stackable data-component="login">
        <Grid.Column width="8">
          <Segment textAlign="left">
            <Form
              id="passwordRequest"
              data-component="password"
              onSubmit={form.handleSubmit(this.onSubmit.bind(this))}
            >
              <Header size="medium">
                <FormattedMessage
                  id="user.actions.sendResetPasswordInstructions.title"
                  defaultText="user.actions.sendResetPasswordInstructions.title"
                />
              </Header>
              <p>
                <FormattedMessage
                  id="user.actions.sendResetPasswordInstructions.body"
                  defaultText="user.actions.sendResetPasswordInstructions.body"
                />
              </p>
              <Form.Field width="16">
                <Field component="Input" {...form.fields.email} />
              </Form.Field>
              <Button type="submit" primary loading={this.state.isLoading}>
                <Icon name="sign in" />
                &nbsp;
                <FormattedMessage
                  id="user.actions.sendResetPasswordInstructions.label"
                  defaultText="user.actions.sendResetPasswordInstructions.label"
                />
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

PasswordRequestForm.propTypes = {
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired,
  resource: instanceOf(PasswordResource).isRequired
};

export default withRouter(PasswordRequestForm);
