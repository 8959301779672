import React, { useCallback, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Container, Grid, Modal, Header } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { Form, TextArea } from "formik-semantic-ui-react";
import PropTypes from "prop-types";
import { lowerFirst } from "lodash";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import ActionButtons from "./ActionButtons";

const HtmlElement = ({ button, onChange, initialValue }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = resetForm => {
    resetForm();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onSubmit = useCallback(values => {
    onChange(values);
    handleClose();
  }, []);

  return (
    <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValue}>
      {({ submitForm, resetForm }) => {
        return (
          <Modal
            open={isOpen}
            onOpen={() => handleOpen(resetForm)}
            onClose={handleClose}
            trigger={button}
            closeOnEscape={false}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id={`configuratorDesign.dashboard.${lowerFirst(
                  initialValue.type
                )}.type`}
              />
            </Modal.Header>
            <Modal.Content>
              <Container style={{ width: "100%" }}>
                <Form id="okDashboardHtmlElement">
                  <Grid style={{ width: "100%" }}>
                    <Grid.Column width={16}>
                      <TextArea
                        name="params.html"
                        rows={10}
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.html"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Header as="h4" style={{ marginBottom: 10 }}>
                        {useIntl().formatMessage({
                          id:
                            "configuratorDesign.dialog.contentPageEditor.presentation"
                        })}
                      </Header>
                      <Field
                        id="css"
                        name="css"
                        label={useIntl().formatMessage({
                          id: "configuratorDesign.dialog.contentPageEditor.css"
                        })}
                        component={SemanticFormikJsonEditor}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <ActionButtons onClose={handleClose} onSubmit={submitForm} />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

HtmlElement.propTypes = {
  button: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  })
};

HtmlElement.defaultProps = {
  initialValue: {
    params: {
      html: ""
    }
  }
};

export default HtmlElement;
