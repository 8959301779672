import PropTypes, { arrayOf } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { Container, Grid, Loader } from "semantic-ui-react";
import { DEPRECATED_PageContentShape } from "shared/shapes/deprecated_pageContent.shape";
import { Account } from "shared/models/account";
import SubLineItemModel from "builder_portal/models/roomBook/SubLineItemModel";
import { MessageDraftsForActivitiesResource } from "builder_portal/actions/messageDraftsActions";
import { getMessageDrafts } from "builder_portal/selectors/messageDrafts";
import { MessageShape } from "shared/shapes/message.shape";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import NotificationMessage from "./NotificationMessage";
import ActivitySegment from "./ActivitySegment";
import DeadlinesSegment from "./DeadlinesSegment";
import BuyerDeveloperSegment from "./BuyerDeveloperSegment";
import ContractorsSegment from "./ContractorsSegment";
import AttachmentSegment from "./AttachmentSegment";
import UtilitySegment from "./UtilitySegment";
import ActivityActions from "./ActivityActions";
import TabMenu from "./TabMenu";
import ActivityCarts from "./carts/ActivityCarts";
import Timeline from "./Timeline";
import TimelineFilterBox, { DEFAULT_FILTER } from "./TimelineFilterBox";
import "./activityItem.scss";
import {
  ActivitiesResource,
  AttachmentReferencesResource,
  ContractorAssignmentResource,
  DeadlinesResource,
  LineItemResource,
  TasksResource
} from "../../actions/activityActions";
import MemosResource from "../../actions/memosActions";
import GrowlMessage from "../../../shared/components/growlMessage/GrowlMessage";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";
import ActivityController from "../../containers/activity/activityController";
import ActivityModel from "../../containers/activity/activityModel";
import { getAccount, getActivityModel } from "../../selectors";
import {
  UPDATE_FILTER,
  UPDATE_PAGE_CONTENT_MESSAGE_FORM
} from "../../../shared/constants/appConstants";
import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import { AttachmentResource } from "../../actions/attachmentsActions";
import { withRouter } from "../../../shared/helpers/withRouter";
import Costs from "./costs/CostsTable";

class ActivityItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      priceCostDiverged: false
    };
  }

  componentDidMount() {
    const { ctxCtrl, actions } = this.props;

    this.loadActivities(ctxCtrl);
    actions.draft.fetchAll();
  }

  componentDidUpdate(prevProps) {
    const { activityId, ctxCtrl } = this.props;

    if (prevProps.activityId !== activityId) {
      this.loadActivities(ctxCtrl);
    }
  }

  handleUpdate = () => {
    const { activityId, actions, ctxCtrl } = this.props;
    ctxCtrl.loadContacts();
    return actions.activity.get(activityId, true).catch(console.error);
  };

  getSummaryByTrade = () => {
    const { ctxModel } = this.props;
    const summaryByTrade = ctxModel.getSummaryByTrade();
    return summaryByTrade;
  };

  getTrades = () => {
    const { ctxModel } = this.props;
    const trades = ctxModel.getTrades();
    return trades;
  };

  getCarts() {
    const { ctxModel } = this.props;
    const carts = ctxModel.getCarts();
    return carts.filter(cart => {
      return cart.sections.length;
    });
  }

  isPriceCostDiverged() {
    const { ctxModel } = this.props;
    const carts = this.getCarts();
    try {
      carts.forEach(cart => {
        const productsRepository = ctxModel.getProductRepositoryForPriceCatalog(
          cart.price_catalog_id
        );

        const lineItems = cart.sections[0]?.sections[0]?.line_items;
        if (lineItems?.length) {
          lineItems.forEach(lineItem => {
            lineItem.sub_line_items.forEach(subLineItem => {
              const productCollection = productsRepository.getGroupProducts(
                subLineItem.product_group_id
              );
              const subLineItemModel = new SubLineItemModel(
                subLineItem,
                productCollection,
                false
              );
              const showWarning = subLineItemModel.isPriseCostDiverged();
              // eslint-disable-next-line no-throw-literal
              if (showWarning) throw "Prices or costs diverge";
            });
          });
        }
      });
      return false;
    } catch {
      return true;
    }
  }

  loadActivities(ctxCtrl) {
    return ctxCtrl
      .load()
      .then(() => {
        const priceCostDiverged = this.isPriceCostDiverged();
        this.setState(prev => ({ ...prev, priceCostDiverged }));
      })
      .catch(silentHandleApiRequestErrors)
      .catch(error => {
        this.setState({ error });
        throw error;
      });
  }

  renderCarts() {
    const {
      ctxModel,
      ctxCtrl,
      account,
      activityId,
      i18n,
      toggleDialog,
      actions
    } = this.props;

    return (
      <ActivityCarts
        ctxModel={ctxModel}
        ctxCtrl={ctxCtrl}
        context={{ activity: activityId, account }}
        i18n={i18n}
        toggleDialog={toggleDialog}
        actions={actions}
        handleUpdate={this.handleUpdate}
      />
    );
  }

  render() {
    const {
      activityId,
      i18n,
      pageContent,
      filter,
      setTimelineFilter,
      actions,
      contractorAssignmentResource,
      deadlinesResource,
      updateMessageForm,
      memosResource,
      activeTab,
      account,
      ctxModel,
      ctxCtrl,
      drafts
    } = this.props;

    const { priceCostDiverged } = this.state;

    const { error } = this.state;

    if (error) {
      return (
        <GrowlMessage
          type="error"
          title="message.display_error.title"
          body="message.display_error.body"
          bodyValues={{ translatedBody: error.message }}
          icon="warning"
          timeout={null}
        />
      );
    }

    if (!ctxModel.isReady()) {
      return <Loader active className="margin top spacious" />;
    }

    const activity = get(pageContent, "activity");
    const availableAssignees = get(pageContent, "available_assignees");
    const attachments = get(pageContent, "attachments");
    const contacts = get(pageContent, "contacts", []);
    const project = get(pageContent, "project");
    const messageForm = get(pageContent, "messageForm");
    const units = get(pageContent, "units", []);
    const unit = units[0];
    const buyers = get(pageContent, "buyers", []);
    const buyer = buyers[0] || null;
    const deadlines = get(pageContent, "deadlines");
    const states = ctxModel.getStatesById();
    const timeline = get(pageContent, "timeline");
    const pageName =
      activity && activity.display_name
        ? [activity.display_name, i18n["meta.app.name"]].join(" - ")
        : [i18n["activity.title.one"], i18n["meta.app.name"]].join(" - ");
    const macros = get(pageContent, "macros");
    const notifications = get(pageContent, "notifications");

    return (
      <Grid stackable data-component="activityItem">
        <Helmet title={pageName} />
        <NotificationMessage
          i18n={i18n}
          handleChange={this.handleUpdate}
          notifications={notifications || []}
        />

        <Grid.Column width="16">
          <TabMenu activity={activity} priceCostDiverged={priceCostDiverged} />
        </Grid.Column>
        <Grid.Column width="6">
          <ActivitySegment
            projectSlug={project && project.slug}
            i18n={i18n}
            activity={activity}
            project={project}
            availableAssignees={availableAssignees}
            unit={unit}
            actions={actions}
            accountingMode={ctxModel.getAccountingMode()}
          />

          <AttachmentSegment ctxModel={ctxModel} ctxCtrl={ctxCtrl} />

          <ContractorsSegment
            onChange={this.handleUpdate}
            contractorAssignmentResource={contractorAssignmentResource}
          />

          <DeadlinesSegment
            activity={activity}
            deadlines={deadlines}
            states={states}
            actions={actions}
            deadlinesResource={deadlinesResource}
          />

          <BuyerDeveloperSegment buyers={buyers} project={project} />

          <IsSystemAdminOrHasSpecificRight right="ext_allow_delete_activities">
            <UtilitySegment activity={activity} account={account} />
          </IsSystemAdminOrHasSpecificRight>
        </Grid.Column>
        <Grid.Column width="10">
          {activeTab === "timeline" && (
            <Container data-component="timelineTabContainer">
              <ActivityActions
                id="activity-meta-actions"
                account={account}
                i18n={i18n}
                activity={activity}
                buyer={buyer}
                buyers={buyers}
                contractors={ctxModel.get("contractors")}
                project={project}
                handleUpdate={this.handleUpdate}
                contacts={contacts}
                memosResource={memosResource}
                attachments={attachments}
                macros={macros}
                ctxCtrl={ctxCtrl}
              />

              <TimelineFilterBox
                i18n={i18n}
                filter={filter[activityId]?.timeline}
                setTimelineFilter={setTimelineFilter}
                contacts={contacts}
              />

              <Timeline
                account={account}
                i18n={i18n}
                activityId={activityId}
                filter={filter[activityId]?.timeline}
                activity={activity}
                actions={actions}
                contacts={contacts}
                memosResource={memosResource}
                messageForm={messageForm}
                updateMessageForm={updateMessageForm}
                attachments={attachments}
                macros={macros}
                notifications={notifications || []}
                deadlines={deadlines}
                timeline={timeline}
                handleUpdate={this.handleUpdate}
                states={states}
                ctxModel={ctxModel}
                ctxCtrl={ctxCtrl}
                draftsCount={drafts.length}
              />
            </Container>
          )}

          {activeTab === "cart" && this.renderCarts()}
          {activeTab === "costs" && (
            <div>
              <Costs
                i18n={i18n}
                activity={activity}
                carts={this.getCarts()}
                summaryByTrade={this.getSummaryByTrade()}
                trades={this.getTrades()}
              />
            </div>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

ActivityItem.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  account: PropTypes.instanceOf(Account).isRequired,
  i18n: PropTypes.object.isRequired,
  pageContent: DEPRECATED_PageContentShape,
  /* eslint-enable react/forbid-prop-types */
  activeTab: PropTypes.oneOf(["timeline", "cart"]),
  filter: PropTypes.objectOf(PropTypes.object).isRequired,
  activityId: PropTypes.string.isRequired,
  setTimelineFilter: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  updateMessageForm: PropTypes.func.isRequired,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  ctxModel: PropTypes.instanceOf(ActivityModel).isRequired,
  deadlinesResource: PropTypes.instanceOf(DeadlinesResource).isRequired,
  memosResource: PropTypes.instanceOf(MemosResource).isRequired,
  actions: PropTypes.shape({
    activity: PropTypes.instanceOf(ActivitiesResource),
    draft: PropTypes.instanceOf(MessageDraftsForActivitiesResource)
  }).isRequired,
  contractorAssignmentResource: PropTypes.instanceOf(
    ContractorAssignmentResource
  ).isRequired,
  drafts: arrayOf(MessageShape)
};

ActivityItem.defaultProps = {
  activeTab: "timeline",
  pageContent: {},
  drafts: []
};

const mapStateToProps = (state, props) => {
  const { activityId, tab } = props.params;

  let filter = get(state, "filter.activity", {});
  if (!filter[activityId]) {
    filter = {
      ...filter,
      [activityId]: {
        timeline: DEFAULT_FILTER
      }
    };
  }

  return {
    activityId,
    ctxModel: getActivityModel(state, props),
    i18n: state.i18n,
    pageContent: state.pageContent,
    account: getAccount(state),
    activeTab: tab,
    dialog: (state.dialog &&
      state.dialog.activity &&
      state.dialog.activity[activityId]) || {
      activityItem: false,
      deadlines: false,
      memo: {},
      lineItem: {}
    },
    filter,
    drafts: getMessageDrafts(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { activityId, projectId } = props.params;

  const setTimelineFilter = filterSettings => {
    if (activityId) {
      const filter = {
        activity: {
          [activityId]: {
            timeline: filterSettings
          }
        }
      };

      dispatch({
        type: UPDATE_FILTER,
        payload: filter
      });
    }
  };

  return {
    activityId,
    projectId,
    ctxCtrl: new ActivityController(projectId, activityId, dispatch),
    toggleDialog: toggleDialogCreator(dispatch, activityId, "activity"),
    setTimelineFilter,
    contractorAssignmentResource: new ContractorAssignmentResource(
      dispatch,
      activityId
    ),
    deadlinesResource: new DeadlinesResource(dispatch, activityId),
    updateMessageForm: do_dispatch => {
      return payload => {
        do_dispatch({
          type: UPDATE_PAGE_CONTENT_MESSAGE_FORM,
          payload
        });
      };
    },
    memosResource: new MemosResource(dispatch, activityId),
    tasksResource: new TasksResource(dispatch),
    actions: {
      activity: new ActivitiesResource(dispatch),
      attachmentReferences: new AttachmentReferencesResource(
        dispatch,
        activityId
      ),
      lineItem: new LineItemResource(dispatch, activityId),
      attachment: new AttachmentResource(dispatch),
      draft: new MessageDraftsForActivitiesResource(dispatch, activityId)
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivityItem)
);
