import React, { useState, useMemo, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import {
  Checkbox,
  Form,
  Input,
  Select,
  TextArea
} from "formik-semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { node } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import SemanticFormikFileUpload from "shared/components/forms/SemanticFormikFileUpload";
import UpgradeBundlesResource from "builder_portal/actions/upgradeBundlesActions";
import UpgradeBundleShape from "shared/shapes/upgradeBundles.shape";

import { UpgradeBundlesLoaderContext } from "./UpgradeBundlesLoader";
import FeatureToggleActive from "../../../../shared/components/elements/FeatureToggleActive";

const UpgradeBundleDialog = ({ button, model }) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { upgradeBundleGroups, projectId, loadData } = useContext(
    UpgradeBundlesLoaderContext
  );
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const upgradeBundleGroupOptions = useMemo(() => {
    return upgradeBundleGroups.map(group => ({
      key: group.id,
      value: group.id,
      text: group.name
    }));
  }, [upgradeBundleGroups]);

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const onSubmit = useCallback(
    (values, formik) => {
      // only send image to backend when changed.
      const image = values.image !== model.image ? values.image : null;

      new UpgradeBundlesResource(dispatch, projectId)
        .save({ ...values, image })
        .then(() => {
          const isCreateDialog = !!values.id;
          if (!isCreateDialog) formik.resetForm();
          loadData();
          setOpen(false);
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
        });
    },
    [model]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UpgradeBundleDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`roomBook.upgradeBundles.dialog.${
            !model.id ? "createTitle" : "editTitle"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.name"
                  })}
                />

                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.titleField"
                  })}
                />

                <Input
                  id="summary"
                  name="summary"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.summary"
                  })}
                />

                <TextArea
                  id="description"
                  name="description"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.description"
                  })}
                />

                <Select
                  id="upgrade_bundle_group_id"
                  errorPrompt
                  clearable
                  name="upgrade_bundle_group_id"
                  options={upgradeBundleGroupOptions}
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.upgrade_bundle_group_id"
                  })}
                />

                <Checkbox
                  id="default_dummy"
                  name="default_dummy"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.defaultDummy"
                  })}
                />

                <Checkbox
                  id="portal_offer"
                  name="portal_offer"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.portalOffer"
                  })}
                />

                <Field
                  name="image"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.image"
                  })}
                  component={SemanticFormikFileUpload}
                />

                <Input
                  id="upgrade_code"
                  errorPrompt
                  name="upgrade_code"
                  label={intl.formatMessage({
                    id: "roomBook.upgradeBundles.dialog.upgradeCode"
                  })}
                />

                <FeatureToggleActive featureToggleName="show_external_id">
                  <Input
                    id="external_id"
                    errorPrompt
                    name="external_id"
                    label={intl.formatMessage({
                      id: "roomBook.upgradeBundles.dialog.externalId"
                    })}
                  />
                </FeatureToggleActive>

                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({
                      id: "meta.actions.cancel"
                    })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UpgradeBundleDialog.propTypes = {
  button: node.isRequired,
  model: UpgradeBundleShape.isRequired
};

export default UpgradeBundleDialog;
