/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import copyToClipboard from "copy-to-clipboard";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Tab,
  Menu
} from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { getConfiguratorDesignFeatures } from "builder_portal/selectors/configuratorDesigns";
import { If } from "shared/components/elements/Conditions";
import { UnitFeatureShape } from "shared/shapes/unitFeatureGroup.shape";
import OptionsShape from "shared/shapes/options.shape";
import { getProjectMembers } from "shared/selectors/project";
import { getUnitTypesOptions } from "builder_portal/selectors/unitTypes";
import { UnitResource } from "builder_portal/actions/unitActions";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import FloatInput from "shared/components/forms/FloatInput";
import { UserShape } from "shared/shapes/user.shape";
import { getProjectStoreysOptions } from "builder_portal/selectors/projectStoreys";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { getBuyersWithStatus } from "../../selectors";
import BuyerDialog from "../buyer/BuyerDialog";
import ProjectBuyersResource from "../../actions/buyerActions";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import ProjectStoreys from "./CreateStoreyDialog";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "section_id",
      label: "unit.attributes.section.label"
    },
    {
      id: "unit_number",
      label: "unit.attributes.unitNumber.label",
      message: "unit.attributes.unitNumber.error",
      rule: "isRequired"
    },
    {
      id: "cellar_number",
      label: "unit.attributes.cellarNumber.label"
    },
    {
      id: "parking_space_number",
      label: "unit.attributes.parkingSpaceNumber.label"
    },
    {
      id: "sample",
      label: "unit.attributes.sample.label",
      control: "Checkbox"
    },
    {
      id: "description",
      label: "unit.attributes.description.label"
    },
    {
      id: "signee_ids",
      label: "unit.attributes.signees.label",
      placeholder: "unit.attributes.signees.placeholder",
      message: "unit.attributes.salesStatus.error"
    },
    {
      id: "contact_ids",
      label: "unit.attributes.contacts.label",
      placeholder: "unit.attributes.contacts.placeholder",
      message: "unit.attributes.salesStatus.error"
    },
    {
      id: "account_manager_id",
      label: "unit.attributes.accountManager.label",
      placeholder: "unit.attributes.accountManager.placeholder"
    },
    {
      id: "sales_manager_id",
      label: "unit.attributes.salesManager.label",
      placeholder: "unit.attributes.salesManager.placeholder"
    },
    {
      id: "display_name",
      label: "unit.attributes.displayName.label"
    },
    {
      id: "unit_type_id",
      label: "unit.attributes.unitType.label"
    },
    {
      id: "price",
      label: "unit.attributes.price.label"
    },
    {
      id: "floor_space",
      label: "unit.attributes.floorSpace.label"
    },
    {
      id: "floor",
      label: "unit.attributes.floor.label"
    },
    {
      id: "reportable",
      label: "unit.attributes.reportable.label",
      control: "Checkbox"
    },
    {
      id: "featured",
      label: "unit.attributes.featured.label",
      control: "Checkbox"
    },
    {
      id: "bau_digital_demo",
      label: "unit.attributes.bauDigitalDemo.label",
      control: "Checkbox"
    },
    {
      id: "buyer_portal_enabled",
      label: "unit.attributes.buyerPortalEnabled.label",
      control: "Checkbox"
    },
    {
      id: "marketing_portal_enabled",
      label: "unit.attributes.marketingPortalEnabled.label",
      control: "Checkbox"
    },
    {
      id: "flat_finder_enabled",
      label: "unit.attributes.flatFinderEnabled.label",
      control: "Checkbox"
    },
    {
      id: "contract_number",
      label: "unit.attributes.contractNumber.label"
    },
    {
      id: "contract_date",
      label: "unit.attributes.contractDate.label"
    },
    {
      id: "room_count",
      label: "unit.attributes.roomCount.label"
    },
    {
      id: "transfer_date",
      label: "unit.attributes.transferDate.label"
    },
    {
      id: "meter_number_water_cold",
      label: "unit.attributes.meterNumberWaterCold.label"
    },
    {
      id: "meter_number_water_warm",
      label: "unit.attributes.meterNumberWaterWarm.label"
    },
    {
      id: "meter_number_electricity",
      label: "unit.attributes.meterNumberElectricity.label"
    },
    {
      id: "meter_number_gas",
      label: "unit.attributes.meterNumberGas.label"
    },
    {
      id: "external_id",
      label: "unit.attributes.externalId.label",
      placeholder: `unit.attributes.externalId.placeholder`,
      autoComplete: "off"
    },
    {
      id: "unit_feature_ids",
      label: "unit.attributes.unitFeatures.label"
    },
    {
      id: "configurator_design_id",
      label: "unit.attributes.configuratorDesign.label"
    },
    {
      id: "sales_status",
      label: "unit.attributes.salesStatus.label",
      message: "unit.attributes.salesStatus.error",
      rule: (value, data) => {
        if (
          !["RESERVED", "SOLD", "NOTARY"].includes(value) &&
          data.signee_ids.length > 0
        ) {
          return true;
        }
        return false;
      }
    },
    {
      id: "social_media_title_override",
      label: "unit.attributes.socialMediaTitleOverride.label"
    },
    {
      id: "social_media_description_override",
      label: "unit.attributes.socialMediaDescriptionOverride.label"
    },
    {
      id: "project_storey_ids",
      label: "unit.attributes.project_storey_ids.label"
    }
  ]
});

const getSalesStatusOptions = i18n => [
  {
    key: "VACANT",
    value: "VACANT",
    text: i18n["unit.attributes.salesStatus.options.vacant"]
  },
  {
    key: "RESERVED",
    value: "RESERVED",
    text: i18n["unit.attributes.salesStatus.options.reserved"]
  },
  {
    key: "NOTARY",
    value: "NOTARY",
    text: i18n["unit.attributes.salesStatus.options.notary"]
  },
  {
    key: "SOLD",
    value: "SOLD",
    text: i18n["unit.attributes.salesStatus.options.sold"]
  }
];

class UnitDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    unit: PropTypes.object,
    sectionOptions: PropTypes.array,
    buyers: PropTypes.array,
    button: PropTypes.node,
    resource: PropTypes.object,
    handleUpdate: PropTypes.func,
    handleChange: PropTypes.func,
    projectId: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      buyersResource: PropTypes.instanceOf(ProjectBuyersResource)
    }).isRequired,
    unitTypeOptions: PropTypes.arrayOf(OptionsShape).isRequired,
    members: PropTypes.arrayOf(UserShape).isRequired,
    unitFeatures: PropTypes.arrayOf(UnitFeatureShape).isRequired,
    configDesignFeatures: PropTypes.arrayOf(OptionsShape).isRequired,
    projectStoreysOptions: PropTypes.arrayOf(OptionsShape).isRequired,
    deepResource: PropTypes.instanceOf(UnitResource)
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      model: cloneDeep(props.unit)
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  fetchAllAndClose = () => {
    const { deepResource, resource, unit } = this.props;
    if (deepResource)
      return deepResource.get(unit.id, true).then(this.close, this.close);
    return resource.fetchAll().then(this.close, this.close);
  };

  close = () => {
    if (this.unmounted) return;
    this.setState({ open: false, isLoading: false });
  };

  onSave = values => {
    const price_in_cents = parseInt(values.price * 100, 10);
    this.setState({ isLoading: true });
    this.props.resource
      .save({ ...values, price_in_cents })
      .then(this.fetchAllAndClose, this.close);
  };

  onRemove = () => {
    this.setState({ isLoading: true });

    this.props.resource
      .remove(this.state.model.id)
      .then(this.fetchAllAndClose, this.close);
  };

  handleOpen = () => {
    const { unit } = this.props;
    this.setState({
      open: true,
      model: cloneDeep(unit)
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleBuyerCreate = (createdBuyer, role) => {
    const { model } = this.state;
    this.setState({
      model: {
        ...model,
        [`${role}`]: model[`${role}`].concat(createdBuyer.id)
      }
    });
  };

  createBuyerButton = () => {
    const { i18n } = this.props;
    return (
      <Button
        icon
        basic
        className="medium margin mobileBehaviour buyer-new"
        data-tooltip={i18n["unit.actions.addBuyer.label"]}
        data-position="top right"
      >
        <Icon color="grey" name="plus" size="large" style={{ margin: "0" }} />
      </Button>
    );
  };

  createStoreyButton = () => {
    const { i18n } = this.props;
    return (
      <Button
        icon
        basic
        className="medium margin mobileBehaviour storey-new"
        data-tooltip={i18n["unit.actions.addStorey.label"]}
        data-position="top right"
      >
        <Icon color="grey" name="plus" size="large" style={{ margin: "0" }} />
      </Button>
    );
  };

  buyerOptions() {
    const { buyers } = this.props;
    if (buyers?.length > 0) {
      return buyers.map(buyerItem => {
        return {
          key: buyerItem.id,
          value: buyerItem.id,
          text: `${buyerItem.label}`
        };
      });
    }
    return [];
  }

  userOptions() {
    const { members } = this.props;
    if (members?.length > 0) {
      return members.map(({ id, first_name, last_name }) => {
        return {
          key: id,
          value: id,
          text: `${first_name} ${last_name}`
        };
      });
    }
    return [];
  }

  renderBuyerDialogModal(button, role) {
    const { i18n, actions, projectId } = this.props;
    return (
      <BuyerDialog
        i18n={i18n}
        actions={actions}
        projectId={projectId}
        button={button}
        handleBuyerCreate={values => this.handleBuyerCreate(values, role)}
      />
    );
  }

  renderBuyerCreateButton(role) {
    return this.renderBuyerDialogModal(this.createBuyerButton(), role);
  }

  handleStoreyCreate = createdStorey => {
    const { model } = this.state;
    this.setState({
      model: {
        ...model,
        project_storey_ids: model?.project_storey_ids?.concat(createdStorey.id)
      }
    });
  };

  renderStoreysDialogModal(button) {
    return (
      <ProjectStoreys
        button={button}
        handleProjectStoreyCreate={this.handleStoreyCreate}
      />
    );
  }

  renderStoreyCreateButton() {
    return this.renderStoreysDialogModal(this.createStoreyButton());
  }

  render() {
    const { model, open, isLoading } = this.state;
    const {
      i18n,
      sectionOptions,
      button,
      unitTypeOptions,
      unitFeatures,
      configDesignFeatures,
      projectStoreysOptions
    } = this.props;
    const form = FormFactory.create(this.state.model, i18n, {
      onChange: model => this.setState({ model })
    });

    form.fields.section_id.props.options = sectionOptions;

    form.fields.signee_ids.props.multiple = true;
    form.fields.signee_ids.props.selection = true;
    form.fields.signee_ids.props.search = true;
    form.fields.signee_ids.props.closeOnChange = true;
    form.fields.signee_ids.props.options = this.buyerOptions();

    form.fields.contact_ids.props.multiple = true;
    form.fields.contact_ids.props.selection = true;
    form.fields.contact_ids.props.search = true;
    form.fields.contact_ids.props.closeOnChange = true;
    form.fields.contact_ids.props.options =
      form.fields.signee_ids.props.options;

    form.fields.project_storey_ids.props.multiple = true;
    form.fields.project_storey_ids.props.selection = true;
    form.fields.project_storey_ids.props.search = true;
    form.fields.project_storey_ids.props.closeOnChange = true;
    form.fields.project_storey_ids.props.options = projectStoreysOptions;

    form.fields.account_manager_id.props = {
      ...form.fields.account_manager_id.props,
      clearable: true,
      options: this.userOptions()
    };
    form.fields.sales_manager_id.props = {
      ...form.fields.sales_manager_id.props,
      clearable: true,
      options: this.userOptions()
    };

    form.fields.unit_type_id.props = {
      ...form.fields.unit_type_id.props,
      options: unitTypeOptions
    };

    form.fields.unit_feature_ids.props = {
      ...form.fields.unit_feature_ids.props,
      multiple: true,
      search: true,
      selection: true,
      options: unitFeatures,
      clearable: true
    };

    form.fields.configurator_design_id.props = {
      ...form.fields.configurator_design_id.props,
      multiple: false,
      selection: true,
      options: configDesignFeatures,
      clearable: true
    };

    form.fields.sales_status.props = {
      ...form.fields.sales_status.props,
      multiple: false,
      selection: true,
      options: getSalesStatusOptions(i18n)
    };

    const hasBuyers = model.signee_ids.length > 0;

    const floorOptions = {
      label: "㎡",
      labelPosition: "right"
    };

    const panes = [];

    if (!model.sample) {
      panes.push({
        menuItem: "Ewerber",
        render: () => (
          <Tab.Pane>
            <Form.Group>
              <Form.Field disabled={model.sample} className="fullLine">
                <div className="flex align-items-flex-end">
                  <div className="block">
                    <Field component="Select" {...form.fields.signee_ids} />
                  </div>
                  <div className="margin left small">
                    {this.renderBuyerCreateButton("signee_ids")}
                  </div>
                </div>
              </Form.Field>
            </Form.Group>
            <FeatureToggleActive featureToggleName="unit_buyer_contacts">
              <Form.Group>
                <Form.Field disabled={model.sample} className="fullLine">
                  <div className="flex align-items-flex-end">
                    <div className="block">
                      <Field component="Select" {...form.fields.contact_ids} />
                    </div>
                    <div className="margin left small">
                      {this.renderBuyerCreateButton("contact_ids")}
                    </div>
                  </div>
                </Form.Field>
              </Form.Group>
            </FeatureToggleActive>
            <Form.Group>
              <Form.Field className="halfLine">
                <label htmlFor="price">
                  {i18n["unit.attributes.price.label"]}
                </label>
                <CurrencyInput
                  id="price"
                  name="price"
                  autoComplete="off"
                  fluid
                  value={model.price}
                  onChange={(_, { value }) => {
                    this.setState(prev => ({
                      ...prev,
                      model: { ...model, price: value }
                    }));
                  }}
                  defaultValue={model.price}
                />
              </Form.Field>
              <Form.Field className="halfLine">
                <Field component="Select" {...form.fields.sales_status} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <If condition={!model.sample}>
                <Form.Field className="halfLine">
                  <Field component="Input" {...form.fields.contract_number} />
                </Form.Field>
              </If>
            </Form.Group>
            <If condition={!model.sample} block>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field component="Input" {...form.fields.contract_date} />
                </Form.Field>
                <Form.Field className="halfLine">
                  <Field component="Input" {...form.fields.transfer_date} />
                </Form.Field>
              </Form.Group>
            </If>
          </Tab.Pane>
        )
      });
    }

    panes.push(
      {
        menuItem: (
          <Menu.Item key="stammdaten" id="stammdaten">
            Stammdaten
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Form.Group>
              <Form.Field className="halfLine">
                <div className="flex align-items-flex-end">
                  <div className="block">
                    <Field
                      component="Select"
                      {...form.fields.account_manager_id}
                    />
                  </div>
                </div>
              </Form.Field>
              <Form.Field className="halfLine">
                <div className="flex align-items-flex-end">
                  <div className="block">
                    <Field
                      component="Select"
                      {...form.fields.sales_manager_id}
                    />
                  </div>
                </div>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="halfLine">
                <Field component="Input" {...form.fields.display_name} />
              </Form.Field>
              <Form.Field className="halfLine">
                <div className="flex align-items-flex-end">
                  <div className="block">
                    <Field component="Select" {...form.fields.unit_type_id} />
                  </div>
                </div>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="block">
                <Field component="Input" {...form.fields.description} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="halfLine">
                <label htmlFor="price">
                  {i18n["unit.attributes.price.label"]}
                </label>
                <CurrencyInput
                  id="price"
                  name="price"
                  autoComplete="off"
                  fluid
                  value={model.price}
                  onChange={(_, { value }) => {
                    this.setState(prev => ({
                      ...prev,
                      model: { ...model, price: value }
                    }));
                  }}
                  defaultValue={model.price}
                />
              </Form.Field>
              <Form.Field className="halfLine">
                <Field component="Select" {...form.fields.sales_status} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="halfLine">
                <label htmlFor="floor_space">
                  {i18n["unit.attributes.floorSpace.label"]}
                </label>
                <FloatInput
                  id="floor_space"
                  name="floor_space"
                  autoComplete="off"
                  fluid
                  {...floorOptions}
                  value={model.floor_space}
                  onChange={(_, { value }) => {
                    this.setState(prev => ({
                      ...prev,
                      model: { ...model, floor_space: value }
                    }));
                  }}
                />
              </Form.Field>
              <Form.Field className="halfLine">
                <FeatureToggle featureToggleName="project_storeys">
                  <div className="flex align-items-flex-end">
                    <div className="block">
                      <Field
                        component="Select"
                        {...form.fields.project_storey_ids}
                      />
                    </div>
                    <div className="margin left small">
                      {this.renderStoreyCreateButton()}
                    </div>
                  </div>
                </FeatureToggle>
                <FeatureToggle featureToggleName="project_storeys" disabled>
                  <Field component="Input" {...form.fields.floor} />
                </FeatureToggle>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="halfLine">
                <Field component="Input" {...form.fields.room_count} />
              </Form.Field>
              <Form.Field className="halfLine">
                <Field
                  component="Input"
                  {...form.fields.parking_space_number}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="halfLine">
                <Field component="Input" {...form.fields.cellar_number} />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <FeatureToggleActive featureToggleName="show_unit_feature_groups">
                <Form.Field className="fullLine">
                  <Field component="Select" {...form.fields.unit_feature_ids} />
                </Form.Field>
              </FeatureToggleActive>
            </Form.Group>
            <If condition={!model.sample} block>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Input"
                    {...form.fields.meter_number_water_cold}
                  />
                </Form.Field>
                <Form.Field className="halfLine">
                  <Field
                    component="Input"
                    {...form.fields.meter_number_water_warm}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Input"
                    {...form.fields.meter_number_electricity}
                  />
                </Form.Field>
                <Form.Field className="halfLine">
                  <Field component="Input" {...form.fields.meter_number_gas} />
                </Form.Field>
              </Form.Group>
            </If>
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field className="halfLine">
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
            <If condition={!model.sample}>
              <Form.Field className="halfLine">
                <Field component="Checkbox" {...form.fields.reportable} />
              </Form.Field>
            </If>
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item key="konfigurator" id="konfigurator">
            Konfigurator
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <If condition={model.sales_status !== "SOLD"}>
              <Form.Field>
                <label htmlFor="marketing_portal_url">
                  <FormattedMessage id="unit.attributes.marketingPortalLink.label" />
                </label>
                <Input
                  id="marketing_portal_url"
                  readOnly
                  onClick={e => e.target.select()}
                  data-model="marketing_portal_url"
                  action={{
                    icon: "copy",
                    "data-component": "copy-to-clipboard",
                    onClick: () => copyToClipboard(model.marketing_portal_url)
                  }}
                  defaultValue={model.marketing_portal_url}
                />
              </Form.Field>
            </If>
            <If condition={model.sales_status !== "VACANT"}>
              <Form.Field>
                <label htmlFor="buyer_portal_url">
                  <FormattedMessage id="unit.attributes.buyerPortalLink.label" />
                </label>
                <Input
                  id="buyer_portal_url"
                  readOnly
                  onClick={e => e.target.select()}
                  data-model="buyer_portal_url"
                  action={{
                    icon: "copy",
                    "data-component": "copy-to-clipboard",
                    onClick: () => copyToClipboard(model.buyer_portal_url)
                  }}
                  defaultValue={model.buyer_portal_url}
                />
              </Form.Field>
            </If>
            <Form.Group>
              <Form.Field className="halfLine">
                <Field
                  component="Input"
                  {...form.fields.social_media_title_override}
                />
              </Form.Field>
              <Form.Field className="halfLine">
                <Field
                  component="Input"
                  {...form.fields.social_media_description_override}
                />
              </Form.Field>
            </Form.Group>

            <IsSystemAdmin>
              <Form.Field className="halfLine">
                <Field component="Checkbox" {...form.fields.featured} />
              </Form.Field>
            </IsSystemAdmin>
            <IsSystemAdmin>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Checkbox"
                    {...form.fields.bau_digital_demo}
                  />
                </Form.Field>
              </Form.Group>
            </IsSystemAdmin>

            <IsSystemAdmin>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Checkbox"
                    {...form.fields.buyer_portal_enabled}
                  />
                </Form.Field>
              </Form.Group>
            </IsSystemAdmin>

            <IsSystemAdmin>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Checkbox"
                    {...form.fields.marketing_portal_enabled}
                  />
                </Form.Field>
              </Form.Group>
            </IsSystemAdmin>

            <IsSystemAdmin>
              <Form.Group>
                <Form.Field className="halfLine">
                  <Field
                    component="Checkbox"
                    {...form.fields.flat_finder_enabled}
                  />
                </Form.Field>
              </Form.Group>
            </IsSystemAdmin>

            <FeatureToggleActive featureToggleName="bp_configurator_design_admin_20">
              <Form.Field className="halfLine">
                <Field
                  component="Select"
                  {...form.fields.configurator_design_id}
                />
              </Form.Field>
            </FeatureToggleActive>
          </Tab.Pane>
        )
      }
    );

    return (
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
        trigger={button}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="unit.actions.edit"
            defaultMessage="unit.actions.edit"
          />
        </Modal.Header>
        <Modal.Content scrolling style={{ minHeight: "70vh" }}>
          <Form id="unit" data-component="unitForm">
            <Form.Group>
              <Form.Field width="5">
                <Field component="Select" {...form.fields.section_id} />
              </Form.Field>
              <Form.Field width="3">
                <Field component="Input" {...form.fields.unit_number} />
              </Form.Field>
              <If condition={form.fields.signee_ids.props?.value?.length === 0}>
                <Form.Field disabled={hasBuyers} style={{ marginTop: "30px" }}>
                  <Field component="Checkbox" {...form.fields.sample} />
                </Form.Field>
              </If>
            </Form.Group>
            <Tab
              menu={{ borderless: true, attached: false, tabular: false }}
              panes={panes}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8} verticalAlign="middle">
              {this.renderDeleteButton(model)}
            </Grid.Column>
            <Grid.Column textAlign="right" width={8}>
              <Button
                type="submit"
                color="green"
                data-form="unit"
                onClick={form.handleSubmit(this.onSave)}
                loading={isLoading}
                id={(model.id && "unit-edit") || "unit-new"}
              >
                <Icon name="save" />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDeleteButton(unit) {
    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isLoading}
        className="left floated element"
      />
    );

    if (unit.deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemove
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="unit.actions.removeDialog.title"
          message="unit.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: "meta.actions.accept",
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title="unit.actions.undeletableDialog.title"
        message="unit.actions.undeletableDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    buyers: getBuyersWithStatus(state),
    members: getProjectMembers(state),
    unitTypeOptions: getUnitTypesOptions(state),
    unitFeatures: getUnitFeatureOptions(state),
    configDesignFeatures: getConfiguratorDesignFeatures(state),
    projectStoreysOptions: getProjectStoreysOptions(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props;
  return {
    actions: {
      buyersResource: new ProjectBuyersResource(dispatch, projectId)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDialog);
