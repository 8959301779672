import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { sortBy, cloneDeep, isEqual } from "lodash";
import {
  Button,
  Header,
  Icon,
  Item,
  Message,
  Popup,
  Segment,
  Tab
} from "semantic-ui-react";
import { getTradesDictionary } from "shared/selectors";
import ButtonCheckbox from "shared/components/elements/ButtonCheckbox";
import TabDialog from "shared/components/elements/TabDialog";
import { ContractorAssignmentResource } from "builder_portal/actions/activityActions";
import { withRouter } from "../../../shared/helpers/withRouter";

import "./contractorAssignmentDialog.scss";

class ContractorAssignmentDialog extends React.Component {
  constructor(props) {
    super(props);
    const { defaultValue, defaultOpen } = props;
    this.state = {
      value: cloneDeep(defaultValue),
      open: defaultOpen,
      isLoading: false,
      suggestionDismissed: false
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    if (!isEqual(defaultValue, prevProps.defaultValue)) {
      this.writeValueToState(defaultValue);
    }
  }

  writeValueToState = defaultValue => {
    this.setState({ value: cloneDeep(defaultValue) });
  };

  setContractor(trade, contractor_id, checked) {
    const oldValue = this.state.value;
    const { onChange } = this.props;

    const newValue = checked
      ? [...oldValue, { contractor_id, trade }]
      : oldValue.filter(this.notEqual(contractor_id, trade));
    this.setState({ value: newValue });
    onChange?.(this, { value: newValue });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = event => {
    const { defaultValue } = this.props;
    if (
      !event ||
      !event.isPropagationStopped ||
      !event.isPropagationStopped()
    ) {
      this.setState({
        open: false,
        value: defaultValue,
        suggestionDismissed: false
      });
    }
  };

  handleSave(event) {
    const { contractorAssignmentResource, onSaved } = this.props;
    const { value } = this.state;

    event.stopPropagation();
    this.setState({ isLoading: true });
    contractorAssignmentResource
      .update_all({ contractor_assignments: value })
      .then(() => onSaved && onSaved())
      .then(() => {
        this.setState({ isLoading: false, open: false });
      })
      .catch(() => {
        this.setState({ isLoading: false, open: false });
      });
  }

  handleActionClick = (event, data) => {
    if (data.positive) {
      this.handleSave(event);
    }
  };

  notEqual(contractor_id, trade) {
    return ({ contractor_id: id, trade: tr }) =>
      id !== contractor_id || tr !== trade;
  }

  getPanes() {
    const { availableContractors, i18n, requiredTrades } = this.props;

    const initialTrades = requiredTrades.reduce(
      (o, trade) => ({ ...o, [trade]: [] }),
      {}
    );

    const contractorsByTrade = availableContractors.reduce(
      (accu, contractor) => {
        contractor.trades.forEach(trade => {
          accu[trade] = accu[trade] || [];
          accu[trade].push(contractor);
        });
        return accu;
      },
      initialTrades
    );

    const mapForSort = ([trade]) => {
      const isRequired = requiredTrades.includes(trade);
      const label = this.getLabel(trade);
      return [isRequired ? 0 : 1, label];
    };

    const sortedContractorsByTrade = sortBy(
      Object.entries(contractorsByTrade),
      mapForSort
    );

    const panels = [];

    if (requiredTrades.length > 0) {
      panels.push({
        menuItem: i18n["contractor.attributes.trades.suggestion"],
        pane: this.renderSuggestionTab(sortedContractorsByTrade)
      });
    }

    return panels.concat(
      sortedContractorsByTrade.map(([trade, contractors]) => ({
        menuItem: {
          content: this.renderTrade(trade),
          key: trade
        },
        pane: (
          <Tab.Pane basic attached={false} key={trade}>
            {this.renderContractorsSegment(contractors, trade)}
          </Tab.Pane>
        )
      }))
    );
  }

  renderSuggestionTab(sortedContractorsByTrade) {
    const { requiredTrades } = this.props;
    const filteredContractors = sortedContractorsByTrade.filter(
      // eslint-disable-next-line no-unused-vars
      ([trade, contractors]) => {
        return requiredTrades.includes(trade);
      }
    );
    return (
      <Tab.Pane basic attached={false} key="summary">
        {this.renderSuggestionNote()}
        {filteredContractors.map(([trade, contractors]) =>
          this.renderContractorsSegment(contractors, trade)
        )}
      </Tab.Pane>
    );
  }

  renderContractorsSegment(contractors, trade) {
    const { i18n } = this.props;
    return [
      <Header key="category-header" content={this.renderTrade(trade)} />,
      <Segment key="category-body">
        {contractors.length < 1 && (
          <Message warning content={i18n["contractor.message.noneAvaiable"]} />
        )}
        {contractors.length > 0 && (
          <Item.Group divided data-trade={trade}>
            {contractors.map(contractor => (
              <Item key={contractor.id} data-model="contractor">
                <Item.Content>
                  <Header size="tiny">
                    {contractor.label}
                    <Header.Subheader>
                      {contractor.contact_person}
                    </Header.Subheader>
                  </Header>
                  <ButtonCheckbox
                    data-action={
                      this.isAssigned(contractor, trade)
                        ? "remove-contractor"
                        : "add-contractor"
                    }
                    floated="right"
                    compact
                    checked={this.isAssigned(contractor, trade)}
                    onChange={(event, data) =>
                      this.setContractor(trade, contractor.id, data.checked)
                    }
                  />
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        )}
      </Segment>
    ];
  }

  isAssigned(contractor, trade) {
    const { value } = this.state;
    return !value.every(this.notEqual(contractor.id, trade));
  }

  renderTrade(trade) {
    const { availableContractors } = this.props;
    const [active, total] = availableContractors.reduce(
      ([active, total], contractor) => {
        if (contractor.trades.includes(trade)) total += 1;
        if (this.isAssigned(contractor, trade)) active += 1;
        return [active, total];
      },
      [0, 0]
    );

    return [
      `${this.getLabel(trade)} (${active}/${total})`,
      this.renderTradeRequiredIcon(active, trade)
    ];
  }

  getLabel(trade) {
    const { trades } = this.props;
    return trades[trade]?.label;
  }

  renderTradeRequiredIcon(active, trade) {
    const { requiredTrades, i18n } = this.props;
    if (active < 1 && requiredTrades.includes(trade)) {
      return (
        <Popup
          key="popup"
          trigger={<Icon name="warning circle" color="yellow" />}
          content={i18n["contractor.message.required"]}
        />
      );
    }
    return null;
  }

  renderIsContractorMissingIcon() {
    const { i18n } = this.props;

    return this.isContractorMissing() ? (
      <Popup
        trigger={<Icon name="warning circle" color="red" />}
        content={i18n["contractor.message.missing"]}
      />
    ) : null;
  }

  renderSuggestionNote() {
    const { i18n } = this.props;
    const { suggestionDismissed, isLoading } = this.state;
    return !suggestionDismissed && this.isMissingContractorAvailable() ? (
      <Message warning onDismiss={this.handleDismissSuggestion}>
        <Message.Header>{i18n["contractor.message.missing"]}</Message.Header>
        <Message.Content>
          <Button
            positive
            loading={isLoading}
            disabled={isLoading}
            content={i18n["contractor.actions.suggestion"]}
            onClick={this.handleGetSuggestion}
          />
        </Message.Content>
      </Message>
    ) : null;
  }

  isContractorMissing() {
    const { requiredTrades } = this.props;
    const { value } = this.state;
    return requiredTrades.some(
      trade =>
        !value.some(
          contractor_assignment => contractor_assignment.trade === trade
        )
    );
  }

  isMissingContractorAvailable() {
    const { requiredTrades, availableContractors } = this.props;
    const { value } = this.state;
    const assignedTrades = value.map(ca => ca.trade);

    return requiredTrades.some(trade => {
      return (
        !assignedTrades.includes(trade) &&
        availableContractors.some(contractor =>
          contractor.trades.includes(trade)
        )
      );
    });
  }

  handleDismissSuggestion = () => {
    this.setState({ suggestionDismissed: true });
  };

  handleGetSuggestion = () => {
    const { contractorAssignmentResource } = this.props;
    this.setState({ isLoading: true });
    contractorAssignmentResource
      .getSuggestion()
      .then(({ data }) => {
        this.applySuggestion(data);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false, open: false });
      });
  };

  applySuggestion(suggestion) {
    const { value } = this.state;

    const assignedTrades = value.map(ca => ca.trade);

    suggestion.forEach(item => {
      if (!assignedTrades.includes(item.trade)) {
        value.push(item);
      }
    });

    this.setState({ value });
  }

  render() {
    const { label, header, i18n } = this.props;
    const { isLoading, open } = this.state;

    const actions = {
      actions: [
        {
          key: "cancel",
          content: i18n["meta.actions.cancel"],
          loading: isLoading,
          disabled: isLoading
        },
        {
          key: "save",
          "data-action": "save",
          content: i18n["meta.actions.save"],
          positive: true,
          loading: isLoading,
          disabled: isLoading
        }
      ],
      onActionClick: this.handleActionClick
    };

    return (
      <TabDialog
        data-component="ContractorAssignmentDialog"
        triggerButton={{
          "data-component": "ContractorAssignmentDialog",
          content: (
            <div className="leftAlignedWithIcon">
              {label || i18n["contractor.actions.assign"]}
              {this.renderIsContractorMissingIcon()}
            </div>
          ),
          icon: "shipping",
          compact: false,
          fluid: true
        }}
        header={header || label || i18n["contractor.actions.assign"]}
        open={open}
        actions={actions}
        panes={this.getPanes()}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        menu={{
          vertical: true,
          pointing: true,
          secondary: true,
          attached: true,
          tabular: true,
          className: "trades"
        }}
      />
    );
  }
}

ContractorAssignmentDialog.propTypes = {
  i18n: PropTypes.object.isRequired,
  label: PropTypes.node,
  header: PropTypes.node,
  defaultOpen: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      contractor_id: PropTypes.number,
      trade: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  onSaved: PropTypes.func,
  requiredTrades: PropTypes.arrayOf(PropTypes.string),
  contractorAssignmentResource: PropTypes.instanceOf(
    ContractorAssignmentResource
  ).isRequired,
  availableContractors: PropTypes.arrayOf(PropTypes.object),
  trades: PropTypes.objectOf(PropTypes.object)
};

ContractorAssignmentDialog.defaultProps = {
  defaultValue: [],
  defaultOpen: false
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  trades: getTradesDictionary(state),
  availableContractors: state.pageContent.available_contractors,
  defaultValue: state.pageContent.contractor_assignments,
  contractors: state.pageContent.contractors,
  requiredTrades: state.pageContent.activity.trades
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    contractorAssignmentResource: new ContractorAssignmentResource(
      dispatch,
      props.params.activityId
    )
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractorAssignmentDialog)
);
