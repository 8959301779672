/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export class UnitFeatureGroupsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "unit_feature_group",
      `/api/v1/projects/${projectId}/unit_feature_groups`
    );
    this.refreshPageContentAfterCommit = true;
  }

  importDefaults() {
    return apiRequest
      .post(`${this.url}/import_defaults`, this.dispatch, {})
      .then(this.propagateUpdate.bind(this));
  }
}

export class UnitFeatureResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "unit_feature",
      `/api/v1/projects/${projectId}/unit_features`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
