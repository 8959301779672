/* eslint-disable react/no-multi-comp */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Header } from "semantic-ui-react";
import { getLineItemOptions } from "builder_portal/selectors/roomBooks/optionalLineItems";
import { LineItemShape } from "shared/components/configurator/roomBook.shape";
import UpgradeBundleShape from "shared/shapes/upgradeBundles.shape";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getUpgradeBundleOptions } from "../../../selectors/upgradeBundles";

const Conditional = props => {
  if (props.visible) {
    return <div>{props.children}</div>;
  }
  return null;
};

Conditional.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node
};

const ToggleButton = props => {
  const active = !!props.value;
  const color = active ? "green" : "grey";
  return (
    <div style={{ marginBottom: "20px" }}>
      <Header>{props.label}</Header>
      <Button.Group>
        <Button
          id={`${props.id}-on`}
          content="Ja"
          size="large"
          basic={!active}
          color={color}
          onClick={props.onClick}
        />
        <Button
          id={`${props.id}-off`}
          content="Nein"
          size="large"
          basic={active}
          color={color}
          onClick={props.onClick}
        />
      </Button.Group>
    </div>
  );
};

ToggleButton.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
};

const UpgradeBundles = ({
  label,
  upgradeBundleOptions,
  handleUpgradeBundleChange,
  lineItem
}) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <Header>{label}</Header>
      <Dropdown
        fluid
        search
        selection
        clearable
        id="upgrade-bundles"
        allowAdditions={false}
        selectOnBlur
        multiple
        defaultValue={lineItem.currentModel.upgrade_bundle_ids}
        onChange={handleUpgradeBundleChange}
        options={upgradeBundleOptions}
      />
    </div>
  );
};

UpgradeBundles.propTypes = {
  label: PropTypes.string.isRequired,
  handleUpgradeBundleChange: PropTypes.func.isRequired,
  upgradeBundleOptions: PropTypes.arrayOf(UpgradeBundleShape),
  lineItem: LineItemShape.isRequired
};

UpgradeBundles.defaultProps = {
  upgradeBundleOptions: []
};

// TODO: i18n (whole file)
class OptionalityConfigPage extends Component {
  static propTypes = {
    lineItem: PropTypes.object,
    lineItems: PropTypes.array,
    onChange: PropTypes.func,
    sameSubSectionItems: PropTypes.array,
    siblingSubSections: PropTypes.array,
    additionalSections: PropTypes.array,
    currentSection: PropTypes.object,
    currentSubSection: PropTypes.object,
    upgradeBundleOptions: PropTypes.arrayOf(UpgradeBundleShape)
  };

  constructor(props) {
    super(props);

    this.state = {
      optional: props.lineItem.isOptional(),
      replacement: props.lineItem.isReplacement(),
      searchQuery: ""
    };
  }

  update(field, value) {
    this.setState({ [field]: value }, this.checkOnChange);
  }

  handleDropdownChange = (_event, { value }) => {
    const { onChange } = this.props;
    const { optional } = this.state;
    if (onChange) {
      onChange({ lineItemId: value, optional });
    }
  };

  handleUpgradeBundleChange = (_event, { value }) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ upgradeBundleIds: value, optional: true });
    }
  };

  handleSearch(query) {
    this.setState({ searchQuery: query });
  }

  checkOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.state);
    }
  };

  filterItems(items) {
    const { searchQuery } = this.state;
    const lowerCasedQuery = searchQuery.toLowerCase();

    return items.filter(item => {
      return (
        item.display_number.toLowerCase().includes(lowerCasedQuery) ||
        item.title.toLowerCase().includes(lowerCasedQuery)
      );
    });
  }

  addHeader(title) {
    return <Dropdown.Header content={title} key={title} />;
  }

  // Keep this an Object! Semantic's "selection"-Dropdown is incompatible with Dropdown.Item Elements
  generateDropdownItem = item => ({
    key: item.id,
    value: item.id,
    text: `${item.display_number} ${item.title}`
  });

  getSameSubSectionItems() {
    const { sameSubSectionItems } = this.props;

    return this.filterItems(sameSubSectionItems).map(this.generateDropdownItem);
  }

  getSiblingSubSectionsItems() {
    const { siblingSubSections } = this.props;

    return siblingSubSections
      .map(subSection => {
        const filteredItems = this.filterItems(subSection.line_items);
        return filteredItems.map(this.generateDropdownItem);
      })
      .flat();
  }

  getAdditionalSubSectionsItems() {
    const { additionalSections } = this.props;

    return additionalSections
      .map(section => {
        return section.sections.map(subSection => {
          const filteredItems = this.filterItems(subSection.line_items);

          return filteredItems.map(this.generateDropdownItem);
        });
      })
      .flat(2);
  }

  renderSameSubSectionItems() {
    const { currentSection, currentSubSection } = this.props;
    const sameSubSectionItems = this.getSameSubSectionItems();
    if (sameSubSectionItems.length) {
      return [
        this.addHeader(`${currentSection.title} - ${currentSubSection.title}`),
        ...sameSubSectionItems,
        <Dropdown.Divider key="sameSubSectionItems" />
      ];
    }
    return [];
  }

  renderSiblingSubSectionItems() {
    const { currentSection } = this.props;
    const siblingSubSectionsItems = this.getSiblingSubSectionsItems();
    if (siblingSubSectionsItems.length) {
      return [
        this.addHeader(currentSection.title),
        ...siblingSubSectionsItems,
        <Dropdown.Divider key="siblingSubSectionItems" />
      ];
    }
    return [];
  }

  renderAdditionalSubSectionsItems() {
    const additionalSubSectionsItems = this.getAdditionalSubSectionsItems();

    if (additionalSubSectionsItems.length) {
      return [
        this.addHeader("Weitere Positionen"),
        ...additionalSubSectionsItems
      ];
    }
    return [];
  }

  render() {
    const { optional, replacement, searchQuery } = this.state;
    const { lineItem, upgradeBundleOptions } = this.props;

    return (
      <div>
        <ToggleButton
          label="Ist diese Position optional?"
          value={optional}
          id="optional"
          onClick={() => {
            this.update("optional", !optional);
          }}
        />
        <Conditional visible={optional}>
          <FeatureToggleActive featureToggleName="show_upgrade_bundles">
            <UpgradeBundles
              label="Upgrade Pakete"
              upgradeBundleOptions={upgradeBundleOptions}
              handleUpgradeBundleChange={this.handleUpgradeBundleChange}
              lineItem={lineItem}
            />
          </FeatureToggleActive>
          <ToggleButton
            label="Wird durch die Aktivierung eine andere Position ersetzt?"
            value={replacement}
            id="replacement"
            hidden={!optional}
            onClick={() => {
              this.update("replacement", !replacement);
            }}
          />
        </Conditional>
        <Conditional visible={optional && replacement}>
          <Header>Welche Position wird durch die Aktivierung ersetzt?</Header>
          <Dropdown
            fluid
            search
            selection
            id="replacement-line-item"
            allowAdditions={false}
            selectOnBlur
            defaultValue={lineItem.getReplacementForId()}
            searchQuery={searchQuery}
            onSearchChange={(e, { searchQuery }) =>
              this.handleSearch(searchQuery)
            }
            onChange={this.handleDropdownChange}
            options={[
              ...this.renderSameSubSectionItems(),
              ...this.renderSiblingSubSectionItems(),
              ...this.renderAdditionalSubSectionsItems()
            ].filter(item => item.value !== lineItem.currentModel?.id)}
          />
        </Conditional>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const lineItems = getLineItemOptions(state);
  const { sections } = state.pageContent.room_book;
  const currentSubSectionId = props.lineItem.currentModel.section_id;
  const currentSection = sections.find(section =>
    section.sections.some(subSection => subSection.id === currentSubSectionId)
  );
  const currentSubSection = currentSection.sections.find(
    section => section.id === currentSubSectionId
  );
  const sameSubSectionItems = lineItems.filter(
    item => item.section_id === currentSubSectionId
  );
  const siblingSubSections = currentSection.sections.filter(
    section => section.id !== currentSubSectionId
  );
  const additionalSections = sections.filter(
    section => section.id !== currentSection.id
  );
  const upgradeBundleOptions = getUpgradeBundleOptions(state);

  return {
    lineItems,
    sections,
    currentSubSection,
    currentSubSectionId,
    currentSection,
    sameSubSectionItems,
    siblingSubSections,
    additionalSections,
    upgradeBundleOptions
  };
};

OptionalityConfigPage.defaultProps = {
  upgradeBundleOptions: []
};

export default connect(mapStateToProps)(OptionalityConfigPage);
