import Resource from "../../shared/actions/resourceActions";
import apiRequest from "../../shared/network/apiRequest";

export default class ZendeskResource extends Resource {
  constructor(dispatch) {
    super(dispatch, "token", "/api/v1/zendesk");
  }

  get(time) {
    const url = `${this.url}?timestamp=${time}`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  getWidgetToken() {
    return apiRequest.get(`${this.url}?mode=widget`, this.dispatch);
  }
}
