import { arrayOf, instanceOf, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AttachmentsList from "../attachment/AttachmentsList";
import { I18nShape } from "../../../shared/shapes/i18n.shape";
import { getAttachments } from "../../selectors";
import { AttachmentShape } from "../../../shared/shapes/attachment.shape";
import { UnitResource } from "../../actions/unitActions";
import { getI18N } from "../../../shared/selectors";
import { AccountShape, ProjectShape } from "../../../shared/shapes";

class UnitItemAttachmentWrapper extends Component {
  constructor(props) {
    super(props);

    this.removeAttachment = this.removeAttachment.bind(this);
    this.updateAttachment = this.updateAttachment.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
    this.handleUpdateAttachment = this.handleUpdateAttachment.bind(this);
  }

  removeAttachment(id) {
    const { actions, unitId } = this.props;

    return actions.unit.removeAttachment(id, unitId).then(() => {
      actions.unit.get(unitId, true);
    });
  }

  updateAttachment(values) {
    const { actions, unitId } = this.props;
    return actions.unit
      .updateAttachment({ attachment: values }, unitId)
      .then(() => {
        return actions.unit.get(unitId, true);
      });
  }

  addAttachment() {
    const { actions, unitId } = this.props;
    return actions.unit.get(unitId, true);
  }

  handleRemoveAttachment() {
    // proxy for react linter
    return this.removeAttachment.apply(this, arguments);
  }

  handleUpdateAttachment() {
    // proxy for react linter
    return this.updateAttachment.apply(this, arguments);
  }

  render() {
    const { unitId, actions, context, i18n, attachments } = this.props;
    return (
      <div>
        <AttachmentsList
          context={context}
          actions={actions}
          resourceId={unitId}
          resourceName="/units"
          i18n={i18n}
          handleRemove={this.removeAttachment}
          handleUpdate={this.updateAttachment}
          handleAdd={this.addAttachment}
          attachments={attachments}
          destructionMode="unit"
        />
      </div>
    );
  }
}

UnitItemAttachmentWrapper.defaultProps = {
  attachments: {}
};

UnitItemAttachmentWrapper.propTypes = {
  actions: shape({ unit: instanceOf(UnitResource) }).isRequired,
  unitId: string.isRequired,
  attachments: objectOf(arrayOf(AttachmentShape)),
  i18n: I18nShape.isRequired,
  context: shape({
    account: AccountShape,
    project: ProjectShape
  }).isRequired
};

const mapStateToProps = createStructuredSelector({
  i18n: getI18N,
  attachments: getAttachments
});

export default connect(mapStateToProps)(UnitItemAttachmentWrapper);
