import { func, instanceOf } from "prop-types";
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Icon, Input, Popup, Table } from "semantic-ui-react";
import {
  calcMargin,
  calcRetailPrice
} from "builder_portal/containers/activity/activityModel";
import { formatCurrency } from "shared/helpers/formatCurrency";
import { get, sumBy } from "lodash";
import { Account as AccountModel } from "shared/models/account";
import FormatCurrency from "../../../shared/components/currency/FormatCurrency";
import ContractorLabel from "../../../shared/components/elements/contractorLabel";
import CurrencyInput from "../../../shared/components/forms/CurrencyInput";
import FloatField from "../../../shared/components/forms/FloatInput";
import FormatQuantity from "../../../shared/components/quantity/FormatQuantity";
import "./importInquiryTable.scss";
import retailItemsShape from "./retailItems.shape";
import FeatureToggle from "../../../shared/components/elements/FeatureToggle";
import FormattedMessageWithFlag from "../../../shared/components/textFormatting/FormattedMessageWithFlag";
import { getAccount } from "../../../shared/selectors";

class ImportInquiryTable extends Component {
  shouldComponentUpdate(nextProps) {
    const { retailSubLineItems } = this.props;

    return (
      JSON.stringify(retailSubLineItems) !==
      JSON.stringify(nextProps.retailSubLineItems)
    );
  }

  // Dragging this Method out of context would suffer readability.
  // eslint-disable-next-line class-methods-use-this
  renderDisplayNumber({ isLabel, display_number: displayNumber }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.position_number"
          defaultMessage="#"
        />
      );
    }
    return displayNumber;
  }

  // Dragging this Method out of context would suffer readability.
  // eslint-disable-next-line class-methods-use-this
  renderSubLineItemTitle({ isLabel, title: subLineItemTitle }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.sub_line_item_title"
          defaultMessage="Titel"
        />
      );
    }
    return subLineItemTitle;
  }

  // Dragging this Method out of context would suffer readability.
  // eslint-disable-next-line class-methods-use-this
  renderContractorLabel({ isLabel, contractorId }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.title.one"
          defaultMessage="Nachunternehmen"
        />
      );
    }
    return <ContractorLabel contractorId={contractorId} />;
  }

  // Dragging this Method out of context would suffer readability.
  // eslint-disable-next-line class-methods-use-this
  renderQuantity({ isLabel, quantity, price_strategy: priceStrategy }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.quantity"
          defaultMessage="Menge"
        />
      );
    }
    return <FormatQuantity unit={priceStrategy} value={quantity} />;
    /* #keep for reference and quick change
    return (
      <Input
        readOnly
        labelPosition="right"
        value={
          priceStrategy === "flat"
            ? i18n[`roomBook.priceStrategy.${priceStrategy}`]
            : quantity
        }
        label={
          <Dropdown
            value={priceStrategy}
            readOnly
            icon={null}
            options={[
              {
                key: priceStrategy,
                text: i18n[`roomBook.priceStrategyShort.${priceStrategy}`],
                value: priceStrategy
              }
            ]}
          />
        }
      />
    );
    */
  }

  // Dragging this Method out of context would suffer readability.
  // eslint-disable-next-line class-methods-use-this
  renderTrade({ isLabel, tradeLabel }) {
    if (isLabel) {
      return <FormattedMessage id="trades.title.one" defaultMessage="Gewerk" />;
    }
    return tradeLabel;
  }

  renderDefaultMarginOverwrite({ isLabel }) {
    const { retailSubLineItems, onChange } = this.props;

    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.margin_overwrite_all"
          defaultMessage="Alle Aufschläge überschreiben"
        />
      );
    }

    return (
      <Input
        size="small"
        className="percentInput"
        label="%"
        labelPosition="right"
        onBlur={event => {
          const nextMargin = event.target.value;
          if (nextMargin !== "") {
            // only change Fields if an Values was typed
            const nextRetailSubLineItems = {};
            Object.keys(retailSubLineItems).forEach(subLineItemId => {
              const prevRetailSubLineItem = retailSubLineItems[subLineItemId];
              const prevRetailItems = prevRetailSubLineItem.retails;
              const nextRetails = prevRetailItems.map(prevRetailItem => {
                if (prevRetailItem.margin !== +nextMargin) {
                  // nextRetailItem
                  return {
                    ...prevRetailItem,
                    margin: +nextMargin,
                    retailPrice: calcRetailPrice({
                      ...prevRetailItem,
                      margin: +nextMargin
                    })
                  };
                }
                return prevRetailItem;
              });

              nextRetailSubLineItems[subLineItemId] = {
                ...retailSubLineItems[subLineItemId],
                price: sumBy(nextRetails, "retailPrice"),
                retails: nextRetails
              };
            });
            onChange({ nextRetailSubLineItems });
          }
        }}
      />
    );
  }

  renderDefaultMargin({
    isLabel,
    subLineItemId,
    priceInquiryItemCostId,
    tabIndex
  }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.margin"
          defaultMessage="Aufschlag"
        />
      );
    }

    const { retailSubLineItems, onChange } = this.props;
    const retailSubLineItem = retailSubLineItems[subLineItemId];
    const retailItems = retailSubLineItem.retails;
    const retailItem = retailItems.find(
      retail => retail.priceInquiryItemCostId === priceInquiryItemCostId
    );
    return (
      <FloatField
        size="small"
        tabIndex={tabIndex}
        className="percentInput"
        label="%"
        labelPosition="right"
        value={retailItem.margin || 0}
        onBlur={(_, { value: nextMargin }) => {
          const nextRetails = retailItems.map(prevRetailItem => {
            if (
              prevRetailItem.priceInquiryItemCostId === priceInquiryItemCostId
            ) {
              // nextRetailItem
              return {
                ...prevRetailItem,
                margin: +nextMargin,
                retailPrice: calcRetailPrice({
                  ...retailItem,
                  margin: +nextMargin
                })
              };
            }

            return prevRetailItem;
          });
          const nextRetailSubLineItems = {
            [subLineItemId]: {
              ...retailSubLineItems[subLineItemId],
              price: sumBy(nextRetails, "retailPrice"),
              retails: nextRetails
            }
          };

          onChange({ nextRetailSubLineItems });
        }}
      />
    );
  }

  // Dragging this Method out of context would suffer readabillity.
  // eslint-disable-next-line class-methods-use-this
  renderPurchasePrice({ isLabel, purchasePrice }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.net_purchase_price"
          defaultMessage="Netto Einkaufspreis"
        />
      );
    }
    return <FormatCurrency readOnly amount={purchasePrice} />;
    // return <CurrencyInput readOnly value={purchase} />; # keep for quick change
  }

  // Dragging this Method out of context would suffer readabillity.
  // eslint-disable-next-line class-methods-use-this
  renderGrossTotal({ isLabel, price }) {
    if (isLabel) {
      return (
        <FormattedMessageWithFlag
          featureToggleName="show_price_net_label"
          id="contractor.inquiry.import_inquiry.net_total_sum"
          alternativeId="contractor.inquiry.import_inquiry.gross_total_sum"
        />
      );
    }
    return <strong>{formatCurrency(price)}</strong>;
    // <CurrencyInput readOnly value={price} /> # keep for quick change
  }

  // Dragging this Method out of context would suffer readabillity.
  // eslint-disable-next-line class-methods-use-this
  renderSummary({ isLabel, summary }) {
    if (isLabel) {
      return (
        <FormattedMessage
          id="contractor.inquiry.import_inquiry.summary"
          defaultMessage="Beschreibung"
        />
      );
    }
    return summary;
  }

  renderRetailPrice({
    isLabel,
    subLineItemId,
    priceInquiryItemCostId,
    tabIndex
  }) {
    const { account } = this.props;
    const showNetto = account.isEnabled("show_price_net_label");

    if (isLabel) {
      return (
        <FormattedMessageWithFlag
          featureToggleName="show_price_net_label"
          id="contractor.inquiry.import_inquiry.net_retail_price"
          alternativeId="contractor.inquiry.import_inquiry.gross_retail_price"
        />
      );
    }
    const { retailSubLineItems, onChange } = this.props;
    const retailSubLineItem = retailSubLineItems[subLineItemId];
    const retailItems = retailSubLineItem.retails;
    const retailItem = retailItems.find(
      retail => retail.priceInquiryItemCostId === priceInquiryItemCostId
    );

    const initialValue = showNetto
      ? retailItem.purchasePrice
      : retailItem.retailPrice;

    return (
      <CurrencyInput
        size="small"
        tabIndex={tabIndex}
        value={initialValue || 0}
        onBlur={(_, { value: nextRetailPrice }) => {
          const nextMargin = calcMargin({
            ...retailItem,
            retailPrice: +nextRetailPrice
          });
          const nextRetails = retailItems.map(prevRetailItem => {
            if (
              prevRetailItem.priceInquiryItemCostId === priceInquiryItemCostId
            ) {
              // nextRetailItem
              return {
                ...prevRetailItem,
                margin: +nextMargin,
                retailPrice: nextRetailPrice
              };
            }
            return prevRetailItem;
          });

          const nextRetailSubLineItems = {
            [subLineItemId]: {
              ...retailSubLineItems[subLineItemId],
              price: sumBy(nextRetails, "retailPrice"),
              retails: nextRetails
            }
          };

          onChange({ nextRetailSubLineItems });
        }}
      />
    );
  }

  render() {
    const { retailSubLineItems } = this.props;
    const taxRate = get(
      retailSubLineItems[Object.keys(retailSubLineItems)[0]],
      "retails[0].taxRate"
    );
    return (
      <Table selectable data-component="importInquiryTable" textAlign="right">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {this.renderDisplayNumber({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">
              {this.renderSubLineItemTitle({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">
              {this.renderTrade({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.renderPurchasePrice({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.renderDefaultMargin({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.renderQuantity({ isLabel: true })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.renderRetailPrice({ isLabel: true })}{" "}
              <FeatureToggle featureToggleName="show_price_net_label" disabled>
                <Popup
                  content={
                    <FormattedMessage
                      id="contractor.inquiry.import_inquiry.info_calculation"
                      values={{ tax: taxRate, p: text => <p>{text}</p> }}
                    />
                  }
                  key="retailPriceHint"
                  trigger={<Icon name="info circle" color="blue" />}
                  wide
                />
              </FeatureToggle>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {Object.keys(retailSubLineItems).map((key, i, arr) => {
          const subLineItem = retailSubLineItems[key];
          return (
            <Fragment key={key}>
              <Table.Body>
                <Popup
                  wide
                  trigger={
                    <Table.Row>
                      <Table.Cell rowSpan={subLineItem.retails.length + 1}>
                        {this.renderDisplayNumber(subLineItem)}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="left"
                        rowSpan={subLineItem.retails.length + 1}
                      >
                        {this.renderSubLineItemTitle(subLineItem)}
                      </Table.Cell>
                    </Table.Row>
                  }
                >
                  <Popup.Header>
                    {this.renderSummary({ isLabel: true })}
                  </Popup.Header>
                  <Popup.Content>
                    {this.renderSummary(subLineItem)}
                  </Popup.Content>
                </Popup>
                {subLineItem.retails.map(retailItem => {
                  return (
                    <Table.Row key={retailItem.priceInquiryItemCostId}>
                      <Popup
                        trigger={
                          <Table.Cell textAlign="left">
                            {this.renderTrade(retailItem.trade)}
                          </Table.Cell>
                        }
                      >
                        <Popup.Header>
                          <FormattedMessage
                            id="contractor.title.one"
                            defaultMessage="Nachunternehmen"
                          />
                        </Popup.Header>
                        <Popup.Content>
                          {this.renderContractorLabel(retailItem)}
                        </Popup.Content>
                      </Popup>
                      <Table.Cell data-cell="purchasePrice">
                        {this.renderPurchasePrice(retailItem)}
                      </Table.Cell>
                      <Table.Cell data-cell="marginInput">
                        {this.renderDefaultMargin({
                          subLineItemId: subLineItem.id,
                          priceInquiryItemCostId:
                            retailItem.priceInquiryItemCostId,
                          tabIndex: i + 1
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        {this.renderQuantity(subLineItem)}
                      </Table.Cell>
                      <Table.Cell data-cell="retailPrice">
                        {this.renderRetailPrice({
                          subLineItemId: subLineItem.id,
                          priceInquiryItemCostId:
                            retailItem.priceInquiryItemCostId,
                          tabIndex: arr.length + i + 1
                        })}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine colSpan={6}>
                    {this.renderGrossTotal({
                      isLabel: true
                    })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.renderGrossTotal(subLineItem)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Fragment>
          );
        })}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell colSpan={2} singleLine>
              <strong>
                {this.renderDefaultMarginOverwrite({ isLabel: true })}
              </strong>
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.renderDefaultMarginOverwrite({ isLabel: false })}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    account: getAccount(state)
  };
}

ImportInquiryTable.propTypes = {
  retailSubLineItems: retailItemsShape,
  onChange: func.isRequired,
  account: instanceOf(AccountModel).isRequired
};
ImportInquiryTable.defaultProps = {
  retailSubLineItems: {}
};

export default connect(mapStateToProps)(ImportInquiryTable);
