import { createSelector } from "reselect";
import { get, groupBy, compact, uniq } from "lodash";
import { getFilter } from "../base";
import { isReady } from "./base";
import { getLineItems } from "./lineItems";
import { getSubLineItemsByLineItemId } from "./subLineItems";
import { getSections } from "./sections";

export const getOrderStatusFilter = createSelector([getFilter], filter => {
  return get(filter, "bpOrderStatusFilter", []);
});

// concatenate product data without empty fields
const brewProductName = (subLineItemOption, subLineItem) =>
  compact([
    subLineItem?.productGroup?.name ?? subLineItem?.title,
    subLineItemOption?.name,
    subLineItemOption?.supplier,
    subLineItemOption?.series
  ]).join(", ");

const getProductNamesByLineItem = createSelector(
  [getSubLineItemsByLineItemId],
  subLineItems => {
    return Object.entries(subLineItems).reduce((accu, [lineItemId, items]) => {
      accu[lineItemId] = uniq(
        compact(
          items.map(subLineItem => {
            return subLineItem.options
              .filter(option => option.isActualSelection)
              .map(option => brewProductName(option, subLineItem))[0];
          })
        )
      );

      return accu;
    }, {});
  }
);

const getFilteredLineItems = createSelector(
  [getLineItems, getProductNamesByLineItem, getOrderStatusFilter],
  (lineItems, productNames, filter) => {
    const result = lineItems
      .filter(lineItem => {
        return (
          lineItem.isActivated &&
          lineItem.isVisible &&
          (filter.length < 1 || filter.includes(lineItem.status.id))
        );
      })
      .map(lineItem => {
        return {
          ...lineItem,
          productNames: productNames[lineItem.id]
        };
      });

    return groupBy(result, "sectionId");
  }
);

const getFilteredSubSections = createSelector(
  [getSections, getFilteredLineItems],
  (sections, lineItems) => {
    // Find all relevant sub sectionss
    const result = sections
      .filter(subSection => {
        return (
          subSection.isVisible &&
          Object.keys(lineItems).includes(String(subSection.id))
        );
      })
      .map(subSection => {
        return {
          ...subSection,
          total: lineItems[subSection.id]
            .filter(li => !li.isCancelled)
            .reduce((sum, li) => sum + li.total, 0)
        };
      });

    return groupBy(result, "parentId");
  }
);

const getFilteredSections = createSelector(
  [getSections, getFilteredSubSections],
  (sections, subSections) => {
    // Find all relevant sections
    return sections
      .filter(section => {
        return (
          section.isRoot &&
          section.isVisible &&
          Object.keys(subSections).includes(String(section.id))
        );
      })
      .map(section => {
        return {
          ...section,
          total: subSections[section.id].reduce((sum, s) => sum + s.total, 0)
        };
      });
  }
);

const getUnitTotal = createSelector([getFilteredSections], sections => {
  return sections.reduce((sum, s) => sum + s.total, 0);
});

const isPriceOnRequest = createSelector([getFilteredSections], sections => {
  return sections.some(s => s.isPriceOnRequest);
});

export const getOrderStatusDetails = createSelector(
  [
    isReady,
    getFilteredLineItems,
    getFilteredSubSections,
    getFilteredSections,
    getUnitTotal,
    isPriceOnRequest
  ],
  (isReady, lineItems, subSections, sections, total, isPriceOnRequest) => {
    if (isReady) {
      return {
        unit: {
          total,
          isPriceOnRequest
        },
        sections,
        subSections,
        lineItems
      };
    }
    return {};
  }
);
