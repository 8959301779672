import React from "react";
import { Form, Grid, Icon } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { shape, string, func } from "prop-types";

const SemanticFormikColorInputWithHexField = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label,
    defaultValue
  } = props;

  const handleResetColor = () => {
    setFieldValue(name, undefined);
  };

  const handleHexInput = e => {
    const input = e.target.value || "#";
    const match = input.match("#[0-9a-fA-F]{0,6}");
    const validatedInput = match ? match[0] : undefined;

    setFieldValue(name, validatedInput);
  };

  return (
    <Form.Field>
      <Grid style={{ alignItems: "center" }}>
        <Grid.Column width={4}>
          <label
            style={{
              marginTop: "5px",
              color: "rgba(0,0,0,.87)",
              fontSize: ".92857143em",
              fontWeight: 700
            }}
          >
            {label}
          </label>
        </Grid.Column>
        <Grid.Column width={7} textAlign="left">
          <input
            type="color"
            onChange={event => {
              setFieldValue(name, event.target.value);
            }}
            value={value || defaultValue}
            style={{
              width: "30px",
              height: "30px",
              padding: 0,
              marginLeft: "20px",
              border: "none",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.21), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          />
          <input
            type="text"
            onChange={e => {
              handleHexInput(e);
            }}
            value={value || defaultValue}
            style={{ display: "inline", width: "10em", marginLeft: "2em" }}
          />
        </Grid.Column>
        <If condition={!!value}>
          <Grid.Column width={1} textAlign="left">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="button"
              onClick={handleResetColor}
              onKeyPress={handleResetColor}
              tabIndex={0}
            >
              <Icon name="undo" />
            </a>
          </Grid.Column>
        </If>
      </Grid>
    </Form.Field>
  );
};

SemanticFormikColorInputWithHexField.propTypes = {
  field: shape({
    name: string
  }).isRequired,
  form: shape({
    setFieldValue: func
  }).isRequired,
  label: string.isRequired,
  defaultValue: string.isRequired
};

export default SemanticFormikColorInputWithHexField;
