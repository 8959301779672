import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { sortBy, take, toString } from "lodash";
import { Button, Image, Input, List, Segment } from "semantic-ui-react";
import FewResultsMessage from "./FewResultsMessage";
import "./localProductSearch.scss";
import ProductInfoItem from "../product/ProductInfoItem";
import { ProductGroupResourcesShape } from "../../../shared/shapes/productGroupResources.shape";
import { I18nShape } from "../../../shared/shapes/i18n.shape";

class LocalProductSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaders: {}
    };
  }

  handleFocus(e) {
    const { value } = e.target;
    e.target.value = "";
    e.target.value = value;
  }

  renderSearchListItems() {
    const { usedProductIds, searchTerm, products } = this.props;

    const searchTerms = searchTerm.toLowerCase().split(" ");

    const productsByMatch = products.map(product => {
      const productTerms = [
        toString(product.sku).toLowerCase(),
        toString(product.supplier).toLowerCase(),
        toString(product.name).toLowerCase(),
        toString(product.series).toLowerCase(),
        toString(product.description).toLowerCase()
      ];

      // The lower the score the better, but every term needs to be found, otherwise it is 0.
      // So sku matches are first, supplier second, and so on...
      let score = 1;

      searchTerms.forEach(term => {
        // Multiplication, so any term that is not found will make it 0.
        score *=
          productTerms.findIndex(
            productTerm => productTerm.indexOf(term) >= 0
          ) + 1;
      });
      return [score, product];
    });
    const matchedProducts = sortBy(
      productsByMatch.filter(([score]) => score > 0),
      ([score]) => score
    ).map(([, product]) => product);

    return matchedProducts.map(product => {
      const used = usedProductIds.indexOf(product.id) >= 0;

      const { loaders } = this.state;
      return (
        <List.Item key={product.id} data-model="product">
          <Image
            avatar
            src={product.thumb_url}
            style={{ height: "32px", width: "32px" }}
          />
          <List.Content>
            <List.Header>
              <ProductInfoItem product={product} renderSku />
            </List.Header>
            {(product.description || "").substring(0, 64)}
          </List.Content>
          <List.Content floated="right">
            {!used && (
              <Button
                id="product_price-add"
                onClick={this.addProduct(product)}
                icon="plus"
                basic
                loading={loaders[product.id]}
              />
            )}
            {used && <Button icon="check" positive disabled />}
          </List.Content>
        </List.Item>
      );
    });
  }

  renderProducts() {
    const { i18n } = this.props;
    const matchedProducts = this.renderSearchListItems();
    const amountProducts = matchedProducts.length;
    const items = take(matchedProducts, 30);

    const showAdditionalResultsHint =
      amountProducts > 8 && amountProducts >= items.length;

    return (
      <div
        data-component="localProductSearchList"
        className="margin top medium"
      >
        {showAdditionalResultsHint && (
          <Segment>
            <FormattedMessage
              id="product_group.menu.additionalResultsHint"
              values={{
                resultsShown: items.length,
                resultsTotal: amountProducts
              }}
            />
          </Segment>
        )}
        <List divided relaxed>
          {items}
        </List>
        <FewResultsMessage
          amount={amountProducts}
          i18n={i18n}
          handleClick={this.handleClickFewResultsMessage}
        />
      </div>
    );
  }

  handleClickFewResultsMessage = () => {
    const { onChangeTab } = this.props;
    onChangeTab("globalProductSearch");
  };

  addProduct = product => () => {
    const { resources, handleUpdate, productGroupId } = this.props;
    const resource = resources.pricesFactory(productGroupId);
    this.setState(({ loaders }) => ({
      loaders: { ...loaders, [product.id]: true }
    }));
    const values = { product_id: product.id, price: 0.0 };

    resource.save(values).then(() => {
      handleUpdate().then(() => {
        this.setState(({ loaders }) => ({
          loaders: { ...loaders, [product.id]: false }
        }));
      });
    });
  };

  updateSearchResults = (proxy, data) => {
    const { onChangeSearchTerm } = this.props;
    onChangeSearchTerm(data.value);
  };

  render() {
    const { i18n, searchTerm } = this.props;
    return (
      <Segment data-component="localProductSearch">
        <Input
          id="product_search"
          fluid
          value={searchTerm}
          onChange={this.updateSearchResults}
          icon="search"
          onFocus={this.handleFocus}
          autoFocus
          placeholder={i18n["product_price.search.placeholder"]}
        />

        {this.renderProducts()}
      </Segment>
    );
  }
}

LocalProductSearch.propTypes = {
  i18n: I18nShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  productGroupId: PropTypes.number,
  usedProductIds: PropTypes.array,
  searchTerm: PropTypes.string,
  onChangeSearchTerm: PropTypes.func,
  onChangeTab: PropTypes.func,
  handleUpdate: PropTypes.func,
  products: PropTypes.array
};

export default LocalProductSearch;
