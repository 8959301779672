import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Grid, Header, Card, Container, Button } from "semantic-ui-react";
import IsBetaPreview from "shared/components/elements/IsBetaPreview";
import ProjectCardItem from "./ProjectCardItem";
import { ProjectsResource } from "../../actions/projectActions";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";
import ProjectSetupDialog from "./projectWizard/ProjectSetupDialog";

const mapProjectStatus = account => {
  if (
    account &&
    account.account &&
    account.account.config &&
    account.account.config.projects &&
    account.account.config.projects.status
  ) {
    const statusMap = {};
    account.account.config.projects.status.forEach(item => {
      statusMap[item.id] = item.label;
    });
    return statusMap;
  }
  return {};
};

class Projects extends React.Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,
    pageContent: PropTypes.object.isRequired,
    // setFilter: PropTypes.func,
    resource: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { resource } = this.props;
    resource.fetchAll().catch(silentHandleApiRequestErrors);
  }

  renderChildren() {
    const { i18n, pageContent } = this.props;

    const children = get(pageContent, "projects", []).map(projectItem => {
      return (
        <ProjectCardItem
          slug={projectItem.slug}
          name={projectItem.name}
          image={projectItem.thumb_url}
          status={projectItem.status}
          statistics={projectItem.statistics}
          key={projectItem.id}
          i18n={i18n}
        />
      );
    });

    if (children.length > 0) {
      return children;
    }
    return (
      <Container>
        <FormattedMessage
          id="project.message.noResults"
          defaultMessage="project.message.noResults"
        />
      </Container>
    );
  }

  render() {
    const { i18n } = this.props;
    const pageName = [i18n["project.title.other"], i18n["meta.app.name"]].join(
      " - "
    );
    return (
      <div data-component="projects">
        <Helmet title={pageName} />
        <Grid
          stackable
          className="margin bottom spacious"
          verticalAlign="middle"
        >
          <Grid.Column width="12">
            <Header size="large">
              <FormattedMessage
                id="project.title.other"
                defaultMessage="project.title.other"
              />
            </Header>
          </Grid.Column>
          <Grid.Column width="4" textAlign="right">
            <IsBetaPreview>
              <ProjectSetupDialog
                button={
                  <Button primary>
                    <FormattedMessage
                      id="project.project_setup.title"
                      default="Projekt anlegen"
                    />
                  </Button>
                }
              />
            </IsBetaPreview>
          </Grid.Column>
        </Grid>
        <Card.Group itemsPerRow="2" stackable>
          {this.renderChildren()}
        </Card.Group>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const pageContent = { ...state.pageContent };
  if (pageContent.projects && pageContent.projects.length) {
    const statusMap = mapProjectStatus(state.account);
    pageContent.projects = pageContent.projects.map(item => {
      const temp = { ...item, status: statusMap[item.status] };
      return temp;
    });
  }
  return {
    i18n: state.i18n,
    // setFilter: () => {
    //   // @todo: dispatch redux action to set filter
    //   // dispatch({
    //   //     type: UPDATE_PROJECTS_FILTER,
    //   //     payload: data
    //   // });
    // },
    pageContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resource: new ProjectsResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
