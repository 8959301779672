import PropTypes from "prop-types";
import React from "react";
import { Dropdown, Icon, Input } from "semantic-ui-react";
import { Link } from "react-router";
import { connect } from "react-redux";
import "./accountSelector.scss";
import { delay, take } from "lodash";
import { FormattedMessage } from "react-intl";
import TokenResource from "shared/actions/tokenActions";
import ProfileResource from "../../actions/profileActions";

const MAX_RESULTS = 8;
const POLLING_INTERVAL = 20000;
class AccountSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      dropdownItems: []
    };
  }

  componentDidMount() {
    const { profilePolling } = this.props;
    this.filterDropdownItems("");
    if (profilePolling) {
      this.tick();
      this.interval = setInterval(this.tick, POLLING_INTERVAL);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { dropdownItems } = this.state;
    const { profile, children, i18n } = this.props;
    const accountCount = profile && profile.account_memberships.length;
    const searchHitCount = Math.min(dropdownItems.length, MAX_RESULTS);

    if (accountCount > 1) {
      const setFocus = () => {
        delay(() => {
          document.getElementById("account-search").focus();
        }, 50);
      };

      return (
        <Dropdown
          trigger={children}
          id="account-selector"
          item
          closeOnBlur={false}
          onOpen={setFocus}
        >
          <Dropdown.Menu>
            <Input
              id="account-search"
              placeholder={
                i18n["account.selector.search.placeholder"] || "Suche..."
              }
              onClick={this.handleSearchClick}
              onChange={this.handleSearchChange.bind(this)}
              autoFocus
              onKeyDown={this.handleKeyDown}
              autoComplete="off"
            />
            {take(dropdownItems, MAX_RESULTS)}
            <Dropdown.Item disabled>
              {dropdownItems.length === 0 && (
                <span className="empty-search-message">
                  <FormattedMessage
                    id="account.selector.search.empty"
                    defaultMessage="Keine Einträge gefunden"
                  />
                </span>
              )}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>
              <Icon name="info" />
              <FormattedMessage
                id="account.selector.search.resultCount"
                values={{
                  resultsShown: searchHitCount,
                  resultsTotal: accountCount
                }}
                defaultMessage="Es werden {resultsShown} von insgesamt {resultsTotal} Ergebnissen angezeigt."
              />
            </Dropdown.Header>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return <Dropdown.Item>{children}</Dropdown.Item>;
  }

  handleKeyDown = event => {
    // eslint-disable-next-line no-unused-expressions
    event.keyCode === 32 ? event.stopPropagation() : null;
    const { dropdownItems } = this.state;
    if (dropdownItems && dropdownItems[0] && event.key === "Enter") {
      const account_id = dropdownItems[0].key; // membershipId
      this.switchTo(account_id);
    }
  };

  handleSearchClick(e) {
    e.stopPropagation();
  }

  filterDropdownItems(searchTerm) {
    const { profile } = this.props;
    const searchWords = searchTerm.split(" ");

    const dropdownItems =
      profile &&
      profile.account_memberships &&
      profile.account_memberships
        .filter(membership => {
          const membershipName = membership.name.toLowerCase();
          return (
            searchWords.every(word =>
              membershipName.toLowerCase().includes(word.toLowerCase())
            ) || membership.id === +searchTerm
          );
        })
        .sort((prevMembership, nextMembership) =>
          prevMembership.name.localeCompare(nextMembership.name)
        )
        .map(membership => {
          const label =
            membership.id === profile.account.id ? (
              <b>{membership.name}</b>
            ) : (
              membership.name
            );

          return (
            <Dropdown.Item key={membership.id}>
              <Link
                to="/projects"
                onClick={() => this.switchTo(membership.id)}
                title={JSON.stringify(membership, null, 2)}
              >
                <Icon name="book" />
                &nbsp;{label}
              </Link>
            </Dropdown.Item>
          );
        });
    this.setState({ dropdownItems });
  }

  handleSearchChange(e, { value }) {
    const searchTerm = value;
    this.filterDropdownItems(searchTerm);
    this.setState({ searchTerm });
  }

  tick = () => {
    const { profile, tokenResource } = this.props;
    tokenResource.get().then(response => {
      const currentAccountId = response?.token?.account?.id;
      if (currentAccountId && currentAccountId !== profile.account.id) {
        window.location.href = window.location.origin;
      }
    });
  };

  switchTo = account_id => {
    const { profileResource } = this.props;
    profileResource.save({ account_id }).then(response => {
      if (response.status === 200) {
        window.location.href = "/projects";
      }
    });
  };
}

AccountSelector.propTypes = {
  profile: PropTypes.object,
  profileResource: PropTypes.object,
  tokenResource: PropTypes.instanceOf(TokenResource).isRequired,
  profilePolling: PropTypes.bool
};

AccountSelector.defaultProps = {
  profilePolling: false
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    profile: state.account
  };
};

const mapDispatchToProps = dispatch => ({
  profileResource: new ProfileResource(dispatch),
  tokenResource: new TokenResource(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelector);
