import { cloneDeep, get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import TradesDialog from "./TradesDialog";

class Trades extends React.Component {
  onDelete = trade => {
    const {
      actions: { account }
    } = this.props;

    const payload = {
      id: account.id,
      trades: JSON.stringify(this.withoutTrade(trade))
    };

    return account.save(payload).then(() => {
      return account.get(true);
    });
  };

  onSave = trade => {
    const {
      actions: { account }
    } = this.props;
    const payload = {
      id: account.id,
      trades: JSON.stringify(this.mergeTrades(trade))
    };

    return account.save(payload).then(() => {
      return account.get(true);
    });
  };

  withoutTrade(deletedTrade) {
    const { trades } = this.props;
    const nextTrades = cloneDeep(trades);
    const tradeIndex = nextTrades.findIndex(
      trade => deletedTrade.id === trade.id
    );

    if (tradeIndex !== -1) {
      nextTrades.splice(tradeIndex, 1);
    }

    return nextTrades;
  }

  mergeTrades(updatedTrade) {
    const { trades } = this.props;
    const nextTrades = cloneDeep(trades);
    const tradeIndex = nextTrades.findIndex(
      trade => updatedTrade.id === trade.id
    );

    if (tradeIndex !== -1) {
      nextTrades.splice(tradeIndex, 1, updatedTrade);
    } else {
      nextTrades.push(updatedTrade);
    }

    return nextTrades;
  }

  renderChildren() {
    const { i18n, trades } = this.props;

    return trades.map(trade => (
      <Table.Row key={trade.id} data-id={trade.id} data-model="trade">
        <Table.Cell data-attr="label">{trade.label}</Table.Cell>
        <Table.Cell data-attr="id">
          <i>{trade.id}</i>
        </Table.Cell>
        <Table.Cell data-attr="defaultMargin" textAlign="right">
          {trade.defaultMargin && (
            <FormattedNumber value={trade.defaultMargin} unit="percent" />
          )}
        </Table.Cell>
        <Table.Cell>
          <TradesDialog
            mode="EDIT"
            trade={trade}
            trades={trades}
            button={
              <span role="button">
                <Icon name="setting" color="grey" />
              </span>
            }
            i18n={i18n}
            onSave={this.onSave}
          />
        </Table.Cell>
        <Table.Cell>{this.renderDeleteButton(trade)}</Table.Cell>
      </Table.Row>
    ));
  }

  renderCreateButton() {
    const { i18n, trades } = this.props;

    const trade = {
      id: "",
      label: ""
    };
    return (
      <TradesDialog
        mode="CREATE"
        trade={trade}
        trades={trades}
        button={
          <span role="button">
            <Icon name="plus" color="grey" />
          </span>
        }
        i18n={i18n}
        onSave={this.onSave}
      />
    );
  }

  renderDeleteButton(trade) {
    const { i18n } = this.props;

    const button = (
      <span
        role="button"
        id="product_group-remove"
        data-tooltip={i18n["meta.actions.remove"]}
      >
        <Icon name="trash" color="grey" />
      </span>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: cb => {
          this.onDelete(trade);
          cb();
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="trades.actions.removeDialog.title"
        message="trades.actions.removeDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  render() {
    const { account } = this.props;

    return (
      <Segment.Group data-component="trades">
        <Segment attached="top">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="10">
              <Header as="h5">
                <FormattedMessage
                  id="trades.title.many"
                  default="trades.title.many"
                />
                : {account.name}
              </Header>
            </Grid.Column>
            <Grid.Column width="6" textAlign="right">
              {this.renderCreateButton()}
            </Grid.Column>
          </Grid>
        </Segment>
        <Table striped attached unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage
                  id="trades.attributes.label.label"
                  default="trades.attributes.label.label"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="trades.attributes.id.label"
                  default="trades.attributes.id.label"
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <FormattedMessage
                  id="trades.attributes.defaultMargin.short_label"
                  defaultMessage="Aufschlag (%)"
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderChildren()}</Table.Body>
        </Table>
      </Segment.Group>
    );
  }
}

Trades.propTypes = {
  actions: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  trades: PropTypes.array.isRequired
};

const mapStateToProps = (state, props) => {
  const account = get(state, "pageContent.account", {});
  const trades = JSON.parse(
    get(state, "pageContent.account.trades", "[]")
  ).sort((a, b) =>
    a.label.localeCompare(b.label, undefined, {
      numeric: true,
      sensitivity: "base"
    })
  );

  return {
    actions: props.actions,
    i18n: state.i18n,
    account,
    trades
  };
};

export default connect(mapStateToProps)(Trades);
