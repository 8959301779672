import Resource from "shared/actions/resourceActions";

export default class RoomBookCatalogResource extends Resource {
  constructor(dispatch, roomBookId) {
    super(
      dispatch,
      "project_catalog",
      `/api/v1/room_books/${roomBookId}/catalog`
    );
  }
}
