import { keyBy } from "lodash";

export default class ProductsRepository {
  constructor(products, productGroups, priceCatalogId) {
    this.productsById = keyBy(products, "id");
    this.productGroups = productGroups;
    this.productGroupsById = keyBy(productGroups, "id");
    this.priceCatalogId = priceCatalogId;
  }

  getProductGroups() {
    return this.productGroups;
  }

  getProduct(productId) {
    return this.productsById[productId];
  }

  getGroupProducts(productGroupId) {
    const group = this.productGroupsById[productGroupId];
    if (group) {
      return group.product_options.map(option => {
        const product = this.productsById[option.product_id];
        const price = option.product_prices.find(p => {
          return p.price_catalog_id === this.priceCatalogId;
        });

        return {
          deprecated: price.deprecated,
          ...product,
          group: {
            ...option,
            ...price,
            quantity_on_request: group.quantity_on_request,
            project_catalog_id: group.project_catalog_id
          }
        };
      });
    }
    return [];
  }
}
