/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";

// eslint-disable-next-line import/prefer-default-export
export class FlatFinderResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "flat_finder",
      `/api/v1/projects/${projectId}/flat_finders`
    );
  }
}
