import React, { useState } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { Form, Message } from "semantic-ui-react";
import PriceCatalogResource from "../../actions/priceCatalogActions";
import { getPriceCatalogs } from "../../../shared/selectors";
import { PriceCatalogShape } from "../../../shared/shapes";
import apiRequest from "../../../shared/network/apiRequest";

const ChangePricesAndCosts = ({ catalogs, submit }) => {
  const [catalogId, setCatalogId] = useState(null);
  const [factor, setFactor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const catalogOptions = catalogs.map(c => {
    return {
      key: `project-catalog-${c.id}`,
      text: `${c.name}`,
      value: c.id
    };
  });

  const handleSubmit = () => {
    setLoading(true);

    if (typeof factor === "string" && factor.indexOf("%") > 0) {
      setFactor(parseFloat(factor.slice(0, -1)) / 100 + 1);
    }

    submit(catalogId, factor)
      .then(response => {
        if (response.error) {
          setError(JSON.stringify(response.error));
          setSuccess(false);
          setLoading(false);
        } else {
          setSuccess(true);
          setError(false);
          setLoading(false);
        }
      })
      .catch(e => {
        setError(JSON.stringify(e));
        setSuccess(false);
        setLoading(false);
        // prevent silent catch
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  return (
    <div>
      <h2>Change Prices and Costs of a Price Catalog</h2>
      <ul>
        <li>please chose a project catalog to apply the updates to</li>
        <li>
          factor can be a float (like 2 would double the costs or 1.1 to add
          10%) or in percentage (like 100% to double or 10%){" "}
        </li>
      </ul>
      <Form
        loading={loading}
        success={success}
        error={!!error}
        onSubmit={handleSubmit}
      >
        <Message
          success
          header="Success!"
          content="Please check the results!"
        />
        <Message error header="Error" content={error} />
        <Form.Select
          name="catalogId"
          options={catalogOptions}
          onChange={(i, { value }) => setCatalogId(value)}
          label="Price Catalog"
          placeholder="Price Catalog"
          required
          width="4"
        />
        <Form.Input
          name="factor"
          onChange={(i, { value }) => setFactor(value)}
          label="Factor"
          placeholder="Factor"
          required
          width="4"
        />
        <Form.Button width="4">Submit</Form.Button>
      </Form>
    </div>
  );
};

const getSubmit = dispatch => (catalogId, factor) =>
  apiRequest.post("/api/v1/support_utils/change_prices_and_costs", dispatch, {
    catalog: {
      price_catalog_id: catalogId,
      factor
    }
  });

ChangePricesAndCosts.propTypes = {
  catalogs: PriceCatalogShape.isRequired,
  submit: func.isRequired
};

const mapStateToProps = state => {
  return {
    catalogs: getPriceCatalogs(state)
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submit: getSubmit(dispatch),
    priceCatalogResource: new PriceCatalogResource(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePricesAndCosts);
