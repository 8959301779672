import { createSelector } from "reselect";
import { get, keyBy } from "lodash";

import { getI18N, getPageContent } from "shared/selectors/base";

export const PRICE_STRATEGIES = ["flat", "area", "length", "pieces", "hour"];

export const getPriceStrategyOptions = createSelector([getI18N], i18n =>
  PRICE_STRATEGIES.map(key => ({
    key,
    value: key,
    text: i18n[`product_group.price_strategies.${key}.label`]
  }))
);

export const getProjects = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "projects"], [])
);

export const getUnits = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "units"], [])
);

export const getUnitsForProject = createSelector([getUnits], units => {
  return projectId => {
    return units.filter(unit => unit.project_id === projectId);
  };
});

export const getBuyers = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "buyers"], [])
);

export const getContacts = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "contacts"], [])
);

export const getUsers = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "users"], [])
);

export const getUserOptions = createSelector([getUsers], users =>
  users.map(user => ({
    key: user.id,
    value: user.id,
    text: user.label
  }))
);

export const getProjectOptions = createSelector([getProjects], projects =>
  projects.map(project => ({
    key: project.id,
    value: project.id,
    text: project.name
  }))
);

export const getContractors = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "contractors"], [])
);

export const getSections = createSelector([getPageContent], pageContent =>
  get(pageContent, ["master_data", "sections"], [])
);

export const getUnitBuyerNames = createSelector(
  [getUnits, getBuyers],
  (units, buyers) => {
    const buyersById = keyBy(buyers, "id");

    return units.reduce((accu, unit) => {
      accu[unit.id] = unit.buyer_ids
        .map(function(id) {
          return get(buyersById, [id, "label"], "?");
        })
        .join(", ");

      return accu;
    }, {});
  }
);

const getMasterData = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["master_data"], {});
});

export const getRoomTypes = createSelector([getMasterData], masterData => {
  return get(masterData, ["room_types"], []);
});

export const getRoomTypeOptions = createSelector([getRoomTypes], roomTypes => {
  return roomTypes.map(({ id, label }) => ({
    value: id,
    key: id,
    text: label
  }));
});
