import PropTypes from "prop-types";
import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import ProductImporter from "./ProductImporter";
import "./globalProductSearch.scss";

class GlobalProductSearch extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    resources: PropTypes.object,
    productGroupId: PropTypes.number,
    searchTerm: PropTypes.string,
    onChangeSearchTerm: PropTypes.func,
    handleUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      i18n,
      resources,
      searchTerm,
      onChangeSearchTerm,
      handleUpdate,
      productGroupId
    } = this.props;
    return (
      <Segment data-component="globalProductSearch">
        <ProductImporter
          i18n={i18n}
          resources={resources}
          searchTerm={searchTerm}
          onChangeSearchTerm={onChangeSearchTerm}
          handleUpdate={handleUpdate}
          productGroupId={productGroupId}
          setShowCloseIcon={() => {}}
        />
      </Segment>
    );
  }
}

export default GlobalProductSearch;
