import React from "react";
import { sortBy } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Header,
  Icon,
  Segment,
  List,
  Label,
  Progress
} from "semantic-ui-react";
import PropTypes from "prop-types";
import ProjectDeadlineDialog from "./ProjectDeadlineDialog";
import ProjectDeadlineProgressDialog from "./ProjectDeadlineProgressDialog";
import ProjectDeadlineLabel from "./ProjectDeadlineLabel";
import { formatDate } from "../../../shared/helpers/formatDate";

class ProjectDeadlinesSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  getTitle(item, itemType) {
    return itemType === "section"
      ? `${item.code} ${item.name}`
      : "Projektfristen";
  }

  getResource(item, itemType) {
    return this.props.resourceProvider(item, itemType);
  }

  handleUpdate = () => {
    const { onUpdate } = this.props;
    return onUpdate();
  };

  onCompletionFn(deadline) {
    return () => {
      const type = deadline.expirable_type.toLowerCase();
      const resource = this.getResource(type, deadline.expirable_id);

      this.setState({ isLoading: true });
      return resource
        .save({ ...deadline, completed: !deadline.completed })
        .then(() => {
          return this.handleUpdate().then(() => {
            this.setState({ isLoading: false });
          });
        });
    };
  }

  render() {
    const { item, itemType } = this.props;
    const id = item.id || itemType;
    const label = this.getTitle(item, itemType);

    return (
      <Segment
        key={id}
        data-id={id}
        data-model={itemType}
        data-component="deadlinesList"
        className="segmentPanel"
      >
        <div className="segmentPanelHeader">
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="6">
              <Header className="left floated element">
                <Icon color="grey" name="clock" /> {label}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="right" width="10">
              {this.renderCreateButton(item, itemType)}
            </Grid.Column>
          </Grid>
        </div>
        <div className="segmentPanelBody">
          <List divided verticalAlign="middle" data-component="unitList">
            {this.renderDeadlines(item, itemType)}
          </List>
        </div>
      </Segment>
    );
  }

  renderDeadlines(item, itemType) {
    const { i18n } = this.props;
    if (item.deadlines.length) {
      const sortedDeadlines = sortBy(item.deadlines, dl => {
        return dl.due_in;
      });
      return sortedDeadlines.map(deadline => {
        const showProgress = deadline.children.total > 0;
        const progress = showProgress
          ? Math.round(
              (deadline.children.completed * 100) / deadline.children.total
            )
          : 0;
        return (
          <List.Item
            data-model="projectDeadline"
            data-component="deadlineItem"
            key={`${deadline.id}::${deadline.parent_id}`}
          >
            <List.Content>
              <Grid stackable verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width="4">
                    <Header as="h5">
                      <Grid>
                        <Grid.Column
                          width={1}
                          data-attr="type"
                          data-value={deadline.type}
                        >
                          <Icon
                            color="grey"
                            name={
                              deadline.type === "actual"
                                ? "circle"
                                : "circle thin"
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={13}>
                          <span data-attr="title">{deadline.title}</span>{" "}
                          {this.renderEditButton(deadline)}
                        </Grid.Column>
                      </Grid>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width="2" textAlign="center">
                    <Label
                      basic
                      title={
                        i18n[
                          `projectDeadline.expiration_scopes.${deadline.expiration_scope}.tooltip`
                        ]
                      }
                      data-attr="expiration_scope"
                    >
                      {
                        i18n[
                          `projectDeadline.expiration_scopes.${deadline.expiration_scope}.label`
                        ]
                      }
                    </Label>
                  </Grid.Column>
                  <Grid.Column
                    width="2"
                    textAlign="center"
                    data-attr="expires_at"
                  >
                    {this.renderExpiresAt(deadline)}
                  </Grid.Column>
                  <Grid.Column
                    width="2"
                    textAlign="center"
                    data-attr="completed_at"
                  >
                    {this.renderCompletedAt(deadline)}
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    width="3"
                    verticalAlign="middle"
                    data-attr="progress"
                    data-value={progress}
                  >
                    {showProgress && (
                      <Progress
                        percent={progress}
                        progress
                        style={{ marginBottom: "0px" }}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    width="3"
                    data-attr="due_in"
                    data-value={deadline.due_in}
                  >
                    {this.renderDueLabel(item, itemType, deadline)}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Content>
          </List.Item>
        );
      });
    }
    return (
      <Header as="h5" textAlign="center" className="margin top bottom spacious">
        <FormattedMessage
          id="projectDeadline.title.missing"
          default="projectDeadline.title.missing"
        />
      </Header>
    );
  }

  renderExpiresAt(deadline) {
    return this.renderDate(deadline.expires_at, "clock");
  }

  renderCompletedAt(deadline) {
    if (deadline.completed_at) {
      return this.renderDate(deadline.completed_at, "checkmark");
    }
    return null;
  }

  renderDate(date, icon) {
    return (
      <div>
        <Icon name={icon} /> {formatDate(date)}
      </div>
    );
  }

  renderDueLabel(item, type, deadline) {
    const { project } = this.props;
    if (deadline.children.total > 0) {
      const button = (
        <ProjectDeadlineLabel
          deadline={deadline}
          loading={this.state.isLoading}
        />
      );
      return (
        <ProjectDeadlineProgressDialog
          item={item}
          project={project}
          deadline={deadline}
          type={type}
          i18n={this.props.i18n}
          button={button}
          onCompletionFn={this.onCompletionFn.bind(this)}
        />
      );
    }
    return (
      <ProjectDeadlineLabel
        deadline={deadline}
        onClick={this.onCompletionFn(deadline)}
        loading={this.state.isLoading}
      />
    );
  }

  renderCreateButton(item, type) {
    const { i18n } = this.props;
    const deadline = {
      title: "",
      expires_at: new Date().toISOString(),
      expiration_scope: type,
      scheduled_for: "operator"
    };

    const resource = this.getResource(type, item.id);

    const button = (
      <a
        id="project_deadline-add"
        role="button"
        data-tooltip={i18n["projectDeadline.actions.new"]}
        className="mobileBehaviour"
      >
        <Icon name="plus" color="grey" size="large" />
      </a>
    );

    return (
      <ProjectDeadlineDialog
        deadline={deadline}
        i18n={i18n}
        actions={{ deadlines: resource }}
        button={button}
        type={type}
        onUpdate={this.handleUpdate}
      />
    );
  }

  renderEditButton(deadline) {
    const { i18n } = this.props;
    const type = deadline.expirable_type.toLowerCase();
    const resource = this.getResource(type, deadline.expirable_id);

    const button = (
      <a
        id="project_deadline-edit"
        role="button"
        data-tooltip={i18n["projectDeadline.actions.edit"]}
        className="mobileBehaviour"
      >
        <Icon name="setting" color="grey" />
      </a>
    );

    return (
      <ProjectDeadlineDialog
        deadline={deadline}
        i18n={i18n}
        actions={{ deadlines: resource }}
        button={button}
        type={type}
        onUpdate={this.handleUpdate}
      />
    );
  }
}

ProjectDeadlinesSegment.propTypes = {
  i18n: PropTypes.object,
  project: PropTypes.object,
  item: PropTypes.object,
  itemType: PropTypes.string,
  resourceProvider: PropTypes.func,
  onUpdate: PropTypes.func
};

export default ProjectDeadlinesSegment;
