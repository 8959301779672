import CreateActivityAction from "builder_portal/components/activity/actions/CreateActivityAction";
import OpenActivityAction from "builder_portal/components/activity/actions/OpenActivityAction";
import AssignLineItemsAction from "builder_portal/components/activity/actions/AssignLineItemsAction";
import CreateExportAction from "builder_portal/components/activity/actions/CreateExportAction";

// I did put BatchAction in the same module as actionFactory to circumvent circular dependencies.
export class BatchAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
    // function declarations are hoisted so this works anyway
    // eslint-disable-next-line no-use-before-define
    this.getAction = actionFactory(dispatch);
  }

  execute(params) {
    const { actionIds } = params;

    return actionIds.reduce(
      (promiseChain, actionId) =>
        promiseChain.then(currentParams =>
          this.getAction(actionId).execute(currentParams)
        ),
      Promise.resolve(params)
    );
  }
}

// please have a look at process definitions property: actions.action
const ACTIONS = {
  createActivity: CreateActivityAction,
  openActivity: OpenActivityAction,
  batch: BatchAction,
  assignLineItems: AssignLineItemsAction,
  createExport: CreateExportAction
};

export function actionFactory(dispatch) {
  return actionId => {
    if (!Object.prototype.hasOwnProperty.call(ACTIONS, actionId)) {
      throw new Error(`Unknown action ${actionId}`);
    }
    return new ACTIONS[actionId](dispatch);
  };
}
