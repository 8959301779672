import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, Tab } from "semantic-ui-react";
import { customPropTypes } from "semantic-ui-react/dist/es/lib";
import * as SUI from "semantic-ui-react/dist/es/lib/SUI";

import "./tabDialog.scss";

class TabDialog extends React.Component {
  static propTypes = {
    triggerButton: customPropTypes.itemShorthand,
    paneWidth: PropTypes.oneOf(SUI.WIDTHS),
    tabWidth: PropTypes.oneOf(SUI.WIDTHS),
    defaultActiveIndex: Tab.propTypes.defaultActiveIndex,
    panes: Tab.propTypes.panes,
    menu: Tab.propTypes.menu
  };

  static defaultProps = {
    paneWidth: 11,
    tabWidth: 5,
    menu: {
      vertical: true,
      pointing: true,
      secondary: true,
      attached: true,
      tabular: true
    }
  };

  static defaultTriggerOptions = {
    defaultProps: {
      compact: true,
      type: "button",
      icon: "plus",
      labelPosition: "left"
    }
  };

  render() {
    const {
      triggerButton,
      paneWidth,
      tabWidth,
      defaultActiveIndex,
      panes,
      menu,
      ...passThroughProps
    } = this.props;

    const tabProps = {
      grid: { paneWidth, tabWidth },
      defaultActiveIndex,
      panes,
      menu,
      renderActiveOnly: false
    };

    return (
      <Modal
        data-component="tabDialog"
        className="tabDialog"
        trigger={Button.create(triggerButton, TabDialog.defaultTriggerOptions)}
        closeIcon
        content={<Tab {...tabProps} />}
        {...passThroughProps}
      />
    );
  }
}

export default TabDialog;
