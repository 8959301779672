import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { browserHistory } from "shared/routes/browserHistory";
import CartAssignmentDialog from "builder_portal/components/cart/CartAssignmentDialog";
import CartDialog from "builder_portal/components/cart/CartDialog";
import ActivityModel from "builder_portal/containers/activity/activityModel";
import ActivityController from "builder_portal/containers/activity/activityController";

class SuccessorActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      successors: []
    };
  }

  componentDidMount() {
    const { ctxCtrl, processDefinition } = this.props;

    if (processDefinition) {
      ctxCtrl
        .fetchSuccessors({
          process_type: processDefinition.id,
          status: processDefinition.getProcessStartStates()[0]?.id
        })
        .then(({ data: { successors } }) => {
          if (!this.unmounted) {
            this.setState({ successors });
          }
        });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const { ctxModel, item, processDefinition } = this.props;

    if (processDefinition) {
      const cart = {
        process_type: processDefinition.id,
        status: processDefinition.getProcessStartStates()[0]?.id,
        name: "",
        line_item_status: item.action.line_item_status
      };

      const button = (
        <Button
          content={
            <div className="leftAlignedWithIcon">{item.action.label}</div>
          }
          fluid
          icon="play"
          labelPosition="left"
        />
      );

      const { successors } = this.state;

      if (successors.length) {
        return (
          <CartAssignmentDialog
            i18n={ctxModel.i18n}
            cart={cart}
            processDefinitions={[processDefinition]}
            button={button}
            successors={successors}
            handleSave={this.onCreateCartFn(item).bind(this)}
          />
        );
      }
      return (
        <CartDialog
          i18n={ctxModel.i18n}
          cart={cart}
          processDefinitions={[processDefinition]}
          button={button}
          handleSave={this.onCreateCartFn(item).bind(this)}
        />
      );
    }
    return null;
  }

  onCreateCartFn(item) {
    return values => {
      const { ctxCtrl, ctxModel, toggleLoading } = this.props;
      toggleLoading(false);
      return ctxCtrl
        .createSuccessor(values)
        .then(response => {
          const activityId = response.data.activity.id;
          browserHistory.push(
            `/projects/${
              ctxModel.getProject().slug
            }/activities/${activityId}/timeline`
          );
          return false;
        })
        .catch(() => {
          ctxCtrl
            .getGrowl()
            .error(item.action.label, "activity.message.successors.forbidden");
          return false;
        });
    };
  }
}

const mapStateToProps = (state, props) => {
  const { ctxModel, item } = props;

  const processType = item.action.process_type;
  const pd = ctxModel.account.getProcessDefinition(processType);

  return {
    processDefinition: pd && pd.isAvailable(ctxModel.getProjectId()) ? pd : null
  };
};

SuccessorActivity.propTypes = {
  ctxModel: PropTypes.instanceOf(ActivityModel).isRequired,
  ctxCtrl: PropTypes.instanceOf(ActivityController).isRequired,
  item: PropTypes.object.isRequired,
  toggleLoading: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(SuccessorActivity);
