import { connect } from "react-redux";
import { getAccount, getLineItemLifeCycle } from "shared/selectors";

const mapStateToProps = state => ({
  i18n: state.i18n,
  account: getAccount(state),
  processDefinition: getLineItemLifeCycle(state)
});

function connectStatistic(component) {
  return connect(mapStateToProps)(component);
}

export default connectStatistic;
