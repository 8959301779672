import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { formatPattern } from "react-router";
import { withRouter } from "../../../shared/helpers/withRouter";

const DEFAULT_KEYWORD = "Tutorials";
const KEYWORDS_FOR_ROUTE = {
  "/users/login": null,
  "/users/password": null,

  "/projects": null,
  "/projects/projectId/product_groups/id": "Ausstattungskatalog",
  "/projects/projectId/overview": null,
  "/projects/projectId/activity": "Vorgänge",
  "/projects/projectId/deadlines": "Projektfristen",
  "/projects/projectId/sectionAndUnit": "Raumbücher Wohneinheiten",
  "/projects/projectId/room_books": "Raumbuchvorlagen",
  "/projects/projectId/statistics": null,
  "/projects/projectId/units/id/room-book": "Raumbücher",
  "/projects/projectId/units/id/activities": "Vorgänge",
  "/projects/projectId/units/id/deadlines": "Projektfristen",
  "/projects/projectId/units/id/documents": null,
  "/projects/projectId/units/id/statistics": null,

  "/activities/id": "Vorgänge",
  "/activities/id/timeline": "Vorgänge",
  "/activities/id/cart": "Vorgänge Raumbücher",
  "/activities/id/documents": "Vorgänge",

  "/products/": "Ausstattungskatalog",
  "/products/project_id/product_groups/id": "Ausstattungskatalog",
  "/products/supplier_id": "Ausstattungskatalog",
  "/products/supplier_id/product_id": "Ausstattungskatalog",

  "/buyers": null,
  "/contractors": null,
  "/notifications": null,

  "/room_books/id": "Raumbuchvorlagen",

  "/account": null,
  "/account/general": null,
  "/account/processes": null,
  "/account/users": null,
  "/account/documents": null,
  "/account/trades": null,
  "/account/macros": "E-Mails",
  "/account/email-outbound": "E-Mail Versand",
  "/account/email-inbound": "E-Mail Empfang",
  "/account/buyer_portal": null,

  "/accounts/id": null,
  "/macros/id": null,
  "/profile": null,
  "/guide": null
};

class ZendeskHelpWidget extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    account: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = { token: null };
    this.keywords = DEFAULT_KEYWORD;
    this.widgetReady = false;
  }

  componentDidMount() {
    const { actions, router } = this.props;

    const onRouterUpdate = () => {
      const routePath = formatPattern(
        joinRoutes(router.routes),
        convertParams(router.params)
      );
      this.keywords = KEYWORDS_FOR_ROUTE[routePath];
      this.updateSuggestions();
    };
    this.unsubscribeRouterListener = router.listen(onRouterUpdate);

    actions.zendesk.getWidgetToken().then(response => {
      const { token } = response.data.zendesk;
      this.setState({ token });
      if (token) this.updateUserDetails(300);
    });
  }

  componentWillUnmount() {
    this.unsubscribeRouterListener?.();
  }

  updateSuggestions() {
    if (this.widgetReady)
      zE.setHelpCenterSuggestions({ search: this.keywords || DEFAULT_KEYWORD });
  }

  render() {
    const { token } = this.state;
    if (token) {
      window.zESettings = {
        authenticate: { jwt: token },
        webWidget: {
          zIndex: 999
        }
      };
      return (
        <Helmet>
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=c33563d3-19e6-40ba-afac-9b9b4400044f"
          />
        </Helmet>
      );
    }
    return <span />;
  }

  updateUserDetails(timeout) {
    setTimeout(() => {
      const { account } = this.props;
      if (window.zE) {
        zE(
          function() {
            zE.identify({
              name: account.user.name,
              email: account.user.email
            });
            this.widgetReady = true;
            this.updateSuggestions();
          }.bind(this)
        );
      } else {
        this.updateUserDetails(800);
      }
    }, timeout);
  }
}

const convertParams = params => {
  return Object.keys(params).reduce((hash, key) => {
    hash[key] = key === "tab" ? params[key] : key;
    return hash;
  }, {});
};

const joinRoutes = routes => {
  return routes
    .slice(1)
    .map(r => {
      return r.path;
    })
    .join("/");
};

export default withRouter(ZendeskHelpWidget);
