import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import { cloneDeep, compact } from "lodash";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { getSalutations, getTitles } from "../../../shared/selectors";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "first_name",
      label: "buyer.attributes.firstName.label",
      rule: "isRequired",
      autoComplete: "off",
      autoFocus: true
    },
    {
      id: "last_name",
      label: "buyer.attributes.lastName.label",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "display_name",
      label: "buyer.attributes.displayName.label",
      autoComplete: "off"
    },
    {
      id: "salutation",
      label: "meta.form_of_address.salutation.label",
      autoComplete: "off"
    },
    {
      id: "title",
      label: "meta.form_of_address.title.label",
      autoComplete: "off"
    },
    {
      id: "company",
      label: "buyer.attributes.company.label",
      autoComplete: "off"
    },
    {
      id: "email",
      label: "buyer.attributes.email.label",
      message: "buyer.attributes.email.error",
      rule: "isEmailOrEmpty",
      autoComplete: "off"
    },
    {
      id: "phone",
      label: "buyer.attributes.phone.label",
      autoComplete: "off"
    },
    {
      id: "mobile",
      label: "buyer.attributes.mobile.label",
      message: "buyer.attributes.mobile.error",
      rule: "isPhoneOrEmpty",
      autoComplete: "off"
    },
    {
      id: "address_street",
      label: "contractor.attributes.address_street.label",
      autoComplete: "off"
    },
    {
      id: "address_number",
      label: "contractor.attributes.address_number.label",
      autoComplete: "off"
    },
    {
      id: "address_postcode",
      label: "contractor.attributes.address_postcode.label",
      autoComplete: "off"
    },
    {
      id: "address_town",
      label: "contractor.attributes.address_town.label",
      autoComplete: "off"
    },
    {
      id: "external_id",
      label: "contractor.attributes.external_id.label",
      placeholder: `contractor.attributes.external_id.placeholder`,
      autoComplete: "off"
    },
    {
      id: "notes",
      label: "contractor.attributes.notes.label"
    }
  ]
});

class BuyerDialog extends PureComponent {
  constructor(props) {
    super(props);

    // @todo: use redux state not component state
    this.state = { open: false, buyer: cloneDeep(this.props.buyer) };
  }

  onSave = values => {
    if (!this.state.isLoading) {
      const { buyersResource } = this.props.actions;
      const { handleBuyerCreate } = this.props;
      this.setState({ isLoading: true });
      return buyersResource
        .save({
          ...values,
          project_id: this.props.projectId
        })
        .then(res => {
          const newBuyer = res.data?.buyer;
          if (handleBuyerCreate && newBuyer) handleBuyerCreate(newBuyer);
          buyersResource.fetchAll().then(() => {
            this.setState({ open: false, isLoading: false });
          });
        });
    }
  };

  onRemove = () => {
    if (!this.state.isLoading) {
      const { buyersResource } = this.props.actions;

      this.setState({ isLoading: true });
      return buyersResource.remove(this.state.buyer.id).then(() => {
        this.setState({ open: false, isLoading: false });
        return buyersResource.fetchAll();
      });
    }
  };

  handleOpen = () => {
    this.setState({ open: true, buyer: cloneDeep(this.props.buyer) });
  };

  handleClose = () => {
    this.setState({ open: false, buyer: cloneDeep(this.props.buyer) });
  };

  render() {
    const { buyer } = this.state;
    const message = buyer.id ? "buyer.actions.edit" : "buyer.actions.new";

    const form = FormFactory.create(buyer, this.props.i18n, {
      onChange: b => {
        this.setState({ buyer: b });
      }
    });

    form.fields.display_name.props = {
      ...form.fields.display_name.props,
      placeholder: compact([
        buyer.title
          ? this.props.i18n[`meta.form_of_address.title.${buyer.title}`]
          : null,
        buyer.first_name,
        buyer.last_name
      ]).join(" ")
    };

    return (
      <Modal
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage id={message} defaultMessage={message} />
        </Modal.Header>
        <Modal.Content scrolling size="large">
          <Form id="buyer" data-component="buyerForm">
            <Form.Field>
              <Field component="Input" {...form.fields.first_name} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.last_name} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.display_name} />
            </Form.Field>

            <Form.Field>
              <Field
                component="Select"
                {...form.fields.salutation}
                props={{
                  ...form.fields.salutation.props,
                  clearable: true,
                  placeholder: this.props.i18n[
                    "meta.form_of_address.salutation.nil"
                  ],
                  options: getSalutations.map(key => {
                    return {
                      key,
                      value: key,
                      text: this.props.i18n[
                        `meta.form_of_address.salutation.${key || "nil"}`
                      ]
                    };
                  })
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field
                component="Select"
                {...form.fields.title}
                props={{
                  ...form.fields.title.props,
                  clearable: true,
                  placeholder: this.props.i18n[
                    "meta.form_of_address.title.nil"
                  ],
                  options: getTitles.map(key => {
                    return {
                      key,
                      value: key,
                      text: this.props.i18n[
                        `meta.form_of_address.title.${key || "nil"}`
                      ]
                    };
                  })
                }}
              />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.company} />
            </Form.Field>

            <Form.Field>
              <Field component="Input" {...form.fields.email} />
            </Form.Field>

            <Form.Group widths="2">
              <Form.Field width={8}>
                <Field component="Input" {...form.fields.phone} />
              </Form.Field>

              <Form.Field width={8}>
                <Field component="Input" {...form.fields.mobile} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="2">
              <Form.Field width="14">
                <Field component="Input" {...form.fields.address_street} />
              </Form.Field>

              <Form.Field width="4">
                <Field component="Input" {...form.fields.address_number} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="2">
              <Form.Field width="4">
                <Field component="Input" {...form.fields.address_postcode} />
              </Form.Field>

              <Form.Field width="12">
                <Field component="Input" {...form.fields.address_town} />
              </Form.Field>
            </Form.Group>
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Field width="16">
                <Field component="Input" {...form.fields.external_id} />
              </Form.Field>
            </FeatureToggleActive>
            <Form.Field width="16">
              <Field component="TextArea" {...form.fields.notes} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8} verticalAlign="middle">
              {this.state.buyer.id && this.renderDeleteButton(this.state.buyer)}
            </Grid.Column>
            <Grid.Column textAlign="right" width={8}>
              <Button
                type="submit"
                name="commit"
                color="green"
                data-form="buyer"
                onClick={form.handleSubmit(this.onSave)}
                loading={this.state.isLoading}
                id="buyer-save"
              >
                <Icon name="save" />
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDeleteButton(buyer) {
    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isLoading}
        className="left floated element"
      />
    );

    if (buyer.deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemove
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="buyer.actions.removeDialog.title"
          message="buyer.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: "meta.actions.accept",
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title="buyer.actions.undeletableDialog.title"
        message="buyer.actions.undeletableDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }
}

BuyerDialog.propTypes = {
  buyer: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  button: PropTypes.node.isRequired,
  actions: PropTypes.object.isRequired,
  handleBuyerCreate: PropTypes.func
};

BuyerDialog.defaultProps = {
  buyer: {},
  handleBuyerCreate: null
};

export default BuyerDialog;
