import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";
import { orderBy } from "lodash";

// eslint-disable-next-line import/prefer-default-export
export const getMembershipReplacements = createSelector(
  [getPageContent],
  pageContent =>
    orderBy(pageContent?.project_membership_replacements || [], "id", "desc")
);
