import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export class UnitProspectListResources extends Resources {
  constructor(dispatch, unitId) {
    super(dispatch, "unit_prospect", `/api/v1/units/${unitId}/unit_prospects`);
  }

  accept(id, data) {
    return apiRequest
      .patch(`${this.url}/${id}/accept`, this.dispatch, data)
      .then(this.propagateUpdate.bind(this));
  }

  reject(id, data) {
    return apiRequest
      .patch(`${this.url}/${id}/reject`, this.dispatch, data)
      .then(this.propagateUpdate.bind(this));
  }
}

export default UnitProspectListResources;
