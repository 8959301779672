import { get } from "lodash";
import { number, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Label } from "semantic-ui-react";

function mapStateToProps(state, props) {
  // TODO i18n and unify Unknown Contractor
  let foundContractor = props.contractor || {
    id: 0,
    name: "Unbekanntes Nachunternehmen"
  };
  if (props.contractorId) {
    foundContractor = get(state, "pageContent.contractors", []).find(
      c => c.id === props.contractorId
    ) || {
      id: 0,
      name: "Unbekanntes Nachunternehmen"
    };
  }

  return {
    contractorId: foundContractor.id,
    contractor: foundContractor
  };
}

function ContractorLabel({ contractorId, contractor: { name } }) {
  return <Label key={contractorId} basic icon="shipping" content={name} />;
}

ContractorLabel.defaultProps = {
  contractorId: undefined,
  contractor: undefined
};
ContractorLabel.propTypes = {
  contractorId: number,
  contractor: shape({ id: number, name: string })
};

export default connect(mapStateToProps)(ContractorLabel);
