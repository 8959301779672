import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, locationShape } from "react-router";
import {
  arrayOf,
  instanceOf,
  number,
  oneOfType,
  string,
  shape
} from "prop-types";
import { Helmet } from "react-helmet";
import { browserHistory } from "shared/routes/browserHistory";
import { AccountUsersResource } from "builder_portal/actions/accountActions";

import {
  Button,
  Grid,
  Icon,
  Menu,
  Header,
  Segment,
  Dropdown
} from "semantic-ui-react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  I18nShape,
  ProjectCatalogShape,
  ProjectShape,
  TradeLabelShape
} from "shared/shapes";
import { ProductGroupResourcesShape } from "shared/shapes/productGroupResources.shape";
import { DEPRECATED_PageContentShape } from "shared/shapes/deprecated_pageContent.shape";
import { Account } from "shared/models/account";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import Growl from "builder_portal/actions/growlActions";
import { useLocalStorage } from "beautiful-react-hooks";
import { withRouter } from "../../../shared/helpers/withRouter";
import EditAllPrices from "./prouctGroupPriceEdit/EditAllPrices";
import ProductGroupList from "./ProductGroupList";
import ProductGroupItem from "./ProductGroupItem";
import DesignLines from "./DesignLines";
import ProductCatalogDialog from "../productCatalog/ProductCatalogDialog";
import { PriceCatalogDropdown, PriceCatalogDialog } from "../priceCatalog";
import ProductCatalogUploadModal from "./ProductCatalogUploadModal";
import {
  getI18N,
  makeGetCatalogById,
  getAccount,
  getProjects
} from "../../../shared/selectors";
import { ProjectsResource } from "../../actions/projectActions";
import { ProductResource } from "../../actions/productActions";
import ProductGroupResource, {
  ProductPriceResource
} from "../../actions/productGroupActions";
import { DesignLineGroupsResource } from "../../actions/designLinesActions";
import getTradeOptions from "../../selectors/trades";
import HasEditRightsForProjectCatalog from "../../../shared/components/authorization/HasEditRightsForProjectCatalog";
import IsBetaPreview from "../../../shared/components/elements/IsBetaPreview";
import ProductTypeResource from "../../actions/productTypeActions";
import BulkPriceUpdate from "./BulkPriceUpdate";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import ProductFilterTagsDialog from "../productFilterTags/ProductFilterTagsDialog";

const GLENCOE_URL =
  "https://glencoe.sonderwunsch-manager.de/data/v1/categories";

const ProductGroup = props => {
  const {
    resources,
    i18n,
    pageContent,
    account,
    trades,
    catalogId,
    catalog,
    productGroupId,
    trade,
    projects,
    designLineList,
    location,
    actions
  } = props;

  const dispatch = useDispatch();
  const [selectedPriceCatalogId, setSelectedPriceCatalogId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value3DDownload, setValue3DDownload] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [lastPriceCatalog, setLastPriceCatalog] = useLocalStorage(
    "lastPriceCatalogs",
    {}
  );

  const setPriceCatalogId = id => {
    const accountId = account.data?.account?.slug;
    const accountStorageData = lastPriceCatalog[accountId] || {};
    setLastPriceCatalog({
      ...lastPriceCatalog,
      [accountId]: {
        ...accountStorageData,
        [catalogId]: id
      }
    });
    setSelectedPriceCatalogId(id);
  };

  useEffect(() => {
    if (location?.query?.price_catalog_id) {
      setPriceCatalogId(location?.query?.price_catalog_id);
    }
  }, [location?.query]);

  useEffect(() => {
    if (catalogId !== "all") {
      setLoading(true);
      Promise.all([
        resources.groups.fetchAll(),
        resources.designLineGroups.fetchAll(),
        resources.productTypes.fetchAll()
      ]).then(() => {
        setValue3DDownload("");
        setLoading(false);
      });
    }
  }, [catalogId]);

  useEffect(() => {
    resources.products.fetchAll();
    actions.users.fetchAll().catch(() => {
      new Growl(dispatch).error(
        "product_group.warnings.productCategories.title",
        "product_group.warnings.productCategories.body"
      );
    });
  }, []);

  useEffect(() => {
    resources.projects.fetchAll();
    fetch(GLENCOE_URL)
      .then(res => res.json())
      .then(data => setProductCategories(data))
      .catch(() => {
        new Growl(dispatch).error(
          "product_group.warnings.productCategories.title",
          "product_group.warnings.productCategories.body"
        );
      });
  }, []);

  const priceCatalogId = useMemo(() => {
    const accountId = account.data?.account?.slug;
    const accountStorageData = lastPriceCatalog[accountId] || {};
    const lastStoredPriceCatalog = accountStorageData[catalogId];

    const catalogIds = get(catalog, "price_catalogs", []).map(c => c.id);
    const lastIdFromList = catalogIds[catalogIds.length - 1];

    const resolvedId =
      catalogIds.find(c => c === selectedPriceCatalogId) ||
      lastStoredPriceCatalog ||
      lastIdFromList;
    setLastPriceCatalog({
      ...lastPriceCatalog,
      [accountId]: { ...accountStorageData, [catalogId]: resolvedId }
    });

    return parseInt(resolvedId, 10);
  }, [catalog, selectedPriceCatalogId]);

  const selectedPriceCatalog = useMemo(() => {
    let pcat = get(catalog, "price_catalogs", []).find(
      pc => pc.id === priceCatalogId
    );
    if (!pcat) {
      const { pathname } = props.location;
      // if incorect price_catalog_id is provided, select the first catalog and clean up the url
      // eslint-disable-next-line prefer-destructuring
      pcat = get(catalog, "price_catalogs", [])[0];
      if (pcat) {
        setPriceCatalogId(pcat.id);
        browserHistory.push(pathname);
      }
    }
    return pcat;
  }, [catalog, priceCatalogId]);

  const options3Download = [
    {
      key: "null"
    },
    {
      key: "xls",
      text: "XLS",
      value: "xlsx",
      icon: "file excel outline"
    },
    {
      key: "json",
      text: "JSON",
      value: "json",
      icon: "js"
    },
    {
      key: "yaml",
      text: "YAML",
      value: "yaml",
      icon: "yahoo"
    }
  ];

  function pageTitle() {
    const productGroup = get(pageContent, "product_group");
    if (productGroupId && productGroup) {
      return `${productGroup.name} - ${i18n["meta.app.name"]}`;
    }
    return `${i18n["product_group.title.other"]} - ${i18n["meta.app.name"]}`;
  }

  const showEditAllPrices =
    account.isSystemAdmin() || account.isEnabled("bulk_price_editing_20");

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function handle3DDownload(value, project_catalog, bearer) {
    if (value) {
      setValue3DDownload(value);
      openInNewTab(
        `/api/v1/project_catalogs/${project_catalog.id}/three_d_report.${value}?_bearer=${bearer}`
      );
    }
  }

  function renderSidebar() {
    return (
      <>
        <ProductGroupList
          key={catalogId}
          resources={resources}
          catalogId={catalogId}
          productGroupId={productGroupId}
          trade={trade}
          account={account}
          i18n={i18n}
          loading={loading}
          pageContent={pageContent}
          trades={trades}
          productCategories={productCategories}
        />
        <HasEditRightsForProjectCatalog catalogId={catalogId}>
          <FeatureToggleActive featureToggleName="design_lines_admin">
            <Header as="h5" attached="top">
              <Link to={`/products/${catalogId}/design_lines/show`}>
                <FormattedMessage
                  id="design_lines.title"
                  defaultMessage="Design lines"
                />
              </Link>
            </Header>
          </FeatureToggleActive>
        </HasEditRightsForProjectCatalog>
        <Header as="h5" attached="top">
          Preislisten
          <HasEditRightsForProjectCatalog catalogId={catalogId}>
            <PriceCatalogDialog
              projectCatalog={catalog}
              projects={projects}
              trigger={
                <div
                  id="add-price-catalog"
                  data-tooltip={i18n["meta.actions.add"]}
                  className="right floated element"
                >
                  <Icon name="plus" size="large" color="grey" />
                </div>
              }
              onCreated={setSelectedPriceCatalogId}
            />
          </HasEditRightsForProjectCatalog>
        </Header>
        <Segment attached data-component="price_catalogs">
          <Grid>
            <Grid.Column width={13}>
              <PriceCatalogDropdown
                catalog={catalog}
                value={priceCatalogId}
                onChange={setSelectedPriceCatalogId}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <HasEditRightsForProjectCatalog catalogId={catalogId}>
                <PriceCatalogDialog
                  key={selectedPriceCatalog.id}
                  priceCatalog={selectedPriceCatalog}
                  projectCatalog={catalog}
                  projects={projects}
                  trigger={<Button id="edit-price-catalog" basic icon="edit" />}
                  onDeleted={() => setSelectedPriceCatalogId(null)}
                />
              </HasEditRightsForProjectCatalog>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment data-component="product_group_exports">
          <Menu vertical fluid>
            <Menu.Item
              link
              href={`/api/v1/price_catalogs/${priceCatalogId}/report.xlsx?_bearer=${get(
                account,
                ["data", "auth_token"]
              )}`}
            >
              <Icon name="file excel outline" />
              <FormattedMessage
                id="product_group.actions.download_price_catalog"
                defaultMessage="Download Preiskatalog"
              />
            </Menu.Item>
            <HasEditRightsForProjectCatalog catalogId={catalogId}>
              {showEditAllPrices && (
                <EditAllPrices trades={trades}>
                  <Menu.Item link>
                    <Icon name="edit" />
                    <FormattedMessage
                      id="edit_product_prices.action.text"
                      defaultMessage="Produktpreise bearbeiten"
                    />
                  </Menu.Item>
                </EditAllPrices>
              )}
            </HasEditRightsForProjectCatalog>
            <FeatureToggleActive featureToggleName="show_bulk_price_update">
              <BulkPriceUpdate
                priceCatalogId={priceCatalogId}
                groupsResource={resources.groups}
                productGroupId={props.params?.productGroupId}
                trigger={
                  <Menu.Item link>
                    <Icon name="chart line" />
                    <FormattedMessage
                      id="product_group.actions.bulks_price_change"
                      defaultMessage="Kosten und Preisen aktualisieren"
                    />
                  </Menu.Item>
                }
              />
            </FeatureToggleActive>
          </Menu>
        </Segment>
        <HasEditRightsForProjectCatalog catalogId={catalogId}>
          <Segment data-component="project_catalog_editing">
            <Menu vertical fluid>
              <ProductCatalogDialog
                key={catalog.id}
                catalog={catalog}
                trigger={
                  <Menu.Item link>
                    <Icon name="edit" />
                    <FormattedMessage
                      id="project_catalog.actions.edit.label"
                      defaultMessage="Ausstattungskatalog bearbeiten"
                    />
                  </Menu.Item>
                }
              />
              <IsSystemAdmin>
                <ProductFilterTagsDialog
                  catalog={catalog}
                  trigger={
                    <Menu.Item link>
                      <Icon name="edit" />
                      Produktfilter Tags bearbeiten
                    </Menu.Item>
                  }
                />
              </IsSystemAdmin>
            </Menu>
          </Segment>
        </HasEditRightsForProjectCatalog>
        {account.isEnabled("buyer_portal") && account.isSystemAdmin() && (
          <Segment>
            <Header as="h5">
              <FormattedMessage
                id="product_group.actions.download_3d_products"
                defaultMessage="Download 3D-Produkte"
              />
            </Header>
            <Dropdown
              placeholder="Format"
              fluid
              selection
              onClick={() => setValue3DDownload("")}
              value={value3DDownload}
              options={options3Download}
              onChange={(event, data) => {
                handle3DDownload(
                  data.value,
                  catalog,
                  get(account, ["data", "auth_token"])
                );
              }}
            />
          </Segment>
        )}
        <IsBetaPreview>
          <Segment data-component="project_catalog_upload">
            <Menu vertical fluid>
              <ProductCatalogUploadModal
                project_catalog_id={catalog.id}
                trigger={
                  <Menu.Item link>
                    <Icon name="cloud upload" />
                    <FormattedMessage
                      id="project_catalog.actions.upload.label"
                      defaultMessage="Ausstattungskatalog hochladen"
                    />
                  </Menu.Item>
                }
              />
            </Menu>
          </Segment>
        </IsBetaPreview>
      </>
    );
  }

  function renderMainContent() {
    if (designLineList) {
      return <DesignLines catalogId={catalogId} />;
    }
    if (productGroupId && resources && pageContent) {
      return (
        <ProductGroupItem
          resources={resources}
          account={account}
          loading={loading}
          i18n={i18n}
          pageContent={pageContent}
          trades={trades}
          productGroupId={productGroupId}
          catalogId={catalogId}
          priceCatalogId={priceCatalogId}
          trade={trade}
          productCategories={productCategories}
        />
      );
    }
    return (
      <ProductGroupList
        key={catalogId}
        loading={loading}
        resources={resources}
        account={account}
        i18n={i18n}
        pageContent={pageContent}
        trades={trades}
        as="Cards"
        productGroupId={productGroupId}
        catalogId={catalogId}
        catalog={catalog}
        trade={trade}
        productCategories={productCategories}
      />
    );
  }

  return (
    <div>
      <Helmet title={pageTitle()} />
      <Grid>
        {catalog && <Grid.Column width={5}>{renderSidebar()}</Grid.Column>}
        <Grid.Column width={11}>{renderMainContent()}</Grid.Column>
      </Grid>
    </div>
  );
};

ProductGroup.defaultProps = {
  pageContent: undefined,
  productGroupId: undefined,
  trade: undefined,
  catalog: undefined,
  projects: [],
  designLineList: undefined
};

ProductGroup.propTypes = {
  i18n: I18nShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  productGroupId: oneOfType([string, number]),
  catalogId: oneOfType([string, number]).isRequired,
  catalog: ProjectCatalogShape,
  projects: arrayOf(ProjectShape),
  trade: oneOfType([string, number]),
  trades: arrayOf(TradeLabelShape).isRequired,
  pageContent: DEPRECATED_PageContentShape,
  account: instanceOf(Account).isRequired,
  designLineList: oneOfType([string, number]),
  location: locationShape.isRequired,
  actions: shape({
    users: instanceOf(AccountUsersResource).isRequired
  }).isRequired,
  params: shape({
    productGroupId: string
  }).isRequired
};

const mapStateToProps = (state, props) => {
  const { catalogId, productGroupId, trade, designLineList } = props.params;
  const getCatalogById = makeGetCatalogById();

  return {
    catalogId,
    productGroupId,
    trade,
    designLineList,
    i18n: getI18N(state),
    trades: getTradeOptions(state),
    account: getAccount(state),
    pageContent: state.pageContent,
    catalog: getCatalogById(state, props.params),
    projects: getProjects(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { catalogId, productGroupId } = props.params;
  return {
    actions: {
      users: new AccountUsersResource(dispatch)
    },
    resources: {
      projects: new ProjectsResource(dispatch),
      products: new ProductResource(dispatch),
      productTypes: new ProductTypeResource(dispatch),
      prices: new ProductPriceResource(productGroupId, dispatch),
      pricesFactory: currentProductGroupId => {
        return new ProductPriceResource(currentProductGroupId, dispatch);
      },
      groups: new ProductGroupResource(catalogId, dispatch),
      designLineGroups: new DesignLineGroupsResource(dispatch, catalogId)
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductGroup)
);
