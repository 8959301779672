import React from "react";
import { Grid, Button } from "semantic-ui-react";
import { shape, string, number, func, object, arrayOf } from "prop-types";
import SignatureElementShape from "shared/shapes/signatureElement.shape";
import { FormattedMessage } from "react-intl";
import {
  heading1Element,
  heading2Element,
  textElement,
  signatureElement
} from "./constants";

const AddElements = ({ onAddElement }) => {
  return (
    <>
      <Grid.Column width={4}>
        <Button
          onClick={event => {
            event.preventDefault();
            onAddElement(heading1Element);
          }}
          icon="plus"
          content={
            <FormattedMessage
              id="documentsExport.dialogs.editSignature.addElements.heading1"
              defaultMessage="Heading 1"
            />
          }
          data-tooltip="Add heading1"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          onClick={event => {
            event.preventDefault();
            onAddElement(heading2Element);
          }}
          icon="plus"
          content={
            <FormattedMessage
              id="documentsExport.dialogs.editSignature.addElements.heading2"
              defaultMessage="Heading 2"
            />
          }
          data-tooltip="Add heading2"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          onClick={event => {
            event.preventDefault();
            onAddElement(textElement);
          }}
          icon="plus"
          content={
            <FormattedMessage
              id="documentsExport.dialogs.editSignature.addElements.text"
              defaultMessage="Text"
            />
          }
          data-tooltip="Add text"
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Button
          onClick={event => {
            event.preventDefault();
            onAddElement(signatureElement);
          }}
          icon="plus"
          content={
            <FormattedMessage
              id="documentsExport.dialogs.editSignature.addElements.signature"
              defaultMessage="Signature"
            />
          }
          data-tooltip="Add signatur"
        />
      </Grid.Column>
    </>
  );
};

AddElements.propTypes = {
  jsonConfig: arrayOf(SignatureElementShape),
  updateJson: func.isRequired,
  originalSignature: shape({
    id: number,
    name: string,
    // eslint-disable-next-line react/forbid-prop-types
    config_content: object
  }).isRequired
};

AddElements.defaultProps = {
  jsonConfig: []
};

export default AddElements;
