import PropTypes from "prop-types";
import React from "react";
import { Segment } from "semantic-ui-react";
import StickyMenu from "shared/components/menus/StickyMenu";
import { keyBy } from "lodash";
import { ProjectShape } from "shared/shapes";

import "./projectSettings.scss";
import { connect } from "react-redux";
import ProjectConfigSelection from "./ProjectConfigSelection";

class ProjectSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: props.projects[0]
    };
  }

  onSelectedItemChange = item => {
    this.setState(prev => ({ ...prev, project: item }));
  };

  render() {
    const { projects, accountId } = this.props;
    const { project } = this.state;
    return (
      <>
        <StickyMenu
          stickyItem={null}
          allItems={keyBy(projects, "id")}
          accountId={accountId}
          onSelectedItemChange={this.onSelectedItemChange}
          storeKey="recentProjectsSettings"
        />
        <Segment data-component="project-settings">
          {/* there is no state in ProjectConfigSelection so we need to force rerender on projects loaded */}
          <ProjectConfigSelection
            project={projects?.find(p => p.id === project.id)}
          />
        </Segment>
      </>
    );
  }
}

ProjectSettingsPage.propTypes = {
  projects: PropTypes.arrayOf(ProjectShape),
  accountId: PropTypes.number.isRequired
};

ProjectSettingsPage.defaultProps = {
  projects: []
};

const mapStateToProps = state => ({
  projects: state.pageContent.projects,
  accountId: state.account?.account?.id
});

export default connect(mapStateToProps)(ProjectSettingsPage);
