/* eslint-disable max-classes-per-file */
import Resources from "../../shared/actions/resourcesActions";

export class FeatureFlagsAccountResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "feature_flags", "/api/v1/account/feature_flags");
  }
}

export class FeatureFlagsProjectsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "feature_flags",
      `/api/v1/projects/${projectId}/feature_flags`
    );
  }
}
