import { browserHistory } from "shared/routes/browserHistory";
import { UPDATE_ACCOUNT, UPDATE_MESSAGE } from "shared/constants/appConstants";
import apiRequest from "shared/network/apiRequest";
import localStorage from "shared/helpers/localStorage";

export default dispatch => reset => {
  apiRequest
    .delete("/api/v1/tokens", dispatch)
    .then(function() {
      if (reset) {
        localStorage.clear();
      } else {
        // clear local storage (except filter)
        localStorage.remove("token");
        localStorage.remove("landingPage");
        localStorage.remove("filter");
      }

      // redirect to login
      browserHistory.push("/users/login");

      // update account
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: {}
      });
      // show message
      dispatch({
        type: UPDATE_MESSAGE,
        payload: {
          title: "message.logout.title",
          body: "message.logout.body"
        }
      });
    })
    .catch(function(error) {
      console.error("Error logout", error);
      dispatch({
        type: UPDATE_MESSAGE,
        payload: {
          title: "message.errorLogout.title",
          body: "message.errorLogout.body"
        }
      });
    });
};
