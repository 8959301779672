/* eslint-disable react/destructuring-assignment */
import { arrayOf, bool, instanceOf, string, shape } from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";
import { get, countBy, keyBy, take } from "lodash";
import {
  Header,
  Icon,
  Segment,
  Image,
  List,
  Card,
  Message,
  Input
} from "semantic-ui-react";
import es6Promise from "es6-promise";
import { If } from "shared/components/elements/Conditions";
import { browserHistory } from "shared/routes/browserHistory";
import { TradeLabelShape } from "shared/shapes/tradeLabel.shape";
import { I18nShape } from "shared/shapes/i18n.shape";
import { ProductGroupResourcesShape } from "shared/shapes/productGroupResources.shape";
import { Account } from "shared/models/account";
import { ProjectCatalogShape } from "shared/shapes/projectCatalog.shape";
import HasEditRightsForProjectCatalog from "shared/components/authorization/HasEditRightsForProjectCatalog";
import ProductGroupDialog from "./ProductGroupDialog";
import ProductGroupCard from "./ProductGroupCard";

import "./productGroupList.scss";

// es lint:
/* global Promise */

es6Promise.polyfill();

class ProductGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterGroup: ""
    };
  }

  onUpdate() {
    const { resources, productGroupId } = this.props;
    return resources.groups.get(productGroupId, true).then(() => {
      return resources.groups.fetchAll();
    });
  }

  onGroupCreated(values) {
    const { catalogId, resources } = this.props;

    browserHistory.push(
      `/products/${catalogId}/product_groups/${values.trades[0]}/${values.id}`
    );
    resources.groups.fetchAll();
  }

  onProductGroupSelected(id) {
    return () => {
      return this.props.resources.groups.get(id, true);
    };
  }

  onChangeFn(entity, field) {
    return value => {
      const data = { id: entity.id, [field]: value };

      return this.props.resources.groups.save(data);
    };
  }

  onPriceChangeFn(entity, field) {
    const { resources } = this.props;
    const resource = resources.pricesFactory(entity.product_group_id);

    return value => {
      const data = { id: entity.id, [field]: value };

      return resource.save(data);
    };
  }

  render() {
    const { trade, as: asProp } = this.props;
    const product_groups = get(
      this.props,
      "pageContent.product_groups",
      []
    ).filter(pg => {
      return !trade || pg.trades.indexOf(trade.toUpperCase()) >= 0;
    });

    if (asProp === "Cards") {
      return this.renderAsCards(product_groups);
    }
    return this.renderAsMenu(product_groups);
  }

  renderAsMenu(product_groups) {
    const { trade, trades } = this.props;
    const tradesById = keyBy(trades, "key");

    if (trade) {
      return this.renderProductGroupsMenu(product_groups, tradesById);
    }
    return this.renderTradesMenu(product_groups, tradesById);
  }

  renderProductGroupsMenu(product_groups, tradesById) {
    const { catalogId, trade } = this.props;
    return (
      <div data-component="productGroupList">
        <Header as="h5" attached="top">
          <Link to={`/products/${catalogId}/product_groups`}>
            <Icon name="arrow left" />
            {get(tradesById, [trade, "text"], trade)}
          </Link>
          <HasEditRightsForProjectCatalog catalogId={catalogId}>
            {this.renderCreateProductGroupButton()}
          </HasEditRightsForProjectCatalog>
        </Header>
        <Segment attached data-component="product_groups">
          {this.renderProductGroupList(product_groups)}
        </Segment>
      </div>
    );
  }

  renderTradesMenu(product_groups, tradesById) {
    const { catalogId, loading } = this.props;
    const relevantTrades = countBy(
      product_groups.reduce((accu, pg) => {
        return accu.concat(pg.trades);
      }, [])
    );

    const sortedTrades = Object.keys(relevantTrades)
      .map(tradeId => {
        return {
          id: tradeId,
          name: get(tradesById, [tradeId, "text"], tradeId)
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    const items = sortedTrades.map(trade => {
      return (
        <List.Item key={trade.id} data-model="trade">
          <List.Content>
            <List.Header>
              <Link to={`/products/${catalogId}/product_groups/${trade.id}`}>
                <Icon name="truck" /> {trade.name} ({relevantTrades[trade.id]})
              </Link>
            </List.Header>
          </List.Content>
        </List.Item>
      );
    });

    return (
      <div data-component="productGroupList">
        <Header as="h5" attached="top">
          Gewerke
          <HasEditRightsForProjectCatalog catalogId={this.props.catalogId}>
            {this.renderCreateProductGroupButton()}
          </HasEditRightsForProjectCatalog>
        </Header>
        <Segment attached style={{ minHeight: "150px" }} loading={loading}>
          <List divided relaxed>
            {items}
            {!items.length && !loading && (
              <FormattedMessage
                id="product_group.missing.short"
                defaultMessage="product_group.missing.short"
              />
            )}
          </List>
        </Segment>
      </div>
    );
  }

  handleFilterChange = event => {
    const { value } = event.target;
    this.setState({ filterGroup: value });
  };

  renderProductGroupList(product_groups) {
    const { i18n, trade, catalogId } = this.props;
    const { filterGroup } = this.state;
    const groups = trade ? product_groups : take(product_groups, 20);

    if (groups.length) {
      const items = groups
        .filter(
          g => g.name.toLowerCase().indexOf(filterGroup.toLowerCase()) > -1
        )
        .map(group => {
          return (
            <List.Item key={group.id} data-model="product_group">
              <Image avatar src={group.thumb_url} />
              <List.Content>
                <List.Header>
                  <Link
                    to={`/products/${catalogId}/product_groups/${trade}/${group.id}`}
                  >
                    <span data-attr="name">{group.name}</span>
                  </Link>
                </List.Header>
                {group.desciption}
              </List.Content>
            </List.Item>
          );
        });

      return (
        <>
          <If condition={groups.length > 15}>
            <Input
              value={filterGroup}
              onChange={this.handleFilterChange}
              name="filterGroup"
              placeholder={i18n["activity.timeline.filter.label"]}
              icon={
                <Icon
                  name="delete"
                  link
                  color="grey"
                  onClick={() => this.setState({ filterGroup: "" })}
                />
              }
              className="fluid"
            />
          </If>
          <List
            divided
            relaxed
            style={{ maxHeight: "40vh", overflowY: "auto" }}
          >
            {items}
          </List>
        </>
      );
    }
    return (
      <FormattedMessage
        id="product_group.missing.short"
        defaultMessage="product_group.missing.short"
      />
    );
  }

  renderAsCards(product_groups) {
    const { loading } = this.props;
    return (
      <Segment.Group data-component="productGroupList">
        <Header as="h5" attached="top">
          <FormattedMessage
            id="product_group.title.other"
            defaultMessage="product_group.title.other"
          />
          <HasEditRightsForProjectCatalog catalogId={this.props.catalogId}>
            {this.renderCreateProductGroupButton()}
          </HasEditRightsForProjectCatalog>
        </Header>
        <Segment attached data-component="product_groups" loading={loading}>
          {this.renderProductGroupCards(product_groups)}
        </Segment>
        {this.renderImportMessage()}
      </Segment.Group>
    );
  }

  renderImportMessage() {
    const { catalog } = this.props;

    if (catalog?.import_status === "failed") {
      return (
        <Message error attached="bottom">
          <Icon name="remove circle" />
          Der Ausstattungskatalog konnte nicht importiert werden. Bitte wenden
          Sie sich an den Support!
        </Message>
      );
    }
    if (catalog?.import_status === "pending") {
      return (
        <Message info attached="bottom">
          <Icon name="info" />
          Bitte haben Sie einen Moment geduld, der Ausstattungskatalog wird
          importiert.
        </Message>
      );
    }
    return null;
  }

  renderProductGroupCards(product_groups) {
    if (product_groups.length) {
      const { catalogId, trade, account } = this.props;
      const items = product_groups.map(group => (
        <ProductGroupCard
          key={group.id}
          data-component="productGroupCard"
          group={group}
          catalogId={catalogId}
          trade={trade}
          account={account}
        />
      ));

      return (
        <Card.Group itemsPerRow="3" stackable>
          {items}
        </Card.Group>
      );
    }
    return (
      <FormattedMessage
        id="product_group.missing.long"
        defaultMessage="product_group.missing.long"
      />
    );
  }

  renderCreateProductGroupButton() {
    const { i18n, resources, account, trades, productCategories } = this.props;

    const button = (
      <a
        role="button"
        id="product_group-new"
        data-tooltip={i18n["meta.actions.add"]}
        className="right floated element"
      >
        <Icon name="plus" size="large" color="grey" />
      </a>
    );

    const group = {
      name: "",
      price_strategy: "flat",
      desciption: "",
      portal_offer: true,
      marketing_portal_offer: true
    };

    return (
      <ProductGroupDialog
        button={button}
        resources={resources}
        account={account}
        model={group}
        i18n={i18n}
        handleUpdate={this.onGroupCreated.bind(this)}
        trades={trades}
        productCategories={productCategories}
      />
    );
  }
}

ProductGroupList.propTypes = {
  i18n: I18nShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  account: instanceOf(Account).isRequired,
  as: string,
  catalogId: string.isRequired,
  catalog: ProjectCatalogShape,
  trade: string,
  productGroupId: string,
  trades: arrayOf(TradeLabelShape).isRequired,
  loading: bool,
  productCategories: arrayOf(shape({ id: string, name: string })).isRequired
};

ProductGroupList.defaultProps = {
  as: undefined,
  trade: undefined,
  productGroupId: null,
  loading: false,
  catalog: undefined
};

export default ProductGroupList;
