import { createSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import React from "react";
import { getPageContent } from "./base";

const getProjectRoles = createSelector([getPageContent], pageContent => {
  return pageContent?.project_roles || [];
});

export const getProjectRolesOptions = createSelector(
  [getProjectRoles],
  projectRoles => {
    return projectRoles
      .filter(projectRole => {
        return projectRole?.system_reference_role !== "contractor";
      })
      .map(projectRole => {
        return {
          key: projectRole.id,
          value: projectRole.id,
          text: (
            // eslint-disable-next-line react/jsx-filename-extension
            <FormattedMessage
              id={`user.roles.${projectRole.name}.label`}
              defaultMessage={projectRole.name}
            />
          )
        };
      });
  }
);

export default getProjectRoles;
