import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Radio, Popup } from "semantic-ui-react";

const BatchOperationSelectionDialog = props => {
  const {
    i18n,
    handleOperationSelectionFn,
    operation,
    selectedProductGroupId,
    selectedItems
  } = props;

  const disableEdit =
    selectedItems.some(item => !item.bulk_updateable) ||
    !selectedProductGroupId;
  const disableDelete = selectedItems.some(item => !item.bulk_deletable);

  return (
    <Modal.Content>
      <Form className="margin left spacious">
        <Form.Field>{i18n["batchEdit.messages.select_operation"]}</Form.Field>
        {!disableDelete && (
          <Form.Field>
            <Radio
              label={i18n["batchEdit.radio.delete"]}
              name="radioGroup"
              value="delete"
              data-action="delete"
              checked={operation === "delete"}
              onChange={(event, data) => handleOperationSelectionFn(data)}
            />
          </Form.Field>
        )}
        {disableDelete && (
          <Form.Field>
            <Popup
              basic
              header={i18n["batchEdit.messages.disabledDelete.header"]}
              content={i18n["batchEdit.messages.disabledDelete.content"]}
              trigger={
                <Radio
                  disabled
                  label={i18n["batchEdit.radio.delete"]}
                  name="radioGroup"
                />
              }
            />
          </Form.Field>
        )}
        {!disableEdit && (
          <Form.Field>
            <Radio
              label={i18n["batchEdit.radio.edit"]}
              name="radioGroup"
              value="edit"
              data-action="edit"
              checked={operation === "edit"}
              onChange={(event, data) => handleOperationSelectionFn(data)}
            />
          </Form.Field>
        )}
        {disableEdit && (
          <Form.Field>
            <Popup
              basic
              flowing
              header={i18n["batchEdit.messages.disabledEdit.header"]}
              content={i18n["batchEdit.messages.disabledEdit.content"]}
              trigger={
                <Radio
                  disabled
                  label={i18n["batchEdit.radio.edit"]}
                  name="radioGroup"
                />
              }
            />
          </Form.Field>
        )}
      </Form>
    </Modal.Content>
  );
};

BatchOperationSelectionDialog.propTypes = {
  i18n: PropTypes.object.isRequired,
  handleOperationSelectionFn: PropTypes.func,
  operation: PropTypes.string,
  selectedItems: PropTypes.array,
  selectedProductGroupId: PropTypes.number
};

export default BatchOperationSelectionDialog;
