import React from "react";
import PropTypes from "prop-types";

class MasterSlaveList extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    options: PropTypes.array,
    selectedOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    options: [],
    disabled: false
  };

  render() {
    const { render: View, options, disabled } = this.props;

    const mappedOptions = options.map(
      ({ id, disabled: optionDisabled, ...rest }) => ({
        ...rest,
        id,
        checked: this.isSelected(id),
        onChange: this.handleChildChange,
        disabled: disabled || optionDisabled
      })
    );

    return (
      <View masterProps={this.getMasterProps()} childrenProps={mappedOptions} />
    );
  }

  isSelected = id => {
    const { selectedOptions } = this.props;
    return selectedOptions.includes(id);
  };

  getMasterProps = () => {
    const { options, disabled } = this.props;
    let allChecked = true;
    let allUnChecked = true;
    let allDisabled = true;
    options.forEach(option => {
      if (this.isSelected(option.id)) allUnChecked = false;
      else allChecked = false;
      if (!option.disabled) allDisabled = false;
    });
    return {
      checked: allChecked,
      indeterminate: !allChecked && !allUnChecked,
      onChange: this.handleMasterChange,
      disabled: disabled || allDisabled
    };
  };

  handleMasterChange = (_event, { checked }) => {
    const {
      options,
      onChange,
      selectedOptions: oldSelectedOptions
    } = this.props;
    // separate all selections that this list is not responsible for
    const unrelatedSelections = oldSelectedOptions.filter(id =>
      options.every(option => option.id !== id)
    );

    const selectedOptions =
      checked &&
      !options.every(option => option.disabled || this.isSelected(option.id))
        ? [
            ...options
              .filter(option => !option.disabled)
              .map(option => option.id),
            ...unrelatedSelections
          ]
        : unrelatedSelections;

    onChange({ selectedOptions });
  };

  handleChildChange = (_event, { id, checked }) => {
    const { selectedOptions, onChange } = this.props;
    if (checked) {
      if (!selectedOptions.includes(id)) {
        onChange({ selectedOptions: [...selectedOptions, id] });
      }
    } else {
      const index = selectedOptions.indexOf(id);
      if (index >= 0) {
        onChange({
          selectedOptions: [
            ...selectedOptions.slice(0, index),
            ...selectedOptions.slice(index + 1)
          ]
        });
      }
    }
  };
}

export default MasterSlaveList;
