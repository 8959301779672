import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getUnitVariableTypes = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "unit_variable_types", []), ["name"]);
  }
);

export const getUnitVariableTypesOptions = createSelector(
  [getPageContent],
  pageContent => {
    const unitVariableTypes = sortBy(
      get(pageContent, "unit_variable_types", []),
      ["name"]
    );
    return unitVariableTypes.map((t, i) => ({
      key: `${t.id}${i}`,
      value: t.id,
      text: t.name
    }));
  }
);

export const getUnitVariables = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "unit_variables", []), "id");
  }
);

export const getTotalPages = createSelector([getPageContent], pageContent => {
  return get(pageContent, "total_unit_variable_pages", null);
});
