import React, { forwardRef } from "react";
import { FormField } from "semantic-ui-react";
import { func, bool, string, number } from "prop-types";
import { Field } from "formik-semantic-ui-react";
import FloatInput from "./FloatInput";
import { parseNumber } from "../../helpers/formatCurrency";

const SemanticFormikFloatInput = forwardRef(
  (
    {
      id,
      name,
      label,
      inputLabel,
      inputLabelPosition,
      validate,
      fast,
      nullable,
      placeholder,
      width
    },
    ref
  ) => {
    return (
      <Field name={name} validate={validate} fast={fast}>
        {({ field: { value, onChange, onBlur }, meta }) => {
          const nValue = parseNumber(value);
          return (
            <FormField error={meta.touched && !!meta.error} width={width}>
              <label id={`${id}-label`} htmlFor={label}>
                {label}
              </label>
              <FloatInput
                id={id}
                ref={ref}
                onChangeFormik={onChange}
                onBlurFormik={onBlur}
                name={name}
                nullable={nullable}
                label={inputLabel}
                labelPosition={inputLabelPosition}
                placeholder={placeholder}
                value={nValue}
              />
            </FormField>
          );
        }}
      </Field>
    );
  }
);

SemanticFormikFloatInput.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  placeholder: string,
  inputLabel: string,
  inputLabelPosition: string,
  fast: bool,
  nullable: bool,
  validate: func,
  width: number
};

SemanticFormikFloatInput.defaultProps = {
  inputLabel: undefined,
  inputLabelPosition: undefined,
  placeholder: undefined,
  fast: true,
  nullable: false,
  validate: () => {},
  width: 16
};

SemanticFormikFloatInput.displayName = "SemanticFormikFloatInput";

export default SemanticFormikFloatInput;
