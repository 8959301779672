import { get, sortBy, keyBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";

export const getUnits = createSelector([getPageContent], pageContent =>
  sortBy(get(pageContent, "units", []), "id")
);

export const getSections = createSelector([getPageContent], pageContent =>
  sortBy(get(pageContent, "sections", []), "id")
);

export const getUnitOptions = createSelector([getUnits], units =>
  units.map(unit => ({
    key: unit.id,
    value: unit.id,
    text: unit.display_name
  }))
);

export const getSectionOptions = createSelector([getSections], sections =>
  sections.map(section => ({
    key: section.id,
    value: section.id,
    text: section.name
  }))
);

export const getUnitsById = createSelector([getUnits], units => {
  return keyBy(units, "id");
});

export const getUnitRoomBookInfos = createSelector([getUnits], units =>
  units.reduce((akku, { display_name, room_book_id }) => {
    if (room_book_id) akku.push({ name: display_name, id: room_book_id });
    return akku;
  }, [])
);

export const getTemplates = createSelector([getPageContent], pageContent =>
  get(pageContent, "room_books", [])
);

export const getTemplateRoomBookInfos = createSelector(
  [getTemplates],
  templates => templates.map(({ id, name }) => ({ id, name }))
);

export const getUnitOrUnits = createSelector([getPageContent], pageContent => {
  const units = get(pageContent, "units", null);
  if (!units) {
    const unit = get(pageContent, "unit", null);
    if (unit) {
      return [{ id: unit.id, slug: unit.slug }];
    }
    return [];
  }
  return units;
});
