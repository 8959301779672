import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Input, Form, TextArea } from "formik-semantic-ui-react";
import { Formik } from "formik";
import { Button, Modal } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import ProjectEmailsResource from "../../../actions/projectEmailsActions";
import Growl from "../../../actions/growlActions";
import { ProjectEmailShape } from "../../../../shared/shapes/projectEmail.shape";

const EmailEditDialog = ({ model, trigger }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = useCallback(
    values => {
      const resource = new ProjectEmailsResource(dispatch);
      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  return (
    <Formik initialValues={model} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit }) => (
        <Modal
          closeIcon
          trigger={trigger}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          size="small"
          closeOnDimmerClick={false}
          closeOnEscape={false}
        >
          <Modal.Header>
            <FormattedMessage id="projectEmail.edit.title" />
          </Modal.Header>
          <Modal.Content>
            <Form id="configuratorBackendForm">
              <Input
                id="email"
                name="email"
                readOnly
                label={intl.formatMessage({
                  id: "projectEmail.edit.fields.email.label"
                })}
              />
              <Input
                id="reply_to_email"
                name="reply_to_email"
                readOnly
                label={intl.formatMessage({
                  id: "projectEmail.edit.fields.reply_to_email.label"
                })}
              />
              <Input
                id="mailjet_account"
                name="mailjet_account"
                readOnly
                label={intl.formatMessage({
                  id: "projectEmail.edit.fields.mailjet_account.label"
                })}
              />
              <Input
                id="bcc_emails"
                name="bcc_emails"
                label={intl.formatMessage({
                  id: "projectEmail.edit.fields.bcc_emails.label"
                })}
              />
              <TextArea
                id="notes"
                name="notes"
                label={intl.formatMessage({
                  id: "projectEmail.edit.fields.notes.label"
                })}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button basic disabled={isSubmitting} onClick={handleClose}>
              <FormattedMessage id="meta.actions.cancel" />
            </Button>
            <Button
              primary
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              <FormattedMessage id="meta.actions.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

EmailEditDialog.propTypes = {
  trigger: PropTypes.node,
  model: ProjectEmailShape
};

EmailEditDialog.defaultProps = {
  trigger: undefined,
  model: undefined
};

export default EmailEditDialog;
