import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Button } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { cloneDeep } from "lodash";

class AttachmentDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    attachmentType: PropTypes.object,
    attachmentTypes: PropTypes.array,
    button: PropTypes.object,
    onSave: PropTypes.func,
    mode: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      attachmentType: cloneDeep(props.attachmentType)
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      attachmentType: cloneDeep(this.props.attachmentType)
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  onSave(activityItem) {
    this.setState({ ...this.state, loading: true });
    this.props.onSave(activityItem).then(() => {
      this.setState({ ...this.state, open: false, loading: false });
    });
  }

  render() {
    const { i18n, mode, attachmentType, attachmentTypes } = this.props;

    const attachmentIds = attachmentTypes.map(t => {
      return t.id;
    });
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "id",
          label: "attachment_type.attributes.id.label",
          rule: value => {
            if (!value) {
              return true;
            }

            if (
              attachmentType.id !== value &&
              attachmentIds.indexOf(value) >= 0
            ) {
              return true;
            }

            return false;
          },
          message: "attachment_type.attributes.id.error"
        },
        {
          id: "label",
          label: "attachment_type.attributes.label.label",
          rule: "isRequired"
        }
      ]
    });

    const form = FormFactory.create(this.state.attachmentType, i18n, {
      onChange: attachmentType => {
        this.setState({ ...this.state, attachmentType });
      }
    });

    form.fields.id.props.readOnly = mode === "EDIT";

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id="attachment_type.title.one"
            default="attachment_type.title.one"
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="attachment_type" data-component="attachment_type">
            <Form.Field>
              <Field component="Input" {...form.fields.id} />
            </Form.Field>
            <Form.Field>
              <Field component="Input" {...form.fields.label} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="submit"
            positive
            loading={this.state.loading}
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.onSave.bind(this))}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AttachmentDialog;
