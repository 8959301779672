import Resources from "shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

class ProjectEmailsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "project_email", `/api/v1/project_emails`);
    this.refreshPageContentAfterCommit = true;
  }

  outboundStatusCheck(id) {
    return apiRequest.post(
      `/api/v1/project_emails/${id}/outbound_status_check`,
      this.dispatch
    );
  }

  inboundStatusCheck(id) {
    return apiRequest.post(
      `/api/v1/project_emails/${id}/inbound_status_check`,
      this.dispatch
    );
  }

  aliasStatusCheck(id) {
    return apiRequest.post(
      `/api/v1/project_emails/${id}/alias_status_check`,
      this.dispatch
    );
  }

  activate(id) {
    return apiRequest.post(
      `/api/v1/project_emails/${id}/activations`,
      this.dispatch
    );
  }

  instructions(id, payload) {
    return apiRequest.post(
      `/api/v1/project_emails/${id}/instructions`,
      this.dispatch,
      { [this.namespace]: payload }
    );
  }

  migration() {
    return apiRequest.post(`/api/v1/project_emails/migration`, this.dispatch);
  }
}

export default ProjectEmailsResource;
