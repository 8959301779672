/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";

export class MessageDraftsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "message_draft", `/api/v1/message_drafts`);
    this.refreshPageContentAfterCommit = true;
  }
}

export class MessageDraftsForMessagesResource extends Resources {
  constructor(dispatch, id) {
    super(dispatch, "message_draft", `/api/v1/messages/${id}/message_drafts`);
    this.refreshPageContentAfterCommit = true;
  }
}

export class MessageDraftsForActivitiesResource extends Resources {
  constructor(dispatch, id) {
    super(dispatch, "message_draft", `/api/v1/activities/${id}/message_drafts`);
    this.refreshPageContentAfterCommit = true;
  }
}
