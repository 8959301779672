import { get, set, cloneDeep, trim, reduce, isString, defaultTo } from "lodash";
/*
  usage:

  const FormFactory = new FormDefinition({
    fields: [{
      id: 'name',
      label: 'someResource.attributes.name.label',
      message: 'someResource.attributes.name.error',
      rule: 'isRequired'
    }, {
      id: 'status',
      label: 'someResource.attributes.status.label'
    }, {
      id: 'email',
      label: 'someResource.attributes.developerEmail.label',
      message: 'someResource.attributes.developerEmail.error',
      rule: 'isEmailOrEmpty'
    }]
  });

  <Field component="Select" {...this.form.fields.fieldName.props} options={this.props.options} />
  <Field component="Input" {...this.form.fields.fieldName.props} />
  <Field component="TextArea" {...this.form.fields.fieldName.props} />
*/

export const rules = {
  isRequired: value => {
    if (!value || value.length < 1) {
      return "errors.required";
    }
    return undefined;
  },

  isEmail: string => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(string)) {
      return "errors.email";
    }
    return undefined;
  },

  isSlug: string => {
    const regex = /^[a-z0-9-]+/;
    if (!regex.test(string)) {
      return "errors.slug";
    }
    return undefined;
  },

  isPassword: string => {
    if (!string || string.length < 6) {
      return "errors.password";
    }
  },

  isEmailOrEmpty: string => {
    if (!string) {
      return undefined;
    }
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(string)) {
      return "errors.emailOrEmpty";
    }
    return undefined;
  },

  isPhoneOrEmpty: string => {
    if (!string) {
      return undefined;
    }
    const regex = /\+(?:[0-9]?){6,14}[0-9]$/;

    if (!regex.test(string)) {
      return "errors.phoneOrEmpty";
    }
    return undefined;
  },

  isExportDisplayName: string => {
    if (!string) {
      return "errors.required";
    }
    if (!string.match(/^[äÄöÖüÜßa-zA-Z0-9-_ \\.]+$/)) {
      return "errors.exportDisplayName";
    }
    return undefined;
  },

  isNumberOrEmpty: string => {
    if (!string) {
      return undefined;
    }
    if (Number(string) != string) {
      return "errors.numberOrEmpty";
    }
    return undefined;
  },

  isNumber: string => {
    if (!string || Number(string) != string) {
      return "errors.number";
    }
    return undefined;
  },

  isNumberOrZero: string => {
    if (Number(string) != string) {
      return "errors.number";
    }
    return undefined;
  },

  isAmount: number => {
    if (!number && number !== 0) {
      return "errors.amount";
    }
    return undefined;
  },

  isAmountOrEmpty: string => {
    if (!string) {
      return undefined;
    }
    if (Number(string) != string) {
      return "errors.amountOrEmpty";
    }
    return undefined;
  },

  isHexColorCodeOrEmpty: string => {
    if (!string) {
      return undefined;
    }
    const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    if (!regex.test(string)) {
      return "errors.hexColorCodeOrEmpty";
    }
    return undefined;
  }
};

export class FormDefinition {
  constructor(props) {
    this.name = props.name;
    this.fields = props.fields.map(field => {
      field.accessor = field.accessor || field.id;
      return field;
    });
  }

  validate(formData, errors) {
    this.fields.map(field => {
      let error;
      if (typeof field.rule === "string") {
        // validating default rule
        error = rules[field.rule](get(formData, field.accessor));
        if (error) {
          errors[field.id] = error;
        }
      }
      if (typeof field.rule === "function") {
        // validating custom rule
        error = field.rule(get(formData, field.accessor), formData);
        if (error) {
          errors[field.id] = error;
        }
      }
    });
    return errors;
  }

  create(values, i18n = {}, options = {}) {
    const localValues = { _errors: {}, _touched: {}, ...values };
    const extErrors = options.errors || {};
    const state = {};
    const form = this;

    const fields = this.fields.reduce((accu, f) => {
      const hasExtError = !!extErrors[f.id];
      const hasError =
        hasExtError ||
        Boolean(localValues._touched[f.id] && localValues._errors[f.id]);

      set(
        state,
        f.accessor,
        get(localValues, f.accessor, defaultTo(f.default, ""))
      ); // add default values to synthetic state

      const applyChanges = (proxy, data) => {
        // do not use redux state while typing (too slow), we use a synthetic form state here
        set(
          state,
          f.accessor,
          f.control === "Checkbox" ? data.checked : data.value
        );

        const newValues = { ...localValues };
        set(newValues, f.accessor, get(state, f.accessor));
        if (localValues._errors[f.id]) {
          newValues._errors = form.validate(newValues, {});
          newValues._touched[f.id] = true;
        }
        if (options.onChange) {
          options.onChange(newValues);
          // todo: rule up for discussion
          // eslint-disable-next-line no-underscore-dangle
          newValues._touched[f.id] = true;
        }
      };

      const handleBlur = data => {
        if (options.onChange && data) {
          const target = get(data, "target");
          const type = get(target, "type");
          if (type === "text") {
            const trimmedValues = this.trimmedValues(localValues);
            options.onChange(trimmedValues);
          }
        }
      };

      const message = hasError
        ? (hasExtError && extErrors[f.id].join()) || f.message
        : undefined;

      accu[f.id] = {
        props: {
          id: f.id,
          name: f.id,
          label: `${i18n[f.label] || f.label}`,
          placeholder: `${i18n[f.placeholder] || f.placeholder || ""}`,
          autoFocus: f.autoFocus,
          autoComplete: f.autoComplete,
          disabled: f.disabled,
          onChange: applyChanges,
          onBlur: handleBlur,
          error: hasError
        },
        message,
        error: hasError,
        dirty: !!localValues._touched[f.id]
      };

      if (f.nullable) {
        // eslint-disable-next-line no-param-reassign
        accu[f.id].props.nullable = true;
      }

      if (f.control === "Checkbox") {
        accu[f.id].props.checked = get(localValues, f.accessor, false);
        accu[f.id].props.defaultValue = false;
      } else {
        const defaultValue = defaultTo(f.default, "");
        const value = get(localValues, f.accessor);

        accu[f.id].props.value = defaultTo(value, defaultValue);
      }

      return accu;
    }, {});

    function validateBeforeSubmit(formData, cb) {
      // merge initial values with synthetic state values
      const newValues = { ...localValues, ...formData };

      newValues._errors = form.validate(newValues, {});
      newValues._touched = form.fields.reduce((accu, f) => {
        accu[f.id] = true;
        return accu;
      }, {});

      const valid = Object.keys(newValues._errors).length < 1;

      cb(newValues, valid);
    }

    const dirty = Object.keys(localValues._touched).length > 0;
    const invalid = Object.keys(localValues._errors).length > 0;
    const trimmedValues = this.trimmedValues(state);
    return {
      fields,
      dirty,
      invalid,
      handleSubmit: cb => {
        return event => {
          if (event && event.preventDefault) {
            event.preventDefault();
          }
          validateBeforeSubmit(trimmedValues, (values, valid) => {
            if (valid && cb) {
              cb(this.sanitizedValues(values));
            } else if (options.onChange) {
              options.onChange(values);
            }
          });
        };
      },
      validate: this.validate,
      trimmedValues
    };
  }

  sanitizedValues(values) {
    const copy = cloneDeep(values);

    delete copy._errors;
    delete copy._touched;
    return copy;
  }

  trimmedValues(values) {
    return reduce(
      values,
      (result, value, key) => {
        const trimmedValue = isString(value) ? trim(value) : value;
        result = { ...result, [key]: trimmedValue };
        return result;
      },
      {}
    );
  }
}
