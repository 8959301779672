import React, { useState } from "react";
import { number, func } from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import FileUploader from "builder_portal/components/dropzone/FileUploader";
import { FormattedMessage } from "react-intl";

const FileUploadDialog = ({ handleAddFile, paramId }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelect = file => {
    if (file) {
      handleAddFile(paramId, file);
      handleClose();
    }
  };

  return (
    <Modal
      data-component="projectSetupFileUPload"
      trigger={
        <Button as="a">
          <FormattedMessage
            id="project.project_setup.dialog.add_file.label"
            default="Datei einfügen"
          />
        </Button>
      }
      closeIcon
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Modal.Header>
        <FormattedMessage
          id="project.project_setup.dialog.add_file_title.label"
          default="Datei einfügen"
        />
      </Modal.Header>
      <Modal.Content>
        <FileUploader handleFile={handleSelect} multiple />
      </Modal.Content>
    </Modal>
  );
};

FileUploadDialog.propTypes = {
  handleAddFile: func.isRequired,
  paramId: number.isRequired
};

export default FileUploadDialog;
