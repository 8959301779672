import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Icon } from "semantic-ui-react";

class ChangeStatusEvent extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    user: PropTypes.string,
    color: PropTypes.string,
    oldValue: PropTypes.string,
    newValue: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { date, user, oldValue, newValue } = this.props;

    return (
      <div
        data-component="timelineItem"
        className="statusChangedEvent"
        data-event="status_changed"
      >
        <div className="bullet-pointer">
          <Icon color="grey" size="large" circular name="check" />
        </div>
        <Container data-component="logDetails">
          <time>{date}</time>{" "}
          <FormattedMessage
            id="activity.timeline.event.changeStatus"
            defaultMessage="activity.timeline.event.changeStatus"
            values={{ oldValue, newValue }}
          />
          <div className="user">{user}</div>
        </Container>
      </div>
    );
  }
}

export default ChangeStatusEvent;
