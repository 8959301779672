import React, { Component } from "react";
import { instanceOf, oneOf } from "prop-types";
import { connect } from "react-redux";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getRoomBook } from "builder_portal/selectors";
import { browserHistory } from "shared/routes/browserHistory";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import { RoomBookShape } from "shared/components/configurator/roomBook.shape";
import { createStructuredSelector } from "reselect";
import * as Sentry from "@sentry/react";
import { withRouter } from "../../../../shared/helpers/withRouter";

const POLLING_INTERVAL = 10000;
const END_CONDITION = "completed";

class DeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: undefined,
      resourceId: undefined,
      pollingId: undefined
    };
  }

  handleEndPolling = () => {
    const { roomBook } = this.props;
    this.setState({ loading: false, error: null });
    if (roomBook.template) {
      browserHistory.push(`/projects/${roomBook.project_id}/room_books`);
    } else {
      browserHistory.push(`/projects/${roomBook.project_id}/sectionAndUnit`);
    }
  };

  tick = () => {
    const { fileImportsResource } = this.props;
    const { resourceId, pollingId } = this.state;
    fileImportsResource.get(resourceId).then(res => {
      if (res.data?.import_status === END_CONDITION) {
        clearInterval(pollingId);
        this.setState({ pollingId: undefined, resourceId: undefined });
        this.handleEndPolling();
      }
    });
  };

  startPolling = () => {
    const pollingId = setInterval(this.tick, POLLING_INTERVAL);
    this.setState({ pollingId });
  };

  handleDelete = () => {
    const { ctrl, mode } = this.props;
    this.setState({ loading: true });
    const method = mode === "archive" ? "deleteRoomBook" : "purgeRoomBook";

    return ctrl[method]()
      .then(res => {
        if (res.data?.id) {
          this.setState({ resourceId: res.data.id });
          this.startPolling();
        }
      })
      .catch(error => {
        this.setState({ loading: false, error });
        /* Displaying the error that was sent to sentry
         * makes in-place-debugging much easier
         */
        // eslint-disable-next-line no-console
        console.error("api Error occurred", error);
        Sentry.captureException(error);
      });
  };

  render() {
    const { roomBook } = this.props;
    const { loading, error } = this.state;

    if (!roomBook.template || roomBook.deletable) {
      const mode = "archive";

      const button = (
        <Button
          id="remove-roomBook"
          color="red"
          basic
          icon="trash"
          content={<FormattedMessage id="meta.actions.remove" />}
          loading={loading}
        />
      );

      const buttons = [
        {
          id: "delete",
          label: `meta.actions.remove`,
          color: "red",
          onClick: this.handleDelete,
          loading
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true,
          loading
        }
      ];

      return (
        <IsSystemAdminOrHasSpecificRight right="ext_allow_delete_room_books">
          <ConfirmationDialog
            title={`roomBook.actions.${mode}Dialog.title`}
            content={
              <div>
                <FormattedMessage
                  id={`roomBook.actions.${mode}Dialog.message`}
                  defaultText={`roomBook.actions.${mode}Dialog.message`}
                />
              </div>
            }
            buttons={buttons}
            button={button}
            errorMessage={error?.message}
            onAsyncClose={() => this.setState({ error: null })}
          />
        </IsSystemAdminOrHasSpecificRight>
      );
    }
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  roomBook: getRoomBook
});

const mapDispatchToProps = dispatch => {
  return {
    fileImportsResource: new FileImportsResource(dispatch)
  };
};

DeleteButton.defaultProps = {
  roomBook: {}
};

DeleteButton.propTypes = {
  ctrl: instanceOf(RoomBookController).isRequired,
  mode: oneOf(["archive", "purge"]).isRequired,
  roomBook: RoomBookShape,
  fileImportsResource: instanceOf(FileImportsResource).isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteButton)
);
