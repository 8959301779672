import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Modal, Button, Icon, Card, Header } from "semantic-ui-react";
import { get } from "lodash";
import { AttachmentShape } from "shared/shapes/attachment.shape";
import Growl from "builder_portal/actions/growlActions";
import { func } from "prop-types";

const TRANSLATIONS = {
  child: "buyer",
  world: "public",
  key: "internal"
};

const AttachmentVisibilityModal = ({ attachment, onUpdate }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);

  const handleSave = accessRight => {
    if (isLoading) return;
    setLoading(true);
    onUpdate({ id: attachment.id, access_rights: accessRight })
      .then(() => {
        setLoading(false);
        handleClose();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const getAccessRight = useCallback(() => {
    const accessRights = get(attachment, "access_rights", []);
    if (accessRights.indexOf("BUYER") >= 0) return "child";
    if (accessRights.indexOf("PUBLIC") >= 0) return "world";
    return "key";
  }, [attachment]);

  const renderButton = () => {
    const accessRight = getAccessRight();
    return (
      <Button basic icon labelPosition="left" style={{ width: "150px" }}>
        <Icon name={accessRight} />
        <p
          style={{
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: ""
          }}
        >
          <FormattedMessage
            id={`attachment.visibility.${TRANSLATIONS[accessRight]}.title`}
          />
        </p>
      </Button>
    );
  };

  return (
    <Modal
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      trigger={renderButton()}
    >
      <Modal.Header>Freigabeeinstellungen</Modal.Header>
      <Modal.Content>
        <Card
          fluid
          style={{ flexDirection: "row" }}
          onClick={() => handleSave([])}
        >
          <Card.Content style={{ flex: 1 }}>
            <Icon name="key" size="huge" />
          </Card.Content>
          <Card.Content style={{ flex: 15 }}>
            <Card.Description>
              <Header as="h3">
                <FormattedMessage id="attachment.visibility.internal.title" />
              </Header>
              <FormattedMessage id="attachment.visibility.internal.description" />
            </Card.Description>
          </Card.Content>
        </Card>

        <Card
          fluid
          style={{ flexDirection: "row" }}
          onClick={() => handleSave(["BUYER"])}
        >
          <Card.Content style={{ flex: 1 }}>
            <Icon name="child" size="huge" />
          </Card.Content>
          <Card.Content style={{ flex: 15 }}>
            <Card.Description>
              <Header as="h3">
                <FormattedMessage id="attachment.visibility.buyer.title" />
              </Header>
              <FormattedMessage id="attachment.visibility.buyer.description" />
            </Card.Description>
          </Card.Content>
        </Card>

        <Card
          fluid
          style={{ flexDirection: "row" }}
          onClick={() => handleSave(["PUBLIC"])}
        >
          <Card.Content style={{ flex: 1 }}>
            <Icon name="world" size="huge" />
          </Card.Content>
          <Card.Content style={{ flex: 15 }}>
            <Card.Description>
              <Header as="h3">
                <FormattedMessage id="attachment.visibility.public.title" />
              </Header>
              <FormattedMessage id="attachment.visibility.public.description" />
            </Card.Description>
          </Card.Content>
        </Card>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={handleClose}
          disabled={isLoading}
          loading={isLoading}
        >
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AttachmentVisibilityModal.propTypes = {
  attachment: AttachmentShape.isRequired,
  onUpdate: func.isRequired
};

export default AttachmentVisibilityModal;
