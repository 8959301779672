import { get, keyBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors";

const getBuyers = createSelector([getPageContent], pageContent =>
  get(pageContent, "buyers", [])
);

const getBuyerappStatus = createSelector([getPageContent], pageContent =>
  get(pageContent, "buyerapp_status", [])
);

const getBuyerappStatusById = createSelector(
  [getBuyerappStatus],
  buyerappStatus => keyBy(buyerappStatus, "id")
);

export const getBuyersWithStatus = createSelector(
  [getBuyers, getBuyerappStatusById],
  (buyers, status) => {
    return buyers.map(buyer => {
      return { ...buyer, ...status[buyer.id] };
    });
  }
);

const getUnitBuyers = createSelector([getPageContent], pageContent =>
  get(pageContent, "unit_buyers", [])
);

export const getUnitBuyersWithStatus = createSelector(
  [getUnitBuyers, getBuyerappStatusById],
  (buyers, status) => {
    return buyers.map(buyer => {
      return { ...buyer, ...status[buyer.id] };
    });
  }
);
