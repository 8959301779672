import React from "react";
import { FormattedMessage } from "react-intl";
import { Header, Grid, Icon, Segment } from "semantic-ui-react";

const NoAccessPage = () => {
  return (
    <Segment>
      <Grid
        centered
        columns={2}
        textAlign="center"
        style={{ height: "50vh" }}
        verticalAlign="middle"
      >
        <Grid.Column textAlign="center">
          <Icon name="lock" size="massive" color="grey" />
        </Grid.Column>
        <Grid.Row>
          <Header as="h2" textAlign="center" color="grey">
            <FormattedMessage id="error.accessDenied.title" />
          </Header>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default NoAccessPage;
