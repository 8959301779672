/* eslint-disable no-param-reassign */
import React, { useState, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Modal, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Select } from "formik-semantic-ui-react";
import SemanticFormikDateInput from "shared/components/forms/SemanticFormikDateInput";
import MembershipReplacementResource from "builder_portal/actions/membershipReplacementActions";
import { getUser } from "shared/selectors";
import { getProjectsMembership } from "shared/selectors/profile";
import Growl from "builder_portal/actions/growlActions";
import * as Yup from "yup";
import moment from "moment";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { node, shape, number, string } from "prop-types";
import { intersectionBy } from "lodash";

const INITIAL_VALUES = {
  project_membership_id: -1,
  replacement_user_id: null,
  active_from: moment(new Date()),
  active_to: moment(new Date()).add(7, "days")
};

const withAll = model => {
  if (!model) return undefined;
  return { ...model, project_membership_id: model.project_membership_id || -1 };
};

const MembershipReplacement = ({ originalModel, trigger }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [model, setModel] = useState(withAll(originalModel) || INITIAL_VALUES);

  const refResetForm = useRef();

  const currentUser = useSelector(getUser);

  const projectMemberships = useSelector(getProjectsMembership);

  const projectId = useMemo(() => {
    return (
      projectMemberships.find(pm => pm.id === model.project_membership_id)
        ?.project_id || -1
    );
  }, [model.project_membership_id, projectMemberships]);

  const optionAll = useMemo(() => {
    if (!projectMemberships.length) return [];
    return [
      {
        key: "all",
        value: -1,
        text: intl.formatMessage({
          id: "project.attributes.membership.all_projects.label"
        })
      }
    ];
  }, [projectMemberships]);

  const projectOptions = useMemo(() =>
    optionAll.concat(
      projectMemberships.map(pm => ({
        key: pm.id,
        value: pm.id,
        text: pm.project_name
      }))
    )
  );

  const userOptions = useMemo(() => {
    if (projectId > 0) {
      return (
        projectMemberships?.find(pm => pm.project_id === projectId) || []
      ).users
        ?.filter(user => user.id !== currentUser.id)
        .map(user => ({
          key: user.id,
          value: user.id,
          text: user.label
        }));
    }

    if (projectId === -1) {
      const args = ["id"];

      projectMemberships.forEach(pm => args.unshift(pm.users));

      return intersectionBy
        .apply(null, args, "id")
        .filter(user => user.id !== currentUser.id)
        .map(user => ({
          key: user.id,
          value: user.id,
          text: user.label
        }));
    }

    return [];
  }, [projectMemberships, projectId]);

  const validationSchema = Yup.object({
    project_membership_id: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    replacement_user_id: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // resetting the model an projectId will reset users to All projects
    if (!model.id) setModel(INITIAL_VALUES);
    setOpen(false);
  };

  const onSubmit = useCallback((values, { setSubmitting }) => {
    const resource = new MembershipReplacementResource(dispatch);

    if (model?.id) values.id = model.id;
    if (values.project_membership_id === -1)
      values.project_membership_id = null;

    resource
      .save(values)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        resource.fetchAll();
        handleClose();
        setSubmitting(false);
        if (!values.project_membership_id) {
          values.project_membership_id = -1;
        }
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
        setSubmitting(false);
      });
  }, []);

  const onDelete = () => {
    const resource = new MembershipReplacementResource(dispatch);
    resource
      .remove(model?.id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "project.attributes.membership.remove.success.body"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "project.attributes.membership.remove.failure.body"
        );
      });
  };

  const renderDeleteButton = isSubmitting => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="project.attributes.membership.confirmation.title"
        message="project.attributes.membership.confirmation.body"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleProjectChange = (_, { value }) => {
    setModel({
      ...model,
      project_membership_id: value,
      replacement_user_id: null
    });
  };

  return (
    <Formik
      initialValues={model}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={trigger}
          >
            <Modal.Header>
              <FormattedMessage id="project.attributes.membership.title" />
            </Modal.Header>
            <Modal.Content scrolling style={{ minHeight: "450px" }}>
              <Form>
                <Form.Field>
                  <Select
                    fluid
                    selection
                    errorPrompt
                    name="project_membership_id"
                    options={projectOptions}
                    label={intl.formatMessage({
                      id: "project.title.one"
                    })}
                    nullable
                    onChange={handleProjectChange}
                  />
                </Form.Field>

                <Form.Group>
                  <Form.Field style={{ flex: 1 }}>
                    <SemanticFormikDateInput
                      id="active_from"
                      name="active_from"
                      label={intl.formatMessage({
                        id: "project.attributes.membership.active_from.label"
                      })}
                      nullable
                    />
                  </Form.Field>
                  <Form.Field style={{ flex: 1 }}>
                    <SemanticFormikDateInput
                      id="active_to"
                      name="active_to"
                      label={intl.formatMessage({
                        id: "project.attributes.membership.active_to.label"
                      })}
                      nullable
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <Select
                    fluid
                    selection
                    clearable
                    errorPrompt
                    name="replacement_user_id"
                    options={userOptions}
                    label={intl.formatMessage({
                      id: "project.attributes.membership.user.label"
                    })}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button
                    basic
                    color="grey"
                    disabled={isSubmitting}
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                  />

                  <Button
                    primary
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    content={intl.formatMessage({
                      id: `meta.actions.${model?.id ? "save" : "add"}`
                    })}
                  />
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

MembershipReplacement.propTypes = {
  trigger: node.isRequired,
  originalModel: shape({
    project_membership_id: number,
    replacement_user_id: number,
    active_from: string,
    active_to: string
  })
};

MembershipReplacement.defaultProps = {
  originalModel: undefined
};

export default MembershipReplacement;
