import React, { useState, useCallback, useMemo } from "react";
import { Button, Modal, Grid, Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountUsersResource,
  AccountResource
} from "builder_portal/actions/accountActions";
import { UserMigrationResource } from "builder_portal/actions/userMigrationActions";
import { FormattedMessage, useIntl } from "react-intl";
import { getProjectUsers } from "builder_portal/selectors/projectUsers";
import { node, object } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import { ProjectShape } from "shared/shapes";

const RemoveDialog = ({ button, membership, project }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const projectUsers = useSelector(getProjectUsers);
  const [replacements, setReplacements] = useState({
    user_id: membership.user_id,
    message_user_id: null,
    account_manager_user_id: null,
    activity_user_id: null,
    sales_user_id: null
  });

  const usersOptions = useMemo(() => {
    return projectUsers
      .find(p => p.id === project.id)
      .project_memberships.filter(pm => pm.user_id !== membership.user_id)
      .map(({ user }) => ({
        key: user.id,
        value: user.id,
        text: `${user.label} (${user.email})`
      }));
  }, [projectUsers]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnassign = useCallback(() => {
    setLoading(true);
    new UserMigrationResource(dispatch, project.id)
      .save(replacements)
      .then(() => {
        new AccountUsersResource(dispatch)
          .removeProjectMembership({
            membershipId: membership.id,
            projectId: project.id
          })
          .then(() => {
            new Growl(dispatch).success(
              "message.success.title",
              "meta.states.saving"
            );
            new AccountResource(dispatch).get(true).then(() => {
              setLoading(false);
              handleClose();
            });
          });
      })
      .catch(() => {
        setLoading(false);
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  }, [membership, project, replacements]);

  const renderSelectActiveUsers = (key, translationKey) => {
    return (
      <Form.Dropdown
        fluid
        selection
        search
        clearable
        label={intl.formatMessage({
          id: `account.project_memberships.dialog.${translationKey}`
        })}
        options={usersOptions}
        onChange={(e, { value }) =>
          setReplacements({ ...replacements, [key]: value })
        }
      />
    );
  };

  return (
    <Modal
      size="small"
      closeOnEscape
      closeOnDimmerClick
      closeIcon
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      trigger={button}
    >
      <Modal.Header>
        <FormattedMessage
          id="account.project_memberships.dialog.title"
          default="Remove and replace user"
          values={{ user: membership.user.label }}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Grid>
            <Grid.Column width={16}>
              {renderSelectActiveUsers("message_user_id", "messages")}
            </Grid.Column>

            <Grid.Column width={16}>
              {renderSelectActiveUsers("account_manager_user_id", "units")}
            </Grid.Column>

            <Grid.Column width={16}>
              {renderSelectActiveUsers("activity_user_id", "activities")}
            </Grid.Column>

            <Grid.Column width={16}>
              {renderSelectActiveUsers("sales_user_id", "prospects")}
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          <FormattedMessage id="meta.actions.cancel" />
        </Button>
        <Button
          primary
          onClick={handleUnassign}
          disabled={isLoading}
          id="submit-delete-membership"
        >
          <FormattedMessage id="meta.actions.apply" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

RemoveDialog.propTypes = {
  button: node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  membership: object.isRequired,
  project: ProjectShape.isRequired
};

export default RemoveDialog;
