import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { instanceOf, arrayOf, number, string, shape } from "prop-types";
import { If } from "shared/components/elements/Conditions";
import { Menu } from "semantic-ui-react";
import { I18nShape } from "shared/shapes/i18n.shape";
import DocumentAssignmentShape from "shared/shapes/documentAssignment.shape";
import { useLocalStorage } from "beautiful-react-hooks";
import AssignmentsTab from "./AssignmentsTab";
import ExportsTab from "./ExportsTab";
import StylesTab from "./StylesTab";
import SignaturesTab from "./SignaturesTab";
import {
  DocumentAssignmentsResource,
  DocumentExportsResource,
  DocumentStylesResource,
  DocumentSignaturesResource
} from "../../../actions/documentsActions";
import {
  getDocumentAssignmentsList,
  getDocumentExports,
  getDocumentStyles,
  getDocumentSignatures
} from "../../../selectors/documentExports";

const tabs = {
  Assignments: "assignments",
  Exports: "exports",
  Styles: "styles",
  Signatures: "signatures"
};

const DocumentsExport = ({
  i18n,
  documentAssignmentsResource,
  documentExportsResource,
  documentStylesResource,
  documentSignaturesResource,
  documentAssignmentslist,
  documentExports,
  documentStyles,
  documentSignatures
}) => {
  const [currentTab, setCurrentTab] = useLocalStorage(
    "DocumentExportsTab",
    tabs.Assignments
  );
  const [isLoading, setLoading] = useState(false);

  const loadData = () => {
    setLoading(true);

    Promise.all([
      documentAssignmentsResource.fetchAll(),
      documentExportsResource.fetchAll(),
      documentExportsResource.metadata(),
      documentStylesResource.fetchAll(),
      documentSignaturesResource.fetchAll()
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderContent = () => {
    return (
      <div data-component="DocumentExports" style={{ margin: "10px 0" }}>
        <If condition={currentTab === tabs.Assignments}>
          <AssignmentsTab
            i18n={i18n}
            list={documentAssignmentslist}
            isLoading={isLoading}
            documentAssignmentsResource={documentAssignmentsResource}
            loadData={loadData}
          />
        </If>
        <If condition={currentTab === tabs.Exports}>
          <ExportsTab
            i18n={i18n}
            list={documentExports}
            isLoading={isLoading}
            documentExportsResource={documentExportsResource}
            loadData={loadData}
          />
        </If>
        <If condition={currentTab === tabs.Styles}>
          <StylesTab
            i18n={i18n}
            list={documentStyles}
            isLoading={isLoading}
            documentStylesResource={documentStylesResource}
            loadData={loadData}
          />
        </If>
        <If condition={currentTab === tabs.Signatures}>
          <SignaturesTab
            i18n={i18n}
            list={documentSignatures}
            isLoading={isLoading}
            documentSignaturesResource={documentSignaturesResource}
            loadData={loadData}
          />
        </If>
      </div>
    );
  };

  return (
    <>
      <Menu data-component="documentsExportMenu" style={{ margin: 0 }}>
        <Menu.Item
          name={i18n["documentsExport.menu.assignments.title"]}
          onClick={() => setCurrentTab(tabs.Assignments)}
          active={currentTab === tabs.Assignments}
        >
          <FormattedMessage
            id="documentsExport.menu.assignments.title"
            defaultMessage="Documents assignments"
          />
        </Menu.Item>
        <Menu.Item
          name={i18n["documentsExport.menu.exports.title"]}
          onClick={() => setCurrentTab(tabs.Exports)}
          active={currentTab === tabs.Exports}
        >
          <FormattedMessage
            id="documentsExport.menu.exports.title"
            defaultMessage="Documents exports"
          />
        </Menu.Item>
        <Menu.Item
          name={i18n["documentsExport.menu.styles.title"]}
          onClick={() => setCurrentTab(tabs.Styles)}
          active={currentTab === tabs.Styles}
        >
          <FormattedMessage
            id="documentsExport.menu.styles.title"
            defaultMessage="Documents styles"
          />
        </Menu.Item>
        <Menu.Item
          name={i18n["documentsExport.menu.signatures.title"]}
          onClick={() => setCurrentTab(tabs.Signatures)}
          active={currentTab === tabs.Signatures}
        >
          <FormattedMessage
            id="documentsExport.menu.signatures.title"
            defaultMessage="Documents signatures"
          />
        </Menu.Item>
      </Menu>
      {renderContent()}
    </>
  );
};

DocumentsExport.propTypes = {
  i18n: I18nShape.isRequired,
  documentAssignmentsResource: instanceOf(DocumentAssignmentsResource)
    .isRequired,
  documentExportsResource: instanceOf(DocumentExportsResource).isRequired,
  documentStylesResource: instanceOf(DocumentStylesResource).isRequired,
  documentSignaturesResource: instanceOf(DocumentSignaturesResource).isRequired,
  documentAssignmentslist: arrayOf(DocumentAssignmentShape),
  documentExports: arrayOf(
    shape({
      id: number,
      name: string,
      role: string,
      group: string
    })
  ),
  documentStyles: arrayOf(
    shape({
      name: string
    })
  ),
  documentSignatures: arrayOf(
    shape({
      name: string
    })
  )
};

DocumentsExport.defaultProps = {
  documentAssignmentslist: [],
  documentExports: [],
  documentStyles: [],
  documentSignatures: []
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    documentAssignmentslist: getDocumentAssignmentsList(state),
    documentExports: getDocumentExports(state),
    documentStyles: getDocumentStyles(state),
    documentSignatures: getDocumentSignatures(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    documentAssignmentsResource: new DocumentAssignmentsResource(dispatch),
    documentExportsResource: new DocumentExportsResource(dispatch),
    documentStylesResource: new DocumentStylesResource(dispatch),
    documentSignaturesResource: new DocumentSignaturesResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsExport);
