import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, Modal } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { connect } from "react-redux";
import MasterData from "../../../helpers/masterData";
import OptionsShape from "../../../../shared/shapes/options.shape";

class AccountMembershipDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    user: PropTypes.object,
    admin: PropTypes.object,
    membership: PropTypes.object,
    resource: PropTypes.object,
    button: PropTypes.object,
    handleUpdate: PropTypes.func,
    accountRoles: PropTypes.arrayOf(OptionsShape).isRequired
  };

  constructor(props) {
    super(props);

    this.state = { open: false, membership: props.membership };
  }

  onSave(values) {
    const { resource, user, handleUpdate } = this.props;
    resource.update_account_membership(user.id, values).then(() => {
      handleUpdate().then(() => {
        this.setState({ open: false });
      });
    });
  }

  render() {
    const { user, i18n, admin, accountRoles } = this.props;
    const { membership } = this.state;
    const masterdata = new MasterData(i18n);
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "account_role_id",
          label: "user.attributes.role.label"
        },
        {
          id: "status",
          label: "user.attributes.status.label"
        },
        {
          id: "incognito",
          label: "user.attributes.incognito.label",
          control: "Checkbox"
        },
        {
          id: "masterdata_access",
          label: "user.attributes.masterdata_access.label",
          control: "Checkbox"
        }
      ]
    });

    const form = FormFactory.create(membership, i18n, {
      onChange: membership => {
        this.setState({ membership });
      }
    });

    form.fields.account_role_id.props.options = accountRoles;
    form.fields.status.props.options = masterdata.accountMembershipStatus();

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={this.props.button}
        open={this.state.open}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
      >
        <Modal.Header>
          <FormattedMessage
            id="user.actions.editMembership.label"
            defaultMessage="user.actions.editMembership.label"
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="membership" data-component="user">
            <Form.Input label="E-Mail" value={user.email} readOnly />
            <Form.Input
              label="Name"
              value={`${user.first_name} ${user.last_name}`}
              readOnly
            />
            <Form.Field>
              <Field component="Select" {...form.fields.account_role_id} />
            </Form.Field>
            <Form.Field>
              <Field component="Select" {...form.fields.status} />
            </Form.Field>
            {admin.group === "admin" && (
              <Form.Field>
                <Field component="Checkbox" {...form.fields.incognito} />
              </Form.Field>
            )}
            <Form.Field>
              <Field component="Checkbox" {...form.fields.masterdata_access} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="submit"
            positive
            content={i18n["meta.actions.save"]}
            onClick={form.handleSubmit(this.onSave.bind(this))}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n
});

export default connect(mapStateToProps)(AccountMembershipDialog);
