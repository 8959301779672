import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Header,
  Divider,
  Button,
  Icon,
  Table,
  Step,
  Loader
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import HasProjectAccessRight from "shared/components/authorization/HasProjectAccessRight";
import Statistics from "./Statistics";
import ProjectDialog from "./ProjectDialog";

import "./dashboard.scss";

class Dashboard extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    account: PropTypes.object,
    project: PropTypes.object,
    dialog: PropTypes.object,
    statistics: PropTypes.object,
    projectResource: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  // only unsed to get statistics for charts
  componentDidMount() {
    const { projectResource, project } = this.props;
    projectResource.fetchStatistics(project.id, {});
  }

  renderSteps() {
    const { account, project } = this.props;
    const statusOptions = account.getProjectStatusOptions();

    let disabled = false;
    if (statusOptions.length) {
      return statusOptions.map((item, index) => {
        const active = item.key === project.status;
        const step = (
          <Step
            key={`status-step-${index}`}
            active={active}
            completed={!active && !disabled}
            disabled={disabled}
          >
            <Step.Content>
              <Step.Title>{item.text}</Step.Title>
            </Step.Content>
          </Step>
        );

        if (active) {
          disabled = true;
        }

        return step;
      });
    }
    return <span />;
  }

  render() {
    const { i18n, account, projectResource } = this.props;

    const { project } = this.props;
    const editProjectButton = (
      <Button
        basic
        className="margin top spacious"
        fluid
        id="editProject"
        icon="setting"
        content={i18n["project.actions.edit.label"]}
      />
    );
    const imageUrl = get(project, "thumb_url");

    if (project) {
      return (
        <>
          <If condition={account.isEnabled("show_page_layout_v2")}>
            <Header size="large">
              <FormattedMessage
                id="project.tabs.overview"
                defaultMessage="Übersicht"
              />
            </Header>
          </If>
          <Grid stackable data-component="projectDashboard">
            <Grid.Column width="16">
              <Step.Group size="mini" ordered fluid>
                {this.renderSteps()}
              </Step.Group>
            </Grid.Column>

            <Grid.Column width="6">
              <div className="margin bottom medium buildingPlaceholder">
                {imageUrl && (
                  <div
                    className="projectImage"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  />
                )}
              </div>

              <Statistics displayType="numbers" project={project} />

              <HasProjectAccessRight
                right="allow_access_edit_project"
                projectId={project.id}
              >
                <ProjectDialog
                  i18n={i18n}
                  project={project}
                  button={editProjectButton}
                  statusOptions={account.getProjectStatusOptions()}
                  resource={projectResource}
                />
              </HasProjectAccessRight>
            </Grid.Column>

            <Grid.Column width="10">
              <div className="projectHeader">
                <Header>Bauprojekt {project.name}</Header>
                <Header sub>
                  {[project.address || "", project.postcode, project.town].join(
                    " "
                  )}
                </Header>
              </div>

              <Divider clearing horizontal />

              <Statistics displayType="charts" />
            </Grid.Column>
            {false &&
              project &&
              (project.developer_name ||
                project.developer_contact_person ||
                project.developer_email ||
                project.developer_phone) && (
                <Grid.Column width="16">
                  <Header size="small">
                    <FormattedMessage
                      id="developer.title.one"
                      defaultMessage="developer.title.one"
                    />
                  </Header>
                  <Table definition>
                    <Table.Body>
                      {project.developer_name && (
                        <Table.Row>
                          <Table.Cell>
                            <FormattedMessage
                              id="project.attributes.developerName.label"
                              defaultMessage="project.attributes.developerName.label"
                            />
                          </Table.Cell>
                          <Table.Cell>{project.developer_name}</Table.Cell>
                        </Table.Row>
                      )}
                      {project.developer_contact_person && (
                        <Table.Row>
                          <Table.Cell>
                            <FormattedMessage
                              id="project.attributes.developerContactPerson.label"
                              defaultMessage="project.attributes.developerContactPerson.label"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {project.developer_contact_person}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {project.developer_email && (
                        <Table.Row>
                          <Table.Cell>
                            <FormattedMessage
                              id="project.attributes.developerEmail.label"
                              defaultMessage="project.attributes.developerEmail.label"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Icon name="mail" />
                            {project.developer_email}
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {project.developer_phone && (
                        <Table.Row>
                          <Table.Cell>
                            <FormattedMessage
                              id="project.attributes.developerPhone.label"
                              defaultMessage="project.attributes.developerPhone.label"
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Icon name="phone" />
                            {project.developer_phone}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
          </Grid>
        </>
      );
    }
    return <Loader active />;
  }
}

export default Dashboard;
