import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Item,
  Header,
  List,
  Segment,
  Message,
  Transition
} from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { flatMap, isEmpty, compact, groupBy, values, take } from "lodash";
import { byIncludes } from "shared/helpers/filters";
import FileDropzone from "builder_portal/components/dropzone/FileDropzone";
import ButtonCheckbox from "shared/components/elements/ButtonCheckbox";
import TabDialog from "shared/components/elements/TabDialog";
import VersionHistoryButton from "../buttons/VersionHistoryButton";

import "./attachmentsDialog.scss";

class AttachmentsDialog extends PureComponent {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    defaultCategory: PropTypes.string,
    defaultOpen: PropTypes.bool,
    options: PropTypes.objectOf(
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object))
    ).isRequired,
    i18n: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    value: PropTypes.arrayOf(PropTypes.number),
    resourceId: PropTypes.number.isRequired,
    resourceName: PropTypes.string.isRequired,
    ctxCtrl: PropTypes.shape({ refresh: PropTypes.func }).isRequired
  };

  static defaultProps = {
    defaultValue: [],
    value: null
  };

  constructor(props) {
    super(props);
    const { defaultValue, value } = props;
    this.state = {
      value: value || defaultValue,
      uploadMode: false,
      isLoading: false,
      isRefreshing: false,
      filterVersions: true,
      showInitialTransition: true
    };
  }

  handleUploadButtonClick = () => {
    this.setState({ uploadMode: !this.state.uploadMode });
  };

  toggleLoading = value => {
    this.setState({ isLoading: value });
  };

  handleSuccess = attachment => {
    const { ctxCtrl } = this.props;
    this.setAttachment(attachment.id, true);
    this.setState({ isRefreshing: true });
    ctxCtrl.refresh().then(() => {
      this.setState({ isRefreshing: false, uploadMode: false });
    });
  };

  render() {
    return (
      <div>
        {this.renderAttachments()}
        {this.renderAddAttachmentButton()}
      </div>
    );
  }

  handleInitialTransitionComplete = () =>
    this.setState({ showInitialTransition: false });

  // Workaround for a bug in semantic transitions
  // Normaly with transitionOnMount it should only play the show transition, but due to this bug
  // show and hide is both triggered. To avoid this we keep track of the Transion state by ourselves.
  // see: https://github.com/Semantic-Org/Semantic-UI-React/issues/3641
  withInitialTransition = content => {
    const { showInitialTransition } = this.state;
    return showInitialTransition ? (
      // when changing the duration make sure to also adjust it in the css
      // see app/javascript/builder_portal/components/attachment/attachments.scss:11
      <Transition
        transitionOnMount
        directional
        unmountOnHide
        animation="custom"
        duration={3100}
        onShow={this.handleInitialTransitionComplete}
      >
        {content}
      </Transition>
    ) : (
      content
    );
  };

  renderAttachments() {
    const { options } = this.props;
    const { value } = this.state;

    const selectedAttachments = flatMap(options, o =>
      flatMap(o, x => x)
    ).filter(byIncludes("id", value));

    return this.withInitialTransition(
      <Transition.Group
        as={List}
        animation="fade down"
        horizontal
        data-component="attachments"
      >
        {selectedAttachments.map(attachment => (
          <List.Item
            data-component="attachment"
            data-id={attachment.id}
            key={attachment.id}
          >
            <Button.Group compact basic size="small">
              <Button
                icon="file outline"
                content={attachment.display_name}
                as="a"
                href={attachment.attachment_url}
                target="_document"
              />
              <Button
                type="button"
                icon="delete"
                onClick={() => this.setAttachment(attachment.id, false)}
              />
            </Button.Group>
          </List.Item>
        ))}
      </Transition.Group>
    );
  }

  renderAddAttachmentButton() {
    const { categories, i18n, defaultOpen, defaultCategory } = this.props;
    const { uploadMode, isLoading } = this.state;

    const panes = categories.map(category => ({
      menuItem: {
        key: category.id,
        id: category.id,
        content: this.labelFor(category)
      },
      pane: {
        key: category.id,
        basic: true,
        attached: false,
        content: [
          <Header key="category-header">
            {category.label}
            <Button
              type="button"
              icon="plus"
              id="upload"
              floated="right"
              content={i18n["attachment.actions.upload"]}
              onClick={this.handleUploadButtonClick}
            />
          </Header>,
          uploadMode
            ? this.renderDropZone(category)
            : this.renderGroups(category)
        ],
        loading: isLoading
      }
    }));

    let defaultActiveIndex = categories.findIndex(
      category => category.id === defaultCategory
    );
    if (defaultActiveIndex === -1) {
      defaultActiveIndex = 0;
    }

    return (
      <TabDialog
        triggerButton={{
          id: "addAttachment",
          content: i18n["attachment.actions.attach"]
        }}
        defaultOpen={defaultOpen}
        header={i18n["attachment.attachDialog.header"]}
        actions={[i18n["meta.actions.done"]]}
        panes={panes}
        defaultActiveIndex={defaultActiveIndex}
      />
    );
  }

  labelFor(category) {
    const { options } = this.props;
    const data = options[category.id];
    const count = Object.values(data).reduce(
      (sum, group) => sum + group.length,
      0
    );
    if (count > 0) {
      return `${category.label} (${count})`;
    }
    return category.label;
  }

  renderDropZone(category) {
    const { i18n, resourceName, resourceId } = this.props;

    return (
      <FileDropzone
        key="attachmentDropzone"
        id="attachment_selector"
        title={i18n["attachment.actions.dragAndDrop"]}
        className="attachmentDropZone margin top spacious"
        resourceName={resourceName}
        resourceId={resourceId}
        additionalData={[{ "attachment[role]": category.id }]}
        onSuccess={this.handleSuccess}
        toggleLoading={this.toggleLoading}
        i18n={i18n}
      />
    );
  }

  renderGroups(category) {
    const { options, i18n } = this.props;

    const attachmentGroups = options[category.id];

    const groups = Object.keys(attachmentGroups).map(key =>
      this.renderGroup(
        i18n[`attachment.reference.${key}`],
        attachmentGroups[key],
        key
      )
    );

    if (isEmpty(compact(groups))) {
      return (
        <Message
          key="nothingUploadedMessage"
          info
          content={`Es wurden noch keine Dokumente vom Typ ${category.label} hochgeladen`}
        />
      );
    }
    return groups;
  }

  toggleVersions = attachment => {
    const filename = attachment.display_name;
    this.setState({ [filename]: !this.state[filename] });
  };

  renderItems(attachments) {
    const { value } = this.state;
    const name = attachments[0].display_name;
    const items = !this.state[name] ? take(attachments) : attachments;

    return items.map(attachment => {
      const indentation =
        items.indexOf(attachment) !== 0 ? { marginLeft: "2em" } : {};

      return (
        <Item key={attachment.id} data-model="attachment">
          <Item.Content>
            <Header
              size="tiny"
              as="a"
              href={attachment.attachment_url}
              target="_document"
              className="ellipsis ellipsis-300"
              style={indentation}
            >
              {attachment.display_name}
              <Header.Subheader>
                <time dateTime={attachment.created_at}>
                  {moment(attachment.created_at).format("LLL")}
                </time>
              </Header.Subheader>
            </Header>
            <ButtonCheckbox
              data-action="add-attachment"
              floated="right"
              compact
              checked={value.includes(attachment.id)}
              onChange={(event, data) =>
                this.setAttachment(attachment.id, data.checked)
              }
            />
            {attachments.length > 1 &&
              attachments.indexOf(attachment) === 0 && (
                <VersionHistoryButton
                  size="tiny"
                  floated="right"
                  onClick={() => this.toggleVersions(attachment)}
                />
              )}
          </Item.Content>
        </Item>
      );
    });
  }

  renderGroup(header, attachmentGroup, key) {
    const sortedAttachments = values(groupBy(attachmentGroup, "display_name"));

    if (attachmentGroup.length <= 0) return null;

    return (
      <Segment vertical basic key={key}>
        <Header sub content={header} />
        <Segment>
          <Item.Group divided>
            {sortedAttachments.map(attachments => {
              return this.renderItems(attachments);
            })}
          </Item.Group>
        </Segment>
      </Segment>
    );
  }

  setAttachment(attachmentId, checked) {
    const oldValue = this.state.value;
    const { onChange } = this.props;

    const newValue = checked
      ? [...oldValue, attachmentId]
      : oldValue.filter(id => id !== attachmentId);
    this.setState({ value: newValue });
    onChange(this, { value: newValue });
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n
});

export default connect(mapStateToProps)(AttachmentsDialog);
