import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import ProductLightbox from "./ProductLightbox";

class ProductLightboxContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dialogModel, i18n, ctrl } = this.props;
    if (dialogModel) {
      return (
        <ProductLightbox
          i18n={i18n}
          model={dialogModel.lineItem}
          toggleDialog={() => ctrl.toggleDialog("productLightBox", null)}
          onChange={dialogModel.onChange}
        />
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, props) => {
  const { module, id } = props;
  return {
    i18n: state.i18n,
    dialogModel: get(state, ["dialog", module, id, "productLightBox"])
  };
};

export default connect(mapStateToProps)(ProductLightboxContainer);
