import React, { useState, useMemo, useContext } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form, Input, Select, TextArea } from "formik-semantic-ui-react";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import ProjectQuestionGroupsResource from "builder_portal/actions/projectQuestionGroupsActions";
import ProjectQuestionGroupShape from "shared/shapes/projectQuestionGroup.shape";
import Growl from "builder_portal/actions/growlActions";
import { node, number } from "prop-types";

import { ProjectQuestionsLoaderContext } from "./ProjectQuestionsLoader";

const ProjectQuestionGroupDialog = ({ button, model, total }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { projectId, loadData } = useContext(ProjectQuestionsLoaderContext);
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const positionOptions = useMemo(() => {
    return Array.from({ length: total }, (_, i) => ({
      key: i + 1,
      value: i + 1,
      text: i + 1
    }));
  });

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const onSubmit = (values, formik) => {
    new ProjectQuestionGroupsResource(dispatch, projectId)
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        loadData();
        setOpen(false);
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
      })
      .catch(() => {
        formik.setSubmitting(false);
        new Growl(dispatch).error(
          "roomBook.projectQuestionGroups.error.title",
          "roomBook.projectQuestionGroups.error.body"
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="ProjectQuestionDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`roomBook.projectQuestionGroups.dialog.${
            !model.id ? "createTitle" : "editTitle"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestionGroups.dialog.name"
                  })}
                />

                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestionGroups.dialog.title"
                  })}
                />

                <TextArea
                  id="summary"
                  name="summary"
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestionGroups.dialog.summary"
                  })}
                />

                <Select
                  id="position"
                  name="position"
                  options={positionOptions}
                  label={intl.formatMessage({
                    id: "roomBook.projectQuestionGroups.dialog.position"
                  })}
                />

                <Modal.Actions className="right">
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

ProjectQuestionGroupDialog.propTypes = {
  button: node.isRequired,
  model: ProjectQuestionGroupShape.isRequired,
  total: number.isRequired
};

export default ProjectQuestionGroupDialog;
