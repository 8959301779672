import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "semantic-ui-react";

class FieldError extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { text } = this.props;
    if (!text || typeof text !== "string") return null;
    return (
      <Label data-component="fieldError" basic color="red" pointing>
        <FormattedMessage id={text} defaultMessage={text} />
      </Label>
    );
  }
}

export default FieldError;
