import React, { forwardRef } from "react";
import { FormField } from "semantic-ui-react";
import { func, bool, string } from "prop-types";
import { Field } from "formik-semantic-ui-react";
import DateInput from "./DateInput";

const SemanticFormikDateInput = forwardRef(
  (
    { id, name, label, validate, fast, nullable, placeholder, onChange },
    ref
  ) => {
    return (
      <Field name={name} validate={validate} fast={fast}>
        {({ field: { value }, form: { setFieldValue }, meta }) => {
          return (
            <FormField error={meta.touched && !!meta.error}>
              <label id={`${id}-label`} htmlFor={label}>
                {label}
              </label>
              <DateInput
                id={id}
                ref={ref}
                onChange={(_, { value: v }) => {
                  setFieldValue(name, v);
                  if (onChange) {
                    onChange(v);
                  }
                }}
                name={name}
                nullable={nullable}
                placeholder={placeholder}
                value={value}
              />
            </FormField>
          );
        }}
      </Field>
    );
  }
);

SemanticFormikDateInput.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  placeholder: string,
  fast: bool,
  nullable: bool,
  validate: func,
  onChange: func
};

SemanticFormikDateInput.defaultProps = {
  placeholder: undefined,
  fast: true,
  nullable: false,
  validate: () => {},
  onChange: undefined
};

SemanticFormikDateInput.displayName = "SemanticFormikDateInput";

export default SemanticFormikDateInput;
