// import APM from "../middlewares/apm";
import { get } from "lodash";
import * as Sentry from "@sentry/react";
import { UPDATE_ACCOUNT } from "../constants/appConstants";

export function getAccountId(state) {
  return get(state, "account.account.id", undefined);
}
export const account = (state = "", action) => {
  let anonymousUser = {};
  switch (action.type) {
    case UPDATE_ACCOUNT:
      if (action.payload && action.payload.user) {
        anonymousUser = {
          group: action.payload.user.group,
          role: action.payload.user.role,
          account_id: action.payload.account.id,
          id: action.payload.user.id,
          email: action.payload.user.email.replace(/^(.).*(@.+)$/, "$1*$2")
        };
      }
      Sentry.setUser(anonymousUser);
      // APM.setUserContext(anonymousUser);

      return action.payload;
    default:
      return state;
  }
};
