import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { cloneDeep, get } from "lodash";
import AttachmentsList from "../attachment/AttachmentsList";

import { AttachmentResource } from "../../actions/attachmentsActions";

class LineItemFormAttachmentWrapper extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    attachments: PropTypes.array,
    parentAttachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isTemplate: PropTypes.bool,
    context: PropTypes.object,
    resource: PropTypes.object,
    lineItemId: PropTypes.number,
    onChange: PropTypes.func
  };

  render() {
    const {
      i18n,
      isTemplate,
      context,
      lineItemId,
      attachments,
      parentAttachments
    } = this.props;

    return (
      !isTemplate && (
        <AttachmentsList
          context={context}
          size="small"
          resourceId={lineItemId}
          resourceName="/room_books/sub_line_items"
          i18n={i18n}
          handleRemove={this.removeAttachment}
          handleAssign={this.assignAttachment}
          handleUpdate={this.updateAttachment}
          handleAdd={this.addAttachment}
          parentAttachments={parentAttachments}
          attachments={attachments}
          destructionMode="lineItem"
        />
      )
    );
  }

  removeAttachment = id => {
    const { resource, lineItemId, attachments, onChange } = this.props;

    return resource.removeAttachment(id, lineItemId).then(() => {
      onChange(
        attachments.filter(attachment => {
          return attachment && attachment.id !== id;
        })
      );
    });
  };

  assignAttachment = id => {
    const { resource, lineItemId } = this.props;

    return resource.assignAttachment(id, lineItemId).then(response => {
      if (response.data && response.data.attachment) {
        const attachments = cloneDeep(this.props.attachments) || [];
        attachments.push(response.data.attachment);
        this.props.onChange(attachments);
      }
    });
  };

  updateAttachment = attachment => {
    const { resource, lineItemId } = this.props;
    return resource
      .updateAttachment({ attachment }, lineItemId)
      .then(response => {
        this.applyAttachmentUpdate(get(response, "data.attachment"));
      });
  };

  addAttachment = attachment => {
    // when addAttachment is fired the document is already uploaded and saved
    const attachments = cloneDeep(this.props.attachments);
    if (attachment) attachments.push(attachment);
    return this.props.onChange(attachments);
  };

  applyAttachmentUpdate(updatedAttachment) {
    const { attachments } = this.props;
    if (!updatedAttachment) {
      return;
    }
    const newAttachments = attachments.map(attachment => {
      if (updatedAttachment.id === attachment.id) {
        return updatedAttachment;
      }
      return attachment;
    });
    this.props.onChange(newAttachments);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resource: new AttachmentResource(dispatch)
  };
};

export default connect(
  (state, props) => props,
  mapDispatchToProps
)(LineItemFormAttachmentWrapper);
