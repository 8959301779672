import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProject, getAccount } from "shared/selectors";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { ProjectSetupsResource } from "builder_portal/actions/projectSetupActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";
import { node } from "prop-types";

export const ProjectSetupDataContext = React.createContext({});

const prepareActions = {
  data: (projectSetup, type) => ({
    time: projectSetup[`${type}completed_at`],
    userId: projectSetup[`${type}completed_by_id`]
  }),
  resetObject: (projectSetupId, type) => ({
    id: projectSetupId,
    [`${type}completed_at`]: null,
    [`${type}completed_by_id`]: null
  }),
  saveObject: (projectSetupId, isoTime, userId, type) => ({
    id: projectSetupId,
    [`${type}completed_at`]: isoTime,
    [`${type}completed_by_id`]: userId
  })
};

const ProjectSetupDataLoader = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const project = useSelector(getProject);
  const { project_setup } = project;
  const account = useSelector(getAccount);

  const setIsLoading = () => setLoading(true);
  const unsetIsLoading = () => setLoading(false);

  const resource = useMemo(
    () => new ProjectSetupsResource(dispatch, project_setup.id),
    [project_setup]
  );

  const updateProjectSetup = values => {
    const { id } = project_setup;
    return resource
      .save({ ...values, id })
      .then(() => new ProjectsResource(dispatch).get(project.id))
      .catch(silentHandleApiRequestErrors);
  };

  const handleUplaodCompleted = type => {
    const { id } = project_setup;
    if (id && account.data?.user?.id) {
      resource
        .save(
          prepareActions.saveObject(
            project.project_setup.id,
            new Date().toISOString(),
            account.data.user.id,
            type
          )
        )
        .then(() => new ProjectsResource(dispatch).get(project.id))
        .catch(silentHandleApiRequestErrors);
    }
  };

  const handleReset = type => {
    const { id } = project_setup;
    setLoading(true);
    resource
      .save(prepareActions.resetObject(id, type))
      .then(() => {
        new ProjectsResource(dispatch).get(project.id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const renderUploadedMessage = ({ userId, time }) => {
    const userObject = account.getUsers().find(u => u.id === userId);

    if (!userObject) return null;

    const user = `${userObject.first_name} ${userObject.last_name}`;
    return (
      <FormattedMessage
        id="project.project_setup.data.changedBy.label"
        values={{
          date: moment(time).format("ll"),
          time: moment(time).format("HH:mm"),
          user
        }}
      />
    );
  };

  return (
    <ProjectSetupDataContext.Provider
      value={{
        project,
        account,
        projectSetup: project_setup,
        handleUplaodCompleted,
        handleReset,
        renderUploadedMessage,
        prepareActions,
        isLoading,
        updateProjectSetup,
        setIsLoading,
        unsetIsLoading
      }}
    >
      {children}
    </ProjectSetupDataContext.Provider>
  );
};

ProjectSetupDataLoader.propTypes = {
  children: node.isRequired
};

export default ProjectSetupDataLoader;
