import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form, Input, TextArea } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { node, func } from "prop-types";
import { cloneDeep } from "lodash";
import { Modal, Button, Grid } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { UnitFeatureGroupShape } from "shared/shapes/unitFeatureGroup.shape";
import Growl from "builder_portal/actions/growlActions";
import { getProject } from "shared/selectors";
import { UnitFeatureGroupsResource } from "../../../actions/unitFeatureGroupsActions";

const UnitFeatureGroupDialog = ({ button, model, onCreate }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const validationScheme = Yup.object({
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  const onSubmit = useCallback((values, formik) => {
    new UnitFeatureGroupsResource(dispatch, project.id)
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        new Growl(dispatch).success(
          "macro.message.success.title",
          "macro.message.success.body"
        );

        new UnitFeatureGroupsResource(dispatch, project.id)
          .fetchAll()
          .then(res => {
            if (onCreate) {
              const id = res.data?.unit_feature_groups?.find(
                x => x.name === values.name
              )?.id;
              if (id) onCreate(id);
            }
          });

        setOpen(false);
      })
      .catch(() => {
        formik.setSubmitting(false);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      open={isOpen}
      trigger={button}
      onOpen={handleOpen}
      onClose={handleClose}
      data-component="UnitFeatureGroupDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`roomBook.unitFeatureGroups.types.dialog.${
            !model.id ? "createTitle" : "editTitle"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form id="unitFeatureGroupForm">
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.unitFeatureGroups.types.dialog.name"
                  })}
                />

                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "roomBook.unitFeatureGroups.types.dialog.titleField"
                  })}
                />

                <TextArea
                  id="description"
                  errorPrompt
                  name="description"
                  label={intl.formatMessage({
                    id: "roomBook.unitFeatureGroups.types.dialog.description"
                  })}
                />

                <Modal.Actions>
                  <Grid>
                    <Grid.Column width={16} textAlign="right">
                      <Button
                        basic
                        id="cancel"
                        content={intl.formatMessage({
                          id: "meta.actions.cancel"
                        })}
                        onClick={handleClose}
                        loading={isSubmitting}
                      />
                      <Button
                        primary
                        id="submit"
                        type="submit"
                        content={
                          model.id
                            ? intl.formatMessage({ id: "meta.actions.save" })
                            : intl.formatMessage({ id: "meta.actions.add" })
                        }
                        loading={isSubmitting}
                      />
                    </Grid.Column>
                  </Grid>
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UnitFeatureGroupDialog.propTypes = {
  button: node.isRequired,
  model: UnitFeatureGroupShape.isRequired,
  onCreate: func
};

UnitFeatureGroupDialog.defaultProps = {
  onCreate: null
};

export default UnitFeatureGroupDialog;
