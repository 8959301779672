import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import { getFileImports } from "builder_portal/selectors/fileImports";
import { Button, Grid, Header, Table } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const Jobs = () => {
  const dispatch = useDispatch();
  const fileImports = useSelector(getFileImports);

  useEffect(() => {
    new FileImportsResource(dispatch).fetchAll();
  }, []);

  const duration = fileImport => {
    if (!fileImport.started_at) return "-";

    const endAt = fileImport.completed_at || fileImport.failed_at;

    if (endAt) {
      return Math.round(
        moment(endAt).diff(moment(fileImport.started_at)) / 1000
      );
    }
    return "-";
  };

  const renderTable = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="fileImports.table.role" default="Role" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage
                id="fileImports.table.user"
                default="Username"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage
                id="fileImports.table.status"
                default="Status"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="fileImports.table.createdAt"
                default="Created At"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="fileImports.table.startedAt"
                default="Started At"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="fileImports.table.completedAt"
                default="Completed At"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage
                id="fileImports.table.duration"
                default="Duration"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              <FormattedMessage id="fileImports.table.file" default="File" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fileImports.map(fileImport => (
            <Table.Row key={fileImport.id}>
              <Table.Cell>{fileImport.role}</Table.Cell>
              <Table.Cell>
                {fileImport.user?.first_name} {fileImport.user?.last_name}
              </Table.Cell>
              <Table.Cell>{fileImport.status}</Table.Cell>
              <Table.Cell textAlign="right">
                {moment(fileImport.created_at).format("LL")}
                <div>{moment(fileImport.created_at).format("h:mm:ss")}</div>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <If condition={!!fileImport.started_at}>
                  {moment(fileImport.started_at).format("LL")}{" "}
                  <div>{moment(fileImport.started_at).format("h:mm:ss")}</div>
                </If>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <If condition={!!fileImport.completed_at}>
                  {moment(fileImport.completed_at).format("LL")}{" "}
                  <div>{moment(fileImport.completed_at).format("h:mm:ss")}</div>
                </If>
                <If condition={!!fileImport.failed_at}>
                  {moment(fileImport.failed_at).format("LL")}{" "}
                  <div>{moment(fileImport.failed_at).format("h:mm:ss")}</div>
                </If>
              </Table.Cell>
              <Table.Cell textAlign="center">{duration(fileImport)}</Table.Cell>
              <Table.Cell textAlign="center">
                <If condition={!!fileImport.file_file_name}>
                  <Button basic target="_blank" href={fileImport.file_url}>
                    <FormattedMessage
                      id="meta.actions.download"
                      defaultMessage="Download"
                    />
                  </Button>
                </If>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div>
      <Grid stackable verticalAlign="middle">
        <Grid.Column width="16">
          <Header size="large">
            <FormattedMessage id="fileImports.title" defaultMessage="Jobs" />
          </Header>
        </Grid.Column>
        <Grid.Column width={16}>{renderTable()}</Grid.Column>
      </Grid>
    </div>
  );
};

export default Jobs;
