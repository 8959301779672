import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Segment, Modal, Button, Header, Form } from "semantic-ui-react";
import { Input, Select } from "formik-semantic-ui-react";
import { getMessageThread } from "builder_portal/selectors/messages";
import { getProcessDefinitions, getUser } from "shared/selectors";
import { AccountUsersResource } from "builder_portal/actions/accountActions";
import { ActivitiesResource } from "builder_portal/actions/activityActions";
import { browserHistory } from "shared/routes/browserHistory";
import { UnitShape } from "shared/shapes/unit.shape";
import { getUsers } from "shared/selectors/account";
import Growl from "builder_portal/actions/growlActions";
import { get } from "lodash";
import { node, shape, number, string } from "prop-types";

const ActivityCreatorDialog = ({
  button,
  messageThread,
  unit,
  project,
  processDefinition
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const users = useSelector(getUsers);
  const currentUser = useSelector(getUser);
  const refResetForm = useRef();

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const userOptions = useMemo(() => {
    return users.map(user => {
      return {
        key: user.id,
        text: `${user.first_name || ""} ${user.last_name || ""}`,
        value: user.id
      };
    });
  }, [users]);

  const onSubmit = useCallback(values => {
    const resource = new ActivitiesResource(dispatch);
    return resource
      .save(values)
      .then(res => {
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
        const activityId = res.data?.activity?.id;
        if (activityId)
          browserHistory.push(`${project.url}/activities/${activityId}`);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  }, []);

  return (
    <Formik
      initialValues={{
        assignee_id: currentUser.id,
        title: "",
        status: "request",
        process_type: processDefinition.config.process_type,
        unit_id: unit?.id,
        message_thread_id: messageThread.id
      }}
      onSubmit={onSubmit}
      enableReinitialize
      validate={values => {
        const errors = {};
        if (values.title === "")
          errors.title = intl.formatMessage({
            id: "message.errorForm.required"
          });
        return errors;
      }}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            data-component="ActivityCreatorDialog"
          >
            <Modal.Header>
              <FormattedMessage id="email.activity_section.dialog.title" />
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Select
                  name="assignee_id"
                  label={intl.formatMessage({
                    id: "email.activity_section.dialog.user"
                  })}
                  placeholder={intl.formatMessage({
                    id: "email.activity_section.dialog.user"
                  })}
                  options={userOptions}
                  fluid
                  selection
                  closeOnChange
                />
                <Input
                  name="title"
                  label={intl.formatMessage({
                    id: "email.activity_section.dialog.activity_title"
                  })}
                  placeholder={intl.formatMessage({
                    id: "email.activity_section.dialog.activity_title"
                  })}
                  type="text"
                  errorPrompt
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

ActivityCreatorDialog.propTypes = {
  button: node.isRequired,
  messageThread: shape({ id: number }).isRequired,
  unit: UnitShape.isRequired,
  project: shape({ url: string }).isRequired,
  processDefinition: shape({
    config: shape({ process_type: string, name: string })
  }).isRequired
};

const ActivityCreator = () => {
  const dispatch = useDispatch();
  const messageThread = useSelector(getMessageThread);
  const processDefinitions = useSelector(getProcessDefinitions);

  useEffect(() => {
    new AccountUsersResource(dispatch).fetchAll();
  }, []);

  const startableProcessDefinitions = useMemo(
    () =>
      processDefinitions.filter(
        processDefinition =>
          processDefinition.config?.startable_from_message_thread
      ),
    [processDefinitions]
  );

  const [activitiesCount, unit, project] = useMemo(() => {
    const references = get(messageThread, "references", []);
    const mActivitiesCount = references.filter(r => r.type === "activity")
      .length;
    const mUnit = references.find(r => r.type === "unit");
    const mProject = references.find(r => r.type === "project");
    return [mActivitiesCount, mUnit, mProject];
  }, [messageThread]);

  if (activitiesCount > 0 || startableProcessDefinitions.length === 0)
    return null;
  if (!unit) return null;

  return (
    <Segment>
      <Header as="h4">
        <FormattedMessage id="email.activity_section.title" />
      </Header>
      {startableProcessDefinitions.map(processDefinition => (
        <ActivityCreatorDialog
          key={processDefinition.id}
          messageThread={messageThread}
          unit={unit}
          project={project}
          processDefinition={processDefinition}
          button={
            <Button>
              <FormattedMessage
                id="email.activity_section.button.label"
                values={{ name: processDefinition.config?.name }}
              />
            </Button>
          }
        />
      ))}
    </Segment>
  );
};

export default ActivityCreator;
