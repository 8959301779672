import React from "react";
import { useSelector } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { getAccount } from "shared/selectors";
import PropTypes from "prop-types";

const HasAccessProjectCatalogRight = ({ children }) => {
  const account = useSelector(getAccount);

  return <If condition={account.hasAccessProjectCatalogRight()}>{children}</If>;
};

HasAccessProjectCatalogRight.propTypes = {
  children: PropTypes.node
};

HasAccessProjectCatalogRight.defaultProps = {
  children: null
};

export default HasAccessProjectCatalogRight;
