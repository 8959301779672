import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { Input, Select } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { string } from "prop-types";
import { getSalutations, getTitles } from "../../../../shared/selectors";

const ContactForm = ({ scope }) => {
  const intl = useIntl();

  return (
    <Grid style={{ overflowX: "hidden" }}>
      <Grid.Column width={16}>
        <Header as="h3">
          <FormattedMessage id="roomBook.unitProspect.dialog.personalData" />
        </Header>
      </Grid.Column>

      <Grid.Column width={16}>
        <Input
          name={`${scope}.email`}
          fluid
          placeholder="example@example.com"
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.email"
          })}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.firstName"
          })}
          name={`${scope}.first_name`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.lastName"
          })}
          name={`${scope}.last_name`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Select
          placeholder="Keine Anrede"
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.salutation"
          })}
          name={`${scope}.salutation`}
          options={getSalutations.map(key => {
            return {
              key,
              value: key,
              text: intl.formatMessage({
                id: `meta.form_of_address.salutation.${key || "nil"}`
              })
            };
          })}
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Select
          placeholder="Keine Titel"
          fluid
          selection
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.title"
          })}
          options={getTitles.map(key => {
            return {
              key,
              value: key,
              text: intl.formatMessage({
                id: `meta.form_of_address.title.${key || "nil"}`
              })
            };
          })}
          name={`${scope}.title`}
        />
      </Grid.Column>
      <Grid.Column width={16}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.company"
          })}
          name={`${scope}.company`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.phone"
          })}
          name={`${scope}.phone`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.mobileNumber"
          })}
          name={`${scope}.mobile`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={12}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.street"
          })}
          name={`${scope}.address_street`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.houseNumber"
          })}
          name={`${scope}.address_number`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.postalCode"
          })}
          name={`${scope}.address_postcode`}
          errorPrompt
        />
      </Grid.Column>
      <Grid.Column width={12}>
        <Input
          fluid
          label={intl.formatMessage({
            id: "roomBook.unitProspect.dialog.location"
          })}
          name={`${scope}.address_town`}
          errorPrompt
        />
      </Grid.Column>
    </Grid>
  );
};

ContactForm.propTypes = {
  scope: string.isRequired
};

export default ContactForm;
