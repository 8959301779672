import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useRef,
  useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form, Select, Input, Checkbox } from "formik-semantic-ui-react";
import SemanticFormikFloatInput from "shared/components/forms/SemanticFormikFloatInput";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { node } from "prop-types";
import { Modal, Button, Message } from "semantic-ui-react";
import { defaultRichTextTags } from "builder_portal/helpers/defaultRichTextTags";
import { useIntl, FormattedMessage } from "react-intl";
import { UnitVariableShape } from "shared/shapes/unitVariables.shape";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import { getRoomBook } from "builder_portal/selectors";
import { getProject } from "shared/selectors";
import { UnitVariablesLoaderContext } from "./UnitVariablesLoader";
import { UnitVariablesResource } from "../../../actions/unitVariablesActions";

const UnitVariableDialog = ({ button, model }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const roomBook = useSelector(getRoomBook);
  const project = useSelector(getProject);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);
  const [selectedUnitId, setUnitId] = useState(
    initialFormValue.unit_id ? initialFormValue.unit_id : undefined
  );

  const {
    unitVariableTypes,
    allUnitOptions,
    unitVariables,
    loadVariables
  } = useContext(UnitVariablesLoaderContext);
  const refResetForm = useRef();

  const validationScheme = Yup.object({
    unit_id: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    unit_variable_type_id: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    value: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const clearForm = () => {
    const closingCreateDialog = !model.id;
    setTypeOptions([]);
    setUnitId(undefined);
    if (closingCreateDialog && typeof refResetForm.current === "function")
      refResetForm.current();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
  }, []);

  const handleOpen = useCallback(() => {
    if (model.unit_id) setUnitId(model.unit_id);
    setOpen(true);
  }, [model]);

  const onSubmit = useCallback(
    (values, formik) => {
      const vals = model.id ? { ...values, id: model.id } : values;
      new UnitVariablesResource(dispatch, selectedUnitId)
        .save(vals)
        .then(() => {
          clearForm();
          new Growl(dispatch).success(
            "macro.message.success.title",
            "macro.message.success.body"
          );
          loadVariables();
          handleClose();

          if (roomBook)
            new RoomBookController(
              project.slug,
              roomBook.id,
              undefined,
              dispatch
            ).refresh();
          formik.setSubmitting(false);
        })
        .catch(() => {
          formik.setSubmitting(false);
        });
    },
    [selectedUnitId, model]
  );

  useEffect(() => {
    if (!selectedUnitId) {
      setTypeOptions([]);
      return;
    }

    let availableTypes = [];
    if (model.id) {
      availableTypes = unitVariableTypes;
    } else {
      // show unit variable types that don't have already values
      const existingVariables = unitVariables
        .filter(x => x.unit.id === selectedUnitId)
        .map(x => x.unit_variable_type.id);
      availableTypes = unitVariableTypes.filter(
        x => existingVariables.indexOf(x.id) === -1
      );
    }

    const temp = availableTypes.map(type => ({
      key: type.id,
      value: type.id,
      text: type.name
    }));
    setTypeOptions(temp);
  }, [unitVariables, selectedUnitId]);

  return (
    <Formik
      initialValues={initialFormValue}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, resetForm, handleSubmit }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            data-component="UnitVariableTypeDialog"
          >
            <Modal.Header>
              <FormattedMessage id="roomBook.unitVariables.dialog.title" />
            </Modal.Header>
            <Modal.Content>
              <Form id="unitVariablForm">
                <If condition={!!model.unit_id} styles={{ display: "block" }}>
                  <Input
                    readOnly
                    fluid
                    name="displayUnitName"
                    label={intl.formatMessage({
                      id: "roomBook.unitVariables.dialog.unit"
                    })}
                    value={
                      allUnitOptions.find(x => x.key === model.unit_id)?.text
                    }
                  />
                </If>
                <If condition={!model.unit_id}>
                  <Select
                    id="unit_id"
                    errorPrompt
                    name="unit_id"
                    options={allUnitOptions}
                    label={intl.formatMessage({
                      id: "roomBook.unitVariables.dialog.unit"
                    })}
                    onChange={(_, { value }) => setUnitId(value)}
                  />
                </If>

                <If condition={!!model.id} styles={{ display: "block" }}>
                  <Input
                    readOnly
                    fluid
                    name="displayUnitVariable"
                    label={intl.formatMessage({
                      id: "roomBook.unitVariables.dialog.type"
                    })}
                    value={
                      typeOptions.find(
                        x => x.key === model.unit_variable_type_id
                      )?.text
                    }
                  />
                </If>
                <If condition={!model.id}>
                  <Select
                    id="unit_variable_type_id"
                    errorPrompt
                    name="unit_variable_type_id"
                    options={typeOptions}
                    label={intl.formatMessage({
                      id: "roomBook.unitVariables.dialog.type"
                    })}
                  />
                </If>

                <SemanticFormikFloatInput
                  id="value"
                  errorPrompt
                  name="value"
                  label={intl.formatMessage({
                    id: "roomBook.unitVariables.dialog.value"
                  })}
                />
                <If condition={!!model.id}>
                  <Checkbox
                    id="force"
                    name="force"
                    label={intl.formatMessage({
                      id: "project.unit_variables.dialog.force"
                    })}
                  />
                </If>
                <Message warning visible>
                  <Message.Content>
                    <FormattedMessage
                      id="project.unit_variables.dialog.messages.forceInfo"
                      values={{
                        ...defaultRichTextTags
                      }}
                    />
                  </Message.Content>
                </Message>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({
                  id: "meta.actions.cancel"
                })}
                onClick={handleClose}
                loading={isSubmitting}
              />
              <Button
                primary
                id="submit"
                type="submit"
                content={
                  model.id
                    ? intl.formatMessage({ id: "meta.actions.save" })
                    : intl.formatMessage({ id: "meta.actions.add" })
                }
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

UnitVariableDialog.propTypes = {
  button: node.isRequired,
  model: UnitVariableShape.isRequired
};

export default UnitVariableDialog;
