import PropTypes from "prop-types";
import React from "react";
import { Button, Form, Checkbox, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import getContactOptions from "../../helpers/getContactOptions";
import contactsMap from "../../helpers/contactsMap";
import "./timelineFilterBox.scss";

const enhanceWithClickOutside = require("react-click-outside");

class TimelineFilterBox extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    filter: PropTypes.object,
    setTimelineFilter: PropTypes.func,
    contacts: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.handleSetFilter = this.handleSetFilter.bind(this);
    this.handleToggleFilterbox = this.handleToggleFilterbox.bind(this);
    this.handleSetStakeholder = this.handleSetStakeholder.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
    this.state = {
      isVisible: false
    };
  }

  // triggered by higher order component (enhanceWithClickOutside)
  handleClickOutside() {
    this.setState({ isVisible: false });
  }

  handleToggleFilterbox() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  handleSetStakeholder(proxy, data) {
    const { filter, setTimelineFilter } = this.props;
    const stakeholderFilter = {};
    data.value.map(item => {
      const type = item.split("-")[0];
      const id = Number(item.split("-")[1]);
      if (!stakeholderFilter[type]) {
        stakeholderFilter[type] = [];
      }
      stakeholderFilter[type].push(id);
    });
    setTimelineFilter({ ...filter, stakeholder: stakeholderFilter });
  }

  handleSetFilter(proxy, data) {
    const { filter, setTimelineFilter } = this.props;

    setTimelineFilter({ ...filter, [data.name]: data.checked });
  }

  handleResetFilter(event) {
    event?.preventDefault();
    const { setTimelineFilter } = this.props;
    setTimelineFilter(DEFAULT_FILTER);
  }

  handleSetExclusiveFilter(item) {
    const { filter, setTimelineFilter } = this.props;
    setTimelineFilter({
      showFutureEvents: filter.showFutureEvents,
      [item]: true
    });
  }

  render() {
    const { i18n } = this.props;
    const contacts = this.props.contacts && contactsMap(this.props.contacts);

    // set defaults if empty
    let filter;
    if (JSON.stringify(this.props.filter) === "{}") {
      this.handleResetFilter();
      filter = DEFAULT_FILTER;
    } else {
      filter = this.props.filter;
      if (!this.props.filter.showFutureEvents) {
        filter.showFutureEvents = false;
      }
      // filter = Object.assign({}, DEFAULT_FILTER, this.props.filter);
    }

    // contact filter value
    const stakeholderFilterValue = [];
    if (
      filter &&
      filter.stakeholder &&
      JSON.stringify(filter.stakeholder) !== "{}"
    ) {
      Object.keys(filter.stakeholder).map(type => {
        filter.stakeholder[type].map(id => {
          stakeholderFilterValue.push(`${type}-${id}`);
        });
      });
    }

    return (
      <div data-component="timelineFilterBox" className="right floated element">
        <Button
          id="timeline-filter-box-toggle"
          content={i18n["activity.timeline.filter.label"]}
          icon="filter"
          onClick={this.handleToggleFilterbox}
          className={
            JSON.stringify(filter) !== JSON.stringify(DEFAULT_FILTER)
              ? "blue"
              : "basic"
          }
        />
        {this.state.isVisible && (
          <div data-component="timelineFilterBoxMenu">
            <Form>
              <Form.Dropdown
                fluid
                placeholder={i18n["activity.timeline.filter.showStakeholder"]}
                multiple
                search
                selection
                closeOnChange
                value={stakeholderFilterValue}
                onChange={this.handleSetStakeholder}
                options={getContactOptions(contacts)}
              />
              <Form.Field>
                <Checkbox
                  name="showFutureEvents"
                  checked={filter.showFutureEvents}
                  toggle
                  onChange={this.handleSetFilter}
                  label={i18n["activity.timeline.filter.showFutureEvents"]}
                />
              </Form.Field>
              <Form.Field>
                <a
                  className="exclusiveFilter"
                  onClick={() => this.handleSetExclusiveFilter("showDeadlines")}
                >
                  <FormattedMessage
                    id="activity.timeline.filter.exclusive"
                    defaultMessage="activity.timeline.filter.exclusive"
                  />
                </a>
                <Checkbox
                  name="showDeadlines"
                  checked={filter.showDeadlines}
                  toggle
                  label={
                    <label>
                      <Icon name="flag checkered" />{" "}
                      <FormattedMessage
                        id="activity.timeline.filter.showDeadlines"
                        defaultMessage="activity.timeline.filter.showDeadlines"
                      />
                    </label>
                  }
                  onChange={this.handleSetFilter}
                />
              </Form.Field>
              <Form.Field>
                <a
                  className="exclusiveFilter"
                  onClick={() =>
                    this.handleSetExclusiveFilter("showStatusChanges")
                  }
                >
                  <FormattedMessage
                    id="activity.timeline.filter.exclusive"
                    defaultMessage="activity.timeline.filter.exclusive"
                  />
                </a>
                <Checkbox
                  name="showStatusChanges"
                  checked={filter.showStatusChanges}
                  toggle
                  label={
                    <label>
                      <Icon name="check" />{" "}
                      <FormattedMessage
                        id="activity.timeline.filter.showStatusChanges"
                        defaultMessage="activity.timeline.filter.showStatusChanges"
                      />
                    </label>
                  }
                  onChange={this.handleSetFilter}
                />
              </Form.Field>
              <Form.Field>
                <a
                  className="exclusiveFilter"
                  onClick={() =>
                    this.handleSetExclusiveFilter("showAuditEvents")
                  }
                >
                  <FormattedMessage
                    id="activity.timeline.filter.exclusive"
                    defaultMessage="activity.timeline.filter.exclusive"
                  />
                </a>
                <Checkbox
                  id="show-audit-events-filter"
                  name="showAuditEvents"
                  checked={filter.showAuditEvents}
                  toggle
                  label={
                    <label>
                      <Icon name="refresh" />{" "}
                      <FormattedMessage
                        id="activity.timeline.filter.showAuditEvents"
                        defaultMessage="activity.timeline.filter.showAuditEvents"
                      />
                    </label>
                  }
                  onChange={this.handleSetFilter}
                />
              </Form.Field>
              <Form.Field>
                <a
                  className="exclusiveFilter"
                  onClick={() => this.handleSetExclusiveFilter("showMessages")}
                >
                  <FormattedMessage
                    id="activity.timeline.filter.exclusive"
                    defaultMessage="activity.timeline.filter.exclusive"
                  />
                </a>
                <Checkbox
                  name="showMessages"
                  checked={filter.showMessages}
                  toggle
                  label={
                    <label>
                      <Icon name="mail" />{" "}
                      <FormattedMessage
                        id="activity.timeline.filter.showMessages"
                        defaultMessage="activity.timeline.filter.showMessages"
                      />
                    </label>
                  }
                  onChange={this.handleSetFilter}
                />
              </Form.Field>
              <Form.Field>
                <a
                  className="exclusiveFilter"
                  onClick={() => this.handleSetExclusiveFilter("showMemos")}
                >
                  <FormattedMessage
                    id="activity.timeline.filter.exclusive"
                    defaultMessage="activity.timeline.filter.exclusive"
                  />
                </a>
                <Checkbox
                  name="showMemos"
                  checked={filter.showMemos}
                  toggle
                  label={
                    <label>
                      <Icon name="chat" />
                      <Icon name="phone" />{" "}
                      <FormattedMessage
                        id="activity.timeline.filter.showMemos"
                        defaultMessage="activity.timeline.filter.showMemos"
                      />
                    </label>
                  }
                  onChange={this.handleSetFilter}
                />
              </Form.Field>
              <Form.Field>
                <Button
                  fluid
                  basic
                  className="resetFilter"
                  onClick={this.handleResetFilter}
                >
                  <Icon name="remove" />
                  <FormattedMessage
                    id="activity.timeline.filter.reset"
                    defaultMessage="activity.timeline.filter.reset"
                  />
                </Button>
              </Form.Field>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default enhanceWithClickOutside(TimelineFilterBox);

export const DEFAULT_FILTER = {
  showFutureEvents: false,
  showDeadlines: false,
  showStatusChanges: true,
  showAuditEvents: false,
  showMemos: true,
  showMessages: true
};
