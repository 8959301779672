import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Header, Select } from "semantic-ui-react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { ActivitiesResource } from "../../actions/activityActions";
import MessagesResource from "../../actions/messagesActions";

class MessageActivityPicker extends Component {
  static propTypes = {
    activitiesResource: PropTypes.instanceOf(ActivitiesResource).isRequired,
    messagesResource: PropTypes.instanceOf(MessagesResource).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      search: true,
      value: null,
      options: []
    };
  }

  componentDidMount() {
    const { activitiesResource, projectId, unitId } = this.props;
    activitiesResource.fetchAll({ project: projectId, unit: unitId });
  }

  handleChange = (e, { value }) => {
    const { messageThread } = this.props;
    this.setActivity(messageThread, value);
    this.setState({ value });
  };

  render() {
    const { isFetching, value } = this.state;
    const { placeholder } = this.props;

    return (
      <Form>
        <Form.Field
          control={Select}
          fluid
          search
          selectOnBlur={false}
          selectOnNavigation={false}
          options={this.fetchActivities()}
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          loading={isFetching}
        />
      </Form>
    );
  }

  fetchActivities() {
    const { pageContent } = this.props;

    return get(pageContent, "activities", []).map(activity => {
      return {
        key: activity.id,
        value: activity.id,
        text: `${activity.display_name},${activity.title}`,
        content: (
          <Header
            as="h5"
            content={activity.display_name}
            subheader={activity.title}
          />
        )
      };
    });
  }

  async setActivity(messageThread, activityId) {
    const { messagesResource } = this.props;

    await messagesResource.save({ ...messageThread, activity_id: activityId });
  }
}

const mapStateToProps = state => {
  return {
    pageContent: state.pageContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    activitiesResource: new ActivitiesResource(dispatch),
    messagesResource: new MessagesResource(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageActivityPicker);
