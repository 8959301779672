export const tabs = {
  Visual: "visual",
  Json: "json"
};

export const signatureElementTypes = ["text", "signature"];

// empty elements dfinitions
export const heading1Element = {
  type: "text",
  value: "New element",
  style: "heading1",
  new_line: 1
};

export const heading2Element = {
  type: "text",
  value: "New element",
  style: "heading2",
  new_line: 1
};

export const textElement = {
  type: "text",
  value: "New element",
  style: "body",
  new_line: 1
};

export const signatureElement = {
  type: "signature",
  template: 1,
  variable_1: "variable_1",
  variable_2: "variable_2",
  new_line: 1
};

// perhaps in future backend could return list of templates and number of variables
export const templateOptions = [1, 2, 3, 4, 5, 6, 7, 8].map(t => ({
  key: t,
  value: t,
  text: `Template ${t}`
}));

export const templateVariables = [2, 1, 2, 2, 4, 2, 1];
