/**
 * like a basic String.prototype.replace but with starting Index.
 *
 * @param {string}  source        The String where replacement is done, will not be mutated.
 * @param {string}  searchValue   A string that is to be replaced by `replaceValue`.
 * @param {string}  replaceValue  The String that replaces the substring specified by the `searchValue` parameter.
 * @param {number}  fromIndex     An integer representing the index at which to start the search. Defaults to 0.
 *
 * @return {string} A new string, with the first match replaced by the replacement.
 */
export function replaceAt(source, searchValue, replaceValue, fromIndex) {
  return (
    source.substr(0, fromIndex) +
    source.substr(fromIndex).replace(searchValue, replaceValue)
  );
}

export default replaceAt;
