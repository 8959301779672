import { compact, sortBy } from "lodash";

export default class Branding {
  constructor(data) {
    this.data = data;

    const sections = data.sections || [];

    this.imageMap = sections.reduce((accu, section) => {
      accu[section.id] = section.thumb_url || data.default_thumb_url;
      return accu;
    }, {});

    this.groupMap = sections.reduce((accu, section) => {
      accu[section.id] = section.group;
      return accu;
    }, {});

    this.visibilityMap = sections.reduce((accu, section) => {
      accu[section.id] = JSON.parse(section.visible || "false");
      return accu;
    }, {});

    this.productLines = data.product_lines || [];

    const configuratorsData = Object.entries(
      data.configurators || {}
    ).map(([id, configurator]) => [id, { ...configurator, id }]);

    this.productMapping = data.product_mapping || {};

    const orderdConfiguratorsData = sortBy(
      configuratorsData,
      ([, { order }]) => order
    );

    this.configurators = new Map(orderdConfiguratorsData);

    this.configuratorMap = sections.reduce((accu, section) => {
      const configuratorId = section.configurator;
      if (this.configurators.has(configuratorId)) {
        accu[section.id] = configuratorId;
      }
      return accu;
    }, {});

    this.glencoeURL = data.glencoeURL;
  }

  isVisible(sectionPath) {
    return this.visibilityMap[this.getSelector(sectionPath)];
  }

  getImageUrl(sectionPath) {
    return this.imageMap[this.getSelector(sectionPath)];
  }

  getGroup(sectionPath) {
    return this.groupMap[this.getSelector(sectionPath)];
  }

  getConfiguratorId(sectionPath) {
    return this.configuratorMap[this.getSelector(sectionPath)];
  }

  getSelector(sectionPath) {
    return compact(sectionPath)
      .map(s => {
        return s.title;
      })
      .join("::");
  }

  getConfigurators() {
    return this.configurators;
  }

  getProductMapping() {
    return this.productMapping;
  }

  getProductLines() {
    return this.productLines;
  }

  getGlencoeURL() {
    return this.glencoeURL;
  }
}
