import React from "react";
import { Header } from "semantic-ui-react";

export const getTagOptions = taxonomies => {
  return taxonomies.reduce((accu, taxonomy) => {
    return accu.concat(
      taxonomy.tags.map(tag => {
        return {
          key: tag.composite_id,
          text: tag.name,
          value: tag.composite_id,
          content: (
            <Header as="h5">
              <Header.Content>
                {tag.name}
                <Header.Subheader>{taxonomy.name}</Header.Subheader>
              </Header.Content>
            </Header>
          )
        };
      })
    );
  }, []);
};
