import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getFeatureToggles } from "shared/selectors";
import { useFeatureToggleContext } from "./FeatureToggleProvider";

const FeatureToggle = ({
  featureToggleName,
  children,
  featureToggles: stateFeatureToggles,
  disabled,
  featureTogglesArray,
  any,
  ignore
}) => {
  const { featureToggles: contextFeatureToggles } = useFeatureToggleContext();
  const [isFeatureVisible, setFeatureVisible] = useState(false);
  const featureToggles = contextFeatureToggles || stateFeatureToggles;
  useEffect(() => {
    if (any && featureToggles) {
      const atLeastOne = featureTogglesArray.some(ft => {
        return featureToggles[ft];
      });
      setFeatureVisible(atLeastOne);
    } else if (featureToggles)
      setFeatureVisible(featureToggles[featureToggleName]);
  }, [featureToggles]);

  if (ignore) return <>{children}</>;

  if (disabled) {
    return !isFeatureVisible ? <>{children}</> : null;
  }
  return isFeatureVisible ? <>{children}</> : null;
};

FeatureToggle.propTypes = {
  featureToggleName: PropTypes.string,
  featureTogglesArray: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  featureToggles: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  any: PropTypes.bool,
  ignore: PropTypes.bool
};

FeatureToggle.defaultProps = {
  children: null,
  disabled: false,
  any: false,
  featureTogglesArray: [],
  featureToggleName: undefined,
  ignore: false
};

const mapStateToProps = state => {
  return {
    featureToggles: getFeatureToggles(state)
  };
};

export default connect(mapStateToProps)(FeatureToggle);
