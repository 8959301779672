import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  Button,
  Header,
  Message,
  Dropdown
} from "semantic-ui-react";
import DateInput from "shared/components/forms/DateInput";
import { getProject } from "shared/selectors";
import { getSections } from "shared/selectors/units";
import { getUnitFeatures } from "builder_portal/selectors/unitFeatureGroups";
import { getRoomBooks } from "shared/selectors/roomBooks";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import ProjectBuyersResource from "builder_portal/actions/buyerActions";
import { If } from "shared/components/elements/Conditions";
import localStorage from "shared/helpers/localStorage";
import { useIntl, FormattedMessage } from "react-intl";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import { node, func } from "prop-types";
import { CustomImportConfigsShape } from "shared/shapes/customImportConfigs.shape";
import FileUploader from "../../dropzone/FileUploader";
import FileImportsResource from "../../../actions/fileImportsActions";

const STATUS_CHECK_INTERVAL = 10_000;
const NUMBER_OF_POLLING_CYCLES = 60;
const EMPTY_MODEL = { file: null };

const CustomUploadModal = ({ button, onCompleted, config }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const project = useSelector(getProject);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [model, setModel] = useState(EMPTY_MODEL);
  const [selectedValues, setValues] = useState({});

  const sections = useSelector(getSections);
  const roomBooks = useSelector(getRoomBooks);
  const unitFeatures = useSelector(getUnitFeatures);

  const required = useMemo(
    () => config.arguments.filter(a => !a.provided).map(a => a.id),
    [config]
  );

  const hideSections = useMemo(
    () => !!config.arguments.find(a => a.data_type === "section")?.provided,
    [config]
  );
  const hideRoomBooks = useMemo(
    () => !!config.arguments.find(a => a.data_type === "room_book")?.provided,
    [config]
  );
  const hideUnitFeatures = useMemo(
    () =>
      !!config.arguments.find(a => a.data_type === "unit_features")?.provided,
    [config]
  );

  const sectionOptions = useMemo(
    () =>
      hideSections
        ? []
        : sections.map(section => ({
            key: section.id,
            value: section.id,
            text: `${section.code}: ${section.name}`
          })),
    [sections]
  );

  const roomBookOptions = useMemo(
    () =>
      hideRoomBooks
        ? []
        : roomBooks.map(data => ({
            key: data.id,
            value: data.id,
            text: data.name
          })),
    [sections]
  );

  const unitFeatureOptions = useMemo(
    () =>
      hideUnitFeatures
        ? []
        : unitFeatures.map(data => ({
            key: data.id,
            value: data.id,
            text: data.name
          })),
    [sections]
  );

  const handleOpen = () => {
    setError("");
    setOpen(true);
    setValues({});
  };

  const handleClose = () => {
    setOpen(false);
    setModel(EMPTY_MODEL);
  };

  const handleFile = file => {
    setModel(currentModel => ({
      ...currentModel,
      file
    }));
    setError("");
  };

  const afterPolling = () => {
    new UnitsResource(dispatch, project.id).fetchAll();
    new ProjectsResource(dispatch).fetchStructure(project.id);
    new ProjectBuyersResource(dispatch, project.id).fetchAll();
    if (typeof onCompleted === "function") {
      onCompleted();
    }
    handleClose();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    NUMBER_OF_POLLING_CYCLES,
    afterPolling
  );

  const customValidation = () => {
    let isValid = true;
    const { file } = model;

    if (!file) {
      setError(
        intl.formatMessage({
          id: "project.unit_variables.dialog.messages.selectFile"
        })
      );
      isValid = false;
    }
    if (!isValid) return false;

    required.forEach(requiredField => {
      if (!isValid) return;
      if (!selectedValues[requiredField]) {
        setError(
          intl.formatMessage({
            id: "unit.actions.upload.customDialogError"
          })
        );
        isValid = false;
      }
    });
    return isValid;
  };

  const handleUpload = () => {
    const formData = new FormData();
    const { file } = model;

    if (!customValidation()) return;
    setError("");

    // add expected name
    formData.append(`projects[file]`, file);

    Object.keys(selectedValues).map(id =>
      formData.append(
        `projects[custom_import_config][${id}]`,
        selectedValues[id]
      )
    );

    formData.append(
      `projects[custom_import_config][importer]`,
      config.importer
    );
    formData.append(`projects[custom_import_config][scope]`, config.scope);

    fetch(`/api/v1/projects/${project.slug}/trigger_custom_imports`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.id) {
          startPolling(res.id, STATUS_CHECK_INTERVAL);
        }
      })
      .catch(() => {
        stopPolling();
        setError(
          intl.formatMessage({
            id: "unit.actions.upload.error"
          })
        );
      });
  };

  const getOptions = dataType => {
    if (dataType === "section") return sectionOptions;
    if (dataType === "room_book") return roomBookOptions;
    if (dataType === "unit_features") return unitFeatureOptions;
    return [];
  };

  const renderField = field => {
    if (field.data_type === "date") {
      return (
        <DateInput
          id={field.id}
          name={field.id}
          fluid
          type="date"
          onChange={(_, { value }) =>
            setValues({ ...selectedValues, [field.id]: value })
          }
          value={field.value}
          nullable
        />
      );
    }
    return (
      <Dropdown
        selection
        name={field.id}
        multiple={field.multiple}
        options={getOptions(field.data_type)}
        onChange={(_, { value }) =>
          setValues({ ...selectedValues, [field.id]: value })
        }
      />
    );
  };

  return (
    <>
      <Modal
        closeIcon
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        trigger={button}
      >
        <Modal.Header>
          <Header as="h3">{config.dialog.title}</Header>
        </Modal.Header>
        <Modal.Content>
          <Message error content={error} hidden={!error} />
          <Form>
            {config.arguments.map(field => (
              <If condition={!field.provided} key={field.id}>
                <Form.Field key={field.id}>
                  <Header as="h5">{field.label}</Header>
                  {renderField(field)}
                </Form.Field>
              </If>
            ))}
            <If condition={!model.file}>
              <FileUploader handleFile={handleFile} fileType="excel" />
            </If>
            <If condition={!!model.file}>
              <Header as="h5">{model.file?.path}</Header>
            </If>
            <a
              rel="noreferrer"
              target="_blank"
              href={`/api/v1/projects/${project.id}/download_example_file.xlsx?projects[custom_import_config][importer]=${config.importer}&projects[custom_import_config][scope]=${config.scope}`}
            >
              <FormattedMessage
                id="unit.actions.customUpload.link.label"
                default="Download example file"
              />
            </a>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            id="close"
            content={intl.formatMessage({ id: "meta.actions.close" })}
            onClick={handleClose}
          />
          <Button
            primary
            id="submit"
            content={intl.formatMessage({ id: "attachment.actions.upload" })}
            onClick={handleUpload}
            disabled={isPolling}
          />
        </Modal.Actions>
      </Modal>
      <TinyLoader opened={isPolling} />
    </>
  );
};

CustomUploadModal.propTypes = {
  button: node.isRequired,
  onCompleted: func,
  config: CustomImportConfigsShape.isRequired
};

CustomUploadModal.defaultProps = {
  onCompleted: () => {}
};

export default CustomUploadModal;
