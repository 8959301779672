import { string, number, shape } from "prop-types";

export const ProductCatalogShape = shape({
  id: number,
  name: string,
  description: string,
  project_catalogs_count: number,
  products_count: number,
  account_id: number
});

export default ProductCatalogShape;
