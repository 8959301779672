import { get, keyBy, uniq, map, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getAccountProjects } from "shared/selectors/account";
import { getPageContent } from "shared/selectors/base";

const getDocumentAssignments = createSelector([getPageContent], pageContent => {
  return sortBy(get(pageContent, "document_export_assignments", []), [
    "projectName",
    "role"
  ]);
});

export const getDocumentExports = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "document_exports", []), "name");
  }
);

export const getDocumentExportsMetadata = createSelector(
  [getPageContent],
  pageContent => {
    return {
      validations: get(pageContent, "validations", []),
      conditions: get(pageContent, "conditions", []),
      styles: get(pageContent, "styles", [])
    };
  }
);

export const getDocumentStyles = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(
      get(
        pageContent,
        ["document_export_styles", "document_export_styles"],
        []
      ),
      "name"
    );
  }
);

export const getDocumentStyleVariables = createSelector(
  [getPageContent],
  pageContent => {
    return get(
      pageContent,
      ["document_export_metadata", "document_export_style_variables"],
      []
    );
  }
);

export const getDocumentExportTextKeys = createSelector(
  [getPageContent],
  pageContent => {
    return get(
      pageContent,
      ["document_export_metadata", "document_export_text_keys"],
      []
    );
  }
);

export const getDocumentExportSettingFields = createSelector(
  [getPageContent],
  pageContent => {
    return get(
      pageContent,
      ["document_export_metadata", "document_export_setting_fields"],
      []
    );
  }
);

export const getDocumentExportStyleSettingFields = createSelector(
  [getPageContent],
  pageContent => {
    return get(
      pageContent,
      ["document_export_metadata", "document_export_style_setting_fields"],
      []
    );
  }
);

export const getDocumentSignatures = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "document_export_signatures", []), "name");
  }
);

export const getDocumentAssignmentsList = createSelector(
  [
    getDocumentAssignments,
    getDocumentExports,
    getDocumentStyles,
    getDocumentSignatures,
    getAccountProjects
  ],
  (assignments, exports, styles, signatures, projects) => {
    if (!assignments || !exports || !styles || !projects?.length) return [];

    const exportsById = keyBy(exports, "id");
    const stylesById = keyBy(styles, "id");
    const signaturesById = keyBy(signatures, "id");
    const projectsById = keyBy(projects, "id");

    const data = [];

    assignments.forEach(x => {
      const docexp = exportsById[x.document_export_id];
      const style = stylesById[x.document_export_style_id];
      const signature = signaturesById[x.document_export_signature_id];
      const contentHeader = signaturesById[x.document_export_content_header_id];
      const project = projectsById[x.project_id];

      if (docexp && style && project) {
        data.push({
          ...x,
          projectName: project.name,
          exportName: docexp.name,
          group: docexp.group,
          role: docexp.role,
          styleName: style.name,
          contentHeaderName: contentHeader?.name || "",
          signatureName: signature?.name || ""
        });
      }
    });
    return sortBy(data, ["projectName", "role"]);
  }
);

export const getGroups = ["room_book_carts", "room_books"];

export const getRoles = ["offer", "order", "enquiry", "roombook", "catalog"];

export const assignmentColumns = [
  { id: "documentsExport.filter.project", propName: "projectName" },
  { id: "attachment.attributes.role.label", propName: "role" },
  { id: "documentsExport.menu.exports.title", propName: "exportName" },
  { id: "documentsExport.menu.styles.title", propName: "styleName" }
];

export const exportColumns = [
  { id: "documentsExport.menu.exports.title", propName: "name" },
  { id: "attachment.attributes.role.label", propName: "role" }
];

export const styleColumns = [
  { id: "documentsExport.menu.styles.title", propName: "name" }
];

export const signatureColumns = [
  { id: "documentsExport.menu.signatures.assignmentsTitle", propName: "name" }
];

const getOptions = (list, column) => {
  const options = {};
  column.forEach(col => {
    const columnOptions = uniq(map(list, col.propName))
      .map(x => ({ key: x, value: x, text: x }))
      .filter(x => !!x.value);

    options[col.propName] = columnOptions;
  });
  return options;
};

export const getAssignmentFilterOptions = createSelector(
  [getDocumentAssignmentsList],
  list => {
    return getOptions(list, assignmentColumns);
  }
);

export const getExportFilterOptions = createSelector(
  [getDocumentExports],
  list => {
    return getOptions(list, exportColumns);
  }
);
