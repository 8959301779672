import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { PriceCatalogShape, RoomBookShape } from "shared/shapes";
import { keyBy } from "lodash";
import RoomBookController from "../../../controllers/roomBook/roomBookController";

const ChangePriceCatalogButton = ({ ctrl, roomBook, priceCatalogs }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceCatalogId, setPriceCatalogId] = useState(
    roomBook.price_catalog_id
  );

  const priceCatalogsById = useMemo(() => {
    return keyBy(priceCatalogs, c => c.id);
  }, [priceCatalogs]);

  const currentPriceCatalog = useMemo(() => {
    return priceCatalogsById[roomBook.price_catalog_id];
  }, [roomBook, priceCatalogsById]);

  const dropDownOptions = useMemo(() => {
    return priceCatalogs.map(c => ({
      value: c.id,
      key: c.id,
      text: `${c.name} ${
        roomBook.price_catalog_id === c.id ? " (aktuell)" : ""
      }`
    }));
  }, [roomBook, priceCatalogs]);

  const handleOpen = useCallback(async () => {
    setOpen(true);
  });

  const handleClose = useCallback(async () => {
    setOpen(false);
  });

  const handleChange = useCallback(
    async (_, { value }) => {
      setPriceCatalogId(value);
    },
    [setPriceCatalogId]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    ctrl
      .updateRoomBook({ id: roomBook.id, price_catalog_id: priceCatalogId })
      .then(() => {
        setOpen(false);
        setLoading(false);
      });
  }, [setLoading, ctrl, roomBook, priceCatalogId]);

  return (
    <Modal
      size="mini"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      closeIcon
      trigger={
        <Button
          id="change-price-catalog"
          icon="barcode"
          content={currentPriceCatalog.name}
        />
      }
    >
      <Modal.Header>Preisliste wechseln</Modal.Header>
      <Modal.Content>
        <Dropdown
          id="price-catalogs"
          fluid
          selection
          options={dropDownOptions}
          value={priceCatalogId}
          onChange={handleChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="submit-price-catalog"
          color="red"
          onClick={handleSubmit}
          loading={loading}
          content="Ändern"
        />
      </Modal.Actions>
    </Modal>
  );
};

ChangePriceCatalogButton.propTypes = {
  ctrl: PropTypes.instanceOf(RoomBookController).isRequired,
  priceCatalogs: PropTypes.arrayOf(PriceCatalogShape).isRequired,
  roomBook: RoomBookShape.isRequired
};

export default ChangePriceCatalogButton;
