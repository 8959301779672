import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Button, Modal, Header, Icon, Table } from "semantic-ui-react";

import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import AttachmentDialog from "./AttachmentDialog";
import VersionHistoryButton from "../buttons/VersionHistoryButton";
import { getAccount } from "../../../shared/selectors";
import { Account } from "../../../shared/models/account";
import AttachmentVisibilityModal from "./AttachmentVisibilityModal";

class VersionsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleDialog = value => {
    this.setState({ open: value });
  };

  renderTrigger = () => {
    return (
      <VersionHistoryButton
        {...this.props.triggerProps}
        onClick={() => this.toggleDialog(!this.state.open)}
      />
    );
  };

  render() {
    const { account, attachments, dialogProps } = this.props;
    const {
      handleUpdate,
      context,
      i18n,
      handleRemove,
      destructionMode
    } = dialogProps;
    const { open } = this.state;
    return (
      <Modal
        trigger={this.renderTrigger()}
        open={open}
        onOpen={() => this.toggleDialog(true)}
        onClose={() => this.toggleDialog(false)}
        closeIcon
        closeOnEscape
        closeOnDimmerClick
      >
        <Modal.Header>
          <Icon name="history" />
          <FormattedMessage id="attachment.hints.version_history" />:{" "}
          {attachments[0].display_name}
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Body>
              {attachments.map(item => {
                return (
                  <Table.Row
                    key={`document-${item.id}`}
                    data-model="attachment"
                    data-role={item.role}
                  >
                    <Table.Cell>
                      <a
                        href={item.attachment_url}
                        key={item.id}
                        target={`document-${item.id}`}
                      >
                        <Header as="h4" image>
                          <Icon name="file text outline" size="large" />
                          <Header.Content>
                            <Header.Subheader>
                              {moment(item.created_at).format("LLL")}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </a>
                    </Table.Cell>
                    <Table.Cell>
                      <AttachmentVisibilityModal
                        account={account}
                        attachment={item}
                        onUpdate={handleUpdate}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      <AttachmentDialog
                        account={context.account}
                        i18n={i18n}
                        attachment={item}
                        button={
                          <a id="edit-attachment" role="button">
                            <Icon name="setting" color="grey" size="large" />
                          </a>
                        }
                        onUpdate={handleUpdate}
                        onRemove={handleRemove}
                        destructionMode={destructionMode}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Schließen"
            color="grey"
            onClick={() => this.toggleDialog(false)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: getAccount(state)
  };
};

VersionsDialog.defaultProps = {
  account: null
};

VersionsDialog.propTypes = {
  account: instanceOf(Account)
};

export default connect(mapStateToProps)(VersionsDialog);
