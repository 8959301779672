import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import { getDerivedIds } from "builder_portal/selectors/roomBooks/descendantsInfo";
import toggleDialogCreator from "../../helpers/toggleDialogCreator";
import LineItemDialog from "./LineItemDialog";
import LineItemModel from "../../models/roomBook/LineItemModel";

class LineItemDialogContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dialogModel,
      i18n,
      toggleDialog,
      ctrl,
      derivedIds,
      template
    } = this.props;
    if (dialogModel) {
      return (
        <LineItemDialog
          i18n={i18n}
          isVisible
          toggleDialog={toggleDialog}
          model={dialogModel.model}
          lineItem={new LineItemModel(dialogModel.model, template)}
          ctrl={ctrl}
          derivedIds={derivedIds}
        />
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, props) => {
  const { module, id } = props;

  return {
    i18n: state.i18n,
    dialogModel: get(state, ["dialog", module, id, "lineItemDialog"]),
    derivedIds: getDerivedIds(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { module, id } = props;
  return {
    toggleDialog: toggleDialogCreator(dispatch, id, module)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemDialogContainer);
