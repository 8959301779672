import { object } from "prop-types";

/*
TODO migration plan
1. replace the "object" with a comprehensive shape in your proptypes!
2. Use DEPRECATED_PageContentShape only when pageContent is passed into children components without actual use
*/

export const DEPRECATED_PageContentShape = object;

export default DEPRECATED_PageContentShape;
