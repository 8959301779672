import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import { Grid, Header, Icon, Label, Table, Message } from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import FormatQuantity from "shared/components/quantity/FormatQuantity";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import SubLineItemModel from "builder_portal/models/roomBook/SubLineItemModel";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import { uniqBy } from "lodash";
import ContractorLabel from "../../../shared/components/elements/contractorLabel";
import { tradeLabel } from "../../helpers/tradeLabel";
import LineItemStatusLabel from "../roomBook/LineItemStatusLabel";

import "./cart.scss";
import CartRemover from "./CartRemover";

class CartContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isNetAccounting = () => {
    const { ctxModel } = this.props;
    const accountingMode = ctxModel.getAccountingMode();
    return accountingMode === "net";
  };

  getCartTotalCosts = cart => {
    if (this.isNetAccounting()) {
      return cart.total_costs;
    }
    return cart.gross_total_costs;
  };

  getDisplayCosts = lineItem => {
    if (lineItem.cancellation) {
      if (this.isNetAccounting()) {
        return -lineItem.cancellation_total_costs;
      }
      return -lineItem.gross_cancellation_total_costs;
    }
    if (this.isNetAccounting()) {
      return lineItem.total_costs;
    }
    return lineItem.gross_total_costs;
  };

  getDisplayTotal = lineItem => {
    if (lineItem.cancellation) {
      return -lineItem.cancellation_total;
    }
    return lineItem.total;
  };

  getCancellationTotalBaseCost = lineItem => {
    if (this.isNetAccounting()) {
      return lineItem.cancellation_total_base_costs;
    }
    return lineItem.gross_cancellation_total_base_costs;
  };

  getReplacementDefaultCost = lineItem => {
    if (this.isNetAccounting()) {
      return lineItem.replacement_info.default_costs;
    }
    return lineItem.replacement_info.gross_default_costs;
  };

  handleUpdate() {
    const { handleUpdate } = this.props;
    if (handleUpdate) {
      return handleUpdate();
    }
    return null;
  }

  toggleLineItemDialog(values) {
    const { model, context, toggleDialog, handleUpdate } = this.props;

    toggleDialog("lineItemDialog", {
      context,
      model: values || model,
      handleUpdate
    });
  }

  toggleEditorDialog(model, products) {
    const { ctxModel, context, toggleDialog, handleUpdate } = this.props;

    const trades = ctxModel.getTrades();
    const attachments = ctxModel.getAttachments();
    const isTemplate = false;

    toggleDialog("subLineItemDialog", {
      context,
      featureToggles: {},
      trades,
      attachments,
      isTemplate,
      handleUpdate,
      model,
      products,
      autoFocus: !model.product_group_id
    });
  }

  renderPrimarySection(section, productsRepository) {
    const tableParts = [];

    return section.sections.reduce((accu, subsection) => {
      return accu.concat(
        this.renderSecondarySection(section, subsection, productsRepository)
      );
    }, tableParts);
  }

  renderSecondarySection(parent, section, productsRepository) {
    const { ctxModel } = this.props;
    const accountingMode = ctxModel.getAccountingMode();
    const tableParts = [];

    tableParts.push(
      <Table.Header key={`secondary-section-${section.id}`}>
        <Table.Row>
          <Table.HeaderCell colSpan={3} style={{ backgroundColor: "#EEE" }}>
            {section.display_number} {parent.title} / {section.title}
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="right"
            style={{ backgroundColor: "#EEE" }}
          >
            <FormattedMessage
              id={`roomBook.carts.tableHeaders.costs.${accountingMode}`}
              defaultMessage={`roomBook.carts.tableHeaders.costs.${accountingMode}`}
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="right"
            style={{ backgroundColor: "#EEE" }}
          >
            <FormattedMessageWithFlag
              featureToggleName="show_price_net_label"
              id="roomBook.carts.tableHeaders.price.net"
              alternativeId="roomBook.carts.tableHeaders.price.gross"
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );

    const lineItems = section.line_items.reduce((accu, item) => {
      return accu.concat(this.renderLineItem(item, productsRepository));
    }, []);

    if (lineItems.length === 0) {
      tableParts.push(
        <Table.Body
          key={`secondary-section-${section.id}-line-items`}
          data-model="line_item"
        >
          <Table.Row>
            <Table.Cell colSpan={16} textAlign="center">
              <Header as="h5" style={{ padding: 16 }}>
                <FormattedMessage id="activity.message.noLineItemsForThisFilter.body" />
              </Header>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
      return tableParts;
    }

    tableParts.push(
      <Table.Body
        key={`secondary-section-${section.id}-line-items`}
        data-model="line_item"
      >
        {lineItems}
      </Table.Body>
    );

    return tableParts;
  }

  renderLineItem(lineItem, productsRepository) {
    const { toggleDialog } = this.props;

    const segments = [
      <Table.Row
        key={`line-item-${lineItem.id}`}
        style={{ backgroundColor: "#F9FAFB" }}
      >
        <Table.Cell colSpan={2} style={{ fontWeight: "bold" }}>
          <a
            role="button"
            className="editLineItem titleButton"
            onClick={() => {
              this.toggleLineItemDialog(lineItem);
            }}
          >
            <span data-attr="display_number">{lineItem.display_number}</span>
            &nbsp;
            {lineItem.title}&nbsp;
            <Icon name="setting" color="grey" />
          </a>
        </Table.Cell>
        <Table.Cell textAlign="right">
          {this.renderUnlinkButton(lineItem)}
        </Table.Cell>
        <Table.Cell textAlign="right" style={{ fontWeight: "bold" }}>
          <FormatCurrency amount={this.getDisplayCosts(lineItem)} />
        </Table.Cell>
        <Table.Cell textAlign="right" style={{ fontWeight: "bold" }}>
          <FormatCurrency amount={this.getDisplayTotal(lineItem)} />
        </Table.Cell>
      </Table.Row>
    ];

    if (
      lineItem.cancellation &&
      lineItem.cancellation_contactor_mode === "skip"
    ) {
      segments.push(
        <Table.Row key={`line-item-${lineItem.id}-cancellation`}>
          <Table.Cell colSpan={5}>
            <Header as="h5">
              <Header.Content>
                <FormattedMessage
                  id="roomBook.lineItems.messages.skipContractorCancellationInfo"
                  defaultValue="roomBook.lineItems.messages.skipContractorCancellationInfo"
                />
              </Header.Content>
            </Header>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (lineItem.optional || lineItem.cancellation) {
      segments.push(this.renderBaseTotalItem(lineItem));
    }

    const cancellationItems = lineItem.cancellation_items.reduce(
      (accu, subLineItem) => {
        const model = { ...subLineItem, status_info: lineItem.status_info };
        return accu.concat(
          this.renderSubLineItem(model, productsRepository, "cancellation")
        );
      },
      []
    );

    const subLineItems = lineItem.sub_line_items.reduce((accu, subLineItem) => {
      const model = { ...subLineItem, status_info: lineItem.status_info };
      return accu.concat(this.renderSubLineItem(model, productsRepository));
    }, []);

    return segments
      .concat(cancellationItems)
      .concat(subLineItems)
      .concat([
        <Table.Row key={`line-item-${lineItem.id}-actions`}>
          <Table.Cell colSpan={5}>
            <Grid>
              <Grid.Column width={9}>{this.renderTrades(lineItem)}</Grid.Column>
              <Grid.Column width={7} textAlign="right" verticalAlign="middle">
                <LineItemStatusLabel
                  model={lineItem}
                  onClick={() =>
                    toggleDialog("lineItemTimelineDialog", lineItem)
                  }
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      ]);
  }

  renderWarning = translationId => (
    <Message color="yellow" icon>
      <Icon name="warning circle" style={{ fontSize: "1.2rem" }} />
      <Message.Content>
        <FormattedMessage id={translationId} />
      </Message.Content>
    </Message>
  );

  checkForPriceCostDiverge = (item, products) => {
    const subLineItemModel = new SubLineItemModel(item, products, false);
    const showWarning =
      subLineItemModel.isPriseCostDiverged() &&
      !item.custom_costs_and_prices_confirmed;

    if (!showWarning) return null;

    return (
      <Table.Row>
        <Table.Cell width={16} colSpan={5}>
          {this.renderWarning("roomBook.lineItems.messages.priseCostDiverged")}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderSubLineItem(subLineItem, productsRepository, nature = "properItem") {
    let product;
    let productCollection;

    if (subLineItem.product_group_id) {
      product = productsRepository.getProduct(subLineItem.product_id);
      productCollection = productsRepository.getGroupProducts(
        subLineItem.product_group_id
      );
    }

    const isCancellation = nature === "cancellation";
    const modifier = isCancellation ? -1 : 1;
    const costs = this.isNetAccounting()
      ? subLineItem.total_costs
      : subLineItem.gross_total_costs;

    return [
      <Table.Row
        key={`sub-line-item-${subLineItem.id}`}
        data-model="sub_line_item"
      >
        <Table.Cell
          colSpan={2}
          style={{ height: "50px" }}
          verticalAlign="middle"
        >
          {!isCancellation && (
            <a
              role="button"
              className="editLineItem titleButton"
              onClick={() => {
                this.toggleEditorDialog(subLineItem, productCollection);
              }}
            >
              <span data-attr="display_number">
                {subLineItem.display_number}
              </span>{" "}
              {subLineItem.title} <Icon name="setting" color="grey" />
            </a>
          )}
          {isCancellation && (
            <span style={{ textDecoration: "line-through" }}>
              <span data-attr="display_number">
                {subLineItem.display_number}
              </span>{" "}
              {subLineItem.title}
            </span>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right" />
        <Table.Cell textAlign="right">
          <FormatCurrency amount={modifier * costs} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <FormatCurrency amount={modifier * subLineItem.total} />
        </Table.Cell>
      </Table.Row>,
      <Table.Row
        key={`sub-line-item-${subLineItem.id}-description`}
        data-model="sub_line_item"
      >
        <Table.Cell colSpan={5} style={{ borderTop: "none" }}>
          <p>{this.renderLineItemDescription(subLineItem, product)}</p>
        </Table.Cell>
      </Table.Row>,
      <FeatureToggle
        featureToggleName="show_additional_price_cost_diverge"
        key={`sub-line-item-warning-${subLineItem.id}`}
      >
        {this.checkForPriceCostDiverge(subLineItem, productCollection)}
      </FeatureToggle>
    ];
  }

  renderTrades(lineItem) {
    const { ctxModel } = this.props;

    const trades = ctxModel.getTrades();

    let contractors = [];
    const unassignedTrades = [];
    lineItem.trades.forEach(trade => {
      const tradeContractors = ctxModel.getContractorsByTrade(trade);
      if (!tradeContractors.length) {
        unassignedTrades.push(trade);
      } else {
        contractors = contractors.concat(tradeContractors);
      }
    });

    return (
      <div>
        {uniqBy(contractors, "id").map(contractor => {
          return (
            <ContractorLabel key={contractor.id} contractor={contractor} />
          );
        })}
        {unassignedTrades.map(trade => {
          return (
            <Label
              key={trade}
              basic
              icon="paper hand"
              content={tradeLabel(trade, trades)}
              color="yellow"
            />
          );
        })}
        {!lineItem.trades.length && (
          <Label
            key="no-trade"
            basic
            icon="paper hand"
            content="Kein Gewerk"
            color="orange"
          />
        )}
      </div>
    );
  }

  renderBaseTotalItem(lineItem) {
    if (lineItem.replacement) {
      return [
        <Table.Row key={`total-base-${lineItem.id}`} data-model="line_item">
          <Table.Cell
            colSpan={3}
            style={{ height: "50px" }}
            verticalAlign="middle"
          >
            Verrechnung der Standardleistung {lineItem.replacement_info.title}
          </Table.Cell>
          <Table.Cell textAlign="right">
            <FormatCurrency
              amount={-this.getReplacementDefaultCost(lineItem)}
            />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <FormatCurrency amount={-lineItem.replacement_info.default_total} />
          </Table.Cell>
        </Table.Row>
      ];
    }
    if (lineItem.cancellation && lineItem.replacement_info) {
      return [
        <Table.Row key={`total-base-${lineItem.id}`} data-model="line_item">
          <Table.Cell
            colSpan={3}
            style={{ height: "50px" }}
            verticalAlign="middle"
          >
            Umsetzung der Standardleistung {lineItem.replacement_info.title},
            deren Entfall zuvor beauftragt wurde.
          </Table.Cell>
          <Table.Cell textAlign="right">
            <FormatCurrency
              amount={this.getCancellationTotalBaseCost(lineItem)}
            />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <FormatCurrency amount={lineItem.cancellation_total_base} />
          </Table.Cell>
        </Table.Row>
      ];
    }
    return [];
  }

  renderLineItemDescription(lineItem, product) {
    if (product) {
      const quantity =
        (lineItem.price_strategy !== "flat" && (
          <span>
            <FormatQuantity
              value={lineItem.quantity}
              unit={lineItem.price_strategy}
            />
          </span>
        )) ||
        "";

      return (
        <span>
          {quantity} {lineItem.summary_short}
        </span>
      );
    }
    return lineItem.description;
  }

  renderUnlinkButton(lineItem) {
    const { model } = this.props;
    return (
      <CartRemover
        cart={model}
        lineItem={lineItem}
        handleUpdate={this.handleUpdate.bind(this)}
      />
    );
  }

  render() {
    const { model, ctxModel } = this.props;

    const productsRepository = ctxModel.getProductRepositoryForPriceCatalog(
      model.price_catalog_id
    );

    const parts = model?.sections?.reduce((accu, section) => {
      return accu.concat(
        this.renderPrimarySection(section, productsRepository)
      );
    }, []);

    if (parts?.length) {
      return (
        <Table stackable compact>
          {parts}
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell textAlign="right" colSpan={3}>
                <FormattedMessage
                  id="roomBook.genericSubtotal"
                  defaultMessage="roomBook.genericSubtotal"
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <FormatCurrency amount={this.getCartTotalCosts(model)} />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <FormatCurrency amount={model.total} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      );
    }
    return null;
  }
}

CartContent.propTypes = {
  model: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  ctxModel: PropTypes.object.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default CartContent;
