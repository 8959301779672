import { arrayOf, number, shape, string } from "prop-types";
import PriceStrategyShape from "../buyer_portal/components/inquiry/priceStrategy.shape";

export default shape({
  inquiryId: string,
  subLineItemId: string,
  productId: string,
  supplier: string,
  series: string,
  name: string,
  sku: string,
  thumbUrl: string,
  description: string,
  quantity: number,
  priceStrategy: PriceStrategyShape,
  productSpecifications: arrayOf(
    shape({
      specification: string,
      trade: string
    })
  )
});
