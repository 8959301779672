import { createSelector } from "reselect";
import { get, keyBy } from "lodash";

const getAssociatedProducts = state =>
  get(state, "pageContent.associated_products", []);
const getProducts = state => get(state, "pageContent.associated_products", []);

export const getProductsById = createSelector(
  [getAssociatedProducts, getProducts],
  associatedProducts => {
    return keyBy(associatedProducts, "id");
  }
);
