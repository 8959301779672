import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Grid, Input, Loader } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { shape, string, node, number } from "prop-types";
import { getAccount } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import CartExportDialog from "../../cart/CartExportDialog";
import { DocumentAssignmentsResource } from "../../../actions/documentsActions";

const PreviewDialog = ({ model, button }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [noPreviewType, setNoPreviewType] = useState("");
  const [exports, setExports] = useState();
  const [loadedId, setLoadedlId] = useState();
  const [valueId, setValueId] = useState();
  const account = useSelector(getAccount);

  const trades = useMemo(() => account.getTrades(), [account]);

  const groupLabel = useMemo(
    () => (model.group === "room_book_carts" ? "activity" : "roomBook"),
    [model]
  );

  const loadData = () => {
    const { id, group } = model;
    if (isOpen) {
      setLoading(true);
      new DocumentAssignmentsResource(dispatch)
        .documentExportPreview(group, id, valueId)
        .then(res => {
          const { data } = res;
          setLoading(false);
          if (!data) return;
          if (data.status === "no_preview") {
            setNoPreviewType(group);
          } else {
            setExports(res.data[0]);
            const objectId =
              res.data[0].activity_id || res.data[0].room_book_id;
            setLoadedlId(objectId);
            setValueId(objectId);
          }
        })
        .catch(() => {
          setOpen(false);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadData();
  }, [isOpen]);

  const renderExportDialog = (mode, option) => {
    const attachment = {
      activity_id: exports.activity_id,
      display_name: exports.file_name,
      role: exports.role,
      format: option.format,
      export_id: exports.id
    };

    const usedTrades = trades.filter(
      trade => exports.trades?.indexOf(trade.id) > -1
    );

    const trigger = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a rel="noreferrer" href="#" className="link">
        <FormattedMessage id="meta.actions.preview" />
      </a>
    );

    return (
      <CartExportDialog
        title={exports.label}
        exports={[exports]}
        attachment={attachment}
        button={trigger}
        mode={mode}
        trades={usedTrades}
        contractors={exports.contractors}
        contractorAssignments={exports.contractor_assignments}
      />
    );
  };

  const renderExportLink = option => {
    return (
      <a rel="noreferrer" target="_blank" href={option.url} className="link">
        <FormattedMessage id="meta.actions.preview" />
      </a>
    );
  };

  const renderLinks = option => {
    return (
      <Grid.Row key={option.label}>
        <Grid.Column width={4} />
        <Grid.Column width={2}>{option.label}</Grid.Column>
        <Grid.Column width={6}>
          <If condition={!exports.arguments?.length}>
            {renderExportLink(option)}{" "}
          </If>
          <If condition={!!exports.arguments?.length}>
            {renderExportDialog("PREVIEW", option)}
          </If>
          <span
            style={{
              fontSize: "0.8rem",
              paddingLeft: "15px",
              color: "silver"
            }}
          >{`for ${groupLabel} ${loadedId}`}</span>
        </Grid.Column>
      </Grid.Row>
    );
  };

  return (
    <Modal
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={button}
      data-component="previewDialog"
    >
      <Modal.Header>
        <If condition={!noPreviewType}>
          <FormattedMessage id="documentsExport.dialogs.preview.title" />
        </If>
        <If condition={!!noPreviewType}>
          <FormattedMessage id="documentsExport.messages.noPreviewTitle" />
        </If>
      </Modal.Header>
      <Modal.Content>
        <If condition={noPreviewType === "room_book_carts"}>
          <FormattedMessage id="documentsExport.messages.noPreviewActivity" />
        </If>
        <If condition={noPreviewType === "room_books"}>
          <FormattedMessage id="documentsExport.messages.noPreviewRoomBook" />
        </If>
        <If condition={!exports && !noPreviewType}>
          <Loader active />
        </If>
        <If condition={!!exports}>
          <Grid>
            <Grid.Row verticalAlign="bottom">
              <Grid.Column width={4} className="label">
                {exports?.label}
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                <FormattedMessage
                  id={`documentsExport.dialogs.preview.labels.${groupLabel}`}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Input
                  name="id"
                  type="number"
                  value={valueId}
                  onChange={e => setValueId(e.target.value)}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  onClick={loadData}
                  loading={isLoading}
                  disabled={loadedId === valueId}
                  color="green"
                >
                  <FormattedMessage id="documentsExport.dialogs.preview.labels.load" />
                </Button>
              </Grid.Column>
            </Grid.Row>
            {exports?.options?.map(option => {
              return renderLinks(option);
            })}
          </Grid>
        </If>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          id="cancel"
          content={intl.formatMessage({ id: "meta.actions.close" })}
          onClick={() => setOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};

PreviewDialog.propTypes = {
  model: shape({
    id: number,
    group: string
  }).isRequired,
  button: node.isRequired
};

export default PreviewDialog;
