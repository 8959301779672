import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import MultiDialog from "builder_portal/components/dialogs/MultiDialog";
import SubLineItemModel from "builder_portal/models/roomBook/SubLineItemModel";
import DerivedInfo from "builder_portal/components/roomBook/DerivedInfo";
import OptionsShape from "shared/shapes/options.shape";
import { getUnitVariableTypes } from "builder_portal/selectors/unitVariables";
import { UnitVariableTypeShape } from "shared/shapes/unitVariables.shape";
import SubLineItemForm from "./SubLineItemForm";

class SubLineItemDialog extends Component {
  state = {
    deleteDerivedLoading: false,
    isLoading: false
  };

  onSave = (values, closeOnSave = true) => {
    const { ctrl } = this.props;
    if (values.id) {
      return ctrl.updateSubLineItem(values).then(() => {
        this.handleUpdate(closeOnSave);
      });
    }
    return ctrl.createSubLineItem(values.line_item_id, values).then(() => {
      this.handleUpdate(closeOnSave);
    });
  };

  handleDelete = () => {
    const { ctrl, subLineItemModel } = this.props;
    return ctrl
      .deleteSubLineItem(subLineItemModel.getId())
      .then(() => {
        this.handleUpdate(true);
      })
      .catch(this.handleClose);
  };

  handleUpdate(closeOnSuccess) {
    const { handleUpdate } = this.props;
    if (handleUpdate) {
      return handleUpdate().then(() => {
        if (closeOnSuccess) {
          this.handleClose();
        }
      });
    }
    if (closeOnSuccess) {
      this.handleClose();
    }
  }

  handleClose = () => this.props.toggleDialog(false);

  handleOpen = () => this.props.toggleDialog(true);

  render() {
    const {
      subLineItemModel,
      toggleDialog,
      isVisible,
      initialPage
    } = this.props;
    return (
      <MultiDialog
        title={`${subLineItemModel.getDisplayNumber()} ${subLineItemModel.getTitle()}`}
        pages={this.getPages()}
        isVisible={isVisible}
        toggleDialog={toggleDialog}
        initialPage={initialPage}
        size="large"
      />
    );
  }

  getPages() {
    return [
      this.getEditPage(),
      // this.getDeleteButton(),
      this.getDeleteDerivedPage()
    ];
  }

  getEditPage() {
    const {
      model,
      module,
      products,
      i18n,
      isTemplate,
      trades,
      attachments,
      ctrl,
      context,
      autoFocus,
      unitVariableTypesOptions,
      unitVariableTypes,
      projectId
    } = this.props;
    return {
      id: "edit",
      title: (
        <FormattedMessage
          id="roomBook.actions.editLineItem"
          defaultMessage="roomBook.actions.editLineItem"
        />
      ),
      summary: "Bearbeiten Sie die Basisdaten dieser Position",
      hideButtons: true,
      renderContent: () => (
        <SubLineItemForm
          module={module}
          i18n={i18n}
          context={{ ...context, lineItem: model.id }}
          trades={trades}
          isTemplate={isTemplate}
          isProductLineItem={model.role && model.role === "product"}
          model={model}
          attachments={attachments}
          ctrl={ctrl}
          products={products}
          cancel={this.handleClose}
          save={this.onSave}
          onDelete={this.handleDelete}
          autoFocus={autoFocus}
          unitVariableTypesOptions={unitVariableTypesOptions}
          unitVariableTypes={unitVariableTypes}
          projectId={projectId}
        />
      )
    };
  }

  hasDeleteButton() {
    const { ctrl } = this.props;
    return !!ctrl.deleteSubLineItem;
  }

  getDeleteButton() {
    const { isTemplate } = this.props;
    return {
      id: "delete",
      title: "Position löschen",
      summary: "Entfernen Sie diese Position aus dem Raumbuch",
      type: "button",
      hidden: !this.hasDeleteButton() || isTemplate,
      renderButton: () => {
        return this.renderDeleteButton();
      }
    };
  }

  handleDeleteDerivedLoading = loading =>
    this.setState({ deleteDerivedLoading: loading });

  handleError = () => {
    const { toggleDialog } = this.props;
    toggleDialog(false);
  };

  getDeleteDerivedPage() {
    const { subLineItemModel, isTemplate, ctrl } = this.props;
    const { deleteDerivedLoading } = this.state;
    return {
      id: "delete_derived",
      title: (
        <FormattedMessage id="roomBook.actions.deleteWithDerived.item.title" />
      ),
      summary: (
        <FormattedMessage id="roomBook.actions.deleteWithDerived.item.summary" />
      ),
      buttonIcon: "trash",
      buttonColor: "red",
      hidden: !this.hasDeleteButton() || !isTemplate,
      renderContent: () => (
        <DerivedInfo
          itemId={subLineItemModel.getId()}
          itemType="sub_line_item"
          onLoading={this.handleDeleteDerivedLoading}
          onError={this.handleError}
          loading={deleteDerivedLoading}
          resource={ctrl.subLineItemResource()}
        />
      ),
      actions: [
        {
          id: "remove",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemoveWithDerived,
          disabled: deleteDerivedLoading
        }
      ]
    };
  }

  onRemoveWithDerived = () => {
    const { model, ctrl, toggleDialog, derivedIds } = this.props;
    this.setState({ isLoading: true });
    return ctrl.deleteSubLineItems([...derivedIds, model.id]).then(() => {
      this.setState({ isLoading: false }, () => {
        toggleDialog(false);
      });
    });
  };
}

SubLineItemDialog.propTypes = {
  i18n: PropTypes.object,
  isTemplate: PropTypes.bool,
  context: PropTypes.object,
  trades: PropTypes.array,
  attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  model: PropTypes.object,
  products: PropTypes.array,
  ctrl: PropTypes.object,
  handleUpdate: PropTypes.func,
  toggleDialog: PropTypes.func,
  isVisible: PropTypes.bool,
  autoFocus: PropTypes.bool,
  subLineItemModel: PropTypes.instanceOf(SubLineItemModel),
  derivedIds: PropTypes.array,
  initialPage: PropTypes.string,
  module: PropTypes.string,
  unitVariableTypesOptions: PropTypes.arrayOf(OptionsShape),
  unitVariableTypes: PropTypes.arrayOf(UnitVariableTypeShape).isRequired,
  projectId: PropTypes.string.isRequired
};

SubLineItemDialog.defaultProps = {
  module: null,
  unitVariableTypesOptions: []
};

const mapStateToProps = state => {
  return {
    unitVariableTypes: getUnitVariableTypes(state)
  };
};

export default connect(mapStateToProps)(SubLineItemDialog);
