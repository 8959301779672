import React, { useContext } from "react";
import { If } from "shared/components/elements/Conditions";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment
} from "semantic-ui-react";
import { get } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { ProjectSetupDataContext } from "./ProjectSetupDataContext";
import SectionAndUnitUploadModal from "../sectionAndUnit/SectionAndUnitUploadModal";

const UnitsSegment = ({ disabled }) => {
  const intl = useIntl();
  const {
    projectSetup,
    project,
    account,
    handleReset,
    handleUplaodCompleted,
    renderUploadedMessage,
    prepareActions,
    isLoading
  } = useContext(ProjectSetupDataContext);

  const disabledStyle = disabled
    ? { pointerEvents: "none", opacity: "0.4" }
    : {};

  return (
    <Segment attached style={{ ...disabledStyle }}>
      <Header as="h3">
        <Icon
          name={`${
            projectSetup.step_units_completed_at
              ? "check circle"
              : "circle outline"
          }`}
          size="huge"
        />
        <FormattedMessage
          id="project.project_setup.items.units"
          default="Wohnungen"
        />
      </Header>
      <Container textAlign="right">
        <If condition={!projectSetup.step_units_completed_at} block>
          <Button
            style={{ width: "43%", height: 50, paddingTop: 18 }}
            basic
            href={`${window.location.origin}/api/v1/projects/${
              project?.slug
            }/units/export.xlsx?_bearer=${get(account, [
              "data",
              "auth_token"
            ])}`}
            target="_blank"
            data-tooltip={intl.formatMessage({
              id: "unit.actions.download.tooltip"
            })}
          >
            <FormattedMessage
              id="meta.actions.download"
              default="Herunterladen"
            />
          </Button>
          <SectionAndUnitUploadModal
            onCompleted={() => handleUplaodCompleted("step_units_")}
            button={
              <Button
                basic
                style={{ width: "43%", height: 50, marginLeft: "10px" }}
              >
                <FormattedMessage
                  id="meta.actions.upload"
                  default="Hochladen"
                />
              </Button>
            }
          />
        </If>
        <If condition={!!projectSetup.step_units_completed_at} block>
          <Grid style={{ marginLeft: "35px" }}>
            <Grid.Column width={13} textAlign="left">
              {renderUploadedMessage(
                prepareActions.data(projectSetup, "step_units_")
              )}
            </Grid.Column>
            <Grid.Column width={3} textAlign="right">
              <Button
                basic
                disabled={isLoading}
                onClick={() => handleReset("step_units_")}
                className="underlinedButton"
              >
                <FormattedMessage
                  id="project.project_setup.data.reset.label"
                  default="Ändern"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </If>
      </Container>
    </Segment>
  );
};

UnitsSegment.propTypes = {
  disabled: PropTypes.bool
};

UnitsSegment.defaultProps = {
  disabled: false
};

export default UnitsSegment;
