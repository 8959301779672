import React from "react";
import { connect } from "react-redux";
import { Container, Header, Loader } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Resources from "shared/actions/resourcesActions";
import { createStructuredSelector } from "reselect";
import {
  getDerivedIds,
  getDerivedInfos,
  getRoomBookStructure
} from "builder_portal/selectors/roomBooks/descendantsInfo";
import DescendantsVisualizer from "builder_portal/components/roomBook/DescendantsVisualizer";

class DerivedInfo extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onLoading: PropTypes.func,
    onError: PropTypes.func,
    resource: PropTypes.instanceOf(Resources).isRequired,
    itemId: PropTypes.number.isRequired,
    roomBookStructure: PropTypes.object,
    derivedInfos: PropTypes.array,
    derivedIds: PropTypes.array,
    itemType: PropTypes.string
  };

  componentDidMount() {
    const { onLoading, onError, resource, itemId } = this.props;
    onLoading?.(true);
    resource
      .getDerivedInfo(itemId)
      .then(() => {
        onLoading?.(false);
      })
      .catch(error => {
        onError?.(error);
      });
  }

  render() {
    const {
      loading,
      derivedIds,
      roomBookStructure,
      derivedInfos,
      itemType
    } = this.props;
    const messageId =
      itemType === "primary" || itemType === "secondary"
        ? "roomBook.actions.deleteWithDerived.section.derived_info"
        : "roomBook.actions.deleteWithDerived.item.derived_info";
    return loading ? (
      <Loader active size="huge" />
    ) : (
      <Container>
        <Header as="h3">
          <FormattedMessage
            id={messageId}
            values={{ derived_count: derivedIds.length }}
          />
        </Header>
        <DescendantsVisualizer
          roomBookStructure={roomBookStructure}
          derivedInfos={derivedInfos}
        />
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  roomBookStructure: getRoomBookStructure,
  derivedInfos: getDerivedInfos,
  derivedIds: getDerivedIds
});

const Component = connect(mapStateToProps)(DerivedInfo);

Component.propTypes = {};

export default Component;
