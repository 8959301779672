import { createSelector } from "reselect";
import { get } from "lodash";

import { getPageContent } from "./base";

export const getProfile = createSelector([getPageContent], pageContent =>
  get(pageContent, "profile", null)
);

export const getProjectsMembership = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "project_memberships", [])
);
