import { createSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import React from "react";
import { getPageContent } from "./base";

const getAccountRoles = createSelector([getPageContent], pageContent => {
  return pageContent?.account_roles || [];
});

export const getAccountRolesOptions = createSelector(
  [getAccountRoles],
  accountRoles => {
    return accountRoles
      .filter(accountRole => {
        return accountRole?.system_reference_role !== "contractor";
      })
      .map(accountRole => {
        return {
          key: accountRole.id,
          value: accountRole.id,
          text: (
            // eslint-disable-next-line react/jsx-filename-extension
            <FormattedMessage
              id={`user.roles.${accountRole.name}.label`}
              defaultMessage={accountRole.name}
            />
          )
        };
      });
  }
);

export default getAccountRoles;
