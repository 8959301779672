import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Segment, Icon, Label, Button } from "semantic-ui-react";
import DOMPurify from "dompurify";
import ContactLabel from "./ContactLabel";
import MemoDialog from "./MemoDialog";

class MemoEvent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeMessage: true
    };
  }

  componentDidMount() {
    this.handleToggleByStringLength(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.handleToggleByStringLength(this.props);
    }
  }

  handleToggle = () => {
    const { disabled, fadeMessage } = this.state;
    if (!disabled) {
      this.setState({
        fadeMessage: !fadeMessage
      });
    }
  };

  handleToggleByStringLength(props) {
    if (props.memo) {
      if (props.memo.body && props.memo.body.length > 300) {
        this.setState({
          fadeMessage: true
        });
      } else {
        this.setState({
          disabled: true,
          fadeMessage: false
        });
      }
    }
  }

  render() {
    const {
      i18n,
      date,
      time,
      memo,
      user,
      currentUser,
      memosResource,
      contacts,
      handleUpdate
    } = this.props;

    const { fadeMessage } = this.state;

    const isEditable = currentUser.id === user.id;

    const attachments = (memo.attachments || []).map(attachment => {
      return (
        <div key={attachment.id} className="ui icon button margin top spacious">
          <i className="file outline icon" />
          {attachment.label}
        </div>
      );
    });

    const authorMessage = (
      <FormattedMessage
        id={`activity.timeline.event.${memo.role}`}
        defaultMessage={`activity.timeline.event.${memo.role}`}
        values={{ user: user && user.label }}
      />
    );

    const icon = memo.role === "note" ? "comment outline" : "phone";

    const contactLabels = memo.contacts.map(contact => {
      return (
        <ContactLabel
          key={`${contact.type}-${contact.id}`}
          contact={contact}
          type={memo.role}
        />
      );
    });

    return (
      <div data-component="timelineItem" data-event={memo.role}>
        <div className="bullet-pointer">
          <Icon color="grey" size="large" circular name={icon} />
        </div>
        <Container data-component="memoEvent" onClick={this.handleToggle}>
          <Segment>
            <Label basic attached="bottom">
              <Icon name="eye" />
              {authorMessage}
            </Label>
            <Label basic attached="top">
              {isEditable && (
                <MemoDialog
                  key={`memo-${memo.id}`}
                  i18n={i18n}
                  memo={memo}
                  button={
                    <Button
                      className="right floated editMemo"
                      size="tiny"
                      basic
                      icon="setting"
                      key={`button-memo-${memo.id}`}
                    />
                  }
                  handleUpdate={handleUpdate}
                  memosResource={memosResource}
                  contacts={contacts}
                />
              )}
              <div className="right floated element">
                <time>{date}</time> um <time>{time}</time>
              </div>
              <div>{contactLabels}</div>
            </Label>
            <div className={`message${fadeMessage ? " fade" : ""}`}>
              {!!memo.body_html && (
                <div
                  className="text"
                  // Needed if we want to display the HTML notes in the timeline
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      (fadeMessage && memo.body_html.substr(0, 300)) ||
                        memo.body_html
                    )
                  }}
                />
              )}
              {!!memo.body && !memo.body_html && (
                <div className="text">
                  {(fadeMessage && memo.body.substr(0, 300)) || memo.body}
                </div>
              )}
              {fadeMessage && <a className="showMore">Mehr anzeigen...</a>}
              {attachments}
            </div>
          </Segment>
        </Container>
      </div>
    );
  }
}

MemoEvent.propTypes = {
  i18n: PropTypes.object,
  memo: PropTypes.object,
  date: PropTypes.string,
  time: PropTypes.string,
  user: PropTypes.object,
  currentUser: PropTypes.object,
  memosResource: PropTypes.object,
  contacts: PropTypes.array,
  handleUpdate: PropTypes.func
};

export default MemoEvent;
