import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

// eslint-disable-next-line import/prefer-default-export
export const getConfigurationGroups = createSelector(
  [getPageContent],
  pageContent => {
    return sortBy(get(pageContent, "configuration_groups", []), ["position"]);
  }
);

export const getConfigurationGroupOptions = createSelector(
  [getConfigurationGroups],
  configurationGroups => {
    return sortBy(configurationGroups, "name").map(t => ({
      key: t.id,
      value: t.id,
      text: t.name
    }));
  }
);
