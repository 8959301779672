import Resources from "../../shared/actions/resourcesActions";

export class MessageLabelsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "message_label", "/api/v1/message_labels");
    this.refreshPageContentAfterCommit = true;
  }
}

export default MessageLabelsResource;
