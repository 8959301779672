import {
  arrayOf,
  instanceOf,
  number,
  objectOf,
  oneOfType,
  shape,
  string
} from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Segment,
  Dropdown
} from "semantic-ui-react";
import { get } from "lodash";
import { ReactSortable } from "react-sortablejs";
import { browserHistory } from "shared/routes/browserHistory";
import { scrollToTop } from "shared/helpers/scrollToTop";
import { I18nShape } from "shared/shapes/i18n.shape";
import { ProductGroupResourcesShape } from "shared/shapes/productGroupResources.shape";
import { Account } from "shared/models/account";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import {
  getProductGroup,
  getProductOptions,
  getProductGroupProductCandidates
} from "builder_portal/selectors/productGroups";
import ProductGroupShape from "shared/shapes/productGroup.shape";
import { ProductOptionShape } from "shared/shapes";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import { If } from "../../../shared/components/elements/Conditions";
import ProductGroupWarningsSegment from "./ProductGroupWarningsSegment";
import ConfirmationDialog from "../../../shared/components/dialogs/ConfirmationDialog";
import ProductRulesDialog from "../productRules/ProductRulesDialog";
import ProductSearchDialog from "./ProductSearchDialog";
import ProductGroupDialog from "./ProductGroupDialog";
import ProductGroupItemCard from "./ProductGroupItemCard";
import TradesList from "./TradesList";
import ProductPricesDialog from "./ProductPricesDialog";
import HasEditRightsForProjectCatalog from "../../../shared/components/authorization/HasEditRightsForProjectCatalog";
import "./productGroupItem.scss";
import { PRODUCT_FILTER } from "./productFilterValues";
import { ProductShape } from "../../../shared/shapes";

class ProductGroupItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      productFitler: PRODUCT_FILTER.ACTIVE,
      list: []
    };
  }

  componentDidMount() {
    this.fetchProductGroups();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      productGroupId,
      pageContent,
      priceCatalogId,
      productCategories
    } = this.props;
    const { isLoading } = this.state;
    const { productGroup, sortableProductOptions } = this.props;

    const productGroupIdChanged = productGroupId !== nextProps.productGroupId;

    const productGroupChanged =
      productGroup?.updated_at !== nextState.productGroup?.updated_at;

    const loadingChanged = isLoading !== nextState.isLoading;

    const sortableProductOptionsChanged =
      sortableProductOptions?.map(({ id }) => id).join(",") !==
      nextState.sortableProductOptions?.map(({ id }) => id).join(",");

    const pageContentSuppliersChanged =
      nextProps.pageContent.suppliers?.map(({ id }) => id).join(",") !==
      pageContent.suppliers?.map(({ id }) => id).join(",");

    const pageContentProductsChanged =
      nextProps.pageContent.products?.map(({ id }) => id).join(",") !==
      pageContent.products?.map(({ id }) => id).join(",");

    const pageContentAssociatedProductsChanged =
      nextProps.pageContent.associated_products
        ?.map(({ id }) => id)
        .join(",") !==
      pageContent.associated_products?.map(({ id }) => id).join(",");

    const priceCatalogIdChanged = priceCatalogId !== nextProps.priceCatalogId;

    const productCategoriesChanged =
      productCategories !== nextProps.productCategories;

    // side-effect
    if (productGroupIdChanged) scrollToTop();

    return (
      productGroupIdChanged ||
      productGroupChanged ||
      loadingChanged ||
      sortableProductOptionsChanged ||
      pageContentSuppliersChanged ||
      pageContentProductsChanged ||
      pageContentAssociatedProductsChanged ||
      priceCatalogIdChanged ||
      productCategoriesChanged
    );
  }

  componentDidUpdate(prevProps) {
    const { productGroupId, priceCatalogId } = this.props;

    if (
      prevProps.productGroupId !== productGroupId ||
      prevProps.priceCatalogId !== priceCatalogId
    ) {
      this.fetchProductGroups();
    }
  }

  onUpdate() {
    const { resources } = this.props;
    return this.fetchProductGroups().then(() => {
      return resources.groups.fetchAll();
    });
  }

  updateMovedPrice = ({ productGroupId, id, position }) => {
    const { resources } = this.props;

    return resources
      .pricesFactory(productGroupId)
      .save({
        id,
        position
      })
      .then(() => this.fetchProductGroups());
  };

  fetchProductGroups = ({ productGroupId, resources } = this.props) => {
    this.setState({ isLoading: true });
    return Promise.all([
      resources.groups.get(productGroupId, true),
      resources.groups.usageCounts(productGroupId)
    ])
      .then(res => {
        const productOptions =
          res[0].data?.product_group?.product_options || [];
        const usageCounts = res[1].data?.product_option_usage_counts;
        const list = productOptions.map(product => ({
          ...product,
          usage_count: usageCounts?.[product.id]
        }));
        this.setState({
          isLoading: false,
          list
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
        throw e;
      });
  };

  duplicateProductGroup(id) {
    const { resources, catalogId, trade } = this.props;
    this.setState({ isLoading: true }, () => {
      resources.groups.duplicate(id).then(result => {
        resources.groups.fetchAll().then(() => {
          browserHistory.push(
            `/products/${catalogId}/product_groups/${trade}/${result.data.product_group.id}`
          );
        });
      });
    });
  }

  renderPortalOfferHint() {
    const { account, i18n, productGroup } = this.props;

    const icon = productGroup.portal_offer ? "unhide" : "hide";
    const title = `product_group.attributes.portal_offer.hints.${
      productGroup.portal_offer ? "shown" : "hidden"
    }`;
    if (account.isEnabled("buyer_portal")) {
      return <Icon name={icon} title={i18n[title]} />;
    }
    return null;
  }

  renderSortableProductOptions() {
    const { list } = this.state;

    if (list.length > 0) {
      return (
        <ReactSortable
          // fake <Card.Group itemsPerRow="3" stackable> Element
          className="ui stackable three cards"
          onEnd={ev => {
            if (ev.oldIndex !== ev.newIndex) {
              const movedProduct = list[ev.newIndex];
              this.updateMovedPrice({
                productGroupId: movedProduct.product_group_id,
                id: movedProduct.id,
                position: ev.newIndex + 1
              });
            }
          }}
          list={list}
          setList={newState => {
            this.setState({ list: newState });
          }}
        >
          <>{list.map(this.renderProductOption)}</>
        </ReactSortable>
      );
    }
    return (
      <Message warning>
        <p>
          <FormattedMessage
            id="product_price.missing"
            defaultMessage="product_price.missing"
          />
        </p>
      </Message>
    );
  }

  renderProductOption = option => {
    const {
      account,
      i18n,
      resources,
      trades,
      priceCatalogId,
      productGroupId,
      productGroup,
      catalogId
    } = this.props;
    const { productFitler } = this.state;

    const associatedProducts = get(
      this.props,
      "pageContent.associated_products"
    );
    const product = associatedProducts.find(associatedProduct => {
      return associatedProduct.id === option.product_id;
    });

    const price = option.product_prices.find(
      p => p.price_catalog_id === priceCatalogId
    );

    if (productFitler === PRODUCT_FILTER.ACTIVE && price?.deprecated_at)
      return null;
    if (productFitler === PRODUCT_FILTER.INACTIVE && !price?.deprecated_at)
      return null;

    return (
      product && (
        <ProductGroupItemCard
          key={option.id}
          account={account}
          option={option}
          price={price}
          i18n={i18n}
          group={productGroup}
          product={product}
          resources={resources}
          priceCatalogId={priceCatalogId}
          productGroupId={productGroupId}
          handleUpdate={() => this.onUpdate()}
          trades={trades}
          handleMovedProductOption={this.updateMovedPrice}
          catalogId={catalogId}
        />
      )
    );
  };

  renderDuplicateProductGroupButton(group) {
    const { i18n } = this.props;
    return (
      <span
        role="button"
        id="product_group-duplicate"
        data-tooltip={i18n["meta.actions.clone"]}
        className="right floated element"
      >
        <Icon
          name="clone"
          size="large"
          color="grey"
          onClick={() => this.duplicateProductGroup(group.id)}
        />
      </span>
    );
  }

  renderEditProductGroupButton(group) {
    const { i18n, resources, account, trades, productCategories } = this.props;

    const button = (
      <span
        role="button"
        id="product_group-edit"
        data-tooltip={i18n["meta.actions.edit"]}
        className="right floated element"
      >
        <Icon name="setting" size="large" color="grey" />
      </span>
    );

    return (
      <ProductGroupDialog
        button={button}
        resources={resources}
        model={group}
        account={account}
        i18n={i18n}
        handleUpdate={() => this.onUpdate()}
        trades={trades}
        productCategories={productCategories}
      />
    );
  }

  renderDeleteProductGroupButton(group) {
    const { i18n, resources, catalogId } = this.props;
    const button = (
      <span
        role="button"
        id="product_group-remove"
        data-tooltip={i18n["meta.actions.remove"]}
        className="right floated element"
      >
        <Icon name="trash" size="large" color="grey" />
      </span>
    );

    if (group.deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: async () => {
            await resources.groups.remove(group.id);
            await resources.groups.fetchAll();
            browserHistory.push(`/products/${catalogId}/product_groups`);
          }
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="product_group.actions.removeDialog.title"
          message="product_group.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: "meta.actions.accept",
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title="product_group.actions.undeletableDialog.title"
        message="product_group.actions.undeletableDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  renderCreateProductPriceButton(productGroup) {
    const {
      i18n,
      resources,
      account,
      pageContent,
      trades,
      products
    } = this.props;

    const button = (
      <Button
        id="product_price-new"
        icon="plus"
        fluid
        content={i18n["meta.actions.add"]}
      />
    );

    const usedProductIds = productGroup.prices.map(price => {
      return price.product_id;
    });

    return (
      <ProductSearchDialog
        button={button}
        account={account}
        resources={resources}
        suppliers={get(pageContent, "suppliers", [])}
        products={products}
        productGroupId={productGroup.id}
        i18n={i18n}
        handleUpdate={() => this.onUpdate()}
        trades={trades}
        usedProductIds={usedProductIds}
      />
    );
  }

  render() {
    const {
      catalogId,
      priceCatalogId,
      trades,
      productGroup,
      sortableProductOptions,
      i18n
    } = this.props;
    const { isLoading, productFitler } = this.state;

    const groupHasDepricated = productGroup?.prices?.some(
      price => price.deprecated
    );

    if (!groupHasDepricated && productFitler === PRODUCT_FILTER.INACTIVE)
      this.setState({ productFitler: PRODUCT_FILTER.ACTIVE });

    const numberOfFilteredProducts =
      groupHasDepricated &&
      sortableProductOptions.reduce((acc, cur) => {
        const price = cur.product_prices.find(
          p => p.price_catalog_id === priceCatalogId
        );
        if (productFitler === PRODUCT_FILTER.ACTIVE && !price?.deprecated_at)
          return acc + 1;
        if (productFitler === PRODUCT_FILTER.INACTIVE && price?.deprecated_at)
          return acc + 1;
        if (productFitler === PRODUCT_FILTER.ALL) return acc + 1;
        return acc;
      }, 0);

    if (productGroup) {
      return (
        <>
          <Segment.Group>
            <Header as="h5" attached="top">
              <Grid stackable verticalAlign="middle">
                <Grid.Column width="10">
                  {productGroup.name} {this.renderPortalOfferHint()}
                </Grid.Column>
                <Grid.Column width="6" textAlign="right">
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink
                      id={productGroup.id}
                      type="ProductGroup"
                      className="right floated element"
                    />
                  </IsVersionHistoryAccessible>
                  <HasEditRightsForProjectCatalog catalogId={catalogId}>
                    {this.renderEditProductGroupButton(productGroup)}
                    {this.renderDuplicateProductGroupButton(productGroup)}
                    {this.renderDeleteProductGroupButton(productGroup)}
                  </HasEditRightsForProjectCatalog>
                </Grid.Column>
              </Grid>
            </Header>
            <Segment attached loading={isLoading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="4">
                    <Header sub>
                      <FormattedMessage
                        id="product_group.attributes.price_strategy.label"
                        defaultMessage="product_group.attributes.price_strategy.label"
                      />
                    </Header>
                    <span>
                      <FormattedMessage
                        id={`product_group.price_strategies.${productGroup.price_strategy}.label`}
                        defaultMessage={`product_group.price_strategies.${productGroup.price_strategy}.label`}
                      />
                    </span>
                  </Grid.Column>
                  <Grid.Column width="12">
                    <Header sub>Beschreibung</Header>
                    <span>
                      {productGroup.desciption || <i>keine Beschreibung</i>}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <If
                  condition={productGroup.glencoe_product_category?.length > 0}
                >
                  <IsSystemAdmin>
                    <Grid.Row>
                      <Grid.Column width="12" textAlign="left">
                        <Header sub>
                          <FormattedMessage
                            id="product_group.attributes.glencoe_product_category.label"
                            defaultMessage="product_group.glencoe_product_category.label"
                          />
                        </Header>
                        <span>{productGroup.glencoe_product_category}</span>
                      </Grid.Column>
                    </Grid.Row>
                  </IsSystemAdmin>
                </If>
                <Grid.Row>
                  <Grid.Column width="10" textAlign="left">
                    <TradesList specificTrades={productGroup.trades} />
                  </Grid.Column>
                  <Grid.Column
                    width="6"
                    textAlign="right"
                    verticalAlign="middle"
                  >
                    <HasEditRightsForProjectCatalog catalogId={catalogId}>
                      <ProductPricesDialog
                        group={productGroup}
                        priceCatalogId={priceCatalogId}
                        trades={trades}
                        trigger={
                          <Button
                            id="edit-prices"
                            content="Preise"
                            icon="pencil"
                          />
                        }
                        reloadPrices={this.fetchProductGroups}
                      />
                      <ProductRulesDialog
                        key={catalogId}
                        productGroupId={productGroup.id}
                      />
                    </HasEditRightsForProjectCatalog>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment
              attached
              loading={isLoading}
              data-component="product_prices"
            >
              <Grid stackable verticalAlign="middle">
                <Grid.Column width="12">
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Header as="h3">
                      <FormattedMessage
                        id="product_price.title.other"
                        defaultMessage="product_price.title.other"
                      />
                    </Header>
                    <If condition={groupHasDepricated}>
                      <div style={{ marginLeft: "2rem" }} className="flex">
                        <Dropdown
                          selection
                          defaultValue="active"
                          value={productFitler}
                          options={[
                            {
                              key: PRODUCT_FILTER.ACTIVE,
                              value: PRODUCT_FILTER.ACTIVE,
                              text: i18n["product_price.filter.active.label"]
                            },
                            {
                              key: PRODUCT_FILTER.INACTIVE,
                              value: PRODUCT_FILTER.INACTIVE,
                              text: i18n["product_price.filter.inactive.label"]
                            },
                            {
                              key: PRODUCT_FILTER.ALL,
                              value: PRODUCT_FILTER.ALL,
                              text: i18n["product_price.filter.all.label"]
                            }
                          ]}
                          onChange={(_, { value }) =>
                            this.setState({ productFitler: value })
                          }
                        />
                      </div>
                    </If>
                  </div>
                </Grid.Column>
                <Grid.Column width="4" textAlign="right">
                  <HasEditRightsForProjectCatalog catalogId={catalogId}>
                    {this.renderCreateProductPriceButton(productGroup)}
                  </HasEditRightsForProjectCatalog>
                </Grid.Column>
              </Grid>
              {this.renderSortableProductOptions()}
              <div style={{ marginTop: "2rem" }}>
                <If condition={numberOfFilteredProducts === 0}>
                  <FormattedMessage
                    id="product_price.products.empty"
                    default="Die Produktliste ist leer"
                  />
                </If>
              </div>
            </Segment>
          </Segment.Group>
          <IsSystemAdmin>
            <div className="margin top spacious">
              <ProductGroupWarningsSegment productGroup={productGroup} />
            </div>
          </IsSystemAdmin>
        </>
      );
    }

    return (
      <Segment>
        <Loader />
      </Segment>
    );
  }
}

ProductGroupItem.propTypes = {
  account: instanceOf(Account).isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  pageContent: shape({
    suppliers: arrayOf(shape({ id: oneOfType([string, number]) })),
    products: arrayOf(shape({ id: oneOfType([string, number]) })),
    associated_products: arrayOf(shape({ id: oneOfType([string, number]) }))
  }).isRequired,
  products: arrayOf(ProductShape).isRequired,
  productGroupId: oneOfType([string, number]).isRequired,
  catalogId: oneOfType([string, number]).isRequired,
  priceCatalogId: oneOfType([number]).isRequired,
  trade: oneOfType([string, number]).isRequired,
  trades: arrayOf(objectOf(string)).isRequired,
  i18n: I18nShape.isRequired,
  productCategories: arrayOf(shape({ id: string, name: string })).isRequired,
  productGroup: ProductGroupShape,
  sortableProductOptions: arrayOf(ProductOptionShape).isRequired
};

ProductGroupItem.defaultProps = {
  productGroup: null
};

const mapStateToProps = createStructuredSelector({
  productGroup: getProductGroup,
  products: getProductGroupProductCandidates,
  sortableProductOptions: getProductOptions
});

export default connect(mapStateToProps)(ProductGroupItem);
