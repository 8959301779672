import React, { Component } from "react";
import {
  func,
  shape,
  object,
  arrayOf,
  string,
  oneOfType,
  number
} from "prop-types";
import { connect } from "react-redux";
import { keyBy, get } from "lodash";
import { Dropdown, Header } from "semantic-ui-react";
import ProgressDialog from "builder_portal/components/dialogs/ProgressDialog";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import { I18nShape } from "shared/shapes/i18n.shape";

class RoomBookCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { templates } = this.props;
    if (!templates) {
      this.getProjectRoomBooksResource().fetchAll();
    }
  }

  getProjectRoomBooksResource() {
    if (!this.projectRoomBooksResource) {
      const { projectRoomBooksResourceCreator, unit } = this.props;
      this.projectRoomBooksResource = projectRoomBooksResourceCreator(
        unit.project_id
      );
    }
    return this.projectRoomBooksResource;
  }

  handleAssignRoomBook(data, unit) {
    const { i18n, templates } = this.props;
    const roomBookDictionary = keyBy(templates, "id");

    const options =
      data.value && data.value > 0
        ? {
            roomBookName: `${i18n["roomBook.copyOf"]} ${
              roomBookDictionary[data.value].name
            }`,
            roomBookId: data.value,
            unitId: unit.id
          }
        : {
            roomBookName: i18n["roomBook.new"],
            unitId: unit.id
          };

    this.setState({ loading: true });

    this.getProjectRoomBooksResource()
      .copyRoomBookToUnit(options)
      .then(() => {
        const { onCreate } = this.props;
        this.setState({ loading: false });
        onCreate();
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { i18n, unit, templates, pointing } = this.props;
    const { loading } = this.state;

    const emptyBook = (
      <Dropdown.Item
        key="new-room-book"
        value="0"
        onClick={(proxy, data) => {
          this.handleAssignRoomBook(data, unit);
        }}
      >
        {i18n["roomBook.actions.addEmptyBook"]}
      </Dropdown.Item>
    );

    const templateOptions = templates.map(item => {
      return (
        <Dropdown.Item
          key={`room-book-${item.id}`}
          value={item.id}
          onClick={(proxy, data) => {
            this.handleAssignRoomBook(data, unit);
          }}
        >
          {item.name}
        </Dropdown.Item>
      );
    });

    return (
      <div>
        <Dropdown
          id={`roombook-selector-${unit.id}`}
          data-component="roomBookSelector"
          text={i18n["roomBook.actions.add"]}
          compact
          fluid
          button
          labeled
          scrolling
          pointing={pointing}
          loading={!templates}
          icon="plus"
          className="icon"
        >
          <Dropdown.Menu>
            {emptyBook}
            {templates.length > 0 && (
              <Dropdown.Item disabled>
                <Header sub>{i18n["roomBook.actions.useTemplate"]}</Header>
              </Dropdown.Item>
            )}
            {templateOptions}
          </Dropdown.Menu>
        </Dropdown>
        <ProgressDialog
          message={i18n["roomBook.title.creation"]}
          visible={loading}
        />
      </div>
    );
  }
}

RoomBookCreator.propTypes = {
  i18n: I18nShape.isRequired,
  projectRoomBooksResourceCreator: func.isRequired,
  unit: shape({
    id: oneOfType([string, number]).isRequired,
    project_id: oneOfType([string, number]).isRequired
  }).isRequired,
  templates: arrayOf(object).isRequired,
  onCreate: func.isRequired,
  pointing: string
};

RoomBookCreator.defaultProps = {
  pointing: "bottom"
};

const mapStateToProps = (state, props) => ({
  i18n: get(state, "i18n", {}),
  unit: props.unit || get(state, "pageContent.unit"),
  templates: get(state, "pageContent.room_books", [])
});

const mapDispatchToProps = dispatch => ({
  projectRoomBooksResourceCreator: projectId =>
    new ProjectRoomBooksResource(dispatch, projectId)
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomBookCreator);
