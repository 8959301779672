import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { cloneDeep } from "lodash";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "code",
      label: "section.attributes.code.label",
      placeholder: "section.attributes.code.placeholder",
      message: "section.attributes.code.error",
      rule: "isRequired"
    },
    {
      id: "name",
      label: "section.attributes.name.label",
      placeholder: "section.attributes.name.placeholder",
      message: "section.attributes.name.error",
      rule: "isRequired"
    },
    {
      id: "external_id",
      label: "section.attributes.external_id.label",
      placeholder: "section.attributes.external_id.placeholder"
    }
  ]
});

class SectionDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isLoading: false,
      model: cloneDeep(props.section)
    };
  }

  onSave = values => {
    this.setState({ isLoading: true });

    const { onSave } = this.props;
    this.props.resource.save(values).then(response => {
      this.props.resource.fetchAll().then(() => {
        this.setState({ open: false, isLoading: false });
        if (onSave) {
          onSave(response.data.section);
        }
      });
    });
  };

  onRemove = () => {
    const { onRemove } = this.props;

    this.setState({ isLoading: true });

    this.props.resource.remove(this.state.model.id).then(() => {
      this.setState({ open: false, isLoading: false });
      this.props.resource.fetchAll().then(() => {
        if (onRemove) {
          onRemove(this.state.model);
        }
      });
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
      model: cloneDeep(this.props.section)
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      model: cloneDeep(this.props.section)
    });
  };

  render() {
    const { i18n, button } = this.props;
    const { model } = this.state;

    const form = FormFactory.create(model, i18n, {
      onChange: model => {
        this.setState({ model });
      }
    });

    return (
      <Modal
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage
            id={model.id ? "section.actions.edit" : "section.actions.new"}
            defaultMessage={
              model.id ? "section.actions.edit" : "section.actions.new"
            }
          />
        </Modal.Header>
        <Modal.Content scrolling>
          <Form id="section" data-component="sectionForm">
            <Form.Group>
              <Form.Field width="4">
                <Field component="Input" {...form.fields.code} />
              </Form.Field>
              <Form.Field width="12">
                <Field component="Input" {...form.fields.name} />
              </Form.Field>
            </Form.Group>
            <FeatureToggleActive featureToggleName="show_external_id">
              <Form.Group>
                <Form.Field width="16">
                  <Field component="Input" {...form.fields.external_id} />
                </Form.Field>
              </Form.Group>
            </FeatureToggleActive>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8} verticalAlign="middle">
              {this.renderDeleteButton(this.state.model)}
            </Grid.Column>
            <Grid.Column textAlign="right" width={8}>
              <Button
                basic
                content={<FormattedMessage id="meta.actions.cancel" />}
                onClick={this.handleClose}
              />
              <Button
                type="submit"
                primary
                data-form="section"
                onClick={form.handleSubmit(this.onSave)}
                loading={this.state.isLoading}
                id={(model.id && "section-edit") || "section-new"}
              >
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  renderDeleteButton(section) {
    const button = (
      <Button
        id="remove"
        color="red"
        basic
        content={<FormattedMessage id="meta.actions.remove" />}
        loading={this.state.isLoading}
        className="left floated element"
      />
    );

    if (section.deletable) {
      const buttons = [
        {
          id: "delete",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemove
        },
        {
          id: "cancel",
          label: "meta.actions.cancel",
          basic: true
        }
      ];

      return (
        <ConfirmationDialog
          title="section.actions.removeDialog.title"
          message="section.actions.removeDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "ok",
        label: "meta.actions.accept",
        basic: true
      }
    ];
    return (
      <ConfirmationDialog
        title="section.actions.undeletableDialog.title"
        message="section.actions.undeletableDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }
}

SectionDialog.propTypes = {
  i18n: PropTypes.object.isRequired,
  section: PropTypes.object,
  button: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired
};

SectionDialog.defaultProps = {
  section: {}
};

export default SectionDialog;
