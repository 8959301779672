import PropTypes from "prop-types";
import React from "react";
import { Form } from "semantic-ui-react";
import FieldError from "./FieldError";
import CurrencyInput from "./CurrencyInput";
import DateInput from "./DateInput";
import "./fieldComponent.scss";

class FieldComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { component } = this.props;
    let Component = null;
    switch (component) {
      case "DateInput":
        Component = DateInput;
        break;
      default:
        Component = Form[component] || this.props.component;
    }

    const options = { ...this.props.props };
    const { className } = this.props;
    if (
      options.multiple &&
      ((options.value === "object" &&
        typeof options.value.length === undefined) ||
        options.value === "")
    ) {
      console.warn(
        'Multiple Select box: Discarded prop "value" as it is not of type array',
        options.value
      );
      options.value = [];
    }

    if (this.props.component === "Input" && this.props.type === "hidden") {
      return (
        <input
          type="hidden"
          name={options.name}
          value={options.defaultValue}
          id={options.id}
          className={options.className}
        />
      );
    }

    return (
      <div data-component="fieldComponent" className={className}>
        {this.requiresLabel() && <label>{this.props.props.label}</label>}
        <Component {...options} />
        <FieldError text={this.props.error && this.props.message} />
      </div>
    );
  }

  requiresLabel() {
    return (
      (this.props.component === CurrencyInput ||
        this.props.component === "CurrencyInput" ||
        this.props.component === DateInput ||
        this.props.component === "DateInput") &&
      this.props.props?.label
    );
  }
}

FieldComponent.propTypes = {
  props: PropTypes.object,
  error: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string
};

FieldComponent.defaultProps = {
  className: ""
};

export default FieldComponent;
