import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  Header,
  Button,
  Icon,
  Grid,
  Accordion
} from "semantic-ui-react";
import { Link } from "react-router";
import ProjectDeadlineLabel from "./ProjectDeadlineLabel";
import { formatDate } from "../../../shared/helpers/formatDate";

class ProjectDeadlineProgressDialog extends Component {
  static propTypes = {
    item: PropTypes.object,
    project: PropTypes.object,
    deadline: PropTypes.object,
    type: PropTypes.string,
    button: PropTypes.node,
    onCompletionFn: PropTypes.func
  };

  constructor(props) {
    super(props);

    // @todo: use redux state not component state
    this.state = { open: false, isLoading: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const message = "projectDeadline.actions.edit";

    return (
      <Modal
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={this.props.button}
        open={this.state.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Modal.Header>
          <FormattedMessage id={message} defaultMessage={message} />
        </Modal.Header>
        <Modal.Content>
          {this.renderContent(
            this.props.item,
            this.props.type,
            this.props.deadline
          )}
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={16} verticalAlign="middle" textAlign="right">
              <Button
                id="close"
                onClick={this.handleClose}
                name="close"
                as="div"
                color="grey"
                basic
                className="right floated element"
              >
                <FormattedMessage
                  id="meta.actions.close"
                  defaultMessage="meta.actions.close"
                />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  renderContent(item, type, deadline) {
    if (type === "section") {
      return this.renderDeadlines(item, type, "unit", deadline);
    }
    if (type === "project") {
      return (
        <div>
          <div>{this.renderDeadlines(item, type, "section", deadline)}</div>
          <div>
            {deadline.expiration_scope === "unit" &&
              this.renderSections(item.sections, deadline)}
          </div>
        </div>
      );
    }
  }

  renderSections(sections, deadline) {
    const panels = [];
    sections.forEach(section => {
      const deadlines = this.renderDeadlines(
        section,
        "section",
        "unit",
        deadline
      );
      if (deadlines.length > 0) {
        panels.push({
          title: {
            key: `${section.id}-title`,
            content: section.name
          },
          content: {
            key: `${section.id}-content`,
            content: <div style={{ marginLeft: "30px" }}>{deadlines}</div>
          }
        });
      }
    });
    return <Accordion panels={panels} />;
  }

  renderDeadlines(item, itemType, childType, deadline) {
    return this.getItemsToRender(item, itemType, deadline).map(i => {
      return this.renderDeadline(i, childType);
    });
  }

  renderDeadline(entry, type) {
    return (
      <Grid
        key={entry.item.id}
        verticalAlign="middle"
        data-model="project_deadline"
        data-id={entry.item.name}
      >
        <Grid.Column width={8}>
          <Header as="h4" style={{ marginBottom: "2px" }}>
            {this.getLabel(entry.item, type)}
          </Header>
          <small>
            {this.renderDate(entry.deadline.expires_at, "clock")}&nbsp;
            {this.renderDate(entry.deadline.completed_at, "checkmark")}
          </small>
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          <ProjectDeadlineLabel
            deadline={entry.deadline}
            onClick={this.onCompletionFn(entry.deadline)}
            loading={this.state.isLoading}
          />
        </Grid.Column>
      </Grid>
    );
  }

  renderDate(date, icon) {
    if (date) {
      return (
        <span>
          <Icon name={icon} /> {formatDate(date)}
        </span>
      );
    }
  }

  getLabel(item, type) {
    const { project } = this.props;
    if (type === "unit") {
      return (
        <Link to={`/projects/${project.slug}/units/${item.id}/room-book`}>
          {`${item.name} - ${item.name_extra || "kein Erwerber"}`}
        </Link>
      );
    }
    return item.name;
  }

  onCompletionFn(deadline) {
    return () => {
      this.setState({ isLoading: true });
      return this.props
        .onCompletionFn(deadline)()
        .then(() => {
          this.setState({ isLoading: false });
        });
    };
  }

  getItemsToRender(item, itemType, deadline) {
    if (itemType === "section") {
      return item.units
        .map(unit => {
          return {
            item: unit,
            deadline: unit.deadlines.filter(dl => {
              return dl.parent_id === (deadline.id || deadline.parent_id);
            })[0]
          };
        })
        .filter(entry => {
          return entry.deadline;
        });
    }
    if (itemType === "project") {
      return item.sections
        .map(section => {
          return {
            item: section,
            deadline: section.deadlines.filter(dl => {
              return (
                dl.parent_id === (deadline.id || deadline.parent_id) &&
                dl.expiration_scope === "section"
              );
            })[0]
          };
        })
        .filter(entry => {
          return entry.deadline;
        });
    }
    return [];
  }
}

export default ProjectDeadlineProgressDialog;
