import { number, objectOf, shape, string } from "prop-types";
import PriceStrategyShape from "./priceStrategy.shape";

export default objectOf(
  shape({
    priceInquiryId: number,
    priceInquiryItemCostId: number,
    subLineItemId: number,
    purchase: number, // purchasePrice
    activityId: number,
    margin: number, // percentage
    retail: number, // purchase * margin
    displayNumber: string, // room book display number
    contractorId: number,
    summary: string,
    subLineItemTitle: string,
    quantity: number,
    taxRate: number, // projects Tax rate
    priceStrategy: PriceStrategyShape,
    tradeLabel: string
  })
);
