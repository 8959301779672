const labelByDueDays = days => {
  if (days < -1) {
    return "activity.overdueDays";
  }
  if (days === -1) {
    return "activity.dueYesterday";
  }
  if (days === 0) {
    return "activity.dueToday";
  }
  if (days === 1) {
    return "activity.dueTomorrow";
  }
  return "activity.dueDays";
};

export default labelByDueDays;
