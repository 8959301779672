import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Segment, Grid, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { getMessageLabels } from "builder_portal/selectors/messages";
import { func, object } from "prop-types";
import MessageLabelDialog from "./MessageLabelDialog";

const MessageLabelsList = ({ filter, setFilter }) => {
  const messageLabels = useSelector(getMessageLabels);

  const handleClick = useCallback((name, id) => {
    setFilter({ ...filter, item: name, message_label: id });
  }, []);

  return (
    <Segment>
      <Grid style={{ paddingBottom: "10px" }}>
        <Grid.Row style={{ padding: "14px 0 5px 0" }}>
          <Grid.Column width={12}>
            <div
              style={{
                fontWeight: 700,
                marginBottom: "0.5rem",
                fontSize: "1.15rem"
              }}
            >
              <FormattedMessage
                id="email.message_labels.list.title"
                defaultMessage="Labels"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <MessageLabelDialog
              button={<Icon name="plus" className="addLabel" />}
            />
          </Grid.Column>
        </Grid.Row>
        <If condition={messageLabels.length === 0}>
          <div className="muted">
            <FormattedMessage
              id="email.message_labels.list.empty"
              defaultMessage="Noch keine Labels vorhanden"
            />
          </div>
        </If>
        {messageLabels.map(ml => (
          <Grid.Row style={{ padding: "5px 0" }} key={ml.id}>
            <Grid.Column width={12}>
              <div
                onClick={() => handleClick(ml.name, ml.id)}
                onKeyDown={() => handleClick(ml.name, ml.id)}
                style={{ cursor: "pointer" }}
                role="button"
                tabIndex={0}
              >
                <Icon name="square full" color={ml.color} />
                <span
                  style={{
                    color: `${ml.name === filter.item ? "#bb3131" : ""}`,
                    fontWeight: `${ml.name === filter.item ? "700" : ""}`
                  }}
                >
                  {ml.name}
                </span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              <MessageLabelDialog
                button={<Icon name="pencil" className="toggleEdit" />}
                model={ml}
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </Segment>
  );
};

MessageLabelsList.propTypes = {
  setFilter: func.isRequired,
  // eslint-disable-next-line  react/forbid-prop-types
  filter: object
};

MessageLabelsList.defaultProps = {
  filter: {}
};

export default MessageLabelsList;
