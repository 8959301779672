import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { Header, Icon, Card, Grid, Label } from "semantic-ui-react";
import "./projectCardItem.scss";

class ProjectCardItem extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    status: PropTypes.string,
    statistics: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, slug, name, image, status, statistics } = this.props;

    const close =
      statistics &&
      statistics.activities &&
      statistics.activities.close != 0 &&
      statistics.activities.close;
    const overdue =
      statistics &&
      statistics.activities &&
      statistics.activities.overdue != 0 &&
      statistics.activities.overdue;

    return (
      <Card data-component="projectCardItem" id={`projectCardItem-${slug}`}>
        <Card.Content className="header">
          <Link to={`/projects/${slug}`}>
            <Header size="large">{name}</Header>
          </Link>
        </Card.Content>
        <Link
          to={`/projects/${slug}`}
          className="item-image buildingPlaceholder"
          style={
            image
              ? {
                  backgroundImage: `url("${image}")`,
                  backgroundSize: "cover"
                }
              : null
          }
        />
        <Card.Content>
          {statistics && statistics.units && statistics.units.count > 0 && (
            <span className="right floated element">
              <Icon name="cube" />
              {statistics.units.count}{" "}
              <FormattedPlural
                value={statistics.units.count}
                zero={i18n["unit.other"]}
                one={i18n["unit.one"]}
                other={i18n["unit.other"]}
                defaultMessage="unit.one"
              />
            </span>
          )}
          {status && <Icon name="clock" />}
          {status}
        </Card.Content>
        <Card.Content extra>
          <Link to={`/projects/${slug}/activity`} className="margin top medium">
            <Grid className="activityButton" verticalAlign="middle">
              <Grid.Column width={6}>
                <Icon name="wrench" />
                {statistics &&
                statistics.activities &&
                statistics.activities.count > 1
                  ? `${statistics.activities.count} `
                  : ""}
                <FormattedPlural
                  value={
                    statistics &&
                    statistics.activities &&
                    statistics.activities.count
                  }
                  zero={i18n["project.activity.other"]}
                  one={i18n["project.activity.one"]}
                  other={i18n["project.activity.other"]}
                  defaultMessage="activity.one"
                />
              </Grid.Column>
              <Grid.Column width={10} textAlign="right">
                {close && (
                  <Label color="orange" basic>
                    <Icon name="attention" />{" "}
                    <FormattedMessage
                      id="activity.amountClose"
                      defaultMessage="activity.amountClose"
                      values={{ amount: close }}
                    />
                  </Label>
                )}
                {overdue && (
                  <Label color="red" basic>
                    <Icon name="attention" />{" "}
                    <FormattedMessage
                      id="activity.amountOverdue"
                      defaultMessage="activity.amountOverdue"
                      values={{ amount: overdue }}
                    />
                  </Label>
                )}
              </Grid.Column>
            </Grid>
          </Link>
        </Card.Content>
      </Card>
    );
  }
}

export default ProjectCardItem;
