import { get } from "lodash";
import { getAccount } from "shared/selectors";

export default class CartSelectorModel {
  constructor(state, lineItem, roomBook) {
    this.state = state;
    this.account = getAccount(state);
    this.lineItem = lineItem;
    this.roomBook = roomBook;
  }

  getBulkSuggestion(activity) {
    const assignableLineItems = this.getSiblingsByAssignableActivity(activity);

    if (assignableLineItems.length >= 2) {
      return {
        section: this.getSection(),
        lineItems: assignableLineItems,
        activity,
        roomBook: this.roomBook
      };
    }
    return null;
  }

  getSiblingsByAssignableActivity(activity) {
    const pd = this.account.getProcessDefinition(activity.process_type);

    return this.getSection().line_items.filter(li => {
      if (li.id === this.lineItem.getId()) {
        return false;
      }
      if (get(li, "buyer_selection_pending")) {
        return false;
      }
      if (get(li, "status_info.current_cart.id")) {
        return false;
      }
      if (!pd.isAssignableToActivity(li, activity)) {
        return false;
      }
      if (!li.activated || li.cancelled) {
        return false;
      }
      return true;
    });
  }

  getSection() {
    return this.roomBook.sections.reduce((accu, section) => {
      return section.sections.reduce((accu, section) => {
        return section.line_items.reduce((accu, li) => {
          if (li.id === this.lineItem.getId()) {
            return section;
          }
          return accu;
        }, accu);
      }, accu);
    }, null);
  }

  getActivities() {
    return get(this.state, "pageContent.available_activities", []);
  }

  getAssignedActivities() {
    return this.getActivities().filter(activity => {
      return this.lineItem.getCurrentActivityId() === activity.id;
    });
  }

  getAssignableActivities() {
    return this.getActivities().filter(activity => {
      const processDefinition = this.account.getProcessDefinition(
        get(activity, "process_type", "sw_process")
      );
      return processDefinition.isAssignableToActivity(
        this.lineItem.currentModel,
        activity
      );
    });
  }

  isCompleted() {
    return this.lineItem.isCompleted();
  }

  isCancellable() {
    return this.lineItem.isCancellable();
  }

  /*
   * An activity is only unassignable from the room book, when the activity is still
   * in one of its potential start states! Otherwise the user should go (and review)
   * the activity and remove it from there.
   */
  isUnassignable() {
    return this.getActivities().some(activity => {
      const processDefinition = this.account.getProcessDefinition(
        get(activity, "process_type", "sw_process")
      );

      if (this.lineItem.getCurrentActivityId() === activity.id) {
        const startStates = processDefinition
          .getProcessStartStates()
          .reduce((accu, state) => {
            return accu.concat(
              get(state, "preconditions.line_item_status", [])
            );
          }, []);
        return startStates.indexOf(this.lineItem.getStatus()) >= 0;
      }
      return false;
    });
  }

  hasAssignedCart() {
    return !!this.lineItem.hasAssignedCart();
  }
}
