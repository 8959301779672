import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Header,
  Icon,
  Segment,
  Modal,
  Grid
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { Formik } from "formik";
import { Form, Select } from "formik-semantic-ui-react";
import CatalogResource from "builder_portal/actions/catalogActions";
import ProductCatalogUploadModal from "builder_portal/components/productGroup/ProductCatalogUploadModal";
import { getCatalogs } from "shared/selectors/catalog";
import { FormattedMessage, useIntl } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import { node } from "prop-types";
import * as Yup from "yup";
import { ProjectSetupDataContext } from "./ProjectSetupDataContext";

const SelectCatalogDialog = ({ button }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const { updateProjectSetup, handleUplaodCompleted } = React.useContext(
    ProjectSetupDataContext
  );
  const catalogs = useSelector(getCatalogs);
  const refResetForm = useRef();

  const validationSchema = Yup.object({
    project_catalog_id: Yup.number().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    price_catalog_id: Yup.number().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const catalogOptions = useMemo(() => {
    return catalogs.map(c => ({
      key: c.id,
      value: c.id,
      text: c.name
    }));
  }, [catalogs]);

  const priceListOptions = useCallback(values => {
    if (!values.project_catalog_id) return [];
    return catalogs
      .find(c => c.id === values.project_catalog_id)
      .price_catalogs.map(pc => ({
        key: pc.id,
        value: pc.id,
        text: pc.name
      }));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
    setOpen(false);
  };

  useEffect(() => {
    new CatalogResource(dispatch).fetchAll();
  }, []);

  const onSubmit = values => {
    updateProjectSetup(values)
      .then(() => {
        handleUplaodCompleted("step_project_catalog_");
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saved"
        );
        handleClose();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        project_catalog_id: "",
        price_catalog_id: ""
      }}
      validationSchema={validationSchema}
    >
      {({ values, submitForm, isSubmitting, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={button}
            closeIcon
          >
            <Modal.Header>
              <FormattedMessage
                id="project.project_setup.dialog.select_catalog.label"
                default="Katalog asuwählen"
              />
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Select
                  name="project_catalog_id"
                  label={intl.formatMessage({
                    id: "project.project_setup.dialog.project_catalog.label"
                  })}
                  options={catalogOptions}
                  errorPrompt={{
                    prompt: false,
                    pointing: false
                  }}
                />
                <Select
                  name="price_catalog_id"
                  label={intl.formatMessage({
                    id: "project.project_setup.dialog.price_list.label"
                  })}
                  options={priceListOptions(values)}
                  errorPrompt={{
                    prompt: false,
                    pointing: false
                  }}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={submitForm}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

SelectCatalogDialog.propTypes = {
  button: node.isRequired
};

const ProjectCatalogsSegment = () => {
  const dispatch = useDispatch();
  const refTriggerButton = React.useRef();
  const refIsTriggerable = React.useRef(false);
  const {
    projectSetup,
    project,
    handleReset,
    handleUplaodCompleted,
    updateProjectSetup,
    renderUploadedMessage,
    prepareActions,
    isLoading
  } = React.useContext(ProjectSetupDataContext);

  useEffect(() => {
    const onClick = refTriggerButton.current?.props?.onClick;
    if (refIsTriggerable.current && typeof onClick === "function") {
      onClick();
      refIsTriggerable.current = false;
    }
  }, [refTriggerButton.current]);

  const handleCreateCatalog = () => {
    refIsTriggerable.current = true;
    new CatalogResource(dispatch)
      .save({
        name: project.name,
        project_ids: [project.id]
      })
      .then(res => {
        if (res.data?.catalog?.id) {
          updateProjectSetup({ project_catalog_id: res.data.catalog.id });
        }
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderProductCatalogUpload = useCallback(
    (className, translationKey) => {
      if (projectSetup.project_catalog_id) {
        return (
          <ProductCatalogUploadModal
            onCompleted={() => handleUplaodCompleted("step_project_catalog_")}
            project_catalog_id={projectSetup.project_catalog_id}
            trigger={
              <Button
                basic
                className={className}
                disabled={isLoading}
                ref={refTriggerButton}
              >
                <FormattedMessage id={translationKey} default="Hochladen" />
              </Button>
            }
          />
        );
      }
      return (
        <Button
          basic
          className={className}
          disabled={isLoading}
          onClick={handleCreateCatalog}
        >
          <FormattedMessage
            id="project.project_setup.dialog.create_project_catalog.label"
            default="Projektkatalog anlegen"
          />
        </Button>
      );
    },
    [projectSetup]
  );

  return (
    <Segment attached>
      <Header as="h3">
        <Icon
          name={`${
            projectSetup.step_project_catalog_completed_at
              ? "check circle"
              : "circle outline"
          }`}
          size="huge"
        />
        <FormattedMessage
          id="project.project_setup.items.project_catalog"
          default="Wohnungen"
        />
      </Header>
      <Container textAlign="right">
        <If condition={!projectSetup.step_project_catalog_completed_at} block>
          <SelectCatalogDialog
            button={
              <Button
                basic
                style={{ width: "43%", height: 50, marginLeft: "10px" }}
              >
                <FormattedMessage
                  id="project.project_setup.dialog.select_catalog.label"
                  default="Hochladen"
                />
              </Button>
            }
          />
          {renderProductCatalogUpload(
            "bigButton",
            `project.project_setup.dialog.${
              projectSetup.project_catalog_id
                ? "upload_catalog"
                : "create_project_catalog"
            }.label`
          )}
        </If>
        <If condition={!!projectSetup.step_project_catalog_completed_at} block>
          <Grid style={{ marginLeft: "35px" }}>
            <Grid.Column width={10} textAlign="left">
              {renderUploadedMessage(
                prepareActions.data(projectSetup, "step_project_catalog_")
              )}
            </Grid.Column>
            <Grid.Column
              width={6}
              textAlign="right"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <Button
                basic
                disabled={isLoading}
                onClick={() => handleReset("step_project_catalog_")}
                className="underlinedButton"
              >
                <FormattedMessage
                  id="project.project_setup.data.reset.label"
                  default="Ändern"
                />
              </Button>
              {renderProductCatalogUpload(
                "underlinedButton",
                "meta.actions.upload"
              )}
            </Grid.Column>
          </Grid>
        </If>
      </Container>
    </Segment>
  );
};

export default ProjectCatalogsSegment;
