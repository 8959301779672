/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectIssuesResource from "builder_portal/actions/projectIssuesActions";
import MasterDataResource from "builder_portal/actions/messaging/masterDataActions";
import ProfileResource from "builder_portal/actions/profileActions";
import { getProfile } from "shared/selectors/profile";
import { getProjectIssues } from "shared/selectors/projectIssues";
import {
  Header,
  Loader,
  Segment,
  Table,
  Grid,
  Dropdown,
  Checkbox
} from "semantic-ui-react";
import {
  getProjects,
  getUserOptions
} from "builder_portal/selectors/masterdata";
import { getAccount } from "shared/selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";
import { uniqBy } from "lodash";

const ProjectIssues = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const projectIssues = useSelector(getProjectIssues);
  const [isLoading, setLoading] = useState(false);
  const [hideResolved, setHideResolved] = useState(true);

  const account = useSelector(getAccount);

  const [filter, setFilter] = useState({
    account_id: account.data?.account?.id,
    project_id: undefined,
    user_id: undefined
  });

  useEffect(() => {
    if (account.data?.account?.id !== filter.account_id)
      setFilter({ ...filter, account_id: account.data.account.id });
  }, [account.data?.account?.id]);

  const projects = useSelector(getProjects);
  const projectOptions = useMemo(() =>
    projects.map(project => ({
      key: project.id,
      value: project.id,
      text: project.name
    }))
  );
  const userOptions = useSelector(getUserOptions);
  const profile = useSelector(getProfile);
  const accountOptions = useMemo(() =>
    (profile?.account_memberships || []).map(am => ({
      key: am.id,
      value: am.id,
      text: am.name
    }))
  );

  const issueTypeOptions = useMemo(() => {
    return uniqBy(projectIssues, "issue_type").map(issue => ({
      key: issue.issue_type,
      value: issue.issue_type,
      content: issue.issue_type,
      text: (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "50%"
          }}
        >
          {issue.issue_type}
        </div>
      )
    }));
  }, [projectIssues]);

  useEffect(() => {
    setLoading(true);
    new ProjectIssuesResource(dispatch)
      .fetchFiltered(filter)
      .then(() => setLoading(false))
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
        setLoading(false);
      });
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    new MasterDataResource(dispatch).fetchAll();
    new ProfileResource(dispatch).get();
  }, []);

  const filteredIssues = useMemo(() => {
    if (hideResolved) return projectIssues.filter(issue => !issue.resolved_at);
    return projectIssues;
  }, [hideResolved, projectIssues]);

  const switchTo = account_id => {
    new ProfileResource(dispatch).save({ account_id }).then(response => {
      if (response.status === 200) {
        window.location.href = "/project_issues";
      }
    });
  };

  return (
    <div>
      <Header as="h2">
        <FormattedMessage
          id="projectIssues.title"
          defaultMessage="projectIssues.title"
        />
      </Header>
      <Segment>
        <Checkbox
          onClick={() => setHideResolved(!hideResolved)}
          label={intl.formatMessage({
            id: "projectIssues.filter.showResolved.label"
          })}
          style={{ marginBottom: "15px" }}
        />
        <Grid>
          <Grid.Column width={4}>
            <Dropdown
              placeholder={intl.formatMessage({
                id: "projectIssues.filter.selectAccount.label"
              })}
              selection
              search
              clearable
              fluid
              name="account_id"
              value={filter.account_id}
              onChange={(_, { value }) =>
                setFilter({ ...filter, account_id: value })
              }
              options={accountOptions}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              placeholder={intl.formatMessage({
                id: "projectIssues.filter.selectProject.label"
              })}
              selection
              search
              clearable
              fluid
              name="project_id"
              value={filter.project_id}
              onChange={(_, { value }) =>
                setFilter({ ...filter, project_id: value })
              }
              options={projectOptions}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              placeholder={intl.formatMessage({
                id: "projectIssues.filter.selectUser.label"
              })}
              selection
              search
              clearable
              fluid
              name="user_id"
              value={filter.user_id}
              onChange={(_, { value }) =>
                setFilter({ ...filter, user_id: value })
              }
              options={userOptions}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Dropdown
              placeholder={intl.formatMessage({
                id: "projectIssues.filter.selectIssueType.label"
              })}
              selection
              search
              clearable
              fluid
              name="issue_type"
              value={filter.issue_type}
              onChange={(_, { value }) =>
                setFilter({ ...filter, issue_type: value })
              }
              options={issueTypeOptions}
            />
          </Grid.Column>
        </Grid>
      </Segment>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="projectIssues.attributes.account.label" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="projectIssues.attributes.project.label" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="projectIssues.attributes.user.label" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="projectIssues.attributes.issueType.label" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="projectIssues.attributes.subject.label" />
            </Table.HeaderCell>
            <Table.HeaderCell width={7}>
              <FormattedMessage id="projectIssues.attributes.message.label" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <If condition={isLoading}>
          <Loader active />
        </If>
        <If condition={!isLoading} block>
          <Table.Body>
            {filteredIssues.map(issue => {
              const isCurrentAccount =
                account.data?.account?.id === issue.account.id;
              return (
                <Table.Row
                  key={issue.id}
                  style={{
                    backgroundColor: issue.resolved_at ? "#E0FFF2" : ""
                  }}
                >
                  <Table.Cell>
                    <div
                      onClick={() => switchTo(issue.account.id)}
                      onKeyDown={() => switchTo(issue.account.id)}
                      style={{ cursor: isCurrentAccount ? "" : "pointer" }}
                    >
                      {issue.account.name}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{issue.project?.name}</Table.Cell>
                  <Table.Cell>{issue.user?.label}</Table.Cell>
                  <Table.Cell>{issue.issue_type}</Table.Cell>
                  <Table.Cell>{issue.subject}</Table.Cell>
                  <Table.Cell>{issue.message}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </If>
      </Table>
      <If condition={!isLoading && projectIssues.length === 0}>
        <Header as="h5" style={{ margin: "20px" }}>
          <FormattedMessage id="projectIssues.messages.empty" />
        </Header>
      </If>
    </div>
  );
};

export default ProjectIssues;
