import PropTypes from "prop-types";
import React from "react";
import { Menu } from "semantic-ui-react";

class TabMenu extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    account: PropTypes.object,
    currentTab: PropTypes.string,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  getMenuItems() {
    const { i18n, onChange, currentTab, account } = this.props;

    const options = [
      {
        id: "local_product_search",
        key: "local_product_search",
        icon: "search",
        active: currentTab === "localProductSearch",
        onClick: () => onChange("localProductSearch"),
        content: i18n["product_group.menu.localProductSearch"]
      },
      {
        id: "global_product_search",
        key: "global_product_search",
        icon: "world",
        active: currentTab === "globalProductSearch",
        onClick: () => onChange("globalProductSearch"),
        content: <span>{i18n["product_group.menu.globalProductSearch"]}</span>
      },
      {
        id: "add_custom_product",
        key: "add_custom_product",
        icon: "plus",
        active: currentTab === "addCustomProduct",
        onClick: () => onChange("addCustomProduct"),
        content: <span>{i18n["product_group.menu.addCustomProduct"]}</span>
      }
    ];
    if (
      !account.isSystemAdmin() &&
      !account.isEnabled("product_manager_enabled")
    ) {
      return [options[0], options[2]];
    }
    if (!account.isSystemAdmin() && !account.hasEditProductsRight()) {
      return [options[0]];
    }
    return options;
  }

  render() {
    return (
      <Menu
        data-component="productSearchTabMenu"
        stackable
        attached="top"
        items={this.getMenuItems()}
      />
    );
  }
}

export default TabMenu;
