import { createSelector } from "reselect";
import { get } from "lodash";
import { getPageContent } from "../../shared/selectors";

const getRoomBookUpgradeBundles = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, ["room_book_upgrade_bundles"], []).filter(
      bundle => !bundle.default
    );
  }
);

export default getRoomBookUpgradeBundles;
