import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import ProductImporter from "./ProductImporter";
import "./productImportDialog.scss";
import imagePlaceholder from "../../../../assets/images/placeholder-square.png";

class ProductImportDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    resources: PropTypes.object,
    button: PropTypes.object,
    handleUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { open: false, showCloseIcon: true };
  }

  setShowCloseIcon = val => {
    this.setState({ showCloseIcon: val });
  };

  render() {
    const { i18n, resources, handleUpdate, button } = this.props;
    const { showCloseIcon } = this.state;

    return (
      <Modal
        data-component="productImportDialog"
        size="fullscreen"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon={showCloseIcon}
        trigger={button}
        open={this.state.open}
        onOpen={() => this.setState({ ...this.state, open: true })}
        onClose={() => this.setState({ ...this.state, open: false })}
      >
        <Modal.Header>
          <FormattedMessage
            id="product_group.search.title"
            defaultMessage="product_group.search.title"
          />
        </Modal.Header>
        <Modal.Content>
          <ProductImporter
            i18n={i18n}
            resources={resources}
            handleUpdate={handleUpdate}
            imagePlaceholder={imagePlaceholder}
            setShowCloseIcon={this.setShowCloseIcon}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default ProductImportDialog;
