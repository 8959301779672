import React, { useReducer, useEffect } from "react";
import {
  string,
  instanceOf,
  objectOf,
  oneOfType,
  array,
  shape,
  bool
} from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Form, Header, Icon, Segment } from "semantic-ui-react";
import { get } from "lodash";
import { I18nShape } from "shared/shapes/i18n.shape";
import FileUploadSegment from "./FileUploadSegment";
import { FormDefinition } from "../../../../shared/components/forms/FormDefinition";
import Field from "../../../../shared/components/forms/FieldComponent";

import "./accountTab.scss";
import Resource from "../../../../shared/actions/resourceActions";

const formStateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FORM":
      return {
        ...state,
        ...action.changedState
      };
    case "UPDATE_CONFIG_PARAMS":
      return {
        ...state,
        // eslint-disable-next-line babel/camelcase
        config_params: {
          ...state.config_params,
          ...action.newParams
        }
      };
    default:
      return state;
  }
};

const AccountTab = ({
  accountConfig,
  i18n,
  accountsResource,
  userGroup,
  isLoading
}) => {
  const [formState, dispatch] = useReducer(formStateReducer, accountConfig);

  useEffect(() => {
    dispatch({ type: "UPDATE_FORM", changedState: accountConfig });
  }, [accountConfig]);

  const formDefinition = new FormDefinition({
    fields: [
      {
        id: "favicon_html",
        accessor: "config_params.buyer_portal_favicon_html",
        label: i18n["account.attributes.buyer_portal.favicon_html.title"]
      },
      {
        id: "styling",
        accessor: "config_params.buyer_portal_login_styling",
        label: i18n["account.attributes.buyer_portal.styling.title"]
      },
      {
        id: "accept_terms_label",
        accessor: "config_params.buyer_portal_login_accept_terms_label",
        label: i18n["account.attributes.buyer_portal.accept_terms_label.title"]
      },
      {
        id: "imprint",
        accessor: "config_params.buyer_portal_imprint",
        label: i18n["account.attributes.buyer_portal.imprint.title"]
      },
      {
        id: "allowed_origins",
        accessor: "config_params.buyer_portal_allowed_origins",
        label: i18n["account.attributes.buyer_portal.allowed_origins.title"]
      }
    ]
  });

  const form = formDefinition.create(formState, i18n, {
    onChange: changedState => {
      dispatch({ type: "UPDATE_FORM", changedState });
    }
  });

  return (
    <Segment data-component="AccountTab" loading={!accountConfig}>
      <FileUploadSegment fileType="image" name="logo" />
      <FileUploadSegment fileType="pdf" name="terms_and_conditions" />
      <FileUploadSegment fileType="pdf" name="data_protection_policy" />
      <Form
        onSubmit={form.handleSubmit(() => accountsResource.save(formState))}
        id="buyerPortalContent"
      >
        <Segment basic>
          <Form.Field>
            <Field component="TextArea" {...form.fields.imprint} />
          </Form.Field>
        </Segment>
        <Segment basic textAlign="right">
          <Form.Button
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            id="save"
          >
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Form.Button>
        </Segment>
        {userGroup === "admin" && (
          <div data-component="AdminConfigSegment">
            <Segment basic>
              <Header as="h3">
                <FormattedMessage
                  id="account.attributes.buyer_portal.admin_area"
                  defaultMessage="Adminbereich"
                />
              </Header>
            </Segment>
            <Segment basic>
              <Form.Field>
                <Field
                  component="TextArea"
                  {...form.fields.accept_terms_label}
                />
              </Form.Field>
            </Segment>
            <Segment basic id="segment-favicon">
              <Form.Field>
                <Field component="TextArea" {...form.fields.favicon_html} />
              </Form.Field>
            </Segment>
            <Segment basic id="segment-styling">
              <Form.Field>
                <Field component="TextArea" {...form.fields.styling} />
              </Form.Field>
            </Segment>
            <Segment basic id="segment-allowed-origins">
              <Form.Field>
                <Field component="TextArea" {...form.fields.allowed_origins} />
              </Form.Field>
            </Segment>
          </div>
        )}
      </Form>
    </Segment>
  );
};

AccountTab.propTypes = {
  accountConfig: shape({
    data_protection_policy: objectOf(string),
    logo: objectOf(string),
    terms_and_conditions: objectOf(string),
    config_params: objectOf(oneOfType([array, string]))
  }),
  i18n: I18nShape.isRequired,
  accountsResource: instanceOf(Resource).isRequired,
  userGroup: string.isRequired,
  isLoading: bool
};

AccountTab.defaultProps = {
  accountConfig: {},
  isLoading: false
};

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading["/api/v1/account"],
    i18n: state.i18n,
    userGroup: get(state.account, "user.group"),
    accountConfig: get(state.pageContent, "account")
  };
};

export default connect(mapStateToProps)(AccountTab);
