import { UPDATE_DIALOG } from "../constants/appConstants";

export const dialog = (state = "", action) => {
  switch (action.type) {
    case UPDATE_DIALOG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
