import React from "react";
import {
  Button,
  Card,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isVideoUrlValid, isMp4Url } from "shared/helpers/matchers";
import "./productAttachments.scss";

import { ProductShape } from "shared/shapes/product.shape";
import HasEditProductsRight from "shared/components/authorization/HasEditProductsRight";
import { ProductVideosResource } from "../../actions/productActions";
import { I18nShape } from "../../../shared/shapes/i18n.shape";

const videoThumbnail = url => {
  if (isMp4Url(url)) return null;
  const id = (url || "").split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0];
  return `https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`;
};

class ProductAttachments extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      attachmentDialogOpen: false,
      attachmentUrl: "",
      validVideoUrl: true
    };
  }

  submitAttachment = () => {
    const { videosResource, product, onChange } = this.props;
    const { attachmentUrl } = this.state;

    if (this.validateUrl(attachmentUrl)) {
      return videosResource(product.id)
        .save({ url: attachmentUrl })
        .then(() => {
          this.setState({ attachmentDialogOpen: false });
          if (onChange) {
            onChange();
          }
        });
    }
    return Promise.resolve();
  };

  removeVideo = id => {
    const { videosResource, product, onChange } = this.props;

    return videosResource(product.id)
      .remove(id)
      .then(() => {
        if (onChange) {
          onChange();
        }
      });
  };

  changeAttachmentUrl = (_, { value }) => {
    this.setState(prev => ({
      attachmentUrl: value,
      validVideoUrl: prev.validVideoUrl
        ? prev.validVideoUrl
        : isVideoUrlValid(value)
    }));
  };

  validateUrl = url => {
    const validVideoUrl = isVideoUrlValid(url);
    this.setState({ validVideoUrl });
    return validVideoUrl;
  };

  attachmentDialog() {
    const { validVideoUrl, attachmentDialogOpen, attachmentUrl } = this.state;
    const { i18n } = this.props;

    const triggerButton = (
      <Button labelPosition="left">
        <Icon name="youtube play" />
        <FormattedMessage
          id="product.attachment.actions.add"
          defaultMessage="Anhang&nbsp;verlinken"
        />
      </Button>
    );

    return (
      <Modal
        size="tiny"
        trigger={triggerButton}
        open={attachmentDialogOpen}
        onOpen={() => this.setState({ attachmentDialogOpen: true })}
        onClose={() => this.setState({ attachmentDialogOpen: false })}
        closeIcon
      >
        <Modal.Header>
          <FormattedMessage
            id="product.attachment.title"
            defaultMessage="Anhang&nbsp;verlinken"
          />
        </Modal.Header>

        <Modal.Content>
          <Form
            id="submitAttachment"
            error={!!(attachmentUrl && !validVideoUrl)}
            onSubmit={this.submitAttachment}
          >
            <Form.Input
              name="attachmentUrl"
              autoComplete="off"
              placeholder="https://www.youtube.com/watch?v=v9d0gejFHc"
              onChange={this.changeAttachmentUrl}
            />
            <Message error>
              <FormattedMessage
                id="product.attachment.invalid"
                defaultMessage="bitte einen vollständigen YouTube-Link angeben"
              />
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            positive
            type="submit"
            form="submitAttachment"
            content={i18n["product.attachment.actions.add"]}
            icon="youtube play"
            labelPosition="right"
          />
        </Modal.Actions>
      </Modal>
    );
  }

  attachmentList() {
    const { i18n, product } = this.props;
    const attachmentList = product.videos.map(({ id, url }) => ({
      id,
      service: "youtube",
      url,
      thumbnailUrl: videoThumbnail(url)
    }));

    if (attachmentList.length) {
      return (
        <Card.Group itemsPerRow="3" stackable>
          {attachmentList.map(({ id, thumbnailUrl, url }) => (
            <Card key={id} data-component="attachment-card">
              <a href={url} target="_external" style={{ height: "148px" }}>
                <If condition={isMp4Url(url)}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    src={url}
                    type="video/mp4"
                    className="video"
                    controls
                  />
                </If>
                <If condition={!isMp4Url(url)}>
                  <Image src={thumbnailUrl} className="fluid" />
                </If>
              </a>
              <Card.Content extra textAlign="center">
                <a
                  role="button"
                  data-tooltip={i18n["product.attachment.actions.remove"]}
                  onClick={() => this.removeVideo(id)}
                >
                  <Icon name="remove circle" size="large" color="grey" />
                </a>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      );
    }

    return <p>Es wurden noch keine Produktvideos verlinkt</p>;
  }

  render() {
    return (
      <Segment attached>
        <Grid columns={2} stackable>
          <Grid.Row verticalAlign="middle">
            <Grid.Column textAlign="left">
              <Header as="h4">
                <FormattedMessage
                  id="product.attachment.title"
                  defaultMessage="Produktanhänge"
                />
              </Header>
            </Grid.Column>
            <HasEditProductsRight>
              <Grid.Column textAlign="right">
                {this.attachmentDialog()}
              </Grid.Column>
            </HasEditProductsRight>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>{this.attachmentList()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

ProductAttachments.propTypes = {
  i18n: I18nShape.isRequired,
  product: ProductShape.isRequired,
  onChange: PropTypes.func.isRequired,
  videosResource: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n
  };
};

const mapDispatchToProps = dispatch => ({
  videosResource: productId => new ProductVideosResource(dispatch, productId)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttachments);
