/* eslint-disable react/static-property-placement */
/* eslint-disable react/require-default-props */
import React, { createRef } from "react";

import Modals from "builder_portal/components/dialogs/Modals";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Sticky } from "semantic-ui-react";
import FlashActionMessage from "../flashActions/FlashActionMessage";
import "./pageLayout.scss";
import GrowlMessage from "../../../shared/components/growlMessage/GrowlMessage";

class PageLayout extends React.Component {
  contextRef = createRef();

  static propTypes = {
    children: PropTypes.node,
    pageName: PropTypes.string,
    isLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  };

  render() {
    const { children, pageName, isLoading } = this.props;

    return (
      <div data-component="pageLayout" data-route={pageName}>
        <Sticky
          context={this.contextRef}
          offset={70}
          styleElement={{ height: 0 }}
        >
          <GrowlMessage />
        </Sticky>
        <div
          className={`loadingIndicator ${isLoading ? "loading" : ""}`}
          data-cucumber="x-cucumber-pending-requests"
        />
        <div
          ref={this.contextRef}
          style={{ height: "100vh", display: "flex", overflow: "hidden" }}
        >
          {children}
        </div>
        <FlashActionMessage />
        <Modals />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { i18n, isLoading } = state;
  const {
    location: { pathname },
    router: { routes }
  } = props;

  const route = routes.filter(x => x.name && x.path === pathname);

  return {
    i18n,
    pageName: route[0]?.name,
    isLoading
  };
};

export default connect(mapStateToProps, null)(PageLayout);
