import React from "react";
import { Formik } from "formik";
import { Form, Select, Input } from "formik-semantic-ui-react";
import { PropTypes } from "prop-types";
import { Button } from "semantic-ui-react";
import { browserHistory } from "shared/routes/browserHistory";
import { If } from "shared/components/elements/Conditions";
import { ProductMetaDataResource } from "builder_portal/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { getProductsMetaData } from "shared/selectors/productsMetaData";
import { useIntl } from "react-intl";
import { getAccount } from "shared/selectors";

const NUMERICAL_KEYS = ["product_catalog_id"];

const getProductCatalogId = paramsValues => {
  try {
    return parseInt(paramsValues.product_catalog_id, 10);
  } catch (err) {
    return "";
  }
};

const ProductFilters = ({ handleFilter, paramsValues, locationSearch }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const productMetaDataResource = new ProductMetaDataResource(dispatch);
  const productsMetaData = useSelector(getProductsMetaData);
  const { suppliers, product_types, trades } = productsMetaData;
  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = React.useState({
    term: "",
    product_catalog_id: getProductCatalogId(paramsValues),
    supplier_id: "",
    series: "",
    product_type_ids: "",
    trades: ""
  });

  const account = useSelector(getAccount);
  const accountId = account.data?.account?.id;

  React.useEffect(() => {
    setLoading(true);
    productMetaDataResource
      .fetchAll()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const convertDataToOptions = data => {
    return (
      data?.map(item => {
        return {
          key: item?.id?.toString() || item?.name || item,
          text: item?.name || item,
          value: item.id?.toString() || item?.name || item
        };
      }) || []
    )?.sort((a, b) => a?.text?.localeCompare(b?.text));
  };

  React.useEffect(() => {
    const paramsTemp = { ...paramsValues };

    if (!paramsValues.product_catalog_id && productsMetaData.product_catalogs) {
      handleFilter({
        ...paramsValues,
        product_catalog_id: productsMetaData.product_catalogs[0]?.id
      });
      return;
    }
    const keys = [
      "term",
      "product_catalog_id",
      "supplier_id",
      "series",
      "product_type_ids",
      "trades"
    ];
    keys.forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (!paramsValues?.hasOwnProperty(key)) {
        paramsTemp[key] = "";
      }
      if (
        // eslint-disable-next-line no-prototype-builtins
        paramsValues?.hasOwnProperty(key) &&
        NUMERICAL_KEYS.indexOf(key) > -1
      ) {
        if (params[key]) paramsTemp[key] = parseInt(params[key], 10);
      }
    });

    setParams(paramsTemp);
  }, [paramsValues]);

  React.useEffect(() => {
    const lsParams = JSON.parse(localStorage.getItem("productFilters"));
    if (!lsParams) {
      return;
    }

    if (lsParams[accountId]) {
      const paramsTemp = { ...lsParams[accountId], ...paramsValues };
      setParams(paramsTemp);
      handleFilter(paramsTemp);
    }
  }, []);

  // create a method to update local storage and params
  const updateParamsInLocalStorage = (vals, key, value) => {
    const paramsTemp = { ...vals };
    paramsTemp[key] = value;
    setParams(paramsTemp);
    localStorage.setItem(
      "productFilters",
      JSON.stringify({
        [accountId]: {
          ...paramsTemp
        }
      })
    );
  };

  return (
    <Formik initialValues={params} enableReinitialize>
      {({ values }) => (
        <Form>
          <If condition={productsMetaData.product_catalogs?.length > 1}>
            <Select
              search
              name="product_catalog_id"
              options={
                productsMetaData.product_catalogs
                  ?.map(product_catalog => {
                    return {
                      key: product_catalog.id,
                      text: product_catalog.name,
                      value: product_catalog.id
                    };
                  })
                  .sort((a, b) => a.text.localeCompare(b.text)) || []
              }
              onChange={(e, { value }) => {
                updateParamsInLocalStorage(values, "product_catalog_id", value);
                handleFilter({ ...values, product_catalog_id: value });
              }}
              label={intl.formatMessage({
                id: "product.filters.productCatalog"
              })}
              loading={loading}
            />
          </If>

          <Input
            name="term"
            icon="search"
            loading={loading}
            label={intl.formatMessage({
              id: "product.filters.term"
            })}
            onChange={(e, { value }) => {
              updateParamsInLocalStorage(values, "term", value);
              handleFilter({ ...values, term: value });
            }}
          />

          <Select
            search
            name="supplier_id"
            options={
              suppliers
                ?.map(supplier => {
                  return {
                    key: supplier.slug,
                    text: supplier.name,
                    value: supplier.slug
                  };
                })
                .sort((a, b) => a.text.localeCompare(b.text)) || []
            }
            onChange={(e, { value }) => {
              updateParamsInLocalStorage(values, "supplier_id", value);
              handleFilter({ ...values, supplier_id: value });
            }}
            clearable
            label={intl.formatMessage({
              id: "product.filters.supplier"
            })}
            loading={loading}
          />
          {values?.supplier_id &&
            convertDataToOptions(
              suppliers?.find(supplier => supplier.slug === values?.supplier_id)
                ?.series
            ).length > 0 && (
              <Select
                search
                name="series"
                label={intl.formatMessage({
                  id: "product.filters.series"
                })}
                options={convertDataToOptions(
                  suppliers?.find(
                    supplier => supplier.slug === values?.supplier_id
                  )?.series
                )}
                disabled={!values?.supplier_id}
                onChange={(e, { value }) => {
                  updateParamsInLocalStorage(values, "series", value);
                  handleFilter({ ...values, series: value });
                }}
                clearable
                loading={loading}
              />
            )}
          <Select
            name="product_type_ids"
            options={convertDataToOptions(product_types)}
            onChange={(e, { value }) => {
              updateParamsInLocalStorage(values, "product_type_ids", value);
              handleFilter({ ...values, product_type_ids: value });
            }}
            search
            clearable
            loading={loading}
            label={intl.formatMessage({
              id: "product.filters.productTypes"
            })}
          />

          <Select
            name="trades"
            options={convertDataToOptions(trades)}
            search
            onChange={(e, { value }) => {
              updateParamsInLocalStorage(values, "trades", value);
              handleFilter({ ...values, trades: value });
            }}
            loading={loading}
            label={intl.formatMessage({
              id: "product.filters.trades"
            })}
            clearable
          />

          <Button
            onClick={() =>
              browserHistory.push(
                `/products?product_catalog_id=${params.product_catalog_id}`
              )
            }
            basic
            fluid
          >
            {intl.formatMessage({
              id: "activity.timeline.filter.reset"
            })}
          </Button>

          <Button
            type="submit"
            onClick={() => browserHistory.push(`/products${locationSearch}`)}
            primary
            fluid
            style={{ marginTop: "1rem" }}
          >
            {intl.formatMessage({
              id: "product.filters.search"
            })}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

ProductFilters.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paramsValues: PropTypes.object,
  locationSearch: PropTypes.string
};

ProductFilters.defaultProps = {
  paramsValues: {},
  locationSearch: ""
};

export default ProductFilters;
