import React, { useState } from "react";
import { Modal, Grid, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "builder_portal/components/product/productFiles.scss";

const ProductFilesListDialog = ({ productFiles }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderProductFiles = () => {
    return productFiles.map(file => (
      <Grid.Row key={file.id} className="highlight-row">
        <Grid.Column width={12}>
          <a href={file.url} target="_blank" rel="noreferrer">
            {file.file_name}
          </a>
        </Grid.Column>
      </Grid.Row>
    ));
  };

  return (
    <Modal
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={
        <div style={{ cursor: "pointer", color: "#2185d0", marginTop: "5px" }}>
          <FormattedMessage
            id="product.attachment.file.listDialog.link"
            default="Product file list"
            values={{ n: productFiles.length }}
          />
        </div>
      }
      closeIcon
    >
      <Modal.Header>
        <FormattedMessage
          id="product.attachment.file.listDialog.title"
          default="Product files"
        />
      </Modal.Header>
      <Modal.Content scrolling>
        <Grid data-component="product-files-card">{renderProductFiles()}</Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={handleClose}>
          <FormattedMessage id="meta.actions.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ProductFilesListDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  productFiles: PropTypes.array
};

ProductFilesListDialog.defaultProps = {
  productFiles: []
};

export default ProductFilesListDialog;
