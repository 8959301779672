import React from "react";
import { Form, Message } from "semantic-ui-react";
import { get } from "lodash";
import { connect } from "react-redux";
import apiRequest from "shared/network/apiRequest";
import { AccountResource } from "builder_portal/actions/accountActions";
import { arrayOf, func, instanceOf } from "prop-types";
import { UserShape } from "shared/shapes/user.shape";

class MigrateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceUserId: null,
      targetUserId: null,
      error: null,
      success: null,
      loading: false
    };
  }

  componentDidMount() {
    const { accountResource } = this.props;
    accountResource.get(true);
  }

  handleSubmit = () => {
    const { sourceUserId, targetUserId } = this.state;
    const { submit } = this.props;
    this.setState({ loading: true });

    submit(sourceUserId, targetUserId)
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      })
      .catch(error => {
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        });
        // prevent silent catch
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  render() {
    const { users = [] } = this.props;
    const { loading, error, success } = this.state;

    const userOptions = users.map(user => {
      return {
        key: `user-${user.id}`,
        text: `${user.email}`,
        value: `${user.id}`
      };
    });

    return (
      <div>
        <h2>Migrate a user to another user</h2>
        <p>
          This action migrates all dependencies belonging to the source user to
          the target user and then deletes the source user.
        </p>
        <Form
          loading={loading}
          success={success}
          error={!!error}
          onSubmit={this.handleSubmit}
        >
          <Message
            success
            header="Success!"
            content="Please check the results!"
          />
          <Message error header="Error" content={error} />
          <Form.Select
            name="sourceUserId"
            options={userOptions}
            onChange={(i, { value }) => this.setState({ sourceUserId: value })}
            label="Source User"
            placeholder="Source User"
            required
            width="4"
          />
          <Form.Select
            name="targetUserId"
            options={userOptions}
            onChange={(i, { value }) => this.setState({ targetUserId: value })}
            label="Target User"
            placeholder="Target User"
            required
            width="4"
          />
          <Form.Button width="4">Submit</Form.Button>
        </Form>
      </div>
    );
  }
}

const getSubmit = dispatch => (sourceId, targetId) =>
  apiRequest.post("/api/v1/support_utils/migrate_user", dispatch, {
    user: {
      source_id: sourceId,
      target_id: targetId
    }
  });

MigrateUser.propTypes = {
  accountResource: instanceOf(AccountResource).isRequired,
  submit: func.isRequired,
  users: arrayOf(UserShape).isRequired
};

const mapStateToProps = state => ({ users: get(state.pageContent, "users") });

const mapDispatchToProps = dispatch => ({
  accountResource: new AccountResource(dispatch),
  submit: getSubmit(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MigrateUser);
