import { createSelector } from "reselect";
import { keyBy, get } from "lodash";
import { getPageContent } from "./base";

export const getCatalogs = createSelector([getPageContent], pageContent => {
  return pageContent?.project_catalogs || [];
});

export const getCatalogsForProject = createSelector(
  [getCatalogs, (_, props) => props.projectId],
  (catalogs, projectId) => {
    const priceCatalogMatcher = pc => pc.project_ids.includes(projectId);
    return catalogs
      .filter(catalog => {
        return catalog.price_catalogs.some(priceCatalogMatcher);
      })
      .map(catalog => {
        return {
          ...catalog,
          price_catalogs: catalog.price_catalogs.filter(priceCatalogMatcher)
        };
      });
  }
);

export const makeGetCatalogById = () => {
  return createSelector(
    [getCatalogs, (_, props) => props.catalogId],
    (catalogs, catalogId) => {
      return catalogs.find(catalog => {
        return catalog.slug === catalogId;
      });
    }
  );
};

export const makeGetCatalogNameById = () => {
  const getCatalogById = makeGetCatalogById();
  return createSelector([getCatalogById], catalog => {
    return catalog?.name;
  });
};

export const getPriceCatalogs = createSelector([getCatalogs], catalogs => {
  return catalogs.flatMap(catalog => {
    return catalog.price_catalogs || [];
  });
});

export const getPriceCatalogsById = createSelector(
  [getPriceCatalogs],
  priceCatalogs => {
    return keyBy(priceCatalogs, c => c.id);
  }
);

export const getDesignLineGroups = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "design_line_groups", []);
  }
);

export const getAllDesignLines = createSelector(
  [getDesignLineGroups],
  designLineGroups => {
    const designLines = [];
    designLineGroups.forEach(dlg => designLines.push(...dlg.design_lines));
    return designLines;
  }
);
