import Resources from "shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export default class MessagesResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "message", `/api/v1/messages`);
    this.refreshPageContentAfterCommit = true;
  }

  read(id) {
    return apiRequest
      .put(`${this.url}/${id}/read`, this.dispatch, {})
      .then(this.propagateUpdate.bind(this));
  }

  unread(id) {
    return apiRequest
      .put(`${this.url}/${id}/unread`, this.dispatch, {})
      .then(this.propagateUpdate.bind(this));
  }
}
