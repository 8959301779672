import { keyBy, difference } from "lodash";

export default class LineItemBatchAssignment {
  constructor(initialModel, currentModel) {
    this.initialModel = initialModel;
    this.currentModel = currentModel;

    this.initialUnits = keyBy(this.initialModel.units, "id");
    this.trade = currentModel.trade;
    this.relevantTrades = currentModel.trades;
    this.units = currentModel.units;
    this.statistics = currentModel.statistics;
  }

  getBatchRequest() {
    const units = this.units.reduce((accu, unit) => {
      const original = this.initialUnits[unit.id];
      accu.push({
        additions: difference(
          unit.line_items.assigned_ids,
          original.line_items.assigned_ids
        ),
        deletions: difference(
          original.line_items.assigned_ids,
          unit.line_items.assigned_ids
        )
      });
      return accu;
    }, []);

    return {
      units
    };
  }

  select(unitId) {
    const units = this.units.map(unit => {
      if (unit.id === unitId) {
        return {
          ...unit,
          line_items: {
            ...unit.line_items,
            assignable_ids: [],
            assigned_ids: unit.line_items.assigned_ids.concat(
              unit.line_items.assignable_ids
            )
          }
        };
      }
      return unit;
    });

    return new LineItemBatchAssignment(this.initialModel, {
      ...this.currentModel,
      units
    });
  }

  deselect(unitId) {
    const units = this.units.map(unit => {
      if (unit.id === unitId) {
        return {
          ...unit,
          line_items: {
            ...unit.line_items,
            assigned_ids: [],
            assignable_ids: unit.line_items.assigned_ids.concat(
              unit.line_items.assignable_ids
            )
          }
        };
      }
      return unit;
    });

    return new LineItemBatchAssignment(this.initialModel, {
      ...this.currentModel,
      units
    });
  }

  isRelevantTrade(trade) {
    return this.relevantTrades.indexOf(trade) >= 0;
  }

  equals(assignment) {
    return (
      assignment &&
      assignment.initialModel.created_at === this.initialModel.created_at
    );
  }

  isEdiable() {
    return this.currentModel.editable;
  }
}
