import React from "react";
import {
  Icon,
  Table,
  Grid,
  Header,
  Dropdown,
  Segment,
  Divider
} from "semantic-ui-react";
import PropTypes, { arrayOf } from "prop-types";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { FormattedMessage } from "react-intl";
import { tradeLabel } from "../../../helpers/tradeLabel";

const CostsTable = ({ carts, i18n, summaryByTrade, trades }) => {
  const types = [
    { key: "net", value: "net", text: i18n["activity.costs.net"] },
    { key: "gross", value: "gross", text: i18n["activity.costs.gross"] }
  ];
  const [type, setType] = React.useState("net");

  const calculateTotal = React.useCallback(
    typeOfTotal => {
      return (
        summaryByTrade &&
        Object.keys(summaryByTrade).reduce(
          (acc, trade) => acc + summaryByTrade[trade][typeOfTotal],
          0
        )
      );
    },
    [summaryByTrade]
  );

  return (
    <Segment>
      <Grid verticalAlign="middle">
        <Grid.Column floated="left" width={5}>
          <Header as="h3">
            <FormattedMessage id="activity.costs.summary" />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <Dropdown
            fluid
            selection
            options={types}
            value={type}
            onChange={(e, { value }) => setType(value)}
          />
        </Grid.Column>
      </Grid>
      <Divider />

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              <FormattedMessage id="activity.costs.trade" />
            </Table.HeaderCell>
            {/* <Table.HeaderCell>
              # <FormattedMessage id="activity.costs.position" />
            </Table.HeaderCell> */}
            <Table.HeaderCell textAlign="right">
              <FormattedMessage id="activity.costs.additionalCosts" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage id="activity.costs.additionalSales" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage id="activity.costs.return" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {summaryByTrade &&
            Object.keys(summaryByTrade).map(trade => (
              <RenderTableRows
                trade={trade}
                key={trade}
                summaryByTrade={summaryByTrade}
                type={type}
                carts={carts}
                trades={trades}
              />
            ))}
          <Table.Row>
            <Table.Cell>
              <strong>{i18n["activity.costs.total.label"]}</strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <strong>
                <FormatCurrency
                  amount={calculateTotal(`${type}_total_costs`)}
                />
              </strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <strong>
                <FormatCurrency
                  amount={calculateTotal(`${type}_total_revenue`)}
                />
              </strong>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <strong>
                <FormatCurrency
                  amount={calculateTotal(`${type}_total_profit`)}
                />
              </strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
};

const RenderTableRows = ({ trade, summaryByTrade, type, carts, trades }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const cartsLength = carts.length;

  const getArrayOfTrades = tradeName => {
    return carts.map(cart => cart.summary_by_trade[tradeName]) || [];
  };

  return (
    <>
      <Table.Row key={trade}>
        <Table.Cell>
          {cartsLength > 1 && (
            <Icon
              name={collapsed ? "angle down" : "angle right"}
              link
              onClick={() => setCollapsed(!collapsed)}
              size="large"
            />
          )}
          {tradeLabel(trade, trades)}
        </Table.Cell>
        {/* <Table.Cell>X number</Table.Cell> */}
        <Table.Cell textAlign="right">
          <FormatCurrency
            amount={summaryByTrade[trade][`${type}_total_costs`]}
          />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <FormatCurrency
            amount={summaryByTrade[trade][`${type}_total_revenue`]}
          />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <FormatCurrency
            amount={summaryByTrade[trade][`${type}_total_profit`]}
          />
        </Table.Cell>
      </Table.Row>
      {collapsed &&
        cartsLength > 1 &&
        getArrayOfTrades(trade).map((cart, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Table.Row key={`${trade}-${index}`}>
            <Table.Cell>
              <span style={{ marginLeft: 26 }}>
                <FormattedMessage id="activity.costs.cart" /> {index + 1}{" "}
              </span>
            </Table.Cell>
            {/* <Table.Cell>X number</Table.Cell> */}
            <Table.Cell textAlign="right">
              <FormatCurrency amount={cart[`${type}_total_costs`]} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <FormatCurrency amount={cart[`${type}_total_revenue`]} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <FormatCurrency amount={cart[`${type}_total_profit`]} />
            </Table.Cell>
          </Table.Row>
        ))}
    </>
  );
};

CostsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object.isRequired,
  carts: arrayOf(PropTypes.object).isRequired,
  summaryByTrade: PropTypes.shape({
    trade: PropTypes.shape({
      net_total_costs: PropTypes.number,
      net_total_revenue: PropTypes.number,
      net_total_profit: PropTypes.number,
      gross_total_costs: PropTypes.number,
      gross_total_revenue: PropTypes.number,
      gross_total_profit: PropTypes.number
    })
  }).isRequired,
  trades: arrayOf(PropTypes.object).isRequired
};

RenderTableRows.propTypes = {
  trade: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  summaryByTrade: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  carts: arrayOf(PropTypes.object).isRequired,
  trades: arrayOf(PropTypes.object).isRequired
};

export default CostsTable;
