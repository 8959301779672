import PropTypes from "prop-types";
import React from "react";
import { Segment, Loader } from "semantic-ui-react";
import { get } from "lodash";
import { connect } from "react-redux";
import ZendeskResource from "../../actions/zendeskActions";

class ZendeskLogin extends React.Component {
  componentDidMount() {
    this.props.actions.zendeskToken
      .get(get(this.props, "location.query.timestamp"))
      .then(response => {
        if (response.status == 200) {
          const url = get(response, "data.zendesk.redirect_url");
          const returnTo = get(this.props, "location.query.return_to");
          window.location.href = returnTo
            ? `${url}&return_to=${returnTo}`
            : url;
        }
      });
  }

  render() {
    return (
      <Segment>
        <div style={{ margin: "100px" }}>
          <Loader active />
        </div>
      </Segment>
    );
  }
}

ZendeskLogin.propTypes = {
  i18n: PropTypes.object,
  actions: PropTypes.object
};

const mapStateToProps = state => {
  return {
    zendeskUrl: get(state, "pageContent.zendesk.redirect_url")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      zendeskToken: new ZendeskResource(dispatch)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZendeskLogin);
