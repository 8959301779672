import { string, number, shape, bool } from "prop-types";

export const ProjectCatalogShape = shape({
  id: number,
  name: string,
  slug: string,
  import_status: string,
  deletable: bool
});

export default ProjectCatalogShape;
