import { cloneDeep, isEqual, startsWith } from "lodash";
import { array, func, string } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useState } from "react";
import {
  Grid,
  Dropdown,
  Icon,
  Checkbox,
  Button,
  Header
} from "semantic-ui-react";

function DialogueFieldEditor({ exportRole, value, onChange }) {
  const [fields, setFields] = useState(value);

  const intl = useIntl();
  const baseIntl = "documentsExport.dialogs.exports.dialogueFields";

  const typeOptions = [
    {
      text: intl.formatMessage({ id: `${baseIntl}.types.string` }),
      value: "string"
    },
    {
      text: intl.formatMessage({ id: `${baseIntl}.types.text` }),
      value: "text"
    },
    {
      text: intl.formatMessage({ id: `${baseIntl}.types.date` }),
      value: "date"
    },
    {
      text: intl.formatMessage({ id: `${baseIntl}.types.boolean` }),
      value: "boolean"
    }
  ];

  // compares all fields ids with each other
  const countDuplicates = () => {
    const idCount = {};
    fields.forEach(field => {
      idCount[field.id] &&= idCount[field.id] + 1;
      idCount[field.id] ||= 1;
    });

    return idCount;
  };

  const duplicates = countDuplicates();

  const handleChange = (_, val, attribute, field) => {
    const newFields = cloneDeep(fields);
    const fieldIndex = newFields.findIndex(el => isEqual(el, field));

    if (fieldIndex !== -1) {
      newFields[fieldIndex][attribute] = val;
    }

    setFields(newFields);
    onChange(newFields);
  };

  const handleMoveField = (field, direction) => {
    const newFields = cloneDeep(fields);
    const index = newFields.findIndex(el => isEqual(el, field));

    if (direction === "up") {
      if (index === 0) return;

      const otherField = newFields[index - 1];
      newFields[index - 1] = field;
      newFields[index] = otherField;
    } else if (direction === "down") {
      if (index === newFields.length - 1) return;

      const otherField = newFields[index + 1];
      newFields[index + 1] = field;
      newFields[index] = otherField;
    }

    setFields(newFields);
    onChange(newFields);
  };

  const handleDeleteField = field => {
    const newFields = cloneDeep(fields);
    const index = newFields.findIndex(el => isEqual(el, field));

    newFields.splice(index, 1);

    setFields(newFields);
    onChange(newFields);
  };

  const handleCreateField = e => {
    e.preventDefault();
    const newFields = cloneDeep(fields);
    newFields.push({
      id: "",
      label: "",
      type: "string",
      isRequired: false
    });

    setFields(newFields);
    onChange(newFields);
  };

  function isAttributePresent(attr, val) {
    const list = [];
    fields.forEach(field => {
      list.push(field[attr]);
    });

    return list.includes(val);
  }

  function handleAddSpecialField(name) {
    if (
      name === "sampling_date" &&
      !isAttributePresent("id", "ctx_sampling_date")
    ) {
      const newFields = cloneDeep(fields);
      newFields.push({
        id: "ctx_sampling_date",
        label: "Bemusterungstermin am",
        type: "date",
        isRequired: false
      });

      setFields(newFields);
      onChange(newFields);
    } else if (name === "trade" && !isAttributePresent("type", "trades")) {
      const newFields = cloneDeep(fields);
      newFields.push({
        id: "trade",
        label: "Gewerk / Nachunternehmer",
        type: "trades",
        isRequired: true
      });

      setFields(newFields);
      onChange(newFields);
    }
  }

  const SPECIAL_FIELDS = {
    offer: [
      {
        key: "sampling_date",
        text: "Bemusterungstermin",
        icon: "plus",
        onClick: () => handleAddSpecialField("sampling_date"),
        disabled: isAttributePresent("id", "ctx_sampling_date")
      }
    ],
    order: [
      {
        key: "trade",
        text: "Gewerk",
        icon: "plus",
        onClick: () => handleAddSpecialField("trade"),
        disabled: isAttributePresent("type", "trades")
      }
    ],
    enquiry: [
      {
        key: "trade",
        text: "Gewerk",
        icon: "plus",
        onClick: () => handleAddSpecialField("trade"),
        disabled: isAttributePresent("type", "trades")
      }
    ],
    roombook: [],
    catalog: []
  };

  const specialFieldOptions = () => {
    if (SPECIAL_FIELDS[exportRole] === undefined) return [];
    return SPECIAL_FIELDS[exportRole];
  };

  const isSpecialType = field => {
    return !typeOptions.some(el => el.value === field.type);
  };

  const renderLabelInput = field => {
    const isSpecial = isSpecialType(field);
    let errorMessage = "";
    if (field.label === "" && !isSpecial)
      errorMessage = intl.formatMessage({
        id: `${baseIntl}.errorMessages.emptyLabel`
      });

    return (
      <>
        <input
          type="text"
          value={field.label}
          disabled={isSpecial}
          style={{
            borderTop: "0",
            borderLeft: "0",
            borderRight: "0",
            width: "85%",
            borderColor: errorMessage ? "red" : null
          }}
          onChange={e => handleChange(e, e.target.value, "label", field)}
        />
        <span style={{ fontSize: "0.9em", color: "red", display: "block" }}>
          {errorMessage}
        </span>
      </>
    );
  };

  const renderIdInput = field => {
    const isSpecial = isSpecialType(field);
    let errorMessage = "";
    if (field.id.includes(" "))
      errorMessage = intl.formatMessage({
        id: `${baseIntl}.errorMessages.whitespace`
      });
    if (!startsWith(field.id, "ctx_") && !isSpecial)
      errorMessage = intl.formatMessage({
        id: `${baseIntl}.errorMessages.leadingCtx`
      });
    if (duplicates[field.id] > 1)
      errorMessage = intl.formatMessage({
        id: `${baseIntl}.errorMessages.takenKey`
      });
    if (field.id === "")
      errorMessage = intl.formatMessage({
        id: `${baseIntl}.errorMessages.emptyKey`
      });

    return (
      <>
        <input
          type="text"
          value={field.id}
          disabled={isSpecial}
          style={{
            borderTop: "0",
            borderLeft: "0",
            borderRight: "0",
            width: "85%",
            borderColor: errorMessage ? "red" : null
          }}
          onChange={e => handleChange(e, e.target.value, "id", field)}
        />
        <span style={{ fontSize: "0.9em", color: "red", display: "block" }}>
          {errorMessage}
        </span>
      </>
    );
  };

  const renderTypeDropdown = field => {
    // if not special type
    if (!isSpecialType(field)) {
      return (
        <Dropdown
          fluid
          button
          options={typeOptions}
          value={field.type}
          onChange={(e, { value: val }) => handleChange(e, val, "type", field)}
        />
      );
    }

    return <span style={{ color: "grey" }}>Spezialtyp</span>;
  };

  const renderIsRequiredToggle = field => {
    return (
      <Checkbox
        toggle
        checked={!!field.isRequired}
        onChange={(e, { checked }) =>
          handleChange(e, checked, "isRequired", field)
        }
      />
    );
  };

  const renderArrowActions = field => {
    return (
      <span>
        <Icon
          style={{ cursor: "pointer" }}
          name="arrow up"
          color="grey"
          onClick={() => handleMoveField(field, "up")}
        />
        <Icon
          style={{ cursor: "pointer" }}
          name="arrow down"
          color="grey"
          onClick={() => handleMoveField(field, "down")}
        />
      </span>
    );
  };

  const renderDeleteAction = field => {
    return (
      <Icon
        style={{ cursor: "pointer", marginLeft: "0.5em" }}
        name="trash"
        color="grey"
        onClick={() => handleDeleteField(field)}
      />
    );
  };

  const renderCreateAction = () => {
    return (
      <Button
        icon="add"
        content={<FormattedMessage id="meta.actions.add" />}
        onClick={e => handleCreateField(e)}
        fluid
        size="large"
        style={{ marginTop: "1em" }}
      />
    );
  };

  const renderField = field => {
    return (
      <>
        <Grid.Column width={4} verticalAlign="middle">
          {renderLabelInput(field)}
        </Grid.Column>
        <Grid.Column width={4} verticalAlign="middle">
          {renderIdInput(field)}
        </Grid.Column>
        <Grid.Column width={3} textAlign="center" verticalAlign="middle">
          {renderTypeDropdown(field)}
        </Grid.Column>
        <Grid.Column width={3} textAlign="center" verticalAlign="middle">
          {renderIsRequiredToggle(field)}
        </Grid.Column>
        <Grid.Column width={2} textAlign="right" verticalAlign="middle">
          {renderArrowActions(field)}
          {renderDeleteAction(field)}
        </Grid.Column>
      </>
    );
  };

  return (
    <span style={{ padding: "-1em 1em 1em" }}>
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <Header as="h2">
          <FormattedMessage
            id={`${baseIntl}.text.header`}
            defaultMessage="Dialogue Fields"
          />
        </Header>
        <Dropdown
          button
          text={
            <FormattedMessage
              id={`${baseIntl}.text.specialFieldsDropdown`}
              defaultMessage="Special Fields"
            />
          }
          options={specialFieldOptions()}
        />
      </span>
      <Grid style={{ marginTop: "0.5em" }} divided padded="horizontally">
        <Grid.Row
          style={{
            borderBottom: "solid 1px #bebebe",
            backgroundColor: "rgb(229 231 233)",
            borderRadius: "1em 1em 0 0"
          }}
        >
          <Grid.Column
            style={{ fontWeight: "bold" }}
            width={4}
            textAlign="center"
            verticalAlign="middle"
          >
            {intl.formatMessage({ id: `${baseIntl}.columns.title` })}
          </Grid.Column>
          <Grid.Column
            style={{ fontWeight: "bold" }}
            width={4}
            textAlign="center"
            verticalAlign="middle"
          >
            {intl.formatMessage({ id: `${baseIntl}.columns.key` })}
          </Grid.Column>
          <Grid.Column
            style={{ fontWeight: "bold" }}
            width={3}
            textAlign="center"
            verticalAlign="middle"
          >
            {intl.formatMessage({ id: `${baseIntl}.columns.type` })}
          </Grid.Column>
          <Grid.Column
            style={{ fontWeight: "bold" }}
            width={3}
            textAlign="center"
            verticalAlign="middle"
          >
            {intl.formatMessage({ id: `${baseIntl}.columns.isRequired` })}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        {fields.map((field, index) => {
          const key = `column${index}`;
          return (
            <Grid.Row
              key={key}
              style={{
                border: "solid 1px rgb(34 36 38 / 15%)",
                borderTop: "0"
              }}
            >
              {renderField(field)}
            </Grid.Row>
          );
        })}
        {renderCreateAction()}
      </Grid>
    </span>
  );
}

DialogueFieldEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: array.isRequired,
  onChange: func.isRequired,
  exportRole: string.isRequired
};

export default DialogueFieldEditor;
