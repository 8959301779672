import apiRequest from "../../shared/network/apiRequest";
import { UPDATE_MESSAGE } from "../../shared/constants/appConstants";
import Resources from "../../shared/actions/resourcesActions";

export class AttachmentResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "line_item", "/api/v1/room_books/sub_line_items");
  }

  removeAttachment(attachmentId, resourceId) {
    const url = `${this.url}/${resourceId}/attachments/${attachmentId}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  updateAttachment(values, resourceId) {
    if (!values || !values.attachment || !values.attachment.id) {
      console.error("updateAttachment failed payload not given");
      return false;
    }
    const url = `${this.url}/${resourceId}/attachments/${values.attachment.id}`;
    return apiRequest
      .patch(url, this.dispatch, values)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  assignAttachment(id, resourceId) {
    if (!id) {
      console.error("assignAttachment failed payload not given");
      return false;
    }
    const url = `${this.url}/${resourceId}/attachments`;
    return apiRequest
      .post(url, this.dispatch, { attachment: { id } })
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }
}
