import React, { useState, useMemo, useRef, useContext } from "react";
import { Formik } from "formik";
import { Form, Input, Select } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { node } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { DesignLineGroupShape } from "shared/shapes";
import { DesignLinesLoaderContext } from "./DesignLinesLoader";

const DesignLineGroupDialog = ({ button, model }) => {
  const { saveDesignLineGroup } = useContext(DesignLinesLoaderContext);

  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);
  const refResetForm = useRef();

  const scopeOptions = useMemo(
    () => [
      {
        key: "room",
        value: "room",
        text: intl.formatMessage({ id: "design_lines.scope.room" })
      },
      {
        key: "unit",
        value: "unit",
        text: intl.formatMessage({ id: "design_lines.scope.unit" })
      }
    ],
    []
  );

  const severityOptions = useMemo(
    () => [
      {
        key: "recommendation",
        value: "recommendation",
        text: intl.formatMessage({ id: "design_lines.severity.recommendation" })
      },
      {
        key: "constraint",
        value: "constraint",
        text: intl.formatMessage({ id: "design_lines.severity.constraint" })
      }
    ],
    []
  );

  const sortOptions = useMemo(
    () => [
      {
        key: "price",
        value: "price",
        text: intl.formatMessage({ id: "design_lines.groups.sort_by.price" })
      },
      {
        key: "position",
        value: "position",
        text: intl.formatMessage({ id: "design_lines.groups.sort_by.position" })
      }
    ],
    []
  );

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    severity: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    scope: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = values => {
    return saveDesignLineGroup(values).then(() => {
      setOpen(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  return (
    <Formik
      initialValues={initialFormValue}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            data-component="UpgradeBundleDialog"
          >
            <Modal.Header>
              <FormattedMessage id="design_lines.groups.dialog.title" />
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.name.label"
                  })}
                />

                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.title_field.label"
                  })}
                />

                <Input
                  id="description"
                  errorPrompt
                  name="description"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.description.label"
                  })}
                />

                <Input
                  id="title_action"
                  errorPrompt
                  name="title_action"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.title_action.label"
                  })}
                />

                <Select
                  id="severity"
                  errorPrompt
                  name="severity"
                  options={severityOptions}
                  label={intl.formatMessage({
                    id: "design_lines.groups.dialog.severity.label"
                  })}
                />

                <Input
                  id="confirmation_message"
                  errorPrompt
                  name="confirmation_message"
                  label={intl.formatMessage({
                    id: "design_lines.dialog.confirmation_message.label"
                  })}
                />

                <Select
                  id="scope"
                  errorPrompt
                  name="scope"
                  options={scopeOptions}
                  label={intl.formatMessage({
                    id: "design_lines.groups.dialog.scope.label"
                  })}
                />

                <Select
                  id="sort_by"
                  errorPrompt
                  name="sort_by"
                  options={sortOptions}
                  label={intl.formatMessage({
                    id: "design_lines.groups.sort_by.label"
                  })}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions className="right">
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({ id: "meta.actions.cancel" })}
                onClick={handleClose}
                loading={isSubmitting}
              />
              <Button
                primary
                type="submit"
                content={
                  model.id
                    ? intl.formatMessage({ id: "meta.actions.save" })
                    : intl.formatMessage({ id: "meta.actions.add" })
                }
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

DesignLineGroupDialog.propTypes = {
  button: node.isRequired,
  model: DesignLineGroupShape.isRequired
};

export default DesignLineGroupDialog;
