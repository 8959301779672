import { shape, number, string, arrayOf } from "prop-types";
import { UserShape } from "./user.shape";

const ProjectMembershipShape = shape({
  id: number,
  user_id: number,
  roles: arrayOf(string),
  user: UserShape,
  mail_template_id: number
});

export default ProjectMembershipShape;
