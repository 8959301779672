import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Segment, Grid, Header, Icon, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { FlatFinderResource } from "../../../../actions/flatFinderActions";
import { getFlatFinders } from "../../../../selectors/flatFinders";
import FlatFinderDialog from "./FlatFinderDialog";

const FlatFinderSegment = ({ projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const flatFinderResource = new FlatFinderResource(dispatch, projectId);

  const flatFinders = useSelector(getFlatFinders);

  React.useEffect(() => {
    flatFinderResource.fetchAll();
  }, []);

  return (
    <div>
      {flatFinders.map(flatFinder => {
        return (
          <Segment key={flatFinder.id}>
            <Grid>
              <Grid.Column width={14}>
                <Header as="h5">{flatFinder.name}</Header>
              </Grid.Column>
              <Grid.Column width={1} textAlign="center">
                <IsVersionHistoryAccessible>
                  <VersionHistoryLink
                    id={flatFinder.id}
                    type="FlatFinder"
                    size="md"
                  />
                </IsVersionHistoryAccessible>
              </Grid.Column>
              <Grid.Column width={1} textAlign="right">
                <FlatFinderDialog
                  trigger={<Icon name="edit" />}
                  model={flatFinder}
                  resource={flatFinderResource}
                  projectId={projectId}
                />
              </Grid.Column>
            </Grid>
          </Segment>
        );
      })}
      <FlatFinderDialog
        projectId={projectId}
        resource={flatFinderResource}
        trigger={
          <Button
            icon="plus"
            basic
            content={intl.formatMessage({
              id: "flatFinder.addNewFlatFinder"
            })}
            fluid
          />
        }
      />
    </div>
  );
};

FlatFinderSegment.propTypes = {
  projectId: PropTypes.number.isRequired
};

export default FlatFinderSegment;
