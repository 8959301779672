import { BatchCartAssignmentResource } from "builder_portal/actions/roomBookActions";

export default class AssignLineItemsAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  execute(params) {
    const { roomBookId, activityId, lineItemIds } = params;
    const batchCartAssignmentResource = new BatchCartAssignmentResource(
      this.dispatch,
      roomBookId
    );
    return batchCartAssignmentResource
      .save({
        activity_id: activityId,
        line_item_ids: lineItemIds
      })
      .then(() => params);
  }
}
