import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import UpgradeBundleGroupsResource from "builder_portal/actions/upgradeBundleGroupsActions";
import getUpgradeBundleGroups from "builder_portal/selectors/upgradeBundleGroups";
import { keyBy } from "lodash";
import Resource from "../../actions/roomBookUpgradeBundles";
import { RoomBookUpgradeBundlesContext } from "./RoomBookUpgradeBundlesContext";
import getRoomBookUpgradeBundles from "../../selectors/roomBookUpgradeBundles";
import { getRoomBook } from "../../selectors";

const RoomBookUpgradeBundlesResource = ({ roomBookId, children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const upgradeBundles = useSelector(getRoomBookUpgradeBundles);
  const upgradeBundleGroups = keyBy(useSelector(getUpgradeBundleGroups), "id");
  const roomBook = useSelector(getRoomBook);

  useEffect(() => {
    if (roomBook?.project_id) {
      new UpgradeBundleGroupsResource(dispatch, roomBook.project_id).fetchAll();
    }
  }, [roomBook]);

  const resource = useMemo(() => {
    return new Resource(dispatch, roomBookId);
  }, [roomBookId]);

  const refresh = useCallback(() => {
    if (roomBookId) {
      setLoading(true);
      resource.fetchAll().then(() => setLoading(false));
    }
  }, [resource]);

  const activate = useCallback(
    upgradeBundle => {
      return resource.save(upgradeBundle).then(refresh);
    },
    [resource, refresh]
  );

  const deactivate = useCallback(
    upgradeBundle => {
      return resource.remove(upgradeBundle.id).then(refresh);
    },
    [resource, refresh]
  );

  const updatePrice = useCallback(
    (upgradeBundle, price) => {
      return resource.updatePrice(upgradeBundle.id, price * 100).then(refresh);
    },
    [resource, refresh]
  );

  useEffect(refresh, [refresh, roomBook]);

  const context = useMemo(
    () => ({
      activate,
      deactivate,
      loading,
      refresh,
      upgradeBundles,
      updatePrice,
      upgradeBundleGroups
    }),
    [
      activate,
      deactivate,
      loading,
      refresh,
      upgradeBundles,
      updatePrice,
      upgradeBundleGroups
    ]
  );

  return (
    <RoomBookUpgradeBundlesContext.Provider value={context}>
      {children}
    </RoomBookUpgradeBundlesContext.Provider>
  );
};

RoomBookUpgradeBundlesResource.propTypes = {
  roomBookId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default RoomBookUpgradeBundlesResource;
