import scrollToElement from "scroll-to-element";

export const scrollToTop = () => {
  setTimeout(() => {
    const element = window.document.querySelector("body");
    scrollToElement(element, {
      offset: -10,
      ease: "out-circ",
      duration: 1000
    });
  }, 50);
};
