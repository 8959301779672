import React, { useEffect, useState, useMemo, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnitVariables,
  getUnitVariableTypes,
  getTotalPages
} from "builder_portal/selectors/unitVariables";
import { getUnitOrUnits } from "shared/selectors/units";
import { string, oneOfType, number, func, node } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import { uniqBy } from "lodash";
import {
  UnitVariablesResource,
  UnitVariableTypesResource
} from "../../../actions/unitVariablesActions";

export const UnitVariablesLoaderContext = createContext({});

const ITEMS_PER_PAGE = 20;

const UnitVariablesLoader = ({ unitId, projectId, children }) => {
  const dispatch = useDispatch();
  const unitVariables = useSelector(getUnitVariables);
  const unitVariableTypes = useSelector(getUnitVariableTypes);
  const units = useSelector(getUnitOrUnits);
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedType, setSelectedType] = useState();
  const [isLoading, setLoading] = useState(false);
  const total = useSelector(getTotalPages);
  const [page, setPage] = useState(1);

  const isProjectLevel = useMemo(() => {
    if (projectId) return true;
    return false;
  }, [unitId, projectId]);

  const loadUnitVariables = () => {
    setLoading(true);
    if (unitId)
      new UnitVariablesResource(dispatch, unitId)
        .fetchWithPaginationUnitLevel(unitId, { page, limit: ITEMS_PER_PAGE })
        .then(() => setLoading(false))
        .catch(err => {
          setLoading(false);
          new Growl(dispatch).error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              bodyValues: { translatedBody: err }
            }
          );
        });
  };

  useEffect(() => {
    loadUnitVariables();
  }, [unitId]);

  const getParams = () => {
    const params = { page, limit: ITEMS_PER_PAGE };
    const rest = {};
    if (selectedUnit) rest.unit_id_filter = selectedUnit;
    if (selectedType) rest.type_id_filter = selectedType;
    return { ...params, ...rest };
  };

  const loadProjectVariables = () => {
    setLoading(true);
    if (projectId)
      new UnitVariablesResource(dispatch, unitId)
        .fetchWithPaginationProjectLevel(projectId, getParams())
        .then(() => setLoading(false))
        .catch(err => {
          setLoading(false);
          new Growl(dispatch).error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              bodyValues: { translatedBody: err }
            }
          );
        });
  };

  const loadVariables = () => {
    if (isProjectLevel && !isLoading) loadProjectVariables();
    if (!isProjectLevel && !isLoading) loadUnitVariables();
  };

  useEffect(() => {
    loadVariables();
  }, [page]);

  useEffect(() => {
    if (projectId) {
      new UnitVariableTypesResource(dispatch, projectId).fetchAll();
    }
  }, [projectId]);

  useEffect(() => {
    // if the last unit variable is deleteted on some page, set previous page selected
    if (page > total && total) setPage(total);
  }, [total]);

  useEffect(() => {
    loadVariables();
  }, [selectedUnit, selectedType]);

  const isFiltered = useMemo(() => !!selectedUnit || !!selectedType, [
    selectedUnit,
    selectedType
  ]);

  const unitFilterOptions = useMemo(() => {
    const unitsInUse = unitVariables.map(uvar => uvar.unit);
    return uniqBy(unitsInUse, "id").map(({ id, display_name }) => ({
      key: id,
      value: id,
      text: display_name
    }));
  }, [unitVariables]);

  const allUnitOptions = useMemo(() => {
    return units.map(unit => ({
      key: unit.id,
      value: unit.id,
      text: `${unit.display_name || unit.slug}`
    }));
  }, [units]);

  const unitVariableTypesOptions = useMemo(() => {
    return unitVariableTypes.map(type => ({
      key: type.id,
      value: type.id,
      text: `${type.identifier}`
    }));
  }, [unitVariableTypes]);

  return (
    <UnitVariablesLoaderContext.Provider
      value={{
        unitFilterOptions,
        unitVariables,
        isLoading,
        setSelectedUnit,
        setSelectedType,
        isProjectLevel,
        projectId,
        unitId,
        unitVariableTypes,
        allUnitOptions,
        loadVariables,
        total,
        page,
        setPage,
        unitVariableTypesOptions,
        isFiltered,
        setProviderLoading: setLoading
      }}
    >
      {children}
    </UnitVariablesLoaderContext.Provider>
  );
};

UnitVariablesLoader.propTypes = {
  unitId: oneOfType([string, number]),
  projectId: oneOfType([string, number]),
  children: oneOfType([func, node])
};

UnitVariablesLoader.defaultProps = {
  unitId: null,
  projectId: null,
  children: null
};

export default UnitVariablesLoader;
