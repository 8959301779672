/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";

export default class ConfigurationGroupsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "configuration_group",
      `/api/v1/projects/${projectId}/configuration_groups`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
