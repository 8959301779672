import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";
import { get } from "lodash";

export const getPageContentSections = createSelector(
  [getPageContent],
  pageContent => get(pageContent, ["sections"], [])
);

export const getSortedSections = createSelector(
  [getPageContentSections],
  sections => {
    return [...sections].sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { numeric: true });
    });
  }
);
