import React, { useCallback } from "react";
import { func, bool, string, oneOf } from "prop-types";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Container,
  Header,
  Icon,
  Image,
  Message,
  Segment,
  Input
} from "semantic-ui-react";
import "./fileUploader.scss";

const FileUploader = props => {
  const {
    id,
    fileType,
    dataComponent,
    handleDelete,
    handleFile,
    loading,
    multiple,
    name,
    originalFileUrl,
    previewImageUrl,
    onSuccess
  } = props;

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles
      .reduce((accuPromise, file) => {
        return accuPromise.then(() => {
          return handleFile(file, name);
        });
      }, Promise.resolve())
      .then(onSuccess());
  }, []);

  let accept;
  let fileIcon;
  let errorMessageId = "attachment.message.unsupportedFile";
  let maxSize = Infinity;

  switch (fileType) {
    case "image":
      accept = "image/jpeg, image/png, image/gif";
      fileIcon = "file image outline";
      maxSize = 5 * 1024 * 1024;
      errorMessageId = "attachment.message.unsupportedFileOrSize";
      break;
    case "pdf":
      accept = ".pdf";
      fileIcon = "file pdf outline";
      break;
    case "json":
      accept = "application/json";
      fileIcon = "file text outline";
      errorMessageId = "attachment.message.supportedOnlyJSON";
      break;
    case "excel":
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      fileIcon = "table";
      errorMessageId = "attachment.message.supportedOnlyExcel";
      break;
    default:
      fileIcon = "file outline";
      break;
  }

  const { getRootProps, getInputProps, fileRejections = [] } = useDropzone({
    accept,
    multiple,
    onDrop,
    maxSize
  });

  const hasRejectedFiles =
    fileRejections.length !== 0 ? "hasRejectedFiles" : "";

  return (
    <Segment
      key={name}
      data-component={dataComponent}
      data-name={name}
      textAlign="center"
      className="uploader-component"
      loading={loading}
    >
      {!previewImageUrl && (
        <div
          {...getRootProps({
            className: `dropzone ${hasRejectedFiles}`
          })}
        >
          <input {...getInputProps()} name={name} id={id} />
          <Header as="span" className="dropzone-header">
            <Icon name={fileIcon} />
            <Header.Content>
              <FormattedMessage id="meta.actions.dragAndDropFile" />
            </Header.Content>
          </Header>
        </div>
      )}
      {!previewImageUrl && (
        <Message hidden={!hasRejectedFiles} negative attached="bottom">
          <FormattedMessage
            id={errorMessageId}
            defaultMessage="Dateiformat nicht akzeptiert, bitte ein gültiges Format nutzen"
          />
        </Message>
      )}
      {!!previewImageUrl && (
        <Container className="preview-segment">
          <Image
            src={previewImageUrl}
            className="thumbnail"
            spaced="right"
            centered
            wrapped
          />
          <Button
            basic
            className="delete-button"
            color="red"
            onClick={() => handleDelete(name)}
            type="button"
          >
            <FormattedMessage id="meta.actions.delete_file" />
          </Button>
          <Input className="file-url" readOnly value={originalFileUrl} />
        </Container>
      )}
    </Segment>
  );
};

FileUploader.propTypes = {
  id: string,
  fileType: oneOf(["image", "pdf", "excel"]),
  handleFile: func.isRequired,
  dataComponent: string,
  fileName: string,
  originalFileUrl: string,
  handleDelete: func,
  loading: bool,
  multiple: bool,
  name: string,
  previewImageUrl: string,
  onSuccess: func
};

FileUploader.defaultProps = {
  id: "",
  fileType: null,
  dataComponent: "FileUploader",
  fileName: "",
  loading: false,
  multiple: false,
  previewImageUrl: "",
  originalFileUrl: "",
  name: "",
  handleDelete: () => {},
  onSuccess: () => {}
};

export default FileUploader;
