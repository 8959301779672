import React, { Component } from "react";
import { connect } from "react-redux";
import Field from "shared/components/forms/FieldComponent";
import { getAccount } from "shared/selectors";

class TradesFormField extends Component {
  render = () => {
    return <Field component="Select" {...this.props} />;
  };
}

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state, props) => {
  const account = getAccount(state);

  const options = account.getTrades().map(item => {
    return {
      key: item.id,
      value: item.id,
      text: item.label
    };
  });

  return {
    props: {
      ...props.props,
      multiple: true,
      selection: true,
      search: true,
      closeOnChange: true,
      options
    }
  };
};

export default connect(mapStateToProps)(TradesFormField);
