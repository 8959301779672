import React from "react";
import { PropTypes } from "prop-types";
import FeatureToggle from "./FeatureToggle";

const FeatureToggleActive = ({ featureToggleName, children }) => {
  return (
    <FeatureToggle featureToggleName={featureToggleName}>
      {children}
    </FeatureToggle>
  );
};

FeatureToggleActive.propTypes = {
  featureToggleName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default FeatureToggleActive;
