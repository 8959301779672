import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Loader } from "semantic-ui-react";
import { bool } from "prop-types";

const TinyLoader = ({ opened }) => {
  const [isOpen, setOpen] = useState(opened);

  useEffect(() => setOpen(opened), [opened]);

  return (
    <Modal
      style={{ width: "250px", height: "200px" }}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Content>
        <Loader size="large" />
        <center>
          <FormattedMessage id="meta.actions.processing" />
        </center>
      </Modal.Content>
    </Modal>
  );
};

TinyLoader.propTypes = {
  opened: bool.isRequired
};

export default TinyLoader;
