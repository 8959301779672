import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Form, Select } from "formik-semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import { cloneDeep } from "lodash";
import { node } from "prop-types";
import { Modal, Button, Header } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { UnitFeatureShape } from "shared/shapes/unitFeatureGroup.shape";
import { getProject } from "shared/selectors";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import {
  UnitResource,
  UnitsResource
} from "builder_portal/actions/unitActions";

const UnitFeatureAssignmentDialog = ({ button, model }) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const project = useSelector(getProject);
  const dispatch = useDispatch();
  const unitFeatureOptions = useSelector(getUnitFeatureOptions);
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const onSubmit = useCallback(values => {
    return new Promise((resolve, reject) => {
      new UnitResource(dispatch, project.id)
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
          setOpen(false);
          new UnitsResource(dispatch, project.id).fetchAll();
          resolve();
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "message.error.body"
          );
          reject();
        });
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UnitFeatureDialog"
    >
      <Modal.Header>
        <FormattedMessage id="unitFeatureAssignments.dialog.title" />
      </Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialFormValue} onSubmit={onSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <Header as="h4">{model.display_name}</Header>

                <Select
                  id="unit_feature_ids"
                  multiple
                  clearable
                  name="unit_feature_ids"
                  options={unitFeatureOptions}
                  label={intl.formatMessage({
                    id: "unitFeatureAssignments.dialog.unitFeatures"
                  })}
                />

                <Modal.Actions style={{ textAlign: "right" }}>
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({
                      id: "meta.actions.cancel"
                    })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      model.id
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

UnitFeatureAssignmentDialog.propTypes = {
  button: node.isRequired,
  model: UnitFeatureShape.isRequired
};

export default UnitFeatureAssignmentDialog;
