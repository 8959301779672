const stripComments = text => {
  return text
    .replace(/&lt;!--[\s\S]*--&gt;/gm, "")
    .replace(/P {.*}/g, "")
    .replace(/<p><\/p>/g, "")
    .replace(/<p class="[^"]+"><\/p>/g, "")
    .replace(/<p>&nbsp;<\/p>/g, "")
    .replace(/<base[^>]*>/g, "");
};

const textFromElement = element => {
  if (!element) {
    return "";
  }
  // const children = Array.from(element.children);
  // return [element?.textContent]
  //   .concat(children.map(textFromElement))
  //   .map(e => (e || "").trim())
  //   .join(" ");
  return element?.textContent || "";
};

const QUOTE_PATTERNS = [
  /^Von:.*Gesendet:.*An:/,
  /^Von:.*Datum:.*An:/,
  /^Gesendet:.*Von:.*An:/,
  /^From:.*Sent:.*To:/,
  /^From:.*Date:.*To:/,
  /^On.*at.*<.*@.*>.*wrote:/,
  /^Am.*um.*schrieb.*<.*@.*>:/,
  /^Am.*[,]?.*schrieb.*<.*@.*>:/,
  /^Von:.*<.*@.*>/,
  /^Gesendet mit der WEB.DE Mail App/,
  /^Diese Nachricht wurde von meinem Android Mobiltelefon mit GMX Mail gesendet/,
  /^Gesendet mit der 1&1 iPad App/,
  /^-+ Original -+/,
  /^-+Ursprüngliche Mitteilung-+/
  // /^.* <[^>]+> schrieb am \w+\.,/
];

const isQuote = element => {
  if (!element) {
    return false;
  }
  const text = textFromElement(element)
    .replace(/\n/g, "")
    .slice(0, 127);

  return !!QUOTE_PATTERNS.some(pattern => {
    return text.match(pattern);
  });
};

const removeQuotes = (element, found = false) => {
  if (!element) {
    return found;
  }
  if (found || isQuote(element)) {
    element.remove();
    return true;
  }
  return Array.from(element.children).reduce((f, e) => {
    return removeQuotes(e, f);
  }, found);
};

const removeMessage = (element, found = false) => {
  if (!element) {
    return found;
  }

  if (isQuote(element)) {
    return true;
  }

  const f = Array.from(element.children || []).some(e => {
    return removeMessage(e, found);
  });

  if (!element.children.length && element.remove) {
    element.remove();
  }

  return f;
};

const removeInlineImages = element => {
  if (element.tagName === "IMG" && !(element.src || "").startsWith("http")) {
    element.remove();
  }
  Array.from(element.children || []).forEach(e => {
    removeInlineImages(e);
  });
  return element;
};

const innerHTML = doc => {
  if (doc?.innerHTML) {
    return doc?.innerHTML;
  }
  return Array.from(doc?.children || [])
    .map(innerHTML)
    .join("");
};

const getSeparatedMessage = text => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(text, "text/html");
  const htmlOriginal = parser.parseFromString(text, "text/html");
  const separated = removeQuotes(htmlDoc);
  removeMessage(htmlOriginal);
  removeInlineImages(htmlDoc);
  removeInlineImages(htmlOriginal);

  const message = innerHTML(htmlDoc.body);
  const quote = innerHTML(htmlOriginal.body);

  return {
    message: stripComments(message),
    quote: stripComments(quote),
    separated
  };
};

export default getSeparatedMessage;
