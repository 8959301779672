import apiRequest from "shared/network/apiRequest";
import Resources from "shared/actions/resourcesActions";
import Resource from "shared/actions/resourceActions";

export default class ProjectBuyersResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "buyer", `/api/v1/projects/${projectId}/buyers`);
    this.refreshPageContentAfterCommit = true;
  }

  getBuyerappStatus() {
    return apiRequest
      .get(`${this.url}/buyerapp_status`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class ProjectBuyerInvitationResource extends Resource {
  constructor(dispatch, projectId, buyerId) {
    super(
      dispatch,
      "buyer",
      `/api/v1/projects/${projectId}/buyers/${buyerId}/invitations`
    );
  }
}
