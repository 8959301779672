import { get, keyBy, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";

export const getProduct = state => {
  return get(state, "pageContent.product", undefined);
};

export const getProducts = state => {
  return get(state, "pageContent.products", undefined);
};

export const getProductGroups = state => {
  return get(state, "pageContent.product_groups", undefined);
};

export const getProductGroupsFromProjectCatalogs = createSelector(
  [getProductGroups, getProducts],
  (productGroups, products) => {
    const productsById = keyBy(products || [], p => p.id);

    return (productGroups || []).map(pg => {
      const ps = pg.product_options.map(po => productsById[po.product_id]);
      return {
        ...pg,
        products: sortBy(ps, p => p.name)
      };
    });
  }
);

export const getMasterProducts = createSelector([getPageContent], pageContent =>
  get(pageContent, "master_products", [])
);

export const getMasterProductOptions = createSelector(
  [getMasterProducts],
  masterProducts =>
    masterProducts.map(product => ({
      key: product.id,
      value: product.id,
      text: `${product.name} (${product.sku})`
    }))
);

export default getProducts;
