import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Button, Grid } from "semantic-ui-react";
import { ContractorShape, ContractorAssignmentShape } from "shared/shapes";
import { getContractors, getContractorAssignments } from "shared/selectors";
import CartExportDialog from "../../cart/CartExportDialog";
import LineItemAssignmentDialog from "./LineItemAssignmentDialog";

class LineItemActions extends Component {
  static propTypes = {
    ctxModel: PropTypes.object,
    ctxCtrl: PropTypes.object,
    i18n: PropTypes.object,
    contractors: PropTypes.arrayOf(ContractorShape),
    contractorAssignments: PropTypes.arrayOf(ContractorAssignmentShape)
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: {},
      tableVisible: true,
      sum: 0
    };

    this.onSave = this.onSave.bind(this);
  }

  toggle(expanded) {
    this.setState({ ...this.state, expanded });
  }

  onSave(values) {
    const { ctxCtrl } = this.props;

    return ctxCtrl.exportAsAttachment(values);
  }

  render() {
    const { ctxModel } = this.props;
    const activity = ctxModel.getActivity();

    const rows = activity.exports.map(exp => {
      const options = exp.options.map((o, idx) => {
        return (
          <div key={idx} style={{ overflow: "hidden" }}>
            <div className="left floated element">
              <span>{o.label}</span>
            </div>
            <div className="right floated element">
              {this.renderPreviewLink(activity, exp, o)}
              &nbsp;|&nbsp;
              {this.renderExportDialog(
                "Vorgangsdokument",
                "ATTACHMENT",
                activity,
                exp,
                o
              )}
            </div>
          </div>
        );
      });
      return (
        <Grid.Row key={exp.id}>
          <Grid.Column width={8}>
            <strong>{exp.label}</strong>
          </Grid.Column>
          <Grid.Column width={8}>{options}</Grid.Column>
        </Grid.Row>
      );
    });

    return (
      <Segment.Group>
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                {this.renderAssignmentDialog()}
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                {this.renderToggle()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {this.state.expanded && (
          <Segment data-component="cartExport">
            <Grid divided="vertically">{rows}</Grid>
          </Segment>
        )}
      </Segment.Group>
    );
  }

  renderToggle() {
    return (
      <Button
        id="export_toggle"
        basic={!this.state.expanded}
        icon="book"
        content="Export"
        onClick={() => {
          this.toggle(!this.state.expanded);
        }}
      />
    );
  }

  renderAssignmentDialog() {
    const { ctxCtrl, ctxModel, i18n } = this.props;
    const button = (
      <Button id="assignment_dialog" basic icon="pencil" content="Bearbeiten" />
    );

    if (ctxModel.hasMultipleUnits()) {
      return (
        <LineItemAssignmentDialog
          ctxModel={ctxModel}
          ctxCtrl={ctxCtrl}
          i18n={i18n}
          button={button}
        />
      );
    }
    return null;
  }

  renderExportDialog(actionLabel, mode, model, exp, o) {
    const {
      ctxCtrl,
      ctxModel,
      contractors,
      contractorAssignments
    } = this.props;
    const trades = ctxModel.getAssignedTrades();
    const attachment = {
      activity_id: model.id,
      display_name: exp.file_name,
      role: exp.role,
      format: o.format,
      export_id: exp.id
    };

    const id = exp.id.replace(/\//g, "-");
    const trigger = (
      <a
        id={`${mode.toLowerCase()}_${id}_${o.format}_attachment`}
        role="button"
        style={{ color: "#00F", textDecoration: "underline" }}
      >
        {actionLabel}
      </a>
    );

    return (
      <CartExportDialog
        title={exp.label}
        exports={[exp]}
        onSave={ctxCtrl.exportAsAttachment}
        attachment={attachment}
        button={trigger}
        mode={mode}
        trades={trades}
        contractors={contractors}
        contractorAssignments={contractorAssignments}
      />
    );
  }

  renderPreviewLink(model, exp, o) {
    if (exp.arguments && exp.arguments.length > 0) {
      return this.renderExportDialog("Vorschau", "PREVIEW", model, exp, o);
    }
    return (
      <a
        role="button"
        href={o.url}
        target={`${model.id}_{exp.label}`}
        style={{ color: "#00F", textDecoration: "underline" }}
      >
        Vorschau
      </a>
    );
  }
}

LineItemActions.defaultProps = {
  contractors: [],
  contractorAssignments: []
};

const mapStateToProps = state => {
  return {
    contractors: getContractors(state),
    contractorAssignments: getContractorAssignments(state)
  };
};

export default connect(mapStateToProps)(LineItemActions);
