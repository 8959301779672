/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unescaped-entities */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { Header, Button, Grid } from "semantic-ui-react";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import { useIntl } from "react-intl";
import CreateApiDocumentation from "./CreateApiDocumentation";
import { prefix } from "./routePrefix";

const DocumentationItemsList = ({
  documentationItems,
  setSeletectedDocumentationItem
}) => {
  const intl = useIntl();
  return (
    <div>
      <title>Sonderwunsch Manager API</title>
      <article id="900fe0ab-99c6-40c9-b94a-5590dd4bd873">
        <Grid stackable>
          <Grid.Column width={10}>
            <Header as="h1">Sonderwunsch Manager API</Header>
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <a
              href="/graphql_workbench"
              style={{ color: "white", textDecoration: "none" }}
            >
              <Button primary>GraphiQL Workbench</Button>
            </a>
          </Grid.Column>
        </Grid>

        <div
          className="api-documentation-page-body"
          style={{ marginTop: "1em" }}
        >
          <p id="a3f96a89-66ce-4c8c-9721-b83bef1fbac2">
            Der Sonderwunsch Manager bietet eine API Schnittstelle für die
            gängigen Daten und Aktionen an. Es basiert auf der GraphQL
            Technologie.
          </p>
          <p>
            GraphQL ist eine flexible Abfrage- (Query) und Bearbeitungssprache
            (Mutation).
          </p>
          <p id="716082b3-66e0-48c7-b706-643e0eaf11f7">
            Grundlegende Informationen zu GraphQL können auf der{" "}
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <a href="https://graphql.org/" role="link">
              graphql.org
            </a>{" "}
            Website gefunden werden.
          </p>
          <p id="a167bbcc-267a-4a91-b9e8-91fcce0edb74">
            GraphQL APIs können über generische Tools und Bibliotheken genutzt
            werden. Beispielsweise über den{" "}
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <a href="https://altair.sirmuel.design/" role="link">
              Altair GraphQL Client
            </a>{" "}
            oder unsere eingebetete{" "}
            {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
            <a href="/graphql_workbench" role="link">
              GraphiQL Workbench
            </a>
            .
          </p>
          <h2 id="5b5800dd-4836-4769-8188-90d99c0f55c4">Endpunkte</h2>
          <p id="cc163f43-a7ee-4271-9b3b-d7b389caa870">
            Die Sonderwunsch Manager API ist unter folgender URL erreichbar:
          </p>
          <pre
            id="21b97761-c939-4a52-93b0-3d898a137ddf"
            className="api-documentation-code"
          >
            <code>https://api.sonderwunsch-manager.de/graphql</code>
          </pre>
          <h2 id="c81238ce-ae0d-4986-a5d5-db470e5286c1" className>
            Authentifizierung
          </h2>
          <p id="448f7b63-1e57-468c-b339-9a7be3756d0c" className>
            Die API kann im Namen jedes Benutzers verwendet werden. Es werden
            E-Mail und das Kennwort sowie die accountId benötigt, mit der
            interagiert werden soll.
          </p>
          <pre
            id="72971bb0-bd31-462d-9c19-68c88dbb39bd"
            className="api-documentation-code"
          >
            <code>
              mutation{"{"}
              {"\n"}
              {"    "}signIn(email: "al@baudigital.com", password: "...",
              accountId: "187"){"{"}
              {"\n"}
              {"      "}authToken{"\n"}
              {"      "}user {"{"}
              {"\n"}
              {"        "}email{"\n"}
              {"      "}
              {"}"}
              {"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"  "}
              {"}"}
            </code>
          </pre>
          <p id="feb62588-b618-4664-87ed-4aa988baf6e1" className>
            Das Ergebnis der erfolgreichen Authentifizierung gibt einen JWT
            Token zurück, z.B.
          </p>
          <pre
            id="d29691e3-9271-4364-8427-7c1bcc25502c"
            className="api-documentation-code"
          >
            <code>
              {"{"}
              {"\n"}
              {"    "}"data": {"{"}
              {"\n"}
              {"      "}"signIn": {"{"}
              {"\n"}
              {"        "}"authToken": "eyJhbGciOi....iUijLews",{"\n"}
              {"        "}"user": {"{"}
              {"\n"}
              {"          "}"email": "al@baudigital.com"{"\n"}
              {"        "}
              {"}"}
              {"\n"}
              {"      "}
              {"}"}
              {"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"  "}
              {"}"}
            </code>
          </pre>
          <p id="d09a936d-d220-479c-bf45-c2cdba2a47c9" className>
            Der authToken kann nun in nachfolgenden Queries oder Mutations als
            Header gesendet werden. In den gängigen GraphQL Tools / Clients gibt
            es typischerweise die Möglichkeit, diese zu setzen.
          </p>
          <pre
            id="044b80bd-1fd7-4e65-b328-160d6b0c555a"
            className="api-documentation-code"
          >
            <code>Authorization: Bearer eyJhbGciOi....iUijLews</code>
          </pre>
          <h2 id="c63cae0e-0fa1-4a48-b893-e2c5ba2afc60" className>
            Abfragen
          </h2>
          <p id="d072da99-dd67-4838-9e83-c0ed1c8fdde5" className>
            Unter Angabe des Authorization Headers kann nun eine Abfrage an die
            API gesendet werden. Beispielsweise können Wohneinheiten wie folgt
            geladen werden.
          </p>
          <pre
            id="a2cd24f8-9de4-4dbe-a181-c8889d8a9f76"
            className="api-documentation-code"
          >
            <code>
              query{"{"}
              {"\n"}
              {"  "}units(limit: 100) {"{"}
              {"\n"}
              {"    "}nodes {"{"}
              {"\n"}
              {"      "}id{"\n"}
              {"      "}unitNumber{"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"  "}
              {"}"}
              {"\n"}
              {""}
              {"}"}
            </code>
          </pre>
          <p id="49162ffa-3d1f-4920-a471-97bad273278f">
            Das Ergebnis der Abfrage sieht wie folgt aus.
          </p>
          <pre
            id="a2b37ef5-6b89-4c1f-b182-dbcc970b39d8"
            className="api-documentation-code"
          >
            <code>
              {"{"}
              {"\n"}
              {"    "}"data": {"{"}
              {"\n"}
              {"      "}"units": {"{"}
              {"\n"}
              {"        "}"nodes": [{"\n"}
              {"          "}
              {"{"}
              {"\n"}
              {"            "}"id": "14715",{"\n"}
              {"            "}"unitNumber": "2-Zimmer Wohnung"{"\n"}
              {"          "}
              {"}"},{"\n"}
              {"          "}
              {"{"}
              {"\n"}
              {"            "}...{"\n"}
              {"          "}
              {"}"}
              {"\n"}
              {"        "}]{"\n"}
              {"      "}
              {"}"}
              {"\n"}
              {"    "}
              {"}"}
              {"\n"}
              {"  "}
              {"}"}
            </code>
          </pre>
          <br />
          <div>
            {/* style div space between */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h3 id="dokumentation">Dokumentation</h3>
              <IsSystemAdmin>
                <CreateApiDocumentation
                  trigger={
                    <Button
                      role="button"
                      compact
                      icon="plus"
                      content={intl.formatMessage({
                        id: "meta.actions.add"
                      })}
                    />
                  }
                />
              </IsSystemAdmin>
            </div>
            {documentationItems.length > 0 ? (
              documentationItems.map(item => (
                <div
                  key={item.id}
                  style={{
                    marginBottom: "0.8em"
                  }}
                >
                  <Link
                    to={`${prefix}${item.slug}`}
                    style={{
                      textDecoration: "underline",
                      fontSize: "1.4em",
                      color: "#2185d0"
                    }}
                    onClick={() => {
                      setSeletectedDocumentationItem(item);
                    }}
                  >
                    {item.title}
                  </Link>
                </div>
              ))
            ) : (
              <p>No documentation available.</p>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

DocumentationItemsList.propTypes = {
  documentationItems: PropTypes.array,
  setSeletectedDocumentationItem: PropTypes.func
};

DocumentationItemsList.defaultProps = {
  documentationItems: [],
  setSeletectedDocumentationItem: () => {}
};

export default DocumentationItemsList;
