import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Header,
  Modal,
  Divider
} from "semantic-ui-react";
import PropTypes, { func } from "prop-types";
import { createSelector } from "reselect";
import { get } from "lodash";
import { useSelector } from "react-redux";
import RoomBookController from "../../../controllers/roomBook/roomBookController";
import { RoomBookShape } from "../../../../shared/shapes";
import { getPageContent } from "../../../../shared/selectors";
import { If } from "../../../../shared/components/elements/Conditions";
import IsBetaPreview from "../../../../shared/components/elements/IsBetaPreview";

const getRoomBookPrototypes = createSelector([getPageContent], pageContent => {
  return get(pageContent, "room_book_prototypes", []);
});

const OptionGuard = ({ generatorConfig, option, children }) => {
  const matchesOnly = !option.only || generatorConfig[option.only];
  const matchesExcept = !option.except || !generatorConfig[option.except];

  if (matchesExcept && matchesOnly) {
    return children;
  }

  return null;
};

OptionGuard.propTypes = {
  generatorConfig: PropTypes.shape({}).isRequired,
  option: PropTypes.shape({
    only: PropTypes.string,
    except: PropTypes.string
  }).isRequired,
  children: PropTypes.node.isRequired
};

const GeneratorButton = React.forwardRef(
  ({ ctrl, roomBook, buttonProps, onCompleted }, ref) => {
    const [loading, setLoading] = useState(false);
    const [roomBookPrototypeId, setRoomBookPrototypeId] = useState(
      roomBook.room_book_prototype_id
    );
    const [generatorConfig, setGeneratorConfig] = useState(
      roomBook.generator_options || {}
    );

    const [open, setOpen] = useState(false);

    const roomBookPrototypes = useSelector(getRoomBookPrototypes);

    const handleOpen = useCallback(async () => {
      setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(async () => {
      setOpen(false);
    }, [setOpen]);

    const handleGenerate = useCallback(() => {
      setLoading(true);
      return ctrl
        .generate({
          room_book_prototype_id: roomBookPrototypeId,
          generator_options: generatorConfig
        })
        .then(() => {
          setLoading(false);
          if (typeof onCompleted === "function") {
            onCompleted();
          }
          setOpen(false);
        });
    }, [roomBook, ctrl, roomBookPrototypeId, generatorConfig]);

    const handleChange = useCallback(
      async (_, { value }) => {
        setRoomBookPrototypeId(value);
      },
      [setRoomBookPrototypeId]
    );

    const handleOptionChange = useCallback(
      async (a, { name, checked }) => {
        setGeneratorConfig(current => ({
          ...current,
          [name]: checked
        }));
      },
      [setGeneratorConfig]
    );

    const dropDownOptions = useMemo(() => {
      return roomBookPrototypes.map(p => ({
        value: p.id,
        key: p.id,
        text: p.name
      }));
    }, [roomBookPrototypes]);

    const roomBookPrototype = useMemo(() => {
      return roomBookPrototypes.find(pt => pt.id === roomBookPrototypeId);
    }, [roomBookPrototypes, roomBookPrototypeId]);

    const configOptions = useMemo(() => {
      return roomBookPrototype?.config?.config_options || [];
    }, [roomBookPrototype]);

    return (
      <IsBetaPreview>
        <Modal
          size="small"
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          closeIcon
          // eslint-disable-next-line react/jsx-props-no-spreading
          trigger={<Button {...buttonProps} loading={loading} ref={ref} />}
        >
          <Modal.Header>Raumbuch generieren</Modal.Header>
          <Modal.Content scrolling>
            <Dropdown
              id="room-book-prototypes"
              fluid
              selection
              options={dropDownOptions}
              value={roomBookPrototypeId}
              onChange={handleChange}
              disabled={roomBook.room_book_prototype_id}
            />
            <If condition={!!configOptions.length} block>
              {configOptions.map(group => {
                return (
                  <div className="margin top spacious block" key={group.id}>
                    <Divider />
                    <Header as="h3">{group.title}</Header>
                    <div>
                      {group.options.map(option => {
                        return (
                          <OptionGuard
                            key={option.id}
                            generatorConfig={generatorConfig}
                            option={option}
                          >
                            <div className="margin bottom spacious flex justify-content-space-between">
                              <div>
                                <Header as="h4">{option.title}</Header>
                                <p>{option.description}</p>
                              </div>
                              <div>
                                <Checkbox
                                  toggle
                                  name={option.id}
                                  checked={generatorConfig[option.id]}
                                  onClick={handleOptionChange}
                                />
                              </div>
                            </div>
                          </OptionGuard>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </If>
          </Modal.Content>
          <Modal.Actions>
            <Button
              id="submit-price-catalog"
              color="red"
              onClick={handleGenerate}
              loading={loading}
              content="Vorlage generieren"
            />
          </Modal.Actions>
        </Modal>
      </IsBetaPreview>
    );
  }
);

GeneratorButton.propTypes = {
  roomBook: RoomBookShape.isRequired,
  ctrl: PropTypes.instanceOf(RoomBookController).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
  onCompleted: func
};

GeneratorButton.defaultProps = {
  buttonProps: {},
  onCompleted: () => {}
};

GeneratorButton.displayName = "GeneratorButton";

export default GeneratorButton;
