/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React from "react";
import { Tab } from "semantic-ui-react";
import PropTypes from "prop-types";
import JsonEditor from "shared/components/forms/JsonEditor";

import RenderList from "./RenderList";

const Tabs = props => {
  const { fieldName, onJsonConfigChange, value } = props;
  // eslint-disable-next-line no-unused-vars
  const handleConfigChange = (event, { _, value: newVal }) => {
    onJsonConfigChange(newVal);
  };

  const panes = [
    {
      menuItem: "Visuell",
      render: () => (
        <Tab.Pane>
          <RenderList {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Json",
      render: () => (
        <Tab.Pane>
          <JsonEditor
            key={fieldName}
            label={fieldName}
            name={fieldName}
            value={value}
            onChange={handleConfigChange}
          />
        </Tab.Pane>
      )
    }
  ];
  return <Tab panes={panes} />;
};

Tabs.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onJsonConfigChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default Tabs;
