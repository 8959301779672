import { number, oneOf } from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import FormatNumber from "shared/components/numbers/FormatNumber";
import PriceStrategyShape from "../../../buyer_portal/components/inquiry/priceStrategy.shape";

function FormatQuantity({ mode, value, unit }) {
  const i18nKey =
    mode === "compact"
      ? `roomBook.priceStrategyShort.${unit}`
      : `roomBook.priceStrategy.${unit}`;
  return (
    <span>
      {mode !== "onlyUnit" && unit !== "flat" && (
        <span>
          <FormatNumber value={value} />
          &nbsp;
        </span>
      )}
      <FormattedMessage id={i18nKey} defaultText=" " />
    </span>
  );
}

FormatQuantity.defaultProps = {
  mode: "",
  value: 0,
  unit: ""
};
FormatQuantity.propTypes = {
  value: number,
  unit: PriceStrategyShape,
  mode: oneOf(["compact", "", "onlyUnit"])
};

export default FormatQuantity;
