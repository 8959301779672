import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Header,
  Table,
  Dropdown,
  Loader,
  Grid,
  Button,
  Pagination
} from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { If } from "shared/components/elements/Conditions";
import { getAccount, getProject } from "shared/selectors";
import { getBaseUnit } from "shared/selectors/unit/unit";
import { get } from "lodash";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import Growl from "builder_portal/actions/growlActions";
import FormatNumber from "shared/components/numbers/FormatNumber";
import { UnitVariablesLoaderContext } from "./UnitVariablesLoader";
import UnitVariablesUploadModal from "./UnitVariablesUploadModal";
import UnitVariableDialog from "./UnitVariableDialog";
import { UnitVariablesResource } from "../../../actions/unitVariablesActions";
import HasProjectAccessRight from "../../../../shared/components/authorization/HasProjectAccessRight";

const UnitVariablesList = () => {
  const {
    unitFilterOptions,
    unitVariables,
    isLoading,
    setSelectedUnit,
    setSelectedType,
    isProjectLevel,
    projectId,
    loadVariables,
    total,
    page,
    setPage,
    unitVariableTypesOptions,
    isFiltered
  } = useContext(UnitVariablesLoaderContext);

  const intl = useIntl();
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const project = useSelector(getProject);
  const unit = useSelector(getBaseUnit);

  const handleDelete = (id, unitId, handleClose) => {
    new UnitVariablesResource(dispatch, unitId)
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "roomBook.unitVariables.success.title",
          "roomBook.unitVariables.success.message"
        );
        loadVariables();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const renderDeleteButton = (id, unitId) => {
    const button = (
      <Button
        id="unit_variable_delete"
        icon="trash"
        basic
        loading={isLoading}
        disabled={isLoading}
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => handleDelete(id, unitId, handleClose)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.unitVariables.removeConfirmation.title"
        message="roomBook.unitVariables.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const renderList = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <If condition={isProjectLevel}>
              <Table.HeaderCell>
                <FormattedMessage
                  id="roomBook.unitVariables.tableHeader.unit"
                  defaultValue="Wohneinheit"
                />
              </Table.HeaderCell>
            </If>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.identifier"
                defaultValue="Identifier"
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.name"
                defaultValue="Name"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.value"
                defaultValue="Wert"
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage
                id="roomBook.unitVariables.tableHeader.action"
                defaultValue="Aktion"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {unitVariables.map(item => (
            <Table.Row key={item.id}>
              <If condition={isProjectLevel}>
                <Table.Cell>{item.unit?.display_name}</Table.Cell>
              </If>
              <Table.Cell>{item.unit_variable_type.identifier}</Table.Cell>
              <Table.Cell>{item.unit_variable_type.name}</Table.Cell>
              <Table.Cell textAlign="right">
                <FormatNumber value={item.value} />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <UnitVariableDialog
                  model={{
                    id: item.id,
                    unit_id: item.unit.id,
                    unit_variable_type_id: item.unit_variable_type.id,
                    value: item.value
                  }}
                  projectId={projectId}
                  isLoading={isLoading}
                  button={
                    <Button
                      id="unit_variable_edit"
                      basic
                      icon="setting"
                      loading={isLoading}
                      disabled={isLoading}
                      data-tooltip={intl.formatMessage({
                        id: "meta.actions.edit"
                      })}
                    />
                  }
                />
                {renderDeleteButton(item.id, item.unit.id)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  const handlePageChange = (_, { activePage }) => {
    setPage(activePage);
  };

  return (
    <>
      <Grid>
        <Grid.Column width={13}>
          <Header size="large">
            <FormattedMessage
              id="roomBook.unitVariables.title"
              defaultValue="Unit variables"
            />
          </Header>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <UnitVariableDialog
            model={{
              unit_id: isProjectLevel ? "" : unit?.id,
              unit_variable_type_id: "",
              value: ""
            }}
            projectId={projectId}
            isLoading={isLoading}
            button={
              <Button
                id="unit_variable_new"
                fluid
                icon="plus"
                content={intl.formatMessage({ id: "meta.actions.add" })}
                loading={isLoading}
                disabled={isLoading || isFiltered}
              />
            }
          />
          <If condition={isFiltered}>
            <div style={{ fontSize: "0.7rem" }}>
              <FormattedMessage id="roomBook.unitVariables.enableMessage" />
            </div>
          </If>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={4}>
          <If condition={isProjectLevel} styles={{ display: "block" }}>
            <Header as="h5">
              <FormattedMessage
                id="roomBook.unitVariables.filter"
                defaultValue="Unit Filter"
              />
            </Header>
            <Dropdown
              name="unitFilter"
              fluid
              selection
              search
              clearable
              options={unitFilterOptions}
              onChange={(_, { value }) => setSelectedUnit(value)}
            />
          </If>
        </Grid.Column>
        <Grid.Column width={9}>
          <If condition={isProjectLevel}>
            <Header as="h5">
              <FormattedMessage
                id="roomBook.unitVariables.types.filter"
                defaultValue="Typen Filter"
              />
            </Header>
            <Dropdown
              name="typeFilter"
              selection
              search
              clearable
              options={unitVariableTypesOptions}
              onChange={(_, { value }) => setSelectedType(value)}
            />
          </If>
        </Grid.Column>
        <Grid.Column width={3} textAlign="right" verticalAlign="top">
          <HasProjectAccessRight
            right="allow_access_edit_units"
            projectId={project.id}
          >
            <FeatureToggleActive featureToggleName="show_unit_variables">
              <Header as="h5">
                <FormattedMessage id="project.unit_variables.section.label" />
              </Header>
              <a
                href={`${window.location.origin}/api/v1/projects/${
                  project?.slug
                }/unit_variables/export.xlsx?_bearer=${get(account, [
                  "data",
                  "auth_token"
                ])}
                ${!isProjectLevel ? `&unit_id=${unit?.id}` : ""}
                `}
                rel="noreferrer"
                target="_blank"
              >
                <Button
                  icon="cloud download"
                  data-tooltip={intl.formatMessage({
                    id: "project.unit_variables.section.download_tooltip"
                  })}
                />
              </a>
              <UnitVariablesUploadModal
                unitId={isProjectLevel ? null : unit?.id}
                button={
                  <Button
                    icon="cloud upload"
                    data-tooltip={intl.formatMessage({
                      id: "project.unit_variables.section.upload_tooltip"
                    })}
                  />
                }
              />
            </FeatureToggleActive>
          </HasProjectAccessRight>
        </Grid.Column>
      </Grid>

      {renderList()}
      <If condition={isLoading}>
        <Loader active />
      </If>
      <If condition={!isLoading && unitVariables.length === 0}>
        <Header as="h5">
          <FormattedMessage
            id="roomBook.unitVariables.empty"
            defaultValue="No variables"
          />
        </Header>
      </If>
      <If condition={!isLoading}>
        <Grid>
          <Grid.Column textAlign="center">
            <Pagination
              disabled={total < 2}
              onPageChange={handlePageChange}
              activePage={page || 1}
              totalPages={total || 0}
            />
          </Grid.Column>
        </Grid>
      </If>
    </>
  );
};

export default UnitVariablesList;
