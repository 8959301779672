import { get } from "lodash";
import {
  UPDATE_FLASH_ACTION,
  REMOVE_FLASH_ACTION
} from "../constants/appConstants";

export const flashAction = (state = "", action) => {
  switch (action.type) {
    case UPDATE_FLASH_ACTION:
      return action.payload;
    case REMOVE_FLASH_ACTION:
      if (get(state, "action_id") === get(action, "payload.action_id")) {
        return null;
      }
      return state;

    default:
      return state;
  }
};
