import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { PackageResource } from "builder_portal/actions/packageActions";
import getPackages from "shared/selectors/packages";
import {
  Segment,
  Header,
  Button,
  Label,
  Loader,
  Table
} from "semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";

const PackageManager = () => {
  const dispatch = useDispatch();
  const packages = useSelector(getPackages);
  const [isLoading, setLoading] = useState("");

  useEffect(() => {
    setLoading("initial");
    new PackageResource(dispatch).fetchAll().then(() => {
      setLoading("");
    });
  }, []);

  const handleInstall = id => {
    setLoading(id);
    new PackageResource(dispatch)
      .installation(id)
      .then(res => {
        if (res.status === 201) {
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
          new PackageResource(dispatch).fetchAll();
        }
        setLoading("");
      })
      .catch(() => {
        setLoading("");
      });
  };

  const renderPackages = () => {
    return (
      <>
        <If condition={isLoading === "initial"}>
          <Loader active style={{ marginTop: "20px" }} />
        </If>
        <If condition={!isLoading.length && !packages.length}>
          <FormattedMessage id="packageManager.noPackages" />
        </If>

        <Table>
          {packages.map(p => (
            <Table.Row key={p.id}>
              <Table.Cell width={6}>
                <Header as="h5">{p.name}</Header>
              </Table.Cell>
              <Table.Cell width={7} style={{ fontSize: "0.9rem" }}>
                {p.description}
              </Table.Cell>
              <Table.Cell width={3} textAlign="right">
                <If condition={p.installed}>
                  <Label color="green">
                    <FormattedMessage id="packageManager.installed" />
                  </Label>
                </If>
                <If condition={!p.installed}>
                  <Button
                    onClick={() => handleInstall(p.id)}
                    disabled={isLoading.length}
                    loading={isLoading === p.id}
                  >
                    <FormattedMessage id="packageManager.install" />
                  </Button>
                </If>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </>
    );
  };

  return (
    <Segment style={{ marginTop: 0, paddingBottom: "50px" }}>
      <Header as="h4">
        <FormattedMessage id="packageManager.title" />
      </Header>

      {renderPackages()}
    </Segment>
  );
};

export default PackageManager;
