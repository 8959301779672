import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { get } from "lodash";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import CartDialog from "builder_portal/components/cart/CartDialog";
import { openModal } from "builder_portal/actions/modals";
import LineItemModel from "builder_portal/models/roomBook/LineItemModel";
import CartSelectorModel from "../../models/roomBook/cartSelectorModel";
import CartSelectorController from "../../controllers/roomBook/cartSelectorController";

import "./cartSelector.scss";

class CartSelector extends Component {
  constructor(props) {
    super(props);

    this.handleChangeActivity = this.handleChangeActivity.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.onAddCart = this.onAddCart.bind(this);

    this.state = {
      loading: false
    };
  }

  onAddCart(payload) {
    const { ctrl } = this.props;

    this.setState({ loading: true });
    return ctrl
      .createActivity(payload)
      .then(response =>
        this.applyCartChange(get(response, "data.cart.activity"))
      )
      .catch(() => this.setState({ loading: false }));
  }

  handleChangeActivity(activity) {
    this.setState({ loading: true });
    this.applyCartChange(activity);
  }

  applyCartChange(activity) {
    const { roomBook, lineItem, ctrl, model } = this.props;
    return ctrl
      .changeActivity(roomBook.id, lineItem, get(activity, "id"))
      .then(() =>
        this.setState({ loading: false }, () => {
          if (activity) {
            ctrl.suggestBulkChange(model.getBulkSuggestion(activity));
          }
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  handleCancelOrder() {
    const { lineItem, ctrl } = this.props;

    return ctrl.cancelLineItem({ id: lineItem.getId() });
  }

  renderCartCreationButton() {
    const {
      i18n,
      account,
      lineItem,
      projectId,
      roomBook,
      onOpenModal
    } = this.props;

    const processDefinitions = account
      .getAssignableProcessDefinitions(lineItem.getStatus())
      .filter(pd => {
        return pd.isAvailable(projectId);
      })
      .sort((a, b) => a.getName().localeCompare(b.getName()));

    return processDefinitions.map(pd => {
      const callToAction = pd.getName();
      if (pd.useCartCreationDialog()) {
        return (
          <Button
            key={pd.id}
            id="new-cart"
            compact
            basic
            color="grey"
            icon="plus"
            content={callToAction}
            style={{ marginBottom: "4px" }}
            title={`Vorgang '${pd.getName()}' anlegen und dieser Position zuordnen.`}
            data-type="cartSuggestion"
            onClick={() =>
              onOpenModal("cartCreationDialog", {
                roomBookId: roomBook.id,
                provokingLineItem: lineItem,
                processDefinition: pd
              })
            }
          />
        );
      }

      const cart = {
        process_type: pd.id,
        status: pd.getProcessStartStates()[0].id,
        name: ""
      };
      const button = (
        <Button
          key={pd.id}
          id="new-cart"
          compact
          basic
          color="grey"
          icon="plus"
          content={callToAction}
          style={{ marginBottom: "4px" }}
          title={`Vorgang '${pd.getName()}' anlegen und dieser Position zuordnen.`}
          data-type="cartSuggestion"
        />
      );
      return (
        <CartDialog
          key={pd.id}
          i18n={i18n}
          cart={cart}
          processDefinitions={[pd]}
          button={button}
          handleSave={this.onAddCart}
        />
      );
    });
  }

  renderInner() {
    const { lineItem } = this.props;
    if (lineItem.isCancelled()) {
      return null;
    }
    if (lineItem.hasAssignedCart()) {
      return this.renderCartAssignments();
    }
    return this.renderCartSuggestions();
  }

  renderCancellationButtons() {
    const { lineItem, i18n, toggleDialog } = this.props;
    if (lineItem.isCancellable()) {
      return [
        <Button
          id="cancel"
          key="cancel"
          basic
          color="red"
          icon="undo"
          content={i18n["meta.actions.cancelOrder"]}
          compact
          onClick={() => {
            toggleDialog("cancellationWizard", {
              lineItem
            });
          }}
        />
      ];
    }
    return [];
  }

  renderCartAssignments() {
    const { model } = this.props;
    const { loading } = this.state;

    const assignedActivities = model.getAssignedActivities();

    const activityButtons = assignedActivities.map(activity => {
      return this.renderActiveCart(activity);
    });
    if (model.isUnassignable()) {
      activityButtons.push(
        <Button
          key="remove"
          id="remove-cart"
          size="tiny"
          basic
          color="red"
          loading={loading}
          onClick={() => {
            this.handleChangeActivity(null);
          }}
          style={{ marginBottom: "4px" }}
          icon="remove"
          title="Entfernen Sie diese Position aus dem Vorgang."
        />
      );
    }

    return <div>{activityButtons}</div>;
  }

  renderActiveCart(activity) {
    const { lineItem, projectId } = this.props;
    const color = lineItem.getStatusColor();

    return (
      <Link
        key={activity.id}
        to={`/projects/${projectId}/activities/${activity.id}/timeline`}
      >
        <Button
          data-component="activity-assignment"
          icon="circle"
          color="blue"
          size="tiny"
          content={activity.title}
          style={{ background: color }}
          title={`Diese Position ist aktuell dem Vorgang '${activity.title}' zugeordnet.`}
        />
      </Link>
    );
  }

  renderCartSuggestions() {
    const { i18n, model } = this.props;
    const { loading } = this.state;

    const activities = model.getAssignableActivities();
    const cartButtons = this.renderCartButtons(activities);
    const creationButtons = this.renderCartCreationButton();
    const cancellationButtons = this.renderCancellationButtons();

    const buttons = cartButtons
      .concat(creationButtons)
      .concat(cancellationButtons);
    if (buttons.length) {
      return (
        <Segment basic data-component="cartSuggestions" loading={loading}>
          <Header as="h4">
            {
              i18n[
                "roomBook.lineItems.actions.cartSelection.suggestion.pending"
              ]
            }
          </Header>
          <div>{buttons}</div>
        </Segment>
      );
    }
    return null;
  }

  renderCartButtons(activities) {
    return activities.map(activity => {
      return (
        <Button
          key={activity.id}
          compact
          basic
          color="blue"
          onClick={() => this.handleChangeActivity(activity)}
          style={{ marginBottom: "4px" }}
          title={`Ordnen Sie diese Position dem Vorgang '${activity.title}' zu.`}
        >
          <Icon name="circle" style={{ color: activity.phase_color }} />{" "}
          {activity.title}
        </Button>
      );
    });
  }

  render() {
    return <div data-component="cartSelector">{this.renderInner()}</div>;
  }
}

CartSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
  lineItem: PropTypes.instanceOf(LineItemModel).isRequired,
  roomBook: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  ctrl: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  onOpenModal: PropTypes.func
};

const mapStateToProps = (state, props) => {
  return {
    i18n: state.i18n,
    model: new CartSelectorModel(state, props.lineItem, props.roomBook)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onOpenModal: (id, modalProps) => dispatch(openModal(id, modalProps)),
    ctrl: new CartSelectorController(props.roomBook.id, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSelector);
