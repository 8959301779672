import React, { useState } from "react";
import { Button, Checkbox, Header, List, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import { FormattedMessage } from "react-intl";
import { arrayOf, func, number, shape } from "prop-types";
import { RoomBookShape } from "shared/shapes";
import { createSelector, createStructuredSelector } from "reselect";
import {
  getTemplateRoomBookInfos,
  getUnitRoomBookInfos
} from "shared/selectors/units";

const AdvancedRoomBookCreatorDialog = ({
  templateRoomBookInfos,
  unitRoomBookInfos,
  projectRoomBooksResource,
  unitId,
  onCreate,
  isTemplateId,
  isNotTemplateId
}) => {
  const [selectedRoomBookIds, setSelectedRoomBookIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const isSelected = id => selectedRoomBookIds.includes(id);

  const select = id => {
    if (isTemplateId(id)) {
      setSelectedRoomBookIds([id]);
    } else {
      setSelectedRoomBookIds(previousSelectedRoomBookIds => [
        ...previousSelectedRoomBookIds.filter(isNotTemplateId),
        id
      ]);
    }
  };
  const unselect = removeId =>
    setSelectedRoomBookIds(previousSelectedRoomBookIds =>
      previousSelectedRoomBookIds.filter(id => id !== removeId)
    );
  const onCheckBoxChange = (_event, { value, checked }) =>
    checked ? select(value) : unselect(value);

  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };
  const handleSubmit = () => {
    setIsLoading(true);
    projectRoomBooksResource
      .createFromMultiple(unitId, selectedRoomBookIds)
      .then(() => {
        handleClose();
        onCreate();
      })
      .catch(handleClose);
  };

  return (
    <Modal
      open={isOpen}
      trigger={
        <Button onClick={() => setOpen(true)} data-action="create-room-book">
          <FormattedMessage
            id="roomBook.actions.add"
            defaultMessage="add room-book"
          />
        </Button>
      }
    >
      <Modal.Header>
        <FormattedMessage
          id="roomBook.actions.add"
          defaultMessage="add room-book"
        />
      </Modal.Header>
      <Modal.Content>
        <Header>
          <FormattedMessage
            id="roomBook.actions.advancedCreatorDialog.selectSource"
            defaultMessage="select the source template or room-book(s):"
          />
        </Header>
        <FormattedMessage
          id="roomBook.actions.advancedCreatorDialog.hint"
          defaultMessage="If you select multiple, the sub-sections of all selected room-books will be copied to the new room-book. Main-sections will be merged if possible."
        />
        <Header>
          <FormattedMessage
            id="project.tabs.roomBookTemplates"
            defaultMessage="room-book-templates"
          />
        </Header>
        <List>
          {templateRoomBookInfos.map(({ id, name }) => (
            <List.Item key={id}>
              <Checkbox
                radio
                checked={isSelected(id)}
                label={name}
                value={id}
                onChange={onCheckBoxChange}
                data-model="template-entry"
              />
            </List.Item>
          ))}
        </List>
        <Header>
          <FormattedMessage
            id="project.tabs.roomBooks"
            defaultMessage="room-books"
          />
        </Header>
        <List>
          {unitRoomBookInfos.map(({ id, name }) => (
            <List.Item key={id}>
              <Checkbox
                checked={isSelected(id)}
                label={name}
                value={id}
                onChange={onCheckBoxChange}
                data-model="unit-entry"
              />
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          disabled={isLoading}
          loading={isLoading}
          onClick={handleClose}
        >
          <FormattedMessage id="meta.actions.cancel" defaultMessage="cancel" />
        </Button>
        <Button
          positive
          disabled={isLoading || selectedRoomBookIds.length < 1}
          loading={isLoading}
          onClick={handleSubmit}
        >
          <FormattedMessage id="meta.actions.save" defaultMessage="save" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AdvancedRoomBookCreatorDialog.defaultProps = {
  templateRoomBookInfos: [],
  unitRoomBookInfos: []
};

AdvancedRoomBookCreatorDialog.propTypes = {
  templateRoomBookInfos: arrayOf(RoomBookShape),
  unitRoomBookInfos: arrayOf(RoomBookShape),
  projectRoomBooksResource: shape({
    createFromMultiple: func
  }).isRequired,
  unitId: number.isRequired,
  onCreate: func.isRequired,
  isTemplateId: func.isRequired,
  isNotTemplateId: func.isRequired
};

const getTemplateIds = createSelector(
  [getTemplateRoomBookInfos],
  templateRoomBookInfos => templateRoomBookInfos.map(({ id }) => id)
);
const getIsTemplateId = createSelector([getTemplateIds], templateIds => id =>
  templateIds.includes(id)
);
const getIsNotTemplateId = createSelector([getTemplateIds], templateIds => id =>
  !templateIds.includes(id)
);

const mapStateToProps = createStructuredSelector({
  templateRoomBookInfos: getTemplateRoomBookInfos,
  unitRoomBookInfos: getUnitRoomBookInfos,
  isTemplateId: getIsTemplateId,
  isNotTemplateId: getIsNotTemplateId
});

const mapDispatchToProps = (dispatch, { projectId }) => ({
  projectRoomBooksResource: new ProjectRoomBooksResource(dispatch, projectId)
});

const ConnectedAdvancedRoomBookCreatorDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedRoomBookCreatorDialog);

ConnectedAdvancedRoomBookCreatorDialog.propTypes = {
  templateRoomBookInfos: arrayOf(RoomBookShape),
  unitRoomBookInfos: arrayOf(RoomBookShape),
  projectId: number.isRequired,
  unitId: number.isRequired,
  onCreate: func.isRequired
};

export default ConnectedAdvancedRoomBookCreatorDialog;
