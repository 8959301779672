import React from "react";
import { useDispatch, connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Image, Header, Button, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form } from "formik-semantic-ui-react";
import * as Yup from "yup";

import { Link } from "react-router";
import { FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import apiRequest from "../../../shared/network/apiRequest";
import {
  DELETE_PAGE_CONTENT,
  UPDATE_ACCOUNT,
  UPDATE_FLASH_ACTION,
  UPDATE_MESSAGE
} from "../../../shared/constants/appConstants";
import { browserHistory } from "../../../shared/routes/browserHistory";
import { AlertsResource } from "../../actions/notificationActions";
import localStorage from "../../../shared/helpers/localStorage";
import bauDigitalLogo from "../../../../assets/images/bau-digital-logo.png";
import "./styles.scss";
import { If } from "../../../shared/components/elements/Conditions";

const ssoUrl = window.initialProps?.planoSsoUrl;
const ssoClientId = window.initialProps?.planoSsoClientId;

const Login = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const [stepNumber, setStepNumber] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const emailField = React.useRef(null);
  const passwordField = React.useRef(null);

  React.useEffect(() => {
    if (stepNumber === 0) emailField.current?.focus();
    if (stepNumber === 1) passwordField.current?.focus();
  }, [stepNumber]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Required"),
    password: Yup.string()
  });

  const handleEmailSubmit = email => {
    setLoading(true);
    if (!window.initialProps?.planoSsoEnabled) {
      setStepNumber(stepNumber + 1);
      setLoading(false);
      return;
    }

    const username = email.substring(0, email.indexOf("@"));

    if (username.indexOf("+") > -1) {
      // the user is not eligible for SSO, move on to the password form
      setStepNumber(stepNumber + 1);
      setLoading(false);
      return;
    }

    fetch(`${ssoUrl}/domain_checks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        domain_check: {
          client_id: ssoClientId,
          email
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.domain_check.result) {
          // redirect the user to the provided redirect_url
          window.location.href = data.domain_check.login_url;
        } else {
          // the user is not eligible for SSO, move on to the password form
          setStepNumber(stepNumber + 1);
          setLoading(false);
        }
      })
      .catch(() => {
        setStepNumber(stepNumber + 1);
        setLoading(false);
      });
  };

  const handleClick = values => {
    if (stepNumber === 0) {
      handleEmailSubmit(values.email);
    } else {
      setLoading(true);
      handleSubmit(values, setLoading);
    }
  };

  const handleDelete = () => {
    window.localStorage.clear();

    new Growl(dispatch).success(
      "message.success.title",
      "login.deleteData.success.message"
    );
  };

  const handleKeyUp = (e, values) => {
    if (e.keyCode === 13) {
      if (stepNumber === 0) {
        handleEmailSubmit(values.email);
      } else {
        setLoading(true);
        handleSubmit(values, setLoading);
      }
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
      }}
      initialValues={{ email: "", password: "" }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <div className="loginPageWrapper" data-component="login">
            <div className="formContainer">
              <Grid columns={1}>
                <Grid.Column textAlign="center">
                  <Image
                    src={bauDigitalLogo}
                    style={{
                      width: "60%",
                      height: "auto",
                      margin: "auto"
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={14} style={{ margin: "auto" }}>
                  <Segment>
                    <Header as="h3">
                      <FormattedMessage id="login.loginProcess" />
                    </Header>
                    <p>
                      <If condition={stepNumber === 0}>
                        <FormattedMessage id="login.loginProcessEmailDescription" />
                      </If>
                      <If condition={stepNumber}>
                        <FormattedMessage id="login.loginProcessPasswordDescription" />
                      </If>
                    </p>
                    <Form id="login">
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as="h5" style={{ marginBottom: 4 }}>
                            <FormattedMessage id="login.email2" />
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <Input
                            fluid
                            placeholder="user@baudigital.com"
                            name="email"
                            type="email"
                            value={values?.email}
                            onChange={e => {
                              setFieldValue("email", e.target.value);
                            }}
                            onKeyUp={e => handleKeyUp(e, values)}
                            error={!!errors?.email}
                            disabled={stepNumber === 1}
                            size="large"
                            ref={emailField}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <br />
                      <Grid.Row>
                        {stepNumber === 1 && (
                          <>
                            <Grid.Column width={16}>
                              <div className="flex justify-content-space-between fluid">
                                <Header as="h5">
                                  <FormattedMessage id="login.password" />
                                </Header>
                                <Link
                                  to="/users/password"
                                  style={{
                                    color: "#2185d0",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    textDecoration: "underline"
                                  }}
                                >
                                  <FormattedMessage id="login.forgottenPassword" />
                                </Link>
                              </div>
                            </Grid.Column>
                            <Grid.Column width={16}>
                              <Input
                                fluid
                                placeholder="Passwort"
                                name="password"
                                type="password"
                                value={values?.password}
                                onChange={e => {
                                  setFieldValue("password", e.target.value);
                                }}
                                size="large"
                                ref={passwordField}
                              />
                            </Grid.Column>
                          </>
                        )}
                        <Grid.Column width={16}>
                          <Button
                            type={stepNumber === 1 ? "submit" : "button"}
                            style={{ margin: "10px 0" }}
                            fluid
                            primary
                            loading={loading}
                            onClick={() => handleClick(values)}
                            disabled={
                              !!errors?.email ||
                              !values?.email ||
                              loading ||
                              (stepNumber === 1 && !values?.password)
                            }
                            id="login-button"
                            size="large"
                          >
                            {stepNumber === 0 ? (
                              <FormattedMessage id="login.nextStep" />
                            ) : (
                              <FormattedMessage id="login.signIn" />
                            )}
                          </Button>
                        </Grid.Column>
                        {stepNumber === 1 && (
                          <Grid.Column floated="left" style={{ marginTop: 16 }}>
                            <Button
                              onClick={() => setStepNumber(0)}
                              style={{
                                color: "#2185d0",
                                fontSize: "14px",
                                fontWeight: "bold",
                                textDecoration: "underline",
                                padding: 0
                              }}
                              as="a"
                            >
                              <FormattedMessage id="login.signToDifferentAccount" />
                            </Button>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid>
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                  textAlign: "center"
                }}
              >
                <div
                  role="link"
                  onClick={handleDelete}
                  onKeyDown={handleDelete}
                  tabIndex={0}
                >
                  <FormattedMessage
                    id="login.deleteData.label"
                    default="Alle Cookies Löschen"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: (data, setLoading) => {
      apiRequest
        .post("/api/v1/tokens", dispatch, data)
        .then(response => {
          setLoading(false);
          localStorage.set("token", response.data.token.auth_token);
          // clear any previous stored data
          dispatch({
            type: DELETE_PAGE_CONTENT
          });
          dispatch({
            type: UPDATE_FLASH_ACTION,
            payload: null
          });

          // dispatch action to update account in redux store
          dispatch({
            type: UPDATE_ACCOUNT,
            payload: response.data.token
          });
          // redirect to dashboard
          let landingPage = localStorage.get("landingPage") || "/";
          if (
            [
              "//users/login",
              "/users/login",
              "/users/logout",
              "/users/password"
            ].indexOf(landingPage) >= 0
          ) {
            landingPage = "/";
          }

          return browserHistory.push(landingPage);
        })
        .then(() => {
          new AlertsResource(dispatch).refresh();
        })
        .catch(error => {
          setLoading(false);
          const { data: { redirect } = {}, status } = error;
          if (status === 403 && redirect) {
            dispatch({
              type: UPDATE_MESSAGE,
              payload: {
                title: "message.redirectBuyerApp.title",
                body: "message.redirectBuyerApp.body"
              }
            });
            setTimeout(() => {
              window.location.assign(redirect);
            }, 5000);
          } else if (status === 401) {
            localStorage.remove("token");
            // show message
            dispatch({
              type: UPDATE_MESSAGE,
              payload: {
                title: "message.loginDenied.title",
                body: "message.loginDenied.body"
              }
            });
          } else {
            // eslint-disable-next-line no-console
            console.error(error);

            localStorage.remove("token");
            // show message
            dispatch({
              type: UPDATE_MESSAGE,
              payload: {
                title: "message.error.title",
                body: "message.error.body"
              }
            });
          }
        });
      return false;
    }
  };
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Login);
