import { get, min, max } from "lodash";

const calculateRelativePrice = (price, defaultPrice) => {
  return get(price, "price", 0) - get(defaultPrice, "price", 0);
};

const calculateDefaultPrice = (lineItem, defaultProduct) => {
  if (lineItem.optional) {
    return 0;
  }
  if (lineItem.price_strategy === "flat") {
    return get(defaultProduct, "price", get(lineItem, "default_price", 0));
  }
  return get(defaultProduct, "price", 0) * get(lineItem, "default_quantity", 0);
};

const getExcessPrice = (excessQuantity, lineItem, product, defaultProduct) => {
  if (excessQuantity >= 0) {
    if (lineItem.product_id === product.product_id) {
      return lineItem.excess_price || lineItem.price;
    }
    return product.excess_price || product.price;
  }
  return defaultProduct.price;
};

const calculatePrice = (lineItem, product, defaultProduct) => {
  if (defaultProduct) {
    const actualPrice =
      lineItem.product_id === product?.product_id
        ? lineItem.price
        : get(product, "price", lineItem.price);

    if (lineItem.price_strategy === "flat") {
      const defaultPrice = calculateDefaultPrice(lineItem, defaultProduct);
      return actualPrice - defaultPrice;
    }
    const excessQuantity = max([
      lineItem.quantity - lineItem.default_quantity,
      0
    ]);
    const excessPrice = getExcessPrice(
      excessQuantity,
      lineItem,
      product,
      defaultProduct
    );
    const quantity = min([lineItem.default_quantity, lineItem.quantity]);
    return (
      excessQuantity * excessPrice +
      actualPrice * quantity -
      (lineItem.default_price ?? defaultProduct.price) *
        lineItem.default_quantity
    );
  }
  return lineItem.total;
};

const hasCustomPrice = (lineItem, productPrice) => {
  const price = get(productPrice, "price", lineItem.price);
  return lineItem.price !== price;
};

export const PriceCalculator = {
  calculateRelativePrice,
  calculateDefaultPrice,
  calculatePrice,
  hasCustomPrice
};
