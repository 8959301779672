import React from "react";
import { connect } from "react-redux";
import { arrayOf, number, oneOfType, string, func, shape } from "prop-types";
import { FormattedMessage } from "react-intl";
import { get, includes, pull, map } from "lodash";
import {
  Card,
  Icon,
  Modal,
  Button,
  Menu,
  Image,
  Segment,
  Message
} from "semantic-ui-react";

import FileUploader from "../../dropzone/FileUploader";
import ProjectImagesGallery from "./ProjectImagesGallery";

import { getI18N } from "../../../../shared/selectors";
import { ProjectCardImagesResource } from "../../../actions/projectImagesActions";

import "./imageSelector.scss";

const IMAGES = {
  exterior: [
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/balcony-chair-decor-1428359.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Fenster.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-477369380.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-499569217.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-584877834-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-636865286-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-687165960_800.jpg`
  ],
  interior: [
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-515672600_medium.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Innentreppe.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Innentüren.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-848813992_super.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-465600001.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-490245700.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-502789732-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-506181698-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Garant_bearbeitet.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-525740470-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-525800614-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-592681584-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-611895714-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-621693500.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-627183120.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-640232992-min.jpg`
  ],
  details: [
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Fliesen_Kreuzfuge.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/Sanitärinstallationen.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-104877583.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-172199831-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-478014077_super.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-896249942_super.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-479008782.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-515004902-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-515681216_2_rz.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-531517807-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-531706290-min.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-588225916-min.jpg`
  ],
  construction: [
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-119693331.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-657569712_high.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-484703760_2_rz.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-508257514_2_rz.jpg`,
    `https://res.cloudinary.com/baudigital/${window?.initialProps
      ?.cloudinaryEnv ||
      "production"}/assets-plano-bauunternehmung/section-cards/iStock-542832530_high.jpg`
  ]
};

const defaultGallery = {
  id: "default",
  name: <FormattedMessage id="account.settings.image_selector.menu.default" />,
  images: map(IMAGES, section =>
    section.map((img, index) => {
      return {
        id: index,
        url: img,
        alt: `stock photo`
      };
    })
  )
};

const INIT_STATE = {
  editing: false,
  loading: false,
  idsToDelete: [],
  activeItem: "default",
  openDialog: false
};

class ImageSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INIT_STATE;
  }

  openDialog = () => {
    this.setState({ openDialog: true });
  };

  closeDialog = () => {
    this.setState(INIT_STATE);
  };

  handleAssignImage = imageUrl => {
    const { onChange } = this.props;
    onChange(
      {},
      {
        value: imageUrl
      }
    );
    this.closeDialog();
  };

  handleDelete = () => {
    const { idsToDelete, activeItem } = this.state;
    const { projectCardImagesResource } = this.props;
    this.setState({ loading: true });

    idsToDelete
      .reduce((remove, id) => {
        return remove.then(() =>
          projectCardImagesResource(activeItem)
            .remove(id)
            .then(() => this.setState({ idsToDelete: pull(idsToDelete, id) }))
        );
      }, Promise.resolve())
      .then(() =>
        this.setState({ loading: false, editing: false, idsToDelete: [] })
      )
      .catch(error => {
        // todo: how are we handling console.error in production?
        // eslint-disable-next-line no-console
        console.error("Error:", error);
        this.setState({ renderMessage: "errorDeleting", loading: false });
        setTimeout(() => this.setState({ renderMessage: null }), 6000);
      });
  };

  handleFileUpload = file => {
    const { activeItem } = this.state;
    const { projectCardImagesResource } = this.props;

    this.setState({ loading: true });

    const formData = new FormData();
    formData.append(`image[image]`, file);

    projectCardImagesResource(activeItem)
      .save(formData)
      .then(this.setState({ loading: false }))
      .catch(error => {
        // todo: how are we handling console.error in production?
        // eslint-disable-next-line no-console
        console.error("Error:", error);
        this.setState({ renderMessage: "errorSaving", loading: false });
        setTimeout(() => this.setState({ renderMessage: null }), 6000);
      });
  };

  handleGallerySelection = (e, { id }) => {
    this.setState({
      activeItem: id,
      idsToDelete: [],
      editing: false
    });
  };

  handleSelectImage = id => {
    const { idsToDelete } = this.state;

    if (includes(idsToDelete, id)) {
      this.setState({
        idsToDelete: idsToDelete.filter(image => image !== id)
      });
    } else {
      this.setState({ idsToDelete: [...idsToDelete, id] });
    }
  };

  toggleGalleryEdit = () => {
    this.setState(prevState => ({
      editing: !prevState.editing,
      idsToDelete: []
    }));
  };

  renderFileUploader = (disabled = false) => {
    const { loading } = this.state;
    return (
      <FileUploader
        multiple
        disabled={disabled || loading}
        fileType="image"
        loading={loading}
        handleFile={this.handleFileUpload}
      />
    );
  };

  renderDefaultGallery() {
    const { value } = this.props;
    const gallerySection = section => {
      return (
        <Segment basic key={section[0].url} id="default-gallery">
          <Card.Group itemsPerRow={4}>
            {section.map(image => {
              const className = value === image.url ? "assigned" : "";
              return (
                <Card
                  key={image.id}
                  className={className}
                  onClick={() => this.handleAssignImage(image.url)}
                >
                  <Card.Content>
                    <Image
                      src={image.url}
                      alt={image.alt}
                      height="180px"
                      width="100%"
                    />
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        </Segment>
      );
    };
    return map(defaultGallery.images, section => gallerySection(section));
  }

  renderGalleryMenuItems() {
    const { activeItem, loading } = this.state;
    const { projectGalleries } = this.props;
    return [defaultGallery, ...projectGalleries].map(gallery => {
      return (
        <Menu.Item
          key={gallery.id}
          active={activeItem === gallery.id}
          disabled={loading}
          id={gallery.id}
          content={gallery.name}
          onClick={this.handleGallerySelection}
        />
      );
    });
  }

  renderContent() {
    const { editing, activeItem, idsToDelete, loading } = this.state;
    const { value } = this.props;
    if (activeItem === "default") return this.renderDefaultGallery();

    return (
      <ProjectImagesGallery
        currentlyAssignedImage={value}
        loading={loading}
        editing={editing}
        idsToDelete={idsToDelete}
        projectId={activeItem}
        fileUploader={this.renderFileUploader}
        handleAssignImage={this.handleAssignImage}
        handleSelectImage={this.handleSelectImage}
      />
    );
  }

  renderMessage() {
    const { renderMessage } = this.state;

    let title;
    let body;
    let color;

    switch (renderMessage) {
      case "errorSaving":
        title = "message.errorSave.title";
        body = "message.errorSave.body";
        color = "red";
        break;
      case "errorDeleting":
        title = "message.errorDelete.title";
        body = "message.errorDelete.body";
        color = "red";
        break;
      default:
        break;
    }

    return (
      <Message color={color}>
        <Message.Header>
          <FormattedMessage id={title} />
        </Message.Header>
        <Message.Content>
          <FormattedMessage id={body} />
        </Message.Content>
      </Message>
    );
  }

  render() {
    const {
      openDialog,
      activeItem,
      editing,
      loading,
      idsToDelete,
      renderMessage
    } = this.state;
    const editButton = editing ? "finish_edit" : "edit_gallery";
    return (
      <>
        <Button
          onClick={this.openDialog}
          data-attr="ImageSelectorTrigger"
          type="button"
        >
          <Icon name="search" />
          <FormattedMessage id="account.settings.image_selector.select_image" />
        </Button>
        <Modal
          open={openDialog}
          onClose={this.closeDialog}
          size="large"
          data-component="ImageSelector"
          closeIcon
        >
          <Modal.Header>
            <FormattedMessage id="account.settings.image_selector.select_image" />
          </Modal.Header>
          <Modal.Content className="padding bottom small">
            <Menu pointing>{this.renderGalleryMenuItems()}</Menu>
          </Modal.Content>
          <Modal.Content scrolling className="padding top small">
            <Segment basic loading={loading} className="padding all none">
              {renderMessage && this.renderMessage()}
              {this.renderContent()}
            </Segment>
          </Modal.Content>
          {activeItem !== "default" && (
            <Modal.Actions className="modal-actions">
              {editing && (
                <Button
                  color="red"
                  id="delete-selected-images"
                  disabled={!idsToDelete.length || loading}
                  onClick={this.handleDelete}
                >
                  <FormattedMessage id="account.settings.image_selector.delete_selected_images" />
                </Button>
              )}
              <Button
                toggle
                basic
                id="edit-gallery"
                disabled={loading}
                onClick={this.toggleGalleryEdit}
              >
                <FormattedMessage
                  id={`account.settings.image_selector.${editButton}`}
                />
              </Button>
            </Modal.Actions>
          )}
        </Modal>
      </>
    );
  }
}

ImageSelector.propTypes = {
  projectGalleries: arrayOf(
    shape({
      name: string.isRequired,
      id: oneOfType([string, number]).isRequired
    })
  ).isRequired,
  projectCardImagesResource: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired
};

const mapStateToProps = (state, { projectId }) => {
  const projects = get(state.pageContent, "projects", []);
  const projectGalleries = projects.map(project => {
    return {
      name: project.name,
      id: project.id
    };
  });
  const currentProjectGallery = projectGalleries.filter(
    gallery => gallery.id === projectId
  );

  return {
    currentProjectGallery,
    projectGalleries,
    i18n: getI18N
  };
};

const mapDispatchToProps = dispatch => ({
  projectCardImagesResource: projectId =>
    new ProjectCardImagesResource(dispatch, projectId)
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelector);
