import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Dropdown, Form, Icon } from "semantic-ui-react";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import ObserverResource from "builder_portal/actions/observerActions";
import { getProjectMembers } from "shared/selectors";
import { difference, sortBy } from "lodash";
import { string, oneOfType } from "prop-types";
import { ActivityShape } from "shared/shapes/activity.shape";
import { MessageThreadShape } from "shared/shapes/messageThread.shape";
import { getUser } from "shared/selectors/base";
import FeatureToggleActive from "../elements/FeatureToggleActive";

const Observers = ({ model, modelIdName }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const users = useSelector(getProjectMembers);
  const [isLoading, setLoading] = useState(false);
  const [observerIds, setObserverIds] = useState(
    model.observers?.map(o => o.user_id) || []
  );
  const [observersList, setObserversList] = useState(model.observers || []);

  const projectId = useMemo(() => {
    let pid = model.project_id;
    if (!pid) {
      pid = model.references?.find(r => r.type === "project")?.id;
    }
    return pid;
  }, [model.project_id, model.references]);

  useEffect(() => {
    if (!projectId) return;
    setLoading(true);
    new ProjectsResource(dispatch)
      .fetchMembers(projectId)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [projectId]);

  const handleObserverChange = (_, { value }) => {
    const removedId = difference(observerIds, value);
    const addedId = difference(value, observerIds);

    setLoading(true);

    if (addedId.length) {
      const payload = {
        [modelIdName]: model.id,
        user_id: addedId[0]
      };
      new ObserverResource(dispatch).save(payload).then(res => {
        const newObserver = res.status === 201 ? res.data : [];
        setObserverIds(value);
        setObserversList(observersList.concat(newObserver));
        setLoading(false);
      });
    } else if (removedId.length) {
      const observer = observersList.find(o => o.user_id === removedId[0]);
      const filteredObservers = observersList.filter(o => o.id !== observer.id);
      if (observer) {
        new ObserverResource(dispatch)
          .remove(observer.id)
          .then(() => {
            setObserverIds(value);
            setObserversList(filteredObservers);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  const renderObserversSelector = () => {
    const options = sortBy(
      users.map(u => ({
        key: u.id,
        value: u.id,
        icon: "user",
        text: `${u.first_name} ${u.last_name}`
      })),
      "text"
    );

    return (
      <Form.Field>
        <label htmlFor="assignee-dropdown">
          <Icon name="bell" /> <FormattedMessage id="observers.title" />:
        </label>
        <p />
        <Dropdown
          id="observers-dropdown"
          multiple
          selection
          fluid
          header={intl.formatMessage({
            id: "activity.attributes.observer.label"
          })}
          options={options}
          value={observerIds}
          loading={isLoading}
          disabled={user.group === "user"}
          onChange={handleObserverChange}
        />
      </Form.Field>
    );
  };

  return (
    <FeatureToggleActive featureToggleName="observers">
      {renderObserversSelector()}
    </FeatureToggleActive>
  );
};

Observers.propTypes = {
  //   users: arrayOf(
  //     shape({
  //       id: number,
  //       label: string
  //     })
  //   ).isRequired,
  model: oneOfType([ActivityShape, MessageThreadShape]).isRequired,
  modelIdName: string.isRequired
};

export default Observers;
