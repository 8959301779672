import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";
import { getProductsById } from "./catalog/productGroups";
import { getProjectCatalogs } from "../../shared/selectors";

const DEFAULT_ARRAY = [];

export const getSelectedPriceCatalogId = (_, props) => {
  return get(props, "priceCatalogId");
};

export const getProductGroup = createSelector([getPageContent], pageContent => {
  return get(pageContent, "product_group");
});

export const getProductGroups = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "product_groups", DEFAULT_ARRAY);
  }
);

export const getProducts = createSelector([getPageContent], pageContent => {
  return get(pageContent, "products", DEFAULT_ARRAY);
});

export const getProductOptionUsageCounts = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "product_option_usage_counts", {});
  }
);

export const getProductGroupProductCandidates = createSelector(
  [getProjectCatalogs, getProductGroup, getProducts],
  (projectCatalogs, productGroup, products) => {
    const projectCatalog = projectCatalogs.find(
      catalog => catalog.id === productGroup?.project_catalog_id
    );

    return products.filter(
      product =>
        product.product_catalog_id === projectCatalog?.product_catalog_id
    );
  }
);

export const getProductOptions = createSelector(
  [getProductGroup, getProductOptionUsageCounts],
  (productGroup, productOptionUsageCounts) => {
    return get(productGroup, "product_options", DEFAULT_ARRAY).map(p => ({
      ...p,
      usage_count: productOptionUsageCounts[p.id]
    }));
  }
);

export const getProductPrices = createSelector(
  [getProductOptions, getProductsById, getSelectedPriceCatalogId],
  (productOptions, productsById, priceCatalogId) => {
    if (!priceCatalogId) {
      return [];
    }

    const prices = productOptions.reduce((accu, option) => {
      const price = option.product_prices.find(p => {
        return p.price_catalog_id === priceCatalogId;
      });

      if (price) {
        accu.push({
          ...option,
          ...price,
          product: productsById[option.product_id],
          cost: price.costs_attributes.reduce((s, c) => s + c.cost, 0),
          excess_cost: price.costs_attributes.reduce((s, c) => {
            return s + (c.excess_cost || c.cost);
          }, 0)
        });
      }

      return accu;
    }, []);
    const defaultPrice = prices.find(price => price.is_default);
    return prices.map(price => {
      if (defaultPrice) {
        return {
          ...price,
          extra_price: price.price - defaultPrice.price,
          extra_cost: price.cost - defaultPrice.cost
        };
      }
      return price;
    });
  }
);

export const makeGetDefaultPrice = () => {
  return createSelector([getProductPrices], prices => {
    return prices.find(price => price.is_default);
  });
};

export const makeGetAlternativePrices = () => {
  return createSelector([getProductPrices], prices => {
    return prices.filter(price => !price.is_default);
  });
};

export const makeGetPriceMap = () => {
  const getDefaultPrice = makeGetDefaultPrice();
  const getAlternativePrices = makeGetAlternativePrices();

  return createSelector(
    [getDefaultPrice, getAlternativePrices],
    (defaultPrice, alternativePrices) => {
      if (defaultPrice) {
        const accu = {};
        accu[defaultPrice.product_option_id] = [
          {
            id: "defaultPrice",
            portalOffer: defaultPrice.portal_offer
          }
        ];

        return alternativePrices.reduce((accu2, price) => {
          accu2[price.product_option_id] = [
            {
              id: "extraPrice",
              amount: price.price - defaultPrice.price,
              onRequest: price.on_request,
              portalOffer: price.portal_offer
            }
          ];
          return accu2;
        }, accu);
      }
      return alternativePrices.reduce((accu, price) => {
        accu[price.product_option_id] = [
          {
            id: "unitPrice",
            amount: price.price,
            onRequest: price.on_request,
            portalOffer: price.portal_offer
          }
        ];
        return accu;
      }, {});
    }
  );
};
