import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Popup, Icon } from "semantic-ui-react";
import { Link } from "react-router";
import { withRouter } from "../../../shared/helpers/withRouter";

class SourceInfo extends PureComponent {
  static propTypes = {
    roomBook: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  render() {
    const {
      roomBook: { sources = [] },
      params: { projectId }
    } = this.props;

    const sourceRoomBookNames = sources.map(roomBook => ({
      content: roomBook.name,
      href: `/projects/${projectId}/room_books/${roomBook.id}`,
      key: roomBook.id
    }));

    if (sourceRoomBookNames.length) {
      const parents = sourceRoomBookNames.map(source => {
        return (
          <span className="ellipsis ellipsis-300" key={source.key}>
            <Link to={source.href}>
              <Icon name="level up" />
              <span className="thin" role="link">
                {source.content}
              </span>
            </Link>
          </span>
        );
      });
      const content = (
        <div>
          <strong>
            <FormattedMessage id="roomBook.derivedFrom" />
          </strong>
          {parents}
        </div>
      );
      const button = <Button icon="fork" />;
      return (
        <Popup
          trigger={button}
          content={content}
          on="click"
          position="top right"
        />
      );
    }
    return null;
  }
}

export default withRouter(SourceInfo);
