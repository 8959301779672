import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import {
  sortBy,
  invert,
  cloneDeep,
  keyBy,
  compact,
  uniq,
  groupBy
} from "lodash";
import {
  Accordion,
  Button,
  Form,
  Header,
  Icon,
  Segment,
  Table,
  Image,
  Grid,
  Popup
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import { arrayOf, func, object } from "prop-types";
import { ProductGroupShape } from "shared/shapes";
import ProductInfoModal from "./ProductInfoModal";
import ParamMapperModal from "./ParamMapperModal";

function getUsageStats(paramMapping, productMapping) {
  let usedCount = 0;
  let totalCount = 0;
  const unused = {};

  Object.entries(paramMapping).forEach(([key, values]) =>
    Object.keys(values).forEach(value => {
      if (
        Object.values(productMapping).some(entries =>
          Object.values(entries).some(
            ([entryKey, entryValue]) => entryKey === key && entryValue === value
          )
        )
      ) {
        usedCount += 1;
      } else {
        unused[key] ||= [];
        unused[key].push(value);
      }
      totalCount += 1;
    })
  );
  return { usedCount, totalCount, unused };
}

const ProductMapping = ({
  isLoading,
  paramMapping,
  onChange,
  value,
  productGroupsList
}) => {
  const intl = useIntl();

  const [productGroups, setProductGroups] = useState(
    cloneDeep(productGroupsList)
  );
  const [configMapperOpen, setConfigMapperOpen] = useState(false);
  const [mapperDialogOpen, setMapperDialogOpen] = useState(0);
  const [productDetail, setProductDetail] = useState(null);

  const ambiguousParams = useMemo(() => {
    const temp = {};
    Object.entries(paramMapping).forEach(([key, v]) => {
      temp[key] = [];
      const paramNames = sortBy(compact(Object.entries(v)?.map(x => x[1])));
      const uniqParamNames = uniq(paramNames);
      if (paramNames.length !== uniqParamNames.length) {
        const grouped = groupBy(paramNames);
        Object.keys(grouped).forEach(key_g => {
          if (grouped[key_g].length > 1) temp[key].push(grouped[key_g][0]);
        });
      }
    });
    return temp;
  }, [paramMapping]);

  const invertedParamMapping = useMemo(() => {
    const map = {};
    Object.keys(paramMapping).forEach(key => {
      const temp = invert(paramMapping[key]);
      // paramMapping can have a lot of null values, and we should delete null key after inverting
      delete temp.null;
      map[key] = temp;
    });
    return map;
  }, [paramMapping]);

  const getProductsDetails = productGroup => {
    const product_mapping = value;
    return productGroup.products.map(product => {
      // prevent mapping product with ambigous param names
      const productParam =
        ambiguousParams[productGroup.glencoe_product_category]?.indexOf(
          product.glencoe_product_id
        ) > -1
          ? undefined
          : invertedParamMapping?.[productGroup.glencoe_product_category]?.[
              product.glencoe_product_id
            ];
      return {
        ...product,
        selected: product_mapping?.[productGroup.id]?.[product.id],
        mappedParam: product_mapping?.[productGroup.id]?.[product.id]?.[1],
        productParam
      };
    });
  };

  useEffect(() => {
    setProductGroups(productGroupsList);
  }, [productGroupsList]);

  const pgMapping = useMemo(() => {
    if (!invertedParamMapping || productGroups.length === 0) return [];
    return sortBy(productGroups, "name").map(productGroup => {
      return {
        ...productGroup,
        products: getProductsDetails(productGroup)
      };
    });
  }, [productGroups, invertedParamMapping]);

  const renderProductGroupsList = useMemo(
    () =>
      pgMapping.reduce((accu, item) => {
        return accu.concat([
          <Table.Row key={item.id}>
            {!!item.thumb_url && (
              <Table.Cell>
                <Image src={item.thumb_url} size="mini" alt={item.name} />
              </Table.Cell>
            )}
            <Table.Cell>
              <Link
                target="_catalog"
                to={`/products/${item.project_catalog_slug}/product_groups/${item.trades?.[0]}/${item.id}`}
              >
                <div>{item.name}</div>
                <div className="description">{item.desciption}</div>
                <div className="catalog-name">{item.project_catalog_name}</div>
              </Link>
            </Table.Cell>
            <Table.Cell>
              {item.products?.filter(x => x?.selected)?.length || 0}/
              {item.products?.length || 0}
            </Table.Cell>
            <Table.Cell>
              <Button
                onClick={event => {
                  event.preventDefault();
                  setMapperDialogOpen(item.id);
                }}
              >
                <FormattedMessage id="meta.actions.edit" />
              </Button>
            </Table.Cell>
            <If
              condition={
                !!item.products.filter(
                  p =>
                    p.mappedParam &&
                    p.productParam &&
                    p.mappedParam !== p.productParam
                ).length
              }
            >
              <Table.Cell>
                <Popup
                  content={intl.formatMessage({
                    id:
                      "project.attributes.buyer_portal.auto_product_mapping.diverting_tooltip"
                  })}
                  trigger={<Icon name="warning circle" color="red" />}
                />
              </Table.Cell>
            </If>
          </Table.Row>
        ]);
      }, []),
    [pgMapping]
  );

  const activeProductGroup = useMemo(() => {
    return pgMapping.find(x => x.id === mapperDialogOpen);
  }, [mapperDialogOpen, pgMapping]);

  const handleMapperApply = (event, changedProductGroup) => {
    setMapperDialogOpen(0);
    // update mapperFormObject
    const cleanedProductGroup = {
      ...changedProductGroup,
      products: changedProductGroup.products.map(product => ({
        ...product,
        selected: product.selected?.[1] ? product.selected : undefined
      }))
    };
    const modifiedProductGroups = productGroups.map(g =>
      g.id === cleanedProductGroup.id ? cleanedProductGroup : g
    );
    setProductGroups(modifiedProductGroups);

    // create mapped params json data for product_mapping field
    const newMappingData = {};
    pgMapping.forEach(group => {
      const productGroup =
        group.id === cleanedProductGroup.id ? cleanedProductGroup : group;
      const mappingProducts = {};
      productGroup.products.forEach(product => {
        if (product.selected && product.selected[0] && product.selected[1]) {
          mappingProducts[product.id] = product.selected;
        }
      });
      if (Object.keys(mappingProducts).length > 0)
        newMappingData[productGroup.id] = mappingProducts;
    });
    onChange(event, {
      value: newMappingData
    });
  };

  const handleAutoMap = event => {
    if (event) event.preventDefault();
    const currentProductMapping = cloneDeep(value);
    pgMapping.forEach(productGroup => {
      const groupMapping = {};
      productGroup.products.forEach(p => {
        if (!p.mappedParam && p.productParam)
          groupMapping[p.id] = [
            productGroup.glencoe_product_category,
            p.productParam
          ];
      });

      // convert to json format for form field
      if (Object.keys(groupMapping).length > 0) {
        if (!currentProductMapping[productGroup.id])
          currentProductMapping[productGroup.id] = {};
        Object.entries(groupMapping).forEach(([key, v]) => {
          currentProductMapping[productGroup.id][key] = v;
        });
      }
    });

    // changed value will update product groups vlaues
    onChange(event, {
      value: currentProductMapping
    });
  };

  useEffect(() => {
    // if json field is changed, propagate changes to product list
    if (productGroups.length === 0) return;

    const data = keyBy(cloneDeep(productGroups), "id");
    Object.keys(value).forEach(groupId => {
      Object.keys(value[groupId]).forEach(productId => {
        const v = value[groupId][productId];
        const temp = data[groupId]?.products.find(
          p => p.id === parseInt(productId, 10)
        );
        if (temp) temp.selected = v;
      });
    });
    const modifiedProductGroups = Object.values(data).map(d => d);
    setProductGroups(sortBy(modifiedProductGroups, "name"));
  }, [value]);

  const mappingsCount = useMemo(() => {
    let counter = 0;
    pgMapping.forEach(g => {
      g.products.forEach(p => {
        if (!p.mappedParam && p.productParam) counter += 1;
      });
    });
    return counter;
  }, [pgMapping]);

  const { usedCount, totalCount, unused } = useMemo(() => {
    return getUsageStats(paramMapping, value);
  }, [paramMapping, value]);

  return (
    <>
      <If condition={mappingsCount > 0} styles={{ width: "100%" }}>
        <Grid>
          <Grid.Column width="12" verticalAlign="bottom">
            <Header as="h5">
              <Icon name="map marker" />
              <FormattedMessage
                id="project.attributes.buyer_portal.auto_product_mapping.label"
                values={{ num: mappingsCount }}
              />
            </Header>
          </Grid.Column>
          <Grid.Column width="4" verticalAlign="bottom" textAlign="right">
            <Button onClick={handleAutoMap}>
              <FormattedMessage id="project.attributes.buyer_portal.auto_product_mapping.button_label" />
            </Button>
          </Grid.Column>
        </Grid>
      </If>

      <If condition={mappingsCount === 0}>
        <Header as="h5" className="section-header">
          <Icon name="exclamation circle" />
          <Header.Content>
            <FormattedMessage id="project.attributes.buyer_portal.auto_product_mapping.no_products" />
          </Header.Content>
        </Header>
      </If>

      <Segment
        basic
        size="small"
        loading={
          isLoading("catalogs") ||
          isLoading("product-groups") ||
          isLoading("param_mapping")
        }
      >
        <Accordion.Title
          active={configMapperOpen}
          index="config"
          data-attr="config-title"
          onClick={() => setConfigMapperOpen(!configMapperOpen)}
        >
          <Header as="h5" className="section-header">
            <Icon name="edit" />
            <Header.Content>
              <FormattedMessage id="project.attributes.buyer_portal.product_mapping.label" />
            </Header.Content>
          </Header>
          <Icon name="dropdown" className="section-caret" />
        </Accordion.Title>
        <Accordion.Content active={configMapperOpen} data-attr="config-content">
          <Table>
            <Table.Body>{renderProductGroupsList}</Table.Body>
          </Table>
          <span>
            Mapped entries: {usedCount}/{totalCount}
          </span>
          <Form.TextArea
            label="Unused entries"
            readOnly
            value={JSON.stringify(unused, null, 2)}
          />
        </Accordion.Content>
      </Segment>
      <ParamMapperModal
        mapperDialogOpen={mapperDialogOpen}
        activeProductGroup={activeProductGroup}
        setProductDetail={setProductDetail}
        handleMapperApply={handleMapperApply}
        paramMapping={paramMapping}
        setMapperDialogOpen={setMapperDialogOpen}
      />
      <ProductInfoModal
        productDetail={productDetail}
        setProductDetail={setProductDetail}
      />
    </>
  );
};

ProductMapping.propTypes = {
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: object,
  productGroupsList: arrayOf(ProductGroupShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  paramMapping: object.isRequired,
  isLoading: func.isRequired
};

ProductMapping.defaultProps = {
  value: {}
};

export default ProductMapping;
