import React from "react";
import { Header } from "semantic-ui-react";
import {
  HeaderCellShape,
  BodyCellShape
} from "shared/shapes/tableContent.shape";
import { oneOfType } from "prop-types";

import "../documentExport.scss";

const DropdownItem = ({ item }) => {
  return (
    <>
      <Header as="h5">{item.title || item.name}</Header>
      <div className="secondary">{item.description}</div>
    </>
  );
};

DropdownItem.propTypes = {
  item: oneOfType([HeaderCellShape, BodyCellShape]).isRequired
};

export default DropdownItem;
