import React, { useMemo } from "react";
import { Form, Dropdown, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { shape, string, func, object } from "prop-types";
import { If } from "shared/components/elements/Conditions";

const SemanticFormikMessageLabelsDropdown = props => {
  const {
    field: { name, value },
    form: { setFieldValue, errors, touched },
    label
  } = props;

  const options = useMemo(() => {
    // eslint-disable-next-line no-use-before-define
    return colorOptions.map(cOption => ({
      key: cOption.color,
      value: cOption.color,
      text: (
        <span>
          <Icon name="square full" color={cOption.color} />
          <FormattedMessage
            id={`email.message_labels.colors.${cOption.color}`}
            default={cOption.text}
          />
        </span>
      )
    }));
  }, []);

  const handleChange = (e, v) => {
    setFieldValue(name, v.value);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Dropdown
        selection
        fluid
        options={options}
        onChange={handleChange}
        value={value}
        error={!!errors[name] && !!touched[name]}
      />
      <If condition={!!errors[name] && !!touched[name]}>
        <div className="ui pointing above prompt label">{errors[name]}</div>
      </If>
    </Form.Field>
  );
};

SemanticFormikMessageLabelsDropdown.propTypes = {
  field: shape({
    name: string
  }).isRequired,
  form: shape({
    setFieldValue: func,
    // eslint-disable-next-line react/forbid-prop-types
    errors: object,
    // eslint-disable-next-line react/forbid-prop-types
    touched: object
  }).isRequired,
  label: string.isRequired,
  value: string.isRequired
};

export default SemanticFormikMessageLabelsDropdown;

const colorOptions = [
  {
    key: "red",
    text: "Red",
    value: "red",
    color: "red"
  },
  {
    key: "orange",
    text: "Orange",
    value: "orange",
    color: "orange"
  },
  {
    key: "yellow",
    text: "Yellow",
    value: "yellow",
    color: "yellow"
  },
  {
    key: "olive",
    text: "Olive",
    value: "olive",
    color: "olive"
  },
  {
    key: "green",
    text: "Green",
    value: "green",
    color: "green"
  },
  {
    key: "teal",
    text: "Teal",
    value: "teal",
    color: "teal"
  },
  {
    key: "blue",
    text: "Blue",
    value: "blue",
    color: "blue"
  },
  {
    key: "violet",
    text: "Violet",
    value: "violet",
    color: "violet"
  },
  {
    key: "purple",
    text: "Purple",
    value: "purple",
    color: "purple"
  },
  {
    key: "pink",
    text: "Pink",
    value: "pink",
    color: "pink"
  },
  {
    key: "brown",
    text: "Brown",
    value: "brown",
    color: "brown"
  },
  {
    key: "grey",
    text: "Grey",
    value: "grey",
    color: "grey"
  },
  {
    key: "black",
    text: "Black",
    value: "black",
    color: "black"
  }
];
