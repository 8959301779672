import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form, Select } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";

const elementsToAddDefaults = [
  {
    name: "project_index",
    value: "project_index",
    text: "Project Index"
  }
];

const AddNewPage = ({ trigger, onAddPage, model }) => {
  const refResetForm = useRef();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (typeof refResetForm?.current === "function") refResetForm.current();
  };

  const handleOpen = () => setOpen(true);

  const onSubmit = values => {
    onAddPage(values);
    handleClose();
  };

  return (
    <Formik
      initialValues={{
        ...model
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            open={open}
            onClose={handleClose}
            size="tiny"
            closeIcon
            trigger={trigger}
            onOpen={handleOpen}
          >
            <Modal.Header>
              <FormattedMessage id="configuratorDesign.dialog.contentPageEditor.addPage" />
            </Modal.Header>
            <Modal.Content>
              <Form id="configuratorBackendForm">
                <Input
                  id="title"
                  name="title"
                  label={intl.formatMessage({
                    id: "configuratorDesign.dialog.contentPageEditor.title"
                  })}
                />
                <Select
                  id="type"
                  name="type"
                  label={intl.formatMessage({
                    id: "configuratorDesign.dialog.contentPageEditor.type"
                  })}
                  options={elementsToAddDefaults}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions className="right">
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({ id: "meta.actions.cancel" })}
                onClick={handleClose}
                loading={isSubmitting}
              />
              <Button
                primary
                type="submit"
                content={intl.formatMessage({ id: "meta.actions.add" })}
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

AddNewPage.propTypes = {
  trigger: PropTypes.element.isRequired,
  onAddPage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object.isRequired
};

export default AddNewPage;
