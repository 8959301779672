import React from "react";
import { Link } from "react-router";
import { Icon, Menu } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { ActivityShape } from "shared/shapes/activity.shape";
import { If } from "shared/components/elements/Conditions";
import { bool } from "prop-types";
import IsBetaPreview from "../../../shared/components/elements/IsBetaPreview";

function TabMenu({ activity, priceCostDiverged }) {
  const backUrl =
    activity.units.length === 1
      ? `/projects/${activity.project.slug}/units/${activity.units[0].slug}/room-book`
      : `/projects/${activity.project.slug}/sectionAndUnit`;

  const rootUrl = `/projects/${activity.project.slug}/activities/${activity.id}`;
  return (
    <Menu stackable attached="top" data-component="activityTabMenu">
      <Menu.Item link name="room-book">
        <Link id="room-book" name="room-book" to={backUrl} onlyActiveOnIndex>
          <Icon name="arrow left" />
          <FormattedMessage id="meta.actions.back" defaultMessage="Zurück" />
        </Link>
      </Menu.Item>
      <Menu.Item id="display_name" name="display_name" key={activity.id}>
        <strong>{activity.display_name}</strong>
      </Menu.Item>
      <Menu.Menu position="right">
        <Link
          id="activity-timeline-tab"
          name="timeline"
          className="item"
          activeClassName="active item"
          to={`${rootUrl}/timeline`}
          onlyActiveOnIndex
        >
          <FormattedMessage
            id="activity.tabs.timeline"
            defaultMessage="Timeline"
          />
        </Link>
        <If condition={!activity.hides_carts}>
          <Link
            id="activity-cart-tab"
            name="cart"
            className="item"
            activeClassName="active item"
            to={`${rootUrl}/cart`}
            onlyActiveOnIndex
          >
            <If condition={priceCostDiverged}>
              <Icon name="warning circle" color="yellow" />
            </If>
            <FormattedMessage
              id="activity.tabs.cart"
              defaultMessage="Leistungsumfang"
            />
          </Link>
        </If>
      </Menu.Menu>
      <IsBetaPreview>
        <Menu.Menu style={{ borderLeft: "1px solid rgba(34,36,38,.15)" }}>
          <Link
            id="activity-costs-tab"
            name="costs"
            className="item"
            activeClassName="active item"
            to={`${rootUrl}/costs`}
            onlyActiveOnIndex
          >
            <FormattedMessage
              id="activity.costs.summary"
              defaultMessage="Zusammenfassung"
            />
          </Link>
        </Menu.Menu>
      </IsBetaPreview>
    </Menu>
  );
}

TabMenu.propTypes = {
  activity: ActivityShape.isRequired,
  priceCostDiverged: bool.isRequired
};

export default TabMenu;
