/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  Button,
  Image,
  Modal,
  Grid,
  Header,
  Input,
  Icon,
  Checkbox,
  List
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import copyToClipboard from "copy-to-clipboard";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { ProjectImageResource } from "builder_portal/actions/projectActions";
import { ProjectImageLibraryResource } from "../../../builder_portal/actions/projectRoleImagesActions";
import { AccountImagesResource } from "../../../builder_portal/actions/accountImagesActions";
import "./imageSelector.scss";

function ImageLibrary({ projectId, getSelectedImage, images, button }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const imageLibrary = projectId
    ? new ProjectImageLibraryResource(dispatch, projectId)
    : new AccountImagesResource(dispatch);
  const growl = new Growl(dispatch);

  const [open, setOpen] = React.useState(false);
  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    imageLibrary.fetchAll();
  }, []);

  const isImageInSelectedImage = image => {
    return selectedImage && selectedImage.id === image.id;
  };

  const filterImagesBySearchTerm = searchTerm => {
    setAllImages(
      allImages.filter(image => {
        return image.image_file_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
  };

  React.useEffect(() => {
    setAllImages(images);
  }, [images]);

  const onDrop = acceptedFiles => {
    const maxSizeAllowed = 5000000;
    setLoading(true);
    const promises = acceptedFiles.map(file => {
      if (file.size > maxSizeAllowed) {
        growl.error("message.error.title", "imageSelector.fileExceededSize");
        return;
      }

      const formData = new FormData();
      formData.append("image[image]", file);
      formData.append(
        "image[role]",
        projectId ? "project_image" : "account_image"
      );
      // eslint-disable-next-line consistent-return
      return imageLibrary.save(formData);
    });

    Promise.all(promises)
      .then(() => {
        imageLibrary.fetchAll().then(response => {
          setAllImages(response.data);
          setLoading(false);
        });
      })
      .catch(() => {
        growl.error("message.error.title", "imageSelector.uploadImageError");
        setLoading(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*"
  });

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
  };

  const onDelete = () => {
    setLoadingDelete(true);
    const imageResource = projectId
      ? new ProjectImageResource(dispatch, projectId)
      : imageLibrary;
    imageResource
      .remove(selectedImage.id)
      .then(() => {
        setLoadingDelete(false);
        setSelectedImage(null);
        setOpenDeleteDialog(false);
        new Growl(dispatch).success(
          "imageSelector.deleteDialog.message.success.title",
          "imageSelector.deleteDialog.message.success.description"
        );
        return imageLibrary.fetchAll();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "imageSelector.deleteDialog.message.error.title",
          "imageSelector.deleteDialog.message.error.description"
        );
        setLoadingDelete(false);
        setOpenDeleteDialog(false);
      });
  };

  const renderCopyButton = url => {
    return (
      <Button
        fluid
        icon
        basic
        labelPosition="right"
        onClick={event => {
          event.preventDefault();
          copyToClipboard(url);
          growl.success("imageSelector.copyTitle", "imageSelector.copyBody", {
            timeout: 750
          });
        }}
      >
        <FormattedMessage
          id="imageSelector.copyButtonLabel"
          default="Kopieren"
        />
        <Icon name="linkify" />
      </Button>
    );
  };

  const renderDeleteButton = () => {
    const deleteButton = (
      <Button
        id="delete"
        color="red"
        basic
        fluid
        icon="trash"
        data-form="process_type"
        loading={loadingDelete}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
        disabled={selectedImage?.image_references_count > 0}
        onClick={() => setOpenDeleteDialog(true)}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete,
        loading: loadingDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true,
        loading: loadingDelete
      }
    ];

    return (
      <ConfirmationDialog
        title="imageSelector.deleteDialog.deleteTitle"
        message="imageSelector.deleteDialog.deleteMessage"
        buttons={buttons}
        button={deleteButton}
        open={openDeleteDialog}
      />
    );
  };

  const trigger = () => (
    <Button type="button" id="image-selector-button">
      <FormattedMessage id="imageSelector.selectImage" />
    </Button>
  );

  return (
    <Modal
      onClose={() => handleClose()}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={button || trigger()}
      size="large"
    >
      <Modal.Header style={{ backgroundColor: "#00456F" }}>
        <Header style={{ color: "white" }} as="h2">
          <FormattedMessage id="imageSelector.mediaLibrary" />
        </Header>
      </Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        <Grid celled style={{ padding: 0, margin: 0 }}>
          <Grid.Column width={12}>
            <Grid>
              <Grid.Column width={12}>
                <Input
                  fluid
                  placeholder="Search..."
                  onChange={e => filterImagesBySearchTerm(e.target.value)}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    primary
                    loading={loading}
                    floated="right"
                    style={{ marginRight: 20 }}
                  >
                    <FormattedMessage id="imageSelector.uploadImage" />
                  </Button>
                </div>
              </Grid.Column>
              <Checkbox
                label={intl.formatMessage({
                  id: "imageSelector.filterByImageReferenceCount"
                })}
                onChange={(e, { checked }) => {
                  if (checked) {
                    setAllImages(
                      allImages.filter(image => {
                        return image.image_references_count === 0;
                      })
                    );
                  } else {
                    setAllImages(images);
                  }
                }}
              />
              <Grid.Column width={16} style={{ minHeight: 300 }}>
                {allImages && allImages.length > 0 ? (
                  <Grid
                    style={{
                      padding: "10px",
                      height: "500px",
                      overflowY: "scroll"
                    }}
                    columns={3}
                  >
                    {allImages.map(image => (
                      <Grid.Column key={image.id} style={{ paddingLeft: 0 }}>
                        <div
                          style={{
                            border: isImageInSelectedImage(image)
                              ? "1.5px solid #00456F"
                              : "none",
                            boxShadow: isImageInSelectedImage(image)
                              ? "0px 0px 5px 0px #00456F"
                              : "none",
                            padding: "4px",
                            height: "250px",
                            marginBottom: "16px"
                          }}
                        >
                          <Image
                            src={image.large_url}
                            size="medium"
                            style={{
                              width: "100%",
                              height: "85%",
                              objectFit: "cover"
                            }}
                            onClick={() => {
                              if (isImageInSelectedImage(image)) {
                                setSelectedImage(null);
                              } else {
                                setSelectedImage(image);
                              }
                            }}
                            onDoubleClick={() => {
                              getSelectedImage(image);
                              handleClose();
                            }}
                          />
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap"
                            }}
                          >
                            {image.image_file_name}
                          </div>
                        </div>
                      </Grid.Column>
                    ))}
                  </Grid>
                ) : (
                  <Grid centered columns={2} style={{ marginTop: 60 }}>
                    <Grid.Column textAlign="center" width={16}>
                      <Icon name="images outline" size="big" />
                    </Grid.Column>
                    <Grid.Column textAlign="center" width={16}>
                      <Header as="h3">
                        <FormattedMessage id="imageSelector.noImagesInMediaLibrary" />
                      </Header>
                    </Grid.Column>
                  </Grid>
                )}
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            {selectedImage ? (
              <List relaxed>
                <List.Item
                  style={{
                    textAlign: "left"
                  }}
                >
                  <Image
                    src={selectedImage?.large_url}
                    size="medium"
                    style={{
                      height: "250px",
                      objectFit: "contain"
                    }}
                  />
                </List.Item>
                <List.Item
                  style={{
                    textAlign: "left"
                  }}
                >
                  {selectedImage?.image_file_name}
                </List.Item>
                <List.Item
                  style={{
                    textAlign: "left"
                  }}
                >
                  <div>
                    <FormattedMessage id="imageSelector.imageReferenceCount" />:{" "}
                    {selectedImage?.image_references_count}
                  </div>
                </List.Item>
                <List.Item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                  }}
                >
                  {renderCopyButton(selectedImage?.url)}
                  {renderDeleteButton()}
                </List.Item>
              </List>
            ) : (
              <div className="image-selector-image_preview">
                <p>
                  <FormattedMessage id="imageSelector.noImageSelectedForPreview" />
                </p>
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          <FormattedMessage id="imageSelector.cancelImageSelection" />
        </Button>
        <Button
          primary
          disabled={!selectedImage}
          onClick={() => {
            getSelectedImage(selectedImage);
            handleClose();
          }}
        >
          <FormattedMessage id="imageSelector.confirmImageSelection" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ImageLibrary.propTypes = {
  multiple: PropTypes.bool,
  projectId: PropTypes.string,
  getSelectedImage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array,
  button: PropTypes.node
};

ImageLibrary.defaultProps = {
  multiple: false,
  images: [],
  button: null,
  projectId: undefined
};

export default ImageLibrary;
