import React, { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "beautiful-react-hooks";
import { InView } from "react-intersection-observer";
import PropTypes from "prop-types";
import MessageBodyV1 from "./MessageBodyV1";
import MessageBodyV2 from "./MessageBodyV2";
import { useAccountFeatureToggle } from "../../../shared/components/elements/FeatureToggleProvider";
import { MessageShape } from "../../../shared/shapes/message.shape";

const MessageBodyContent = ({ message, inView, height: refHeight }) => {
  const [height, setHeight] = useState(refHeight);
  const { isEnabled } = useAccountFeatureToggle();
  const [messageBodyVersion] = useLocalStorage("messageBodyVersion", null);

  useEffect(() => {
    if (refHeight) {
      setHeight(h => Math.max(refHeight, h));
    }
  }, [refHeight]);

  if (!inView) {
    return <div style={{ minHeight: `${height}px` }}>&nbsp;</div>;
  }

  if (messageBodyVersion === "v2" || isEnabled("email_display_20")) {
    return <MessageBodyV2 message={message} />;
  }
  return <MessageBodyV1 text={message.body_text} />;
};

MessageBodyContent.propTypes = {
  message: MessageShape.isRequired,
  inView: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired
};

const MessageBody = ({ message }) => {
  const outerRef = useRef();
  return (
    <div ref={outerRef}>
      <InView threshold={0}>
        {({ inView, ref }) => {
          return (
            <div ref={ref}>
              <MessageBodyContent
                message={message}
                inView={inView}
                height={outerRef?.current?.clientHeight || 100}
              />
            </div>
          );
        }}
      </InView>
    </div>
  );
};

MessageBody.propTypes = {
  message: MessageShape.isRequired
};

export default MessageBody;
