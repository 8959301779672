import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import {
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Dropdown,
  Loader
} from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { Link } from "react-router";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { FormattedMessage } from "react-intl";
import FormattedMessageWithFlag from "shared/components/textFormatting/FormattedMessageWithFlag";
import Observers from "shared/components/observers/Observers";
import ActivityDialog from "./ActivityDialog";
import "./activitySegment.scss";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

class ActivitySegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      accountingMode,
      projectSlug,
      i18n,
      activity,
      project,
      actions
    } = this.props;

    if (!activity) {
      return null;
    }

    const activityHeader = (
      <Grid>
        <Grid.Column width={12}>
          <strong>{activity.title}</strong>
        </Grid.Column>
        <Grid.Column width={4} textAlign="right">
          <IsVersionHistoryAccessible>
            <VersionHistoryLink id={activity.id} type="Activity" size="mid" />
          </IsVersionHistoryAccessible>
          <ActivityDialog
            context="activity"
            i18n={i18n}
            activity={activity}
            button={
              <a role="button" className="editActivity">
                <Icon name="setting" color="grey" />
              </a>
            }
            actions={actions}
          />
        </Grid.Column>
      </Grid>
    );

    const applicableCosts =
      accountingMode === "net"
        ? activity.applicable_costs
        : activity.applicable_gross_costs;

    return (
      <Segment.Group data-component="activitySegment">
        <Segment>{activityHeader}</Segment>
        <Segment>
          <Grid as={Link} to={`/projects/${projectSlug}/activity`}>
            {project && project.thumb_url && (
              <Grid.Column width="6">
                <div
                  style={{
                    backgroundImage: `url(${project.thumb_url})`,
                    backgroundSize: "cover",
                    height: "100%",
                    minHeight: "96px"
                  }}
                />
              </Grid.Column>
            )}
            <Grid.Column width={(project && project.thumb_url && 10) || 16}>
              <Header size="small">{project && project.name}</Header>
              {this.renderBuyers(activity)}
              {this.renderSections(activity)}
              {this.renderUnits(activity)}
            </Grid.Column>
          </Grid>
        </Segment>
        {activity.summary && <Segment>{activity.summary}</Segment>}
        <Segment>
          <Form.Field>
            <div className="right floated element" data-attr="applicable_price">
              <FormatCurrency amount={activity.applicable_price} />
            </div>
            <label>
              <Icon name="child" />{" "}
              <FormattedMessageWithFlag
                featureToggleName="show_price_net_label"
                id="activity.attributes.price.net.label"
                alternativeId="activity.attributes.price.gross.label"
              />
              :
            </label>
          </Form.Field>
        </Segment>
        <Segment>
          <Form.Field>
            <div className="right floated element" data-attr="applicable_costs">
              <FormatCurrency amount={applicableCosts} />
            </div>
            <label>
              <Icon name="shipping" />{" "}
              <FormattedMessage
                id={`activity.attributes.costs.${accountingMode}.label`}
                defaultText={`activity.attributes.costs.${accountingMode}.label`}
              />
              :
            </label>
          </Form.Field>
        </Segment>
        <Segment>{this.renderAssigneeSelector()}</Segment>
        <FeatureToggleActive featureToggleName="observers">
          <Segment>
            <Observers modelIdName="activity_id" model={activity} />
          </Segment>
        </FeatureToggleActive>
      </Segment.Group>
    );
  }

  renderBuyers(activity) {
    if (activity.units.length === 1 && activity.buyers.length) {
      const buyersLabel = activity.buyers
        .map(buyer => {
          return buyer.label;
        })
        .join(", ");
      return (
        <div>
          <Icon name="child" /> {buyersLabel}
        </div>
      );
    }
    return null;
  }

  renderUnits(activity) {
    if (activity.units.length === 1) {
      const buyersLabel = activity.units
        .map(unit => {
          return unit.label;
        })
        .join(", ");
      return (
        <div>
          <Icon name="cube" /> {buyersLabel}
        </div>
      );
    }
  }

  renderSections(activity) {
    const sectionLabels = activity.sections
      .map(section => {
        return section.label;
      })
      .join(", ");
    return (
      <div>
        <Icon name="cubes" /> {sectionLabels}
      </div>
    );
  }

  handleAssigneeChange = (_, data) => {
    const { activity, actions } = this.props;

    const values = {
      id: activity.id,
      assignee_id: data.value
    };

    this.setState({ reassignment: true });
    actions.activity.save(values).then(() => {
      actions.activity.get(values.id, true).then(() => {
        this.setState({ reassignment: false });
      });
    });
  };

  renderAssigneeSelector() {
    const { i18n, activity, availableAssignees } = this.props;
    const { reassignment } = this.state;

    // add assignee even if not contained in availableAssignees (e.g. incognito or inactive assignees)

    const currentAssignee = activity && activity.assignee;

    let options = [
      {
        key: currentAssignee.id,
        value: currentAssignee.id,
        icon: "user",
        text: currentAssignee.label
      }
    ];

    (availableAssignees || []).forEach(assignee => {
      if (assignee.id !== currentAssignee.id)
        options.push({
          key: assignee.id,
          value: assignee.id,
          icon: "user",
          text: assignee.label
        });
    });

    options = sortBy(options, "text");

    const value = activity && activity.assignee_id;

    return (
      <Form.Field>
        {!reassignment && (
          <Dropdown
            id="assignee-dropdown"
            className="right floated element"
            inline
            header={i18n["activity.attributes.assignee.label"]}
            options={options}
            value={value}
            onChange={this.handleAssigneeChange}
          />
        )}
        {reassignment && (
          <div className="right floated element">
            <Loader size="small" inline active />
          </div>
        )}
        <label htmlFor="assignee-dropdown">
          <Icon name="user" /> Verantwortlicher:
        </label>
      </Form.Field>
    );
  }
}

ActivitySegment.propTypes = {
  projectSlug: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  availableAssignees: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  accountingMode: PropTypes.oneOf(["net", "gross"]).isRequired
};

export default ActivitySegment;
