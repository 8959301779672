import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getProjectCurrency } from "shared/selectors/currency";
import { withRouter } from "../../helpers/withRouter";

class CurrencySign extends Component {
  render() {
    return <span>{this.props.currency.sign}</span>;
  }
}

CurrencySign.propTypes = {
  currency: PropTypes.shape({
    sign: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = (state, props) => {
  const { projectId } = props.params;
  return {
    currency: getProjectCurrency(state, projectId)
  };
};

export default withRouter(connect(mapStateToProps)(CurrencySign));
