/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ListItem from "./ListItem";

const Columns = ({ elements }) => {
  return elements.length > 0 ? (
    elements?.map((item, index) => (
      <ListItem key={item.id} item={item} index={index} />
    ))
  ) : (
    <div style={{ textAlign: "center", marginTop: 20 }}>
      <FormattedMessage
        id="roomBook.unitProspect.noProspectsFound"
        defaultMessage="No prospects found"
      />
    </div>
  );
};

Columns.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired
};

export default Columns;
