import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Icon } from "semantic-ui-react";

const EditSectionButton = () => {
  const { formatMessage } = useIntl();

  return (
    <Button
      icon
      data-tooltip={formatMessage({ id: "section.actions.edit" })}
      className="mobileBehaviour editSection"
    >
      <Icon name="setting" color="grey" size="large" />
      <span>
        <FormattedMessage
          id="section.actions.edit"
          defaultMessage="section.actions.edit"
        />
      </span>
    </Button>
  );
};

export default EditSectionButton;
