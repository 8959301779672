import React, { useCallback, useMemo, useState } from "react";
import { Container, Grid, Modal, Header } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { Input, Form, Select } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getAccount } from "shared/selectors";
import { lowerFirst } from "lodash";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import ActionButtons from "./ActionButtons";

const DocumentCardElement = ({
  button,
  onChange,
  initialValue,
  validationSchema
}) => {
  const [isOpen, setOpen] = useState(false);
  const account = useSelector(getAccount);
  const roleOptions = account.getDocumentTypes();

  // convert role options to dropdown options using useMemo hook
  const roleOptionsDropdown = useMemo(
    () =>
      roleOptions?.map(role => ({
        key: role.id,
        text: role.label,
        value: role.id
      })) || [],
    [roleOptions]
  );

  const handleOpen = resetForm => {
    resetForm();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onSubmit = useCallback(values => {
    onChange(values);
    handleClose();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitForm, resetForm }) => (
        <Modal
          open={isOpen}
          onOpen={() => handleOpen(resetForm)}
          onClose={handleClose}
          trigger={button}
          closeIcon
        >
          <Modal.Header>
            <FormattedMessage
              id={`configuratorDesign.dashboard.${lowerFirst(
                initialValue.type
              )}.type`}
            />
          </Modal.Header>
          <Modal.Content>
            <Container style={{ width: "100%" }}>
              <Form id="accountRolesBackendForm">
                <Grid style={{ width: "100%" }}>
                  <Grid.Column width={16}>
                    <Input
                      fluid
                      name="params.title"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.title"
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Input
                      fluid
                      name="params.url"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.url"
                      })}
                      disabled={values?.params?.role?.length > 0}
                      onChange={() => {
                        setFieldValue("params.role", null);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Select
                      fluid
                      name="params.role"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.role"
                      })}
                      options={roleOptionsDropdown}
                      disabled={values?.params?.url?.length > 0}
                      onChange={() => {
                        setFieldValue("params.url", null);
                      }}
                      clearable
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Header as="h4" style={{ marginBottom: 10 }}>
                      {useIntl().formatMessage({
                        id:
                          "configuratorDesign.dialog.contentPageEditor.presentation"
                      })}
                    </Header>
                    <Field
                      id="css"
                      name="css"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.css"
                      })}
                      component={SemanticFormikJsonEditor}
                    />
                  </Grid.Column>
                </Grid>
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <ActionButtons onClose={handleClose} onSubmit={submitForm} />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

DocumentCardElement.propTypes = {
  button: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  }),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired
};

DocumentCardElement.defaultProps = {
  initialValue: {
    params: {
      title: "",
      url: ""
    }
  }
};

export default DocumentCardElement;
