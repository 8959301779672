import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal, Button, Grid } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import CartDialog from "./CartDialog";

import { withLoadingIndicator } from "../helpers/withLoadingIndicator";

class CartAssignmentDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isLoading: false
    };

    this.withLoadingIndicator = withLoadingIndicator.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onSaveFn(status) {
    return values => {
      values.status = status;
      return this.onSave(values);
    };
  }

  onSave(values) {
    const { handleSave } = this.props;
    values.status = status;
    return handleSave(values).then(() => {
      this.handleToggleDialog(false);
    });
  }

  handleToggleDialog(open) {
    if (!this.unmounted) {
      this.setState({ isLoading: false, open });
    }
  }

  renderSuccessors() {
    const { i18n, cart, processDefinitions } = this.props;
    const { isLoading } = this.state;
    const successors = get(this.props, "successors", []);
    if (successors) {
      return (
        <div>
          <Grid>
            {successors.map(successor => this.renderSuccessor(successor))}
            <Grid.Row key="new">
              <Grid.Column width={12} verticalAlign="middle">
                <strong>Neuer Vorgang</strong>
                <br />
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                <CartDialog
                  i18n={i18n}
                  cart={cart}
                  processDefinitions={processDefinitions}
                  button={
                    <Button loading={isLoading} fluid color="green">
                      Anlegen
                    </Button>
                  }
                  handleSave={this.withLoadingIndicator(this.onSave.bind(this))}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }
    return null;
  }

  renderSuccessor(successor) {
    const { cart } = this.props;
    const { isLoading } = this.state;
    return (
      <Grid.Row key={successor.id}>
        <Grid.Column width={12} verticalAlign="middle">
          <strong>{successor.title}</strong>
          <br />
          {successor.display_name}
        </Grid.Column>
        <Grid.Column width={4} textAlign="right" verticalAlign="middle">
          <Button
            fluid
            basic
            color="green"
            loading={isLoading}
            onClick={this.withLoadingIndicator(() => {
              return this.onSave({
                successor_id: successor.id,
                line_item_status: cart.line_item_status
              });
            })}
          >
            Zuweisen
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { processDefinitions, button } = this.props;
    const { open } = this.state;
    return (
      <Modal
        size="small"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          {processDefinitions.length ? (
            processDefinitions[0].getName()
          ) : (
            <FormattedMessage
              id="roomBook.cart.title.one"
              defaultMessage="roomBook.cart.title.one"
            />
          )}
        </Modal.Header>
        <Modal.Content>{this.renderSuccessors()}</Modal.Content>
      </Modal>
    );
  }
}

CartAssignmentDialog.propTypes = {
  i18n: PropTypes.object,
  cart: PropTypes.object,
  button: PropTypes.object,
  processDefinitions: PropTypes.array,
  handleSave: PropTypes.func
};

export default CartAssignmentDialog;
