import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "../../shared/selectors";

export const getUnitTypes = createSelector([getPageContent], pageContent => {
  return get(pageContent, "unit_types", []);
});

export const getUnitTypesOptions = createSelector([getUnitTypes], unitTypes => {
  return unitTypes.map(t => ({
    key: t.id,
    value: t.id,
    text: t.label_singular
  }));
});
