import { find, get, includes } from "lodash";
import { createSelector } from "reselect";
import { getPageContent, getTrades } from "shared/selectors";

export const getAllContractors = createSelector([getPageContent], pageContent =>
  get(pageContent, "contractors", [])
);

export const getSelectedContractors = state =>
  get(state, "contractors.selectedContractorIds", []);

export const getContractorAssignmentRules = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "contractor_assignment_rules", [])
);

export const getProjectContractorsByTrade = createSelector(
  [getAllContractors, getContractorAssignmentRules, getTrades],
  (allContractors, assignmentRules, allTrades) => {
    return assignmentRules.reduce((accu, rule) => {
      const contractor = find(
        allContractors,
        contractor => contractor.id === rule.contractor_id
      );
      const trade = find(allTrades, trade => trade.id === rule.trade);
      const contractorWithRule = {
        ...contractor,
        assignedTrade: trade
      };
      return [...accu, contractorWithRule];
    }, []);
  }
);

export const getUnassignedProjectContractorsByTrade = createSelector(
  [getAllContractors, getContractorAssignmentRules],
  (allContractors, assignmentRules) => {
    const assignedContractorIds = assignmentRules.map(
      rule => rule.contractor_id
    );
    const unassignedContractors = allContractors.filter(
      contractor => !includes(assignedContractorIds, contractor.id)
    );

    return unassignedContractors;
  }
);

export const getUnassignedContractorsByTrade = (state, props) => {
  // const
};
