import React from "react";
import { Form, Message } from "semantic-ui-react";

class DeleteProductFromProductGroup extends React.Component {
  state = {
    product_id: "",
    product_group_id: "",
    force_deletion_of_buyer_selections: false,
    error: null,
    success: null,
    loading: false
  };

  handleSubmit = () => {
    const {
      product_id,
      product_group_id,
      force_deletion_of_buyer_selections
    } = this.state;
    this.setState({ loading: true });

    fetch("/api/v1/support_utils/delete_product_from_product_group", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        product: {
          product_id,
          product_group_id,
          force_deletion_of_buyer_selections
        }
      })
    })
      .then(response => response.json())
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  handleChange = (e, { name, value, checked }) => {
    this.setState({ [name]: checked !== undefined ? checked : value });
  };

  render() {
    const {
      product_id,
      product_group_id,
      force_deletion_of_buyer_selections,
      loading,
      error,
      success
    } = this.state;

    return (
      <div>
        <h2>Delete a Product from Product Group</h2>
        <p>
          This willl delete a product from a product group by deleting the
          related product prices.
        </p>
        <p>
          WARNING: If you force the deletion of buyer selections, this cannot be
          reverted!
        </p>
        <Form
          loading={loading}
          success={success}
          error={!!error}
          onSubmit={this.handleSubmit}
        >
          <Message
            success
            header="Success!"
            content="Please check the results!"
          />
          <Message error header="Error" content={error} />
          <Form.Input
            name="product_id"
            value={product_id}
            label="Product Id"
            required
            onChange={this.handleChange}
            width={4}
          />
          <Form.Input
            name="product_group_id"
            value={product_group_id}
            label="Product Group Id"
            required
            onChange={this.handleChange}
            width={4}
          />
          <Form.Checkbox
            name="force_deletion_of_buyer_selections"
            checked={!!force_deletion_of_buyer_selections}
            onChange={this.handleChange}
            label="Force deletion of buyer selections?"
          />
          <Form.Button width="4">Submit</Form.Button>
        </Form>
      </div>
    );
  }
}
export default DeleteProductFromProductGroup;
