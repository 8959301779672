import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Header, Icon, Modal, Table, List } from "semantic-ui-react";
import { get } from "lodash";
import { createStructuredSelector } from "reselect";
import {
  getSortedProductGroups,
  getProductsById
} from "builder_portal/selectors";

const BatchEditConfirmationDialog = props => {
  const {
    selectedItems,
    products,
    productGroups,
    priceStrategy,
    selectedProductGroupId,
    newQuantity,
    newQuantityEnabled,
    newDefaultQuantity,
    newDefaultQuantityEnabled,
    newDefaultProductId,
    newDefaultProductIdEnabled,
    newProductId,
    newProductIdEnabled
  } = props;

  const renderProductChange = newQuantityEnabled || newProductIdEnabled;
  const renderDefaultProductChange =
    newDefaultQuantityEnabled || newDefaultProductIdEnabled;

  const productGroup = productGroups.find(
    group => group.id === selectedProductGroupId
  ).name;

  const newDefaultProduct = newDefaultProductIdEnabled && {
    id: get(products[newProductId], "id"),
    name: get(products[newDefaultProductId], "name"),
    sku: get(products[newDefaultProductId], "sku"),
    quantity: newDefaultQuantity
  };
  const newProduct = newProductIdEnabled && {
    id: get(products[newProductId], "id"),
    name: get(products[newProductId], "name"),
    sku: get(products[newProductId], "sku"),
    quantity: newQuantity
  };

  // eslint-disable-next-line react/no-multi-comp
  const renderProduct = (product, quantity) => {
    return (
      <Header as="h5" className="product-cell">
        {quantity >= 0 && (
          <Header.Content className="product-quantity">
            <FormattedMessage
              id={`batchEdit.priceStrategy.${priceStrategy}`}
              values={{ quantity }}
            />
          </Header.Content>
        )}
        {product && (
          <Header.Content className="product-info">
            {product.name}
            <Header.Subheader>{product.sku}</Header.Subheader>
          </Header.Content>
        )}
      </Header>
    );
  };

  return (
    <Modal.Content>
      <Modal.Description>
        Produktgruppe: <b>{productGroup}</b>
      </Modal.Description>
      <Modal.Description className="margin bottom spacious">
        <FormattedMessage id="batchEdit.messages.itemsSelected" />
        <b>{selectedItems.length}</b>
      </Modal.Description>
      <Modal.Description>
        <FormattedMessage id="batchEdit.messages.edit" />
      </Modal.Description>
      <Modal.Description className="margin bottom spacious">
        <FormattedMessage id="batchEdit.messages.noUndo" />
      </Modal.Description>
      <Modal.Description className="table-container">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <List items={["Sektion", "Wohneinheit / Raumbuch"]} />
              </Table.HeaderCell>
              {renderProductChange && (
                <Table.HeaderCell colSpan="3">Produktauswahl</Table.HeaderCell>
              )}
              {renderDefaultProductChange && (
                <Table.HeaderCell colSpan="3">Standardauswahl</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedItems.map(item => {
              const section = get(item, "section.title");
              const unit = get(
                item,
                item.room_book.template ? "room_book.name" : "unit.label"
              );

              const isNewQuantity =
                newQuantity >= 0 && item.quantity !== newProduct.quantity;
              const isNewDefaultQuantity =
                newDefaultQuantity >= 0 &&
                item.default_quantity !== newDefaultProduct.quantity;
              const isNewProduct =
                newProduct && item.product.id !== newProduct.id;
              const isNewDefaultProduct =
                newDefaultProduct &&
                item.default_product.id !== newDefaultProduct.id;

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <List items={[section, unit]} />
                  </Table.Cell>
                  {renderProductChange && (
                    <Table.Cell collapsing>
                      {renderProduct(
                        isNewProduct ? item.product : false,
                        isNewQuantity && item.quantity
                      )}
                    </Table.Cell>
                  )}
                  {renderProductChange && (
                    <Table.Cell collapsing>
                      <Icon name="arrow right" />
                    </Table.Cell>
                  )}
                  {renderProductChange && (
                    <Table.Cell>
                      {renderProduct(
                        isNewProduct ? newProduct : item.product,
                        isNewQuantity && newQuantity
                      )}
                    </Table.Cell>
                  )}
                  {renderDefaultProductChange && (
                    <Table.Cell collapsing>
                      {renderProduct(
                        isNewDefaultProduct ? item.default_product : false,
                        isNewDefaultQuantity && item.default_quantity
                      )}
                    </Table.Cell>
                  )}
                  {renderDefaultProductChange && (
                    <Table.Cell>
                      <Icon name="arrow right" />
                    </Table.Cell>
                  )}
                  {renderDefaultProductChange && (
                    <Table.Cell>
                      {renderProduct(
                        isNewDefaultProduct
                          ? newDefaultProduct
                          : item.default_product,
                        isNewDefaultQuantity && newDefaultQuantity
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Description>
    </Modal.Content>
  );
};

BatchEditConfirmationDialog.propTypes = {
  products: PropTypes.object,
  productGroups: PropTypes.array,
  selectedProductGroupId: PropTypes.number,
  newDefaultQuantity: PropTypes.number,
  newDefaultQuantityEnabled: PropTypes.bool,
  newQuantity: PropTypes.number,
  newQuantityEnabled: PropTypes.bool,
  newDefaultProductIdEnabled: PropTypes.bool,
  newProductIdEnabled: PropTypes.bool,
  priceStrategy: PropTypes.string,
  selectedItems: PropTypes.array,
  newDefaultProductId: PropTypes.number,
  newProductId: PropTypes.number
};

const mapStateToProps = createStructuredSelector({
  products: getProductsById,
  productGroups: getSortedProductGroups
});

export default connect(mapStateToProps)(BatchEditConfirmationDialog);
