import React, { memo } from "react";
import { func, shape, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal, Image, Button, Grid } from "semantic-ui-react";

const ProductInfoModal = ({ productDetail, setProductDetail }) => {
  const closeModal = () => {
    if (setProductDetail) setProductDetail(null);
  };

  return (
    <Modal
      data-component="buyerPortalProductInfo"
      open={!!productDetail}
      onClose={closeModal}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header>{productDetail?.name}</Modal.Header>
      <Modal.Content>
        <Grid columns={15}>
          <Grid.Row>
            <Grid.Column width={5}>
              <div className="bold">
                <FormattedMessage id="product.attributes.sku.label" />
              </div>
              <div>{productDetail?.sku}</div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="bold">
                <FormattedMessage id="product.attributes.supplier.label" />
              </div>
              <div>{productDetail?.supplier}</div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="bold">
                <FormattedMessage id="product.attributes.series.label" />
              </div>
              <div>{productDetail?.series}</div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Image src={productDetail?.thumb_url} alt={productDetail?.name} />

        <div className="bold">
          {productDetail?.description ? (
            <FormattedMessage id="product.attributes.description.label" />
          ) : (
            <FormattedMessage id="product.attributes.description.none" />
          )}
        </div>
        <div>{productDetail?.description}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>
          <FormattedMessage id="meta.actions.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ProductInfoModal.propTypes = {
  productDetail: shape({
    name: string,
    description: string,
    thumb_url: string,
    series: string,
    sku: string
  }),
  setProductDetail: func.isRequired
};

ProductInfoModal.defaultProps = {
  productDetail: undefined
};

export default memo(ProductInfoModal);
