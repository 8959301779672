import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal } from "semantic-ui-react";

import { ProjectSubLineItemResource } from "builder_portal/actions/subLineItemActions";
import { withRouter } from "../../../shared/helpers/withRouter";
import BatchOperationSelectionDialog from "./BatchOperationSelectionDialog";
import BatchDeleteDialog from "./BatchDeleteDialog";
import BatchEditDialog from "./BatchEditDialog";
import BatchEditConfirmationDialog from "./BatchEditConfirmationDialog";
import BatchEditNextButton from "./BatchEditNextButton";

const INIT_STATE = {
  page: "select_operation",
  operation: "",
  loading: false,
  newDefaultQuantity: 0,
  newDefaultQuantityEnabled: false,
  newQuantity: 0,
  newQuantityEnabled: false,
  newDefaultProductId: null,
  newDefaultProductIdEnabled: false,
  newProductId: null,
  newProductIdEnabled: false
};

class SubLineItemBatchEditWizard extends Component {
  static propTypes = {
    subLineItemResource: PropTypes.object,
    onClose: PropTypes.func,
    selectedItems: PropTypes.array,
    selectedIds: PropTypes.array,
    productGroups: PropTypes.array,
    i18n: PropTypes.object,
    selectedProductGroupId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
      PropTypes.string
    ]),
    dialogOpen: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = INIT_STATE;
  }

  handleOperationSelection = data => {
    this.setState({ operation: data.value });
  };

  handleDelete = () => {
    const { subLineItemResource, selectedIds } = this.props;

    this.setState({ loading: true });
    subLineItemResource
      .delete(selectedIds)
      .then(this.closeDialog, this.closeDialog);
  };

  handleSetNewParameter = (data, id) => {
    this.setState({
      [id]: data.value,
      [`${id}Enabled`]: true
    });
  };

  handleEnableNewParameter = data => {
    this.setState({
      [data.id]: !this.state[data.id]
    });
  };

  handleEdit = () => {
    const { selectedItems, subLineItemResource } = this.props;
    const selectedIds = selectedItems.map(item => item.id);
    const {
      newDefaultQuantity,
      newDefaultQuantityEnabled,
      newQuantity,
      newQuantityEnabled,
      newDefaultProductId,
      newDefaultProductIdEnabled,
      newProductId,
      newProductIdEnabled
    } = this.state;

    const params = {};

    if (newDefaultQuantityEnabled) {
      params.default_quantity = newDefaultQuantity;
    }
    if (newQuantityEnabled) {
      params.quantity = newQuantity;
    }
    if (newDefaultProductIdEnabled) {
      params.default_product_id = newDefaultProductId;
    }
    if (newProductIdEnabled) {
      params.product_id = newProductId;
    }

    this.setState({ loading: true });
    subLineItemResource
      .update(selectedIds, params)
      .then(this.closeDialog, this.closeDialog);
  };

  closeDialog = () => {
    const { onClose } = this.props;

    this.setState(INIT_STATE);
    onClose?.();
  };

  getPriceStrategy = () => {
    const { selectedProductGroupId, productGroups } = this.props;
    return selectedProductGroupId
      ? productGroups.find(group => group.id === selectedProductGroupId)
          .price_strategy
      : "individual";
  };

  renderModalBody() {
    const { i18n, selectedProductGroupId, selectedItems } = this.props;
    const {
      page,
      operation,
      enabledEdits,
      newDefaultQuantity,
      newDefaultQuantityEnabled,
      newQuantity,
      newQuantityEnabled,
      newDefaultProductId,
      newDefaultProductIdEnabled,
      newProductId,
      newProductIdEnabled
    } = this.state;

    switch (page) {
      case "select_operation":
        return (
          <BatchOperationSelectionDialog
            i18n={i18n}
            operation={operation}
            selectedItems={selectedItems}
            selectedProductGroupId={selectedProductGroupId}
            handleOperationSelectionFn={this.handleOperationSelection}
          />
        );
      case "delete":
        return <BatchDeleteDialog i18n={i18n} selectedItems={selectedItems} />;
      case "edit":
        return (
          <BatchEditDialog
            i18n={i18n}
            selectedItems={selectedItems}
            selectedProductGroupId={selectedProductGroupId}
            priceStrategy={this.getPriceStrategy()}
            newDefaultQuantity={newDefaultQuantity}
            newDefaultQuantityEnabled={newDefaultQuantityEnabled}
            newQuantity={newQuantity}
            newQuantityEnabled={newQuantityEnabled}
            newDefaultProductId={newDefaultProductId}
            newDefaultProductIdEnabled={newDefaultProductIdEnabled}
            newProductId={newProductId}
            newProductIdEnabled={newProductIdEnabled}
            handleSetNewParameterFn={this.handleSetNewParameter}
            handleEnableNewParameterFn={this.handleEnableNewParameter}
          />
        );
      case "confirm_edit":
        return (
          <BatchEditConfirmationDialog
            i18n={i18n}
            selectedItems={selectedItems}
            selectedProductGroupId={selectedProductGroupId}
            enabledEdits={enabledEdits}
            priceStrategy={this.getPriceStrategy()}
            newDefaultQuantity={newDefaultQuantity}
            newDefaultQuantityEnabled={newDefaultQuantityEnabled}
            newQuantity={newQuantity}
            newQuantityEnabled={newQuantityEnabled}
            newDefaultProductId={newDefaultProductId}
            newDefaultProductIdEnabled={newDefaultProductIdEnabled}
            newProductId={newProductId}
            newProductIdEnabled={newProductIdEnabled}
          />
        );
    }
  }

  nextButtonBehavior = () => {
    const { operation, page } = this.state;
    switch (page) {
      case "select_operation":
        this.setState({ page: operation });
        break;
      case "delete":
        this.handleDelete();
        break;
      case "edit":
        this.setState({ page: "confirm_edit" });
        break;
      case "confirm_edit":
        this.handleEdit();
        break;
      case "success":
        this.closeDialog();
        break;
    }
  };

  renderNextButton() {
    const { i18n } = this.props;

    const {
      page,
      operation,
      newDefaultQuantity,
      newQuantity,
      newDefaultProductId,
      newProductId,
      newDefaultQuantityEnabled,
      newQuantityEnabled,
      newDefaultProductIdEnabled,
      newProductIdEnabled,
      loading
    } = this.state;

    const disabled = () => {
      switch (page) {
        case "select_operation":
          return !operation;
        case "edit":
          return !(
            (newQuantity >= 0 && newQuantityEnabled) ||
            (newDefaultQuantity >= 0 && newDefaultQuantityEnabled) ||
            (newProductId && newProductIdEnabled) ||
            (newDefaultProductId && newDefaultProductIdEnabled)
          );
        default:
          return false;
      }
    };

    return (
      <BatchEditNextButton
        i18n={i18n}
        disabled={disabled()}
        key={operation}
        page={page}
        operation={operation}
        newDefaultQuantity={newDefaultQuantity}
        newDefaultQuantityEnabled={newDefaultQuantityEnabled}
        newQuantity={newQuantity}
        newQuantityEnabled={newQuantityEnabled}
        newProductId={newProductId}
        newDefaultProductId={newDefaultProductId}
        nextButtonBehaviorFn={this.nextButtonBehavior}
        loading={loading}
      />
    );
  }

  renderCancelationButton() {
    const { i18n } = this.props;
    const { page } = this.state;
    return (
      <Button
        key="cancel"
        disabled={page.includes("success")}
        content={i18n["batchEdit.buttons.cancel"]}
        onClick={this.closeDialog}
      />
    );
  }

  modalContent() {
    const { i18n } = this.props;
    const { page } = this.state;
    return {
      header: i18n[`batchEdit.header.${page}`],
      content: this.renderModalBody(),
      actions: [this.renderCancelationButton(), this.renderNextButton()]
    };
  }

  render() {
    const { dialogOpen } = this.props;
    return (
      <Modal
        data-component="batchEditWizard"
        open={dialogOpen}
        {...this.modalContent()}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const projectId = props.params.projectId || undefined;

  return { ...props, i18n: state.i18n, projectId };
};

const mapDispatchToProps = (dispatch, props) => {
  const { projectId } = props.params;

  return {
    subLineItemResource: new ProjectSubLineItemResource(dispatch, projectId)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubLineItemBatchEditWizard)
);
