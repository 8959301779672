import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext
} from "react";
import PropTypes, { node } from "prop-types";
import {
  Container,
  Grid,
  Button,
  Modal,
  Header,
  List
} from "semantic-ui-react";
import { Formik } from "formik";
import { Form, TextArea, Select } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSalesUserOptions } from "builder_portal/selectors/projectUsers";
import Growl from "builder_portal/actions/growlActions";
import { getUnitOptions } from "shared/selectors/units";
import moment from "moment";
import { UnitProspectListLoaderContext } from "./UnitProspectLoader";
import ContactForm from "./ContactForm";
import { If } from "../../../../shared/components/elements/Conditions";

const App = ({ button, model, prospectId }) => {
  const intl = useIntl();
  const refResetForm = useRef();
  const dispatch = useDispatch();
  const growl = new Growl(dispatch);
  const [isOpen, setOpen] = useState(false);
  const unitOptions = useSelector(getUnitOptions);

  const { prospectListResource } = useContext(UnitProspectListLoaderContext);

  const accountManagerOptions = useSelector(getSalesUserOptions);

  const validationScheme = Yup.object({
    unit_prospect: Yup.object({
      prospect_attributes: Yup.object({
        contact_attributes: Yup.object({
          email: Yup.string()
            .email(
              intl.formatMessage({
                id: "projectEmail.wizard.validation.invalidEmail"
              })
            )
            .required(
              intl.formatMessage({
                id: "projectEmail.wizard.validation.emailRequired"
              })
            )
        })
      })
    })
  });

  const clearForm = () => {
    const closingCreateDialog = !model.id;
    if (closingCreateDialog && typeof refResetForm.current === "function")
      refResetForm.current();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const onSubmit = useCallback(
    (values, formik) => {
      const valuesWithProspectId = {
        ...values.unit_prospect,
        id: prospectId
      };

      prospectListResource
        .save(valuesWithProspectId)
        .then(() => {
          handleClose();
          growl.success("message.success.title", "message.success.body");
          prospectListResource.fetchAll();
          formik.setSubmitting(false);
        })
        .catch(() => {
          formik.setSubmitting(false);
        });
    },
    [model]
  );

  return (
    <Formik
      initialValues={initialFormValue}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
      data-component="UnitProspectListDialog"
      enableReinitialize
    >
      {({ isSubmitting, resetForm, handleSubmit }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape
            closeOnDimmerClick
            trigger={button}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            data-component="UnitVariableTypeDialog"
          >
            <Modal.Header>
              <FormattedMessage id="roomBook.unitProspect.dialog.editProspect" />
            </Modal.Header>
            <Modal.Content scrolling>
              <Form id="unitProspectForm">
                <Container>
                  <Grid columns={1} style={{ overflow: "hidden" }}>
                    <Grid.Column width={8}>
                      <Select
                        fluid
                        selection
                        clearable
                        name="unit_prospect.account_manager_id"
                        options={accountManagerOptions}
                        placeholder={intl.formatMessage({
                          id: "roomBook.unitProspect.filter.accountManager"
                        })}
                        label={intl.formatMessage({
                          id: "roomBook.unitProspect.filter.accountManager"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Select
                        fluid
                        selection
                        clearable
                        name="unit_prospect.unit_id"
                        options={unitOptions}
                        placeholder={intl.formatMessage({
                          id: "roomBook.unitProspect.filter.unit"
                        })}
                        label={intl.formatMessage({
                          id: "roomBook.unitProspect.filter.unit"
                        })}
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <TextArea
                        placeholder="Add text"
                        label={intl.formatMessage({
                          id: "roomBook.unitProspect.dialog.notes"
                        })}
                        name="unit_prospect.prospect_attributes.comment"
                      />
                    </Grid.Column>
                    <ContactForm scope="unit_prospect.prospect_attributes.contact_attributes" />

                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Header as="h3" style={{ marginTop: "0.2rem" }}>
                          <FormattedMessage id="roomBook.unitProspect.dialog.requests" />
                        </Header>
                        <If
                          condition={
                            model?.unit_prospect?.prospect_configurations
                              .length === 0
                          }
                        >
                          <FormattedMessage id="roomBook.unitProspect.dialog.emptyRequests" />
                        </If>
                        <List divided verticalAlign="middle">
                          {model?.unit_prospect?.prospect_configurations.map(
                            config => (
                              <List.Item key={config.id}>
                                <List.Content floated="right">
                                  <Button
                                    basic
                                    target="_blank"
                                    href={config.prospect_configuration_url}
                                  >
                                    <FormattedMessage
                                      id="meta.actions.preview"
                                      default="Preview"
                                    />
                                  </Button>
                                </List.Content>
                                <List.Content>
                                  <div
                                    className="muted"
                                    style={{
                                      fontSize: "0.85rem"
                                    }}
                                  >
                                    {moment(config.created_at).format("LLL")}
                                  </div>
                                  {config.comment}
                                </List.Content>
                              </List.Item>
                            )
                          )}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                id="cancel"
                content={intl.formatMessage({
                  id: "meta.actions.cancel"
                })}
                onClick={handleClose}
              />
              <Button
                primary
                id="submit"
                type="submit"
                content={
                  model?.unit_prospect?.prospect_attributes?.id
                    ? intl.formatMessage({ id: "meta.actions.save" })
                    : intl.formatMessage({ id: "meta.actions.add" })
                }
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

App.propTypes = {
  button: node.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string,
    unit_prospect: PropTypes.shape({
      prospect_attributes: PropTypes.shape({
        id: PropTypes.number
      }),
      prospect_configurations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          created_at: PropTypes.string,
          prospect_configuration_url: PropTypes.string
        })
      )
    })
  }).isRequired,
  prospectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

export default App;
