import { getPageContent } from "shared/selectors";
import { createSelector } from "reselect";
import { get } from "lodash";

export const getProjectRooms = createSelector([getPageContent], pageContent =>
  get(pageContent, "project_rooms", [])
);

export const getProjectRoomOptions = createSelector([getProjectRooms], rooms =>
  rooms.map(({ id, name }) => ({ key: id, value: id, text: name }))
);
