import { shape, number, string, arrayOf } from "prop-types";
import ProjectMembershipShape from "./projectMembership.shape";

export const ProjectUserShape = shape({
  id: number,
  name: string,
  project_memberships: arrayOf(ProjectMembershipShape)
});

export default ProjectUserShape;
