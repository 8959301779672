import React from "react";
import { FormattedMessage } from "react-intl";
import { routerShape, Link } from "react-router";
import { Grid, Header, Form, Segment, Button, Icon } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { I18nShape } from "shared/shapes/i18n.shape";
import { instanceOf, string } from "prop-types";
import Growl from "builder_portal/actions/growlActions";
import PasswordResource from "builder_portal/actions/passwordActions";
import { withRouter } from "../../../shared/helpers/withRouter";

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user: {
        id: props.token,
        password: "",
        password_confirmation: ""
      },
      valid_token: false
    };
  }

  componentDidMount() {
    const { resource, token, growl } = this.props;
    this.setState({ isLoading: true });
    resource
      .verifyToken(token)
      .then(response => {
        if (response.status === 200) this.setState({ valid_token: true });
        this.setState({ isLoading: false });
      })
      .catch(error => {
        const { status } = error;
        if (status === 401) {
          growl.error(
            "user.actions.resetPassword.invalidToken.title",
            "user.actions.resetPassword.invalidToken.body",
            {
              bodyValues: {
                error: error.statusText
              }
            }
          );
        }
        this.setState({ isLoading: false });
      });
  }

  onSubmit(values) {
    const { resource, growl, router } = this.props;
    this.setState({ isLoading: true });

    resource
      .save(values)
      .then(() => {
        router.push("/users/login");
        growl.success(
          "user.messages.resetSuccess.title",
          "user.messages.resetSuccess.message"
        );
      })
      .catch(() => {
        growl.error(
          "message.passwordResetFailed.title",
          "message.passwordResetFailed.body"
        );
        this.setState({ isLoading: false });
      });
  }

  renderTokenError = () => (
    <>
      <Header size="medium">
        <FormattedMessage
          id="user.actions.resetPassword.invalidToken.title"
          defaultText="user.actions.resetPassword.invalidToken.title"
        />
      </Header>
      <div style={{ margin: "10px 0" }}>
        <FormattedMessage
          id="user.actions.resetPassword.invalidToken.body"
          defaultText="user.actions.resetPassword.invalidToken.body"
        />
      </div>
      <u>
        <Link to="/users/password">
          <FormattedMessage
            id="user.actions.resetPassword.invalidToken.link"
            defaultText="user.actions.resetPassword.invalidToken.link"
          />
        </Link>
      </u>
    </>
  );

  render() {
    const { i18n } = this.props;
    const { valid_token } = this.state;
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "password",
          label: "user.attributes.password.label",
          message: "user.attributes.password.error",
          rule: "isPassword"
        },
        {
          id: "password_confirmation",
          label: "user.attributes.password_confirmation.label",
          message: "user.attributes.password_confirmation.error",
          rule: (value, data) => {
            if (value !== data.password) {
              return "errors.password_confirmation";
            }
          }
        }
      ]
    });

    const form = FormFactory.create(this.state.user, i18n, {
      onChange: user => {
        this.setState({ user });
      }
    });

    form.fields.password.props.type = "password";
    form.fields.password_confirmation.props.type = "password";

    return (
      <Grid centered stackable data-component="login">
        <Grid.Column width="8">
          <Segment textAlign="left">
            {!valid_token && this.renderTokenError()}
            {valid_token && (
              <Form
                id="passwordReset"
                data-component="password"
                onSubmit={form.handleSubmit(this.onSubmit.bind(this))}
              >
                <Header size="medium">
                  <FormattedMessage
                    id="user.actions.resetPassword.title"
                    defaultText="user.actions.resetPassword.title"
                  />
                </Header>
                <p>
                  <FormattedMessage
                    id="user.actions.resetPassword.body"
                    defaultText="user.actions.resetPassword.body"
                  />
                </p>
                <Form.Field width="16">
                  <Field component="Input" {...form.fields.password} />
                </Form.Field>
                <Form.Field width="16">
                  <Field
                    component="Input"
                    {...form.fields.password_confirmation}
                  />
                </Form.Field>
                <Button
                  type="submit"
                  color="green"
                  loading={this.state.isLoading}
                >
                  <Icon name="save" />
                  &nbsp;
                  <FormattedMessage
                    id="user.actions.resetPassword.label"
                    defaultText="user.actions.resetPassword.label"
                  />
                </Button>
              </Form>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

PasswordResetForm.propTypes = {
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired,
  resource: instanceOf(PasswordResource).isRequired,
  router: routerShape.isRequired,
  token: string.isRequired
};

export default withRouter(PasswordResetForm);
