import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form, Input, TextArea } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { node, oneOfType, number, string, shape } from "prop-types";
import { cloneDeep } from "lodash";
import { Modal, Button, Grid } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import ConfigurationGroupsResource from "../../../actions/configurationGroupsActions";

const ConfigurationGroupDialog = ({ button, model, projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const initialFormValue = useMemo(() => {
    if (model) return cloneDeep(model);
    return {
      name: "",
      title: "",
      description: "",
      summary: ""
    };
  }, [model]);
  const configurationGroupsResource = new ConfigurationGroupsResource(
    dispatch,
    projectId
  );

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    description: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      ),
    summary: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      ),
    position: Yup.number()
  });

  const onSubmit = useCallback((values, formik) => {
    configurationGroupsResource
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        new Growl(dispatch).success(
          "macro.message.success.title",
          "macro.message.success.body"
        );
        configurationGroupsResource.fetchAll();
        setOpen(false);
      })
      .catch(() => {
        formik.setSubmitting(false);
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      open={isOpen}
      trigger={button}
      onOpen={handleOpen}
      onClose={handleClose}
      data-component="ConfigurationGroupDialog"
    >
      <Modal.Header>
        <FormattedMessage id="roomBook.configurationGroups.dialog.title" />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialFormValue}
          validationSchema={validationScheme}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => {
            return (
              <Form id="unitVariablTypeForm">
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "roomBook.configurationGroups.dialog.name"
                  })}
                />
                <Input
                  id="title"
                  errorPrompt
                  name="title"
                  label={intl.formatMessage({
                    id: "roomBook.configurationGroups.dialog.title"
                  })}
                  nullable
                />
                <TextArea
                  id="description"
                  errorPrompt
                  name="description"
                  label={intl.formatMessage({
                    id: "roomBook.configurationGroups.dialog.description"
                  })}
                />
                <TextArea
                  id="summary"
                  errorPrompt
                  name="summary"
                  label={intl.formatMessage({
                    id: "roomBook.configurationGroups.dialog.summary"
                  })}
                  type="number"
                  nullable
                />
                <Modal.Actions>
                  <Grid>
                    <Grid.Column width={16} textAlign="right">
                      <Button
                        basic
                        id="cancel"
                        content={intl.formatMessage({
                          id: "meta.actions.cancel"
                        })}
                        onClick={handleClose}
                        loading={isSubmitting}
                      />
                      <Button
                        primary
                        id="submit"
                        type="submit"
                        content={
                          model?.id
                            ? intl.formatMessage({ id: "meta.actions.save" })
                            : intl.formatMessage({ id: "meta.actions.add" })
                        }
                        loading={isSubmitting}
                      />
                    </Grid.Column>
                  </Grid>
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

ConfigurationGroupDialog.propTypes = {
  button: node.isRequired,
  model: shape({
    id: oneOfType([number, string]),
    name: string,
    title: string,
    description: string,
    summary: string,
    position: number
  }),
  projectId: oneOfType([number, string]).isRequired
};

ConfigurationGroupDialog.defaultProps = {
  model: null
};

export default ConfigurationGroupDialog;
