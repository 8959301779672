import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "./base";

export const getProductCatalogs = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "product_catalogs", [])
);

export const getProductCatalogOptions = createSelector(
  [getProductCatalogs],
  productCatalogs =>
    productCatalogs.map(pc => ({
      key: pc.id,
      value: pc.id,
      text: pc.name
    }))
);

export default getProductCatalogs;
