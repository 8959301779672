import { get, isNumber, isEqual } from "lodash";

const UNASSIGNABLE_REASON = {
  alreadyAssigned: "cartAssignment.message.already_assigned",
  buyerSelectionPending: "roomBook.lineItems.buyerSelection.pending",
  inValidStatus: "cartAssignment.message.invalid_status",
  inactive: "cartAssignment.message.inactive",
  replaced: "cartAssignment.message.replacedShort"
};

export default class LineItemModel {
  constructor(currentModel, isTemplate) {
    this.currentModel = currentModel;
    this.isTemplate = isTemplate;
  }

  getSubLineItemIds() {
    return this.currentModel.sub_line_items.map(sli => sli.id);
  }

  getId() {
    return this.currentModel.id;
  }

  getTitle() {
    return this.currentModel.title;
  }

  getDisplayNumber() {
    return this.currentModel.display_number;
  }

  getTrades() {
    return get(this.currentModel, "trades", []);
  }

  isOptional() {
    return get(this.currentModel, "optional", false);
  }

  isActivated() {
    return get(this.currentModel, "activated", false);
  }

  isInactive() {
    return this.isOptional() && !this.isActivated();
  }

  isActivatable() {
    return (
      this.isOptional() &&
      !this.isActivated() &&
      !this.isCancelled() &&
      get(this.currentModel, "activatable", false)
    );
  }

  isReplaced() {
    return get(this.currentModel, "replaced", false);
  }

  isReplacement() {
    return get(this.currentModel, "replacement", false);
  }

  isCancelled() {
    return get(this.currentModel, "cancelled", false);
  }

  isCancellation() {
    return get(this.currentModel, "cancellation", false);
  }

  getCancellationBaseTotal() {
    return get(this.currentModel, "cancellation_total_base", 0);
  }

  isProperLineItem() {
    return !this.isCancellation();
  }

  isCompleted() {
    return get(this.currentModel, "status_info.status.terminal", false);
  }

  isCancellable() {
    return get(this.currentModel, "status_info.status.cancellable", false);
  }

  isInProcess() {
    return this.currentModel.status !== "open";
  }

  hasActiveCart() {
    return !!this.getActiveCartId();
  }

  getActiveCartId() {
    return get(this.currentModel, ["status_info", "current_cart", "id"]);
  }

  hasUpgradeBundle() {
    return this.getUpgradeBundles().length;
  }

  getUpgradeBundles() {
    return get(this.currentModel, ["upgrade_bundles"], []);
  }

  getUpgradeBundleIds() {
    return this.getUpgradeBundles().map(bundle => bundle.id);
  }

  hasBuyerSelection() {
    return this.getSubLineItems().some(sli =>
      get(sli, "buyer_selection", false)
    );
  }

  pendingBuyerSelections() {
    return this.currentModel.sub_line_items.filter(sli => {
      const selected = get(sli, "product_id");
      const buyerSelected = get(sli, "buyer_selection.product_id");
      if (sli.buyer_selection && !isEqual(buyerSelected, selected)) {
        return true;
      }

      const quantity = get(sli, "quantity");
      const quantityFromSelection = get(sli, "buyer_selection.quantity");
      const buyerQuantity = isNumber(quantityFromSelection)
        ? quantityFromSelection
        : quantity;

      if (quantity !== buyerQuantity) {
        return true;
      }

      return false;
    });
  }

  matchesBuyerSelection() {
    return this.pendingBuyerSelections().length === 0;
  }

  hasPendingBuyerSelection() {
    return (
      !this.isInProcess() &&
      this.hasBuyerSelection() &&
      !this.matchesBuyerSelection()
    );
  }

  requiresCartControls() {
    return !this.isTemplate; // && !this.isInactive();
  }

  requiresStatusLabel() {
    return !this.isTemplate; // && !this.isInactive();
  }

  getChangePolicy() {
    return get(this.currentModel, "status_info.status.change_policy", {
      policy: "allowed"
    });
  }

  isChangePolicy(v) {
    return this.getChangePolicy().policy === v;
  }

  getStatus() {
    return get(this.currentModel, "status");
  }

  getStatusColor() {
    return get(this.currentModel, "status_info.status.phase_color", "#eee");
  }

  getPhaseId() {
    return get(this.currentModel, "status_info.status.phase_id");
  }

  getStatusLabel() {
    return get(this.currentModel, "status_info.status.label");
  }

  hasAssignedCart() {
    return !!this.getCurrentCartId();
  }

  getCurrentCartId() {
    return get(this.currentModel, "status_info.current_cart.id");
  }

  hasCarts() {
    return get(this.currentModel, "status_info.cart_ids", []).length > 0;
  }

  getCurrentActivityId() {
    return get(this.currentModel, "status_info.current_cart.activity_id");
  }

  getReplacementForId() {
    return get(this.currentModel, ["replacement_info", "id"]);
  }

  getReplacementBaseTotal() {
    return get(this.currentModel, ["replacement_info", "default_total"], 0);
  }

  getReplacementTitle() {
    return get(this.currentModel, ["replacement_info", "title"]);
  }

  isEqual(other) {
    if (other) {
      return (
        this.currentModel.position === other.currentModel.position &&
        this.currentModel.updated_at === other.currentModel.updated_at
      );
    }
    return false;
  }

  getSubLineItems() {
    return get(this.currentModel, ["sub_line_items"], []);
  }

  isAssignableToProcess(processDefinition) {
    return (
      !this.hasPendingBuyerSelection() &&
      !this.getCurrentCartId() &&
      !this.isInactive() &&
      !this.isReplaced() &&
      processDefinition.handlesStatus(this.getStatus())
    );
  }

  getUnassignableReason(processDefinition) {
    if (this.getCurrentCartId()) return UNASSIGNABLE_REASON.alreadyAssigned;
    if (this.hasPendingBuyerSelection())
      return UNASSIGNABLE_REASON.buyerSelectionPending;
    if (this.isInactive()) return UNASSIGNABLE_REASON.inactive;
    if (this.isReplaced()) return UNASSIGNABLE_REASON.replaced;
    if (!processDefinition.handlesStatus(this.getStatus()))
      return UNASSIGNABLE_REASON.inValidStatus;
    return null;
  }
}
