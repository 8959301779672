import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button } from "semantic-ui-react";
import { shape, node, string, objectOf, func, arrayOf } from "prop-types";
import { cloneDeep } from "lodash";
import { FormattedMessage } from "react-intl";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import JsonEditor from "shared/components/forms/JsonEditor";
import Field from "shared/components/forms/FieldComponent";
import { I18nShape, StyleVariableShape } from "shared/shapes";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";
import FileUploader from "../../dropzone/FileUploader";
import {
  getDocumentStyleVariables,
  getDocumentExportStyleSettingFields
} from "../../../selectors/documentExports";
import StyleElementForm from "./StyleElementForm";
import LogoPlacementForm from "./LogoPlacementForm";
import ExportSettings from "./ExportSettings";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "name",
      label: "documentsExport.dialogs.styles.labels.name",
      placeholder: "documentsExport.dialogs.styles.placeholders.name",
      autoComplete: "off",
      autoFocus: true,
      rule: "isRequired"
    },
    {
      id: "account_logo",
      label: "documentsExport.dialogs.styles.labels.accountLogo"
    },
    {
      id: "project_logo",
      label: "documentsExport.dialogs.styles.labels.projectLogo"
    },
    {
      id: "layout_header_logo",
      label: "documentsExport.dialogs.styles.labels.layoutHeaderLogo"
    },
    {
      id: "layout_footer_logo",
      label: "documentsExport.dialogs.styles.labels.layoutFooterLogo"
    },
    {
      id: "styles_content",
      label: "JSON",
      rule: "isRequired"
    },
    {
      id: "layout_content",
      label: "JSON",
      rule: "isRequired"
    }
  ]
});

const emptyModel = {
  name: "",
  account_logo: null,
  project_logo: null,
  layout_header_logo: null,
  layout_footer_logo: null,
  styles_content: {},
  layout_content: {}
};

const StyleDialog = ({ i18n, originalStyle, button, onSubmit, variables }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [model, setModel] = useState(emptyModel);
  const [form, setForm] = useState();

  const exportSettingsMetadata = useSelector(
    getDocumentExportStyleSettingFields
  );

  useEffect(() => {
    if (!originalStyle) return;
    setModel(cloneDeep(originalStyle));
  }, [originalStyle]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(model)
      .then(() => {
        setLoading(false);
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
        setOpen(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const valid = model.name && model.styles_content && model.layout_content;

  const handleFile = (file, name) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      setModel(currentModel => ({ ...currentModel, [name]: binaryStr }));
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const f = FormFactory.create(model, i18n, {
      onChange: data => {
        const { name } = data;
        setModel(data);
        f.fields.name.props.value = name;
      }
    });

    f.fields.layout_content.props = {
      ...f.fields.layout_content.props,
      onChange: (_, { value }) => {
        setModel(currentModel => ({ ...currentModel, layout_content: value }));
      }
    };

    setForm(f);
  }, [model]);

  const addImage = (id, image, position) => {
    const newLogo = { [position]: { file: id, scale: 0.5 } };

    setModel(currentModel => {
      const currentLogo = currentModel.layout_content.layout?.logo || {};
      return {
        ...currentModel,
        [id]: image,
        layout_content: { layout: { logo: { ...currentLogo, ...newLogo } } }
      };
    });
  };

  const removeImage = id => {
    setModel(currentModel => ({
      ...currentModel,
      [id]: null,
      [`${id}_url`]: "",
      [`destroy_${id}`]: true
    }));
  };

  const handleDeleteLogo = name => {
    setModel(currentModel => ({
      ...currentModel,
      [name]: null,
      [`destroy_${name}`]: true,
      [`${name}_url`]: null,
      [`${name}_id`]: null
    }));
  };

  return (
    <Modal
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={button}
      data-component="DocumentStyleDialog"
    >
      <Modal.Header>
        <FormattedMessage
          id={`documentsExport.dialogs.styles.title.${
            originalStyle ? "edit" : "create"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        {form && (
          <Form
            id="export"
            onSubmit={form.handleSubmit(handleSubmit)}
            data-component="styleForm"
          >
            <Form.Field>
              <Field component="Input" {...form.fields.name} />
            </Form.Field>

            <label htmlFor="account_logo">
              {form.fields.account_logo.props.label}
            </label>

            <LogoPlacementForm
              model={model}
              onChange={form.fields.layout_content.props.onChange}
              logoId="account_logo"
              addImage={addImage}
              removeImage={removeImage}
            />

            <label htmlFor="project_logo">
              {form.fields.project_logo.props.label}
            </label>

            <LogoPlacementForm
              model={model}
              onChange={form.fields.layout_content.props.onChange}
              logoId="project_logo"
              addImage={addImage}
              removeImage={removeImage}
            />

            <If
              condition={!!model.layout_header_logo_presence}
              styles={{ display: "block" }}
            >
              <label htmlFor="layout_header_logo">
                {form.fields.layout_header_logo.props.label}
              </label>
              <FileUploader
                id="layout_header_logo"
                fileType="image"
                loading={isLoading}
                name="layout_header_logo"
                previewImageUrl={
                  model.layout_header_logo_url || model.layout_header_logo
                }
                handleFile={handleFile}
                handleDelete={handleDeleteLogo}
              />
            </If>

            <If
              condition={!!model.layout_footer_logo_presence}
              styles={{ display: "block" }}
            >
              <label htmlFor="layout_footer_logo">
                {form.fields.layout_footer_logo.props.label}
              </label>
              <FileUploader
                id="layout_footer_logo"
                fileType="image"
                loading={isLoading}
                name="layout_footer_logo"
                previewImageUrl={
                  model.layout_footer_logo_url || model.layout_footer_logo
                }
                handleFile={handleFile}
                handleDelete={handleDeleteLogo}
              />
            </If>

            <div style={{ marginTop: "2em" }} />
            <ExportSettings
              value={form.fields.layout_content.props.value}
              onChange={form.fields.layout_content.props.onChange}
              metadata={exportSettingsMetadata}
            />

            <StyleElementForm
              variables={variables}
              json={model?.styles_content}
              field={form.fields.styles_content}
            />

            <Form.Field>
              <Field component={JsonEditor} {...form.fields.styles_content} />
            </Form.Field>

            <Form.Field>
              <Field component={JsonEditor} {...form.fields.layout_content} />
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          id="cancel"
          content={i18n["meta.actions.cancel"]}
          onClick={handleClose}
          loading={isLoading}
        />
        <Button
          primary
          id="submit"
          content={
            originalStyle ? i18n["meta.actions.save"] : i18n["meta.actions.add"]
          }
          onClick={handleSubmit}
          disabled={!valid}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

StyleDialog.propTypes = {
  i18n: I18nShape.isRequired,
  button: node.isRequired,
  onSubmit: func.isRequired,
  originalStyle: shape({
    name: string,
    styles_content: objectOf(string),
    layout_content: objectOf(string)
  }),
  variables: arrayOf(StyleVariableShape).isRequired
};

StyleDialog.defaultProps = {
  originalStyle: null
};

const mapStateToProps = state => {
  return {
    variables: getDocumentStyleVariables(state)
  };
};

export default connect(mapStateToProps)(StyleDialog);
