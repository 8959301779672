export const ROOMBOOK_EXPAND_ALL = "ROOMBOOK_EXPAND_ALL";
export const ROOMBOOK_EXPAND_ID = "ROOMBOOK_EXPAND_ID";
export const ROOMBOOK_COLLAPSE_ID = "ROOMBOOK_COLLAPSE_ID";
export const ROOMBOOK_COLLAPSE = "ROOMBOOK_COLLAPSE";
export const ROOMBOOK_TOGGLE_ID = "ROOMBOOK_TOGGLE_ID";

export const dispatchToggleCollapse = (dispatch, id) => {
  return dispatch({
    type: ROOMBOOK_TOGGLE_ID,
    payload: { id }
  });
};

export const dispatchCollapseId = (dispatch, id) => {
  return dispatch({
    type: ROOMBOOK_COLLAPSE_ID,
    payload: { id }
  });
};

export const dispatchExpandId = (dispatch, id) => {
  return dispatch({
    type: ROOMBOOK_EXPAND_ID,
    payload: { id }
  });
};

export const getCollapsedById = (store, id) => {
  return !!store.roomBookFilter?.collapsedItems?.[id];
};

const INIT_STATE = {
  collapsedItems: {}
};

function roomBookFilter(state = INIT_STATE, action) {
  switch (action.type) {
    case ROOMBOOK_EXPAND_ALL: {
      return {
        ...state,
        collapsedItems: {}
      };
    }
    case ROOMBOOK_EXPAND_ID: {
      const { id } = action.payload;
      return {
        ...state,
        collapsedItems: {
          ...state.collapsedItems,
          [id]: false
        }
      };
    }
    case ROOMBOOK_COLLAPSE_ID: {
      const { id } = action.payload;
      return {
        ...state,
        collapsedItems: {
          ...state.collapsedItems,
          [id]: true
        }
      };
    }
    case ROOMBOOK_COLLAPSE: {
      return {
        ...state,
        collapsedItems: {
          ...state.collapsedItems,
          ...action.payload
        }
      };
    }
    case ROOMBOOK_TOGGLE_ID: {
      const { id } = action.payload;
      return {
        ...state,
        collapsedItems: {
          ...state.collapsedItems,
          [id]: !state.collapsedItems?.[id]
        }
      };
    }
    default:
      return state;
  }
}

export default roomBookFilter;
