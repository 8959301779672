/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Segment,
  Image,
  Form as FormSemantic,
  Header,
  Icon
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import { If } from "shared/components/elements/Conditions";
import * as Yup from "yup";
import Growl from "builder_portal/actions/growlActions";
import SemanticFormikFloatInput from "shared/components/forms/SemanticFormikFloatInput";
import SemanticFormikDateInput from "shared/components/forms/SemanticFormikDateInput";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Input, Select } from "formik-semantic-ui-react";
import { getUnitTypesOptions } from "builder_portal/selectors/unitTypes";
import { getSalesUserOptions } from "builder_portal/selectors/projectUsers";
import { getProject } from "shared/selectors";
import { getI18N } from "shared/selectors/base";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import ImageDropzone from "builder_portal/components/dropzone/ImageDropzone";
import SemanticFormikImageSelector from "shared/components/forms/SemanticFormikImageSelector";
import ProjectImagesLoader from "shared/components/forms/ProjectImagesLoader";

import "./styles.scss";
import ProjectAnalysisTopicDialog from "./ProjectAnalysisTopicDialog";

const BasicProjectTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const i18n = useSelector(getI18N);
  const project = useSelector(getProject);

  const accountManagerOptions = useSelector(getSalesUserOptions);

  const unitTypeOptions = useSelector(getUnitTypesOptions);

  const handleRemoveImage = e => {
    e.preventDefault();
    const projectResources = new ProjectsResource(dispatch);
    projectResources.removeThumb(project.id).then(() => {
      projectResources.get(project.id);
    });
  };

  const renderImage = useCallback(
    () => (
      <div className="imageContainer">
        <Image src={project.thumb_url} />
        <Button
          onClick={handleRemoveImage}
          color="red"
          size="tiny"
          basic
          circular
          icon="remove"
          className="removeImage"
        />
      </div>
    ),
    [project.thumb_url]
  );

  const renderDropzone = useCallback(
    () => (
      <ImageDropzone
        title={intl.formatMessage({
          id: "project.actions.uploadImage.label"
        })}
        className="projectDropZone"
        resourceName="projects"
        resourceId={project.id}
        handleSuccess={() => {
          new ProjectsResource(dispatch).get(project.id); // refresh page
        }}
        i18n={i18n}
      />
    ),
    [project]
  );

  const validationScheme = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = values => {
    const projectResources = new ProjectsResource(dispatch);
    projectResources
      .save(values)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
        projectResources.get(project.id);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  return (
    <Formik
      initialValues={project}
      onSubmit={onSubmit}
      validationSchema={validationScheme}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <ProjectImagesLoader projectId={project.id}>
              <Header size="large">
                <FormattedMessage id="project.project_setup.tabs.basic_data" />
              </Header>

              <Segment
                style={{
                  maxHeight: "600px",
                  overflowY: "auto",
                  overflowX: "hidden"
                }}
              >
                <Input
                  fluid
                  errorPrompt
                  label={intl.formatMessage({
                    id: "project.project_setup.dialog.project_name.label"
                  })}
                  name="name"
                />

                <Select
                  fluid
                  selection
                  clearable
                  errorPrompt
                  name="customer_success_manager_id"
                  options={accountManagerOptions}
                  label={intl.formatMessage({
                    id:
                      "project.project_setup.dialog.customer_success_manager.label"
                  })}
                />

                <Select
                  fluid
                  selection
                  clearable
                  errorPrompt
                  name="default_unit_type_id"
                  options={unitTypeOptions}
                  label={intl.formatMessage({
                    id: "project.attributes.defaultUnitType.label"
                  })}
                />

                <Input
                  fluid
                  errorPrompt
                  label={intl.formatMessage({
                    id: "project.attributes.unitQuota.label"
                  })}
                  name="unit_quota"
                />

                <Input
                  fluid
                  errorPrompt
                  label={intl.formatMessage({
                    id: "project.attributes.address.label"
                  })}
                  name="address"
                />

                <FormSemantic.Group>
                  <FormSemantic.Field width={3}>
                    <Input
                      fluid
                      errorPrompt
                      label={intl.formatMessage({
                        id: "project.attributes.postcode.label"
                      })}
                      name="postcode"
                    />
                  </FormSemantic.Field>
                  <FormSemantic.Field width={13}>
                    <Input
                      fluid
                      errorPrompt
                      label={intl.formatMessage({
                        id: "project.attributes.town.label"
                      })}
                      name="town"
                    />
                  </FormSemantic.Field>
                </FormSemantic.Group>

                <div className="field" style={{ display: "flex", gap: "10px" }}>
                  <div style={{ flex: 1 }}>
                    <SemanticFormikDateInput
                      id="construction_begins_at"
                      name="construction_begins_at"
                      label={intl.formatMessage({
                        id:
                          "project.attributes.marketing_portal.construction_begins_at.label"
                      })}
                      nullable
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <SemanticFormikDateInput
                      id="construction_completes_at"
                      name="construction_completes_at"
                      label={intl.formatMessage({
                        id:
                          "project.attributes.marketing_portal.construction_completes_at.label"
                      })}
                      nullable
                    />
                  </div>
                </div>

                <FormSemantic.Group>
                  <SemanticFormikFloatInput
                    id="marketing_unit_price_min"
                    errorPrompt
                    name="marketing_unit_price_min"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_price_min.label"
                    })}
                    nullable
                    inputLabel="€"
                    inputLabelPosition="right"
                    width={8}
                  />

                  <SemanticFormikFloatInput
                    id="marketing_unit_price_max"
                    errorPrompt
                    name="marketing_unit_price_max"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_price_max.label"
                    })}
                    nullable
                    inputLabel="€"
                    inputLabelPosition="right"
                    width={8}
                  />
                </FormSemantic.Group>
                <FormSemantic.Group>
                  <SemanticFormikFloatInput
                    id="marketing_unit_floor_space_min"
                    errorPrompt
                    name="marketing_unit_floor_space_min"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_floor_space_min.label"
                    })}
                    nullable
                    width={8}
                  />
                  <SemanticFormikFloatInput
                    id="marketing_unit_floor_space_max"
                    errorPrompt
                    name="marketing_unit_floor_space_max"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_floor_space_max.label"
                    })}
                    nullable
                    width={8}
                  />
                </FormSemantic.Group>

                <FormSemantic.Group>
                  <SemanticFormikFloatInput
                    id="marketing_unit_rooms_min"
                    errorPrompt
                    name="marketing_unit_rooms_min"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_rooms_min.label"
                    })}
                    nullable
                    width={8}
                  />
                  <SemanticFormikFloatInput
                    id="marketing_unit_rooms_max"
                    errorPrompt
                    name="marketing_unit_rooms_max"
                    label={intl.formatMessage({
                      id:
                        "project.attributes.marketing_portal.marketing_unit_rooms_max.label"
                    })}
                    nullable
                    width={8}
                  />
                </FormSemantic.Group>

                <SemanticFormikFloatInput
                  id="marketing_unit_count_total"
                  errorPrompt
                  name="marketing_unit_count_total"
                  label={intl.formatMessage({
                    id:
                      "project.attributes.marketing_portal.marketing_unit_count_total.label"
                  })}
                  nullable
                />

                <Field
                  name="thumb_id"
                  label="configuratorDesign.dialog.logo"
                  projectId={project.id}
                  multiple={false}
                  title={
                    <FormattedMessage id="project.attributes.thumb_id.label" />
                  }
                  component={SemanticFormikImageSelector}
                />

                {/* <If condition={!!project.thumb_url}>{renderImage()}</If>
              <If condition={!project.thumb_url}>{renderDropzone()}</If> */}
              </Segment>
              <Segment textAlign="right" attached>
                <Button primary loading={isSubmitting}>
                  <FormattedMessage
                    id="meta.actions.save"
                    default="Speichern"
                  />
                </Button>
              </Segment>
            </ProjectImagesLoader>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BasicProjectTab;
