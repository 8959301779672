import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "semantic-ui-react";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import { string } from "prop-types";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import { getProject, getAccount } from "shared/selectors";
import useTabsWithStorage from "builder_portal/hooks/useTabsWithStorage";
import UnitVariables from "./unitVariables/UnitVariables";
import UnitFeaturesProjectTab from "./unitFeatureGroups/UnitFeaturesProjectTab";

const tabs = {
  show_unit_variables: "UnitVariables",
  show_unit_feature_groups: "UnitFeatures"
};

const UnitSetup = ({ projectId, currentTab: newTab }) => {
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const account = useSelector(getAccount);
  const [currentTab, setCurrentTab] = useTabsWithStorage(
    project,
    tabs,
    "UnitSetupTab"
  );

  useEffect(() => {
    Promise.all([
      new UnitsResource(dispatch, projectId).fetchAll(),
      new UnitFeatureResource(dispatch, projectId).fetchAll()
    ]);
  }, []);

  useEffect(() => {
    if (account.isEnabled("show_page_layout_v2")) {
      if (currentTab !== newTab) {
        setCurrentTab(newTab);
      }
    }
  }, [newTab]);

  return (
    <>
      <If condition={!account.isEnabled("show_page_layout_v2")}>
        <Menu>
          <FeatureToggleActive featureToggleName="show_unit_variables">
            <Menu.Item
              name={tabs.UnitVariables}
              onClick={() => {
                setCurrentTab(tabs.show_unit_variables);
              }}
              active={currentTab === tabs.show_unit_variables}
            >
              <FormattedMessage id="unitSetup.tabs.unitVariables" />
            </Menu.Item>
          </FeatureToggleActive>

          <FeatureToggleActive featureToggleName="show_unit_feature_groups">
            <Menu.Item
              name={tabs.UnitFeatures}
              onClick={() => {
                setCurrentTab(tabs.show_unit_feature_groups);
              }}
              active={currentTab === tabs.show_unit_feature_groups}
            >
              <FormattedMessage id="unitSetup.tabs.unitFeatures" />
            </Menu.Item>
          </FeatureToggleActive>
        </Menu>
      </If>

      <FeatureToggleActive featureToggleName="show_unit_variables">
        <If condition={currentTab === tabs.show_unit_variables}>
          <UnitVariables projectId={projectId} />
        </If>
      </FeatureToggleActive>

      <FeatureToggleActive featureToggleName="show_unit_feature_groups">
        <If condition={currentTab === tabs.show_unit_feature_groups}>
          <UnitFeaturesProjectTab />
        </If>
      </FeatureToggleActive>
    </>
  );
};

UnitSetup.propTypes = {
  projectId: string.isRequired,
  currentTab: string
};

UnitSetup.defaultProps = {
  currentTab: "UnitVariables"
};

export default UnitSetup;
