import { number, string, shape } from "prop-types";

const SignatureElementShape = shape({
  id: string,
  type: string,
  style: string,
  value: string,
  new_line: number
});

export default SignatureElementShape;
