import { get, keyBy } from "lodash";
import {
  instanceOf,
  oneOfType,
  number,
  string,
  func,
  shape,
  arrayOf
} from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getAccount } from "shared/selectors";
import { ActivityShape } from "shared/shapes/activity.shape";
import { Account } from "shared/models/account";
import { Loader } from "semantic-ui-react";
import ActivityListItem from "../activity/ActivityListItem";
import Pager from "../pager/Pager";
import { ActivitiesResource } from "../../actions/activityActions";
import { setUnitProjectActivityFilter } from "../../../shared/actions/filterActions";

const Activities = props => {
  const {
    id,
    activities,
    account,
    filter,
    meta,
    trades,
    activitiesResource,
    projectId
  } = props;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    activitiesResource.fetchAll({ ...filter, page, unit_id: id });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [activities]);

  useEffect(() => {
    setLoading(true);
    activitiesResource.fetchAll({ ...filter, page, unit_id: id });
  }, [filter.progress, page]);

  function handlePagerClick(newPage) {
    setPage(newPage);
    props.setFilter({ ...filter });
  }

  function renderChildren() {
    if (loading) return <Loader active inline="centered" />;
    const children =
      activities?.map(item => {
        return (
          <ActivityListItem
            projectId={projectId}
            account={account}
            listType="deadline_due_at"
            tradesDictionary={keyBy(trades, "id")}
            item={item}
            key={`item-${item.id}`}
          />
        );
      }) || [];

    if (children.length > 0) {
      return children;
    }
    return (
      <FormattedMessage
        id="activity.message.noResults"
        defaultMessage="activity.message.noResults"
      />
    );
  }

  return (
    <div data-component="activities">
      <div data-component="activityListContainer">{renderChildren()}</div>

      {meta && (
        <Pager
          currentPage={meta.page}
          itemsPerPage={meta.limit}
          totalItems={meta.total}
          onClick={handlePagerClick}
        />
      )}
    </div>
  );
};

Activities.propTypes = {
  id: oneOfType([number, string]).isRequired,
  projectId: string,
  account: instanceOf(Account).isRequired,
  setFilter: func.isRequired,
  filter: shape({ progress: string }),
  meta: shape({ limit: number, page: number, total: number }),
  trades: arrayOf(shape({ id: oneOfType([string, number]), label: string }))
    .isRequired,
  activities: arrayOf(ActivityShape),
  activitiesResource: instanceOf(ActivitiesResource).isRequired
};

Activities.defaultProps = {
  meta: null,
  activities: [],
  projectId: undefined,
  filter: {}
};

const mapDispatchToProps = (dispatch, props) => ({
  setFilter: setUnitProjectActivityFilter(dispatch, props.projectId, props.id),
  activitiesResource: new ActivitiesResource(dispatch)
});

const mapStateToProps = (state, props) => {
  const { pageContent, filter } = state;
  const { id, projectId } = props;

  const activitiesFilter = get(
    filter,
    `projectsUnits.${`${projectId}_${id}`}.activities`,
    undefined
  );

  return {
    account: getAccount(state),
    activities: pageContent?.activities,
    filter: activitiesFilter,
    meta: pageContent?.meta,
    trades: get(pageContent, "activitiesFilter.trades", [])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
