import {
  string,
  func,
  number,
  oneOfType,
  bool,
  array,
  object
} from "prop-types";
import React, { Component } from "react";
import { Form } from "semantic-ui-react";

class JsonEditor extends Component {
  constructor(props) {
    super(props);
    const original = props.value || props.defaultValue;
    this.state = { original, value: JSON.stringify(original, null, 2) };
  }

  componentDidUpdate(prevProps) {
    const { value, defaultValue } = this.props;
    if (!this.focus) {
      const original = value || defaultValue;
      const prevOriginal = prevProps.value || prevProps.defaultValue;
      if (original !== prevOriginal) this.writeValueToState(original);
    }
  }

  writeValueToState = original => {
    this.setState({ original, value: JSON.stringify(original, null, 2) });
  };

  handleChange = (event, data) => {
    const { onChange } = this.props;
    this.setState({ value: data.value }, () => {
      if (onChange) {
        onChange(event, {
          ...this.props,
          value: this.valueAsJson()
        });
      }
    });
  };

  handleBlur = (data, event) => {
    this.focus = false;
    const { onChange, onBlur } = this.props;

    if (onChange) {
      onChange(event, { ...this.props, value: this.valueAsJson() });
    }
    if (onBlur) {
      onBlur(event, { ...this.props, value: this.valueAsJson() });
    }
  };

  valueAsJson() {
    const { value, original } = this.state;
    try {
      return JSON.parse(value);
    } catch (e) {
      this.setState({ error: true });
      return original;
    }
  }

  render() {
    const { label, name, rows, id, readOnly } = this.props;
    const { error, value } = this.state;
    return (
      <div className={error ? "error" : ""} data-component="JsonEditor">
        <Form.TextArea
          label={<label>{label}</label>}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={() => {
            this.focus = true;
          }}
          name={name}
          rows={rows || 8}
          id={id}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

JsonEditor.propTypes = {
  value: oneOfType([array, object, string]),
  defaultValue: string,
  onChange: func.isRequired,
  onBlur: func,
  label: string.isRequired,
  name: string.isRequired,
  rows: number,
  id: oneOfType([number, string]),
  readOnly: bool
};

JsonEditor.defaultProps = {
  value: "",
  defaultValue: "",
  readOnly: false,
  onBlur: () => {},
  rows: undefined,
  id: undefined
};

export default JsonEditor;
