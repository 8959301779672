import { oneOf } from "prop-types";

export default oneOf([
  "length",
  "pieces",
  "area",
  "flat",
  "hour",
  "individual"
]);
