import { bool, number, oneOfType, shape, string } from "prop-types";

export const BuyerShape = shape({
  id: oneOfType([string, number]).isRequired,
  first_name: string.isRequired,
  last_name: string.isRequired,
  email: string.isRequired,
  invited_at: string,
  login_count: number,
  portal_active: bool.isRequired,
  portal_registered: bool,
  bp_created_at: string,
  bp_reminded_at: string
});

export default BuyerShape;
