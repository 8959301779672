import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { Button, Popup, Modal, Header, Loader } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import { getDerivedRoombooks } from "shared/selectors/roomBooks";
import { If } from "shared/components/elements/Conditions";
import { string, number } from "prop-types";

const DerivedRoomBooks = ({ roomBookId, projectId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const derivedRoombooks = useSelector(getDerivedRoombooks);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    new ProjectRoomBooksResource(dispatch, projectId)
      .derivedRoomBooks(roomBookId)
      .then(() => setLoading(false));
  }, []);

  const renderButton = () => {
    return (
      <Popup
        trigger={<Button icon="sitemap" onClick={() => setOpen(true)} />}
        content={<FormattedMessage id="roomBook.derivedRoombooks.tooltip" />}
      />
    );
  };

  const renderDialog = () => {
    return (
      <Modal
        closeIcon
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={isOpen}
        trigger={renderButton()}
      >
        <Modal.Header>
          <FormattedMessage id="roomBook.derivedRoombooks.dialog.title" />
        </Modal.Header>
        <Modal.Content scrolling style={{ height: "50vh" }}>
          <Header as="h5">
            <FormattedMessage id="roomBook.derivedRoombooks.dialog.templates" />
          </Header>
          {derivedRoombooks.templates.map(item => (
            <span key={item.id} style={{ marginBottom: "10px" }}>
              <Button basic>
                <Link to={`/projects/${projectId}/room_books/${item.id}`}>
                  {item.name}
                </Link>
              </Button>
            </span>
          ))}
          <If condition={!isLoading && derivedRoombooks.templates.length === 0}>
            <FormattedMessage id="roomBook.derivedRoombooks.noRoombookTemplates" />
          </If>

          <Header as="h5">
            <FormattedMessage id="roomBook.derivedRoombooks.dialog.units" />
          </Header>
          {derivedRoombooks.units.map(item => (
            <span key={item.id} style={{ marginBottom: "4px" }}>
              <Button basic>
                <Link
                  to={`/projects/${projectId}/units/${item.unit_slug}/room-book`}
                >
                  {item.unit_display_name}
                </Link>
              </Button>
            </span>
          ))}

          <If condition={!isLoading && derivedRoombooks.units.length === 0}>
            <FormattedMessage id="roomBook.derivedRoombooks.noRoombookUnits" />
          </If>
          <If condition={isLoading}>
            <Loader active />
          </If>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={intl.formatMessage({ id: "meta.actions.close" })}
            basic
            color="grey"
            onClick={() => setOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  return renderDialog();
};

DerivedRoomBooks.propTypes = {
  projectId: string.isRequired,
  roomBookId: number.isRequired
};

export default DerivedRoomBooks;
