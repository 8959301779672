import React from "react";
import { connect } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { getAccount } from "shared/selectors";

const mapStateToProps = state => ({ account: getAccount(state) });

const HasEditAccountsSettingsRight = connect(mapStateToProps)(props => (
  <If condition={props.account.hasEditAccountsSettingsRight()}>
    {props.children}
  </If>
));

export default HasEditAccountsSettingsRight;
