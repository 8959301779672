import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Icon } from "semantic-ui-react";

class DeadlineEvent extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    label: PropTypes.string,
    complete: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { date, label, complete } = this.props;

    return (
      <div data-component="timelineItem">
        <div className="bullet-pointer">
          <Icon
            color={(complete && "grey") || "red"}
            size="large"
            circular
            name="flag checkered"
          />
          {complete && <Icon color="green" name="check" className="corner" />}
        </div>
        <Container data-component="deadline">
          <time>{date}</time>{" "}
          <FormattedMessage
            id="activity.timeline.event.deadline"
            defaultMessage="activity.timeline.event.deadline"
          />
          : {label}
        </Container>
      </div>
    );
  }
}

export default DeadlineEvent;
