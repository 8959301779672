/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Card,
  Grid,
  Loader,
  Input,
  Dropdown,
  Button,
  Icon,
  Header
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { getUnitOptions, getSectionOptions } from "shared/selectors/units";
import { getSalesUserOptions } from "builder_portal/selectors/projectUsers";
import { getAccount } from "shared/selectors";
import { get } from "lodash";
import Growl from "builder_portal/actions/growlActions";
import Columns from "./Columns";
import { UnitProspectListLoaderContext } from "../UnitProspectLoader";
import ReasonDialog from "./ReasonDialog";

const columnsOrder = ["new", "active", "won", "lost"];
const Board = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [dropResult, setResult] = useState(null);

  const intl = useIntl();
  const {
    unitProspectList,
    isLoading,
    prospectListResource,
    isProjectLevel,
    projectId,
    loadUnitProspectList
  } = useContext(UnitProspectListLoaderContext);

  const [elements, setElements] = React.useState({});
  const [initialElements, setInitialElements] = React.useState({});
  const unitOptions = useSelector(getUnitOptions);
  const sectionOptions = useSelector(getSectionOptions);
  const accountManagerOptions = useSelector(getSalesUserOptions);
  const account = useSelector(getAccount);

  const filterSearch = () => {
    return Object.keys(initialElements).reduce((acc, key) => {
      acc[key] = initialElements[key]?.filter(el => {
        const { prospect } = el;
        const { contact } = prospect;
        return (
          Object.values(prospect).some(val => {
            if (typeof val === "string") {
              return val.toLowerCase().includes(filter.search.toLowerCase());
            }
          }) ||
          Object.values(contact).some(val => {
            if (typeof val === "string") {
              return val.toLowerCase().includes(filter.search.toLowerCase());
            }
          })
        );
      });
      return acc;
    }, {});
  };

  const filterDropdown = (field, value, filtered) => {
    return Object.keys(filtered).reduce((acc, key) => {
      acc[key] = filtered[key]?.filter(el => {
        return el[field] === value;
      });
      return acc;
    }, {});
  };

  useEffect(() => {
    let filtered = initialElements;
    if (filter.search) filtered = filterSearch();
    if (filter.section)
      filtered = filterDropdown("section_id", filter.section, filtered);
    if (filter.unit)
      filtered = filterDropdown("unit_id", filter.unit, filtered);
    if (filter.accountManager)
      filtered = filterDropdown(
        "account_manager_id",
        filter.accountManager,
        filtered
      );

    setElements(filtered);
  }, [filter]);

  useEffect(() => {
    const prospectListGrouped = columnsOrder.reduce((acc, curr) => {
      acc[curr] = unitProspectList.filter(item => item.status === curr);
      return acc;
    }, {});

    setElements(prospectListGrouped);
    setInitialElements(prospectListGrouped);
  }, [unitProspectList]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId !== "new" &&
      result.source.droppableId !== result.destination.droppableId &&
      (result.destination.droppableId === "active" ||
        result.destination.droppableId === "new")
    ) {
      new Growl(dispatch).error(
        "message.error.title",
        "meta.confirmations.actionNotAllowed"
      );
      return;
    }

    const sourceColumn = elements[result.source.droppableId];
    const destinationColumn = elements[result.destination.droppableId];
    const sourceColumnInitial = initialElements[result.source.droppableId];
    const destinationColumnInitial =
      initialElements[result.destination.droppableId];

    const item = sourceColumn.find(
      itemVal => itemVal.id.toString() === result.draggableId.toString()
    );

    if (result.source.droppableId === result.destination.droppableId) {
      const newColumn = [...sourceColumn];
      const newColumnInitial = [...sourceColumnInitial];

      newColumn.splice(result.source.index, 1);
      newColumn.splice(result.destination.index, 0, item);
      setElements({
        ...elements,
        [result.source.droppableId]: newColumn
      });

      newColumnInitial.splice(result.source.index, 1);
      newColumnInitial.splice(result.destination.index, 0, item);
      setInitialElements({
        ...initialElements,
        [result.source.droppableId]: newColumnInitial
      });
    }

    if (result.source.droppableId !== result.destination.droppableId) {
      const newSourceColumn = [...sourceColumn];
      const newDestinationColumn = [...destinationColumn];
      const newSourceColumnInitial = [...sourceColumnInitial];
      const newDestinationColumnInitial = [...destinationColumnInitial];

      newSourceColumn.splice(result.source.index, 1);
      newDestinationColumn.splice(result.destination.index, 0, item);
      newSourceColumnInitial.splice(result.source.index, 1);
      newDestinationColumnInitial.splice(result.destination.index, 0, item);

      const updateElements = () =>
        setElements({
          ...elements,
          [result.source.droppableId]: newSourceColumn,
          [result.destination.droppableId]: newDestinationColumn
        });

      const updateInitialElements = () =>
        setInitialElements({
          ...initialElements,
          [result.source.droppableId]: newSourceColumnInitial,
          [result.destination.droppableId]: newDestinationColumnInitial
        });

      if (
        result.source.droppableId === "new" &&
        result.destination.droppableId === "active"
      ) {
        // to avoid card jumping backwards and forwards, state should be updated before store action
        updateElements();
        updateInitialElements();
        prospectListResource
          .save({
            id: item.id,
            status: result.destination.droppableId
          })
          .catch(() => {
            // set column back to original
            loadUnitProspectList();
          });
      } else {
        setResult({ ...result, updateElements, updateInitialElements, item });
        setOpen(true);
      }
    }
  };

  return (
    <>
      <If condition={account.isEnabled("show_page_layout_v2")}>
        <Header size="large">
          <FormattedMessage
            id="roomBook.unitProspect.prospect"
            defaultMessage="Interessenten"
          />
        </Header>
      </If>

      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <ReasonDialog isOpen={isOpen} setOpen={setOpen} result={dropResult} />
        <Loader active={isLoading} inline="centered" />
        <If condition={!isLoading} block>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Dropdown
                    fluid
                    selection
                    clearable
                    options={accountManagerOptions}
                    placeholder={intl.formatMessage({
                      id: "roomBook.unitProspect.filter.accountManager"
                    })}
                    onChange={(e, { value }) =>
                      setFilter({ ...filter, accountManager: value })
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <If condition={isProjectLevel}>
                    <Dropdown
                      fluid
                      selection
                      clearable
                      options={sectionOptions}
                      placeholder={intl.formatMessage({
                        id: "roomBook.unitProspect.filter.section"
                      })}
                      onChange={(e, { value }) =>
                        setFilter({ ...filter, section: value })
                      }
                    />
                  </If>
                </Grid.Column>
                <Grid.Column width={4}>
                  <If condition={isProjectLevel}>
                    <Dropdown
                      fluid
                      selection
                      clearable
                      options={unitOptions}
                      placeholder={intl.formatMessage({
                        id: "roomBook.unitProspect.filter.unit"
                      })}
                      onChange={(e, { value }) =>
                        setFilter({ ...filter, unit: value })
                      }
                    />
                  </If>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input
                    placeholder={intl.formatMessage({
                      id: "roomBook.unitProspect.filter.byText"
                    })}
                    onChange={e =>
                      setFilter({ ...filter, search: e.target.value })
                    }
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Button
                    icon
                    basic
                    href={`${
                      window.location.origin
                    }/api/v1/projects/${projectId}/unit_prospects/export.xlsx?_bearer=${get(
                      account,
                      ["data", "auth_token"]
                    )}`}
                    target="_blank"
                    data-tooltip={intl.formatMessage({
                      id: "roomBook.unitProspect.actions.download.tooltip"
                    })}
                  >
                    <Icon name="cloud download" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
              {columnsOrder.map((columnId, index) => {
                return (
                  <Grid.Column key={columnId} width={4}>
                    <Droppable droppableId={columnId}>
                      {(provided, snapshot) => (
                        <div
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            ...provided.droppableProps.style,
                            border: "none",
                            borderImage: "none"
                          }}
                        >
                          <Card
                            style={{
                              border: "none",
                              boxShadow: "none"
                            }}
                          >
                            <Card.Content
                              style={{
                                ...provided.droppableProps.style,
                                backgroundColor: snapshot.isDraggingOver
                                  ? "#cfedfe"
                                  : "white",
                                border: "none",
                                padding: 0,
                                paddingTop: "0.5rem"
                              }}
                            >
                              <Card.Header>
                                <FormattedMessage
                                  id={`roomBook.unitProspect.${columnId}`}
                                />{" "}
                                ({elements[columnId]?.length || 0})
                              </Card.Header>
                              <Card.Meta />
                              <div
                                style={{
                                  width: "100%",
                                  margin: 0,
                                  height: "calc(100vh - 200px)",
                                  overflowY: "auto",
                                  overflowX: "hidden"
                                }}
                              >
                                <Columns
                                  key={columnId}
                                  index={index}
                                  elements={elements[columnId] || []}
                                />
                              </div>
                            </Card.Content>
                          </Card>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Grid.Column>
                );
              })}
            </Grid>
          </DragDropContext>
        </If>
      </div>
    </>
  );
};

export default Board;
