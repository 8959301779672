import PropTypes from "prop-types";

export const DesignLinesShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
  })
);

const DesignLineGroupsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    scope: PropTypes.string,
    severity: PropTypes.string,
    design_lines: DesignLinesShape
  })
);

export default DesignLineGroupsShape;
