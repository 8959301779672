import React, { useMemo, useCallback } from "react";
import {
  bool,
  oneOfType,
  string,
  number,
  func,
  arrayOf,
  objectOf,
  node,
  shape,
  object
} from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Card, Dropdown, Grid, Icon, Label } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import {
  getUser,
  getFeatureToggles,
  getProjects,
  getDesignLineGroups,
  getAllDesignLines,
  getProjectCatalogs
} from "shared/selectors";
import {
  AccountShape,
  I18nShape,
  ProductShape,
  ProductOptionShape,
  ProductGroupShape,
  ProductGroupResourcesShape,
  TradeLabelShape,
  ProjectShape,
  DesignLineGroupShape,
  ProductPriceShape
} from "shared/shapes";

import { keyBy, compact } from "lodash";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { makeGetPriceMap } from "../../selectors";
import ProductPriceDialog from "./ProductPriceDialog";
import { getTagNames } from "../../helpers/getTagNames";
import ProductInfoItem from "../product/ProductInfoItem";
import "./productGroupItemCard.scss";

const ProductPriceDialogWrapper = ({
  account,
  button,
  resources,
  productOption,
  product,
  i18n,
  handleUpdate,
  trades,
  priceCatalogId,
  designLineGroups,
  catalogId
}) => {
  const price = useMemo(() => {
    return productOption.product_prices.find(
      p => p.price_catalog_id === priceCatalogId
    );
  }, [productOption, priceCatalogId]);

  const model = useMemo(() => {
    if (price) {
      return {
        ...productOption,
        ...price,
        id: productOption.id
      };
    }
    return null;
  }, [productOption, price]);

  const handleSubmit = useCallback(
    value => {
      const resource = resources.pricesFactory(value.product_group_id);

      const update = {
        id: value.id,
        position: value.position,
        product_tags: value.product_tags,
        design_line_ids: value.design_line_ids,
        is_default: value.is_default,
        glencoe_product: value.glencoe_product,
        glencoe_product_id: value.glencoe_product_id,
        deprecated_at: value.deprecated_at,
        deprecated_by_id: value.deprecated_by_id,
        external_id: value.external_id,
        product_prices_attributes: [
          {
            id: price.id,
            price: value.price,
            portal_offer: value.portal_offer,
            on_request: value.on_request,
            excess_price: value.excess_price,
            costs_attributes: value.costs_attributes
          }
        ]
      };

      return resource.save(update).then(handleUpdate);
    },
    [resources, handleUpdate, price]
  );

  const handleDelete = useCallback(value => {
    const resource = resources.pricesFactory(value.product_group_id);
    return resource.remove(value.id).then(handleUpdate);
  });

  if (model) {
    return (
      <ProductPriceDialog
        key={priceCatalogId}
        account={account}
        button={button}
        resources={resources}
        model={model}
        product={product}
        i18n={i18n}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        trades={trades}
        designLineGroups={designLineGroups}
        catalogId={catalogId}
        productGroupId={productOption.product_group_id}
      />
    );
  }
  return null;
};

ProductPriceDialogWrapper.propTypes = {
  i18n: I18nShape.isRequired,
  account: AccountShape.isRequired,
  resources: ProductGroupResourcesShape.isRequired,
  button: node.isRequired,
  handleUpdate: func.isRequired,
  trades: arrayOf(TradeLabelShape).isRequired,
  priceCatalogId: number.isRequired,
  product: ProductShape.isRequired,
  productOption: ProductOptionShape.isRequired,
  designLineGroups: arrayOf(DesignLineGroupShape),
  catalogId: oneOfType([number, string]).isRequired
};

ProductPriceDialogWrapper.defaultProps = {
  designLineGroups: []
};

class ProductGroupItemCard extends React.Component {
  renderPortalOfferLabel = () => {
    const { account, option, priceMap } = this.props;
    const prices = priceMap[option.id] || [];

    if (account.isEnabled("buyer_portal")) {
      return prices.map(p => (
        <Label key={p.id}>
          <Icon
            name={p.portalOffer ? "unhide" : "hide"}
            className="iconMargin"
          />
        </Label>
      ));
    }
    return null;
  };

  renderSelectedFor3DLabel = () => {
    const { account, option, i18n } = this.props;

    if (account.isEnabled("buyer_portal") && option.glencoe_product) {
      return (
        <Label
          key={`${option.id}-3d`}
          title={i18n["product_price.attributes.glencoe_product.hint"]}
        >
          <Icon name="cubes" className="iconMargin" />
        </Label>
      );
    }
    return null;
  };

  renderPriceLabels = () => {
    const { group, option, priceMap } = this.props;
    const prices = priceMap[option.id] || [];

    return compact(
      prices.map(p => {
        if (p.id === "defaultPrice") {
          return null;
        }
        if (p.onRequest) {
          return (
            <Label data-attr="price" key={p.id}>
              <FormattedMessage
                id="product_group.price_strategies.on_request"
                defaultMessage="product_group.price_strategies.on_request"
              />
            </Label>
          );
        }
        return (
          <Label data-attr="price" key={p.id}>
            <FormatCurrency amount={p.amount} />
            <FormattedMessage
              id={`product_group.price_strategies.${group.price_strategy}.per_unit`}
              defaultMessage={`product_group.price_strategies.${group.price_strategy}.per_unit`}
            />
          </Label>
        );
      })
    );
  };

  handleSelectDefaultProduct = () => {
    const { group, option, resources, handleUpdate } = this.props;

    const optionId = option.is_default ? null : option.id;

    return resources.groups
      .save({
        ...group,
        default_product_option_id: optionId
      })
      .then(handleUpdate);
  };

  renderDefaultProductLabel = () => {
    const { option } = this.props;

    if (option.is_default) {
      return (
        <Label
          as="a"
          onClick={this.handleSelectDefaultProduct}
          title="Standardprodukt"
          data-component="default-product-toggle"
        >
          <Icon name="check circle outline" className="iconMargin" />
        </Label>
      );
    }
    return (
      <Label
        as="a"
        onClick={this.handleSelectDefaultProduct}
        title="Alternativprodukt"
        data-component="default-product-toggle"
      >
        <Icon name="circle outline" className="iconMargin" />
      </Label>
    );
  };

  renderMoveButton(direction) {
    const { group, option, i18n, handleMovedProductOption } = this.props;
    const optionIndex = group.product_options.findIndex(
      p => p.id === option.id
    );
    const isMovable = {
      left: group.product_options.length > 1 && optionIndex > 0,
      right:
        group.product_options.length > 1 &&
        optionIndex < group.product_options.length - 1
    };
    const newPositionModifier = {
      left: -1, // decrement
      right: 1 // increment
    };

    return (
      isMovable[direction] && (
        <a
          data-component="move-item-card-button"
          role="button"
          data-tooltip={i18n[`meta.actions.move_${direction}`]}
          className={`${direction} floated element`}
          onClick={() =>
            handleMovedProductOption({
              productGroupId: option.product_group_id,
              id: option.id,
              position: option.position + newPositionModifier[direction]
            })
          }
        >
          <Icon name={`arrow ${direction}`} size="large" color="grey" />
        </a>
      )
    );
  }

  renderTagNames(option) {
    const {
      account: {
        data: {
          account: {
            config: { taxonomies }
          }
        }
      }
    } = this.props;
    const tags = getTagNames(option.product_tags, taxonomies);

    return tags.map(tag => {
      return (
        <Label key={tag} className="productTag">
          {tag}
        </Label>
      );
    });
  }

  renderDesignLineNames = option => {
    const { designLines } = this.props;
    const { design_line_ids } = option;
    return design_line_ids.map(dline => (
      <Label key={dline}>{designLines[dline]?.name}</Label>
    ));
  };

  renderUsageCountLabel() {
    const {
      account,
      projects,
      option,
      product,
      productGroupId,
      searchEnabled,
      group,
      priceCatalogId,
      projectCatalogs
    } = this.props;
    const { project_catalog_id } = group;
    const projectCatalog = projectCatalogs[project_catalog_id];
    const assignedProjectIds = projectCatalog?.price_catalogs?.find(
      priceCatalog => priceCatalog.id === priceCatalogId
    )?.project_ids;
    const assignedProjects = assignedProjectIds
      ? projects.filter(project => assignedProjectIds.indexOf(project.id) > -1)
      : projects;

    if (searchEnabled || account.isSystemAdmin()) {
      const projectsOptions = assignedProjects.map(project => {
        return {
          key: project.slug,
          text: project.name,
          as: Link,
          to: {
            pathname: `/projects/${project.slug}/search`,
            query: {
              input: `(product_group.id:${productGroupId})AND((product.id:${product.id})OR(default_product.id:${product.id})OR(buyer_selection_product.id:${product.id}))`
            }
          }
        };
      });

      return (
        <Dropdown
          text={`${option.usage_count}`}
          icon="tags"
          floating
          compact
          button
          className="icon marginLeft"
          options={projectsOptions}
        />
      );
    }
    return <Label icon="tags" content={option.usage_count} />;
  }

  renderCopyProductId() {
    const { account, product } = this.props;

    return account.isSystemAdmin() ? (
      <Label
        as="a"
        onClick={() => {
          navigator.clipboard.writeText(product.id);
        }}
        title="Produkt-Id"
        data-component="copy-product-id"
      >
        <Icon name="clipboard outline" />
        {product.id}
      </Label>
    ) : (
      ""
    );
  }

  render() {
    const {
      account,
      option,
      trades,
      product,
      i18n,
      resources,
      handleUpdate,
      catalogId,
      priceCatalogId,
      designLineGroups,
      price
    } = this.props;

    const bgColor = price?.deprecated_at ? { backgroundColor: "#F5F5F5" } : {};

    const buttonIcon = (
      <a
        role="button"
        data-action="edit"
        data-tooltip={i18n["meta.actions.edit"]}
      >
        <Icon name="setting" size="large" color="grey" />
      </a>
    );
    const supplierId = product.supplier_id || "none";
    return (
      <Card
        key={option.id}
        data-id={option.id} // needed because i am a <ReactSortable> child
        data-model="product_price"
        data-component="productGroupItemCard"
        data-default-product={option.is_default}
        style={bgColor}
      >
        <Card.Content>
          <Link
            to={`/products/${supplierId}/${product.id}`}
            onlyActiveOnIndex={false}
          >
            <ProductInfoItem product={product} renderSku={false} />
            <div
              style={{ backgroundImage: `url(${product.thumb_url})` }}
              className="image"
            />
          </Link>
          <div className="muted margin top small">
            <small>
              <If condition={!!product.sku}>
                <FormattedMessage
                  id="product.attributes.sku.label"
                  defaultMessage="product.attributes.sku.label"
                />
                : <span className="sku">{product.sku}</span>
              </If>
              <If condition={!product.sku}>
                <FormattedMessage
                  id="product.attributes.sku.none"
                  defaultMessage="product.attributes.sku.none"
                />
              </If>
            </small>
          </div>
        </Card.Content>
        <Card.Content>
          {this.renderDefaultProductLabel()}
          {this.renderUsageCountLabel()}
          {price.deprecated_at && (
            <Label
              icon="ban"
              content={<FormattedMessage id="meta.states.inactive" />}
            />
          )}
          {this.renderPortalOfferLabel()}
          {this.renderSelectedFor3DLabel()}
          {this.renderPriceLabels()}
          {this.renderTagNames(option)}
          {this.renderDesignLineNames(option)}
          {this.renderCopyProductId()}
        </Card.Content>
        <Card.Content>
          <Grid>
            <Grid.Column width={3}>{this.renderMoveButton("left")}</Grid.Column>
            <Grid.Column width={10} textAlign="center">
              <IsVersionHistoryAccessible>
                <VersionHistoryLink id={price.id} type="ProductPrice" />
              </IsVersionHistoryAccessible>
              <ProductPriceDialogWrapper
                account={account}
                button={buttonIcon}
                resources={resources}
                productOption={option}
                product={product}
                catalogId={catalogId}
                priceCatalogId={priceCatalogId}
                i18n={i18n}
                handleUpdate={handleUpdate}
                trades={trades}
                designLineGroups={designLineGroups}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              {this.renderMoveButton("right")}
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

ProductGroupItemCard.propTypes = {
  option: ProductOptionShape.isRequired,
  account: AccountShape.isRequired,
  product: ProductShape.isRequired,
  group: ProductGroupShape.isRequired,
  resources: shape({
    groups: shape({
      save: func.isRequired
    }).isRequired
  }).isRequired,
  handleUpdate: func.isRequired,
  priceMap: objectOf(arrayOf(objectOf(oneOfType([number, string, bool]))))
    .isRequired,
  productGroupId: string.isRequired,
  priceCatalogId: number.isRequired,
  trades: arrayOf(objectOf(string)).isRequired,
  i18n: I18nShape.isRequired,
  handleMovedProductOption: func.isRequired,
  searchEnabled: bool,
  projects: arrayOf(ProjectShape),
  designLineGroups: arrayOf(DesignLineGroupShape),
  // eslint-disable-next-line react/forbid-prop-types
  designLines: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectCatalogs: object.isRequired,
  catalogId: oneOfType([number, string]).isRequired,
  price: ProductPriceShape
};

ProductGroupItemCard.defaultProps = {
  searchEnabled: false,
  projects: [],
  designLineGroups: [],
  price: {}
};

const makeMapStateToProps = () => {
  const getPriceMap = makeGetPriceMap();
  return (state, props) => {
    const projects = getProjects(state);
    const featureToggles = getFeatureToggles(state);
    const searchEnabled = featureToggles.search_enabled;
    const designLineGroups = getDesignLineGroups(state);
    const designLines = keyBy(getAllDesignLines(state), "id");
    const projectCatalogs = keyBy(getProjectCatalogs(state), "id");
    const userId = getUser(state)?.id;
    return {
      projects,
      searchEnabled,
      priceMap: getPriceMap(state, props),
      designLineGroups,
      designLines,
      projectCatalogs,
      userId
    };
  };
};

export default connect(makeMapStateToProps)(ProductGroupItemCard);
