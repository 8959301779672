import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Segment, Header, List } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

import { get } from "lodash";
import { ProductResource } from "../../actions/productActions";

class ProductReferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const { productId, loadReferences } = this.props;
    loadReferences(productId).then(() => {
      this.setState({ loading: false });
    });
  }

  renderReferenceList() {
    const { references } = this.props;

    if (references.length) {
      const items = references.map(reference => {
        const url = `/products/${reference.project_catalog.slug}/product_groups/${reference.group.trades[0]}/${reference.group.id}`;
        const link = (
          <Link to={url} role="link">
            {reference.project_catalog.name} / {reference.group.name}
          </Link>
        );
        return (
          <List.Item key={reference.group.id} icon="linkify" content={link} />
        );
      });

      return <List>{items}</List>;
    }
    return (
      <p>
        <FormattedMessage
          id="product.attributes.references.missing"
          defaultMessage="product.attributes.references.missing"
        />
      </p>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <Segment attached loading={loading}>
        <Header as="h4">
          <FormattedMessage
            id="product.attributes.references.label"
            defaultMessage="product.attributes.references.label"
          />
        </Header>
        {this.renderReferenceList()}
      </Segment>
    );
  }
}

ProductReferences.propTypes = {
  productId: PropTypes.number.isRequired,
  loadReferences: PropTypes.func.isRequired,
  references: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    references: get(state, ["pageContent", "product_references", "groups"], [])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadReferences: id => {
      const resource = new ProductResource(dispatch);
      return resource.references(id);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductReferences);
