import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { bool, func, node, string } from "prop-types";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { I18nShape } from "shared/shapes/i18n.shape";

function ConfirmableButton(props) {
  const {
    i18n,
    id,
    content,
    icon,
    size,
    color,
    basic,
    loading,
    className,
    onConfirm,
    confirmationTitle,
    confirmationMessage,
    confirmationContent,
    confirmationButtonId,
    confirmationButtonColor,
    confirmationButtonContent,
    errorMessage,
    onClose,
    "data-component": dataComponent
  } = props;

  const button = (
    <Button
      data-component={dataComponent}
      as="div"
      basic={basic}
      className={className}
      color={color}
      content={i18n[content] || content}
      icon={icon}
      id={id}
      loading={loading}
      size={size}
    />
  );

  const buttons = [
    {
      id: confirmationButtonId || "confirm",
      label: i18n[confirmationButtonContent] || confirmationButtonContent,
      color: confirmationButtonColor,
      onClick: onConfirm,
      loading
    },
    {
      id: "cancel",
      label: "meta.actions.cancel",
      basic: true,
      loading
    }
  ];

  return (
    <ConfirmationDialog
      button={button}
      buttons={buttons}
      content={confirmationContent}
      message={i18n[confirmationMessage] || confirmationMessage}
      errorMessage={errorMessage}
      title={i18n[confirmationTitle] || confirmationTitle}
      onAsyncClose={onClose}
    />
  );
}
ConfirmableButton.defaultProps = {
  confirmationButtonId: "confirm",
  basic: undefined,
  id: undefined,
  confirmationMessage: undefined,
  confirmationButtonColor: undefined,
  loading: undefined,
  className: undefined,
  color: undefined,
  onClose: () => {},
  errorMessage: undefined,
  confirmationContent: undefined,
  size: undefined,
  "data-component": "confirmableButton"
};

ConfirmableButton.propTypes = {
  id: string,
  content: string.isRequired,
  confirmationTitle: string.isRequired,
  confirmationMessage: string,
  confirmationContent: node,
  confirmationButtonId: string,
  confirmationButtonColor: string,
  confirmationButtonContent: string.isRequired,
  onConfirm: func.isRequired,
  loading: bool,
  className: string,
  icon: string.isRequired,
  size: string,
  color: string,
  errorMessage: string,
  basic: bool,
  onClose: func,
  "data-component": string,
  i18n: I18nShape.isRequired
};

const mapStateToProps = state => ({
  i18n: state.i18n
});

export default connect(mapStateToProps)(ConfirmableButton);
