import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { Form, Input, TextArea } from "formik-semantic-ui-react";
import { ProductCatalogsResource } from "builder_portal/actions/productCatalogActions";
import { ProductMetaDataResource } from "builder_portal/actions/productActions";
import Growl from "builder_portal/actions/growlActions";
import * as Yup from "yup";

const EMPTY_MODEL = {
  name: "",
  description: ""
};

const ProductCatalogDialog = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const refResetForm = useRef();

  const handleOpen = () => setOpen(true);
  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };
  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const onSubmit = values => {
    new ProductCatalogsResource(dispatch).save(values).then(() => {
      new Growl(dispatch).success(
        "message.success.title",
        "meta.states.saving"
      );
      handleClose();
      new ProductCatalogsResource(dispatch).fetchAll();
      new ProductMetaDataResource(dispatch).fetchAll();
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  return (
    <Formik
      initialValues={EMPTY_MODEL}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ isSubmitting, resetForm, handleSubmit }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            trigger={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="button"
                id="product_catalog-new"
                data-tooltip={intl.formatMessage({
                  id: "product.actions.addCatalog.label"
                })}
                className="right floated element"
              >
                <Icon name="plus" size="large" color="grey" />
              </a>
            }
          >
            <Modal.Header>
              <Header as="h3">
                <FormattedMessage
                  id="product.catalogDialog.title"
                  default="Create new product catalog"
                />
              </Header>
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Input
                  fluid
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "product.catalogDialog.attributes.name.label"
                  })}
                />
                <TextArea
                  fluid
                  errorPrompt
                  name="description"
                  label={intl.formatMessage({
                    id: "product.catalogDialog.attributes.description.label"
                  })}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>

              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ProductCatalogDialog;
