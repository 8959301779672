/* eslint-disable  jsx-a11y/anchor-is-valid */
import htmlToText from "html-to-text";
import { Liquid } from "liquidjs";
import { unescape } from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { If } from "shared/components/elements/Conditions";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Input,
  Tab,
  TextArea
} from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import { getAccount } from "shared/selectors";
import flattenObject from "../../helpers/flattenObject";
import LiquidPreview from "./liquidPreview";
import "./mailEditor.scss";
import WysiwygEditor from "./wysiwyg/WysiwygEditor";
import ImageLibrary from "../forms/ImageLibrary";

class MailEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.liquidEngine = new Liquid();
    const { role, body, subject, projects, sender } = props.defaultValue;
    const roleSource = role || MailEditor.defaultProps.defaultValue.role;
    const flatLiquidContext = this.createFlatLiquidContext(
      props.liquidContext[roleSource]
    );

    this.state = {
      flatLiquidContext,
      roleUuid: uuid(),
      roleSource,
      bodySource: body || "",
      bodyUuid: uuid(),
      bodyPreview: "",
      bodyTextPreview: "",
      subjectUuid: uuid(),
      subjectSource: subject || "",
      sender: sender || "",
      subjectPreview: "",
      selectedProjects: projects?.map(p => p.id) || [],
      htmlView: false
    };
  }

  componentDidMount() {
    this.renderBodyPreview();
    this.renderSubjectPreview();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      bodySource,
      subjectSource,
      roleSource,
      selectedProjects,
      sender
    } = this.state;
    const { onChange } = this.props;

    if (prevState.bodySource !== bodySource) {
      this.renderBodyPreview();
      onChange({ body: bodySource });
    }
    if (prevState.subjectSource !== subjectSource) {
      this.renderSubjectPreview();
      onChange({ subject: subjectSource });
    }
    if (prevState.sender !== sender) {
      onChange({ sender });
    }
    if (prevState.roleSource !== roleSource) {
      this.updateFlatLiquidContext();
      onChange({ role: roleSource });
    }
    if (prevState.selectedProjects !== selectedProjects) {
      onChange({ project_ids: selectedProjects });
    }
  }

  createBodyControl = () => {
    const { flatLiquidContext, bodyUuid, bodySource } = this.state;

    const bodyLabel = null;
    const flatLiquidContextHash = flatLiquidContext.reduce(
      (acc, obj) => `${acc}${obj.value}${obj.variable}`,
      ""
    );
    const bodyControl = (
      <WysiwygEditor
        value={bodySource}
        key={flatLiquidContextHash}
        id={bodyUuid}
        flatLiquidContext={flatLiquidContext}
        onChange={({ value }) => this.setState({ bodySource: unescape(value) })}
        name="htmlBody"
        imageButton={(handleImage, images) => (
          <ImageLibrary
            multiple={false}
            getSelectedImage={image => {
              handleImage(image.url);
            }}
            images={images}
            button={
              <a id="image-selector-button">
                <FormattedMessage id="imageSelector.selectImage" />
              </a>
            }
          />
        )}
      />
    );

    return { bodyUuid, bodyLabel, bodyControl };
  };

  createFlatLiquidContext = scopedLiquidContext => {
    const { i18n } = this.props;

    let setAttachmentsEntryOnce = false;

    return Object.keys(flattenObject(scopedLiquidContext)).reduce(
      (acc, key) => {
        /*
         * Handle additional Elements from scopedLiquidContext
         * heads up: this object is not synced with backend values anymore!
         */
        let additionalVariable;
        let additionalPreview;
        let replaceElement = false;
        if (key.indexOf("password_url") === 0) {
          // add an additional Hyperlink element for password
          additionalVariable = `<a href="{{password_url}}">Passwort setzen</a>`;
          additionalPreview = "Passwort setzen (link)";
          replaceElement = true;
        } else if (key.indexOf("invitation_url") === 0) {
          // add an additional Hyperlink element for invitation
          additionalVariable = `<a href="{{invitation_url}}">Passwort setzen</a>`;
          additionalPreview = "Passwort setzen (link)";
          replaceElement = true;
        } else if (key.indexOf("login_url") === 0) {
          // add an additional Hyperlink  element for login
          additionalVariable = `<a href="{{login_url}}">Mein Online-Konfigurator</a>`;
          additionalPreview = "Mein Online-Konfigurator (link)";
          replaceElement = true;
        } else if (key.indexOf("attachments.") === 0) {
          // replace all attachment occurrences with this LiquidLoop

          if (setAttachmentsEntryOnce) {
            // ignore any further attachment entries
            return acc;
          }
          setAttachmentsEntryOnce = true;

          additionalVariable = `
                    {% if attachments.size != 0 %}
                      ${i18n["attachment.title.other"] || "Anhänge"}<br/>
                      {% for attachment in attachments %}
                        - <a href="{{attachment.url}}">{{attachment.name}}</a><br/>
                      {% endfor %}
                    {% endif %}
                `;
          additionalPreview = `-- ${i18n["attachment.title.other"] ||
            "Dokumente"} --`;
          replaceElement = true;
        }

        if (additionalVariable) {
          additionalVariable = additionalVariable.replace(/\s+/g, " "); // replace CSS-selector invalid chars
          acc.push({
            variable: additionalVariable,
            value: additionalPreview || additionalVariable
          });
        }

        if (!replaceElement) {
          const variable = `{{${key}}}`.replace(/\s+/g, " "); // replace CSS-selector invalid chars;
          const preview =
            this.liquidEngine.parseAndRenderSync(
              variable,
              scopedLiquidContext
            ) || variable;
          acc.push({
            variable,
            value: preview || variable
          });
        }
        return acc;
      },
      []
    );
  };

  createProjectsControl = () => {
    const { projectOptions } = this.props;
    const { selectedProjects } = this.state;

    const projectsLabel = (
      // ffs htmlFor is given, stop yelling
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor="projects">
        <FormattedMessage
          id="activerecord.attributes.mail_template.projects"
          defaultMessage="Projekte"
        />
      </label>
    );
    const projectsControl = (
      <Dropdown
        id="projects"
        selection
        multiple
        name="projects"
        options={projectOptions}
        value={selectedProjects}
        onChange={(e, { value }) => this.setState({ selectedProjects: value })}
      />
    );

    return { projectsLabel, projectsControl };
  };

  createRoleControl = () => {
    const { emailRoleOptions } = this.props;
    const { roleSource, roleUuid } = this.state;

    const roleLabel = (
      // ffs htmlFor is given, stop yelling
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor={roleUuid}>
        <FormattedMessage
          id="activerecord.attributes.mail_template.role"
          defaultMessage="Rolle"
        />
      </label>
    );
    const roleControl = (
      <Dropdown
        id={roleUuid}
        selection
        name="role"
        options={emailRoleOptions}
        value={roleSource}
        onChange={(e, { value }) => this.setState({ roleSource: value })}
      />
    );

    return { roleUuid, roleLabel, roleControl };
  };

  createSubjectControl = () => {
    const { subjectUuid, subjectSource } = this.state;
    const subjectLabel = (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor={subjectUuid}>
        <FormattedMessage
          id="activerecord.attributes.mail_template.subject"
          defaultMessage="Betreff"
        />
      </label>
    );
    const subjectControl = (
      <Input
        id={subjectUuid}
        value={subjectSource}
        onChange={e => this.setState({ subjectSource: e.target.value })}
        name="subject"
      />
    );

    return { subjectUuid, subjectLabel, subjectControl };
  };

  createSenderControl = () => {
    const { sender } = this.state;
    const senderLabel = (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor="sendeLabel">
        <FormattedMessage
          id="activerecord.attributes.mail_template.sender_label"
          defaultMessage="Sender label"
        />
      </label>
    );
    const senderControl = (
      <Input
        id="sendeLabel"
        value={sender}
        onChange={e => this.setState({ sender: e.target.value })}
        name="sender"
      />
    );

    return { senderLabel, senderControl };
  };

  handleHtmlChange = (_, { value }) => {
    return this.setState({ bodySource: unescape(value) });
  };

  editorTabPanes = () => {
    const { i18n } = this.props;
    const {
      subjectPreview,
      bodyPreview,
      bodyTextPreview,
      bodySource,
      htmlView
    } = this.state;

    const { roleLabel, roleControl } = this.createRoleControl();
    const { projectsLabel, projectsControl } = this.createProjectsControl();
    const { subjectLabel, subjectControl } = this.createSubjectControl();
    const { bodyLabel, bodyControl } = this.createBodyControl();
    const { senderLabel, senderControl } = this.createSenderControl();

    return [
      {
        menuItem: {
          key: "editor",
          icon: "pencil",
          content: i18n["meta.editor"] || "Editor"
        },
        render: () => (
          <Tab.Pane key="editor-pane">
            <Form>
              <Form.Group>
                <Form.Field width={8} className="subject">
                  {subjectLabel}
                  {subjectControl}
                </Form.Field>
                <Form.Field className="role" width={4}>
                  {roleLabel}
                  {roleControl}
                </Form.Field>
                <Form.Field className="projects" width={4}>
                  {projectsLabel}
                  {projectsControl}
                </Form.Field>
              </Form.Group>

              <Grid>
                <Grid.Column width={8}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label style={{ fontWeight: 700 }}>
                    <FormattedMessage
                      id="macro.attributes.body_template.label"
                      defaultMessage="Nachricht"
                    />
                  </label>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  <Checkbox
                    toggle
                    label="Toggle HTML view"
                    checked={htmlView}
                    style={{ marginBottom: "5px" }}
                    onChange={() => this.setState({ htmlView: !htmlView })}
                  />
                </Grid.Column>
              </Grid>

              <If condition={!htmlView}>
                <Form.Field className="body">
                  {bodyLabel}
                  {bodyControl}
                </Form.Field>
              </If>
              <If condition={htmlView}>
                <TextArea
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    minHeight: "300px"
                  }}
                  value={bodySource}
                  onChange={this.handleHtmlChange}
                />
              </If>

              <Form.Field width={16}>
                {senderLabel}
                {senderControl}
              </Form.Field>
            </Form>
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          key: "html",
          icon: "html5",
          content: i18n["meta.htmlPreview"] || "HTML Vorschau"
        },
        render: () => (
          <Tab.Pane key="html-pane" className="ui form">
            <Form.Field className="subject preview text">
              {subjectLabel}
              <Divider />
              {subjectPreview}
            </Form.Field>
            <Divider horizontal />
            <Form.Field className="body preview html">
              {bodyLabel}
              <Divider />
              {bodyPreview}
            </Form.Field>
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          key: "text",
          icon: "paragraph",
          content: i18n["meta.plaintextPreview"] || "Text Vorschau"
        },
        render: () => (
          <Tab.Pane key="text-pane" className="preview ui form">
            <Form.Field className="subject preview text">
              {subjectLabel}
              <Divider />
              {subjectPreview}
            </Form.Field>
            <Form.Field className="body preview text">
              {bodyLabel}
              <Divider />
              {bodyTextPreview}
            </Form.Field>
          </Tab.Pane>
        )
      }
    ];
  };

  renderBodyPreview = () => {
    const { liquidContext } = this.props;
    const { roleSource, bodySource } = this.state;

    const bodyPreview = (
      <LiquidPreview
        liquidContext={liquidContext[roleSource]}
        html={bodySource}
      />
    );

    const parsedTextBody = htmlToText.fromString(bodySource, {
      singleNewLineParagraphs: true
    });

    const bodyTextPreview = (
      <LiquidPreview
        liquidContext={liquidContext[roleSource]}
        html={parsedTextBody}
        colorize
      />
    );

    this.setState({ bodyPreview, bodyTextPreview });
  };

  renderSubjectPreview = () => {
    const { liquidContext } = this.props;
    const { subjectSource, roleSource } = this.state;
    const subjectPreview = (
      <LiquidPreview
        liquidContext={liquidContext[roleSource]}
        html={subjectSource}
        colorize
      />
    );
    this.setState({ subjectPreview });
  };

  updateFlatLiquidContext = () => {
    const { liquidContext } = this.props;
    const { roleSource } = this.state;

    const flatLiquidContext = this.createFlatLiquidContext(
      liquidContext[roleSource]
    );
    this.setState({ flatLiquidContext });
  };

  render() {
    return (
      <div data-component="mailEditor">
        <Tab panes={this.editorTabPanes()} />
      </div>
    );
  }
}

MailEditor.defaultProps = {
  onChange: () => {},
  i18n: {},
  liquidContext: {},
  emailRoleOptions: [],
  defaultValue: {
    role: "default_mail",
    subject: "",
    body: "",
    projects: []
  }
};

MailEditor.propTypes = {
  // i18n provides no shape
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object,
  liquidContext: PropTypes.objectOf(PropTypes.object),
  emailRoleOptions: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  projectOptions: PropTypes.array
};

MailEditor.defaultProps = {
  projectOptions: []
};

const mapStateToProps = state => {
  const liquidContext = state.pageContent.mailTemplateExampleParams;
  const { i18n } = state;
  const emailRoleOptions = Object.keys(liquidContext).map(role => ({
    text: role, // TODO: i18n
    value: role
  }));
  const account = getAccount(state);
  const projectOptions = account.getProjects().map(project => ({
    key: project.id,
    value: project.id,
    text: project.name
  }));
  return {
    i18n,
    liquidContext,
    emailRoleOptions,
    projectOptions
  };
};

export default connect(mapStateToProps)(MailEditor);
