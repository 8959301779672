import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import { get } from "lodash";
import MessagesResource from "../../actions/messagesActions";

class MessageProjectPicker extends PureComponent {
  handleChange = (e, { value }) => {
    const { messageThread } = this.props;
    this.setProject(messageThread, value);
  };

  render() {
    const { placeholder, projectOptions } = this.props;

    return (
      <Dropdown
        fluid
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        options={projectOptions}
        placeholder={placeholder}
        onChange={this.handleChange}
      />
    );
  }

  async setProject(messageThread, projectId) {
    const { messagesResource } = this.props;

    await messagesResource.save({ ...messageThread, project_id: projectId });
  }
}

MessageProjectPicker.propTypes = {
  messagesResource: PropTypes.instanceOf(MessagesResource).isRequired
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    projectOptions: get(state.pageContent, "master_data.projects", []).map(
      project => {
        return { key: project.id, text: project.name, value: project.id };
      }
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    messagesResource: new MessagesResource(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageProjectPicker);
