/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageFiltersResource } from "builder_portal/actions/messageFiltersActions";
import {
  Button,
  Grid,
  Header,
  Loader,
  Segment,
  Icon,
  List
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { getMessageFilters } from "shared/selectors/messageFilters";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import CreateFilterDialog from "./CreateFilterDialog";

const Filters = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [initialLoadingIndicator, setInitialLoadingIndicator] = useState(false);

  const filters = useSelector(getMessageFilters);

  useEffect(() => {
    setInitialLoadingIndicator(true);
    new MessageFiltersResource(dispatch)
      .fetchAll()
      .then(() => {
        setInitialLoadingIndicator(false);
      })
      .catch(() => {
        setInitialLoadingIndicator(false);
      });
  }, []);

  return (
    <div>
      <Grid stackable verticalAlign="middle" className="margin bottom spacious">
        <Grid.Column width="4">
          <Header size="large">
            <FormattedMessage id="message_filters.title" />
          </Header>
        </Grid.Column>
        <Grid.Column width="12" textAlign="right">
          <CreateFilterDialog
            trigger={
              <Button
                basic
                icon="plus"
                floated="right"
                id="new-webhook"
                content={intl.formatMessage({ id: "meta.actions.add" })}
              />
            }
          />
        </Grid.Column>
      </Grid>

      <Segment.Group>
        {initialLoadingIndicator ? (
          <Segment>
            <Loader active inline="centered" />
          </Segment>
        ) : filters.length === 0 ? (
          <Segment>
            <FormattedMessage
              id="message_filters.no_filters_found"
              defaultMessage="No filters found"
            />
          </Segment>
        ) : (
          filters?.map(filter => {
            return (
              <Segment key={filter.id} clearing data-model="filter">
                <Grid>
                  <Grid.Column width={16}>
                    <Header
                      as="h5"
                      size="medium"
                      data-attr="name"
                      className="margin top none"
                    >
                      <div className="flex justify-content-space-between">
                        <div>
                          <span data-attr="name">{filter.name}</span>
                        </div>
                        <div>
                          <IsVersionHistoryAccessible
                            filter={filter}
                            i18n={intl}
                          >
                            <VersionHistoryLink
                              type="InboundMessageFilter"
                              id={filter.id}
                              className="float right"
                              size="normal"
                            />
                          </IsVersionHistoryAccessible>
                          <CreateFilterDialog
                            trigger={
                              <Icon
                                color="grey"
                                name="setting"
                                className="pointer"
                              />
                            }
                            model={filter}
                          />
                        </div>
                      </div>
                    </Header>
                    <List horizontal>
                      <List.Item>
                        <span data-attr="summary">{filter.summary}</span>
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid>
              </Segment>
            );
          })
        )}
      </Segment.Group>
    </div>
  );
};

export default Filters;
