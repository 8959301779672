import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Modal,
  Segment
} from "semantic-ui-react";
import HasEditProductsRight from "shared/components/authorization/HasEditProductsRight";
import { FormattedMessage, useIntl } from "react-intl";
import FileUploader from "builder_portal/components/dropzone/FileUploader";
import { If } from "shared/components/elements/Conditions";
import localStorage from "shared/helpers/localStorage";
import Growl from "builder_portal/actions/growlActions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { ProductFilesResource } from "builder_portal/actions/productActions";
import { func } from "prop-types";
import { ProductShape } from "shared/shapes";

import "./productFiles.scss";

const EMPTY_MODEL = {
  file: null,
  visibility: "private"
};

const ProductFileAttachment = ({ product, onChange }) => {
  const intl = useIntl();
  const disaptch = useDispatch();
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(EMPTY_MODEL);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModel(EMPTY_MODEL);
  };

  const submitFile = () => {
    const { file, visibility } = model;

    const formData = new FormData();
    formData.append(`product_file[file]`, file);
    formData.append(`product_file[visibility]`, visibility);

    fetch(`/api/v1/products/${product.id}/product_files`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.product_file) {
          new Growl(disaptch).success(
            "message.file_upload.success.title",
            "message.file_upload.success.body"
          );
          handleClose();
          return onChange();
        }
        return response.errors?.forEach(error => {
          new Growl(disaptch).error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              timeout: 8000,
              bodyValues: { translatedBody: error }
            }
          );
        });
      });
  };

  const handleSelect = file => {
    setModel(currentModel => ({
      ...currentModel,
      file
    }));
  };

  const attachmentDialog = () => {
    const triggerButton = (
      <Button labelPosition="left">
        <Icon name="file" />
        <FormattedMessage
          id="product.attachment.actions.add"
          defaultMessage="Anhang&nbsp;verlinken"
        />
      </Button>
    );

    return (
      <Modal
        size="tiny"
        trigger={triggerButton}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        closeIcon
      >
        <Modal.Header>
          <FormattedMessage
            id="product.attachment.file.dialog.title"
            defaultMessage="Datai verlinken"
          />
        </Modal.Header>

        <Modal.Content>
          <If condition={!model.file}>
            <FileUploader handleFile={handleSelect} />
          </If>
          <If condition={model.file}>
            <Header as="h5">{model.file?.path}</Header>
          </If>
          <Checkbox
            toggle
            onChange={(_, { checked }) => {
              const visibility = checked ? "public" : "private";
              setModel(currentModel => ({
                ...currentModel,
                visibility
              }));
            }}
            checked={model.visibility === "public"}
            label={intl.formatMessage({
              id: "product.attachment.file.dialog.visibility"
            })}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button
            positive
            onClick={submitFile}
            content={intl.formatMessage({
              id: "product.attachment.actions.add"
            })}
            icon="file"
            labelPosition="right"
          />
        </Modal.Actions>
      </Modal>
    );
  };

  const renderRemoveButton = file => {
    const deleteButton = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="product_group-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          new ProductFilesResource(disaptch, product.id)
            .remove(file.id)
            .then(() => {
              onChange();
              closeConfirmation();
            })
            .catch(() => {
              closeConfirmation();
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="product.attachment.file.confirmation.delete.title"
        message="product.attachment.file.confirmation.delete.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  const renderVisibilityButton = file => {
    const visibilityButton = (
      <Checkbox
        toggle
        checked={file.visibility === "public"}
        label={intl.formatMessage({
          id: "product.attachment.file.dialog.visibility"
        })}
      />
    );

    const buttons = [
      {
        id: "confirm",
        label: "meta.actions.yeah",
        color: file.visibility === "private" ? "facebook" : "grey",
        onClick: closeConfirmation => {
          new ProductFilesResource(disaptch, product.id)
            .save({
              id: file.id,
              visibility: file.visibility === "public" ? "private" : "public"
            })
            .then(() => {
              onChange();
              closeConfirmation();
            })
            .catch(() => {
              closeConfirmation();
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="product.attachment.file.confirmation.visibility.title"
        message={
          file.visibility === "public"
            ? "product.attachment.file.confirmation.visibility.messageHide"
            : "product.attachment.file.confirmation.visibility.messagePublish"
        }
        buttons={buttons}
        button={visibilityButton}
      />
    );
  };

  const attachmentList = () => {
    if (product.product_files?.length) {
      return product.product_files.map(file => (
        <Grid.Row key={file.id} className="highlight-row">
          <Grid.Column width={12}>
            <a href={file.internal_url} target="_blank" rel="noreferrer">
              {file.file_file_name}
            </a>
          </Grid.Column>
          <Grid.Column width={3} textAlign="right">
            {renderVisibilityButton(file)}
          </Grid.Column>
          <Grid.Column width={1} textAlign="right">
            {renderRemoveButton(file)}
          </Grid.Column>
        </Grid.Row>
      ));
    }
    return (
      <p>
        <FormattedMessage
          id="product.attachment.file.empty"
          dfault="No files"
        />
      </p>
    );
  };

  return (
    <Segment attached data-component="product-files-card">
      <Grid columns={2} stackable>
        <Grid.Row verticalAlign="middle">
          <Grid.Column textAlign="left">
            <Header as="h4">
              <FormattedMessage
                id="product.attachment.file.title"
                defaultMessage="Product files"
              />
            </Header>
          </Grid.Column>
          <HasEditProductsRight>
            <Grid.Column textAlign="right">{attachmentDialog()}</Grid.Column>
          </HasEditProductsRight>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid>{attachmentList()}</Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

ProductFileAttachment.propTypes = {
  product: ProductShape.isRequired,
  onChange: func.isRequired
};

export default ProductFileAttachment;
