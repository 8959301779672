import {
  get,
  keyBy,
  last,
  find,
  truncate,
  uniq,
  sortBy,
  omit,
  has
} from "lodash";
import { createSelector } from "reselect";
import { getAccount } from "shared/selectors/account";

import { getFilter, getPageContent, getUser } from "shared/selectors/base";
import { FILTER_MESSAGES } from "../constants/filters";
import { getProjects, getUnits, getBuyers } from "./masterdata";

export const getMessagesFilter = createSelector([getFilter], filter => {
  return get(filter, FILTER_MESSAGES, {});
});

export const getMessages = createSelector([getPageContent], pageContent =>
  get(pageContent, "messages", [])
);

export const getMessagesStatistics = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "messages_statistics", { projects: {} })
);

export const getMessageThread = createSelector(
  [getPageContent],
  pageContent => {
    const messageThread = get(pageContent, "message_thread");
    if (!messageThread) return null;
    const recipients = last(sortBy(messageThread.messages, "id"))?.recipients;
    if (recipients) {
      const recipientsByEmail = keyBy(recipients, "email");
      const participants = messageThread.participants.map(p => {
        if (recipientsByEmail[p.email])
          return { ...p, mode: recipientsByEmail[p.email].mode };
        return { ...p, mode: "to" };
      });
      return { ...messageThread, participants };
    }
    return messageThread;
  }
);

export const getFilterOptions = createSelector([getUser], user => {
  return {
    inbound: {
      message_type: "inbound"
    },
    inbound_user: {
      message_type: "inbound",
      user: get(user, "id")
    },
    inbound_unread: {
      message_type: "inbound",
      unread: "true"
    },
    outbound: {
      message_type: "outbound"
    },
    outbound_user: {
      message_type: "outbound",
      user: get(user, "id")
    },
    unassigned: {
      load_unassigned: true
    }
  };
});

export const getProjectOptions = createSelector(
  [getProjects, getAccount],
  (projects, account) => {
    let menu = [];
    if (projects.length >= 1) {
      menu = projects.map(project => {
        return {
          key: `project-${project.id}`,
          text: `${project.name}`,
          value: `${project.id}`
        };
      });
    }
    if (account.hasAccessUnassignedEmailsRight()) {
      menu = [
        ...menu,
        {
          key: "project-unassigned",
          text: "Ohne Projekt",
          value: "none"
        }
      ];
    }

    return menu;
  }
);

export const getProjectOptionsForNewMessage = createSelector(
  [getProjects],
  projects => {
    let menu = [];
    if (projects.length >= 1) {
      menu = projects.map(project => {
        return {
          key: `project-${project.id}`,
          text: `${project.name}`,
          value: `${project.id}`
        };
      });
    }

    return menu;
  }
);

export const getUnitOptions = createSelector(
  [getUnits, getAccount, getBuyers],
  (units, account, buyers) => {
    let options = [];

    if (units.length >= 1) {
      options = units.map(unit => {
        const unitBuyers = unit.buyer_ids.map((buyer_id, index) => {
          const unitBuyer = find(buyers, buyer => buyer.id === buyer_id);
          const whiteSpaceAfterComma = index > 0 ? " " : "";
          return `${whiteSpaceAfterComma}${truncate(unitBuyer.first_name, {
            length: 2,
            omission: "."
          })} ${unitBuyer.last_name}`;
        });

        return {
          key: `unit-${unit.id}`,
          project: `${unit.project_id}`,
          text: `${unit.name} - ${unitBuyers}`,
          value: `${unit.id}`
        };
      });
    }

    if (account.isAdmin()) {
      options = [
        ...options,
        {
          key: "unit-unassigned",
          text: "Ohne Wohneinheit",
          value: "none"
        }
      ];
    }

    return options;
  }
);

export const getInboxMenu = createSelector([getMessagesStatistics], stats => {
  return [
    {
      id: "inbound",
      label: "Alle",
      title: `${stats.unread_total} ungelesen von insgesamt ${stats.total}`,
      labelCount: stats.unread_total,
      color: "grey"
    },
    {
      id: "inbound_user",
      label: "Meine Nachrichten",
      title: `${stats.unread_own} ungelesen von insgesamt ${stats.own_total}`,
      labelCount: stats.unread_own,
      color: "teal"
    },
    {
      id: "inbound_unread",
      title: "",
      label: "Ungelesen"
    },
    {
      id: "unassigned",
      label: "Unzugeordnet",
      title: "",
      labelCount: stats.unassigned,
      color: "grey"
    }
  ];
});

export const getOutboxMenu = createSelector([], () => {
  return [
    {
      id: "outbound",
      label: "Alle",
      title: ""
    },
    {
      id: "outbound_user",
      label: "Meine Nachrichten",
      title: ""
    }
  ];
});

export const getContacts = createSelector(
  [getPageContent, getMessageThread],
  (pageContent, messageThread) => {
    const base = ["users", "contractors", "contacts"].reduce((accu, type) => {
      return accu.concat(get(pageContent, ["master_data", type], []));
    }, []);
    if (messageThread) {
      return base.concat(
        messageThread.participants.filter(participant => {
          return participant.type === "unknown";
        })
      );
    }
    return base;
  }
);

export const getContactsById = createSelector([getContacts], contacts =>
  keyBy(contacts, c => `${c.type}:${c.id}`)
);

export const getReplyTemplate = createSelector(
  [getMessageThread, getUser],
  (messageThread, user) => {
    if (messageThread) {
      const parent = last(messageThread.messages);
      const recipients = mode =>
        messageThread.participants
          .filter(participant => {
            return (
              user.email !== participant.email && participant.mode === mode
            );
          })
          .map(participant => {
            return `${participant.type}:${participant.id}`;
          });
      const project = messageThread.references.find(reference => {
        return reference.type === "project";
      });

      return {
        parent_id: parent.id,
        project_id: get(project, "id"),
        subject: `AW: ${parent.subject}`,
        recipients: uniq(recipients("to")),
        cc: uniq(recipients("cc")),
        bcc: uniq(recipients("bcc")),
        body: "",
        body_html: "",
        attachments: []
      };
    }
    return {};
  }
);

export const getSearchParams = createSelector(
  [getFilterOptions, getMessagesFilter],
  (filterOptions, filter) => {
    let params = {
      ...get(filterOptions, [filter.item], {}),
      page: filter.page || 1,
      project: `${filter.project || "all"}`,
      unit: `${filter.unit || "all"}`
    };
    if (has(filter, "message_type")) {
      params.message_type = filter.message_type;
    } else if (!params.message_type) {
      params.message_type = "inbound";
    }
    if (filter.query) {
      params.query = filter.query;
      params = omit(params, ["message_type"]);
    }
    if (filter.message_label) {
      params.message_label = filter.message_label;
    }
    return params;
  }
);

export const getMessageLabels = createSelector([getPageContent], pageContent =>
  sortBy(get(pageContent, "message_labels", []), item =>
    item.name.toLowerCase()
  )
);
