import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { includes } from "lodash";
import { Segment, Tab, Header, Button, Menu } from "semantic-ui-react";

import { getFormattedTrades } from "shared/selectors";
import HasEditProductsRight from "shared/components/authorization/HasEditProductsRight";
import {
  ProductResource,
  ProductSpecificationResource
} from "../../actions/productActions";
import ProductSpecificationEditDialog from "./ProductSpecificationEditDialog";
import "./productSpecifications.scss";

const INIT_STATE = {
  open: false,
  loading: false,
  showFullText: false,
  currentTrade: "",
  specId: "",
  textValue: "",
  placeholder: ""
};

class ProductSpecifications extends Component {
  static propTypes = {
    cleanSpecifications: PropTypes.array,
    defaultText: PropTypes.string,
    i18n: PropTypes.object,
    onUpdateFn: PropTypes.func,
    product: PropTypes.object,
    resources: PropTypes.object,
    trades: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = INIT_STATE;
  }

  handleSaveSpecification = async dialogState => {
    const { resources, product } = this.props;
    const { id } = product;

    this.setState({ loading: true });

    const newSpec = {
      id: dialogState.specId,
      trade: dialogState.currentTrade,
      specification: dialogState.textValue
    };
    const resource = resources.specificationResource(id);
    try {
      if (!newSpec.specification.length) {
        await resource.remove(newSpec.id);
      } else {
        await resource.save(newSpec);
      }
      await this.props.onUpdateFn(product);
    } finally {
      this.setState(INIT_STATE);
    }
  };

  handleSaveTechnicalDescription = async dialogState => {
    const { resources, product } = this.props;
    const resource = resources.products;
    const description = {
      ...product,
      technical_description: dialogState.textValue
    };
    try {
      await resource.save(description);
      await this.props.onUpdateFn(product);
    } finally {
      this.setState(INIT_STATE);
    }
  };

  editTechnicalDescription = () => {
    const { i18n, defaultTechnicalDescription, product } = this.props;

    const initialTextValue = product.technical_description || "";

    return (
      <ProductSpecificationEditDialog
        i18n={i18n}
        initialTextValue={initialTextValue}
        placeholder={defaultTechnicalDescription}
        handleSaveFn={this.handleSaveTechnicalDescription}
      />
    );
  };

  editSpecification = trade => {
    const { i18n, defaultTechnicalDescription, product } = this.props;

    const initialTextValue = trade.spec.specification || "";
    const placeholder =
      product.technical_description || defaultTechnicalDescription;

    return (
      <ProductSpecificationEditDialog
        i18n={i18n}
        initialTextValue={initialTextValue}
        placeholder={placeholder}
        handleSaveFn={this.handleSaveSpecification}
        trade={trade}
      />
    );
  };

  collapseText = () => {
    this.setState({ showFullText: false });
    const paragraph = document.getElementById(`specification-paragraph`);

    return (paragraph.className = "snippet");
  };

  showText = () => {
    this.setState({ showFullText: true });
    const paragraph = document.getElementById(`specification-paragraph`);

    return (paragraph.className = "fullText");
  };

  renderPanes = () => {
    const {
      i18n,
      tradesOnProduct,
      defaultTechnicalDescription,
      product
    } = this.props;

    if (!product) return null;

    const menuTemplate = (key, label, button, text) => {
      const paragraphClass = !this.state.showFullText ? "snippet" : "fullText";
      const showButtons = text.length > 300;
      return {
        menuItem: (
          <Menu.Item key={key} data-component="specificationTab">
            {label}
            <HasEditProductsRight>{button}</HasEditProductsRight>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane data-component="specificationPane" className="preWrap">
            <p id="specification-paragraph" className={paragraphClass}>
              {text}
            </p>
            {showButtons && !this.state.showFullText && (
              <Button
                basic
                size="tiny"
                icon="angle down"
                onClick={() => this.showText()}
                content={i18n["meta.actions.showMore"]}
              />
            )}
            {showButtons && this.state.showFullText && (
              <Button
                basic
                size="tiny"
                icon="angle up"
                onClick={() => this.collapseText()}
                content={i18n["meta.actions.showLess"]}
              />
            )}
          </Tab.Pane>
        )
      };
    };

    const technicalDescription = () => {
      const label = i18n["product.attributes.technical_description.label"];
      const button = this.editTechnicalDescription();
      const text = product.technical_description || defaultTechnicalDescription;
      const key = "technical_description";

      return menuTemplate(key, label, button, text);
    };

    const tradesPanes = tradesOnProduct.map(trade => {
      const { key } = trade;
      const { label } = trade;
      const button = this.editSpecification(trade);
      const text =
        trade.spec.specification ||
        product.technical_description ||
        defaultTechnicalDescription;

      return menuTemplate(key, label, button, text);
    });

    const panes = [technicalDescription(), ...tradesPanes];
    return panes;
  };

  render() {
    return (
      <Segment attached data-component="productSpecifications">
        <Header as="h4">
          <FormattedMessage
            id="product.attributes.description.specifications"
            defaultMessage="product.attributes.description.specifications"
          />
        </Header>
        <Tab
          className="specifications margin top medium"
          menu={{
            fluid: true,
            vertical: true,
            tabular: false,
            borderless: true,
            secondary: true
          }}
          panes={this.renderPanes()}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { product } = props;
  const supplier = product.supplier !== null ? `${product.supplier}\n` : "";
  const series = product.series ? `Produktlinie: ${product.series}\n` : "";
  const sku = product.sku ? `Produktcode: ${product.sku}\n` : "";
  // prettier-ignore
  const defaultTechnicalDescription = `${supplier}${product.name}\n${series}${sku}\n\n${product.description}`;

  const tradesOnProduct = getFormattedTrades(state)
    .filter(trade => includes(product.trades, trade.value))
    .map(trade => {
      const existingSpecification = product.specifications.find(
        spec => spec.trade === trade.key
      );
      return {
        key: trade.key,
        label: trade.text,
        spec: existingSpecification || ""
      };
    });

  return {
    i18n: state.i18n,
    tradesOnProduct,
    defaultTechnicalDescription,
    pageContent: state.pageContent,
    trades: getFormattedTrades(state)
  };
};

const mapDispatchToProps = dispatch => ({
  resources: {
    products: new ProductResource(dispatch),
    specificationResource: productId =>
      new ProductSpecificationResource(dispatch, productId)
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSpecifications);
