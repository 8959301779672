import { createSelector } from "reselect";
import { get, keyBy } from "lodash";

import Account from "../models/account";
import { getAccountData, getI18N, getPageContent } from "./base";

export const getSalutations = [
  "mr",
  "mrs",
  "mr+mrs",
  "family",
  "spouses",
  "other"
];
export const getTitles = ["dr", "prof_dr", "dipl_ing"];

export const getAccount = createSelector(
  [getAccountData, getI18N, getPageContent],
  (accountData, i18n, pageContent) =>
    new Account(accountData, i18n, pageContent)
);

export const getUserToken = createSelector([getAccount], account =>
  get(account, ["data", "auth_token"], "")
);

export const getTrades = createSelector([getAccount], account =>
  account.getTrades()
);

export const getFormattedTrades = createSelector([getTrades], trades =>
  trades.map(trade => {
    return {
      key: trade.id,
      value: trade.id,
      text: trade.label
    };
  })
);

export const getTradesDictionary = createSelector([getAccount], account =>
  account.getTradesDictionary()
);

export const getLineItemLifeCycle = createSelector([getAccount], account =>
  account.getLineItemLifeCycle()
);

export const getProcessDefinitions = createSelector([getAccount], account =>
  account.getProcessDefinitions()
);

export const getFeatureToggles = createSelector([getAccount], account =>
  account.getFeatureToggles()
);

export const getDocumentTypes = createSelector([getAccount], account =>
  account.getDocumentTypes()
);

export const getProjectsConfig = createSelector([getAccountData], data =>
  keyBy(get(data, ["account", "projects"], []), "slug")
);

export const getProjectConfig = (state, projectId) => {
  return getProjectsConfig(state)[projectId];
};

export const getAccountProjects = state => {
  return get(state, ["account", "account", "projects"], []);
};

export const getPageContentAccount = state => {
  return get(state, "pageContent.account", {});
};

export const getDeletableTypes = state => {
  return get(state, ["pageContent", "deletable_types"], []);
};

export const getUsers = state => {
  return get(state, ["pageContent", "users"], []);
};

export const getAccountMemberships = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "account_memberships", [])
);
