import React from "react";
import { get, keyBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { Grid, Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AmountsByTrade from "../analytics/AmountsByTrade";
import ProgressByTrade from "../analytics/ProgressByTrade";
import ProgressByPhase from "../analytics/ProgressByPhase";
import PhaseLegend from "../analytics/PhaseLegend";
import ProfitByPhase from "../analytics/ProfitByPhase";
import { UnitResource } from "../../actions/unitActions";

import "./statistics.scss";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";

class UnitStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    const { unitId, unitResource } = this.props;
    unitResource
      .getStatistics(unitId)
      .catch(silentHandleApiRequestErrors)
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const { statistics, i18n, accountingMode } = this.props;
    const { loading } = this.state;

    const statistics_by_phase = get(statistics, "by_phase", []);

    if (statistics_by_phase.length > 0) {
      const phaseStatistic = keyBy(statistics_by_phase, "phase");

      return (
        <div data-component="unitStatistics">
          <Loader active={loading} inline />
          <Grid stackable>
            <Grid.Column width="8" textAlign="center">
              <Header>
                <FormattedMessage
                  id="unit.statistics.progress.title"
                  default="unit.statistics.progress.title"
                />
              </Header>
              <ProgressByPhase statistics={statistics_by_phase} />
            </Grid.Column>

            <Grid.Column width="8" textAlign="center">
              <Header>
                <FormattedMessage
                  id={`unit.statistics.profit.${accountingMode}.title`}
                  default={`unit.statistics.profit.${accountingMode}.title`}
                />
              </Header>
              <ProfitByPhase
                statistics={phaseStatistic}
                accountingMode={accountingMode}
              />
            </Grid.Column>

            <Grid.Column width="16" textAlign="center">
              <PhaseLegend />
            </Grid.Column>

            <Grid.Column
              width="16"
              textAlign="center"
              className="margin top spacious"
            >
              <Header>
                <FormattedMessage
                  id="unit.statistics.progressByTrade.title"
                  default="unit.statistics.progressByTrade.title"
                />
              </Header>
              <ProgressByTrade statistics={statistics} />
            </Grid.Column>

            <Grid.Column
              width="16"
              textAlign="center"
              className="margin top spacious"
            >
              <AmountsByTrade
                statistics={statistics}
                accountingMode={accountingMode}
              />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Loader
            active
            inline="centered"
            content={i18n["loadingIndicator.loading"]}
          />
        </div>
      );
    }
    return (
      <div>
        <Header
          as="h3"
          textAlign="center"
          className="margin top bottom spacious"
        >
          <FormattedMessage
            id="unit.statistics.noValues"
            default="unit.statistics.noValues"
          />
        </Header>
      </div>
    );
  }
}

UnitStatistics.propTypes = {
  i18n: PropTypes.object.isRequired,
  unitId: PropTypes.string.isRequired,
  statistics: PropTypes.object.isRequired,
  accountingMode: PropTypes.oneOf(["net", "gross"]),
  unitResource: PropTypes.instanceOf(UnitResource).isRequired
};

UnitStatistics.defaultProps = {
  accountingMode: "net"
};

const getAccountingMode = state => {
  const featureToggles = get(state, "account.account.feature_toggles", {});
  return featureToggles.show_reports_with_gross_amounts ? "gross" : "net";
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  statistics: get(state, "pageContent.statistics"),
  accountingMode: getAccountingMode(state)
});

const mapDispatchToProps = dispatch => ({
  unitResource: new UnitResource(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitStatistics);
