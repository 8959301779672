import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import ProjectAttachmentsList from "../attachment/ProjectAttachmentsList";
import { If } from "../../../shared/components/elements/Conditions";
import ProjectQuestions from "./projectQuestions/ProjectQuestions";
import ProjectQuestionsLoader from "./projectQuestions/ProjectQuestionsLoader";
import FeatureToggle from "../../../shared/components/elements/FeatureToggle";

const ProjectContentTabMenu = ({ tab, onChange }) => {
  return (
    <Menu>
      <Menu.Item
        active={tab === "attachments"}
        onClick={() => onChange("attachments")}
      >
        <FormattedMessage id="roomBook.attachments.title" />
      </Menu.Item>
      <FeatureToggle featureTogglesArray={["bp_faq", "mp_faq"]} any>
        <Menu.Item
          active={tab === "questions"}
          onClick={() => onChange("questions")}
        >
          <FormattedMessage id="roomBook.projectQuestions.title" />
        </Menu.Item>
      </FeatureToggle>
    </Menu>
  );
};

ProjectContentTabMenu.propTypes = {
  tab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ProjectContentTab = ({ projectId }) => {
  const [tab, setTab] = React.useState("attachments");
  return (
    <div>
      <FeatureToggle featureTogglesArray={["bp_faq", "mp_faq"]} any>
        <ProjectContentTabMenu tab={tab} onChange={setTab} />
      </FeatureToggle>
      <If condition={tab === "attachments"}>
        <ProjectAttachmentsList projectId={projectId} />
      </If>
      <If condition={tab === "questions"}>
        <ProjectQuestionsLoader projectId={projectId}>
          <ProjectQuestions projectId={projectId} />
        </ProjectQuestionsLoader>
      </If>
    </div>
  );
};

ProjectContentTab.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default ProjectContentTab;
