import { groupBy } from "lodash";
import { createSelector } from "reselect";
import { getLineItems } from "./roomBook";

export const getReplacementItems = createSelector(
  [getLineItems],
  lineItemsById => {
    const lineItems = Object.values(lineItemsById);
    const replaced = lineItems.filter(li => li.replaced);
    const replacements = lineItems.filter(li => {
      return li.replacement_info;
    });

    const replacementsByReplacableId = groupBy(replacements, li => {
      return li.replacement_info.id;
    });

    return replaced.reduce((accu, replacedItem) => {
      const replacements = replacementsByReplacableId[replacedItem.id];
      const activeReplacment = replacements.find(li => li.activated);
      accu[replacedItem.id] = activeReplacment;

      replacements.forEach(li => {
        accu[li.id] = activeReplacment;
      });

      return accu;
    }, {});
  }
);

export const getLineItemOptions = createSelector([getLineItems], lineItems => {
  return Object.values(lineItems);
});
