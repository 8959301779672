import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form, Select } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import MacroResource from "builder_portal/actions/macroActions";
import Growl from "builder_portal/actions/growlActions";
import { getAllProjectOptions } from "shared/selectors/project";
import { MacroShape } from "shared/shapes/macro.shape";
import { node } from "prop-types";

const MacroCopyDialog = ({ model, trigger }) => {
  const dispatch = useDispatch();
  const refResetForm = useRef();
  const [isOpen, setOpen] = useState(false);
  const projectOptions = useSelector(getAllProjectOptions);

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearForm();
  };

  const getCopyName = macro => {
    return `Kopie von ${macro.name}`;
  };

  const onSubmit = useCallback(
    values => {
      const resource = new MacroResource(dispatch);

      return resource
        .save({ ...model, ...values, id: null })
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  return (
    <Formik
      initialValues={{
        name: getCopyName(model),
        project_ids: model.project_ids
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validate={values => {
        const minLength = 10;
        const errors = {};
        if (!values.name) {
          errors.name = "Pflichtfeld.";
        } else if (values.name.length < minLength) {
          errors.name = `Muss mindestens aus ${minLength} Zeichen bestehen.`;
        }
        return errors;
      }}
    >
      {({ isSubmitting, handleSubmit, resetForm, errors, touched }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeOnEscape
            closeOnDimmerClick
            closeIcon
            trigger={trigger}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Modal.Header>Standardemail duplizieren</Modal.Header>

            <Modal.Content>
              <Form>
                <Input name="name" label="Name" />
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {errors.name && touched.name && errors.name}
                </div>
                <Select
                  multiple
                  name="project_ids"
                  label="Projekte"
                  options={projectOptions}
                />
              </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button basic disabled={isSubmitting} onClick={handleClose}>
                <FormattedMessage id="meta.actions.cancel" />
              </Button>
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                <FormattedMessage id="meta.actions.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

MacroCopyDialog.propTypes = {
  trigger: node.isRequired,
  model: MacroShape.isRequired
};

export default MacroCopyDialog;
