import React, { useState, useEffect } from "react";
import { Table, Header, Button, Segment, Loader } from "semantic-ui-react";
import { WebhooksResource } from "builder_portal/actions/webhooksActions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getWebhooks } from "shared/selectors/webhooks";
import WebhookDialog from "./CreateWebhookDialog";
import "./webhooks.scss";

const Webhooks = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const webhooksResource = new WebhooksResource(dispatch);
  const projects = useSelector(state => state.account.account.projects);

  const webhooks = useSelector(getWebhooks) || [];

  useEffect(() => {
    setLoading(true);
    webhooksResource.fetchAll().then(() => {
      setLoading(false);
    });
  }, []);

  // return project name by id
  const getProjectName = id => {
    if (id === null) return "*";
    const project = projects.find(item => item.id === id);
    return project?.name;
  };

  const getItemType = type => {
    if (type === null) return "*";
    return type;
  };

  return (
    <Segment.Group data-component="webhooks">
      <Segment.Group horizontal>
        <Segment>
          <Header>
            <FormattedMessage
              id="webhooks.webhooks"
              defaultMessage="Webhooks"
            />
          </Header>
        </Segment>
        <WebhookDialog
          trigger={<Button icon="plus" floated="right" id="new-webhook" />}
          projects={projects}
        />
      </Segment.Group>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <Table attached striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="webhooks.id" defaultMessage="ID" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="webhooks.url" defaultMessage="URL" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="webhooks.method"
                  defaultMessage="Method"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="webhooks.project"
                  defaultMessage="Project"
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage
                  id="webhooks.occurrence"
                  defaultMessage="Event"
                />
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {webhooks.length > 0 ? (
              webhooks.map(webhook => (
                <Table.Row key={webhook.id}>
                  <Table.Cell>{webhook.id}</Table.Cell>
                  <Table.Cell>{webhook.url}</Table.Cell>
                  <Table.Cell>{webhook.request_method}</Table.Cell>
                  <Table.Cell>{getProjectName(webhook.project_id)}</Table.Cell>
                  <Table.Cell>
                    {getItemType(webhook.item_type)}, {webhook.event}
                  </Table.Cell>
                  <Table.Cell>
                    <WebhookDialog
                      trigger={<Button icon="setting" data-cmd="edit" />}
                      model={webhook}
                      projects={projects}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <FormattedMessage
                    id="webhooks.emptyMessage"
                    defaultMessage="Es sind noch keine Webhooks definiert"
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      )}
    </Segment.Group>
  );
};

export default Webhooks;
