/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";

// eslint-disable-next-line import/prefer-default-export
export class ProjectStoreysResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_storey",
      `/api/v1/projects/${projectId}/project_storeys`
    );
  }
}
