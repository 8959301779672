export default class OpenActivityAction {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  // Needs to have the same signature as the other Action classes
  // eslint-disable-next-line class-methods-use-this
  execute(params) {
    const { router, activityId, activityAction, attachmentId } = params;
    const { projectId } = router.params;

    const state = activityAction ? { activityAction, attachmentId } : {};

    router.push({
      pathname: `/projects/${projectId}/activities/${activityId}/timeline`,
      state
    });
    return Promise.resolve(params);
  }
}
