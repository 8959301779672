import React from "react";
import { Grid, Image, Header, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import loginImage from "../../../../assets/images/auth-redirect.png";
import "./styles.scss";
import bauDigitalLogo from "../../../../assets/images/bau-digital-logo.png";
import { browserHistory } from "../../../shared/routes/browserHistory";
import {
  DELETE_PAGE_CONTENT,
  UPDATE_ACCOUNT,
  UPDATE_FLASH_ACTION,
  UPDATE_MESSAGE
} from "../../../shared/constants/appConstants";
import localStorage from "../../../shared/helpers/localStorage";
import TokenResource from "../../../shared/actions/tokenActions";

const AuthRedirect = ({ handleRedirect }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);

  const signOn = authCode => {
    setLoading(true);
    fetch("/api/v1/single_sign_on_tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ authorization_code: authCode })
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.token) {
          handleRedirect(data.token);
        }
      })
      .catch(() => {
        setLoading(false);
        dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.authenticationFailed.title",
            body: "message.authenticationFailed.body"
          }
        });
      });
  };

  React.useEffect(() => {
    const authCode = new URLSearchParams(window.location.search).get(
      "authorization_code"
    );
    signOn(authCode);
  }, []);

  return (
    <Grid stackable data-component="auth-redirect">
      <Grid.Row
        style={{
          alignItems: "center",
          paddingTop: 0
        }}
      >
        <Grid.Column mobile={16} tablet={16} computer={6}>
          <div className="formContainer">
            <Grid columns={1}>
              <Grid.Row style={{ marginTop: "2em", marginBottom: "1em" }}>
                <Grid.Column>
                  <Image src={bauDigitalLogo} size="huge" centered />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "2em", marginBottom: "1em" }}>
                <Grid.Column>
                  <Header as="h1" size="huge" textAlign="center">
                    <FormattedMessage id="login.authRedirect.signingIn" />
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "2em", marginBottom: "1em" }}>
                <Grid.Column textAlign="center">
                  <Icon name="spinner" loading size="massive" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={10}
          className="imageContainer"
          only="wide screen"
        >
          <div style={{ width: "100%", margin: "0 auto", height: "100%" }}>
            <Grid columns={1}>
              <Grid.Column>
                <Image
                  style={{ height: "102vh", objectFit: "cover" }}
                  fluid
                  src={loginImage}
                />
              </Grid.Column>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    handleRedirect: token => {
      localStorage.set("token", token);
      // clear any previous stored data
      dispatch({
        type: DELETE_PAGE_CONTENT
      });
      dispatch({
        type: UPDATE_FLASH_ACTION,
        payload: null
      });

      // dispatch action to update account in redux store
      dispatch({
        type: UPDATE_ACCOUNT,
        payload: token
      });

      return new TokenResource(dispatch).get().then(() => {
        // redirect to dashboard
        let landingPage = localStorage.get("landingPage") || "/";
        if (
          [
            "//users/login",
            "/users/login",
            "/users/logout",
            "/users/password",
            "/auth_redirect"
          ].indexOf(landingPage) >= 0
        ) {
          landingPage = "/";
        }

        return browserHistory.push(landingPage);
      });
    }
  };
};

AuthRedirect.propTypes = {
  handleRedirect: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(AuthRedirect);
