import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";
import { createSelector } from "reselect";
import { get, groupBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { Message, Icon, Button, Header } from "semantic-ui-react";
import { defaultRichTextTags } from "builder_portal/helpers/defaultRichTextTags";
import { ProductGroupResourcesShape } from "shared/shapes/productGroupResources.shape";
import { getTrades } from "shared/selectors";
import ProductGroupWarningsResource from "../../actions/productGroupWarningActions";
import ProductGroupResource from "../../actions/productGroupActions";
import { If } from "../../../shared/components/elements/Conditions";
import { getPageContent } from "../../../shared/selectors";
import { getProductsById } from "../../selectors/catalog/productGroups";
import { ProductGroupShape } from "../../../shared/shapes";

export const getProductGroupWarnings = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, "product_group_warnings", []);
  }
);

const useProductGroupWarnings = ({ productGroup }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const products = useSelector(getProductsById);
  const warnings = useSelector(getProductGroupWarnings);
  const resource = useMemo(() => {
    return new ProductGroupWarningsResource(
      productGroup.project_catalog_id,
      productGroup.id,
      dispatch
    );
  }, [productGroup]);

  useEffect(() => {
    resource.fetchAll();
  }, [productGroup]);

  const warningsWithData = useMemo(() => {
    return warnings
      .map(({ type, payload }) => {
        return {
          type,
          payload: { ...payload, product: products[payload.product_id] }
        };
      })
      .filter(({ payload }) => !!payload.product);
  }, [warnings, products]);

  return {
    warnings: warningsWithData,
    isLoading,
    setLoading,
    resource
  };
};

const StaleTradeWarning = ({
  warning,
  resource,
  isLoading,
  setLoading,
  productGroupResource,
  tradesLabel
}) => {
  const supplierId = warning.product.supplier_id || "none";
  const trades = warning.trades
    .map(trade => tradesLabel[trade][0]?.label)
    .join(", ");

  const deleteCosts = () => {
    setLoading(true);
    resource.updateWarnings(warning).then(() => {
      setLoading(false);
      productGroupResource.get(warning.product_group_id, true);
    });
  };

  return (
    <Message warning>
      <div style={{ display: "flex" }}>
        <div>
          <Icon
            name="warning sign"
            color="red"
            size="large"
            style={{ margin: "10px 10px 0 0" }}
          />
        </div>
        <div>
          <div>
            <FormattedMessage
              id="product_group.warnings.segment.message"
              defaultValue="Warning message"
              values={{
                name: warning.product?.name || "",
                trades,
                ...defaultRichTextTags
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              basic
              color="red"
              onClick={deleteCosts}
              disabled={isLoading}
              loading={isLoading}
            >
              <FormattedMessage id="product_group.warnings.segment.deleteCosts" />
            </Button>
            <Button basic color="grey" disabled={isLoading} loading={isLoading}>
              <Link to={`/products/${supplierId}/${warning.product_id}`}>
                <FormattedMessage id="product_group.warnings.segment.changeProduct" />
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </Message>
  );
};

StaleTradeWarning.propTypes = {
  warning: PropTypes.shape({
    product_id: PropTypes.number.isRequired,
    product_group_id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      supplier_id: PropTypes.string.isRequired
    }),
    trades: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  resource: PropTypes.instanceOf(ProductGroupWarningsResource).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  productGroupResource: ProductGroupResourcesShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tradesLabel: PropTypes.object.isRequired
};

const ProductGroupWarningsSegment = ({ productGroup }) => {
  const dispatch = useDispatch();
  const productGroupResource = useMemo(
    () => new ProductGroupResource(productGroup.project_catalog_id, dispatch),
    [productGroup]
  );
  const tradesLabel = useSelector(getTrades);

  const { warnings, resource, isLoading, setLoading } = useProductGroupWarnings(
    {
      productGroup
    }
  );
  return (
    <If condition={!!warnings.length}>
      <Header as="h4">
        <FormattedMessage id="product_group.warnings.segment.title" />
      </Header>
      {warnings?.map(warning => (
        <If
          condition={warning.type === "stale_trade"}
          key={warning.payload.product_price_id}
        >
          <StaleTradeWarning
            warning={warning.payload}
            resource={resource}
            isLoading={isLoading}
            setLoading={setLoading}
            productGroupResource={productGroupResource}
            tradesLabel={groupBy(tradesLabel, "id")}
          />
        </If>
      ))}
    </If>
  );
};

ProductGroupWarningsSegment.propTypes = {
  productGroup: ProductGroupShape.isRequired
};

export default ProductGroupWarningsSegment;
