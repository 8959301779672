import { shape, number, string } from "prop-types";

const DocumentAssignmentShape = shape({
  id: number,
  projectName: string,
  exportName: string,
  styleName: string,
  role: string,
  group: string
});

export default DocumentAssignmentShape;
