import React from "react";
import FormatQuantity from "shared/components/quantity/FormatQuantity";
import { Label } from "semantic-ui-react";

export const SubLineItemQuantityLabel = props => {
  const { lineItem } = props;
  if (lineItem.isFlat()) {
    return null;
  }
  return (
    <span data-attr="quantity">
      <Label basic>
        <FormatQuantity
          value={lineItem.getQuantity()}
          unit={lineItem.getPriceStrategy()}
        />
      </Label>
    </span>
  );
};
