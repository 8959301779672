import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Header, Icon, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { number } from "prop-types";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import ProjectQuestionsResource from "builder_portal/actions/projectQuestionsActions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { sortBy } from "lodash";
import ProjectQuestionDialog from "./ProjectQuestionDialog";
import { ProjectQuestionsLoaderContext } from "./ProjectQuestionsLoader";

const ProjectQuestionsList = ({ groupId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { projectQuestions, projectId, isLoading, loadData } = useContext(
    ProjectQuestionsLoaderContext
  );

  const projectQuestionsForGroup = useMemo(
    () =>
      sortBy(
        projectQuestions.filter(ub => ub.project_question_group_id === groupId),
        "position"
      ),
    [projectQuestions]
  );

  const deleteProjectQuestion = id => {
    return new ProjectQuestionsResource(dispatch, projectId)
      .remove(id)
      .then(() => {
        loadData();
      });
  };

  const renderDeleteButton = model => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="project_question-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          deleteProjectQuestion(model.id).catch(() => {
            handleClose();
          });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.projectQuestions.removeConfirmation.title"
        message="roomBook.projectQuestions.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const renderProjectQuestion = item => {
    return (
      <Table.Row key={item.id} id={`project_questions-${item.id}`}>
        <Table.Cell width={13}>
          <Header as="h5">
            {item.position}. {item.question}
          </Header>
          <p>{item.answer}</p>
        </Table.Cell>
        <Table.Cell width={3} textAlign="right">
          <div className="flex gap-1 justify-content-end align-items-center">
            <VersionHistoryLink
              id={item.id}
              type="ProjectQuestion"
              style={{
                fontSize: "1rem",
                lineHeight: "0.75rem"
              }}
            />
            <ProjectQuestionDialog
              model={item}
              total={projectQuestionsForGroup.length || 0}
              button={
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  id="project_question-edit"
                  loading={isLoading}
                  disabled={isLoading}
                  data-tooltip={intl.formatMessage({ id: "meta.actions.edit" })}
                >
                  <Icon name="setting" color="grey" />
                </a>
              }
            />
            {renderDeleteButton(item)}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderProjectQuestions = () => {
    if (!projectQuestionsForGroup.length && !isLoading) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.projectQuestions.notFoundInGroup"
            defaultMessage="Es liegen keine Fragen zu dieser Gruppe vor"
          />
        </Header>
      );
    }

    return projectQuestionsForGroup.map(renderProjectQuestion);
  };

  return (
    <>
      <Table
        data-component="project_question_items"
        style={{ marginTop: ".5em" }}
      >
        <Table.Body>{renderProjectQuestions()}</Table.Body>
      </Table>
      <ProjectQuestionDialog
        total={(projectQuestionsForGroup?.length || 0) + 1}
        model={{
          name: "",
          title: "",
          description: "",
          position: (projectQuestionsForGroup?.length || 0) + 1,
          image: null,
          project_question_group_id: groupId
        }}
        button={
          <Button
            id="project_question-new"
            fluid
            icon="plus"
            content={intl.formatMessage({
              id: "roomBook.projectQuestions.add"
            })}
            loading={isLoading}
            disabled={isLoading}
            style={{ marginTop: "10px" }}
          />
        }
      />
    </>
  );
};

ProjectQuestionsList.propTypes = {
  groupId: number.isRequired
};

export default ProjectQuestionsList;
