import { string, shape, bool } from "prop-types";

export const ProjectEmailShape = shape({
  email: string,
  active: bool,
  outbound_confirmed: bool,
  inbound_confirmed: bool,
  technical_contact_email: string,
  technical_contact_name: string
});

export default ProjectEmailShape;
