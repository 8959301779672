import Resources from "shared/actions/resourcesActions";

export default class ProjectQuestionGroupsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_question_group",
      `/api/v1/projects/${projectId}/project_question_groups`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
