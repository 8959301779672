import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";
import { get } from "lodash";

export const getSupplierIntegrationSuppliers = createSelector(
  getPageContent,
  pageContent => get(pageContent, "supplierIntegration.suppliers", [])
);

export const getSupplierIntegrationSupplier = createSelector(
  getPageContent,
  pageContent => get(pageContent, "supplierIntegration.supplier")
);

export const getSupplierIntegrationSeries = createSelector(
  getPageContent,
  pageContent => get(pageContent, "supplierIntegration.serie")
);
