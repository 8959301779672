import Resources from "shared/actions/resourcesActions";
import apiRequest from "shared/network/apiRequest";

export default class PriceCatalogResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "price_catalog", `/api/v1/price_catalogs`);
  }

  bulkPriceUpdate(id, payload) {
    return apiRequest
      .post(`${this.url}/${id}/bulk_price_update`, this.dispatch, {
        [this.namespace]: payload
      })
      .then(this.propagateUpdate.bind(this));
  }
}
