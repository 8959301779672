import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import { capitalize, get, isEqual, keyBy } from "lodash";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Modal,
  Segment,
  Tab,
  Menu
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Form, Checkbox, TextArea } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import Growl from "../../../actions/growlActions";
import ConfiguratorBackendsResource from "../../../actions/configuratorBackendsActions";
import {
  getCatalogsForProject,
  getPageContent
} from "../../../../shared/selectors";
import SemanticFormikJsonEditor from "../../../../shared/components/forms/SemanticFormikJsonEditor";
import ProductMapping from "./ProductMapping";
import CatalogResource from "../../../actions/catalogActions";
import loadProductGroupsForMapping from "./products/loadProductGroupsForMapping";
import silentHandleApiRequestErrors from "../../../../shared/helpers/silentHandleApiRequestErrors";
import {
  ProjectCatalogShape,
  ProductGroupShape,
  ProjectWithContentShape
} from "../../../../shared/shapes";
import { ConfiguratorBackendShape } from "../../../../shared/shapes/configuratorBackend.shape";
import { If } from "../../../../shared/components/elements/Conditions";
import FeatureToggle from "../../../../shared/components/elements/FeatureToggle";

const INITIAL_CONFIGURATOR_BACKEND = {
  name: "",
  url: "",
  config: {},
  product_mapping: {},
  config_sealed: false,
  editing_note: ""
};

const ProductMappingField = ({ field, form, ...props }) => {
  const handleChange = (_, { value }) => {
    const { name, id } = field;
    const { setFieldValue } = form;

    setFieldValue(name || id, value);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <ProductMapping onChange={handleChange} value={field.value} {...props} />
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

ProductMappingField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired
};

const BASE_URL = "https://glencoe.sonderwunsch-manager.de";

const metaDataToConfig = metaData => {
  return metaData.rooms.reduce((accu, room) => {
    return {
      ...accu,
      [room.id]: {
        title: capitalize(room.name.replace("-", " ")),
        views: [
          {
            title: capitalize(room.name.replace("-", " ")),
            room: room.id,
            productCategories: room.params.map(({ id }) => id)
          }
        ],
        sections: []
      }
    };
  }, {});
};

const CreateConfiguratorBackendDialog = ({ trigger, onSave }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState(null);
  const [config, setConfig] = useState(null);
  const [configurators, setConfigurators] = useState([]);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    fetch(`${BASE_URL}/api/v1/projects`)
      .then(res => res.json())
      .then(data => {
        setConfigurators(
          data
            .filter(({ id }) => !!id)
            .map(({ id }) => ({
              id,
              url: `${BASE_URL}/${id}`
            }))
        );
      })
      .catch(silentHandleApiRequestErrors);
  }, []);

  useEffect(() => {
    if (value) {
      fetch(`${BASE_URL}/api/v1/projects/${value}/preview`)
        .then(res => res.json())
        .then(data => {
          setConfig(metaDataToConfig(data));
        })
        .catch(silentHandleApiRequestErrors);
    }
  }, [value]);

  const configuratorsById = useMemo(() => {
    return keyBy(configurators, "id");
  }, [configurators]);

  const configuratorOptions = useMemo(() => {
    return configurators.map(c => ({
      key: c.id,
      text: c.id,
      value: c.id
    }));
  }, [configurators]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    return onSave({
      name: value,
      url: configuratorsById[value].url,
      product_mapping: {},
      advanced_config: {},
      config,
      auto_mapping: true
    }).then(() => {
      setSubmitting(false);
      setValue(null);
      handleClose();
    });
  }, [onSave, configuratorsById, config]);

  return (
    <Modal
      open={open}
      size="small"
      trigger={trigger}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="configuratorBackend.actions.new" />
      </Modal.Header>
      <Modal.Content>
        <Dropdown
          placeholder="Auswählen"
          fluid
          search
          selection
          options={configuratorOptions}
          value={value}
          onChange={(_, { value: v }) => {
            setValue(v);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Column textAlign="right" width={16}>
            <Button
              id="submit"
              data-form="product_group"
              positive
              type="submit"
              onClick={onSubmit}
              content={intl.formatMessage({
                id: "meta.actions.save"
              })}
              disabled={!value}
              loading={submitting}
            />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

CreateConfiguratorBackendDialog.propTypes = {
  trigger: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired
};

const ConfiguratorBackendDialog = ({
  configuratorBackend,
  trigger,
  onSave,
  onDelete,
  productGroups
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [isDiverged, setDiverged] = useState(false);
  const [refetchedConfig, setRefetchedConfig] = useState(null);
  const [paramMapping, setParamMapping] = useState({});
  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => {
    const urlParts = configuratorBackend.url?.split("/") || [];
    const slug = urlParts[urlParts.length - 1];
    setOpen(true);
    fetch(`${BASE_URL}/api/v1/projects/${slug}/preview`)
      .then(res => res.json())
      .then(data => {
        setRefetchedConfig(metaDataToConfig(data));
      })
      .catch(silentHandleApiRequestErrors);
  }, []);

  useEffect(() => {
    if (refetchedConfig) {
      setDiverged(!isEqual(configuratorBackend?.config, refetchedConfig));
    }
  }, [refetchedConfig]);

  const applyRefetchedConfig = useCallback(
    (event, setFieldValue) => {
      event.preventDefault();
      if (refetchedConfig) {
        setFieldValue("config", refetchedConfig);
        setDiverged(false);
      }
    },
    [refetchedConfig]
  );

  useEffect(() => {
    if (configuratorBackend.url) {
      fetch(`${configuratorBackend.url}/param_mapping`)
        .then(res => res.json())
        .then(data => {
          setParamMapping(data);
        })
        .catch(silentHandleApiRequestErrors);
    }
  }, [configuratorBackend]);

  const validationScheme = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    ),
    url: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const onSubmit = useCallback(
    values => {
      return onSave(values).then(() => {
        handleClose();
      });
    },
    [onSave]
  );

  const handleDelete = useCallback(() => {
    return onDelete(configuratorBackend.id);
  }, [onDelete, configuratorBackend]);

  const renderDivergeMessage = useCallback(
    setFieldValue => {
      let validJson = true;
      try {
        Object.keys(refetchedConfig);
      } catch (e) {
        validJson = false;
      }
      return (
        <Grid
          style={{
            margin: "0 0 10px 0",
            backgroundColor: "#fffaf3",
            color: "#573a08",
            borderRadius: "0.28rem",
            boxShadow: "0 0 0 1px #c9ba9b inset,0 0 0 0 transparent"
          }}
        >
          <Grid.Column width={12} verticalAlign="middle">
            <strong>
              <FormattedMessage id="configuratorBackend.messages.diverged" />
            </strong>
            <If condition={!validJson}>
              <div>
                <i>Konfiguration weicht von Glencoe ab.</i>
              </div>
            </If>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              fluid
              positive
              disabled={!validJson}
              onClick={event => applyRefetchedConfig(event, setFieldValue)}
            >
              <FormattedMessage id="meta.actions.apply" />
            </Button>
          </Grid.Column>
        </Grid>
      );
    },
    [refetchedConfig]
  );

  return (
    <Formik
      initialValues={configuratorBackend || INITIAL_CONFIGURATOR_BACKEND}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        setDiverged(!isEqual(values?.config, refetchedConfig));
        /* eslint-disable react/display-name */
        return (
          <Modal
            open={open}
            size="small"
            trigger={trigger}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Modal.Header>
              {configuratorBackend.id && (
                <FormattedMessage id="configuratorBackend.actions.edit" />
              )}
              {!configuratorBackend.id && (
                <FormattedMessage id="configuratorBackend.actions.new" />
              )}
            </Modal.Header>
            <Modal.Content scrolling>
              <Form id="configuratorBackendForm">
                <Input
                  id="url"
                  errorPrompt
                  name="url"
                  label={intl.formatMessage({
                    id: "configuratorBackend.attributes.url.label"
                  })}
                  readOnly={!!configuratorBackend.id}
                />

                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "configuratorBackend.attributes.name.label"
                  })}
                  readOnly={!values.url}
                />

                <If condition={!!configuratorBackend.id}>
                  <Tab
                    menu={{ fluid: true }}
                    panes={[
                      {
                        menuItem: "Produktmapping",
                        render: () => (
                          <Tab.Pane>
                            <Field
                              name="product_mapping"
                              label="JSON"
                              component={ProductMappingField}
                              paramMapping={paramMapping}
                              productGroupsList={productGroups}
                              isLoading={() => false}
                            />
                          </Tab.Pane>
                        )
                      },
                      {
                        menuItem: "Produktmapping (JSON)",
                        render: () => (
                          <Tab.Pane>
                            <Field
                              name="product_mapping"
                              label="Produktmapping"
                              component={SemanticFormikJsonEditor}
                              rows={20}
                            />
                          </Tab.Pane>
                        )
                      },
                      {
                        menuItem: (
                          <Menu.Item key="json">
                            Ansichten (JSON){" "}
                            {isDiverged && (
                              <Icon name="warning circle" color="yellow" />
                            )}
                          </Menu.Item>
                        ),
                        render: () => (
                          <Tab.Pane>
                            <If condition={isDiverged} block>
                              <If condition={values.config_sealed}>
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "0 0 10px 0",
                                    backgroundColor: "#fffaf3",
                                    color: "#573a08",
                                    borderRadius: "0.28rem",
                                    boxShadow:
                                      "0 0 0 1px #c9ba9b inset,0 0 0 0 transparent",
                                    padding: "14px",
                                    gap: "1rem"
                                  }}
                                >
                                  <div>
                                    <Icon
                                      name="warning circle"
                                      color="yellow"
                                    />
                                  </div>
                                  <div>
                                    <b>
                                      <FormattedMessage id="configuratorBackend.messages.sealed" />
                                    </b>
                                    <br />
                                    {values.editing_note}
                                  </div>
                                </div>
                              </If>
                              <If condition={!values.config_sealed}>
                                {renderDivergeMessage(setFieldValue)}
                              </If>
                            </If>
                            <Field
                              name="config"
                              label="Hauptkonfiguration"
                              component={SemanticFormikJsonEditor}
                              rows={20}
                            />
                            <Checkbox
                              label={intl.formatMessage({
                                id:
                                  "configuratorBackend.attributes.config_sealed.label"
                              })}
                              name="config_sealed"
                            />
                            <If condition={values.config_sealed} block>
                              <div className="field">
                                <label htmlFor="editing_note">
                                  <FormattedMessage id="configuratorBackend.attributes.editing_note.label" />
                                </label>
                                <TextArea name="editing_note" />
                              </div>
                            </If>
                          </Tab.Pane>
                        )
                      },
                      {
                        menuItem: "Advanced config",
                        render: () => (
                          <Tab.Pane>
                            <Field
                              name="advanced_config"
                              label="Advanced config"
                              component={SemanticFormikJsonEditor}
                              rows={20}
                            />
                          </Tab.Pane>
                        )
                      }
                    ]}
                  />
                </If>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Grid>
                <Grid.Column width={8} verticalAlign="middle">
                  {configuratorBackend.id && (
                    <Button
                      id="delete"
                      color="red"
                      basic
                      data-form="product_group"
                      content={intl.formatMessage({
                        id: "meta.actions.remove"
                      })}
                      loading={isSubmitting}
                      className="left floated element"
                      onClick={handleDelete}
                    />
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={8}>
                  <Button
                    id="submit"
                    data-form="product_group"
                    positive
                    type="submit"
                    onClick={handleSubmit}
                    content={intl.formatMessage({
                      id: "meta.actions.save"
                    })}
                    loading={isSubmitting}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Actions>
          </Modal>
        );
        /* eslint-enable react/display-name */
      }}
    </Formik>
  );
};

ConfiguratorBackendDialog.propTypes = {
  trigger: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  configuratorBackend: ConfiguratorBackendShape.isRequired,
  productGroups: PropTypes.arrayOf(ProductGroupShape).isRequired
};

const ConfiguratorBackendsSegment = ({
  project,
  catalogs,
  configuratorBackends,
  refresh,
  onSave,
  onDelete,
  catalogsResource
}) => {
  const intl = useIntl();
  const [productGroups, setProductGroups] = useState([]);

  useEffect(() => {
    loadProductGroupsForMapping(catalogsResource, catalogs).then(groups => {
      setProductGroups(groups);
    });
  }, [project, catalogs]);

  useEffect(() => {
    refresh();
  }, [project]);

  return (
    <div>
      {configuratorBackends.map(configuratorBackend => (
        <Segment key={configuratorBackend.id}>
          <Grid>
            <Grid.Column width={14}>
              <Header as="h4">{configuratorBackend.name}</Header>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <IsVersionHistoryAccessible>
                <VersionHistoryLink
                  id={configuratorBackend.id}
                  type="ConfiguratorBackend"
                  size="mid"
                />
              </IsVersionHistoryAccessible>
            </Grid.Column>
            <Grid.Column width={1} textAlign="right">
              <ConfiguratorBackendDialog
                onSave={onSave}
                onDelete={onDelete}
                configuratorBackend={configuratorBackend}
                trigger={<Icon name="edit" link />}
                project={project}
                catalogs={catalogs}
                productGroups={productGroups}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      ))}
      <FeatureToggle featureToggleName="ok20">
        <CreateConfiguratorBackendDialog
          onSave={onSave}
          trigger={
            <Button
              fluid
              basic
              content={intl.formatMessage({
                id: "configuratorBackend.actions.new"
              })}
            />
          }
        />
      </FeatureToggle>
    </div>
  );
};

ConfiguratorBackendsSegment.propTypes = {
  catalogsResource: PropTypes.instanceOf(CatalogResource).isRequired,
  project: ProjectWithContentShape.isRequired,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape).isRequired,
  configuratorBackends: PropTypes.arrayOf(ConfiguratorBackendShape).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

export const getConfiguratorBackends = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "configurator_backends", [])
);

const mapStateToProps = (state, { project }) => {
  return {
    configuratorBackends: getConfiguratorBackends(state),
    catalogs: getCatalogsForProject(state, { projectId: project?.id })
  };
};

const mapDispatchToProps = (dispatch, { project }) => {
  const resource = new ConfiguratorBackendsResource(
    dispatch,
    get(project, "id")
  );
  return {
    refresh: () => {
      return resource.fetchAll();
    },
    onSave: r => {
      return resource.save(r).then(data => {
        return resource.fetchAll().then(() => {
          return data;
        });
      });
    },
    onDelete: id => {
      return resource.remove(id).then(data => {
        return resource.fetchAll().then(() => {
          return data;
        });
      });
    },
    growl: new Growl(dispatch),
    catalogsResource: new CatalogResource(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfiguratorBackendsSegment);
