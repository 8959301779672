const contactsMap = contacts => {
  return (contacts || []).reduce((accu, contact) => {
    const text =
      (contact.label &&
        `${contact.label.contact} ${
          contact.label.company ? `(${contact.label.company})` : ""
        }`) ||
      "";
    return {
      ...accu,
      [`${contact.type}-${contact.id}`]: {
        id: contact.id,
        type: contact.type,
        text,
        role: contact.role,
        email: contact.email
      }
    };
  }, {});
};

export default contactsMap;
