import React from "react";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { find } from "lodash";
import apiRequest from "shared/network/apiRequest";
import { getCatalogs } from "shared/selectors";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import ProfileResource from "builder_portal/actions/profileActions";
import CatalogResource from "builder_portal/actions/catalogActions";
import PropTypes from "prop-types";
import { ProjectCatalogShape } from "shared/shapes";

class CopyProjectCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetAccountId: null,
      sourceProjectId: null,
      sourceProjectCatalogId: null,
      targetProjectId: null,
      copyRoomBookTemplates: false,
      error: null,
      success: null,
      loading: false
    };
  }

  componentDidMount() {
    const { projectsResource, catalogsResource } = this.props;
    projectsResource.fetchAll({ shallow: true });
    catalogsResource.fetchAll({ shallow: true });
  }

  handleChange = (e, { name, value, checked }) => {
    this.setState({ [name]: value || checked });
  };

  handleSubmit = () => {
    const {
      sourceProjectId,
      sourceProjectCatalogId,
      targetProjectId,
      copyRoomBookTemplates
    } = this.state;
    const { submit } = this.props;
    this.setState({ loading: true });

    submit(
      sourceProjectId,
      sourceProjectCatalogId,
      targetProjectId,
      copyRoomBookTemplates
    )
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  render() {
    const { profile, catalogs } = this.props;
    const {
      loading,
      error,
      success,
      targetAccountId,
      sourceProjectId
    } = this.state;

    // comes from backend
    // eslint-disable-next-line camelcase
    const accountOptions = profile?.account_memberships.map(account => {
      return {
        key: `project-${account.id}`,
        text: `${account.name}`,
        value: `${account.id}`
      };
    });

    const sourceProjectOptions = find(
      // comes from backend
      // eslint-disable-next-line camelcase
      profile?.account_memberships,
      membership => membership.id === profile?.account.id
    )?.projects?.map(sourceProject => {
      return {
        key: `project-${sourceProject.id}`,
        text: `${sourceProject.name}`,
        value: sourceProject.id
      };
    });

    const sourceCatalogOptions = catalogs
      .filter(c => {
        return c.price_catalogs?.some(pc => {
          return pc.project_ids.includes(sourceProjectId);
        });
      })
      .map(c => {
        return {
          key: `project-catalog-${c.id}`,
          text: `${c.name}`,
          value: c.id
        };
      });

    const targetProjectOptions = find(
      // comes from backend
      // eslint-disable-next-line camelcase
      profile?.account_memberships,
      membership => membership.id.toString() === targetAccountId
    )?.projects?.map(targetProject => {
      return {
        key: `project-${targetProject.id}`,
        text: `${targetProject.name}`,
        value: `${targetProject.id}`
      };
    });

    return (
      <div>
        <h2>Copy Product Catalog</h2>
        <p>
          This will copy the entire product catalog from one project to another.
        </p>
        <p>
          <strong>WARNINGS:</strong>
          <ul>
            <li>
              The target product catalog must be empty as it overrides all
              products!
            </li>
            <li>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              This is run in the background so you won't see the result
              immediately.
            </li>
            <li>
              For security reasons you have to be account and project member in
              target and source projects.
            </li>
          </ul>
          <p>
            <strong>What gets copied:</strong>
            <ul>
              <li>All Product Groups</li>
              <li>All Product Rules</li>
              <li>All Product Prices and Costs</li>
              <li>Buyer Portal Config (incl. mapping for 3D configurator)</li>
              <li>RoomBook Templates (if enabled!)</li>
            </ul>
          </p>
        </p>
        <Form
          loading={loading}
          success={success}
          error={!!error}
          onSubmit={this.handleSubmit}
        >
          <Message
            success
            header="Success!"
            content="Please check the results!"
          />
          <Message error header="Error" content={error} />

          <div className="four wide field">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Source Account</label>
            <p>
              {profile?.account?.name} ({profile?.account?.id})
            </p>
          </div>

          <Form.Select
            name="sourceProjectId"
            options={sourceProjectOptions ?? []}
            onChange={this.handleChange}
            label="Source Project"
            placeholder="Source Project"
            required
            width="4"
          />

          <Form.Select
            name="sourceProjectCatalogId"
            options={sourceCatalogOptions ?? []}
            onChange={this.handleChange}
            label="Source Project Catalog"
            placeholder="Source Project Catalog"
            required
            width="4"
          />

          <Form.Select
            name="targetAccountId"
            options={accountOptions ?? []}
            onChange={this.handleChange}
            label="Target Account"
            placeholder="Target Account"
            required
            width="4"
          />

          <Form.Select
            name="targetProjectId"
            options={targetProjectOptions ?? []}
            onChange={this.handleChange}
            label="Target Project"
            placeholder="Target Project"
            required
            width="4"
          />

          <Form.Checkbox
            name="copyRoomBookTemplates"
            onChange={this.handleChange}
            label="Copy RoomBook Templates?"
          />

          <Form.Button width="4">Submit</Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  profile: state.account,
  catalogs: getCatalogs(state)
});

const getSubmit = dispatch => (
  sourceProjectId,
  sourceProjectCatalogId,
  targetProjectId,
  copyRoomBookTemplates
) =>
  apiRequest.post("/api/v1/support_utils/copy_project_catalog", dispatch, {
    project: {
      source_id: sourceProjectId,
      source_project_catalog_id: sourceProjectCatalogId,
      target_id: targetProjectId,
      copy_room_book_templates: copyRoomBookTemplates
    }
  });

const mapDispatchToProps = dispatch => ({
  profileResource: new ProfileResource(dispatch),
  projectsResource: new ProjectsResource(dispatch),
  catalogsResource: new CatalogResource(dispatch),
  submit: getSubmit(dispatch)
});

CopyProjectCatalog.propTypes = {
  projectsResource: PropTypes.instanceOf(ProjectsResource).isRequired,
  catalogsResource: PropTypes.instanceOf(CatalogResource).isRequired,
  submit: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(ProfileResource).isRequired,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape)
};

CopyProjectCatalog.defaultProps = {
  catalogs: []
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyProjectCatalog);
