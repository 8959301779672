import {
  arrayOf,
  bool,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string
} from "prop-types";

export const ProductLineTagDescriptionShape = shape({
  id: string, // one of `tags` value
  displayName: string,
  description: string,
  images: arrayOf(string) // urls for image
});
export const ProductLineShape = shape({
  id: oneOfType([number, string]),
  composite_id: string,
  name: string,
  name_plural: string,
  mode: oneOf(["recommend", "silent", "warn", "required"]),
  exclusive: bool,
  scope: oneOf(["roombook", "section", "sub_section"]),
  warning: string,
  tags: arrayOf(string), // syntax: "id:displayedName" i.E. "equipment_line:trend"
  tagDescriptions: objectOf(ProductLineTagDescriptionShape)
});

export default ProductLineShape;
