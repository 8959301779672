import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Icon,
  Button,
  Grid,
  Radio,
  Form,
  Segment,
  Checkbox,
  Header,
  Label,
  GridRow,
  Message,
  Popup
} from "semantic-ui-react";
import { getProjectSetupParameters } from "builder_portal/selectors/projectSetupParameters";
import ProjectAnalysisTopicResource from "builder_portal/actions/projectAnalyssisTopicActions";
import silentHandleApiRequestErrors from "shared/helpers/silentHandleApiRequestErrors";
import { If } from "shared/components/elements/Conditions";
import { getUser } from "shared/selectors";
import { getUsers } from "shared/selectors/account";
import { keyBy } from "lodash";
import { object, func, bool } from "prop-types";
import { v4 as uuid } from "uuid";
import moment from "moment";
import WysiwygEditor from "shared/components/elements/wysiwyg/WysiwygEditor";
import DOMPurify from "dompurify";
import FileUploadDialog from "./FileUploadDialog";
import ProjectAnalysisTopicDialog from "./ProjectAnalysisTopicDialog";

const ICONS = {
  done: { name: "check circle", color: "green" },
  notStarted: { name: "dot circle", color: "red" }
};

const getActiveEdits = (editMode, currentKey, value) => {
  const temp = {};
  Object.keys(editMode).forEach(key => {
    // eslint-disable-next-line eqeqeq
    if (key == currentKey && !value) return;
    if (editMode[key]) temp[key] = true;
  });
  if (value) temp[currentKey] = true;
  return temp;
};

const Content = ({ tab, saveParameter, deleteParameter, isInEditMode }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [editMode, setEditMode] = useState({});
  const [error, setError] = useState({});
  const [touched, setTouched] = useState({});
  const originalParams = useSelector(getProjectSetupParameters);
  const [allParams, setAllParams] = useState(originalParams);
  const currentUser = useSelector(getUser);
  const users = keyBy(useSelector(getUsers), "id");

  const analysisOptions = useMemo(() => {
    const topicIds = tab.items.map(topic => ({
      id: topic.id,
      key: topic.technical_reference
    }));
    const temp = {};
    topicIds.forEach(topicId => {
      const completed = originalParams.filter(
        x => x.completes_topic && x.project_analysis_topic_id === topicId.id
      );
      if (completed.length)
        temp[topicId.key] = completed[completed.length - 1].value;
    });
    return temp;
  }, [tab, originalParams]);

  useEffect(() => {
    setEditMode({});
    setError({});
    setTouched({});
    setAllParams(originalParams);
  }, [tab]);

  useEffect(() => {
    setAllParams(originalParams);
  }, [originalParams]);

  const handleChange = (value, paramId) => {
    const temp = [...allParams];
    // eslint-disable-next-line eqeqeq
    temp.find(x => x.id == paramId).note = value;
    if (touched[paramId] && error[paramId] && value.trim().length > 0)
      setError({ ...error, [paramId]: false });
    setAllParams(temp);
  };

  const handleAddFile = (paramId, file) => {
    const temp = [...allParams];
    // eslint-disable-next-line eqeqeq
    const param = temp.find(x => x.id == paramId);
    if (!param.project_setup_parameter_attachments_attributes)
      param.project_setup_parameter_attachments_attributes = [];
    param.project_setup_parameter_attachments_attributes.push({ file });
    // prview
    if (!param.project_setup_parameter_attachments)
      param.project_setup_parameter_attachments = [];
    param.project_setup_parameter_attachments.push({
      file_file_name: file.name,
      new: true,
      id: uuid()
    });
    setAllParams(temp);
  };

  const handleRemoveFile = (paramId, file) => {
    const temp = [...allParams];
    // eslint-disable-next-line eqeqeq
    const param = temp.find(x => x.id == paramId);
    // remove from preview
    param.project_setup_parameter_attachments = param.project_setup_parameter_attachments.filter(
      x => x.id !== file.id
    );
    if (file.new) {
      // remove from storing array
      param.project_setup_parameter_attachments_attributes = param.project_setup_parameter_attachments_attributes.filter(
        x => !(x.file?.name === file.file_file_name && file.new)
      );
    } else {
      if (!param.project_setup_parameter_attachments_attributes)
        param.project_setup_parameter_attachments_attributes = [];
      param.project_setup_parameter_attachments_attributes.push({
        id: file.id,
        destroy: true
      });
    }
    setAllParams(temp);
  };

  const handleChangeRadio = (e, { value }) => {
    const paramId = e.target.closest(".checkbox").getAttribute("paramid");
    const temp = [...allParams];
    // eslint-disable-next-line eqeqeq
    temp.find(x => x.id == paramId).value = value;
    setAllParams(temp);
  };

  const handleCompleteTopic = (e, { checked }) => {
    const paramId = e.target.closest(".checkbox").getAttribute("paramid");
    const temp = [...allParams];
    // eslint-disable-next-line eqeqeq
    temp.find(x => x.id == paramId).completes_topic = checked;
    setAllParams(temp);
  };

  const handleSubmit = id => {
    // eslint-disable-next-line eqeqeq
    const param = allParams.find(x => x.id == id);
    if (!param.note.trim()) {
      setError({ [id]: true });
      setTouched({ [id]: true });
      return;
    }

    saveParameter(param)
      .then(() => setEditMode(getActiveEdits(editMode, id)))
      .catch(silentHandleApiRequestErrors);

    if (error[id]) setError({ [id]: false });
  };

  const handleDelete = id => {
    // eslint-disable-next-line eqeqeq
    setAllParams(allParams.filter(x => x.id != id));
    deleteParameter(id);
  };

  const renderParam = (param, item, isLastParam) => {
    if (param.user_id === currentUser.id) {
      if (editMode[param.id]) {
        const hasOptions = item.project_analysis_topic_options.length > 0;
        return (
          <Segment attached key={param.id}>
            <Form>
              <Grid>
                <GridRow className="gridRow">
                  <Grid.Column
                    width={hasOptions ? 12 : 16}
                    verticalAlign="bottom"
                    style={{ padding: "1rem" }}
                  >
                    <WysiwygEditor
                      value={param.note}
                      onChange={({ value }) => handleChange(value, param.id)}
                      name="note"
                    />
                    {param.project_setup_parameter_attachments?.map(f => (
                      <Label
                        key={f.id}
                        color={f.new ? "gray" : "blue"}
                        style={{ margin: "2px 2px 0 0" }}
                      >
                        {f.file_file_name}
                        <Icon
                          name="delete"
                          onClick={() => handleRemoveFile(param.id, f)}
                        />
                      </Label>
                    ))}
                    <FileUploadDialog
                      handleAddFile={handleAddFile}
                      paramId={param.id}
                    />
                  </Grid.Column>
                  <If condition={hasOptions}>
                    <Grid.Column width={4}>
                      <div style={{ padding: "1.5rem 1rem 0" }}>
                        <Header as="h5">Optionen</Header>
                        {item.project_analysis_topic_options.map(option => {
                          return (
                            <Form.Field key={option.id}>
                              <Radio
                                name="project_setup_parameter_value"
                                label={option.label}
                                value={option.value}
                                paramid={param.id}
                                checked={param.value === option.value}
                                onChange={handleChangeRadio}
                              />
                            </Form.Field>
                          );
                        })}
                      </div>
                    </Grid.Column>
                  </If>
                </GridRow>
                <GridRow>
                  <Grid.Column width={6} verticalAlign="middle">
                    <If condition={isLastParam}>
                      <Checkbox
                        paramid={param.id}
                        checked={param.completes_topic}
                        name="completes_topic"
                        label={intl.formatMessage({
                          id:
                            "project.project_setup.dialog.complete_topic.label"
                        })}
                        onChange={handleCompleteTopic}
                        disabled={
                          !!(
                            item.project_analysis_topic_options.length &&
                            !allParams.find(x => x.id === param.id).value
                          )
                        }
                      />
                    </If>
                  </Grid.Column>
                  <Grid.Column
                    width={10}
                    verticalAlign="middle"
                    textAlign="right"
                  >
                    <Button
                      basic
                      content="Abbrechen"
                      onClick={() => {
                        if (param.new) {
                          setAllParams(allParams.filter(x => x.id && !x.new));
                        } else {
                          const originalParam = originalParams.find(
                            x => x.id === param.id
                          );
                          const temp = [...allParams];
                          // eslint-disable-next-line eqeqeq
                          const tempParam = temp.find(x => x.id == param.id);
                          tempParam.note = originalParam.note;
                          tempParam.value = originalParam.value;
                          tempParam.completes_topic =
                            originalParam.completes_topic;
                          tempParam.project_setup_parameter_attachments = param.project_setup_parameter_attachments.filter(
                            x => !x.new
                          );
                          tempParam.project_setup_parameter_attachments_attributes = [];
                          setAllParams(temp);
                        }
                        setEditMode(getActiveEdits(editMode, param.id));
                      }}
                    />
                    <Button
                      primary
                      content="Speichern"
                      onClick={() => handleSubmit(param.id)}
                    />
                  </Grid.Column>
                </GridRow>
              </Grid>
            </Form>
          </Segment>
        );
      }
    }

    const paramLabel =
      isLastParam &&
      !!param.value &&
      tab.items
        .find(x => x.id === param.project_analysis_topic_id)
        ?.project_analysis_topic_options.find(x => x.value === param.value)
        ?.label;
    return (
      <Segment key={param.id} attached>
        <Grid className="commentContainer">
          <Grid.Column width={12}>
            <div>
              <strong>
                {users[param.user_id]?.first_name}{" "}
                {users[param.user_id]?.last_name}{" "}
                {moment(param.updated_at).format("lll")}
              </strong>
              <If condition={param.user_id === currentUser.id}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                <a
                  role="button"
                  style={{ marginLeft: "2rem" }}
                  onClick={() =>
                    setEditMode(getActiveEdits(editMode, param.id, true))
                  }
                >
                  Bearbeiten
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                <a
                  role="button"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => handleDelete(param.id)}
                >
                  Löschen
                </a>
              </If>
            </div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(param.note)
              }}
            />
            {param.project_setup_parameter_attachments?.map(f => (
              <a href={f.file_url} target="_blank" rel="noreferrer" key={f.id}>
                <Label basic style={{ margin: "2px 2px 0 0" }}>
                  {f.file_file_name}
                </Label>
              </a>
            ))}
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <If condition={isLastParam && !!param.value}>
              <Label size="large" color="black">
                {paramLabel}
              </Label>
            </If>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  const handleMove = (topic, direction) => {
    const resource = new ProjectAnalysisTopicResource(dispatch);
    resource
      .save({
        id: topic.id,
        position: direction === "up" ? topic.position - 1 : topic.position + 1
      })
      .then(() => resource.fetchAll())
      .catch(silentHandleApiRequestErrors);
  };

  return (
    <Container>
      {tab.items.map((topic, tindex) => {
        const { requirements } = topic;

        const shouldBeRendered = requirements
          ? Object.keys(requirements).every(
              key => analysisOptions[key] === requirements[key]
            )
          : true;

        if (!shouldBeRendered && !isInEditMode) return null;

        const params = allParams.filter(
          p => p.project_analysis_topic_id === topic.id
        );

        const lastParam = params[params.length - 1];

        const status = lastParam?.completes_topic ? "done" : "notStarted";

        const optionDesription =
          status === "done" &&
          topic.project_analysis_topic_options.find(
            x => x.value === lastParam.value
          )?.description;

        return (
          <Segment.Group key={topic.id}>
            <Header as="h5" attached="top" className="titleRow">
              <Icon
                name={ICONS[status].name}
                color={ICONS[status].color}
                size="large"
              />
              <div>
                {topic.summary}
                <If
                  condition={
                    !shouldBeRendered && isInEditMode && !!topic.requirements
                  }
                >
                  <Popup
                    content={
                      <FormattedMessage
                        id="project.project_setup.dialog.filter_message.label"
                        values={{
                          reference:
                            topic.requirements &&
                            Object.keys(topic.requirements)?.[0],
                          value:
                            topic.requirements &&
                            topic.requirements[
                              Object.keys(topic.requirements)?.[0]
                            ]
                        }}
                      />
                    }
                    trigger={
                      <Icon
                        name="filter"
                        color="grey"
                        style={{ marginLeft: "0.8rem" }}
                      />
                    }
                  />
                </If>
              </div>
              <If condition={isInEditMode}>
                <div style={{ flexGrow: 1, textAlign: "right" }}>
                  <If condition={tindex > 0}>
                    <Icon
                      role="button"
                      name="angle up"
                      size="large"
                      onClick={() => handleMove(topic, "up")}
                    />
                  </If>
                  <If condition={tindex < tab.items.length - 1}>
                    <Icon
                      role="button"
                      name="angle down"
                      size="large"
                      onClick={() => handleMove(topic, "down")}
                    />
                  </If>
                  <ProjectAnalysisTopicDialog
                    button={<Icon role="button" name="setting" size="large" />}
                    model={topic}
                    tab={tab}
                  />
                </div>
              </If>
            </Header>

            <If condition={shouldBeRendered} block>
              <Segment attached className="descriptionRow">
                {topic.description}
              </Segment>

              {params.map((param, index) =>
                renderParam(param, topic, params.length === index + 1)
              )}
              <Segment attached style={{ textAlign: "center" }}>
                <Button
                  basic
                  disabled={!!Object.keys(editMode).length}
                  style={{ margin: "1rem 0", width: "90%" }}
                  onClick={() => {
                    const tempId = uuid();
                    setAllParams(
                      [...allParams].concat({
                        id: tempId,
                        new: true,
                        project_analysis_topic_id: topic.id,
                        note: "",
                        value: null,
                        user_id: currentUser.id
                      })
                    );
                    setEditMode(getActiveEdits(editMode, tempId, true));
                  }}
                >
                  <FormattedMessage
                    id="project.project_setup.dialog.add_comment.label"
                    default="Notiz hinzufügen"
                  />
                </Button>
              </Segment>
              <If condition={!!optionDesription}>
                <Message
                  info
                  attached
                  icon="info circle"
                  header={intl.formatMessage({
                    id:
                      "project.project_setup.analysisTopics.dialog.editingNote"
                  })}
                  content={optionDesription}
                  className="closingInfo"
                />
              </If>
            </If>
          </Segment.Group>
        );
      })}
      <If condition={tab.items.length === 0}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "700",
            padding: "1rem 0"
          }}
        >
          <FormattedMessage
            id="project.project_setup.dialog.no_topics.label"
            default="There are no params for this topic."
          />
        </div>
      </If>
      <Segment>
        <div style={{ width: "100%", textAlign: "center" }}>
          <ProjectAnalysisTopicDialog
            tab={tab}
            button={
              <Button basic style={{ width: "40%" }}>
                <FormattedMessage
                  id="project.project_setup.analysisTopics.new.label"
                  default="Label"
                />
              </Button>
            }
          />
        </div>
      </Segment>
    </Container>
  );
};

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tab: object.isRequired,
  saveParameter: func.isRequired,
  deleteParameter: func.isRequired,
  isInEditMode: bool.isRequired
};

export default Content;
