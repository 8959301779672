import React from "react";
import { Modal } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

import "./htmlMessageDialog.scss";
import MessageBodyV1 from "./MessageBodyV1";

const HtmlMessageDialog = props => {
  const { message } = props;
  const { subject, body_html, body_text } = message;
  const text = body_html || (body_text || "").replaceAll(/\n/g, "<br/>");
  return (
    <Modal
      closeIcon
      trigger={
        <a role="link">
          <FormattedMessage
            id="messaging.message_container.buttons.showOriginal"
            defaultMessage="messaging.message_container.buttons.showOriginal"
          />
        </a>
      }
    >
      <Modal.Header>
        <MessageBodyV1 text={subject} whiteSpace="unset" />
      </Modal.Header>
      <Modal.Content scrolling>
        <div className="htmlMessageContainer">
          <MessageBodyV1 text={text} whiteSpace="unset" />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default HtmlMessageDialog;
