import React from "react";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { Header, Loader } from "semantic-ui-react";
import PropTypes, { arrayOf } from "prop-types";
import ProjectDeadlinesSegment from "../projectDeadlines/ProjectDeadlinesSegment";
import DeadlineShape from "../../../shared/shapes/deadline.shape";

class UnitDeadlines extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.fetchDeadlines = this.fetchDeadlines.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    this.fetchDeadlines();
  }

  fetchDeadlines() {
    const { actions } = this.props;

    return actions.deadlines.fetchAll({ view: "unit" });
  }

  handleUpdate() {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
    return this.fetchDeadlines();
  }

  render() {
    const { deadlines } = this.props;

    const unit = get(deadlines, "unit");
    if (unit) {
      return (
        <div>
          <div>
            <Header>
              <FormattedMessage
                id="unit.tabs.deadlines"
                defaultMessage="unit.tabs.deadlines"
              />
            </Header>
          </div>
          <ProjectDeadlinesSegment
            key={`unit::${unit.id}`}
            i18n={this.props.i18n}
            item={unit}
            itemType="unit"
            resourceProvider={this.getResource.bind(this)}
            onUpdate={this.handleUpdate}
          />
        </div>
      );
    }
    return <Loader active />;
  }

  getResource() {
    return this.props.actions.deadlines;
  }
}

UnitDeadlines.propTypes = {
  i18n: PropTypes.object,
  account: PropTypes.object,
  deadlines: arrayOf(DeadlineShape),
  actions: PropTypes.object,
  onUpdate: PropTypes.func
};

export default UnitDeadlines;
