import { createSelector } from "reselect";
import { get } from "lodash";

import { getPageContent } from "shared/selectors/base";

export const getEmailDeliveries = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "email_deliveries", [])
);

export const getEmailDeliveryStatistics = createSelector(
  [getPageContent],
  pageContent => get(pageContent, "email_delivery_statistics", [])
);
