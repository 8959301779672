import PropTypes from "prop-types";
import React from "react";
import { Icon, Message } from "semantic-ui-react";
import "./fewResultsMessage.scss";

class FewResultsMessage extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    handleClick: PropTypes.func,
    amount: PropTypes.number
  };

  getMessage() {
    const { amount, i18n } = this.props;

    if (amount === 0) {
      return {
        title: i18n["product_price.search.results.none.title"],
        content: i18n["product_price.search.results.none.content"]
      };
    }

    if (amount <= 5) {
      return {
        title: i18n["product_price.search.results.few.title"],
        content: i18n["product_price.search.results.few.content"]
      };
    }
  }

  render() {
    const { amount, handleClick } = this.props;
    if (amount > 5) {
      return null; // no message
    }

    const message = this.getMessage();

    return (
      <Message data-component="fewResultsMessage" icon color="blue">
        <Icon name="meh" />
        <Message.Content>
          {message.title}
          <div className="action">
            <Icon name="search" />
            <u onClick={handleClick}>{message.content}</u>
          </div>
        </Message.Content>
      </Message>
    );
  }
}

export default FewResultsMessage;
