import React, { useContext, useMemo } from "react";
import { connect } from "react-redux";
import { arrayOf, string, number, oneOfType } from "prop-types";
import { Image, Header, Grid, Icon } from "semantic-ui-react";
import { useIntl } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { DesignLineGroupShape } from "shared/shapes";
import { If } from "shared/components/elements/Conditions";
import { I18nShape } from "shared/shapes/i18n.shape";
import { getI18N } from "shared/selectors";
import { sortBy } from "lodash";
import DesignLineDialog from "./DesignLineDialog";
import { DesignLinesLoaderContext } from "./DesignLinesLoader";
import ImageDropzone from "../dropzone/ImageDropzone";

const DesignLineItems = ({ designLineGroup, i18n }) => {
  const {
    destroyDesignLine,
    loadData,
    loading,
    catalogId,
    saveDesignLine
  } = useContext(DesignLinesLoaderContext);
  const intl = useIntl();

  const sortedDesignLines = useMemo(() => {
    const { design_lines } = designLineGroup;
    if (!design_lines) return [];

    return sortBy(design_lines, "position");
  }, [designLineGroup]);

  const renderDeleteButton = id => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="design_line_group-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          destroyDesignLine(id, designLineGroup.id)
            .then(handleClose)
            .catch(handleClose);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="design_lines.removeConfirmation.title"
        message="design_lines.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const onUpdate = () => {
    loadData();
  };

  const handleMove = (line, direction) => {
    if (loading) return;
    const position = direction === "up" ? line.position - 1 : line.position + 1;
    saveDesignLine({ ...line, position }, designLineGroup.id);
  };

  return sortedDesignLines.map((line, index) => (
    <Grid stackable verticalAlign="middle" key={line.id}>
      <Grid.Row>
        <Grid.Column width="3">
          <If condition={!!line.image}>
            <Image src={line.image} alt={line.name} fluid />
          </If>
          <If condition={!line.image}>
            <ImageDropzone
              key={line.id}
              title={i18n["design_lines.uploadImage"]}
              className="designLineDropZone"
              resourceName={`project_catalogs/${catalogId}/design_line_groups`}
              resourceId={designLineGroup.id}
              handleSuccess={() => onUpdate(line)}
              i18n={i18n}
              options={{ path: `design_lines/${line.id}/image` }}
              paramName="design_line[image]"
            />
          </If>
        </Grid.Column>
        <Grid.Column width="9">
          <Header as="h4">{line.name}</Header>
          <div>{line.description}</div>
        </Grid.Column>
        <Grid.Column width="1" textAlign="right">
          <If condition={index > 0}>
            <Icon
              style={{ cursor: "pointer" }}
              name="arrow up"
              color="grey"
              onClick={() => handleMove(line, "up")}
            />
          </If>
        </Grid.Column>
        <Grid.Column width="1" textAlign="right">
          <If condition={index < sortedDesignLines.length - 1}>
            <Icon
              style={{ cursor: "pointer" }}
              name="arrow down"
              color="grey"
              onClick={() => handleMove(line, "down")}
            />
          </If>
        </Grid.Column>

        <Grid.Column width="1" textAlign="right">
          <DesignLineDialog
            model={line}
            groupId={designLineGroup.id}
            button={
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="button"
                id="design_line-edit"
                data-tooltip={intl.formatMessage({
                  id: "meta.actions.edit"
                })}
                disabled={loading}
              >
                <Icon name="setting" color="grey" />
              </a>
            }
          />
        </Grid.Column>
        <Grid.Column width="1" textAlign="right">
          {renderDeleteButton(line.id)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  ));
};

DesignLineItems.propTypes = {
  designLineGroups: arrayOf(DesignLineGroupShape),
  catalogId: oneOfType([number, string]),
  i18n: I18nShape.isRequired
};

const mapStateToProps = state => {
  return {
    i18n: getI18N(state)
  };
};

export default connect(mapStateToProps)(DesignLineItems);
