// todo: How are we handling this?
/* eslint-disable max-classes-per-file */
import apiRequest from "shared/network/apiRequest";
import Resources from "shared/actions/resourcesActions";
import Resource from "../../shared/actions/resourceActions";

export class ProjectImagesResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "image", `/api/v1/projects/${projectId}/images`);
    this.refreshPageContentAfterCommit = true;
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }
}

export class ProjectCardImagesResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "image",
      `/api/v1/projects/${projectId}/card_images`,
      `project_${projectId}_card_images`
    );
    this.refreshPageContentAfterCommit = true;
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }
}

export class ProjectImageResource extends Resource {
  constructor(dispatch, projectId) {
    super(dispatch, "image", `/api/v1/projects/${projectId}/images`);
    this.refreshPageContentAfterCommit = true;
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }
}

export class ProjectImageLibraryResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "image",
      `/api/v1/projects/${projectId}/images?role=project_image`
    );
  }
}

export class ProjectResource extends Resource {
  constructor(dispatch, projectId) {
    // todo: make this work with the endpoint
    super(
      dispatch,
      "project",
      `/api/v1/projects/${projectId}`,
      `project_${projectId}`
    );
    this.refreshPageContentAfterCommit = true;
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }
}
