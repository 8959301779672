import Resources from "shared/actions/resourcesActions";

export class ProjectDataExportsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_data_export",
      `/api/v1/project_data_exports?project_id=${projectId}`
    );
  }
}

export default ProjectDataExportsResource;
