/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { browserHistory } from "shared/routes/browserHistory";
import FormatNumber from "shared/components/numbers/FormatNumber";
import { string, array, object, number, oneOfType } from "prop-types";
import ProductSection from "./ProductSection";
import "./styles.scss";

const ProductItem = ({
  product,
  sections,
  units,
  projectId,
  params,
  sectionId
}) => {
  const [details, setDetails] = React.useState(false);
  const [productQuantities, setProductQuantities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const groupedData = React.useMemo(() => {
    return sections.map(section => {
      return {
        ...section,
        units: units.filter(unit => unit.section_id === section.id)
      };
    });
  }, [sections, units]);

  useEffect(() => {
    if (details && !hasFetched) {
      setLoading(true);
      const filteredSectins = groupedData.filter(section =>
        !sectionId ? true : section.id === sectionId
      );

      const fetchPromises = filteredSectins.map(section => {
        const sectionParam = sectionId ? "" : `&section_id=${section.id}`;
        let url = `/api/v1/projects/${projectId}/product_quantities?product_id=${product.id}${sectionParam}`;

        Object.keys(params).forEach(key => {
          url += `&${key}=${params[key]}`;
        });

        return fetch(url).then(response => response.json());
      });
      Promise.all(fetchPromises)
        .then(responses => {
          setHasFetched(true);
          const concatenatedResponse = responses.flatMap(
            response => response.product_quantities
          );
          setProductQuantities(concatenatedResponse);
        })
        .finally(() => setLoading(false));
    }
  }, [details, projectId, params, groupedData]);

  // find the section name by id
  const getSectionName = id => {
    const section = sections.find(sectionItem => sectionItem.id === id);
    return section.name;
  };

  const getSectionSlug = id => {
    const section = sections.find(sectionItem => sectionItem.id === id);
    return section.slug;
  };

  // get units in groupedData by section_id
  const getUnitsBySectionId = id => {
    const section = groupedData.find(sectionItem => sectionItem.id === id);
    return section?.units || [];
  };

  return (
    <>
      <tr key={product.id} id={`product-${product.id}`}>
        <td colSpan="1">
          {isLoading ? (
            <Icon name="spinner" loading />
          ) : !details ? (
            <Icon
              onClick={e => {
                e.stopPropagation();
                setDetails(!details);
              }}
              name="plus square outline"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Icon
              onClick={e => {
                e.stopPropagation();
                setDetails(!details);
              }}
              name="minus square outline"
            />
          )}
        </td>
        <td colSpan="3">
          <span
            style={{
              maxWidth: "320px"
            }}
            className="productFields"
          >
            {product.name}
          </span>
        </td>
        <td colSpan="2">
          <span
            style={{
              maxWidth: "220px"
            }}
            className="productFields"
          >
            {product.supplier || "-"} {product.series || "-"}
          </span>
        </td>
        <td colSpan="2">
          <span
            style={{
              maxWidth: "120px"
            }}
            className="productFields"
          >
            {product.sku}
          </span>
        </td>
        <td colSpan="1" style={{ textAlign: "right" }} className="width-100">
          <FormatNumber value={product.quantity} />
        </td>
        <td colSpan="1" style={{ textAlign: "center" }} className="width-70">
          <a
            href={`/products/${product.supplier_id}/${product.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="external" />
          </a>
        </td>
      </tr>
      {details &&
        productQuantities.map(quantity => (
          <ProductSection
            key={quantity.section_id}
            sectionQuantity={quantity}
            sectionName={getSectionName(quantity.section_id)}
            units={getUnitsBySectionId(quantity.section_id)}
            product={product}
            projectId={projectId}
            onClick={() =>
              browserHistory.push(
                `/products/${getSectionSlug(quantity.section_id)}/${product.id}`
              )
            }
            params={params}
            sectionId={sectionId}
          />
        ))}
    </>
  );
};

ProductItem.propTypes = {
  product: object.isRequired,
  sections: array.isRequired,
  units: array.isRequired,
  projectId: string.isRequired,
  params: object,
  sectionId: oneOfType([number, string])
};

ProductItem.defaultProps = {
  params: {},
  sectionId: undefined
};
export default ProductItem;
