import PropTypes from "prop-types";
import React from "react";
import { Loader } from "semantic-ui-react";

import { connect } from "react-redux";
import {
  AccountProjectsResource,
  AccountProjectConfigsResource
} from "builder_portal/actions/accountActions";
import ProjectSettingsPage from "./ProjectSettingsPage";

class ProjectSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  static propTypes = {
    projectsResource: PropTypes.instanceOf(AccountProjectsResource),
    projectConfigsResource: PropTypes.instanceOf(AccountProjectConfigsResource)
  };

  componentDidMount() {
    const { projectsResource, projectConfigsResource } = this.props;
    projectsResource
      .fetchAll()
      .then(() => projectConfigsResource.fetchAll())
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) return <Loader active />;
    return <ProjectSettingsPage />;
  }
}

const mapDispatchToProps = dispatch => ({
  projectsResource: new AccountProjectsResource(dispatch),
  projectConfigsResource: new AccountProjectConfigsResource(dispatch)
});

export default connect(null, mapDispatchToProps)(ProjectSettings);
