import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { TextArea, Icon, Dropdown, Header, Table } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { getAccount } from "shared/selectors";
import { func, object } from "prop-types";

import "builder_portal/components/account/documents/documentExport.scss";

const CHARS_IN_SHORT_HINT_VIEW = 275;

const getShortenHintText = text => {
  if (text.length > CHARS_IN_SHORT_HINT_VIEW)
    return `${text.substr(text, CHARS_IN_SHORT_HINT_VIEW)}...`;
  return text.substr(text, CHARS_IN_SHORT_HINT_VIEW);
};

const TradeHintsSelector = ({ value, setTradeHints }) => {
  const intl = useIntl();
  const account = useSelector(getAccount);
  const [hintValue, setHintValue] = useState("");
  const [editingTrade, setEditingTrade] = useState();

  const tradeHints = useMemo(() => value, [value]);
  const trades = useMemo(
    () =>
      account
        .getTrades()
        .filter(x => Object.keys(tradeHints).indexOf(x.id) === -1),
    [account, value]
  );

  const tradeOptions = useMemo(() => {
    return trades.map(trade => ({
      key: trade.id,
      value: trade.id,
      text: `${trade.label} (${trade.id})`
    }));
  }, [trades]);

  useEffect(() => setHintValue(value[editingTrade]), [editingTrade]);

  const updateTradeHints = useCallback(
    (tradeId, hintVal) => {
      const edited = { ...tradeHints, [tradeId]: hintVal };
      setTradeHints(edited);
    },
    [tradeHints]
  );

  const removeTradeHint = useCallback(
    tradeId => {
      const temp = { ...tradeHints };
      delete temp[tradeId];
      setTradeHints(temp);
    },
    [tradeHints]
  );

  const handleAddSelected = (_, { value: tradeId }) => {
    if (tradeId) updateTradeHints(tradeId, "");
  };

  const handleHintChange = (_, { value: tradeId }) => {
    setHintValue(tradeId);
  };

  const handleElementRemove = tradeId => {
    removeTradeHint(tradeId);
  };

  const renderRemoveButton = tradeId => {
    const deleteButton = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="product_group-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          handleElementRemove(tradeId);
          closeConfirmation();
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="documentsExport.dialogs.exports.tradeHints.removeConfirmation.title"
        message="documentsExport.dialogs.exports.tradeHints.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  const renderActions = tradeId => {
    return (
      <>
        <Table.Cell width={1} className="element-border">
          <Icon
            style={{
              display: editingTrade !== tradeId ? "block" : "none"
            }}
            name="edit"
            color="grey"
            onClick={() => {
              setEditingTrade(tradeId);
            }}
          />
          <Icon
            style={{
              display: editingTrade === tradeId ? "block" : "none"
            }}
            name="check"
            color="grey"
            onClick={() => {
              updateTradeHints(tradeId, hintValue);
              setEditingTrade();
            }}
          />
        </Table.Cell>

        <Table.Cell width={1} className="element-border">
          {renderRemoveButton(tradeId)}
        </Table.Cell>
      </>
    );
  };

  return (
    <div>
      <Header as="h5" style={{ marginBottom: "5px" }}>
        <FormattedMessage id="documentsExport.dialogs.exports.tradeHints.title" />
      </Header>
      <div>
        <FormattedMessage id="documentsExport.dialogs.exports.tradeHints.add" />
      </div>
      <Dropdown
        selection
        search
        fluid
        options={tradeOptions}
        onChange={handleAddSelected}
      />

      <Table style={{ border: "none" }}>
        <Table.Body>
          {Object.keys(tradeHints).map(key => {
            return (
              <Table.Row key={key}>
                <Table.Cell width={14} className="element-border">
                  <If condition={editingTrade !== key}>
                    <strong>{key}</strong>
                    <div>{getShortenHintText(tradeHints[key])}</div>
                  </If>

                  <If
                    condition={editingTrade === key}
                    styles={{ width: "100%" }}
                  >
                    <strong>{key}</strong>
                    <TextArea value={hintValue} onChange={handleHintChange} />
                  </If>
                </Table.Cell>

                {renderActions(key)}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

TradeHintsSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: object.isRequired,
  setTradeHints: func.isRequired
};

export default TradeHintsSelector;
