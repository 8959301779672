import { difference } from "lodash";
import { func, object, string } from "prop-types";
import React, { useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import {
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Segment,
  Input
} from "semantic-ui-react";
import FileUploader from "../../dropzone/FileUploader";

const allPositionOptions = [
  {
    text: "Links",
    value: "left"
  },
  {
    text: "Mitte",
    value: "middle"
  },
  {
    text: "Rechts",
    value: "right"
  }
];

const positionOptions = (logos, currentLogo) => {
  if (!logos || !currentLogo) return [];
  // show options of positions that are not already taken, but add current position (it should be selected)
  const list = allPositionOptions.filter(
    p => Object.keys(logos).indexOf(p.value) === -1
  );
  const currentPos = Object.keys(currentLogo)[0];
  if (currentPos) {
    list.push({
      text: `${currentPos[0].toUpperCase()}${currentPos.slice(1)}`,
      value: currentPos
    });
  }

  return list;
};

const logosWrapper = logos => ({ layout: { logo: logos } });

function LogoPlacementForm({ model, onChange, logoId, addImage, removeImage }) {
  const logos = useRef({});

  const applyFormChange = (e, jsonLogo) => {
    onChange(e, { value: jsonLogo });
  };

  const currentLogo = useMemo(() => {
    // use ref to have updated values in handler
    const allLogos = model.layout_content?.layout?.logo || {};
    logos.current = allLogos;
    const keys = Object.keys(allLogos);
    if (keys.length === 0) return null;
    let temp = null;
    keys.forEach(key => {
      if (allLogos[key].file === logoId) temp = { [key]: allLogos[key] };
    });
    return temp;
  }, [model]);

  const position = useMemo(() => {
    if (currentLogo) return Object.keys(currentLogo)[0];
    return null;
  }, [currentLogo]);

  const removeLogo = e => {
    delete logos.current[position];
    applyFormChange(e, logosWrapper(logos.current));
  };

  const scale = useMemo(() => {
    if (currentLogo) return currentLogo[position].scale;
    return 0.5;
  }, [currentLogo]);

  const handlePositionSelect = (e, { value }) => {
    logos.current[value] = currentLogo[position];
    delete logos.current[position];
    applyFormChange(e, logosWrapper(logos.current));
  };

  const handleSliderChange = e => {
    const newLogo = {
      [position]: { file: logoId, scale: e.target.valueAsNumber }
    };
    const logo = model.layout_content.layout?.logo || {};
    applyFormChange(e, logosWrapper({ ...logo, ...newLogo }));
  };

  const anyFreePosition = () => {
    const allPositions = ["left", "middle", "right"];
    const takenPosition = Object.keys(logos.current);
    return difference(allPositions, takenPosition)[0] || "left";
  };

  const handleFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      addImage(logoId, binaryStr, anyFreePosition());
    };
    reader.readAsDataURL(file);
  };

  const handleDeleteLogo = e => {
    removeImage(logoId, Object.keys(currentLogo)[0]);
    removeLogo(e);
  };

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <FileUploader
            id={logoId}
            fileType="image"
            name={logoId}
            previewImageUrl={model[`${logoId}_url`] || model[logoId]}
            handleFile={handleFile}
            handleDelete={handleDeleteLogo}
          />
        </Grid.Row>
        <GridRow>
          <GridColumn width={6}>
            <label htmlFor={`${logoId}-position`}>
              <FormattedMessage id="documentsExport.dialogs.styles.labels.position" />
            </label>
            <Dropdown
              id={`${logoId}-position`}
              name="position"
              disabled={!currentLogo}
              fluid
              selection
              basic
              options={positionOptions(logos.current, currentLogo)}
              onChange={handlePositionSelect}
              value={position}
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={4}>
            <label htmlFor={`${logoId}-scale`}>
              <FormattedMessage id="documentsExport.dialogs.styles.labels.scale" />
            </label>
            <input
              id={`${logoId}-scale`}
              name="scale"
              disabled={!currentLogo}
              type="range"
              min={0}
              max={1.5}
              step={0.05}
              style={{ width: "100%", padding: "0" }}
              onChange={handleSliderChange}
              value={scale}
            />
          </GridColumn>
          <GridColumn width={2}>
            <Input
              name="scale"
              type="number"
              fluid
              disabled={!currentLogo}
              value={scale}
              onChange={handleSliderChange}
              step={0.05}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}

LogoPlacementForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  model: object.isRequired,
  onChange: func.isRequired,
  logoId: string.isRequired,
  addImage: func.isRequired,
  removeImage: func.isRequired
};

export default LogoPlacementForm;
