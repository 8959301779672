import React, { useState } from "react";
import PropTypes, { number, instanceOf, arrayOf, object } from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Icon, Button, Segment, Header } from "semantic-ui-react";

import { getPendingSubLineItemFormModels } from "builder_portal/selectors";
import Growl from "../../actions/growlActions";
import RoomBookController from "../../controllers/roomBook/roomBookController";

import "./buyerSelectionBulkControl.scss";
import { If } from "../../../shared/components/elements/Conditions";
import SubLineItemFormModel from "../../models/roomBook/SubLineItemFormModel";

function BuyerSelectionBulkControl({
  updates,
  count,
  ctrl,
  growl,
  nonUpdatableLineItems
}) {
  const [loading, setLoading] = useState(false);

  async function handleBatchUpdate() {
    await setLoading(true);
    await ctrl
      .updateSubLineItems(updates)
      .catch(e => {
        if (e.data?.error) {
          growl.error(
            "message.errorBackend.title",
            "message.errorBackend.body",
            {
              timeout: 8000,
              bodyValues: { translatedBody: e.data.error }
            }
          );
        } else {
          growl.error("message.errorLoad.title", "message.errorLoad.body", {
            timeout: 8000
          });
        }
        setLoading(false);
      })
      .then(response => {
        if (response?.status === 200) {
          growl.success(
            "roomBook.lineItems.buyerSelection.acceptedAll.title",
            "roomBook.lineItems.buyerSelection.acceptedAll.body"
          );
        }
      });

    await setLoading(false);
  }

  return (
    <>
      <If condition={count}>
        <Segment data-component="roombookBuyerSelectionBulkControl">
          <Header as="h6" className="buyerLabel">
            <FormattedMessage
              id="roomBook.lineItems.buyerSelection.buyerSelectedBatch.title"
              defaultMessage="roomBook.lineItems.buyerSelection.buyerSelectedBatch.title"
            />
          </Header>
          <Icon name="attention" size="large" color="yellow" />
          <div className="text">
            <Header as="h3">
              <FormattedMessage
                id="roomBook.lineItems.buyerSelection.buyerSelectedBatch.total"
                values={{
                  count
                }}
              />
            </Header>
          </div>
          <div className="apply">
            <Button
              basic
              onClick={handleBatchUpdate}
              color="green"
              loading={loading}
            >
              <FormattedMessage
                id="meta.actions.applyAll"
                message="meta.actions.applyAll"
                values={{
                  count
                }}
              />
            </Button>
          </div>
        </Segment>
      </If>
      <If condition={nonUpdatableLineItems.length}>
        <Segment>
          <div data-component="roombookBuyerSelectionBulkControl">
            <Header as="h6" className="buyerLabel">
              <FormattedMessage
                id="roomBook.lineItems.buyerSelection.customProductBuyerSelections.title"
                defaultMessage="roomBook.lineItems.buyerSelection.customProductBuyerSelections.title"
              />
            </Header>
            <Icon name="attention" size="large" color="yellow" />
            <div className="text">
              <Header as="h3">
                <FormattedMessage
                  id="roomBook.lineItems.buyerSelection.customProductBuyerSelections.total"
                  values={{
                    count: nonUpdatableLineItems.length
                  }}
                />
              </Header>
            </div>
          </div>
          <div style={{ padding: "0.25rem 1rem 0" }}>
            <ul>
              {nonUpdatableLineItems.map(lineItem => (
                <li key={lineItem.getId()}>
                  <Header as="h5">
                    {lineItem.getDisplayNumber()} {lineItem.getTitle()}
                  </Header>
                </li>
              ))}
            </ul>
          </div>
        </Segment>
      </If>
    </>
  );
}

BuyerSelectionBulkControl.propTypes = {
  ctrl: instanceOf(RoomBookController).isRequired,
  growl: instanceOf(Growl).isRequired,
  count: number,
  updates: arrayOf(object),
  nonUpdatableLineItems: arrayOf(PropTypes.instanceOf(SubLineItemFormModel))
};

BuyerSelectionBulkControl.defaultProps = {
  updates: [],
  nonUpdatableLineItems: [],
  count: 0
};

const mapStateToProps = state => {
  const lineItems = getPendingSubLineItemFormModels(state);
  const updatableLineItems = lineItems.filter(
    li => !li.isBuyerSelectionWithCustomProduct()
  );
  const nonUpdatableLineItems = lineItems.filter(li =>
    li.isBuyerSelectionWithCustomProduct()
  );

  const updates = updatableLineItems.map(model => {
    return model.applyBuyerSelection().currentModel;
  });

  const count = updates?.length;

  return {
    updates,
    updatableLineItems,
    nonUpdatableLineItems,
    count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: new Growl(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerSelectionBulkControl);
