import React, { useEffect, useState } from "react";
import { arrayOf, object, func, shape } from "prop-types";
import { Segment, Grid, Input, Label } from "semantic-ui-react";
import { StyleVariableShape } from "shared/shapes";
import { keyBy } from "lodash";
import { If } from "shared/components/elements/Conditions";

const colorHashMatch = /^#([0-9A-F]{0,6})$/i;

const StyleElementForm = ({ variables, json, field }) => {
  const [state, setState] = useState({ ...json });
  const [keyVariables, setKeyVariables] = useState({});

  useEffect(() => {
    field.props.onChange(undefined, { value: state });
  }, [state]);

  useEffect(() => {
    setKeyVariables(keyBy(variables, "name"));
  }, [variables]);

  useEffect(() => {
    if (JSON.stringify(state) !== JSON.stringify(json)) setState({ ...json });
  }, [json]);

  const handleChange = event => {
    const {
      target: { name, value }
    } = event;
    if (!value) {
      const temp = { ...state };
      delete temp[name];
      setState(temp);
      return;
    }

    const type = keyVariables[name]?.type;
    if (type !== "hexColor") {
      setState({ ...state, [name]: value });
    } else if (colorHashMatch.test(value)) {
      setState({ ...state, [name]: value });
    }
  };

  return (
    <Segment>
      <Grid>
        {variables.map(variable => {
          return (
            <Grid.Row key={variable.name} verticalAlign="bottom">
              <Grid.Column width={6}>
                <label>{variable.label}</label>
                <Input
                  name={variable.name}
                  value={state[variable.name]}
                  onChange={handleChange}
                  fluid
                  type={variable.type}
                  placeholder={variable.default}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <If condition={variable.type === "hexColor"}>
                  <Label
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor:
                        state[variable.name]?.length >= 4
                          ? state[variable.name]
                          : variable.default,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.21), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                  />
                </If>
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    </Segment>
  );
};

StyleElementForm.propTypes = {
  variables: arrayOf(StyleVariableShape),
  // eslint-disable-next-line react/forbid-prop-types
  json: object.isRequired,
  field: shape({
    props: shape({
      onChange: func
    })
  }).isRequired
};

StyleElementForm.defaultProps = {
  variables: []
};

export default StyleElementForm;
