import Resources from "shared/actions/resourcesActions";

// eslint-disable-next-line import/prefer-default-export
export default class ProjectAnalysisTopicResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "project_analysis_topic",
      "/api/v1/project_analysis_topics"
    );
  }
}
