import React from "react";
import { useSelector } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { getAccount } from "shared/selectors";
import { number, string, node } from "prop-types";

const HasProjectAccessRight = ({ projectId, right, children }) => {
  const account = useSelector(getAccount);
  return (
    <If condition={account.hasProjectRoleRight(projectId, right)}>
      {children}
    </If>
  );
};

HasProjectAccessRight.propTypes = {
  projectId: number.isRequired,
  right: string.isRequired,
  children: node
};

HasProjectAccessRight.defaultProps = {
  children: null
};

export default HasProjectAccessRight;
