import { cloneDeep } from "lodash";
import Resources from "shared/actions/resourcesActions";
import { UPDATE_PAGE_CONTENT } from "shared/constants/appConstants";
import apiRequest from "../../shared/network/apiRequest";

export class ProductManagerResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "product", "/api/v1/product_manager/products");
  }

  save(values) {
    return super.save(values).then(({ data }) => {
      this.dispatch({
        type: UPDATE_PAGE_CONTENT,
        payload: { products: [data] }
      });
    });
  }

  fetchSuppliers() {
    return apiRequest
      .get(`${this.url}/suppliers`, this.dispatch)
      .then(this.propagateUpdate);
  }

  fetctProductTypeMetadata(productTypeId) {
    return apiRequest.get(
      `${this.url}/product_types/${productTypeId}`,
      this.dispatch
    );
  }

  fetchProductTypes() {
    return apiRequest
      .get(`${this.url}/product_types`, this.dispatch)
      .then(this.propagateUpdate);
  }

  search = filter => this.productDbSearch(filter);

  productDbSearch = filter => {
    const requestObject = {
      method: "GET",
      timeout: 5000,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return fetch(this.crateUrl(filter), requestObject)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return Promise.reject();
      })
      .then(data =>
        data.products.map(product => ({
          ...product,
          favorite: true
        }))
      )
      .catch(() => []);
  };

  crateUrl = filter => {
    const f = cloneDeep(filter);
    const { tag_ids } = f;
    delete f.tag_ids;
    let url = `${this.url}?${new URLSearchParams(f).toString()}`;

    if (tag_ids) {
      tag_ids.forEach(id => {
        url = `${url}&tag_ids[]=${id}`;
      });
    }

    return url;
  };
}

export default ProductManagerResource;
