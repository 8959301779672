import PropTypes from "prop-types";
import React from "react";
import { keyBy, compact } from "lodash";
import { FormattedMessage } from "react-intl";
import FormatQuantity from "shared/components/quantity/FormatQuantity";
import { Icon, Button, Grid, Segment } from "semantic-ui-react";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";

import "./buyerSelectionControl.scss";

class BuyerSelectionControl extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    lineItem: PropTypes.object,
    products: PropTypes.array,
    ctrl: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { i18n, lineItem, products } = this.props;
    const productDictionary = keyBy({ ...products }, "id");
    const desiredProduct = productDictionary[lineItem.getDesiredProductId()];

    return (
      <Segment
        id={`buyerSelectionControl-${lineItem.currentModel.id}`}
        data-component="lineItemBuyerSelectionControl"
        loading={this.state.loading}
      >
        <Grid stackable verticalAlign="middle">
          <Grid.Column width="10" className="text">
            <Icon name="attention" size="big" color="yellow" />
            <div>
              <div className="buyerLabel">
                <FormattedMessage
                  id="roomBook.lineItems.buyerSelection.buyerSelected"
                  defaultMessage="roomBook.lineItems.buyerSelection.buyerSelected"
                />
              </div>
              {desiredProduct && (
                <div className="productName">
                  <FormatQuantity
                    value={lineItem.getDesiredQuantity()}
                    unit={lineItem.getPriceStrategy()}
                  />
                  {compact([
                    desiredProduct?.supplier,
                    desiredProduct?.series,
                    desiredProduct?.name
                  ]).join(", ")}
                </div>
              )}
              {!desiredProduct && (
                <div className="productName">
                  {lineItem.getCustomProductDescription()}
                </div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column width="3">
            <Button
              id={`accept-${lineItem.currentModel.id}`}
              fluid
              compact
              onClick={this.handleApplyBuyerSelection}
              basic
              color="green"
              className="apply"
              content={i18n["meta.actions.apply"]}
            />
          </Grid.Column>
          <Grid.Column width="3">{this.renderDiscardButton()}</Grid.Column>
        </Grid>
      </Segment>
    );
  }

  handleApplyBuyerSelection = () => {
    const { ctrl, lineItem } = this.props;

    const updatedLineItem = lineItem.applyBuyerSelection();

    this.setState({ loading: true });
    ctrl
      .updateSubLineItem(updatedLineItem.currentModel)
      .then(() => this.setState({ loading: false }));
  };

  handleDiscardBuyerSelection = () => {
    const { ctrl, lineItem } = this.props;

    this.setState({ loading: true });
    return ctrl.discardBuyerSelection(lineItem.currentModel);
  };

  renderDiscardButton() {
    const { i18n } = this.props;
    const button = (
      <Button
        fluid
        compact
        basic
        color="red"
        className="discard"
        content={i18n["meta.actions.discard"]}
      />
    );
    const buttons = [
      {
        id: "confirm",
        label: "meta.actions.discard",
        color: "red",
        onClick: cb => {
          this.handleDiscardBuyerSelection().then(cb);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.actions.discardBuyerSelectionDialog.title"
        message="roomBook.actions.discardBuyerSelectionDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }
}

export default BuyerSelectionControl;
