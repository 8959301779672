/* eslint-disable max-classes-per-file */
import Resources from "shared/actions/resourcesActions";

export class DesignLineGroupsResource extends Resources {
  constructor(dispatch, catalogId) {
    super(
      dispatch,
      "design_line_group",
      `/api/v1/project_catalogs/${catalogId}/design_line_groups`
    );
    this.refreshPageContentAfterCommit = true;
  }
}

export class DesignLinesResource extends Resources {
  constructor(dispatch, catalogId, designLineGroupId) {
    super(
      dispatch,
      "design_line",
      `/api/v1/project_catalogs/${catalogId}/design_line_groups/${designLineGroupId}/design_lines`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
