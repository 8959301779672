import { string, number, shape, bool } from "prop-types";

const PriceCatalogShape = shape({
  id: number,
  name: string,
  deletable: bool,
  external_id: string
});

export default PriceCatalogShape;
