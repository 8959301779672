import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get, isEmpty, map, pickBy, find } from "lodash";
import { ProjectsCatalogResource } from "builder_portal/actions/projectCatalogActions";
import { ProjectSubLineItemResource } from "builder_portal/actions/subLineItemActions";
import {
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  List,
  Message,
  Pagination,
  Popup,
  Segment,
  Select,
  Table,
  Button
} from "semantic-ui-react";
import {
  getI18N,
  getLineItemLifeCycle,
  getSearchMeta,
  getSearchResults,
  getSortedProductGroups,
  getSortedProducts
} from "builder_portal/selectors";
import MasterSlaveList from "shared/components/elements/MasterSlaveList";
import ProductGroupShape from "shared/shapes/productGroup.shape";
import { ProductShape } from "shared/shapes/product.shape";
import { I18nShape } from "shared/shapes/i18n.shape";
import { getAccount } from "shared/selectors";
import SubLineItemBatchEditWizard from "./SubLineItemBatchEditWizard";
import DownloadQuantitiesDialog from "./quantities/DownloadQuantitiesDialog";
import UploadQuantitiesDialog from "./quantities/UploadQuantitiesDialog";
import ProductInfoItem from "../product/ProductInfoItem";
import { withRouter } from "../../../shared/helpers/withRouter";

import "./subLineItemSearchContainer.scss";
import { If } from "../../../shared/components/elements/Conditions";

const COLUMNS = [
  "title",
  "product_group.name",
  "product",
  "product.sku",
  "default_product",
  "default_product.sku",
  "buyer_selection_product",
  "buyer_selection_product.sku",
  "section.title",
  "unit"
];
const ALL_PRODUCTS_OPTION = { value: null, text: "Alle Produkte" };
const ALL_PRODUCT_GROUPS_OPTION = { value: null, text: "Alle Produktgruppen" };
const ALL_PHASES_OPTION = { value: null, text: "Alle Vorgänge" };
const SEARCH_PROPS = {
  productGroupId: "product_group.id",
  productId: "product.id",
  defaultProductId: "default_product.id",
  buyerSelectionProductId: "buyer_selection_product.id",
  template: "room_book.template"
};

class SubLineItemSearchContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      location: { query: locationQuery = {} }
    } = props;

    const { page: _page, limit: _limit, ...query } = locationQuery;

    this.state = {
      query,
      column: "",
      dialogOpen: false,
      direction: null,
      selectedIds: [],
      showTableSettings: false,
      renderResults: false,
      visibleColumns: [
        "product_group.name",
        "product",
        "default_product",
        "buyer_selection_product",
        "section.title",
        "unit"
      ],
      onlyTemplates: query.template === "true",
      noTemplates: query.template === "false"
    };
  }

  componentDidMount() {
    const { query } = this.state;
    const { catalogResource } = this.props;
    catalogResource.get();
    if (!isEmpty(query)) {
      this.updateSearch();
    } else {
      this.setState({ query: { input: "" } });
    }
  }

  componentDidUpdate(prevProps) {
    const { results } = this.props;
    const { selectedIds } = this.state;
    const resultContainsId = id => results.some(result => result.id === id);
    if (
      prevProps.results !== results &&
      selectedIds.some(id => !resultContainsId(id))
    ) {
      this.writeIdsToState(selectedIds, resultContainsId);
    }
  }

  getUpdateQuery = key => (_event, { value }) => {
    const { query } = this.state;
    this.setState({ query: { ...query, [key]: value } });
  };

  getCleanQuery() {
    const { query } = this.state;
    return pickBy(query, value => value != null && value !== "");
  }

  getSortParams = (column, direction) => {
    const dir = direction === "ascending" ? "asc" : "desc";
    switch (column) {
      case "product":
      case "default_product":
      case "buyer_selection_product":
        return {
          [`${column}.name`]: dir
        };
      case "unit":
        return [
          {
            "unit.label": {
              order: dir,
              missing: dir === "asc" ? "_last" : "_first"
            }
          },
          { "room_book.name": dir }
        ];
      default:
        return {
          [column]: dir
        };
    }
  };

  getPriceStrategy = ({ product_group }) => {
    const { productGroups } = this.props;
    const relevantGroup = find(
      productGroups,
      group => group.id === get(product_group, "id")
    );
    const priceStrategy = get(relevantGroup, "price_strategy");
    return product_group ? priceStrategy : "individual";
  };

  getProductGroupsFilterOptions = () => {
    const { productGroups } = this.props;
    return [
      ALL_PRODUCT_GROUPS_OPTION,
      ...productGroups.map(group => {
        const count = group.prices.length;

        return {
          value: group.id.toString(),
          text: group.name,
          content: (
            <Header as="h5">
              <Header.Content>
                {group.name}
                <Header.Subheader>
                  <FormattedMessage
                    id="batchEdit.drop_down.product_groups_subheader"
                    values={{ count }}
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>
          )
        };
      })
    ];
  };

  getProductFilterOptions = () => {
    const { products, productGroups } = this.props;
    const {
      query: { productGroupId }
    } = this.state;
    const productGroup =
      productGroupId &&
      productGroups.find(
        group => group.id.toString() === productGroupId.toString()
      );
    const productIds = get(productGroup, "prices", []).map(
      price => price.product_id
    );

    return [
      ALL_PRODUCTS_OPTION,
      ...products
        .filter(product => !productGroup || productIds.includes(product.id))
        .map(product => {
          return {
            value: product.id.toString(),
            text: <ProductInfoItem product={product} mode="compact" />,
            content: <ProductInfoItem product={product} />
          };
        })
    ];
  };

  getStatusFilterOptions() {
    const { lineItemLifeCycle } = this.props;

    return [
      ALL_PHASES_OPTION,
      ...lineItemLifeCycle.getPhases().map(phase => {
        return {
          value: phase.id,
          text: phase.label,
          content: (
            <Header as="h5">
              <Icon name="circle" style={{ color: phase.color }} />
              {phase.label}
            </Header>
          )
        };
      })
    ];
  }

  writeIdsToState = (ids, resultContainsId) => {
    this.setState({
      selectedIds: ids.filter(id => resultContainsId(id))
    });
  };

  updateSearch = (options = {}) => {
    const {
      subLineItemResource,
      router,
      location,
      location: {
        query: { page: oldPage, limit: oldLimit }
      }
    } = this.props;
    const { sort: oldSort } = this.state;
    const { page = oldPage, sort = oldSort, limit = oldLimit } = options;
    const cleanQuery = this.getCleanQuery();
    const searchString = map(cleanQuery, (value, key) => {
      const searchProp = SEARCH_PROPS[key];
      return searchProp ? `${searchProp}:${value}` : value;
    }).join(" AND ");

    router.push({ ...location, query: pickBy({ ...cleanQuery, page, limit }) });
    subLineItemResource
      .search({ query: searchString, page, sort, limit })
      .then(() => this.setState({ renderResults: true }));
  };

  handleSelectProductGroup = value =>
    this.setState({
      query: {
        ...this.state.query,
        productGroupId: value,
        defaultProductId: "",
        productId: "",
        buyerSelectionProductId: ""
      }
    });

  handleSelectProduct = this.getUpdateQuery("productId");

  handleSelectDefaultProduct = this.getUpdateQuery("defaultProductId");

  handleSelectBuyerSelectionProduct = this.getUpdateQuery(
    "buyerSelectionProductId"
  );

  handleInputChange = this.getUpdateQuery("input");

  handleSelectTemplate = (_event, { value, checked }) => {
    const { onlyTemplates, noTemplates, query } = this.state;

    this.setState({
      query: { ...query, template: checked ? value : null },
      onlyTemplates: value === "true" ? checked : onlyTemplates && !checked,
      noTemplates: value === "false" ? checked : noTemplates && !checked
    });
  };

  handleSearch = () => this.updateSearch({ page: null });

  handleSelectPage = (_event, { activePage }) =>
    this.updateSearch({ page: activePage });

  handleLimitChange = (_event, { value }) =>
    this.updateSearch({ limit: value, page: null });

  handleSorting = clickedColumn => {
    const { column, direction } = this.state;
    const newDirection =
      column !== clickedColumn || direction === "descending"
        ? "ascending"
        : "descending";

    this.setState({
      column: clickedColumn,
      direction: newDirection
    });
    this.updateSearch({
      sort: this.getSortParams(clickedColumn, newDirection)
    });
  };

  handleSelectChange = ({ selectedOptions: selectedIds }) =>
    this.setState({ selectedIds });

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  checkProductGroupId(selectedItems) {
    const selectedProductGroupIds = selectedItems.map(item => {
      let productGroupId;
      if (item.role === "product") {
        productGroupId = item.product_group.id;
      }
      if (item.role === "adhoc") {
        productGroupId = "individual";
      }
      return productGroupId;
    });

    if (selectedProductGroupIds.length) {
      return selectedProductGroupIds.reduce((a, b) => {
        return a === b ? a : false;
      });
    }
  }

  toggleSuccessMessage = () => {
    this.setState({ successOpen: true });
  };

  toggleSettings = () => {
    this.setState({ showTableSettings: !this.state.showTableSettings });
  };

  toggleTableColumn = column => {
    const { visibleColumns } = this.state;

    this.setState({
      visibleColumns: COLUMNS.filter(
        c => visibleColumns.includes(c) !== (column === c)
      )
    });
  };

  handleStatusFilterChange(value) {
    const { query } = this.state;
    this.setState({ query: { ...query, phase: value } });
  }

  renderPagination() {
    const { searchMeta } = this.props;
    if (searchMeta.total_pages < 2) return null;
    return (
      <Pagination
        totalPages={searchMeta.total_pages}
        activePage={searchMeta.current_page}
        onPageChange={this.handleSelectPage}
      />
    );
  }

  renderCheckbox = checkboxProps => {
    const { disabled } = checkboxProps;
    if (disabled) {
      return (
        <Popup trigger={<Checkbox {...checkboxProps} />} basic>
          <FormattedMessage id="batchEdit.messages.disabledCheckbox" />
        </Popup>
      );
    }
    return <Checkbox {...checkboxProps} />;
  };

  renderTableHeader(masterProps) {
    const { visibleColumns } = this.state;

    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Checkbox {...masterProps} />
          </Table.HeaderCell>
          {visibleColumns.map(this.renderColumnHeader)}
        </Table.Row>
      </Table.Header>
    );
  }

  renderColumnHeader = column => {
    const { column: sortColumn, direction } = this.state;

    return (
      <Table.HeaderCell
        key={column}
        sorted={sortColumn === column ? direction : null}
        onClick={() => this.handleSorting(column)}
      >
        <FormattedMessage id={`batchEdit.table_header.${column}`} />
      </Table.HeaderCell>
    );
  };

  renderResultColumn = (result, column) => {
    const { projectId } = this.props;

    const quantityReference =
      column === "product" ? "quantity" : "default_quantity";

    const product = {
      name: get(result, `${column}.name`),
      supplier: get(result, `${column}.supplier`),
      sku: get(result, `${column}.sku`),
      series: get(result, `${column}.series`)
    };
    const resultQuantity = get(result, quantityReference);
    const priceStrategy = this.getPriceStrategy(result);

    const isTemplate = result.room_book.template;
    const resultTemplateId = result.room_book.id;
    const resultUnitId = result.unit?.slug || result.unit?.id;

    const baseUrl = `/projects/${projectId}/`;
    const productUrl = isTemplate
      ? `room_books/${resultTemplateId}`
      : `units/${resultUnitId}/room-book`;

    let content;
    switch (column) {
      case "product":
      case "default_product":
      case "buyer_selection_product":
        content = (
          <Link
            to={{
              pathname: `${baseUrl}${productUrl}`,
              hash: `#sub-line-item-${result.id}`
            }}
          >
            <If condition={product.name}>
              <Header as="h5" style={{ display: "table" }}>
                <Header.Content
                  style={{ display: "table-cell", width: "5em" }}
                  data-attr="quantity"
                >
                  <FormattedMessage
                    id={`batchEdit.priceStrategy.${priceStrategy}`}
                    values={{ quantity: resultQuantity }}
                  />
                </Header.Content>
                <Header.Content style={{ display: "table-cell" }}>
                  <ProductInfoItem product={product} />
                </Header.Content>
              </Header>
            </If>
            <If condition={!product.name}>
              <FormattedMessage id="batchEdit.messages.noSelection.label" />
            </If>
          </Link>
        );
        break;
      case "unit":
        content = result.room_book.template
          ? get(result, "room_book.name")
          : get(result, "unit.label");
        break;
      default:
        content = get(result, column, "").toString();
    }
    return (
      <Table.Cell key={column} data-attr={column}>
        {content}
      </Table.Cell>
    );
  };

  renderStatus() {
    const {
      searchMeta: { total_count, offset_value, limit_value }
    } = this.props;
    const max = Math.min(offset_value + limit_value, total_count);
    const min = Math.min(offset_value + 1, max);
    const options = [
      { key: 10, value: 10, text: 10 },
      { key: 25, value: 25, text: 25 },
      { key: 100, value: 100, text: 100 },
      { key: 250, value: 250, text: 250 },
      { key: 1000, value: 1000, text: 1000 }
    ];
    return (
      <div className="ui pagination menu">
        <div className="item">
          <FormattedMessage
            id="batchEdit.searchStatus"
            values={{ min, max, total: total_count }}
          />
        </div>
        <div className="item">
          Pro Seite:
          <Dropdown
            options={options}
            value={limit_value}
            onChange={this.handleLimitChange}
          />
        </div>
      </div>
    );
  }

  renderSearchResult = ({ result, ...checkBoxProps }) => {
    const { visibleColumns } = this.state;

    const icon = !result.room_book.template ? (
      <Icon
        name="circle"
        style={{ color: `${result.status_info.phase_color}` }}
      />
    ) : (
      <Icon name="book" />
    );

    const popupContent = !result.room_book.template
      ? result.status_info.label
      : result.room_book.name;

    return (
      <Table.Row key={result.id} data-model="sub_line_item">
        <Table.Cell>{this.renderCheckbox(checkBoxProps)}</Table.Cell>
        <Table.Cell>
          <Popup trigger={icon} content={popupContent} />
        </Table.Cell>
        {visibleColumns.map(column => this.renderResultColumn(result, column))}
      </Table.Row>
    );
  };

  renderTableSettings() {
    const { visibleColumns } = this.state;

    return (
      <Popup.Content>
        <List>
          <List.Content>
            {COLUMNS.map(column => {
              return (
                <List.Item key={column}>
                  <Checkbox
                    checked={visibleColumns.includes(column)}
                    label={
                      <FormattedMessage
                        tagName="label"
                        id={`batchEdit.table_header.${column}`}
                      />
                    }
                    onChange={() => this.toggleTableColumn(column)}
                  />
                </List.Item>
              );
            })}
          </List.Content>
        </List>
      </Popup.Content>
    );
  }

  renderTable = ({ masterProps, childrenProps }) => (
    <Table sortable compact>
      {this.renderTableHeader(masterProps)}
      <Table.Body>{childrenProps.map(this.renderSearchResult)}</Table.Body>
    </Table>
  );

  renderSearchResults() {
    const { results, searchMeta: { total_count = 0 } = {} } = this.props;
    const { selectedIds } = this.state;

    if (total_count <= 0) {
      return (
        <Message warning>
          <Message.Header>
            <FormattedMessage id="batchEdit.messages.noResults.header" />
          </Message.Header>
          <Message.Content>
            <FormattedMessage id="batchEdit.messages.noResults.content" />
          </Message.Content>
        </Message>
      );
    }
    const options = results.map(result => ({
      result,
      id: result.id,
      disabled: !result.bulk_deletable && !result.bulk_editable
    }));

    return (
      <div className="margin top spacious">
        {this.renderPagination()}
        {this.renderStatus()}
        <MasterSlaveList
          render={this.renderTable}
          onChange={this.handleSelectChange}
          options={options}
          selectedOptions={selectedIds}
        />
      </div>
    );
  }

  renderBatchQuantities = () => {
    return (
      <div>
        <DownloadQuantitiesDialog
          trigger={<Button content="Mengenübersicht" />}
        />
        <UploadQuantitiesDialog trigger={<Button content="Mengenänderung" />} />
      </div>
    );
  };

  render() {
    const { i18n, results = [], productGroups, account } = this.props;

    const {
      dialogOpen,
      noTemplates,
      onlyTemplates,
      query,
      renderResults,
      selectedIds,
      showTableSettings
    } = this.state;

    const selectedItems = results.filter(item => selectedIds.includes(item.id));
    const buttonDisabled = selectedIds.length > 0;
    const selectedProductGroupId = this.checkProductGroupId(selectedItems);
    const cleanQuery = this.getCleanQuery();

    return (
      <div>
        <If condition={account.isEnabled("show_page_layout_v2")}>
          <Header size="large">
            <FormattedMessage id="project.tabs.search" defaultMessage="Suche" />
          </Header>
        </If>
        <Segment data-component="subLineItemSearchContainer">
          <Helmet title={i18n["batchEdit.title"]} />
          <SubLineItemBatchEditWizard
            results={results}
            selectedItems={selectedItems}
            selectedIds={selectedIds}
            selectedProductGroupId={selectedProductGroupId}
            productGroups={productGroups}
            dialogOpen={dialogOpen}
            onClose={this.handleCloseDialog}
            toggleSuccessMessageFn={this.toggleSuccessMessage}
            getPriceStrategyFn={this.getPriceStrategy}
          />
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                search
                width="8"
                label="Produktgruppen"
                options={this.getProductGroupsFilterOptions()}
                value={query.productGroupId}
                onChange={(_, { value }) =>
                  this.handleSelectProductGroup(value)
                }
                placeholder="Produktgruppe wählen..."
                id="search-product-group"
              />
              <Form.Field
                control={Select}
                search
                width="8"
                label="Kundenauswahl"
                options={this.getProductFilterOptions()}
                value={query.productId}
                onChange={this.handleSelectProduct}
                placeholder="Produkt wählen..."
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                search
                width="8"
                label="Standardauswahl"
                options={this.getProductFilterOptions()}
                value={query.defaultProductId}
                onChange={this.handleSelectDefaultProduct}
                placeholder="Produkt wählen..."
              />
              <Form.Field
                control={Select}
                search
                width="8"
                label="Konfiguratorauswahl"
                options={this.getProductFilterOptions()}
                value={query.buyerSelectionProductId}
                onChange={this.handleSelectBuyerSelectionProduct}
                placeholder="Produkt wählen..."
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                autoFocus
                width="8"
                label="Volltextsuche"
                value={query.input || ""}
                onChange={this.handleInputChange}
              />
              <Form.Field
                width="8"
                control={Select}
                label="Status filtern"
                value={query.phase}
                onChange={(e, { value }) =>
                  this.handleStatusFilterChange(value)
                }
                options={this.getStatusFilterOptions()}
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                width="4"
                label="nur Raumbuchvorlagen"
                onChange={this.handleSelectTemplate}
                checked={onlyTemplates}
                value="true"
              />
              <Form.Checkbox
                width="4"
                label="keine Raumbuchvorlagen"
                onChange={this.handleSelectTemplate}
                checked={noTemplates}
                value="false"
              />
            </Form.Group>
            <Form.Group>
              <Form.Button
                icon="search"
                content={i18n["batchEdit.buttons.search"]}
                color={results.length || isEmpty(cleanQuery) ? null : "green"}
                disabled={isEmpty(cleanQuery)}
                onClick={this.handleSearch}
                id="search"
              />
              <Form.Button
                content={i18n["batchEdit.buttons.editItems"]}
                disabled={!buttonDisabled}
                color={selectedIds.length ? "green" : null}
                onClick={this.handleOpenDialog}
                id="edit"
              />
              {renderResults && (
                <Popup
                  open={showTableSettings}
                  basic
                  flowing
                  on="click"
                  onOpen={this.toggleSettings}
                  onClose={this.toggleSettings}
                  position="right center"
                  trigger={<Form.Button icon="setting" />}
                >
                  {this.renderTableSettings()}
                </Popup>
              )}
            </Form.Group>
          </Form>
          {renderResults && this.renderSearchResults()}
        </Segment>
        <Segment>{this.renderBatchQuantities()}</Segment>
      </div>
    );
  }
}

SubLineItemSearchContainer.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  catalogResource: PropTypes.instanceOf(ProjectsCatalogResource).isRequired,
  i18n: I18nShape.isRequired,
  location: PropTypes.object,
  productGroups: PropTypes.arrayOf(ProductGroupShape),
  products: PropTypes.arrayOf(ProductShape),
  projectId: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  router: PropTypes.object,
  searchMeta: PropTypes.object,
  subLineItemResource: PropTypes.instanceOf(ProjectSubLineItemResource)
    .isRequired,
  lineItemLifeCycle: PropTypes.shape({ getPhases: PropTypes.func }),
  account: PropTypes.object.isRequired
  /* eslint-enable react/forbid-prop-types */
};

SubLineItemSearchContainer.defaultProps = {
  productGroups: undefined,
  products: undefined,
  searchMeta: undefined,
  lineItemLifeCycle: undefined
};

const mapStateToProps = createStructuredSelector({
  i18n: getI18N,
  productGroups: getSortedProductGroups,
  products: getSortedProducts,
  results: getSearchResults,
  searchMeta: getSearchMeta,
  lineItemLifeCycle: getLineItemLifeCycle,
  account: getAccount
});

const mapDispatchToProps = (dispatch, props) => ({
  subLineItemResource: new ProjectSubLineItemResource(
    dispatch,
    props.projectId
  ),
  catalogResource: new ProjectsCatalogResource(dispatch, props.projectId)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubLineItemSearchContainer)
);
