import { CLOSE_MODAL, OPEN_MODAL } from "shared/constants/appConstants";

export const openModal = (id, props) => {
  return {
    type: OPEN_MODAL,
    modal: { id, props }
  };
};
export const closeModal = id => {
  return {
    type: CLOSE_MODAL,
    modal: { id }
  };
};
