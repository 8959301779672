import { get, isEqual, sortBy } from "lodash";
import renderProductOptions from "./renderProductOptions";

export default class SubLineItemModel {
  constructor(currentModel, products, isTemplate) {
    this.currentModel = currentModel;
    this.products = products;
    this.isTemplate = isTemplate;

    this.product_selected = (products || []).filter(product => {
      return product.id === currentModel.product_id;
    })[0];
    this.product_default = (products || []).filter(product => {
      return product.id === currentModel.default_product_id;
    })[0];
  }

  /*
   * QUERIES
   */

  getId() {
    return this.currentModel.id;
  }

  getTitle() {
    return this.currentModel.title;
  }

  getDisplayNumber() {
    return this.currentModel.display_number;
  }

  getSummary() {
    return this.currentModel.summary;
  }

  getDefaultSummary() {
    return this.currentModel.default_summary;
  }

  getTrades() {
    return get(this.currentModel, "trades", []);
  }

  getDefaultProductId() {
    return parseInt(this.currentModel.default_product_id, 10);
  }

  getDefaultProduct() {
    return this.product_default;
  }

  getProductFiles() {
    return this.product_selected?.product_files || [];
  }

  hasProductFiles() {
    return this.product_selected?.product_files?.length > 0;
  }

  getProductId() {
    return parseInt(this.currentModel.product_id, 10);
  }

  getProduct() {
    return this.product_selected;
  }

  hasProduct() {
    return !!this.currentModel.product_id;
  }

  getDiscount() {
    return this.currentModel.discount;
  }

  getSurcharge() {
    return this.currentModel.surcharge;
  }

  getSubtotal() {
    return this.getQuantity() * this.getPrice();
  }

  getTransferTotal() {
    return this.getTransferQuantity() * this.getPrice();
  }

  getExcessTotal() {
    return this.getExcessQuantity() * this.getExcessPrice();
  }

  getTotal() {
    return (
      this.getTransferTotal() +
      this.getExcessTotal() -
      this.getBudget() -
      this.getDiscount() +
      this.getSurcharge()
    );
  }

  getBudget() {
    return this.getDefaultQuantity() * this.getDefaultPrice();
  }

  getCostBudget() {
    return this.getDefaultCosts().reduce((sum, cost) => {
      return sum + this.getDefaultQuantity() * cost.cost;
    }, 0);
  }

  getQuantity() {
    return this.currentModel.quantity;
  }

  getExcessQuantity() {
    const excessQuantity = this.getQuantity() - this.getDefaultQuantity();

    return excessQuantity > 0 ? excessQuantity : 0;
  }

  getTransferQuantity() {
    return this.getQuantity() < this.getDefaultQuantity()
      ? this.getQuantity()
      : this.getDefaultQuantity();
  }

  getExcessPrice() {
    if (
      this.currentModel.excess_price ||
      this.currentModel.excess_price === 0.0
    ) {
      return this.currentModel.excess_price;
    }
    return this.currentModel.price;
  }

  getPrice() {
    if (!this.isTemplate) {
      return this.currentModel.price;
    }
    return get(this.product_selected, "group.price", this.currentModel.price);
  }

  getDefaultQuantity() {
    return this.currentModel.default_quantity;
  }

  getDefaultPrice() {
    if (!this.isTemplate && this.isOptional()) {
      return 0;
    }
    if (!this.isTemplate) {
      return this.currentModel.default_price;
    }
    return get(
      this.product_default,
      "group.price",
      this.currentModel.default_price
    );
  }

  getPriceStrategy() {
    return this.currentModel.price_strategy;
  }

  getCosts() {
    return this.currentModel.costs_attributes;
  }

  getDefaultCosts() {
    if (this.isOptional()) {
      return [];
    }
    return this.currentModel.default_costs_attributes;
  }

  isFlat() {
    return this.getPriceStrategy() === "flat";
  }

  isAdhoc() {
    return this.currentModel.role === "adhoc";
  }

  isProductLineItem() {
    return this.currentModel.role === "product";
  }

  getProductOptions(showDeprecated = false) {
    return this.generateProductOption(
      true,
      showDeprecated,
      this.product_selected?.deprecated
    );
  }

  getDefaultProductOptions() {
    // for default - show all products
    return this.generateProductOption(false);
  }

  generateProductOption(
    selection,
    showDeprecated = true,
    deprecatedIsSelected = true
  ) {
    const base = {
      ...this.currentModel,
      quantity: this.getQuantity(),
      default_quantity: this.getDefaultQuantity()
    };
    return renderProductOptions(
      base,
      this.products,
      selection,
      undefined,
      showDeprecated,
      deprecatedIsSelected
    );
  }

  isProductPriceStale() {
    if (this.product_selected) {
      return this.getProductCatalogPrice() !== this.getPrice();
    }
    return false;
  }

  isExcessPriceStale() {
    if (this.currentModel.excess_price && this.product_selected) {
      return this.getProductCatalogExcessPrice() !== this.getExcessPrice();
    }
    return false;
  }

  isQuantityStale() {
    return (
      !!this.currentModel.unit_variable_value &&
      this.currentModel.quantity !== this.currentModel.unit_variable_value
    );
  }

  isDefaultQuantityStale() {
    return (
      !!this.currentModel.unit_variable_value &&
      this.currentModel.default_quantity !==
        this.currentModel.unit_variable_value
    );
  }

  isDefaultProductPriceStale() {
    if (this.product_default && !this.currentModel.optional) {
      return (
        get(this.product_default, "group.price") !== this.getDefaultPrice()
      );
    }
    return false;
  }

  areDefaultCostsStale() {
    if (this.product_default) {
      return this.compareCosts(
        this.currentModel.default_costs_attributes,
        this.getDefaultProductCatalogCosts()
      );
    }
    return false;
  }

  areCostsStale() {
    if (this.product_selected) {
      return this.compareCosts(
        this.currentModel.costs_attributes,
        this.getProductCatalogCosts()
      );
    }
    return false;
  }

  isPriseCostDiverged() {
    return (
      this.isDefaultProductPriceStale() ||
      this.areDefaultCostsStale() ||
      this.isProductPriceStale() ||
      this.isExcessPriceStale() ||
      this.areCostsStale()
    );
  }

  isTemplate() {
    return this.isTemplate;
  }

  isOptional() {
    return get(this.currentModel, "optional", false);
  }

  hasBuyerSelection() {
    return !!this.currentModel.buyer_selection;
  }

  isBuyerSelectionWithCustomProduct() {
    return (
      this.hasBuyerSelection() && !this.currentModel.buyer_selection.product_id
    );
  }

  matchesBuyerSelection() {
    return (
      isEqual(this.getDesiredProductId() || 0, this.getProductId() || 0) &&
      this.getDesiredQuantity() === this.getQuantity()
    );
  }

  getDesiredProductId() {
    if (this.hasBuyerSelection()) {
      return get(this, ["currentModel", "buyer_selection", "product_id"]);
    }
    return this.getProductId();
  }

  getCustomProductDescription() {
    return get(this, [
      "currentModel",
      "buyer_selection",
      "custom_product_description"
    ]);
  }

  getDesiredQuantity() {
    const q = get(this, ["currentModel", "buyer_selection", "quantity"]);
    if (q !== null) {
      return q;
    }
    return this.getQuantity();
  }

  getProductsWithTotals() {
    const budget = this.getBudget();
    const defaultQuantity = this.getDefaultQuantity();
    const excessQuantity = this.getExcessQuantity();

    const customProducts = [];

    if (!this.product_selected) {
      customProducts.push({
        id: "__custom__",
        name: "Wunschprodukt",
        description: this.currentModel.description,
        series: "",
        total: this.getTotal(),
        images: [{ url: this.currentModel.thumb_url }]
      });
    }

    return customProducts.concat(
      this.products.map(product => {
        const price = get(product, "group.price");
        const excessPrice = get(product, "group.excess_price") || price;

        return {
          ...product,
          total: defaultQuantity * price + excessQuantity * excessPrice - budget
        };
      })
    );
  }

  getSelectedProductWithTotals() {
    return this.getProductsWithTotals().filter(product => {
      return product.id === get(this.product_selected, "id", "__custom__");
    })[0];
  }

  /*
   * HELPERS
   */
  getProductCatalogPrice() {
    return get(this.product_selected, "group.price");
  }

  getProductCatalogExcessPrice() {
    return get(this.product_selected, "group.excess_price");
  }

  getDefaultProductCatalogPrice() {
    return get(this.product_default, "group.price");
  }

  getDefaultProductCatalogCosts() {
    return get(this.product_default, "group.costs_attributes");
  }

  getProductCatalogCosts() {
    return get(this.product_selected, "group.costs_attributes");
  }

  getChangePolicy() {
    return get(this.currentModel, "status_info.status.change_policy", {
      policy: "allowed"
    });
  }

  isChangePolicy(v) {
    return this.getChangePolicy().policy === v;
  }

  getStatusColor() {
    return get(this.currentModel, "status_info.status.phase_color", "#eee");
  }

  getStatusLabel() {
    return get(this.currentModel, "status_info.status.label");
  }

  getUnitVariableType() {
    return get(this.currentModel, "unit_variable_type_id", "");
  }

  getConfigurationGroup() {
    return get(this.currentModel, "configuration_group_id", "");
  }

  getUnitFeatureIds() {
    return get(this.currentModel, "unit_feature_ids", []);
  }

  getFilterTags() {
    return get(this.currentModel, "filter_tags", []);
  }

  hasCarts() {
    return get(this.currentModel, "status_info.cart_ids", []).length > 0;
  }

  /*
   * Warnings
   */
  hasPriceOnRequest() {
    return get(this.getProduct(), ["group", "on_request"], false);
  }

  hasQuantityOnRequest() {
    if (this.getPrice() !== this.getDefaultPrice()) {
      return get(this.getProduct(), ["group", "quantity_on_request"], false);
    }
    return false;
  }

  /*
   * Utilities
   */

  compareCosts(c1, c2) {
    const actualC1 = (c1 || []).filter(c => {
      return !c._destroy;
    });
    const actualC2 = (c2 || []).filter(c => {
      return !c._destroy;
    });
    return !isEqual(this.sanitizeCosts(actualC1), this.sanitizeCosts(actualC2));
  }

  sanitizeCosts = costs =>
    sortBy(
      costs.map(({ cost, excess_cost, trade }) => ({
        cost,
        excess_cost,
        trade
      })),
      c => c.trade
    );
}
