import React, { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { get, difference } from "lodash";

import PropTypes from "prop-types";

import { Modal, Button, Form } from "semantic-ui-react";
import {
  I18nShape,
  ProjectCatalogShape,
  PriceCatalogShape,
  ProjectShape
} from "shared/shapes";
import PriceCatalogResource from "../../actions/priceCatalogActions";
import CatalogResource from "../../actions/catalogActions";

const PriceCatalogDialog = ({
  i18n,
  priceCatalog,
  projectCatalog,
  projects,
  trigger,
  priceCatalogResource,
  projectCatalogResource,
  onClose,
  onCreated,
  onDeleted
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [catalog, setCatalog] = useState(
    priceCatalog || {
      name: "",
      source_id: null,
      project_ids: [],
      external_id: ""
    }
  );

  const originalProjectIds = useMemo(() => {
    return get(priceCatalog, ["project_ids"], []);
  }, [priceCatalog]);

  const sourceOptions = useMemo(() => {
    return get(projectCatalog, "price_catalogs", []).map(c => ({
      key: c.id,
      text: c.name,
      value: c.id
    }));
  }, [projectCatalog]);

  const projectOptions = useMemo(() => {
    return projects.map(project => {
      return {
        key: project.id,
        text: project.name,
        value: project.id
      };
    });
  }, [projects]);

  const handleNameChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, name: value });
    },
    [catalog]
  );

  const handleSourceChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, source_id: value });
    },
    [catalog]
  );

  const handleProjectIdsChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, project_ids: value });
    },
    [catalog]
  );

  const handleExternalIdChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, external_id: value });
    },
    [catalog]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setLoading(false);
    if (!priceCatalog) {
      setCatalog({
        name: "",
        source_id: null,
        assignable_project_ids: [],
        external_id: ""
      });
    }
    onClose();
  }, [setCatalog, onClose, setOpen]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const payload = {
      ...catalog,
      project_catalog_id: projectCatalog.id,
      project_ids_to_remove: difference(
        originalProjectIds,
        catalog.project_ids
      ),
      project_ids_to_add: difference(catalog.project_ids, originalProjectIds)
    };
    priceCatalogResource.save(payload).then(({ data }) => {
      projectCatalogResource.fetchAll().then(() => {
        handleClose();
        onCreated(data.price_catalog.id);
      });
    });
  }, [catalog, onCreated, priceCatalogResource, projectCatalogResource]);

  const handleDelete = useCallback(() => {
    setLoading(true);
    priceCatalogResource
      .remove(catalog.id)
      .then(() => {
        projectCatalogResource.fetchAll();
        onDeleted();
        handleClose();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const deletable =
    catalog.deletable && projectCatalog.price_catalogs.length > 1;

  const valid = catalog.name && catalog.project_ids.length;

  return (
    <Modal
      open={open}
      size="small"
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={trigger}
    >
      <Modal.Header>
        {i18n[`price_catalog.title.${catalog.id ? "edit" : "add"}`]}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            id="name"
            label={i18n["price_catalog.attributes.name.label"]}
            value={catalog.name}
            placeholder={i18n["price_catalog.attributes.name.placeholder"]}
            onChange={handleNameChanged}
          />
          {!catalog.id && (
            <Form.Select
              id="source_id"
              label={i18n["price_catalog.attributes.source_id.label"]}
              value={catalog.source_id}
              placeholder={
                i18n["price_catalog.attributes.source_id.placeholder"]
              }
              onChange={handleSourceChanged}
              options={sourceOptions}
              clearable
            />
          )}
          <Form.Dropdown
            id="project_ids"
            label={i18n["price_catalog.attributes.project_ids.label"]}
            value={catalog.project_ids}
            placeholder={
              i18n["price_catalog.attributes.project_ids.placeholder"]
            }
            options={projectOptions}
            onChange={handleProjectIdsChanged}
            fluid
            multiple
            selection
          />
          <Form.Input
            id="external_id"
            label={i18n["price_catalog.attributes.external_id.label"]}
            value={catalog.external_id}
            placeholder={i18n["price_catalog.attributes.external_id.placeholder"]}
            onChange={handleExternalIdChanged}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {deletable && (
          <Button
            id="delete"
            color="red"
            content={i18n["meta.actions.remove"]}
            onClick={handleDelete}
            loading={loading}
            className="left floated"
          />
        )}
        <Button
          basic
          id="cancel"
          content={i18n["meta.actions.cancel"]}
          onClick={handleClose}
          loading={loading}
        />
        <Button
          primary
          id="submit"
          content={
            catalog.id ? i18n["meta.actions.save"] : i18n["meta.actions.add"]
          }
          onClick={handleSubmit}
          disabled={!valid}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

PriceCatalogDialog.propTypes = {
  i18n: I18nShape.isRequired,
  priceCatalog: PriceCatalogShape,
  projectCatalog: ProjectCatalogShape.isRequired,
  priceCatalogResource: PropTypes.instanceOf(PriceCatalogResource).isRequired,
  projectCatalogResource: PropTypes.instanceOf(CatalogResource).isRequired,
  projects: PropTypes.arrayOf(ProjectShape),
  onClose: PropTypes.func,
  onCreated: PropTypes.func,
  onDeleted: PropTypes.func,
  trigger: PropTypes.node.isRequired
};

PriceCatalogDialog.defaultProps = {
  priceCatalog: null,
  projects: [],
  onClose: () => {},
  onCreated: () => {},
  onDeleted: () => {}
};

const mapStateToProps = state => ({
  i18n: state.i18n
});

const mapDispatchToProps = dispatch => {
  return {
    priceCatalogResource: new PriceCatalogResource(dispatch),
    projectCatalogResource: new CatalogResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceCatalogDialog);
