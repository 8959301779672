import React, { Component } from "react";
import { connect } from "react-redux";
import { instanceOf, number, oneOf, shape, string } from "prop-types";
import { Link } from "react-router";
import { FormattedMessage } from "react-intl";
import { Menu, Dropdown } from "semantic-ui-react";
import { find } from "lodash";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import { getCatalogs } from "shared/selectors";
import { I18nShape } from "shared/shapes/i18n.shape";
import { RoomBookShape } from "shared/components/configurator/roomBook.shape";
import copyToClipboard from "copy-to-clipboard";
import { If } from "shared/components/elements/Conditions";
import HasAccessProjectCatalogRight from "shared/components/authorization/HasAccessProjectCatalogRight";
import RoomBookSortOrderDialog from "./RoomBookSortOrderDialog";
import RoomBookPositionDialog from "./RoomBookPositionDialog";
import "./sectionOrderControl.scss";
import IsVersionHistoryAccessible from "../../../shared/components/elements/IsVersionHistoryAccessible";

class SectionOrderControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  handleDuplicate = () => {
    this.setState({ isLoading: true }, () => {
      const { ctrl, element } = this.props;
      return ctrl
        .duplicateSection(element)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  handleDecreasePosition = () => {
    const { element } = this.props;
    return this.handleMove(element.position - 1);
  };

  handleIncreasePosition = () => {
    const { element } = this.props;
    return this.handleMove(element.position + 1);
  };

  handleMove = newPosition => {
    const { ctrl, type } = this.props;
    if (type === "lineItem") {
      return this.reorder(newPosition, ctrl.moveLineItem);
    }
    if (type === "subLineItem") {
      return this.reorder(newPosition, ctrl.moveSubLineItem);
    }
    return this.reorder(newPosition, ctrl.moveSection);
  };

  reorder = (newPosition, func) => {
    const { element } = this.props;
    this.setState({ isLoading: true });
    return func(element, newPosition).then(() => {
      this.setState({ isLoading: false });
    });
  };

  handleClipboardCopy = (e, url) => {
    e.preventDefault();
    copyToClipboard(url);
  };

  renderClipboardCopy = itemId => {
    const { roomBook, type } = this.props;
    const { id, project_id, unit_id, template } = roomBook;

    if (!project_id) return null;

    const name = type === "subLineItem" ? "sub-line-item" : "item";
    const url = template
      ? `${window.location.origin}/projects/${project_id}/room_books/${id}#${name}-${itemId}`
      : `${window.location.origin}/projects/${project_id}/units/${unit_id}/room-book#${name}-${itemId}`;
    return (
      <Dropdown.Item
        icon="clipboard outline"
        id={`line-item-url${itemId}`}
        text={<FormattedMessage id="meta.actions.clipboard" />}
        onClick={e => this.handleClipboardCopy(e, url)}
      />
    );
  };

  renderClipboardIdCopy = itemId => {
    return (
      <Dropdown.Item
        icon="clipboard outline"
        id={`line-item-id${itemId}`}
        text={<FormattedMessage id="roomBook.lineItems.actions.copyId" />}
        onClick={e => this.handleClipboardCopy(e, itemId)}
      />
    );
  };

  renderSortActions() {
    const { itemsInGroup, element, i18n, ctrl, roomBook, type } = this.props;

    return (
      <>
        {type === "secondarySection" && (
          <Dropdown.Item
            icon="copy"
            data-component="duplicate-line-item"
            onClick={this.handleDuplicate}
            text={
              <FormattedMessage
                id="meta.actions.clone"
                defaultMessage="Duplizieren"
              />
            }
          />
        )}
        {element.position > 1 && (
          <Dropdown.Item
            icon="caret up"
            data-component="sort-position-up"
            onClick={this.handleDecreasePosition}
            text={
              <FormattedMessage
                id="roomBook.lineItems.actions.increasePosition"
                defaultMessage="Nach oben verschieben"
              />
            }
          />
        )}
        {element.position < itemsInGroup && (
          <Dropdown.Item
            icon="caret down"
            data-component="sort-position-down"
            onClick={this.handleIncreasePosition}
            text={
              <FormattedMessage
                id="roomBook.lineItems.actions.decreasePosition"
                defaultMessage="Nach unten verschieben"
              />
            }
          />
        )}
        <RoomBookPositionDialog
          i18n={i18n}
          itemsInGroup={itemsInGroup}
          position={element.position}
          button={
            <Dropdown.Item
              data-component="sort-position"
              icon="sort"
              text={
                <FormattedMessage
                  id="roomBook.lineItems.actions.sort"
                  defaultMessage="Produkt verschieben"
                />
              }
            />
          }
          onSubmit={this.handleMove}
        />
        {!(["primarySection", "subLineItem"].indexOf(type) >= 0) && (
          <RoomBookSortOrderDialog
            i18n={i18n}
            element={element}
            ctrl={ctrl}
            type={type}
            roomBook={roomBook}
            button={
              <Dropdown.Item
                data-component="order-section"
                icon="outdent"
                text={
                  <FormattedMessage
                    id="roomBook.lineItems.actions.sortSection"
                    defaultMessage="Abschnitt Einordnen"
                  />
                }
              />
            }
          />
        )}

        <If condition={type !== "primarySection"}>
          <>
            {this.renderClipboardCopy(element.id)}
            {this.renderClipboardIdCopy(element.id)}
          </>
        </If>
      </>
    );
  }

  renderVersionsLink() {
    const { element } = this.props;
    let type = "RoomBookSection";
    if (element.sub_line_items) type = "RoomBookLineItem";
    if (!element.line_items && !element.sub_line_items)
      type = "RoomBookSubLineItem";
    return (
      <Dropdown.Item
        as={Link}
        onlyActiveOnIndex={false}
        target="_versions"
        to={`/versions?type=${type}&id=${element.id}`}
        icon="linkify"
        text={<FormattedMessage id="versions.title" />}
      />
    );
  }

  renderLinkActions() {
    const { element, supplierId, currentProjectCatalog, roomBook } = this.props;
    const { product_id: productId, product_group_id: productGroupId } = element;

    return (
      <>
        <Dropdown.Divider />
        {productId > 0 && supplierId && (
          <Dropdown.Item
            as={Link}
            onlyActiveOnIndex={false}
            target="_catalog"
            to={`/products/${supplierId}/${productId}`}
            icon="linkify"
            text={
              <FormattedMessage
                id="roomBook.lineItems.actions.linkToCatalogProduct"
                defaultMessage="Ausgewähltes Produkt im Katalog öffnen"
              />
            }
          />
        )}
        {/* trades[0] is fine here, the URL does not care about the correct trade */}
        {/* While the URL will technically work, there might be a mismatch of trade menu and product group as a result */}
        <HasAccessProjectCatalogRight>
          {roomBook.project_id > 0 &&
            productGroupId > 0 &&
            element.trades[0] && (
              <Dropdown.Item
                as={Link}
                onlyActiveOnIndex={false}
                target="_catalog"
                to={`/products/${currentProjectCatalog?.slug}/product_groups/${element.trades[0]}/${productGroupId}?price_catalog_id=${roomBook.price_catalog_id}`}
                icon="linkify"
                text={
                  <FormattedMessage
                    id="roomBook.lineItems.actions.linkToCatalogProductGroup"
                    defaultMessage="Produktgruppe im Katalog öffnen"
                  />
                }
              />
            )}
        </HasAccessProjectCatalogRight>
        <IsVersionHistoryAccessible>
          {this.renderVersionsLink()}
        </IsVersionHistoryAccessible>
      </>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Menu icon compact className="borderless outer">
        <Dropdown
          icon="ellipsis vertical"
          data-component="context-menu"
          loading={isLoading}
          pointing="top right"
          item
        >
          <Dropdown.Menu>
            {this.renderSortActions()}
            {this.renderLinkActions()}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  }
}

SectionOrderControl.propTypes = {
  ctrl: instanceOf(RoomBookController).isRequired,
  type: oneOf(["primarySection", "secondarySection", "lineItem", "subLineItem"])
    .isRequired,
  i18n: I18nShape.isRequired,
  element: shape({ id: number, position: number }).isRequired,
  supplierId: string,
  itemsInGroup: number.isRequired,
  roomBook: RoomBookShape.isRequired,
  currentProjectCatalog: shape({ slug: string })
};

SectionOrderControl.defaultProps = {
  supplierId: undefined,
  currentProjectCatalog: undefined
};

const mapStateToProps = (state, props) => {
  const projectCatalogs = getCatalogs(state);
  const currentProjectCatalog = find(
    projectCatalogs,
    cat => cat.id === props.roomBook.project_catalog_id
  );

  return { currentProjectCatalog };
};

export default connect(mapStateToProps)(SectionOrderControl);
