import PropTypes from "prop-types";
import React from "react";
import { Segment, Loader } from "semantic-ui-react";

class ZendeskLogout extends React.Component {
  componentDidMount() {
    window.location.href = "/";
  }

  render() {
    return (
      <Segment>
        <div style={{ margin: "100px" }}>
          <Loader active />
        </div>
      </Segment>
    );
  }
}

ZendeskLogout.propTypes = {
  i18n: PropTypes.object,
  actions: PropTypes.object
};

export default ZendeskLogout;
