import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { BuyerShape } from "shared/shapes/buyer.shape";
import BuyerInvitationDialog from "./BuyerInvitationDialog";
import { buyerInvitationStatus } from "../../helpers/buyerInvitationStatus";

class BuyerInvitationStatus extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    buyers: PropTypes.arrayOf(BuyerShape),
    enabled: PropTypes.bool,
    actions: PropTypes.object,
    onUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { buyers, enabled, i18n, actions, onUpdate } = this.props;

    if (enabled) {
      const button = (
        <Button
          id="invite-buyer"
          basic
          color={buyerInvitationStatus.color(buyers)}
          icon={buyerInvitationStatus.icon(buyers)}
          data-active={buyerInvitationStatus.status(buyers)}
          content="Online-Konfigurator"
        />
      );

      return (
        <BuyerInvitationDialog
          button={button}
          buyers={buyers}
          i18n={i18n}
          onUpdate={onUpdate}
          actions={actions}
        />
      );
    }
    return <div />;
  }
}

export default BuyerInvitationStatus;
