/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Header, List, Segment, Grid, Button, Icon } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import ProjectQuestionGroupsResource from "builder_portal/actions/projectQuestionGroupsActions";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ProjectQuestionGroupDialog from "./ProjectQuestionGroupDialog";
import ProjectQuestionsList from "./ProjectQuestionsList";
import { ProjectQuestionsLoaderContext } from "./ProjectQuestionsLoader";

const ProjectQuestionGroups = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    projectQuestionGroups,
    projectId,
    isLoading,
    openedGroups,
    setOpenedGroups,
    loadData
  } = useContext(ProjectQuestionsLoaderContext);

  const toggleBundles = groupId => {
    const index = openedGroups.indexOf(groupId);
    if (index === -1) {
      setOpenedGroups(openedGroups.concat(groupId));
    } else {
      const temp = [...openedGroups];
      temp.splice(index, 1);
      setOpenedGroups(temp);
    }
  };

  const deleteProjectQuestion = id => {
    return new ProjectQuestionGroupsResource(dispatch, projectId)
      .remove(id)
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.deleted"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderDeleteButton = model => {
    const button = (
      <a
        role="button"
        id="project_question-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          deleteProjectQuestion(model.id).catch(() => {
            handleClose();
          });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="roomBook.projectQuestionGroups.removeConfirmation.title"
        message="roomBook.projectQuestionGroups.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleDuplicate = id => {
    return new ProjectQuestionGroupsResource(dispatch, projectId)
      .save({ prototype_group_id: id, name: undefined })
      .then(() => {
        loadData();
        new Growl(dispatch).success(
          "message.success.title",
          "meta.states.saving"
        );
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderProjectQuestionGroup = item => {
    return (
      <Segment key={item.id} id={`project_questions-${item.id}`}>
        <Grid stackable verticalAlign="middle">
          <Grid.Column width={1}>
            <If condition={openedGroups.indexOf(item.id) > -1}>
              <Button
                icon="folder open outline"
                size="tiny"
                data-tooltip="Hide upgrade bundles"
                basic
                onClick={() => toggleBundles(item.id)}
              />
            </If>
            <If condition={openedGroups.indexOf(item.id) === -1}>
              <Button
                icon="folder outline"
                size="tiny"
                basic
                onClick={() => toggleBundles(item.id)}
                data-tooltip="Show upgrade bundles"
              />
            </If>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header as="h5">{item.name}</Header>
          </Grid.Column>
          <Grid.Column width={7}>{item.title}</Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <div className="flex gap-1 justify-content-end align-items-center">
              <VersionHistoryLink
                id={item.id}
                type="ProjectQuestionGroup"
                style={{
                  fontSize: "1rem",
                  lineHeight: "0.75rem"
                }}
              />
              <ProjectQuestionGroupDialog
                model={item}
                total={projectQuestionGroups.length || 0}
                button={
                  <a
                    role="button"
                    id="project_question-edit"
                    loading={isLoading}
                    disabled={isLoading}
                    data-tooltip={intl.formatMessage({
                      id: "meta.actions.edit"
                    })}
                  >
                    <Icon name="setting" color="grey" />
                  </a>
                }
              />
              <a
                role="button"
                id="project_question-duplicate"
                loading={isLoading}
                disabled={isLoading}
                data-tooltip={intl.formatMessage({
                  id: "meta.actions.duplicate"
                })}
                onClick={() => handleDuplicate(item.id)}
              >
                <Icon name="copy" color="grey" />
              </a>
              {renderDeleteButton(item)}
            </div>
          </Grid.Column>
        </Grid>
        <If
          condition={openedGroups.indexOf(item.id) > -1}
          styles={{ display: "block" }}
        >
          <ProjectQuestionsList groupId={item.id} />
        </If>
      </Segment>
    );
  };

  const renderProjectQuestionGroups = () => {
    if (!projectQuestionGroups.length && !isLoading) {
      return (
        <Header as="h5">
          <FormattedMessage
            id="roomBook.upgreadeBundleGroups.notFound"
            defaultMessage="Es liegen keine Upgrade Pakete zu diesem Projekt vor"
          />
        </Header>
      );
    }

    return projectQuestionGroups.map(renderProjectQuestionGroup);
  };

  return (
    <>
      <Grid
        stackable
        data-component="project_question_groups"
        className="margin top bottom medium"
      >
        <Grid.Column width="12">
          <Header size="large" style={{ marginBottom: "1em" }}>
            <FormattedMessage
              id="roomBook.projectQuestions.title"
              defaultMessage="roomBook.projectQuestions.title"
            />
          </Header>
          <p className="description">
            <FormattedMessage
              id="roomBook.projectQuestions.description"
              defaultMessage="roomBook.projectQuestions.description"
            />
          </p>
        </Grid.Column>
        <Grid.Column textAlign="right" width="4" verticalAlign="top">
          <ProjectQuestionGroupDialog
            model={{
              name: "",
              title: "",
              position: (projectQuestionGroups.length || 0) + 1
            }}
            total={(projectQuestionGroups.length || 0) + 1}
            button={
              <Button
                id="upgrade-bundle-group-new"
                icon="plus"
                fluid
                content={intl.formatMessage({
                  id: "roomBook.upgreadeBundleGroups.add"
                })}
                loading={isLoading}
                disabled={isLoading}
              />
            }
          />
        </Grid.Column>
      </Grid>

      <List divided size="big" data-component="project_question_items">
        {renderProjectQuestionGroups()}
      </List>
    </>
  );
};
export default ProjectQuestionGroups;
