import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Dropdown, Icon } from "semantic-ui-react";
import { ProjectActivitiesResource } from "builder_portal/actions/projectActions";
import { getContractors } from "shared/selectors";
import { arrayOf, number } from "prop-types";

const GenerateMultiplePreviewButton = ({
  contractorIds,
  projectId,
  activityId
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const contractors = useSelector(getContractors);

  const openPreview = contractorId => {
    new ProjectActivitiesResource(dispatch, projectId)
      .getPriceInquiryPreviewUrl(activityId, contractorId)
      .then(res => {
        if (res.status === 200) {
          const { data } = res;
          window.open(data.preview_url, "sw_preview");
        }
      });
  };

  const dropdownItems = useMemo(
    () => (
      <Dropdown.Menu>
        {contractors
          .filter(c => contractorIds.indexOf(c.id) > -1)
          .map(c => (
            <Dropdown.Item onClick={() => openPreview(c.id)} key={c.id}>
              <Icon name="eye" className="left" />
              {`${c.contact_person} (${c.name})`}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    ),
    [contractors]
  );

  return (
    <Dropdown
      data-id="contractor-options"
      button
      className="icon"
      floating
      labeled
      icon="truck"
      text={intl.formatMessage({ id: "activity.preview.selectAndOpen" })}
      style={{ paddingTop: ".5em", paddingBottom: ".5em" }}
    >
      {dropdownItems}
    </Dropdown>
  );
};

GenerateMultiplePreviewButton.propTypes = {
  contractorIds: arrayOf(number).isRequired,
  projectId: number.isRequired,
  activityId: number.isRequired
};

export default GenerateMultiplePreviewButton;
