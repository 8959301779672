import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Button } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import { getAccountProjects } from "shared/selectors/account";
import { cloneDeep } from "lodash";
import { node, number, shape, func } from "prop-types";
import {
  getDocumentExports,
  getDocumentStyles,
  getDocumentSignatures
} from "../../../selectors/documentExports";

const AssignmentDialog = ({ button, originalAssignment, onSubmit }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignment, setAssignment] = useState({});

  const projects = useSelector(getAccountProjects);
  const documentExports = useSelector(getDocumentExports);
  const documentStyles = useSelector(getDocumentStyles);
  const documentSignatures = useSelector(getDocumentSignatures);

  useEffect(() => {
    if (originalAssignment) setAssignment(cloneDeep(originalAssignment));
  }, [originalAssignment]);

  const handleChange = (_, { id, value }) => {
    setAssignment({ ...assignment, [id]: value });
  };

  const handleClose = () => {
    setOpen(false);
    if (!originalAssignment) setAssignment({});
  };

  const handleSubmit = () => {
    setLoading(true);

    onSubmit(assignment)
      .then(() => {
        setLoading(false);
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
        handleClose();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const projectOptions = useMemo(() => {
    return projects.map(x => {
      return {
        key: x.id,
        value: x.id,
        text: x.name
      };
    });
  }, [projects]);

  const documentExportOptions = useMemo(() => {
    return documentExports.map(x => {
      return {
        key: x.id,
        value: x.id,
        text: x.name
      };
    });
  }, [documentExports]);

  const documentExportStyleOptions = useMemo(() => {
    return documentStyles.map(x => {
      return {
        key: x.id,
        value: x.id,
        text: x.name
      };
    });
  }, [documentStyles]);

  const documentExportHeaderContentOptions = useMemo(() => {
    return documentSignatures.map(x => {
      return {
        key: x.id,
        value: x.id,
        text: x.name
      };
    });
  }, [documentSignatures]);

  const documentExportSignatureOptions = useMemo(() => {
    return documentSignatures.map(x => {
      return {
        key: x.id,
        value: x.id,
        text: x.name
      };
    });
  }, [documentSignatures]);

  const valid =
    assignment.project_id &&
    assignment.document_export_id &&
    assignment.document_export_style_id;

  return (
    <Modal
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      trigger={button}
      closeIcon
    >
      <Modal.Header>
        <FormattedMessage
          id={`documentsExport.dialogs.assignments.title.${
            originalAssignment ? "edit" : "create"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Form id="assignmenet">
          <Form.Select
            id="project_id"
            value={assignment.project_id}
            label={intl.formatMessage({
              id: "documentsExport.dialogs.assignments.placeholders.project"
            })}
            onChange={handleChange}
            options={projectOptions}
            clearable
          />
          <Form.Select
            id="document_export_id"
            value={assignment.document_export_id}
            label={intl.formatMessage({
              id: "documentsExport.dialogs.assignments.placeholders.export"
            })}
            onChange={handleChange}
            options={documentExportOptions}
            clearable
          />
          <Form.Select
            id="document_export_style_id"
            value={assignment.document_export_style_id}
            label={intl.formatMessage({
              id: "documentsExport.dialogs.assignments.placeholders.style"
            })}
            onChange={handleChange}
            options={documentExportStyleOptions}
            clearable
          />
          <Form.Select
            id="document_export_content_header_id"
            value={assignment.document_export_content_header_id}
            label={intl.formatMessage({
              id: "documentsExport.dialogs.assignments.placeholders.header"
            })}
            onChange={handleChange}
            options={documentExportHeaderContentOptions}
            clearable
          />
          <Form.Select
            id="document_export_signature_id"
            value={assignment.document_export_signature_id}
            label={intl.formatMessage({
              id: "documentsExport.dialogs.assignments.placeholders.signature"
            })}
            onChange={handleChange}
            options={documentExportSignatureOptions}
            clearable
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          id="cancel"
          content={intl.formatMessage({ id: "meta.actions.cancel" })}
          onClick={handleClose}
          loading={loading}
        />
        <Button
          primary
          id="submit"
          content={
            originalAssignment
              ? intl.formatMessage({ id: "meta.actions.save" })
              : intl.formatMessage({ id: "meta.actions.add" })
          }
          onClick={handleSubmit}
          disabled={!valid}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

AssignmentDialog.propTypes = {
  button: node.isRequired,
  originalAssignment: shape({
    project_id: number,
    document_export_id: number,
    document_export_style_id: number,
    document_export_content_header_id: number,
    document_export_signature_id: number
  }),
  onSubmit: func.isRequired
};

AssignmentDialog.defaultProps = {
  originalAssignment: null
};

export default AssignmentDialog;
