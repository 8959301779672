import React, { useState, useEffect } from "react";
import { Segment, Header, Grid, Button, Input, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import WysiwygEditor from "shared/components/elements/wysiwyg/WysiwygEditor";
import DOMPurify from "dompurify";
import { isEqual } from "lodash";
import { arrayOf, func, string, shape } from "prop-types";
import { jumpToElement } from "shared/helpers/scrollTo";

const EMPTY_FAQ = { question: "", answer: "" };

const Faqs = ({ data, onChange }) => {
  const [editing, setEditing] = useState(-1);
  const [showNewForm, setShowNewForm] = useState(false);
  const [newFaq, setNewFaq] = useState(EMPTY_FAQ);

  const handleChange = (value, name, index) => {
    const temp = [...data];
    temp[index][name] = value;
    onChange("faqs", temp);
  };

  const handleAdd = e => {
    e.preventDefault();
    setEditing(-1);
    setShowNewForm(true);
  };

  useEffect(() => {
    if (showNewForm) jumpToElement("#new-question");
  }, [showNewForm]);

  const moveItem = (direction, index) => {
    if (data.length === 1) return;
    if (direction === "up" && index === 0) return;
    if (direction === "down" && index === data.length - 1) return;
    setEditing(-1);
    const newPosition = direction === "up" ? index - 1 : index + 1;
    const reorderd = [...data];
    const temp = reorderd[index];
    reorderd[index] = reorderd[newPosition];
    reorderd[newPosition] = temp;
    onChange("faqs", reorderd);
  };

  const deleteItem = index => {
    const temp = data.filter((_, i) => i !== index);
    onChange("faqs", temp);
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <div key={item.question}>
            <div htmlFor="bodyHtml" style={{ padding: "5px" }}>
              <If condition={editing !== index}>
                <Header as="h5">
                  {index + 1}. {item.question}
                </Header>
              </If>
              <If condition={editing === index}>
                <Input
                  name="question"
                  defaultValue={item.question}
                  onBlur={({ target: { value } }) =>
                    handleChange(value, "question", index)
                  }
                />
              </If>
            </div>
            <Grid>
              <Grid.Column width={12}>
                <If condition={editing !== index}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(0,0,0,0.05)",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      fontSize: "0.9rem"
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.answer)
                    }}
                  />
                </If>
                <If condition={editing === index}>
                  <WysiwygEditor
                    value={item.answer}
                    name="answer"
                    buttonsHide={["html"]}
                    onChange={d => handleChange(d.value, "answer", index)}
                  />
                </If>
              </Grid.Column>

              <Grid.Column width={4} textAlign="right">
                <Button
                  basic
                  size="tiny"
                  icon="edit"
                  disabled={editing === index}
                  onClick={e => {
                    e.preventDefault();
                    setEditing(index);
                  }}
                />
                <Button
                  basic
                  size="tiny"
                  icon="check"
                  disabled={editing !== index}
                  onClick={e => {
                    e.preventDefault();
                    setEditing(-1);
                  }}
                />
                <Button
                  basic
                  size="tiny"
                  icon="arrow up"
                  onClick={e => {
                    e.preventDefault();
                    moveItem("up", index);
                  }}
                />
                <Button
                  basic
                  size="tiny"
                  icon="arrow down"
                  onClick={e => {
                    e.preventDefault();
                    moveItem("down", index);
                  }}
                />
                <Button
                  basic
                  size="tiny"
                  icon="trash"
                  onClick={e => {
                    e.preventDefault();
                    deleteItem(index);
                  }}
                />
              </Grid.Column>
            </Grid>
          </div>
        );
      })}
      <If condition={showNewForm}>
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h5" id="new-question">
                  <FormattedMessage id="configuratorDesign.dialog.newQuestion" />
                </Header>
                <Input
                  name="newQuestion"
                  value={newFaq.question}
                  onChange={({ target: { value } }) =>
                    setNewFaq(prev => ({ ...prev, question: value }))
                  }
                  style={{ width: "100%" }}
                />
                <Header as="h5">
                  <FormattedMessage id="configuratorDesign.dialog.answer" />
                </Header>
                <WysiwygEditor
                  value={newFaq.answer}
                  name="newAnswer"
                  buttonsHide={["html"]}
                  onChange={d =>
                    setNewFaq(prev => ({ ...prev, answer: d.value }))
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button
                  onClick={e => {
                    e.preventDefault();
                    setNewFaq(EMPTY_FAQ);
                    setTimeout(() => {
                      setShowNewForm(false);
                    }, 200);
                  }}
                >
                  <FormattedMessage id="meta.actions.cancel" />
                </Button>
                <Button
                  basic={isEqual(EMPTY_FAQ, newFaq)}
                  color={!isEqual(EMPTY_FAQ, newFaq) ? "primary" : "grey"}
                  onClick={e => {
                    e.preventDefault();
                    const temp = [...data];
                    temp[data.length] = newFaq;
                    onChange("faqs", temp);
                    setNewFaq(EMPTY_FAQ);
                    setShowNewForm(false);
                  }}
                >
                  <Icon name="check" />
                  <FormattedMessage id="meta.actions.ok" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </If>
      <Grid>
        <Grid.Column textAlign="right">
          <Button onClick={handleAdd} disabled={showNewForm}>
            <Icon name="plus" />
            <FormattedMessage id="meta.actions.add" />
          </Button>
        </Grid.Column>
      </Grid>
    </>
  );
};

Faqs.propTypes = {
  data: arrayOf(
    shape({
      question: string,
      answer: string
    })
  ).isRequired,
  onChange: func.isRequired
};

export default Faqs;
