import { arrayOf, bool, instanceOf } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Accordion,
  Button,
  Container,
  Form,
  Header,
  Icon,
  Image,
  Message,
  Popup,
  Segment,
  Table,
  Checkbox
} from "semantic-ui-react";
import { get, sortBy } from "lodash";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import { ProjectCatalogShape, ProjectWithContentShape } from "shared/shapes";
import JsonEditor from "shared/components/forms/JsonEditor";
import { getAccount, getCatalogsForProject } from "shared/selectors";
import { Account } from "shared/models/account";
import CatalogResource from "builder_portal/actions/catalogActions";
import { I18nShape } from "shared/shapes/i18n.shape";
import ProductMapping from "./ProductMapping";
import ProjectsResource from "../../../actions/projectActions";
import { AccountResource } from "../../../actions/accountActions";
import Growl from "../../../actions/growlActions";
import ImageSelector from "./ImageSelector";
import FileUploader from "../../dropzone/FileUploader";
import get3dOptions from "../../../helpers/get3dOptions";
import ProjectRoomsSegment from "./ProjectRoomsSegment";
import silentHandleApiRequestErrors from "../../../../shared/helpers/silentHandleApiRequestErrors";
import "./projectTab.scss";
import FeatureToggle from "../../../../shared/components/elements/FeatureToggle";
import ConfiguratorBackendsSegment from "./ConfiguratorBackendsSegment";
import loadProductGroupsForMapping from "./products/loadProductGroupsForMapping";
import { If } from "../../../../shared/components/elements/Conditions";
import FeatureToggleProvider from "../../../../shared/components/elements/FeatureToggleProvider";
import IsSystemAdminOrHasSpecificRight from "../../../../shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import FlatFinderSegment from "./flat-finder/FlatFinderSegment";
import ConfiguratorDesign from "./ConfiguratorDesign";

const ProjectTabV1 = ({
  projectsResource,
  i18n,
  isAdmin,
  project,
  catalogsResource,
  catalogs
}) => {
  const STORAGE = get(window, "localStorage", null);
  const [content, setContent] = useState({
    sections: [],
    glencoeURL: ""
  });
  const legacyConfigurators = false;
  const [displayProductMapping, setDisplayProductMapping] = useState(false);
  const [currentProject, setCurrentProject] = useState({
    _errors: {},
    _touched: {},
    ...project
  });

  const [configOpen, setConfigOpen] = useState(false);
  const [paramMapping, setParamMapping] = useState({});
  const [productGroups, setProductGroups] = useState([]);
  const [loadingArray, setLoadingArray] = useState([]);
  const isLoading = part => loadingArray.includes(part);
  const startLoading = part =>
    setLoadingArray(prevLoading => [...prevLoading, part]);
  const stopLoading = part =>
    setLoadingArray(prevLoading => prevLoading.filter(value => value !== part));

  const [dirtyForm, setDirtyForm] = useState(false);
  const [configuratorSectionsOpen, setConfiguratorSectionsOpen] = useState(
    false
  );

  const reloadContent = () => {
    startLoading("content");

    projectsResource
      .fetchBuyerPortalContent(currentProject.id)
      .then(({ data }) => {
        setContent(data.buyer_portal_content);
        setCurrentProject({
          ...currentProject,
          content: data.buyer_portal_content
        });
        stopLoading("content");
      });
  };
  useEffect(reloadContent, [currentProject.id]);

  useEffect(() => {
    const { glencoeURL } = content;

    if (!catalogs.length) {
      startLoading("catalogs");
      catalogsResource
        .fetchAll()
        .then(() => stopLoading("catalogs"))
        .catch(silentHandleApiRequestErrors);
    }

    if (glencoeURL) {
      startLoading("param_mapping");
      fetch(`${glencoeURL}/param_mapping`)
        .then(res => res.json())
        .then(data => {
          setParamMapping(data);
          stopLoading("param_mapping");
        })
        .catch(silentHandleApiRequestErrors);
    }
  }, [currentProject.id]);

  useEffect(() => {
    setProductGroups([]);
    startLoading("product-groups");

    loadProductGroupsForMapping(catalogsResource, catalogs).then(groups => {
      setProductGroups(groups);
      stopLoading("product-groups");
    });
  }, [catalogs]);

  const handleUpload = async (file, role) => {
    await startLoading(role);
    const token = get(STORAGE, "token", null);
    const formData = new FormData();
    formData.append(`image[role]`, role);
    formData.append(`image[image]`, file);

    await fetch(`/api/v1/projects/${currentProject.id}/${role}`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        if (response.status === 201) {
          reloadContent();
        }
      })
      .then(() => stopLoading(role));
  };

  const handleDelete = async role => {
    await startLoading(role);
    await projectsResource
      .removeAttachable(currentProject.id, role)
      .then(reloadContent)
      .then(() => stopLoading(role));
  };

  const onSaveProject = p => {
    startLoading("project");

    const payload = {
      id: p.id,
      buyer_portal_content_json: p.content
    };

    return projectsResource.save(payload).then(() => {
      setDirtyForm(false);
      stopLoading("project");
    });
  };

  const formDefinition = new FormDefinition({
    fields: [
      {
        id: "branding_color",
        accessor: "content.branding_color",
        label: "account.settings.buyer_portal.project_tab.branding_color",
        placeholder: "#95C120",
        rule: "isHexColorCodeOrEmpty"
      },
      {
        id: "branding_contrast_color",
        accessor: "content.branding_contrast_color",
        label:
          "account.settings.buyer_portal.project_tab.branding_contrast_color",
        placeholder: "#F5F8FA",
        rule: "isHexColorCodeOrEmpty"
      },
      {
        id: "warning_color",
        accessor: "content.warning_color",
        label: "account.settings.buyer_portal.project_tab.warning_color",
        placeholder: "#BB3132",
        rule: "isHexColorCodeOrEmpty"
      },
      {
        id: "styles",
        accessor: "content.styles",
        label: "project.attributes.buyer_portal.styles.label"
      },
      {
        id: "additional_html",
        accessor: "content.additional_html",
        label: "project.attributes.buyer_portal.additional_html.title",
        placeholder: "project.attributes.buyer_portal.additional_html.label"
      },
      {
        id: "configurators",
        accessor: "content.configurators",
        label: "project.attributes.buyer_portal.configurators.label"
      },
      {
        id: "glencoeURL",
        accessor: "content.glencoeURL",
        label: "project.attributes.buyer_portal.glencoeURL.label"
      },
      {
        id: "product_mapping",
        accessor: "content.product_mapping",
        label: "project.attributes.buyer_portal.product_mapping.label",
        default: {}
      },
      {
        id: "product_lines",
        accessor: "content.product_lines",
        label: "project.attributes.buyer_portal.product_lines.label"
      },
      {
        id: "units_menu_item_text",
        accessor: "content.units_menu_item_text",
        label:
          "account.settings.buyer_portal.project_tab.units_menu_item_text.label",
        placeholder:
          "account.settings.buyer_portal.project_tab.units_menu_item_text.placeholder"
      }
    ].concat(
      content.sections.reduce((accu, section, idx) => {
        return accu.concat([
          {
            id: `sections_id_${idx}`,
            accessor: `content.sections[${idx}].id`,
            label: "project.attributes.buyer_portal.sections.id.label"
          },
          {
            id: `sections_thumb_url_${idx}`,
            accessor: `content.sections[${idx}].thumb_url`,
            label: "project.attributes.buyer_portal.sections.thumb_url.label"
          },
          {
            id: `sections_group_${idx}`,
            accessor: `content.sections[${idx}].group`,
            label: "project.attributes.buyer_portal.sections.group.label"
          },
          {
            id: `sections_description_${idx}`,
            accessor: `content.sections[${idx}].description`,
            label: "project.attributes.buyer_portal.sections.description.label"
          },
          {
            id: `sections_visible_${idx}`,
            accessor: `content.sections[${idx}].visible`,
            label: "project.attributes.buyer_portal.sections.visible.label",
            control: "Checkbox"
          },
          {
            id: `sections_configurator_${idx}`,
            accessor: `content.sections[${idx}].configurator`,
            label: "project.attributes.buyer_portal.sections.configurator.label"
          }
        ]);
      }, [])
    )
  });

  const form = formDefinition.create(currentProject, i18n, {
    onChange: data => {
      setCurrentProject(data);
      setDirtyForm(true);
    }
  });

  const configOptions = get3dOptions(form.fields.configurators.props.value);

  const sortedSections = sortBy(content.sections, ele => {
    return ele.id;
  });

  const sectionForms = sortedSections.reduce((accu, section) => {
    const isMainSection = section.id.indexOf("::") < 0;
    const idx = content.sections.indexOf(section);
    form.fields[`sections_description_${idx}`].props.rows = 2;
    form.fields[
      `sections_thumb_url_${idx}`
    ].props.projectsResource = projectsResource;
    form.fields[`sections_thumb_url_${idx}`].props.projectId =
      currentProject.id;
    form.fields[`sections_configurator_${idx}`].props = {
      ...form.fields[`sections_configurator_${idx}`].props,
      selection: true,
      clearable: true,
      options: configOptions
    };

    return accu.concat([
      <Table.Row key={`${section.id}-title`}>
        <Table.Cell colSpan="2">
          <Header as="h4">
            <Icon name={section.visible ? "unhide" : "hide"} />
            {section.id.split("::").join(" / ")}
          </Header>
        </Table.Cell>
      </Table.Row>,
      <Table.Row key={section.id}>
        {!!section.thumb_url && (
          <Table.Cell>
            <Image src={section.thumb_url} size="medium" alt={section.name} />
          </Table.Cell>
        )}
        {!section.thumb_url && (
          <Table.Cell textAlign="center">
            <Icon name="image" size="huge" />
          </Table.Cell>
        )}
        <Table.Cell>
          {isMainSection && isAdmin && (
            <Form.Field>
              <Field
                component="Input"
                {...form.fields[`sections_group_${idx}`]}
              />
            </Form.Field>
          )}
          <Form.Field>
            <Field
              component={ImageSelector}
              {...form.fields[`sections_thumb_url_${idx}`]}
            />
          </Form.Field>
          <Form.Field>
            <Field
              component="Checkbox"
              {...form.fields[`sections_visible_${idx}`]}
            />
          </Form.Field>
          <Form.Field>
            <Field
              component="Dropdown"
              {...form.fields[`sections_configurator_${idx}`]}
            />
          </Form.Field>
        </Table.Cell>
      </Table.Row>
    ]);
  }, []);

  return (
    <Segment
      key={`project-${currentProject.id}`}
      data-component="buyerPortalContent"
      loading={isLoading("project")}
    >
      <Segment basic size="small" loading={isLoading("logo")}>
        <Header as="h4" className="section-header project-logo">
          <Header.Content>
            <FormattedMessage id="account.settings.buyer_portal.project_tab.project_logo_url.header" />
            <Popup
              position="top center"
              trigger={<Icon name="info" circular color="grey" size="small" />}
            >
              <Popup.Content>
                <FormattedMessage
                  id="account.settings.buyer_portal.project_tab.project_logo_url.info"
                  defaultMessage="Das Projektlogo wird über das Bildbanner im Online Konfigurator gelegt. Es ersetzt nicht das Firmenlogo, das in der Menüleiste oben links angezeigt wird."
                />
              </Popup.Content>
            </Popup>
          </Header.Content>
        </Header>
        <FileUploader
          handleFile={handleUpload}
          handleDelete={handleDelete}
          fileType="image"
          name="logo"
          previewImageUrl={get(content, "logo_medium_url", "")}
          originalFileUrl={get(content, "logo_large_url", "")}
        />
      </Segment>
      <Segment basic size="small" loading={isLoading("banner_image")}>
        <Header as="h4" className="section-header banner-image">
          <Header.Content>
            <FormattedMessage
              id="account.settings.buyer_portal.project_tab.banner_image_url.header"
              defaultMessage="Bildbanner für den Online Konfigurator"
            />
            <Popup
              position="top center"
              trigger={<Icon name="info" circular color="grey" size="small" />}
            >
              <Popup.Content>
                <FormattedMessage
                  id="account.settings.buyer_portal.project_tab.banner_image_url.info"
                  defaultMessage={`Die ideale Bildgröße liegt um 2000x470 Pixel.\n\nBitte beachten Sie, dass sich die Dateigröße auf die Ladezeit im Online Konfigurator auswirkt.`}
                />
              </Popup.Content>
            </Popup>
          </Header.Content>
        </Header>
        <FileUploader
          handleFile={handleUpload}
          handleDelete={handleDelete}
          fileType="image"
          name="banner_image"
          previewImageUrl={get(content, "banner_image_url", "")}
          originalFileUrl={get(content, "banner_image_url", "")}
        />
      </Segment>
      <Form id="buyerPortalContent" onSubmit={form.handleSubmit(onSaveProject)}>
        <Accordion exclusive={false}>
          <Segment basic size="small">
            <Accordion.Title
              active={configuratorSectionsOpen}
              data-attr="configurator-sections-title"
              onClick={() =>
                setConfiguratorSectionsOpen(!configuratorSectionsOpen)
              }
            >
              <Header as="h4" className="section-header">
                <Icon name="images outline" />
                <Header.Content>
                  <FormattedMessage id="account.settings.buyer_portal.project_tab.configurator_sections" />
                </Header.Content>
              </Header>
              <Icon name="dropdown" className="section-caret" />
            </Accordion.Title>
            <Accordion.Content
              active={configuratorSectionsOpen}
              data-attr="configurator-sections-content"
            >
              <Table>
                <Table.Body>{sectionForms}</Table.Body>
              </Table>
            </Accordion.Content>
          </Segment>
        </Accordion>
        <Segment basic data-component="AdminConfigSegment">
          <IsSystemAdminOrHasSpecificRight right="ext_allow_edit_configurator_config">
            <Header as="h3">
              <FormattedMessage
                id="account.attributes.buyer_portal.admin_area"
                defaultMessage="Adminbereich"
              />
            </Header>
            <Segment basic size="small">
              <Accordion.Title
                active={configOpen}
                index="config"
                data-attr="config-title"
                onClick={() => setConfigOpen(!configOpen)}
              >
                <Header as="h4" className="section-header">
                  <Icon name="cogs" />
                  <Header.Content>
                    <FormattedMessage id="account.settings.buyer_portal.project_tab.project_configuration" />
                  </Header.Content>
                </Header>
                <Icon name="dropdown" className="section-caret" />
              </Accordion.Title>
              <Accordion.Content active={configOpen} data-attr="config-content">
                <FeatureToggle
                  featureToggleName="bp_configurator_design_admin_20"
                  disabled
                >
                  <Container className="color-inputs">
                    <Form.Field>
                      <Field
                        component="Input"
                        {...form.fields.branding_color}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Field
                        component="Input"
                        {...form.fields.branding_contrast_color}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Field component="Input" {...form.fields.warning_color} />
                    </Form.Field>
                  </Container>
                  <Form.Field>
                    <Field
                      component="Input"
                      {...form.fields.units_menu_item_text}
                    />
                  </Form.Field>
                  <FeatureToggle featureToggleName="ok20" disabled>
                    <Form.Field>
                      <Field component="TextArea" {...form.fields.styles} />
                    </Form.Field>
                    <Form.Field>
                      <Field
                        component="TextArea"
                        {...form.fields.additional_html}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Field
                        component={JsonEditor}
                        {...form.fields.product_lines}
                      />
                    </Form.Field>
                  </FeatureToggle>
                  <If condition={legacyConfigurators}>
                    <Form.Field>
                      <Field
                        component={JsonEditor}
                        {...form.fields.configurators}
                      />
                    </Form.Field>
                  </If>
                  <FeatureToggle featureToggleName="ok20" disabled>
                    <Form.TextArea
                      label="Sections (readonly)"
                      readOnly
                      value={content.sections
                        .map(s => `${s.id} ${s.count}`)
                        .join("\n")}
                    />

                    <Form.TextArea
                      label="Sections-Combos (readonly)"
                      readOnly
                      value={JSON.stringify(content.section_combos, null, 2)}
                    />
                  </FeatureToggle>
                  <If condition={legacyConfigurators}>
                    <Form.Field>
                      <Field component="Input" {...form.fields.glencoeURL} />
                    </Form.Field>
                    <div className="field">
                      <label htmlFor="productMappingToggle">
                        {
                          i18n[
                            "project.attributes.buyer_portal.product_mapping.toggle_label"
                          ]
                        }
                      </label>
                      <Checkbox
                        toggle
                        name="productMappingToggle"
                        onClick={() =>
                          setDisplayProductMapping(!displayProductMapping)
                        }
                      />
                    </div>
                    <Form.Field
                      style={{
                        display: displayProductMapping ? "block" : "none",
                        marginTop: "15px"
                      }}
                    >
                      <Field
                        component={JsonEditor}
                        {...form.fields.product_mapping}
                      />
                    </Form.Field>

                    <ProductMapping
                      isLoading={isLoading}
                      paramMapping={paramMapping}
                      onChange={form.fields.product_mapping.props.onChange}
                      value={form.fields.product_mapping.props.value}
                      productGroupsList={productGroups}
                    />
                  </If>
                </FeatureToggle>
              </Accordion.Content>
            </Segment>
          </IsSystemAdminOrHasSpecificRight>
        </Segment>
      </Form>
      <Message floating warning className="saving-reminder" hidden={!dirtyForm}>
        <Message.Header>
          <FormattedMessage id="account.settings.saving_reminder.header" />
        </Message.Header>
        <Message.Content className="saving-reminder-content">
          <FormattedMessage id="account.settings.saving_reminder.content" />
          <Button
            color="green"
            data-attr="submit-button"
            onClick={form.handleSubmit(onSaveProject)}
            loading={isLoading("project")}
            id="save"
          >
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Message.Content>
      </Message>
    </Segment>
  );
};

ProjectTabV1.propTypes = {
  account: instanceOf(Account).isRequired,
  accountsResource: instanceOf(AccountResource).isRequired,
  projectsResource: instanceOf(ProjectsResource).isRequired,
  project: ProjectWithContentShape.isRequired,
  isAdmin: bool.isRequired,
  growl: instanceOf(Growl).isRequired,
  i18n: I18nShape.isRequired,
  catalogsResource: instanceOf(CatalogResource).isRequired,
  catalogs: arrayOf(ProjectCatalogShape).isRequired
};

const ProjectTabV2 = ({ currentProject }) => {
  const STORAGE = get(window, "localStorage", null);

  const [configOpen, setConfigOpen] = useState(true);
  const [projectRoomsOpen, setProjectRoomsOpen] = useState(false);
  const [configuratorBackendsOpen, setConfiguratorBackendsOpen] = useState(
    false
  );
  const [configuratorSectionsOpen, setConfiguratorSectionsOpen] = useState(
    false
  );
  const [flatFinderOpen, setFlatFinderOpen] = useState(false);

  useEffect(() => {
    setConfiguratorSectionsOpen(
      JSON.parse(get(STORAGE, "configuratorSectionsOpen", "false"))
    );
    setConfigOpen(JSON.parse(get(STORAGE, "configOpen", "true")));
  }, []);

  useEffect(() => {
    STORAGE.setItem(
      "configuratorSectionsOpen",
      JSON.stringify(configuratorSectionsOpen)
    );
  }, [configuratorSectionsOpen]);

  useEffect(() => {
    STORAGE.setItem("configOpen", JSON.stringify(configOpen));
  }, [configOpen]);

  return (
    <Segment
      key={`project-${currentProject.id}`}
      data-component="buyerPortalContent"
    >
      <Segment basic data-component="AdminConfigSegment">
        <IsSystemAdminOrHasSpecificRight right="ext_allow_edit_configurator_config">
          <Header as="h3">
            <FormattedMessage
              id="account.attributes.buyer_portal.admin_area"
              defaultMessage="Adminbereich"
            />
          </Header>
          <Segment basic size="small">
            <Accordion>
              <Accordion.Title
                active={configOpen}
                index="config"
                data-attr="config-title"
                onClick={() => setConfigOpen(!configOpen)}
              >
                <Header as="h4" className="section-header">
                  <Icon name="cogs" />
                  <Header.Content>
                    <FormattedMessage id="account.settings.buyer_portal.project_tab.project_configuration" />
                  </Header.Content>
                </Header>
                <Icon name="dropdown" className="section-caret" />
              </Accordion.Title>
              <Accordion.Content active={configOpen} data-attr="config-content">
                <ConfiguratorDesign projectId={currentProject.id} />
              </Accordion.Content>
              <Accordion.Title
                active={configuratorBackendsOpen}
                index="config"
                data-attr="config-title-configurator-backend"
                onClick={() =>
                  setConfiguratorBackendsOpen(!configuratorBackendsOpen)
                }
              >
                <Header as="h4" className="section-header">
                  <Icon name="cogs" />
                  <Header.Content>
                    <FormattedMessage id="account.settings.buyer_portal.project_tab.configurator_backends" />
                  </Header.Content>
                </Header>
                <Icon name="dropdown" className="section-caret" />
              </Accordion.Title>
              <Accordion.Content
                active={configuratorBackendsOpen}
                data-attr="config-configurator_backends"
              >
                <ConfiguratorBackendsSegment project={currentProject} />
              </Accordion.Content>

              <Accordion.Title
                active={projectRoomsOpen}
                index="config"
                data-attr="config-title-project-rooms"
                onClick={() => setProjectRoomsOpen(!projectRoomsOpen)}
              >
                <Header as="h4" className="section-header">
                  <Icon name="cogs" />
                  <Header.Content>
                    <FormattedMessage id="account.settings.buyer_portal.project_tab.project_rooms" />
                  </Header.Content>
                </Header>
                <Icon name="dropdown" className="section-caret" />
              </Accordion.Title>
              <Accordion.Content
                active={projectRoomsOpen}
                data-attr="config-project-rooms"
              >
                <ProjectRoomsSegment project={currentProject} />
              </Accordion.Content>
              <Accordion.Title
                active={flatFinderOpen}
                index="config"
                data-attr="config-title-flat-finder"
                onClick={() => setFlatFinderOpen(!flatFinderOpen)}
              >
                <Header as="h4" className="section-header">
                  <Icon name="cogs" />
                  <Header.Content>
                    <FormattedMessage id="account.settings.buyer_portal.project_tab.flat_finder" />
                  </Header.Content>
                </Header>
                <Icon name="dropdown" className="section-caret" />
              </Accordion.Title>
              <Accordion.Content
                active={flatFinderOpen}
                data-attr="config-flat-finder"
              >
                <FlatFinderSegment projectId={currentProject.id} />
              </Accordion.Content>
            </Accordion>
          </Segment>
        </IsSystemAdminOrHasSpecificRight>
      </Segment>
    </Segment>
  );
};

ProjectTabV2.propTypes = {
  currentProject: ProjectWithContentShape.isRequired
};

const ProjectTab = ({
  account,
  growl,
  accountsResource,
  projectsResource,
  i18n,
  isAdmin,
  project,
  catalogsResource,
  catalogs
}) => {
  return (
    <FeatureToggleProvider featureToggles={project.features}>
      <FeatureToggle featureToggleName="ok20" disabled>
        <ProjectTabV1
          account={account}
          growl={growl}
          project={project}
          isAdmin={isAdmin}
          i18n={i18n}
          catalogs={catalogs}
          projectsResource={projectsResource}
          accountsResource={accountsResource}
          catalogsResource={catalogsResource}
        />
      </FeatureToggle>
      <FeatureToggle featureToggleName="ok20">
        <ProjectTabV2 currentProject={project} />
      </FeatureToggle>
    </FeatureToggleProvider>
  );
};

ProjectTab.propTypes = {
  account: instanceOf(Account).isRequired,
  accountsResource: instanceOf(AccountResource).isRequired,
  projectsResource: instanceOf(ProjectsResource).isRequired,
  catalogsResource: instanceOf(CatalogResource).isRequired,
  project: ProjectWithContentShape.isRequired,
  isAdmin: bool.isRequired,
  growl: instanceOf(Growl).isRequired,
  i18n: I18nShape.isRequired,
  catalogs: arrayOf(ProjectCatalogShape).isRequired
};

const mapStateToProps = (state, props) => {
  const { project } = props;
  const user = get(state.account, "user");
  const isAdmin = user?.group === "admin";

  return {
    isAdmin,
    i18n: state.i18n,
    account: getAccount(state),
    catalogs: getCatalogsForProject(state, { projectId: project?.id })
  };
};

const mapDispatchToProps = dispatch => ({
  catalogsResource: new CatalogResource(dispatch),
  projectsResource: new ProjectsResource(dispatch),
  growl: new Growl(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTab);
