import React, { useState } from "react";
import PropTypes, { instanceOf } from "prop-types";
import { Button } from "semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import { connect } from "react-redux";
import localStorage from "../../../shared/helpers/localStorage";
import { HooksConfigShape } from "../../../shared/shapes/hooksConfig.shape";

const OnOffice = ({ hooksConfig, activity, growl }) => {
  const [loading, setLoading] = useState(false);
  const sendToOnOffice = () => {
    setLoading(true);

    fetch(`/api/v1/activities/${activity.id}/on_office`, {
      method: "POST",
      body: JSON.stringify({ on_office: { class: hooksConfig.action.class } }),
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          growl.error("message.on_office.title", "message.on_office.error", {
            bodyValues: {
              error: data.error
            }
          });
        } else if (data.find(response => response.errorcode > 0)) {
          growl.error("message.on_office.title", "message.on_office.error", {
            bodyValues: {
              error: data
                .find(response => response.errorcode > 0)
                .map(error => error.message)
            }
          });
        } else {
          growl.success("message.on_office.title", "message.on_office.success");
        }
        setLoading(false);
      })
      .catch(error => {
        growl.error("message.on_office.title", "message.on_office.error", {
          bodyValues: {
            error: error.statusText
          }
        });
        // eslint-disable-next-line no-console
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Button
      content={
        <div className="leftAlignedWithIcon">{hooksConfig.action.label}</div>
      }
      fluid
      compact
      icon="play"
      labelPosition="left"
      loading={loading}
      onClick={sendToOnOffice}
    />
  );
};

OnOffice.propTypes = {
  hooksConfig: HooksConfigShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object.isRequired,
  growl: instanceOf(Growl).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    growl: new Growl(dispatch)
  };
};

export default connect(null, mapDispatchToProps)(OnOffice);
