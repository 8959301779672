import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const ActionButtons = ({ onClose, onSubmit }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <Button basic id="cancel" onClick={onClose}>
        <FormattedMessage id="meta.actions.cancel" />
      </Button>
      <Button primary id="cancel" type="submit" onClick={() => onSubmit()}>
        <FormattedMessage id="meta.actions.apply" />
      </Button>
    </div>
  );
};

ActionButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

ActionButtons.defaultProps = {
  onSubmit: () => {}
};

export default ActionButtons;
