import { get, keyBy } from "lodash";
import { createSelector } from "reselect";
import { getI18N } from "shared/selectors";

export const getProductGroupId = (_, props) => props.productGroupId;
export const getProductGroupRuleSets = state =>
  get(state, "pageContent.productGroupRuleSets");

export const getRelevantProductGroupRuleSetsById = createSelector(
  [getProductGroupId, getProductGroupRuleSets],
  (productGroupId, productGroupRuleSets) =>
    productGroupRuleSets &&
    keyBy(
      productGroupRuleSets.filter(
        ruleSet =>
          ruleSet.product_group_then_id === productGroupId ||
          ruleSet.product_group_when_id === productGroupId
      ),
      "id"
    )
);

export const getProductGroupRuleSetsById = createSelector(
  [getProductGroupRuleSets],
  productGroupRuleSets => keyBy(productGroupRuleSets, "id")
);

const SCOPES = ["line_item", "sub_section", "section", "room_book"];
export const getScopes = createSelector([getI18N], i18n =>
  Object.assign(
    ...SCOPES.map(scope => ({
      [scope]: i18n[`productRules.scopes.${scope}`]
    }))
  )
);

export const getScopeOptions = createSelector([getI18N], i18n =>
  SCOPES.map(scope => ({
    value: scope,
    text: i18n[`productRules.scopes.${scope}`]
  }))
);
