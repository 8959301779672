import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form, Button, Icon } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "title",
      label: "activity.attributes.title.label",
      message: "activity.attributes.title.error",
      placeholder: "activity.attributes.title.placeholder",
      rule: "isRequired",
      autoComplete: "off"
    },
    {
      id: "summary",
      label: "activity.attributes.summary.label",
      placeholder: "activity.attributes.summary.placeholder"
    }
  ]
});

class ActivityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, model: {} };
  }

  componentDidMount() {
    const { model } = this.props;
    this.writeModelToState(model);
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props;
    if (model !== prevProps.model) {
      this.writeModelToState(model);
    }
  }

  writeModelToState = model => {
    this.setState({ model });
  };

  onSubmit = values => {
    const { save } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return false;
    }
    this.setState({ isLoading: true });
    save(values);
  };

  render() {
    const { i18n } = this.props;
    const { model } = this.state;
    const form = FormFactory.create(model, i18n, {
      onChange: changedModel => this.setState({ model: changedModel })
    });

    return (
      <Form
        id="activity"
        onSubmit={form.handleSubmit(this.onSubmit)}
        data-component="activityForm"
      >
        <Form.Field>
          <Field component="Input" {...form.fields.title} />
        </Form.Field>

        <Form.Field>
          <Field component="TextArea" {...form.fields.summary} />
        </Form.Field>

        <Form.Field>
          <Button type="submit" color="green" loading={this.state.isLoading}>
            <Icon name="save" />
            <FormattedMessage
              id="meta.actions.save"
              defaultMessage="meta.actions.save"
            />
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

ActivityForm.propTypes = {
  i18n: PropTypes.object,
  model: PropTypes.object,
  save: PropTypes.func
};

export default ActivityForm;
