import React from "react";
import { connect } from "react-redux";
import PropTypes, { shape, instanceOf, object, arrayOf } from "prop-types";
import { Loader, Segment, Button, Icon, Grid, Header } from "semantic-ui-react";
import { get, set, cloneDeep, sortBy } from "lodash";
import { getI18N } from "shared/selectors";
import { FormattedMessage } from "react-intl";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import ProcessItem from "./ProcessItem";
import silentHandleApiRequestErrors from "../../../../shared/helpers/silentHandleApiRequestErrors";
import { ProcessDefinitionsResource } from "../../../actions/accountActions";
import { I18nShape } from "../../../../shared/shapes/i18n.shape";
import ProcessDialog from "./ProcessDialog";

class ProcessItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = { processes: [] };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.process_definitions
      .fetchAll()
      .catch(silentHandleApiRequestErrors)
      .then(response => {
        const initDefinitions = get(
          response.data,
          "process_definitions",
          []
        ).map(d => {
          return { ...d, process: JSON.parse(d.config) };
        });

        this.writeProcessDefinitionsToState(initDefinitions);
      });
  }

  componentDidUpdate(prevProps) {
    const prevDefinitions = get(prevProps, "processDefinitions", []);

    const nextDefinitions = get(this.props, "processDefinitions", []);

    if (prevDefinitions !== nextDefinitions) {
      const defs = nextDefinitions.map(d => {
        return { ...d, process: JSON.parse(d.config) };
      });
      this.writeProcessDefinitionsToState(defs);
    }
  }

  writeProcessDefinitionsToState = definitions => {
    const orderedDefinitions = sortBy(definitions, "name");
    this.setState({ processes: orderedDefinitions });
  };

  handleStateChangeFn(processIdx, phaseIdx, stateIdx) {
    const { processes } = this.state;

    return stateValues => {
      const ps = cloneDeep(processes[processIdx]);
      set(ps, `process[${phaseIdx}].states[${stateIdx}]`, stateValues);

      return this.saveProcess(ps);
    };
  }

  handlePhaseChangeFn(processIdx, phaseIdx) {
    const { processes } = this.state;
    return phaseValues => {
      const ps = cloneDeep(processes[processIdx]);
      set(ps, `process[${phaseIdx}]`, phaseValues);

      return this.saveProcess(ps);
    };
  }

  saveProcess = (model, mode) => {
    const { actions } = this.props;

    if (mode !== "ADD") model.config = JSON.stringify(model.process);

    return actions.process_definitions.save(model).then(() => {
      return actions.process_definitions.fetchAll();
    });
  };

  renderProcesses(processes) {
    return processes.map(process => {
      return this.renderProcess(process);
    });
  }

  renderProcess(process) {
    const { actions, i18n, admin } = this.props;
    return (
      <ProcessItem
        key={process.id}
        i18n={i18n}
        process={process}
        admin={admin}
        resource={actions.process_definitions}
      />
    );
  }

  renderAddProcess() {
    const { processes } = this.state;
    const { actions, i18n } = this.props;
    return (
      <Segment style={{ margin: "0 0 20px 0" }}>
        <Grid>
          <Grid.Column width="10" verticalAlign="middle">
            <Header as="h4">
              <FormattedMessage id="process.header.title" />
            </Header>
          </Grid.Column>
          <Grid.Column width="6" textAlign="right">
            <IsSystemAdmin>
              <ProcessDialog
                mode="ADD"
                processes={processes}
                button={
                  <Button>
                    <Icon name="add" color="grey" />
                    <FormattedMessage id="process.header.button.add" />
                  </Button>
                }
                i18n={i18n}
                onSave={this.saveProcess}
                resource={actions.process_definitions}
              />
            </IsSystemAdmin>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  render() {
    const { processes } = this.state;
    if (!processes.length) return <Loader active />;

    return (
      <>
        {this.renderAddProcess()}
        {this.renderProcesses(processes)}
      </>
    );
  }
}

ProcessItems.propTypes = {
  actions: shape({
    process_definitions: instanceOf(ProcessDefinitionsResource)
  }).isRequired,
  admin: PropTypes.object.isRequired,
  processDefinitions: arrayOf(object),
  i18n: I18nShape.isRequired
};

const mapStateToProps = state => {
  const processDefinitions = get(state.pageContent, "process_definitions", []);

  return {
    i18n: getI18N(state),
    processDefinitions
  };
};

export default connect(mapStateToProps)(ProcessItems);
