import { createSelector } from "reselect";
import {
  getI18N,
  getPageContent,
  getRouterParams
} from "shared/selectors/base";
import { getAccount } from "shared/selectors/account";
import ActivityModel from "../containers/activity/activityModel";
import { getProject } from "../../shared/selectors";

// eslint-disable-next-line import/prefer-default-export
export const getActivityModel = createSelector(
  [getI18N, getPageContent, getAccount, getProject, getRouterParams],
  (i18n, pageContent, account, project, params) => {
    const { activityId } = params;
    return new ActivityModel(activityId, pageContent, i18n, account, project);
  }
);
