import React, { useRef, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Header, Button, Modal, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form, Select, Checkbox } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { MessageFiltersResource } from "builder_portal/actions/messageFiltersActions";
import * as Yup from "yup";
import Growl from "builder_portal/actions/growlActions";
import {
  getMessageLabels,
  getProjectOptions
} from "builder_portal/selectors/messages";
import { getUserOptions } from "builder_portal/selectors/masterdata";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import PropTypes from "prop-types";

const EMPTY_MODEL = {
  name: "",
  summary: "",
  filter_project_id: null,
  filter_user_id: null,
  filter_sender_email_pattern: "",
  filter_subject_pattern: "",
  filter_body_pattern: "",
  filter_has_attachment: false,
  action_assign_label_id: null,
  action_assign_user_id: null,
  action_mark_read: false,
  action_mute_notification: false
};

const CreateFilterDialog = ({ trigger, model }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const userOptions = useSelector(getUserOptions);
  const messageLabels = useSelector(getMessageLabels);
  const projectOptions = useSelector(getProjectOptions).map(option => ({
    ...option,
    value: parseInt(option.value, 10)
  }));

  const messageLabelOptions = useMemo(
    () =>
      messageLabels.map(label => ({
        value: label.id,
        key: label.id,
        text: (
          <span>
            <Icon name="square full" color={label.color} />
            {label.name}
          </span>
        )
      })),
    [messageLabels]
  );

  const refResetForm = useRef();

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: "message.errorForm.required" })
    )
  });

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    clearForm();
  }, []);

  const onSubmit = useCallback(
    values => {
      // append the id to the values if it exists
      if (model?.id) {
        // eslint-disable-next-line no-param-reassign
        values.id = model?.id;
      }

      const resource = new MessageFiltersResource(dispatch);

      return resource
        .save(values)
        .then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );

          // eslint-disable-next-line consistent-return
          return resource.fetchAll().then(handleClose);
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model]
  );

  const onDelete = () => {
    const resource = new MessageFiltersResource(dispatch);
    resource
      .remove(model?.id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "message_filters.remove.success.body"
        );
        return resource.fetchAll().then(handleClose);
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "message_filters.remove.failure.body"
        );
      });
  };

  const renderDeleteButton = isSubmitting => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        disabled={isSubmitting}
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="message_filters.confirmation.title"
        message="message_filters.confirmation.body"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Formik
      initialValues={model || EMPTY_MODEL}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            trigger={trigger}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Header
              content={
                model
                  ? intl.formatMessage({ id: "createContact.title.edit" })
                  : intl.formatMessage({ id: "createContact.title.create" })
              }
            />
            <Modal.Content scrolling>
              <Form onSubmit={handleSubmit}>
                <Container>
                  <Input
                    name="name"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.name"
                    })}
                    errorPrompt
                  />
                  <Input
                    name="summary"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.summary"
                    })}
                  />

                  <Header as="h4">
                    <FormattedMessage
                      id="message_filters.dialog.title.if"
                      defalt="Wenn"
                    />
                  </Header>

                  <Select
                    name="filter_project_id"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.filter_project"
                    })}
                    options={projectOptions}
                    clearable
                  />

                  <Select
                    name="filter_user_id"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.filter_user"
                    })}
                    options={userOptions}
                    clearable
                  />

                  <Input
                    name="filter_sender_email_pattern"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.filter_sender_email_pattern"
                    })}
                    errorPrompt
                  />

                  <Input
                    name="filter_subject_pattern"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.filter_subject_pattern"
                    })}
                    errorPrompt
                  />

                  <Input
                    name="filter_body_pattern"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.filter_body_pattern"
                    })}
                    errorPrompt
                  />

                  <Checkbox
                    name="filter_has_attachment"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.filter_has_attachment"
                    })}
                    errorPrompt
                  />

                  <Header as="h4">
                    <FormattedMessage
                      id="message_filters.dialog.title.then"
                      defalt="Dann"
                    />
                  </Header>

                  <Select
                    name="action_assign_label_id"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.action_assign_label"
                    })}
                    options={messageLabelOptions}
                    clearable
                  />

                  <Select
                    name="action_assign_user_id"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.action_assign_user"
                    })}
                    options={userOptions}
                    clearable
                  />

                  <Checkbox
                    name="action_mark_read"
                    label={intl.formatMessage({
                      id: "message_filters.dialog.attributes.action_mark_read"
                    })}
                    errorPrompt
                  />

                  <Checkbox
                    name="action_mute_notification"
                    label={intl.formatMessage({
                      id:
                        "message_filters.dialog.attributes.action_mute_notification"
                    })}
                    errorPrompt
                  />
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div>{model?.id && renderDeleteButton(isSubmitting)}</div>
                <div>
                  <Button
                    content={intl.formatMessage({
                      id: "meta.actions.cancel"
                    })}
                    onClick={handleClose}
                  />
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    primary
                    content={intl.formatMessage({
                      id: "meta.actions.save"
                    })}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

CreateFilterDialog.propTypes = {
  trigger: PropTypes.node.isRequired,
  model: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    summary: PropTypes.string,
    filter_project_id: PropTypes.number,
    filter_user_id: PropTypes.number
  })
};

CreateFilterDialog.defaultProps = {
  model: undefined
};

export default CreateFilterDialog;
