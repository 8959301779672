import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import "./phaseLegend.scss";
import connectStatistic from "./statisticsContainer";

class PhaseLegend extends React.Component {
  static propTypes = {
    processDefinition: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { processDefinition } = this.props;
    const phases = processDefinition.getPhases(true);

    return (
      <div data-component="phaseLegend">
        <div className="legend">
          {phases.map(phase => {
            return (
              <div className="legendItem" key={`legend-${phase.id}`}>
                <Icon name="circle" style={{ color: phase.color }} />
                {phase.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connectStatistic(PhaseLegend);
