import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { browserHistory } from "shared/routes/browserHistory";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import {
  Table,
  Message,
  Icon,
  Button,
  Header,
  Segment
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getMessageDrafts,
  getMessageDraftsNoActivities
} from "builder_portal/selectors/messageDrafts";
import {
  MessageDraftsResource,
  MessageDraftsForActivitiesResource
} from "builder_portal/actions/messageDraftsActions";
import Growl from "builder_portal/actions/growlActions";
import { If } from "shared/components/elements/Conditions";
import { func, number, string, node } from "prop-types";
import { displayDate } from "./MessageThreadsContainer";

import "./messages.scss";

const Div = ({ children, ...props }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...props}>{children}</div>;
};

Div.propTypes = {
  children: node.isRequired
};

const DraftMessages = ({ handleOpenDraft, activityId, view }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selector = useMemo(
    () => (activityId ? getMessageDrafts : getMessageDraftsNoActivities),
    []
  );
  const loadDraftsResource = useMemo(
    () =>
      activityId
        ? new MessageDraftsForActivitiesResource(dispatch, activityId)
        : new MessageDraftsResource(dispatch),
    []
  );
  const drafts = useSelector(selector);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loadDraftsResource.fetchAll();
  }, []);

  const handleDelete = draft => {
    setLoading(true);
    return new MessageDraftsResource(dispatch)
      .remove(draft.id)
      .then(() => {
        loadDraftsResource.fetchAll();
        setLoading(false);
        new Growl(dispatch).success(
          "messaging.drafts.messages.deleted.title",
          "messaging.drafts.messages.deleted.body"
        );
      })
      .catch(() => setLoading(false));
  };

  const renderDeleteButton = draft => {
    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: cb => {
          handleDelete(draft)
            .then(cb)
            .catch(cb);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="messaging.drafts.delete.title"
        message="messaging.drafts.delete.message"
        button={
          <Button
            basic
            icon="trash"
            size="mini"
            data-cmd="delete"
            disabled={isLoading}
            onClick={event => event.stopPropagation()}
          />
        }
        buttons={buttons}
      />
    );
  };

  const renderDraftsEmpty = () => {
    return (
      <Message>
        <If condition={!activityId}>
          <Icon name="edit outline" />
        </If>

        <Message.Content>
          <FormattedMessage
            id="messaging.drafts.no_drafts"
            defaultValue="Es wurden keine Entwürfe gefunden."
          />
        </Message.Content>
      </Message>
    );
  };

  const renderDraftsContent = () => {
    return (
      <Table selectable verticalAlign="top">
        <Table.Body>
          {drafts.map(draft => {
            const recipients = draft.originalRecipients
              .map(r => r.label)
              .join(", ") || (
              <span style={{ fontStyle: "italic", fontSize: "0.9em" }}>
                {intl.formatMessage({
                  id: "email.attributes.noRecipients.label"
                })}
              </span>
            );
            return (
              <Table.Row
                onClick={() => {
                  if (draft.message_thread_id) {
                    browserHistory.push(`/messages/${draft.message_thread_id}`);
                  } else {
                    handleOpenDraft(draft);
                    if (draft.parent_id)
                      setTimeout(() => {
                        // if clicked two times sequentially
                        window.location.hash = "";
                        window.location.hash = draft.parent_id;
                      }, 100);
                  }
                }}
                verticalAlign="top"
                key={draft.id}
                style={{ cursor: "pointer" }}
              >
                <Table.Cell width={15}>
                  <div className="subject e-width">
                    {draft.subject || (
                      <span style={{ fontStyle: "italic" }}>
                        <FormattedMessage id="messaging.drafts.no_subject" />
                      </span>
                    )}
                  </div>
                  <div
                    style={{ display: "flex", marginTop: "3px" }}
                    className="e-width"
                  >
                    <div
                      style={{
                        width: "60%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {recipients}
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        flexFlow: "row-reverse"
                      }}
                    >
                      {displayDate(draft.updated_at)}

                      <If condition={!!draft.attachments.length}>
                        <div className="meta">
                          <div>
                            <Icon name="attach" />
                            <FormattedMessage
                              id="messaging.messages_container.messageThread.attachmentCount"
                              values={{
                                attachmentCount: draft.attachments.length
                              }}
                              defaultMessage={`${draft.attachments.length}`}
                            />
                          </div>
                        </div>
                      </If>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell width={2}>
                  <div style={{ display: "flex" }}>
                    <Button
                      icon="edit outline"
                      basic
                      size="mini"
                      disabled={isLoading}
                    />
                    {renderDeleteButton(draft)}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const Component = view === "timeline" ? Div : Segment;

  return (
    <div data-component="DraftMessages">
      <Component className={view === "timeline" ? "top label" : ""}>
        <Header as="h4">
          <FormattedMessage id="messaging.drafts.title" />
        </Header>
      </Component>
      <Segment basic={view === "timeline"}>
        <If condition={drafts.length === 0}>{renderDraftsEmpty()}</If>
        <If condition={drafts.length > 0}>{renderDraftsContent()}</If>
      </Segment>
    </div>
  );
};

DraftMessages.propTypes = {
  handleOpenDraft: func.isRequired,
  activityId: number,
  view: string
};

DraftMessages.defaultProps = {
  activityId: undefined,
  view: undefined
};

export default DraftMessages;
