/* eslint-disable max-classes-per-file */
import Resources from "../../shared/actions/resourcesActions";
import { UPDATE_MESSAGE } from "../../shared/constants/appConstants";
import serialize from "../../shared/helpers/serialize";
import apiRequest from "../../shared/network/apiRequest";

export class ProjectsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "project", "/api/v1/projects");
  }

  removeThumb(id) {
    const url = `${this.url}/${id}/thumb`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorDelete.title",
            body: "message.errorDelete.body"
          }
        });
      });
  }

  removeAttachable(projectId, role) {
    const url = `${this.url}/${projectId}/${role}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate)
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorDelete.title",
            body: "message.errorDelete.body"
          }
        });
      });
  }

  fetchStructure(id) {
    const url = `${this.url}/${id}/structure`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchUnitStatistics(id) {
    const url = `${this.url}/${id}/unit_statistics`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchStatistics(id, params) {
    let url = `${this.url}/${id}/statistics`;
    if (params && Object.keys(params).length > 0) {
      url = `${url}?${serialize(params)}`;
    }
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchAnalytics(id) {
    const url = `${this.url}/${id}/analytics`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchBuyerPortalContent(id) {
    const url = `${this.url}/${id}/buyer_portal_content`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchBuyers(id) {
    const url = `/api/v1/projects/${id}/buyers`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchMembers(id) {
    const url = `/api/v1/projects/${id}/members`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchProductQuantities(id, params) {
    let url = `/api/v1/projects/${id}/product_quantities`;
    if (params && Object.keys(params).length > 0) {
      url = `${url}?${serialize(params)}`;
    }

    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchAttachments(id) {
    const url = `/api/v1/projects/${id}/attachments`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  updateAttachment(values, projectId) {
    const url = `/api/v1/projects/${projectId}/attachments/${values.attachment.id}`;
    return apiRequest
      .patch(url, this.dispatch, values)
      .then(this.propagateUpdate.bind(this));
  }

  removeAttachment(attachmentId, projectId) {
    const url = `/api/v1/projects/${projectId}/attachments/${attachmentId}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }

  fetchCustomImportConfigs(id) {
    const url = `/api/v1/projects/${id}/custom_import_configs`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class ProjectActivitiesResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "activity", `/api/v1/projects/${projectId}/activities`);
  }

  async getPriceInquiryPreviewUrl(activityId, contractorId) {
    return apiRequest.get(
      `${this.url}/${activityId}/price_inquiry/contractor_preview?contractor_id=${contractorId}`,
      this.dispatch
    );
  }
}

export class StatisticsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "statistics",
      `/api/v1/projects/${projectId}/activities/statistics`
    );
  }
}

export class ProjectImageResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "images", `/api/v1/projects/${projectId}/images`);
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate.bind(this));
  }
}

export class ActivityFilterResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "activities",
      `/api/v1/projects/${projectId}/activity_filters`,
      "activitiesFilter"
    );
  }
}

export default ProjectsResource;
