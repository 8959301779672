import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Header, Form, Icon, Segment, Label, Grid } from "semantic-ui-react";
import { set, cloneDeep, get } from "lodash";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import Field from "shared/components/forms/FieldComponent";
import { FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";

import { getAccount } from "shared/selectors";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ProcessDialog from "./ProcessDialog";
import ProcessStateDialog from "./ProcessStateDialog";

const FormFactory = new FormDefinition({
  fields: [
    {
      id: "config",
      label: "account.attributes.name.label",
      rule: "isRequired"
    }
  ]
});

class ProcessItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "visual",
      process: {},
      isLoading: false
    };
  }

  componentDidMount() {
    this.writeProcessToState(get(this.props, "process", {}));
  }

  componentDidUpdate(prevProps) {
    const prevProcess = get(prevProps, "process", {});
    const nextProcess = get(this.props, "process", {});

    if (prevProcess !== nextProcess) {
      this.writeProcessToState(nextProcess);
    }
  }

  writeProcessToState = process => {
    this.setState({ process });
  };

  handleToggleModeVisual = () => {
    if (this.state.mode === "visual") {
      this.setState({ mode: "json" });
    } else {
      this.setState({ mode: "visual" });
    }
  };

  handleStateChangeFn = stateIdx => {
    return stateValues => {
      const ps = cloneDeep(this.state.process);
      set(ps, `process[${stateIdx}]`, stateValues);
      return this.saveProcess(this.reserializeConfig(ps));
    };
  };

  handleStateRemoveFn = () => {
    return stateValues => {
      const { process } = this.state;
      const ps = cloneDeep(process);
      ps.process = ps.process.filter(p => p.id !== stateValues.id);
      return this.saveProcess(this.reserializeConfig(ps));
    };
  };

  handleProcessChange = processValues => {
    return this.saveProcess(this.reserializeConfig(processValues));
  };

  reserializeConfig = model => {
    model.config = JSON.stringify(model.process);
    return model;
  };

  saveProcess = model => {
    const { resource, growl } = this.props;
    this.setState(prevState => ({ ...prevState, isLoading: true }));
    return resource
      .save(model)
      .then(() => {
        resource.fetchAll();
        growl.success(
          `process.messages.edit.success.title`,
          `process.messages.edit.success.body`,
          { timeout: 1500 }
        );
        this.setState(prevState => ({ ...prevState, isLoading: false }));
      })
      .catch(() => {
        growl.error(
          `process.messages.edit.error.title`,
          `process.messages.edit.error.body`
        );
        this.setState(prevState => ({ ...prevState, isLoading: false }));
      });
  };

  renderToggle(mode) {
    if (mode === "visual") {
      return (
        <div>
          <b>Visuell</b>&nbsp;|&nbsp;
          <a role="link" onClick={this.handleToggleModeVisual}>
            Json
          </a>
        </div>
      );
    }
    return (
      <div>
        <a role="link" onClick={this.handleToggleModeVisual}>
          Visuell
        </a>
        &nbsp;|&nbsp;
        <b>Json</b>
      </div>
    );
  }

  renderBody() {
    const { mode } = this.state;
    if (mode === "visual") {
      return this.renderVisual();
    }
    return this.renderJson();
  }

  renderVisual() {
    const { account, i18n, growl } = this.props;
    const { process } = this.state;

    const lineItemLifeCycle = account.getLineItemLifeCycle();

    return (
      <Segment attached>
        <Grid>
          {process.process?.map((state, stateIdx) => {
            const phase = lineItemLifeCycle.getPhase(state.phase_id);
            return (
              <Grid.Column key={state.id} width={16}>
                <Header as="h5" key={state.id}>
                  <Label
                    circular
                    basic
                    style={{ backgroundColor: phase.color }}
                    size="mini"
                  />
                  &nbsp;
                  <ProcessStateDialog
                    mode="EDIT"
                    model={state}
                    button={
                      <a role="button">
                        <span>
                          {state.label} <Icon name="setting" color="grey" />
                        </span>
                      </a>
                    }
                    i18n={i18n}
                    onSave={this.handleStateChangeFn(stateIdx)}
                    onRemove={this.handleStateRemoveFn(stateIdx)}
                    growl={growl}
                  />
                  {state.deadline && (
                    <span className="right floated element">
                      {state.deadline_on !== "entry" && (
                        <Icon name="pin" title="Ab Vorgangsbeginn" />
                      )}{" "}
                      {state.deadline} Tage
                    </span>
                  )}
                  {state.terminal && (
                    <span className="right floated element">Endzustand</span>
                  )}
                </Header>
              </Grid.Column>
            );
          })}
        </Grid>
      </Segment>
    );
  }

  renderJson() {
    const { i18n } = this.props;
    const { process, isLoading } = this.state;

    const form = FormFactory.create(process, i18n, {
      onChange: data => {
        this.setState({ process: data });
      }
    });

    form.fields.config.props.rows = 20;

    return (
      <Segment attached>
        <Form
          id="process"
          onSubmit={form.handleSubmit(this.saveProcess)}
          data-component="processForm"
        >
          <Form.Field>
            <Field component="TextArea" {...form.fields.config} />
          </Form.Field>
          <Form.Field>
            <Form.Button
              type="submit"
              color="green"
              loading={isLoading}
              id="save"
            >
              <Icon name="save" />
              <FormattedMessage
                id="meta.actions.save"
                defaultMessage="meta.actions.save"
              />
            </Form.Button>
          </Form.Field>
        </Form>
      </Segment>
    );
  }

  render() {
    const { admin, resource } = this.props;
    const { process } = this.state;
    const textDecoration = process.deprecated ? "line-through" : "none";
    return (
      <div key={process.id}>
        <Segment.Group style={{ marginBottom: "20px" }}>
          <Segment attached="top">
            <Grid>
              <Grid.Column width={12}>
                <Header as="h4">
                  <ProcessDialog
                    mode="EDIT"
                    model={process}
                    button={
                      <a role="button">
                        <span style={{ textDecoration }}>
                          {process.name} <Icon name="setting" color="grey" />
                        </span>
                      </a>
                    }
                    i18n={this.props.i18n}
                    onSave={this.handleProcessChange}
                    resource={resource}
                  />
                  <IsVersionHistoryAccessible>
                    <VersionHistoryLink
                      id={process.id}
                      type="ProcessDefinition"
                      size="mid"
                    />
                  </IsVersionHistoryAccessible>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                {admin.group === "admin" && this.renderToggle(this.state.mode)}
              </Grid.Column>
            </Grid>
          </Segment>
          {this.renderBody()}
        </Segment.Group>
      </div>
    );
  }
}

ProcessItem.propTypes = {
  resource: PropTypes.object,
  admin: PropTypes.object,
  process: PropTypes.object,
  i18n: PropTypes.object,
  account: PropTypes.object,
  growl: PropTypes.instanceOf(Growl).isRequired
};

const mapStateToProps = state => ({
  account: getAccount(state)
});

const mapDispatchToProps = dispatch => ({
  growl: new Growl(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessItem);
