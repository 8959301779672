import Resources from "../../shared/actions/resourcesActions";

export class CitiesResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "", "/api/v1/cities");
    this.refreshPageContentAfterCommit = true;
  }
}

export default CitiesResource;
