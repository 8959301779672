import React from "react";
import { connect } from "react-redux";
import { Button, Header, Segment, Table } from "semantic-ui-react";
import "./email-templates.scss";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { FormattedMessage } from "react-intl";
import Growl from "builder_portal/actions/growlActions";
import PropTypes, { instanceOf } from "prop-types";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { I18nShape } from "shared/shapes/i18n.shape";
import EmailTemplateDialog from "./EmailTemplateDialog";
import { MailTemplatesResource } from "../../../actions/accountActions";

class EmailTemplates extends React.Component {
  componentDidMount() {
    this.props.mailTemplatesResource.fetchAll();
    if (!this.props.mailTemplateExampleParams)
      this.props.mailTemplatesResource.loadExampleParams();
  }

  render() {
    const { mailTemplates } = this.props;
    return (
      <Segment.Group data-component="mail_templates">
        <Segment.Group horizontal>
          <Segment>
            <Header>
              <FormattedMessage id="activerecord.models.mail_template.other" />
            </Header>
          </Segment>
          <EmailTemplateDialog
            trigger={<Button icon="plus" floated="right" id="new-template" />}
          />
        </Segment.Group>
        <Table
          attached
          striped
          renderBodyRow={::this.renderBodyRow}
          tableData={mailTemplates || []}
          headerRow={this.renderHeaderRow()}
        />
      </Segment.Group>
    );
  }

  renderHeaderRow() {
    return (
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="activerecord.attributes.mail_template.name" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="activerecord.attributes.mail_template.role" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="activerecord.attributes.mail_template.projects" />
        </Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell />
        <IsVersionHistoryAccessible>
          <Table.HeaderCell />
        </IsVersionHistoryAccessible>
        <Table.HeaderCell />
      </Table.Row>
    );
  }

  handleDuplicate = emailTemplate => {
    const { mailTemplatesResource, growl } = this.props;
    const { id, ...values } = emailTemplate;
    mailTemplatesResource
      .save({ ...values, name: `${values.name} copy` })
      .then(() => {
        growl.success("message.success.title", "message.success.body");
        mailTemplatesResource.fetchAll();
      })
      .catch(() => {
        growl.error("message.error.title", "message.error.body");
      });
  };

  renderBodyRow(mailTemplate) {
    const { mailTemplatesResource, growl, i18n } = this.props;
    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: cb => {
          mailTemplatesResource
            .remove(mailTemplate.id)
            .then(() => {
              growl.success("message.success.title", "message.success.body");
              cb();
            })
            .catch(cb);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <Table.Row key={mailTemplate.id} data-model="mail-template">
        <Table.Cell>{mailTemplate.name}</Table.Cell>
        <Table.Cell>{mailTemplate.role}</Table.Cell>
        <Table.Cell>
          {mailTemplate.projects?.map(p => p.name).join(", ")}
        </Table.Cell>
        <Table.Cell>
          <EmailTemplateDialog
            trigger={<Button icon="setting" data-cmd="edit" />}
            emailTemplate={mailTemplate}
          />
        </Table.Cell>
        <IsVersionHistoryAccessible>
          <Table.Cell>
            <VersionHistoryLink
              id={mailTemplate.id}
              type="MailTemplate"
              size="small"
              style={{ fontSize: "1rem" }}
            />
          </Table.Cell>
        </IsVersionHistoryAccessible>
        <Table.Cell>
          <ConfirmationDialog
            title="emailTemplates.actions.removeDialog.title"
            message="emailTemplates.actions.removeDialog.message"
            button={<Button icon="trash" data-cmd="delete" />}
            buttons={buttons}
          />
        </Table.Cell>
        <Table.Cell>
          <Button
            icon="copy"
            data-cmd="duplicate"
            onClick={() => this.handleDuplicate(mailTemplate)}
            data-tooltip={i18n["meta.actions.clone"]}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

EmailTemplates.propTypes = {
  i18n: I18nShape.isRequired,
  growl: PropTypes.instanceOf(Growl).isRequired,
  mailTemplatesResource: instanceOf(MailTemplatesResource).isRequired
};

const mapStateToProps = state => {
  return {
    mailTemplates: state.pageContent.mail_templates,
    mailTemplateExampleParams: state.pageContent.mailTemplateExampleParams,
    i18n: state.i18n
  };
};
const mapDispatchToProps = dispatch => ({
  mailTemplatesResource: new MailTemplatesResource(dispatch),
  growl: new Growl(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
