import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  Button,
  Header,
  Message,
  Checkbox
} from "semantic-ui-react";
import { getProject } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import { defaultRichTextTags } from "builder_portal/helpers/defaultRichTextTags";
import localStorage from "shared/helpers/localStorage";
import { FormattedMessage, useIntl } from "react-intl";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import { node, func, number } from "prop-types";
import FileUploader from "../../dropzone/FileUploader";
import { UnitVariablesLoaderContext } from "./UnitVariablesLoader";

const STATUS_CHECK_INTERVAL = 1500;
const EMPTY_MODEL = { file: null, force: false };

const UnitVariablesUploadModal = ({ button, onCompleted, unitId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [model, setModel] = useState(EMPTY_MODEL);
  const project = useSelector(getProject);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { loadVariables, setProviderLoading } = useContext(
    UnitVariablesLoaderContext
  );

  const handleClose = () => {
    setOpen(false);
    setModel(EMPTY_MODEL);
  };

  const afterPolling = () => {
    setProviderLoading(true);
    loadVariables();
    if (typeof onCompleted === "function") {
      onCompleted();
    }
    handleClose();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    5,
    afterPolling
  );

  const handleOpen = () => {
    setError("");
    setOpen(true);
  };

  const handleFile = file => {
    setModel(currentModel => ({
      ...currentModel,
      file
    }));
    setError("");
  };

  const handleChange = (_, { checked, name }) => {
    setModel(prev => ({ ...prev, [name]: checked }));
  };

  const handleUpload = () => {
    const formData = new FormData();
    const { file, force } = model;
    if (!file) {
      setError(
        intl.formatMessage({
          id: "project.unit_variables.dialog.messages.selectFile"
        })
      );
      return;
    }
    setError("");

    formData.append(`unit_variables[file]`, file);
    formData.append(`unit_variable[force]`, force);
    if (unitId) formData.append(`unit_id`, unitId);

    fetch(`/api/v1/projects/${project.slug}/unit_variables/import`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.id) {
          startPolling(res.id, STATUS_CHECK_INTERVAL);
        }
      })
      .catch(() => {
        stopPolling();
        setError(
          intl.formatMessage({
            id: "project.unit_variables.section.upload_error"
          })
        );
      });
  };

  return (
    <>
      <Modal
        closeIcon
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        trigger={button}
      >
        <Modal.Header>
          <Header as="h3">
            <FormattedMessage id="project.unit_variables.dialog.title" />
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Message error content={error} hidden={!error} />
          <Form>
            <If condition={!model.file}>
              <FileUploader handleFile={handleFile} fileType="excel" />
            </If>
            <If condition={!!model.file}>
              <Header as="h5">{model.file?.path}</Header>
            </If>
            <Checkbox
              name="force"
              label={
                <label>
                  {intl.formatMessage({
                    id: "project.unit_variables.dialog.force"
                  })}
                </label>
              }
              onChange={handleChange}
            />
            <Message warning visible>
              <Message.Content>
                <FormattedMessage
                  id="project.unit_variables.dialog.messages.forceInfo"
                  values={{
                    ...defaultRichTextTags
                  }}
                />
              </Message.Content>
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            id="close"
            content={intl.formatMessage({ id: "meta.actions.close" })}
            onClick={handleClose}
          />
          <Button
            primary
            id="submit"
            content={intl.formatMessage({ id: "attachment.actions.upload" })}
            onClick={handleUpload}
            disabled={isPolling}
          />
        </Modal.Actions>
      </Modal>
      <TinyLoader opened={isPolling} />
    </>
  );
};

UnitVariablesUploadModal.propTypes = {
  button: node.isRequired,
  onCompleted: func,
  unitId: number
};

UnitVariablesUploadModal.defaultProps = {
  onCompleted: () => {},
  unitId: undefined
};

export default UnitVariablesUploadModal;
