/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import PropTypes, { oneOfType, number, string } from "prop-types";
import { FormattedMessage } from "react-intl";
import FormatNumber from "shared/components/numbers/FormatNumber";
import "./styles.scss";

const ProductSection = ({
  product,
  units,
  sectionName,
  sectionQuantity,
  projectId,
  params,
  sectionId
}) => {
  const [details, setDetails] = React.useState(false);
  const [productQuantities, setProductQuantities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (details && !hasFetched) {
      setLoading(true);
      const fetchPromises = units.map(unit => {
        const sectionParam = sectionId
          ? ""
          : `&section_id=${sectionQuantity.section_id}`;
        let url = `/api/v1/projects/${projectId}/product_quantities?product_id=${product.id}&unit_id=${unit.id}${sectionParam}`;

        Object.keys(params).forEach(key => {
          url += `&${key}=${params[key]}`;
        });

        return fetch(url).then(response => response.json());
      });
      Promise.all(fetchPromises)
        .then(responses => {
          setHasFetched(true);
          const concatenatedResponse = responses.flatMap(
            response => response.product_quantities
          );
          setProductQuantities(concatenatedResponse);
        })
        .finally(() => setLoading(false));
    }
  }, [details, params]);

  const getUnitName = id => {
    const unit = units.find(unitItem => unitItem.id === id);
    return unit?.display_name;
  };

  return (
    <>
      <tr
        key={sectionQuantity.section_id}
        id={`section-${sectionQuantity.section_id}`}
      >
        <td colSpan="1">
          {isLoading ? (
            <Icon name="spinner" loading />
          ) : !details ? (
            <Icon
              onClick={() => setDetails(!details)}
              name="plus square outline"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Icon
              onClick={() => setDetails(!details)}
              name="minus square outline"
              style={{ cursor: "pointer" }}
            />
          )}
        </td>

        <td colSpan="7">
          <span>{sectionName}</span>
        </td>
        <td colSpan="1" style={{ textAlign: "right" }}>
          <FormatNumber value={sectionQuantity.quantity} />
        </td>
        <td colSpan="1" style={{ textAlign: "center" }} />
      </tr>
      {details && hasFetched && (
        <>
          {productQuantities.length > 0 ? (
            productQuantities.map(quantity => (
              <tr
                key={quantity.unit_id}
                style={{ cursor: "pointer" }}
                id={`unit-${quantity.unit_id}`}
              >
                <td colSpan="1" />
                <td colSpan="7">
                  <span>{getUnitName(quantity.unit_id)}</span>
                </td>

                <td colSpan="1" style={{ textAlign: "right" }}>
                  <FormatNumber value={quantity.quantity} />
                </td>
                <td colSpan="1" style={{ textAlign: "center" }}>
                  <a
                    href={`/projects/${projectId}/units/${quantity.unit_id}/room-book`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="external" />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">
                <FormattedMessage id="projectQuantities.noUnitsFound" />
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

ProductSection.propTypes = {
  product: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  sectionName: PropTypes.string.isRequired,
  sectionQuantity: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  params: PropTypes.object,
  sectionId: oneOfType([number, string])
};

ProductSection.defaultProps = {
  params: {},
  sectionId: undefined
};

export default ProductSection;
