import { shape, number, string } from "prop-types";

export const UserShape = shape({
  id: number,
  salutation: string,
  title: string,
  first_name: string,
  last_name: string,
  email: string
});

export default UserShape;
