import { createSelector } from "reselect";
import { get, keyBy } from "lodash";

const getProducts = state => get(state, "pageContent.products", []);
export const getProductsById = createSelector(getProducts, products =>
  keyBy(products, "id")
);

export const getProductGroups = state =>
  get(state, "pageContent.product_groups", []);

export const getTradesForProductGroups = createSelector(
  getProductGroups,
  productGroups =>
    productGroups.reduce((acc, group) => {
      const { trades } = group;
      trades.forEach(trade => {
        if (acc.indexOf(trade) === -1) acc.push(trade);
      });
      return acc;
    }, [])
);

export const getProductGroupsById = createSelector(
  getProductGroups,
  productGroups => keyBy(productGroups, "id")
);

export const getProductGroupsWithProductIds = createSelector(
  getProductGroups,
  productGroups =>
    Object.assign(
      {},
      ...productGroups.map(({ id, name, thumb_url, prices }) => ({
        [id]: {
          id,
          name,
          thumb_url,
          productIds: prices.map(price => price.product_id)
        }
      }))
    )
);
