import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes, { shape } from "prop-types";
import moment from "moment";
import { get, groupBy, head, indexOf, sortBy, values } from "lodash";
import {
  Grid,
  Header,
  Icon,
  Label,
  Table,
  Transition,
  Popup
} from "semantic-ui-react";
import { routerShape, Link } from "react-router";
import { UnitShape } from "shared/shapes/unit.shape";
import { getUnits } from "shared/selectors/units";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { FormattedMessage } from "react-intl";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import { withRouter } from "../../../shared/helpers/withRouter";
import AttachmentSelector from "./AttachmentSelector";
import AttachmentAssigner from "./AttachmentAssigner";
import AttachmentDialog from "./AttachmentDialog";
import AttachmentFeatureToggle from "./AttachmentFeatureToggle";
import VersionsDialog from "./VersionsDialog";
import { formatMessage } from "../helpers/i18n";
import {
  getFolderStateFromStorage,
  setFolderStateToStorage
} from "./folderStateStorageQueue";

import "./attachments.scss";
import { AccountShape, ProjectShape } from "../../../shared/shapes";
import AttachmentVisibilityModal from "./AttachmentVisibilityModal";

const DEFAULT_FOLDER_STATE = "folder open";
class Attachments extends Component {
  constructor(props) {
    super(props);

    const { router, params } = props;
    const { projectId, id } = params;
    const activityAction = get(router, "location.state.activityAction");

    this.state = {
      showInitialTransition: !!activityAction,
      isLoading: false,
      attachmentTypeFolderIcons:
        getFolderStateFromStorage(projectId, id || "project-level") ||
        this.getDefaultIcons()
    };
  }

  componentDidMount() {
    const { unitsResource } = this.props;
    unitsResource.fetchAll();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isLoading } = this.state;
    const { attachments } = this.props;
    return (
      isLoading !== nextState ||
      JSON.stringify(nextProps.attachments) !== JSON.stringify(attachments)
    );
  }

  getDefaultIcons = () => {
    const { context } = this.props;
    return context.account
      .getDocumentTypes()
      .map(x => ({ id: x.id, icon: DEFAULT_FOLDER_STATE }));
  };

  toggleIcon = attachmentTypeId => {
    const { params } = this.props;
    const { attachmentTypeFolderIcons } = this.state;
    const temp = attachmentTypeFolderIcons.map(x => {
      if (x.id === attachmentTypeId)
        return {
          id: x.id,
          icon: x.icon === "folder" ? "folder open" : "folder"
        };
      return x;
    });
    this.setState({ attachmentTypeFolderIcons: temp });
    setFolderStateToStorage(
      params.projectId,
      params.id || "project-level",
      temp
    );
  };

  addAttachment = attachment => {
    const { handleAdd } = this.props;
    return handleAdd(attachment).then(() => {
      this.setState({ isLoading: false });
    });
  };

  removeAttachment = attachmentId => {
    const { resourceId, handleRemove } = this.props;
    this.setState({ isLoading: true });
    return handleRemove(attachmentId, resourceId).then(() => {
      this.setState({ isLoading: false });
    });
  };

  assignAttachment = id => {
    const { handleAssign } = this.props;
    this.setState({ isLoading: true });
    return handleAssign(id).then(() => {
      this.setState({ isLoading: false });
    });
  };

  // Workaround for a bug in semantic transitions
  // Normally with transitionOnMount it should only show the show transition, but cause of this bug
  // show and hide is both triggered. To avoid this we keep track on the Transion state by ourselfs.
  // see: https://github.com/Semantic-Org/Semantic-UI-React/issues/3641
  handleInitialTransitionComplete = () =>
    this.setState({ showInitialTransition: false });

  withInitialTransition = content => {
    const { showInitialTransition } = this.state;
    return showInitialTransition ? (
      // when changing the duration make sure to also adjust it in the css
      // see app/javascript/builder_portal/components/attachment/attachments.scss:11
      <Transition
        transitionOnMount
        directional
        animation="custom"
        duration="3100"
        onComplete={this.handleInitialTransitionComplete}
      >
        {content}
      </Transition>
    ) : (
      content
    );
  };

  sortByUpdatedAtDate = sortElement => {
    if (sortElement) {
      return -moment(sortElement.updated_at).unix();
    }
    return 0;
  };

  getReferenceUrl = ref => {
    const { units, params } = this.props;
    const refUnit =
      ref.referencee_type === "Unit"
        ? units.find(unit => unit.id === ref.referencee_id)
        : {};

    switch (ref.referencee_type) {
      case "Unit":
        return `/projects/${params.projectId}/units/${refUnit?.slug}/documents`;
      case "Project":
        return `/projects/${params.projectId}/documents`;
      case "Activity":
        return `/projects/${params.projectId}/activities/${ref.referencee_id}`;
      case "Message":
        return `/messages/${ref.message_thread_id}`;
      case "RoomBookSubLineItem":
        return `/projects/${params.projectId}/units/${ref.unit_id}/room-book#sub-line-item-${ref.referencee_id}`;
      default:
        return `/projects/${params.projectId}/units/${params.id}/documents`;
    }
  };

  renderAttachmentsTable() {
    const { i18n, context, attachments, size } = this.props;
    const { attachmentTypeFolderIcons } = this.state;
    const sortedAttachments = sortBy(attachments, [this.sortByUpdatedAtDate]);
    const groupedAttachments = groupBy(sortedAttachments, attachment => {
      return attachment.role;
    });

    const featuredAttachments = sortedAttachments.filter(a => a.featured);

    const accu = [];

    if (size !== "small" && featuredAttachments.length) {
      this.renderAttachmentsBlock(
        "star",
        i18n["attachment.hints.featured"],
        featuredAttachments
      ).forEach(a => accu.push(a));
    }

    const groups = context.account
      .getDocumentTypes()
      .reduce((accu, attachmentType) => {
        const attachmentsForType = groupedAttachments[attachmentType.id];

        if (attachmentsForType && attachmentsForType.length > 0) {
          return accu.concat(
            this.renderAttachmentsBlock(
              attachmentTypeFolderIcons.find(x => x.id === attachmentType.id)
                ?.icon || "folder open",
              attachmentType.label,
              attachmentsForType,
              attachmentType.id
            )
          );
        }
        return accu;
      }, accu);

    return this.withInitialTransition(
      <div data-component="attachments">
        <Table stackable columns={16}>
          <Transition.Group as={Table.Body} animation="custom" directional>
            {groups}
          </Transition.Group>
        </Table>
      </div>
    );
  }

  renderAttachmentsBlock(icon, label, attachments, attachmentTypeId) {
    const groupedAttachments = values(groupBy(attachments, "display_name"));
    const accu = [];
    accu.push(
      this.renderAttachmentsHeader(
        icon,
        label,
        groupedAttachments.length,
        attachmentTypeId
      )
    );
    const className = icon === "folder open" ? "show-row" : "hide-row";
    this.renderAttachments(label, groupedAttachments, className).forEach(a =>
      accu.push(a)
    );

    return accu;
  }

  renderAttachmentsHeader(icon, label, count, attachmentTypeId) {
    return (
      <Table.Row key={`type-${label}`}>
        <Table.Cell collapsing width={16} colSpan={3}>
          <Header as="h4" image>
            <Icon
              name={icon}
              size="large"
              role="button"
              onClick={() => this.toggleIcon(attachmentTypeId)}
            />
            <Header.Content>{`${label} (${count})`}</Header.Content>
          </Header>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderPopup = (trigger, content) => {
    return (
      <Popup trigger={trigger} flowing hoverable position="bottom left">
        <Header as="h5">
          <FormattedMessage id="attachment.hints.popup.title" />
        </Header>
        {content}
      </Popup>
    );
  };

  renderReferencesLabel = (item, ellipsisRange) => {
    const { i18n, params, resourceName } = this.props;

    const refs = item.references?.filter(r => {
      if (resourceName === "/units") {
        return !(r.referencee_type === "Unit" && r.unit_id === params.id);
      }
      if (resourceName === "/projects") {
        return r.referencee_type !== "Project";
      }
      return true;
    });
    const title = formatMessage(i18n["attachment.hints.reference_count"], {
      count: item.references_count - 1
    });

    const trigger = (
      <Label
        content={item.references_count - 1}
        icon="share alternate"
        style={{ display: "flex", alignItems: "center" }}
      />
    );

    const content = !refs?.[0] ? (
      <p>{title}</p>
    ) : (
      refs.map(ref => (
        <p key={`${ref.referencee_type}-${ref.referencee_id}`}>
          <Link to={this.getReferenceUrl(ref)} className="ref-link">
            <span
              data-attr="display_name"
              className={`ellipsis ${ellipsisRange}`}
            >
              <FormattedMessage
                id={`attachment.hints.popup.${ref.referencee_type[0].toLowerCase()}${ref.referencee_type.slice(
                  1
                )}`}
              />
              : {ref.display_name}
            </span>
          </Link>
        </p>
      )) || null
    );

    return this.renderPopup(trigger, content);
  };

  renderMessagesLabel = (item, ellipsisRange) => {
    const { i18n } = this.props;
    const title = formatMessage(i18n["attachment.hints.message_count"], {
      count: item.message_attachments_count
    });

    const trigger = (
      <Label
        content={item.message_attachments_count}
        icon="mail"
        style={{ display: "flex", alignItems: "center" }}
      />
    );

    const content = !item.message_attachments?.[0] ? (
      <p>{title}</p>
    ) : (
      item.message_attachments?.map(ref => (
        <p key={`${ref.message_thread_id}-${ref.message_id}`}>
          <Link
            to={this.getReferenceUrl({
              ...ref,
              referencee_type: "Message"
            })}
            className="ref-link"
          >
            <span
              data-attr="display_name"
              className={`ellipsis ${ellipsisRange}`}
            >
              {ref.display_name}
            </span>
          </Link>
        </p>
      )) || null
    );

    return this.renderPopup(trigger, content);
  };

  renderAttachments(type, groupedAttachments, className) {
    const { i18n, context, handleUpdate, size } = this.props;
    const extended = size !== "small";

    return groupedAttachments.map(files => {
      const sortedFiles = sortBy(files, [this.sortByUpdatedAtDate]);
      const item = head(sortedFiles);
      const ellipsisRange = extended ? "ellipsis-400" : "ellipsis-200";

      return (
        <Table.Row
          key={`document-${type}-${item.id}`}
          data-model="attachment"
          data-role={item.role}
          className={className}
        >
          <Table.Cell width={10}>
            <a
              key={item.id}
              href={item.attachment_url}
              target={`document-${item.id}`}
              title={item.display_name}
            >
              <Header as="h4" image>
                <Icon name="file text outline" size="large" />
                <Header.Content>
                  <span
                    data-attr="display_name"
                    style={{
                      wordBreak: "break-word"
                    }}
                  >
                    {item.display_name}
                  </span>
                  <Header.Subheader>
                    {moment(item.updated_at).format("LLL")}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </a>
          </Table.Cell>
          {extended && (
            <Table.Cell textAlign="center" width={2}>
              <AttachmentFeatureToggle
                i18n={i18n}
                attachment={item}
                onUpdate={handleUpdate}
              />
            </Table.Cell>
          )}
          {extended && (
            <Table.Cell textAlign="center" width={3}>
              <AttachmentVisibilityModal
                account={context.account}
                attachment={item}
                onUpdate={handleUpdate}
              />
            </Table.Cell>
          )}
          {extended && (
            <Table.Cell textAlign="center" width={3}>
              <div style={{ display: "flex" }}>
                {this.renderReferencesLabel(item, ellipsisRange)}
                {this.renderMessagesLabel(item, ellipsisRange)}
              </div>
            </Table.Cell>
          )}
          <Table.Cell width={2}>
            <div style={{ display: "flex" }}>
              {sortedFiles.length > 1 && this.renderVersionDialog(sortedFiles)}
              <IsVersionHistoryAccessible>
                <VersionHistoryLink
                  id={item.id}
                  type="Attachment"
                  tooltip={i18n["attachment.hints.attachment_version_history"]}
                />
              </IsVersionHistoryAccessible>
            </div>
          </Table.Cell>
          <Table.Cell textAlign="center" width={1}>
            {this.renderAttachmentDialog(
              item,
              <a id="edit-attachment" role="button">
                <Icon name="setting" color="grey" size="large" />
              </a>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  renderAttachmentSelector() {
    const { context, resourceId, resourceName, i18n } = this.props;
    return (
      <AttachmentSelector
        account={context.account}
        project={context.project}
        resourceId={resourceId}
        resourceName={resourceName}
        label={i18n["attachment.actions.upload"]}
        i18n={i18n}
        compact
        toggleLoading={value => this.setState({ isLoading: value })}
        onSuccess={this.addAttachment}
      />
    );
  }

  renderAttachmentAssigner() {
    const { context, attachments, parentAttachments, i18n } = this.props;

    if (parentAttachments) {
      // remove item from parentAttachments list that are already associated with this line item
      const existingIds = attachments.map(item => item && item.id) || [];
      const assignableAttachments = parentAttachments.filter(
        parentItem =>
          parentItem.id && indexOf(existingIds, parentItem.id) === -1
      );

      return (
        <AttachmentAssigner
          account={context.account}
          i18n={i18n}
          attachments={assignableAttachments}
          label={i18n["attachment.actions.assign"]}
          compact
          handleChange={this.assignAttachment}
        />
      );
    }
    return null;
  }

  renderAttachmentDialog(attachment, trigger) {
    const { context, i18n, destructionMode } = this.props;
    return (
      <AttachmentDialog
        account={context.account}
        i18n={i18n}
        attachment={attachment}
        button={trigger}
        onUpdate={this.props.handleUpdate}
        onRemove={this.props.handleRemove}
        destructionMode={destructionMode}
      />
    );
  }

  renderVersionDialog(attachments) {
    const { context } = this.props;

    if (attachments) {
      return (
        <VersionsDialog
          attachments={attachments}
          account={context.account}
          triggerProps={{
            id: "attachment-versions",
            mode: "icon",
            size: "large",
            color: "blue"
          }}
          dialogProps={{
            handleUpdate: this.props.handleUpdate,
            handleRemove: this.props.handleRemove,
            context: this.props.context,
            i18n: this.props.i18n,
            destructionMode: this.props.destructionMode
          }}
        />
      );
    }
  }

  render() {
    const { actionLayout } = this.props;

    return (
      <>
        <Grid stackable data-component="attachment-actions">
          <Grid.Column width={actionLayout === "stacked" ? 16 : 8}>
            {this.renderAttachmentSelector()}
          </Grid.Column>
          <Grid.Column width={actionLayout === "stacked" ? 16 : 8}>
            {this.renderAttachmentAssigner()}
          </Grid.Column>
        </Grid>

        {this.renderAttachmentsTable()}
      </>
    );
  }
}

Attachments.propTypes = {
  context: shape({
    account: AccountShape,
    project: ProjectShape
  }).isRequired,
  resourceId: PropTypes.number,
  resourceName: PropTypes.string,
  i18n: PropTypes.object,
  handleRemove: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleAdd: PropTypes.func,
  handleAssign: PropTypes.func,
  parentAttachments: PropTypes.array,
  attachments: PropTypes.array,
  actionLayout: PropTypes.string,
  size: PropTypes.string,
  destructionMode: PropTypes.string,
  router: routerShape.isRequired,
  params: shape({ projectId: PropTypes.string, id: PropTypes.string })
    .isRequired,
  unitsResource: PropTypes.instanceOf(UnitsResource).isRequired,
  units: PropTypes.arrayOf(UnitShape).isRequired
};

const mapStateToProps = state => ({
  units: getUnits(state)
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    unitsResource: new UnitsResource(dispatch, props.params.projectId)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attachments)
);
