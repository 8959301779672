import { createSelector } from "reselect";
import { get } from "lodash";

const getRoomBook = state => {
  return get(state, ["pageContent", "room_book"], { sections: [] });
};

export const isReady = state =>
  !!get(state, ["pageContent", "room_book"], null);

export const getFlatRoomBook = createSelector([getRoomBook], roomBook => {
  const sections = [];
  const subSections = [];
  const lineItems = [];
  const subLineItems = [];

  roomBook.sections.forEach(section => {
    section.sections.forEach(subSection => {
      subSection.line_items.forEach(lineItem => {
        lineItem.sub_line_items.forEach(subLineItem => {
          subLineItems.push({
            ...subLineItem,
            room_book_id: roomBook.id,
            line_item_id: lineItem.id,
            sub_section_id: subSection.id,
            section_id: section.id
          });
        });
        lineItems.push({ ...lineItem, section_id: subSection.id });
      });
      subSections.push({ ...subSection, section_id: section.id });
    });
    sections.push(section);
  });

  return {
    id: roomBook.id,
    sections,
    subSections,
    lineItems,
    subLineItems
  };
});

export const getBuyerSelections = state => {
  return get(state, ["pageContent", "buyer_selections"], {});
};
