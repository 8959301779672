import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormattedTrades } from "shared/selectors";

import { Label } from "semantic-ui-react";

const TradesList = props => {
  const trades = props.specificTrades.map(trade => {
    const label = props.trades
      .filter(to => {
        return to.value === trade;
      })
      .map(to => {
        return to.text;
      })[0];
    return (
      <Label
        key={trade}
        basic
        icon="paper hand"
        content={label}
        className="margin top medium"
      />
    );
  });

  return <div className="top bottom margin medium">{trades}</div>;
};

TradesList.propTypes = {
  i18n: PropTypes.object,
  trades: PropTypes.array.isRequired,
  specificTrades: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    i18n: state.i18n,
    trades: getFormattedTrades(state)
  };
};

export default connect(mapStateToProps)(TradesList);
