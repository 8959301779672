import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Menu,
  Modal,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router";
import { delay, groupBy, sortBy, throttle } from "lodash";

import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { instanceOf, func, shape, node } from "prop-types";
import Growl from "../../../actions/growlActions";
import RoomBookProgress from "../../project/RoomBookProgress";

import "./lineItemAssignmentDialog.scss";
import { I18nShape } from "../../../../shared/shapes/i18n.shape";

class LineItemAssignmentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      assignment: null,
      trade: null
    };

    this.selectTrade = this.selectTrade.bind(this);
    this.save = throttle(this.save.bind(this), 500);
  }

  componentDidUpdate(prevProps) {
    const { ctxModel } = prevProps;
    const { assignment } = this.state;
    const assignmentFromModel = ctxModel.getLineItemBatchAssignment();
    if (assignmentFromModel && !assignmentFromModel.equals(assignment)) {
      this.writeTradeToState(assignmentFromModel);
    }
  }

  handleToggleDialog(open) {
    const { onClose } = this.props;
    this.setState({ open, saving: false, loading: false }, () => {
      if (!open && onClose) {
        onClose();
      }
    });
  }

  writeTradeToState = assignment => {
    const { trade } = this.state;
    this.setState({
      trade: trade || assignment.trade,
      assignment
    });
  };

  open() {
    const { ctxCtrl } = this.props;
    const { trade } = this.state;
    this.setState({ loading: true, open: true }, () => {
      ctxCtrl.suggestAssignments(trade).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  selectTrade(tradeValue) {
    const { ctxCtrl } = this.props;
    const { trade } = this.state;
    if (tradeValue !== trade) {
      this.setState({ trade: tradeValue, loading: true }, () => {
        ctxCtrl.suggestAssignments(tradeValue).then(() => {
          this.setState({ loading: false });
        });
      });
    }
  }

  select(unitId) {
    const { assignment } = this.state;
    this.setState({ assignment: assignment.select(unitId) });
  }

  deselect(unitId) {
    const { assignment } = this.state;
    this.setState({ assignment: assignment.deselect(unitId) });
  }

  save() {
    const { ctxCtrl, growl } = this.props;
    const { saving, assignment } = this.state;
    if (saving) {
      return;
    }
    this.setState({ saving: true }, () => {
      delay(() => {
        ctxCtrl.batchAssign(assignment.getBatchRequest()).then(() => {
          growl.success(
            "cartAssignment.message.updated.title",
            "cartAssignment.message.updated.body"
          );
          this.handleToggleDialog(false);
        });
      }, 5);
    });
  }

  renderSaveButton() {
    const { i18n } = this.props;
    const { assignment, saving } = this.state;

    if (assignment && assignment.isEdiable()) {
      return (
        <Button
          id="save"
          content={i18n["meta.actions.save"]}
          loading={saving}
          color="green"
          onClick={this.save}
        />
      );
    }
    const buttons = [
      {
        id: "cancel",
        label: "meta.actions.accept",
        basic: true
      }
    ];

    const button = (
      <Button content={i18n["meta.actions.save"]} basic color="grey" />
    );

    return (
      <ConfirmationDialog
        key="uneditable"
        title="cartAssignment.message.uneditable.title"
        message="cartAssignment.message.uneditable.body"
        buttons={buttons}
        button={button}
        onAsyncClose={() => {
          this.setState({ saving: false, loading: false });
        }}
      />
    );
  }

  renderUnits() {
    const { i18n, ctxModel } = this.props;
    const { assignment } = this.state;
    const stats = groupBy(assignment.statistics, "unit");
    return assignment.units.map(unit => {
      const assignableCount = unit.line_items.assignable_ids.length;
      const assignedCount = unit.line_items.assigned_ids.length;

      const irrelevant = assignableCount + assignedCount < 1;

      return (
        <Grid.Row
          key={unit.id}
          data-model="unit"
          verticalAlign="middle"
          className={irrelevant ? "muted" : ""}
        >
          <Grid.Column width={2} data-attr="unit">
            <Link
              onlyActiveOnIndex
              to={`/projects/${unit.project_slug}/units/${unit.slug}/room-book`}
              target={unit.slug}
            >
              {unit.label}
            </Link>
          </Grid.Column>
          <Grid.Column width={1}>{this.renderAlerts(unit)}</Grid.Column>
          <Grid.Column width={3}>
            <RoomBookProgress
              unitItem={{ id: unit.id, room_book_id: "x" }}
              i18n={i18n}
              unitStatistics={stats}
              processDefinition={ctxModel.account.getLineItemLifeCycle()}
              withBuyerPortal={ctxModel.account.isEnabled("buyer_portal")}
            />
          </Grid.Column>
          <Grid.Column width={2} textAlign="center" data-attr="pending">
            {unit.line_items.pending}
          </Grid.Column>
          <Grid.Column width={2} textAlign="center" data-attr="assignable">
            {assignableCount > 0 ? <b>{assignableCount}</b> : assignableCount}
          </Grid.Column>
          <Grid.Column width={2} textAlign="center">
            <Button.Group>
              <Button
                id="unassign"
                size="tiny"
                icon="angle double left"
                onClick={() => {
                  this.deselect(unit.id);
                }}
              />
              <Button
                id="assign"
                size="tiny"
                icon="angle double right"
                onClick={() => {
                  this.select(unit.id);
                }}
              />
            </Button.Group>
          </Grid.Column>
          <Grid.Column width={2} textAlign="center" data-attr="assigned">
            {assignedCount}
          </Grid.Column>
          <Grid.Column width={2} textAlign="center" data-attr="completed">
            {unit.line_items.completed}
          </Grid.Column>
        </Grid.Row>
      );
    });
  }

  renderContent() {
    const { assignment } = this.state;
    if (!assignment) {
      return null;
    }
    const { i18n } = this.props;
    return (
      <Grid data-component="assignmentGrid" data-trade={assignment.trade}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3}>
            <b>{i18n["cartAssignment.dialog.unit.label"]}</b>
          </Grid.Column>
          <Grid.Column width={3}>
            <b>{i18n["cartAssignment.dialog.progress.label"]}</b>
          </Grid.Column>
          <Grid.Column
            width={2}
            textAlign="center"
            title={i18n["cartAssignment.dialog.pending.hint"]}
          >
            <b>{i18n["cartAssignment.dialog.pending.label"]}</b>
          </Grid.Column>
          <Grid.Column
            width={2}
            textAlign="center"
            title={i18n["cartAssignment.dialog.assignable.hint"]}
          >
            <b>{i18n["cartAssignment.dialog.assignable.label"]}</b>
          </Grid.Column>
          <Grid.Column width={2} textAlign="center" />
          <Grid.Column
            width={2}
            textAlign="center"
            title={i18n["cartAssignment.dialog.assigned.hint"]}
          >
            <b>{i18n["cartAssignment.dialog.assigned.label"]}</b>
          </Grid.Column>
          <Grid.Column
            width={2}
            textAlign="center"
            title={i18n["cartAssignment.dialog.completed.hint"]}
          >
            <b>{i18n["cartAssignment.dialog.completed.label"]}</b>
          </Grid.Column>
        </Grid.Row>
        {this.renderUnits()}
      </Grid>
    );
  }

  renderFilter() {
    const { ctxModel } = this.props;
    const { trade, assignment } = this.state;

    const assignedTradesCount = ctxModel.getAssignedTradesCount();
    const trades = ctxModel.getTrades();

    const tradeOptions = trades
      .filter(t => {
        return assignment && assignment.isRelevantTrade(t.id);
      })
      .map(t => {
        const isAssigned = !!assignedTradesCount[t.id];
        const icon = isAssigned ? "star" : "outline star";
        const color = isAssigned ? "green" : "grey";
        return {
          id: t.id,
          key: t.id,
          content: (
            <span>
              <Icon name={icon} color={color} />{" "}
              <span style={{ display: "inline-block" }}>{t.label}</span>
            </span>
          ),
          active: t.id === trade
        };
      });

    const sortFn = item => {
      return assignedTradesCount[item.id];
    };

    return (
      <Menu
        vertical
        pointing
        secondary
        compact
        items={sortBy(tradeOptions, sortFn)}
        onItemClick={(event, data) => {
          this.selectTrade(data.id);
        }}
      />
    );
  }

  renderAlerts(unit) {
    const { i18n } = this.props;
    const assignedDifferently = unit.line_items.assigned_differently;

    if (assignedDifferently) {
      return (
        <Icon
          name="warning sign"
          color="orange"
          title={i18n["cartAssignment.dialog.assignedDifferently.hint"]}
        />
      );
    }
    return null;
  }

  render() {
    const { i18n, button } = this.props;
    const { open, loading, saving } = this.state;

    return (
      <Modal
        data-component="lineItemAssignmentDialog"
        size="large"
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={button}
        open={open}
        onOpen={() => this.open()}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>Leistungsumfang bearbeiten</Modal.Header>
        <Modal.Content style={{ padding: "0px" }}>
          <Segment basic style={{ minHeight: "256px", padding: "0px" }}>
            <Dimmer active={loading || saving} inverted>
              <Loader size="mini">
                {i18n[`meta.states.${saving ? "saving" : "loading"}`]}
              </Loader>
            </Dimmer>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: "1", width: "25%", padding: "0px" }}>
                {this.renderFilter()}
              </div>
              <div style={{ flexGrow: "1", width: "100%", padding: "0px" }}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>{this.renderContent()}</Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </Segment>
        </Modal.Content>
        <Modal.Actions>{this.renderSaveButton()}</Modal.Actions>
      </Modal>
    );
  }
}

LineItemAssignmentDialog.propTypes = {
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired,
  onClose: func,
  ctxModel: shape({
    getLineItemBatchAssignment: func.isRequired,
    getTrades: func.isRequired,
    getAssignedTradesCount: func.isRequired,
    isEnabled: func,
    getLineItemLifeCycle: func
  }),
  ctxCtrl: shape({
    batchAssign: func.isRequired,
    suggestAssignments: func.isRequired
  }).isRequired,
  button: node.isRequired
};

LineItemAssignmentDialog.defaultProps = {
  onClose: () => {},
  ctxModel: {
    isEnabled: () => {},
    getLineItemLifeCycle: () => {}
  }
};

const mapStateToProps = state => ({
  i18n: state.i18n
});

const mapDispatchToProps = (dispatch, props) => {
  return { ...props, growl: new Growl(dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemAssignmentDialog);
