import PropTypes from "prop-types";
import React from "react";
import { Label, Message } from "semantic-ui-react";
import { filter } from "lodash";
import { connect } from "react-redux";

class UsageHint extends React.Component {
  static propTypes = {
    projectConfig: PropTypes.object.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object)
  };

  render() {
    const usages = this.getUsages();
    const { projectConfig, projects, ...passThroughProps } = this.props;

    return (
      <Message>
        <Message.Header content="Diese Konfiguration wird in diesen Projekten benutzt:" />
        <Message.Content>
          {usages.map(project => (
            <Label
              icon="building outline"
              data-model="project"
              {...passThroughProps}
              key={project.id}
              content={project.name}
            />
          ))}
        </Message.Content>
      </Message>
    );
  }

  getUsages() {
    const { projects, projectConfig } = this.props;
    const property = `project_config_selection.${projectConfig.role}_config_id`;
    return filter(projects, [property, projectConfig.id]);
  }
}

const mapStateToProps = state => ({
  projects: state.pageContent.projects
});

export default connect(mapStateToProps, () => ({}))(UsageHint);
