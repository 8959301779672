/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Header,
  Icon,
  Input,
  List,
  Segment,
  Button,
  Loader
} from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import { ContactsResource } from "builder_portal/actions/contactsActions";
import { getContacts } from "shared/selectors/contacts";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import CreateContactDialog from "./CreateContactDialog";

const Contacts = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const resource = new ContactsResource(dispatch);
  const [initialLoadingIndicator, setInitialLoadingIndicator] = useState(false);
  const [filterContact, setFilterContact] = useState("");
  const contacts = useSelector(getContacts);

  useEffect(() => {
    setInitialLoadingIndicator(true);
    resource
      .fetchAll()
      .then(() => {
        return setInitialLoadingIndicator(false);
      })
      .catch(() => {
        return setInitialLoadingIndicator(false);
      });
  }, []);

  const handleChange = e => {
    setFilterContact(e.target.value);
  };

  const filteredContacts =
    filterContact.trim().length > 0
      ? contacts.filter(
          contact =>
            contact.first_name
              .toLowerCase()
              .indexOf(filterContact.trim().toLowerCase()) > -1 ||
            contact.last_name
              .toLowerCase()
              .indexOf(filterContact.trim().toLowerCase()) > -1 ||
            contact.email
              .toLowerCase()
              .indexOf(filterContact.trim().toLowerCase()) > -1 ||
            contact.phone.indexOf(filterContact.trim()) > -1
        )
      : contacts;

  const sortedContacts = sortBy(filteredContacts, "last_name");

  return (
    <div>
      <div data-component="contacts">
        <Helmet title={intl.formatMessage({ id: "contacts.title" })} />
        <Grid
          stackable
          verticalAlign="middle"
          className="margin bottom spacious"
        >
          <Grid.Column width="4">
            <Header size="large">
              <FormattedMessage id="contacts.title" />
            </Header>
          </Grid.Column>
          <Grid.Column width="12" textAlign="right">
            <Input
              icon="search"
              placeholder={intl.formatMessage({
                id: "contacts.search"
              })}
              name="filterContact"
              value={filterContact}
              onChange={handleChange}
            />
            <CreateContactDialog
              trigger={
                <Button
                  basic
                  icon="plus"
                  floated="right"
                  id="new-webhook"
                  content={intl.formatMessage({ id: "contacts.add" })}
                />
              }
            />
          </Grid.Column>
        </Grid>
      </div>
      <Segment.Group>
        {initialLoadingIndicator ? (
          <Segment>
            <Loader active inline="centered" />
          </Segment>
        ) : sortedContacts.length === 0 ? (
          <Segment>
            <FormattedMessage
              id="contacts.no_contacts_found"
              defaultMessage="contacts.no_contacts_found"
            />
          </Segment>
        ) : (
          sortedContacts?.map(contact => {
            return (
              <Segment key={contact.id} clearing data-model="contact">
                <Grid>
                  <Grid.Column width={16}>
                    <Header
                      as="h5"
                      size="medium"
                      data-attr="name"
                      className="margin top none"
                    >
                      <div className="flex justify-content-space-between">
                        <div>
                          <span data-attr="last_name">{contact.last_name}</span>
                          ,&nbsp;
                          <span data-attr="first_name">
                            {contact.first_name}
                          </span>
                        </div>
                        <div>
                          <IsVersionHistoryAccessible
                            contact={contact}
                            i18n={intl}
                          >
                            <VersionHistoryLink
                              type="Contact"
                              id={contact.id}
                              className="float right"
                              size="normal"
                            />
                          </IsVersionHistoryAccessible>
                          <CreateContactDialog
                            trigger={
                              <Icon
                                color="grey"
                                name="setting"
                                className="pointer"
                              />
                            }
                            model={contact}
                          />
                        </div>
                      </div>
                    </Header>
                    <List horizontal>
                      <List.Item>
                        <Icon name="mail outline" />
                        <span data-attr="email">{contact.email}</span>
                      </List.Item>
                      {contact.phone && (
                        <List.Item>
                          <Icon name="call" />
                          <span data-attr="phone">{contact.phone}</span>
                        </List.Item>
                      )}
                    </List>
                  </Grid.Column>
                </Grid>
              </Segment>
            );
          })
        )}
      </Segment.Group>
    </div>
  );
};

export default Contacts;
