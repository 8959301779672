import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Icon, Message } from "semantic-ui-react";
import { getInvalidSubLineItemsById } from "builder_portal/selectors/roomBooks/subLineItemRules";
import { FormattedMessage } from "react-intl";
import { defaultRichTextTags } from "builder_portal/helpers/defaultRichTextTags";

class SubLineItemRuleHint extends React.Component {
  static propTypes = {
    lineItem: PropTypes.object,
    rule: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { rule } = this.props;
    if (rule) {
      return (
        <Message color="yellow" icon>
          <Icon name="warning circle" size="small" />
          <Message.Content>
            <Message.Header>
              <FormattedMessage id="productRules.headers.title" />
            </Message.Header>
            <FormattedMessage
              id="productRules.error.ruleViolation"
              values={{
                sub_line_item_title: rule.sub_line_item_when.title,
                ...defaultRichTextTags
              }}
            />
          </Message.Content>
        </Message>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, props) => {
  const rules = getInvalidSubLineItemsById(state);
  return {
    i18n: state.i18n,
    rule: rules[props.lineItem.getId()]
  };
};

export default connect(mapStateToProps)(SubLineItemRuleHint);
