import { createSelector } from "reselect";
import {
  get,
  groupBy,
  keyBy,
  takeRightWhile,
  reverse,
  takeWhile,
  min,
  max,
  isNumber
} from "lodash";

import { getState } from "../base";
import { getFlatRoomBook, getBuyerSelections } from "./base";
import { getProductOptions, getProductGroup } from "./productOptions";
import { getSubLineItemStatus } from "./status";
import { getSubLineItemDependencies } from "./productRules";

import { getAccount } from "../account";

//* *****************************************************************************
const applyBaseInfo = (state, raw) => {
  return {
    ...raw,
    lineItemId: raw.line_item_id,
    priceStrategy: raw.price_strategy,
    quantity: raw.quantity,
    defaultQuantity: raw.default_quantity,
    isOptional: raw.optional
  };
};

const applyProductGroup = (state, raw) => {
  return {
    productGroup: getProductGroup(state, raw)
  };
};

const applyStatus = (state, raw, subLineItem) => {
  return {
    status: getSubLineItemStatus(state)(subLineItem)
  };
};

const applyQuantity = (state, raw, subLineItem) => {
  const buyerSelections = getBuyerSelections(state);
  const buyerSelection = buyerSelections[subLineItem.id];

  if (buyerSelection && isNumber(buyerSelection.quantity)) {
    return {
      quantity: buyerSelection.quantity,
      quantityBuyerSelection: buyerSelection.quantity
    };
  }
  return {
    quantityBuyerSelection: null
  };
};

const applyProductOptions = (state, raw, subLineItem) => {
  const options = getProductOptions(state)(subLineItem);
  return {
    options
  };
};

const applyDerivedInfos = (state, raw, subLineItem) => {
  let defactoSelectedOption;
  let hasBuyerSelection;
  let isConfigurable;
  let isDecided;
  let isPriceOnRequest;
  let isProgressed;
  let isRecommendable;
  let isSelectable;
  let isValid;
  let isVisible;
  let selectedOption;
  let thumbUrl;
  let total;
  let totalBase;
  let totalDefault;
  let totalMin;
  let totalRelative;
  let validOptions;

  const {
    total: subLineItemTotal,
    options,
    status,
    productGroup
  } = subLineItem;
  const buyerSelectedOption = options.find(option => option.isBuyerSelection);
  const defaultOption = options.find(option => option.isDefault);

  validOptions = options.filter(option => option.isValidOption);
  const itemSelectedOption = validOptions.find(o => o.isSelected);

  if (!status.selectable) {
    total = subLineItemTotal;
    selectedOption = {
      ...itemSelectedOption,
      isOnRequest: false,
      isQuantityOnRequest: false,
      total
    };

    defactoSelectedOption = selectedOption;
    hasBuyerSelection = true;
    isConfigurable = true;
    isDecided = true;
    isPriceOnRequest = false;
    isProgressed = true;
    isRecommendable = true;
    isSelectable = false;
    isValid = true;
    isVisible = true;
    thumbUrl = itemSelectedOption?.thumbUrl;
    totalBase = subLineItemTotal;
    totalDefault = isPriceOnRequest ? 0 : get(defaultOption, "defaultTotal", 0);
    totalMin = subLineItemTotal;
    totalRelative = subLineItemTotal;
    validOptions = [selectedOption];
  } else {
    selectedOption = buyerSelectedOption;

    const validDefaultOption = !subLineItem.isOptional ? validOptions[0] : null;
    defactoSelectedOption = buyerSelectedOption || validDefaultOption;

    const optionsVisible = options.some(option => option.isVisible);
    isPriceOnRequest =
      get(defactoSelectedOption || validOptions[0], "isOnRequest", false) ||
      (get(
        defactoSelectedOption || validOptions[0],
        "isQuantityOnRequest",
        false
      ) &&
        (get(defactoSelectedOption, "total", 0) != 0 ||
          subLineItem.isOptional));

    total = isPriceOnRequest ? 0 : get(defactoSelectedOption, "total", 0);
    totalBase = isPriceOnRequest
      ? 0
      : min(options.filter(o => o.isDefault).map(o => o.total));
    totalMin = isPriceOnRequest
      ? 0
      : min(options.filter(o => o.isAcceptableProduct).map(o => o.total));
    totalDefault = isPriceOnRequest ? 0 : get(defaultOption, "defaultTotal", 0);
    totalRelative = subLineItem.optional ? max([total - totalMin, 0]) : total;

    isVisible = optionsVisible && get(productGroup, "portal_offer", true);
    hasBuyerSelection = !!buyerSelectedOption;
    thumbUrl = options.find(o => o.isAcceptableProduct)?.thumbUrl;
    isValid = options.every(o => o.isValidSelection);
    isRecommendable = options.every(
      o => !o.isBuyerSelection || o.isRecommendable
    );
    isConfigurable =
      isVisible && options.some(o => o.isAcceptableProduct && o.isVisible);
    isDecided = isValid && (!isConfigurable || !!selectedOption);
    isSelectable = true;
    isProgressed = false;
  }

  const productTags = get(selectedOption, "productTags", []);

  return {
    total,
    totalMin,
    totalBase,
    totalDefault,
    totalRelative,
    isVisible,
    hasBuyerSelection,
    isDecided,
    isValid,
    thumbUrl,
    isConfigurable,
    isProgressed,
    isSelectable,
    isRecommendable,
    isPriceOnRequest,
    productTags,
    defactoSelectedOption,
    validOptions
  };
};

const applyAutoDecision = (state, raw, subLineItem) => {
  const account = getAccount(state);
  const enabled = account.isEnabled("bp_auto_selection");
  return {
    isAutoDecidable:
      enabled && !subLineItem.isDecided && subLineItem.validOptions.length === 1
  };
};

const MAPPER = [
  applyBaseInfo,
  applyProductGroup,
  applyStatus,
  applyQuantity,
  applyProductOptions,
  applyDerivedInfos,
  applyAutoDecision
];
//* *****************************************************************************

export const getSubLineItems = createSelector(
  [getFlatRoomBook, getState],
  (roomBook, state) => {
    return roomBook.subLineItems.map(raw => {
      return MAPPER.reduce((subLineItem, mapper) => {
        return Object.assign(subLineItem, mapper(state, raw, subLineItem));
      }, {});
    });
  }
);

export const getSubLineItemsById = createSelector(
  [getSubLineItems],
  subLineItems => {
    return keyBy(subLineItems, "id");
  }
);

export const getSubLineItemsByLineItemId = createSelector(
  [getSubLineItems],
  subLineItems => {
    return groupBy(subLineItems, "lineItemId");
  }
);

export const findSubLineItems = state => {
  return (lineItemId, filterFn = () => true) => {
    return (getSubLineItemsByLineItemId(state)[lineItemId] || []).filter(
      filterFn
    );
  };
};

export const findSubLineItem = state => {
  return subLineItemId => {
    return getSubLineItemsById(state)[subLineItemId];
  };
};

const truePredicate = () => true;

export const getPrevSibling = (
  state,
  subLineItem,
  predicate = truePredicate
) => {
  const siblings =
    getSubLineItemsByLineItemId(state)[subLineItem.lineItemId] || [];
  return reverse(
    takeWhile(siblings, sibling => sibling.id !== subLineItem.id)
  ).filter(predicate)[0];
};

export const getNextSibling = (
  state,
  subLineItem,
  predicate = truePredicate
) => {
  const siblings =
    getSubLineItemsByLineItemId(state)[subLineItem.lineItemId] || [];
  return takeRightWhile(siblings, sibling => {
    return sibling.id !== subLineItem.id;
  }).filter(predicate)[0];
};

export const getAffectingSubLineItems = createSelector(
  [getSubLineItemDependencies, getSubLineItemsById],
  (dependencyMap, subLineItems) => {
    return subLineItem => {
      return (dependencyMap[subLineItem.id] || []).map(id => {
        return subLineItems[id];
      });
    };
  }
);

export const getSubLineItemForProductLineTag = createSelector(
  [getSubLineItems],
  subLineItems => productLineTag => {
    return subLineItems.find(({ options }) => {
      return options.find(({ productTags }) =>
        productTags.includes(productLineTag)
      );
    });
  }
);
