import {
  ADD_CONFIGURATOR_SELECTION,
  REMOVE_CONFIGURATOR_SELECTION,
  SET_CONFIGURATOR_SELECTION,
  TOGGLE_CONFIGURATOR_SELECTION
} from "../../shared/constants/appConstants";

/*
 ** State Typings
 * {
 *   [subLineItemId]: selectedOptionId
 * }
 */
const INIT_STATE = {};

const configuratorPreviewSelection = (selection = INIT_STATE, action) => {
  const { type, payload } = action;
  if (payload) {
    const { subLineItemId, optionId } = payload;
    switch (type) {
      case TOGGLE_CONFIGURATOR_SELECTION:
        return {
          ...selection,
          [subLineItemId]:
            selection[subLineItemId] === optionId ? null : optionId
        };
      case ADD_CONFIGURATOR_SELECTION:
        return {
          ...selection,
          [subLineItemId]: optionId
        };
      case REMOVE_CONFIGURATOR_SELECTION:
        return {
          ...selection,
          [subLineItemId]: null
        };
      case SET_CONFIGURATOR_SELECTION:
        return payload;
      default:
        return selection;
    }
  }

  return selection;
};

export default configuratorPreviewSelection;
