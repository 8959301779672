import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Label, Segment, Divider } from "semantic-ui-react";
import ContractorAssignmentDialog from "./ContractorAssignmentDialog";
import TradesAndContractors from "./TradesAndContractors";
import "./contractorsSegment.scss";

class ContractorsSegment extends React.Component {
  static propTypes = {
    onChange: PropTypes.func
  };

  render() {
    return (
      <Segment data-component="contractorsSegment">
        <Label attached="top" basic>
          <FormattedMessage
            id="activity.contractor.title"
            defaultText="activity.contractor.title"
          />
        </Label>
        <ContractorAssignmentDialog onSaved={this.props.onChange} />
        <Divider />
        <TradesAndContractors />
      </Segment>
    );
  }
}

export default ContractorsSegment;
