import { combineReducers } from "redux";

import modals from "shared/reducers/modals";
import roomBookFilter from "shared/reducers/pageContent/roomBookFilter";
import { i18n } from "../../shared/reducers/i18n";
import { locale } from "../../shared/reducers/locale";
import { account } from "../../shared/reducers/account";
import { message } from "../../shared/reducers/message";
import { flashAction } from "../../shared/reducers/flashAction";
import { isLoading } from "../../shared/reducers/isLoading";
import { logoPath } from "../../shared/reducers/logoPath";
import { pageContent } from "../../shared/reducers/pageContent";
import { filter } from "../../shared/reducers/filter";
import { dialog } from "../../shared/reducers/dialog";
import { notifications } from "../../shared/reducers/notifications";
import { contractors } from "../../shared/reducers/contractors";
import { branding } from "../../buyer_portal/reducers/branding";
import cloudinaryEnv from "../../shared/reducers/cloudinaryEnv";
import configuratorPreviewSelection from "../../buyer_portal/reducers/configuratorPreviewSelection";
import stringEnv from "../../shared/reducers/stringEnv";

export const combinedReducers = combineReducers({
  i18n,
  locale,
  account,
  message,
  flashAction,
  isLoading,
  logoPath,
  pageContent,
  filter,
  dialog,
  notifications,
  contractors,
  roomBookFilter,
  modals,
  branding,
  configuratorPreviewSelection,
  cloudinaryEnv,
  planoSsoUrl: stringEnv,
  planoSsoClientId: stringEnv,
  planoSsoEnabled: stringEnv
});
