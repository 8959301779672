import PropTypes from "prop-types";
import React, { Component } from "react";
import { Loader, Dimmer } from "semantic-ui-react";

class ProgressDialog extends Component {
  static propTypes = {
    message: PropTypes.string,
    visible: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.visible) {
      return (
        <Dimmer active page>
          <Loader active inline="centered">
            <p dangerouslySetInnerHTML={{ __html: this.props.message }} />
          </Loader>
        </Dimmer>
      );
    }
    return <span />;
  }
}

export default ProgressDialog;
