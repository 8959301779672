import { createSelector } from "reselect";
import { get, keyBy } from "lodash";

import { getDocumentTypes } from "shared/selectors";
import { getFilter, getPageContent } from "shared/selectors/base";
import { FILTER_ATTACHMENTS } from "../constants/filters";
import { getProjects, getUnits, getBuyers } from "./masterdata";

const getUnitsWithBuyers = createSelector(
  [getUnits, getBuyers],
  (units, buyers) => {
    const buyersById = keyBy(buyers, "id");

    return units.map(unit => {
      return {
        ...unit,
        buyers: unit.buyer_ids.map(id => buyersById[id])
      };
    });
  }
);

export const getAllDocumentTypes = createSelector(
  [getDocumentTypes],
  documentTypes => {
    return [{ id: "all", label: "Alle" }].concat(documentTypes);
  }
);

const getDefaultFitler = createSelector([getProjects], projects => {
  const project_id = get(projects, [0, "id"]);
  return {
    project_id
  };
});

export const getAttachmentsFilter = createSelector(
  [getFilter, getDefaultFitler, getProjects, getUnits, getAllDocumentTypes],
  (filter, defaultFilter, projects, units, documentTypes) => {
    const f = get(filter, FILTER_ATTACHMENTS, defaultFilter);

    const projectIds = projects.map(project => project.id);

    if (!projectIds.includes(f.project_id)) {
      f.project_id = projectIds[0];
      f.unit_id = null;
    }

    if (!f.unit_id) {
      const unit = units.find(unit => unit.project_id === f.project_id);
      f.unit_id = get(unit, "id");
    }

    if (!f.document_type) {
      f.document_type = get(documentTypes, [0, "id"]);
    }

    return f;
  }
);

export const getUnitsForProject = createSelector(
  [getAttachmentsFilter, getUnitsWithBuyers],
  (filter, units) => units.filter(unit => unit.project_id === filter.project_id)
);

export const getAttachments = createSelector([getPageContent], pageContent => {
  return pageContent?.attachments;
});
