import React, { useContext, useMemo } from "react";
import { Segment, Header, Grid, Icon, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { arrayOf } from "prop-types";
import { DesignLineGroupShape } from "shared/shapes";
import { If } from "shared/components/elements/Conditions";
import { sortBy } from "lodash";
import { DesignLinesLoaderContext } from "./DesignLinesLoader";
import DesignLineGroupDialog from "./DesignLineGroupDialog";
import DesignLineDialog from "./DesignLineDialog";
import DesignLineItems from "./DesignLineItems";

const DesignLineGroups = ({ designLineGroups }) => {
  const { saveDesignLineGroup } = useContext(DesignLinesLoaderContext);

  const { destroyDesignLineGroup, catalogId, loading } = useContext(
    DesignLinesLoaderContext
  );
  const intl = useIntl();

  const renderDeleteButton = id => {
    const button = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="design_line_group-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: handleClose => {
          destroyDesignLineGroup(id)
            .then(handleClose)
            .catch(handleClose);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="design_lines.groups.removeConfirmation.title"
        message="design_lines.groups.removeConfirmation.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const handleMove = (line, direction) => {
    if (loading) return;
    const position = direction === "up" ? line.position - 1 : line.position + 1;
    saveDesignLineGroup({ ...line, position });
  };

  const sortedDesignLineGroups = useMemo(() => {
    if (!designLineGroups) return [];
    // sort by position
    return sortBy(designLineGroups, "position");
  }, [designLineGroups]);

  const renderDesignLineGroups = () => {
    return sortedDesignLineGroups.map((group, index) => {
      return (
        <Segment key={group.id}>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="12">
              <Header as="h5">{group.name}</Header>
            </Grid.Column>
            <Grid.Column width="1">
              <If condition={index > 0}>
                <Icon
                  style={{ cursor: "pointer" }}
                  name="arrow up"
                  color="grey"
                  onClick={() => handleMove(group, "up")}
                />
              </If>
            </Grid.Column>
            <Grid.Column width="1">
              <If condition={index < designLineGroups.length - 1}>
                <Icon
                  style={{ cursor: "pointer" }}
                  name="arrow down"
                  color="grey"
                  onClick={() => handleMove(group, "down")}
                />
              </If>
            </Grid.Column>
            <Grid.Column width="1">
              <DesignLineGroupDialog
                model={group}
                catalogId={catalogId}
                button={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    role="button"
                    id="design_line_group-edit"
                    data-tooltip={intl.formatMessage({
                      id: "meta.actions.edit"
                    })}
                    disabled={loading}
                  >
                    <Icon name="setting" color="grey" />
                  </a>
                }
              />
            </Grid.Column>
            <Grid.Column width="1" textAlign="right">
              {renderDeleteButton(group.id)}
            </Grid.Column>
          </Grid>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="16" textAlign="right" verticalAlign="bottom">
              <DesignLineDialog
                model={{ name: "", title: "", description: "", image: "" }}
                groupId={group.id}
                button={
                  <Button
                    id="design_line-new"
                    icon="plus"
                    content={intl.formatMessage({
                      id: "design_lines.add"
                    })}
                    loading={loading}
                    disabled={loading}
                  />
                }
              />
            </Grid.Column>
          </Grid>

          <DesignLineItems designLineGroup={group} />
        </Segment>
      );
    });
  };

  return renderDesignLineGroups();
};

DesignLineGroups.propTypes = {
  designLineGroups: arrayOf(DesignLineGroupShape)
};

export default DesignLineGroups;
