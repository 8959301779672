import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Segment,
  Icon,
  Label,
  Button,
  List,
  Header
} from "semantic-ui-react";
import { get, isEqual } from "lodash";
import DOMPurify from "dompurify";
import { getAccount, getUser } from "shared/selectors";
import { MessageShape } from "shared/shapes/message.shape";
import { UserShape } from "shared/shapes/user.shape";
import ContactLabel from "./ContactLabel";
import MessageForm from "./MessageForm";
import contactsMap from "../../helpers/contactsMap";
import HtmlMessageDialog from "../messages/HtmlMessageDialog";
import MessageBody from "../messages/MessageBody";
import FeatureToggle from "../../../shared/components/elements/FeatureToggle";
import { If } from "../../../shared/components/elements/Conditions";

const awPrefix = subject => {
  return subject.toLowerCase().indexOf("aw:") === 0 ? "" : "AW: ";
};

class MessageEvent extends React.Component {
  static convertRecipientFormat = recipient => {
    // user:1 to User-1
    const parts = recipient.split(":");
    if (parts.length !== 2) return recipient;
    return `${parts[0].charAt(0).toUpperCase()}${parts[0].slice(1)}-${
      parts[1]
    }`;
  };

  static hendleDraftReply = (message, i18n, user) => {
    // template vars
    const { subject } = message;
    const date = moment(message.created_at).format("DD.MM.YYYY [um] HH:mm");
    const sender = `${user.name} <${user.email}>`;
    const { body_html: body } = message;

    const subjectTemplate = i18n["message.reply.subjectTemplate"].replace(
      /{/g,
      "${"
    );
    const bodyTemplate = i18n["message.reply.bodyTemplateDraft"].replace(
      /{/g,
      "${"
    );

    return {
      subject: MessageEvent.renderTemplateDraft(subjectTemplate, {
        subject,
        prefix: awPrefix(subject)
      }),
      body_template: MessageEvent.renderTemplateDraft(bodyTemplate, {
        date,
        sender,
        body
      }),
      recipients: message.recipients.map(r =>
        MessageEvent.convertRecipientFormat(r)
      ),
      cc: message.cc?.map(r => MessageEvent.convertRecipientFormat(r)),
      bcc: message.bcc?.map(r => MessageEvent.convertRecipientFormat(r)),
      parent_id: message.parent_id,
      id: message.id,
      isDraft: true
    };
  };

  static renderTemplateDraft = (t, vars) => {
    const prefix = t.indexOf("body") > -1 ? "<p></p>" : "";
    const content = Object.entries(vars)
      .reduce((s, [key, value]) => {
        const reg = `\\$\\{${key}\\}`;
        return s.replace(new RegExp(reg, "g"), value);
      }, t)
      .replace(new RegExp("<p></p>", "g"), "");
    // if body text is not changes, we need first empty row above <blockquiote>, but add it only once
    return `${content.substr(0, 3) === "<p>" ? "" : prefix}${content}`;
  };

  constructor(props) {
    super(props);

    this.state = {
      replyForm: null
    };
  }

  componentDidMount() {
    this.handleToggleByStringLength(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.clearReply) {
      return { ...state, clearReply: false };
    }
    if (props.messageDraft) {
      if (!isEqual(props.messageDraft, state.replyForm)) {
        return {
          ...state,
          replyForm: props.messageDraft
            ? MessageEvent.hendleDraftReply(
                props.messageDraft,
                props.i18n,
                props.user
              )
            : null
        };
      }
    }

    return state;
  }

  onCloseMessageForm = () => {
    const { handleClose } = this.props;
    handleClose();
    this.setState({ replyForm: null, clearReply: true });
  };

  renderTemplate = (t, vars) => {
    return Object.entries(vars)
      .reduce((s, [key, value]) => {
        const reg = `\\$\\{${key}\\}`;
        return s.replace(new RegExp(reg, "g"), value);
      }, t)
      .replace(new RegExp("\\\\n", "g"), "\n");
  };

  handleReply = () => {
    const { message, i18n } = this.props;

    // template vars
    const { subject } = message; // eslint-disable-line no-unused-vars
    const date = moment(message.created_at).format("DD.MM.YYYY [um] HH:mm"); // eslint-disable-line no-unused-vars
    const sender = `${message.sender.label.contact} <${message.sender.email}>`; // eslint-disable-line no-unused-vars
    const { body } = message; // eslint-disable-line no-unused-vars

    const subjectTemplate = i18n["message.reply.subjectTemplate"].replace(
      /{/g,
      "${"
    );

    const bodyTemplate = i18n["message.reply.bodyTemplate"].replace(/{/g, "${");
    const vars = { subject, date, sender, body, prefix: awPrefix(subject) };

    const replyForm = {
      subject: this.renderTemplate(subjectTemplate, vars), // eval('U+0060' + subjectTemplate + 'U+0060'), // parses template string using the local var scope (defined above)
      body: this.renderTemplate(bodyTemplate, vars), // `${bodyTemplate}`, // eval('U+0060' + bodyTemplate + 'U+0060'), // parses template string using the local var scope (defined above)
      recipients: message.sender.type
        ? [`${message.sender.type}-${message.sender.id}`]
        : [],
      parent_id: message.id
    };

    this.setState({ replyForm });
  };

  handleToggle = () => {
    const { disabled, fadeMessage } = this.state;
    if (!disabled) {
      this.setState({
        fadeMessage: !fadeMessage
      });
    }
  };

  handleUpdate = () => {
    const { ctxCtrl } = this.props;
    return ctxCtrl.refresh();
  };

  handleToggleByStringLength(props) {
    if (props.message) {
      if (props.message.body && props.message.body.length > 300) {
        this.setState({
          fadeMessage: true
        });
      } else {
        this.setState({
          disabled: true,
          fadeMessage: false
        });
      }
    }
  }

  renderSender = message => {
    return <ContactLabel type={message.type} contact={message.sender} />;
  };

  renderRecipient = (message, mode) => {
    const label =
      mode !== "to"
        ? [
            <span key={mode} style={{ opacity: 0.6 }}>
              {mode}:{" "}
            </span>
          ]
        : [];

    const list = message.recipients
      .filter(c => c.mode === mode)
      .map(contact => (
        <ContactLabel key={contact.id} type={message.type} contact={contact} />
      ));
    return list.length ? [...label, ...list] : null;
  };

  renderReplyMessageForm() {
    const {
      account,
      i18n,
      message,
      activity,
      buyer,
      contacts,
      attachments,
      ctxCtrl,
      emailReplyIncludeThread,
      messageDraft
    } = this.props;

    const { replyForm, fadeMessage, disabled } = this.state;

    return (
      <div
        data-component="timelineItem"
        data-event={`${message.type}-message`}
        data-id={message.id}
      >
        <div className="bullet-pointer">
          <Icon
            color="grey"
            size="large"
            circular
            name={message.type === "outbound" ? "mail" : "mail forward"}
          />
          {/* eslint-disable-next-line */}
          <a name={message.id} />
        </div>
        <Container data-component="messageEvent">
          <Segment attached="top" data-component="messageForm">
            <MessageForm
              account={account}
              i18n={i18n}
              activity={activity}
              buyer={buyer}
              handleClose={this.onCloseMessageForm}
              handleUpdate={this.handleUpdate}
              contacts={contactsMap(contacts)}
              attachments={attachments}
              macro={{ ...replyForm, isReply: true }}
              ctxCtrl={ctxCtrl}
              emailReplyIncludeThread={
                messageDraft ? false : emailReplyIncludeThread
              }
            />
          </Segment>
          <Segment attached>
            <Header as="h4">
              <FormattedMessage id="meta.actions.message.previousMessage" />
            </Header>
          </Segment>
          <Segment attached>
            <FeatureToggle featureToggleName="email_display_20">
              <MessageBody message={message} />
            </FeatureToggle>
            <FeatureToggle featureToggleName="email_display_20" disabled>
              <div
                className="text"
                // Needed if we want to display the HTML emails in the timeline
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    (fadeMessage && message.body_text.substr(0, 300)) ||
                      message.body_text
                  )
                }}
              />
              {!disabled && fadeMessage && (
                // eslint-disable-next-line
                <a className="toggleShowMore" onClick={this.handleToggle}>
                  <FormattedMessage
                    id="activity.actions.showMore"
                    defaultMessage="activity.actions.showMore"
                  />
                </a>
              )}
              {!disabled && !fadeMessage && (
                // eslint-disable-next-line
                <a className="toggleShowMore" onClick={this.handleToggle}>
                  <FormattedMessage
                    id="activity.actions.showLess"
                    defaultMessage="activity.actions.showLess"
                  />
                </a>
              )}
            </FeatureToggle>
          </Segment>
        </Container>
      </div>
    );
  }

  renderMessage() {
    const { i18n, date, time, message, isRead } = this.props;
    const { fadeMessage, disabled } = this.state;

    const attachments = (
      <List horizontal className="attachments">
        {(message.attachments || []).map(attachment => (
          <List.Item data-component="attachment" key={attachment.id}>
            <Button
              compact
              basic
              size="small"
              icon="file outline"
              content={attachment.label}
              as="a"
              href={attachment.url}
              target="_document"
            />
          </List.Item>
        ))}
      </List>
    );

    return (
      <div
        data-component="timelineItem"
        data-event={`${message.type}-message`}
        data-id={message.id}
      >
        <div className="bullet-pointer">
          <Icon
            color="grey"
            size="large"
            circular
            name={message.type === "outbound" ? "mail" : "mail forward"}
          />
          {/* eslint-disable-next-line */}
          <a name={message.id} />
        </div>
        <Container data-component="messageEvent">
          <Segment>
            <Label basic attached="bottom" className="eventFooter">
              <div>
                {message.type === "outbound" && (
                  <>
                    <Icon name="eye" />
                    <FormattedMessage
                      id="activity.timeline.event.email"
                      defaultMessage="activity.timeline.event.email"
                      values={{
                        from: get(message, ["sender", "label", "contact"])
                      }}
                    />
                  </>
                )}
              </div>
              <HtmlMessageDialog message={message} />
            </Label>
            <Label basic attached="top">
              <div className="right floated element">
                <time>
                  {!isRead && (
                    <Icon
                      name="mail"
                      size="large"
                      color="blue"
                      className="pulsate"
                    />
                  )}{" "}
                  {date}
                </time>{" "}
                um <time>{time}</time>
                {message.type === "inbound" && (
                  <Button
                    icon="reply"
                    size="tiny"
                    content={i18n["meta.actions.message.reply"]}
                    className="reply"
                    onClick={this.handleReply}
                  />
                )}
              </div>
              <If condition={message.type === "inbound"}>
                <div>{this.renderSender(message)}</div>
              </If>
              <If condition={message.type === "outbound"}>
                <div>{this.renderRecipient(message, "to")}</div>
              </If>
              <div>{this.renderRecipient(message, "cc")}</div>
              <div>{this.renderRecipient(message, "bcc")}</div>
            </Label>
            <div className={`message${fadeMessage ? " fade" : ""}`}>
              <div className="subject">{message.subject}</div>
              <FeatureToggle featureToggleName="email_display_20">
                <MessageBody message={message} />
              </FeatureToggle>
              <FeatureToggle featureToggleName="email_display_20" disabled>
                <div
                  className="text"
                  // Needed if we want to display the HTML emails in the timeline
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      (fadeMessage && message.body_text.substr(0, 300)) ||
                        message.body_text
                    )
                  }}
                />
                {!disabled && fadeMessage && (
                  <a className="toggleShowMore" onClick={this.handleToggle}>
                    <FormattedMessage
                      id="activity.actions.showMore"
                      defaultMessage="activity.actions.showMore"
                    />
                  </a>
                )}
                {!disabled && !fadeMessage && (
                  <a className="toggleShowMore" onClick={this.handleToggle}>
                    <FormattedMessage
                      id="activity.actions.showLess"
                      defaultMessage="activity.actions.showLess"
                    />
                  </a>
                )}
              </FeatureToggle>
              {attachments}
            </div>
          </Segment>
        </Container>
      </div>
    );
  }

  render() {
    const { replyForm } = this.state;
    if (replyForm) {
      return this.renderReplyMessageForm();
    }
    return this.renderMessage();
  }
}

MessageEvent.propTypes = {
  account: PropTypes.object,
  i18n: PropTypes.object,
  date: PropTypes.string,
  time: PropTypes.string,
  user: UserShape.isRequired,
  activity: PropTypes.object,
  buyer: PropTypes.object,
  actions: PropTypes.object,
  contacts: PropTypes.array,
  attachments: PropTypes.object,
  macros: PropTypes.array,
  message: PropTypes.object,
  ctxCtrl: PropTypes.object,
  isRead: PropTypes.bool,
  emailReplyIncludeThread: PropTypes.bool,
  messageDraft: MessageShape,
  handleClose: PropTypes.func.isRequired
};

MessageEvent.defaultProps = {
  emailReplyIncludeThread: false,
  messageDraft: null
};

const mapStateToProps = state => {
  const account = getAccount(state);

  return {
    emailReplyIncludeThread: account?.isEnabled("email_reply_include_thread"),
    user: getUser(state)
  };
};

export default connect(mapStateToProps)(MessageEvent);
