import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Form } from "formik-semantic-ui-react";
import { ProjectStoreysResource } from "builder_portal/actions/projectStoreysActions";
import { getProject } from "shared/selectors";
import * as Yup from "yup";

const initialValues = {
  name: "",
  name_short: ""
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  name_short: Yup.string()
});

const BuyerDialog = ({ model, button, handleProjectStoreyCreate }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const project = useSelector(getProject);

  const refResetForm = useRef();

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    if (!model) clearForm();
  };

  const onSubmit = values => {
    if (!isLoading) {
      setIsLoading(true);
      const projectStoreysResource = new ProjectStoreysResource(
        dispatch,
        project.id
      );

      projectStoreysResource
        .save({ ...values })
        .then(res => {
          if (handleProjectStoreyCreate)
            handleProjectStoreyCreate(res?.data?.project_storey);
          projectStoreysResource.fetchAll().then(() => {
            handleClose();
            setIsLoading(false);
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={model || initialValues}
    >
      {({ handleSubmit, resetForm }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            closeIcon
            closeOnEscape={false}
            closeOnDimmerClick={false}
            trigger={button}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <Modal.Header>
              <FormattedMessage
                id={`projectStoreys.actions.${model?.id ? "edit" : "new"}`}
              />
            </Modal.Header>
            <Modal.Content scrolling size="large">
              <Form id="accountRolesBackendForm">
                <Input
                  fluid
                  placeholder={intl.formatMessage({
                    id: "projectStoreys.dialog.name"
                  })}
                  name="name"
                  label={intl.formatMessage({
                    id: "projectStoreys.dialog.name"
                  })}
                />
                <Input
                  fluid
                  placeholder={intl.formatMessage({
                    id: "projectStoreys.dialog.shortName"
                  })}
                  name="name_short"
                  label={intl.formatMessage({
                    id: "projectStoreys.dialog.shortName"
                  })}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                color="grey"
                content={intl.formatMessage({ id: "meta.actions.cancel" })}
                onClick={handleClose}
              />
              <Button
                onClick={handleSubmit}
                name="commit"
                color="green"
                data-form="projectStoreys"
                loading={isLoading}
                id="projectStoreys-save"
              >
                <FormattedMessage
                  id="meta.actions.save"
                  defaultMessage="meta.actions.save"
                />
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

BuyerDialog.propTypes = {
  button: PropTypes.node.isRequired,
  handleProjectStoreyCreate: PropTypes.func,
  model: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    name_short: PropTypes.string
  })
};

BuyerDialog.defaultProps = {
  handleProjectStoreyCreate: null,
  model: undefined
};

export default BuyerDialog;
