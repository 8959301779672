import React from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import { find } from "lodash";
import Field from "../../../shared/components/forms/FieldComponent";
import { getAccount } from "../../../shared/selectors";
import { AccountResource } from "../../actions/accountActions";
import { ProjectUserShape } from "../../../shared/shapes/projectUser.shape";

class AccountManager extends React.PureComponent {
  componentDidMount() {
    const { accountResource, account } = this.props;

    if (account.isSystemAdmin()) {
      accountResource.get(true);
    }
  }

  getAccountManagerOptions() {
    const { projectUsers, model } = this.props;

    const currentProjectsUsers = find(projectUsers, { id: model?.id })
      ?.project_memberships;

    const accountManagerOptions =
      currentProjectsUsers?.reduce((accountMemberships, accountMembership) => {
        const { user } = accountMembership;
        if (user.group === "admin") {
          accountMemberships.push({
            key: user?.id,
            value: user?.id,
            text: user?.label
          });
        }
        return accountMemberships;
      }, []) || [];

    accountManagerOptions.sort((a, b) => {
      const nameA = a.text.toLowerCase();
      const nameB = b.text.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return accountManagerOptions;
  }

  render() {
    const { account, form } = this.props;

    form.fields.customer_success_manager_id.props = {
      ...form.fields.customer_success_manager_id.props,
      options: this.getAccountManagerOptions(),
      clearable: true
    };

    return (
      account.isSystemAdmin() && (
        <>
          <Form.Field className="margin top medium">
            <Field
              component="Select"
              {...form.fields.customer_success_manager_id}
            />
          </Form.Field>
        </>
      )
    );
  }
}

const mapStateToProps = state => ({
  projectUsers: state.pageContent?.project_users,
  account: getAccount(state)
});

const mapDispatchToProps = dispatch => {
  return {
    accountResource: new AccountResource(dispatch)
  };
};
AccountManager.defaultProps = {
  projectUsers: [],
  accountResource: null,
  account: null
};

AccountManager.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  form: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  account: PropTypes.object,
  accountResource: PropTypes.instanceOf(AccountResource),
  projectUsers: PropTypes.arrayOf(ProjectUserShape)
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountManager);
