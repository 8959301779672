import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button, Form, Radio, Grid, Header } from "semantic-ui-react";
import { get, delay } from "lodash";
import { scrollTo } from "../../../shared/helpers/scrollTo";

class CancellationWizard extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    toggleDialog: PropTypes.func,
    lineItem: PropTypes.object,
    ctrl: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      cancellationMode: "cancellation",
      contractorMode: "inform",
      loading: false,
      scrollDialog: null
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectOptionFn = this.handleSelectOptionFn.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.scrollToLineItem = this.scrollToLineItem.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleSelectOptionFn(field) {
    return (e, { value }) => {
      this.setState({ [field]: value });
    };
  }

  render() {
    const { i18n } = this.props;
    return (
      <div>
        <Modal
          id="confirmation"
          size="small"
          closeOnEscape
          closeOnDimmerClick={false}
          closeIcon
          open={this.state.open}
          onOpen={() => this.setState({ open: true })}
          onClose={this.handleClose}
          data-component="cancellationWizard"
        >
          <Modal.Header>
            <FormattedMessage
              id="roomBook.lineItems.actions.cancellationDialog.title"
              defaultValue="roomBook.lineItems.actions.cancellationDialog.title"
            />
          </Modal.Header>
          <Modal.Content>
            <p>
              <FormattedMessage
                id="roomBook.lineItems.actions.cancellationDialog.message01"
                defaultValue="roomBook.lineItems.actions.cancellationDialog.message01"
              />
            </p>
            <div>{this.renderModesSelection()}</div>
          </Modal.Content>
          <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
        </Modal>
        <Modal
          id="scrollTo"
          size="mini"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeIcon
          open={!!this.state.scrollDialog}
          onClose={this.handleClose}
        >
          <Modal.Content>
            <p>
              <FormattedMessage
                id="roomBook.lineItems.actions.cancellationDialog.gotoPosition.hint"
                defaultValue="roomBook.lineItems.actions.cancellationDialog.gotoPosition.hint"
              />
            </p>
            <Button
              fluid
              icon="angle double down"
              content={
                i18n[
                  "roomBook.lineItems.actions.cancellationDialog.gotoPosition.label"
                ]
              }
              onClick={this.scrollToLineItem}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  renderModesSelection() {
    return (
      <Form>
        {this.renderCancellationModes()}
        {this.renderContractorModes()}
      </Form>
    );
  }

  renderContractorModes = () => {
    const { i18n } = this.props;

    const contractorModes = ["inform", "skip"].map(k => {
      return {
        id: k,
        title:
          i18n[
            `roomBook.lineItems.attributes.cancellation_contactor_mode.${k}.title`
          ],
        hint:
          i18n[
            `roomBook.lineItems.attributes.cancellation_contactor_mode.${k}.hint`
          ]
      };
    });

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <strong>
              <FormattedMessage
                id="roomBook.lineItems.actions.cancellationDialog.message03"
                defaultValue="roomBook.lineItems.actions.cancellationDialog.message03"
              />
            </strong>
          </Grid.Column>
        </Grid.Row>
        {this.renderRadioGroup("contractorMode", contractorModes)}
      </Grid>
    );
  };

  renderCancellationModes = () => {
    const { i18n } = this.props;
    const { lineItem } = this.props;

    if (!lineItem.isOptional()) {
      const cancellationModes = ["cancellation", "replacement"].map(k => {
        return {
          id: k,
          title:
            i18n[`roomBook.lineItems.attributes.cancellation_mode.${k}.title`],
          hint:
            i18n[`roomBook.lineItems.attributes.cancellation_mode.${k}.hint`]
        };
      });

      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <strong>
                <FormattedMessage
                  id="roomBook.lineItems.actions.cancellationDialog.message02"
                  defaultValue="roomBook.lineItems.actions.cancellationDialog.message02"
                />
              </strong>
            </Grid.Column>
          </Grid.Row>
          {this.renderRadioGroup("cancellationMode", cancellationModes)}
        </Grid>
      );
    }
  };

  renderRadioGroup(field, options) {
    return options.map(option => {
      return (
        <Grid.Row key={option.id}>
          <Grid.Column width={1} verticalAlign="middle" textAlign="center">
            <Form.Field>
              <Radio
                name={field}
                value={option.id}
                checked={this.state[field] === option.id}
                onChange={this.handleSelectOptionFn(field)}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={15} verticalAlign="middle">
            <Header as="h4">
              {option.title}
              <Header.Subheader>{option.hint}</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      );
    });
  }

  renderActionButtons() {
    const { i18n } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Button
          id="cancel"
          color="grey"
          basic
          onClick={this.handleClose}
          loading={loading}
          disabled={loading}
          content={i18n["meta.actions.cancel"]}
        />
        <Button
          id="cancelOrder"
          color="red"
          onClick={this.handleCancelOrder}
          loading={loading}
          disabled={loading}
          content={i18n["meta.actions.proceed"]}
        />
      </div>
    );
  }

  handleClose() {
    const { toggleDialog } = this.props;
    this.setState({ loading: false, scrollDialog: null }, () => {
      toggleDialog("cancellationWizard", null);
    });
  }

  handleCancelOrder() {
    const { ctrl, lineItem } = this.props;
    const { cancellationMode, contractorMode } = this.state;
    const options = {
      cancellation_mode: cancellationMode,
      contractor_mode: contractorMode
    };
    this.setState({ loading: true }, () => {
      ctrl.cancelLineItem({ id: lineItem.getId() }, options).then(response => {
        const id = response.data.events
          .filter(event => {
            return event.type === "line_item_created";
          })
          .map(event => event.payload.id)[0];
        this.setState({ scrollDialog: { id } });
      });
    });
  }

  scrollToLineItem() {
    const id = get(this.state, "scrollDialog.id");
    delay(() => {
      this.handleClose();
      scrollTo(`[data-model="line_item"][data-id="${id}"]`);
    }, 100);
  }
}

export default CancellationWizard;
