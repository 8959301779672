import Resources from "../../shared/actions/resourcesActions";

export default class SectionsResource extends Resources {
  constructor(dispatch, projectId) {
    if (!projectId) {
      throw new Error("undefined projectId");
    }
    super(dispatch, "section", `/api/v1/projects/${projectId}/sections`);
  }
}
