import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { get, countBy, uniqBy } from "lodash";
import { Message, Button, Icon, Grid } from "semantic-ui-react";
import { FormattedMessage, FormattedPlural } from "react-intl";

import NotificationsResource, {
  AlertsResource
} from "builder_portal/actions/notificationActions";

import "./notificationMessage.scss";

class NotificationMessage extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    notifications: PropTypes.array,
    handleChange: PropTypes.func,
    notificationsResource: PropTypes.object,
    alertsResource: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    const {
      handleChange,
      notifications,
      alertsResource,
      notificationsResource
    } = this.props;
    const payload = notifications.map(item => {
      return { id: item.id };
    });
    notificationsResource.batchUpdate(payload).then(() => {
      alertsResource.refresh().then(() => {
        handleChange();
      });
    });
  }

  render() {
    const { notifications, i18n } = this.props;
    if (notifications.length === 0) {
      return null;
    }

    // only count assignments once!
    const count = uniqBy(notifications, n => {
      if (n.notification_type === "assignment") {
        return "assignment";
      }
      if (n.notification_type === "status_changed") {
        return "status_changed";
      }
      return n.id;
    }).length;

    return (
      <Message icon color="blue" data-component="notificationMessage">
        <Icon name="inbox" />
        <Message.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={13}>
                <Message.Header>
                  <FormattedPlural
                    value={count}
                    one={
                      <FormattedMessage
                        id="notification.newMessages.one"
                        defaultMessage="notification.newMessages.one"
                      />
                    }
                    other={
                      <FormattedMessage
                        id="notification.newMessages.other"
                        defaultMessage="notification.newMessages.other"
                        values={{ amount: count }}
                      />
                    }
                    defaultMessage={
                      <FormattedMessage
                        id="notification.newMessages.one"
                        defaultMessage="notification.newMessages.one"
                      />
                    }
                    values={{ amount: count }}
                  />
                </Message.Header>
                <Message.List>
                  {notifications && this.renderChildren()}
                </Message.List>
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                <Button
                  content={i18n["notification.actions.dismiss"]}
                  onClick={this.handleDismiss}
                  color="blue"
                  basic
                  icon="check"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Message.Content>
      </Message>
    );
  }

  renderChildren() {
    return this.renderMessages()
      .concat(
        this.renderTypedMessage("assignment", "notification.assignment.label")
      )
      .concat(
        this.renderTypedMessage(
          "status_changed",
          "notification.statusChanged",
          true
        )
      )
      .concat(
        this.renderTypedMessage(
          "memo_created",
          "notification.memoCreated",
          true
        )
      )
      .concat(
        this.renderTypedMessage(
          "message_outbound_created",
          "notification.messageOutboundCreated",
          true
        )
      );
  }

  renderMessages() {
    const { notifications, i18n } = this.props;

    // group notifications by activity
    const messageCountByContact = countBy(
      notifications
        .filter(notification => {
          return notification.notification_type === "inbound_message";
        })
        .map(notification => {
          return get(notification, "payload.sender.label.contact");
        })
    );

    return Object.keys(messageCountByContact).map((contact, index) => {
      return (
        <Message.Item key={`message-item-${index}`}>
          {messageCountByContact[contact] > 1
            ? messageCountByContact[contact]
            : ""}{" "}
          <FormattedPlural
            value={messageCountByContact[contact]}
            zero={i18n["notification.amountMessages.zero"]}
            one={i18n["notification.amountMessages.one"]}
            other={i18n["notification.amountMessages.other"]}
            defaultMessage="notification.amountMessages.one"
          />{" "}
          {contact}
        </Message.Item>
      );
    });
  }

  renderTypedMessage(type, label, pluralizable = false) {
    const { notifications } = this.props;
    const items = [];
    const count = notifications.filter(n => n.notification_type === type)
      .length;

    if (count > 0) {
      if (pluralizable) {
        items.push(
          <Message.Item key={`message-item-${type}`}>
            <FormattedPlural
              value={count}
              values={{ count }}
              zero={<FormattedMessage id={`${label}.zero`} />}
              one={<FormattedMessage id={`${label}.one`} />}
              other={
                <FormattedMessage id={`${label}.other`} values={{ count }} />
              }
              defaultMessage={<FormattedMessage id={`${label}.zero`} />}
            />
          </Message.Item>
        );
      } else {
        items.push(
          <Message.Item key={`message-item-${type}`}>
            <FormattedMessage
              id={label}
              defaultMessage={label}
              values={{ count }}
            />
          </Message.Item>
        );
      }
    }

    return items;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    alertsResource: new AlertsResource(dispatch),
    notificationsResource: new NotificationsResource(dispatch)
  };
};

export default connect(null, mapDispatchToProps)(NotificationMessage);
