import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Loader, Checkbox, Grid, Header, Segment } from "semantic-ui-react";
import { getProjects } from "shared/selectors";
import { find, keyBy } from "lodash";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { arrayOf, shape, string, object, number } from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from "query-string";
import { locationShape } from "react-router";
import { withRouter } from "shared/helpers/withRouter";
import { browserHistory } from "shared/routes/browserHistory";
import {
  FeatureFlagsAccountResource,
  FeatureFlagsProjectsResource
} from "builder_portal/actions/featureFlagsActions";
import {
  getFeatureGroups,
  getFeaturesAccount
} from "shared/selectors/featureGroups";
import { If } from "shared/components/elements/Conditions";

import StickyMenu from "shared/components/menus/StickyMenu";
import IsBetaPreview from "shared/components/elements/IsBetaPreview";

const getFeatureEntity = (projectOrAccount, isAccount) => {
  const { id, features, slug } = projectOrAccount;
  return {
    id,
    features,
    slug,
    isAccount,
    save: false
  };
};

const FeatureToggles = ({ projects, featureGroups, account, location }) => {
  const queryParams = queryString.parse(location?.search);
  const projectId = Number(queryParams?.projectId);

  const [isLoading, setLoading] = useState(false);
  const [currentFeatureEntity, setCurrentFeatureEntity] = useState();
  const [featureToggles, setFeatureToggles] = useState({});
  const dispatch = useDispatch();

  const loadData = () => {
    setLoading(true);
    Promise.all([
      new ProjectsResource(dispatch).fetchAll(),
      new FeatureFlagsAccountResource(dispatch).fetchAll()
    ]).then(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (account?.id && !currentFeatureEntity)
      browserHistory.push(`/account/feature_toggles`);
    if (projectId) {
      browserHistory.push(`/account/feature_toggles?projectId=${projectId}`);
    }
  }, [account, projects]);

  const onSelectedItemChange = (item, isSticky) => {
    if (isSticky) {
      browserHistory.push(`/account/feature_toggles`);
    } else {
      browserHistory.push(`/account/feature_toggles?projectId=${item.id}`);
    }
  };

  useEffect(() => {
    if (projectId) {
      const project = find(projects, { id: projectId });
      if (project) {
        setCurrentFeatureEntity(getFeatureEntity(project, false));
      } else if (Object.keys(projects).length)
        window.location.href = "/account/feature_toggles";
    } else {
      setCurrentFeatureEntity(getFeatureEntity(account, true));
    }
  }, [projectId, projects, account]);

  useEffect(() => {
    if (!currentFeatureEntity) return;
    setFeatureToggles(currentFeatureEntity.features);
  }, [currentFeatureEntity]);

  const clearStorage = vals => {
    // if selected feature flag is disabled, remove the storage key
    const keys = {
      show_upgrade_bundles: {
        key: "RoombookTemplatesSubTab",
        value: "UpgradeBundles"
      },
      show_unit_variables: {
        key: "RoombookTemplatesSubTab",
        value: "UnitVariables"
      },
      show_unit_feature_groups: {
        key: "RoombookTemplatesSubTab",
        value: "UnitFeatureGroups"
      }
    };
    const storage = keys[vals.name];
    if (storage?.key) {
      const currentValue = localStorage.getItem(storage.key);
      if (currentValue === storage?.value) localStorage.removeItem(storage.key);
    }
  };

  const handleChange = (_, vals) => {
    if (!vals.checked) clearStorage(vals);
    setFeatureToggles({
      ...featureToggles,
      [vals.name]: vals.checked,
      save: true
    });
  };

  const save = () => {
    const { isAccount, id } = currentFeatureEntity;
    setLoading(true);
    const resource = isAccount
      ? new FeatureFlagsAccountResource(dispatch)
      : new FeatureFlagsProjectsResource(dispatch, id);
    resource
      .save({
        id: currentFeatureEntity.id,
        features: featureToggles
      })
      .then(() => {
        loadData();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentFeatureEntity && featureToggles?.save) save();
  }, [featureToggles]);

  const renderContent = useCallback(() => {
    if (!featureGroups || !featureToggles) return [];
    return featureGroups.map(group => {
      const toggles = group.toggles.map(toggle => {
        const value = featureToggles[toggle.id];
        const onlyForBetaUsers = toggle.only_for_beta_users;
        return (
          <Grid key={toggle.id}>
            <IsBetaPreview ignore={!onlyForBetaUsers}>
              <Grid.Row style={{ margin: "10px 0", padding: 0 }}>
                <Grid.Column width={7}>
                  <Header as="h5">{toggle.name}</Header>
                  <div>{toggle.description}</div>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                  <Checkbox
                    toggle
                    name={toggle.id}
                    checked={value}
                    onClick={handleChange}
                    disabled={isLoading}
                  />
                </Grid.Column>
              </Grid.Row>
            </IsBetaPreview>
          </Grid>
        );
      });
      return (
        <Segment key={group.title}>
          <Header>{group.title}</Header>
          {toggles}
        </Segment>
      );
    });
  }, [featureGroups, featureToggles, isLoading]);

  return (
    <>
      <If condition={!!currentFeatureEntity} styles={{ display: "block" }}>
        <StickyMenu
          stickyItem={account}
          allItems={projects}
          selectedItem={projectId ? currentFeatureEntity : undefined}
          accountId={currentFeatureEntity?.id || account.id}
          onSelectedItemChange={onSelectedItemChange}
          storeKey="recentFeatureTogglesProjects"
          featureToggleName="use_project_feature_flags"
        />
        {renderContent()}
      </If>

      <If condition={!currentFeatureEntity}>
        <Loader active />
      </If>
    </>
  );
};

FeatureToggles.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  projects: object,
  featureGroups: arrayOf(
    shape({
      title: string,
      toggles: arrayOf(
        shape({
          id: string,
          name: string,
          description: string
        })
      )
    })
  ),
  account: shape({
    id: number,
    slug: string,
    name: string,
    // eslint-disable-next-line react/forbid-prop-types
    features: object
  }).isRequired,
  location: locationShape.isRequired
};

FeatureToggles.defaultProps = {
  projects: {},
  featureGroups: []
};

const mapStateToProps = state => ({
  projects: keyBy(getProjects(state), "id"),
  featureGroups: getFeatureGroups(state),
  account: getFeaturesAccount(state)
});

export default withRouter(connect(mapStateToProps)(FeatureToggles));
