/* eslint-disable max-classes-per-file */
/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { connect } from "react-redux";
import getAccountRoles from "shared/selectors/accountRoles";
import { getSalutations, getTitles } from "../../../../shared/selectors";

function findOperatorId(accountRolesData) {
  return (
    accountRolesData.find(role => role.system_reference_role === "operator")
      ?.id || null
  );
}

class EmailStep {
  constructor(wizard) {
    this.wizard = wizard;
    this.action = {
      label: "meta.actions.next"
    };
  }

  form(model, onChange) {
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "email",
          label: "user.attributes.email.label",
          rule: "isEmail"
        }
      ]
    });

    return FormFactory.create(model, this.wizard.props.i18n, { onChange });
  }

  render(form, callback) {
    return (
      <Form
        id="email"
        data-component="email"
        onSubmit={form.handleSubmit(callback)}
      >
        <Form.Field width="16">
          <Field component="Input" {...form.fields.email} />
        </Form.Field>
      </Form>
    );
  }

  onSubmit(values) {
    this.wizard.props.actions.users.emailCheck(values.email).then(response => {
      switch (response.status) {
        case "unknown":
          this.wizard.transitionTo(CreateStep, values);
          break;
        case "present":
          if (response.account_member) {
            this.wizard.transitionTo(AlreadyMemberStep, values);
            break;
          } else {
            this.wizard.transitionTo(InviteMemberStep, values);
            break;
          }
      }
    });
  }
}

class CreateStep {
  constructor(wizard) {
    this.wizard = wizard;
    this.action = {
      label: "user.actions.invite.label"
    };
  }

  form(model, onChange) {
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "email",
          label: "user.attributes.email.label",
          rule: "isEmail"
        },
        {
          id: "first_name",
          label: "user.attributes.first_name.label",
          rule: "isRequired"
        },
        {
          id: "last_name",
          label: "user.attributes.last_name.label",
          rule: "isRequired"
        },
        {
          id: "salutation",
          label: "meta.form_of_address.salutation.label",
          autoComplete: "off"
        },
        {
          id: "title",
          label: "meta.form_of_address.title.label",
          autoComplete: "off"
        },
        {
          id: "account_role_id",
          label: "user.attributes.role.label",
          rule: "isRequired"
        }
      ]
    });

    return FormFactory.create(model, this.wizard.props.i18n, { onChange });
  }

  render(form, callback) {
    form.fields.email.props.readOnly = true;
    form.fields.account_role_id.props.options = this.wizard.props.accountRoles;

    return (
      <Form
        id="user"
        data-component="user"
        onSubmit={form.handleSubmit(callback)}
      >
        <Form.Field width="16">
          <Field component="Input" {...form.fields.email} />
        </Form.Field>
        <Form.Field width="16">
          <Field
            component="Select"
            {...form.fields.salutation}
            props={{
              ...form.fields.salutation.props,
              clearable: true,
              placeholder: this.wizard.props.i18n[
                "meta.form_of_address.salutation.nil"
              ],
              options: getSalutations.map(key => {
                return {
                  key,
                  value: key,
                  text: this.wizard.props.i18n[
                    `meta.form_of_address.salutation.${key || "nil"}`
                  ]
                };
              })
            }}
          />
        </Form.Field>
        <Form.Field width="16">
          <Field
            component="Select"
            {...form.fields.title}
            props={{
              ...form.fields.title.props,
              clearable: true,
              placeholder: this.wizard.props.i18n[
                "meta.form_of_address.title.nil"
              ],
              options: getTitles.map(key => {
                return {
                  key,
                  value: key,
                  text: this.wizard.props.i18n[
                    `meta.form_of_address.title.${key || "nil"}`
                  ]
                };
              })
            }}
          />
        </Form.Field>
        <Form.Field width="16">
          <Field component="Input" {...form.fields.first_name} />
        </Form.Field>
        <Form.Field width="16">
          <Field component="Input" {...form.fields.last_name} />
        </Form.Field>
        <Form.Field width="16">
          <Field component="Select" {...form.fields.account_role_id} />
        </Form.Field>
      </Form>
    );
  }

  onSubmit(values) {
    const { actions } = this.wizard.props;
    actions.users.save(values).then(() => {
      actions.account.get(true).then(() => {
        this.wizard.onClose();
      });
    });
  }
}

class AlreadyMemberStep {
  constructor(wizard) {
    this.wizard = wizard;
    this.action = {
      label: "meta.actions.close"
    };
  }

  form(model, onChange) {
    const FormFactory = new FormDefinition({ fields: [] });
    return FormFactory.create(model, this.wizard.props.i18n, { onChange });
  }

  render() {
    const data = this.wizard.state.user;
    return (
      <div>
        <p>
          <FormattedMessage
            id="user.messages.alreadyInvited"
            defaultMessage="user.messages.alreadyInvited"
          />
        </p>
        <p>
          <FormattedMessage
            id="user.attributes.email.label"
            defaultMessage="user.attributes.email.label"
          />
          : <b>{data.email}</b>
        </p>
      </div>
    );
  }

  onSubmit() {
    this.wizard.onClose();
  }
}

class InviteMemberStep {
  constructor(wizard) {
    this.wizard = wizard;
    this.action = {
      label: "user.actions.invite.label"
    };
  }

  form(model, onChange) {
    const FormFactory = new FormDefinition({
      fields: [
        {
          id: "email",
          label: "user.attributes.email.label",
          rule: "isEmail"
        },
        {
          id: "account_role_id",
          label: "user.attributes.role.label",
          rule: "isRequired"
        }
      ]
    });
    const form = FormFactory.create(model, this.wizard.props.i18n, {
      onChange
    });

    return form;
  }

  render(form, callback) {
    form.fields.email.props.readOnly = true;
    form.fields.account_role_id.props.options = this.wizard.props.accountRoles;

    return (
      <div>
        <Message color="yellow">
          <p>
            <FormattedMessage
              id="user.messages.alreadyExists"
              defaultMessage="user.messages.alreadyExists"
            />
          </p>
        </Message>
        <Form
          id="user"
          data-component="user"
          onSubmit={form.handleSubmit(callback)}
        >
          <Form.Field width="16">
            <Field component="Input" {...form.fields.email} />
          </Form.Field>
          <Form.Field width="16">
            <Field component="Select" {...form.fields.account_role_id} />
          </Form.Field>
        </Form>
      </div>
    );
  }

  onSubmit(values) {
    const { actions } = this.wizard.props;
    actions.users.invite(values).then(() => {
      actions.account.get(true).then(() => {
        this.wizard.onClose();
      });
    });
  }
}

class UserWizard extends Component {
  static propTypes = {
    button: PropTypes.object,
    i18n: PropTypes.object,
    actions: PropTypes.object,
    handleCreated: PropTypes.func,
    allAccountRolesData: PropTypes.arrayOf(
      PropTypes.shape({
        allow_edit_contractors: PropTypes.bool.isRequired
      }).isRequired
    )
  };

  static defaultState() {
    return {
      open: false,
      user: {
        email: "",
        account_role_id: "",
        first_name: "",
        last_name: ""
      },
      step: EmailStep
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      ...UserWizard.defaultState(),
      user: {
        ...UserWizard.defaultState().user,
        account_role_id: findOperatorId(props.allAccountRolesData)
      }
    };
  }

  onClose() {
    this.setState({
      ...UserWizard.defaultState(),
      user: {
        ...UserWizard.defaultState().user,
        // eslint-disable-next-line react/destructuring-assignment
        account_role_id: findOperatorId(this.props.allAccountRolesData)
      }
    });
  }

  transitionTo(step, values) {
    this.setState({ ...this.state, step, user: values });
  }

  render() {
    const { button, i18n } = this.props;
    const step = new this.state.step(this);

    const form = step.form(this.state.user, values => {
      this.setState({
        ...this.state,
        user: Object.assign(this.state.user, values)
      });
    });

    return (
      <Modal
        size="small"
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        trigger={button}
        open={this.state.open}
        onOpen={() => this.setState({ ...this.state, open: true })}
        onClose={this.onClose.bind(this)}
      >
        <Modal.Header>Neuer Benutzer</Modal.Header>
        <Modal.Content>
          {step.render(form, step.onSubmit.bind(step))}
        </Modal.Content>
        <Modal.Actions>
          <Button
            id="proceed"
            positive
            content={i18n[step.action.label]}
            onClick={form.handleSubmit(step.onSubmit.bind(step))}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  allAccountRolesData: getAccountRoles(state)
});

export default connect(mapStateToProps)(UserWizard);
