import React, { useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Grid, Icon, Header, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProjectStoreysResource } from "builder_portal/actions/projectStoreysActions";
import { getProjectStoreysOrdered } from "builder_portal/selectors/projectStoreys";
import { getProject } from "shared/selectors";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import PropTypes from "prop-types";
import CreateStoreyDialog from "builder_portal/components/unit/CreateStoreyDialog";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";

const ProjectStoreysDialog = ({ trigger }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const projectStoreys = useSelector(getProjectStoreysOrdered);
  const project = useSelector(getProject);

  const resource = useMemo(
    () => new ProjectStoreysResource(dispatch, project.id),
    [project]
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleUp = useCallback(
    m => {
      return resource
        .save({ ...m, position: m.position - 1 })
        .then(() => resource.fetchAll());
    },
    [resource]
  );

  const handleDown = useCallback(
    m => {
      return resource
        .save({ ...m, position: m.position + 1 })
        .then(() => resource.fetchAll());
    },
    [resource]
  );

  const onDelete = id => {
    resource
      .remove(id)
      .then(() => {
        new Growl(dispatch).success(
          "message.success.title",
          "message.success.body"
        );
        resource.fetchAll();
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "meta.confirmations.changesNotSaved"
        );
      });
  };

  const renderDeleteButton = id => {
    const button = <Icon name="trash" link />;

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: () => onDelete(id)
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="projectStoreys.confirmation.title"
        message="projectStoreys.confirmation.body"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Modal
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      trigger={trigger}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        <FormattedMessage id="projectStoreys.dialog.titlePlural" />
      </Modal.Header>
      <Modal.Content scrolling size="large">
        {projectStoreys.map((projectStorey, idx) => (
          <Segment key={projectStorey.id} clearing data-model="project-storey">
            <Grid>
              <Grid.Column width={11}>
                <Header as="h4" data-attr="name">
                  {projectStorey.name}
                </Header>
              </Grid.Column>

              <Grid.Column width={1} textAlign="right">
                <IsVersionHistoryAccessible>
                  <VersionHistoryLink
                    id={projectStorey.id}
                    type="ProjectStorey"
                    size="mid"
                  />
                </IsVersionHistoryAccessible>
              </Grid.Column>
              <Grid.Column width={1} textAlign="right">
                <If condition={idx < projectStoreys.length - 1}>
                  <Icon
                    name="caret down"
                    link
                    onClick={() => handleDown(projectStorey)}
                  />
                </If>
              </Grid.Column>
              <Grid.Column width={1} textAlign="right">
                <If condition={idx > 0}>
                  <Icon
                    name="caret up"
                    link
                    onClick={() => handleUp(projectStorey)}
                  />
                </If>
              </Grid.Column>
              <Grid.Column width={1} textAlign="right">
                {renderDeleteButton(projectStorey.id)}
              </Grid.Column>
              <Grid.Column width={1} textAlign="right">
                <CreateStoreyDialog
                  model={projectStorey}
                  button={<Icon name="edit" link />}
                />
              </Grid.Column>
            </Grid>
          </Segment>
        ))}
        <If condition={projectStoreys.length === 0} block>
          <Header as="h4" style={{ margin: "10px" }}>
            <FormattedMessage id="projectStoreys.emptyList" />
          </Header>
        </If>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="grey"
          content={intl.formatMessage({ id: "meta.actions.close" })}
          onClick={handleClose}
        />
        <CreateStoreyDialog
          button={
            <Button primary>
              <FormattedMessage id="projectStoreys.actions.new" />
            </Button>
          }
        />
      </Modal.Actions>
    </Modal>
  );
};

ProjectStoreysDialog.propTypes = {
  trigger: PropTypes.node.isRequired
};

export default ProjectStoreysDialog;
