import { arrayOf, bool, number, shape, string } from "prop-types";
import ProductPriceShape from "shared/shapes/productPrice.shape";
import { ProductLineShape } from "./productLine.shape";

const ProductOptionShape = shape({
  id: number,
  product_id: number,
  product_group_id: number,
  position: number,
  portal_offer: bool,
  on_request: bool,
  product_tags: arrayOf(string),
  created_at: string,
  updated_at: string,
  is_default: bool,
  product_prices: arrayOf(ProductPriceShape),
  productLines: arrayOf(ProductLineShape),
  external_id: string
});
export default ProductOptionShape;
