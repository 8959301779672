import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeletableTypes, getUsers } from "shared/selectors/account";
import {
  Menu,
  Button,
  Table,
  Pagination,
  Grid,
  Dropdown,
  Header,
  Segment,
  Loader
} from "semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import moment from "moment";
import { getProjects } from "shared/selectors";
import { keyBy } from "lodash";
import { If } from "shared/components/elements/Conditions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import {
  RestorationResource,
  AccountUsersResource
} from "../../actions/accountActions";

const MAX_DELETED = 20;
const TIME_RANGE_OPTIONS = [
  { key: 0, value: 0, text: "today" },
  { key: 1, value: 1, text: "1" },
  { key: 5, value: 5, text: "5" },
  { key: 15, value: 15, text: "15" },
  { key: 30, value: 30, text: "30" },
  { key: 90, value: 90, text: "90" }
];

const useTimeRangeOptions = () => {
  const intl = useIntl();

  return TIME_RANGE_OPTIONS.map(option => {
    if (option.value === 0) {
      return {
        ...option,
        text: intl.formatMessage({ id: "meta.date.formatSince.today" })
      };
    }
    if (option.value === 1) {
      return {
        ...option,
        text: intl.formatMessage({ id: "meta.date.formatSince.yesterday" })
      };
    }
    return {
      ...option,
      text: intl.formatMessage({ id: "meta.date.formatSince.days" }, option)
    };
  });
};

const TrashBin = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const types = useSelector(getDeletableTypes);
  const projectsList = useSelector(getProjects);
  const userList = useSelector(getUsers);
  const timeRangeOptions = useTimeRangeOptions();
  const [isLoading, setLoading] = useState(false);
  const [isRestoring, setRestoringLoading] = useState(false);
  const [currentType, setCurrentType] = useState();
  const [deletedItems, setDeletedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [numDays, setNumDays] = useState(5);
  const [total, setTotal] = useState(0);

  const projects = useMemo(() => {
    return keyBy(projectsList, "id");
  }, [projectsList]);

  const users = useMemo(() => {
    return keyBy(userList, "id");
  }, [userList]);

  const loadData = useCallback(() => {
    setLoading(true);
    Promise.all([
      new ProjectsResource(dispatch).fetchAll(),
      new RestorationResource(dispatch).fetchAll(),
      new AccountUsersResource(dispatch).fetchAll()
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const getParams = () => {
    const endDate = new Date();
    const startDate = new Date();
    if (numDays === 0) {
      startDate.setHours(0, 0, 0, 0);
    } else {
      startDate.setDate(endDate.getDate() - numDays);
    }
    return {
      page,
      limit: MAX_DELETED,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };
  };

  const loadDeletedItems = useCallback(() => {
    if (!currentType) return;
    setLoading(true);
    new RestorationResource(dispatch)
      .getDeletedItems(currentType, getParams())
      .then(res => {
        const items = res.data?.deleted_items?.items || [];
        setDeletedItems(items);
        const totalItems = res.data?.deleted_items?.total || 0;
        setTotal(Math.ceil(totalItems / MAX_DELETED));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [currentType, page, numDays]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (types?.[0]) setCurrentType(types[0]);
  }, [types]);

  useEffect(() => {
    setPage(1);
    loadDeletedItems();
  }, [currentType]);

  useEffect(() => {
    loadDeletedItems();
  }, [page, numDays]);

  const handleRestore = (type, id, closeConfirmation) => {
    setRestoringLoading(true);
    new RestorationResource(dispatch)
      .restoreItem(type, id)
      .then(res => {
        setRestoringLoading(false);
        new Growl(dispatch).success(
          "trashBin.messages.success.title",
          "trashBin.messages.success.body",
          {
            bodyValues: {
              message: res.data?.message
            }
          }
        );
        loadDeletedItems();
        closeConfirmation();
      })
      .catch(err => {
        setRestoringLoading(false);
        new Growl(dispatch).error(
          "trashBin.messages.error.title",
          "trashBin.messages.error.body",
          {
            bodyValues: {
              error: err.data?.message?.match(/--.*--/)?.[0]?.replace(/--/g, "")
            }
          }
        );
        closeConfirmation();
      });
  };

  const renderRestoreButton = (type, id) => {
    const button = (
      <Button disabled={isRestoring}>
        <FormattedMessage id="meta.actions.restore" />
      </Button>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.restore",
        color: "red",
        onClick: closeConfirmation => {
          handleRestore(type, id, closeConfirmation);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title={intl.formatMessage(
          { id: "trashBin.confirmationDialog.title" },
          { type }
        )}
        message={intl.formatMessage(
          { id: "trashBin.confirmationDialog.message" },
          { type, id }
        )}
        buttons={buttons}
        button={button}
      />
    );
  };

  const handlePageChange = (_, { activePage }) => {
    setPage(activePage);
  };

  const handleTimeRangeChange = (_, { value }) => {
    setNumDays(value);
  };

  const renderTable = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <If condition={!!deletedItems[0]?.project_id}>
              <Table.HeaderCell>
                <FormattedMessage id="trashBin.table.project" />
              </Table.HeaderCell>
            </If>
            <Table.HeaderCell>
              <FormattedMessage id="trashBin.table.title" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="trashBin.table.versionId" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="trashBin.table.deletedBy" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="trashBin.table.deletedAt" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="trashBin.table.action" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <If condition={isLoading}>
          <Loader active />
        </If>
        <If condition={!isLoading} styles={{ width: "100%" }}>
          <Table.Body>
            {deletedItems.map(item => {
              return (
                <Table.Row key={item.item_id}>
                  <Table.Cell>{item.item_id}</Table.Cell>
                  <If condition={!!item.project_id}>
                    <Table.Cell>{projects[item.project_id]?.name}</Table.Cell>
                  </If>
                  <Table.Cell>{item.title || "-"}</Table.Cell>
                  <Table.Cell>{item.version_id}</Table.Cell>
                  <Table.Cell>
                    {users[item.whodunnit]?.email || item.whodunnit}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format("LLL")}
                  </Table.Cell>
                  <Table.Cell>
                    <IsVersionHistoryAccessible>
                      <VersionHistoryLink
                        id={item.item_id}
                        type={currentType}
                      />
                    </IsVersionHistoryAccessible>
                    {renderRestoreButton(currentType, item.item_id)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </If>
        <If condition={!isLoading && total === 0}>
          <Header as="h5" style={{ margin: "20px" }}>
            <FormattedMessage id="trashBin.messages.empty" />
          </Header>
        </If>
      </Table>
    );
  };

  return (
    <div>
      <Header as="h2">
        <FormattedMessage id="trashBin.title" defaultMessage="trashBin.title" />
      </Header>
      <FormattedMessage id="trashBin.subtitle" />
      <Segment>
        <FormattedMessage id="trashBin.filter" />
        <Dropdown
          inline
          defaultValue={numDays}
          onChange={handleTimeRangeChange}
          options={timeRangeOptions}
          style={{ padding: "0px 5px" }}
        />
      </Segment>

      <Grid>
        <Grid.Column width="3">
          <Menu
            pointing
            vertical
            style={{ width: "100%" }}
            data-component="trashbin-menu"
          >
            {types.map(type => (
              <Menu.Item
                key={type}
                active={type === currentType}
                onClick={() => setCurrentType(type)}
              >
                <FormattedMessage
                  id={`trashBin.tabs.${type.toLowerCase()}`}
                  defaultMessage={type}
                />
              </Menu.Item>
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width="13">
          {renderTable()}
          <Grid>
            <Grid.Column textAlign="center">
              <Pagination
                disabled={total < 2}
                onPageChange={handlePageChange}
                activePage={page || 1}
                totalPages={total}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default TrashBin;
