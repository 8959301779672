import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import { Form, Grid, Radio, Header } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { cloneDeep } from "lodash";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { If } from "shared/components/elements/Conditions";
import DerivedInfo from "builder_portal/components/roomBook/DerivedInfo";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import FeatureToggle from "shared/components/elements/FeatureToggle";
import DeleteButton from "../buttons/DeleteButton";
import ConfirmationPromptContent from "../buttons/ConfirmationPromptContent";
import OptionalityConfigPage from "./dialog/OptionalityConfigPage";
import MultiDialog from "../dialogs/MultiDialog";
import OptionsShape from "../../../shared/shapes/options.shape";
import { getDefaultUpgradeBundleOptions } from "../../selectors/upgradeBundles";

// TODO: i18n (whole file)
class LineItemDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    model: PropTypes.object,
    lineItem: PropTypes.object,
    ctrl: PropTypes.object,
    toggleDialog: PropTypes.func,
    isVisible: PropTypes.bool,
    derivedIds: PropTypes.array,
    unitFeatures: PropTypes.arrayOf(OptionsShape).isRequired,
    upgradeBundles: PropTypes.arrayOf(OptionsShape).isRequired
  };

  constructor(props) {
    super(props);

    const model = cloneDeep(props.model);
    const lineItemId = model.replacement_info?.id;
    const {
      optional,
      replacement,
      upgrade_bundle_ids: upgradeBundleIds
    } = model;

    this.state = {
      loading: false,
      deleteDerivedLoading: false,
      model,
      optionality: { optional, replacement, lineItemId, upgradeBundleIds }
    };
  }

  onSave = values => {
    const { ctrl } = this.props;
    if (values.id) {
      return ctrl.updateLineItem(values);
    }
    return ctrl.createLineItem(values);
  };

  onRemove = () => {
    const { model, ctrl, toggleDialog } = this.props;
    this.setState({ isLoading: true });
    return ctrl.deleteLineItem(model.id).then(() => {
      this.setState({ isLoading: false }, () => {
        toggleDialog(false);
      });
    });
  };

  onRemoveWithDerived = () => {
    const { model, ctrl, toggleDialog, derivedIds } = this.props;
    this.setState({ isLoading: true });
    return ctrl.deleteLineItems([...derivedIds, model.id]).then(() => {
      this.setState({ isLoading: false }, () => {
        toggleDialog(false);
      });
    });
  };

  static getFormFactory(isTemplate) {
    const fields = [
      {
        id: "title",
        label: `roomBook.lineItems.attributes.title.label`,
        placeholder: `roomBook.lineItems.attributes.title.placeholder`,
        message: `roomBook.lineItems.attributes.title.error`,
        rule: "isRequired",
        autoComplete: "off",
        autoFocus: true
      },
      {
        id: "display_mode",
        label: "roomBook.lineItems.attributes.display_mode.label",
        rule: "isRequired"
      },
      {
        id: "display_mode_buyer",
        label: "roomBook.lineItems.attributes.display_mode_buyer.label",
        rule: "isRequired"
      },
      {
        id: "external_id",
        label: "roomBook.lineItems.attributes.external_id.title.label",
        placeholder: `roomBook.lineItems.attributes.external_id.title.placeholder`,
        autoComplete: "off"
      },
      {
        id: "upgrade_bundle_id",
        label: "roomBook.lineItems.attributes.upgrade_bundle_id.label",
        placeholder: `roomBook.lineItems.attributes.upgrade_bundle_id.placeholder`
      }
    ];

    if (isTemplate)
      fields.push({
        id: "unit_feature_ids",
        label: "roomBook.sections.attributes.unitFeatures.label"
      });

    return new FormDefinition({ fields });
  }

  render() {
    const { isVisible, toggleDialog, lineItem } = this.props;
    return (
      <MultiDialog
        title={`${lineItem.getDisplayNumber()} ${lineItem.getTitle()}`}
        pages={this.getPages()}
        isVisible={isVisible}
        toggleDialog={toggleDialog}
      />
    );
  }

  getPages() {
    const { lineItem, ctrl, i18n, unitFeatures, upgradeBundles } = this.props;
    const { deleteDerivedLoading } = this.state;
    return [
      {
        id: "base",
        title: "Position bearbeiten",
        summary: "Bearbeiten Sie die Basisdaten dieser Position",
        renderContent: () => {
          const { model } = this.state;

          const form = LineItemDialog.getFormFactory(
            lineItem.isTemplate
          ).create(model, i18n, {
            onChange: data => this.setState({ model: data })
          });

          form.fields.display_mode.props.options = [
            {
              value: "cards",
              text:
                i18n["roomBook.lineItems.attributes.display_mode.options.cards"]
            },
            {
              value: "compact_list",
              text:
                i18n[
                  "roomBook.lineItems.attributes.display_mode.options.compact_list"
                ]
            },
            {
              value: "mandatory",
              text:
                i18n[
                  "roomBook.lineItems.attributes.display_mode.options.mandatory"
                ]
            }
          ];

          form.fields.display_mode_buyer.props.options = [
            {
              value: "default",
              text:
                i18n[
                  "roomBook.lineItems.attributes.display_mode_exports.options.default"
                ]
            },
            {
              value: "compact",
              text:
                i18n[
                  "roomBook.lineItems.attributes.display_mode_exports.options.compact"
                ]
            }
          ];

          form.fields.upgrade_bundle_id.props = {
            ...form.fields.upgrade_bundle_id.props,
            options: upgradeBundles,
            search: true,
            selection: true,
            clearable: true
          };

          if (lineItem.isTemplate) {
            form.fields.unit_feature_ids.props = {
              ...form.fields.unit_feature_ids.props,
              multiple: true,
              search: true,
              selection: true,
              options: unitFeatures,
              clearable: true
            };
          }

          return (
            <div>
              <Form
                id="lineItem"
                data-component="lineItemForm"
                onSubmit={form.handleSubmit(this.onSave.bind(this))}
              >
                <Form.Field width="16">
                  <Field component="Input" {...form.fields.title} />
                </Form.Field>

                {["display_mode", "display_mode_buyer"].map(field => (
                  <FeatureToggle
                    key={field.id}
                    featureToggleName="hide_line_item_display_mode_for_account_users"
                  >
                    <IsSystemAdmin styles={{ display: "block" }}>
                      <Form.Field width="16">
                        <Field component="Select" {...form.fields[field]} />
                      </Form.Field>
                    </IsSystemAdmin>
                  </FeatureToggle>
                ))}
                {["display_mode", "display_mode_buyer"].map(field => (
                  <FeatureToggle
                    key={field.id}
                    featureToggleName="hide_line_item_display_mode_for_account_users"
                    disabled
                  >
                    <Form.Field width="16">
                      <Field component="Select" {...form.fields[field]} />
                    </Form.Field>
                  </FeatureToggle>
                ))}
                <FeatureToggleActive featureToggleName="show_external_id">
                  <Form.Field width="16">
                    <Field component="Input" {...form.fields.external_id} />
                  </Form.Field>
                </FeatureToggleActive>
                <If condition={!lineItem.isOptional()}>
                  <FeatureToggleActive featureToggleName="show_upgrade_bundles">
                    <Form.Field width="16">
                      <Field
                        component="Select"
                        {...form.fields.upgrade_bundle_id}
                      />
                    </Form.Field>
                  </FeatureToggleActive>
                </If>
                <FeatureToggleActive featureToggleName="show_unit_feature_groups">
                  <If condition={lineItem.isTemplate}>
                    <Form.Field width="16">
                      <Field
                        component="Select"
                        {...form.fields.unit_feature_ids}
                      />
                    </Form.Field>
                  </If>
                </FeatureToggleActive>
              </Form>
              {this.renderContractorNotificationModeSelector()}
            </div>
          );
        },
        actions: [
          {
            id: "save",
            label: "meta.actions.save",
            color: "green",
            onClick: () => {
              const { model } = this.state;
              const { ctrl } = this.props;
              if (model.id) {
                return ctrl.updateLineItem(model);
              }
              return ctrl.createLineItem(model);
            }
          }
        ]
      },
      {
        id: "optionality",
        title: "Verhalten konfigurieren",
        summary:
          "Konfigurieren Sie diese Position als optional oder alternativ",
        hidden: !this.isOptionallityConfigAllowed(lineItem),
        renderContent: () => {
          return (
            <OptionalityConfigPage
              lineItem={lineItem}
              onChange={optionality => {
                this.setState(prevState => ({
                  optionality: { ...prevState.optionality, ...optionality }
                }));
              }}
            />
          );
        },
        actions: [
          {
            id: "save",
            label: "meta.actions.save",
            color: "green",
            onClick: () => {
              const { optionality } = this.state;

              if (optionality && optionality.optional) {
                return ctrl.createLineItemOptionality(lineItem.getId(), {
                  replacement_for_id: optionality.replacement
                    ? optionality.lineItemId
                    : undefined,
                  upgrade_bundle_ids: optionality.upgradeBundleIds
                });
              }
              return ctrl.deleteLineItemOptionality(lineItem.getId());
            }
          }
        ]
      },
      {
        id: "delete_derived",
        title: (
          <FormattedMessage id="roomBook.actions.deleteWithDerived.item.title" />
        ),
        summary: (
          <FormattedMessage id="roomBook.actions.deleteWithDerived.item.summary" />
        ),
        buttonIcon: "trash",
        buttonColor: "red",
        hidden:
          lineItem.isCancelled() ||
          !this.hasDeleteButton() ||
          !lineItem.isTemplate,
        renderContent: () => (
          <DerivedInfo
            itemId={lineItem.getId()}
            itemType="line_item"
            onLoading={this.handleDeleteDerivedLoading}
            onError={this.handleError}
            loading={deleteDerivedLoading}
            resource={ctrl.lineItemResource()}
          />
        ),
        actions: [
          {
            id: "remove",
            label: "meta.actions.remove",
            color: "red",
            onClick: this.onRemoveWithDerived,
            disabled: deleteDerivedLoading
          }
        ]
      },
      {
        id: "delete",
        title: "Position löschen",
        summary: "Entfernen Sie diese Position aus dem Raumbuch",
        type: "button",
        hidden:
          lineItem.isCancelled() ||
          !this.hasDeleteButton() ||
          lineItem.isTemplate,
        renderButton: () => {
          return this.renderDeleteButton();
        }
      }
    ];
  }

  handleDeleteDerivedLoading = loading =>
    this.setState({ deleteDerivedLoading: loading });

  handleError = () => {
    const { toggleDialog } = this.props;
    toggleDialog(false);
  };

  renderContractorNotificationModeSelector() {
    const { model, i18n } = this.props;
    const contractorModes = ["inform", "skip"].map(k => {
      return {
        id: k,
        title:
          i18n[
            `roomBook.lineItems.attributes.cancellation_contactor_mode.${k}.title`
          ],
        hint:
          i18n[
            `roomBook.lineItems.attributes.cancellation_contactor_mode.${k}.hint`
          ]
      };
    });

    if (model.cancellation) {
      return (
        <Grid compact className="margin top spacious">
          <Grid.Row>
            <Grid.Column width={16}>
              <strong>
                <FormattedMessage
                  id="roomBook.lineItems.actions.cancellationDialog.message03"
                  defaultValue="roomBook.lineItems.actions.cancellationDialog.message03"
                />
              </strong>
            </Grid.Column>
          </Grid.Row>
          {this.renderRadioGroup(
            "cancellation_contactor_mode",
            contractorModes
          )}
        </Grid>
      );
    }
    return null;
  }

  renderRadioGroup(field, options) {
    const { model } = this.state;

    return options.map(option => {
      return (
        <Grid.Row key={option.id}>
          <Grid.Column width={1} verticalAlign="middle" textAlign="center">
            <Form.Field>
              <Radio
                name={field}
                value={option.id}
                checked={model[field] === option.id}
                onChange={this.handleUpdateModelFn(field)}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={15} verticalAlign="middle">
            <Header as="h4">
              {option.title}
              <Header.Subheader>{option.hint}</Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      );
    });
  }

  handleUpdateModelFn(field) {
    return (e, { value }) => {
      const { model } = this.state;
      model[field] = value;
      this.setState({ model });
    };
  }

  isOptionallityConfigAllowed = lineItem => {
    return !(
      (lineItem.isOptional() && lineItem.isActivated()) ||
      lineItem.isCancellation() ||
      lineItem.isCancelled() ||
      lineItem.isInProcess() ||
      lineItem.hasActiveCart()
    );
  };

  hasDeleteButton() {
    const { ctrl } = this.props;
    return !!ctrl.deleteSubLineItem;
  }

  renderDeleteButton() {
    const { lineItem } = this.props;
    const props = {};
    if (lineItem.isChangePolicy("allowed")) {
      props.confirmationMessage =
        "roomBook.lineItems.actions.removeDialog.message";
    } else {
      props.confirmationContent = (
        <ConfirmationPromptContent
          message={lineItem.getChangePolicy().confirmation_message}
        />
      );
    }
    return (
      <DeleteButton
        confirmationTitle="roomBook.lineItems.actions.removeDialog.title"
        onDelete={this.onRemove}
        loading={this.state.isLoading}
        content=""
        icon="trash"
        {...props}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  unitFeatures: getUnitFeatureOptions,
  upgradeBundles: getDefaultUpgradeBundleOptions
});

export default connect(mapStateToProps)(LineItemDialog);
