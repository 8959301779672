import React from "react";
import { Link } from "react-router";
import { Menu, Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import { If } from "shared/components/elements/Conditions";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { AccountShape, ProjectShape } from "shared/shapes";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";

class TabMenu extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    activeTab: PropTypes.string,
    rootUrl: PropTypes.string,
    statistics: PropTypes.object,
    unit: PropTypes.object,
    account: AccountShape.isRequired,
    project: ProjectShape.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { i18n, rootUrl, activeTab, unit, project, account } = this.props;

    const isVisibleDeadline = account.hasProjectRoleRight(
      project?.id,
      "allow_access_deadlines"
    );
    const isVisibleDocuments = account.hasProjectRoleRight(
      project?.id,
      "allow_access_documents"
    );
    const isVisibleStatistics = account.hasProjectRoleRight(
      project?.id,
      "allow_access_statistics"
    );
    const isVisibleActivities = account.hasProjectRoleRight(
      project?.id,
      "allow_access_activities"
    );
    const isVisibleProspects = account.hasProjectRoleRight(
      project?.id,
      "allow_perspective_sales"
    );
    const isVisibleUnitVariables = account.hasProjectRoleRight(
      project?.id,
      "allow_perspective_onboarding"
    );

    const isSample = get(unit, "sample", false);

    return (
      <Menu stackable tabular attached="top" data-component="unitTabMenu">
        <Menu.Item
          id="unit-roomBook-tab"
          name="roomBook"
          content={
            <Link to={`${rootUrl}/room-book`}>
              {i18n["unit.tabs.roomBook"]}
            </Link>
          }
          active={activeTab === "room-book"}
        />
        {!isSample && isVisibleActivities && (
          <Menu.Item
            id="unit-activities-tab"
            name="activities"
            content={
              <Link to={`${rootUrl}/activities`}>
                {i18n["unit.tabs.activities"]}
              </Link>
            }
            active={activeTab === "activities"}
          />
        )}
        {!isSample && isVisibleDeadline && (
          <Menu.Item
            id="unit-deadlines-tab"
            name="deadlines"
            content={this.renderDeadlineTabLabel()}
            active={activeTab === "deadlines"}
          />
        )}
        <If condition={isVisibleDocuments}>
          <Menu.Item
            id="unit-documents-tab"
            name="documents"
            content={
              <Link to={`${rootUrl}/documents`}>
                {i18n["unit.tabs.attachments"]}
              </Link>
            }
            active={activeTab === "documents"}
          />
        </If>
        {!isSample && isVisibleStatistics && (
          <Menu.Item
            id="unit-overview-tab"
            name="statistics"
            content={
              <Link to={`${rootUrl}/statistics`}>
                {i18n["unit.tabs.statistics"]}
              </Link>
            }
            active={activeTab === "statistics"}
          />
        )}
        {!isSample && (
          <Menu.Item
            id="unit-messages-tab"
            name="messages"
            content={
              <Link to={`${rootUrl}/messages`}>
                {i18n["unit.tabs.messages"]}
              </Link>
            }
            active={activeTab === "messages"}
          />
        )}
        <If condition={isVisibleUnitVariables}>
          <FeatureToggleActive featureToggleName="show_unit_variables">
            <Menu.Item
              id="unit-variables-tab"
              name="unitVariables"
              content={
                <Link to={`${rootUrl}/unit_variables`}>
                  <FormattedMessage
                    id="roomBook.unitVariables.tab"
                    defaultMessage="Unit vars"
                  />
                </Link>
              }
              active={activeTab === "unit_variables"}
            />
          </FeatureToggleActive>
        </If>

        <If condition={isVisibleProspects}>
          <FeatureToggleActive featureToggleName="mp_prospect_generation">
            <Menu.Item
              id="unit-prospect-tab"
              name="unitProspect"
              content={
                <Link to={`${rootUrl}/prospect_list`}>
                  <FormattedMessage
                    id="roomBook.unitProspect.prospect"
                    defaultMessage="Prospect List"
                  />
                </Link>
              }
              active={activeTab === "prospect_list"}
            />
          </FeatureToggleActive>
        </If>
      </Menu>
    );
  }

  renderDeadlineTabLabel() {
    const { i18n, statistics, rootUrl } = this.props;

    if (statistics && statistics.deadlines) {
      const children = [];
      if (statistics.deadlines.overdue && statistics.deadlines.overdue > 0) {
        children.push(
          <Label
            color="red"
            data-attr="overdue"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.overdue"]}
            key="overdue"
          >
            {statistics.deadlines.overdue}
          </Label>
        );
      }
      if (statistics.deadlines.close && statistics.deadlines.close > 0) {
        children.push(
          <Label
            color="orange"
            data-attr="close"
            size="mini"
            circular
            data-tooltip={i18n["projectDeadline.messages.close"]}
            key="due"
          >
            {statistics.deadlines.close}
          </Label>
        );
      }
      return (
        <Link to={`${rootUrl}/deadlines`}>
          {children} {i18n["unit.tabs.deadlines"]}
        </Link>
      );
    }
    return (
      <Link to={`${rootUrl}/deadlines`}>{i18n["unit.tabs.deadlines"]}</Link>
    );
  }
}

export default TabMenu;
