import { get } from "lodash";

const QUEUE_SIZE = 100;
const STORAGE = get(window, "localStorage", null);

export const getFolderStateFromStorage = (projectId, unitId) => {
  const uniqId = `${projectId}-${unitId}`;
  try {
    const dokumentsFolderState = JSON.parse(
      get(STORAGE, "dokumentsFolderState", null)
    );
    if (dokumentsFolderState) {
      return dokumentsFolderState.find(x => x.uniqId === uniqId)?.data;
    }
  } catch (e) {
    STORAGE.removeItem("dokumentsFolderState");
  }
  return null;
};

export const setFolderStateToStorage = (projectId, unitId, data) => {
  const uniqId = `${projectId}-${unitId}`;
  try {
    const dokumentsFolderState = JSON.parse(
      get(STORAGE, "dokumentsFolderState", [])
    );
    // changed unit goes to the first position
    const cleanList = dokumentsFolderState.filter(x => x.uniqId !== uniqId);
    if (cleanList.length === QUEUE_SIZE) cleanList.length = QUEUE_SIZE - 1;

    cleanList.unshift({ uniqId, data });
    STORAGE.setItem("dokumentsFolderState", JSON.stringify(cleanList));
  } catch (e) {
    STORAGE.removeItem("dokumentsFolderState");
    STORAGE.setItem("dokumentsFolderState", JSON.stringify([{ uniqId, data }]));
  }
};
