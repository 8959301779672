/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React from "react";
import { Tab } from "semantic-ui-react";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import { Field } from "formik";
import PropTypes from "prop-types";

import RenderList from "./content-page-elements/RenderList";

const Tabs = props => {
  const { fieldName } = props;
  const panes = [
    {
      menuItem: "Visuell",
      render: () => (
        <Tab.Pane>
          <RenderList {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Json",
      render: () => (
        <Tab.Pane>
          <Field
            id={fieldName}
            name={fieldName}
            component={SemanticFormikJsonEditor}
            label="JSON content"
          />
        </Tab.Pane>
      )
    }
  ];
  return <Tab panes={panes} />;
};

Tabs.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default Tabs;
