import React, { useState } from "react";
import PropTypes from "prop-types";
import { CustomDataTypesResource } from "builder_portal/actions/customDataTypesActions";
import { CustomFieldsResource } from "builder_portal/actions/customFieldsActions";
import { useDispatch, useSelector } from "react-redux";
import { getCustomFields } from "shared/selectors/customFields";
import { getCustomDataTypes } from "shared/selectors/customDataTypes";
import { Form, Header, Loader, FormField } from "semantic-ui-react";
import CurrencyInput from "shared/components/forms/CurrencyInput";
import { FormattedMessage } from "react-intl";
import DateInput from "shared/components/forms/DateInput";
import TimePicker from "shared/components/forms/TimePicker";
import { If } from "../../../../shared/components/elements/Conditions";

const CustomFieldValues = ({ product, onChange }) => {
  const value = product.product_field_values || [];
  const dispatch = useDispatch();
  const customFieldsResource = new CustomFieldsResource(dispatch);
  const customDataTypesResource = new CustomDataTypesResource(dispatch);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const customDataTypes = useSelector(getCustomDataTypes) || [];

  const customFields = useSelector(getCustomFields) || [];
  const productCustomFields = customFields
    .filter(field => field.subject === "Product")
    .sort((a, b) => a.position - b.position);

  // given a custom field, return the corresponding custom data type
  const getCustomDataType = id => {
    return customDataTypes.find(customDataType => customDataType?.id === id)
      ?.base_type;
  };

  const getCustomDataTypeOptions = id => {
    return customDataTypes.find(customDataType => customDataType?.id === id)
      ?.options;
  };

  const fetchCustomFieldsAndTypes = async () => {
    try {
      await customFieldsResource.fetchAll();
      await customDataTypesResource.fetchAll();
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCustomFieldsAndTypes();
  }, []);

  const handleCustomFieldChange = (val, field) => {
    const updatedProductFieldValues = [...value];

    // loop through the product field values and update the value of the field that was changed, if it exists
    // if it doesn't exist, add it to the array
    const index = updatedProductFieldValues.findIndex(
      productFieldValue => productFieldValue.custom_field_id === field.id
    );

    if (index > -1) {
      updatedProductFieldValues[index].internal_value = val;
      updatedProductFieldValues[index].account_id = field.account_id;
      updatedProductFieldValues[index].custom_field_id = field.id;
    } else {
      updatedProductFieldValues.push({
        account_id: field.account_id,
        internal_value: val,
        custom_field_id: field.id
      });
    }

    onChange(updatedProductFieldValues);
  };

  const renderStringInput = (field, label, type) => {
    return (
      <Form.Input
        label={label}
        value={
          value.find(val => val.custom_field_id === field.id)?.internal_value
        }
        onChange={e => handleCustomFieldChange(e.target.value, field)}
        type={type || "text"}
      />
    );
  };

  const renderTextInput = (field, label) => {
    return (
      <Form.TextArea
        label={label}
        value={
          value.find(val => val.custom_field_id === field.id)?.internal_value
        }
        onChange={e => handleCustomFieldChange(e.target.value, field)}
      />
    );
  };

  const renderBooleanInput = (field, label) => {
    return (
      <Form.Checkbox
        label={label}
        checked={field?.internal_value}
        // eslint-disable-next-line no-unused-vars
        onChange={(_event, { _, checked }) =>
          handleCustomFieldChange(checked, field)
        }
      />
    );
  };

  const renderCurrencyInput = (field, label) => {
    return (
      <FormField
        style={{
          width: "100%"
        }}
      >
        <label id={`${field.id}-label`} htmlFor={label}>
          {label}
        </label>
        <CurrencyInput
          label={label}
          fluid
          value={
            value.find(val => val.custom_field_id === field.id)?.internal_value
          }
          onBlur={(event, data) => handleCustomFieldChange(data.value, field)}
        />
      </FormField>
    );
  };

  const handleDateChange = (data, field) => {
    handleCustomFieldChange(data.value, field);
  };

  const renderDateInput = (field, label) => {
    return (
      <FormField
        style={{
          width: "100%"
        }}
      >
        <label id={`${field.id}-label`} htmlFor={label}>
          {label}
        </label>
        <DateInput
          id={field.id}
          fluid
          type="date"
          onChange={(_event, data) => handleDateChange(data, field)}
          value={
            value.find(val => val.custom_field_id === field.id)?.internal_value
          }
          nullable
        />
      </FormField>
    );
  };

  const renderDateTimeInput = (field, label) => {
    return (
      <TimePicker
        type="datetime-local"
        label={label}
        onChange={val => handleCustomFieldChange(val, field)}
        value={
          value.find(val => val.custom_field_id === field.id)?.internal_value
        }
      />
    );
  };

  const renderEnumInput = (field, label) => {
    return (
      <Form.Select
        options={
          getCustomDataTypeOptions(field.custom_data_type_id)?.map(option => ({
            key: option.id,
            text: option.label,
            value: option.value
          })) || []
        }
        label={label}
        value={
          value.find(val => val.custom_field_id === field.id)?.internal_value
        }
        onChange={(_event, data) => handleCustomFieldChange(data.value, field)}
      />
    );
  };

  const renderCustomFieldInput = (field, type) => {
    const { label } = field;

    switch (type) {
      case "String":
        return renderStringInput(field, label);
      case "Text":
        return renderTextInput(field, label);
      case "Float":
        return renderStringInput(field, label);
      case "Integer":
        return renderStringInput(field, label, "number");
      case "Boolean":
        return renderBooleanInput(field, label);
      case "Currency":
        return renderCurrencyInput(field, label);
      case "Date":
        return renderDateInput(field, label);
      case "DateTime":
        return renderDateTimeInput(field, label);
      case "Enum":
        return renderEnumInput(field, label);
      default:
        return null;
    }
  };

  if (error) {
    return null;
  }

  if (isLoading) {
    return <Loader active inline="center" />;
  }

  return (
    <If condition={productCustomFields?.length}>
      <Form.Field width={16}>
        <Header as="h3">
          <FormattedMessage id="product.attributes.customFields.label" />
        </Header>
        {productCustomFields.map(field => (
          <Form.Field key={field.id} width={16}>
            {renderCustomFieldInput(
              field,
              getCustomDataType(field?.custom_data_type_id)
            )}
          </Form.Field>
        ))}
      </Form.Field>
    </If>
  );
};

CustomFieldValues.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomFieldValues;
