import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import ContractorsResource, {
  ContractorAssignmentRulesResource
} from "builder_portal/actions/contractorActions";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { withRouter } from "shared/helpers/withRouter";
import { Loader, Table, Header, Icon } from "semantic-ui-react";
import {
  getAllContractors,
  getContractorAssignmentRules
} from "builder_portal/selectors";
import { getTradesDictionary } from "shared/selectors";
import { getUnitsById } from "shared/selectors/units";
import { getPageContentSectionsById } from "shared/selectors/unit/sections";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { uniqBy, keyBy } from "lodash";
import { shape, string } from "prop-types";
import { If } from "shared/components/elements/Conditions";
import Growl from "builder_portal/actions/growlActions";
import ContractorAssignmentRulesDialog from "./ContractorAssignmentRulesDialog";

const ContractorTabV2 = props => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const contractors = keyBy(useSelector(getAllContractors), "id");
  const contractorAssignmenRules = useSelector(getContractorAssignmentRules);
  const tradeDictionary = useSelector(getTradesDictionary);
  const units = useSelector(getUnitsById);
  const sections = useSelector(getPageContentSectionsById);

  const trades = useMemo(() => {
    const temp = [];
    contractorAssignmenRules.forEach(element => {
      temp.push({
        id: element.trade,
        label: tradeDictionary[element.trade]?.label
      });
    });
    return uniqBy(temp, "id");
  }, [contractorAssignmenRules]);

  useEffect(() => {
    const { projectId } = props.params;
    setLoading(true);
    Promise.all([
      new ContractorsResource(dispatch).fetchAll(),
      new ContractorAssignmentRulesResource(dispatch, projectId).fetchAll(),
      new UnitsResource(dispatch, projectId).fetchAll()
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const renderDeleteButton = id => {
    const { projectId } = props.params;
    const deleteButton = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role="button"
        id="assignment-rule-remove"
        data-tooltip={intl.formatMessage({ id: "meta.actions.remove" })}
      >
        <Icon name="trash" />
      </a>
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: closeConfirmation => {
          const resource = new ContractorAssignmentRulesResource(
            dispatch,
            projectId
          );
          resource
            .remove(id)
            .then(() => resource.fetchAll())
            .then(closeConfirmation)
            .catch(() => {
              new Growl(dispatch).error(
                "message.error.title",
                "meta.confirmations.changesNotSaved"
              );
            });
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="contractor.assignmentRule.removeConfirmation.title"
        message="contractor.assignmentRule.removeConfirmation.message"
        buttons={buttons}
        button={deleteButton}
      />
    );
  };

  if (isLoading) return <Loader active style={{ marginTop: "50px" }} />;

  const noTrades = (
    <Table.Row>
      <Table.Cell colSpan="3">
        <Header as="h4" icon textAlign="center">
          <div className="margin top spacious margin bottom spacious">
            <Icon name="info circle" />
          </div>
          <Header.Content>
            <FormattedMessage id="contractor.message.noContractors" />
          </Header.Content>
        </Header>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="contractor.title.one" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="section.title.singular" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="unit.singular" />
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <If condition={trades.length === 0}>{noTrades}</If>
        {trades.map(trade => {
          return (
            <Fragment key={trade.id}>
              <Table.Row style={{ backgroundColor: "#EEE" }}>
                <Table.Cell width={15} colSpan={3}>
                  <Header as="h5" id={trade.id}>
                    {trade.label}
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign="right" width={1}>
                  <ContractorAssignmentRulesDialog
                    button={<Icon name="plus" link />}
                    model={{ trade: trade.id }}
                    projectId={props.params.projectId}
                  />
                </Table.Cell>
              </Table.Row>
              {contractorAssignmenRules
                .filter(rule => rule.trade === trade.id)
                .map(rule => (
                  <Table.Row key={rule.id}>
                    <Table.Cell
                      width={5}
                      style={{ borderRight: "1px solid #EEE" }}
                    >
                      {contractors[rule.contractor_id]?.name}
                    </Table.Cell>
                    <Table.Cell
                      width={5}
                      style={{ borderRight: "1px solid #EEE" }}
                    >
                      {rule.section_id ? (
                        sections[rule.section_id]?.name
                      ) : (
                        <FormattedMessage id="section.title.all" />
                      )}
                    </Table.Cell>
                    <Table.Cell
                      width={5}
                      style={{ borderRight: "1px solid #EEE" }}
                    >
                      {rule.unit_id ? (
                        units[rule.unit_id]?.display_name
                      ) : (
                        <FormattedMessage id="section.title.all" />
                      )}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="right">
                      <ContractorAssignmentRulesDialog
                        button={
                          <Icon name="setting" link id={`edit-${rule.id}`} />
                        }
                        model={rule}
                        projectId={props.params.projectId}
                      />
                      {renderDeleteButton(rule.id)}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Fragment>
          );
        })}
      </Table.Body>
    </Table>
  );
};

ContractorTabV2.propTypes = {
  params: shape({ projectId: string }).isRequired
};

export default withRouter(ContractorTabV2);
