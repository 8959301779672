import React from "react";
import { connect } from "react-redux";
import { Grid, Form, Message } from "semantic-ui-react";
import { shape, string, number } from "prop-types";

import { getUsers } from "../../selectors/index";

class AddProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_name: "",
      customer_success_manager_id: "",
      unit_quota: 0,
      error: null,
      success: null,
      loading: false
    };
  }

  handleSubmit = () => {
    const {
      project_name,
      customer_success_manager_id,
      unit_quota
    } = this.state;
    this.setState({
      project_name,
      customer_success_manager_id,
      unit_quota,
      loading: true
    });

    fetch("/api/v1/support_utils/add_project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        project: {
          project_name,
          unit_quota,
          customer_success_manager_id
        }
      })
    })
      .then(response => response.json())
      .catch(error =>
        this.setState({
          error: JSON.stringify(error),
          success: false,
          loading: false
        })
      )
      .then(response => {
        if (response.error) {
          this.setState({
            error: JSON.stringify(response.error),
            success: false,
            loading: false
          });
        } else {
          this.setState({ success: true, error: false, loading: false });
        }
      });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  getUserOptions = () => {
    const { users } = this.props;
    return users
      .filter(user => user.group === "admin")
      .map(user => {
        return {
          key: user.id,
          value: user.id,
          text: `${user.first_name} ${user.last_name}`
        };
      });
  };

  render() {
    const {
      project_name,
      customer_success_manager_id,
      unit_quota,
      error,
      success,
      loading
    } = this.state;

    return (
      <div>
        <h2>Add Project</h2>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form
              loading={loading}
              success={success}
              error={!!error}
              onSubmit={this.handleSubmit}
            >
              <Message
                success
                header="Project Added!"
                content="Please check the results!"
              />
              <Message error header="Project Creation Failed" content={error} />
              <Form.Input
                name="project_name"
                value={project_name}
                onChange={this.handleChange}
                label="Project Name"
                placeholder="Metropolapart"
                required
                width="4"
              />
              <Form.Input
                name="unit_quota"
                value={unit_quota}
                defaultValue={0}
                onChange={this.handleChange}
                label="Unit quota"
                required
                width="4"
              />
              <Form.Select
                name="customer_success_manager_id"
                value={customer_success_manager_id}
                placeholder="Customer success manager"
                onChange={this.handleChange}
                options={this.getUserOptions()}
                clearable
                width="4"
              />
              <Form.Button width="4">Submit</Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}

AddProject.propTypes = {
  users: shape({
    id: number,
    first_name: string,
    last_name: string
  })
};

AddProject.defaultProps = {
  users: []
};

const mapStateToProps = state => {
  return {
    users: getUsers(state)
  };
};

export default connect(mapStateToProps)(AddProject);
