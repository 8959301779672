import PropTypes from "prop-types";
import React, { Component } from "react";

import { formatNumber } from "shared/helpers/formatNumber";

class FormatNumber extends Component {
  render() {
    return <span>{formatNumber(this.props.value, this.props.decimals)}</span>;
  }
}

FormatNumber.propTypes = {
  value: PropTypes.number.isRequired,
  decimals: PropTypes.number.isRequired
};

FormatNumber.defaultProps = {
  decimals: 2
};

export default FormatNumber;
