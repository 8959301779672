import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { get, keyBy } from "lodash";
import { Grid, Header, Icon, Label, List } from "semantic-ui-react";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import FormatQuantity from "shared/components/quantity/FormatQuantity";

import "./roomBookLineItem.scss";

class RoomBookCancellationItem extends React.Component {
  static propTypes = {
    model: PropTypes.object,
    productsDictionary: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextModel = nextProps.model;
    const prevModel = this.props.model;

    return (
      this.state !== nextState ||
      nextModel.updated_at !== prevModel.updated_at ||
      nextModel.position !== prevModel.position
    );
  }

  render() {
    const { productsDictionary, model } = this.props;
    const product = productsDictionary[model.product_id];
    const productImage = model && {
      backgroundImage: `url('${get(product, "thumb_url", model.thumb_url)}')`
    };
    const isFlat = model && model.price_strategy === "flat";
    const quantityString = !isFlat ? (
      <Label basic className="strikethrough">
        <FormatQuantity value={model.quantity} unit={model.price_strategy} />
      </Label>
    ) : null;
    const isIndividualLineItem = model && !model.product_group_id;

    return (
      <List.Item
        data-model="sub_line_item"
        data-component="roomBookLineItem"
        className={isIndividualLineItem ? "isIndividualLineItem" : null}
      >
        <List.Content>
          <Grid stackable verticalAlign="middle">
            <Grid.Column width="3">
              {productImage && (
                <div>
                  <div style={productImage} className="productImage" />
                </div>
              )}
            </Grid.Column>
            <Grid.Column width="13" className="content">
              <Grid>
                <Grid.Column width={16}>
                  <div className="titleButton">
                    <Icon color="grey" name="remove" />
                    <Header className="strikethrough">
                      <span data-attr="display_number">
                        {model.display_number}
                      </span>
                      &nbsp;
                      <span data-attr="title">{model.title}</span>
                    </Header>
                    <div className="quantity">
                      {!isFlat && (
                        <span data-attr="quantity">{quantityString}</span>
                      )}
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
              <div className="productContainer">
                {product && (
                  <div className="description">
                    <div className="text">
                      <div className="descriptionHeader">
                        {product.supplier}, {product.series}
                      </div>
                      {product.name}
                    </div>
                  </div>
                )}
                {!product && (
                  <div className="description">
                    <div className="text">
                      <div className="descriptionHeader">
                        <FormattedMessage
                          id="roomBook.lineItems.individualProduct"
                          defaultText="roomBook.lineItems.individualProduct"
                        />
                      </div>
                      {model.description || ""}
                    </div>
                  </div>
                )}
                <div className="price">
                  <span data-attr="total">
                    <FormatCurrency amount={-model.total} />
                  </span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </List.Content>
      </List.Item>
    );
  }
}

const mapStateToProps = (state, props) => {
  const products = props.productsRepository.getGroupProducts(
    props.model.product_group_id
  );
  return { ...props, productsDictionary: keyBy(products || [], "id") };
};

export default connect(mapStateToProps)(RoomBookCancellationItem);
