/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import { Container, Header, Button, Modal, Grid } from "semantic-ui-react";
import { Formik } from "formik";
import { Input, Form, Select, TextArea } from "formik-semantic-ui-react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { If } from "shared/components/elements/Conditions";
import { ContactsResource } from "builder_portal/actions/contactsActions";
import { useDispatch } from "react-redux";
import Growl from "builder_portal/actions/growlActions";
import MasterDataResource from "builder_portal/actions/messaging/masterDataActions";
import ActivityController from "builder_portal/containers/activity/activityController";

const CreateContactDialog = ({
  trigger,
  model,
  isOpened,
  onContactCreated,
  contactModalType,
  onDialogClose,
  activity
}) => {
  const intl = useIntl();

  const [open, setOpen] = useState(isOpened);
  const dispatch = useDispatch();
  const resource = new ContactsResource(dispatch);
  const masterDataResource = new MasterDataResource(dispatch);

  const refResetForm = useRef();

  React.useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  const validationSchema = Yup.object({
    salutation: Yup.string(),
    title: Yup.string(),
    first_name: Yup.string(),
    last_name: Yup.string(),
    company: Yup.string(),
    email: Yup.string()
      .email(intl.formatMessage({ id: "message.errorForm.email" }))
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    phone: Yup.string(),
    mobile: Yup.string(),
    address_postcode: Yup.string(),
    address_number: Yup.string(),
    address_street: Yup.string(),
    address_town: Yup.string()
  });

  const clearForm = () => {
    if (typeof refResetForm.current === "function") refResetForm.current();
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onDialogClose && typeof onDialogClose === "function") onDialogClose();
    clearForm();
  }, []);

  const onSubmit = useCallback(
    values => {
      // append the id to the values if it exists
      if (model?.id) {
        values.id = model?.id;
      }

      return resource
        .save(values)
        .then(data => {
          new Growl(dispatch).success(
            "message.success.title",
            "meta.states.saving"
          );

          if (activity) {
            const activityContactsCtrl = new ActivityController(
              activity.projectId,
              activity.activityId,
              dispatch
            );

            return activityContactsCtrl
              .loadContacts()
              .then(handleClose)
              .then(() => {
                if (
                  onContactCreated &&
                  typeof onContactCreated === "function"
                ) {
                  onContactCreated(data?.data?.contact, contactModalType);
                }
              });
          }

          masterDataResource.fetchAll();
          // eslint-disable-next-line consistent-return
          return resource
            .fetchAll()
            .then(handleClose)
            .then(() => {
              if (onContactCreated && typeof onContactCreated === "function") {
                onContactCreated(data?.data?.contact, contactModalType);
              }
            });
        })
        .catch(() => {
          new Growl(dispatch).error(
            "message.error.title",
            "meta.confirmations.changesNotSaved"
          );
        });
    },
    [model, contactModalType]
  );

  const handleDelete = (id, confirmationClose) => {
    confirmationClose();
    return resource.remove(id).then(() => {
      masterDataResource.fetchAll();
      // eslint-disable-next-line consistent-return
      return resource.fetchAll().then(handleClose);
    });
  };

  const renderDeleteButton = (id, isSubmitting, setSubmitting) => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        icon="trash"
        loading={isSubmitting}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        disabled={isSubmitting}
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: confirmationClose => {
          setSubmitting(true);
          handleDelete(id, confirmationClose);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="createContact.removeConfirmation.title"
        message="createContact.removeConfirmation.body"
        buttons={buttons}
        button={button}
      />
    );
  };

  return (
    <Formik
      initialValues={
        model || {
          salutation: "",
          title: "",
          first_name: "",
          last_name: "",
          company: "",
          email: "",
          phone: "",
          mobile: "",
          address_postcode: "",
          address_number: "",
          address_street: "",
          address_town: ""
        }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, resetForm, setSubmitting }) => {
        refResetForm.current = resetForm;
        return (
          <Modal
            trigger={trigger}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            size="small"
          >
            <Header
              content={
                model
                  ? intl.formatMessage({ id: "createContact.title.edit" })
                  : intl.formatMessage({ id: "createContact.title.create" })
              }
            />
            <Modal.Content scrolling>
              <Form onSubmit={handleSubmit}>
                <Container>
                  <Input
                    name="email"
                    label={intl.formatMessage({
                      id: "createContact.form.email"
                    })}
                    errorPrompt
                  />

                  <Grid>
                    <Grid.Column width={8}>
                      <Select
                        name="salutation"
                        label={intl.formatMessage({
                          id: "createContact.form.salutation"
                        })}
                        options={[
                          { key: "mr", value: "mr", text: "Herr" },
                          { key: "mrs", value: "mrs", text: "Frau" }
                        ]}
                        clearable
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Select
                        name="title"
                        label={intl.formatMessage({
                          id: "createContact.form.title"
                        })}
                        options={[
                          { key: "dr", value: "dr", text: "Dr." },
                          { key: "prof", value: "prof", text: "Prof." }
                        ]}
                        clearable
                      />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={8}>
                      <Input
                        name="first_name"
                        label={intl.formatMessage({
                          id: "createContact.form.first_name"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        name="last_name"
                        label={intl.formatMessage({
                          id: "createContact.form.last_name"
                        })}
                      />
                    </Grid.Column>
                  </Grid>

                  <Input
                    name="company"
                    label={intl.formatMessage({
                      id: "createContact.form.company"
                    })}
                  />

                  <Grid>
                    <Grid.Column width={8}>
                      <Input
                        name="phone"
                        label={intl.formatMessage({
                          id: "createContact.form.phone"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Input
                        name="mobile"
                        label={intl.formatMessage({
                          id: "createContact.form.mobile"
                        })}
                      />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={11}>
                      <Input
                        name="address_street"
                        label={intl.formatMessage({
                          id: "createContact.form.address_street"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Input
                        name="address_number"
                        label={intl.formatMessage({
                          id: "createContact.form.address_number"
                        })}
                      />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={5}>
                      <Input
                        name="address_postcode"
                        label={intl.formatMessage({
                          id: "createContact.form.address_postcode"
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Input
                        name="address_town"
                        label={intl.formatMessage({
                          id: "createContact.form.address_town"
                        })}
                      />
                    </Grid.Column>
                  </Grid>

                  <Grid>
                    <Grid.Column width={16}>
                      <TextArea
                        name="notes"
                        label={intl.formatMessage({
                          id: "createContact.form.notes"
                        })}
                      />
                    </Grid.Column>
                  </Grid>
                </Container>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Grid>
                <Grid.Column width={6} textAlign="left">
                  <If condition={!!model?.id && !!model?.deletable}>
                    {renderDeleteButton(model?.id, isSubmitting, setSubmitting)}
                  </If>
                </Grid.Column>
                <Grid.Column width={10} textAlign="right">
                  <Button
                    content={intl.formatMessage({
                      id: "meta.actions.cancel"
                    })}
                    onClick={handleClose}
                  />
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    primary
                    content={intl.formatMessage({
                      id: "meta.actions.save"
                    })}
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  );
};

CreateContactDialog.propTypes = {
  trigger: PropTypes.node,
  isOpened: PropTypes.bool,
  onContactCreated: PropTypes.func,
  contactModalType: PropTypes.string,
  onDialogClose: PropTypes.func,
  activity: PropTypes.shape({
    projectId: PropTypes.number,
    activityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  model: PropTypes.shape({
    id: PropTypes.number,
    salutation: PropTypes.string,
    title: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    address_postcode: PropTypes.string,
    address_number: PropTypes.string,
    address_street: PropTypes.string,
    address_town: PropTypes.string,
    deletable: PropTypes.bool
  })
};

CreateContactDialog.defaultProps = {
  activity: null,
  model: null,
  isOpened: false,
  onContactCreated: null,
  contactModalType: null,
  onDialogClose: null,
  trigger: null
};

export default CreateContactDialog;
