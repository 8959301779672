import React from "react";
import { Segment, Button, Loader, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import ProjectSetupDataLoader, {
  ProjectSetupDataContext
} from "./ProjectSetupDataContext";
import UnitsSegment from "./UnitsSegment";
import ProjectCatalogsSegment from "./ProjectCatalogsSegment";
import RoomBookSegment from "./RoomBookSegment";
import UnitFeaturesSegment from "./UnitFeaturesSegment";
import UnitVariableSegment from "./UnitVariableSegment";

const SegmentList = () => {
  const { projectSetup, isLoading, handleUplaodCompleted } = React.useContext(
    ProjectSetupDataContext
  );

  if (!projectSetup) return <Loader active />;

  const {
    step_project_catalog_completed_at,
    step_units_completed_at,
    step_room_books_completed_at,
    step_unit_features_completed_at,
    step_unit_variables_completed_at
  } = projectSetup;

  const isCompleted =
    step_project_catalog_completed_at &&
    step_units_completed_at &&
    step_room_books_completed_at &&
    step_unit_features_completed_at &&
    step_unit_variables_completed_at;

  return (
    <Segment.Group>
      <Header as="h3" attached="top">
        <FormattedMessage id="project.project_setup.tabs.data_providing" />
      </Header>
      <ProjectCatalogsSegment />
      <UnitsSegment disabled={!step_project_catalog_completed_at} />
      <RoomBookSegment disabled={!step_project_catalog_completed_at} />
      <UnitFeaturesSegment
        disabled={!(step_room_books_completed_at && step_units_completed_at)}
      />
      <UnitVariableSegment
        disabled={!(step_room_books_completed_at && step_units_completed_at)}
      />
      <Segment attached textAlign="right">
        <Button
          primary
          loading={isLoading}
          disabled={!isCompleted}
          onClick={() => handleUplaodCompleted("")}
        >
          <FormattedMessage id="meta.actions.complete" default="Abschließen" />
        </Button>
      </Segment>
    </Segment.Group>
  );
};

const DataTab = () => {
  return (
    <ProjectSetupDataLoader>
      <SegmentList />
    </ProjectSetupDataLoader>
  );
};

export default DataTab;
