import { shape, number, string, oneOf, bool, arrayOf } from "prop-types";

export const AttachmentShape = shape({
  access_rights: arrayOf(oneOf(["PUBLIC", "BUYER", "CONTRACTOR"])),
  attachment_url: string,
  created_at: string,
  deletable: bool,
  display_name: string,
  featured: bool,
  file_name: string,
  id: number,
  message_attachments_count: number,
  references_count: number,
  role: string,
  updated_at: string
});

export default AttachmentShape;
