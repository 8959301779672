import React from "react";
import PropTypes from "prop-types";
import {
  flatten,
  isEmpty,
  values,
  get,
  isArray,
  isString,
  isObject
} from "lodash";
import { UPDATE_MESSAGE } from "shared/constants/appConstants";

const processErrors = errors => {
  if (isString(errors)) {
    return errors;
  }
  if (isArray(errors)) {
    return errors;
  }
  return errors.map(err => {
    const [key] = Object.keys(err);
    if (key) {
      return `${key}: ${err[key]}`;
    }
    return err;
  });
};

const FormattedErrorReason = ({ errors }) => {
  return (
    <ul>
      {errors.map(error => {
        let errorText = error;
        if (isObject(error)) {
          const key = Object.keys(error)[0];
          errorText = `${error[key]} (${key})`;
        }
        return <li key={error}>{errorText}</li>;
      })}
    </ul>
  );
};

FormattedErrorReason.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const getHandleDeleteFailed = dispatch => response => {
  const errors = flatten(values(get(response, "data", {})), true);
  if (!isEmpty(errors)) {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        type: "error",
        title: "resources.error.delete.title",
        body: "resources.error.delete.body",
        bodyValues: {
          reason: <FormattedErrorReason errors={errors} />
        }
      }
    });
  } else {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        type: "error",
        title: "message.errorDelete.title",
        body: "message.errorDelete.body"
      }
    });
  }
  throw response;
};

export const getHandleUpdateFailed = dispatch => response => {
  const errors = flatten(values(get(response, "data", {})), true);
  // if errors consists of objects i.e.: {identifier: ['ist bereits vergeben']}
  const arrayOfValuesErrors = processErrors(errors);

  if (!isEmpty(arrayOfValuesErrors)) {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        type: "error",
        title: "resources.error.update.title",
        body: "resources.error.update.body",
        bodyValues: {
          reason: <FormattedErrorReason errors={arrayOfValuesErrors} />
        }
      }
    });
  } else {
    dispatch({
      type: UPDATE_MESSAGE,
      payload: {
        type: "error",
        title: "message.errorSave.title",
        body: "message.errorSave.body"
      }
    });
  }
  throw response;
};
