import React, { useCallback, useState } from "react";
import { Container, Grid, Modal, Header } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { Input, Form } from "formik-semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { lowerFirst } from "lodash";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import ActionButtons from "./ActionButtons";

const VideoCardElement = ({
  button,
  onChange,
  initialValue,
  validationSchema
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = resetForm => {
    resetForm();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onSubmit = useCallback(values => {
    onChange(values);
    handleClose();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({ submitForm, resetForm }) => (
        <Modal
          open={isOpen}
          onOpen={() => handleOpen(resetForm)}
          onClose={handleClose}
          trigger={button}
          closeIcon
        >
          <Modal.Header>
            <FormattedMessage
              id={`configuratorDesign.dashboard.${lowerFirst(
                initialValue.type
              )}.type`}
            />
          </Modal.Header>
          <Modal.Content>
            <Container style={{ width: "100%" }}>
              <Form id="okDashboardVideoCard">
                <Grid style={{ width: "100%" }}>
                  <Grid.Column width={16}>
                    <Input
                      fluid
                      name="params.title"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.title"
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Input
                      fluid
                      name="params.url"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.url"
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Header as="h4" style={{ marginBottom: 10 }}>
                      {useIntl().formatMessage({
                        id:
                          "configuratorDesign.dialog.contentPageEditor.presentation"
                      })}
                    </Header>
                    <Field
                      id="css"
                      name="css"
                      label={useIntl().formatMessage({
                        id: "configuratorDesign.dialog.contentPageEditor.css"
                      })}
                      component={SemanticFormikJsonEditor}
                    />
                  </Grid.Column>
                </Grid>
              </Form>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <ActionButtons onClose={handleClose} onSubmit={submitForm} />
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};
VideoCardElement.propTypes = {
  button: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  }),
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired
};

VideoCardElement.defaultProps = {
  initialValue: {
    params: {
      title: "",
      url: ""
    }
  }
};

export default VideoCardElement;
