import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ProjectRoomsResource from "../../actions/projectRoomsActions";

const ProjectRoomsLoader = ({ projectId, children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      new ProjectRoomsResource(dispatch, projectId)
        .fetchAll({})
        .then(() => setLoading(false));
    }
  }, [projectId]);

  if (typeof children === "function") {
    const content = children(loading);
    return content || null;
  }
  return children;
};

ProjectRoomsLoader.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

ProjectRoomsLoader.defaultProps = {
  children: () => {}
};

export default ProjectRoomsLoader;
