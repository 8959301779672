/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Icon } from "semantic-ui-react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const ReferencesLink = ({ id, type, size, className, style }) => {
  const intl = useIntl();

  const handleClick = () => {
    window.open(`/references?subject_type=${type}&subject_id=${id}`, "_blank");
  };

  return (
    <a
      role="button"
      className={className}
      data-tooltip={intl.formatMessage({ id: "references.tooltip" })}
    >
      <Icon
        name="list layout"
        size={size}
        style={style}
        color="grey"
        onClick={handleClick}
      />
    </a>
  );
};

ReferencesLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object
};

ReferencesLink.defaultProps = {
  size: "large",
  className: "",
  style: {}
};

export default ReferencesLink;
