import { number, oneOfType, shape, string } from "prop-types";

export const ContractorShape = shape({
  id: oneOfType([string, number]).isRequired,
  contact_person: string.isRequired,
  email: string.isRequired,
  salutation: string,
  title: string
});

export default ContractorShape;
