/* eslint-disable import/prefer-default-export */
import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export class AccountImagesResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "image", `/api/v1/account/images`, "images");
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }

  fetchAll() {
    return apiRequest
      .get(`${this.url}?role=account_image`, this.dispatch)
      .then(this.propagateUpdate);
  }
}
