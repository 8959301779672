import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import { FormattedMessage } from "react-intl";

// first_name
const FormFactory = new FormDefinition({
  fields: [
    {
      id: "section_id",
      label: "unit.attributes.section.label"
    },
    {
      id: "unit_numbers",
      label: "unit.attributes.unitNumbers.label",
      placeholder: "unit.attributes.unitNumbers.placeholder"
    }
  ]
});

class UnitsForm extends Component {
  static propTypes = {
    model: PropTypes.object,
    sectionOptions: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    i18n: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = { model: props.model };
  }

  render() {
    const { onChange } = this.props;
    const form = FormFactory.create(this.state.model, this.props.i18n, {
      onChange: data => {
        this.setState({ model: data });
        onChange(data);
      }
    });
    form.fields.section_id.props.options = this.props.sectionOptions;
    return (
      <Form
        id="units"
        onSubmit={form.handleSubmit(this.onSubmit)}
        data-component="unitsForm"
      >
        <Form.Field>
          <Field component="Select" {...form.fields.section_id} />
        </Form.Field>

        <Form.Field>
          <Field component="TextArea" {...form.fields.unit_numbers} />
        </Form.Field>

        <Message
          info
          icon="info"
          content={
            <FormattedMessage
              id="unit.attributes.unitNumbers.explanation"
              defaultMessage="unit.attributes.unitNumbers.explanation"
            />
          }
        />
      </Form>
    );
  }
}

export default UnitsForm;
