import React from "react";
import { connect } from "react-redux";

import { Modal, Dropdown, Button } from "semantic-ui-react";

import { get } from "lodash";
import { withRouter } from "../../../../shared/helpers/withRouter";

class DownloadQuantitiesDialog extends React.Component {
  state = {
    open: false,
    productGroups: []
  };

  render = () => {
    const { productGroups, open } = this.state;
    const { productGroupOptions } = this.props;
    const downloadable = !!productGroups.length;

    return (
      <Modal
        open={open}
        size="small"
        trigger={this.props.trigger}
        closeIcon
        closeOnEscape
        closeOnDimmerClick={false}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Mengenübersicht</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder="Produktgruppen"
            fluid
            multiple
            search
            selection
            options={productGroupOptions}
            closeOnChange
            value={productGroups}
            onChange={this.handleSelectProductGroup}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            as="a"
            href={this.getReportUrl()}
            content="Download"
            color="green"
            basic={!downloadable}
            disabled={!downloadable}
            onClick={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  handleOpen = () => {
    this.setState({ open: true, productGroups: [] });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSelectProductGroup = (_, { value }) => {
    this.setState({ productGroups: value });
  };

  getReportUrl = () => {
    const { projectId } = this.props;
    const { productGroups } = this.state;
    const query = productGroups.map(pg => `product_group_id[]=${pg}`).join("&");

    return `/api/v1/projects/${projectId}/reports/quantities.xlsx?${query}`;
  };
}

const mapStateToProps = (state, props) => {
  const { projectId } = props.params;
  const productGroups = get(state, ["pageContent", "product_groups"], []);
  return {
    projectId,
    productGroupOptions: productGroups.map(pg => {
      return {
        key: pg.id,
        text: pg.name,
        value: pg.id
      };
    })
  };
};

export default withRouter(connect(mapStateToProps)(DownloadQuantitiesDialog));
