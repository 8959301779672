import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { func, shape, string, number, node, object } from "prop-types";
import { Formik, Field } from "formik";
import { Form, Input } from "formik-semantic-ui-react";
import * as Yup from "yup";
import { Modal, Menu, Button } from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import SemanticFormikJsonEditor from "shared/components/forms/SemanticFormikJsonEditor";
import { tabs } from "./signaturePartials/constants";
import BlocksEditor from "./signaturePartials/BlocksEditor";

const SignatureEditor = ({ button, originalSignature, onSubmit }) => {
  const [isOpen, setOpen] = useState(false);
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState(tabs.Visual);

  const handleSubmit = vals => {
    onSubmit(vals)
      .then(() => {
        setOpen(false);
      })
      .catch(() => {
        setOpen(false);
      });
  };

  const handleClose = event => {
    event.preventDefault();
    // cleanup the form
    setOpen(false);
  };

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" }))
  });

  return (
    <Modal
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      trigger={button}
      data-component="SignatureEditor"
    >
      <Modal.Header>
        <FormattedMessage
          id={`documentsExport.dialogs.editSignature.title.${
            originalSignature ? "edit" : "create"
          }`}
        />
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={originalSignature}
          validationSchema={validationScheme}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setValues }) => {
            return (
              <Form>
                <Input
                  id="name"
                  errorPrompt
                  name="name"
                  label={intl.formatMessage({
                    id: "documentsExport.dialogs.editSignature.name"
                  })}
                />

                <Menu data-component="documentsExportMenu">
                  <Menu.Item
                    onClick={() => setCurrentTab(tabs.Visual)}
                    active={currentTab === tabs.Visual}
                  >
                    <FormattedMessage id="documentsExport.dialogs.editSignature.menu.visual" />
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setCurrentTab(tabs.Json)}
                    active={currentTab === tabs.Json}
                  >
                    <FormattedMessage id="documentsExport.dialogs.editSignature.menu.json" />
                  </Menu.Item>
                </Menu>

                <If condition={currentTab === tabs.Visual}>
                  <BlocksEditor
                    blocks={values?.config_content?.blocks || []}
                    onChange={blocks => {
                      const v = {
                        ...values,
                        config_content: { ...values?.config_content, blocks }
                      };
                      setValues(v);
                    }}
                  />
                </If>

                <If condition={currentTab === tabs.Json}>
                  <Field
                    name="config_content"
                    label="JSON"
                    component={SemanticFormikJsonEditor}
                  />
                </If>

                <Modal.Actions style={{ float: "right", margin: "10px 0" }}>
                  <Button
                    basic
                    id="cancel"
                    content={intl.formatMessage({ id: "meta.actions.cancel" })}
                    onClick={handleClose}
                    loading={isSubmitting}
                  />
                  <Button
                    primary
                    type="submit"
                    content={
                      originalSignature
                        ? intl.formatMessage({ id: "meta.actions.save" })
                        : intl.formatMessage({ id: "meta.actions.add" })
                    }
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Modal.Actions>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

SignatureEditor.propTypes = {
  onSubmit: func.isRequired,
  originalSignature: shape({
    id: number,
    name: string,
    // eslint-disable-next-line react/forbid-prop-types
    config_content: object
  }),
  button: node.isRequired
};

SignatureEditor.defaultProps = {
  originalSignature: { name: "", config_content: "" }
};

export default SignatureEditor;
