import React, { useMemo, useState } from "react";
import { shape, string, number, func } from "prop-types";
import {
  Input,
  TextArea,
  Form,
  Dropdown,
  Button,
  Icon,
  Divider,
  Header,
  Modal,
  Label
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";

const TextElementForm = ({ element, onChange }) => {
  const handleChange = (elementId, { name, value }) => {
    onChange({ ...element, [name]: value });
  };

  const intl = useIntl();

  const [conditionModalOpen, setConditionModalOpen] = useState(false);
  const [conditionModalKeyInput, setConditionModalKeyInput] = useState("ctx_");
  const [conditionKeyTaken, setConditionKeyTaken] = useState(false);

  const conditions = useMemo(() => element?.conditions || {}, [element]);

  const conditionOptions = [
    {
      text: intl.formatMessage({
        id:
          "documentsExport.dialogs.editSignature.conditions.options.mustBePresent",
        defaultMessage: "Must be Present"
      }),
      value: true
    },
    {
      text: intl.formatMessage({
        id:
          "documentsExport.dialogs.editSignature.conditions.options.mustNotBePresent",
        defaultMessage: "Must not be Present"
      }),
      value: false
    }
  ];

  const handleConditionModalOpen = e => {
    e.preventDefault();
    setConditionModalOpen(true);
  };

  const handleConditionModalClose = () => {
    setConditionModalOpen(false);
    setConditionKeyTaken(false);
    setConditionModalKeyInput("ctx_");
  };

  const handleChangeConditionValue = (elementId, val, key) => {
    const c = element.conditions || {};
    c[key] = val;
    handleChange(elementId, { name: "conditions", value: c });
  };

  const handleDeleteCondition = (e, elementId, key) => {
    const c = element.conditions || {};
    delete c[key];
    handleChange(elementId, { name: "conditions", value: c });
  };

  const handleAddCondition = (e, elementId) => {
    handleChangeConditionValue(elementId, true, conditionModalKeyInput);
    setConditionModalOpen(false);
    setConditionModalKeyInput("ctx_");
  };

  return (
    <>
      <Form.Field>
        <label htmlFor="value">
          <FormattedMessage
            id="documentsExport.dialogs.editSignature.value"
            defaultMessage="Text"
          />
        </label>
        <TextArea
          name="value"
          type="text"
          value={element.value}
          onChange={(_, vals) => handleChange(element.id, vals)}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="new_line">
          <FormattedMessage
            id="documentsExport.dialogs.editSignature.newLine"
            defaultMessage="New Line"
          />
        </label>
        <Input
          name="new_line"
          type="number"
          value={element.new_line}
          onChange={(_, vals) => handleChange(element.id, vals)}
        />
      </Form.Field>
      <Form.Field>
        <Header as="label">
          <FormattedMessage
            id="documentsExport.dialogs.editSignature.conditions.title"
            defaultMessage="Conditions"
          />
        </Header>
        {Object.entries(conditions).map(condition => {
          const [key, value] = condition;
          return (
            <div
              key={key}
              style={{
                marginBottom: "1em",
                width: "100%",
                padding: "0.5em",
                backgroundColor: "rgb(235 235 235)",
                display: "inline-flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginRight: "1em",
                borderRadius: "0.4em",
                position: "relative",
                height: "3.5em"
              }}
            >
              <span
                style={{
                  width: "6em",
                  textAlign: "center",
                  fontWeight: "bold"
                }}
              >
                {key}
              </span>
              <Icon name="arrow right" />
              <span>
                <Dropdown
                  name="condition_value"
                  onChange={(_, { value: val }) =>
                    handleChangeConditionValue(element.id, val, key)
                  }
                  defaultValue={value}
                  options={conditionOptions}
                  style={{ width: "13em" }}
                />
              </span>
              <Button
                style={{
                  marginLeft: "2em",
                  position: "absolute",
                  right: "0.5em"
                }}
                icon="trash"
                size="small"
                negative
                onClick={e => handleDeleteCondition(e, element.id, key)}
              />
            </div>
          );
        })}
        <Modal
          open={conditionModalOpen}
          onOpen={e => handleConditionModalOpen(e)}
          onClose={handleConditionModalClose}
          centered
          closeOnEscape
          closeOnDimmerClick
          trigger={
            <Button fluid basic>
              <Icon name="plus" />
              <FormattedMessage
                id="documentsExport.dialogs.editSignature.conditions.add"
                defaultMessage="add Condition"
              />
            </Button>
          }
        >
          <Modal.Header>
            <FormattedMessage id="documentsExport.dialogs.editSignature.conditions.modal.enterKey" />
          </Modal.Header>
          <Modal.Content>
            <Input
              style={{ display: "block" }}
              value={conditionModalKeyInput}
              onChange={(_, { value }) => setConditionModalKeyInput(value)}
            />
            {conditionKeyTaken ? (
              <Label basic color="red" pointing>
                <FormattedMessage id="documentsExport.dialogs.editSignature.conditions.modal.keyTaken" />
              </Label>
            ) : null}
          </Modal.Content>
          <Modal.Actions>
            <Button basic onClick={handleConditionModalClose}>
              <FormattedMessage id="meta.actions.cancel" />
            </Button>
            <Button primary onClick={e => handleAddCondition(e, element.id)}>
              <FormattedMessage id="meta.actions.add" defaultMessage="add" />
            </Button>
          </Modal.Actions>
        </Modal>
        <Divider />
      </Form.Field>
    </>
  );
};

TextElementForm.propTypes = {
  element: shape({
    id: string,
    template: number
  }).isRequired,
  onChange: func.isRequired
};

export default TextElementForm;
