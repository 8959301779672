import * as Sentry from "@sentry/react";

function createLocalStorage() {
  try {
    const localStorage = {
      get: key => window.localStorage.getItem(key),
      set: (key, value) => window.localStorage.setItem(key, value),
      remove: key => window.localStorage.removeItem(key),
      clear: () => window.localStorage.clear()
    };

    // Need to check if it is actually working,
    // Safari Private Mode e.g. sets quota to 0
    // and fails
    const x = `test-localstorage-${Date.now()}`;
    localStorage.set(x, x);
    const y = localStorage.get(x);
    localStorage.remove(x);

    if (y !== x) {
      throw new Error(
        "LocalStorage not available, reverting to MemoryStorage."
      );
    }

    return localStorage;
  } catch (e) {
    if (process.env.NODE_ENV !== "test" && Sentry) {
      Sentry.captureException(e);
    }

    if (!window.dataStorage) {
      window.dataStorage = {};
    }

    return {
      get: key => window.dataStorage[key],
      set: (key, value) => {
        window.dataStorage[key] = value;
        return window.dataStorage[key];
      },
      remove: key => {
        if (window.dataStorage[key]) {
          const value = window.dataStorage[key];
          delete window.dataStorage[key];
          return value;
        }
        return null;
      },
      clear: () => {
        return (window.dataStorage = {});
      }
    };
  }
}

const localStorage = createLocalStorage();

export default localStorage;
