import Resources from "../../shared/actions/resourcesActions";

export class ProjectAnalysisTopicsResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "project_analysis_topics",
      "/api/v1/project_analysis_topics"
    );
  }
}

export default ProjectAnalysisTopicsResource;
