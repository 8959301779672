import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export default class MemosResource extends Resources {
  constructor(dispatch, activityId) {
    super(dispatch, "memo", `/api/v1/activities/${activityId}/memos`);
  }

  // TODO: Move to Resources Class!!!
  delete(id) {
    return apiRequest.delete(`/api/v1/memos/${id}`, this.dispatch);
  }
}
