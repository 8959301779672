import React from "react";
import { connect } from "react-redux";
import { jumpTo } from "shared/helpers/scrollTo";
import { shape, string, func, number, object, array } from "prop-types";
import { getReplacementItems } from "builder_portal/selectors/roomBooks/optionalLineItems";
import { Responsive } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";
import CartSelector from "./CartSelector";
import LineItemActivatorButton from "./LineItemActivatorButton";

class LineItemStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { suggestionMinWidth } = this.props;

    return (
      <Responsive minWidth={suggestionMinWidth || 0}>
        {this.renderContent()}
      </Responsive>
    );
  }

  renderContent() {
    const {
      i18n,
      carts,
      lineItem,
      roomBook,
      account,
      toggleDialog,
      projectId
    } = this.props;

    if (lineItem.isReplaced()) {
      return this.renderReplaced();
    }
    if (
      lineItem.isActivated() &&
      account.hasProjectRoleRight(projectId, "allow_access_activities")
    ) {
      return (
        <CartSelector
          i18n={i18n}
          projectId={projectId}
          lineItem={lineItem}
          carts={carts}
          roomBook={roomBook}
          account={account}
          toggleDialog={toggleDialog}
        />
      );
    }
    if (lineItem.isCancelled()) {
      return null;
    }
    if (lineItem.isActivatable()) {
      return (
        <>
          <FormattedMessage tagName="p" id="cartAssignment.message.inactive" />
          <LineItemActivatorButton
            lineItem={lineItem}
            roomBookId={roomBook.id}
          />
        </>
      );
    }
    if (lineItem.isReplacement()) {
      return this.renderUnactivatableExplanation();
    }
    return null;
  }

  jumpToElement = id => {
    jumpTo(`[data-model="line_item"][data-id="${id}"]`);
  };

  renderReplaced() {
    const { replacementItem } = this.props;
    if (replacementItem) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={() => this.jumpToElement(replacementItem.id)}
          onKeyDown={() => this.jumpToElement(replacementItem.id)}
          style={{ cursor: "pointer" }}
        >
          <FormattedMessage
            id="cartAssignment.message.replaced"
            values={this.replacementTagValue()}
            tagName="p"
          />
        </div>
      );
    }
  }

  renderUnactivatableExplanation() {
    const { replacementItem } = this.props;
    if (replacementItem) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={() => this.jumpToElement(replacementItem.id)}
          onKeyDown={() => this.jumpToElement(replacementItem.id)}
          style={{ cursor: "pointer" }}
        >
          <FormattedMessage
            id="cartAssignment.message.unactivatable"
            values={this.replacementTagValue()}
            tagName="p"
          />
        </div>
      );
    }
  }

  replacementTagValue() {
    const { replacementItem } = this.props;
    return (
      replacementItem && {
        replacement: (
          <strong>
            {replacementItem.display_number}&nbsp;{replacementItem.title}
          </strong>
        )
      }
    );
  }
}

LineItemStatus.defaultProps = {
  replacementItem: null
};

LineItemStatus.propTypes = {
  i18n: object,
  account: object,
  roomBook: object,
  lineItem: object,
  toggleDialog: func,
  carts: array,
  suggestionMinWidth: number,
  replacementItem: shape({ display_number: string, title: string })
};

const mapStateToProps = (state, props) => {
  return {
    replacementItem: getReplacementItems(state)[props.lineItem.getId()]
  };
};

export default connect(mapStateToProps)(LineItemStatus);
