import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Grid, Header, Icon, Modal } from "semantic-ui-react";
import { func } from "prop-types";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import ProjectEmailsResource from "../../../actions/projectEmailsActions";
import Growl from "../../../actions/growlActions";
import ConfirmationDialog from "../../../../shared/components/dialogs/ConfirmationDialog";
import { If } from "../../../../shared/components/elements/Conditions";
import { IsSystemAdmin } from "../../../../shared/components/authorization/IsSystemAdmin";
import { ProjectEmailShape } from "../../../../shared/shapes/projectEmail.shape";

const EmailDialog = ({ model, loadData }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const CONFIRMATION_CHECK_INTERVAL = 10000;
  const MAX_REPETITIONS = 18;

  const afterPolling = () => {
    loadData();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new ProjectEmailsResource(dispatch),
    MAX_REPETITIONS,
    afterPolling,
    "project_email.inbound_confirmed",
    undefined,
    true,
    undefined,
    {
      errorTitle: "projectEmail.list.details.inbound.messageTitle",
      errorBody: "projectEmail.list.details.inbound.messageError",
      successTitle: "projectEmail.list.details.inbound.messageTitle",
      successBody: "projectEmail.list.details.inbound.messageSuccess"
    }
  );

  const [startAliasPolling, stopAliasPolling, isAliasPolling] = usePolling(
    new ProjectEmailsResource(dispatch),
    MAX_REPETITIONS,
    afterPolling,
    "project_email.alias_confirmed",
    undefined,
    true,
    undefined,
    {
      errorTitle: "projectEmail.list.details.alias.messageTitle",
      errorBody: "projectEmail.list.details.alias.messageError",
      successTitle: "projectEmail.list.details.alias.messageTitle",
      successBody: "projectEmail.list.details.alias.messageSuccess"
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    stopPolling();
    setOpen(false);
  };

  const onDelete = () => {
    new ProjectEmailsResource(dispatch).remove(model.id).then(() => {
      setLoading(false);
      setOpen(false);
      loadData();
      new Growl(dispatch).success(
        "message.success.title",
        "meta.states.deleted"
      );
    });
  };

  const renderDeleteButton = () => {
    const button = (
      <Button
        id="delete"
        color="red"
        basic
        data-form="process_type"
        disabled={loading || isPolling}
        content={intl.formatMessage({ id: "meta.actions.remove" })}
        className="left floated element"
      />
    );

    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: onDelete
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="projectEmail.messages.delete.title"
        message="projectEmail.messages.delete.message"
        buttons={buttons}
        button={button}
      />
    );
  };

  const resource = useMemo(() => new ProjectEmailsResource(dispatch), []);

  const checkOutbound = () => {
    setLoading(true);
    resource
      .outboundStatusCheck(model.id)
      .then(res => {
        if (res.data?.project_email?.outbound_confirmed) {
          loadData();
          setLoading(false);
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
        } else {
          setLoading(false);
          new Growl(dispatch).error(
            "message.error.title",
            "projectEmail.list.details.outbound.error"
          );
        }
      })
      .catch(() => {
        new Growl(dispatch).error(
          "message.error.title",
          "projectEmail.list.details.outbound.error"
        );
      });
  };

  const checkInbound = () => {
    resource
      .inboundStatusCheck(model.id)
      .then(() => {
        startPolling(model.id, CONFIRMATION_CHECK_INTERVAL);
      })
      .catch(() => {
        stopPolling();
        new Growl(dispatch).error(
          "projectEmail.list.details.inbound.messageTitle",
          "projectEmail.list.details.inbound.messageError"
        );
      });
  };

  const checkAlias = () => {
    resource
      .aliasStatusCheck(model.id)
      .then(() => {
        startAliasPolling(model.id, CONFIRMATION_CHECK_INTERVAL);
      })
      .catch(() => {
        stopAliasPolling();
        new Growl(dispatch).error(
          "projectEmail.list.details.alias.messageTitle",
          "projectEmail.list.details.alias.messageError"
        );
      });
  };

  const handleActivation = useCallback(() => {
    setLoading(true);
    resource
      .activate(model.id)
      .then(() => {
        return resource.fetchAll().then(() => {
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
          setLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
        new Growl(dispatch).error(
          "message.error.title",
          "projectEmail.list.details.inbound.error"
        );
      });
  }, [model, resource]);

  const activationStatus = useMemo(() => {
    const { active, inbound_confirmed, outbound_confirmed } = model;
    if (!outbound_confirmed || !inbound_confirmed) {
      return {
        icon: {
          name: "hourglass half",
          color: "orange"
        },
        message: "projectEmail.list.status.pending"
      };
    }
    if (active) {
      return {
        icon: {
          name: "check",
          color: "green"
        },
        message: "projectEmail.list.status.active"
      };
    }
    return {
      icon: {
        name: "ban",
        color: "grey"
      },
      message: "projectEmail.list.status.deactivated"
    };
  }, [model]);

  return (
    <>
      <Modal
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        trigger={
          <Button basic>
            <Icon
              name={activationStatus.icon.name}
              color={activationStatus.icon.color}
            />
            <FormattedMessage id={activationStatus.message} />
          </Button>
        }
      >
        <Modal.Header>
          <Header as="h3">Status: {model.email}</Header>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h5">
                  <If condition={model.outbound_confirmed}>
                    <Icon name="check circle outline" color="green" />
                  </If>
                  <If condition={!model.outbound_confirmed}>
                    <Icon name="circle outline" color="grey" />
                  </If>
                  <FormattedMessage id="projectEmail.list.details.outbound.message" />
                </Header>
                <If condition={!model.outbound_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.outbound.pending" />
                </If>
                <If condition={model.outbound_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.outbound.confirmed" />
                </If>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <Button basic onClick={checkOutbound} loading={loading}>
                  <If condition={model.outbound_confirmed}>
                    <FormattedMessage id="meta.actions.recheck" />
                  </If>
                  <If condition={!model.outbound_confirmed}>
                    <FormattedMessage id="meta.actions.check" />
                  </If>
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h5">
                  <If condition={model.alias_confirmed}>
                    <Icon name="check circle outline" color="green" />
                  </If>
                  <If condition={!model.alias_confirmed}>
                    <Icon name="circle outline" color="grey" />
                  </If>
                  <FormattedMessage id="projectEmail.list.details.alias.message" />
                </Header>
                <If condition={!model.alias_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.alias.pending" />
                </If>
                <If condition={model.alias_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.alias.confirmed" />
                </If>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <Button basic onClick={checkAlias} disabled={isAliasPolling}>
                  <If condition={model.alias_confirmed}>
                    <FormattedMessage id="meta.actions.recheck" />
                  </If>
                  <If condition={!model.alias_confirmed}>
                    <FormattedMessage id="meta.actions.check" />
                  </If>
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header as="h5">
                  <If condition={model.inbound_confirmed}>
                    <Icon name="check circle outline" color="green" />
                  </If>
                  <If condition={!model.inbound_confirmed}>
                    <Icon name="circle outline" color="grey" />
                  </If>
                  <FormattedMessage id="projectEmail.list.details.inbound.message" />
                </Header>
                <If condition={!model.inbound_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.inbound.pending" />
                </If>
                <If condition={model.inbound_confirmed}>
                  <FormattedMessage id="projectEmail.list.details.inbound.confirmed" />
                </If>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <Button basic onClick={checkInbound} disabled={isPolling}>
                  <If condition={model.inbound_confirmed}>
                    <FormattedMessage id="meta.actions.recheck" />
                  </If>
                  <If condition={!model.inbound_confirmed}>
                    <FormattedMessage id="meta.actions.check" />
                  </If>
                </Button>
              </Grid.Column>
            </Grid.Row>
            <If
              condition={
                !model.active &&
                model.inbound_confirmed &&
                model.outbound_confirmed
              }
            >
              <Grid.Row>
                <Grid.Column width={12}>
                  <Header as="h5">
                    <Icon name="circle outline" color="grey" />
                    E-Mail Adresse aktivieren
                  </Header>
                  <p>
                    Die E-Mail Adresse ist vollständig eingerichtet. Sie kann
                    nun für die Verwendung in diesem Projekt aktiviert werden.
                  </p>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Button basic onClick={handleActivation} loading={loading}>
                    <FormattedMessage id="meta.actions.activate" />
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </If>
            <If
              condition={
                model.active &&
                model.inbound_confirmed &&
                model.outbound_confirmed
              }
            >
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h5">
                    <Icon name="check circle outline" color="green" />
                    E-Mail Adresse aktiviert
                  </Header>
                  <p>
                    Die E-Mail Adresse ist vollständig eingerichtet und für das
                    Projekt aktiviert.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </If>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={4} textAlign="left" style={{ display: "flex" }}>
              <IsSystemAdmin>{renderDeleteButton()}</IsSystemAdmin>
            </Grid.Column>
            <Grid.Column width={12} textAlign="right">
              <Button onClick={handleClose} basic>
                <FormattedMessage id="meta.actions.close" />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
      <TinyLoader opened={isPolling || isAliasPolling} />
    </>
  );
};

EmailDialog.propTypes = {
  model: ProjectEmailShape.isRequired,
  loadData: func.isRequired
};

export default EmailDialog;
