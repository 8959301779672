import { func, instanceOf, shape } from "prop-types";
import { ProductResource } from "../../builder_portal/actions/productActions";
import ProductGroupResource, {
  ProductPriceResource
} from "../../builder_portal/actions/productGroupActions";
import ProductTypeResource from "../../builder_portal/actions/productTypeActions";

export const ProductGroupResourcesShape = shape({
  products: instanceOf(ProductResource),
  prices: instanceOf(ProductPriceResource),
  pricesFactory: func, // returns instanceOf(ProductPriceResource)
  groups: instanceOf(ProductGroupResource),
  productTypes: instanceOf(ProductTypeResource)
});

export default ProductGroupResourcesShape;
