export const getTagNames = (tags, taxonomies) => {
  const allTags = taxonomies.reduce((accu, taxonomy) => {
    return accu.concat(
      taxonomy.tags.map(tag => {
        return {
          name: tag.name,
          composite_id: tag.composite_id
        };
      })
    );
  }, []);

  return allTags
    .filter(tag => {
      return tags.includes(tag.composite_id);
    })
    .map(tag => {
      return tag.name;
    });
};
