import { get, cloneDeep, omit, keyBy, difference } from "lodash";
import SubLineItemModel from "./SubLineItemModel";
import { shallowRemoveIdPropertiesFromCollection } from "./lineItemHelpers";

export default class SubLineItemFormModel extends SubLineItemModel {
  constructor(initialModel, currentModel, products, isTemplate) {
    super(currentModel, products, isTemplate);
    this.initialModel = initialModel;
  }

  getInitialValue(field) {
    const om = this.initialModel;
    return get(om, field);
  }

  isFieldDirty(field) {
    const om = this.initialModel;
    const cm = this.currentModel;

    return get(om, field) !== get(cm, field);
  }

  isDirty() {
    const om = this.initialModel;
    const cm = this.currentModel;

    if (om.title !== cm.title) return true;
    if (om.product_id !== cm.product_id) return true;
    if (om.discount !== cm.discount) return true;
    if (om.quantity !== cm.quantity) return true;
    if (om.default_product_id !== cm.default_product_id) return true;
    if (om.default_quantity !== cm.default_quantity) return true;
    if (om.price !== cm.price) return true;
    if (om.default_price !== cm.default_price) return true;
    if (om.selection_is_confirmed !== cm.selection_is_confirmed) return true;
    if (this.compareCosts(om.costs_attributes, cm.costs_attributes)) {
      return true;
    }
    if (
      this.compareCosts(
        om.default_costs_attributes,
        cm.default_costs_attributes
      )
    ) {
      return true;
    }
    return false;
  }

  getProductById(id) {
    return this.products.filter(prod => {
      return prod.id === id;
    })[0];
  }

  /*
   * COMMANDS
   */

  setCustomCostsAndPricesConfirmed(value) {
    return this.update({
      custom_costs_and_prices_confirmed: value
    });
  }

  changeProduct(productId, description = null) {
    if (this.isTemplate) {
      return this.changeDefaultProduct(productId);
    }
    let selectedProduct;
    if (productId) {
      // standard product
      selectedProduct = this.getProductById(productId);
    } else {
      // individual product
      selectedProduct = {
        id: null,
        group: {
          price: 0,
          excess_price: null,
          costs_attributes: this.getTrades().map(trade => {
            return {
              id: null,
              cost: 0,
              trade
            };
          })
        }
      };
    }

    // confirmed change of cost or no change of cost has to be applied:
    // change product, change costs
    return this.update({
      product_id: productId,
      description,
      price: selectedProduct.group.price,
      excess_price: selectedProduct.group.excess_price,
      costs_attributes: shallowRemoveIdPropertiesFromCollection(
        selectedProduct.group.costs_attributes
      ),
      // set if productId changed, toggle id stayed the same
      selection_is_confirmed:
        productId !== this.currentModel.product_id ||
        !this.currentModel.selection_is_confirmed
    });
  }

  changeDefaultProduct(productId) {
    const selectedProduct = this.getProductById(productId);

    if (this.isTemplate) {
      return this.update({
        product_id: productId, // same as default_product_id in templates
        default_product_id: productId,
        price: selectedProduct.group.price,
        default_price: selectedProduct.group.price,
        costs_attributes: shallowRemoveIdPropertiesFromCollection(
          selectedProduct.group.costs_attributes
        ),
        default_costs_attributes: shallowRemoveIdPropertiesFromCollection(
          selectedProduct.group.costs_attributes
        )
      });
    }
    return this.update({
      default_product_id: productId,
      default_price: selectedProduct.group.price,
      default_costs_attributes: shallowRemoveIdPropertiesFromCollection(
        selectedProduct.group.costs_attributes
      )
      // don't update selection status as the default Product was changed
    });
  }

  applyBuyerSelection() {
    return this.changeProduct(
      this.getDesiredProductId(),
      this.getCustomProductDescription()
    ).update({
      quantity: this.getDesiredQuantity()
    });
  }

  applyUnitVariableType(id) {
    return this.update({ unit_variable_type_id: id });
  }

  update(values) {
    const cm = { ...cloneDeep(this.currentModel), ...values };
    const nm = new SubLineItemFormModel(
      this.initialModel,
      cm,
      this.products,
      this.isTemplate
    );
    nm.currentModel.total = nm.getTotal();
    return nm;
  }

  fixWarning(warningId) {
    switch (warningId) {
      case "WID_STALE_PRICE":
        return this.update({
          price: this.getProductCatalogPrice()
        });
      case "WID_STALE_DEFAULT_PRICE":
        return this.update({
          default_price: this.getDefaultProductCatalogPrice()
        });
      case "WID_FORCE_UNIT_VARIABLE_QUANTITY_SYNC":
        return this.update({
          quantity: this.currentModel.unit_variable_value
        });
      case "WID_FORCE_UNIT_VARIABLE_DEFAULT_QUANTITY_SYNC":
        return this.update({
          default_quantity: this.currentModel.unit_variable_value
        });
      case "WID_STALE_EXCESS_PRICE":
        return this.update({
          excess_price: this.getProductCatalogExcessPrice()
        });
      case "WID_STALE_COSTS": {
        const newCosts = this.applyCosts(
          this.currentModel.costs_attributes,
          this.getProductCatalogCosts()
        );
        return this.update({
          costs_attributes: newCosts
        });
      }
      case "WID_STALE_DEFAULT_COSTS": {
        const newDefaultCosts = this.applyCosts(
          this.currentModel.default_costs_attributes,
          this.getDefaultProductCatalogCosts()
        );
        return this.update({
          default_costs_attributes: newDefaultCosts
        });
      }
      default:
        return this;
    }
  }

  /*
   * Utilities
   */

  applyCosts(existingCosts, updatedCosts) {
    const keyedEC = keyBy(existingCosts, "trade");
    const keyedUC = keyBy(updatedCosts, "trade");
    const newTrades = difference(Object.keys(keyedUC), Object.keys(keyedEC));

    return existingCosts
      .map(c => {
        if (keyedUC[c.trade]) {
          return {
            ...omit(c, ["_destroy"]),
            cost: keyedUC[c.trade].cost,
            excess_cost: keyedUC[c.trade].excess_cost
          };
        }
        return { ...c, _destroy: 1 };
      })
      .concat(
        newTrades.map(trade => {
          return omit(keyedUC[trade], ["id"]);
        })
      );
  }
}
