import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Menu, Dropdown, Popup } from "semantic-ui-react";
import { useIntl } from "react-intl";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { If } from "shared/components/elements/Conditions";
import { number, string, shape, func, object, bool } from "prop-types";

const StickyMenu = ({
  stickyItem,
  allItems,
  accountId,
  onSelectedItemChange,
  storeKey,
  featureToggleName,
  selectedItem,
  showPopup
}) => {
  if (!accountId) return null;
  const MAX_VISIBLE_ITEMS = useMemo(() => (stickyItem ? 2 : 3), []);

  const intl = useIntl();
  const storedIds = useMemo(() => {
    return JSON.parse(localStorage.getItem(storeKey)) || {};
  }, []);
  const [currentItem, setCurrentItem] = useState();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [recentItemIds, setRecentItemIds] = useState(
    storedIds[accountId] || []
  );

  useEffect(() => {
    if (!currentItem) {
      setCurrentItem(
        selectedItem ||
          stickyItem ||
          allItems[recentItemIds[0]] ||
          allItems[Object.keys(allItems)[0]]
      );
    }
  }, [allItems]);

  useEffect(() => {
    if (!accountId || recentItemIds.length === 0) return;
    if (storeKey) {
      localStorage.setItem(
        storeKey,
        JSON.stringify({
          ...storedIds,
          [accountId]: recentItemIds
        })
      );
    }
  }, [recentItemIds, accountId]);

  useEffect(() => {
    if (!!Object.keys(allItems).length && !!accountId) {
      if (recentItemIds.length === 0) {
        const [firstItemId, secondItemId, thirdItem] = Object.keys(allItems);
        const ids = secondItemId ? [firstItemId, secondItemId] : [firstItemId];
        if (MAX_VISIBLE_ITEMS === 3 && thirdItem) ids.push(thirdItem);
        setRecentItemIds(ids);
      }
    }
  }, [allItems, accountId]);

  const options = useMemo(() => {
    return Object.keys(allItems).map(key => {
      return {
        key: allItems[key].id,
        value: allItems[key].id,
        text: allItems[key].name,
        icon: "building"
      };
    });
  }, [allItems]);

  const handleChange = item => {
    setCurrentItem(item);
  };

  const renderItemsList = useCallback(() => {
    if (!Object.keys(allItems).length) return <></>;
    return (
      <>
        {recentItemIds.map(itemId => {
          const item = allItems[itemId];

          if (!item) {
            setRecentItemIds([]);
            return null;
          }

          return (
            <Menu.Item
              name={item.name}
              onClick={() => {
                handleChange(item);
                onSelectedItemChange(item);
              }}
              active={
                currentItem?.slug
                  ? currentItem?.slug === item?.slug
                  : currentItem?.name === item?.name
              }
              key={item.id}
            >
              <If condition={showPopup}>
                <Popup
                  content={item.name}
                  trigger={
                    <div
                      style={{
                        width: "80px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {item.name}
                    </div>
                  }
                />
              </If>
              <If condition={!showPopup}>{item.name}</If>
            </Menu.Item>
          );
        })}
        <Menu.Menu position="right">
          <Dropdown
            item
            search
            scrolling
            onClick={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            onChange={(_, { value }) => {
              const strValue = value.toString();
              setCurrentItem(allItems[strValue]);
              setDropdownOpen(false);
              onSelectedItemChange(allItems[strValue]);

              if (recentItemIds.indexOf(strValue) === -1) {
                recentItemIds.unshift(strValue);
                setRecentItemIds(recentItemIds.slice(0, MAX_VISIBLE_ITEMS));
              }
            }}
            icon="search"
            open={dropdownOpen}
            text={intl.formatMessage({
              id: "featureToggles.projectChoose"
            })}
            value={currentItem?.id}
            options={options}
          />
        </Menu.Menu>
      </>
    );
  }, [allItems, currentItem, dropdownOpen, recentItemIds]);

  const renderOtherItems = () => {
    if (featureToggleName)
      return (
        <FeatureToggleActive featureToggleName={featureToggleName}>
          {renderItemsList()}
        </FeatureToggleActive>
      );
    return renderItemsList();
  };

  // if (!currentItem) return null;

  return (
    <Menu data-component="StickyMenu" style={{ marginTop: 0 }}>
      <If condition={!!stickyItem}>
        <Menu.Item
          name={stickyItem?.name}
          onClick={() => {
            setCurrentItem(stickyItem);
            onSelectedItemChange(stickyItem, true);
          }}
          active={currentItem?.slug === stickyItem?.slug}
        >
          {stickyItem?.name}
        </Menu.Item>
      </If>
      {renderOtherItems()}
    </Menu>
  );
};

StickyMenu.propTypes = {
  stickyItem: shape({
    id: number,
    name: string,
    slug: string
  }),
  // eslint-disable-next-line react/forbid-prop-types
  allItems: object.isRequired,
  onSelectedItemChange: func.isRequired,
  accountId: number,
  storeKey: string,
  featureToggleName: string,
  selectedItem: shape({
    id: number,
    name: string,
    slug: string
  }),
  showPopup: bool
};

StickyMenu.defaultProps = {
  stickyItem: null,
  accountId: undefined,
  featureToggleName: undefined,
  storeKey: undefined,
  selectedItem: undefined,
  showPopup: false
};

export default StickyMenu;
