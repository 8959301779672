// requires withRouter() properties
import { findSection } from "./sections";
import { findLineItem } from "./lineItems";

export const getUnitPath = props => {
  const { projectId, id } = props.params;
  // basename may `undefined` on Testing environment, using `createMemoryHistory`
  const { pathname, basename } = props.location;

  const isMarketingUnit =
    basename?.includes("/mp/") &&
    pathname?.includes(`${projectId}/musterwohnung/`);

  if (isMarketingUnit) {
    return `/${projectId}/musterwohnung/${id}`;
  }

  return `/${projectId}/unit/${id}`;
};

export const makeSectionPath = (props, sectionId) => {
  return `${getUnitPath(props)}/section/${sectionId}`;
};

export const getSectionPath = props => {
  const { sectionId } = props.params;
  return makeSectionPath(props, sectionId);
};

export const makeLineItemPath = (props, lineItemId) => {
  return `${getSectionPath(props)}/line_item/${lineItemId}`;
};

export const getLineItemPath = props => {
  const { lineItemId } = props.params;
  return makeLineItemPath(props, lineItemId);
};

export const makeSubLineItemPath = (props, subLineItemId) => {
  return `${getLineItemPath(props)}/sub_line_item/${subLineItemId}`;
};

export const getSubLineItemPath = props => {
  const { subLineItemId } = props.params;
  return makeSubLineItemPath(props, subLineItemId);
};

export const getProductLinesPath = props => {
  return `${getUnitPath(props)}/product_line`;
};

export function getBreadcrumbPartials(state) {
  return ({ to }) => {
    const section = findSection(state)(to.section_id);
    const subSection = findSection(state)(to.sub_section_id);
    const lineItem = findLineItem(state)(to.line_item_id);
    const subLineItem = to;

    return [
      { key: `${section.id}`, content: `${section.title}` },
      { key: `${subSection.id}`, content: `${subSection.title}` },
      { key: `${lineItem.id}`, content: `${lineItem.title}` },
      { key: `${subLineItem.id}`, content: `${subLineItem.title}` }
    ];
  };
}
