import Resources from "shared/actions/resourcesActions";

export default class ProjectRoomsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_room",
      `/api/v1/projects/${projectId}/project_rooms`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
