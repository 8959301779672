import es6Promise from "es6-promise";

es6Promise.polyfill();
// es lint:
/* global Promise */

export function withLoadingIndicator(func) {
  return values => {
    const promise = new Promise((resolve, reject) => {
      this.setState({ isLoading: true }, () => {
        const retVal = func(values);

        if (retVal) {
          retVal
            .then(response => {
              this.setState({ isLoading: false });
              resolve(response);
            })
            .catch(error => {
              this.setState({ isLoading: false });
              reject(error);
            });
        }
      });
    });

    return promise;
  };
}
