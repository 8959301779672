import { createSelector } from "reselect";
import {
  get,
  keyBy,
  groupBy,
  takeRightWhile,
  reverse,
  takeWhile,
  max
} from "lodash";

import { getState } from "../base";
import { getFlatRoomBook } from "./base";
import { getLineItemStatus, getStatus } from "./status";
import { findSubLineItems } from "./subLineItems";

//* *****************************************************************************
const applyBaseInfo = (state, raw) => {
  return {
    id: raw.id,
    title: raw.title,
    isRoot: false,
    isCancelled: raw.cancelled,
    isOptional: raw.optional,
    isActivated: !raw.optional, // do not care if activated in backend
    isBackendActivated: raw.activated,
    sectionId: raw.section_id,
    replacementToId: raw.replacement_for_id,
    isReplacement: raw.replacement,
    isReplaced: false, // do not care if replaced in backend
    isCompactDisplay: get(raw, "display_mode") === "compact_list",
    isMandatoryDisplay: get(raw, "display_mode") === "mandatory"
  };
};

const applyDerivedInfos = (state, raw, lineItem) => {
  const subLineItems = findSubLineItems(state)(lineItem.id);

  const info = {
    total: 0,
    totalBase: 0,
    totalMin: 0,
    totalDefault: 0,
    subLineItemCount: 0,
    isVisible: false,
    isActivated: lineItem.isActivated,
    isOptional: lineItem.isOptional,
    isReplaced: lineItem.isReplaced,
    isDecided: true,
    hasBuyerSelection: false,
    thumbUrl: null,
    isValid: true,
    isConfigurable: false,
    isProgressed: false,
    isPriceOnRequest: false,
    subLineItemIds: []
  };

  subLineItems.forEach(subLineItem => {
    info.totalBase += subLineItem.totalBase;
    info.totalMin += subLineItem.totalMin;
    info.totalDefault += subLineItem.totalDefault;

    if (subLineItem.isVisible) {
      info.isActivated =
        info.isActivated ||
        (subLineItem.isConfigurable && subLineItem.isDecided);
      info.subLineItemCount += 1;
      info.total += subLineItem.total;
      info.isVisible = info.isVisible || subLineItem.isVisible;
      info.isDecided = info.isDecided && subLineItem.isDecided;
      info.hasBuyerSelection =
        info.hasBuyerSelection || !!subLineItem.hasBuyerSelection;
      info.thumbUrl = info.thumbUrl || subLineItem.thumbUrl;
      info.imageClass = "productImage";
      info.isValid = info.isValid && subLineItem.isValid;
      info.isConfigurable = info.isConfigurable || subLineItem.isConfigurable;
      info.isProgressed = info.isProgressed || subLineItem.isProgressed;
      info.isPriceOnRequest =
        info.isPriceOnRequest || subLineItem.isPriceOnRequest;
      info.subLineItemIds = info.subLineItemIds.concat([subLineItem.id]);
    }
  });

  return info;
};

const applyStatus = (state, raw, lineItem) => {
  const status = getLineItemStatus(state)(lineItem);
  const finalStatusId = status.selectable
    ? lineItem.hasBuyerSelection
      ? "selected"
      : "open"
    : status.id;
  const finalStatus = getStatus(state)(finalStatusId);

  return {
    status: finalStatus
  };
};

const MAPPER = [applyBaseInfo, applyDerivedInfos, applyStatus];

//* *****************************************************************************

const preparedLineItems = createSelector(
  [getFlatRoomBook, getState],
  (roomBook, state) => {
    const lineItems = roomBook.lineItems.map(raw => {
      return MAPPER.reduce((lineItem, mapper) => {
        return Object.assign(lineItem, mapper(state, raw, lineItem));
      }, {});
    });

    const lineItemsById = keyBy(lineItems, "id");

    // We need to change lineItems and replaced line items here, so we cannot create new objects here
    /* eslint-disable no-param-reassign */
    lineItems.forEach(lineItem => {
      if (lineItem.isReplacement) {
        const replacable = lineItemsById[lineItem.replacementToId];
        lineItem.replacementTo = replacable;

        if (!lineItem.isPriceOnRequest) {
          lineItem.totalRefund =
            lineItem.totalDefault - replacable.totalDefault;
          if (lineItem.isActivated) {
            lineItem.total =
              lineItem.totalRefund + max([lineItem.total, lineItem.totalMin]);
          } else {
            lineItem.total = 0;
          }
          lineItem.totalMin = lineItem.totalRefund + lineItem.totalMin;
        }
        replacable.isReplaced = replacable.isReplaced || lineItem.isActivated;
        if (lineItem.isActivated) {
          replacable.replacedById = lineItem.id;
        } else if (replacable.replacedById === lineItem.id) {
          replacable.replacedById = null;
        }
        replacable.isActivated =
          replacable.isActivated && !replacable.isReplaced;
        replacable.isDecided = replacable.isDecided || replacable.isReplaced;
      }
      if (lineItem.isOptional) {
        if (lineItem.isActivated) {
          lineItem.total = max([lineItem.total, lineItem.totalMin]);
        } else {
          lineItem.total = 0;
        }
      }
    });
    /* eslint-enable no-param-reassign */

    return lineItems;
  }
);

export const getLineItems = createSelector([preparedLineItems], lineItems => {
  return lineItems;
});

const getLineItemsById = createSelector([getLineItems], lineItems => {
  return keyBy(lineItems, "id");
});

const getLineItemsBySectionId = createSelector([getLineItems], lineItems => {
  return groupBy(lineItems, "sectionId");
});

export const findLineItem = state => {
  return lineItemId => {
    return getLineItemsById(state)[lineItemId];
  };
};

export const findLineItems = state => {
  return sectionId => {
    return getLineItemsBySectionId(state)[sectionId] || [];
  };
};

export const getPrevSibling = (state, lineItem) => {
  const siblings = getLineItemsBySectionId(state)[lineItem.sectionId] || [];
  return reverse(takeWhile(siblings, sibling => sibling.id !== lineItem.id))[0];
};

export const getNextSibling = (state, lineItem) => {
  const siblings = getLineItemsBySectionId(state)[lineItem.sectionId] || [];
  return takeRightWhile(siblings, sibling => sibling.id !== lineItem.id)[0];
};

export const findReplacementLineItems = createSelector(
  [getLineItems],
  lineItems => {
    const lineItemsByReplacementId = groupBy(
      lineItems.filter(li => li.replacementToId),
      "replacementToId"
    );

    return lineItemId => {
      return lineItemsByReplacementId[lineItemId] || [];
    };
  }
);
