import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Form,
  Input,
  Select,
  TextArea,
  Checkbox
} from "formik-semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { node } from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { useIntl, FormattedMessage } from "react-intl";
import { UnitFeatureResource } from "builder_portal/actions/unitFeatureGroupsActions";
import { UnitFeatureShape } from "shared/shapes/unitFeatureGroup.shape";
import { getProject } from "shared/selectors";
import { getUnitFeatureGroups } from "../../../selectors/unitFeatureGroups";
import { If } from "../../../../shared/components/elements/Conditions";

const UnitFeatureDialog = ({ button, model }) => {
  const unitFeatureGroups = useSelector(getUnitFeatureGroups);
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const project = useSelector(getProject);
  const dispatch = useDispatch();
  const initialFormValue = useMemo(() => cloneDeep(model), [model]);

  const unitFeatureGroupsOptions = useMemo(() => {
    return unitFeatureGroups.map(group => ({
      key: group.id,
      value: group.id,
      text: group.name
    }));
  }, [unitFeatureGroups]);

  const validationScheme = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    title: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "message.errorForm.at_least_n_chars" },
          { n: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage({ id: "message.errorForm.max_n_chars" }, { n: 255 })
      )
      .required(intl.formatMessage({ id: "message.errorForm.required" })),
    marketing_feature: Yup.boolean()
  });

  const onSubmit = useCallback((values, formik) => {
    new UnitFeatureResource(dispatch, project.id)
      .save(values)
      .then(() => {
        const isCreateDialog = !!values.id;
        if (!isCreateDialog) formik.resetForm();
        new UnitFeatureResource(dispatch, project.id).fetchAll().then(() => {
          setOpen(false);
          new Growl(dispatch).success(
            "message.success.title",
            "message.success.body"
          );
        });
      })
      .catch(() => {
        formik.setSubmitting(false);
        new Growl(dispatch).error("message.error.title", "message.error.body");
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      closeIcon
      closeOnEscape
      closeOnDimmerClick
      trigger={button}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      data-component="UnitFeatureDialog"
    >
      <Formik
        initialValues={initialFormValue}
        validationSchema={validationScheme}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, isValid, submitForm }) => {
          return (
            <>
              <Modal.Header>
                <FormattedMessage
                  id={`roomBook.unitFeatures.dialog.${
                    !model.id ? "createTitle" : "editTitle"
                  }`}
                />
              </Modal.Header>
              <Modal.Content scrolling>
                <Form>
                  <Input
                    id="name"
                    errorPrompt
                    name="name"
                    label={intl.formatMessage({
                      id: "roomBook.unitFeatures.dialog.name"
                    })}
                  />

                  <Input
                    id="title"
                    errorPrompt
                    name="title"
                    label={intl.formatMessage({
                      id: "roomBook.unitFeatures.dialog.titleField"
                    })}
                  />

                  <Select
                    id="unit_feature_group_id"
                    errorPrompt
                    clearable
                    name="unit_feature_group_id"
                    options={unitFeatureGroupsOptions}
                    label={intl.formatMessage({
                      id: "roomBook.unitFeatures.dialog.unitFeatureGroup"
                    })}
                  />

                  <TextArea
                    id="description"
                    name="description"
                    label={intl.formatMessage({
                      id: "roomBook.unitFeatures.dialog.description"
                    })}
                  />

                  <Checkbox
                    id="marketing_feature"
                    name="marketing_feature"
                    label={useIntl().formatMessage({
                      id: "roomBook.unitFeatures.dialog.marketingFeature"
                    })}
                    style={{ fontWeight: "600", margin: "7px 0" }}
                  />

                  <If condition={values.marketing_feature} block>
                    <Input
                      id="icon"
                      errorPrompt
                      name="icon"
                      label={intl.formatMessage({
                        id: "roomBook.unitFeatures.dialog.icon"
                      })}
                      placeholder="default"
                    />

                    <Input
                      id="marketing_label"
                      errorPrompt
                      name="marketing_label"
                      label={intl.formatMessage({
                        id: "roomBook.unitFeatures.dialog.marketingLabel"
                      })}
                      placeholder={values.title || values.name}
                    />

                    <TextArea
                      id="marketing_description"
                      name="marketing_description"
                      label={intl.formatMessage({
                        id: "roomBook.unitFeatures.dialog.marketingDescription"
                      })}
                      placeholder={values.description}
                    />
                  </If>
                </Form>
              </Modal.Content>
              <Modal.Actions style={{ textAlign: "right" }}>
                <Button
                  basic
                  id="cancel"
                  content={intl.formatMessage({
                    id: "meta.actions.cancel"
                  })}
                  onClick={handleClose}
                  loading={isSubmitting}
                />
                <Button
                  primary
                  type="submit"
                  content={
                    model.id
                      ? intl.formatMessage({ id: "meta.actions.save" })
                      : intl.formatMessage({ id: "meta.actions.add" })
                  }
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={submitForm}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

UnitFeatureDialog.propTypes = {
  button: node.isRequired,
  model: UnitFeatureShape.isRequired
};

export default UnitFeatureDialog;
