import { get, keyBy } from "lodash";
import ProcessDefinition from "./processDefinition";

export class Account {
  constructor(data, i18n, pageContent) {
    this.data = data;
    this.i18n = i18n;
    this.pageContent = pageContent;
    this.processDefinitions = {};
  }

  getProcessDefinitions() {
    return get(this.data, "account.config.processes", []).map(proc => {
      return this.getProcessDefinition(proc.process_type);
    });
  }

  getAssignableProcessDefinitions(status) {
    return this.getProcessDefinitions().filter(pd => {
      return !pd.isDeprecated() && pd.handlesStatus(status);
    });
  }

  getProcessDefinition(name) {
    this.processDefinitions[name] =
      this.processDefinitions[name] ||
      new ProcessDefinition(name, this, this.i18n);
    return this.processDefinitions[name];
  }

  getLineItemLifeCycle() {
    const data = get(this.data, "account.config.line_items", {
      phases: [],
      states: []
    });
    const lc = {
      getPhases: () => {
        return data.phases;
      },
      getPhase: id => {
        return data.phases.filter(phase => {
          return phase.id == id;
        })[0];
      },
      getStateById: id => {
        return lc.getStates().filter(state => {
          return state.id == id;
        })[0];
      },
      getStates: () => {
        return data.states.map(state => {
          const phase = lc.getPhase(state.phase);
          return {
            ...state,
            phase_id: phase.id,
            phase_label: phase.label,
            phase_color: phase.color
          };
        });
      },
      getReportGroups: () => {
        return data.states.reduce((accu, state) => {
          (state.report_groups || []).forEach(group => {
            if (!accu[group]) {
              accu[group] = [];
            }
            accu[group].push(state.id);
          });

          return accu;
        }, {});
      }
    };

    return lc;
  }

  getTrades(includeNone) {
    const trades = get(this.data, "account.config.contractors.trades", []);

    if (includeNone) {
      trades.push({
        id: "__NONE__",
        label: "Ohne Gewerk"
      });
    }

    return trades;
  }

  getTradeById(id) {
    return this.getTradesDictionary()[id];
  }

  getProjects() {
    return get(this.data, "account.projects", []);
  }

  getTaxonomies() {
    return get(this.data, "account.config.taxonomies", []);
  }

  getTradesDictionary() {
    return keyBy(this.getTrades(), "id");
  }

  getDocumentTypes() {
    return get(this.data, ["account", "config", "attachment_types"], []);
  }

  getProjectStatusOptions() {
    return get(this.data, "account.config.projects.status", []).map(item => {
      return {
        key: item.id,
        value: item.id,
        text: item.label
      };
    });
  }

  getCurrentUser() {
    return get(this.data, "user", {});
  }

  getUsers() {
    return get(this.pageContent, "users", []);
  }

  getCurrentAccountRole() {
    return get(this.getCurrentAccount(), "account_role");
  }

  getCurrentAccount() {
    return get(this.data, "account", {});
  }

  isSystemAdmin() {
    return this.getCurrentUser().group === "admin";
  }

  isAdmin() {
    return this.getCurrentUser().role === "admin";
  }

  isProjectPage = () => {
    const path = window.location.pathname;
    const regexProjects = /^\/projects/;
    return !!path.match(regexProjects);
  };

  getFeatureToggles() {
    const projectFeatureToggles = get(
      this.pageContent,
      "project.features",
      null
    );
    if (this.isProjectPage() && !!projectFeatureToggles)
      return projectFeatureToggles;
    return get(this.data, "account.feature_toggles", {});
  }

  isEnabled(feature) {
    return get(this.getFeatureToggles(), feature, false);
  }

  hasProjectRoles() {
    return this.getProjectRoles().length > 0;
  }

  hasProjectRoleRight(projectId, right) {
    const project = get(this.data, "account.projects", []).find(
      p => p.id === projectId || p.slug === projectId
    );
    return project && project.project_role[right];
  }

  getProjectRoles() {
    return get(this.data, "account.config.project_roles", []);
  }

  isNetAccounting() {
    return !get(
      this.getFeatureToggles(),
      "show_reports_with_gross_amounts",
      false
    );
  }

  hasAccountRoleRight(right) {
    return get(this.getCurrentAccountRole(), right, false);
  }

  hasEditProductsRight() {
    return this.hasAccountRoleRight("allow_edit_products");
  }

  hasEditAccountsSettingsRight() {
    return this.hasAccountRoleRight("allow_edit_account_settings");
  }

  hasEditContractorRoles() {
    return this.hasAccountRoleRight("allow_edit_contractors");
  }

  hasAccessProjectCatalogRight() {
    return this.hasAccountRoleRight("allow_access_project_catalogs");
  }

  hasAccessStatisticsCenterRight() {
    return this.hasAccountRoleRight("allow_access_statistics_center");
  }

  hasAccessUnassignedEmailsRight() {
    return this.hasAccountRoleRight("allow_access_unassigned_emails");
  }

  hasBetaPreviewRight() {
    return get(this.getCurrentUser(), "beta_preview_right", false);
  }

  hasAccessAPIDocumentationRight() {
    return this.hasAccountRoleRight("ext_allow_api_documentation");
  }
}

export default Account;
