import apiRequest from "shared/network/apiRequest";
import Resources from "shared/actions/resourcesActions";

export default class ProductGroupResource extends Resources {
  constructor(catalogId, dispatch) {
    super(
      dispatch,
      "product_group",
      `/api/v1/project_catalogs/${catalogId}/product_groups`
    );
  }

  updatePrices(productGroupId, values) {
    return apiRequest
      .patch(`${this.url}/${productGroupId}/prices`, this.dispatch, values)
      .then(this.propagateUpdate.bind(this));
  }

  duplicate(id) {
    return apiRequest.post(`${this.url}/${id}/duplicate`, this.dispatch);
  }

  usageCounts(id) {
    return apiRequest
      .get(`${this.url}/${id}/usage_counts`, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}

export class ProductPriceResource extends Resources {
  constructor(productGroupId, dispatch) {
    super(dispatch, "price", `/api/v1/product_groups/${productGroupId}/prices`);
  }
}

export class ProductGroupRuleSetResource extends Resources {
  constructor(catalogId, dispatch) {
    super(
      dispatch,
      "productGroupRuleSet",
      `/api/v1/project_catalogs/${catalogId}/product_group_rule_sets`,
      "productGroupRuleSets"
    );
  }
}
