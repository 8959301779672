/* eslint-disable import/prefer-default-export */
import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

export class ProjectImageLibraryResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "image",
      `/api/v1/projects/${projectId}/images?role=project_image`,
      "images"
    );
  }

  save(formData) {
    return apiRequest
      .post(this.url, this.dispatch, formData)
      .then(this.propagateUpdate);
  }
}
