import React from "react";
import PropTypes from "prop-types";
import { get, keyBy, sortBy, groupBy } from "lodash";
import { Grid } from "semantic-ui-react";
import Empty from "./Empty";
import ProgressBar from "./ProgressBar";

import connectStatistic from "./statisticsContainer";

class ProgressByTrade extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    statistics: PropTypes.object,
    account: PropTypes.object,
    processDefinition: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { statistics } = this.props;
    const byTrade = statistics.by_trade;

    if (byTrade && byTrade.length > 0) {
      return this.renderStatistics();
    }
    return <Empty />;
  }

  renderStatistics() {
    const { i18n, statistics, processDefinition, account } = this.props;

    const processDictionary = keyBy(processDefinition.getPhases(true), "id");
    const tradesDictionary = keyBy(account.getTrades(true), "id");
    const phases = Object.keys(processDictionary);
    const statsByTrade = groupBy(statistics.by_trade, "trade");

    const tradeData = Object.keys(statsByTrade).map(key => {
      const dataByPhase = keyBy(statsByTrade[key] || [], "phase");
      return {
        trade: key,
        data: phases.map(phase => {
          return {
            phase,
            count: (dataByPhase[phase] || { count: 0 }).count
          };
        })
      };
    }, {});

    const sortedTradeData = sortBy(tradeData, e => {
      return -e.data.reduce((sum, d, idx) => {
        return sum + d.count * idx;
      }, 0);
    });

    const rows = sortedTradeData.map(entry => {
      return (
        <Grid.Row key={entry.trade}>
          <Grid.Column width={3} textAlign="left" verticalAlign="middle">
            <b>
              {get(
                tradesDictionary[entry.trade.toUpperCase()],
                "label",
                entry.trade
              )}
            </b>
          </Grid.Column>
          <Grid.Column width={13}>
            <ProgressBar
              i18n={i18n}
              statistics={entry.data}
              processDefinition={processDefinition}
              height={35}
            />
          </Grid.Column>
        </Grid.Row>
      );
    });

    return <Grid>{rows}</Grid>;
  }
}

export default connectStatistic(ProgressByTrade);
