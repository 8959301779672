import React, { useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Segment, Dropdown, Header, Icon } from "semantic-ui-react";
import { getMessageLabels } from "builder_portal/selectors/messages";
import Growl from "builder_portal/actions/growlActions";
import { MessageThreadShape } from "shared/shapes/messageThread.shape";
import MessagesResource from "../../actions/messagesActions";

const MessageLabelsSelect = ({ messageThread }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const messageLabels = useSelector(getMessageLabels);

  const options = useMemo(() => {
    return messageLabels.map(label => ({
      key: label.id,
      value: label.id,
      text: (
        <span>
          <Icon name="square full" color={label.color} />
          {label.name}
        </span>
      )
    }));
  }, [messageLabels]);

  const handleChange = useCallback((e, { value, defaultValue }) => {
    const message = defaultValue.length < value.length ? "added" : "removed";
    new MessagesResource(dispatch).save({
      ...messageThread,
      message_label_ids: value
    });

    new Growl(dispatch).success(
      `message.success.title`,
      `email.message_labels.select_message.${message}`
    );
  }, []);

  return (
    <Segment>
      <Header
        sub
        textAlign="left"
        icon="tags"
        content={intl.formatMessage({
          id: "email.message_labels.list.title"
        })}
        style={{ marginBottom: "7px" }}
      />
      <Dropdown
        options={options}
        selection
        multiple
        fluid
        onChange={handleChange}
        defaultValue={messageThread.message_labels.map(ml => ml.id)}
      />
    </Segment>
  );
};

MessageLabelsSelect.propTypes = {
  messageThread: MessageThreadShape.isRequired
};

export default MessageLabelsSelect;
