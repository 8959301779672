/* eslint-disable prettier/prettier */
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { If } from "shared/components/elements/Conditions";
import { getUser, getProjectCatalogs } from "shared/selectors";
import PropTypes from "prop-types";
import { find } from "lodash";

export const useHasEditRightsForProjectCatalog = catalogId => {
  const { id: userId, role } = useSelector(getUser);
  const projectCatalogs = useSelector(getProjectCatalogs);

  const userIds = useMemo(() => {
    const catalog = find(projectCatalogs, p => {
      return p.id === catalogId || p.slug === catalogId;
    });
    return catalog ? catalog?.user_ids : [];
  }, [catalogId, projectCatalogs]);

  const isCurrentUserInUserIds = useMemo(() => {
    if (role === "admin") {
      return true;
    }
    if (userIds) {
      if (userIds.length === 0) {
        return true;
      }
      if (userIds.includes(userId)) {
        return true;
      }
      return false;
    }
    return false;
  }, [userIds, userId]);

  return isCurrentUserInUserIds;
};

const HasEditRightsForProjectCatalog = ({ catalogId, children }) => {
  const hasUserEditRights = useHasEditRightsForProjectCatalog(catalogId);
  return <If condition={hasUserEditRights}>{children}</If>;
};

HasEditRightsForProjectCatalog.propTypes = {
  catalogId: PropTypes.string.isRequired,
  children: PropTypes.node
};

HasEditRightsForProjectCatalog.defaultProps = {
  children: null
};

export default HasEditRightsForProjectCatalog;
