import { createSelector } from "reselect";
import { get, keyBy, groupBy } from "lodash";

import { getState } from "../base";
import { getFlatRoomBook } from "./base";
import { findLineItems } from "./lineItems";
import { getProjectBranding } from "../project";

const getSectionCompletion = createSelector([getState], state => {
  return get(state, ["pageContent", "sectionCompleted"], {});
});

export const getSections = createSelector(
  [getFlatRoomBook, getProjectBranding, getState, getSectionCompletion],
  (roomBook, branding, state, completion) => {
    const subSectionsBySection = groupBy(roomBook.subSections, "section_id");
    return roomBook.sections.reduce((accu, section) => {
      const subSections = subSectionsBySection[section.id] || [];
      let sectionTotal = 0;
      let subSectionCount = 0;
      let isSectionDecided = true;
      let isSectionValid = true;
      let isSectionConfigurable = false;
      let isSectionPriceOnRequest = false;

      subSections.forEach(subSection => {
        const subSectionVisible = branding.isVisible([section, subSection]);
        const subSectionImage = branding.getImageUrl([section, subSection]);
        const configuratorId = branding.getConfiguratorId([
          section,
          subSection
        ]);
        const selector = branding.getSelector([section, subSection]);

        const lineItems = findLineItems(state)(subSection.id);

        let lineItemCount = 0;
        let subSectionTotal = 0;
        let isValid = true;
        let isConfigurable = false;
        let isPriceOnRequest = false;

        lineItems.forEach(lineItem => {
          if (lineItem.isVisible) {
            lineItemCount += 1;
            isValid = isValid && lineItem.isValid;
            isConfigurable = isConfigurable || lineItem.isConfigurable;

            if (lineItem.isActivated) {
              isPriceOnRequest = isPriceOnRequest || lineItem.isPriceOnRequest;
            }

            if (!lineItem.isCancelled && !lineItem.isReplaced) {
              subSectionTotal += lineItem.total;
            }
          }
        });

        const isDecided = lineItems.every(li => {
          if (!li.isVisible) {
            return true;
          }
          if (li.isReplacement && !li.isActivated) {
            return true;
          }
          if (li.isDecided) {
            return true;
          }
          return li.isOptional && !!completion[subSection.id];
        });
        const isMandatoryDisplay = lineItems.every(li => li.isMandatoryDisplay);

        accu.push({
          id: subSection.id,
          parentId: section.id,
          title: subSection.title,
          image: subSectionImage,
          imageClass: "sectionImage",
          total: subSectionTotal,
          isVisible: subSectionVisible,
          hasChildren: lineItemCount > 0,
          isRoot: false,
          isDecided,
          isValid,
          isConfigurable,
          isMandatoryDisplay,
          isPriceOnRequest,
          configuratorId,
          selector,
          position: subSection.position
        });

        if (subSectionVisible) {
          sectionTotal += subSectionTotal;
          subSectionCount += 1;
          isSectionDecided = isSectionDecided && isDecided;
          isSectionValid = isSectionValid && isValid;
          isSectionConfigurable = isSectionConfigurable || isConfigurable;
          isSectionPriceOnRequest = isSectionPriceOnRequest || isPriceOnRequest;
        }
      });

      const sectionVisible = branding.isVisible([section]);
      const sectionImage = branding.getImageUrl([section]);
      const sectionGroup = branding.getGroup([section]);
      const configuratorId = branding.getConfiguratorId([section]);
      const selector = branding.getSelector([section]);

      const isMandatoryDisplay = subSections.every(sS =>
        sS.line_items.every(li => li.display_mode === "mandatory")
      );

      accu.push({
        id: section.id,
        title: section.title,
        image: sectionImage,
        imageClass: "sectionImage",
        group: sectionGroup,
        total: sectionTotal,
        isVisible: sectionVisible,
        hasChildren: subSectionCount > 0,
        isRoot: true,
        isDecided: isSectionDecided,
        isMandatoryDisplay,
        isValid: isSectionValid,
        isConfigurable: isSectionConfigurable,
        isPriceOnRequest: isSectionPriceOnRequest,
        configuratorId,
        selector,
        position: section.position
      });

      return accu;
    }, []);
  }
);

const getSectionsById = createSelector([getSections], sections => {
  return keyBy(sections, "id");
});

const getSectionsByParentId = createSelector([getSections], sections => {
  return groupBy(sections, "parentId");
});

export const getRootSections = createSelector([getSections], sections => {
  return sections.filter(s => s.isRoot);
});

export const findSection = createSelector([getSectionsById], sectionsById => {
  return sectionId => {
    return sectionsById[sectionId];
  };
});

export const findSubSections = (state, sectionId) => {
  return getSectionsByParentId(state)[sectionId] || [];
};

const getPageContentSections = createSelector([getState], state => {
  return get(state, ["pageContent", "sections"], {});
});

export const getPageContentSectionsById = createSelector(
  [getPageContentSections],
  sections => {
    return keyBy(sections, "id");
  }
);
