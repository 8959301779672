import React from "react";
import { Link } from "react-router";
import { Grid, Header, Icon, Popup, Segment } from "semantic-ui-react";
import { get } from "lodash";
import { formatDate } from "../../../shared/helpers/formatDate";
import DueLabel from "../dueLabel/DueLabel";
import colorByDueDays from "../../helpers/colorByDueDays";
import "./activityList.scss";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class ActivityListItem extends React.Component {
  static propTypes = {
    projectId: PropTypes.string,
    item: PropTypes.object.isRequired,
    listType: PropTypes.string,
    account: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { item, account, projectId } = this.props;
    const processDefinition = account.getProcessDefinition(item.process_type);
    if (!item || !processDefinition) {
      return null;
    }
    const state = processDefinition.getStateById(item.status);
    const color = colorByDueDays(item.deadline_due_at);
    const nextStep = get(state, "label");
    const typeSpecificFigure = item.deadline_due_at && (
      <DueLabel date={item.deadline_due_at} />
    );
    const processTypeName = get(processDefinition, "config.name");

    return (
      <Segment data-component="activityListItem" data-model="activity">
        <Link to={`/projects/${projectId}/activities/${item.id}`}>
          <div className="title">
            <Grid
              stackable
              verticalAlign="middle"
              style={{ padding: "5px 0px" }}
            >
              <Grid.Column width={1} textAlign="center">
                <Icon
                  size="big"
                  name="circle"
                  style={{ color }}
                  className="statusBubble"
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Grid stackable className="activityFigures">
                  <Grid.Row>
                    <Grid.Column width={7} verticalAlign="middle">
                      <Popup trigger={<Header>{item.title}</Header>}>
                        {processDefinition.getName()}
                      </Popup>
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                      {this.getScopeInfo(item)}
                    </Grid.Column>
                    <Grid.Column width={2} verticalAlign="middle">
                      <Popup trigger={<small>{item.display_name}</small>}>
                        <FormattedMessage
                          id="activity.item.displayName"
                          defaultMessage="Vorgangsnummer"
                        />
                      </Popup>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={7} verticalAlign="middle">
                      <Popup
                        wide
                        trigger={
                          <small>
                            {processTypeName}{" "}
                            <Icon name="chevron right" size="small" fitted />{" "}
                            {nextStep}
                          </small>
                        }
                      >
                        <FormattedMessage
                          id="activity.item.processTypeName"
                          defaultMessage="Vorgangstyp"
                        />
                        &nbsp;
                        <Icon name="chevron right" size="small" fitted />
                        &nbsp;
                        <FormattedMessage
                          id="activity.item.nextStep"
                          defaultMessage="Aktueller Arbeitsschritt"
                        />
                      </Popup>
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                      <Popup
                        trigger={
                          <small>
                            <Icon name="user" /> {item.assignee.label}
                          </small>
                        }
                      >
                        <FormattedMessage
                          id="activity.item.assignee.label"
                          defaultMessage="Verantwortlicher"
                        />
                      </Popup>
                    </Grid.Column>
                    <Grid.Column width={2} verticalAlign="middle">
                      <Popup
                        trigger={<small>{formatDate(item.created_at)}</small>}
                      >
                        <FormattedMessage
                          id="activity.item.createdAt"
                          defaultMessage="Begonnen am"
                        />
                      </Popup>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={3} textAlign="right">
                {typeSpecificFigure || ""}
              </Grid.Column>
            </Grid>
          </div>
        </Link>
      </Segment>
    );
  }

  getScopeInfo(activity) {
    if (activity.activity_scope === "unit") {
      const buyer_names = activity.buyers.map(b => b.label).join(", ");
      return (
        <Popup
          trigger={
            <span>
              {activity.buyers.length > 0 && <span>{buyer_names}</span>}
            </span>
          }
        >
          <FormattedMessage
            id="activity.scope.buyers"
            defaultMessage="Erwerber"
          />
        </Popup>
      );
    }
    return (
      <FormattedMessage
        id="activity.scope.across"
        defaultMessage="Wohnungsübergreifend"
      />
    );
  }
}

export default ActivityListItem;
