import React, {
  useMemo,
  useContext,
  useRef,
  useEffect,
  useCallback
} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router";
import { If } from "shared/components/elements/Conditions";
import {
  Button,
  Container,
  Header,
  Icon,
  Segment,
  Grid,
  Message
} from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import GeneratorButton from "builder_portal/components/roomBook/widgets/GeneratorButton";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import PropTypes from "prop-types";
import RoomBookPrototypeResource from "builder_portal/actions/roomBookPrototypeActions";
import RoomBookController from "builder_portal/controllers/roomBook/roomBookController";
import { ProjectSetupDataContext } from "./ProjectSetupDataContext";

const RoomBookSegment = ({ disabled }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const refTriggerButton = useRef();
  const refIsTriggerable = React.useRef(false);
  const {
    project,
    projectSetup,
    renderUploadedMessage,
    prepareActions,
    isLoading,
    updateProjectSetup,
    setIsLoading,
    unsetIsLoading,
    handleUplaodCompleted
  } = useContext(ProjectSetupDataContext);

  const disabledStyle = disabled
    ? { pointerEvents: "none", opacity: "0.4" }
    : {};

  const roomBookResource = useMemo(
    () => new ProjectRoomBooksResource(dispatch, projectSetup.project_id),
    [projectSetup]
  );

  useEffect(() => {
    new RoomBookPrototypeResource(dispatch).fetchAll();
  }, []);

  useEffect(() => {
    const onClick = refTriggerButton.current?.props?.onClick;
    if (refIsTriggerable.current && typeof onClick === "function") {
      onClick();
      refIsTriggerable.current = false;
    }
  }, [refTriggerButton.current, refIsTriggerable.current]);

  const roombookStatus = useMemo(() => {
    const isCatalogOlder =
      projectSetup.step_room_books_completed_at >
      projectSetup.step_project_catalog_completed_at;

    if (!projectSetup.room_book_id) return "shouldBeCreated";
    if (!projectSetup.step_room_books_completed_at && projectSetup.room_book)
      return "shouldBeGenerated";
    if (projectSetup.step_room_books_completed_at && isCatalogOlder)
      return "isGenerated";
    if (
      projectSetup.step_room_books_completed_at &&
      projectSetup.step_project_catalog_completed_at &&
      !isCatalogOlder
    )
      return "shouldBeRegenerated";
    return "shouldBeCreated";
  }, [projectSetup]);

  const handleCreate = useCallback(() => {
    const { project_catalog_id, price_catalog_id } = projectSetup;
    setIsLoading();
    new ProjectRoomBooksResource(dispatch, project.id)
      .save({
        name: `${project.slug}-room-book-auto`,
        project_catalog_id,
        price_catalog_id
      })
      .then(res => {
        if (res.data?.room_book?.id) {
          updateProjectSetup({ room_book_id: res.data.room_book.id }).then(
            () => {
              refIsTriggerable.current = true;
            }
          );
        }
        unsetIsLoading();
      })
      .catch(() => {
        unsetIsLoading();
      });
  }, [project]);

  return (
    <Segment attached style={{ ...disabledStyle }}>
      <Header as="h3">
        <Icon
          name={`${
            roombookStatus === "isGenerated" ? "check circle" : "circle outline"
          }`}
          size="huge"
        />
        <FormattedMessage
          id="project.project_setup.items.roombooks_templates"
          default="Wohnungen"
        />
      </Header>
      <Container textAlign="right">
        <If condition={roombookStatus === "shouldBeCreated"} block>
          <Button
            basic
            style={{ width: "43%", height: 50, marginLeft: "10px" }}
            onClick={handleCreate}
            disabled={isLoading}
          >
            <FormattedMessage
              id="project.project_setup.dialog.generate_roombook.label"
              default="Raumbuch generieren"
            />
          </Button>
        </If>
        <If condition={roombookStatus === "shouldBeGenerated"} block>
          <GeneratorButton
            roomBook={projectSetup.room_book}
            ctrl={
              new RoomBookController(
                project.slug,
                projectSetup.room_book_id,
                "template",
                dispatch
              )
            }
            ref={refTriggerButton}
            onCompleted={() => handleUplaodCompleted("step_room_books_")}
            buttonProps={{
              id: "generate-roombook",
              content: intl.formatMessage({
                id: "project.project_setup.dialog.generate_roombook.label"
              }),
              style: { width: "43%", height: 50, marginLeft: "10px" },
              disabled: isLoading,
              basic: true
            }}
          />
        </If>
        <If condition={roombookStatus === "isGenerated"} block>
          <Grid style={{ marginLeft: "35px" }}>
            <Grid.Column width={9} textAlign="left">
              {renderUploadedMessage(
                prepareActions.data(projectSetup, "step_room_books_")
              )}
            </Grid.Column>
            <Grid.Column
              width={7}
              textAlign="right"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <GeneratorButton
                roomBook={projectSetup.room_book}
                ctrl={roomBookResource}
                buttonProps={{
                  id: "generate-roombook",
                  basic: true,
                  content: intl.formatMessage({
                    id:
                      "project.project_setup.dialog.generate_roombook_new.label"
                  }),
                  className: "underlinedButton",
                  disabled: isLoading
                }}
              />
              <Link
                className="underlinedLink"
                to={`/projects/${project.slug}/room_books/${projectSetup.room_book_id}`}
              >
                <FormattedMessage
                  id="project.project_setup.dialog.open_roombook.label"
                  default="Raumbuch öffnen"
                />
              </Link>
            </Grid.Column>
          </Grid>
        </If>
        <If condition={roombookStatus === "shouldBeRegenerated"} block>
          <Message warning style={{ textAlign: "left" }}>
            <FormattedMessage
              id="project.project_setup.dialog.catalog_changed.label"
              default="Raumbuch öffnen"
            />
          </Message>
          <Button.Group basic compact>
            <GeneratorButton
              roomBook={projectSetup.room_book}
              ctrl={roomBookResource}
              buttonProps={{
                id: "generate-roombook",
                content: intl.formatMessage({
                  id:
                    "project.project_setup.dialog.generate_roombook_again.label"
                }),
                style: { width: "43%", height: 50, marginLeft: "10px" },
                disabled: isLoading
              }}
            />
          </Button.Group>
        </If>
      </Container>
    </Segment>
  );
};

RoomBookSegment.propTypes = {
  disabled: PropTypes.bool
};

RoomBookSegment.defaultProps = {
  disabled: false
};

export default RoomBookSegment;
