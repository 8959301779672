import React from "react";
import { string, arrayOf, shape, number, node, func } from "prop-types";
import { Table, Dropdown, Loader, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { I18nShape } from "shared/shapes/i18n.shape";
import { If } from "shared/components/elements/Conditions";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import AssignmentDialog from "./AssignmentDialog";
import ExportDialog from "./ExportDialog";
import StyleDialog from "./StyleDialog";
import SignatureEditor from "./SignatureEditor";
import PreviewDialog from "./PreviewDialog";
import DuplicateDialog from "./DuplicateDialog";

import "./documentExport.scss";

const BUTTON_COLUMNS = 1;
const MIN_COLUMNS = 2;

export const renderTableLoader = cols => (
  <Table.Body>
    <Table.Row>
      <Table.Cell colSpan={cols} align="center">
        <Loader active inline="centered" />
      </Table.Cell>
    </Table.Row>
  </Table.Body>
);

const emptySignatureModel = {
  name: "",
  config_content: { blocks: [{ elements: [] }] }
};

const renderDisabledIcon = (iconName, translationKey, i18n) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      role="button"
      id="product_group-remove"
      data-tooltip={i18n[`documentsExport.messages.${translationKey}`]}
    >
      <Icon name={iconName} color="grey" className="disabledIcon" />
    </a>
  );
};

const renderDeleteButton = (i18n, onDelete, model, dialog) => {
  if (dialog !== "assignment" && !model.deletable)
    return renderDisabledIcon("trash", "notDeletable", i18n);
  const button = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      role="button"
      id="product_group-remove"
      data-tooltip={i18n["meta.actions.remove"]}
    >
      <Icon name="trash" color="grey" />
    </a>
  );

  const buttons = [
    {
      id: "delete",
      label: "meta.actions.remove",
      color: "red",
      onClick: handleClose => {
        onDelete(model.id).catch(() => {
          handleClose();
        });
      }
    },
    {
      id: "cancel",
      label: "meta.actions.cancel",
      basic: true
    }
  ];

  return (
    <ConfirmationDialog
      title={`documentsExport.removeConfirmation.${dialog}.title`}
      message={`documentsExport.removeConfirmation.${dialog}.message`}
      buttons={buttons}
      button={button}
    />
  );
};

const renderFilterDropdown = (name, filterOptions, applyFilter, filters) => {
  return (
    <Dropdown
      fluid
      selection
      search
      options={filterOptions[name]}
      name={name}
      onChange={(_, vals) => applyFilter(name, vals)}
      className="filterBox"
      clearable
      value={filters[name] || ""}
    />
  );
};

const renderButton = ({
  i18n,
  title,
  icon,
  linkClass = "",
  iconClass = ""
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={linkClass} role="button" data-tooltip={i18n[title]}>
      <Icon name={icon} color="grey" className={iconClass} />
    </a>
  );
};

export const renderTableHeader = ({
  i18n,
  list,
  columns,
  handlers,
  applyFilter,
  title,
  dialog,
  filterOptions,
  filters,
  setFilters
}) => {
  return (
    <>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={columns.length - 1} className="tabTitle">
            <FormattedMessage id={title} />
          </Table.HeaderCell>
          <Table.HeaderCell className="buttonCell" textAlign="right">
            <If condition={dialog === "assignment"}>
              <Dialog
                handlers={handlers}
                i18n={i18n}
                dialog="duplicate"
                button={renderButton({
                  i18n,
                  title: "meta.actions.clone",
                  icon: "copy",
                  linkClass: "buttonIconLarge"
                })}
              />
            </If>
          </Table.HeaderCell>
          <Table.HeaderCell className="buttonCell" textAlign="right">
            <Dialog
              handlers={handlers}
              i18n={i18n}
              dialog={dialog}
              button={renderButton({
                i18n,
                title: "meta.actions.add",
                icon: "add",
                linkClass: "buttonIconLarge"
              })}
            />
          </Table.HeaderCell>
        </Table.Row>
        {filterOptions && (
          <Table.Row>
            {columns.map(col => (
              <Table.HeaderCell key={col.id}>
                <FormattedMessage id={col.id} />
                {list.length > 1 &&
                  renderFilterDropdown(
                    col.propName,
                    filterOptions,
                    applyFilter,
                    filters
                  )}
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell colSpan={BUTTON_COLUMNS} className="rightAlign">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                role="button"
                data-tooltip={i18n["documentsExport.filter.remove.title"]}
                onClick={() => setFilters({})}
                onKeyPress={() => setFilters({})}
                tabIndex={0}
              >
                <Icon name="remove" color="grey" size="large" />
              </a>
            </Table.HeaderCell>
          </Table.Row>
        )}
      </Table.Header>
    </>
  );
};

export const renderTableBody = ({
  i18n,
  list,
  columns,
  isLoading,
  handlers,
  dialog
}) => {
  if (isLoading) return renderTableLoader(columns.length + BUTTON_COLUMNS);
  const colSpan = columns.length < MIN_COLUMNS ? 2 : 1;

  // map dialogs to their respective models
  const dialogs = {
    assignment: "DocumentExportAssignment",
    signature: "DocumentExportSignature",
    export: "DocumentExport",
    style: "DocumentExportStyle"
  };

  return (
    <Table.Body>
      {list?.map(item => {
        const isPreviewActive = true;

        const { id, name, created_at, updated_at, ...duplicateItem } = item;

        return (
          <Table.Row key={item.id}>
            {columns.map(col => (
              <Table.Cell key={col.id} colSpan={colSpan} className="wrapper">
                {item[col.propName]}
              </Table.Cell>
            ))}
            <Table.Cell className="buttonCell">
              <IsVersionHistoryAccessible>
                <VersionHistoryLink
                  id={id}
                  type={dialogs[dialog]}
                  size="small"
                />
              </IsVersionHistoryAccessible>
              <Dialog
                model={item}
                handlers={handlers}
                i18n={i18n}
                dialog={dialog}
                button={renderButton({
                  i18n,
                  title: "meta.actions.edit",
                  icon: "setting"
                })}
              />
              {renderDeleteButton(i18n, handlers.handleDelete, item, dialog)}

              <If condition={dialog === "assignment" && !!isPreviewActive}>
                <Dialog
                  model={item}
                  handlers={{}}
                  i18n={i18n}
                  dialog="preview"
                  button={renderButton({
                    i18n,
                    title: "meta.actions.preview",
                    icon: "file"
                  })}
                />
              </If>

              <If condition={dialog === "assignment" && !isPreviewActive}>
                {renderDisabledIcon("file", "noPreview", i18n)}
              </If>
              <If condition={dialog !== "assignment"}>
                <Dialog
                  model={duplicateItem}
                  handlers={handlers}
                  i18n={i18n}
                  dialog={dialog}
                  button={renderButton({
                    i18n,
                    title: "meta.actions.clone",
                    icon: "copy"
                  })}
                />
              </If>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};

const Dialog = ({ model, button, handlers, dialog, i18n }) => {
  if (dialog === "assignment")
    return (
      <AssignmentDialog
        originalAssignment={model}
        onSubmit={handlers.handleSubmit}
        button={button}
      />
    );

  if (dialog === "export")
    return (
      <ExportDialog
        originalExport={model}
        onSubmit={handlers.handleSubmit}
        button={button}
      />
    );

  if (dialog === "style")
    return (
      <StyleDialog
        originalStyle={model}
        onSubmit={handlers.handleSubmit}
        button={button}
        i18n={i18n}
      />
    );

  if (dialog === "signature")
    return (
      <SignatureEditor
        originalSignature={model || emptySignatureModel}
        onSubmit={handlers.handleSubmit}
        button={button}
      />
    );

  if (dialog === "preview")
    return <PreviewDialog model={model} button={button} />;

  if (dialog === "duplicate") return <DuplicateDialog button={button} />;

  return null;
};

Dialog.propTypes = {
  i18n: I18nShape.isRequired,
  model: shape({
    id: number,
    exports: arrayOf(
      shape({
        id: string,
        label: string,
        options: arrayOf(
          shape({
            format: string,
            label: string,
            url: string
          })
        )
      })
    )
  }),
  handlers: shape({
    handleSubmit: func
  }).isRequired,
  dialog: string.isRequired,
  button: node.isRequired
};

Dialog.defaultProps = {
  model: null
};
