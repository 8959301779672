import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import { arrayOf, bool, func, instanceOf, number, object } from "prop-types";
import { I18nShape } from "shared/shapes/i18n.shape";
import DocumentAssignmentShape from "shared/shapes/documentAssignment.shape";
import { useLocalStorage } from "beautiful-react-hooks";
import { getAccount } from "shared/selectors";
import { DocumentAssignmentsResource } from "../../../actions/documentsActions";
import {
  getAssignmentFilterOptions,
  assignmentColumns
} from "../../../selectors/documentExports";
import { renderTableHeader, renderTableBody } from "./TablePartsRenderers";

import "./documentExport.scss";

const getValidFilters = filters => {
  const allowed_filters = [
    "projectName",
    "role",
    "exportName",
    "contentHeaderName",
    "signatureName",
    "styleName"
  ];
  const temp = {};
  allowed_filters.forEach(key => {
    if (filters[key]) temp[key] = filters[key];
  });
  return temp;
};

const AssignmentsTab = ({
  i18n,
  list,
  isLoading,
  documentAssignmentsResource,
  loadData,
  filterOptions,
  accountId
}) => {
  const [filtersA, setFilters] = useLocalStorage("documentExportFilters", {});
  const filters = useMemo(() => filtersA[accountId] || {}, [
    filtersA,
    accountId
  ]);

  const applyFilter = (name, vals) => {
    setFilters({});
    setFilters({ [accountId]: { ...filters, [name]: vals.value } });
  };

  const filterList = useMemo(() => {
    if (!filters) return list;
    let data = [...list];
    const validFilters = getValidFilters(filters);

    Object.keys(validFilters).forEach(key => {
      const value = filters[key];
      if (value) data = data.filter(x => x[key] === value);
    });
    return data;
  }, [filters, list]);

  const handlers = {
    handleSubmit: model => {
      return documentAssignmentsResource.save(model).then(() => {
        loadData();
      });
    },
    handleDelete: id => {
      return documentAssignmentsResource.remove(id).then(() => {
        loadData();
      });
    },
    handlePreview: () => {},
    handleDuplicate: () => {},
    handleDuplicateAll: () => {}
  };

  return (
    <Table>
      {renderTableHeader({
        i18n,
        columns: assignmentColumns,
        list,
        handlers,
        applyFilter,
        title: "documentsExport.menu.assignments.title",
        dialog: "assignment",
        filterOptions,
        filters: getValidFilters(filters),
        setFilters
      })}
      {renderTableBody({
        i18n,
        columns: assignmentColumns,
        list: filterList,
        isLoading,
        handlers,
        dialog: "assignment"
      })}
    </Table>
  );
};

AssignmentsTab.propTypes = {
  i18n: I18nShape.isRequired,
  list: arrayOf(DocumentAssignmentShape),
  isLoading: bool.isRequired,
  documentAssignmentsResource: instanceOf(DocumentAssignmentsResource)
    .isRequired,
  loadData: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterOptions: object.isRequired,
  accountId: number
};

AssignmentsTab.defaultProps = {
  list: [],
  accountId: undefined
};

const mapStateToProps = state => {
  return {
    filterOptions: getAssignmentFilterOptions(state),
    accountId: getAccount(state)?.data?.account?.id
  };
};

export default connect(mapStateToProps)(AssignmentsTab);
