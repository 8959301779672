import { get, keyBy } from "lodash";
import { bool, func, instanceOf, object } from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Button, Dropdown, Header, Icon } from "semantic-ui-react";
import ConfirmationDialog from "../../../shared/components/dialogs/ConfirmationDialog";
import ActivityController from "../../containers/activity/activityController";
import ActivityModel from "../../containers/activity/activityModel";
import "./activityTransitionControl.scss";
import CartTransitionDialog from "./CartTransitionDialog";

class ActivityTransitionControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transitionDialog: null
    };
    this.unmounted = false;
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  applyNextState = nextState => {
    const { ctxModel } = this.props;

    const targetStates = ctxModel.getStateById(nextState).getLineItemStatus();

    if (targetStates.length > 1) {
      return this.setState({
        transitionPrompt: { nextState, targetStates }
      });
    }
    return this.handleStateTransition(nextState, []);
  };

  handleStateTransition = (nextActivityState, lineItemTransitions) => {
    const { ctxModel, ctxCtrl, onTransition } = this.props;
    const update = {
      prev_state: ctxModel.getActivity().status,
      next_state: nextActivityState,
      transitions: lineItemTransitions || []
    };
    onTransition({ started: true });
    return ctxCtrl
      .triggerTransition(update)
      .then(response => {
        // this is necessary to have the costs for contractor-portal imports applied, although the
        // component gets unmounted
        onTransition({ completed: true });
        if (!this.unmounted) this.setState({ transitionDialog: null });
        return response;
      })
      .catch(response => {
        if (!this.unmounted)
          this.setState({ errorMessage: response.data.message }, () => {
            onTransition({ failed: true });
          });
      });
  };

  handleChangeStatus = (proxy, data) => {
    const targetState = data.value;

    this.applyNextState(targetState);
  };

  renderTransitionPrompt() {
    const { transitionPrompt } = this.state;
    if (!transitionPrompt) return null;
    const { i18n, ctxModel } = this.props;

    const buttons = [
      {
        id: "yes",
        label: "meta.actions.yeah",
        color: "green",
        onClick: cb => {
          this.handleStateTransition(transitionPrompt.nextState, []).then(cb);
        }
      },
      {
        id: "no",
        label: "activity.actions.configureTransitions.label",
        color: "grey",
        onClick: cb => {
          this.setState(
            {
              transitionDialog: { targetState: transitionPrompt.nextState },
              transitionPrompt: null
            },
            cb
          );
        }
      }
    ];

    const defaultStateId = transitionPrompt.targetStates[0];
    const lineItemLifeCycle = ctxModel.account.getLineItemLifeCycle();
    const defaultState = lineItemLifeCycle.getStateById(defaultStateId);

    const message = i18n["activity.message.transitions.bulkUpdate"].replace(
      "{state}",
      defaultState.label
    );

    return (
      <ConfirmationDialog
        title={i18n["activity.transitions.title"]}
        message={message}
        buttons={buttons}
        open
        onAsyncClose={() => {
          if (!this.unmounted) {
            this.setState({ transitionPrompt: null });
          }
        }}
      />
    );
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    if (!errorMessage) return null;
    const { i18n } = this.props;

    const buttons = [
      {
        id: "ok",
        label: i18n["meta.actions.accept"],
        color: "grey"
      }
    ];

    const message = i18n[errorMessage];

    return (
      <ConfirmationDialog
        title={i18n["meta.title.attention"]}
        message={message}
        buttons={buttons}
        open
        onAsyncClose={() => {
          this.setState({ errorMessage: null });
        }}
      />
    );
  }

  render() {
    const { i18n, ctxModel, disabled } = this.props;
    const { transitionDialog } = this.state;
    const status = ctxModel.getStatus();
    const state = ctxModel.getCurrentState();
    const transitions = keyBy(state.getTransitions(), "type");
    const isTerminal = state.terminal;
    const hasPrimaryButton = Boolean(transitions && transitions.primary);
    const hasSecondaryButton = Boolean(transitions && transitions.secondary);

    const statusOptions = ctxModel.getStates().map(ctxState => {
      return (
        <Dropdown.Item
          key={`status-menu-item-${ctxState.id}`}
          active={ctxState.id === status}
          value={ctxState.id}
          onClick={this.handleChangeStatus}
        >
          <Icon name="circle" style={{ color: get(ctxState, "phase.color") }} />{" "}
          {ctxState.label}
        </Dropdown.Item>
      );
    });

    return (
      <>
        <>
          {this.renderErrorMessage()}
          {this.renderTransitionPrompt()}
        </>
        {hasSecondaryButton && (
          <div className="left floated element">
            <Button
              disabled={disabled}
              id="abort-status"
              color="red"
              className="reject"
              basic
              onClick={() => this.applyNextState(transitions.secondary.target)}
            >
              <Icon name="remove" /> {transitions.secondary.label}
            </Button>
          </div>
        )}

        <div className="right floated element transitions">
          <div
            className={`primaryAction${
              isTerminal || !hasPrimaryButton ? " terminal" : ""
            }`}
          >
            {hasPrimaryButton && (
              <Button
                disabled={disabled}
                id="complete-status"
                className="done"
                color="green"
                onClick={() => this.applyNextState(transitions.primary.target)}
              >
                <Icon name="check" /> {transitions.primary.label}
              </Button>
            )}
            <Dropdown
              id="select-status"
              disabled={disabled}
              icon="dropdown"
              pointing="top right"
              className="status button"
              text={
                isTerminal || !hasPrimaryButton
                  ? i18n["activity.actions.changeStatus"]
                  : ""
              }
            >
              <Dropdown.Menu>
                <Header>
                  <FormattedMessage
                    id="activity.actions.proceedWith"
                    defaultMessage="activity.actions.proceedWith"
                  />{" "}
                  ...
                </Header>
                {statusOptions}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {transitionDialog && (
          <CartTransitionDialog
            i18n={i18n}
            transition={transitionDialog}
            ctxModel={ctxModel}
            open={transitionDialog}
            onSubmit={this.handleStateTransition}
            onClose={() => {
              this.setState({ transitionDialog: null });
            }}
          />
        )}
      </>
    );
  }
}

ActivityTransitionControl.defaultProps = {
  disabled: false
};

ActivityTransitionControl.propTypes = {
  i18n: object.isRequired,
  onTransition: func.isRequired,
  ctxCtrl: instanceOf(ActivityController).isRequired,
  ctxModel: instanceOf(ActivityModel).isRequired,
  disabled: bool
};

const mapStateToProps = (state, props) => {
  return {
    i18n: state.i18n,
    ctxCtrl: props.ctxCtrl,
    ctxModel: props.ctxModel
  };
};

export default connect(mapStateToProps)(ActivityTransitionControl);
