import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "semantic-ui-react";
import ActivityForm from "./ActivityForm";

class ActivityDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    activity: PropTypes.object,
    button: PropTypes.node,
    handleChange: PropTypes.func,
    actions: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.onSave = this.onSave.bind(this);
  }

  onSave(values) {
    if (this.props.activity && this.props.activity.id) {
      values = { ...values, id: this.props.activity.id };
    }

    this.props.actions.activity.save(values).then(() => {
      this.props.actions.activity.get(this.props.activity.id, true).then(() => {
        // close dialog (of updated item)
        this.handleToggleDialog(false);
      });
    });
  }

  handleToggleDialog(open) {
    this.setState({ ...this.state, open });
  }

  render() {
    const { i18n, button, activity } = this.props;
    const model = activity || {};

    return (
      <Modal
        data-component="activityDialog"
        closeIcon
        closeOnEscape
        closeOnDimmerClick
        trigger={button}
        open={this.state.open}
        onOpen={() => this.handleToggleDialog(true)}
        onClose={() => this.handleToggleDialog(false)}
      >
        <Modal.Header>
          <FormattedMessage
            id={
              activity && activity.id
                ? "activity.actions.edit"
                : "activity.actions.new"
            }
            defaultMessage={
              activity && activity.id
                ? "activity.actions.edit"
                : "activity.actions.new"
            }
          />
        </Modal.Header>
        <Modal.Content>
          <ActivityForm save={this.onSave} model={model} i18n={i18n} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default ActivityDialog;
