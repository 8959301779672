import { useSelector } from "react-redux";
import { getAccount } from "shared/selectors";
import { node, string } from "prop-types";

const IsSystemAdminOrHasSpecificRight = ({ right, children }) => {
  const account = useSelector(getAccount);

  if (account.isSystemAdmin() || account.hasAccountRoleRight(right))
    return children;

  return null;
};

IsSystemAdminOrHasSpecificRight.propTypes = {
  right: string.isRequired,
  children: node.isRequired
};

export default IsSystemAdminOrHasSpecificRight;
