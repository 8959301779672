import React, { useContext, useMemo } from "react";
import { bool, objectOf, node } from "prop-types";
import { useSelector } from "react-redux";
import { getAccount } from "../../selectors";

const FeatureToggleContext = React.createContext({});

export const useFeatureToggleContext = () => {
  return useContext(FeatureToggleContext);
};

export const useAccountFeatureToggle = () => {
  const account = useSelector(getAccount);

  return useMemo(
    () => ({
      isEnabled: toggle => {
        return account.isEnabled(toggle);
      }
    }),
    [account]
  );
};

const FeatureToggleProvider = ({ featureToggles, children }) => {
  return (
    <FeatureToggleContext.Provider value={{ featureToggles }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

FeatureToggleProvider.propTypes = {
  children: node.isRequired,
  featureToggles: objectOf(bool).isRequired
};

export default FeatureToggleProvider;
