/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IndexLink, Link, locationShape } from "react-router";
import { Dropdown, Menu, Icon, Label, Sidebar } from "semantic-ui-react";
import { getAccount, getUser, getNotifications } from "shared/selectors";
import { If } from "shared/components/elements/Conditions";
import logoutAction from "builder_portal/actions/logoutAction";
import { FormattedMessage, useIntl } from "react-intl";
import HasEditAccountsSettingsRight from "shared/components/authorization/HasEditAccountsSettingsRight";
import HasAccessStatisticsCenterRight from "shared/components/authorization/HasAccessStatisticsCenterRight";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import HasAccessAPIDocumentationRight from "shared/components/authorization/HasAccessAPIDocumentationRight";
import { bool } from "prop-types";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { withRouter } from "shared/helpers/withRouter";
import logo from "../../../../assets/images/bau_digital_logo_bemusterung_neg.png";
import AccountSelector from "./AccountSelector";
import {
  IsNormalUser,
  IsSystemAdmin
} from "../../../shared/components/authorization/IsSystemAdmin";
import IsBetaPreview from "../../../shared/components/elements/IsBetaPreview";
import "./primaryNavigationV2.scss";

const PrimaryTopNavigationV2 = ({ profilePolling, location }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const accountObject = useSelector(getAccount);
  const alerts = useSelector(getNotifications);
  const account = useMemo(() => accountObject?.data?.account || {}, [
    accountObject
  ]);
  const user = useSelector(getUser);

  const isLoggedIn = !!accountObject?.data?.auth_token;

  const getShortName = name => {
    const numberOfChars = 32;
    if (name.length > numberOfChars) {
      return `${name.substring(0, numberOfChars)} ...`;
    }
    return name;
  };

  const toggleProp = {
    onClick: () => setSidebarVisible(false)
  };

  const upperPadding =
    accountObject.isEnabled("show_page_layout_v2") &&
    accountObject.isEnabled("payment_reminder")
      ? { paddingTop: "6.2em" }
      : {};

  const renderSupportUtils = () => {
    return (
      <IsSystemAdmin>
        <Dropdown.Item>
          <Link to="/supportUtils" {...toggleProp}>
            <Icon name="exclamation" />
            <FormattedMessage
              id="menu.main.supportUtils"
              defaultText="menu.main.supportUtils"
            />
          </Link>
        </Dropdown.Item>
      </IsSystemAdmin>
    );
  };

  const renderAccountAdmin = () => {
    return (
      <HasEditAccountsSettingsRight>
        <Dropdown.Item>
          <Link to="/account" {...toggleProp}>
            <Icon name="setting" />
            <FormattedMessage
              id="menu.main.accountAdmin"
              defaultText="menu.main.accountAdmin"
            />
          </Link>
        </Dropdown.Item>
      </HasEditAccountsSettingsRight>
    );
  };

  const renderAPIDocs = () => {
    return (
      <FeatureToggleActive featureToggleName="api_documentation">
        <HasAccessAPIDocumentationRight>
          <Dropdown.Item>
            <Link to="/api_documentation" {...toggleProp}>
              <Icon name="retweet" />
              <FormattedMessage id="menu.main.API" defaultText="API" />
            </Link>
          </Dropdown.Item>
        </HasAccessAPIDocumentationRight>
      </FeatureToggleActive>
    );
  };

  const renderSettingsLink = () => {
    if (user) {
      return (
        <Dropdown.Item>
          <Link to="/profile" {...toggleProp}>
            <Icon name="user" />
            <FormattedMessage
              id="menu.main.myProfile"
              defaultText="menu.main.myProfile"
            />
          </Link>
        </Dropdown.Item>
      );
    }
    return undefined;
  };

  const renderProductsLink = () => {
    let recentProductCatalogIds = JSON.parse(
      localStorage.getItem("recentProjectCatalogIds")
    );

    if (!recentProductCatalogIds?.[account.id]) {
      recentProductCatalogIds = {
        [account.id]: { last: null }
      };
    }
    const lastProjectId = recentProductCatalogIds[account.id].last;
    const isOnCatalogTab = location.pathname.indexOf("product_groups") > -1;
    const isNotOnProductsPage = location.pathname.indexOf("products") === -1;

    let link = `/products?page=1`;
    if (
      accountObject?.hasAccessProjectCatalogRight() &&
      lastProjectId &&
      (isOnCatalogTab || isNotOnProductsPage)
    )
      link = `/products/${lastProjectId}/product_groups`;
    return (
      <Link
        to={link}
        activeClassName="active item"
        className="item"
        {...toggleProp}
      >
        <Icon name="box" size="large" color="inverted" />
      </Link>
    );
  };

  const renderLinks = () => {
    return (
      <>
        <Link
          to="/projects"
          activeClassName="active item"
          className="item"
          {...toggleProp}
        >
          <Icon name="building" size="large" style={{ color: "#FFF" }} />
        </Link>

        {user.masterdata_access && (
          <Link
            to="/contractors"
            activeClassName="active item"
            className="item"
            {...toggleProp}
          >
            <Icon name="database" size="large" color="inverted" />
          </Link>
        )}

        {renderProductsLink()}
        <div className="mobile-split" />
        <Link
          to="/messages"
          activeClassName="active item"
          className="item messages compact"
          {...toggleProp}
          style={{ position: "relative", float: "right" }}
        >
          {alerts.messages > 0 && (
            <Label
              id="notification-count"
              size="mini"
              circular
              color="red"
              className="badge"
            >
              {alerts.messages}
            </Label>
          )}{" "}
          <Icon name="envelope" size="large" color="inverted" />
        </Link>
        <div className="mobile-split" />
        <FeatureToggleActive featureToggleName="show_statistics_20">
          <HasAccessStatisticsCenterRight>
            <a
              href="/analytics_portal"
              className="item trash compact"
              {...toggleProp}
            >
              <Icon name="pie graph" size="large" color="inverted" />
            </a>
          </HasAccessStatisticsCenterRight>
        </FeatureToggleActive>
        <div className="mobile-split" />
        <Link
          to="/notifications"
          activeClassName="active item"
          className="item notifications compact"
          {...toggleProp}
          style={{ position: "relative", float: "right" }}
        >
          {alerts.notifications > 0 && (
            <Label
              id="notification-count"
              size="mini"
              circular
              color="red"
              className="badge"
            >
              {alerts.notifications}
            </Label>
          )}{" "}
          <Icon name="bell" size="large" color="inverted" />
        </Link>
        <div className="mobile-split" />

        <IsSystemAdmin>
          <Link
            to="/project_issues"
            activeClassName="active item"
            className="item compact"
            {...toggleProp}
          >
            <Icon name="warning circle" size="large" color="inverted" />
          </Link>
        </IsSystemAdmin>
        <IsSystemAdminOrHasSpecificRight right="ext_allow_edit_configurator_config">
          <Link
            to="/trashbin"
            activeClassName="active item"
            className="item trash compact"
            {...toggleProp}
          >
            <Icon name="trash" size="large" color="inverted" />
          </Link>
        </IsSystemAdminOrHasSpecificRight>
      </>
    );
  };

  const renderHelp = () => {
    return (
      <Dropdown
        icon="help circle"
        style={{ color: "white", fontSize: "1.45rem", marginLeft: "14px" }}
        className="item"
      >
        <Dropdown.Menu className="sub-menu">
          <Dropdown.Item
            text={intl.formatMessage({ id: "menu.main.help" })}
            icon="help"
            onClick={() =>
              window.open("https://baudigital.zendesk.com", "_help_center")
            }
          />
          <Dropdown.Item
            text={intl.formatMessage({ id: "menu.main.news" })}
            icon="heart outline"
            onClick={() =>
              window.open(
                "https://baudigital.zendesk.com/hc/de/categories/360000138293-Produktupdates",
                "_product_news"
              )
            }
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderMobilMenu = () => {
    return (
      <div className="mobile-menu">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IndexLink to="/">
            <div
              style={{
                backgroundImage: `url(${logo})`,
                width: "365px",
                height: "35px",
                marginTop: "15px"
              }}
            />
          </IndexLink>
          <div
            style={{ marginTop: "10px", fontSize: "1.5rem", cursor: "pointer" }}
          >
            <a className="toc" onClick={() => setSidebarVisible(false)}>
              <Icon name="close" color="inverted" />
            </a>
          </div>
        </div>
        {renderLinks()}
        {renderHelp()}

        {renderSettingsLink()}
        {renderAccountAdmin()}
        {renderAPIDocs()}
        {renderSupportUtils()}
        <IsBetaPreview>
          <Dropdown.Item>
            <Link to="/file_imports" {...toggleProp}>
              <Icon name="file outline" />
              <FormattedMessage id="menu.main.jobs" defaultText="Jobs" />
            </Link>
          </Dropdown.Item>
        </IsBetaPreview>
        <Dropdown.Item>
          <Link id="logout" onClick={() => new logoutAction(dispatch)(true)}>
            <Icon name="power" size="large" color="inverted" />
            <FormattedMessage
              id="menu.main.logout"
              defaultText="menu.main.logout"
            />
          </Link>
        </Dropdown.Item>
      </div>
    );
  };

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: 100 }}>
      <div data-component="primaryNavigationV2">
        <div className="ui menu topbar-fixed">
          <div>
            <IndexLink to="/">
              <div
                style={{
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  marginLeft: "1rem",
                  height: "100%",
                  maxHeight: "3rem",
                  width: "300px",
                  marginTop: "15px"
                }}
              />
            </IndexLink>
          </div>

          <div className="right-menu">
            <a className="toc" onClick={() => setSidebarVisible(true)}>
              <Icon name="sidebar" color="inverted" />
            </a>
          </div>

          <If condition={isLoggedIn}>
            <Menu.Menu position="right">
              <AccountSelector profilePolling={profilePolling}>
                <IsNormalUser>{user.name}</IsNormalUser>
                <IsSystemAdmin>
                  {user.name}
                  <br />
                  <small>
                    {getShortName(account.name)} ({account.id})
                  </small>
                </IsSystemAdmin>
              </AccountSelector>

              <Dropdown id="nav-settings" item icon="setting">
                <Dropdown.Menu>
                  {renderSettingsLink()}
                  {renderAccountAdmin()}
                  {renderAPIDocs()}
                  {renderSupportUtils()}
                  <IsBetaPreview>
                    <Dropdown.Item>
                      <Link to="/file_imports" {...toggleProp}>
                        <Icon name="file outline" />
                        <FormattedMessage
                          id="menu.main.jobs"
                          defaultText="Jobs"
                        />
                      </Link>
                    </Dropdown.Item>
                  </IsBetaPreview>
                  <Dropdown.Item>
                    <Link
                      id="logout"
                      onClick={() => new logoutAction(dispatch)(true)}
                    >
                      <Icon name="power" size="large" color="inverted" />
                      <FormattedMessage
                        id="menu.main.logout"
                        defaultText="menu.main.logout"
                      />
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </If>
        </div>

        <div data-component="leftNavigation">
          <div className="sidebar-fixed ">
            <div className="upper-icons" style={upperPadding}>
              {renderLinks()}
            </div>
            <div className="lower-icons">{renderHelp()}</div>
          </div>
        </div>
      </div>

      <Sidebar
        as={Menu}
        animation="push"
        width="wide"
        visible={isSidebarVisible}
        vertical
        inverted
        style={{ width: "100%" }}
      >
        {renderMobilMenu()}
      </Sidebar>
    </div>
  );
};

PrimaryTopNavigationV2.propTypes = {
  profilePolling: bool,
  location: locationShape
};

PrimaryTopNavigationV2.defaultProps = {
  profilePolling: false,
  location: {}
};

export default withRouter(PrimaryTopNavigationV2);
