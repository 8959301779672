import { createSelector } from "reselect";
import { get, keyBy, sortBy, flatMap } from "lodash";
import createCachedSelector from "re-reselect";
import { getProjectBranding } from "./project";
import { getLineItems, getSections, getSubLineItems } from "./unit";
import { getPageContent } from "./base";

const DEFAULT_ROOM_BOOK = {};
const getRoomBook = createSelector(
  [getPageContent],
  pageContent => pageContent?.room_book ?? DEFAULT_ROOM_BOOK
);

export const getRoomBookSectionMap = createSelector(
  [getPageContent],
  pageContent => {
    const sections = pageContent?.room_book?.sections;
    if (!sections) return {};
    const sectionMap = {};

    sections.forEach(section => {
      const temp = [];
      temp.push(section.id);
      section.sections.forEach(subSection => {
        temp.push(subSection.id);
        subSection.line_items.forEach(lineItem => {
          temp.push(lineItem.id);
          lineItem.sub_line_items.forEach(subLineItem => {
            temp.push(subLineItem.id);
          });
        });
      });
      sectionMap[section.display_number] = temp;
    });

    return sectionMap;
  }
);

export const getConfigurators = createSelector(
  [getProjectBranding, getSections],
  (projectBranding, sections) =>
    new Map(
      [...projectBranding.getConfigurators()].filter(([, configurator]) =>
        configurator.sections.some(configuratorSection =>
          sections.some(
            unitSection => unitSection.selector === configuratorSection
          )
        )
      )
    )
);

export const getRoomId = (state, props) => {
  return props?.roomId || props.routeParams?.roomId || props.params?.roomId;
};

export const getConfiguratorId = createCachedSelector(
  [getConfigurators, getRoomId],
  (configurators, roomId) => {
    const configurator = configurators.get(roomId);
    return configurator?.id;
  }
)(getRoomId);

export const getConfigurator = createCachedSelector(
  getConfiguratorId,
  getConfigurators,
  (configuratorId, configurators) =>
    configuratorId && configurators.get(configuratorId)
)(getConfiguratorId);

export const getConfiguratorTitle = (state, props) =>
  getConfigurator(state, props)?.title;

export const getIsRoomWithMapping = createSelector(
  [getConfigurators, getRoomId],
  (configurators, roomId) => {
    return !!configurators.get(roomId);
  }
);

const getConfiguratorSectionIds = createCachedSelector(
  [getConfigurator, getSections],
  (configurator, sections) => {
    return sections
      .filter(section =>
        get(configurator, "sections", []).includes(section.selector)
      )
      .map(section => section.id);
  }
)(getConfiguratorId);

const getConfiguratorLineItemsById = createCachedSelector(
  [getConfiguratorSectionIds, getLineItems],
  (sectionIds, lineItems) => {
    return keyBy(
      lineItems.filter(lineItem => sectionIds.includes(lineItem.sectionId)),
      "id"
    );
  }
)(getConfiguratorId);

const getAllConfiguratorLineItems = createCachedSelector(
  [getConfiguratorSectionIds, getLineItems],
  (sectionIds, lineItems) => {
    return lineItems.filter(
      lineItem =>
        sectionIds.includes(lineItem.sectionId) &&
        !lineItem.isReplaced &&
        (!lineItem.isReplacement ||
          !lineItem.replacementTo.isReplaced ||
          lineItem.replacementTo.replacedById === lineItem.id)
    );
  }
)(getConfiguratorId);

const getConfiguratorLineItemIds = createCachedSelector(
  [getAllConfiguratorLineItems],
  lineItems => lineItems.map(lineItem => lineItem.id)
)(getConfiguratorId);

const getConfiguratorCategories = createCachedSelector(
  [getConfigurator],
  ({ views }) => {
    const accu = new Set();
    views.forEach(view =>
      view.productCategories.forEach(category => accu.add(category))
    );
    return accu;
  }
)(getConfiguratorId);

function getOptionsWithMapping(
  subLineItem,
  productMapping,
  configuratorImageMapping
) {
  const {
    options,
    productGroup,
    status,
    hasBuyerSelection,
    quantity
  } = subLineItem;

  const hasCart = !status.selectable;
  let atLeastOneWithMapping = false;
  const mappingCategoriesSet = new Set();

  if (!productGroup) {
    return { atLeastOneWithMapping, optionsWithMapping: [] };
  }

  const optionsWithMapping = options.reduce((akku, option) => {
    const [category, unmappedImageId] = get(
      productMapping,
      [productGroup.id, option.id],
      []
    );
    const imageId = get(configuratorImageMapping, [category, unmappedImageId]);
    if (category) {
      atLeastOneWithMapping = true;
      mappingCategoriesSet.add(category);
    }
    akku.push({
      ...option,
      category,
      imageId,
      hasBuyerSelection,
      status,
      hasCart,
      quantity,
      hasConfiguratorMapping: !!imageId,
      subLineItemId: subLineItem.id
    });
    return akku;
  }, []);
  const mappingCategories = [...mappingCategoriesSet];
  return { atLeastOneWithMapping, optionsWithMapping, mappingCategories };
}

const getConfiguratorImageMapping = state =>
  state.pageContent.configuratorImageMapping;

const getProductMapping = (state, props) =>
  getProjectBranding(state, props).getProductMapping();
const getConfiguratorProductSorting = createCachedSelector(
  [getConfigurator],
  configurator =>
    get(configurator, "productSorting", [
      "selected",
      "mappingAvailable",
      "order"
    ])
)(getConfiguratorId);

const getConfiguratorHideSubLineItemsWithoutMapping = createCachedSelector(
  [getConfigurator],
  configurator => get(configurator, "hideSubLineItemsWithoutMapping", false)
)(getConfiguratorId);

export const getConfiguratorSubLineItems = createCachedSelector(
  [
    getConfiguratorLineItemIds,
    getConfiguratorLineItemsById,
    getSubLineItems,
    getProductMapping,
    getConfiguratorImageMapping,
    getConfiguratorProductSorting,
    getConfiguratorHideSubLineItemsWithoutMapping,
    getConfiguratorCategories
  ],
  (
    lineItemIds,
    lineItems,
    subLineItems,
    productMapping,
    configuratorImageMapping,
    productSorting,
    hideSubLineItemsWithoutMapping,
    configuratorCategories
  ) =>
    subLineItems
      .filter(
        subLineItem =>
          subLineItem.isVisible && lineItemIds.includes(subLineItem.lineItemId)
      )
      .reduce((akku, subLineItem) => {
        const {
          atLeastOneWithMapping,
          optionsWithMapping,
          mappingCategories
        } = getOptionsWithMapping(
          subLineItem,
          productMapping,
          configuratorImageMapping
        );

        let selectedOption = optionsWithMapping.find(
          option => option.isActualSelection
        );

        // fall back to first acceptable product if no product was found
        if (!selectedOption) {
          selectedOption = optionsWithMapping.find(
            option => option.isAcceptableProduct
          );
        }

        const orderFunctions = productSorting.map(orderName => {
          switch (orderName) {
            case "selected":
              return option => option !== selectedOption;
            case "mappingAvailable":
              return option => !option.hasConfiguratorMapping;
            default:
              return orderName;
          }
        });
        const sortedOptions = sortBy(optionsWithMapping, orderFunctions);

        const lineItem = lineItems[subLineItem.line_item_id];
        const { isOptional, isReplaced, isReplacement, isActivated } = lineItem;

        if (
          !isReplaced &&
          (!hideSubLineItemsWithoutMapping ||
            (atLeastOneWithMapping &&
              mappingCategories.some(category =>
                configuratorCategories.has(category)
              )))
        ) {
          // keep potential hidden 3D-Items in Mind for configured-Status: https://gitlab.com/baudigital/plano/-/issues/1200
          akku.push({
            ...subLineItem,
            isDecided:
              (hideSubLineItemsWithoutMapping && !atLeastOneWithMapping) ||
              subLineItem.isDecided,
            lineItem,
            isOptional,
            isReplaced,
            isReplacement,
            isActivated,
            atLeastOneWithMapping,
            options: sortedOptions,
            selectedOption,
            key: subLineItem.id.toString()
          });
        }
        return akku;
      }, [])
)(getConfiguratorId);

// Returns an array of ConfiguratorJourneyConfigShape
export const getConfiguratorJourneyConfigs = createSelector(
  [getRoomBook],
  roomBook => {
    return roomBook.configurator_journey?.map(c => {
      return {
        ...c,
        // this mapping was constructed on time-shortage.
        // The Backend changed the Variable names and there was no time to refactor it properly.
        renderId: c.render_id,
        roomId: c.room_id
      };
    });
  }
);

export const getConfiguratorJourneyRoomConfig = createSelector(
  [getConfiguratorJourneyConfigs, getRoomId],
  (journeyConfig, selectedRoomId) => {
    return journeyConfig?.find(jc => jc.roomId === selectedRoomId);
  }
);

export const getConfiguratorRoomJourney = createSelector(
  [
    getConfiguratorJourneyRoomConfig,
    getLineItems,
    getSubLineItems,
    getConfiguratorSubLineItems
  ],
  (journeyConfig, lineItems, subLineItems, configuratorSubLineItems) => {
    return journeyConfig?.sub_line_item_ids
      .map(sliId => {
        const subLineItemIdMatcher = subLineItem => subLineItem.id === sliId;
        const newSubLineItem =
          configuratorSubLineItems?.find(subLineItemIdMatcher) ||
          subLineItems?.find(subLineItemIdMatcher);

        if (newSubLineItem) {
          // we will need the lineItem Object, not just ID
          newSubLineItem.lineItem = newSubLineItem.lineItem
            ? newSubLineItem.lineItem
            : lineItems.find(li => li.id === newSubLineItem.lineItemId);
        }

        return newSubLineItem;
      })
      .filter(Boolean); // nullfilter
  }
);

export const getVisibleConfiguratorLineItems = createCachedSelector(
  [
    getAllConfiguratorLineItems,
    getConfiguratorSubLineItems,
    getConfiguratorHideSubLineItemsWithoutMapping
  ],
  (lineItems, subLineItems, hideSubLineItemsWithoutMapping) =>
    lineItems.reduce((akku, lineItem) => {
      if (
        !lineItem.isReplaced &&
        (!lineItem.isReplacement ||
          !lineItem.replacementTo.isReplaced ||
          lineItem.replacementTo.replacedById === lineItem.id) &&
        subLineItems.some(
          subLineItem =>
            subLineItem.lineItemId === lineItem.id &&
            subLineItem.atLeastOneWithMapping
        )
      ) {
        const { subLineItemIds, ...rest } = lineItem;
        const filteredSubLineItemIds = subLineItemIds.filter(id =>
          subLineItems.some(subLineItem => subLineItem.id === id)
        );

        // keep potential hidden 3D-Items in Mind for configured-Status: https://gitlab.com/baudigital/plano/-/issues/1200
        const allSubLineItemsDecided = subLineItems
          .filter(({ lineItemId }) => lineItemId === lineItem.id)
          .every(({ atLeastOneWithMapping, isDecided }) =>
            hideSubLineItemsWithoutMapping
              ? !atLeastOneWithMapping || isDecided
              : isDecided
          );

        akku.push({
          ...rest,
          subLineItemIds: filteredSubLineItemIds,
          isDecided: allSubLineItemsDecided
        });
      }

      return akku;
    }, [])
)(getConfiguratorId);

export const getConfiguratorCardInfo = createSelector(
  [
    getConfigurator,
    getConfiguratorSubLineItems,
    getConfiguratorHideSubLineItemsWithoutMapping
  ],
  (configurator, subLineItems, hideSubLineItemsWithoutMapping) => {
    const total = subLineItems.reduce((sum, item) => sum + item.total, 0);

    // keep potential hidden 3D-Items in Mind for configured-Status: https://gitlab.com/baudigital/plano/-/issues/1200
    const configured = subLineItems
      .filter(({ isActivated }) => isActivated)
      .every(({ atLeastOneWithMapping, isDecided }) =>
        hideSubLineItemsWithoutMapping
          ? !atLeastOneWithMapping || isDecided
          : isDecided
      );

    return {
      total,
      configured
    };
  }
);

const extractAllImageCategories = subLineItems => {
  const imageCategories = new Set();
  subLineItems.forEach(subLineItem => {
    subLineItem.options.forEach(({ category }) =>
      imageCategories.add(category)
    );
  });
  return imageCategories;
};

const extractActiveImageCategories = subLineItems => {
  const imageCategories = new Set();
  subLineItems.forEach(subLineItem => {
    if (
      !subLineItem.isReplaced &&
      (!subLineItem.isReplacement || subLineItem.isActivated)
    ) {
      subLineItem.options.forEach(({ category }) => {
        imageCategories.add(category);
      });
    }
  });
  return imageCategories;
};

const extractNonOptionalImageCategories = subLineItems => {
  const imageCategories = new Set();
  subLineItems.forEach(subLineItem => {
    if (!subLineItem.isOptional || subLineItem.isActivated) {
      subLineItem.options.forEach(({ category }) => {
        imageCategories.add(category);
      });
    }
  });
  return imageCategories;
};

export const getConfiguratorViews = createSelector(
  [getConfigurator, getConfiguratorSubLineItems, getConfiguratorImageMapping],
  (configurator, subLineItems, configuratorImageMapping) => {
    if (!configurator) {
      return [];
    }

    const { views } = configurator;

    const allProductImageCategories = extractAllImageCategories(subLineItems);
    const activeProductImageCategories = extractActiveImageCategories(
      subLineItems
    );
    const nonOptionalImageCategories = extractNonOptionalImageCategories(
      subLineItems
    );

    const viewsWithRelevantCategories = views.map(view => ({
      ...view,
      activeCategories: new Set(
        get(view, "productCategories", []).filter(cat =>
          activeProductImageCategories.has(cat)
        )
      ),
      relevantCategories: new Set(
        get(view, "productCategories", []).filter(cat =>
          allProductImageCategories.has(cat)
        )
      ),
      nonOptionalCategories: new Set(
        get(view, "productCategories", []).filter(cat =>
          nonOptionalImageCategories.has(cat)
        )
      ),
      defaultImages: {
        room: get(configuratorImageMapping, ["room", view.room]),
        ...view.productCategories.reduce((akku, cat) => {
          akku[cat] = get(configuratorImageMapping, [
            cat,
            `${cat.toUpperCase()}_missing`
          ]);
          return akku;
        }, {})
      }
    }));

    // If only one view, shortcut return that one
    if (viewsWithRelevantCategories.length === 1) {
      return viewsWithRelevantCategories;
    }

    const sortedViews = sortBy(viewsWithRelevantCategories, ["order", "title"]);

    // Find the first view with the most matching categories
    const bestView = sortedViews.reduce((best, current) =>
      current.nonOptionalCategories.size > best.nonOptionalCategories.size
        ? current
        : best
    );

    // Filter out all views that don't have any category the best one doesn't have
    const relevantViews = sortedViews.filter(
      view =>
        [...view.relevantCategories].filter(
          cat => !bestView.relevantCategories.has(cat)
        ).length > 0
    );

    // Mark all views where the extra categories are not active as deactivated
    const mappendViews = relevantViews.map(view => ({
      ...view,
      active: [...view.activeCategories].some(
        cat => !bestView.activeCategories.has(cat)
      ),
      optional: ![...view.nonOptionalCategories].some(
        cat => !bestView.relevantCategories.has(cat)
      )
    }));

    // Return the best view first, and all other ones sorted
    const bestAndMappedViews = [
      { ...bestView, active: true, optional: false },
      ...mappendViews
    ];

    // Order again by order and optionality. This way the order can be overwritten manually and
    // optional views are put to the back
    return sortBy(bestAndMappedViews, ["order", "optional"]);
  }
);

export const getActiveConfiguratorViews = createSelector(
  [getConfiguratorViews],
  views => views.filter(view => view.active)
);

export const getConfiguratorTemplateParams = createSelector(
  [getConfigurator, getRoomBook],
  (configurator, roomBook) => {
    if (!configurator || !roomBook) return [];

    const { templateParams = [] } = configurator;
    const { source_id: sourceId } = roomBook;
    const entry = templateParams.find(params => params.source_id === sourceId);
    return entry?.params;
  }
);

export const getConfiguratorPreviewSelection = state =>
  state.configuratorPreviewSelection;

export const getOptionsForImageUrl = createSelector(
  [getConfiguratorSubLineItems, getConfiguratorPreviewSelection],
  (subLineItems, configuratorPreviewSelection) => {
    const optionsForImageUrl = subLineItems
      .filter(sli => !sli.isOptional || sli.isActivated)
      .map(sli => {
        if (configuratorPreviewSelection?.[sli.id]) {
          return sli.options.find(
            option => configuratorPreviewSelection[sli.id] === option.id
          );
        }
        return sli.selectedOption;
      });

    return optionsForImageUrl;
  }
);

export const getRoomProgress = createSelector(
  [getConfiguratorRoomJourney],
  subLineItems => {
    const sampledSubLineItems = subLineItems?.filter(
      sli => sli.hasBuyerSelection
    ).length;

    return sampledSubLineItems / subLineItems?.length || 0;
  }
);

export const getUnitProgress = createSelector(
  [getConfiguratorJourneyConfigs, getSubLineItems],
  (journeyConfig, subLineItems) => {
    const configurableItemIds = flatMap(
      journeyConfig,
      c => c.sub_line_item_ids
    );
    const configurableItems = subLineItems.filter(s =>
      configurableItemIds.includes(s.id)
    );
    const configuredItems = configurableItems.filter(s => s.hasBuyerSelection);
    return configurableItems.length
      ? configuredItems.length / configurableItems.length
      : 0;
  }
);
