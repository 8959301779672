import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";
import {
  UPDATE_MESSAGE,
  UPDATE_PAGE_CONTENT
} from "../../shared/constants/appConstants";

export class UnitsResource extends Resources {
  constructor(dispatch, projectId) {
    super(dispatch, "unit", `/api/v1/projects/${projectId}/units`);
  }

  batchCreate(payload) {
    return apiRequest.patch(`${this.url}/batch`, this.dispatch, {
      units: payload
    });
  }
}

export class UnitResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "unit", "/api/v1/units");
  }

  removeAttachment(attachmentId, resourceId) {
    const url = `${this.url}/${resourceId}/attachments/${attachmentId}`;
    return apiRequest
      .delete(url, this.dispatch)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  updateAttachment(values, resourceId) {
    if (!values || !values.attachment || !values.attachment.id) {
      console.error("updateAttachment failed payload not given");
      return false;
    }
    const url = `${this.url}/${resourceId}/attachments/${values.attachment.id}`;
    return apiRequest
      .patch(url, this.dispatch, values)
      .then(this.propagateUpdate.bind(this))
      .catch(() => {
        this.dispatch({
          type: UPDATE_MESSAGE,
          payload: {
            title: "message.errorLoad.title",
            body: "message.errorLoad.body"
          }
        });
      });
  }

  getStatistics(id) {
    const url = `${this.url}/${id}/statistics`;
    return apiRequest.get(url, this.dispatch).then(response => {
      this.dispatch({
        type: UPDATE_PAGE_CONTENT,
        payload: { statistics: response.data || undefined }
      });
      return response;
    });
  }

  getApplicableActivities(id) {
    const url = `${this.url}/${id}/applicable_activities`;
    return apiRequest
      .get(url, this.dispatch)
      .then(this.propagateUpdate.bind(this));
  }
}
