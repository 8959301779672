import React, { useState, useRef } from "react";
import { string, func, objectOf, arrayOf, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { Modal, Button, Form, Input, Segment } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getI18N } from "shared/selectors";
import { cloneDeep, flatMap, sortBy } from "lodash";
import { I18nShape } from "shared/shapes/i18n.shape";
import Field from "../../../shared/components/forms/FieldComponent";
import { FormDefinition } from "../../../shared/components/forms/FormDefinition";

function ExportConfigDialog({
  open,
  onSubmit,
  configArguments,
  onClose,
  i18n,
  title,
  trades,
  contractors,
  contractorAssignments
}) {
  const [modelState, setModelState] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const refCustomFieldsArg = useRef();

  const getCustomFields = arg => {
    if (!arg?.custom_fields) return [];
    return arg.custom_fields.reduce((accu, customGroup, index) => {
      return accu.concat(
        customGroup.map(customField => ({
          id: customField.id,
          label: customField.label,
          group: index
        }))
      );
    }, []);
  };

  const setDefaultCustomInfoValue = values => {
    if (activeIndex > 0) return values;
    const customFields = getCustomFields(refCustomFieldsArg.current);
    const firstGroupFields = customFields.filter(field => field.group === 0);
    if (firstGroupFields.length === 1) {
      const customInfoId = firstGroupFields[0]?.id;
      // eslint-disable-next-line no-param-reassign
      values[customInfoId] = true;
    }
    return values;
  };

  function getTradeOptions() {
    const orderedTrades = sortBy(trades, "label");

    function tradeToOptions(trade) {
      const assignedContractors = contractorAssignments
        .filter(contractorAssignment => {
          return contractorAssignment.trade === trade.id;
        })
        .map(contractorAssignment => {
          return contractors.find(contractor => {
            return contractor.id === contractorAssignment.contractor_id;
          });
        });

      const disabled = assignedContractors.length > 0;
      const tradeOption = {
        key: trade.id,
        value: trade.id,
        text: trade.label,
        "data-model": "trade",
        "data-id": trade.id,
        icon: "paper hand",
        size: "small",
        disabled
      };
      const contractorOptions = assignedContractors.map(contractor => ({
        key: `${trade.id}-${contractor.id}`,
        value: `${trade.id}-${contractor.id}`,
        text: contractor.name,
        "data-model": "contractor",
        "data-id": contractor.id,
        icon: "shipping",
        size: "small",
        className: "indented"
      }));

      return [tradeOption, ...contractorOptions];
    }

    return flatMap(orderedTrades, tradeToOptions);
  }

  function renderArgumentByType(form, argument, disabled) {
    // eslint-disable-next-line no-param-reassign
    if (disabled) form.fields[argument.id].props.disabled = disabled;
    if (argument.type === "trades") {
      const tradeOption = form.fields[argument.id];
      tradeOption.props.options = getTradeOptions();
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Select" {...tradeOption} />
        </Form.Field>
      );
    }
    if (argument.type === "boolean") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Checkbox" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "date" || argument.type === "string") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="Input" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    if (argument.type === "text") {
      return (
        <Form.Field key={argument.id} width="16">
          <Field component="TextArea" {...form.fields[argument.id]} />
        </Form.Field>
      );
    }
    return <span key={argument.id} />;
  }

  const resetOtherCustomFields = index => {
    const customFields = getCustomFields(refCustomFieldsArg.current);

    const closedFields = customFields.filter(field => field.group !== index);
    closedFields.forEach(field => {
      setModelState(prevModel => ({ ...prevModel, [field.id]: "" }));
    });
  };

  const handleClick = index => {
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
    resetOtherCustomFields(newIndex);
  };

  const renderArgument = (form, argument) => {
    if (argument.type === "custom_fields") {
      return (
        <div key={argument.id}>
          {argument.custom_fields?.reduce((accu, customGroup, index) => {
            const active = activeIndex === index;
            const hasContent = customGroup[0]?.type !== "info";
            const cb_title = hasContent ? "" : customGroup[0].label;
            const checkboxMarginTop = hasContent
              ? { marginTop: "0.2rem" }
              : { marginTop: "0.6rem" };

            const content = customGroup.reduce((accuContent, customField) => {
              if (!customField) accuContent.push(<span />);
              accuContent.push(
                renderArgumentByType(form, customField, !active)
              );
              return accuContent;
            }, []);

            accu.push(
              <Segment
                style={{ display: "flex", marginBottom: "15px" }}
                key={`content-${customGroup[0].id}`}
              >
                <div style={{ display: "flex", alignItems: "top" }}>
                  <Input
                    type="checkbox"
                    onClick={() => handleClick(index)}
                    checked={active}
                    style={checkboxMarginTop}
                  />
                  <div as="h4" style={{ margin: "5px" }}>
                    {cb_title}
                  </div>
                </div>
                <div>{content}</div>
              </Segment>
            );
            return accu;
          }, [])}
        </div>
      );
    }
    return renderArgumentByType(form, argument);
  };

  async function handleSubmit() {
    const sanitizedValues = cloneDeep(modelState || {});

    // this is how our form handles things
    // eslint-disable-next-line no-underscore-dangle
    delete sanitizedValues._errors;
    // eslint-disable-next-line no-underscore-dangle
    delete sanitizedValues._touched;
    const valuesWithDefaultCustomInfo = setDefaultCustomInfoValue(
      sanitizedValues
    );
    await onSubmit(valuesWithDefaultCustomInfo);
    onClose();
  }

  function getFormFactory() {
    const argumentFields = configArguments.reduce((accu, arg) => {
      if (arg.type === "boolean") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          control: "Checkbox"
        });
      }
      if (arg.type === "date" || arg.type === "string" || arg.type === "text") {
        return accu.concat({
          id: arg.id,
          label: arg.label,
          rule: arg.isRequired ? "isRequired" : null,
          default: arg.defaultValue ? arg.defaultValue : null
        });
      }
      if (arg.type === "custom_fields") {
        refCustomFieldsArg.current = { ...arg };
        return accu;
      }
      return accu.concat({
        id: arg.id,
        label: arg.label,
        rule: "isRequired"
      });
    }, []);

    if (refCustomFieldsArg.current)
      argumentFields.push(...getCustomFields(refCustomFieldsArg.current));

    return new FormDefinition({
      fields: [
        {
          id: "export_id",
          label: "attachment.attributes.export_id.label",
          rule: "isRequired"
        },
        {
          id: "display_name",
          label: "attachment.attributes.display_name.label",
          placeholder: "attachment.attributes.display_name.placeholder",
          message: "attachment.attributes.display_name.error",
          rule: "isRequired"
        },
        {
          id: "role",
          label: "attachment.attributes.role.label",
          rule: "isRequired"
        }
      ].concat(argumentFields)
    });
  }

  const form = getFormFactory().create(modelState, i18n, {
    onChange: data => setModelState(data)
  });

  return (
    <Modal open={open}>
      <Modal.Header>
        <FormattedMessage
          id="roomBook.cart.actions.export"
          defaultMessage="Export"
        />
        : {title}
      </Modal.Header>
      <Modal.Content>
        <Form id="export" data-component="exportForm">
          {configArguments.map(f => {
            return renderArgument(form, f);
          })}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          content="Speichern"
          color="green"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}

ExportConfigDialog.propTypes = {
  open: bool.isRequired,
  onSubmit: func.isRequired,
  configArguments: arrayOf(shape({ id: string, label: string, type: string }))
    .isRequired,
  onClose: func.isRequired,
  i18n: I18nShape.isRequired,
  title: string,
  trades: arrayOf(objectOf(string)),
  contractors: arrayOf(objectOf(string)),
  contractorAssignments: arrayOf(objectOf(string))
};

ExportConfigDialog.defaultProps = {
  title: "",
  contractors: [],
  trades: [],
  contractorAssignments: []
};

const mapStateToProps = state => ({
  i18n: getI18N(state),
  contractors: state.pageContent.contractors,
  contractorAssignments: state.pageContent.contractor_assignments
});

export default connect(mapStateToProps)(ExportConfigDialog);
