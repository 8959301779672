import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Button, Grid, Header, Message, Icon } from "semantic-ui-react";
import { withRouter } from "shared/helpers/withRouter";
import { shape, string } from "prop-types";
import ContractorTabV2 from "./ContractorTabV2";
import ContractorAssignmentRulesDialog from "./ContractorAssignmentRulesDialog";

function ProjectContractors({ params }) {
  const { formatMessage } = useIntl();

  return (
    <div data-component="contractors">
      <Helmet title={formatMessage({ id: "contractor.title.other" })} />
      <Grid stackable className="margin bottom spacious">
        <Grid.Row>
          <Grid.Column width="8">
            <Header size="large">
              <FormattedMessage
                id="contractor.title.other"
                defaultMessage="contractor.title.other"
              />
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <ContractorAssignmentRulesDialog
              button={
                <Button floated="right" id="addContractor">
                  <Icon name="plus" />
                  <FormattedMessage
                    id="contractor.actions.assign"
                    defaultMessage="contractor.actions.assign"
                  />
                </Button>
              }
              model={{}}
              projectId={params.projectId}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Message info>
              <Message.Content>
                <FormattedMessage
                  id="contractor.title.description"
                  defaultMessage="contractor.title.description"
                />
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ContractorTabV2 />
    </div>
  );
}

ProjectContractors.propTypes = {
  params: shape({ projectId: string }).isRequired
};

export default injectIntl(withRouter(ProjectContractors));
