import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import FormatCurrency from "shared/components/currency/FormatCurrency";
import { delay, get, keyBy } from "lodash";
import {
  Button,
  Confirm,
  Grid,
  Header,
  Icon,
  List,
  Label
} from "semantic-ui-react";
import { ProductDropdown } from "builder_portal/components/product/ProductDropdown";
import {
  dispatchToggleCollapse,
  getCollapsedById
} from "shared/reducers/pageContent/roomBookFilter";
import { getUnitVariableTypes } from "builder_portal/selectors/unitVariables";
import { UnitVariableTypeShape } from "shared/shapes/unitVariables.shape";
import { If } from "shared/components/elements/Conditions";
import UnitFeatureIndicators from "./UnitFeatureIndicators";
import { areProductCostsDirty } from "../../models/roomBook/lineItemHelpers";
import renderProductOptions from "../../models/roomBook/renderProductOptions";
import SubLineItemFormModel from "../../models/roomBook/SubLineItemFormModel";
import SectionOrderControl from "./SectionOrderControl";
import BuyerSelectionControl from "../lineItems/BuyerSelectionControl";
import SubLineItemRuleHint from "../lineItems/SubLineItemRuleHint";
import CopyToDerivedDialog from "./CopyToDerivedDialog";
import { SubLineItemWarnings } from "./widgets/SubLineItemWarnings";
import { SubLineItemQuantityLabel } from "./widgets/SubLineItemQuantityLabel";
import "./roomBookLineItem.scss";
import "./roomBookSubLineItem.scss";
import ProductFilesListDialog from "./ProductFilesListDialog";

class RoomBookSubLineItem extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    context: PropTypes.object,
    roomBook: PropTypes.object,
    isTemplate: PropTypes.bool,
    products: PropTypes.array,
    trades: PropTypes.array,
    ctrl: PropTypes.object,
    model: PropTypes.object,
    lineItem: PropTypes.object,
    carts: PropTypes.array,
    toggleDialog: PropTypes.func,
    parentDisplayNumber: PropTypes.string,
    productsDictionary: PropTypes.object,
    parent: PropTypes.object,
    id: PropTypes.string.isRequired,
    toggleCollapse: PropTypes.func.isRequired,
    collapsed: PropTypes.bool.isRequired,
    unitVariableTypes: UnitVariableTypeShape.isRequired
  };

  constructor(props) {
    super(props);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.changeProduct = this.changeProduct.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.toggleProductLightBox = this.toggleProductLightBox.bind(this);

    this.state = {
      loading: {
        productDropdown: false
      },
      confirm: {
        productId: null,
        content: null,
        open: false
      },
      showDeprecated: false
    };
  }

  componentDidMount() {
    const { hash } = window.location;

    if (hash !== "") {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      model: prevModel,
      collapsed: prevCollapsed,
      parentDisplayNumber: prevParentDisplayNumber,
      unitVariableTypes: prevUnitVariableTypes
    } = this.props;
    const {
      model: nextModel,
      collapsed: nextCollapsed,
      parentDisplayNumber: nextParentDisplayNumber,
      unitVariableTypes: nextUnitVariableTypes
    } = nextProps;

    return (
      this.state !== nextState ||
      nextParentDisplayNumber !== prevParentDisplayNumber ||
      nextModel.updated_at !== prevModel.updated_at ||
      nextModel.position !== prevModel.position ||
      nextModel.status_info !== prevModel.status_info ||
      nextCollapsed !== prevCollapsed ||
      nextUnitVariableTypes !== prevUnitVariableTypes
    );
  }

  showEditorDialog = () => this.toggleEditorDialog();

  handleConfirm() {
    const { confirm } = this.state;
    this.changeProduct(confirm.productId);
  }

  handleCancel() {
    this.setState(prevState => ({
      ...prevState,
      confirm: { open: false, productId: null, content: null }
    }));
  }

  handleChangeProduct(e, data) {
    const { i18n, productsDictionary, model } = this.props;
    const productId = data.value;

    if (productId === -1) {
      e.stopPropagation();
      this.setState(prevState => ({
        ...prevState,
        showDeprecated: !prevState.showDeprecated
      }));
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        productDropdown: true
      }
    }));

    setTimeout(() => {
      const messages = [];

      const changePolicy = get(model, "status_info.status.change_policy", {
        policy: "allowed"
      });

      if (changePolicy.policy === "confirm") {
        messages.push(changePolicy.confirmation_message);
      }

      if (areProductCostsDirty(model, productsDictionary)) {
        messages.push(i18n["roomBook.lineItems.messages.changeOfCosts"]);
      }

      if (messages.length < 1) {
        this.changeProduct(productId, productsDictionary);
      } else {
        const messageComponent = (
          <List size="big" data-component="confirm-messages-list">
            {messages.map((message, index) => {
              return (
                <List.Item key={index}>
                  <List.Icon name="attention" color="red" />
                  <List.Content>{message}</List.Content>
                </List.Item>
              );
            })}
          </List>
        );

        /*
         * When triggered from another dialog (ProductLightBox), the click event
         * might be directly passed on to its dimmer and it closes without being seen,
         * therefore we delay it.
         */
        delay(() => {
          this.setState(prevState => ({
            ...prevState,
            confirm: { open: true, productId, content: messageComponent }
          }));
        }, 0);
      }
    });
  }

  changeProduct(productId) {
    const { ctrl, lineItem } = this.props;

    const updatedLineItem = lineItem.changeProduct(productId);

    if (productId) {
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          productDropdown: true
        },
        confirm: {
          productId: null,
          content: null,
          open: false
        }
      }));
      ctrl.updateSubLineItem(updatedLineItem.currentModel).then(() => {
        this.setState(prevState => ({
          ...prevState,
          loading: {
            ...prevState.loading,
            productDropdown: false
          }
        }));
      });
    } else {
      this.toggleEditorDialog(updatedLineItem.currentModel, "edit");
    }
  }

  toggleEditorDialog(values, initialPage = undefined) {
    const {
      isTemplate,
      products,
      model,
      roomBook,
      toggleDialog,
      trades
    } = this.props;

    toggleDialog("subLineItemDialog", {
      trades,
      isTemplate,
      roomBookId: roomBook.id,
      model: values || model,
      products,
      autoFocus: !model.product_group_id,
      initialPage
    });
  }

  toggleProductLightBox() {
    const { ctrl, lineItem } = this.props;

    ctrl.toggleDialog("productLightBox", {
      lineItem,
      onChange: this.handleChangeProduct
    });
  }

  renderBuyerSelection() {
    const { context, lineItem, ctrl, i18n, products } = this.props;

    if (!context.account.isEnabled("buyer_portal")) {
      return null;
    }

    if (lineItem.hasCarts()) {
      return null;
    }

    if (!lineItem.hasBuyerSelection() || lineItem.matchesBuyerSelection()) {
      return null;
    }

    return (
      <BuyerSelectionControl
        i18n={i18n}
        lineItem={lineItem}
        products={products}
        ctrl={ctrl}
      />
    );
  }

  render() {
    const {
      i18n,
      isTemplate,
      products,
      model,
      parent,
      roomBook,
      ctrl,
      lineItem,
      toggleDialog,
      parentDisplayNumber,
      id,
      toggleCollapse,
      collapsed,
      context,
      unitVariableTypes
    } = this.props;
    const { confirm, loading, showDeprecated } = this.state;

    const product = lineItem.getProduct();

    const productImage = model && {
      backgroundImage: `url('${get(product, "thumb_url", model.thumb_url)}')`
    };
    const displayNumber = `${parentDisplayNumber}.${model.position}`;

    return (
      <List.Item
        id={id}
        data-model="sub_line_item"
        data-component="roomBookLineItem"
        className={lineItem.isAdhoc() ? "isIndividualLineItem" : null}
      >
        <List.Content>
          <Grid stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={11}>
                <div className="titleButton">
                  <Icon
                    title={
                      context.account.isSystemAdmin()
                        ? lineItem.currentModel.id
                        : "Position"
                    }
                    size="large"
                    color="grey"
                    link
                    onClick={() => toggleCollapse(`sub-line-item-${model.id}`)}
                    name={`caret ${collapsed ? "right" : "down"}`}
                  />
                  <Icon
                    color="grey"
                    name="setting"
                    size="large"
                    link
                    onClick={this.showEditorDialog}
                  />
                  <Header
                    onClick={() => toggleCollapse(`sub-line-item-${model.id}`)}
                    className="toggleSection"
                  >
                    <span data-attr="display_number">{displayNumber}</span>
                    &nbsp;
                    <span data-attr="title">{model.title}</span>
                  </Header>
                  <div className="quantity">
                    <SubLineItemQuantityLabel lineItem={lineItem} />
                  </div>
                  <If
                    condition={!!unitVariableTypes[model.unit_variable_type_id]}
                  >
                    <Label className="unit_variable_type_label">
                      <Icon name="hdd outline" color="grey" />
                      {unitVariableTypes[model.unit_variable_type_id]?.name}
                    </Label>
                  </If>
                  <UnitFeatureIndicators item={model} isTemplate={isTemplate} />
                </div>
              </Grid.Column>
              <Grid.Column width={5} textAlign="right">
                {isTemplate && (
                  <CopyToDerivedDialog
                    roomBookId={roomBook.id}
                    item={model}
                    itemType="sub_line_item"
                    trigger={
                      <Button
                        size="mini"
                        data-component="copy-to-derived-button"
                      >
                        Übernehmen
                      </Button>
                    }
                  />
                )}
                <SectionOrderControl
                  i18n={i18n}
                  type="subLineItem"
                  ctrl={ctrl}
                  element={model}
                  supplierId={
                    products.find(
                      ({ id: productId }) => productId === model.product_id
                    )?.supplier_id
                  }
                  itemsInGroup={parent.sub_line_items.length}
                  roomBook={roomBook}
                />
              </Grid.Column>
            </Grid.Row>
            {!collapsed && (
              <Grid.Row>
                <Grid.Column width={3}>
                  <Confirm
                    header={i18n["roomBook.lineItems.messages.header"]}
                    content={confirm.content}
                    open={confirm.open}
                    cancelButton={i18n["roomBook.lineItems.actions.cancel"]}
                    confirmButton={i18n["roomBook.lineItems.actions.confirm"]}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleConfirm}
                  />
                  {productImage && (
                    <div
                      style={productImage}
                      className="productImage"
                      onClick={this.toggleProductLightBox}
                    >
                      <Icon name="zoom" />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column width={13}>
                  <div className="productContainer">
                    {model.role === "product" && model.product_id && (
                      <ProductDropdown
                        id={`product_selector_${model.id}`}
                        selectedId={lineItem.product_selected?.id}
                        loading={loading.productDropdown}
                        value={parseInt(model.product_id, 10)}
                        onChange={this.handleChangeProduct}
                        options={renderProductOptions(
                          model,
                          products,
                          true,
                          isTemplate,
                          showDeprecated,
                          !!lineItem.product_selected?.deprecated
                        )}
                      />
                    )}
                    {!model.product_id && (
                      <div
                        className="description individualProduct"
                        onClick={() =>
                          toggleDialog(`lineItem-${model.id}`, true)
                        }
                      >
                        <Grid>
                          <Grid.Row columns={2}>
                            <Grid.Column width={12}>
                              {model.role === "product" && (
                                <div className="descriptionHeader">
                                  <FormattedMessage
                                    id="roomBook.lineItems.individualProduct"
                                    defaultText="roomBook.lineItems.individualProduct"
                                  />
                                </div>
                              )}
                              {model.description || ""}
                            </Grid.Column>
                            <Grid.Column
                              width={4}
                              textAlign="right"
                              verticalAlign="top"
                            >
                              {!isTemplate && (
                                <div className="individualProduct price">
                                  <span data-attr="total">
                                    <FormatCurrency amount={model.total} />
                                  </span>
                                </div>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    )}
                  </div>
                  <If condition={lineItem.hasProductFiles()}>
                    <ProductFilesListDialog
                      productFiles={lineItem.getProductFiles()}
                    />
                  </If>
                  <SubLineItemWarnings lineItem={lineItem} />
                  {this.renderBuyerSelection(model)}
                  <SubLineItemRuleHint lineItem={lineItem} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </List.Content>
      </List.Item>
    );
  }
}

const mapStateToProps = (state, props) => {
  const products = props.productsRepository.getGroupProducts(
    props.model.product_group_id
  );

  return {
    ...props,
    collapsed: getCollapsedById(state, `sub-line-item-${props.model.id}`),
    products,
    productsDictionary: keyBy(products || [], "id"),
    lineItem: new SubLineItemFormModel(
      props.model,
      props.model,
      products,
      props.isTemplate
    ),
    unitVariableTypes: keyBy(getUnitVariableTypes(state), "id")
  };
};

const mapDispatchToProps = dispatch => ({
  toggleCollapse: itemId => {
    dispatchToggleCollapse(dispatch, itemId);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomBookSubLineItem);
