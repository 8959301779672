/* eslint-disable import/prefer-default-export */
import { createSelector } from "reselect";
import { getPageContent } from "./base";

export const getDocumentationItems = createSelector(
  [getPageContent],
  pageContent => {
    return pageContent?.documentation_items || [];
  }
);
