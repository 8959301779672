import { get } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "./base";

export const getSuppliers = createSelector([getPageContent], pageContent =>
  get(pageContent, "product_manager_suppliers", [])
);

export const getProductTypeOptions = createSelector(
  [getPageContent],
  pageContent =>
    get(pageContent, "product_manager_product_types", []).map(productType => ({
      key: productType.id,
      value: productType.id,
      text: productType.name
    }))
);
