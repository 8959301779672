/* eslint-disable no-unused-expressions */
import React from "react";
import { shape, string, func, bool } from "prop-types";
import {
  Button,
  Image,
  Header,
  Form,
  Grid,
  Icon,
  Segment
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getImages } from "shared/selectors/project";
import { ReactSortable } from "react-sortablejs";
import ImagesLibrary from "./ImageLibrary";
import "./imageSelector.scss";

const SemanticFormikImageSelector = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    projectId,
    multiple,
    onChange,
    title
  } = props;
  const images = useSelector(getImages);
  const [allDataImages, setAllDataImages] = React.useState(images);

  React.useEffect(() => {
    setAllDataImages(images);
  }, [images]);

  const setValue = newImage => {
    if (multiple) {
      const newImageId = newImage.id;
      if (value && value.length >= 0) {
        const newValues = [...value, newImageId];
        const uniqueValues = [...new Set(newValues)];
        setFieldValue(name, uniqueValues);
        setAllDataImages([...allDataImages, newImage]);

        if (onChange) {
          onChange(uniqueValues);
        }
      } else {
        setFieldValue(name, [newImageId]);
        setAllDataImages([newImage]);

        if (onChange) {
          onChange([newImageId]);
        }
      }
    } else {
      setFieldValue(name, newImage.id || null);
      setAllDataImages([...allDataImages, newImage]);

      if (onChange) {
        onChange(newImage?.id);
      }
    }
  };

  const getProjectImageById = id => {
    return allDataImages.find(image => {
      return image.id === id;
    });
  };

  const getSelectedImages = () => {
    if (value && value.length > 0) {
      return value.map(id => getProjectImageById(id));
    }
    return [];
  };

  const fullDataImages = React.useMemo(() => {
    if (multiple) return getSelectedImages();
    return [];
  }, [value, allDataImages]);

  const onDragDropEnds = (oldIndex, newIndex) => {
    const newImage = [...fullDataImages];
    const movedImage = newImage.splice(oldIndex, 1)[0];
    newImage.splice(newIndex, 0, movedImage);
    const ids = newImage.map(image => image.id);
    setFieldValue(name, ids);
    onChange && onChange(ids);
  };

  // check if all items of array are undefined
  const isAllItemsUndefined = arr => {
    if (arr && arr.length > 0) {
      return arr.every(item => item !== undefined);
    }
    return false;
  };

  const isAllItemsUndefinedMemo = React.useMemo(() => {
    return isAllItemsUndefined(fullDataImages);
  }, [fullDataImages]);

  return (
    <Segment>
      <Form.Field>
        <Grid celled>
          <Grid.Column width={16}>
            <div className="image-selector-header ">
              <div>
                <Header as="h3">
                  {title ||
                    (multiple ? (
                      <FormattedMessage id="imageSelector.imageGallery" />
                    ) : (
                      <FormattedMessage id="imageSelector.singleImage" />
                    ))}
                </Header>
              </div>
              <div>
                {!multiple && value && (
                  <Button
                    primary
                    onClick={() => {
                      setFieldValue(name, null);
                    }}
                  >
                    <FormattedMessage id="imageSelector.removeImage" />
                  </Button>
                )}
                <ImagesLibrary
                  projectId={projectId}
                  multiple={multiple}
                  getSelectedImage={image => {
                    setValue(image);
                  }}
                  images={images}
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={16}>
            {!multiple && value && (
              <div className="image-selector-container-single_image ">
                <Image
                  src={getProjectImageById(value)?.large_url}
                  style={{
                    width: "90%",
                    minHeight: "10rem",
                    maxHeight: "25rem",
                    objectFit: "contain"
                  }}
                />
              </div>
            )}

            {multiple && value && value.length > 0 && isAllItemsUndefinedMemo && (
              <ReactSortable
                list={fullDataImages}
                setList={() => {}}
                ghostClass="dropArea"
                handle=".dragHandle"
                filter=".ignoreDrag"
                className="grid-container"
                preventOnFilter
                onEnd={({ oldIndex, newIndex }) =>
                  onDragDropEnds(oldIndex, newIndex)
                }
              >
                {fullDataImages.map(image => (
                  <div
                    key={image?.id}
                    style={{ height: "240px", cursor: "pointer" }}
                    className="dragHandle"
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        marginBottom: 36,
                        height: "220px"
                      }}
                    >
                      <Button
                        size="mini"
                        icon="delete"
                        onClick={() => {
                          const newValues = value?.filter(
                            img => img !== image.id
                          );
                          setFieldValue(name, newValues);
                          onChange && onChange(newValues);
                        }}
                        className="image-selector-delete-button"
                      />

                      <Image
                        src={image?.large_url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  </div>
                ))}
              </ReactSortable>
            )}
            {(!value || (Array.isArray(value) && value.length === 0)) && (
              <Grid centered columns={2}>
                <Grid.Column textAlign="center" width={16}>
                  <Icon name="images outline" size="big" />
                </Grid.Column>
                <Grid.Column textAlign="center" width={16}>
                  <Header as="h3">
                    <FormattedMessage id="imageSelector.noImageUploaded" />
                  </Header>
                </Grid.Column>
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </Form.Field>
    </Segment>
  );
};

SemanticFormikImageSelector.propTypes = {
  field: shape({
    name: string.isRequired,
    value: string
  }).isRequired,
  form: shape({
    setFieldValue: func.isRequired
  }).isRequired,
  projectId: string.isRequired,
  multiple: bool,
  onChange: func,
  title: string
};

SemanticFormikImageSelector.defaultProps = {
  multiple: true,
  onChange: null,
  title: ""
};

export default SemanticFormikImageSelector;
