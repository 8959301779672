const colorList = [
  {
    id: "background",
    label: "Hintergrundfarben",
    items: [
      {
        id: "background_default",
        type: "color",
        label: "Standardfarbe",
        defaultValue: {
          light: "#fafafa",
          dark: "#000000"
        }
      },
      {
        id: "background_paper",
        type: "color",
        label: "Paperfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#282828"
        }
      }
    ]
  },
  {
    id: "text",
    label: "Textfarben",
    items: [
      {
        id: "text_primary",
        type: "color",
        label: "Primärfarbe",
        defaultValue: {
          light: "#333333",
          dark: "#ffffff"
        }
      },
      {
        id: "text_secondary",
        type: "color",
        label: "Sekundärfarbe",
        defaultValue: {
          light: "#333333",
          dark: "#fafafa"
        }
      },
      {
        id: "text_disabled",
        type: "color",
        label: "Ausgegraut",
        defaultValue: {
          light: "#bdbdbd",
          dark: "#bdbdbd"
        }
      }
    ]
  },
  {
    id: "primary",
    label: "Primärfarben",
    items: [
      {
        id: "primary_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#00456F",
          dark: "#0195ef"
        }
      },
      {
        id: "primary_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#000000"
        }
      }
    ]
  },
  {
    id: "secondary",
    label: "Sekundärfarben",
    items: [
      {
        id: "secondary_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#95C11F",
          dark: "#51acf5"
        }
      },
      {
        id: "secondary_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#000000"
        }
      }
    ]
  },
  {
    id: "error",
    label: "Fehlerfarben",
    items: [
      {
        id: "error_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#D32F2F",
          dark: "#D32F2F"
        }
      },
      {
        id: "error_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#ffffff"
        }
      }
    ]
  },
  {
    id: "warning",
    label: "Warnfarben",
    items: [
      {
        id: "warning_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#FF9800",
          dark: "#f9756b"
        }
      },
      {
        id: "warning_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#000000"
        }
      }
    ]
  },
  {
    id: "info",
    label: "Infofarben",
    items: [
      {
        id: "info_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#2196F3",
          dark: "#2196F3"
        }
      },
      {
        id: "info_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#000000"
        }
      }
    ]
  },
  {
    id: "success",
    label: "Erfolgsfarben",
    items: [
      {
        id: "success_color_main",
        type: "color",
        label: "Hauptfarbe",
        defaultValue: {
          light: "#95C11F",
          dark: "#95C11F"
        }
      },
      {
        id: "success_color_contrast",
        type: "color",
        label: "Kontrastfarbe",
        defaultValue: {
          light: "#ffffff",
          dark: "#000000"
        }
      }
    ]
  },
  {
    id: "dashboard_buttons",
    label: "Dashboard Buttons",
    items: [
      {
        id: "dashboard_buttons_variant",
        type: "select",
        label: "Buttonvariante",
        defaultValue: {
          light: "outlined",
          dark: "contained"
        },
        options: [
          {
            key: "outlined",
            text: "Outlined",
            value: "outlined"
          },
          {
            key: "contained",
            text: "Contained",
            value: "contained"
          }
        ]
      }
    ]
  }
];

export default colorList;
