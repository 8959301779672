import Resources from "../../shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";
import { UPDATE_NOTIFICATIONS } from "../../shared/constants/appConstants";

export class AlertsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "alert", "/api/v1/alerts");
  }

  refresh() {
    return apiRequest
      .get(this.url, this.dispatch, null, true)
      .then(response => {
        if (response.data && response.data.alerts) {
          // write account to redux store
          this.dispatch({
            type: UPDATE_NOTIFICATIONS,
            payload: response.data.alerts
          });
        }
        return response;
      })
      .catch(function(error) {
        console.error(error);
      });
  }
}

export default class NotificationsResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "notifications", "/api/v1/notifications");
  }

  batchUpdate(values) {
    const payload = {};
    payload[this.namespace] = values;
    return apiRequest.post(
      `${this.url}/acknowledgements`,
      this.dispatch,
      payload
    );
  }
}
