import { arrayOf, bool, number, shape, string } from "prop-types";
import ImageShape from "shared/shapes/image.shape";

export const ProductShape = shape({
  id: number,
  name: string,
  description: string,
  technical_description: string,
  sku: string,
  series: string,
  supplier: string,
  supplier_id: string,
  // product_tags: arrayOf(),
  deletable: bool,
  custom_product_code: string,
  trades: arrayOf(string),
  created_at: string,
  updated_at: string,
  product_manager_url: string,
  thumb_url: string,
  images: arrayOf(ImageShape),
  // specifications: arrayOf
  supplier_url: string,
  vr_model_url: string,
  product_files: arrayOf(
    shape({
      id: number,
      file_file_name: string,
      visibility: string,
      internal_url: string
    })
  )
});

export default ProductShape;
