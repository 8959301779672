import { ActivityContactsResource } from "builder_portal/actions/activityActions";
import MessagesResource from "../../actions/activities/messagesActions";
import {
  ActivitiesResource,
  AssignmentSuggestionsResource,
  AttachmentReferencesResource,
  ContractorAssignmentResource,
  ExportResource,
  LineItemResource,
  SubLineItemResource,
  SuccessorsResource,
  TasksResource,
  TransitionsResource
} from "../../actions/activityActions";
import Growl from "../../actions/growlActions";
import MemosResource from "../../actions/memosActions";
import { ProjectActivitiesResource } from "../../actions/projectActions";
import { ProjectsCatalogResource } from "../../actions/projectCatalogActions";
import { CartAssignmentResource } from "../../actions/roomBookActions";
import { ProjectSubLineItemResource } from "../../actions/subLineItemActions";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";

export default class ActivityController {
  constructor(projectId, id, dispatch) {
    this.projectId = projectId;
    this.id = parseInt(id, 10);
    this.dispatch = dispatch;
  }

  load() {
    this.getProjectCatalogResource()
      .get()
      .catch(silentHandleApiRequestErrors);
    return this.refresh();
  }

  loadContacts() {
    return this.getContactsResource()
      .fetchAll()
      .catch(silentHandleApiRequestErrors);
  }

  refresh = () => {
    this.loadContacts();
    return this.getActivityResource().get(this.id, true);
  };

  assignContractor = value => {
    return this.getContractorAssignmentResource()
      .save(value)
      .then(() => {
        return this.refresh();
      });
  };

  /*
   * Sucecssors
   */
  fetchSuccessors(params) {
    return this.getSuccessorsResource().fetchAll(params);
  }

  /*
   * Assignment
   */
  reassignActivity(userId) {
    const values = {
      id: this.id,
      assignee_id: userId
    };

    return this.getActivityResource()
      .save(values)
      .then(() => {
        return this.refresh();
      });
  }

  /*
   * Status Transitions
   */

  triggerTransition = values => {
    return this.getTransitionsResource()
      .save(values)
      .then(response => {
        if (response.status === 200) {
          return this.refresh();
        }
        return response;
      });
  };

  removeLineItemFromCart(lineItem) {
    const payload = {
      cart_id: lineItem.cart_id
    };
    return this.getCartAssignmentResource(lineItem.id)
      .remove(payload)
      .then(() => {
        return this.refresh();
      });
  }

  /*
   * Tasks Handling
   */

  completeTask = id => {
    return this.getTasksResource()
      .complete(id)
      .then(() => {
        return this.refresh();
      });
  };

  reopenTask = id => {
    return this.getTasksResource()
      .reopen(id)
      .then(() => {
        return this.refresh();
      });
  };

  /*
   * Attachmments
   */

  removeAttachment = id => {
    return this.getAttachmentReferencesResource()
      .remove(id)
      .then(() => {
        return this.refresh();
      });
  };

  assignAttachment = id => {
    return this.getAttachmentReferencesResource()
      .assignAttachment(id)
      .then(() => {
        return this.refresh();
      });
  };

  updateAttachment = values => {
    return this.getAttachmentReferencesResource()
      .save(values)
      .then(() => {
        return this.refresh();
      });
  };

  getCartAssignmentResource(lineItemId) {
    return new CartAssignmentResource(this.dispatch, lineItemId);
  }

  exportAsAttachment = values => {
    return this.getExportResource()
      .save(values)
      .then(response => {
        return this.refresh().then(() => {
          return response;
        });
      });
  };

  createSuccessor(values) {
    return this.getSuccessorsResource().save(values);
  }

  /*
   * Assignment suggestions
   */
  suggestAssignments(trade) {
    const params = trade ? { trade } : {};
    return this.getAssignmentSuggestionsResource().get(false, params);
  }

  batchAssign(payload) {
    return this.getAssignmentSuggestionsResource()
      .save(payload)
      .then(() => {
        return this.refresh();
      });
  }

  /*
   * Line Items
   */
  updateLineItem(values) {
    return this.lineItemResource().save(values);
  }

  updateSubLineItem(values) {
    return this.subLineItemResource().save(values);
  }

  /*
   * Messages
   */

  sendMessage = values => {
    return this.getMessagesResource().save(values);
  };

  /*
   * Resources
   */

  getActivityResource() {
    return new ActivitiesResource(this.dispatch);
  }

  getContactsResource() {
    return new ActivityContactsResource(this.dispatch, this.projectId, this.id);
  }

  getContractorAssignmentResource() {
    return new ContractorAssignmentResource(this.dispatch, this.id);
  }

  getMemosResource() {
    return new MemosResource(this.dispatch, this.id);
  }

  getMessagesResource() {
    return new MessagesResource(this.dispatch, this.id);
  }

  getTasksResource() {
    return new TasksResource(this.dispatch);
  }

  getAttachmentReferencesResource() {
    return new AttachmentReferencesResource(this.dispatch, this.id);
  }

  getExportResource() {
    return new ExportResource(this.dispatch, this.id);
  }

  getProjectSubLineResource() {
    return new ProjectSubLineItemResource(this.dispatch, this.projectId);
  }

  getTransitionsResource() {
    return new TransitionsResource(this.dispatch, this.id);
  }

  getGrowl() {
    return new Growl(this.dispatch);
  }

  getSuccessorsResource() {
    return new SuccessorsResource(this.dispatch, this.id);
  }

  getAssignmentSuggestionsResource() {
    return new AssignmentSuggestionsResource(this.dispatch, this.id);
  }

  lineItemResource() {
    return new LineItemResource(this.dispatch, this.id);
  }

  subLineItemResource() {
    return new SubLineItemResource(this.dispatch, this.id);
  }

  getProjectCatalogResource() {
    return new ProjectsCatalogResource(this.dispatch, this.projectId);
  }

  getProjectActivitiesResource() {
    return new ProjectActivitiesResource(this.dispatch, this.projectId);
  }

  updateSubLineItems() {
    throw new Error(
      `Activity.updateSubLineItems not implemented ${this.projectId}`
    );
  }
}
