import PropTypes from "prop-types";
import React from "react";
import { Segment, Grid, Header, Container, Icon } from "semantic-ui-react";
import "./pageFooter.scss";
import Info from "../../../shared/components/info/Info";

class PageFooter extends React.Component {
  static propTypes = {
    logoPath: PropTypes.string
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { logoPath } = this.props;
    return (
      <Segment data-component="pageFooter" inverted vertical className="footer">
        <Container style={{ backgroundImage: `url(${logoPath})` }}>
          <Grid divided stackable className="equal height">
            <Grid.Column width="6">
              <Info />
            </Grid.Column>
            <Grid.Column width="10">
              <Header size="small" inverted>
                Haben Sie Fragen, Feedback oder ein technisches Problem?
              </Header>
              <p>Sie erreichen uns per E-Mail</p>
              <Header size="small" inverted>
                <a href="mailto:support@baudigital.com">
                  <Icon name="mail outline" /> Kundenbetreuung
                  &lt;support@baudigital.com&gt;
                </a>
              </Header>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

export default PageFooter;
