import { get, keyBy, size } from "lodash";
import { createSelector } from "reselect";

import ProductsRepository from "builder_portal/models/roomBook/ProductsRepository";
import SubLineItemFormModel from "builder_portal/models/roomBook/SubLineItemFormModel";
import LineItemModel from "builder_portal/models/roomBook/LineItemModel";

import { getPageContent } from "shared/selectors/base";

const DEFAULT_ARRAY = [];

export const getUnit = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["unit"]);
});

export const getRoomBook = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["room_book"]);
});

export const getRoomBookCarts = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, ["carts"], DEFAULT_ARRAY);
  }
);

export const getRoomBookExports = createSelector(
  [getPageContent],
  pageContent => {
    return get(pageContent, ["room_book_exports"], DEFAULT_ARRAY);
  }
);

export const getIsTemplate = createSelector([getRoomBook], roomBook => {
  return roomBook.template;
});

const getPriceCatalogs = createSelector([getPageContent], pageContent => {
  return get(pageContent, ["price_catalogs"], DEFAULT_ARRAY);
});

export const getRoomBookPriceCatalogs = createSelector(
  [getRoomBook, getPriceCatalogs],
  (roomBook, priceCatalogs) => {
    return priceCatalogs.filter(pc => {
      const matchesProject = roomBook?.price_catalog_id === pc.id;
      const matchesCatalog = pc.assignable_project_ids.includes(
        roomBook?.project_id
      );

      return matchesProject || matchesCatalog;
    });
  }
);

const getProducts = createSelector([getPageContent], pageContent =>
  get(pageContent, "products", DEFAULT_ARRAY)
);

const getProductGroups = createSelector([getPageContent], pageContent =>
  get(pageContent, "product_groups", DEFAULT_ARRAY)
);

export const getProductRepository = createSelector(
  [getRoomBook, getProducts, getProductGroups],
  (roomBook, products, productGroups) =>
    new ProductsRepository(products, productGroups, roomBook.price_catalog_id)
);

export const getLineItems = createSelector([getRoomBook], roomBook => {
  if (!roomBook) {
    return {};
  }
  const result = {};
  roomBook.sections.forEach(mainSection => {
    mainSection.sections.forEach(subSection => {
      subSection.line_items.forEach(lineItem => {
        result[lineItem.id] = lineItem;
      });
    });
  });
  return result;
});

export const getLineItemsCount = createSelector([getLineItems], lineItems =>
  size(lineItems)
);

export const getActivatedLineItemsCount = createSelector(
  [getLineItems],
  lineItems => {
    return size(Object.values(lineItems).filter(li => li.activated));
  }
);

export const getLineItemModels = createSelector(
  [getLineItems, getIsTemplate],
  (lineItems, isTemplate) => {
    return Object.values(lineItems).map(lineItem => {
      return new LineItemModel(lineItem, isTemplate);
    });
  }
);

export const getPendingLineItems = createSelector(
  [getLineItemModels],
  lineItems => {
    return lineItems.filter(li => li.hasPendingBuyerSelection());
  }
);

const toSubLineItemFormModelsFn = createSelector(
  [getProductRepository, getIsTemplate],
  (productRepository, isTemplate) => {
    return slis => {
      return slis.map(sli => {
        const products = productRepository.getGroupProducts(
          sli.product_group_id
        );
        return new SubLineItemFormModel(sli, sli, products, isTemplate);
      });
    };
  }
);

export const getPendingSubLineItemFormModels = createSelector(
  [getPendingLineItems, toSubLineItemFormModelsFn],
  (lineItems, toSubLineItemFormModels) => {
    return lineItems.reduce((accu, li) => {
      return accu.concat(toSubLineItemFormModels(li.pendingBuyerSelections()));
    }, []);
  }
);

export const getSubLineItems = createSelector([getLineItems], lineItemsById => {
  return Object.values(lineItemsById).reduce((accu, lineItem) => {
    return accu.concat(lineItem.sub_line_items);
  }, []);
});

export const getSubLineItemsById = createSelector(
  [getSubLineItems],
  subLineItems => {
    return keyBy(subLineItems, "id");
  }
);

export const getSubLineItemsByScope = createSelector(
  [getRoomBook],
  roomBook => {
    const result = {
      section: {},
      sub_section: {},
      line_item: {},
      room_book: {},
      product_group: {},
      scope_map: {}
    };

    if (roomBook) {
      result.room_book[roomBook.id] = [];
      roomBook.sections.forEach(section => {
        section.sections.forEach(subSection => {
          subSection.line_items.forEach(lineItem => {
            lineItem.sub_line_items.forEach(subLineItem => {
              if (!result.section[section.id]) {
                result.section[section.id] = [];
              }
              if (!result.sub_section[subSection.id]) {
                result.sub_section[subSection.id] = [];
              }
              if (!result.line_item[lineItem.id]) {
                result.line_item[lineItem.id] = [];
              }
              if (!result.product_group[subLineItem.product_group_id]) {
                result.product_group[subLineItem.product_group_id] = [];
              }

              result.section[section.id].push(subLineItem);
              result.sub_section[subSection.id].push(subLineItem);
              result.line_item[lineItem.id].push(subLineItem);
              result.product_group[subLineItem.product_group_id].push(
                subLineItem
              );
              result.room_book[roomBook.id].push(subLineItem);

              result.scope_map[subLineItem.id] = {
                section: section.id,
                sub_section: subSection.id,
                line_item: lineItem.id,
                product_group: subLineItem.product_group_id,
                room_book: roomBook.id
              };
            });
          });
        });
      });
    }

    return result;
  }
);

export const getSubLineItemsByProductGroupId = createSelector(
  [getSubLineItemsByScope],
  subLineItemsByScope => {
    return subLineItemsByScope.product_group;
  }
);

export const getRoomBookFilter = createSelector(
  [state => state.roomBookFilter],
  roomBookFilter => {
    return roomBookFilter;
  }
);

export const getRoomBookCollapsedItems = createSelector(
  [getRoomBookFilter],
  roomBookFilter => {
    return roomBookFilter?.collapsedItems;
  }
);
