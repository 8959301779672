import moment from "moment";

const colorByDueDays = date => {
  if (!date) {
    return "#95c11f";
  }
  const days = moment(date).diff(moment().startOf("day"), "days");
  if (days < 0) {
    return "#bb3131";
  }
  if (days <= 3) {
    return "#F2711C";
  }
  return "teal";
};

export default colorByDueDays;
