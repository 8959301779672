import React, { useEffect, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { string, oneOfType, number, func, node } from "prop-types";
import ProjectQuestionsResource from "builder_portal/actions/projectQuestionsActions";
import ProjectQuestionGroupsResource from "builder_portal/actions/projectQuestionGroupsActions";
import getProjectQuestions from "builder_portal/selectors/projectQuestions";
import getProjectQuestionGroups from "builder_portal/selectors/projectQuestionGroups";

export const ProjectQuestionsLoaderContext = createContext({});

const ProjectQuestionsLoader = ({ projectId, children }) => {
  const dispatch = useDispatch();
  const [openedGroups, setOpenedGroups] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const projectQuestionGroups = useSelector(getProjectQuestionGroups);
  const projectQuestions = useSelector(getProjectQuestions);

  const loadData = () => {
    setLoading(true);

    Promise.all([
      new ProjectQuestionsResource(dispatch, projectId).fetchAll(),
      new ProjectQuestionGroupsResource(dispatch, projectId).fetchAll()
    ]).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ProjectQuestionsLoaderContext.Provider
      value={{
        projectId,
        projectQuestionGroups,
        projectQuestions,
        isLoading,
        openedGroups,
        setOpenedGroups,
        loadData
      }}
    >
      {children}
    </ProjectQuestionsLoaderContext.Provider>
  );
};

ProjectQuestionsLoader.propTypes = {
  projectId: oneOfType([string, number]),
  children: oneOfType([func, node])
};

ProjectQuestionsLoader.defaultProps = {
  projectId: null,
  children: null
};

export default ProjectQuestionsLoader;
