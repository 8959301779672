import PropTypes from "prop-types";
import React, { Component } from "react";
import { withI18n } from "shared/containers/i18n/i18n";
import { Button, Header, List, Modal } from "semantic-ui-react";

import "./multiDialog.scss";

class MultiDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    pages: PropTypes.array.isRequired,
    isVisible: PropTypes.bool,
    toggleDialog: PropTypes.func,
    size: PropTypes.string,
    title: PropTypes.string,
    trigger: PropTypes.node,
    initialPage: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pageId: this.initialPageId(props)
    };
  }

  initialPageId = props => {
    if (props.initialPage) return props.initialPage;
    const visiblePages = props.pages.filter(page => !page.hidden);
    return visiblePages.length === 1 ? visiblePages[0].id : null;
  };

  componentWillUnmount = () => {
    this.unmounted = true;
  };

  render() {
    const { isVisible, toggleDialog, size, trigger } = this.props;

    const page = this.getPage();
    const hideButtons = page && page.hideButtons;

    return (
      <Modal
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className="multiDialog"
        open={isVisible}
        onOpen={() => toggleDialog(true)}
        onClose={this.handleClose}
        size={size || "small"}
        trigger={trigger}
      >
        <Modal.Header>{this.renderHeader()}</Modal.Header>
        <Modal.Content>{this.renderContent()}</Modal.Content>
        {!hideButtons && <Modal.Actions>{this.renderActions()}</Modal.Actions>}
      </Modal>
    );
  }

  getPage() {
    const { pages } = this.props;
    const { pageId } = this.state;
    return pages.find(page => page.id === pageId);
  }

  renderHeader() {
    const { title } = this.props;
    const page = this.getPage();

    if (page) {
      return <span>{page.title}</span>;
    }
    return title;
  }

  renderContent() {
    const page = this.getPage();

    if (page) {
      return page.renderContent();
    }
    return this.renderHomePageContent();
  }

  renderHomePageContent() {
    const { pages } = this.props;

    const listItems = pages
      .filter(page => !page.hidden)
      .map(page => {
        return (
          <List.Item key={page.id}>
            <List.Content floated="right">
              {page.type !== "button" && (
                <Button
                  id={`open-${page.id}-page`}
                  icon={page.buttonIcon || "arrow circle right"}
                  color={page.buttonColor}
                  onClick={() => {
                    this.setState({ pageId: page.id });
                  }}
                />
              )}
              {page.type === "button" && page.renderButton()}
            </List.Content>
            <List.Content>
              <Header>
                {page.title}
                <Header.Subheader>{page.summary}</Header.Subheader>
              </Header>
            </List.Content>
          </List.Item>
        );
      });

    return <List verticalAlign="middle">{listItems}</List>;
  }

  renderActions() {
    const { i18n } = this.props;
    const { loading } = this.state;
    const page = this.getPage();
    const buttons = [
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];
    if (page) {
      (page.actions || []).forEach(action => {
        buttons.push(action);
      });
    }

    return buttons.map((button, idx) => {
      return (
        <Button
          key={idx}
          content={i18n[button.label] || button.label}
          color={button.color}
          icon={button.icon}
          onClick={this.handleButtonClick(button)}
          loading={loading}
          disabled={loading || button.disabled}
          id={button.id}
        />
      );
    });
  }

  handleButtonClick(button) {
    return () => {
      this.setState({ loading: true }, () => {
        if (button.onClick) {
          button
            .onClick()
            .then(() => {
              this.handleClose();
            })
            .catch(() => {
              this.handleClose();
            });
        } else {
          this.handleClose();
        }
      });
    };
  }

  handleClose = () => {
    if (!this.unmounted) {
      const { toggleDialog } = this.props;
      this.setState(
        { pageId: this.initialPageId(this.props), loading: false },
        () => {
          toggleDialog(false);
        }
      );
    }
  };
}

export default withI18n(MultiDialog);
