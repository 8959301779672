import PropTypes from "prop-types";

const UpgradeBundleShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
});

export default UpgradeBundleShape;
