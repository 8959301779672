import Resources from "shared/actions/resourcesActions";
import apiRequest from "../../shared/network/apiRequest";

// eslint-disable-next-line import/prefer-default-export
export class PackageResource extends Resources {
  constructor(dispatch) {
    super(dispatch, "account", "/api/v1/packages");
    this.refreshPageContentAfterCommit = true;
  }

  installation(id) {
    return apiRequest.post(`${this.url}/${id}/installation`, this.dispatch);
  }
}
