import React from "react";
import apiRequest from "shared/network/apiRequest";
import serialize from "shared/helpers/serialize";
import { UPDATE_SUB_LINE_ITEM_SEARCH_RESULT } from "shared/constants/appConstants";
import Growl from "builder_portal/actions/growlActions";
import { pickBy } from "lodash";
import { EventedRoomBookResources } from "builder_portal/actions/roomBookActions";

export class ProjectSubLineItemResource extends EventedRoomBookResources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "sub_line_item",
      `/api/v1/projects/${projectId}/sub_line_items`,
      "sub_line_items_search_results"
    );
    this.growl = new Growl(dispatch);
  }

  search(params) {
    const cleanParams = pickBy(params, value => value != null && value !== "");
    const url = `${this.url}/search?${serialize(cleanParams)}`;
    return apiRequest
      .get(url, this.dispatch, null, true)
      .then(::this.propagateUpdate)
      .catch(err => {
        const { status, data } = err;
        if (status === 400 && data && data.errors) {
          this.growl.error(
            "message.searchFailed.title",
            "message.searchFailed.error",
            {
              bodyValues: {
                error: (
                  <ul>
                    {data.errors.map(error => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                )
              }
            }
          );
        } else {
          this.growl.error(
            "message.searchFailed.title",
            "message.searchFailed.unknown"
          );
        }
      });
  }

  delete(ids) {
    const url = `${this.url}/delete_multiple`;
    return apiRequest
      .post(url, this.dispatch, { ids })
      .then(this.propagateRoomBookEvent)
      .then(response => {
        if (response.data.events) {
          const count = response.data.events.filter(
            event => event.type === "sub_line_item_deleted"
          ).length;
          this.dispatch({
            type: UPDATE_SUB_LINE_ITEM_SEARCH_RESULT,
            payload: response.data.events
          });
          this.growl.success(
            "batchEdit.messages.success.delete.title",
            "batchEdit.messages.success.delete.body",
            { bodyValues: { count } }
          );
        } else {
          this.propagateUpdate(response);
        }
      })
      .catch(this.handleDeleteFailed);
  }

  update(ids, params) {
    const url = `${this.url}/update_multiple`;
    const payload = { sub_line_item_ids: ids, ...params };
    return apiRequest
      .put(url, this.dispatch, payload)
      .then(response => {
        if (response.data.events) {
          const count = response.data.events.filter(
            event => event.type === "sub_line_item_updated"
          ).length;
          this.dispatch({
            type: UPDATE_SUB_LINE_ITEM_SEARCH_RESULT,
            payload: response.data.events
          });
          this.growl.success(
            "batchEdit.messages.success.update.title",
            "batchEdit.messages.success.update.body",
            { bodyValues: { count } }
          );
        } else {
          this.propagateUpdate(response);
        }
      })
      .catch(this.handleUpdateFailed);
  }
}
