import { string, shape, arrayOf } from "prop-types";

const ProjectRoomShape = shape({
  title: string,
  render_id: string,
  description: string,
  section_selectors: arrayOf(string)
});

export default ProjectRoomShape;
