import Resources from "shared/actions/resourcesActions";

export default class ProjectQuestionsResource extends Resources {
  constructor(dispatch, projectId) {
    super(
      dispatch,
      "project_question",
      `/api/v1/projects/${projectId}/project_questions`
    );
    this.refreshPageContentAfterCommit = true;
  }
}
