import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "semantic-ui-react";
import Field from "shared/components/forms/FieldComponent";
import { FormDefinition } from "shared/components/forms/FormDefinition";
import MultiDialog from "builder_portal/components/dialogs/MultiDialog";
import DerivedInfo from "builder_portal/components/roomBook/DerivedInfo";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getDerivedIds } from "builder_portal/selectors/roomBooks/descendantsInfo";
import { getUnitFeatureOptions } from "builder_portal/selectors/unitFeatureGroups";
import { UnitFeatureShape } from "shared/shapes/unitFeatureGroup.shape";
import DeleteButton from "../buttons/DeleteButton";
import silentHandleApiRequestErrors from "../../../shared/helpers/silentHandleApiRequestErrors";
import FeatureToggleActive from "../../../shared/components/elements/FeatureToggleActive";
import { getProjectRoomOptions } from "../../selectors/roomBooks/projectRooms";
import { If } from "../../../shared/components/elements/Conditions";
import ProjectRoomShape from "../../../shared/shapes/projectRoom.shape";

class SectionDialog extends Component {
  state = {
    open: false,
    isLoading: false,
    model: null,
    deleteDerivedLoading: false
  };

  onSave = values => {
    const { ctrl, parentId } = this.props;
    this.setState({ isLoading: true });

    if (values.id) {
      return ctrl.updateSection(values).then(this.handleClose);
    }
    return ctrl.createSection(values, parentId).then(this.handleClose);
  };

  componentWillUnmount = () => {
    this.unmounted = true;
  };

  onRemove = () => {
    const { ctrl, model } = this.props;
    this.setState({ isLoading: true });
    return ctrl
      .deleteSection(model.id)
      .catch(silentHandleApiRequestErrors)
      .then(() => {
        this.setState({ open: false, isLoading: false });
      });
  };

  toggleDialog = open => (open ? this.handleOpen() : this.handleClose());

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    if (!this.unmounted) {
      this.setState({ open: false, isLoading: false, data: undefined });
    }
  };

  getFormFactory() {
    const { type, isTemplate } = this.props;
    const fields = [
      {
        id: "title",
        label: `roomBook.sections.${type}.attributes.title.label`,
        placeholder: `roomBook.sections.${type}.attributes.title.placeholder`,
        message: `roomBook.sections.${type}.attributes.title.error`,
        rule: "isRequired",
        autoComplete: "off",
        autoFocus: true
      },
      {
        id: "project_room_id",
        label: `roomBook.sections.attributes.project_room_id.label`,
        placeholder: `roomBook.sections.attributes.project_room_id.placeholder`,
        message: `roomBook.sections.attributes.project_room_id.error`
      },
      {
        id: "project_room_ids",
        label: `roomBook.sections.attributes.project_room_ids.label`,
        placeholder: `roomBook.sections.attributes.project_room_ids.placeholder`,
        message: `roomBook.sections.attributes.project_room_ids.error`
      },
      {
        id: "external_id",
        label: `roomBook.sections.attributes.external_id.label`,
        placeholder: `roomBook.sections.attributes.external_id.placeholder`,
        message: `roomBook.sections.attributes.external_id.error`,
        autoComplete: "off"
      }
    ];

    if (isTemplate)
      fields.push({
        id: "unit_feature_ids",
        label: "roomBook.sections.attributes.unitFeatures.label"
      });

    return new FormDefinition({ fields });
  }

  render() {
    const { button, model } = this.props;
    return (
      <MultiDialog
        title={`${model.display_number} ${model.title}`}
        pages={this.getPages()}
        isVisible={this.state.open}
        toggleDialog={this.toggleDialog}
        trigger={button}
      />
    );
  }

  getPages = () => [
    this.getEditPage(),
    this.getDeleteButton(),
    this.getDeleteDerivedPage()
  ];

  getEditPage() {
    const {
      type,
      i18n,
      model,
      projectRooms,
      unitFeatures,
      isTemplate
    } = this.props;
    const { data } = this.state;

    let message;
    switch (type) {
      case "primary":
        message = model.id
          ? "roomBook.actions.editPrimarySection"
          : "roomBook.actions.addPrimarySection";
        break;
      case "secondary":
        message = model.id
          ? "roomBook.actions.editSecondarySection"
          : "roomBook.actions.addSecondarySection";
        break;
    }

    const form = this.getFormFactory().create(data || model, i18n, {
      onChange: data => this.setState({ data })
    });

    form.fields.project_room_id.props.selection = true;
    form.fields.project_room_id.props.clearable = true;
    form.fields.project_room_id.props.closeOnChange = true;
    form.fields.project_room_id.props.options = projectRooms;

    form.fields.project_room_ids.props.multiple = true;
    form.fields.project_room_ids.props.selection = true;
    form.fields.project_room_ids.props.clearable = true;
    form.fields.project_room_ids.props.closeOnChange = true;
    form.fields.project_room_ids.props.options = projectRooms;

    if (isTemplate) {
      form.fields.unit_feature_ids.props = {
        ...form.fields.unit_feature_ids.props,
        multiple: true,
        search: true,
        selection: true,
        options: unitFeatures,
        clearable: true
      };
    }

    return {
      id: "edit",
      title: <FormattedMessage id={message} defaultMessage={message} />,
      summary: "Bearbeiten Sie die Basisdaten dieser Section",
      renderContent: () => (
        <Form
          id="section"
          data-component="sectionForm"
          onSubmit={form.handleSubmit(this.onSave)}
        >
          <Form.Field width="16">
            <Field component="Input" {...form.fields.title} />
          </Form.Field>
          <If condition={type === "secondary"}>
            <Form.Field>
              <Field component="Select" {...form.fields.project_room_ids} />
            </Form.Field>
          </If>
          <FeatureToggleActive featureToggleName="show_external_id">
            <Form.Field width="16">
              <Field component="Input" {...form.fields.external_id} />
            </Form.Field>
          </FeatureToggleActive>
          <FeatureToggleActive featureToggleName="show_unit_feature_groups">
            <If condition={isTemplate}>
              <Form.Field width="16">
                <Field component="Select" {...form.fields.unit_feature_ids} />
              </Form.Field>
            </If>
          </FeatureToggleActive>
        </Form>
      ),
      actions: [
        {
          "data-form": "section",
          color: "green",
          disabled: this.state.isLoading,
          loading: this.state.isLoading,
          id: "save",
          onClick: this.getHandleMultiDialogOnClick(form),
          label: "meta.actions.save"
        }
      ]
    };
  }

  // TODO: Refactor Multidialog and/or FormDefinition so this crasy Promise/callback handling is not necessary anymore
  getHandleMultiDialogOnClick = form => () => {
    return new Promise((resolve, reject) => {
      form.handleSubmit(values => {
        resolve(this.onSave(values));
      })();
      reject();
    });
  };

  getDeleteButton() {
    const { isTemplate, model } = this.props;
    return {
      id: "delete",
      title: "Abschnitt löschen",
      summary: "Entfernen Sie diesen Abschnitt aus dem Raumbuch",
      type: "button",
      hidden: !model.deletable || isTemplate,
      renderButton: () => (
        <DeleteButton
          confirmationTitle="roomBook.sections.actions.removeDialog.title"
          confirmationMessage="roomBook.sections.actions.removeDialog.message"
          onDelete={this.onRemove}
          loading={this.state.isLoading}
          content={null}
          icon="trash"
        />
      )
    };
  }

  handleDeleteDerivedLoading = loading =>
    this.setState({ deleteDerivedLoading: loading });

  getDeleteDerivedPage() {
    const { model, type, isTemplate, ctrl } = this.props;
    const { deleteDerivedLoading } = this.state;
    return {
      id: "delete_derived",
      title: (
        <FormattedMessage id="roomBook.actions.deleteWithDerived.section.title" />
      ),
      summary: (
        <FormattedMessage id="roomBook.actions.deleteWithDerived.section.summary" />
      ),
      hidden: !model.deletable || !isTemplate,
      buttonIcon: "trash",
      buttonColor: "red",
      renderContent: () => (
        <DerivedInfo
          itemId={model.id}
          itemType={type}
          onLoading={this.handleDeleteDerivedLoading}
          onError={this.handleClose}
          loading={deleteDerivedLoading}
          resource={ctrl.sectionResource()}
        />
      ),
      actions: [
        {
          id: "remove",
          label: "meta.actions.remove",
          color: "red",
          onClick: this.onRemoveWithDerived,
          disabled: deleteDerivedLoading
        }
      ]
    };
  }

  onRemoveWithDerived = () => {
    const { model, ctrl, derivedIds } = this.props;
    this.setState({ isLoading: true });
    return ctrl
      .deleteSections([...derivedIds, model.id])
      .then(this.handleClose);
  };
}

SectionDialog.propTypes = {
  parentId: PropTypes.number,
  model: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  ctrl: PropTypes.object.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  derivedIds: PropTypes.array.isRequired,
  projectRooms: PropTypes.arrayOf(ProjectRoomShape),
  unitFeatures: PropTypes.arrayOf(UnitFeatureShape).isRequired
};

SectionDialog.defaultProps = {
  parentId: null,
  projectRooms: []
};

const mapStateToProps = createStructuredSelector({
  derivedIds: getDerivedIds,
  projectRooms: getProjectRoomOptions,
  unitFeatures: getUnitFeatureOptions
});

export default connect(mapStateToProps)(SectionDialog);
