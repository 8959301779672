import React from "react";
import { shape, string } from "prop-types";
import { connect } from "react-redux";
import { Header, Icon } from "semantic-ui-react";
import { IsSystemAdmin } from "../authorization/IsSystemAdmin";
import "./info.scss";

class Info extends React.PureComponent {
  render() {
    const { info, currentMailJetVariation } = this.props;
    return (
      <IsSystemAdmin>
        <div data-component="info">
          <Header as="div" size="tiny">
            <Icon name="gitlab" />
            <Header.Content>
              {info.tag}
              <Header.Subheader>{info.sha}</Header.Subheader>
            </Header.Content>
          </Header>
          <Header as="div" size="tiny">
            <Icon name="mail outline" />
            <Header.Content>{currentMailJetVariation}</Header.Content>
          </Header>
        </div>
      </IsSystemAdmin>
    );
  }
}

const mapStateToProps = state => {
  return {
    info: state?.account?.info,
    currentMailJetVariation: state?.account?.account?.mailjet_variation
  };
};

Info.defaultProps = {
  info: {
    tag: "",
    sha: ""
  },
  currentMailJetVariation: ""
};

Info.propTypes = {
  info: shape({
    tag: string,
    sha: string
  }),
  currentMailJetVariation: string
};

export default connect(mapStateToProps)(Info);
