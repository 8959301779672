import React, { useState, useMemo } from "react";
import { Table } from "semantic-ui-react";
import { arrayOf, bool, shape, string, instanceOf, func } from "prop-types";
import { I18nShape } from "shared/shapes/i18n.shape";
import { renderTableHeader, renderTableBody } from "./TablePartsRenderers";
import { DocumentStylesResource } from "../../../actions/documentsActions";
import { styleColumns } from "../../../selectors/documentExports";

import "./documentExport.scss";

const StylesTab = ({
  i18n,
  list,
  isLoading,
  loadData,
  documentStylesResource
}) => {
  const [filters, setFilters] = useState();

  const applyFilter = (name, vals) => {
    setFilters({ ...filters, [name]: vals.value });
  };

  const filterList = useMemo(() => {
    if (!filters) return list;
    let data = [...list];

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value) data = data.filter(x => x[key] === value);
    });
    return data;
  }, [filters, list]);

  const handlers = {
    handleSubmit: model => {
      return documentStylesResource.save(model).then(() => {
        loadData();
      });
    },
    handleDelete: id => {
      return documentStylesResource.remove(id).then(() => {
        loadData();
      });
    },
    handlePreview: () => {},
    handleDuplicate: () => {},
    handleDuplicateAll: () => {}
  };

  return (
    <Table data-component="DocumentExports">
      {renderTableHeader({
        i18n,
        columns: styleColumns,
        list,
        handlers,
        applyFilter,
        title: "documentsExport.menu.styles.title",
        dialog: "style"
      })}
      {renderTableBody({
        i18n,
        columns: styleColumns,
        list: filterList,
        isLoading,
        handlers,
        dialog: "style"
      })}
    </Table>
  );
};

StylesTab.propTypes = {
  i18n: I18nShape.isRequired,
  list: arrayOf(
    shape({
      name: string
    })
  ),
  isLoading: bool.isRequired,
  documentStylesResource: instanceOf(DocumentStylesResource).isRequired,
  loadData: func.isRequired
};

StylesTab.defaultProps = {
  list: []
};

export default StylesTab;
