import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getFeatureToggles } from "shared/selectors";
import { PropTypes } from "prop-types";

const FormattedMessageWithFlag = props => {
  const { featureToggleName, alternativeId } = props;
  const propsWithAlternativeId = { ...props, id: alternativeId };
  const featureToggles = useSelector(getFeatureToggles);
  const [isFeatureActive, setFeatureActive] = useState(false);

  useEffect(() => {
    if (featureToggles) setFeatureActive(featureToggles[featureToggleName]);
  }, [featureToggles]);

  return isFeatureActive ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormattedMessage {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormattedMessage {...propsWithAlternativeId} />
  );
};

FormattedMessageWithFlag.propTypes = {
  featureToggleName: PropTypes.string.isRequired,
  alternativeId: PropTypes.string.isRequired
};

export default FormattedMessageWithFlag;
