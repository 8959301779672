/* eslint-disable react/no-multi-comp */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { getProductsByProductGroupId } from "builder_portal/selectors";
import { Checkbox, Form, Modal } from "semantic-ui-react";

import FloatInput from "shared/components/forms/FloatInput";

import ProductInfoItem from "../product/ProductInfoItem";

const BatchEditDialog = props => {
  const {
    i18n,
    handleSetNewParameterFn,
    handleEnableNewParameterFn,
    selectedProductGroupId,
    groupedProducts,
    newQuantity,
    newDefaultQuantity,
    newProductId,
    newDefaultProductId
  } = props;

  const availableProductOptions = groupedProducts[selectedProductGroupId].map(
    product => {
      return {
        value: product.id,
        text: <ProductInfoItem product={product} mode="compact" />,
        content: <ProductInfoItem product={product} />
      };
    }
  );

  const renderCheckbox = id => {
    const checked = props[`${id}Enabled`];
    return (
      <Form.Field width="4">
        <Checkbox
          id={`${id}Enabled`}
          checked={checked}
          onChange={(event, data) => handleEnableNewParameterFn(data)}
          label={i18n[`batchEdit.labels.checkbox.${id}`]}
        />
      </Form.Field>
    );
  };

  const renderInput = (id, value) => {
    const { priceStrategy } = props;
    const label = i18n[`roomBook.priceStrategy.${priceStrategy}`];
    const inputValue = props[`${id}Enabled`] ? value : "";
    return (
      <Form.Field width="4">
        <FloatInput
          id={id}
          name={id}
          value={inputValue}
          autoComplete="off"
          disabled={!props[`${id}Enabled`]}
          placeholder={i18n[`batchEdit.placeholder.${id}`]}
          type="float"
          label={{
            basic: true,
            content: label
          }}
          labelPosition="right"
          onChange={(_, data) => handleSetNewParameterFn(data, id)}
        />
      </Form.Field>
    );
  };

  const renderDropdown = (id, value) => {
    const dropdownValue = props[`${id}Enabled`] ? value : "";
    return (
      <Form.Dropdown
        id={id}
        width="12"
        search
        selection
        value={dropdownValue}
        disabled={!props[`${id}Enabled`]}
        placeholder={i18n[`batchEdit.placeholder.${id}`]}
        options={availableProductOptions}
        onChange={(_, data) => handleSetNewParameterFn(data, id)}
      />
    );
  };

  return (
    <Modal.Content>
      <Form>
        <Form.Group width="16">
          {renderCheckbox("newQuantity")}
          {renderCheckbox("newProductId")}
        </Form.Group>
        <Form.Group width="16">
          {renderInput("newQuantity", newQuantity)}
          {renderDropdown("newProductId", newProductId)}
        </Form.Group>
        <Form.Group width="16">
          {renderCheckbox("newDefaultQuantity")}
          {renderCheckbox("newDefaultProductId")}
        </Form.Group>
        <Form.Group width="16" className="margin bottom spacious">
          {renderInput("newDefaultQuantity", newDefaultQuantity)}
          {renderDropdown("newDefaultProductId", newDefaultProductId)}
        </Form.Group>
      </Form>
    </Modal.Content>
  );
};

BatchEditDialog.propTypes = {
  i18n: PropTypes.object,
  priceStrategy: PropTypes.string,
  handleSetNewParameterFn: PropTypes.func,
  handleEnableNewParameterFn: PropTypes.func,
  selectedProductGroupId: PropTypes.number,
  groupedProducts: PropTypes.object,
  newQuantity: PropTypes.number,
  newDefaultQuantity: PropTypes.number
};

const mapStateToProps = createStructuredSelector({
  groupedProducts: getProductsByProductGroupId
});

export default connect(mapStateToProps)(BatchEditDialog);
