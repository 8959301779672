import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Icon } from "semantic-ui-react";

function PriceInquirySubmittedEvent({ date, contractor, title }) {
  return (
    <div
      data-component="timelineItem"
      className="PriceInquirySubmittedEvent"
      data-event="price_inquiry_submitted"
    >
      <div className="bullet-pointer">
        <Icon color="grey" size="large" circular name="send" />
      </div>
      <Container data-component="logDetails">
        <time>{date}</time>
        <FormattedMessage
          id="activity.timeline.event.priceInquirySubmitted"
          defaultMessage="activity.timeline.event.priceInquirySubmitted"
          values={{ title, contractor }}
        />
        <div className="user">{contractor.name}</div>
      </Container>
    </div>
  );
}

PriceInquirySubmittedEvent.propTypes = {
  date: PropTypes.string.isRequired,
  contractor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default PriceInquirySubmittedEvent;
