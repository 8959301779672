import React from "react";
import { Modal, Card, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { If } from "shared/components/elements/Conditions";
import { func, number, object, shape, string } from "prop-types";
import ProductInfoItem from "../product/ProductInfoItem";

import imagePlaceholder from "../../../../assets/images/placeholder-square.png";
import "./lineItemCreationWizard.scss";

const ProductSelector = ({
  productsRepository,
  dialogModel,
  selection,
  toggleSelection,
  desc,
  page
}) => {
  return (
    <Modal.Content>
      <Card.Group itemsPerRow={3}>
        {productsRepository
          .getGroupProducts(dialogModel.productGroupId)
          .filter(product => !product.deprecated)
          .map(product => {
            return (
              <Card
                key={product.id}
                className={selection === product.id ? "selected" : ""}
                onClick={() => toggleSelection(product)}
                data-model="product"
              >
                <Card.Content
                  className="image"
                  style={{ backgroundImage: `url(${product.thumb_url})` }}
                />
                <Card.Content>
                  <ProductInfoItem product={product} renderSku />
                </Card.Content>
              </Card>
            );
          })}

        <If condition={page === "productSelection"}>
          <Card
            className={desc === "no_product" ? "selected" : ""}
            onClick={() => toggleSelection("no_product")}
            data-model="product"
          >
            <Card.Content
              className="image"
              style={{ backgroundImage: `url(${imagePlaceholder})` }}
            />
            <Card.Content>
              <Header
                as="h4"
                data-test="component-adhoc-product-info-item"
                data-component="adhoc-product-info-item"
              >
                <Header.Subheader>
                  <div className="productSupplier" data-test="product-supplier">
                    <FormattedMessage
                      id="roomBook.lineItems.individualProduct"
                      defaultText="Wunschprodukt"
                    />
                  </div>
                </Header.Subheader>

                <FormattedMessage
                  id="roomBook.lineItems.addIndividualProduct"
                  defaultText="Wunschprodukt hinzufugen"
                />
              </Header>
            </Card.Content>
          </Card>
        </If>
      </Card.Group>
    </Modal.Content>
  );
};

ProductSelector.propTypes = {
  desc: string,
  page: string.isRequired,
  toggleSelection: func.isRequired,
  selection: string,
  dialogModel: shape({
    productGroupId: number,
    parentType: string,
    parentId: number
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  productsRepository: object.isRequired
};

ProductSelector.defaultProps = {
  desc: undefined,
  selection: null
};

export default ProductSelector;
