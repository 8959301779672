import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { browserHistory } from "shared/routes/browserHistory";
import PropTypes from "prop-types";
import { Modal, Button, Form, Message } from "semantic-ui-react";
import { I18nShape, ProjectCatalogShape, ProjectShape } from "shared/shapes";
import { getProjects, getUser } from "shared/selectors";
import { getUsers } from "shared/selectors/account";
import { FormattedMessage } from "react-intl";
import { getProductsMetaData } from "shared/selectors/productsMetaData";
import { AccountUsersResource } from "builder_portal/actions/accountActions";
import { ProductMetaDataResource } from "builder_portal/actions/productActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import CatalogResource from "../../actions/catalogActions";
import { IsSystemAdmin } from "../../../shared/components/authorization/IsSystemAdmin";
import { If } from "../../../shared/components/elements/Conditions";
import { UserShape } from "../../../shared/shapes";

const ProductCatalogDialog = ({
  i18n,
  catalog: initialCatalog,
  catalogs,
  open: externalOpen,
  resource,
  onClose,
  trigger,
  projects,
  users
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(externalOpen || false);
  const { product_catalogs: productCatalogs } = useSelector(
    getProductsMetaData
  );

  const productCatalogOptions = useMemo(
    () =>
      productCatalogs?.map(
        productCatalog =>
          ({
            key: productCatalog.id,
            value: productCatalog.id,
            text: productCatalog.name
          } || [])
      ),
    [productCatalogs]
  );

  const [catalog, setCatalog] = useState(
    initialCatalog || {
      name: "",
      source_id: null,
      project_ids: [],
      user_ids: []
    }
  );

  const [loading, setLoading] = useState(false);
  const { id: userId } = useSelector(getUser);

  const isCurrentUserInUserIds = useMemo(() => {
    if (catalog?.user_ids) {
      if (catalog?.user_ids.length === 0) {
        return true;
      }
      if (catalog?.user_ids.includes(userId)) {
        return true;
      }
      return false;
    }
    return false;
  }, [catalog?.user_ids, userId]);

  useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  useEffect(() => {
    new AccountUsersResource(dispatch).fetchAll();
    new ProductMetaDataResource(dispatch).fetchAll();
    new ProjectsResource(dispatch).fetchAll();
  }, []);

  const projectCatalogOptions = useMemo(() => {
    const list = catalog.product_catalog_id
      ? catalogs.filter(
          c => c.product_catalog_id === catalog.product_catalog_id
        )
      : catalogs;

    return list.map(c => ({
      key: c.id,
      text: c.name,
      value: c.id
    }));
  }, [catalogs, catalog.product_catalog_id]);

  const projectOptions = useMemo(() => {
    return projects.map(project => {
      return {
        key: project.id,
        text: project.name,
        value: project.id
      };
    });
  }, [projects]);

  const userOptions = useMemo(() => {
    return users.map(user => {
      return {
        key: user.id,
        text: `${user.first_name || ""} ${user.last_name || ""}`,
        value: user.id
      };
    });
  }, [users]);

  const handleNameChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, name: value });
    },
    [catalog]
  );

  const handleSlugChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, slug: value });
    },
    [catalog]
  );

  const handleSourceChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, source_id: value });
    },
    [catalog]
  );

  const handleProductCatalogChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, product_catalog_id: value, source_id: null });
    },
    [catalog]
  );

  const handleProjectIdsChanged = useCallback(
    (_, { value }) => {
      setCatalog({ ...catalog, project_ids: value });
    },
    [catalog]
  );

  const handleUserIdsChanged = useCallback((_, { value }) => {
    setCatalog({ ...catalog, user_ids: value });
  });

  const handleClose = useCallback(() => {
    if (!catalog.id) {
      setCatalog(initialCatalog || { name: "", source_id: null, user_ids: [] });
    }
    setLoading(false);
    setOpen(false);
    onClose();
  }, [initialCatalog, setCatalog, setOpen, onClose]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    resource
      .save(catalog)
      .then(({ data }) => {
        resource.fetchAll().then(() => {
          browserHistory.push(`/products/${data.catalog.slug}/product_groups`);
          handleClose();
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }, [catalog, resource]);

  const handleDelete = useCallback(() => {
    setLoading(true);
    resource
      .remove(catalog.id)
      .then(() => {
        resource.fetchAll();
        onClose();
        browserHistory.push(`/products`);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const validProjectIds = catalog.id || catalog.project_ids?.length;
  const validCatalogName = !!catalog.name;
  const validSlug = /^[a-z0-9-_]+$/.test(catalog.slug);
  const valid = validSlug && validProjectIds && validCatalogName;

  return (
    <Modal open={open} size="small" trigger={trigger} onOpen={handleOpen}>
      <Modal.Header>
        {i18n[`project_catalog.title.${catalog.id ? "edit" : "add"}`]}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            label={i18n["project_catalog.attributes.name.label"]}
            value={catalog.name}
            placeholder={i18n["project_catalog.attributes.name.placeholder"]}
            onChange={handleNameChanged}
          />
          <If condition={!!catalog.id}>
            <IsSystemAdmin>
              <Form.Input
                label={i18n["project_catalog.attributes.slug.label"]}
                value={catalog.slug}
                placeholder={
                  i18n["project_catalog.attributes.slug.placeholder"]
                }
                onChange={handleSlugChanged}
              />
            </IsSystemAdmin>
          </If>
          <If condition={productCatalogs?.length > 1}>
            <Form.Dropdown
              label={
                i18n["project_catalog.attributes.product_catalog_id.label"]
              }
              placeholder={
                i18n[
                  "project_catalog.attributes.product_catalog_id.placeholder"
                ]
              }
              value={catalog.product_catalog_id}
              options={productCatalogOptions}
              onChange={handleProductCatalogChanged}
              disabled={!!catalog.id}
              fluid
              selection
              search
              closeOnChange
            />
          </If>
          {!catalog.id && projectCatalogOptions.length > 0 && (
            <Form.Select
              label={i18n["project_catalog.attributes.source_id.label"]}
              value={catalog.source_id}
              placeholder={
                i18n["project_catalog.attributes.source_id.placeholder"]
              }
              onChange={handleSourceChanged}
              options={projectCatalogOptions}
              clearable
            />
          )}
          {!catalog.id && (
            <Form.Dropdown
              label={i18n["project_catalog.attributes.project_ids.label"]}
              value={catalog.project_ids}
              placeholder={
                i18n["project_catalog.attributes.project_ids.placeholder"]
              }
              options={projectOptions}
              onChange={handleProjectIdsChanged}
              fluid
              multiple
              selection
              closeOnChange
            />
          )}
          <Form.Dropdown
            label={i18n["project_catalog.attributes.user_ids.label"]}
            value={catalog?.user_ids}
            placeholder={
              i18n["project_catalog.attributes.user_ids.placeholder"]
            }
            options={userOptions}
            onChange={handleUserIdsChanged}
            fluid
            multiple
            selection
            search
            closeOnChange
          />
        </Form>
        {!isCurrentUserInUserIds && (
          <Message warning>
            <FormattedMessage id="project_catalog.attributes.no_current_user_id_warning_message" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        {catalog.deletable && (
          <Button
            color="red"
            content={i18n["meta.actions.remove"]}
            onClick={handleDelete}
            loading={loading}
            className="left floated"
          />
        )}
        <Button
          basic
          content={i18n["meta.actions.cancel"]}
          onClick={handleClose}
          loading={loading}
        />
        <Button
          primary
          content={
            catalog.id ? i18n["meta.actions.save"] : i18n["meta.actions.add"]
          }
          onClick={handleSubmit}
          disabled={!valid}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

ProductCatalogDialog.propTypes = {
  i18n: I18nShape.isRequired,
  catalog: ProjectCatalogShape,
  catalogs: PropTypes.arrayOf(ProjectCatalogShape),
  open: PropTypes.bool,
  resource: PropTypes.instanceOf(CatalogResource).isRequired,
  onClose: PropTypes.func,
  trigger: PropTypes.node,
  projects: PropTypes.arrayOf(ProjectShape),
  users: PropTypes.arrayOf(UserShape)
};

ProductCatalogDialog.defaultProps = {
  open: false,
  catalog: null,
  catalogs: [],
  projects: [],
  onClose: () => {},
  trigger: null,
  users: []
};

const mapStateToProps = state => ({
  i18n: state.i18n,
  projects: getProjects(state),
  users: getUsers(state)
});

const mapDispatchToProps = dispatch => {
  return {
    resource: new CatalogResource(dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCatalogDialog);
