/* eslint-disable react/display-name */
import React from "react";

export const defaultRichTextTags = {
  b: (...chunks) => <b>{chunks}</b>,
  strong: (...chunks) => <strong>{chunks}</strong>,
  i: (...chunks) => <i>{chunks}</i>,
  em: (...chunks) => <em>{chunks}</em>,
  mark: (...chunks) => <mark>{chunks}</mark>,
  small: (...chunks) => <small>{chunks}</small>,
  s: (...chunks) => <s>{chunks}</s>,
  del: (...chunks) => <del>{chunks}</del>,
  ins: (...chunks) => <ins>{chunks}</ins>,
  sub: (...chunks) => <sub>{chunks}</sub>,
  sup: (...chunks) => <sup>{chunks}</sup>,
  p: (...chunks) => <p>{chunks}</p>,
  ul: (...chunks) => <ul>{chunks}</ul>,
  ol: (...chunks) => <ol>{chunks}</ol>,
  li: (...chunks) => <li>{chunks}</li>
};

export default defaultRichTextTags;
