import { get, sortBy } from "lodash";
import { createSelector } from "reselect";
import { getPageContent } from "shared/selectors/base";

export const getDerivedRoombooks = createSelector(
  [getPageContent],
  pageContent => {
    const list = get(pageContent, "derived_room_books", []);
    return {
      templates: sortBy(
        list.filter(item => item.template),
        "name"
      ),
      units: sortBy(
        list.filter(item => !item.template),
        "unit_display_name"
      )
    };
  }
);

export const getRoomBooks = createSelector([getPageContent], pageContent =>
  sortBy(get(pageContent, "room_books", []), "id")
);

export default getDerivedRoombooks;
