import React from "react";
import { Form, Grid, Input } from "semantic-ui-react";
import { string, func } from "prop-types";

const ColorInputWithHexField = ({ label, value, onChange }) => {
  const handleHexInput = e => {
    const input = e.target.value || "#";
    const match = input.match("#[0-9a-fA-F]{0,6}");
    const validatedInput = match ? match[0] : undefined;

    onChange(e, { value: validatedInput });
  };

  return (
    <Form.Field>
      <label
        style={{
          color: "rgba(0,0,0,.87)",
          fontSize: ".92857143em",
          fontWeight: 700
        }}
      >
        {label}
      </label>
      <Grid>
        <Grid.Column width={13} textAlign="left">
          <Input type="text" fluid onChange={handleHexInput} value={value} />
        </Grid.Column>
        <Grid.Column width={3} textAlign="right">
          <input
            type="color"
            onChange={event => {
              onChange(event, { value: event.target.value });
            }}
            value={value}
            style={{
              width: "100%",
              height: "30px",
              padding: 0,
              border: "none",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.21), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          />
        </Grid.Column>
      </Grid>
    </Form.Field>
  );
};

ColorInputWithHexField.propTypes = {
  onChange: func.isRequired,
  label: string.isRequired,
  value: string.isRequired
};

export default ColorInputWithHexField;
