import PropTypes from "prop-types";

export const UnitFeatureGroupShape = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
});

export const UnitFeatureShape = PropTypes.shape({
  unitFeatureGroupId: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
});
