import React from "react";
import { connect } from "react-redux";
import { instanceOf, oneOf, shape } from "prop-types";
import { Grid, Menu, Header, Loader } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { DEPRECATED_PageContentShape } from "shared/shapes/deprecated_pageContent.shape";
import { Link } from "react-router";
import { I18nShape } from "shared/shapes/i18n.shape";
import Growl from "builder_portal/actions/growlActions";
import { UserShape } from "shared/shapes/user.shape";
import { Account as AccountModel } from "shared/models/account";
import {
  AccountResource,
  AccountUsersResource,
  ProcessDefinitionsResource
} from "builder_portal/actions/accountActions";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import MacroResource from "builder_portal/actions/macroActions";
import Account from "builder_portal/components/account/Account";
import { IsSystemAdmin } from "shared/components/authorization/IsSystemAdmin";
import FeatureToggleActive from "shared/components/elements/FeatureToggleActive";
import { If } from "shared/components/elements/Conditions";
import ProjectDataExport from "builder_portal/components/account/project_data_export/ProjectDataExport";
import ProjectFiles from "builder_portal/components/account/project_files/ProjectFiles";
import Users from "./users/Users";
import ProcessItems from "./processes/ProcessItems";
import MacroItems from "./macros/MacroItems";
import DnsItem from "./dns/DnsItem";
import Attachments from "./attachments/Attachments";
import Trades from "./trades/Trades";
import BuyerPortal from "./buyerPortal/BuyerPortal";
import DocumentExports from "./documents/DocumentExports";
import EmailTemplates from "./email_templates/EmailTemplates";
import ProjectSettings from "./project_settings";
import { withRouter } from "../../../shared/helpers/withRouter";
import { getAccount } from "../../../shared/selectors";
import MasterDataLoader from "../../loaders/masterData/MasterDataLoader";
import FeatureToggles from "./feature_toggles/FeatureToggles";
import PackageManager from "./package_manager/PackageManager";
import Roles from "./account_roles";
import CustomFieldsTable from "./custom_fields/CustomFieldsTable";
import Webhooks from "./webhooks/WebhooksTable";

class Layout extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.account.get(true);
  }

  renderChildren() {
    const {
      tab,
      pageContent,
      actions,
      i18n,
      growl,
      user,
      account
    } = this.props;
    switch (tab) {
      case "projects":
        return <ProjectSettings />;
      case "general":
        return (
          <Account
            pageContent={pageContent}
            resource={actions.account}
            i18n={i18n}
            growl={growl}
          />
        );
      case "processes":
        return <ProcessItems actions={actions} admin={user} />;
      case "users":
        return (
          <Users
            pageContent={pageContent}
            actions={actions}
            i18n={i18n}
            growl={growl}
            admin={user}
            isSystemAdmin={account.isSystemAdmin()}
          />
        );
      case "documents":
        return (
          <Attachments
            pageContent={pageContent}
            actions={actions}
            i18n={i18n}
            growl={growl}
            admin={user}
          />
        );
      case "trades":
        return <Trades actions={actions} i18n={i18n} admin={user} />;
      case "macros":
        return (
          <MacroItems
            pageContent={pageContent}
            actions={actions}
            i18n={i18n}
            growl={growl}
            admin={user}
          />
        );
      case "email-integration":
        return (
          <DnsItem
            account={get(pageContent, "account")}
            actions={actions}
            i18n={i18n}
            growl={growl}
          />
        );
      case "buyer_portal":
        return <BuyerPortal />;
      case "email-templates":
        return <EmailTemplates />;
      case "document_exports":
        return <DocumentExports />;
      case "feature_toggles":
        return <FeatureToggles />;
      case "package_manager":
        return <PackageManager />;
      case "roles":
        return <Roles />;
      case "custom_fields":
        return <CustomFieldsTable />;
      case "webhooks":
        return <Webhooks />;
      case "project_data_export":
        return <ProjectDataExport />;
      case "project_files":
        return <ProjectFiles />;
      default:
        return <h1>{tab}</h1>;
    }
  }

  render() {
    const { user, account, tab, pageContent } = this.props;
    const isAdmin = user.group === "admin";
    const canEditEmailTemplates = account.isEnabled("edit_email_templates");

    const isLoading = !(account && pageContent);

    return (
      <div>
        <Helmet title="Account" />
        <MasterDataLoader />
        <Grid stackable verticalAlign="middle">
          <Grid.Column width="16">
            <Header size="large">
              <FormattedMessage
                id="account.title.settings"
                defaultMessage="account.title.settings"
              />
            </Header>
          </Grid.Column>
        </Grid>
        <Grid stackable data-component="account">
          <Grid.Column width="4">
            <Menu
              pointing
              vertical
              style={{ width: "100%" }}
              data-component="settings-menu"
            >
              <IsSystemAdmin>
                <Menu.Item
                  as={Link}
                  to="/account/general"
                  className="general"
                  active={tab === "general"}
                >
                  <FormattedMessage
                    id="menu.main.setting"
                    defaultMessage="Einstellungen"
                  />
                </Menu.Item>
              </IsSystemAdmin>
              <IsSystemAdmin>
                <Menu.Item
                  as={Link}
                  to="/account/package_manager"
                  className="package_manager"
                  active={tab === "package_manager"}
                >
                  <FormattedMessage
                    id="menu.main.packageManager"
                    defaultMessage="Package manager"
                  />
                </Menu.Item>
              </IsSystemAdmin>
              <IsSystemAdmin>
                <Menu.Item
                  as={Link}
                  to="/account/projects"
                  className="projects"
                  active={tab === "projects"}
                >
                  <FormattedMessage
                    id="project.title.other"
                    defaultMessage="Bauprojekte"
                  />
                </Menu.Item>
              </IsSystemAdmin>
              <Menu.Item
                as={Link}
                to="/account/processes"
                className="processes"
                active={tab === "processes"}
              >
                <FormattedMessage
                  id="activity.label.other"
                  defaultMessage="Prozesse"
                />
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/account/users"
                className="users"
                active={tab === "users"}
              >
                <FormattedMessage
                  id="user.roles.operator.label"
                  defaultMessage="Benutzer"
                />
              </Menu.Item>
              <FeatureToggleActive featureToggleName="custom_roles">
                <Menu.Item
                  as={Link}
                  to="/account/roles"
                  className="roles"
                  active={tab === "roles"}
                >
                  <FormattedMessage
                    id="menu.main.accountRoles"
                    defaultMessage="accountRoles"
                  />
                </Menu.Item>
              </FeatureToggleActive>
              <Menu.Item
                as={Link}
                to="/account/documents"
                className="documents"
                active={tab === "documents"}
              >
                <FormattedMessage
                  id="attachment.title.other"
                  defaultMessage="Dokumente"
                />
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/account/trades"
                className="trades"
                active={tab === "trades"}
              >
                <FormattedMessage
                  id="trades.title.many"
                  defaultMessage="Gewerke"
                />
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/account/macros"
                className="macros"
                active={tab === "macros"}
              >
                <FormattedMessage
                  id="email.macro.other"
                  defaultMessage="Standardemails"
                />
              </Menu.Item>
              <If condition={isAdmin || canEditEmailTemplates}>
                <Menu.Item
                  as={Link}
                  to="/account/email-templates"
                  className="email-templates"
                  active={tab === "email-templates"}
                >
                  <FormattedMessage
                    id="email.template.other"
                    defaultMessage="E-Mail Vorlagen"
                  />
                </Menu.Item>
              </If>
              <Menu.Item
                as={Link}
                to="/account/email-integration"
                className="email-integration"
                active={tab === "email-integration"}
              >
                <FormattedMessage
                  id="email.integration"
                  defaultMessage="E-Mail Integration"
                />
              </Menu.Item>
              <IsSystemAdminOrHasSpecificRight right="ext_allow_edit_configurator_config">
                <Menu.Item
                  as={Link}
                  to="/account/buyer_portal"
                  className="buyer_portal"
                  active={tab === "buyer_portal"}
                >
                  <FormattedMessage
                    id="buyerPortal.label.one"
                    defaultMessage="Erwerberportal"
                  />
                </Menu.Item>
              </IsSystemAdminOrHasSpecificRight>
              <IsSystemAdmin>
                <FeatureToggleActive featureToggleName="document_exports_12">
                  <Menu.Item
                    as={Link}
                    to="/account/document_exports"
                    className="document_exports"
                    active={tab === "document_exports"}
                  >
                    <FormattedMessage
                      id="menu.main.documentExports"
                      defaultMessage="Dokumentenexporte"
                    />
                  </Menu.Item>
                </FeatureToggleActive>
              </IsSystemAdmin>
              <IsSystemAdmin>
                <Menu.Item
                  as={Link}
                  to="/account/feature_toggles"
                  className="feature_toggles"
                  active={tab === "feature_toggles"}
                >
                  <FormattedMessage
                    id="menu.main.featureToggles"
                    defaultMessage="Feature toggles"
                  />
                </Menu.Item>
              </IsSystemAdmin>
              <FeatureToggleActive featureToggleName="api_documentation">
                <Menu.Item
                  as={Link}
                  to="/account/webhooks"
                  className="webhooks"
                  active={tab === "webhooks"}
                >
                  <FormattedMessage
                    id="menu.main.webhooks"
                    defaultMessage="Webhooks"
                  />
                </Menu.Item>
              </FeatureToggleActive>
              <FeatureToggleActive featureToggleName="custom_product_fields">
                <IsSystemAdmin>
                  <Menu.Item
                    as={Link}
                    to="/account/custom_fields"
                    className="custom_fields"
                    active={tab === "custom_fields"}
                  >
                    <FormattedMessage
                      id="customFields.tab.title"
                      defaultMessage="Datenmodel"
                    />
                  </Menu.Item>
                </IsSystemAdmin>
              </FeatureToggleActive>
              <Menu.Item
                as={Link}
                to="/account/project_data_export"
                className="project_data_export"
                active={tab === "project_data_export"}
              >
                <FormattedMessage
                  id="menu.main.dataExport"
                  defaultMessage="Data Export"
                />
              </Menu.Item>

              <Menu.Item
                as={Link}
                to="/account/project_files"
                className="project_files"
                active={tab === "project_files"}
              >
                <FormattedMessage
                  id="menu.main.projectFiles"
                  defaultMessage="Project files"
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width="12">
            <Loader active={isLoading} />
            {!isLoading && this.renderChildren()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
Layout.defaultProps = {
  tab: "users",
  pageContent: {}
};

Layout.propTypes = {
  actions: shape({
    account: instanceOf(AccountResource),
    users: instanceOf(AccountUsersResource),
    process_definition: instanceOf(ProcessDefinitionsResource),
    projects: instanceOf(ProjectsResource),
    macros: instanceOf(MacroResource)
  }).isRequired,
  pageContent: DEPRECATED_PageContentShape,
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired,
  user: UserShape.isRequired,
  account: instanceOf(AccountModel).isRequired,
  tab: oneOf([
    "general",
    "projects",
    "processes",
    "users",
    "documents",
    "trades",
    "macros",
    "email-templates",
    "email-integration",
    "buyer_portal",
    "document_exports",
    "feature_toggles",
    "package_manager",
    "api_documentation",
    "webhooks",
    "project_data_export",
    "project_files"
  ])
};

const mapStateToProps = (state, props) => {
  const { tab } = props.params;
  return {
    tab,
    i18n: state.i18n,
    pageContent: state.pageContent,
    user: get(state, "account.user", { group: "user" }),
    account: getAccount(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      account: new AccountResource(dispatch),
      users: new AccountUsersResource(dispatch),
      process_definitions: new ProcessDefinitionsResource(dispatch),
      projects: new ProjectsResource(dispatch),
      macros: new MacroResource(dispatch)
    },
    growl: new Growl(dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
