import { shape, number, string, oneOfType } from "prop-types";

export const ActivityShape = shape({
  id: oneOfType([string, number]),
  display_name: string,
  title: string,
  summary: string,
  project: shape({
    id: number,
    label: string,
    slug: string
  })
});

export default ActivityShape;
