import Resources from "../../shared/actions/resourcesActions";

export default class MembershipReplacementResource extends Resources {
  constructor(dispatch) {
    super(
      dispatch,
      "project_membership_replacement",
      "/api/v1/project_membership_replacements"
    );
  }
}
