import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Container, Icon } from "semantic-ui-react";

class ChangeDeadlineEvent extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    user: PropTypes.string,
    status: PropTypes.string,
    oldValue: PropTypes.string,
    newValue: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { date, user, status, oldValue, newValue } = this.props;
    const oldDeadline = moment(oldValue).format("DD.MM.YYYY");
    const newDeadline = moment(newValue).format("DD.MM.YYYY");

    return (
      <div data-component="timelineItem" data-event="deadline_changed">
        <div className="bullet-pointer">
          <Icon color="grey" size="large" circular name="refresh" />
        </div>
        <Container data-component="logDetails">
          <time>{date}</time>{" "}
          <FormattedMessage
            id="activity.timeline.event.changeDeadline"
            defaultMessage="activity.timeline.event.changeDeadline"
            values={{ status, oldDeadline, newDeadline }}
          />
          <div className="user">{user}</div>
        </Container>
      </div>
    );
  }
}

export default ChangeDeadlineEvent;
