import React, { useEffect, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { string, oneOfType, number, func, node } from "prop-types";
import UpgradeBundlesResource from "builder_portal/actions/upgradeBundlesActions";
import UpgradeBundleGroupsResource from "builder_portal/actions/upgradeBundleGroupsActions";
import getUpgradeBundles from "builder_portal/selectors/upgradeBundles";
import getUpgradeBundleGroups from "builder_portal/selectors/upgradeBundleGroups";

export const UpgradeBundlesLoaderContext = createContext({});

const UpgradeBundlesLoader = ({ projectId, children }) => {
  const dispatch = useDispatch();
  const [openedBundles, setOpenedBundles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const upgradeBundleGroups = useSelector(getUpgradeBundleGroups);
  const upgradeBundles = useSelector(getUpgradeBundles);

  const loadData = () => {
    setLoading(true);

    Promise.all([
      new UpgradeBundlesResource(dispatch, projectId).fetchAll(),
      new UpgradeBundleGroupsResource(dispatch, projectId).fetchAll()
    ]).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <UpgradeBundlesLoaderContext.Provider
      value={{
        projectId,
        upgradeBundleGroups,
        upgradeBundles,
        isLoading,
        openedBundles,
        setOpenedBundles,
        loadData
      }}
    >
      {children}
    </UpgradeBundlesLoaderContext.Provider>
  );
};

UpgradeBundlesLoader.propTypes = {
  projectId: oneOfType([string, number]),
  children: oneOfType([func, node])
};

UpgradeBundlesLoader.defaultProps = {
  projectId: null,
  children: null
};

export default UpgradeBundlesLoader;
