import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal, Button, Header, Message } from "semantic-ui-react";
import { getProject } from "shared/selectors";
import { UnitsResource } from "builder_portal/actions/unitActions";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { If } from "shared/components/elements/Conditions";
import localStorage from "shared/helpers/localStorage";
import { FormattedMessage, useIntl } from "react-intl";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import { node, func } from "prop-types";
import FileUploader from "../../dropzone/FileUploader";
import FileImportsResource from "../../../actions/fileImportsActions";

const STATUS_CHECK_INTERVAL = 1500;
const NUMBER_OF_POLLING_CYCLES = 5;
const EMPTY_MODEL = { file: null };

const SectionAndUnitUploadModal = ({ button, onCompleted }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const project = useSelector(getProject);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [model, setModel] = useState(EMPTY_MODEL);

  const handleOpen = () => {
    setError("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModel(EMPTY_MODEL);
  };

  const handleFile = file => {
    setModel(currentModel => ({
      ...currentModel,
      file
    }));
    setError("");
  };

  const afterPolling = () => {
    new UnitsResource(dispatch, project.id).fetchAll();
    new ProjectsResource(dispatch).fetchStructure(project.id);
    if (typeof onCompleted === "function") {
      onCompleted();
    }
    handleClose();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    NUMBER_OF_POLLING_CYCLES,
    afterPolling
  );

  const handleUpload = () => {
    const formData = new FormData();
    const { file } = model;
    if (!file) {
      setError(
        intl.formatMessage({
          id: "project.unit_variables.dialog.messages.selectFile"
        })
      );
      return;
    }
    setError("");

    // add expected name
    formData.append(`units[file]`, file);
    // add expected name
    fetch(`/api/v1/projects/${project.slug}/units/import`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.get("token")}`
      }
    })
      .then(response => response.json())
      .then(res => {
        if (res.id) {
          startPolling(res.id, STATUS_CHECK_INTERVAL);
        }
      })
      .catch(() => {
        stopPolling();
        setError(
          intl.formatMessage({
            id: "unit.actions.upload.error"
          })
        );
      });
  };

  return (
    <>
      <Modal
        closeIcon
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        trigger={button}
      >
        <Modal.Header>
          <Header as="h3">
            <FormattedMessage id="unit.actions.upload.title" />
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Message error content={error} hidden={!error} />
          <Form>
            <If condition={!model.file}>
              <FileUploader handleFile={handleFile} fileType="excel" />
            </If>
            <If condition={!!model.file}>
              <Header as="h5">{model.file?.path}</Header>
            </If>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            id="close"
            content={intl.formatMessage({ id: "meta.actions.close" })}
            onClick={handleClose}
          />
          <Button
            primary
            id="submit"
            content={intl.formatMessage({ id: "attachment.actions.upload" })}
            onClick={handleUpload}
            disabled={isPolling}
          />
        </Modal.Actions>
      </Modal>
      <TinyLoader opened={isPolling} />
    </>
  );
};

SectionAndUnitUploadModal.propTypes = {
  button: node.isRequired,
  onCompleted: func
};

SectionAndUnitUploadModal.defaultProps = {
  onCompleted: () => {}
};

export default SectionAndUnitUploadModal;
