import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getProjectCurrency } from "shared/selectors/currency";
import { withRouter } from "../../helpers/withRouter";
import FloatInput from "./FloatInput";

// Behavioural wrapper for FloatInput

class CurrencyInput extends Component {
  static propTypes = {
    props: PropTypes.object,
    error: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const options = { ...this.props };
    options.label = this.props.currency.sign;
    options.labelPosition = options.labelPosition || "right";

    return <FloatInput data-component="currencyInput" {...options} />;
  }
}

const mapStateToProps = (state, props) => {
  const { projectId } = props.params;
  return {
    currency: getProjectCurrency(state, projectId)
  };
};

export default withRouter(connect(mapStateToProps)(CurrencyInput));
