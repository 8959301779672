import { shape, arrayOf, number, string, object } from "prop-types";

export const MacroShape = shape({
  body_template: string,
  call_to_action: string,
  config: object,
  due_at: string,
  icon: string,
  id: number,
  macro_id: string,
  name: string,
  subject_template: string,
  task_title: string,
  project_ids: arrayOf(number),
  projects: arrayOf(
    shape({
      id: number,
      name: string
    })
  )
});

export default MacroShape;
