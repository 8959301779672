import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, Grid, Button, Dropdown, Header } from "semantic-ui-react";
import { ProjectsResource } from "builder_portal/actions/projectActions";
import { ProjectRoomBooksResource } from "builder_portal/actions/roomBookActions";
import FileImportsResource from "builder_portal/actions/fileImportsActions";
import usePolling from "builder_portal/hooks/usePolling";
import TinyLoader from "shared/components/loader/TinyLoader";
import { getProjects, getProject } from "shared/selectors";
import { func } from "prop-types";
import SelectableTree from "./SelectableTree";

const STATUS_CHECK_INTERVAL = 8000;

const RoomBookTemplatesImport = ({ deselectAction }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const project = useSelector(getProject);
  const [isOpen, setOpen] = useState(true);
  const [selectedProject, setProject] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    new ProjectsResource(dispatch).fetchAll({ shallow: true });
  }, []);

  const handleClose = () => {
    deselectAction();
    setOpen(false);
  };

  const afterPolling = () => {
    new ProjectRoomBooksResource(dispatch, project.id).fetchAll({
      template: true
    });
    handleClose();
  };

  const [startPolling, stopPolling, isPolling] = usePolling(
    new FileImportsResource(dispatch),
    15,
    afterPolling
  );

  const createTree = list => {
    list.forEach(item => {
      const temp = item;
      temp.derived = list.filter(tf => tf.source_id === item.id);
      temp.chcked = false;
    });
    return list.filter(t => t.source_id === null);
  };

  useEffect(() => {
    if (selectedProject)
      new ProjectRoomBooksResource(dispatch, selectedProject)
        .fetchTemplatesWithoutPropagatedUpdate(selectedProject)
        .then(res => {
          const templates = res.data?.room_books;
          setListData(templates);
          if (templates) setTreeData(createTree(templates));
        });
  }, [selectedProject]);

  const options = useMemo(
    () =>
      projects
        .filter(p => p.id !== project.id)
        .map(p => ({
          key: p.id,
          value: p.id,
          text: p.name
        })),
    [projects]
  );

  const handleSave = () => {
    const copy_room_book_template_ids = listData
      .filter(d => d.checked)
      .map(d => d.id);
    const payload = {
      room_book: { source_id: selectedProject, copy_room_book_template_ids }
    };
    new ProjectRoomBooksResource(dispatch, project.slug)
      .copyRoomBookTemplates(payload)
      .then(res => {
        if (res.data?.id) {
          startPolling(res.data?.id, STATUS_CHECK_INTERVAL);
        }
      })
      .catch(() => {
        stopPolling();
      });
  };

  const handleTreeChange = useCallback(
    (selection, checked) => {
      const tempData = [...listData];
      let tempItem = tempData.find(x => x.id === selection);
      tempItem.checked = checked;
      if (checked) {
        let sourceId = tempItem.source_id;
        while (sourceId) {
          const { source_id } = tempItem;
          tempItem = tempData.find(x => x.id === source_id);
          tempItem.checked = true;
          sourceId = tempItem.source_id;
        }
      }
      setListData(tempData);
      setTreeData(createTree(tempData));
    },
    [treeData]
  );

  return (
    <>
      <Modal
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        closeIcon
      >
        <Modal.Header>
          <FormattedMessage id="roomBook.copyTemplates.dialog.title" />
        </Modal.Header>
        <Modal.Content scrolling style={{ height: "70vh" }}>
          <Header as="h5">
            <FormattedMessage id="roomBook.copyTemplates.dialog.sourceProject" />
          </Header>
          <Dropdown
            selection
            search
            options={options}
            value={selectedProject}
            onChange={(_, { value }) => setProject(value)}
          />
          <Header as="h5">
            <FormattedMessage id="roomBook.copyTemplates.dialog.templates" />
          </Header>
          <SelectableTree data={treeData} onChange={handleTreeChange} />
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={16} textAlign="right">
              <Button
                basic
                id="close"
                content={intl.formatMessage({
                  id: "meta.actions.close"
                })}
                onClick={handleClose}
              />
              <Button
                primary
                id="submit"
                type="submit"
                content={intl.formatMessage({ id: "meta.actions.save" })}
                onClick={handleSave}
                disabled={isPolling || !selectedProject}
              />
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
      <TinyLoader opened={isPolling} />
    </>
  );
};

RoomBookTemplatesImport.propTypes = {
  deselectAction: func.isRequired
};

export default RoomBookTemplatesImport;
