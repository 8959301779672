import htmlToText from "html-to-text";
import { Liquid } from "liquidjs";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Button, Input, Message, Modal } from "semantic-ui-react";
import Growl from "builder_portal/actions/growlActions";
import MailEditor from "../../../../shared/components/elements/MailEditor";
import { MailTemplatesResource } from "../../../actions/accountActions";
import "./emailTemplateDialog.scss";

class EmailTemplateDialog extends React.Component {
  constructor(props) {
    super(props);

    const emailTemplate = props.emailTemplate || { role: "default_mail" };
    this.state = {
      open: props.open,
      emailTemplate,
      hasError: false,
      isValid: this.validateEmailTemplate(emailTemplate),
      liquidError: ""
    };
  }

  handleClose = () => {
    const { emailTemplate } = this.props;
    const nextEmailTemplate = emailTemplate
      ? { ...emailTemplate }
      : { role: "default_mail" };

    this.setState({
      open: false,
      emailTemplate: nextEmailTemplate
    });
  };

  handleOpen = () => {
    const { emailTemplate } = this.props;
    const project_ids =
      emailTemplate.projects?.map(project => project.id) || [];
    this.setState({
      open: true,
      emailTemplate: { ...emailTemplate, project_ids }
    });
  };

  handleSubmit = () => {
    const {
      mailTemplatesResource,
      mailTemplateExampleParams,
      growl
    } = this.props;
    const { emailTemplate } = this.state;
    const text = htmlToText.fromString(emailTemplate.html, {
      singleNewLineParagraphs: true
    });
    try {
      const liquidEngine = new Liquid({ strictVariables: true });
      liquidEngine.parseAndRenderSync(
        emailTemplate.html,
        mailTemplateExampleParams[emailTemplate.role]
      );

      this.setState({ isLoading: true });
      mailTemplatesResource
        .save({
          ...emailTemplate,
          text
        })
        .then(() => {
          this.setState({ isLoading: false });
          growl.success("message.success.title", "message.success.body");
        })
        .then(this.handleClose)
        .catch(() => {
          this.setState({ isLoading: false, hasError: true });
        });
    } catch (e) {
      /* Displaying the error that was sent to raven
       * makes in-place-debugging much easier
       */
      // eslint-disable-next-line no-console
      console.error("Liquid Error occurred", e);

      this.setState({ isLoading: false, liquidError: e.message });
    }
  };

  validateEmailTemplate = emailTemplate =>
    !!(
      emailTemplate &&
      // template name should at least have three chars
      emailTemplate.name &&
      emailTemplate.name.length >= 3 &&
      // html body should at least have five chars (html tags included)
      emailTemplate.html &&
      emailTemplate.html.length >= 5
    );

  render() {
    const { trigger, i18n } = this.props;
    const {
      hasError,
      open,
      isLoading,
      emailTemplate,
      isValid,
      liquidError
    } = this.state;

    return (
      <Modal
        trigger={trigger}
        open={open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeIcon
        size="large"
        centered={false}
        data-component="emailTemplateDialog"
      >
        <Modal.Header>
          <FormattedMessage id="activerecord.models.mail_template.one" />
          <Input
            transparent
            icon="pencil"
            iconPosition="left"
            defaultValue={emailTemplate.name}
            name="templateName"
            onChange={(e, { value }) => {
              this.setState(prevState => {
                const nextEmailTemplate = {
                  ...prevState.emailTemplate,
                  name: value
                };
                return {
                  emailTemplate: nextEmailTemplate,
                  isValid: this.validateEmailTemplate(nextEmailTemplate)
                };
              });
            }}
          />
        </Modal.Header>
        <Modal.Content>
          <MailEditor
            defaultValue={{
              role: emailTemplate.role,
              subject: emailTemplate.subject,
              body: emailTemplate.html,
              projects: emailTemplate.projects,
              sender: emailTemplate.sender_label
            }}
            onChange={({ subject, body, role, project_ids, sender }) => {
              this.setState(prevState => {
                const nextEmailTemplate = { ...prevState.emailTemplate };
                if (subject !== undefined) {
                  nextEmailTemplate.subject = subject;
                }
                if (body !== undefined) {
                  nextEmailTemplate.html = body;
                }
                if (role !== undefined) {
                  nextEmailTemplate.role = role;
                }
                if (project_ids !== undefined) {
                  nextEmailTemplate.project_ids = project_ids;
                }
                if (sender !== undefined) {
                  nextEmailTemplate.sender_label = sender;
                }

                return {
                  emailTemplate: nextEmailTemplate,
                  isValid: this.validateEmailTemplate(nextEmailTemplate),
                  liquidError: ""
                };
              });
            }}
          />
          <Message negative hidden={!hasError}>
            <Message.Header>
              <FormattedMessage
                id="message.errorSave.title"
                defaultMessage="Hoppla"
              />
            </Message.Header>
            <p>
              <FormattedMessage
                id="message.errorSave.body"
                defaultMessage="Hier ist etwas schiefgelaufen. Die Daten konnten nicht gespeichert werden."
              />
            </p>
          </Message>
          <Message negative hidden={isValid}>
            <Message.Header>
              <FormattedMessage
                id="message.errorSave.title"
                defaultMessage="Hoppla"
              />
            </Message.Header>
            <p>
              <strong>
                <FormattedMessage
                  id="macro.attributes.name.label"
                  defaultMessage="Name"
                />
              </strong>
              &nbsp;
              <FormattedMessage
                id="message.errorForm.at_least_n_chars"
                defaultMessage="muss mindestens aus {n} Zeichen bestehen"
                values={{ n: 3 }}
              />
            </p>
            <p>
              <strong>
                <FormattedMessage
                  id="macro.attributes.body_template.label"
                  defaultMessage="Vorlage Nachricht"
                />
              </strong>
              &nbsp;
              <FormattedMessage
                id="message.errorForm.at_least_n_chars"
                defaultMessage="muss mindestens aus {n} Zeichen bestehen"
                values={{ n: 5 }}
              />
            </p>
          </Message>
          <Message negative hidden={!liquidError}>
            <Message.Header>
              <FormattedMessage
                id="message.errorSave.title"
                defaultMessage="Hoppla"
              />
            </Message.Header>
            <p>{liquidError}</p>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            loading={isLoading}
            content={i18n["meta.actions.cancel"]}
            onClick={this.handleClose}
          />
          <Button
            id="submit"
            disabled={!isValid || liquidError.length > 0}
            positive
            loading={isLoading}
            content={i18n["meta.actions.save"]}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n,
  mailTemplateExampleParams: state.pageContent.mailTemplateExampleParams
});
const mapDispatchToProps = dispatch => ({
  mailTemplatesResource: new MailTemplatesResource(dispatch),
  growl: new Growl(dispatch)
});

EmailTemplateDialog.defaultProps = {
  emailTemplate: {
    id: null,
    name: "Neue Vorlage", // TODO i18n
    role: "default_mail",
    subject: "",
    html: "",
    text: ""
  },
  i18n: {},
  open: false,
  mailTemplateExampleParams: undefined
};

EmailTemplateDialog.propTypes = {
  // i18n provides no shape
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object,
  mailTemplateExampleParams: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string)
      ])
    )
  ),
  emailTemplate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    subject: PropTypes.string,
    html: PropTypes.string,
    text: PropTypes.string,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.numbner,
        name: PropTypes.string
      })
    ),
    project_ids: PropTypes.arrayOf(PropTypes.number)
  }),
  trigger: PropTypes.node.isRequired,
  open: PropTypes.bool,
  mailTemplatesResource: PropTypes.instanceOf(MailTemplatesResource).isRequired,
  growl: PropTypes.instanceOf(Growl).isRequired
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateDialog);
