import { createSelector } from "reselect";
import {
  merge,
  isArray,
  isEmpty,
  isEqual,
  groupBy,
  keyBy,
  get,
  intersectionWith
} from "lodash";
import { getBuyerSelections, getFlatRoomBook } from "./base";

export class RulesEngine {
  constructor(baseRules, baseFacts) {
    this.baseRules = baseRules;
    this.baseFacts = baseFacts;
    this.rules = this.filterMatchingRules(baseRules, baseFacts);
  }

  assert(assertable) {
    if (isEmpty(this.rules)) {
      return true;
    }
    return this.assertItem(this.rules, assertable);
  }

  getApplicableFacts() {
    return this.baseFacts.filter(fact => {
      return this.baseRules.some(rule => {
        return this.assertFilter(rule.when, fact);
      });
    });
  }

  assertItem(rules, item) {
    return rules.every(rule => {
      return this.assertFilter(rule.then, item);
    });
  }

  assertFilter(filter, item) {
    return Object.entries(filter).every(([key, matcher]) => {
      return this.matches(item[key], matcher);
    });
  }

  filterMatchingRules(rules, facts) {
    return rules.filter(rule => {
      return facts.some(fact => {
        return this.assertFilter(rule.when, fact);
      });
    });
  }

  matches(item, matcher) {
    return Object.entries(matcher).every(([property, expectedValues]) => {
      const actualValue = item[property];
      if (isArray(actualValue)) {
        return (
          intersectionWith(actualValue, expectedValues, isEqual).length > 0
        );
      }
      return expectedValues.indexOf(actualValue) >= 0;
    });
  }
}

export const getRuleSets = state => get(state, "pageContent.product_rules", []);

export const getRuleSetsByProductGroup = createSelector(
  [getRuleSets],
  ruleSets => {
    const mappedRuleSets = ruleSets.map(
      ({ product_group_then, product_group_when, rules, scope }) => ({
        product_group_id: product_group_then,
        rules: rules.map(({ when, then }) => ({
          when: { ...when, product_group: { id: [product_group_when] } },
          then
        })),
        scope
      })
    );
    return groupBy(mappedRuleSets, "product_group_id");
  }
);

const subLineItemToFact = subLineItem => {
  return {
    line_item: {
      id: subLineItem.line_item_id
    },
    sub_section: {
      id: subLineItem.sub_section_id
    },
    section: {
      id: subLineItem.section_id
    },
    sub_line_item: {
      id: subLineItem.id
    },
    product_group: {
      id: subLineItem.product_group_id
    },
    product: {
      id: subLineItem.product_id
    }
  };
};

const getFacts = createSelector(
  [getBuyerSelections, getFlatRoomBook],
  (buyerSelections, roomBook) => {
    const subLineItems = keyBy(roomBook.subLineItems, "id");
    return Object.entries(buyerSelections).reduce((accu, [id, obj]) => {
      const subLineItem = subLineItems[id];

      if (!obj || !subLineItem) {
        return accu;
      }

      return accu.concat([
        merge(subLineItemToFact(subLineItem), {
          room_book: {
            id: roomBook.id
          },
          product: {
            id: obj.product_id
          }
        })
      ]);
    }, []);
  }
);

const getFactsByLineItem = createSelector([getFacts], facts => {
  return groupBy(facts, fact => fact.line_item.id);
});

const getFactsBySubSection = createSelector([getFacts], facts => {
  return groupBy(facts, fact => fact.sub_section.id);
});

const getFactsBySection = createSelector([getFacts], facts => {
  return groupBy(facts, fact => fact.section.id);
});

const getFactsByScopeFn = createSelector(
  [getFacts, getFactsByLineItem, getFactsBySubSection, getFactsBySection],
  (facts, factsByLineItem, factsBySubSection, factsBySection) => {
    return (scope, subLineItem) => {
      switch (scope) {
        case "line_item":
          return factsByLineItem[subLineItem.line_item_id] || [];
        case "sub_section":
          return factsBySubSection[subLineItem.sub_section_id] || [];
        case "section":
          return factsBySection[subLineItem.section_id] || [];
        default:
          return facts;
      }
    };
  }
);

export const isProductAcceptable = createSelector(
  [getRuleSetsByProductGroup, getFactsByScopeFn],
  (ruleSets, getFactsByScope) => {
    return (subLineItem, product) => {
      const productRuleSets = ruleSets[subLineItem.product_group_id] || [];
      return (
        !product ||
        productRuleSets.every(rule => {
          const facts = getFactsByScope(rule.scope, subLineItem);
          const engine = new RulesEngine(rule.rules, facts);
          return engine.assert({ product });
        })
      );
    };
  }
);

/*
 * For each subLineItem.id it returns an array of dependencies to other subLineItems.
 */
export const getSubLineItemDependencies = createSelector(
  [getRuleSetsByProductGroup, getFactsByScopeFn, getFlatRoomBook],
  (ruleSets, getFactsByScope, roomBook) => {
    const { subLineItems } = roomBook;

    return subLineItems.reduce((accu, subLineItem) => {
      const rules = ruleSets[subLineItem.product_group_id] || [];

      const dependencies = rules.reduce((accu, rule) => {
        const facts = getFactsByScope(rule.scope, subLineItem);
        const engine = new RulesEngine(rule.rules, facts);

        const applicableFacts = engine.getApplicableFacts();
        return accu.concat(
          applicableFacts.map(f => {
            return f.sub_line_item.id;
          })
        );
      }, []);

      accu[subLineItem.id] = dependencies;
      return accu;
    }, {});
  }
);
