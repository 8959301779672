import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Segment,
  Header,
  Button,
  Loader,
  List,
  Grid,
  Icon
} from "semantic-ui-react";
import { If } from "shared/components/elements/Conditions";
import getProjectRoles from "shared/selectors/projectRoles";
import IsVersionHistoryAccessible from "shared/components/elements/IsVersionHistoryAccessible";
import VersionHistoryLink from "shared/components/elements/VersionHistoryLink";
import ProjectRoleDialog from "./ProjectRoleDialog";
import ProjectsRolesResource from "../../../actions/projectRolesActions";

const ProjectRoles = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const projectRoles = useSelector(getProjectRoles);
  const [isLoading, setLoading] = useState(false);

  // fetch all project roles
  useEffect(() => {
    setLoading(true);
    new ProjectsRolesResource(dispatch)
      .fetchAll()
      .then(() => setLoading(false));
  }, []);

  const renderProjectRolesList = () => {
    return (
      <>
        <If condition={isLoading === "initial"} block>
          <Loader active />
        </If>
        <If condition={!isLoading && !projectRoles.length} block>
          <FormattedMessage id="projectRoles.noRolesFound" />
        </If>
        <List divided relaxed size="large">
          {projectRoles.map(role => (
            <List.Item key={role.id}>
              <List.Content floated="right">
                <IsVersionHistoryAccessible>
                  <VersionHistoryLink id={role.id} type="ProjectRole" />
                </IsVersionHistoryAccessible>
                <ProjectRoleDialog
                  model={{ ...role }}
                  trigger={
                    <Button data-cmd="edit" className="icon">
                      <Icon name="setting" />
                    </Button>
                  }
                />
              </List.Content>
              <List.Content>
                <List.Header>{role.name}</List.Header>
                <List.Description>{role.description}</List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </>
    );
  };

  return (
    <Segment style={{ marginTop: 0, paddingBottom: "50px" }}>
      <If condition={isLoading}>
        <Loader active style={{ marginTop: "10px" }} />
      </If>
      <If condition={!isLoading} block>
        <Grid stackable verticalAlign="middle">
          <Grid.Column width="10">
            <Header as="h3">
              <FormattedMessage id="projectRoles.title" />
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right" width="6">
            <ProjectRoleDialog
              trigger={
                <Button
                  role="button"
                  id="user-new"
                  fluid
                  compact
                  icon="plus"
                  content={intl.formatMessage({
                    id: "projectRoles.actions.add"
                  })}
                />
              }
            />
          </Grid.Column>
        </Grid>
        {renderProjectRolesList()}
      </If>
    </Segment>
  );
};

export default ProjectRoles;
