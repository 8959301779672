import { shape, string, oneOfType, number, node } from "prop-types";

const OptionsShape = shape({
  key: oneOfType([string, number]),
  value: oneOfType([string, number]),
  text: node,
  content: node
});

export default OptionsShape;
