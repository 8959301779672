import PropTypes from "prop-types";
import React from "react";
import { Loader, Header, Segment, Grid, Table, Icon } from "semantic-ui-react";
import { get } from "lodash";
import ConfirmationDialog from "shared/components/dialogs/ConfirmationDialog";
import { FormattedMessage } from "react-intl";
import AttachmentDialog from "./AttachmentDialog";

class Attachments extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    admin: PropTypes.object,
    pageContent: PropTypes.object,
    i18n: PropTypes.object,
    growl: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  onSaveFn(account) {
    return attachmentType => {
      const payload = {
        id: account.id,
        attachment_types: this.mergeAttachmentType(
          attachmentType,
          account.attachment_types
        )
      };
      return this.props.actions.account.save(payload).then(() => {
        return this.props.actions.account.get(true);
      });
    };
  }

  onDelete(account, attachmentType, attachmentTypes) {
    attachmentTypes.splice(attachmentTypes.indexOf(attachmentType), 1);
    const payload = {
      id: account.id,
      attachment_types: attachmentTypes
    };
    return this.props.actions.account.save(payload).then(() => {
      return this.props.actions.account.get(true);
    });
  }

  onMoveFn(account, attachmentType, attachmentTypes, direction) {
    return () => {
      const oldIdx = attachmentTypes.indexOf(attachmentType);
      const newIdx = oldIdx + (direction === "up" ? -1 : 1);

      if (newIdx >= 0 && newIdx < attachmentTypes.length) {
        attachmentTypes.splice(oldIdx, 1);
        attachmentTypes.splice(newIdx, 0, attachmentType);
      }

      const payload = {
        id: account.id,
        attachment_types: attachmentTypes
      };
      return this.props.actions.account.save(payload).then(() => {
        return this.props.actions.account.get(true);
      });
    };
  }

  mergeAttachmentType(attachmentType, attachmentTypes) {
    const ids = attachmentTypes.map(t => {
      return t.id;
    });

    if (ids.indexOf(attachmentType.id) >= 0) {
      return attachmentTypes.map(t => {
        return t.id === attachmentType.id ? attachmentType : t;
      });
    }
    return attachmentTypes.concat([attachmentType]);
  }

  render() {
    const { pageContent } = this.props;
    const { account } = pageContent;

    if (account) {
      return (
        <Segment.Group data-component="users">
          <Segment attached="top">
            <Grid stackable verticalAlign="middle">
              <Grid.Column width="10">
                <Header as="h5">
                  <FormattedMessage
                    id="attachment_type.title.many"
                    default="attachment_type.title.many"
                  />
                  : {account.name}
                </Header>
              </Grid.Column>
              <Grid.Column width="6" textAlign="right">
                {this.renderCreateButton(account)}
              </Grid.Column>
            </Grid>
          </Segment>
          <Table striped attached>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="attachment_type.attributes.label.label"
                    default="attachment_type.attributes.label.label"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="attachment_type.attributes.id.label"
                    default="attachment_type.attributes.id.label"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage
                    id="attachment_type.attributes.count.label"
                    default="attachment_type.attributes.count.label"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderChildren(account)}</Table.Body>
          </Table>
        </Segment.Group>
      );
    }
    return <Loader active />;
  }

  renderChildren(account) {
    const attachmentTypes = get(account, "attachment_types", []);
    return attachmentTypes.map(type => {
      return (
        <Table.Row key={type.id} data-id={type.id} data-model="attachment_type">
          <Table.Cell data-attr="label">{type.label}</Table.Cell>
          <Table.Cell data-attr="id">
            <i>{type.id}</i>
          </Table.Cell>
          <Table.Cell data-attr="count">{type.count}</Table.Cell>
          <Table.Cell>
            <AttachmentDialog
              mode="EDIT"
              attachmentType={type}
              attachmentTypes={attachmentTypes}
              button={
                <a role="button">
                  <Icon name="setting" color="grey" />
                </a>
              }
              i18n={this.props.i18n}
              onSave={this.onSaveFn(account).bind(this)}
            />
          </Table.Cell>
          <Table.Cell>
            {this.renderDeleteButton(account, type, attachmentTypes)}
          </Table.Cell>
          <Table.Cell>
            {this.renderMoveButton(account, type, attachmentTypes, "up")}
          </Table.Cell>
          <Table.Cell>
            {this.renderMoveButton(account, type, attachmentTypes, "down")}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  renderCreateButton(account) {
    const attachmentTypes = get(account, "attachment_types", []);
    const type = {
      id: "",
      label: ""
    };
    return (
      <AttachmentDialog
        mode="CREATE"
        attachmentType={type}
        attachmentTypes={attachmentTypes}
        button={
          <a role="button">
            <Icon name="plus" color="grey" />
          </a>
        }
        i18n={this.props.i18n}
        onSave={this.onSaveFn(account).bind(this)}
      />
    );
  }

  renderDeleteButton(account, attachmentType, attachmentTypes) {
    const { i18n } = this.props;

    const button = (
      <a
        role="button"
        id="product_group-remove"
        data-tooltip={i18n["meta.actions.remove"]}
      >
        <Icon name="trash" color="grey" />
      </a>
    );

    if ((attachmentType.count || 0) > 0) {
      const buttons = [
        {
          id: "ok",
          label: "meta.actions.accept",
          basic: true
        }
      ];
      return (
        <ConfirmationDialog
          title="attachment_type.actions.undeletableDialog.title"
          message="attachment_type.actions.undeletableDialog.message"
          buttons={buttons}
          button={button}
        />
      );
    }
    const buttons = [
      {
        id: "delete",
        label: "meta.actions.remove",
        color: "red",
        onClick: () => {
          this.onDelete(account, attachmentType, attachmentTypes);
        }
      },
      {
        id: "cancel",
        label: "meta.actions.cancel",
        basic: true
      }
    ];

    return (
      <ConfirmationDialog
        title="attachment_type.actions.removeDialog.title"
        message="attachment_type.actions.removeDialog.message"
        buttons={buttons}
        button={button}
      />
    );
  }

  renderMoveButton(account, attachmentType, attachmentTypes, direction) {
    return (
      <a
        role="button"
        onClick={this.onMoveFn(
          account,
          attachmentType,
          attachmentTypes,
          direction
        ).bind(this)}
      >
        <Icon name={`chevron ${direction}`} color="grey" />
      </a>
    );
  }
}

export default Attachments;
