import { omit, sortBy } from "lodash";

const shallowRemoveZeroCostElementsFromCollection = collection => {
  return collection.filter(item => item.cost !== 0);
};

export const isProductPriceDirty = (
  model,
  productDictionary,
  isDefault = false
) => {
  const productId = isDefault ? model.default_product_id : model.product_id;
  const price = isDefault ? model.default_price : model.price;
  if (!productId) {
    return false;
  }
  const product = productDictionary[productId];

  return product && product.group.price !== price;
};

export const areProductCostsDirty = (
  model,
  productDictionary,
  isDefault = false
) => {
  if (!model.product_id) {
    // no model, no dirty :)
    return false;
  }
  const product = productDictionary[model.product_id];

  if (!isDefault) {
    if (
      JSON.stringify(
        sortBy(
          shallowRemoveZeroCostElementsFromCollection(
            shallowRemoveIdPropertiesFromCollection(model.costs_attributes)
          ),
          "trade"
        )
      ) !==
      JSON.stringify(
        sortBy(
          shallowRemoveZeroCostElementsFromCollection(
            shallowRemoveIdPropertiesFromCollection(
              product.group.costs_attributes
            )
          ),
          "trade"
        )
      )
    ) {
      return true;
    }
    return false;
  }
  if (
    JSON.stringify(
      sortBy(
        shallowRemoveZeroCostElementsFromCollection(
          shallowRemoveIdPropertiesFromCollection(
            model.default_costs_attributes
          )
        ),
        "trade"
      )
    ) !==
    JSON.stringify(
      sortBy(
        shallowRemoveZeroCostElementsFromCollection(
          shallowRemoveIdPropertiesFromCollection(
            product.group.costs_attributes
          )
        ),
        "trade"
      )
    )
  ) {
    return true;
  }
  return false;
};

export const shallowRemoveIdPropertiesFromCollection = collection => {
  return collection.map(item => {
    return omit(item, [
      "id",
      "cost_created_by_id",
      "cost_updated_by_id",
      "excess_cost_created_by_id",
      "excess_cost_updated_by_id"
    ]);
  });
};

export const isActivityStaredYet = model => {
  if (model && model.activity && model.activity.status !== "phase_01_01") {
    return true;
  }
  return false;
};
