import React from "react";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";
import { locationShape } from "react-router";
import { get } from "lodash";
import { I18nShape } from "shared/shapes/i18n.shape";
import Growl from "builder_portal/actions/growlActions";
import PasswordResource from "builder_portal/actions/passwordActions";
import PasswordResetForm from "./PasswordResetForm";
import PasswordRequestForm from "./PasswordRequestForm";

function Password({ i18n, growl, resource, location }) {
  const { token } = get(location, "query");

  if (token) {
    return (
      <PasswordResetForm
        token={token}
        i18n={i18n}
        growl={growl}
        resource={resource}
      />
    );
  }
  return <PasswordRequestForm i18n={i18n} growl={growl} resource={resource} />;
}

Password.propTypes = {
  i18n: I18nShape.isRequired,
  growl: instanceOf(Growl).isRequired,
  resource: instanceOf(PasswordResource).isRequired,
  location: locationShape.isRequired
};

const mapStateToProps = state => {
  return { i18n: state.i18n };
};

const mapDispatchToProps = dispatch => {
  return {
    growl: new Growl(dispatch),
    resource: new PasswordResource(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
