import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, Button, Grid } from "semantic-ui-react";
import ProductSearchContainer from "./ProductSearchContainer";

class ProductSearchDialog extends Component {
  static propTypes = {
    i18n: PropTypes.object,
    resources: PropTypes.object,
    account: PropTypes.object,
    button: PropTypes.object,
    handleUpdate: PropTypes.func,
    productGroupId: PropTypes.number,
    usedProductIds: PropTypes.array,
    suppliers: PropTypes.array,
    products: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = { open: false };

    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const {
      i18n,
      products,
      resources,
      account,
      handleUpdate,
      productGroupId,
      usedProductIds,
      suppliers,
      button
    } = this.props;

    const title = "product_price.actions.add.label";

    return (
      <Modal
        size="large"
        closeOnEscape
        closeOnDimmerClick={false}
        closeIcon
        trigger={button}
        open={this.state.open}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
      >
        <Modal.Header>
          <FormattedMessage id={title} defaultMessage={title} />
        </Modal.Header>
        <Modal.Content style={{ overflowY: "auto" }}>
          <ProductSearchContainer
            i18n={i18n}
            resources={resources}
            account={account}
            handleUpdate={handleUpdate}
            productGroupId={productGroupId}
            usedProductIds={usedProductIds}
            suppliers={suppliers}
            products={products}
            onSave={this.handleClose}
          />
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={13} verticalAlign="middle" />
            <Grid.Column textAlign="right" width={3}>
              <Button
                id="close"
                basic
                color="grey"
                content={i18n["meta.actions.close"]}
                loading={this.state.isLoading}
                onClick={() => this.setState({ open: false })}
              />
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  handleClose() {
    this.setState({ open: false });
  }
}

export default ProductSearchDialog;
