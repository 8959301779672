import React from "react";
import { PropTypes } from "prop-types";
import IsSystemAdminOrHasSpecificRight from "shared/components/authorization/IsSystemAdminOrHasSpecificRight";

const IsVersionHistoryAccessible = ({ children }) => (
  <IsSystemAdminOrHasSpecificRight right="ext_allow_access_version_history">
    {children}
  </IsSystemAdminOrHasSpecificRight>
);

IsVersionHistoryAccessible.propTypes = {
  children: PropTypes.node
};

IsVersionHistoryAccessible.defaultProps = {
  children: null
};

export default IsVersionHistoryAccessible;
